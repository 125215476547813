import {Response} from '../../../../../../urdad/services/models/response.model';

export class ProvideSecuredLegalEntityObscuredContactNumberResponse extends Response {
    constructor() {
        super();
    }

    obscuredContactNumber: string;
    country: string;
}
