import { Criteria } from "../../../criteria/models/criteria.model";

export class ReportDateCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.ReportDateCriteria";
    }

    public dateFrom: Date;
    public dateTo: Date;
}