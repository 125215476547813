import {Criteria} from '../../criteria/models/criteria.model';

export class SystemParameterNameTypeCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.system.SystemParameterNameTypeCriteria";
    }

    systemParameterName: string;
    systemParameterType: string;
}