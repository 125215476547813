import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {
  Card,
  CardPaymentOption,
  CardToken,
  CreatePaymentProfileRequest,
  CreatePaymentProfileResponse,
  Money,
  OrderReferenceNumber,
  ProcessCardPaymentRequest,
  ProcessCardPaymentResponse,
  ProcessTokenPaymentRequest,
  ProcessTokenPaymentResponse,
  RemovePaymentOptionRequest,
  RetailProfileOwnerIdentifier,
  TextualElaboration,
  UnMarshallerService
} from '@magnabc/tpi'
import * as moment from 'moment'
import {environment} from "../../../../../../environments/environment"
import {FormComponent} from "../../../../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {ToastComponent} from "../../../../shared/toast/toast.component"
import {Utils} from "../../../../shared/Utils/Utils"
import {
  PaymentPersistenceManagerService
} from '../../../../../http.services/retail/payment/payment-persistence-manager/payment-persistence-manager.service'
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service'
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
  DirectPayOnlinePaymentGatewayManagerService
} from '../../../../../http.services/retail/payment/dpo/direct-pay-online-payment-gateway-manager/direct-pay-online-gateway-manager.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import {
  LegalEntityFinalisationManagerService
} from "../../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
    selector: 'app-direct-pay-online-payment-gateway-manager',
    templateUrl: './direct-pay-online-payment-gateway-manager.component.html',
    styleUrls: ['./direct-pay-online-payment-gateway-manager.component.scss']
})
export class DirectPayOnlinePaymentGatewayManagerComponent extends FormComponent implements OnInit {

    @Input() padding = true;
    @Input() reference: string;
    @Input() description: string;
    @Input() amountInCents = 1000;
    @Input() currencyCode = 'USD';

    @Output() onPaid = new EventEmitter<void>();
    @Output() onContinue = new EventEmitter<void>();

    paymentComplete = false;
    currentTab = "creditCard"; // creditCard, recent
    errorMessage: string;
    loadingMap: {[id: string]: boolean} = {};
    enterCvv: string;

    card: Card;

    selectedCardToken: CardToken;
    activeToast: ActiveToast<any>;

    constructor(
        private translate: TranslateService,
        private translateParser: TranslateParser,
        public paymentService: PaymentPersistenceManagerService,
        private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
        private directPayOnlinePaymentGatewayManagerService: DirectPayOnlinePaymentGatewayManagerService,
        private authenticationService: AuthenticationService,
        private unMarshallerService: UnMarshallerService,
        private toastr: ErrorToastService,
        private runtimeConfigurationService: RuntimeConfigurationService,
    ) {
        super(translate, translateParser);
    }

    ngOnInit() {
    }

    onTab(tab: string): boolean {
        this.currentTab = tab;
        return false;
    }

    onRowSelect(event): void {
        if (event.selected.length > 0) {
            this.selectedCardToken = event.selected[0];
        }
    }

    onPayWithToken(event): void {
        if (this.selectedCardToken) {
            this.processTokenPayment(this.selectedCardToken);
        }
    }

    refreshPaymentProfile(callback): void {
        this.paymentService.refreshRetailProfile(this.legalEntityFinalisationManagerService.legalEntity, callback);
    }

    onDeleteCardSelected(event, cardToken: CardToken): boolean {

        event.stopPropagation();

        this.translate.get('TOAST.DELETE_CARD').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: cardToken.cardLastFourDigits}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.onDeleteCard(cardToken);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

        return false;

    }

    onDeleteCard(cardToken: CardToken): void {

        event.stopPropagation();

        const paymentOption = new CardPaymentOption();
        paymentOption.cardLastFourDigits = cardToken.cardLastFourDigits;
        paymentOption.cardExpiryDate = cardToken.cardExpiryDate;
        paymentOption.cardType = cardToken.cardType;

        this.startLoad();

        const retailProfileIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const removePaymentOptionRequest = new RemovePaymentOptionRequest();
        removePaymentOptionRequest.cardPaymentOption = paymentOption;
        removePaymentOptionRequest.retailProfileIdentifier = retailProfileIdentifier;
        this.directPayOnlinePaymentGatewayManagerService.removePaymentOption(removePaymentOptionRequest).then(response =>{
            this.refreshPaymentProfile(() => {
                this.stopLoad();
                if (this.paymentService.paymentProfile.cardTokens.length === 0) {
                    this.currentTab = 'creditCard';
                }
            });
            this.toast(response.resultExplanation, () => {});
        }).catch((error) => {
            this.toastr.errorToast(error);
            this.stopLoad();
        })

    }

    processCardPayment(card: Card): void {

        const retailProfileIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const processCardPaymentRequest = new ProcessCardPaymentRequest();
        processCardPaymentRequest.card = card;
        processCardPaymentRequest.retailProfileIdentifier = retailProfileIdentifier;
        processCardPaymentRequest.amount = new Money();
        processCardPaymentRequest.amount.currency = this.currencyCode;
        processCardPaymentRequest.amount.amount = this.amountInCents;
        processCardPaymentRequest.paymentReference = new OrderReferenceNumber(this.reference);
        processCardPaymentRequest.backUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/orders?noRedirect=true`;
        processCardPaymentRequest.declineUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/orders?noRedirect=true`;
        processCardPaymentRequest.redirectUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/orders?noRedirect=true`;

        this.startLoad();
        this.directPayOnlinePaymentGatewayManagerService.processCardPayment(processCardPaymentRequest).then(data => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProcessCardPaymentResponse) as ProcessCardPaymentResponse;
            if (response.paymentAdvice.paymentSuccessful) {
                this.toast((response.paymentAdvice.elaboration as TextualElaboration).message, () => {
                    this.onPaid.emit();
                });
            } else {
                this.errorMessage = (response.paymentAdvice.elaboration as TextualElaboration).message;
            }
            this.stopLoad();
        }).catch((error) => {
            this.errorMessage = error.error;
            this.stopLoad();
        })

    }

    createPaymentProfile(card: Card): void {

        const cardExpiry = moment(`${card.expiryMonth} ${card.expiryYear}`, 'MMYY').endOf('month').toDate();

        this.errorMessage = null;

        this.startLoad();

        const retailProfileIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const createPaymentProfileRequest = new CreatePaymentProfileRequest();
        createPaymentProfileRequest.card = card;
        createPaymentProfileRequest.emailAddress = this.legalEntityFinalisationManagerService.legalEntity.emailAddresses[0];
        createPaymentProfileRequest.retailProfileIdentifier = retailProfileIdentifier;
        this.directPayOnlinePaymentGatewayManagerService.createPaymentProfile(createPaymentProfileRequest).then(data =>{
            const response = this.unMarshallerService.unMarshallFromJson(data.body, CreatePaymentProfileResponse) as CreatePaymentProfileResponse;
            if (response.cardPaymentOption) {
                this.enterCvv = card.cardVerificationValue;
                const cardToken = new CardToken();
                cardToken.cardType = response.cardPaymentOption.cardType;
                cardToken.cardLastFourDigits = response.cardPaymentOption.cardLastFourDigits;
                cardToken.value = card.cardVerificationValue;
                cardToken.cardExpiryDate = cardExpiry;
                this.processTokenPayment(cardToken);
            } else {
                this.stopLoad();
            }
        }).catch((error) => {
            console.error(error);
            this.errorMessage = error.error;
            this.stopLoad();
        })

    }

    processTokenPayment(cardToken: CardToken): void {

        const retailProfileIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const cardPaymentOption = new CardPaymentOption();
        cardPaymentOption.cardExpiryDate = cardToken.cardExpiryDate;
        cardPaymentOption.cardLastFourDigits = cardToken.cardLastFourDigits;
        cardPaymentOption.cardType = cardToken.cardType;

        const processTokenPaymentRequest = new ProcessTokenPaymentRequest();
        processTokenPaymentRequest.cardPaymentOption = cardPaymentOption;
        processTokenPaymentRequest.cardVerificationValue = this.enterCvv ? this.enterCvv.replace(/ /g, '') : null;
        processTokenPaymentRequest.retailProfileIdentifier = retailProfileIdentifier;
        processTokenPaymentRequest.amount = new Money();
        processTokenPaymentRequest.amount.currency = this.currencyCode;
        processTokenPaymentRequest.amount.amount = this.amountInCents;
        processTokenPaymentRequest.paymentReference = new OrderReferenceNumber(this.reference);
        processTokenPaymentRequest.backUrl = `${this.runtimeConfigurationService.hostUrl}/retail/orders`
        processTokenPaymentRequest.declineUrl = `${this.runtimeConfigurationService.hostUrl}/retail/orders`
        processTokenPaymentRequest.redirectUrl = `${this.runtimeConfigurationService.hostUrl}/retail/orders`

        this.startLoad();
        this.directPayOnlinePaymentGatewayManagerService.processTokenPayment(processTokenPaymentRequest).then(data =>{
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProcessTokenPaymentResponse) as ProcessTokenPaymentResponse;
            if (response.paymentAdvice.paymentSuccessful) {
                this.toast((response.paymentAdvice.elaboration as TextualElaboration).message, () => {
                    this.onPaid.emit();
                });
            } else {
                this.errorMessage = (response.paymentAdvice.elaboration as TextualElaboration).message;
            }
            this.stopLoad();
        }).catch((error) => {
            this.errorMessage = error.error;
            this.stopLoad();
        })

    }

    formatDate(date: string): string {
        return moment(date, environment.formatDate).format("MMYY");
    }

    onCard(card: Card): void {
        this.card = card;
    }

    onRemember(remember: boolean): void {
        if (!this.paymentService.paymentProfile || remember) {
            this.createPaymentProfile(this.card);
        } else {
            this.processCardPayment(this.card);
        }
    }

    toast(message, callback): void {

        this.activeToast = this.toastr.success(message, "Payment", {
            toastComponent: ToastComponent,
            timeOut: environment.toast_time_out,
            progressBar: true,
            closeButton: true
        });
        this.activeToast.onHidden.subscribe(() => {
            callback();
        });

    }

    enablePayButton(): boolean {
        return !this.selectedCardToken || !this.enterCvv || this.enterCvv.replace(/_/g, '').length < 3;
    }

}
