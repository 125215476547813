import { Request } from "../../../../../../../urdad/services/models/request.model";
import {ScannedDocument} from '../../../../../models/scanned-document.model';

export class InsertScannedDocumentRequest extends Request {
  constructor(){
    super();
  }

  public scannedDocuments: ScannedDocument[] = [];
}
