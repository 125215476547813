import { RenderedDocument } from "./rendered-document.model";

export class PdfDocument extends RenderedDocument{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.rendering.PdfDocument";
    }

    public encodedPdf:string;
}
