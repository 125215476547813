import {Response} from '../../../../../../../urdad/services/models/response.model';
import { CustomerInvoiceSummary } from '../../../../../models/customer-invoice-summary.model';
export class ProvideCustomerInvoiceSummaryResponse extends Response {

    constructor() {
        super();
    }

    public customerInvoiceSummaries: CustomerInvoiceSummary [] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
}

