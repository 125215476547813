import { OfxEntryLog } from "../../../../../models/ofx-entry-log.model";
import { OfxEntry } from "../../../../../models/ofx-entry.model";
import { Response } from "../../../../../../../urdad/services/models/response.model";

export class ImportOfxResponse extends Response{
    constructor(){ 
        super();
    }

    public ofxEntryLog: OfxEntryLog;
    public ofxEntries: OfxEntry[] = [];
}