import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProductPriceScheduleType } from '../../product-price-schedule/product-price-schedule.component';
import { ProductTypePipe } from './../../../../../shared/pipes/product-type.pipe';
import { DurationType } from '@magnabc/tpi';

@Component({
    selector: 'app-yellow-card-product-price-schedule',
    templateUrl: './yellow-card-product-price-schedule.component.html',
    styleUrls: ['./yellow-card-product-price-schedule.component.scss']
})

export class YellowCardProductPriceScheduleComponent implements OnInit {
    @Input() formGroup: UntypedFormGroup;
    @Input() months = []
    @Input() days = []
    @Input() durationTypes;
    @Input() intervalIDs;
    @Input() formType: ProductPriceScheduleType;

    @Output() onAddInterval = new EventEmitter<any>();
    @Output() onChangeDurationType = new EventEmitter<any>();
    @Output() onRemoveInterval = new EventEmitter<any>();

    invalidMinimumValueAnnual = false;
    invalidMinimumValueNewPolicy = false;
    invalidMinimumValueAdditional = false;
    invalidNewPolicyValueAnnual = false;
    invalidNewPolicyValueAdditional = false;
    invalidAdditionalVehicleToPolicyValueAnnual = false;
    invalidPolicyDuration = false;
    invalidDuplicateDuration = false;
    invalidMinimumValue = false;
    invalidInterval = false;
    invalidExact = false;
    showProRata = false;
    showPercentage = true;
    productTypePipe = ProductTypePipe;

    constructor() { }

    ngOnInit(): void { }

    changeDurationType(event) {
        this.onChangeDurationType.emit(event);
    }

    onRemoveUserIncrementError(event) {
        this.invalidMinimumValueAnnual = false
        this.invalidMinimumValueNewPolicy = false
        this.invalidMinimumValueAdditional = false
        this.invalidNewPolicyValueAnnual = false
        this.invalidNewPolicyValueAdditional = false
        this.invalidAdditionalVehicleToPolicyValueAnnual = false
    }

    addInterval(): void {
        this.onAddInterval.emit();
    }

    removeInterval(data): void {
        this.onRemoveInterval.emit(data);
    }

    get durationTypeInterval(): boolean {
        if (this.formGroup) {
            return this.formGroup.get('durationType').value === DurationType.INTERVAL;
        } else {
            return false;
        }
    }

    get durationTypeProRata(): boolean {
        if (this.formGroup) {
            return this.formGroup.get('durationType').value === DurationType.PRO_RATA;
        } else {
            return false;
        }
    }
}
