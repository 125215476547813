import {VehicleIdentifier} from "../identification/models/vehicle-identifier.model";
import {Engine} from "./engine.model";
import {VehicleRegistration} from "./vehicle-registration.model";
import {VehicleCertificate} from "./vehicle-certificate.model";
import { VehiclePlateNumber } from "../vehicle_api";

export class Vehicle {
  constructor() {
    this['@class'] = "za.co.magnabc.tpi.vehicle.Vehicle";
  }

  public vehicleRegisterNumber: string;
  public vehicleIdentificationNumber: string;
  public manufactureDate: Date;
  public introductionDate: Date;
  public tare: number;
  public grossVehicleMass: number;
  public seatingCapacity: number;
  public standingCapacity: number;
  public colour: string;
  public make: string;
  public model: string;
  public usage: string;
  public vehicleType: string;
  public category: string;
  public propulsion: string;
  public cargo: string;
  public vehicleCertificates: VehicleCertificate[] = [];
  public vehicleRegistrations: VehicleRegistration[] = [];
  public plateNumbers: VehiclePlateNumber[] = [];
  public engine: Engine;
  public vehicleIdentifiers: VehicleIdentifier[] = [];
  public position: number;

}
