import { Request } from '../../../../../../../urdad/services/models/request.model';
import { LegalEntityIdentifier } from "../../../../../../../entity/identification/models/legal-entity-identifier.model";

export class RetrieveBankTransferInformationRequest extends Request{
    constructor(){
        super();
    }

    public orderNumber: string;
    public legalEntityIdentifier: LegalEntityIdentifier;
}
