import { Elaboration } from "./elaboration.model";
import { Token } from "../token/token.model";

export class TokenElaboration extends Elaboration {

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.security.TokenElaboration";
  }

  public accessToken: Token;
  public refreshToken: Token;
}
