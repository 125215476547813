import { Injectable } from "@angular/core";

@Injectable()
export class ClassesToBoundservice
{

  // classesToBeBound = new Object();
  testFucntion(object : any){

    let classes = new Object();

    let obj = new object();
    classes[obj['@class']] = object;

    // classes.push(Axle);
    // classes.push(AxleUnit);
    // classes.push(WimAxleUnitMassPermissible);
    // classes.push(WimMaxMassPermissible);
    // classes.push(WimLengthPermissible);
    // classes.push(WimSteeringOverloadPermissible);
    // classes.push(RequestNotValidException);
    // classes.push(UnexpectedServiceError);
    // classes.push(PermissibleDoesNotExistException);
    // classes.push(FindWimPermissiblesResponse);
    // classes.push(UpdateWimPermissiblesResponse);

    return classes;
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);

    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);
    // classes.push(AxleUnit);

    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);

    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);

    //  classes.push(AxleUnit);

    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);

    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);
    //  classes.push(AxleUnit);

    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);

    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);
    //   classes.push(AxleUnit);

    //   classes.push(AxleUnit);

    // addClassesToBeBound(object);
  }

}


function addClassesToBeBound(clazz) {



}
