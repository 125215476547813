import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";
import {ToastComponent} from "../../../view.components/shared/toast/toast.component";
import {Router} from "@angular/router";
import {NaturalPersonComponent} from "../../../view.components/entity/natural-person/natural-person.component";
import {FormComponent} from "../../../view.components/shared/form/form.component";
import {QueryNaturalPersonComponent} from "../../../view.components/entity/query-natural-person/query-natural-person.component";
import {IStepComponent} from "../../../view.components/shared/guards/step-guard";
import {LocationStrategy} from "@angular/common";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
    AllCountryCriteria,
    EmailAddress,
    NaturalPerson,
    PlainTextUsername,
    ProvideCountryInformationRequest,
    ServiceGroup
} from "@magnabc/tpi";
import {Utils} from "../../../view.components/shared/Utils/Utils";
import {CreateBackOfficeAccountEnum} from './create-back-office-account.enum';
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service';
import {UserAccountManagerService} from '../../../http.services/security/account/user-account-manager/user-account-manager.service';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';
import { UnderwriterInformationProviderService } from '../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import {Title} from '@angular/platform-browser';
import {MatOptionSelectionChange} from '@angular/material/core';
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-create-back-office-account',
  templateUrl: './create-back-office-account-page.component.html',
  styleUrls: ['./create-back-office-account-page.component.scss']
})
export class CreateBackOfficeAccountPageComponent extends FormComponent implements OnInit, IStepComponent {

    utils = Utils;
    @ViewChild(QueryNaturalPersonComponent) entityQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    activeToast: ActiveToast<any>;
    accountExists = false;
    termsNotAccepted = false;
    formGroup: UntypedFormGroup;
    serviceGroupForm: UntypedFormGroup;
    entityFormGroup: UntypedFormGroup;
    terms: UntypedFormControl;
    firstNames: UntypedFormControl;
    surname: UntypedFormControl;
    emailAddress: UntypedFormControl;
    branchCode: UntypedFormControl;

    /* Steps */
    currentState: CreateBackOfficeAccountEnum = CreateBackOfficeAccountEnum.SELECT_SERVICE_GROUP;
    backPressed: boolean;
    steps: any = [];
    breadcrumbStep = 0;

    /* */
    message: string;
    errorMessage = null;
    serviceGroupsToSelectFrom: ServiceGroup[] = [];
    serviceGroupsSelected: ServiceGroup[] = [];
    serviceGroupControl: UntypedFormControl;
    defaultServiceGroups = [];

    /** Natural person users */
    usersToAddList: NaturalPerson [] = [];
    usersAdded: string[] = [];
    failedUsersList: string [] =[];

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                private authenticationService: AuthenticationService,
                private brokerService: UnderwriterInformationProviderService,
                private userAccountManagerService: UserAccountManagerService,
                locationStrategy: LocationStrategy,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Create User - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.steps = [];

        setTimeout(() => {
            this.setSteps();
        });
    }

    ngOnInit(): void {
        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;
        this.startLoad();
        // console.log("------------------------- CreateBackOfficeAccountComponent ",this.authenticationService.getLinkedToLegalEntityIdentifiers())
        this.userAccountManagerService.initialiseCreateAccount(Utils.getTpiIdentifier
            (this.authenticationService.getLinkedToLegalEntityIdentifiers())).then(serviceGroups => {
                if (serviceGroups) {
                    this.serviceGroupsToSelectFrom = [...serviceGroups];
                    this.serviceGroupsToSelectFrom.forEach(serviceGroupItem => {
                        if (serviceGroupItem.defaultGroup) {
                            // Set default service groups to be selected by default.
                            this.defaultServiceGroups.push(serviceGroupItem);
                        }
                    });
                    this.sortDisplayList();
                    this.stopLoad();
                }
        }).catch(err => {
            this.stopLoad();
            RaygunErrorHandler.sendError(err);
            this.toastr.errorToast(err);
            this.router.navigate(['/console/home']);
        })

        this.terms = new UntypedFormControl(false, [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            terms: this.terms
        });

        this.serviceGroupControl = new UntypedFormControl(this.defaultServiceGroups, []);

        this.serviceGroupForm = new UntypedFormGroup({
            serviceGroupControl: this.serviceGroupControl
        });

        this.firstNames = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(50),
            this.noWhitespaceValidator
        ]);

        this.surname = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(50),
            this.noWhitespaceValidator
        ]);

        this.emailAddress = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(254),
            Validators.email,
            Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$"),
            this.noWhitespaceValidator
        ]);

        this.branchCode = new UntypedFormControl('',
            [
                Validators.required, this.noWhitespaceValidator,
                this.noWhitespaceValidator
            ]);

        this.entityFormGroup = new UntypedFormGroup({
            firstNames: this.firstNames,
            surname: this.surname,
            emailAddress: this.emailAddress,
            branchCode: this.branchCode
        });
    }

    onCheckValidation(): void {
        this.termsNotAccepted = false;
        if (this.formGroup.valid && this.terms.value) {
            this.onSubmit(null);
        } else {
            this.termsNotAccepted = true;
        }
    }

    onCheckUserValidation(): void {
        if (this.entityFormGroup.valid) {
            this.onSubmit(null);
        }
    }

    onDeactivate(): boolean {
        if (this.breadcrumbStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    setSteps(): void {

        this.steps = [];
        this.translate.get("NAV.SELECT_SERVICE_GROUP").subscribe((response) => {
            this.steps.push({id: 0, name: response, icon: "fa-address-card", borderColor: "#1592E6"});
        });

        this.translate.get("NAV.CAPTURE_BRANCH_INFORMATION").subscribe((response) => {
            this.steps.push({id: 1, name: response, icon: "fa-university", borderColor: "#318cdf"});
        });

        this.translate.get("NAV.CAPTURE_USER_INFORMATION").subscribe((response) => {
            this.steps.push({id: 2, name: response, icon: "fa-user", borderColor: "#318cdf"});
        });

        this.translate.get("NAV.USER_ACCOUNTS_CREATED").subscribe((response) => {
            this.steps.push({id: 3, name: response, icon: "fa-check", borderColor: "#3171c3"});
        });
    }

    onCurrentStateChange(state:CreateBackOfficeAccountEnum){
        this.currentState = state;
        this.breadcrumbStep++;
    }

    onSubmit(event): void {
        switch (this.currentState) {
            case CreateBackOfficeAccountEnum.SELECT_SERVICE_GROUP: {
                this.submitSelectedServiceGroup();
                break;
            }
            case CreateBackOfficeAccountEnum.CAPTURE_BRANCH_DETAILS: {
                this.branchCode.markAsTouched();
                if(this.branchCode.valid){
                    this.onCurrentStateChange(CreateBackOfficeAccountEnum.CAPTURE_USER_NP_INFO)
                }
                break;
            }
            case CreateBackOfficeAccountEnum.CAPTURE_USER_NP_INFO: {
                this.submitUsersInfo();
                break;
            }
            case CreateBackOfficeAccountEnum.EMAILS_SENT: {
                this.router.navigate(['/console/home']);
                break;
            }
            default: {
                break;
            }
        }
    }

    onBack(event): void {

        if(this.breadcrumbStep > 0){
            this.breadcrumbStep--;
            this.serviceGroupsSelected = [];
        }


        switch (this.currentState) {
            case CreateBackOfficeAccountEnum.SELECT_SERVICE_GROUP: {
                if (confirm('Going back to the console will discard user creation, do you want to proceed?')) {
                    this.router.navigate(['/console/home']);
                }
                break;
            }
            case CreateBackOfficeAccountEnum.CAPTURE_BRANCH_DETAILS: {
                this.onCurrentStateChange(CreateBackOfficeAccountEnum.SELECT_SERVICE_GROUP);
                break;
            }
            case CreateBackOfficeAccountEnum.CAPTURE_USER_NP_INFO: {
                this.onCurrentStateChange(CreateBackOfficeAccountEnum.CAPTURE_BRANCH_DETAILS);
                break;
            }
            case CreateBackOfficeAccountEnum.EMAILS_SENT: {
                this.router.navigate(['/console/home']);
                break;
            }
            default: {
                this.breadcrumbStep++;
                break;
            }
        }
        window.scrollTo(0, 0);
    }

    submitSelectedServiceGroup() {
        if (this.serviceGroupForm.valid) {
            if (this.serviceGroupsSelected.length > 0 || confirm('You have not selected any service groups, do you want to proceed?')) {
                this.userAccountManagerService.clearServiceGroups();

                if(this.serviceGroupsSelected.length === 0 || this.serviceGroupsSelected.find(x => x.name == "CONSOLE LOGIN") === undefined)
                {
                    this.serviceGroupsSelected.push(this.serviceGroupsToSelectFrom.find(x => x.name == "CONSOLE LOGIN"));
                }

                this.serviceGroupsSelected.forEach(serviceGroup => {
                    this.userAccountManagerService.addServiceGroup(serviceGroup);
                })

                this.userAccountManagerService.setOwnedBy(Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers()));
                this.onCurrentStateChange(CreateBackOfficeAccountEnum.CAPTURE_BRANCH_DETAILS)
            }
        } else {
            console.log(this.serviceGroupForm.errors);
        }
    }

    submitUsersInfo() {

        if(this.usersToAddList.length > 0)
        {
            this.userAccountManagerService.setBranch(this.branchCode.value);

            for (const user of this.usersToAddList) {
                this.userAccountManagerService.addNaturalPerson(user);
            }

            this.startLoad();
            this.userAccountManagerService.finaliseCreateAccount().then(response => {
                if (response) {
                    for (const account of response) {
                        this.usersAdded.push((account.naturalPersonIdentifier as PlainTextUsername).username);
                    }

                    for (const user of this.usersToAddList) {

                        if (this.usersAdded.indexOf(user.emailAddresses[0].address) === -1) {
                            this.failedUsersList.push(user.emailAddresses[0].address);
                        }
                    }
                }
                this.onCurrentStateChange(CreateBackOfficeAccountEnum.EMAILS_SENT);
                this.stopLoad();
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
                this.stopLoad();
                console.log('ERROR ', err);
            })
        }
        else
        {
            this.translate.get('TOAST.NO_USER_ADDED').subscribe((res: any) => {
                this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            });
        }
    }

    addUser(){

        let duplicateUser = false;

        if(this.entityFormGroup.valid){

            this.usersToAddList.forEach(value=>{
                if(value.emailAddresses[0].address === this.entityFormGroup.get("emailAddress").value){
                    duplicateUser = true;
                }
            })

            if(!duplicateUser){
                const naturalPerson = new NaturalPerson();
                const emailAddress = new EmailAddress();

                emailAddress.address = this.entityFormGroup.get("emailAddress").value;
                emailAddress.emailAddressType = "PRIMARY";
                naturalPerson.emailAddresses.push(emailAddress);

                naturalPerson.firstNames = this.entityFormGroup.get("firstNames").value;
                naturalPerson.surname = this.entityFormGroup.get("surname").value;
                this.usersToAddList.push(naturalPerson);
                this.entityFormGroup.get("emailAddress").reset();
                this.entityFormGroup.get("surname").reset();
                this.entityFormGroup.get("firstNames").reset();
            }
        }
    }

    removeUser(user: NaturalPerson){

        const filteredUser = this.usersToAddList.filter(userFilter => userFilter.emailAddresses[0].address === user.emailAddresses[0].address);
        const index = this.usersToAddList.indexOf(filteredUser[0]);

        if(index >= 0){
            this.usersToAddList.splice(index, 1);
        }

    }

    clear() {
        this.accountExists = false;
        this.termsNotAccepted = false;
        this.onCurrentStateChange(CreateBackOfficeAccountEnum.SELECT_SERVICE_GROUP);
        this.message = null;
        this.errorMessage = null;
    }

    toast(response) {

        this.translate.get('TOAST.CREATE_RETAIL_PROFILE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.router.navigate(['/retail/profile']);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.router.navigate(['/retail/profile']);
            });
        });
    }

    onServiceGroupSelected($event: MatOptionSelectionChange) {
        const serviceGroup = ($event.source.value as ServiceGroup);

        let exist = false;
        for (const serviceG of this.serviceGroupsSelected) {
            if (serviceG.name === serviceGroup.name) {
                exist = true;
            }
        }

        if (exist) {
            const newServiceGroups = this.serviceGroupsSelected.filter(sq => sq.name !== serviceGroup.name);
            this.serviceGroupsSelected = [...newServiceGroups];
        } else {
            this.serviceGroupsSelected.push(serviceGroup);
        }
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    isCoVendor(): boolean {
        return !!(this.authenticationService.getAgent() || this.brokerService.selectedUnderwriter);
    }
    sortDisplayList() {
        this.serviceGroupsToSelectFrom.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }
}
