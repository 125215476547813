import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MarshallerService, ProvideAgentBrokersProductsRequest} from '@magnabc/tpi'
import {Observable} from 'rxjs'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class AgentBrokersProductsProviderService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  provideAgentBrokersProducts(provideAgentBrokersProductsRequest: ProvideAgentBrokersProductsRequest): Observable<any> {
    const path = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/agentbrokersproductsprovider/provideagentbrokersproducts';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideAgentBrokersProductsRequest);
    return this.httpClient.post(path, jsonRequest, this.options);
  }
}
