import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {StringUtils} from "../../../common/utils/utils.string";

declare const jQuery: any;
@Component({
    templateUrl: './vehicle-trim.component.html',
    styleUrls: ['./vehicle-trim.component.scss']
})
export class VehicleTrimComponent implements OnInit {

    rows = [
        { id: 'lx', name: 'LX', make: 'unknown', model: 'unknown', edit: '' },
        { id: 'ds', name: 'DS', make: 'unknown', model: 'unknown', edit: '' },
        { id: 'ls', name: 'LS', make: 'unknown', model: 'unknown', edit: '' },
        { id: 'ex', name: 'EX', make: 'unknown', model: 'unknown', edit: '' },
    ];
    columns = [
        { prop: 'name' },
        { name: 'model' },
        { name: 'make' },
        { name: 'edit' },
    ];
    temp = [];

    make: string;
    model: string;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService, private router: Router, private route: ActivatedRoute) {
        this.temp = this.rows;
    }

    ngOnInit(): void {
        this.onToggleSearch();

        //noinspection JSIgnoredPromiseFromCall
        this.route.queryParams.forEach((params: Params) => {

            this.make = params['make'];
            this.model = params['model'];
            if (this.make && this.model) {

                for (const r of this.rows) {
                    r.name = StringUtils.applySentenceCase(this.model) + " " + r.name;
                    r.make = StringUtils.applySentenceCase(this.make);
                    r.model = StringUtils.applySentenceCase(this.model);
                }
                this.temp = this.rows;

            }

        });

    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter(function(d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    onSelect(event): void {
        this.router.navigate(['/makemodeltrim/' + event.selected[0].id], {queryParams: {type: 'trim'}}).then();
    }

    onEdit(event, id): boolean {
        event.stopPropagation();
        this.router.navigate(['/makemodeltrim/' + id], {queryParams: {type: 'trim'}}).then();
        return false;
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/makemodeltrim/create'], {queryParams: {type: 'trim'}}).then();
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

}
