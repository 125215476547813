import { Response } from "../../../../../../urdad/services/models/response.model";
import { LegalEntity } from "../../../../../models/legal-entity.model";

export class ProvideLegalEntityInformationResponse extends Response {

  constructor(){
    super();
  }

  public legalEntities: LegalEntity[] = [];
}
