import {Request} from '../../../../../../urdad/services/models/request.model';
import {CountryIdentifier} from '../../../../../../location/geographic/identification/models/country-identifier.model';
import { LegalEntityIdentifier } from '../../../../../identification/models/legal-entity-identifier.model';

export class ProvideSecuredLegalEntityInformationRequest extends Request {
    constructor(){
        super();
    }

    public countryIdentifier: CountryIdentifier;
    public legalEntityIdentifier: LegalEntityIdentifier;
} 