import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../../shared/form/form.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";
import {ToastComponent} from "../../../shared/toast/toast.component";
import {ActiveToast, ToastrService} from "ngx-toastr"
import {QueryNaturalPersonComponent} from "../../../entity/query-natural-person/query-natural-person.component";
import {ServiceGroupInformationProviderService} from "../../../../http.services/security/authorisation/service-group-information-provider/service-group-information-provider.service";
import {Utils} from "../../../shared/Utils/Utils";
import {
    Subscription,
    LegalEntity,
    NaturalPerson,
    ServiceGroup,
    ProvideServiceGroupsRequest,
    TpiIdentifier,
    UnMarshallerService,
    ProvideServiceGroupsResponse, CreateSubscriptionRequest, CreateSubscriptionResponse
} from '@magnabc/tpi';
import {SubscriptionComponentService} from './services/subscription-component.service';
import { SubscriptionManagerService } from '../../../../http.services/security/authorisation/subscription-manager/subscription-manager.service';
import { RaygunErrorHandler } from '../../../../common/utils/utils.raygun';
import {ErrorToastService} from "../../../../app.services/common/error-toast/error-toast.service";
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import {
    LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss'],
    providers: [
        {
          provide: MAT_SELECT_CONFIG,
          useValue: { overlayPanelClass: 'tpi-overlay-custom' }
        }
    ]})
export class SubscriptionComponent extends FormComponent implements OnInit {

    @Input() subscription: Subscription;
    @Input() owner: TpiIdentifier;
    @Output() onSubscription = new EventEmitter<Subscription>();

    selectedServiceGroup: ServiceGroup[] = [];
    serviceGroupList: ServiceGroup[] = [];

    usedBy: LegalEntity[] = [];

    formGroup: UntypedFormGroup;
    serviceGroup: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translateService: TranslateService,
                private translateParser: TranslateParser,
                private serviceGroupInformationProviderService: ServiceGroupInformationProviderService,
                private subscriptionManagerService: SubscriptionManagerService,
                private subscriptionComponentService: SubscriptionComponentService,
                private unMarshallerService: UnMarshallerService,
                private dialog: MatDialog,
                private errorToastService: ErrorToastService ) {
        super(translateService, translateParser);
    }

    ngOnInit() {

        this.usedBy = [];

        this.serviceGroup = new UntypedFormControl(null, [
            Validators.required,
        ]);

        this.formGroup = new UntypedFormGroup({
            'serviceGroup': this.serviceGroup,
        });

        const provideRequest = new ProvideServiceGroupsRequest();
        provideRequest.criteria = this.subscriptionComponentService.getServiceGroupTypeCriteria();
        this.serviceGroupInformationProviderService.provideServiceGroups(provideRequest).then((httpResponse) => {
            this.serviceGroupList = [];

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideServiceGroupsResponse) as ProvideServiceGroupsResponse);
                for (const serviceGroup of response.serviceGroups) {
                    this.serviceGroupList.push(serviceGroup);
                    if (this.subscription && this.subscription.serviceGroup && this.subscription.serviceGroup.name === serviceGroup.name) {
                        this.selectedServiceGroup.push(serviceGroup);
                    }
                }

                this.sortDisplayList();
                this.initSubscription();
            }
        });

    }

    checkTypeAllowed(serviceGroup: ServiceGroup): boolean {

        if (serviceGroup.type === 'public') {
            return true;
        }

        if (serviceGroup.type === 'retail') {

            // FIXME: REDO CHECKS FOR RETAIL AND ADMIN
            // for (const service of ServiceList.retailServices) {
            //     if (this.authenticationService.services[service]) {
            //         return true;
            //     }
            // }

        }

        if (serviceGroup.type === 'admin') {

            // for (const service of ServiceList.adminServices) {
            //     if (this.authenticationService.services[service]) {
            //         return true;
            //     }
            // }

        }

        return false;

    }

    onServiceGroupSelected($event: MatOptionSelectionChange) {
        const serviceGroup = ($event.source.value as ServiceGroup);

        let exist = false;
        for (const serviceG of this.selectedServiceGroup) {
            if (serviceG.name === serviceGroup.name) {
                exist = true;
            }
        }

        if (exist) {
            const newServiceGroups = this.selectedServiceGroup.filter(sq => sq.name !== serviceGroup.name);
            this.selectedServiceGroup = [...newServiceGroups];
        } else {
            this.selectedServiceGroup.push(serviceGroup);
        }
    }

    removeUser(index: number): void {
        this.usedBy.splice(index, 1);
    }

    submit(): void {
        document.getElementById('form-submit-subscription').click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid && this.usedBy.length > 0 && this.serviceGroup.value) {

            this.subscription.serviceGroup = this.serviceGroup.value;
            // this.selectedServiceGroup.push()
            const usedByList = [];
            for (const user of this.usedBy) {
                for(const legalEntityIds of user.legalEntityIdentifiers)
                {
                    if(legalEntityIds instanceof TpiIdentifier)
                    {
                        usedByList.push(Utils.getTpiIdentifier(user.legalEntityIdentifiers));
                    }
                }
            }


            const createSubscriptionRequest = new CreateSubscriptionRequest();
            this.selectedServiceGroup.forEach( serviceGroup => {
                createSubscriptionRequest.type = "INTERNAL_PUBLIC";
                createSubscriptionRequest.owner = this.owner;
                createSubscriptionRequest.user = usedByList[0];
                createSubscriptionRequest.serviceGroups.push(serviceGroup);
            })

            this.startLoad();
            this.subscriptionManagerService.createSubscription(createSubscriptionRequest).then((httpResponse) => {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateSubscriptionResponse) as CreateSubscriptionResponse);
                if (response.subscriptions.length > 0)
                {
                    this.onSubscription.emit(response.subscriptions[0]);
                }
                this.stopLoad();
            }).catch(err => {
                console.error('Error while creating subscriptions :: ', err);
                RaygunErrorHandler.sendError(err);

                if (err.error === "The Subscriptions has already been persisted")
                {
                    err.error = "The Subscriptions has already been created";
                }

                this.errorToastService.errorToast(err);

                this.stopLoad();
            });
        }

        if (this.usedBy.length === 0) {
            this.errorToastService.success("No Users have been assigned. Please assign at least one.", "No Users")
        }

        if (!this.serviceGroup.value) {
            this.errorToastService.success("No Service Group has been selected.", "Service Group")
        }

    }

    initSubscription() {
        if (this.selectedServiceGroup) {
            this.serviceGroup.setValue(this.selectedServiceGroup);
        } else if (this.serviceGroupList.length > 0) {
            // this.serviceGroup.setValue(this.serviceGroupList[0]);
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogQueryUserComponent, {
            width: '80%',
            panelClass: 'padded-modal',
            position: {top: "5%"},
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.legalEntityIdentifiers.length > 0) {
                this.usedBy.push(result);
            }
        });
    }

    getDisplayName(name): string {
        return this.titleCase(name.replace(/_/g, ' '));
    }

    titleCase(str): string {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    sortDisplayList() {
        this.serviceGroupList.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    /**
     * generate an element id from a name
     * @param name
     * @returns {string} the element id
     */
    generateElementId(name: string): string {
        return name.toLowerCase().replace(/ /g, '-');
    }
}

@Component({
    selector: 'app-dialog-query-user',
    templateUrl: 'dialog-query-user.html',
})
export class DialogQueryUserComponent {

    notFound: boolean;
    notAllowed: boolean;
    legalEntity: LegalEntity;

    @ViewChild(QueryNaturalPersonComponent, { static: true }) entityQueryComponent: QueryNaturalPersonComponent;

    constructor(public dialogRef: MatDialogRef<DialogQueryUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private entityService: LegalEntityFinalisationManagerService) {
        this.legalEntity = new NaturalPerson();
        this.notFound = false;
    }

    close(): void {
        if (this.notFound) {
            this.dialogRef.close();
        } else {
            this.dialogRef.close(this.legalEntity);
        }
    }

    onQuery(): void {
        this.entityQueryComponent.submit();
    }

    onLegalEntity(legalEntity: LegalEntity): void {
        this.legalEntity = legalEntity;
    }

    onExists(exists): void {
        this.notFound = false;
        this.notAllowed = false;
        if (exists) {
            this.dialogRef.close(this.legalEntity);
        } else {
            this.notFound = true;
        }
    }

}
