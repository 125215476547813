import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";
export class InvoiceDateLegalEntityCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.invoice.InvoiceDateLegalEntityCriteria";
    }

    public legalEntityIdentifier: LegalEntityIdentifier;
    public dateFrom : Date;
    public dateTo : Date;
}
