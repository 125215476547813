import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import {MarshallerService, ProvideServiceGroupsRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ServiceGroupInformationProviderService {

    basePath = '/security/authorisation/servicegroupinformationprovider';

    constructor(private httpClient: HttpClient,
                private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideServiceGroups(provideServiceGroupsRequest: ProvideServiceGroupsRequest){
        const provideServiceGroupsUri = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideservicegroup';
        const jsonProvideServiceGroupsRequest = this.marshallerService.marshallObjectToJSON(provideServiceGroupsRequest);
        return this.httpClient.post<any>(provideServiceGroupsUri, jsonProvideServiceGroupsRequest, this.options).toPromise();
    }
}
