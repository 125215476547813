import {AbstractControl, ValidatorFn} from "@angular/forms";
import {IDropdown} from "../model/dropdown.model";
import {environment} from "../../../environments/environment";

export function autoCompleteValidator(list: IDropdown[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (!control.value) {
            return null;
        }
        for (const item of list) {
            if (item.name === control.value.name || item.name === control.value) {
                return null;
            }
        }
        return {'autoComplete': {value: control.value}};
    };
}

export function manufactureDateValidator(): ValidatorFn {

    return (control: AbstractControl): {[key: string]: any} => {
        if (control.value && parseInt(control.value.format(environment.formatDateYear)) < 1900) {
            return {'manufactureDate': {value: control.value}};
        }
        else if (control.value && parseInt(control.value.format(environment.formatDateYear)) > new Date().getFullYear()) {
            return {'manufactureFutureDate': {value: control.value}};
        }
        return null;
    };
}

export function forbiddenName(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? {'forbiddenName': {value: control.value}} : null;
    };
}
