import { CategoryIdentifier } from "../../../../identification/models/category-identifier.model";
import { Response } from "../../../../../urdad/services/models/response.model";

export class ProvideCategoryIdentifiersResponse extends Response {

    constructor() {
        super();
    }

    public categoryIdentifiers: CategoryIdentifier[] = [];

}

