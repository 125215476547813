import { Request } from '../../../../../../../urdad/services/models/request.model';
import { Broker } from '../../../../../insurance_api';
import { InsuranceType } from '../../../../../setup/setup_api';
export class InitialiseInsuranceProductRequest extends Request {
    constructor() {
        super();
    }

    public broker: Broker;
    public insuranceType: InsuranceType;
}
