import { NaturalPersonIdentifier } from "../../models/natural-person-identifier.model";

export class PlainTextUsername extends NaturalPersonIdentifier
{
  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.identification.naturalperson.PlainTextUsername";
  }
    public username: string;

}
