/*
 * Public API Surface of vehicle
*/

export * from './identification/models/all-category-identifier-criteria.model';
export * from './identification/models/category-description-identifier.model';
export * from './identification/models/category-identifier.model';
export * from './identification/models/engine-number.model';
export * from './identification/models/make-description-identifier.model';
export * from './identification/models/make-identifier.model';
export * from './identification/models/model-description-identifier.model';
export * from './identification/models/model-identifier.model';
export * from './identification/models/plate-number.model';
export * from './identification/models/tpi-vehicle-identifier.model';
export * from './identification/models/usage-description-identifier.model';
export * from './identification/models/usage-identifier.model';
export * from './identification/models/usages-criteria.model';
export * from './identification/models/vehicle-description-identifier.model';
export * from './identification/models/vehicle-identification-number.model';
export * from './identification/models/vehicle-identifier.model';
export * from './identification/models/vehicle-register-number.model';
export * from './identification/models/propulsion-description-identifier.model';
export * from './identification/models/propulsion-identifier.model';
export * from './makemodeltrim/makemodeltrim_api';
export * from './models/category.model';
export * from './models/certificate-of-fitness.model';
export * from './models/engine.model';
export { PlateNumber as VehiclePlateNumber } from './models/plate-number.model';
export * from './models/propulsion.model';
export * from './models/road-service-permit.model';
export * from './models/usage.model';
export * from './models/vehicle-certificate.model';
export * from './models/vehicle-criteria.model';
export * from './models/vehicle-provider-criteria.model';
export * from './models/vehicle-registration.model';
export * from './models/vehicle-type.model';
export * from './models/vehicle.model';
export * from './models/fuel-type.model';
export * from './models/transmission.model';

export * from './services/categoryidentifierprovider/provide-category-identifiers-request.model';
export * from './services/categoryidentifierprovider/request/models/provide-category-identifiers-response.model';
export * from './services/queryvehicleinformationmanager/request/models/query-vehicle-information-request.model';
export * from './services/queryvehicleinformationmanager/response/models/query-vehicle-information-response.model';
export * from './services/usageidentifierprovider/provide-usage-identifiers-request.model';
export * from './services/usageidentifierprovider/responses/models/provide-usage-identifiers-response.model';
export * from './services/vehicleinformationfinalisationmanager/finalisemaintainvehicleinformation/request/models/finalise-maintain-vehicle-information-request.model';
export * from './services/vehicleinformationfinalisationmanager/finalisemaintainvehicleinformation/response/models/finalise-maintain-vehicle-information-response.model';
export * from './services/vehicleinformationprovider/providevehicleinformation/request/models/provide-vehicle-information-request.model';
export * from './services/vehicleinformationprovider/providevehicleinformation/response/models/provide-vehicle-information-response.model';
