import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Criteria} from '../../../../../../../criteria/models/criteria.model';
import {UnderwriterIdentifier} from '../../../../../identification/models/underwriter-identifier.model';

export class ProvideInsurancePolicyItemRequest extends Request{

    constructor(){
        super();
    }
    public batchSize: number
    public batchNumber: number
    public criteria: Criteria
    public underwriterIdentifier: UnderwriterIdentifier
}
