import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { SubscriptionInformationProviderService } from "../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service";
import { Utils } from "../../shared/Utils/Utils";
import {
    ProvideSubscriptionsRequest,
    Subscription,
    SubscriptionOwnedByLegalEntityCriteria,
    SubscriptionSummary,
    ProvideSubscriptionsSummaryBackofficeRequest,
    ResendVerificationLinkRequest,
    ProvideAccountInformationRequest,
    AccountLegalEntityIdentifierCriteria,
    ServiceGroup
} from '@magnabc/tpi';
import { AuthenticationService } from "../../../app.services/managers/authentication/authentication.service";
import { UserSummary } from "../../../common/model/user-summary.model";
import { MatDialog } from "@angular/material/dialog";
import { CustomDialogComponent } from "../../shared/dialogs/custom-dialog/custom-dialog.component";
import { UserUpdateComponent } from "../user-update/user-update.component";
import { AccountVerificationLinkResenderService } from '../../../http.services/security/account/account-verification-link-resender/account-verification-link-resender.service';
import { TpiIdentifier } from '@magnabc/tpi';
import { ErrorToastService } from '../../../app.services/common/error-toast/error-toast.service';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ToastComponent } from '../../shared/toast/toast.component';
import { environment } from '../../../../environments/environment';
import { ServiceGroupData, UserData } from '../user-update/user-data.model'
import { BackOfficeAccountInformationProvider } from '../../../http.services/security/account/back-office-account-information-provider/back-office-account-information-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export let userDataList: UserData[] = [];

export interface UserSummaryList {
    id: number;
    name: string;
    surname: string;
    email: string;
    status: string;
    tpiIdentifier: string;
    serviceGroup: ServiceGroup[];
    verified?: Boolean;
}

declare const jQuery: any;
@Component({
    selector: 'app-manage-users-list',
    templateUrl: './manage-users-list.component.html',
    styleUrls: ['./manage-users-list.component.scss']
})
export class ManageUsersListComponent implements OnInit, AfterContentInit {

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    activeToast: ActiveToast<any>;

    subscriptionSummaries: SubscriptionSummary[] = [];
    subscriptions: Subscription[] = [];
    userSummaries: UserSummaryList[] = [];

    private userSubscriptionList: Subscription[] = [];
    private accountEntityIdentifiers: string[] = [];

    $users = new BehaviorSubject<UserSummaryList[]>([]);

    constructor(
        public authenticationService: AuthenticationService,
        private subscriptionInformationProviderService: SubscriptionInformationProviderService,
        private backOfficeAccountInformationProvider: BackOfficeAccountInformationProvider,
        private dialog: MatDialog,
        public addServiceGroupDialog: MatDialog,
        private accountVerificationLinkResenderService: AccountVerificationLinkResenderService,
        private errorToastService: ErrorToastService,
        private translate: TranslateService,
        private toastr: ErrorToastService,
        private translateParser: TranslateParser,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.getSubscriptions();
        this.reloadState();

    }

    ngAfterContentInit(): void {
    }

    private reloadState(): void {
        const id = this.activatedRoute.snapshot.queryParams.id;
        if (id) {
           const subscription = this.$users.asObservable().subscribe((data) => {
                const row = data.find((row) => row.tpiIdentifier === id);
                if (row) {
                    this.manageUser(row);
                    if(subscription){
                        subscription.unsubscribe();
                    }
                }
            });
        }
    }

    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
    }

    onDetailToggle(event) {

    }

    resendVerificationLink(userSummary) {

        const tpiIdentifier = new TpiIdentifier()
        tpiIdentifier.tpiIdentifier = userSummary.tpiIdentifier;

        const resendVerificationLinkRequest = new ResendVerificationLinkRequest()
        resendVerificationLinkRequest.legalEntityIdentifier = tpiIdentifier;
        this.accountVerificationLinkResenderService.resendVerificationLink(resendVerificationLinkRequest).then(httpResponse => {

            this.translate.get('TOAST.VERIFICATION_LINK').subscribe((res: any) => {
                this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: userSummary.email }), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            });
            this.userSummaries.map(summary => {
                if (summary.email === userSummary.email) {
                    summary.verified = false;
                }
                return summary;
            });

        }).catch(error => {
            this.errorToastService.errorToast(error);
        });
    }

    // deactivateUser(row) {
    //     let email = row.email;
    //     if (row.status === 'ACTIVE') {
    //         this.dialog.open(VerifyDisableUserDialogComponent, {
    //             width: '400px',
    //             data: {
    //                 email
    //             },
    //             disableClose: true
    //         }).afterClosed().subscribe(result => {
    //             if (result && result === 'disable') {
    //                 this.manageUsersService.changeUserAccountStatus(row.email, row.active)
    //                     .then(() => {
    //                         setTimeout(() => {
    //                             this.updateAuthorityUsers();
    //                         }, 500);
    //                     }).catch(() => {
    //                     this.showDialog(true, 'Error', 'An error occurred while deactivating the user');
    //                 });
    //             }
    //         });
    //     } else {
    //         console.log('no');
    //     }
    // }

    showDialog(error: boolean, title: string, message: string) {
        return this.dialog.open(CustomDialogComponent, {
            width: '400px',
            panelClass: 'padded-modal',
            disableClose: true,
            data: { error, title, message }
        });
    }

    getSubscriptionSummaryBackoffice() {
        const ownedByIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
        const criteria = new SubscriptionOwnedByLegalEntityCriteria();
        criteria.legalEntityIdentifier = ownedByIdentifier;

        const provideSubscriptionsSummaryBackofficeRequest = new ProvideSubscriptionsSummaryBackofficeRequest();
        provideSubscriptionsSummaryBackofficeRequest.criteria = criteria;
        provideSubscriptionsSummaryBackofficeRequest.batchNumber = 1;
        provideSubscriptionsSummaryBackofficeRequest.batchSize = 100;

        this.subscriptionInformationProviderService.provideSubscriptionsSummaryBackOffice(provideSubscriptionsSummaryBackofficeRequest)
            .then(response => {

                if (response && response.body) {
                    const legalEntityCriteria = new AccountLegalEntityIdentifierCriteria();

                    response.body.subscriptionSummaries.forEach((subscriptionSummary, index) => {
                        let userSummary = new UserSummary;
                        userSummary.id = index;
                        userSummary.name = subscriptionSummary.name;
                        userSummary.surname = subscriptionSummary.surname;
                        userSummary.email = subscriptionSummary.email;

                        // this will be hard coded for now, need to rethink the deactivate functionality
                        userSummary.status = 'ACTIVE';
                        userSummary.tpiIdentifier = subscriptionSummary.tpiIdentifier.tpiIdentifier;
                        legalEntityCriteria.legalEntityIdentifiers.push(subscriptionSummary.tpiIdentifier);

                        this.subscriptions.forEach(subscription => {
                            if (subscriptionSummary.tpiIdentifier.tpiIdentifier === subscription.usedBy.tpiIdentifier) {
                                userSummary.serviceGroup.push(subscription.serviceGroup);
                            }
                        });

                        this.userSummaries.push(userSummary);
                        this.userSummaries = [...this.userSummaries];
                        this.userSummaries.forEach(userSummary => {
                            this.sortDisplayList(userSummary.serviceGroup)
                        })
                    });
                    this.$users.next(this.userSummaries);

                    const provideAccountInformationRequest = new ProvideAccountInformationRequest();
                    provideAccountInformationRequest.criteria = legalEntityCriteria;

                    this.backOfficeAccountInformationProvider.provideAccountInformation(provideAccountInformationRequest).then(res => {

                        if (res && res.body) {
                            const accounts = res.body.accounts;
                            this.userSummaries.map(summary => {
                                let match = accounts.find(account => summary.email === account.naturalPersonIdentifier.username);
                                if (match) {
                                    summary.verified = match.verified;
                                }
                                return summary;
                            });
                        }
                    });
                }
            });
    }

    getSubscriptions() {
        const ownedByIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

        const criteria = new SubscriptionOwnedByLegalEntityCriteria();
        criteria.legalEntityIdentifier = ownedByIdentifier;

        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = criteria;

        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest)
            .then(response => {

                this.subscriptions = [];
                response.body.subscriptions.forEach(subscription => {
                    if (subscription.usedBy != null) {
                        this.subscriptions.push(subscription);
                    }
                });

                this.getSubscriptionSummaryBackoffice();
            });
    }

    manageUser(row) {
        const user: UserData = {
            userName: row.name,
            userSurname: row.surname,
            userEmail: row.email,
            status: row.status,
            usedBy: row.tpiIdentifier,
            ownedBy: this.subscriptions[0].ownedBy.tpiIdentifier,
            serviceGroupDataList: [],
            subscriptions: []
        };

        row.serviceGroup.forEach((serviceGroup, index) => {
            const serviceGroupData: ServiceGroupData = {
                defaultGroup: serviceGroup.defaultGroup,
                serviceGroupName: serviceGroup.name
            };
            user.serviceGroupDataList.push(serviceGroupData);
        });

        this.addServiceGroupDialog.open(UserUpdateComponent,
            { data: user }).afterClosed()
            .subscribe(() => {
                this.userSummaries = [];
                this.$users.next(this.userSummaries);
                this.getSubscriptions();
                this.router.navigate(['/manage/users']);
            });

        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: {
                    id: row.tpiIdentifier
                }
            });
    }

    sortDisplayList(userSummaryServiceGroup) {
        userSummaryServiceGroup.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    getMangeUserLink(row): string {
        return `/manage/users/${row.tpiIdentifier}`;
    }
}
