import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, YCInsuranceProduct, Vehicle, RetailVehicle, YCInsurancePolicyCartItem } from '@magnabc/tpi';

import {
    InsuranceProductService,
} from '../../../../../../app.services/managers/insurance-product/insurance-product.service';
import { CountryService } from '../../../../../shared/country/services/country.service';
import { RetailProfileFinalisationManagerService } from '../../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';

@Component({
    selector: 'app-yellow-card-price-schedule-selector',
    templateUrl: './yellow-card-price-schedule-selector.component.html',
    styleUrls: ['./yellow-card-price-schedule-selector.component.scss']
})
export class YellowCardPriceScheduleSelectorComponent implements OnInit {
    @Input() product: YCInsuranceProduct;

    @Output() pricingSelected = new EventEmitter<boolean>();

    coveredCountries: Country[] = [];
    selectedCountries: string[] = [];

    constructor(
        private countryService: CountryService,
        public insuranceProductService: InsuranceProductService,
        private retailProfileFinalisationManagerService: RetailProfileFinalisationManagerService) { }

    ngOnInit(): void {
        if (this.product instanceof YCInsuranceProduct) {
            if(!this.insuranceProductService.item){
                this.insuranceProductService.setDateForYCProduct(this.product);
            }
            this.getCoveredCountries();
        }
        if (this.insuranceProductService.selectedYCPriceSchedules) {
            const schedules = this.insuranceProductService.selectedYCPriceSchedules;
            this.selectedCountries = [... new Set(schedules.map(item => item.country))];
        }
    }

    onSelectCountry(countries: Country[]): void {
        if (this.insuranceProductService.selectedVehicles.length > 0) {
            const vehicleCategoryDescriptions = this.getVehicleCategoryDescriptions();
            const countryNames = countries.map(item => item.description);
            if (this.product) {
                const selectedPriceSchedules = this.product.ycPriceSchedules.filter((schedule) =>
                    vehicleCategoryDescriptions.includes(schedule.insuranceVehicleCategorySubDescription) &&
                    countryNames.includes(schedule.country));
                //TODO calculation implementation
                if (selectedPriceSchedules.length > 0) {
                    this.insuranceProductService.selectedYCPriceSchedules = selectedPriceSchedules;
                    this.insuranceProductService.ycPriceIntervals = this.product.ycPriceIntervals;
                    this.insuranceProductService.calculateRealTimeYCPrice();
                    this.pricingSelected.emit(true);
                } else {
                    this.pricingSelected.emit(false);
                }
            }
        }
    }

    private getCoveredCountries() {
        let uniqueCountries = new Set(this.product.ycPriceSchedules.map(item => item.country));
        const vehicleCategoryDescriptions = this.getVehicleCategoryDescriptions();
        const selectedPriceSchedules = this.product.ycPriceSchedules.filter(
            (schedule) =>
                vehicleCategoryDescriptions.includes(schedule.insuranceVehicleCategorySubDescription) &&
                uniqueCountries.has(schedule.country) && schedule.price !== 0);
        uniqueCountries = new Set(selectedPriceSchedules.map(item => item.country));
        this.coveredCountries = this.countryService.getCountries()
            .filter(item => uniqueCountries.has(item.description))
            .sort((a, b) => a.description.localeCompare(b.description));
    }

    private getVehicleCategoryDescriptions() {
        const filteredDescriptions = this.insuranceProductService.selectedVehicles.map(vehicle => this.getVehicleCategories(vehicle)).flat();
        return [...new Set(filteredDescriptions)];
    }

    private getVehicleCategories(vehicle: RetailVehicle): string[] {
        if (vehicle.insuranceVehicleCategories) {
            return vehicle.insuranceVehicleCategories.map(category => `${category.description} - ${category.subDescription}`)
        } else {
            const result = [];
            const items = this.retailProfileFinalisationManagerService.retailProfile.cart.items;
            if (items) {
                items.forEach((item) => {
                    if ((item as YCInsurancePolicyCartItem).vehicle) {
                        const testItem = (item as YCInsurancePolicyCartItem);
                        const testVehicle = testItem.vehicle;
                        if (testVehicle.vehicleIdentificationNumber === vehicle.vehicleIdentificationNumber) {
                            result.push(`${testItem.ycInsuranceProductCartItem.insuranceVehicleCategoryDescription} - ${testItem.ycInsuranceProductCartItem.insuranceVehicleCategorySubDescription}`);
                        }
                    }
                });
            }
            return result;
        }
    }

}
