import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable, Subject} from 'rxjs'
import {ScheduledMaintenance, UnMarshallerService, UpcomingMaintenanceResponse} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    private REFRESH_RATE_IN_MILLISECONDS = 15000;
    private upcoming: Subject<ScheduledMaintenance[]> = new Subject<ScheduledMaintenance[]>();
    private active: Subject<ScheduledMaintenance[]> = new Subject<ScheduledMaintenance[]>();
    upcomingMaintenanceList: ScheduledMaintenance[];
    activeMaintenanceList: ScheduledMaintenance[];

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private http: HttpClient, private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        if (runtimeConfigurationService.statusEnabled) {
            this.checkMaintenance();
            setInterval(() => {
                this.checkMaintenance();
            }, this.REFRESH_RATE_IN_MILLISECONDS);
        } else {
            this.upcomingMaintenanceList = [];
            this.activeMaintenanceList = [];
        }
    }

    private checkMaintenance(): void {

        this.upcomingMaintenances().subscribe((data) => {

            const response = this.unMarshallerService.unMarshallFromJson(data.body, UpcomingMaintenanceResponse) as UpcomingMaintenanceResponse;
            this.upcomingMaintenanceList = response.scheduled_maintenances;
            this.notifyUpcoming();

        });

        this.activeMaintenances().subscribe((data) => {

            const response = this.unMarshallerService.unMarshallFromJson(data.body, UpcomingMaintenanceResponse) as UpcomingMaintenanceResponse;
            this.activeMaintenanceList = response.scheduled_maintenances;
            this.notifyActive();

        });

    }

    private upcomingMaintenances(): Observable<any> {
        const path = this.runtimeConfigurationService.statusBaseUrl + '/scheduled-maintenances/upcoming.json';
        return this.http.get(path, this.options);
    }

    private activeMaintenances(): Observable<any> {
        const path = this.runtimeConfigurationService.statusBaseUrl + '/scheduled-maintenances/active.json';
        return this.http.get(path, this.options);
    }

    subscribeUpcomingMaintenances(): Observable<ScheduledMaintenance[]> {
        return this.upcoming.asObservable();
    }

    subscribeActiveMaintenances(): Observable<ScheduledMaintenance[]> {
        return this.active.asObservable();
    }

    notifyUpcoming(): void {
        if (this.upcomingMaintenanceList) {
            this.upcoming.next(this.upcomingMaintenanceList);
        }
    }

    notifyActive(): void {
        if (this.activeMaintenanceList) {
            this.active.next(this.activeMaintenanceList);
        }
    }

}
