import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarshallerService, ReceivePayfastPaymentRequest } from '@magnabc/tpi';
import { RuntimeConfigurationService } from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class ReivePayfastPaymentService {

    basePath = '/retail/payment/payfast/payfastpaymentfinalisationmanager';
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };


    constructor(
        private http: HttpClient,
        private runtimeConfigurationService: RuntimeConfigurationService,
        private marshallerService: MarshallerService
    ) { }

    receivePayfastPayment(receivePayfastPaymentRequest: ReceivePayfastPaymentRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisepayfastpayment';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(receivePayfastPaymentRequest);
        return this.http.post(path, jsonRequest, this.options).toPromise();
    }


}
