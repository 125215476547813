import {Request} from '../../../../../../../../urdad/services/models/request.model';
import { CardPaymentOption } from '../../../../../models/card-payment-option.model';
import {Money} from "../../../../../../../../financial/models/money.model";
import {RetailProfileIdentifier} from "../../../../../../../profile/identification/retail-profile-identifier.model";
import {PaymentReference} from "../../../../../../../../financial/models/payment-reference.model";

export class ProcessTokenPaymentRequest extends Request{
    constructor(){
        super();
    }

    public amount: Money;
    public cardVerificationValue: string;
    public redirectUrl: string;
    public backUrl: string;
    public declineUrl: string;
    public retailProfileIdentifier: RetailProfileIdentifier;
    public paymentReference: PaymentReference;
    public cardPaymentOption: CardPaymentOption;
}
