import {Money} from "./money.model";
import {PaymentReference} from "./payment-reference.model";
import {PaymentProviderIdentifier} from "../identification/models/payment-provider-identifier.model";
import {PaymentPlan} from "../../retail/payment/models/payment-plan.model";

export class Payment{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.financial.Payment";
    }

	public amount: Money;
	public depositDate: Date;
	public notificationDate: Date;
	public cashier: string;
	public paymentProvider: PaymentProviderIdentifier;
	public paymentReferences: PaymentReference [] = [];
	public paymentPlan: PaymentPlan;
}
