import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs/internal/Observable";
import {InitialiseInsuranceProductRequest, MarshallerService} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsuranceProductInitialisationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseInsuranceProduct(initialiseInsuranceProductRequest: InitialiseInsuranceProductRequest): Observable<any> {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/insuranceproductinitialisationmanager/initialiseinsuranceproduct';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseInsuranceProductRequest);
        return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
    }

}
