import {ProposalForm} from "../../../../../../../../retail/insurance/comprehensiveinsurance/models/proposal-form.model"
import {Response} from "../../../../../../../../urdad/services/models/response.model"
import {
  CountryRegistrationConfiguration
} from "../../../../../../../../retail/insurance/setup/models/country-registration-configuration.model"
import {
  CountryRegistrationConfigurationInformation
} from '../../../../../../../../retail/insurance/setup/models/country-registration-configuration-information.model'
import {PickUpPoint} from "../../../../../../../../retail/retail_api"
import {LegalEntityDetails} from "../../../../../../../../entity/models/legal-entity-details.model"

export class InitialiseCountryCheckoutResponse extends Response {

  constructor() {
    super();
  }

  public naturalPerson: boolean;
  public judicialPerson: boolean;
  public proposalForm: ProposalForm;
  public countryRegistrationConfigurations: CountryRegistrationConfiguration[] = [];
  public countryRegistrationConfigurationInformations: CountryRegistrationConfigurationInformation;
  public pickupPoints: PickUpPoint[] = [];
  public legalEntityDetails: LegalEntityDetails;
}
