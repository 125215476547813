import {NoopScrollStrategy} from '@angular/cdk/overlay'
import {Location} from '@angular/common'
import {Component, Inject, OnInit} from '@angular/core'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {MAT_SELECT_CONFIG} from '@angular/material/select'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Params, Router} from '@angular/router'
import {
    CreateIdentifierRequest,
    CreateIdentifierResponse,
    DocumentType,
    FinaliseProposalAssessmentRequest,
    FinaliseProposalAssessmentResponse,
    FindProposalRequest,
    FindProposalResponse,
    IdentifierType,
    InitialiseProposalAssessmentRequest,
    InitialiseProposalAssessmentResponse,
    JudicialPerson,
    JudicialPersonCountryRegistrationConfigurationInformation,
    LegalEntityIdentifier,
    Money,
    NaturalPersonCountryRegistrationConfigurationInformation,
    Proposal,
    ProposalCriteria,
    ProposalGroupEntryAssessorCriteria,
    ProposalPolicyItem,
    ProposalRecord,
    ProposalRecordStatus,
    ProposalResult,
    ProposalResultsEnum,
    ProvideProposalGroupEntryRequest,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    ProvideScannedDocumentRequest,
    ProvideScannedDocumentResponse,
    RenderedDocumentType,
    RenderedDocumentTypeIdCriteria,
    ScannedDocumentCriteria,
    ScannedDocumentIdentifierIdentifier,
    ScannedDocumentTypeDescriptionIdentifier,
    SnapshotVehicle,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    VehicleEvaluation,
} from '@magnabc/tpi'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ActiveToast, ToastrService} from 'ngx-toastr'

import {environment} from '../../../../../../environments/environment'
import {Dropdown} from '../../../../../common/model/dropdown.model'
import {RaygunErrorHandler} from '../../../../../common/utils/utils.raygun'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import {
    IdentifierManagerService,
} from '../../../../../http.services/identification/identifier-manager/identifier-manager.service'
import {
    ScannedDocumentProviderService,
} from '../../../../../http.services/media/scanneddocument/scanned-document-provider/scanned-document-provider.service'
import {
    RenderedConceptualDocumentProviderService,
} from '../../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service'
import {
    ProposalAssessmentFinalisationManagerService,
} from '../../../../../http.services/retail/insurance/comprehensiveinsurance/proposalassessmentfinalisationmanager/proposal-assessment-finalisation-manager.service'
import {
    ProposalAssessmentInitialisationManagerService,
} from '../../../../../http.services/retail/insurance/comprehensiveinsurance/proposalassessmentinitialisationmanager/proposal-assessment-initialisation-manager.service'
import {
    ProposalGroupEntryProviderService,
} from '../../../../../http.services/retail/insurance/comprehensiveinsurance/proposalgroupentryprovider/proposal-group-entry-provider.service'
import {
    AuthenticationService,
} from '../../../../../app.services/managers/authentication/authentication.service'
import {
    AttachedDocumentDialogComponent,
} from '../../../../../view.components/retail/checkout/assess-proposal/attached-document-dialog/attached-document-dialog.component'
import {
    VehicleImageDialogComponent,
} from '../../../../../view.components/retail/checkout/assess-proposal/vehicle-image-dialog/vehicle-image-dialog.component'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {Utils} from '../../../../../view.components/shared/Utils/Utils'
import {PDFDisplayModel, PrintService} from '../../../../../app.services/common/printing/print.service'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import { UnderwriterManagerService } from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'

declare const jQuery: any;
@Component({
    selector: "app-assess-proposal",
    templateUrl: "./proposal-assessment-manager.component.html",
    styleUrls: ["./proposal-assessment-manager.component.scss"],
    providers: [
        {
          provide: MAT_SELECT_CONFIG,
          useValue: { overlayPanelClass: 'proposal-overlay-panel' },
        },
    ]
})

export class ProposalAssessmentManagerComponent extends FormComponent implements OnInit {

    loading = false;
    transparent = false;

    proposalCount = [
        new Dropdown(1, "1 Proposal"),
        new Dropdown(2, "2 Proposals"),
        new Dropdown(3, "3 Proposals"),
        new Dropdown(4, "4 Proposals"),
        new Dropdown(5, "5 Proposals"),
        new Dropdown(6, "6 Proposals"),
        new Dropdown(7, "7 Proposals"),
        new Dropdown(8, "8 Proposals"),
        new Dropdown(9, "9 Proposals"),
        new Dropdown(10, "10 Proposals"),
    ];

    countControl = new UntypedFormControl("", Validators.required);

    proposalId = "";
    legalEntityDescriptionIdentifier: LegalEntityIdentifier;
    judicialPerson: JudicialPerson;

    previewDocument = false;
    documentToPreview: any;
    currentProposal: Proposal;
    resultId: number;
    capturedResults: ProposalResult[] = [];
    currentProposalRecord: ProposalRecord;
    proposalPages = 1;
    itemsList: ProposalPolicyItem[] = [];
    proofOfAddressIdentifiers: ScannedDocumentTypeDescriptionIdentifier[] = [];
    activeToast: ActiveToast<any>;
    resultsMap =new Map<string, ProposalResult>();
    scannedDocuments: any [] = [];
    proposalResultEdit: ProposalResult;

    constructor(
        private authenticationService: AuthenticationService,
        private title: Title,
        private route: ActivatedRoute,
        private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
        private unMarshallerService: UnMarshallerService,
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private router: Router,
        private location: Location,
        private dialog: MatDialog,
        private proposalAssessmentFinalisationManagerService: ProposalAssessmentFinalisationManagerService,
        private identifierManagerService: IdentifierManagerService,
        private proposalAssessmentInitialisationManagerService: ProposalAssessmentInitialisationManagerService,
        private proposalGroupEntryProviderService: ProposalGroupEntryProviderService,
        private toastr: ErrorToastService,
        private errorToastService: ErrorToastService,
        private scannedDocumentProviderService: ScannedDocumentProviderService,
        private printService: PrintService,
        private runtimeConfigurationService: RuntimeConfigurationService,
        private underwriterManagerService: UnderwriterManagerService
    ) {
        super(translate, translateParser);

        title.setTitle(`Proposal Assessment - Console - ${runtimeConfigurationService.title}`);
    }

    ngOnInit() {
        this.route.params.forEach((params: Params) => {
            this.proposalId = params["proposalid"];
        });

        this.legalEntityDescriptionIdentifier = this.authenticationService.getLegalEntityDescriptionIdentifier().tpiIdentifier;
        this.judicialPerson = this.authenticationService.getLinkedToJudicialPerson();


        if (this.proposalId !== undefined && this.proposalId !== "") {

            var previewId = this.proposalId;

            if(this.proposalId.indexOf('_') != -1){
                previewId = this.proposalId.substring(0, this.proposalId.indexOf('_'));
            }
            this.onPreview(previewId);

            this.initialiseProposalAssessment(this.proposalId).then((initialiseProposalAssessmentResult) => {
                if (initialiseProposalAssessmentResult) {
                    this.currentProposal = {...initialiseProposalAssessmentResult.proposal};
                    this.currentProposalRecord = {...initialiseProposalAssessmentResult.proposalRecord};
                    this.itemsList = [...initialiseProposalAssessmentResult.proposal.proposalPolicyItems];

                    if (initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0].proofOfAddressIdentifier) {
                        const proofOfAddressScannedDocument = new ScannedDocumentTypeDescriptionIdentifier();
                        proofOfAddressScannedDocument.documentIdentifier = initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0].proofOfAddressIdentifier;
                        const proofOfAddressDocumentType = new DocumentType();
                        proofOfAddressDocumentType.description = "Proof of Address";
                        proofOfAddressScannedDocument.documentTypeDescription = proofOfAddressDocumentType;

                        this.proofOfAddressIdentifiers.push(proofOfAddressScannedDocument);
                    }

                    if ((initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0] as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification) {
                        const proofOfIdentificationScannedDocument = new ScannedDocumentTypeDescriptionIdentifier();
                        proofOfIdentificationScannedDocument.documentIdentifier = (initialiseProposalAssessmentResult
                        .countryRegistrationConfigurationInformation[0] as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification;
                        const proofOfIdentificationDocumentType = new DocumentType();
                        proofOfIdentificationDocumentType.description = "Proof of Identification";
                        proofOfIdentificationScannedDocument.documentTypeDescription = proofOfIdentificationDocumentType;

                        this.proofOfAddressIdentifiers.push(proofOfIdentificationScannedDocument);
                    }

                    if ((initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier) {
                        const ciproScannedDocument = new ScannedDocumentTypeDescriptionIdentifier();
                        ciproScannedDocument.documentIdentifier = (initialiseProposalAssessmentResult
                        .countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier;
                        const ciproDocumentType = new DocumentType();
                        ciproDocumentType.description = "Cipro Document";
                        ciproScannedDocument.documentTypeDescription = ciproDocumentType;

                        this.proofOfAddressIdentifiers.push(ciproScannedDocument);
                    }

                    if ((initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier) {
                        const companyLetterHeadScannedDocument = new ScannedDocumentTypeDescriptionIdentifier();
                        companyLetterHeadScannedDocument.documentIdentifier = (initialiseProposalAssessmentResult
                        .countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier;
                        const companyLetterHeadDocumentType = new DocumentType();
                        companyLetterHeadDocumentType.description = "Company Letter Head";
                        companyLetterHeadScannedDocument.documentTypeDescription = companyLetterHeadDocumentType;

                        this.proofOfAddressIdentifiers.push(companyLetterHeadScannedDocument);
                    }

                    if ((initialiseProposalAssessmentResult.countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier) {
                        const proofOfExistenceScannedDocument = new ScannedDocumentTypeDescriptionIdentifier();
                        proofOfExistenceScannedDocument.documentIdentifier = (initialiseProposalAssessmentResult
                        .countryRegistrationConfigurationInformation[0] as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier;
                        const proofOfExistenceDocumentType = new DocumentType();
                        proofOfExistenceDocumentType.description = "Proof of Existence";
                        proofOfExistenceScannedDocument.documentTypeDescription = proofOfExistenceDocumentType;

                        this.proofOfAddressIdentifiers.push(proofOfExistenceScannedDocument);
                    }
                }
            }).catch((error) => {
                console.log('Error', error);
                this.errorToastService.errorToast(error);
            });
        }
        else{

            this.getProposalGroupEntry().then((hasOpenProposals) => {
                if (hasOpenProposals) {
                    this.router.navigate(["/proposal-list/open"], { state: { proposalCount: undefined }});
                }
            });

        }

        this.resultId = 0;
    }

    getProposalGroupEntry() {
        var provideProposalGroupEntryRequest = new ProvideProposalGroupEntryRequest();

        var proposalGroupEntryAssessorCriteria = new ProposalGroupEntryAssessorCriteria();

        proposalGroupEntryAssessorCriteria.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
            this.authenticationService.getLinkedToJudicialPerson().name,
            Utils.getLegalEntityDescriptionIdentifier(
                this.authenticationService.getLinkedToJudicialPerson()
                    .legalEntityIdentifiers
            )
        );

        proposalGroupEntryAssessorCriteria.assessor = this.legalEntityDescriptionIdentifier;

        provideProposalGroupEntryRequest.criteria = proposalGroupEntryAssessorCriteria;

        return new Promise<Boolean>((resolve, reject) => {
            this.proposalGroupEntryProviderService
                .provideProposalGroupEntry(provideProposalGroupEntryRequest)
                .then((provideProposalGroupEntryResponse) => {
                    if (provideProposalGroupEntryResponse.proposalGroupEntries.length > 0) {


                        var proposalGroupEntry = provideProposalGroupEntryResponse.proposalGroupEntries[0];

                        resolve(proposalGroupEntry.proposalIds.length > 0);

                        return;
                    }
                })
                .catch((err) => {
                    RaygunErrorHandler.sendError(err);

                    resolve(null);
                    return;
                });
        });
    }

    checkoutProposals() {
        if (this.countControl.valid) {
            this.router.navigate(["/proposal-list/open/" + this.countControl.value ], { state: { proposalCount: this.countControl.value }});
        } else {
            this.countControl.markAsTouched();
        }
    }

    getRenderedDocument(proposalId: string, callback): void {
        const renderedDocumentTypeIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentTypeIdCriteria.documentId = proposalId;
        renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.PROPOSAL];
        renderedDocumentTypeIdCriteria.userID = Utils.getTpiIdentifier(
            this.authenticationService.getLoggedInLegalEntityIdentifiers()
        ).tpiIdentifier;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentTypeIdCriteria;

        this.startLoad();

        this.renderedConceptualDocumentProviderService
            .provideRenderedConceptualDocument(
                provideRenderedConceptualDocumentRequest
            )
            .subscribe((data) => {

                this.stopLoad();

                const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(
                    data.body,
                    ProvideRenderedConceptualDocumentResponse
                ) as ProvideRenderedConceptualDocumentResponse;
                if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0)
                {
                    callback(provideRenderedConceptualDocumentResponse.renderedDocuments[0]);
                } else {
                    callback({});
                }
            });
    }

    onPreview(proposalId: string) {
        this.getRenderedDocument(proposalId, (pdfDocument) => {
            if (pdfDocument.type === RenderedDocumentType.PROPOSAL) {
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.startLoad()
                this.displayPDF(this.documentToPreview, () => {
                    this.stopLoad();
                });
            });
        });
    }

    displayPDF(base64, complete) {
        const canvasId = `#canvas-proposal`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.proposalPages = numPages;
        });
    }

    backClicked(): void {
        this.location.back();
    }

    proposalResultAccepted(proposalResults: ProposalResult[]) {
        for (const proposalResult of proposalResults) {
            if (proposalResult.result === ProposalResultsEnum.ACCEPT) {
                return true;
            }
        }
        return false;
    }

    proposalResultPending(proposalResults: ProposalResult[]) {
        for (const proposalResult of proposalResults) {
            if (proposalResult.result === ProposalResultsEnum.EVALUATE) {
                return true;
            }
        }
        return false;
    }

    submitResults(){
        this.startLoad();
        var finaliseProposalAssessmentRequest = new FinaliseProposalAssessmentRequest();

        this.resultsMap.forEach((resultEntry) => {
            finaliseProposalAssessmentRequest.proposalResults.push(resultEntry);
        });

        finaliseProposalAssessmentRequest.assessor = this.legalEntityDescriptionIdentifier;
        finaliseProposalAssessmentRequest.proposalId = this.proposalId
        finaliseProposalAssessmentRequest.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
            this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier.name,
            Utils.getLegalEntityDescriptionIdentifier(
                [this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier.legalEntityDescriptionIdentifier]
            )
        );

        if (this.proposalResultAccepted(finaliseProposalAssessmentRequest.proposalResults)) {
            this.createIdentifier(IdentifierType.ORDER_NUMBER).then((orderNumber) => {
                finaliseProposalAssessmentRequest.orderNumber = orderNumber;
                this.processProposalAssessment(finaliseProposalAssessmentRequest);
            });
        } else {
            this.processProposalAssessment(finaliseProposalAssessmentRequest);
        }

    }

    processProposalAssessment(finaliseProposalAssessmentRequest: FinaliseProposalAssessmentRequest) {
        if (this.proposalResultPending(finaliseProposalAssessmentRequest.proposalResults)) {
            this.createIdentifier(IdentifierType.PROPOSAL_IDENTIFIER).then((proposalId) => {
                finaliseProposalAssessmentRequest.proposalIdentifier = proposalId;
                this.sendFinaliseProposalAssessment(finaliseProposalAssessmentRequest);
            });
        }
        else{
            this.sendFinaliseProposalAssessment(finaliseProposalAssessmentRequest);
        }
    }

    sendFinaliseProposalAssessment(finaliseProposalAssessmentRequest: FinaliseProposalAssessmentRequest) {

        this.finaliseProposalAssessment(finaliseProposalAssessmentRequest).then((finalisationResponse) => {
            this.stopLoad();

            if(finalisationResponse){

                this.toast("TOAST.PROPOSAL_ASSESSED");

                setTimeout(() => {
                    this.router.navigate(["/assessment/proposals"]);
                }, environment.toast_time_out);
            }
            else{
                this.activeToast = this.toastr.success("Unable to submit results.", "Error", {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            }
        }).catch((error) => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
        });;

    }

    allItemsCaptured(): boolean {
        var hasEverything = true;

        for(var item of this.currentProposal.proposalPolicyItems){
            if((this.resultsMap.get(item.snapshotVehicle.plateNumber+item.startDate) === undefined)){

                return false;
            }
        }
        return hasEverything;
    }

    showCaptureDialog(proposalPolicyItem: ProposalPolicyItem, editDialog: boolean): void {

        if(editDialog){
            this.proposalResultEdit =  this.resultsMap.get(proposalPolicyItem.snapshotVehicle.plateNumber+proposalPolicyItem.startDate);
        }else{
            this.proposalResultEdit = null
        }

        const dialogRef = this.dialog.open(DialogCaptureResultsComponent, {
            scrollStrategy: new NoopScrollStrategy,
            width: '50%',
            panelClass: 'padded-modal',
            maxWidth: '600px',
            data:   {
                        vehicleRegistration: proposalPolicyItem.snapshotVehicle.plateNumber,
                        moneyCurrency: proposalPolicyItem.vehicleValue.currency,
                        proposalRecord: this.currentProposalRecord,
                        proposalResultEdit: this.proposalResultEdit
                    }
        });

        dialogRef.afterClosed().subscribe((result: UntypedFormGroup) => {

            if(result !== undefined){

                this.resultId++;

                var proposalResults = new ProposalResult();

                proposalResults.proposalResultId = this.resultId.toString();
                proposalResults.proposalId = this.proposalId;
                proposalResults.resultDate = new Date();
                proposalResults.result = this.getResultEnum(result.value.evaluatorResult);
                proposalResults.assessor = this.legalEntityDescriptionIdentifier;
                proposalResults.proposalPolicyItemNumber = proposalPolicyItem.number;
                proposalResults.annualPricePercentage = parseFloat(result.value.annualPricePercentage === undefined ? '0' : result.value.annualPricePercentage);
                proposalResults.periodPricePercentage = parseFloat(result.value.periodPricePercentage === undefined ? '0' : result.value.periodPricePercentage);
                proposalResults.ncdPercentage = (result.value.ncdPercentage !== undefined && result.value.ncdPercentage !== "") ? parseFloat(result.value.ncdPercentage) : null;

                var vehicleEvaluation = new VehicleEvaluation();
                if(result.value.vehicleValue !== "" && result.value.vehicleValue !== undefined && result.value.vehicleValue !== null){

                    vehicleEvaluation.vehicleValue = new Money();
                    vehicleEvaluation.vehicleValue.amount = result.value.vehicleValue * 100;
                    vehicleEvaluation.vehicleValue.currency = proposalPolicyItem.vehicleValue.currency;
                } else{
                    vehicleEvaluation.vehicleValue = proposalPolicyItem.vehicleValue;
                }

                if(result.value.evaluatorRecommendation !== undefined && result.value.evaluatorRecommendation !== null){
                    vehicleEvaluation.recommendation = result.value.evaluatorRecommendation.toLowerCase() === 'accept' ? true : false;
                }

                if(result.value.comments !== undefined && result.value.comments !== null){
                    vehicleEvaluation.comments.push(result.value.comments);
                }

                proposalResults.vehicleEvaluation = vehicleEvaluation;

                if(result.value.additionalPrice !== "" && result.value.additionalPrice !== undefined){
                    proposalResults.additionalPrice = new Money();
                    proposalResults.additionalPrice.amount = result.value.additionalPrice * 100;
                    proposalResults.additionalPrice.currency = proposalPolicyItem.vehicleValue.currency;
                }

                if(this.resultsMap.get(proposalPolicyItem.snapshotVehicle.plateNumber+proposalPolicyItem.startDate) !== undefined){
                    this.resultsMap.delete(proposalPolicyItem.snapshotVehicle.plateNumber+proposalPolicyItem.startDate);
                }

                this.resultsMap.set(proposalPolicyItem.snapshotVehicle.plateNumber+proposalPolicyItem.startDate, proposalResults);
            }
        });
    }

    viewVehicleImages(proposalPolicyItem: ProposalPolicyItem): boolean {

        this.scannedDocuments = []
        for(const vehicleImage of proposalPolicyItem.vehicleImages){
            var scannedDocumentCriteria = new ScannedDocumentCriteria();
            var scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier();
            scannedDocumentIdentifierIdentifier.documentIdentifier = vehicleImage;
            scannedDocumentCriteria.scannedDocumentIdentifier = scannedDocumentIdentifierIdentifier;

            var provideScannedDocumentRequest = new ProvideScannedDocumentRequest();
            provideScannedDocumentRequest.criteria = scannedDocumentCriteria;
            this.scannedDocumentProviderService.provideScannedDocument(provideScannedDocumentRequest).toPromise().then(data => {
              const response: ProvideScannedDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideScannedDocumentResponse);
              this.scannedDocuments.push(response.scannedDocuments[0]);

            }).then(_ => {

                if(proposalPolicyItem.vehicleImages.length == this.scannedDocuments.length){
                    this.dialog.open(VehicleImageDialogComponent, {
                        scrollStrategy: new NoopScrollStrategy,
                        width: '50%',
                        maxWidth: '550px',
                        height: '65%',
                        data:   {
                            scannedDocuments:  this.scannedDocuments,
                            plateNumber: proposalPolicyItem.snapshotVehicle.plateNumber
                        }
                    });
                }

            });
          }
        return false;
    }

    openAttachedDocument(): void{
        this.dialog.open(AttachedDocumentDialogComponent, {
            width: '900px',
            panelClass: 'padded-modal',
            height: '700px',
            data: this.proofOfAddressIdentifiers.length > 0? this.proofOfAddressIdentifiers : []
        });
    }

    itemCaptured(item: ProposalPolicyItem): boolean {
        return (this.resultsMap.get(item.snapshotVehicle.plateNumber+item.startDate) !== undefined)
    }

    finaliseProposalAssessment(finaliseProposalAssessmentRequest: FinaliseProposalAssessmentRequest) {
        return new Promise<Boolean>((resolve, reject) => {
            this.proposalAssessmentFinalisationManagerService
                .finaliseProposalAssessment(finaliseProposalAssessmentRequest)
                .toPromise()
                .then((httpResponse) => {
                    if (httpResponse && httpResponse.body) {
                        const response = this.unMarshallerService.unMarshallFromJson(
                            httpResponse.body,
                            FinaliseProposalAssessmentResponse
                        ) as FinaliseProposalAssessmentResponse;

                        resolve(true);
                    }
                })
                .catch((err) => {
                    RaygunErrorHandler.sendError(err);

                    resolve(null);
                    return;
                });
        });
    }

    createOrderNumber(): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            const generateIdentifierRequest = new CreateIdentifierRequest();
            generateIdentifierRequest.quantity = 1;
            generateIdentifierRequest.type = IdentifierType.ORDER_NUMBER;

            this.identifierManagerService.createIdentifier(generateIdentifierRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateIdentifierResponse) as CreateIdentifierResponse);
                    resolve(response.identifier[0]);
                } else {
                    reject();
                }

            }).catch((error) => {
                reject(error);
            });

        });

    }

    createIdentifier(identifierType: IdentifierType): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            const generateIdentifierRequest = new CreateIdentifierRequest();
            generateIdentifierRequest.quantity = 1;
            generateIdentifierRequest.type = identifierType;

            this.identifierManagerService.createIdentifier(generateIdentifierRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateIdentifierResponse) as CreateIdentifierResponse);
                    resolve(response.identifier[0]);
                } else {
                    reject();
                }

            }).catch((error) => {
                reject(error);
            });

        });

    }

    getResultEnum(proposalResult: string): ProposalResultsEnum{
        switch(proposalResult.toLowerCase()){
            case ProposalResultsEnum.ACCEPT.valueOf().toLowerCase():
                return ProposalResultsEnum.ACCEPT;

            case ProposalResultsEnum.DECLINE.valueOf().toLowerCase():
                return ProposalResultsEnum.DECLINE;

            case ProposalResultsEnum.EVALUATE.valueOf().toLowerCase():
                return ProposalResultsEnum.EVALUATE;
        }
    }

    getPlateNumber(vehicle: SnapshotVehicle) {
        return vehicle.plateNumber;
    }

    initialiseProposalAssessment(proposalId: string) {
        var initialiseProposalAssessmentRequest = new InitialiseProposalAssessmentRequest();
        initialiseProposalAssessmentRequest.proposalId = proposalId;

        return new Promise<InitialiseProposalAssessmentResponse>(
            (resolve, reject) => {
                this.proposalAssessmentInitialisationManagerService
                    .initialiseProposalAssessment(
                        initialiseProposalAssessmentRequest
                    )
                    .toPromise()
                    .then((httpResponse) => {
                        if (httpResponse && httpResponse.body) {
                            const response = this.unMarshallerService.unMarshallFromJson(
                                httpResponse.body,
                                InitialiseProposalAssessmentResponse
                            ) as InitialiseProposalAssessmentResponse;
                            resolve(response);
                        } else {
                            console.log("not done");
                        }
                    })
                    .catch((error) => {
                        RaygunErrorHandler.sendError(error);
                        console.log("not done");
                    });
            }
        );
    }

    getPreviewId(proposalId: string){
        var previewId = proposalId;

        if(proposalId.indexOf('_') != -1){
            previewId = proposalId.substring(0, proposalId.indexOf('_'));
        }

        return previewId;
    }

    toast(message): void {

        this.translate.get(message).subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: false,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {
                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }
}

@Component({
    selector: 'app-dialog-capture-results',
    templateUrl: './dialog-capture-results.component.html',
    styleUrls: ["./dialog-capture-results.component.scss"]
})
export class DialogCaptureResultsComponent extends FormComponent{

    proposalResults: UntypedFormGroup;

    evaluatorResult: UntypedFormControl;
    annualPricePercentage: UntypedFormControl;
    periodPricePercentage: UntypedFormControl;
    additionalPrice: UntypedFormControl;
    ncdPercentage: UntypedFormControl;
    vehicleValue: UntypedFormControl;
    comments: UntypedFormControl;
    evaluatorRecommendation: UntypedFormControl;

    valueCurrency: string;
    vehicleRegistration: string;
    proposalRecord: ProposalRecord;
    hideFormGroup = false;

    constructor(public dialogRef: MatDialogRef<DialogCaptureResultsComponent>, @Inject(MAT_DIALOG_DATA) data: any, private translate: TranslateService, private translateParser: TranslateParser) {

        super(translate, translateParser);

        this.valueCurrency = data.moneyCurrency;
        this.vehicleRegistration = data.vehicleRegistration;
        this.proposalRecord = data.proposalRecord;

        if(data.proposalResultEdit){
            if(data.proposalResultEdit.vehicleEvaluation){
                if(data.proposalRecord.status === ProposalRecordStatus.PENDING){
                    if(data.proposalResultEdit.vehicleEvaluation.recommendation){
                        this.evaluatorRecommendation = new UntypedFormControl('accept', [
                            Validators.maxLength(8)
                        ]);
                    }else{
                        this.evaluatorRecommendation = new UntypedFormControl('decline', [
                            Validators.maxLength(8)
                        ]);
                    }

                }else{
                    this.evaluatorRecommendation = new UntypedFormControl('', [
                        Validators.maxLength(8)
                    ]);
                }

                if(data.proposalResultEdit.vehicleEvaluation.vehicleValue){
                    this.vehicleValue = new UntypedFormControl(data.proposalResultEdit.vehicleEvaluation.vehicleValue.amount/100, [
                        Validators.min(0)
                    ]);
                }else{
                    this.vehicleValue = new UntypedFormControl('', [
                        Validators.min(0)
                    ]);
                }

                if(data.proposalResultEdit.vehicleEvaluation.comments){
                    this.comments = new UntypedFormControl(data.proposalResultEdit.vehicleEvaluation.comments[0], [
                    ]);
                }else{
                    this.comments = new UntypedFormControl('', [
                    ]);
                }
            }

            this.evaluatorResult = new UntypedFormControl(data.proposalResultEdit.result.toLowerCase(), [
                Validators.maxLength(8),
                Validators.required
            ]);

            if(data.proposalResultEdit.annualPricePercentage){
                this.annualPricePercentage = new UntypedFormControl(data.proposalResultEdit.annualPricePercentage, [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }else{
                this.annualPricePercentage = new UntypedFormControl('', [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }

            if(data.proposalResultEdit.periodPricePercentage){
                this.periodPricePercentage = new UntypedFormControl(data.proposalResultEdit.periodPricePercentage, [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }else{
                this.periodPricePercentage = new UntypedFormControl('', [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }


            if(data.proposalResultEdit.additionalPrice){
                this.additionalPrice = new UntypedFormControl(data.proposalResultEdit.additionalPrice.amount/100, [
                ]);
            }else{
                this.additionalPrice = new UntypedFormControl('', [
                ]);
            }

            if(data.proposalResultEdit.ncdPercentage){
                this.ncdPercentage = new UntypedFormControl(data.proposalResultEdit.ncdPercentage, [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }else{
                this.ncdPercentage = new UntypedFormControl('', [
                    Validators.max(100),
                    Validators.min(0)
                ]);
            }

            this.proposalResults = new UntypedFormGroup({
                evaluatorResult: this.evaluatorResult,
                annualPricePercentage: this.annualPricePercentage,
                periodPricePercentage: this.periodPricePercentage,
                additionalPrice: this.additionalPrice,
                ncdPercentage: this.ncdPercentage,
                vehicleValue: this.vehicleValue,
                comments: this.comments,
                evaluatorRecommendation: this.evaluatorRecommendation
            });

            if(data.proposalResultEdit.result.toLowerCase() === "decline" || data.proposalResultEdit.result.toLowerCase() === "evaluate"){
                this.hideFormGroup = true;
                this.annualPricePercentage.disable();
                this.periodPricePercentage.disable();
                this.additionalPrice.disable();
                this.ncdPercentage.disable();
                this.vehicleValue.disable();
            }else{
                this.hideFormGroup = false;
            }

        }else{
            if(data.proposalRecord.status === ProposalRecordStatus.PENDING){
                this.evaluatorRecommendation = new UntypedFormControl('', [
                    Validators.required,
                    Validators.maxLength(8)
                ]);
            }
            else{
                this.evaluatorRecommendation = new UntypedFormControl('', [
                    Validators.maxLength(8)
                ]);
            }

            this.evaluatorResult = new UntypedFormControl('', [
                Validators.maxLength(8),
                Validators.required
            ]);

            this.vehicleValue = new UntypedFormControl('', [
                Validators.min(0)
            ]);

            this.comments = new UntypedFormControl('', [
            ]);

            this.annualPricePercentage = new UntypedFormControl('', [
                Validators.max(100),
                Validators.min(0)
            ]);

            this.periodPricePercentage = new UntypedFormControl('', [
                Validators.max(100),
                Validators.min(0)
            ]);

            this.additionalPrice = new UntypedFormControl('', [
            ]);

            this.ncdPercentage = new UntypedFormControl('', [
                Validators.max(100),
                Validators.min(0)
            ]);


            this.proposalResults = new UntypedFormGroup({
                evaluatorResult: this.evaluatorResult,
                annualPricePercentage: this.annualPricePercentage,
                periodPricePercentage: this.periodPricePercentage,
                additionalPrice: this.additionalPrice,
                ncdPercentage: this.ncdPercentage,
                vehicleValue: this.vehicleValue,
                comments: this.comments,
                evaluatorRecommendation: this.evaluatorRecommendation
            });

            this.annualPricePercentage.disable();
            this.periodPricePercentage.disable();
            this.additionalPrice.disable();
            this.ncdPercentage.disable();
            this.vehicleValue.disable();
            this.hideFormGroup = true;
        }

    }

    isPendingProposal(): boolean{
        return (this.proposalRecord.status === ProposalRecordStatus.PENDING);
    }

    assessorInputChanged(assessorInput: string){

        if(this.proposalRecord.status === ProposalRecordStatus.PENDING){
            if(assessorInput === 'decline'){
                this.hideFormGroup = true;
                this.annualPricePercentage.reset();
                this.periodPricePercentage.reset();
                this.additionalPrice.reset();
                this.ncdPercentage.reset();
                this.vehicleValue.reset();
                this.evaluatorRecommendation.reset();
                this.comments.reset();

                this.vehicleValue.clearValidators();

                this.annualPricePercentage.disable();
                this.periodPricePercentage.disable();
                this.additionalPrice.disable();
                this.ncdPercentage.disable();
                this.vehicleValue.disable();
            }
            else if(assessorInput === 'accept'){
                this.hideFormGroup = false;
                this.evaluatorRecommendation.reset();
                this.comments.reset();
                this.annualPricePercentage.setValidators([Validators.required, Validators.max(100), Validators.min(0)]);
                this.periodPricePercentage.setValidators([Validators.required, Validators.max(100), Validators.min(0)]);

                this.evaluatorRecommendation.setValidators([Validators.required]);

                this.annualPricePercentage.enable();
                this.periodPricePercentage.enable();
                this.additionalPrice.enable();
                this.ncdPercentage.enable();
                this.vehicleValue.enable();
                this.comments.enable();
                this.evaluatorRecommendation.enable();
            }
        }
        else{
            if(assessorInput === 'decline' || assessorInput === 'evaluate'){
                this.hideFormGroup = true;
                this.annualPricePercentage.reset();
                this.periodPricePercentage.reset();
                this.additionalPrice.reset();
                this.ncdPercentage.reset();
                this.vehicleValue.reset();
                this.comments.reset();
                this.evaluatorRecommendation.reset();

                this.annualPricePercentage.disable();
                this.periodPricePercentage.disable();
                this.additionalPrice.disable();
                this.ncdPercentage.disable();
                this.vehicleValue.disable();
                this.evaluatorRecommendation.disable();
                if(assessorInput === 'decline')
                {
                    this.comments.enable();
                    this.evaluatorRecommendation.enable();
                }
            }
            else if(assessorInput === 'accept'){
                this.hideFormGroup = false;
                this.comments.reset();
                this.evaluatorRecommendation.reset();
                this.annualPricePercentage.enable();
                this.periodPricePercentage.enable();

                this.annualPricePercentage.setValidators([Validators.required, Validators.max(100), Validators.min(0)]);
                this.periodPricePercentage.setValidators([Validators.required, Validators.max(100), Validators.min(0)]);

                this.annualPricePercentage.updateValueAndValidity();
                this.periodPricePercentage.updateValueAndValidity();

                this.additionalPrice.enable();
                this.ncdPercentage.enable();

                this.vehicleValue.enable();
                this.comments.enable();
                this.evaluatorRecommendation.enable();
            }
        }
    }

    close(): void {
        this.dialogRef.close(this.proposalResults);
    }

    discardResults(){
        this.dialogRef.close();
    }

    onSubmit(): void{
        // console.log('SDFSA', this.proposalResults);
     }

     checkPeriodPricePercentage(): void{
        const annualPricePercentage = Number(this.annualPricePercentage.value);
        const periodPricePercentage = Number(this.periodPricePercentage.value);
        if(annualPricePercentage && periodPricePercentage){
            if(periodPricePercentage > annualPricePercentage){
                this.annualPricePercentage.setErrors({ invalidAmount: 'Annual Price must be greater or equal to the Period Price Percentage' }, {emitEvent: true});
                this.periodPricePercentage.setErrors({ invalidAmount: 'Period Price must be less or equal to the Annual Price Percentage' }, {emitEvent: true});
            }else{
                this.annualPricePercentage.setErrors(null, {emitEvent: true});
                this.periodPricePercentage.setErrors(null, {emitEvent: true});
            }
        }
     }
}
