import { CountryIdentifier } from "../../../location/location_api";
import { AccountType } from "../../models/account-type.model";
import { PayoutMethod } from "./payout-method.model";

export class BankTransfer extends PayoutMethod{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.payout.BankTransfer";
    }

    public accountName : string;
    public accountNumber : string;
    public accountType : AccountType;

    public bankName : string;
    public branchCode : string;
    public swiftBicCode : string;

    public legalEntityIdentifier : string; //string is used until backend is updated to use a legalEntityIdentifier object
    public countryIdentifier : CountryIdentifier;
}