import { Money } from "../../../../financial/models/money.model";
import { ScannedDocument } from "../../../../media/scanneddocument/models/scanned-document.model";
import { Vehicle } from "../../../../vehicle/models/vehicle.model";
import { ComprehensiveInsuranceCartItem } from "./comprehensive-insurance-cart-item.model";
import { ComprehensiveInsuranceProductCartItem } from "./comprehensive-insurance-product-cart-item.model";

export class ComprehensiveInsurancePolicyCartItem extends ComprehensiveInsuranceCartItem {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsurancePolicyCartItem";
    }

    public startDate: Date;
    public expiryDate: Date;
    public extensionExpiryDate: Date;
    public vehicleValue: Money;
    public alarmImmobiliser: boolean;
    public vehicle: Vehicle;
    public comprehensiveInsuranceProductCartItem: ComprehensiveInsuranceProductCartItem;
    public vehicleImages: string[] = [];
}
