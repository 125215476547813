import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Dropdown } from '../../../../../common/model/dropdown.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  period; selectFormControl: UntypedFormControl;
  timeFrames = [
    new Dropdown(1, "Today"),
    new Dropdown(2, "Yesterday"),
    new Dropdown(3, "Last 7 Days"),
    new Dropdown(4, "Current Month"),
    new Dropdown(5, "Last 30 Days")
  ];

  @Output() onSelected = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.period = new UntypedFormControl(this.timeFrames[2], []);

    this.onSelected.emit(this.period.value.name);
  }

  onChangeSelected(event){
    this.onSelected.emit(event.value.name);
  }

}
