import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;

@Component({
  selector: 'app-subscription-list-page',
  templateUrl: './subscription-list-page.component.html',
  styleUrls: ['./subscription-list-page.component.scss']
})
export class SubscriptionListPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Subscriptions - Console - ${this.runtimeConfigurationService.title}`);
    }

}
