import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConfigurationService } from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service';
import { MarshallerService, PayfastSignatureRequest } from '@magnabc/tpi';

@Injectable({
    providedIn: 'root'
  })
export class PayFastService {

  basePath = '/retail/payment/payfast';

  constructor(
    private http: HttpClient,
    private runtimeConfigurationService: RuntimeConfigurationService,
    private marshallerService: MarshallerService
  ) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  getSignature(payfastSignatureRequest: PayfastSignatureRequest): Promise<any> {
    const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/payfastsignaturegenerator/generatepayfastsignature';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(payfastSignatureRequest);
    return this.http.post(path, jsonRequest, this.options).toPromise();
  }

}
