export class MotorInsuranceLimitDetails {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.MotorInsuranceLimitDetails";
    } 

    public description: string;
    public type: string;
    public value: number;
    public currency: string;
}