import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FormComponent } from "../../shared/form/form.component";
import { Dropdown } from "../../../common/model/dropdown.model";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { autoCompleteValidator } from "../../../common/utils/utils.validation";
import { Vehicle, Engine, EngineNumber } from "@magnabc/tpi";

declare const jQuery: any;
@Component({
    selector: 'app-vehicle-engine',
    templateUrl: './vehicle.engine.component.html'
})
export class VehicleEngineComponent extends FormComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

    displayDropDown = FormComponent.displayDropDown;
    engineNumberPattern = '^[a-hj-np-zA-HJ-NP-Z0-9/]+$';

    @Input() engine: Engine;
    @Input() vehicle: Vehicle;
    @Input() title = "";
    @Input() isSelfPropelled: boolean;
    @Output() onEngine = new EventEmitter<Engine>();
    @Output() onValid = new EventEmitter<void>();

    transmissions = [
        new Dropdown('manual', 'Manual'),
        new Dropdown('automatic_transmission', 'Automatic transmission'),
        new Dropdown('semi_automatic_transmission', 'Semi-automatic transmission'),
        new Dropdown('adapted_for_physically_disabled', 'Adapted for physically disabled'),
        new Dropdown('not_applicable', 'Not applicable'),
    ];
    filteredTransmissions: Observable<Dropdown[]>;

    fuelTypes = [
        new Dropdown('petrol', 'Petrol'),
        new Dropdown('diesel', 'Diesel'),
        new Dropdown('electricity', 'Electricity'),
        new Dropdown('not_applicable', 'Not Applicable'),
    ];
    filteredFuelTypes: Observable<Dropdown[]>;

    formGroup: UntypedFormGroup;
    engineNumber: UntypedFormControl;
    engineCapacity: UntypedFormControl;
    netPower: UntypedFormControl;
    transmission: UntypedFormControl;
    fuelType: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {
        super(translate, translateParser);

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSelfPropelled) {
            if (changes.isSelfPropelled.currentValue === true) {
                console.log('Is self propelled', changes);
                if (this.engineNumber) {
                    this.engineNumber.setValidators([
                        Validators.required,
                        Validators.maxLength(20),
                        Validators.pattern(this.engineNumberPattern)
                    ]);
                }
            } else {
                console.log('Is not self propelled',);
                if (this.engineNumber) {
                    this.engineNumber.clearValidators();
                    this.clearEngine();
                }
            }
        }
    }

    ngOnInit(): void {

        if (this.vehicle.propulsion === "Self-propelled") {
            this.isSelfPropelled = true;
        } else {
            this.isSelfPropelled = false;
        }

        if (this.engine) {

            this.engineNumber = new UntypedFormControl(this.engine.engineNumber, [
                Validators.maxLength(20),
                Validators.pattern(this.engineNumberPattern)
            ]);
            this.engineCapacity = new UntypedFormControl(this.engine.engineCapacity, [
                Validators.maxLength(5),
                Validators.min(10),
                Validators.pattern('^[0-9]+$')
            ]);
            this.netPower = new UntypedFormControl(this.engine.netPower, [
                Validators.maxLength(8),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.transmission = new UntypedFormControl(this.getDropdown(this.transmissions, this.engine.transmission), [
                autoCompleteValidator(this.transmissions)
            ]);
            this.fuelType = new UntypedFormControl(this.getDropdown(this.fuelTypes, this.engine.fuelType), [
                autoCompleteValidator(this.fuelTypes)
            ]);

        } else {

            this.engine = new Engine();
            this.engineNumber = new UntypedFormControl('', [
                Validators.maxLength(20),
                Validators.pattern(this.engineNumberPattern)
            ]);
            this.engineCapacity = new UntypedFormControl('', [
                Validators.maxLength(5),
                Validators.min(10),
                Validators.pattern('^[0-9]+$')
            ]);
            this.netPower = new UntypedFormControl('', [
                Validators.maxLength(8),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.transmission = new UntypedFormControl('', [
                autoCompleteValidator(this.transmissions)
            ]);
            this.fuelType = new UntypedFormControl('', [
                autoCompleteValidator(this.fuelTypes)
            ]);

        }

        if (this.isSelfPropelled) {
            this.engineNumber.setValidators([
                Validators.required,
                Validators.maxLength(20),
                Validators.pattern(this.engineNumberPattern)]);
        }

        this.filteredTransmissions = this.transmission.valueChanges.pipe(
            startWith<string | Dropdown>(''),
            map((value: any) => typeof value === 'string' ? value : value.name),
            map((name: any) => name ? FormComponent.filterDropDown(this.transmissions, name) : this.transmissions.slice())
        );

        this.filteredFuelTypes = this.fuelType.valueChanges.pipe(
            startWith<string | Dropdown>(''),
            map((value: any) => typeof value === 'string' ? value : value.name),
            map((name: any) => name ? FormComponent.filterDropDown(this.fuelTypes, name) : this.fuelTypes.slice())
        );

        this.formGroup = new UntypedFormGroup({
            'engineNumber': this.engineNumber,
            'engineCapacity': this.engineCapacity,
            'netPower': this.netPower,
            'transmission': this.transmission,
            'fuelType': this.fuelType
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    submit(): void {
        document.getElementById('form-submit-engine-' + this.title).click();
    }

    clearEngine(): void {
        this.engineNumber.setValue('');
        this.engineCapacity.setValue('');
        this.netPower.setValue('');
        this.transmission.setValue('');
        this.fuelType.setValue('');
        const identifiers = this.vehicle.vehicleIdentifiers;
        this.vehicle.vehicleIdentifiers = identifiers.filter((identifier) => {
            if (!(identifier instanceof EngineNumber)) {
                return identifier;
            }
        });
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            this.engine.engineNumber = this.engineNumber.value;
            this.engine.engineCapacity = this.engineCapacity.value ? parseInt(this.engineCapacity.value) : null;
            this.engine.netPower = this.netPower.value ? parseInt(this.netPower.value) : null;
            this.engine.transmission = this.transmission.value ? this.transmission.value.name : null;
            this.engine.fuelType = this.fuelType.value ? this.fuelType.value.name : null;

            this.onEngine.emit(this.engine);
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

}
