import {BrokerIdentifier} from "./broker-identifier.model";
import {LegalEntityDescriptionIdentifier} from "../../../../entity/identification/models/legal-entity-description-identifier.model";

export class BrokerLegalEntityIdentifier extends BrokerIdentifier {

    name: string;
    legalEntityDescriptionIdentifier: LegalEntityDescriptionIdentifier;

    constructor(name: string, legalEntityDescriptionIdentifier: LegalEntityDescriptionIdentifier) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.identification.BrokerLegalEntityIdentifier";
        this.name = name;
        this.legalEntityDescriptionIdentifier = legalEntityDescriptionIdentifier;
    }
}

