import {Request} from '../../../../../../../urdad/services/models/request.model';
import {ContactNumber} from '../../../../../../../entity/models/contact-number.model';
import { OneTimePinContext } from '../../../../../models/one-time-pin-context.model';

export class VerifyOneTimePinRequest extends Request{
    constructor(){
        super();
    }

    public contactNumber: ContactNumber;
    public oneTimePinContext: OneTimePinContext;
}