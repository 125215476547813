import { InsuranceSalesBySalesChannelEntry } from "./insurance-sales-by-sales-channel-entry.model";

export class InsuranceSalesBySalesChannelReport
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.InsuranceSalesBySalesChannelReport";
    }

    public dateFrom: Date;
    public dateTo: Date;
    public insuranceSalesBySalesChannelEntries: InsuranceSalesBySalesChannelEntry[];
}
