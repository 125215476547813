import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {Country, MarshallerService, ProvideSupportedCountriesRequest}from '@magnabc/tpi'
import {Subject} from 'rxjs'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class SupportedCountriesProviderService {

    basePath = '/retail/setup/supportedcountriesprovider';

    selectedCountry: Country;
    subject = new Subject<Country>()

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideSupportedCountries(provideSupportedCountriesRequest: ProvideSupportedCountriesRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesupportedcountries';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSupportedCountriesRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    setSelectedCountry(country: Country) {
        this.selectedCountry = country;
        this.subject.next(this.selectedCountry);
    }

    getActiveCountry(): Observable<Country> {
        return this.subject.asObservable();
    }

}
