import { Money } from "../../../financial/financial_api";
import { OrderStatus } from "./order-status.model";

export class OrderSummary{

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderSummary";
    }
    
    public date: Date;
    public orderNumber: string;
    public status: OrderStatus;
    public totalDue: Money;
}
