import { MotorInsuranceDetails } from '../../../../../checkout/models/motor-insurance-details.model';
import { RetailProfileIdentifier } from '../../../../../profile/identification/retail-profile-identifier.model';
import { Request } from '../../../../../../urdad/services/models/request.model';
import { Money } from '../../../../../../financial/models/money.model';
import { ScannedDocument } from '../../../../../../media/scanneddocument/models/scanned-document.model';
import {PricePerVehicle} from '../../../../thirdpartyinsurance/models/price-per-vehicle.model';

export class AddToCartRequest extends Request{
    constructor(){
        super();
    }

    public identifier: string[] = [];
    public pricePerVehicles: PricePerVehicle[] = [];
    public motorInsuranceDetails: MotorInsuranceDetails;
    public scannedDocuments: ScannedDocument[] = [];
    public retailProfileIdentifier: RetailProfileIdentifier;
}
