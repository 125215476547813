import { Criteria } from "../../../criteria/models/criteria.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class ServiceGroupTypeLegalEntityCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.ServiceGroupTypeLegalEntityCriteria";
    }

    public legalEntityIdentifier: TpiIdentifier;
    public serviceGroupType: string;

}
