import {Request} from '../../../../../../../../urdad/services/models/request.model'
import {AgentIdentifier} from '../../../../../../../../entity/identification/models/agent-identifier.model'

export class FinaliseYCAssessmentRequest extends Request{

    constructor(){
        super();
    }

    public userId: string
    public certificateNumber: string
    public ycPolicyNumber: string
    public orderItemNumber: string
    public agentIdentifier: AgentIdentifier

}
