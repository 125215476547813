import { RetailProfileOwnerIdentifier } from '../../../../../../profile/identification/retail-profile-owner-identifier.model';
import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Item} from '../../../../../models/item.model';
import {ItemIdentifier} from '../../../../../identification/models/item-identifier.model';
import {PricePerVehicle} from '../../../../../../insurance/thirdpartyinsurance/models/price-per-vehicle.model';

export class FinaliseExtendCheckoutOrderRequest extends Request {
    constructor() {
        super();
    }
    public expiryDate: Date;
    public retailProfileIdentifier: RetailProfileOwnerIdentifier;
    public updateItem: Item;
    public removeItem: ItemIdentifier;
    public pricePerVehicle: PricePerVehicle;
}
