import {Injectable} from '@angular/core';
import {Criteria, ServiceGroupTypeCriteria} from '@magnabc/tpi';
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionComponentService {

    constructor(private authenticationService: AuthenticationService) {
    }

    getServiceGroupTypeCriteria(): Criteria {
        let criteria: Criteria;

        if (!!this.authenticationService.getLoggedInNaturalPerson()) {
            criteria = new ServiceGroupTypeCriteria();
            (criteria as ServiceGroupTypeCriteria).serviceGroupType = 'PUBLIC';
            return criteria;
        }

        return null;
    }
}
