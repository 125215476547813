import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";
import { ProposalRecordStatus } from "./proposal-record-status.model";
import { ProposalResult } from "./proposal-result.model";

export class ProposalRecord {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalRecord";
    }

    public checkoutDate: Date;
    public status: ProposalRecordStatus;
    public statusDate: Date;
    public capturedDate: Date;
    public proposalId: string;
    public proposalResults: ProposalResult[] =[];
    public underwriterIdentifier: UnderwriterIdentifier;
}