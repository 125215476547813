import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session-tracking-modal',
  templateUrl: './session-tracking-modal.component.html',
  styleUrls: ['./session-tracking-modal.component.scss']
})
export class SessionTrackingModalComponent implements OnInit {

  message: string;
  displayButton: boolean;

  constructor(public dialogRef: MatDialogRef<SessionTrackingModalComponent>, @Inject(MAT_DIALOG_DATA) result: any) {
      this.message = result.message;
      this.displayButton = result.displayButton;
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  onYesClick(){
    this.closeDialog('Y');
  }

  onNoClick(){
    this.closeDialog('N');
  }

  closeDialog(button: 'Y' | 'N') {
    this.dialogRef.close(button);
  }


}
