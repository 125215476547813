import {Component, OnInit} from '@angular/core'
import {
    ComprehensiveInsurancePolicyCartItem,
    MotorInsuranceLimitDetails,
    ThirdPartyInsurancePolicyCartItem,
    YCInsurancePolicyCartItem,
    YCLimitDetails
} from '@magnabc/tpi'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'

@Component({
    selector: 'app-product-information-limits',
    templateUrl: './product-information-limits.component.html',
    styleUrls: ['./product-information-limits.component.scss']
})
export class ProductInformationLimitsComponent implements OnInit {

    initalLimitValueMap: {[id: string]: number} = {}
    initalLimitMinMap: {[id: string]: number} = {}
    initalLimitMaxMap: {[id: string]: number} = {}

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {

        if (this.insuranceProductService.item) {

            let motorInsuranceLimitDetails: MotorInsuranceLimitDetails[] |  YCLimitDetails[] = []

            if ((this.insuranceProductService.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem) {
                motorInsuranceLimitDetails = (this.insuranceProductService.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.motorInsuranceLimitDetails
            } else if ((this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem) {
                motorInsuranceLimitDetails = (this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.motorInsuranceLimitDetails
            } else if ((this.insuranceProductService.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem) {
                motorInsuranceLimitDetails = (this.insuranceProductService.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem.ycLimitDetails
            }

            for (const itemLimit of motorInsuranceLimitDetails) {
                for (const productLimit of this.insuranceProductService.limits) {
                    if (itemLimit.type === productLimit.type && itemLimit.description === productLimit.description) {
                        productLimit.value = itemLimit.value
                    }
                }
            }

        }
        for (const productLimit of this.insuranceProductService.limits) {
            this.initalLimitValueMap[productLimit.type + productLimit.description] = productLimit.value
            this.initalLimitMinMap[productLimit.type + productLimit.description] = productLimit.minimumValue
            this.initalLimitMaxMap[productLimit.type + productLimit.description] = productLimit.maximumValue
            if (productLimit.value % 100 != 0) {
                this.initalLimitMaxMap[productLimit.type + productLimit.description] = productLimit.maximumValue + 100
            }
        }

    }

    onCalculatePrice() {
        this.insuranceProductService.calculatePrice()
    }

    onLimitChange(event, limit) {
        if (this.initalLimitValueMap[limit.type + limit.description] % 100 != 0) {
            limit.value = event - (100 - (this.initalLimitValueMap[limit.type + limit.description] % 100))
            if (limit.value < this.initalLimitMinMap[limit.type + limit.description]) {
                limit.value = this.initalLimitMinMap[limit.type + limit.description]
            }
            if (limit.value > (this.initalLimitMaxMap[limit.type + limit.description] - 100)) {
                limit.value = this.initalLimitMaxMap[limit.type + limit.description] - 100
            }
        } else {
            limit.value = event
        }
    }

    resetLimit(limit) {
        limit.value = this.initalLimitValueMap[limit.type + limit.description]
        this.onCalculatePrice()
        return false
    }
    customisableLimits() {
        for (const limit of this.insuranceProductService.limits) {
            if (limit.customisable) {
                return true
            }
        }
        return false
    }

}
