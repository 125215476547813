import { VehicleCertificate } from "./vehicle-certificate.model";

export class RoadServicePermit extends VehicleCertificate{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.RoadServicePermit";
    }

	public permitNumber: string;
	public effectiveFrom: Date;
	public expiryDate: Date;
	public usage: string;
}