import {NaturalPersonIdentifier} from "../../../entity/identification/models/natural-person-identifier.model";
import {Credentials} from "../../credentials/models/credentials.model";


export class Account {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.security.account.Account";
    }

    public number: string;
    public verified: boolean;
    public effectiveTo: Date;
    public effectiveFrom: Date;
    public naturalPersonIdentifier: NaturalPersonIdentifier;
    public credentials: Credentials[] = [];
    public branch: string;
}
