import { Response } from "../../../../../../urdad/services/models/response.model";
import { AccountingTransaction } from "../../../../accounting_transaction_api";

export class ProvideAccountingTransactionInformationResponse extends Response{
    constructor(){ 
        super();
    }

    public accountingTransactions: AccountingTransaction [] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
}