import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";
import { InsuranceCategory } from "../../models/insurance-category.model";

export class PolicyWording {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.PolicyWording';
    }
   
    public policyDocument: any[] = [] ;
    public insuranceCategory: InsuranceCategory;
    public insuranceVehicleCategory: string []=[];
    public underwriterIdentifier: UnderwriterIdentifier;
    public name: string;
}