import { Response } from '../../../../../../../urdad/urdad_api';
import { InsuranceType } from '../../../../models/insurance-type.model';

export class ProvideInsuranceTypeResponse extends Response {
    constructor() {
        super();
    }

    insuranceTypes: InsuranceType[] = [];
}
