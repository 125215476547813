import { Duration } from './../../../../temporal/models/duration.model';
import { DurationType } from './../../models/duration-type.model';

export class YCPriceInterval {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCPriceInterval';
    }

    ycPriceIntervalId: string;
    durationType: DurationType;
    minimumPolicyValue: number;
    newPolicyValue: number;
    intervalDurationFrom: Duration;
    intervalDurationTo: Duration;
    proRata: boolean;
}