import {Response} from '../../../../../../../urdad/services/models/response.model';
import { InsurancePolicy } from '../../../../../models/insurance-policy.model';


export class ProvideInsurancePoliciesResponse extends Response{

    constructor(){
        super();
    }
    
    public resultsFrom: number;
    public resultsTo : number;
    public totalNumberOfResults : number;
    public insurancePolicies: InsurancePolicy [] = [];
}
