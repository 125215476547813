import {MatDialog} from '@angular/material/dialog'

export enum AccountUpdateType {
    NONE,
    All,
    SERVICE_GROUPS,
    BRANCH,
    DONE,
    ERROR
}

export interface AccountUpdateEvent {
    updateType: AccountUpdateType,
    dialog?: MatDialog
}
