import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog"
import {Money}from "@magnabc/tpi"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ToastrService} from "ngx-toastr"

@Component({
    selector: 'app-summary-dialog',
    templateUrl: './summary-dialog.component.html',
    styleUrls: ['./summary-dialog.component.scss']
})
export class SummaryDialogComponent implements OnInit {

    refundList: RefundItem[];
    refundTotal = new Money();
    refundLevyTotal = new Money();
    hasRefund: boolean;
    policyNumber: string;
    underwriterName: string;

    constructor(public dialogRef: MatDialogRef<SummaryDialogComponent>, @Inject(MAT_DIALOG_DATA) summaryDialogData: SummaryDialogData,
                private translate: TranslateService,
                private translateParser: TranslateParser,
                private toastr: ToastrService)
    {
        this.refundList = summaryDialogData.refundList;
        this.hasRefund = summaryDialogData.refundPaid;
        this.policyNumber = summaryDialogData.policyNumber
        this.underwriterName = summaryDialogData.underwriterName
    }

    ngOnInit(): void {
        this.refundTotal.amount = 0;
        this.refundTotal.currency = this.refundList.at(0).refundAmount.currency;
        this.refundLevyTotal.amount = 0;
        this.refundLevyTotal.currency = this.refundList.at(0).refundAmount.currency;

        for(var refundSummary of this.refundList){
            this.refundLevyTotal.amount += refundSummary.levy.amount;
            this.refundTotal.amount += refundSummary.refundAmount.amount;
        }
        this.refundTotal.amount += this.refundLevyTotal.amount;
    }

    onClose(closingState: boolean): void {
        this.dialogRef.close(closingState);
    }
}

export interface SummaryDialogData {
    refundList: RefundItem[],
    refundPaid: boolean,
    policyNumber: string,
    underwriterName: string
}

export interface RefundItem {
    refundAmount: Money,
    refundVehiclePlate: string,
    levy: Money,
    isExtension: boolean
}
