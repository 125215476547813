import {VehicleIdentifier} from '../../../../vehicle/identification/models/vehicle-identifier.model';
import {Money} from '../../../../financial/models/money.model';

export class PricePerVehicle {

    constructor(){}

    public vehicleIdentifier: VehicleIdentifier
    public price: Money

}
