import {Request} from '../../../../../../urdad/services/models/request.model';
import {RetailProfile} from "../../../../../profile/models/retail-profile.model";
import {VehicleIdentifier} from '../../../../../../vehicle/identification/models/vehicle-identifier.model';

export class ValidateCoveragePeriodRequest extends Request {
    constructor(){
        super();
    } 

    public retailProfile: RetailProfile;
    public vehicleIdentifier: VehicleIdentifier;
}
