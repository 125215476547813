import { NoWhiteSpaceDirective } from '../../shared/directives/no-white-space.directive';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {Account, FinaliseMaintainUserAccountRequest} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AccountUpdateEvent, AccountUpdateType } from '../user-update/user-update.model';
import { FormComponent } from '../../shared/form/form.component';
import {UserAccountFinalisationManagerService} from "../../../http.services/security/account/user-account-finalisation-manager/user-account-finalisation-manager.service";

@Component({
    selector: 'app-account-branch',
    templateUrl: './account-branch.component.html',
    styleUrls: ['./account-branch.component.scss']
})
export class AccountBranchComponent extends FormComponent implements OnInit, OnDestroy {
    @Input() branchCode: UntypedFormControl;
    @Input() formGroup: UntypedFormGroup;
    @Input() $submit: BehaviorSubject<AccountUpdateEvent> = null;
    @Input() $userAccount: BehaviorSubject<Account>;
    branch: string;
    subscriptions = new Subscription();

    constructor(
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private userAccountFinalisationManagerService: UserAccountFinalisationManagerService) {
        super(translate, translateParser);
    }

    ngOnInit(): void {
        if (this.formGroup) {
            this.branchCode = new UntypedFormControl(this.branch, [
                Validators.required,
                Validators.maxLength(50)
            ])
        } else {
            this.branchCode = new UntypedFormControl(this.branch, [
                Validators.required,
                Validators.maxLength(50),
                NoWhiteSpaceDirective.noWhiteSpaceValidator
            ]);

            this.formGroup = new UntypedFormGroup({
                branchCode: this.branchCode
            });
        }

        if (this.$userAccount) {
            this.subscriptions.add(this.$userAccount.subscribe((data) => {
                if (data) {
                    this.branchCode.setValue(data.branch);
                }
            }));
        }

        this.onCheckUserValidation();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onCheckUserValidation() {
        if (this.$submit !== null) {
            const subscription = this.$submit.subscribe((event) => {
                if (event.updateType === AccountUpdateType.BRANCH || event.updateType === AccountUpdateType.All) {
                    this.formGroup.markAllAsTouched();
                    if (this.formGroup.valid) {
                        this.updateAccount();
                    } else {
                        this.$submit.next({
                            updateType: AccountUpdateType.ERROR
                        });
                    }
                }
            });
            this.subscriptions.add(subscription);
        }
    }

    updateAccount() {
        const finaliseMaintainUserAccountRequest = new FinaliseMaintainUserAccountRequest();
        const userAccount = this.$userAccount.value;
        userAccount.branch = this.branchCode.value;
        finaliseMaintainUserAccountRequest.account = userAccount;
        const subscription = this.userAccountFinalisationManagerService
            .finaliseMaintainUserAccount(finaliseMaintainUserAccountRequest)
            .subscribe((response) => {
                this.$submit.next({
                    updateType: AccountUpdateType.DONE
                });
            });
        this.subscriptions.add(subscription);
    }
}
