import {Request} from "../../../../../../urdad/services/models/request.model";
import { IdentifierType } from "../../../../../models/identifier-type.model";

export class GenerateIdentifierRequest extends Request{
    constructor(){
        super();
    }

   public type : IdentifierType;
   public quantity: number;

}
