import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MarshallerService, UpdatePublicPasswordRequest,} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class PublicPasswordUpdateManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  updatePublicPassword(updatePasswordRequest:UpdatePublicPasswordRequest){
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/publicpasswordupdatemanager/updatepassword';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(updatePasswordRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();

  }
}
