import { Location } from '@angular/common';
import { InsuranceTypeManagerService } from './../../../../../app.services/managers/insurance-type-manager/insurance-type-manager.service';
import { TranslateParser } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { FormComponent } from './../../../../../view.components/shared/form/form.component';
import { InsuranceTypeCardModel } from './../../../../../view.components/retail/insurance/insurance-type-selector/insurance-type-selector/insurance-type-card/insurance-type-card/insurance-type-card.component';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-insurance-type-selector-page',
    templateUrl: './insurance-type-selector-page.component.html',
    styleUrls: ['./insurance-type-selector-page.component.scss']
})
export class InsuranceTypeSelectorPageComponent extends FormComponent implements OnInit {
    cards: InsuranceTypeCardModel[] = [];
    constructor(public translate: TranslateService,
        public translateParser: TranslateParser,
        private insuranceTypeManager: InsuranceTypeManagerService,
        private location: Location) {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.getInsuranceTypes();
    }

    getInsuranceTypes(): void {
        let results: InsuranceTypeCardModel[] = [];
        this.insuranceTypeManager.getAllInsuranceTypes().then((response) => {
            response.forEach((insuranceType) => {
                const found = response.filter(x => x.insuranceCategory === insuranceType.insuranceCategory);
                let description: string;
                if (found.length === 2) {
                    description = found.map(ins => ins.description).join(' or ');
                } else if (found.length > 2) {
                    const mapped = found.map(ins => ins.description);
                    description = `${mapped.splice(mapped.length - 1).join(',')} or ${mapped.at(mapped.length - 1)}`;
                } else {
                    description = found.map(ins => ins.description).at(0);
                }

                let match = results.filter(x => x.title == insuranceType.insuranceCategory).length > 0;
                if (!match) {
                    results.push({
                        title: insuranceType.insuranceCategory,
                        hint: `${description} insurance.`
                    });
                }
            })
            this.cards = Array.from(new Set(results));
            this.sort();
        })
    }

    onBack() {
        this.location.back();
    }

    private sort(): void {
        this.cards.sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        });
    }
}
