import {Request} from "../../urdad/services/models/request.model";

export class RetrieveExchangeRateRequest extends Request {
    constructor(){
        super();
    }

    date: Date;
    baseCurrency: string;
    quoteCurrency: string;
}