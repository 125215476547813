import { PriceSchedule } from '../../models/price-schedule.model';
import { MotorInsuranceLimitDetails } from '../../../checkout/models/motor-insurance-limit-details.model';
import { OrderProduct } from "../../../checkout/models/order-product.model";
import { BrokerIdentifier } from "../../identification/models/broker-identifier.model";
import { Excess } from "../../models/excess.model";
import { CountryIdentifier } from "../../../../location/geographic/identification/models/country-identifier.model";
import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";
import { Money } from "../../../../financial/models/money.model";

export class OrderComprehensiveInsuranceProduct extends OrderProduct {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.OrderComprehensiveInsuranceProduct";
    }

    public insuranceVehicleCategoryDescription: string;
    public brokerIdentifier: BrokerIdentifier;
    public conditions: string;
    public excess: Excess[] = [];
    public coveredCountries: CountryIdentifier[];
    public underwriterIdentifier: UnderwriterIdentifier;
    public additionalPrice: Money;
    public annualPricePercentage: number;
    public alarmImmobiliser: boolean;
    public insuranceVehicleCategorySubDescription: string;
    public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[];
    public priceSchedule: PriceSchedule;
}
