import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, ValidateOneTimePinRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class OneTimePinValidationManagerProviderService {

    basePath = '/security/onetimepin/onetimepinvalidationmanager';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    validateOneTimePin(validateOneTimePinRequest: ValidateOneTimePinRequest) {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const validateOneTimePinUri = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/validateonetimepin';

        const jsonValidateOneTimePinRequest = this.marshallerService.marshallObjectToJSON(validateOneTimePinRequest);

        return this.http.post<any>(validateOneTimePinUri, jsonValidateOneTimePinRequest, options).toPromise();
    }

}
