import {Injectable} from '@angular/core';
import {SupportedCountriesProviderService} from '../../../http.services/retail/setup/supported-countries-provider/supported-countries-provider.service';
import {
    AllSupportedCountriesCriteria,
    ContactNumber,
    CountryIdentificationConfiguration,
    CountryIdentificationConfigurationCriteria,
    CountryIdentifier, Criteria,
    IssueOneTimePinRequest, IssueOneTimePinResponse,
    OneTimePinContext, OneTimePinIdentifier, PinContext,
    ProvideCountryIdentificationConfigurationRequest,
    ProvideCountryIdentificationConfigurationResponse,
    ProvideSupportedCountriesRequest,
    ProvideSupportedCountriesResponse,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    SupportedCountry,
    UnMarshallerService, ValidateOneTimePinRequest, ValidateOneTimePinResponse
} from '@magnabc/tpi';
import {InsuranceConfigurationProviderService} from '../../../http.services/retail/insurance/setup/insurance-configuration-provider/insurance-configuration-provider.service';
import {QueryLegalEntityInformationManagerService} from '../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service';
import {OneTimePinIssuanceManagerService} from '../../../http.services/security/onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service';
import {OneTimePinValidationManagerProviderService} from '../../../http.services/security/onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';

@Injectable({
    providedIn: 'root'
})
export class SecuredLegalEntityService {

    constructor(private supportedCountriesProviderService: SupportedCountriesProviderService,
                private insuranceConfigurationProviderService: InsuranceConfigurationProviderService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
                private oneTimePinValidationManagerService: OneTimePinValidationManagerProviderService,
                private unMarshallerService: UnMarshallerService) {
    }

    private contactNumber:ContactNumber = null;
    private obscuredContactNumber: string = null;
    private obscuredContactNumberAttempts = 3;
    private oneTimePinIdentifier: OneTimePinIdentifier = null;

    getSupportedCountries() {
        const criteria = new AllSupportedCountriesCriteria();
        const request = new ProvideSupportedCountriesRequest();
        request.criteria = criteria;

        return new Promise<SupportedCountry[]>((resolve, reject) => {
            this.supportedCountriesProviderService.provideSupportedCountries(request).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSupportedCountriesResponse) as ProvideSupportedCountriesResponse);

                    resolve(response.supportedCountries);
                    return;
                }

                console.log(httpResponse);
                reject('Unable to read response.');
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not retrieve supported countries.');

                return;
            });
        });
    }

    getCountryIdentificationConfiguration(selectedCounty: CountryIdentifier) {

        const criteria = new CountryIdentificationConfigurationCriteria();
        criteria.countryIdentifier = selectedCounty;

        const request = new ProvideCountryIdentificationConfigurationRequest();
        request.criteria = criteria;

        return new Promise<CountryIdentificationConfiguration>((resolve, reject) => {
            this.insuranceConfigurationProviderService.provideCountryIdentificationConfiguration(request).then(httpResponse => {
                if (httpResponse && httpResponse.body){
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, ProvideCountryIdentificationConfigurationResponse) as ProvideCountryIdentificationConfigurationResponse);

                    resolve(response.countryIdentificationConfiguration);
                    return;
                }
                console.log(httpResponse);
                reject('Unable to read response.');
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not retrieve country insurance configuration.');

                return;
            })
        });
    }

    queryLegalEntityInformation(criteria: Criteria) {
        const request = new QueryLegalEntityInformationRequest();
        request.criteria = criteria;

        return new Promise<QueryLegalEntityInformationResponse>((resolve, reject) => {
            this.queryLegalEntityInformationManagerService.provideLegalEntity(request).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                    if (response.legalEntities && response.legalEntities.length>0){
                        this.contactNumber = response.legalEntities[0].contactNumbers[0];
                        this.calculateObscuredContactNumber();
                    }

                    resolve(response);
                    return;
                }

                console.log(httpResponse);
                reject('Unable to read response.');
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not retrieve legal entity information.');

                return;
            })
        });
    }

    captureObscuredContactNumber(capturedObscuredContactNumber: string): boolean {
        if (this.obscuredContactNumberAttempts > 0){
            if ((this.obscuredContactNumber + capturedObscuredContactNumber) === this.contactNumber.number) {
                return true;
            }
            this.obscuredContactNumberAttempts--;
            return false;
        } else {
            return false;
        }
    }

    issueOneTimePin(contactNumber: ContactNumber): Promise<boolean> {
        const context = new OneTimePinContext();
        context.context = PinContext.LOGIN;

        const issueOneTimePinRequest = new IssueOneTimePinRequest();
        issueOneTimePinRequest.contactNumber = contactNumber;
        issueOneTimePinRequest.oneTimePinContext = context;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinIssuanceManagerService.issueOneTimePin(issueOneTimePinRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);
                    this.oneTimePinIdentifier = response.oneTimePinIdentifier;
                    resolve(true);
                    return;
                }

                resolve(false);
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not issue one time pin.');

                return;
            });
        });
    }

    validateOneTimePin(capturedOneTimePin: string) {
        const validateOneTimePinRequest = new ValidateOneTimePinRequest();
        validateOneTimePinRequest.oneTimePinIdentifier = this.oneTimePinIdentifier;
        validateOneTimePinRequest.capturedOneTimePin = capturedOneTimePin;

        return new Promise((resolve, reject) => {
            if (!this.oneTimePinIdentifier) {
                reject('Seems like no OTP was issued.');
                return;
            }

            this.oneTimePinValidationManagerService.validateOneTimePin(validateOneTimePinRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinResponse) as ValidateOneTimePinResponse);

                    resolve(response.valid);
                    return;
                }

                resolve(false);
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not validate one time pin.');

                return;
            });
        });
    }

    private calculateObscuredContactNumber() {
        if (this.contactNumber) {
            this.obscuredContactNumberAttempts = 3;
            this.obscuredContactNumber = this.contactNumber.number.substr(0, this.contactNumber.number.length-4);
        }
    }
}
