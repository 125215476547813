import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MalawiCountryCheckoutManagerComponent} from './retail/checkout/malawi-country-checkout-manager/malawi-country-checkout-manager.component';
import {EntityModule} from "../entity/entity.module";
import {TranslateModule} from "@ngx-translate/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [MalawiCountryCheckoutManagerComponent],
    exports: [MalawiCountryCheckoutManagerComponent],
    imports: [
        CommonModule,
        EntityModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule
    ]
})
export class MalawiModule { }
