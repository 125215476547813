import {LegalEntityIdentifier} from '../../../../entity/identification/models/legal-entity-identifier.model'
import {CountryRegistrationConfigurationInformation} from './country-registration-configuration-information.model'

export class NaturalPersonCountryRegistrationConfigurationInformation extends CountryRegistrationConfigurationInformation{
    constructor() {
        super()
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.NaturalPersonCountryRegistrationConfigurationInformation';
    }

    public proofOfIdentification: string
    public legalEntityIdentifier: LegalEntityIdentifier

}
