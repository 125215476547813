import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss']
})
export class UserManagementPageComponent implements OnInit {

  constructor(private router: Router,
              private title: Title,
              private runtimeConfigurationService: RuntimeConfigurationService) {
      title.setTitle(`Manage Users - Console - ${this.runtimeConfigurationService.title}`);
  }

  ngOnInit() {
  }

  onNewUser(event){
    this.router.navigate(['/manage/create-user']);
  }

}
