import {Component} from "@angular/core"
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    templateUrl: './vehicle-make-page.component.html',
    styleUrls: ['./vehicle-make-page.component.scss']
})
export class VehicleMakePageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Vehicle Makes - Console - ${this.runtimeConfigurationService.title}`);
    }

}
