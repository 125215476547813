import {Request} from "../../../../../../urdad/services/models/request.model";
import {LegalEntityIdentifier} from "../../../../../identification/models/legal-entity-identifier.model";

export class ProvideLegalEntityInformationRequest extends Request {

  constructor(){
    super();
  }

  public legalEntityIdentifiers: LegalEntityIdentifier[] = [];
}
