import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {CreateOrderRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class OrderManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {
  }

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  createOrder(createOrderRequest: CreateOrderRequest) {
      const URI = this.runtimeConfigurationService.apiBaseUrl + '/retail/checkout/ordermanager/createorder';

      const jsonRequest = this.marshallerService.marshallObjectToJSON(createOrderRequest);

      return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }
}
