import { Request } from "../../../../../urdad/urdad_api";
import { Criteria } from "../../../../../criteria/criteria_api";

export class ProvideConceptualDocumentRequest extends Request {

  constructor() {
    super();
  }

  public criteria: Criteria;
}
