import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../../environments/environment";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {JudicialPersonComponent} from "../../../../view.components/entity/judicial-person/judicial-person.component";
import {QueryJudicialPersonComponent} from "../../../../view.components/entity/query-judicial-person/query-judicial-person.component";
import {Location, LocationStrategy} from "@angular/common";
import {OTPVerifyComponent} from "../../../../view.components/security/opt-verify/opt-verify.component";
import {OTPComponent} from "../../../../view.components/security/otp.component";
import {IStepComponent} from "../../../../view.components/shared/guards/step-guard";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {
    AllCountryCriteria,
    ContactNumber,
    Country,
    DriverTrafficRegisterNumber,
    FinaliseCreatePrincipalByUpdateRequest,
    JudicialPerson,
    LegalEntityProviderCriteria,
    ProvideCountryInformationRequest,
    ProvideLegalEntityInformationRequest,
    ProvideLegalEntityInformationResponse,
    RetailProfile,
    PinContext,
    CountryDescriptionIdentifier,
    UnMarshallerService,
    TrafficRegisterNumber,
    RegistrationNumber,
    OneTimePinStringIdentifier,
    ValidateOneTimePinRequest, ValidateOneTimePinResponse, SubscriptionOwner, IssueOneTimePinRequest, IssueOneTimePinResponse, OneTimePinContext, ContactPerson
} from "@magnabc/tpi";
import {LegalEntityComponent} from '../../../../view.components/entity/legal-entity/legal-entity.component';
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service';
import {PrincipalFinalisationManagerService} from '../../../../http.services/security/authorisation/principal-finalisation-manager/principal-finalisation-manager.service';
import {OneTimePinVerificationManagerService} from '../../../../http.services/security/onetimepin/one-time-pin-verification-manager/one-time-pin-verification-manager.service';
import {PublicLegalEntityInformationProviderService} from '../../../../http.services/entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service';
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun';
import {QuickTransactManagerService} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Dropdown} from "../../../../common/model/dropdown.model";
import {VerifiedAccountManagerService} from "../../../../http.services/security/account/verified-account-manager/verified-account-manager.service";
import {SecuredLegalEntityService} from '../../../../app.services/managers/secured-legal-entity/secured-legal-entity.service';
import {OneTimePinValidationManagerProviderService} from '../../../../http.services/security/onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service';
import {OneTimePinService} from '../../../../view.components/security/services/one-time-pin.service';
import { RetailProfileInformationProviderService } from '../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service';
import { RetailProfileFinalisationManagerService } from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import {Subscription as RxJsSubscription} from "rxjs/internal/Subscription";
import { OneTimePinIssuanceManagerService } from '../../../../http.services/security/onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
declare const jQuery: any;

@Component({
    selector: 'app-create-principal',
    templateUrl: './principal-manager-page.component.html',
    styleUrls: ['./principal-manager-page.component.scss']
})
export class PrincipalManagerPageComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy, IStepComponent {

    backPressed: boolean;
    skipDeactivate: boolean;
    steps: any = [];
    currentStep = 0;
    utils = Utils;

    duplicateCompanyName = false;
    entityInsertError = false;
    companyAlreadyAdded = false;

    judicialPerson: JudicialPerson;
    contactNumber: ContactNumber;
    selectedCountry: Country;

    retailProfile = new RetailProfile();

    @ViewChild(QueryJudicialPersonComponent) entityQueryComponent: QueryJudicialPersonComponent;
    @ViewChild(JudicialPersonComponent) judicialPersonComponent: JudicialPersonComponent;
    @ViewChild(LegalEntityComponent) legalEntityComponent: LegalEntityComponent;
    @ViewChild(OTPComponent) otpComponent: OTPComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;

    pinContext = PinContext;
    activeToast: ActiveToast<any>;
    exists = false;

    quickTransact = false;
    changeMobile = false;

    registrationNumber: RegistrationNumber;
    provideExternalLegalEntityFormGroup: UntypedFormGroup;
    identifierType: UntypedFormControl;
    identifierTypePlaceholder: string;
    externalIdentifierType: UntypedFormControl;
    externalIdentifierTypes = [new Dropdown(1, "Traffic Register Number")];
    country: Country;
    countries: Country[] = null;
    externalCountry: Country;
    externalCountries: Country[] = [];
    externalIdentifierTypePlaceholder: string;
    externalIdentifier: UntypedFormControl;
    errorMessage = null;
    capturedExternalIdentifier: DriverTrafficRegisterNumber;
    proxies: SubscriptionOwner[] = [];
    judicialList: SubscriptionOwner[] = [];
    proxySubscriber: RxJsSubscription;
    issuedOTPIdentifier: any;
    oneTimePinStringIdentifier: any;
    resend:boolean = false;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private router: Router,
                private toastr: ErrorToastService,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private location: Location,
                private route: ActivatedRoute,
                private principalFinalisationManagerService: PrincipalFinalisationManagerService,
                private authenticationService: AuthenticationService,
                private quickStartService: QuickTransactManagerService, locationStrategy: LocationStrategy,
                private unMarshallerService: UnMarshallerService,
                private legalEntityInformationProvider: PublicLegalEntityInformationProviderService,
                private oneTimePinVerificationManagerService: OneTimePinVerificationManagerService,
                private securedLegalEntityInformationService: SecuredLegalEntityService,
                private oneTimePinValidationManagerProviderService: OneTimePinValidationManagerProviderService,
                private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
                private oneTimePinService : OneTimePinService,
                public createVerifiedAccountService: VerifiedAccountManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {

        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.steps = [];

        setTimeout(() => {
            this.judicialPerson = new JudicialPerson();
            this.setSteps();
        });

        this.identifierTypePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
        this.externalIdentifierTypePlaceholder = "ENTITY.LABEL_TRN_NUMBER";

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed && !this.skipDeactivate) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    setSteps(): void {

        this.steps = [];

        if (!this.exists) {

            this.translate.get("ENTITY.TITLE_QUERY_JUDICIAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-check", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_COMPANY_DETAILS").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-building", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-phone", borderColor: "#3171c3"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 4, name: "One Time Pin", icon: "fa-mobile", borderColor: "#3e67b5"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 5, name: "Verification", icon: "fa-check", borderColor: "#3f5baa", hidden: true});
            });

        } else {

            this.translate.get("ENTITY.TITLE_QUERY_JUDICIAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 1, name: "Verification", icon: "fa-check", borderColor: "#3f5baa", hidden: true});
            });

            this.translate.get("ENTITY.TITLE_COMPANY_DETAILS").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-building", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-phone", borderColor: "#3171c3"});
            });

        }

        this.setTitle();

    }

    ngOnInit(): void {

        this.judicialPerson = new JudicialPerson();
        this.quickStartService.legalEntity=null;

        this.route.queryParams.forEach((params) => {
            this.quickTransact = params.quickTransact;
        });

        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

    }

    loadProxies() {
        this.proxies = [];
        this.judicialList = [];
        for (const key of this.authenticationService.getLoginSubscriptions().keys()) {
            this.proxies.push(this.authenticationService.getLoginSubscriptions().get(key));
        }

        for (const key of this.authenticationService.getJudicialPersonSubscriptions().keys()) {
            this.proxies.push(this.authenticationService.getJudicialPersonSubscriptions().get(key));
            this.judicialList.push(this.authenticationService.getJudicialPersonSubscriptions().get(key));
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');

        if (this.proxySubscriber) {
            this.proxySubscriber.unsubscribe();
            this.proxySubscriber = null;
        }
    }

    setTitle() {
        let title = `${this.steps[this.currentStep].name} - Add Company - ${this.runtimeConfigurationService.title}`;
        if (this.currentStep === 0) {
            title = `Add Company - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }


    onSubmit(event): void {
        if (this.entityQueryComponent) {
            this.entityQueryComponent.submit();
        } else if (this.judicialPersonComponent) {
            this.judicialPersonComponent.submit();
        } else if (this.legalEntityComponent) {
            this.legalEntityComponent.submit();
        } else if (this.otpComponent) {
            this.issueOTP(this.otpComponent.contactNumber.contactNumber);
        } else if (this.otpVerifyComponent) {
            this.otpVerifyComponent.submit();
        } else if (this.currentStep === 1) {
            this.provideLegalEntityInformation();
        }
    }

    onExists(exists): void {
        this.exists = exists;
        if (!exists) {
            this.setSteps();
            this.onNext(null);
        }
    }

    onCompanyAlreadyAdded(companyAlreadyAdded): void {
        this.companyAlreadyAdded = companyAlreadyAdded;
    }

    onBack(event): void {
        if (this.currentStep > 0) {
            if (this.selectedCountry.alphaTwo !== 'MW') {
                if (this.currentStep === 2) {
                    this.currentStep = 0;
                } else {
                    this.currentStep--;
                }
            } else {
                this.currentStep--;
            }
            window.scrollTo(0, 0);
            this.setTitle();
        } else {
            this.skipDeactivate = true;
            this.location.back();
        }
    }

    onNext(event): void {

        if (!this.companyAlreadyAdded) {
            this.currentStep++;
            if (this.selectedCountry.alphaTwo === 'MW') {

                this.externalCountries = [];
                this.externalCountries.push(this.selectedCountry);

                this.externalIdentifierType = new UntypedFormControl(this.externalIdentifierTypes[0], []);
                this.externalIdentifier = new UntypedFormControl('', [
                    Validators.required,
                    Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
                ]);
                this.provideExternalLegalEntityFormGroup = new UntypedFormGroup({
                    externalIdentifierType: this.externalIdentifierType,
                    externalIdentifier: this.externalIdentifier,
                });
            } else if (this.currentStep === 1) {
                this.currentStep = 2;
            }
        }

        if (this.exists && this.currentStep === 1) {
            this.onValidContactNumber(this.contactNumber);
        }

        if (this.exists && this.currentStep === 4) {
            this.onUpdateLegalEntity(this.judicialPerson);
        }

        window.scrollTo(0, 0);
        this.setTitle();

    }


    onLegalEntity(judicialPerson): void {

        this.judicialPerson = judicialPerson;
        if (this.judicialPerson.contactPersons[0] && this.judicialPerson.contactPersons[0].contactNumbers[0]) {
            this.contactNumber = this.judicialPerson.contactPersons[0].contactNumbers[0];
        } else {
          this.judicialPerson.contactPersons = []
          this.judicialPerson.contactNumbers = []

          const contactPerson = new ContactPerson()
          const contactNumber = new ContactNumber();
          contactNumber.country = this.selectedCountry.dialingCode
          contactNumber.number = ''
          contactNumber.contactNumberType = 'Cell'
          contactPerson.contactNumbers.push(contactNumber)
          this.judicialPerson.contactPersons.push(contactPerson)
          this.judicialPerson.contactNumbers.push(contactNumber)
        }
    }

    onSelectedCountry(country): void {
        this.selectedCountry = country;
    }


    onUpdateLegalEntity(judicialPerson): void {
        this.judicialPerson = judicialPerson;

        const finaliseCreatePrincipalByUpdateRequest = new FinaliseCreatePrincipalByUpdateRequest();
        finaliseCreatePrincipalByUpdateRequest.ownedBy = this.judicialPerson;
        finaliseCreatePrincipalByUpdateRequest.usedBy = this.utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        this.startLoad();
        this.principalFinalisationManagerService.finaliseCreatePrincipalByUpdate(finaliseCreatePrincipalByUpdateRequest).then(httpResponse => {
            this.onPrincipalCreated();
        }).catch(error => {
            this.stopLoad();
            RaygunErrorHandler.sendError(error);

            if (error.status === 403) {
                this.duplicateCompanyName = true;
            } else {
                this.entityInsertError = true;
            }
        });
    }

    onValidContactNumber(contact): void {
        const contactNumber = new ContactNumber();
        contactNumber.number = contact.number;
        contactNumber.contactNumberType = contact.contactNumberType;
        contactNumber.country = contact.country;

        //this.resend=true;
        this.issueOTP(contactNumber);
    }

    onVerifyContact(contactNumber: string): void {
        this.contactNumber.number = contactNumber;
        this.onNext(null);
    }

    issueOTP(contactNumber: ContactNumber) {
        const context = new OneTimePinContext();
        context.context = PinContext.VERIFY_ACCOUNT;

        const issueRequest = new IssueOneTimePinRequest();
        issueRequest.oneTimePinContext = context;
        issueRequest.contactNumber = contactNumber;

        this.startLoad();
        return new Promise<any>((resolve, reject) => {
            this.oneTimePinIssuanceManagerService.issueOneTimePin(issueRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);
                    this.issuedOTPIdentifier = response.oneTimePinIdentifier;
                    if(!this.resend)
                    {
                        if(!this.exists)
                        {
                            this.currentStep++;
                        }
                    }
                    this.stopLoad();
                    resolve({successful: true});
                    return;
                }
                resolve({successful: false, error: 'Failed to send One Time Pin'});
                return;
            }).catch(error => {
                console.error('Error while issuing OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                resolve({successful: false, error: error.error});
                return;
            });
        });
    }


    onValidateOTP(event, next = false) {
        this.startLoad();
        this.validateOTP(event).then(response => {
            if (response !== undefined) {
                if (!response){
                    this.otpVerifyComponent.otpInvalid();
                    this.stopLoad();
                } else {

                    if(!this.exists)
                    {
                        this.currentStep++;
                    }
                    this.stopLoad();


                    if (next) {
                        this.onNext(event)
                    } else {
                        this.submitJudicialPerson(event)
                    }
                }
            }
        });
    }

    validateOTP(capturedOTP: string) {
        const oneTimePinIdentifier = new OneTimePinStringIdentifier();
        oneTimePinIdentifier.identifier = this.issuedOTPIdentifier.identifier;
        const validateOTPRequest = new ValidateOneTimePinRequest();
        validateOTPRequest.oneTimePinIdentifier = oneTimePinIdentifier;
        validateOTPRequest.capturedOneTimePin = capturedOTP;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinValidationManagerProviderService.validateOneTimePin(validateOTPRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinRequest) as ValidateOneTimePinResponse);
                    resolve(response.valid);
                    return;
                }
            }).catch(error => {
                console.error('Error while validating OTP', error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return;
            });
        });
    }

    submitJudicialPerson(event): void {

        this.duplicateCompanyName = false;
        this.entityInsertError = false;
        const finaliseCreatePrincipalByUpdateRequest = new FinaliseCreatePrincipalByUpdateRequest();
        const legalEntity = this.judicialPerson;
        if (!legalEntity.contactPersons[0].emailAddresses[0] || !legalEntity.contactPersons[0].emailAddresses[0].address) {
            legalEntity.contactPersons[0].emailAddresses = [];
        }
        legalEntity.contactPersons[0]['@class'] = 'za.co.magnabc.tpi.entity.ContactPerson';

        finaliseCreatePrincipalByUpdateRequest.ownedBy = legalEntity;
        finaliseCreatePrincipalByUpdateRequest.usedBy = this.utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());
        this.startLoad();
        this.principalFinalisationManagerService.finaliseCreatePrincipalByInsert(finaliseCreatePrincipalByUpdateRequest).then(httpResponse => {
            this.onPrincipalCreated();
            this.stopLoad();
        }).catch(error => {
            this.stopLoad();
            RaygunErrorHandler.sendError(error);

            if (error.status === 403) {
                this.duplicateCompanyName = true;
            } else {
                this.entityInsertError = true;
            }
        });
    }

    onPrincipalCreated() {
        this.authenticationService.loadEntitySubscriptions().then(() => {
            this.authenticationService.reloadProxyInformation();

            if (this.quickTransact) {
                this.authenticationService.refreshPublicAuthentication();
                this.proxySubscriber = this.authenticationService.proxiesUpdated.subscribe(val => {
                    if (val) {
                        this.loadProxies();
                    }

                    const provideRequest = new ProvideLegalEntityInformationRequest();
                    provideRequest.legalEntityIdentifiers.push(this.judicialPerson.legalEntityIdentifiers[0]);
                    this.legalEntityInformationProvider.providePublicLegalEntityInformation(provideRequest).then(httpResponse => {
                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);
                            this.quickStartService.selectedLegalEntity = response.legalEntities[0];
                            this.onQuickTransact();
                        }
                    });
                });

            } else {
                this.router.navigate(['/retail/profile']).then(() => {
                    this.stopLoad();
                });
            }
        });
    }

    onChangeMobile(event): void {
        this.changeMobile = true;
    }

    onMobileComplete(event): void {
        this.judicialPerson = event;
        this.changeMobile = false;
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    toast(response, subscription) {

        this.translate.get('TOAST.CREATE_RETAIL_PROFILE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY: {
                        if (this.quickTransact) {
                            this.onQuickTransact();
                        } else {
                            this.authenticationService.setActiveProxySubscription([subscription])
                            this.router.navigate(['/retail/profile']);
                        }
                        this.activeToast.portal.destroy();
                        break;
                    }
                    case ToastComponent.SECONDARY: {
                        break;
                    }
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                if (this.quickTransact) {
                    this.onQuickTransact();
                } else {
                    this.authenticationService.setActiveProxySubscription([subscription])
                    this.router.navigate(['/retail/profile']);
                }
            });
        });
    }

    private onQuickTransact() {
        this.quickStartService.judicial = true;
        this.quickStartService.type = 'business';
        this.quickStartService.currentStep++;
        this.skipDeactivate = true;
        if(this.quickTransact){
            this.onSwitchAccount();
        }else{
            this.location.back();
        }
        this.stopLoad();
    }

    onSwitchAccount(): void {

        for(const proxy of this.proxies){
            if(this.quickStartService.selectedLegalEntity instanceof JudicialPerson){
                if(proxy.name === this.quickStartService.selectedLegalEntity.name){
                    this.authenticationService.setActiveProxySubscription(proxy.subscriptions);
                    this.location.back();
                }
            }

        }
    }

    resendOTP() {
        this.resend=true;
        this.startLoad();
        this.issueOTP(this.contactNumber).then(otpResponse => {

            if (otpResponse === true) {
                this.otpVerifyComponent.otpResent();
            } else {
                this.errorMessage = 'Unable to resend OTP, please try again.';
            }
            this.stopLoad();
        });
    }

    onIdentifierTypeChanged(_): void {
        if (this.identifierType.value.value === 1) {
            this.identifierTypePlaceholder = "ENTITY.LABEL_TRN_NUMBER";
        }
    }

    onCaptureExternalIdentifierCountry(country): void {
        this.externalCountry = country;
    }

    checkInputClicked() {
        this.errorMessage = null;
    }

    provideLegalEntityInformation() {
        if(this.provideExternalLegalEntityFormGroup.valid)
        {
            this.registrationNumber = (this.judicialPerson.legalEntityIdentifiers[0] as RegistrationNumber)
            const trn = new TrafficRegisterNumber();
            trn.number = this.externalIdentifier.value.toLocaleUpperCase();
            trn.countryOfIssue = this.selectedCountry.description;
            const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
            provideLegalEntityInformationRequest.legalEntityIdentifiers.push(trn);


            this.legalEntityInformationProvider.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const provideLegalEntityInformationResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities && provideLegalEntityInformationResponse.legalEntities[0]) {
                        this.errorMessage = 'TRN already linked.';
                    } else {

                        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
                        const trnJP = new TrafficRegisterNumber();
                        trnJP.number = this.externalIdentifier.value.toLocaleUpperCase();
                        trnJP.countryOfIssue = this.selectedCountry.description;
                        legalEntityProviderCriteria.legalEntityIdentifier = trnJP;
                        legalEntityProviderCriteria.type = "EXTERNAL";
                        const countryIdentifier = new CountryDescriptionIdentifier();
                        countryIdentifier.description = this.selectedCountry.description;
                        legalEntityProviderCriteria.countryIdentifier = countryIdentifier;

                        this.judicialPerson = new JudicialPerson();
                        this.startLoad();
                        this.securedLegalEntityInformationService.queryLegalEntityInformation(legalEntityProviderCriteria).then(queryLegalEntityResponse =>{
                            if (queryLegalEntityResponse.legalEntities.length > 0) {

                                for (const emailAddress of queryLegalEntityResponse.legalEntities[0].emailAddresses) {
                                    if (emailAddress.address.toLowerCase() === 'n/a') {
                                        queryLegalEntityResponse.legalEntities[0].emailAddresses.splice(queryLegalEntityResponse
                                            .legalEntities[0].emailAddresses.indexOf(emailAddress), 1);
                                    }
                                }
                                if (queryLegalEntityResponse.legalEntities[0] instanceof JudicialPerson){

                                    queryLegalEntityResponse.legalEntities[0].legalEntityIdentifiers.push(this.registrationNumber);
                                    this.judicialPerson = (queryLegalEntityResponse.legalEntities[0] as JudicialPerson);

                                }
                            }else {
                                this.judicialPerson.legalEntityIdentifiers.push(this.registrationNumber);
                            }
                            this.onNext(null);
                            this.stopLoad();
                        }).catch(exception => {
                            RaygunErrorHandler.sendError(exception);
                            this.onNext(null);
                            this.stopLoad();
                        });

                    }

                }
            });

        }else{
            this.errorMessage = "Traffic Register Number is required.";
        }

    }

    skipTRNCapturing(): boolean{
        this.currentStep++;
        return false
    }

}
