import {BrokerIdentifier} from "./broker-identifier.model";

export class BrokerIdentifierIdentifier extends BrokerIdentifier {

    id: string;

    constructor(id: string) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.identification.BrokerIdentifierIdentifier";
        this.id = id;
    }
}

