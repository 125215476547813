import {Criteria} from "../../../../criteria/criteria_api";
import {UnderwriterIdentifier} from "../../insurance_api";
import {InsuranceCategory} from "../../models/insurance-category.model";

export class InsuranceProductUnderwriterCriteria extends Criteria{
    constructor(){
        super();
        this['@class']="za.co.magnabc.tpi.retail.insurance.quicktransact.InsuranceProductUnderwriterCriteria"
    }

    public underwriterIdentifiers: UnderwriterIdentifier[] = [];
    public insuranceCategory: InsuranceCategory;
    public active: boolean;
}
