import {UnderwriterIdentifier} from "./underwriter-identifier.model";

export class UnderwriterIdentifierIdentifier extends UnderwriterIdentifier {

    id: string;

    constructor(id: string) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.identification.UnderwriterIdentifierIdentifier";
        this.id = id;
    }
}

