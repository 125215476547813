import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {Money} from '../../../../../../../../financial/models/money.model';
import {PricePerVehicle} from '../../../../../models/price-per-vehicle.model';

export class CalculateRealTimePriceResponse extends Response{

    constructor(){
        super();
    }

    public pricePerVehicle: PricePerVehicle[] = [];
    public totalPrice: Money;
    public insurancePolicyFound: boolean;
}
