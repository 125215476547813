export class ServiceList {

    //Administrator Type Linked Services
    static PROVIDESERVICEGROUPS = 'PROVIDESERVICEGROUPS';
    static PROVIDESERVICEIDENTIFIERS = 'PROVIDESERVICEIDENTIFIERS';
    static CREATESERVICEGROUP = 'CREATESERVICEGROUP';
    static UPDATESERVICEGROUP = 'UPDATESERVICEGROUP';
    static PROVIDEVEHICLEMAKES = 'PROVIDEVEHICLEMAKES';
    static CREATEVEHICLEMAKE = 'CREATEVEHICLEMAKE';
    static UPDATEVEHICLEMAKE = 'UPDATEVEHICLEMAKE';
    static PROVIDEVEHICLEMODELS = 'PROVIDEVEHICLEMODELS';
    static CREATEVEHICLEMODEL = 'CREATEVEHICLEMODEL';
    static UPDATEVEHICLEMODEL = 'UPDATEVEHICLEMODEL';
    // static IMPORTOFX = 'IMPORTOFX';
    static PROVIDEACCOUNTINFORMATION = 'PROVIDEACCOUNTINFORMATION';
    static PROVIDEACCOUNTINGTRANSACTIONINFORMATION = 'PROVIDEACCOUNTINGTRANSACTIONINFORMATION';
    static PROVIDEACCOUNTSNAPSHOTINFORMATION = 'PROVIDEACCOUNTSNAPSHOTINFORMATION';
    static PROVIDEPAYOUTMETHOD = 'PROVIDEPAYOUTMETHOD';
    static AUTHENTICATECREDENTIALS = 'AUTHENTICATECREDENTIALS';

    //Agent Type Linked Services
    static ASSISTEDQUICKTRANSACT = 'ASSISTEDQUICKTRANSACT';
    static PROVIDEINSURANCEPOLICIES = 'PROVIDEINSURANCEPOLICIES';
    static ISSUEINSURANCECERTIFICATE = 'ISSUEINSURANCECERTIFICATE';
    static PROVIDEORDERINFORMATION = 'PROVIDEORDERINFORMATION';
    static RECEIVECASHIERPAYMENT = 'RECEIVECASHIERPAYMENT';

    //Retail Type Linked Services
    static PROVIDEROLEREVENUE = 'PROVIDEROLEREVENUE';
    static PROVIDEPOPULARPRODUCTS = 'PROVIDEPOPULARPRODUCTS';

    //Authority Type Linked Services
    static QUERYINSURANCEPOLICYINFORMATION = 'QUERYINSURANCEPOLICYINFORMATION';

    //Broker Type Linked Services
    static CREATETHIRDPARTYINSURANCEPRODUCT = 'CREATETHIRDPARTYINSURANCEPRODUCT';
    static UPDATETHIRDPARTYINSURANCEPRODUCT = 'UPDATETHIRDPARTYINSURANCEPRODUCT';
    static CREATEYELLOWCARDINSURANCEPRODUCT = 'CREATEYELLOWCARDINSURANCEPRODUCT';
    static UPDATEYELLOWCARDINSURANCEPRODUCT = 'UPDATEYELLOWCARDINSURANCEPRODUCT';

    //Public Type Linked Services
    static CREATEPRINCIPAL = 'CREATEPRINCIPAL';
    static PROVIDESUBSCRIPTIONSSUMMARY = 'PROVIDESUBSCRIPTIONSSUMMARY';
    static PROVIDESUBSCRIPTIONS = 'PROVIDESUBSCRIPTIONS';
    static UPDATESUBSCRIPTION = 'UPDATESUBSCRIPTION';
    static CREATESUBSCRIPTION = 'CREATESUBSCRIPTION';
    static PROVIDERETAILPROFILEVEHICLEINFORMATION = 'PROVIDERETAILPROFILEVEHICLEINFORMATION';
    static LINKVEHICLE = 'LINKVEHICLE';
    static UPDATERETAILPROFILE = 'UPDATERETAILPROFILE';
    static MAINTAINVEHICLEINFORMATION = 'MAINTAINVEHICLEINFORMATION';
    static PROVIDECOLOURINFORMATION = 'PROVIDECOLOURINFORMATION';
    static PROVIDECOUNTRYINFORMATION = 'PROVIDECOUNTRYINFORMATION';
    static PROVIDERETAILPROFILEINFORMATION = 'PROVIDERETAILPROFILEINFORMATION';
    static PROVIDEPUBLICINSURANCEPOLICYINFORMATION = 'PROVIDEPUBLICINSURANCEPOLICYINFORMATION';
    static PROVIDECOUNTRYUNDERWRITERPRODUCTINFORMATION = 'PROVIDECOUNTRYUNDERWRITERPRODUCTINFORMATION';
    static PROVIDEAGENTBROKERSPRODUCTS = 'PROVIDEAGENTBROKERSPRODUCTS'
    static CHECKOUTORDER = 'CHECKOUTORDER';
    static REMOVEITEM = 'REMOVEITEM';
    static CLEARCART = 'CLEARCART';
    static CANCELORDER = 'CANCELORDER';
    static PROVIDEORDERSUMMARY = 'PROVIDEORDERSUMMARY';
    static PROVIDEORDER = 'PROVIDEORDER';
    static PROVIDERENDEREDCONCEPTUALDOCUMENT = 'PROVIDERENDEREDCONCEPTUALDOCUMENT';
    static PERSONALQUICKTRANSACT = 'PERSONALQUICKTRANSACT';
    static PROVIDEORDERPAYMENTINFORMATION = 'PROVIDEORDERPAYMENTINFORMATION';
    static PROCESSONLINECARDPAYMENT = 'PROCESSONLINECARDPAYMENT';
    static UPDATEPASSWORD = 'UPDATEPASSWORD';
    static UPDATELEGALENTITY = 'UPDATELEGALENTITY';
    static PROVIDEREVENUEREPORT = 'PROVIDEREVENUEREPORT';
    static CREATEUSERACCOUNT = 'CREATEUSERACCOUNT';
    static PROVIDEDEPOSITMETHOD = 'PROVIDEDEPOSITMETHOD';
    static PROVIDESUPPLIERINVOICESUMMARY = 'PROVIDESUPPLIERINVOICESUMMARY';
    static ASSESSPROPOSAL = 'ASSESSPROPOSAL';
    static CHECKOUTPROPOSAL = 'CHECKOUTPROPOSAL';
    static PROVIDEACCOUNTSTATEMENTREPORT = 'PROVIDEACCOUNTSTATEMENTREPORT';
    static PROVIDESALESSUMMARY = 'PROVIDESALESSUMMARY';
    static PROVIDETHIRDPARTYINSURANCESALESBYPRODUCTREPORT = 'PROVIDETHIRDPARTYINSURANCESALESBYPRODUCTREPORT';
    static PROVIDETHIRDPARTYINSURANCESALESBYSALESCHANNELREPORT = 'PROVIDETHIRDPARTYINSURANCESALESBYSALESCHANNELREPORT';
    static PROVIDETRANSACTIONREPORT = 'PROVIDETRANSACTIONREPORT';
    static VERIFYCARDPAYMENT = 'VERIFYCARDPAYMENT';
    static ASSESSYELLOWCARD = 'ASSESSYELLOWCARD';

}
