export class Services {

    serviceDescription: string;
    serviceType: number;
    serviceDate: Date;

    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.dpo.Services";
    }

}
