import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {
    RetailVehicle,
    ScannedDocument,
    ScannedDocumentIdentifier,
    ScannedDocumentIdentifierIdentifier,
    Vehicle
} from '@magnabc/tpi'
import {Utils} from '../../../../../shared/Utils/Utils'
import {Dropdown} from '../../../../../../common/model/dropdown.model'
import {
    InsuranceProductService
} from '../../../../../../app.services/managers/insurance-product/insurance-product.service'

@Component({
    selector: 'app-capture-vehicle-images',
    templateUrl: './capture-vehicle-images.component.html',
    styleUrls: ['./capture-vehicle-images.component.scss']
})
export class CaptureVehicleImagesComponent implements OnInit {

    @Output() onCloseCaptureImages = new EventEmitter<any>()

    file: any
    documentTypes = [
        new Dropdown(1, 'Front of the Vehicle'),
        new Dropdown(2, 'Rear of the Vehicle'),
        new Dropdown(3, 'Drivers (right) side of the vehicle'),
        new Dropdown(4, 'Passenger (left) side of vehicle'),
        new Dropdown(5, 'Inside the vehicle (Dashboard and radio)'),
        new Dropdown(6, 'Engine (Engine number to be displayed)')
    ]
    description = this.documentTypes[0].name
    uploadPdf = false;
    scannedDocumentInput = true
    removeImages: ScannedDocumentIdentifier[] = []

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {
    }

    getVehiclePlateNumber(vehicle: Vehicle | RetailVehicle): string {
        return Utils.getVehiclePlateNumber(vehicle)
    }

    onType(data: any) {
        this.description = data.value.name
    }

    onScannedDocument(scannedDocument: ScannedDocument) {

        if (!this.insuranceProductService.vehicleImageFilesMap[this.insuranceProductService.tpiIdentifier]) {
            this.insuranceProductService.vehicleImageFilesMap[this.insuranceProductService.tpiIdentifier] = []
        }
        this.insuranceProductService.vehicleImageFilesMap[this.insuranceProductService.tpiIdentifier].push({file: this.file, description: this.description, scannedDocument: scannedDocument, isBase64: false})

        // reset scanned document component
        this.scannedDocumentInput = false
        setTimeout(() => {
            this.scannedDocumentInput = true
        })

    }

    onFile(file: any) {
        this.file = file
    }

    removeImage(tpiIdentifier: string, position: number) {
        const scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier()
        scannedDocumentIdentifierIdentifier.documentIdentifier = this.insuranceProductService.vehicleImageFilesMap[tpiIdentifier][position].scannedDocument.documentIdentifier
        this.removeImages.push(scannedDocumentIdentifierIdentifier)
        this.insuranceProductService.vehicleImageFilesMap[tpiIdentifier].splice(position, 1)
    }

}
