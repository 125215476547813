import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'formatCreditCardDate'})
export class FormatCreditCardDatePipe implements PipeTransform {

    transform(value: string): string {
        return value.substr(0, 2) + "/" + value.substr(2, 4);
    }

}
