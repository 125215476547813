import { CityTownIdentifier } from "./city-town-identifier.model";

export class CityTownDescriptionIdentifier extends CityTownIdentifier {
    
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.location.identification.CityTownDescriptionIdentifier";
    }

    public description: string;
}