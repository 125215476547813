import {SnapshotVehicle} from "../../checkout/models/snapshot-vehicle.model"
import {Money} from "../../../financial/models/money.model"
import {ExtensionInsuranceOrderItem} from "./extension-insurance-order-tem.model"
import {orderExtensionInsuranceProduct} from "./order-extension-insurance-product.model"

export class ExtensionInsurancePolicyOrderItem extends ExtensionInsuranceOrderItem {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.ExtensionInsurancePolicyOrderItem";
  }

  public startDate: Date;
  public expiryDate: Date;
  public vehicleValue: Money;
  public policyNumber: string;
  public snapshotVehicle: SnapshotVehicle;
  public orderExtensionInsuranceProduct: orderExtensionInsuranceProduct;
}
