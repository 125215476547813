import {Response} from '../../../../../../urdad/services/models/response.model';
import {MotorInsuranceCertificate} from '../../../../certificate/models/motor-insurance-certificate.model';
import {InsurancePolicy} from "../../../../models/insurance-policy.model";

export class QueryInsurancePolicyInformationResponse extends Response{
    constructor(){
        super();
    }

    public resultsFrom: number;
    public resultsTo : number;
    public totalNumberOfResults : number;
    public motorInsuranceCertificates: MotorInsuranceCertificate [] = [];
    public insurancePolicies: InsurancePolicy [] = [];
}
