import { PremiumDeltaType } from "./premium-delta-type.model";
import { Money } from "../../../financial/models/money.model";

export class InsuranceLevy {
  constructor() {
    this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsuranceLevy";
  }

  public premiumDelta: Money;
  public premiumDeltaType: PremiumDeltaType;
  public date: Date;
}
