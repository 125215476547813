export class Country{
  constructor() {
    this['@class'] = "za.co.magnabc.tpi.location.geographic.Country";
  }

  code: string;
  description: string;
  flagUrl: string;
  dialingCode: string;
  dialingMask: string;
  alphaTwo: string;
  alphaThree: string;
  currency: string;
}
