import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class TokenIssuerService {

  constructor(private httpClient: HttpClient,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  issueNewTokens(refreshToken: string) {
    const issueNewTokenURI = this.runtimeConfigurationService.apiBaseUrl + '/security/token/issuer/issuenewtokens';

    const issueNewTokenRequest = {
      '@class' : 'za.co.magnabc.tpi.security.jwt.IssueNewTokenRequest',
      refreshToken
    };

    return this.httpClient.post<any>(issueNewTokenURI, issueNewTokenRequest, this.options);
  }

  issueTempQueryToken(accountNumber: string) {
    const issueTempTokenURI = this.runtimeConfigurationService.apiBaseUrl + '/security/token/issuer/issuetempquerytoken';

    const issueTempTokenRequest = {
      '@class' : 'za.co.magnabc.trafmanprime.security.jwt.IssueTempQueryTokenRequest',
      accountNumber
    };

    return this.httpClient.post<any>(issueTempTokenURI, issueTempTokenRequest, this.options)
      .toPromise();
  }
}
