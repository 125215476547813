import { Criteria } from '../../../../criteria/models/criteria.model';
import { LegalEntityIdentifier } from '../../../../entity/identification/models/legal-entity-identifier.model';
import { UnderwriterIdentifier } from '../../identification/models/underwriter-identifier.model';
import { ProposalRecordStatus } from './proposal-record-status.model';

export class ProposalRecordStatusLegalEntityCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalRecordStatusLegalEntityCriteria";
    }

    public status: ProposalRecordStatus;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public underwriterIdentifier: UnderwriterIdentifier;

}