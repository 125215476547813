import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {
    MarshallerService,
    ProvidePopularProductsRequest,
    ProvideRoleRevenueRequest,
    ProvideSalesSummaryRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class DashboardProviderService {

  basePath = '/ledger/reporting/dashboardprovider';

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  providePopularProducts(providePopularProductsRequest: ProvidePopularProductsRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providepopularproducts';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(providePopularProductsRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

  provideRoleRevenue(provideRoleRevenueRequest: ProvideRoleRevenueRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providerolerevenue';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRoleRevenueRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

  provideSalesSummary(provideSalesSummaryRequest: ProvideSalesSummaryRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesalessummary';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSalesSummaryRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }
}
