import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {ProvideCountryIdentifiersRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class CountryIdentifierProviderService {

    basePath = '/location/geographic/identification/countryidentifierprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideCountryIdentifiers(provideCountryIdentifiersRequest: ProvideCountryIdentifiersRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providecountryidentifiers';
        return this.http.post<any>(path, provideCountryIdentifiersRequest, this.options).toPromise();
    }

}
