import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Criteria} from '../../../../../../../criteria/models/criteria.model';

export class ProvideOrderSummaryRequest extends Request {
    constructor() {
        super();
    }

    public batchSize:number;
    public batchNumber:number;
    public criteria: Criteria;
}
