import {Component, OnInit, ViewChild} from "@angular/core"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {Router} from "@angular/router"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {
    Broker,
    BrokerLegalEntityCriteria,
    BrokerLegalEntityIdentifier,
    ComprehensiveInsuranceProduct,
    InsuranceCategory,
    InsuranceProductBrokerCriteria,
    LegalEntityDescriptionIdentifier,
    Product,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideInsuranceProductInformationRequest,
    ProvideInsuranceProductInformationResponse,
    ThirdPartyInsuranceProduct,
    TpiIdentifier,
    Underwriter,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    YCInsuranceProduct
} from "@magnabc/tpi"
import {AuthenticationService} from "../../../../../app.services/managers/authentication/authentication.service"
import {Utils} from "../../../../../view.components/shared/Utils/Utils"
import {AgentManagerService} from "../../../../../app.services/managers/agent-manager/agent-manager.service"
import {
    InsuranceProductManagerService
} from "../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service"
import {
    InsuranceProductInformationProviderService
} from "../../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service"
import {
    BrokerInformationProviderService
} from "../../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service"
import {Title} from '@angular/platform-browser'
import {
    UnderwriterManagerService
} from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    ProductListComponent
} from '../../../../../view.components/retail/insurance/insurance-product-manager/product-list/product-list.component'

declare const jQuery: any;
@Component({
    templateUrl: './retail.products.list.component.html',
    styleUrls: ['./retail.products.list.component.scss']
})
export class RetailProductsPageComponent extends FormComponent implements OnInit {

    underwriterMap: { [id: string]: Underwriter } = {};
    insuranceCategory = InsuranceCategory;
    broker: Broker;
    products: Product[] = []

    @ViewChild(ProductListComponent) productListComponent: ProductListComponent

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private authenticationService: AuthenticationService,
                private insuranceProductInformationProviderService: InsuranceProductInformationProviderService,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private agentService: AgentManagerService,
                private underwriterManagerService: UnderwriterManagerService,
                public insuranceProductManagerService: InsuranceProductManagerService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Products - Console - ${this.runtimeConfigurationService.title}`);

        this.underwriterManagerService.registerUnderwriterListener((underwriter) => {
            this.getBroker(underwriter);
        });

    }

    getBroker(underwriter): void {

        const provideBrokerRequest = new ProvideBrokerInformationRequest();
        const brokerLegalEntityCriteria = new BrokerLegalEntityCriteria();
        if (this.authenticationService.getLoggedInNaturalPerson() != null) {
            brokerLegalEntityCriteria.legalEntity = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers())
        }

        provideBrokerRequest.criteria = brokerLegalEntityCriteria;

        this.startLoad();
        this.brokerInformationProviderService.provideBroker(provideBrokerRequest).subscribe((response) => {
            this.stopLoad();
            if (response && response.body) {
                const provideBrokerInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse);
                this.broker = provideBrokerInformationResponse.brokers[0];
                this.insuranceProductManagerService.broker = this.broker;
                this.insuranceProductManagerService.brokerCountry = this.broker.countryIdentifier;
                this.onSelectInsuranceCategory(null);
                this.selectUnderwriter(underwriter);
            }
        });

    }

    onSelectInsuranceCategory(insuranceCategory: InsuranceCategory) {
        this.insuranceProductManagerService.insuranceCategory = insuranceCategory;
        if (event != null) {
            this.setActiveClass(event)
        }
        this.getProducts(insuranceCategory);
    }

    selectUnderwriter(underwriter: Underwriter) {
        if (underwriter) {
            this.underwriterMap[underwriter.name] = underwriter;
            this.insuranceProductManagerService.brokerCountry = underwriter.countryIdentifier;
            this.insuranceProductManagerService.selectedUnderwriter = underwriter;
        }
    }

    getProducts(insuranceCategory?: InsuranceCategory): void {

        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier()
        legalEntityDescriptionIdentifier.name = this.broker.name;
        legalEntityDescriptionIdentifier.tpiIdentifier = this.broker.legalEntityIdentifier as TpiIdentifier;
        const brokerIdentifier = new BrokerLegalEntityIdentifier(this.broker.name, legalEntityDescriptionIdentifier);

        const provideInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
        const insuranceProductBrokerCriteria = new InsuranceProductBrokerCriteria();
        insuranceProductBrokerCriteria.insuranceCategory = insuranceCategory;
        insuranceProductBrokerCriteria.brokers.push(brokerIdentifier);
        provideInsuranceProductInformationRequest.criteria = insuranceProductBrokerCriteria;

        this.startLoad();
        this.insuranceProductInformationProviderService.provideInsuranceProductInformation(provideInsuranceProductInformationRequest).toPromise().then(response => {
            this.stopLoad();
            const filteredProducts = [];
            if (response && response.body) {
                const provideInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
                const products: Product[] = provideInsuranceProductInformationResponse.products;
                this.insuranceProductManagerService.addOnProductOptions = [];
                for (const product of products) {
                    if (product instanceof ThirdPartyInsuranceProduct || product instanceof ComprehensiveInsuranceProduct) {
                        this.insuranceProductManagerService.addOnProductOptions.push(product);
                        product.priceSchedules.forEach((schedule) => {
                            if (schedule.additionalVehicleToPolicy === null) {
                                schedule.additionalVehicleToPolicy = false;
                            }
                        })
                    }
                    if (this.isActiveUnderwriter(product)) {
                        filteredProducts.push(product);
                    }
                }
            }
            this.products = filteredProducts
            if (this.productListComponent) {
                this.productListComponent.setProducts(this.products)
            }
        }).catch(err => {
            this.products = []
            if (this.productListComponent) {
                this.productListComponent.setProducts([])
            }
            this.stopLoad();
            console.error(err)
        });

    }

    isActiveUnderwriter(product) {
        let underwriterIdentifier = null
        if (product.underwriterIdentifier) {
            underwriterIdentifier = (product.underwriterIdentifier as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier
        }
        if (product.underwriter) {
            underwriterIdentifier = (product.underwriter as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier
        }
        if (underwriterIdentifier && underwriterIdentifier === this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier) {
            return true
        }
        return false
    }

    ngOnInit(): void {}

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.insuranceProductManagerService.product = null
        this.insuranceProductManagerService.disabledFields = false;
        this.insuranceProductManagerService.refreshProduct = false;
        this.insuranceProductManagerService.ycPriceIntervals.next([])
        this.insuranceProductManagerService.ycPriceSchedules.next([])
        this.router.navigate(['/broker/products/create/new']).then();
    }

    onProductSelect(product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct): void {
        this.insuranceProductManagerService.product = product
        this.insuranceProductManagerService.refreshProduct = false;
        this.insuranceProductManagerService.disabledFields = true;
        if (product instanceof YCInsuranceProduct) {
            this.insuranceProductManagerService.ycPriceIntervals.next(product.ycPriceIntervals);
            this.insuranceProductManagerService.ycPriceSchedules.next(product.ycPriceSchedules);
        }
        this.router.navigate([`/retail/insurance/product-overview/${product.number}`]);
    }

    // add active class to the button
    setActiveClass(event) {
        const element = event.target || event.srcElement;
        if (element.nodeName === "BUTTON") {
            let buttonAlreadyActive = element.parentElement.querySelector('.active');
            if (buttonAlreadyActive) {
                buttonAlreadyActive.classList.remove('active');
            }
            element.classList.add('active');
        }
    }

}
