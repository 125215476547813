import { Money } from "../../../../financial/models/money.model";
export class Transaction {

    public backUrl: string;
    public companyAccRef: string;
    public declineUrl: string;
    public paymentAmount: Money;
    public paymentTimeLimit: number;
    public paymentTimeLimitType: string;
    public redirectUrl: string;
    public request: string;
    public transactionAutoChargeDate: Date;
    public transactionChargeType: number;

    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.dpo.Transaction";
    }

}
