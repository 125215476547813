import { Criteria } from "../../criteria/models/criteria.model";
import { ConceptualDocumentType } from "./conceptual-document-type.model";
export class ConceptualDocumentTypeIdCriteria extends Criteria {

  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.conceptualdocument.ConceptualDocumentTypeIdCriteria";
  }

  public documentId: string;
  public type: ConceptualDocumentType;
  public userID: string;
}
