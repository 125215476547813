import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PayoutData} from '../../account-transaction/account-statements/account-statements.component'

@Component({
    selector: 'payout-dialog',
    templateUrl: './payout-dialog.component.html',
  })
  export class PayoutDialogComponent{

    constructor(private dialogRef:MatDialogRef<PayoutDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: PayoutData []){ }

    closeDialog(){
    this.dialogRef.close();
    }
  }
