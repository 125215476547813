import { QuestionType } from "./question-type.model";
import { Questions } from "./questions.model";

export class InsuranceHistoryQuestions extends Questions{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.InsuranceHistoryQuestions";
    }

    public questionLabel: string;
    public questiontype: QuestionType;
    public mandatoryQuestion: boolean;
    public commentRequired: boolean;
    public commentLabel: string;
    public score: number;
    public scoreAllocation: number;
}