import {Response} from '../../../../../../urdad/services/models/response.model';
import { SessionTracking } from '../../../../models/session-tracking.model';

export class ProvideSessionTrackingResponse extends Response {

    constructor() {
        super();
    }

    public sessionTrackings: SessionTracking[] = [];
}