import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {FormGroupDirective, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms"
import {FormComponent} from "../../../../../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ClaimSpecificExcess, Excess, Money} from '@magnabc/tpi'
import {
    InsuranceProductManagerService
} from "../../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service"
import {ToastComponent} from "../../../../../shared/toast/toast.component"
import {ActiveToast} from "ngx-toastr"
import {Dropdown} from '../../../../../../common/model/dropdown.model'
import {ErrorToastService} from '../../../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;
@Component({
    selector: 'app-claim-specific-excess',
    templateUrl: './claim-specific-excess.component.html',
    styleUrls: ['./claim-specific-excess.component.scss']
})
export class ClaimSpecificExcessComponent extends FormComponent implements OnInit {

    @Input() excess: Excess[] = [];
    @Output() onExcess= new EventEmitter<Excess[]>();
    @Output() onValid = new EventEmitter<void>();

    activeToast: ActiveToast<any>;
    excessRequired = false;
    excessDuplicateDescription = false;
    excessTypes: Dropdown[] = [];
    formGroup: UntypedFormGroup;

    @ViewChild('form') form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private formBuilder: UntypedFormBuilder,
                private toastr: ErrorToastService,
                public retailProductService: InsuranceProductManagerService) {
        super(translate, translateParser);

    }

    ngOnInit() {
        for(var excess of this.retailProductService.claimSpecificExcesses){
            this.excessTypes.push(new Dropdown(excess.description, excess.description));
        }

        this.formGroup = new UntypedFormGroup({
            'excesses': this.formBuilder.array([])
        });

        if(this.retailProductService.editingIndex == -1){
            this.onAddExcess();
        }
        else{
            for (const excess of this.excess) {
                if(excess instanceof ClaimSpecificExcess || excess['@class'] === new ClaimSpecificExcess('')['@class']){
                    this.pushFormGroupExcess(excess as ClaimSpecificExcess);
                }
            }
        }

        this.sortDisplayList();
    }

    pushFormGroupExcess(claimSpecificExcess: ClaimSpecificExcess) {
        const formGroupExcess = this.formBuilder.group({
            'type': [this.getDropdown(this.excessTypes, claimSpecificExcess.type) , [Validators.required]],
            'value': [claimSpecificExcess.value.amount/100, [Validators.required, Validators.min(0)]],
            'percentage': [claimSpecificExcess.percentage, [Validators.required, Validators.min(0), Validators.max(100)]],
            'description': [claimSpecificExcess.description, [Validators.maxLength(250)]],
        });
        (this.formGroup.get('excesses') as UntypedFormArray).push(formGroupExcess);

    }

    onRemoveExcess(event, index: number): boolean {
        this.toastRemoveExcess(index);
        event.stopPropagation();
        return false;
    }

    onAddExcess(): void {
        const formGroupExcess = this.formBuilder.group({
            'type': [null , [Validators.required]],
            'value': [null, [Validators.required, Validators.min(0)]],
            'percentage': [null, [Validators.required, Validators.min(0), Validators.max(100)]],
            'description': [null, [Validators.maxLength(250)]],
        });
        (this.formGroup.get('excesses') as UntypedFormArray).push(formGroupExcess);
    }

    toastRemoveExcess(index: number) {

        this.translate.get('TOAST.REMOVE_EXCESS').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: "excess"}), res.TITLE, {
                toastComponent: ToastComponent,
                disableTimeOut: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        (this.formGroup.get('excesses') as UntypedFormArray).removeAt(index);
                        this.excess.splice(index, 1);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    getExcessCurrency(): string {
        return this.retailProductService.currency;
    }

    submit(): void {
        document.getElementById('form-submit-excess').click();
    }

    onCheckValidation(event): void {

        this.excessRequired = false;
        this.excessDuplicateDescription = false;
        if (!this.form.invalid) {

            if ((this.formGroup.get('excesses') as UntypedFormArray).length === 0) {
                this.excessRequired = true;
                jQuery('html,body').animate({ scrollTop: jQuery('#excesses').offset().top - 300 }, 'slow');
                return;
            }

            if(this.retailProductService.editingIndex != -1){
                this.retailProductService.product.excess.splice(this.retailProductService.editingIndex, 1);
                this.excess = [];
            }

            this.retailProductService.product.excess = this.retailProductService.product.excess.filter(excess => (excess instanceof ClaimSpecificExcess || excess['@class'] === new ClaimSpecificExcess('')['@class']));

            const descriptionMap: {[id:string]: string} = {};
            for(var i = 0; i < (this.formGroup.get('excesses') as UntypedFormArray).length; i++){

                var currentExcess = (this.formGroup.get('excesses') as UntypedFormArray).at(i);
                var newExcess = new ClaimSpecificExcess(currentExcess.get('description').value);

                const money = new Money;
                money.amount = currentExcess.get('value').value * 100;
                money.currency = this.retailProductService.currency;

                newExcess.value = money;
                newExcess.percentage = currentExcess.get('percentage').value;
                newExcess.type = currentExcess.get('type').value.value;
                newExcess.description = currentExcess.get('description').value;

                for (const excess of this.retailProductService.product.excess) {
                    if ((excess as any).description === newExcess.description) {
                        this.excessDuplicateDescription = true;
                        jQuery('html,body').animate({ scrollTop: jQuery('#excesses').offset().top - 300 }, 'slow');
                        return;
                    }
                }

                if (descriptionMap[newExcess.description]) {
                    this.excessDuplicateDescription = true;
                    jQuery('html,body').animate({ scrollTop: jQuery('#excesses').offset().top - 300 }, 'slow');
                    return;
                }
                descriptionMap[newExcess.description] = newExcess.description;

                this.excess.push(newExcess);
            }

            this.onExcess.emit(this.excess);
            this.onValid.emit();

        } else {
            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }
        }
    }

    sortDisplayList() {
        this.excessTypes.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }
}
