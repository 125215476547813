import { Response } from "../../../../../../../urdad/services/models/response.model";
import { SubscriptionSummary } from "../../../../../models/subscription-summary.model";

export class ProvideSubscriptionsSummaryResponse extends Response {
    constructor(){
        super();
    }

    public subscriptionSummaries: SubscriptionSummary[] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberResults: number;
}
