import {MakeIdentifier} from "../../identification/models/make-identifier.model";

export class Model {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.vehicle.makemodeltrim.Model";
    }

    public makeIdentifier: MakeIdentifier;
    public name: string;
}
