import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
    MarshallerService,
    ProvideSubscriptionsOwnersBackOfficeRequest,
    ProvideSubscriptionsOwnersRequest,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsSummaryBackofficeRequest,
    ProvideSubscriptionsSummaryRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SubscriptionInformationProviderService {

    basePath = '/security/authorisation/subscriptioninformationprovider';

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideSubscriptions(provideSubscriptionRequest: ProvideSubscriptionsRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesubscriptions';

        const jsonProvideSubscriptionsRequest = this.marshallerService.marshallObjectToJSON(provideSubscriptionRequest);

        return this.httpClient.post<any>(path, jsonProvideSubscriptionsRequest, this.options).toPromise();
    }

    provideSubscriptionsOwners(provideSubscriptionsOwnersRequest: ProvideSubscriptionsOwnersRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesubscriptionsowners';

        const jsonProvideSubscriptionsRequest = this.marshallerService.marshallObjectToJSON(provideSubscriptionsOwnersRequest);

        return this.httpClient.post<any>(path, jsonProvideSubscriptionsRequest, this.options).toPromise();
    }

    provideSubscriptionsOwnersBackOffice(provideSubscriptionsOwnersBackOfficeRequest: ProvideSubscriptionsOwnersBackOfficeRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesubscriptionsownersbackoffice';

        const jsonProvideSubscriptionsRequest = this.marshallerService.marshallObjectToJSON(provideSubscriptionsOwnersBackOfficeRequest);

        return this.httpClient.post<any>(path, jsonProvideSubscriptionsRequest, this.options).toPromise();
    }

    provideSubscriptionsSummary(provideSubmissionRecordSummaryRequest: ProvideSubscriptionsSummaryRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesubscriptionssummary';
        const jsonProvideSubscriptionsSummaryRequest = this.marshallerService.marshallObjectToJSON(provideSubmissionRecordSummaryRequest);
        return this.httpClient.post<any>(path, jsonProvideSubscriptionsSummaryRequest, this.options).toPromise();
    }

    provideSubscriptionsSummaryBackOffice(provideSubscriptionsSummaryBackofficeRequest: ProvideSubscriptionsSummaryBackofficeRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesubscriptionssummarybackoffice';
        const jsonProvideSubscriptionsSummaryRequest = this.marshallerService.marshallObjectToJSON(provideSubscriptionsSummaryBackofficeRequest);
        return this.httpClient.post<any>(path, jsonProvideSubscriptionsSummaryRequest, this.options).toPromise();
    }
}
