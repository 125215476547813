import {Request} from '../../../../../../../../urdad/services/models/request.model';
import {PaymentReference} from "../../../../../../../../financial/models/payment-reference.model";

export class VerifyCardPaymentRequest extends Request {
    constructor (){
        super();
    }

    paymentReference: PaymentReference;

}
