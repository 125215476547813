import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, ProvideAccountInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class BackOfficeAccountInformationProvider {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  provideAccountInformation(provideAccountInformationRequest: ProvideAccountInformationRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/backofficeaccountinformationprovider/provideaccountinformation';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideAccountInformationRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }
}
