import { LegalEntity } from "./legal-entity.model";

export class JudicialPerson extends LegalEntity{
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.JudicialPerson";
  }
  public name: string;
  public type: string;
  public industry: string;
}
