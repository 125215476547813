import { Money } from '../../../financial/models/money.model';
import { ScannedDocumentIdentifier } from '../../../media/scanneddocument/identification/models/scanned-document-identifier.model';
import { VehicleIdentifier } from '../../../vehicle/identification/models/vehicle-identifier.model';

export class MotorInsuranceVehicleDetails {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.MotorInsuranceVehicleDetails";
    } 

    public alarmImmobiliser: boolean;
    public vehicleValue: Money;
    public scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
    public vehicleIdentifier: VehicleIdentifier;
}
