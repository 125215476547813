import {Component, OnInit} from '@angular/core'
import * as XLSX from 'xlsx'
import * as moment from "moment"
import {environment} from "../../../../../environments/environment"
import {FormComponent} from "../../../../view.components/shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {Agent, BrokerLegalEntityIdentifier, TpiIdentifier} from "@magnabc/tpi"
import {AgentManagerService} from "../../../../app.services/managers/agent-manager/agent-manager.service"
import {ServiceList} from '../../../../common/model/service-list.model'
import {AuthenticationService} from "../../../../app.services/managers/authentication/authentication.service"
import {
  UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'

type AOA = any[][];

declare const jQuery: any;

@Component({
    selector: 'app-reports',
    templateUrl: './financial-report-page.component.html',
    styleUrls: ['./financial-report-page.component.scss']
})
export class FinancialReportPageComponent extends FormComponent implements OnInit {

    zambeziInsureIdentifier = 'ENT-2'; // FIXME hardcoded
    activeLink = "";

    rows = [];
    data: AOA = [];
    fileName = 'export.xlsx';

    agent: Agent;
    entity = "Entity";
    from;
    to;

    roleMap: {[id: string]: string[]} = {};

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private brokerService: UnderwriterInformationProviderService,
                private agentService: AgentManagerService,
                public authenticationService: AuthenticationService) {
        super(translate, translateParser);

    }

    ngOnInit() {
        this.from = moment();
        this.to = moment();
        //this.getRevenueSplit();
    }

    getRevenueSplit(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        //FIXME: Add date assignments
        // thirdPartyInsuranceRevenueSplitRoleCriteria.fromDate = moment(this.from).format(environment.formatDateServer);
        // thirdPartyInsuranceRevenueSplitRoleCriteria.toDate =  moment(this.to).format(environment.formatDateServer);
        this.roleMap = {};
        this.startLoad();


    }

    getDate(date): string {
        return moment(date).format(environment.formatDate);
    }

    checkApplicable(r: any, i: any): boolean {

        if (!this.roleMap[r.dateTime]) {
            this.roleMap[r.dateTime] = [];
        }

        if (this.brokerService.selectedUnderwriterLegalEntityIdentifier && i.underwriter &&
            this.brokerService.selectedUnderwriterLegalEntityIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier === i.underwriter.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.dateTime].push("Underwriter");
            return true;
        }
        if (this.agentService.agent.brokerIdentifiers[0] && i.broker &&
            (this.agentService.agent.brokerIdentifiers[0] as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier
            === i.broker.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.dateTime].push("Broker");
            return true;
        }
        if (this.agentService.agentIdentifier && i.selling &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier).tpiIdentifier === i.selling.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.dateTime].push("Selling Agent");
            return true;
        }
        if (this.agentService.agentIdentifier && i.printing &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier).tpiIdentifier === i.printing.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.dateTime].push("Printing Agent");
            return true;
        }
        if (this.agentService.agentIdentifier && i.paymentProvider &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier).tpiIdentifier === i.paymentProvider.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.dateTime].push("Payment Provider");
            return true;
        }
        if (!this.brokerService.selectedUnderwriterLegalEntityIdentifier && this.agentService.agent &&
            (this.agentService.agent.legalEntityIdentifier as TpiIdentifier).tpiIdentifier === this.zambeziInsureIdentifier) {
            this.roleMap[r.dateTime].push("10% Commission");
        }
        return false;

    }

    export(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report');
        XLSX.writeFile(wb, this.fileName);
    }

    onSubmit(): void {
        this.getRevenueSplit();
    }

    servicesRevenueSplit(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEREVENUEREPORT]));
    }

    servicesTransactionReport(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDETRANSACTIONREPORT]));
    }

    servicesSalesReport(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDETHIRDPARTYINSURANCESALESBYPRODUCTREPORT]) ||
            this.authenticationService.isAuthorised([ServiceList.PROVIDETHIRDPARTYINSURANCESALESBYSALESCHANNELREPORT]));
    }

}
