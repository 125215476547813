import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, OrderSummary, ProvideOrderInformationRequest, ProvideOrderSummaryRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class OrderInformationProviderService {
    orderSummaries: OrderSummary[];
    totalNumberOfResults: number;

    basePath = '/retail/checkout/orderinformationprovider';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideOrder(provideOrderRequest: ProvideOrderInformationRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideorderinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideOrderRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideOrderSummary(provideOrderSummaryRequest: ProvideOrderSummaryRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideordersummary';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideOrderSummaryRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

}
