import { Duration } from "../../../temporal/models/duration.model";
import { DurationType } from "./duration-type.model";


export class PriceSchedule {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.PriceSchedule";
    }

    public additionalVehicleToPolicyValue: number;
    public additionalVehicleToPolicyExtensionValue: number;
    public currency: string;
    public durationType: DurationType;
    public exactDuration: Duration;
    public extension : boolean;
    public extensionValue: number;
    public gracePeriod: Duration;
    public intervalDurationFrom: Duration;
    public intervalDurationTo: Duration;
    public lengthOfExtension: Duration;
    public minimumPolicyValue: number;
    public newPolicyValue: number;
    public percentageOfAnnualAmount: boolean;
    public priceScheduleId: string;
    public proRata: boolean;
    public additionalVehicleToPolicy: boolean = false;
}
