import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";

export class SetupClaimSpecificExcess {

    description: string;
    countryIdentifier: CountryIdentifier;

    constructor(description: string, countryIdentifier: CountryIdentifier) {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.ClaimSpecificExcess';
        this.description = description;
        this.countryIdentifier = countryIdentifier;
    }
}
