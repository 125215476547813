import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Dropdown } from './../../../../common/model/dropdown.model';

@Directive({
    selector: '[appAutocompleteShowOptions]'
})
export class AutocompleteShowOptionsDirective implements AfterViewInit, OnDestroy {
    @Input() control: FormControl;
    @Output() onFilter = new EventEmitter<string>();

    previousValue: any = null;
    selectionMade = false;
    value = "";
    input: HTMLElement;

    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this.input = this.elementRef.nativeElement.querySelector('input');
        if (this.input) {
            this.input.addEventListener('blur', () => {
                this.blur();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.input) {
            this.input.removeAllListeners();
        }
    }

    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        this.previousValue = null;
        this.value = this.value.concat(event.data);
        this.onFilter.emit(this.value);
    }

    @HostListener('blur') onBlur() {
        this.blur();
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        this.onFilter.emit(event.target.value);
    }

    @HostListener('keyup', ['$event']) onkeyup(event) {
        this.onFilter.emit(event.target.value);
    }

    private blur(): void {
        this.setPageScroll(true);
        if (this.value === "" && !this.selectionMade && this.previousValue) {
            this.control.setValue(this.previousValue);
            this.previousValue = null;
            this.selectionMade = true;
            this.resetValue();
        }
    }

    @HostListener('click') onClick() {
        this.selectionMade = false;
        if (this.control.value instanceof Dropdown) {
            this.previousValue = this.control.value;
        }
        this.control.setValue("");
        this.resetValue();
        this.setPageScroll(false);
    }

    private resetValue(): void {
        setTimeout(() => {
            this.value = "";
            this.onFilter.emit(this.value);
        })
    }

    private setPageScroll(allow: boolean): void {
        let b = document.getElementById('router-outlet');
        if (allow) {
            b.style.overflow = "auto";
            b.style.height = '100%';
        } else {
            b.style.overflow = "hidden";
            b.style.height = '101%';
        }
    }
}


