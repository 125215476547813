import { Vehicle } from "../../../../../../vehicle/models/vehicle.model";
import { Response } from "../../../../../../urdad/services/models/response.model";

export class AddExtensionToCartResponse extends Response {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.InsuranceRetailManager.AddExtensionToCartResponse";
  }

  public unverifiedVehicleMessage: string[] = [];
  public verifiedVehicles: Vehicle[] = [];
}
