import { Criteria } from "../../../criteria/models/criteria.model";
import { AccountNumberIdentifier } from '../identification/models/account-number-identifier.model';

export class AccountCriteria extends Criteria {

    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.account.AccountCriteria";
    }

    public accountNumberIdentifier : AccountNumberIdentifier;
}
