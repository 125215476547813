import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {FinaliseCancelInsurancePolicyRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsurancePolicyFinalisationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCancelInsurancePolicy(finaliseCancelInsurancePolicyRequest: FinaliseCancelInsurancePolicyRequest): Observable<any> {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/cancelinsurancepolicyfinalisationmanager/finalisecancelinsurancepolicy';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCancelInsurancePolicyRequest);
        return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
    }

}
