import {Excess} from "./excess.model";
import {Money} from "../../../financial/models/money.model";


export class ClaimSpecificExcess extends Excess {
    type: string;
    percentage = 0;
    value: Money;
    description: string;
    
    constructor(type) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.ClaimSpecificExcess";
        this.type = type;
    }
}
