import {Injectable} from '@angular/core'
import {Observable} from "rxjs"
import {HttpClient} from "@angular/common/http"
import {
    BrokerInformationProviderService
} from "../broker-information-provider/broker-information-provider.service"
import {
    Broker,
    LegalEntityDescriptionIdentifier,
    ProvideUnderwriterInformationRequest,
    ProvideUnderwriterInformationResponse,
    ProvideUnderwriterWhiteLabelInformationRequest,
    Underwriter,
    UnderwriterCriteria,
    UnderwriterIdentifier,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService
}from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class UnderwriterInformationProviderService {

    basePath = '/retail/insurance/underwriterinformationprovider';

    brokerListeners = [];
    underwriterListeners = [];

    broker: Broker;
    underwriters: Underwriter[];
    selectedUnderwriter: Underwriter;
    selectedUnderwriterLegalEntityIdentifier: UnderwriterLegalEntityIdentifier;
    underwriterMap: {[id: string]: Underwriter} = {};
    queriedUnderwriter: {[id: string]: boolean} = {};

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private http: HttpClient,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    getUnderwriters(): void {

        const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
        underwriterLegalEntityCriteria.legalEntityIdentifiers = [this.broker.legalEntityIdentifier];
        const provideUnderwritersRequest = new ProvideUnderwriterInformationRequest();
        provideUnderwritersRequest.criteria = underwriterLegalEntityCriteria;

        this.provideUnderwriters(provideUnderwritersRequest).subscribe((response) => {
            if(response && response.body){
                const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                this.underwriters = provideUnderwriterInformationResponse.underwriters;

                if (this.underwriters && this.underwriters.length > 0) {
                    this.underwriters.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        }
                        if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });

                    this.selectedUnderwriter = this.underwriters[0];
                    this.selectedUnderwriterLegalEntityIdentifier = new UnderwriterLegalEntityIdentifier(this.selectedUnderwriter.name,
                        (this.selectedUnderwriter.legalEntityIdentifier as LegalEntityDescriptionIdentifier));
                    this.onUnderwriterChange();
                }
            }
        });

    }

    getUnderwriter(identifier: UnderwriterIdentifier, callback): void {

        const underwriterLegalEntityIdentifier = identifier as UnderwriterLegalEntityIdentifier;

        if (this.underwriterMap[underwriterLegalEntityIdentifier.name]) {
            callback(this.underwriterMap[underwriterLegalEntityIdentifier.name]);
        } else if (!this.queriedUnderwriter[underwriterLegalEntityIdentifier.name]) {
            const underwriterCriteria = new UnderwriterLegalEntityCriteria();
            underwriterCriteria.legalEntityIdentifiers = [underwriterLegalEntityIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier];
            const provideUnderwritersRequest = new ProvideUnderwriterInformationRequest();
            provideUnderwritersRequest.criteria = underwriterCriteria;

            this.provideUnderwriters(provideUnderwritersRequest).subscribe((response) => {
                this.queriedUnderwriter[underwriterLegalEntityIdentifier.name] = true;
                if(response && response.body){
                    const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                    this.underwriterMap[underwriterLegalEntityIdentifier.name] = provideUnderwriterInformationResponse.underwriters[0];
                    callback(provideUnderwriterInformationResponse.underwriters[0]);
                }
            });

        }

    }

    getAllUnderwriters(callback): void {
        const underwriterCountryCriteria = new UnderwriterCriteria();

        const provideUnderwritersRequest = new ProvideUnderwriterInformationRequest();
        provideUnderwritersRequest.criteria = underwriterCountryCriteria;
        this.provideUnderwriters(provideUnderwritersRequest).subscribe((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                callback(response.underwriters);
            }
        });
    }

    onUnderwriterChange(): void {
        for (const listener of this.underwriterListeners) {
            listener();
        }
    }

    registerUnderwriterListener(listener): void {
        this.underwriterListeners.push(listener);
        if (this.selectedUnderwriter) {
            listener(this.selectedUnderwriter);
        }
    }

    registerBrokerListener(listener): any {
        if (this.broker) {
            listener(this.broker);
        }
        this.brokerListeners.push(listener);
        return listener;
    }

    deregisterBrokerListener(listener): void {
        const index = this.brokerListeners.indexOf(listener);
        this.brokerListeners.splice(index, 0);
    }

    provideUnderwriters(provideUnderwritersRequest: ProvideUnderwriterInformationRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideunderwriterinformation';
        return this.http.post(path, provideUnderwritersRequest, this.options);
    }

    provideUnderwriterWhiteLabelInformation(provideUnderwriterWhiteLabelInformationRequest: ProvideUnderwriterWhiteLabelInformationRequest): Observable<any>{
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideunderwriterwhitelabelinformation';
        return this.http.post(path, provideUnderwriterWhiteLabelInformationRequest, this.options);
    }

}
