import { Criteria } from "../../criteria/criteria_api";

export class ConceptualDocumentIdCriteria extends Criteria {

  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.conceptualdocument.ConceptualDocumentIdCriteria";
  }

  public documentId: string;
  public userID: string;
}
