import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankTransferInformationComponent } from '../../../../view.components/retail/payment/bank-transfer-information/bank-transfer-information.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, OrderNumberCriteria, OrderNumberIdentifier, PayfastSignatureRequest, PayfastSignatureResponse, ProvideOrderInformationRequest, ProvideOrderInformationResponse, ReceivePayfastPaymentRequest, ReceivePayfastPaymentResponse, UnMarshallerService } from '@magnabc/tpi';
import { FormComponent } from '../../../../view.components/shared/form/form.component';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { OrderInformationProviderService } from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';
import { RuntimeConfigurationService } from '../../../../app.services/common/runtime-configuration/runtime-configuration.service';
import { environment } from '../../../../../environments/environment';
import { PayFastService } from '../../../../http.services/retail/payment/payfast/payfast-manager/payfast.service';
import { error } from 'console';
import { PayfastPaymentManagerService } from '../../../../app.services/managers/payafast-payament-manager/payfast-payment-manager.service';

@Component({
  selector: 'app-select-payment-method-page',
  templateUrl: './select-payment-method-page.component.html',
  styleUrls: ['./select-payment-method-page.component.scss']
})
export class SelectPaymentMethodPageComponent extends FormComponent implements OnInit, AfterViewInit{

    orderNumber: string;
    order: Order;
    returnUrl: string;
    paymentStatus: string
    payFastSignature: any
    convertedTotalDue: Number;
    zarExchangeRate: any;
    foreignExchangeRate: any;

    constructor(
        private dialogRef: MatDialogRef<SelectPaymentMethodPageComponent>,
        private dialog: MatDialog,
        private location: Location,
        private route: ActivatedRoute,
        private orderInformationProviderService: OrderInformationProviderService,
        private unMarshallerService: UnMarshallerService,
        public translate: TranslateService,
        private translateParser: TranslateParser,
        private runtimeConfigurationService: RuntimeConfigurationService,
        private payFastService: PayFastService,
        private payfastPaymentManager: PayfastPaymentManagerService,
        private router: Router
       ) {
        super(translate, translateParser);
    }

    ngAfterViewInit(): void {
        this.route.params.subscribe(params => {
            this.orderNumber = params['number'];
            this.paymentStatus = params['status']
        });
    }

    ngOnInit(): void {
    }

    /**
     * Open bank transfer information dialog
     */
    payByBankTransfer() {
        this.dialog.open(BankTransferInformationComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "10%" },
            data: {
                orderNumber: this.orderNumber
            }
        });
    }

    payOnline() {
        this.getOrder(this.orderNumber)
    }

    /**
     * Navigate to previous page
     */
    back() {
        const retrieve = localStorage.getItem('checkOutPage');
        if (retrieve === 'true') {
                this.router.navigate(['/retail/checkout'], { queryParams: { backToOrderSummary: true, orderNumber: this.orderNumber }, queryParamsHandling: 'merge' });
        } else {
            localStorage.removeItem('checkOutPage')
            this.location.back();
        }
    }

    getReturnUrl() {
        const retrieve = localStorage.getItem('checkOutPage');
        if (retrieve === 'true') {
            this.returnUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/checkout?backToOrderSummary=true&orderNumber=${this.orderNumber}`;
        } else {
            this.returnUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/orders?backToOrderHistory=true&orderNumber=${this.orderNumber}`;
            localStorage.removeItem('checkOutPage')
        }
    }



    getOrderCurrency() {
        this.startLoad()
        this.payfastPaymentManager.retrieveExchangeRate("ZAR").then(() => {
            this.zarExchangeRate = this.payfastPaymentManager.exchangeRate.rate;
            if (this.order.totalDue.currency === "USD") {
                this.convertedTotalDue = this.roundDown(((this.order.totalDue.amount/100) * this.zarExchangeRate));
                this.getOrderDetails();
                this.getSignature();
            } else {
                let newExchangeRate = 0;
                this.payfastPaymentManager.retrieveExchangeRate(this.order.totalDue.currency).then(() => {
                    this.foreignExchangeRate = this.payfastPaymentManager.exchangeRate.rate;
                    newExchangeRate = this.zarExchangeRate / this.foreignExchangeRate;
                    this.convertedTotalDue = this.roundDown(((this.order.totalDue.amount/100) * newExchangeRate));
                    this.getOrderDetails();
                    this.getSignature();
                })
            }
        })
    }

    roundDown(num) {
        return Math.floor(num * 100) / 100;
    }

    getOrderDetails() {
        this.getReturnUrl()
        return {
          merchant_id: environment.merchant_id,
          merchant_key: environment.merchant_key,
          amount: this.convertedTotalDue,
          item_name: this.order.number,
          return_url: this.returnUrl,
          cancel_url: this.returnUrl + '&cancelled=true',
          notify_url: this.getNotifyUrl()
        }
      }

      getNotifyUrl() {
        return `${this.runtimeConfigurationService.payfastPaymentBaseUrl}/retail/payment/payfast/payfastpaymentnotificationmanager/receivenotificationitn`;
      }

      getSignature() {
        const payfastSignatureRequest = new PayfastSignatureRequest();
        payfastSignatureRequest.payload = this.getOrderDetails();

       this.payFastService.getSignature(payfastSignatureRequest).then((data) => {
        const response: PayfastSignatureResponse = this.unMarshallerService.unMarshallFromJson(data.body, PayfastSignatureResponse);
          this.payFastSignature = response.digitalSignature;
          this.payFastHiddenForm(this.finalPayloadForPayFast(), environment.payFastUrl);
        })
      }

      finalPayloadForPayFast() {
        return { ...this.getOrderDetails(), signature: this.payFastSignature.replace(/\s/g, '') }
      }

      /**
       * Pays fast hidden form
       * @param obj : all input values that need to be sent to Pay fast
       * @param url : sandbox / production
       */
      payFastHiddenForm(obj, url) {
        const mapForm = document.createElement('form');
        mapForm.id = 'payfastForm';
        mapForm.target = '_self';
        mapForm.method = 'POST';
        mapForm.action = url;
        Object.keys(obj).forEach((param): void => {
          const mapInput = document.createElement('input');
          mapInput.type = 'hidden';
          mapInput.name = param;
          mapInput.setAttribute('value', obj[param]);
          mapForm.appendChild(mapInput);
        });
        document.body.appendChild(mapForm);
        mapForm.submit();
      }

      getOrder(reference: string): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(reference));

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderReferenceNumberCriteria;

        this.startLoad();
        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {
            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            for (const orderItem of response.orders[0].orderItems) {
                if ((orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem") && (orderItem["scannedDocument"].length === 0)) {
                    delete orderItem["scannedDocument"]
                }
            }
            this.order = response.orders[0];
            this.getOrderCurrency();
            this.stopLoad();
        }).catch((error) => {
            this.stopLoad();
        });

    }
}
