import {Router} from '@angular/router'
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {
    AddExtensionToCartRequest,
    AddExtensionToCartResponse,
    CreateIdentifierRequest,
    ExtensionInsurancePolicyCartItem,
    FinaliseExtendCheckoutOrderRequest,
    FinaliseExtendCheckoutOrderResponse,
    IdentifierType,
    InsuranceCategory,
    OrderItem,
    RetailProfileOwnerIdentifier,
    UnMarshallerService
} from '@magnabc/tpi'
import {ProductActionEnum} from '../insurance-product-information-provider/product/product-action.enum'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {InsuranceProductService} from '../../../../app.services/managers/insurance-product/insurance-product.service'
import {FormComponent} from '../../../shared/form/form.component'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {environment} from '../../../../../environments/environment'
import {ActiveToast} from 'ngx-toastr'
import {
    ProductInformationPriceScheduleComponent
} from '../insurance-product-information-provider/product-information-price-schedule/product-information-price-schedule.component'
import {
    InsuranceRetailManagerService
} from '../../../../http.services/retail/insurance/insurance-retail-manager/insurance-retail-manager.service'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
    CheckoutOrderFinalisationManagerService
} from '../../../../http.services/retail/checkout/checkout-order-finalisation-manager/checkout-order-finalisation-manager.service'
import {
    IdentifierManagerService
} from '../../../../http.services/identification/identifier-manager/identifier-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any

@Component({
    selector: 'app-extend-insurance-policy',
    templateUrl: './extend-insurance-policy.component.html',
    styleUrls: ['./extend-insurance-policy.component.scss']
})
export class ExtendInsurancePolicyComponent extends FormComponent implements OnInit {

    @Input() orderItems: OrderItem[]
    @Input() action = ProductActionEnum.ADD_TO_CART
    @Input() policyNumber: string
    @Input() endDate: any
    @Input() item: ExtensionInsurancePolicyCartItem

    @Output() onClose = new EventEmitter<boolean>()

    insuranceCategory = InsuranceCategory
    productActionEnum = ProductActionEnum

    captureImages = false
    showBreakdown = true
    submitting = false
    scrollPosition = 0

    activeToast: ActiveToast<any>

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                private toastr: ErrorToastService,
                private unMarshallerService: UnMarshallerService,
                public insuranceProductService: InsuranceProductService,
                private insuranceRetailManagerService: InsuranceRetailManagerService,
                private quickStartService: QuickTransactManagerService,
                private checkoutOrderFinalisationManagerService: CheckoutOrderFinalisationManagerService,
                private RetailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private identifierManagerService: IdentifierManagerService,
                private router: Router) {
        super(translate, translateParser)
    }

    ngOnInit(): void {
        this.insuranceProductService.initialiseOrderItems(this.orderItems, this.action, this.policyNumber, this.endDate)
    }

    onCloseCaptureImages(vehicleImageFilesMap) {
        if (vehicleImageFilesMap) {
            this.insuranceProductService.vehicleImageFilesMap = vehicleImageFilesMap
        }
        this.captureImages = false
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            container.animate({
                scrollTop: this.scrollPosition
            }, 0)
        })
    }

    submit(): void {

        if (!this.submitting) {
            this.submitting = true
            if (this.insuranceProductService.selectedVehicles.length === 0) {
                this.scrollToVehicleSelect()
                return
            }
            this.insuranceProductService.invalidPriceSchedule = false
            if (!this.insuranceProductService.selectedPriceSchedule) {
                this.insuranceProductService.invalidPriceSchedule = true
                ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
                return
            }

            switch (this.insuranceProductService.action) {
                case ProductActionEnum.ADD_TO_CART:
                    this.addToCart()
                    break
                case ProductActionEnum.UPDATE_ITEM:
                    this.updateItem()
                    break
            }
        }

    }

    updateItem() {

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
        retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

        const finaliseExtendCheckoutOrderRequest = new FinaliseExtendCheckoutOrderRequest()
        finaliseExtendCheckoutOrderRequest.updateItem = this.item
        finaliseExtendCheckoutOrderRequest.expiryDate = this.insuranceProductService.endDate.format(environment.formatDateServer) as any
        finaliseExtendCheckoutOrderRequest.retailProfileIdentifier = retailProfileOwnerIdentifier
        finaliseExtendCheckoutOrderRequest.pricePerVehicle = this.insuranceProductService.pricePerVehicles[0]

        this.startLoad()
        this.checkoutOrderFinalisationManagerService.finaliseExtendCheckoutOrder(finaliseExtendCheckoutOrderRequest).then((data) => {

            const response = this.unMarshallerService.unMarshallFromJson(data.body, FinaliseExtendCheckoutOrderResponse) as FinaliseExtendCheckoutOrderResponse
            this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage

            this.item.expiryDate = finaliseExtendCheckoutOrderRequest.expiryDate
            this.stopLoad()
            this.refreshRetailProfile(response.verifiedVehicles)
            this.submitting = false

        }).catch(() => {
            this.stopLoad()
            this.submitting = false
        })

    }

    addToCart() {

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
        if (this.quickStartService.retailProfile) {
            retailProfileOwnerIdentifier.tpiIdentifier = this.quickStartService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        } else {
            retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        }




        this.startLoad()
        const orderItems = this.insuranceProductService.getSelectedOrderItems();
        const createIdentifierRequest = new CreateIdentifierRequest()
        createIdentifierRequest.type = IdentifierType.ORDER_ITEM_NUMBER;
        createIdentifierRequest.quantity = orderItems.length;

        this.identifierManagerService.createIdentifier(createIdentifierRequest).then((response) => {
            const identifiers = response.body.identifier;
            let index = 0;
            const orderItemNumbers: string[] = []
            for (const orderItem of identifiers) {
                orderItemNumbers.push(orderItem);
                index++;
            }

            const addExtensionToCartRequest = new AddExtensionToCartRequest()
            addExtensionToCartRequest.policyNumber = this.policyNumber;
            addExtensionToCartRequest.orderItemNumbers = orderItemNumbers;
            addExtensionToCartRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
            addExtensionToCartRequest.expiryDate = this.insuranceProductService.endDate.format(environment.formatDateServer) as any;
            addExtensionToCartRequest.orderItems = orderItems;
            addExtensionToCartRequest.pricePerVehicles = this.insuranceProductService.pricePerVehicles;

            this.startLoad()
            this.insuranceRetailManagerService.addToCartExtension(addExtensionToCartRequest).subscribe((data) => {
                const response = this.unMarshallerService.unMarshallFromJson(data.body, AddExtensionToCartResponse) as AddExtensionToCartResponse

                this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage
                if (response.verifiedVehicles.length > 0) {
                    this.toastAddToCart();
                    this.router.navigate(['/retail/checkout']);
                }

                this.stopLoad()
                this.refreshRetailProfile(response.verifiedVehicles)
                this.submitting = false

            })
        });


    }

    refreshRetailProfile(verifiedVehicles) {
        this.RetailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
            this.insuranceProductService.clearVehicles(verifiedVehicles)
            if (this.insuranceProductService.unverifiedVehicleMessage.length === 0) {
                this.scrollToVehicleSelect()
            } else {
                ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
            }
            console.log(this.insuranceProductService.vehicles.length)
            if (this.insuranceProductService.vehicles.length === 0) {
                this.onClose.emit(true)
            }
        })
    }

    scrollToVehicleSelect() {
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            const scrollTo = jQuery('#vehicle-select')
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top - 50 + container.scrollTop()
            })
        })
    }

    toggleBreakDown(show: boolean) {
        this.showBreakdown = show
        return false
    }

    toastAddToCart() {

        this.translate.get('TOAST.ADD_TO_CART').subscribe((res) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            })
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy()
                        break
                }
            })
            this.activeToast.onHidden.subscribe(() => {

            })
        })
    }

}
