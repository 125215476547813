import {Component, OnInit} from '@angular/core'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'


@Component({
    selector: 'app-payment-terms-conditions',
    templateUrl: './payment-terms-conditions.component.html',
    styleUrls: ['./payment-terms-conditions.component.scss']
})
export class PaymentTermsConditionsComponent implements OnInit {

    constructor(public runtimeConfigurationService: RuntimeConfigurationService) { }

    ngOnInit() {
    }

}
