import { NaturalPersonIdentifier } from "../../../../../../../entity/identification/models/natural-person-identifier.model";
import {Request} from '../../../../../../../urdad/services/models/request.model';

export class ForgotPasswordRequest extends Request{

    constructor(){
        super();
    }

    public naturalPersonIdentifier: NaturalPersonIdentifier;
}