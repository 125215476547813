import { LevyType } from "../../../financial/models/levy-type.model";
import { Money } from "../../../financial/models/money.model";
import { AgentIdentifier } from "../../../entity/identification/models/agent-identifier.model";
import { OrderItem } from "./order-item.model";
import { OrderStatus } from "./order-status.model";

export class Order{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.Order";
    } 

    public orderDate: Date;
    public status: OrderStatus;
    public statusDate: Date;
    public number: string;
    public agentIdentifier: AgentIdentifier;
    public orderItems: OrderItem [] = [];
    public subTotal: Money;
    public vat: Money;
    public vatRate: number;
    public levy: Money;
    public levyType: LevyType;
    public totalDue: Money;
    public sellerBranch: string;
}
