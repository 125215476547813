import { PlateNumber } from "../identification/models/plate-number.model";
import { LegalEntity } from "../../entity/models/legal-entity.model";
import { VehicleLicence } from "./vehicle-licence.model";

export class VehicleRegistration{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.vehicle.VehicleRegistration";
    }

	public effectiveFrom: Date;
    public effectiveTo: Date;
	public owner: LegalEntity;
	public titleHolder: LegalEntity;
	public vehicleLicences: VehicleLicence[] = [];
	public plateNumbers : PlateNumber[] = [];
	public countryOfRegistration: string;
}