import { Request } from "../../../../../../../urdad/services/models/request.model";
import { Credentials } from "../../../../../../credentials/models/credentials.model";
import { NaturalPerson } from "../../../../../../../entity/models/natural-person.model";


export class CompileAccountRequest extends Request{
    constructor(){ 
        super();
    }

    public accountNumber: string;
    public credentials: Credentials;
    public naturalPerson: NaturalPerson;
}