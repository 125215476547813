import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {FinaliseSyncVehicleRequest, InitialiseSyncVehicleRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SyncVehicleRetailProfileFinalisationManagerService {

    basePath = '/retail/profile/syncvehicleretailprofilefinalisationmanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseSyncVehicle(initialiseSyncVehicleRequest: InitialiseSyncVehicleRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/initialisesyncvehicle';
        return this.http.post(path, initialiseSyncVehicleRequest, this.options);
    }

    finaliseSyncVehicle(finaliseSyncVehicleRequest: FinaliseSyncVehicleRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisesyncvehicle';
        return this.http.post(path, finaliseSyncVehicleRequest, this.options);
    }
}
