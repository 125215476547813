import {Pipe, PipeTransform} from '@angular/core';
import {CountryTaxesCountryCriteria, ProvideCountryTaxesInformationRequest, Product, Money} from '@magnabc/tpi';
import { CountryTaxesInformationProviderService } from '../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service';
import {StringUtils} from '../../../common/utils/utils.string';

@Pipe({name: 'formatSimplePrice', pure: false})
export class FormatSimplePricePipe implements PipeTransform {

    constructor() {}

    transform(value: any): string {
        if (value instanceof Money || value.amount) {
            return StringUtils.formatCents(value.amount);
        }
        return `${StringUtils.formatCents(value)}`;
    }
}
