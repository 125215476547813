import {Request} from '../../../../../../../urdad/services/models/request.model';
import { Item } from '../../../../../../checkout/models/item.model';
import { RetailProfileIdentifier } from '../../../../../identification/retail-profile-identifier.model';

export class FinaliseUpdateItemRequest extends Request{
    constructor(){
        super();
    } 

    public items: Item[];
    public retailProfileIdentifier: RetailProfileIdentifier;
}
