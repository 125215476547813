import {Component, OnInit} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-sales-report-page',
    templateUrl: './sales-report-page.component.html',
    styleUrls: ['./sales-report-page.component.scss']
})
export class SalesReportPageComponent implements OnInit {

    backPressed:boolean;
    loading = false;
    constructor(private title: Title,
                private runtimeConfigurationService: RuntimeConfigurationService) {

        title.setTitle(`Sales Report - Console - ${runtimeConfigurationService.title}`);

    }

    ngOnInit() {
    }

}
