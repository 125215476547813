import {Criteria} from "../../../../criteria/models/criteria.model";
import {BrokerIdentifier} from "../../identification/models/broker-identifier.model";
import {InsuranceCategory} from "../../models/insurance-category.model";

export class InsuranceProductBrokerCriteria extends Criteria{
    constructor(){
        super();
        this['@class']="za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.InsuranceProductBrokerCriteria"
    }

    public brokers: BrokerIdentifier [] = [];
    public insuranceCategory: InsuranceCategory;
    public active: boolean;
}
