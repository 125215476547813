import {Component} from "@angular/core";
import {Toast, ToastPackage, ToastrService} from "ngx-toastr";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-toast-component',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                display: 'none',
                opacity: 0
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false,
})
export class ToastComponent extends Toast {

    public static PRIMARY = 0;
    public static SECONDARY = 1;

    primaryButton;
    secondaryButton;

    imagePath: string;

    constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
        super(toastrService, toastPackage);

    }

    primaryClicked(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction(ToastComponent.PRIMARY);
        return false;
    }

    secondaryClicked(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction(ToastComponent.SECONDARY);
        return false;
    }

}
