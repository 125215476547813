import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, ValidationErrors, Validators} from "@angular/forms";
import {FormComponent} from "../form/form.component";
import { EmailAddress } from '@magnabc/tpi';

declare const jQuery: any;
@Component({
    selector: 'app-email-address',
    templateUrl: './email_address.component.html'
})
export class EmailAddressComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() emailAddress: EmailAddress;
    @Input() title = "";
    @Input() required = true;
    @Input() disabled = false;
    @Output() onEmailAddress = new EventEmitter<EmailAddress>();
    @Output() onValid = new EventEmitter<void>();

    formGroup: UntypedFormGroup;
    address: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        if (this.emailAddress) {
            this.address = new UntypedFormControl(this.emailAddress.address, [
                this.customEmailValidator,
                Validators.maxLength(254),
                Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
            ]);
        } else {
            const newEmailAddress = new EmailAddress();
            newEmailAddress.address ="";
            newEmailAddress.emailAddressType = "primary"
            this.emailAddress = newEmailAddress;
            this.address = new UntypedFormControl('', [
                this.customEmailValidator,
                Validators.maxLength(254),
                Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
            ]);
        }
        this.formGroup = new UntypedFormGroup({
            'address': this.address,
        });

        if (this.required) {
            this.address.setValidators([Validators.required, Validators.email]);
        }

        if(this.disabled){
            this.address.disable();
        }

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    customEmailValidator(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return null;
        }

        return Validators.email(control);
    }

    submit(): void {
        document.getElementById('form-submit-email-address-' + this.title).click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            this.emailAddress.address = this.address.value;
            this.emailAddress.emailAddressType = 'Primary';

            this.onEmailAddress.emit(this.emailAddress);
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    isDirty() {
        return this.form.dirty;
    }
}
