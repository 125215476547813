import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    CalculateRealTimeExtensionPriceRequest,
    CalculateRealTimePriceRequest,
    CalculateRealTimeYCPriceRequest,
    MarshallerService
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class RealTimePricingCalculationManagerService {

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    calculateRealTimePrice(calculateRealTimePriceRequest: CalculateRealTimePriceRequest) {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/thirdpartyinsurance/realtimepricingcalculationmanager/calculaterealtimeprice';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(calculateRealTimePriceRequest);
        return this.httpClient.post<any>(url, jsonRequest, this.options).toPromise();
    }

    calculateRealTimeExtensionPrice(calculateRealTimeExtensionPriceRequest: CalculateRealTimeExtensionPriceRequest) {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/thirdpartyinsurance/realtimepricingcalculationmanager/calculaterealtimeextensionprice';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(calculateRealTimeExtensionPriceRequest);
        return this.httpClient.post<any>(url, jsonRequest, this.options).toPromise();
    }

    calculateRealTimeYCPrice(calculateRealTimeYCPriceRequest: CalculateRealTimeYCPriceRequest): Promise<any> {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/thirdpartyinsurance/realtimepricingcalculationmanager/calculaterealtimeycprice';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(calculateRealTimeYCPriceRequest);
        return this.httpClient.post<any>(url, jsonRequest, this.options).toPromise();
    }
}
