import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OneTimePinService {

    otpIdentifier = '';

    constructor() {}

    setOneTimePinIdentifier(response: any) {
        return new Promise<any>((resolve, reject) => {
            try {
                this.otpIdentifier = response.body.oneTimePinIdentifier.identifier;
                resolve(true);
            } catch (e) {
                reject(false);
            }
        });
    }
}
