import { Request } from '../../../../../../../urdad/services/models/request.model';
import { Make, Model, Vehicle } from '../../../../../../../vehicle/vehicle_api';
import { RetailProfileIdentifier } from '../../../../../profile_api';

export class FinaliseLinkVehicleRequest extends Request{

    constructor() {
        super();
    }

    public retailProfileIdentifier: RetailProfileIdentifier;
    public vehicle: Vehicle;
    public unverifiedMake: Make;
    public unverifiedModel: Model;
}

