import {Request} from '../../../../../../../urdad/services/models/request.model';
import {MakeIdentifier} from '../../../../../../identification/models/make-identifier.model';

export class FinaliseDeleteVehicleModelRequest extends Request {
    constructor() {
        super();
    }

    public makeIdentifier: MakeIdentifier;
}
