import {AfterViewInit, Component, EventEmitter, Input, Output} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";

declare let jQuery: any;
@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements AfterViewInit {

    static DEFAULT_ICON = "fa-info";
    static DEFAULT_BORDER_COLOR = "#1592E6";

    @Input() steps;
    @Input() mini = false;

    step: number;
    @Input() set currentStep(currentStep) {
        this.step = currentStep;
        setTimeout(() => {
            StepsComponent.tooltip();
        });
    }

    get currentStep(): number {
        return this.step;
    }

    @Output() onStep = new EventEmitter<any>();

    static tooltip(): void {
        jQuery('[data-toggle="tooltip"]').tooltip();
    }

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {}

    ngAfterViewInit(): void {
        StepsComponent.tooltip();

        if (!this.steps) {
            this.steps = [];
            for (let i = 0; i < 3; i++) {
                this.translate.get("COMMON.STEP_X").subscribe((response) => {
                    this.steps.push({
                        id: i,
                        name: this.translateParser.interpolate(response, {value: i}),
                        icon: StepsComponent.DEFAULT_ICON,
                        borderColor: StepsComponent.DEFAULT_BORDER_COLOR
                    });
                });
            }
        }
    }

    nextStepHidden(i): boolean {
        if (!this.steps[i + 1]) {
            return false;
        }
        return !!this.steps[i + 1].hidden && (i + 1) >= this.currentStep;
    }

    getStepCount(): number {
        let count = 0;
        for (const s of this.steps) {
            if (!s.hidden) {
                count++;
            }
        }
        return count;
    }

}
