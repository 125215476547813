import { ScannedDocument } from '../../../../media/scanneddocument/models/scanned-document.model';
import { SnapshotVehicle } from '../../../checkout/models/snapshot-vehicle.model';
import { YCInsuranceOrderItem } from './yc-insurance-order-item.model';
import { OrderYCInsuranceProduct } from './order-yc-insurance-product.model';

export class YCInsurancePolicyOrderItem extends YCInsuranceOrderItem{

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem";
    }

    public startDate: Date
    public expiryDate: Date
    public orderTYCInsuranceProduct: OrderYCInsuranceProduct
    public snapshotVehicle: SnapshotVehicle
    public scannedDocument: ScannedDocument[] = []
}
