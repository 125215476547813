import { Response } from "../../../../../../urdad/services/models/response.model";
import { Criteria } from "../../../../../../criteria/models/criteria.model";
import { FinancialAccount } from "../../../../models/financial-account.model";

export class ProvideFinancialAccountInformationResponse extends Response{
    constructor(){ 
        super();
    }

    public resultsFrom: number;
    public resultsTo : number;
    public totalNumberResults : number;
    public financialAccounts: FinancialAccount [] = [];
}