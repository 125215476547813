import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";
import {Limit} from "../../models/limit.model";
import {InsuranceProductTemplate} from "../../models/insurance-product-template.model";
import {Excess} from "../../models/excess.model";
import {InsuranceVehicleCategory} from "../../setup/models/insurance-vehicle-category.model";
import {Duration} from "../../../../temporal/models/duration.model";
import {RecommendedPrice} from "./recommended-price.model";
import {InsuranceType} from "../../setup/models/insurance-type.model";

export class ThirdPartyInsuranceProductTemplate extends InsuranceProductTemplate {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsuranceProductTemplate";
    }

    public insuranceType: InsuranceType;
    public excess: Excess[] = [];
    public limit: Limit[] = [];
    public countryIdentifier: CountryIdentifier;
    public insuranceVehicleCategory: InsuranceVehicleCategory;
    public coveragePeriod: Duration;
    public recommendedPrice: RecommendedPrice;

}
