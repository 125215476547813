import {Response} from '../../../../../../../urdad/services/models/response.model';
import {VehicleIdentifier} from '../../../../../../../vehicle/identification/models/vehicle-identifier.model';

export class FinaliseExtendCheckoutOrderResponse extends Response {
    constructor() {
        super();
    }

    public verifiedVehicles: VehicleIdentifier[] = [];
    public unverifiedVehicleMessage: string[] = []

}