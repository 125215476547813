import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, VerifyCardPaymentRequest}from '@magnabc/tpi'
import {Observable} from "rxjs"
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class DirectPayOnlineCardPaymentVerifierService {

    basePath = '/retail/payment/dpo/directpayonlinecardpaymentverifier';

    constructor(private httpClient: HttpClient,
                private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    verifyCardPayment(verifyCardPaymentRequest: VerifyCardPaymentRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/verifycardpaymentrequest';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(verifyCardPaymentRequest);
        return this.httpClient.post<any>(path, jsonRequest, this.options);
    }

}
