import { InsuranceTypeSelectorModule } from './insurance-type-selector/insurance-type-selector/insurance-type-selector.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { YellowCardProductViewModule } from './insurance-product-manager/yellow-card-product-view/yellow-card-product-view.module';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
    RetailProductsTemplateComponent
} from './insurance-product-manager/product-template/retail.products.template.component'
import {
    RetailProductsDetailsComponent
} from './insurance-product-manager/create-product-details/product-details/retail.products.details.component'
import { RetailProductsPriceComponent } from './insurance-product-manager/product-price/retail.products.price.component'
import {
    DialogAcknowledgePrintSuccessfulComponent,
    DialogCaptureCertificateNumberComponent
} from './certificate/issue-certificates/issue-certificates.component'
import { ProductCardComponent } from './insurance-product-manager/product-card/product-card.component'
import { ProductComponent } from './insurance-product-information-provider/product/product.component'
import { ZoomDirective } from '../../shared/directives/zoom.directive'
import {
    InsurancePolicyManagerExtensionDialogComponent
} from './insurance-policy-manager-extension-dialog/insurance-policy-manager-extension-dialog.component'
import { ProductExtensionComponent } from './insurance-product-manager/product-extension/product-extension.component'
import { ProductDetailsComponent } from './insurance-product-manager/product-details/product-details.component'
import {
    ProductInformationComponent
} from './insurance-product-information-provider/product-information/product-information.component'
import {
    ProductInformationLimitsComponent
} from './insurance-product-information-provider/product-information-limits/product-information-limits.component'
import {
    ProductInformationExcessComponent
} from './insurance-product-information-provider/product-information-excess/product-information-excess.component'
import {
    ProductVehicleSelectionComponent
} from './insurance-product-information-provider/product-vehicle-selection/product-vehicle-selection.component'
import {
    ProductInformationPriceScheduleComponent
} from './insurance-product-information-provider/product-information-price-schedule/product-information-price-schedule.component'
import {
    ProductDateSelectionComponent
} from './insurance-product-information-provider/product-date-selection/product-date-selection.component'
import {
    ProductBreakdownComponent
} from './insurance-product-information-provider/product-breakdown/product-breakdown.component'
import { ExtendInsurancePolicyComponent } from './extend-insurance-policy/extend-insurance-policy.component'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '../../shared/shared.module'
import { LoadingModule } from 'ngx-loading'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { MatFormFieldModule } from '@angular/material/form-field'
import { EntityModule } from '../../entity/entity.module'
import { MatSelectModule } from '@angular/material/select'
import { MatCardModule } from '@angular/material/card'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSliderModule } from '@angular/material/slider'
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop'
import { SecurityModule } from '../../security/security.module'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { VehicleModule } from '../../vehicle/vehicle.module'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { VehicleListComponent } from './vehicle-list/vehicle.list.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatInputModule } from '@angular/material/input'
import { CertificateModule } from './certificate/certificate.module'
import { QuickTransactModule } from './quick-transact/quick-transact.module'
import { ThirdPartyInsuranceModule } from './third-party-insurance/third-party-insurance.module';
import { InsurancePeriodComponent } from './insurance-period/insurance-period.component';
import { VehicleSelectorComponent } from './vehicle-selector/vehicle-selector.component';
import { ProductPriceScheduleComponent } from './insurance-product-manager/product-price-schedule/product-price-schedule.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ProductListComponent } from './insurance-product-manager/product-list/product-list.component';

@NgModule({
    declarations: [
        RetailProductsTemplateComponent,
        RetailProductsDetailsComponent,
        RetailProductsPriceComponent,
        DialogCaptureCertificateNumberComponent,
        DialogAcknowledgePrintSuccessfulComponent,
        ProductCardComponent,
        ProductComponent,
        ZoomDirective,
        InsurancePolicyManagerExtensionDialogComponent,
        ProductExtensionComponent,
        ProductDetailsComponent,
        ProductInformationComponent,
        ProductInformationLimitsComponent,
        ProductInformationExcessComponent,
        ProductVehicleSelectionComponent,
        ProductInformationPriceScheduleComponent,
        ProductDateSelectionComponent,
        ProductBreakdownComponent,
        ExtendInsurancePolicyComponent,
        VehicleListComponent,
        InsurancePeriodComponent,
        VehicleSelectorComponent,
        ProductListComponent

    ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        SharedModule,
        SecurityModule,
        CertificateModule,
        EntityModule,
        VehicleModule,
        NgxDatatableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatDatepickerModule,
        MatSliderModule,
        CdkDropList,
        CdkDrag,
        MatCheckboxModule,
        MatMenuModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatInputModule,
        QuickTransactModule,
        ThirdPartyInsuranceModule,
        YellowCardProductViewModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        InsuranceTypeSelectorModule
    ],
    exports: [
        ZoomDirective,
        ProductComponent,
        VehicleListComponent,
        RetailProductsDetailsComponent,
        ProductDetailsComponent,
        ProductExtensionComponent,
        RetailProductsPriceComponent,
        ProductCardComponent,
        InsurancePeriodComponent,
        VehicleSelectorComponent,
        InsuranceTypeSelectorModule,
        ProductListComponent
    ]
})
export class InsuranceModule { }
