import { Request } from "../../../../../../../urdad/services/models/request.model";
import { OneTimeLinkContext } from "../../../../../../onetimelink/models/one-time-link-context.model";

export class GenerateIdentifierTokenRequest extends Request {
    constructor(){ 
       super();
    }

    public transactionIdentifier: string;
    public context: OneTimeLinkContext;

}