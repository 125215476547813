import { Criteria } from "../../../criteria/models/criteria.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class SubscriptionOwnedByLegalEntityCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionOwnedByLegalEntityCriteria";
    }

    public legalEntityIdentifier: TpiIdentifier;
}
