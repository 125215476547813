import { Criteria } from "../../../criteria/models/criteria.model";
import { OrderIdentifier } from "../identification/models/order-identifier.model";

export class OrderDateCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderDateCriteria";
    }
    
    public dateFrom : Date;
    public dateTo : Date;
    public orderIdentifiers: OrderIdentifier [] = [];
}