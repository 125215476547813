import {Injectable} from '@angular/core';
import {ContactNumber, JudicialPerson, LegalEntity, NaturalPerson, NaturalPersonIdentifier, RetailProfile} from '@magnabc/tpi';

@Injectable({
    providedIn: 'root'
})
export class QuickTransactManagerService {

    legalEntity: LegalEntity;
    contactNumber: ContactNumber;
    exists = false;
    jwt: any;
    currentStep = 0;
    retailProfile: RetailProfile;
    selectedLegalEntity: LegalEntity;
    type = 'personal';
    judicial = false;
    showPopup = 0;
    trn = false;

    /* Temp used to get a JWT */
    loadQuickTransact = false;
    isAssistedQuickTransact = false;
    npIdentifier: NaturalPersonIdentifier;

    constructor() { }

    getLegalEntityNames() {
        if (this.legalEntity instanceof NaturalPerson) {
            return {
                name: this.legalEntity.firstNames,
                surname: this.legalEntity.surname
            }
        } else if (this.legalEntity instanceof JudicialPerson) {
            return {
                name: this.legalEntity.name,
                surname: null
            }
        } else {
            return null;
        }
    }

    reset() {
        this.legalEntity = null;
        this.contactNumber = null;
        this.exists = false;
        this.jwt = null;
        this.currentStep = 0;
        this.selectedLegalEntity = null;
        this.type = 'personal';
        this.judicial = false;
        this.retailProfile = null;
        this.showPopup = 0;
        this.loadQuickTransact = false;
        this.isAssistedQuickTransact = false;
        this.npIdentifier = null;
        this.trn = false;
    }
}
