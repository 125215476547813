import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ServiceGroupFinalisationManagerService} from "../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service";
import {FormComponent} from "../../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ServiceIdentifierProviderService} from "../../../../http.services/security/authorisation/identification/service-identifier-provider/service-identifier-provider.service";
import {
    ProvideServiceIdentifiersRequest,
    ProvideServiceIdentifiersResponse,
    ServiceGroup,
    ServiceIdentifierTypeCriteria,
    ServiceNameTypeIdentifier,
    UnMarshallerService
} from '@magnabc/tpi';

@Component({
  selector: 'app-service-group',
  templateUrl: './service-group.component.html',
  styleUrls: ['./service-group.component.scss']
})
export class ServiceGroupComponent extends FormComponent implements OnInit {

    @Input() serviceGroup: ServiceGroup;
    @Input() editing = false;
    @Output() onServiceGroup = new EventEmitter<ServiceGroup>();

    serviceList = [];

    serviceGroupTypeList: string[] = [
        'PUBLIC',
        'RETAIL',
        'ADMINISTRATOR',
        'AGENT',
        'BROKER',
        'AUTHORITY',
    ];

    formGroup: UntypedFormGroup;
    name: UntypedFormControl;
    description: UntypedFormControl;
    services: UntypedFormControl;
    active: UntypedFormControl;
    serviceGroupType: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translateService: TranslateService,
                private translateParser: TranslateParser,
                private ServiceGroupManagerService: ServiceGroupFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private serviceIdentifierProviderService: ServiceIdentifierProviderService) {
        super(translateService, translateParser);
    }

    initServiceGroup(): void {
        this.serviceGroup = new ServiceGroup();
        this.name.setValue('');
        this.description.setValue('');
        this.active.setValue(true);
        this.services.setValue([]);
    }

    ngOnInit() {

        if (!this.serviceGroup) {

            this.name = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(50)
            ]);

            this.description = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(1024)
            ]);

            this.services = new UntypedFormControl([], [
                Validators.required
            ]);

            this.active = new UntypedFormControl(true, [
                Validators.required
            ]);

            this.serviceGroupType = new UntypedFormControl(null, [
                Validators.required,
            ]);

            this.serviceGroupType.setValue(this.serviceGroupTypeList[0]);

        } else {

            this.name = new UntypedFormControl(this.serviceGroup.name, [
                Validators.required,
                Validators.maxLength(50)
            ]);

            this.description = new UntypedFormControl(this.serviceGroup.description, [
                Validators.required,
                Validators.maxLength(1024)
            ]);

            this.services = new UntypedFormControl(this.serviceGroup.linked, [
                Validators.required
            ]);

            this.active = new UntypedFormControl(this.serviceGroup.active, [
                Validators.required
            ]);

            this.serviceGroupType = new UntypedFormControl(null, [
                Validators.required,
            ]);

            if (this.serviceGroup.type) {
                for (const serviceGroupType of this.serviceGroupTypeList) {
                    if (serviceGroupType.toUpperCase() === this.serviceGroup.type.toUpperCase()) {
                        this.serviceGroupType.setValue(serviceGroupType);
                        break;
                    }
                }
            }

        }

        this.getAllServices();

        if (this.editing) {
            this.name.disable();
            this.serviceGroupType.disable();
        }

        this.formGroup = new UntypedFormGroup({
            'name': this.name,
            'serviceGroupType': this.serviceGroupType,
            'description': this.description,
            'services': this.services,
            'active': this.active
        });

    }

    getAllServices() {

        const promises = [];

        for (const type of this.serviceGroupTypeList) {
            promises.push(this.getServices(type));
        }

        Promise.all(promises).then((results) => {

            const services = [];

            for (const list of results) {
                for (const i of list) {
                    if(!(services.filter(s => s.name === i.name).length>0))
                    {
                        services.push(i);
                    }
                }
            }
            // console.log(services);

            this.serviceList = services;
            this.serviceList.sort((first, second) => {
                if (first.name > second.name) {
                    return 1;
                }
                if (first.name < second.name) {
                    return -1;
                }
                return 0;
            });

            const temp = [];
            for (const s1 of this.serviceList) {
                for (const s2 of this.serviceGroup.linked) {
                    if (s1.name === (s2 as any).name) {
                        temp.push(s1);
                    }
                }
            }
            this.services.setValue(temp);

        });

    }

    getServices(type): Promise<ServiceNameTypeIdentifier[]> {
        return new Promise<ServiceNameTypeIdentifier[]>((resolve) => {
            const criteria = new ServiceIdentifierTypeCriteria();
            criteria.identifierType = type;

            const provideServiceIdentifiersRequest = new ProvideServiceIdentifiersRequest();
            provideServiceIdentifiersRequest.criteria = criteria;
            this.serviceIdentifierProviderService.provideServiceIdentifiers(provideServiceIdentifiersRequest).subscribe((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideServiceIdentifiersResponse) as ProvideServiceIdentifiersResponse);
                    resolve((response as any).serviceIdentifiers);
                }

            });

        });
    }

    submit(): void {
        document.getElementById('form-submit-service-group').click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            this.serviceGroup.name = this.name.value;
            this.serviceGroup.type = this.serviceGroupType.value;
            this.serviceGroup.description = this.description.value;
            this.serviceGroup.active = this.active.value;
            this.serviceGroup.linked = this.services.value;
            this.onServiceGroup.emit(this.serviceGroup);
        }

    }

}
