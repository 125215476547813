import { Criteria } from "../../../criteria/criteria_api";
import { FinancialAccountIdentifier } from "../identification/models/financial-account-identifier.model";

export class FinancialAccountNumberCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.FinancialAccountNumberCriteria";
    }

    public accountIdentifiers : FinancialAccountIdentifier [] = [];
}