import { LegalEntityIdentifier } from "../../../../entity/identification/models/legal-entity-identifier.model";
import { Answers } from "./answers.model";
import { ProposalPolicyItem } from "./proposal-policy-item.model";

export class Proposal {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.Proposal";
    }

    public proposalFormId: string;
    public proposalId: string;
    public customer: LegalEntityIdentifier;
    public allowableScore: number;
    public totalScore: number;
    public proposalPolicyItems: ProposalPolicyItem[] = [];
    public answers: Answers[] = [];
}