import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MarshallerService, ResendVerificationLinkRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class AccountVerificationLinkResenderService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  resendVerificationLink(resendVerificationLinkRequest: ResendVerificationLinkRequest) {
      const path = this.runtimeConfigurationService.apiBaseUrl + '/security/account/accountverificationlinkresender/resendverificationlink';

      const jsonRequest = this.marshallerService.marshallObjectToJSON(resendVerificationLinkRequest);
      return this.httpClient.post<any>(path, jsonRequest, this.options).toPromise();
  }
}
