import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";
import { OrderStatus } from "./order-status.model";

export class OrderSummaryLegalEntityCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderSummaryLegalEntityCriteria";
    }
    
    public status: OrderStatus;
    public legalEntityIdentifier: LegalEntityIdentifier;
}
