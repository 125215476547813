import { Money } from "../../../financial/models/money.model";
import { InsuranceCategory } from "../../../retail/insurance/models/insurance-category.model";
import { Duration } from "../../../temporal/models/duration.model";

export class InsuranceSalesByProductEntry
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.InsuranceSalesByProductEntry";
    }

    public productName: string;
    public productDescription: string;
    public insuranceType: string;
    public insuranceCategory: InsuranceCategory;
    public insuranceVehicleCategoryDescription: string;
    public netQuantity: number;
    public grossSales: Money;
    public netSales: Money;
}
