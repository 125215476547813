import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {SupplierInvoicesComponent} from './supplier-invoices/supplier-invoices.component'
import {CustomerInvoicesComponent} from './customer-invoices/customer-invoices.component'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {SharedModule} from '../../shared/shared.module'
import {LoadingModule} from 'ngx-loading'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {MatTabsModule} from '@angular/material/tabs'
import { MatInputModule } from '@angular/material/input'

@NgModule({
    declarations: [
        SupplierInvoicesComponent,
        CustomerInvoicesComponent
    ],
    exports: [
        CustomerInvoicesComponent,
        SupplierInvoicesComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LoadingModule,
        MatFormFieldModule,
        MatDatepickerModule,
        NgxDatatableModule,
        MatTabsModule,
        MatInputModule
    ]
})
export class InvoiceModule { }
