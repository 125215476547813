import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { ProposalRecordSummary } from '../../../../../models/proposal-record-summary.model';

export class FindProposalRecordSummaryResponse extends Response {
    constructor(){
        super();
    }

    public proposalRecordSummaries: ProposalRecordSummary [] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
}