import { Criteria } from "../../../criteria/criteria_api";
import { AccountingTransactionIdentifier } from "../identification/models/accounting-transaction-identifier.model";

export class AccountingTransactionReferenceLatestCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.accountingtransaction.AccountingTransactionReferenceLatestCriteria";
    }

    public accountingTransactionIdentifiers : AccountingTransactionIdentifier [] = [];
}