import { Metadata } from "./metadata.model";


export class KeyValue extends Metadata{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.metadata.KeyValue";
    }

    public key : string;
    public value : string;
}