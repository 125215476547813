import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, ProvideAgentInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class AgentInformationProviderService {
    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    provideAgentInformation(provideAgentInformationRequest: ProvideAgentInformationRequest) {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const provideAgentInformationURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/agentinformationprovider/provideagentinformation';

        const jsonProvideAgentInformationRequest = this.marshallerService.marshallObjectToJSON(provideAgentInformationRequest);

        return this.http.post<any>(provideAgentInformationURI, jsonProvideAgentInformationRequest, options).toPromise();
    }
}
