import {Request} from '../../../../../../../urdad/services/models/request.model';
import {LegalEntityIdentifier} from '../../../../../../../entity/identification/models/legal-entity-identifier.model';
import {Credentials} from '../../../../../../../security/credentials/models/credentials.model';
import {MotorInsurancePolicyItem} from '../../../../../models/motor-insurance-policy-item.model';

export class InitialiseCancelInsurancePolicyRequest extends Request{
    constructor(){
        super();
    }

    public policyNumber: string;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public credentials: Credentials;
    public naturalPerson: LegalEntityIdentifier;
    public motorInsurancePolicyItem: MotorInsurancePolicyItem;
    public tpiIdentifier: string;

}
