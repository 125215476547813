import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {MatDialog} from "@angular/material/dialog"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {Router} from "@angular/router"
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

    @Output() onSelected = new EventEmitter<void>();

    selected: string;
    onlinePaymentEnabled: boolean;

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                private dialog: MatDialog,
                private router: Router,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        this.onlinePaymentEnabled = this.runtimeConfigurationService.onlinePayment;
    }

    ngOnInit() {
    }

    onSelect(event): void {
        this.selected = 'cashier';
        this.onSelected.emit();
    }

    onPayOnline(event): void {
        this.router.navigate(['/retail/orders']);
    }

}
