import { Response } from "../../../../../urdad/urdad_api";
import { ProposalRecordSummary } from "../../comprehensiveinsurance_api";

export class ProvideProposalRecordSummaryResponse extends Response {
    constructor() {
        super();
    }

    public proposalRecordSummaries: ProposalRecordSummary[] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
}