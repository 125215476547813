import { ServiceIdentifier } from "../identification/models/service-identifier.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class ServiceGroup{
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.ServiceGroup";
    }

    public defaultGroup: boolean;
    public description: string;
    public linked: ServiceIdentifier[] = [];
    public name: string;
    public ownedBy: TpiIdentifier;
    public type: string;
    public active: boolean;
}
