import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    BasicPostalAddress,
    InsuranceCategory,
    InsurancePolicy,
    InsurancePolicyItem,
    MotorInsurancePolicy,
    MotorInsurancePolicyItem,
    MotorInsuranceVehiclePolicy,
    OrderNumberIdentifier,
    PdfDocument,
    PolicyWordingUnderwriterCategoryCriteria,
    ProvidePolicyWordingRequest,
    ProvidePolicyWordingResponse,
    ProvideRefundSummaryRequest,
    ProvideRefundSummaryResponse,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    PublicInsurancePolicySummary,
    RefundPolicyNumberCriteria,
    RenderedDocumentType,
    RenderedDocumentTypeIdCriteria,
    TpiIdentifier,
    Underwriter,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { environment } from '../../../../../../environments/environment';
import { StringUtils } from '../../../../../common/utils/utils.string';
import { ErrorToastService } from '../../../../../app.services/common/error-toast/error-toast.service';
import {
    RenderedConceptualDocumentProviderService,
} from '../../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service';
import {
    PolicyWordingProviderService,
} from '../../../../../http.services/retail/insurance/setup/policy-wording-provider/policy-wording-provider.service';
import {
    UnderwriterInformationProviderService,
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import {
    RefundProviderService,
} from '../../../../../http.services/retail/payment/refund-provider/refund-provider.service';
import {
    AuthenticationService,
} from '../../../../../app.services/managers/authentication/authentication.service';
import {
    PolicyAction,
} from '../../../../../view.pages/retail/insurance/third-party-insurance/query-policy-page/policy-action-enum';
import { FormComponent } from '../../../../shared/form/form.component';
import { ToastComponent } from '../../../../shared/toast/toast.component';
import { PrintService, PDFPrintModel, PDFDisplayModel } from '../../../../../app.services/common/printing/print.service';
import { Utils } from '../../../../shared/Utils/Utils';

declare const jQuery: any;
@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss']
})
export class ThirdPartyInsurancePolicyComponent extends FormComponent implements OnInit {

    @Input() policy: InsurancePolicy | MotorInsurancePolicy | PublicInsurancePolicySummary | any;
    @Input() showButtons = true;
    @Input() printButtonVisible = true;
    @Input() isPolicyView : boolean;
    @Input() summaryExtensionMap: {[id: string]: PublicInsurancePolicySummary} = {}
    @Input() activePolicy: any;
    @Output() onAction = new EventEmitter<any>()

    postalAddress: BasicPostalAddress | any;
    underwriters: {[id: string]: Underwriter} = {};

    policyAction = PolicyAction;
    active: any;
    previewDocument = false;
    documentToPreview: any;
    tsAndCsToDownolad: any[];
    numberOfPages:number;
    activeToast: ActiveToast<any>;
    isBackOfficeUser: boolean;
    newPolicies: MotorInsurancePolicyItem[] = []
    groupedPolicyExtensionMap: {[id: string]: MotorInsurancePolicyItem} = {}
    policyNumbersList: string[] = [];
    pdfScale = 4;
    insuranceCategory = InsuranceCategory;
    @Input() isPublicUserView: boolean;

    updating = new BehaviorSubject(false);

    constructor(private brokerService: UnderwriterInformationProviderService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private unMarshallerService: UnMarshallerService,
                private policyWordingProviderService: PolicyWordingProviderService,
                private translate: TranslateService,
                private translateParser: TranslateParser,
                private errorToastService: ErrorToastService,
                private toastr: ErrorToastService,
                private refundProviderService: RefundProviderService,
                private authenticationService: AuthenticationService,
                private printService: PrintService) {
        super(translate, translateParser);

    }

    ngOnInit() {
        if (this.policy && !this.policy.insurancePolicyItems) {
            this.policy.expiryDate = this.addExtraDay(this.policy.expiryDate);
        }
        this.isBackOfficeUser = this.authenticationService.isBackOfficeUser();
        this.brokerService.getAllUnderwriters((underwriters: Underwriter[]) => {
            for (const underwriter of underwriters) {
                this.underwriters[(underwriter.legalEntityIdentifier as TpiIdentifier).tpiIdentifier] = underwriter;
            }
        });
        if(this.policy && this.policy.underwriterIdentifier){
            this.brokerService.getUnderwriter(this.policy.underwriterIdentifier, (brokerIdentifier) => {
                this.underwriters[(this.policy.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name] = brokerIdentifier;
            });
        }
        if (this.policy.insurancePolicyItems) {
            let newPolicy: any;
            let filteredExtensions: any[] = [];
            for (const insurancePolicyItem of this.policy.insurancePolicyItems) {
                if (insurancePolicyItem.insurancePolicyItemType !== 'EXTENSION') {
                    this.newPolicies.push(insurancePolicyItem)
                } else if (insurancePolicyItem.insurancePolicyItemType === 'EXTENSION') {
                    newPolicy = insurancePolicyItem;
                    filteredExtensions.push(newPolicy);
                }
            }
            this.checkIfMoreThanOneExtensions(newPolicy, filteredExtensions);
        }
        if (this.isBackOfficeUser) {
            this.getRefundInformation(this.policy.number)
        }
    }

    /**
     * Checks if more than one extensions available
     * @param newPolicy
     * @param filteredExtensions
     */
    checkIfMoreThanOneExtensions(newPolicy, filteredExtensions) {
        // If the policy has more than one extension
        // One still active and the rest as cancelled
        if (newPolicy && newPolicy.insurancePolicyItemType === 'EXTENSION') {
            if (filteredExtensions.length !== 1) {
                filteredExtensions.forEach(element => {
                    if (!element.motorInsuranceVehiclePolicy.cancelled && moment(element.motorInsuranceVehiclePolicy.effectiveDate).isAfter(new Date)) {
                    newPolicy = element
                    } else if (element.motorInsuranceVehiclePolicy.cancelled) {
                    // If there is more than 1 cancelled extensions
                    // Take the latest one
                    newPolicy = filteredExtensions.reduce((a, b) => a.motorInsuranceVehiclePolicy.effectiveDate > b.motorInsuranceVehiclePolicy.effectiveDate ? a : b);
                    }
                });
            }
            this.groupedPolicyExtensionMap[newPolicy.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier] = newPolicy
        }
    }

    addExtraDay(date) {
        const newDate = new Date(date)
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1, 0, 0, 0);
   }

    formatDate(date): string {
        return moment(date).format("DD MMM YYYY");
    }

    isPolicyValid(policy: any){
        const extensionPolicy = this.summaryExtensionMap[policy.plateNumber];
        if(extensionPolicy) policy = extensionPolicy;
        const result = this.isExpired(policy.expiryDate) || this.isCancelled(policy);
        return result;
    }

    isCancelled(policy: MotorInsuranceVehiclePolicy): boolean{
        return policy.cancelled;
    }

    isPolicyExpired(policy: any): boolean{
        this.updating.next(true);
        let result: boolean;
        if(policy.plateNumber === this.activePolicy.plateNumber){
            result = true;
        }else{
            result = false;
        }
        setTimeout(() => {
            this.updating.next(false);
        },5000);
        return result;
    }

    isExpired(expiryDate: Date): boolean {
        return moment(new Date()).isAfter(moment(expiryDate).add(-1, 'day').endOf('day'));
    }

    isValid(policy: MotorInsuranceVehiclePolicy): boolean {
        if (this.isExpired(policy.expiryDate) || this.isCancelled(policy)) {
            return false;
        }
        return true;
    }

    getLimitType(value): string {
        return value.description;
    }

    formatCurrency(amount): string {
        return StringUtils.formatCents(amount);
    }

    printPolicySchedule(insurancePolicyItem: InsurancePolicyItem): boolean {

        var orderNumber = ((insurancePolicyItem as MotorInsurancePolicyItem).orderIdentifier as OrderNumberIdentifier).orderNumber
        var underwriterIdentifier =  (this.policy.underwriterIdentifier as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier;

        const renderedDocumentTypeIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentTypeIdCriteria.documentId = orderNumber+"-"+underwriterIdentifier;
        renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.POLICYSCHEDULE];

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentTypeIdCriteria;

        this.startLoad();
        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                this.documentToPreview = (provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument).encodedPdf;
                this.onPrintPreview();
            }
        },(error) => {
            this.stopLoad();
        });

        return false

    }

    printTsandCs(insurancePolicyItem: InsurancePolicyItem): boolean {

        const policyWordingUnderwriterCategoryCriteria = new PolicyWordingUnderwriterCategoryCriteria();

        policyWordingUnderwriterCategoryCriteria.underwriterIdentifier = this.policy.underwriterIdentifier;
        policyWordingUnderwriterCategoryCriteria.insuranceCategory = (insurancePolicyItem as MotorInsurancePolicyItem).insuranceCategory
        policyWordingUnderwriterCategoryCriteria.insuranceVehicleCategory = [(this.policy as MotorInsurancePolicy).insuranceVehicleCategoryDescription];

        const providePolicyWordingRequest = new ProvidePolicyWordingRequest();
        providePolicyWordingRequest.criteria = policyWordingUnderwriterCategoryCriteria;

        this.startLoad();
        this.policyWordingProviderService.providePolicyWording(providePolicyWordingRequest).then((response) => {
            const providePolicyWordingResponse = this.unMarshallerService.unMarshallFromJson(response.body, ProvidePolicyWordingResponse) as ProvidePolicyWordingResponse;
            this.tsAndCsToDownolad = providePolicyWordingResponse.policyWording;
            if(this.tsAndCsToDownolad) {
                this.downloadPdf();
                this.stopLoad();
            }else{
                this.toast();
            }
        }).catch(error =>{
            this.stopLoad();
            this.errorToastService.errorToast(error);
        });

        return false

    }

    downloadAuditCertificate(insurancePolicyItem: InsurancePolicyItem, policyNumber: String): boolean {
        const renderedDocumentTypeIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentTypeIdCriteria.documentId = policyNumber+"-"+insurancePolicyItem.insurancePolicyItemId;
        renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.INSURANCE_CERTIFICATE_AUDIT];
        renderedDocumentTypeIdCriteria.userID = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentTypeIdCriteria;

        this.startLoad();
        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                for(const renderedDocument of provideRenderedConceptualDocumentResponse.renderedDocuments){
                    if(renderedDocument.type === RenderedDocumentType.INSURANCE_CERTIFICATE_AUDIT){
                        let filepdf = 'data:application/pdf;base64,' + (renderedDocument as PdfDocument).encodedPdf;
                        let fileholder = document.createElement('a');
                        fileholder.href = filepdf;
                        fileholder.download = "Insurance Certificate Audit-"+ (insurancePolicyItem as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.certificateNumber;
                        fileholder.click();
                    }
                }
            }
            this.stopLoad();
        },(error) => {
            this.stopLoad();
        });

        return false

    }

    displayPDF(base64, complete) {
        const canvasId = `#canvas-print`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numberOfPages = numPages;
        });
    }

    downloadPdf() {

        for(const tsAndCs of this.tsAndCsToDownolad){
            let filepdf = 'data:application/pdf;base64,' + tsAndCs.policyDocument;
            let fileholder = document.createElement('a');
            fileholder.href = filepdf;
            fileholder.download = tsAndCs.name;
            fileholder.click();
        }

    }


    printDocument(): void {
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId: `canvas-print`,
            openBlank: true
        };
        this.printService.printDocument(pdfPrintData);
    }

    onPrintPreview(): void {
        this.displayPDF(this.documentToPreview, () => {
            setTimeout(() => {
                this.printDocument();
                this.stopLoad();
            }, 100);
        });
    }

    onSelect(policyAction: PolicyAction, motorInsurancePolicyItem: MotorInsurancePolicyItem) {
        this.onAction.emit({policyAction: policyAction, policy: this.policy, motorInsurancePolicyItem: motorInsurancePolicyItem})
    }

    toast() {
        this.translate.get('TOAST.T_SANDC_S_NOTPROVIDED').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
            });
            this.stopLoad();
        });
    }

    isPolicyActive(expiryDate: Date, insurancePolicyItem?: MotorInsurancePolicyItem){
        if(insurancePolicyItem){
            if(this.isVehiclePolicyCancelled(insurancePolicyItem)) return false;
        }
        return moment(new Date()).isBefore(moment(expiryDate))
    }

    getRefundInformation(policyNumber: string){
        var provideRefundSummaryRequest = new ProvideRefundSummaryRequest();
        var criteria = new RefundPolicyNumberCriteria();

        criteria.policyNumber = policyNumber;

        provideRefundSummaryRequest.criteria = criteria;

        this.refundProviderService.provideRefundSummary(provideRefundSummaryRequest).toPromise().then( httpResponse => {
            this.stopLoad();
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideRefundSummaryResponse) as ProvideRefundSummaryResponse);

                if (response.refundSummaries && response.refundSummaries.length > 0) {
                    this.policyNumbersList.push(policyNumber);
                }
            }
        }).catch(err=>{
            this.stopLoad();
        });
    }

    refundApplies(policyNumber: string){
        return this.policyNumbersList && this.policyNumbersList.includes(policyNumber);
    }

    removeExtraDay(date) {
        const nextDay = new Date(date);
        return new Date(nextDay.setDate(nextDay.getDate() - 1));
    }

    isVehiclePolicyCancelled(insurancePolicyItem: MotorInsurancePolicyItem): boolean{
        return insurancePolicyItem.motorInsuranceVehiclePolicy.cancelled;
     }
}
