import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

const VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', environment.raygun_api_key);
rg4js('setVersion', VERSION_NUMBER);
rg4js('enableCrashReporting', environment.production);
rg4js('enablePulse', false);

// Create a new ErrorHandler and report and an issue straight to Raygun
@Injectable()
export class RaygunErrorHandler implements ErrorHandler {

    //Capture any unhandled errors from the app and send them to Raygun as an extension of the built in errorHandler
    handleError (e: any) {
        if (environment.production) {
            rg4js('send', {
                error: e,
                tags: [`Website Error ${window.location.host}`]
            });
        }
        console.error(e);
    }

    sendError(e: any){
        if (environment.production) {
            rg4js('send', {
                error: e,
                tags: [`Website Error ${window.location.host}`]
            });
        }
        console.error(e);
    }

    //Manually send errors to Raygun where exceptions are expected
    static sendError(e: any){
        if (environment.production) {
            rg4js('send', {
                error: e,
                tags: [`Website Error ${window.location.host}`]
            });
        }
        console.error(e);
    }
}
