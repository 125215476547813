import { Response } from "../../../../../../../urdad/services/models/response.model";
import { Subscription } from "../../../../../models/subscription.model";

export class ProvideSubscriptionsResponse extends Response {
    constructor(){
        super();
    }

    public subscriptions: Subscription[] = [];
}
