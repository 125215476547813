import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { min } from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ObscuredValidationService {
    blockDuration = 600;
    private obscuredValidationCountDown = this.blockDuration;
    private $data : BehaviorSubject<IObscuredValidationData>;
    private resetData: IObscuredValidationData = {
        allowObscuredValidation: true,
        countDownDisplay: this.durationToTime(this.blockDuration),
        obscuredIncorrectCounter: 0
    };

    constructor() {
        this.$data = new BehaviorSubject(this.resetData);
    }

    get data(): BehaviorSubject<IObscuredValidationData> {
        return this.$data;
    }

    startValidationCounter(): void {
        const interval = setInterval(() => {
            this.obscuredValidationCountDown--;

            this.$data.next(
                {
                    allowObscuredValidation: false,
                    countDownDisplay: this.durationToTime(this.obscuredValidationCountDown),
                    obscuredIncorrectCounter: this.obscuredValidationCountDown
                }
            )

            if (this.obscuredValidationCountDown === 0) {
                this.$data.next(this.resetData);
                this.obscuredValidationCountDown = this.blockDuration;
                clearInterval(interval);
            }
        }, 1000);
    }

    durationToTime(duration : number, includeHours: boolean = false,
        includeMinutes: boolean = true, includeSeconds: boolean = true) {
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        let strSecs = secs >= 10 ? secs : `0${secs}`;
        let strHrs = hrs >= 10 ? hrs : `0${hrs}`;
        if(!includeMinutes){
            strSecs = `${secs}`;
            strHrs = `${hrs}`;
        }

        let strMins = mins >= 10 ? mins : `0${mins}`;
        strMins = includeHours || includeSeconds ? strMins : mins;

        let result = [];
        if(includeHours)result.push(strHrs);
        if(includeMinutes)result.push(strMins);
        if(includeSeconds)result.push(strSecs);

        return result.join(":");
    }

}

export interface IObscuredValidationData {
    allowObscuredValidation: boolean;
    obscuredIncorrectCounter: number;
    countDownDisplay: string
}
