import { Request } from "../../../../../../../urdad/services/models/request.model";
import {NaturalPerson} from "../../../../../../../entity/models/natural-person.model";
import {ServiceGroupNameTypeIdentifier} from "../../../../../../authorisation/identification/models/service-group-name-type-identifier.model";
import {JudicialPersonIdentifier} from '../../../../../../../entity/identification/models/judicial-person-identifier.model';

export class FinaliseCreateUserAccountRequest extends Request {

  constructor(){
    super();
  }

  public ownedBy: JudicialPersonIdentifier;
  public naturalPersons: NaturalPerson[] = [];
  public serviceGroups: ServiceGroupNameTypeIdentifier[] = [];
  public branch: string;
}
