import { CountryIdentifier } from "../../../../location/geographic/identification/models/country-identifier.model";
import {InsuranceVehicleCategoryClassification} from "./insurance-vehicle-category-classification.model";

export class InsuranceVehicleCategory {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.InsuranceVehicleCategory";
    }

    public insuranceVehicleCategoryId: string;
    public description: string;
    public subDescription: string;
    public countryIdentifier: CountryIdentifier;
    public insuranceVehicleCategoryClassifications: InsuranceVehicleCategoryClassification[] = [];
}
