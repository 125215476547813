import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, VerifyAccountLinkRequest} from '@magnabc/tpi'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class AccountLinkVerifierService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  verifyAccountLink(verifyAccountLinkRequest: VerifyAccountLinkRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/accountlinkverifier/verifyaccountlink';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(verifyAccountLinkRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

}
