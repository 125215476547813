import {VehicleIdentifier} from '../../../../../../vehicle/identification/models/vehicle-identifier.model';
import {Response} from '../../../../../../urdad/services/models/response.model';

export class AddToCartResponse extends Response{
    constructor(){
        super();
    }

    public verifiedVehicles: VehicleIdentifier[] = [];
    public unverifiedVehicleMessage: string[] = []
}
