import { ItemIdentifier } from "./item-identifier.model";

export class ItemNumberIdentifier extends ItemIdentifier{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.identification.ItemNumberIdentifier";
    }
     
    public itemNumber: string;
}