import {ScannedDocumentIdentifier} from './scanned-document-identifier.model';

export class ScannedDocumentIdentifierIdentifier extends ScannedDocumentIdentifier {
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.media.scanneddocument.identification.ScannedDocumentIdentifierIdentifier";
  }

  public documentIdentifier:string;
}
