import { Response } from "../../../../../../../urdad/services/models/response.model";
import {ServiceGroup} from '../../../../../../authorisation/models/service-group.model';

export class InitialiseCreateUserAccountResponse extends Response{
  constructor(){
    super();
  }

  public serviceGroups: ServiceGroup[] = [];
}
