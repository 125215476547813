import { Criteria } from "../../../criteria/criteria_api";
import { LegalEntityIdentifier } from "../../../entity/entity_api";

export class PaymentProfileLegalEntityCriteria extends Criteria{

    legalEntity: LegalEntityIdentifier;

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.payment.PaymentProfileLegalEntityCriteria";
    }

}
