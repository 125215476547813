import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ThirdPartyInsurancePolicyComponent} from './policy/policy.component'
import {InsurancePolicyQueryComponent} from './query/query.component'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {LoadingModule} from 'ngx-loading'
import {SharedModule} from '../../../shared/shared.module'
import {MatMenuModule} from '@angular/material/menu'
import {MatFormFieldModule} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'


@NgModule({
    declarations: [
        ThirdPartyInsurancePolicyComponent,
        InsurancePolicyQueryComponent
    ],
    exports: [
        ThirdPartyInsurancePolicyComponent,
        InsurancePolicyQueryComponent
    ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        SharedModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule
    ]
})
export class PolicyModule { }
