import {CountryIdentifier} from '../../../../location/geographic/identification/models/country-identifier.model'
import {Money} from '../../../../financial/models/money.model'
import {ShipmentType} from './shipment-type.model'

export abstract class CountryRegistrationConfigurationInformation {
    constructor() {}

    public vatNumber: string
    public bankName: string
    public bankAccountNumber: string
    public income: Money
    public sourceOfIncome: string
    public shippingAddressLineOne: string
    public shippingAddressLineTwo: string
    public shippingAddressLineThree: string
    public shippingAddressCity: string
    public shippingAddressCode: string
    public proofOfAddressIdentifier: string
    public shipmentType: ShipmentType
    public shippingCost: Money

}
