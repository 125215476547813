import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  AlphaTwo,
  BasicPhysicalAddress,
  ContactNumber,
  Country,
  CountryDescriptionIdentifier,
  CountryDialingIdentifier,
  EmailAddress,
  GenericAddress,
  MalawiPhysicalAddress
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { RaygunErrorHandler } from '../../../../../common/utils/utils.raygun';
import { FormComponent } from '../../../../shared/form/form.component';
import { VerifiedAccountManagerService } from '../../../../../http.services/security/account/verified-account-manager/verified-account-manager.service';
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;
@Component({
  selector: 'app-capture-entity-information',
  templateUrl: './capture-entity-information.component.html',
  styleUrls: ['./capture-entity-information.component.scss']
})
export class CaptureEntityInformationComponent extends FormComponent implements OnInit {

  constructor(private translate: TranslateService, private translateParser: TranslateParser,
    public createVerifiedAccountService: VerifiedAccountManagerService,
    private runtimeConfigurationService: RuntimeConfigurationService) {
    super(translate, translateParser);
  }

  /* Mobile Number */
  mobileNumberFormGroup: UntypedFormGroup;
  mobileNumberFormControl: UntypedFormControl;
  mobileNumberCountryCodeFormControl: UntypedFormControl;
  selectedCountry: Country = null;
  obscuredContactNumber: string;

  /* Email Address */
  emailFormGroup: UntypedFormGroup;
  emailFormControl: UntypedFormControl;

  /* Physical Address */
  physicalAddressFormGroup: UntypedFormGroup;
  residentialLineOne: UntypedFormControl;
  residentialLineTwo: UntypedFormControl;
  residentialLineThree: UntypedFormControl;
  residentialCountry: UntypedFormControl;
  residentialLineFive: UntypedFormControl;
  residentialSelectedCountry: Country = null;

  ngOnInit() {
    /* Mobile Number*/
    if (this.createVerifiedAccountService.dropdownCountrySelected) {
      this.selectedCountry = this.createVerifiedAccountService.dropdownCountrySelected;
    }
    else if (this.createVerifiedAccountService.contactNumber && this.createVerifiedAccountService.contactNumber.country) {
      this.selectedCountry = this.getCountryByDialingCode(this.createVerifiedAccountService.contactNumber.country);
      if (this.selectedCountry === null || this.selectedCountry === undefined) {
        this.selectedCountry = this.getCountryByDialingCode(this.runtimeConfigurationService.defaultCountryDialingCode);
      }
    }
    else {
      this.selectedCountry = this.getCountryByDialingCode(this.runtimeConfigurationService.defaultCountryDialingCode);
    }

    if (this.createVerifiedAccountService.contactNumber && this.createVerifiedAccountService.contactNumber.number) {
      if (this.createVerifiedAccountService.showObscuredContactNumber) {
        let obscuredContactNumber = this.createVerifiedAccountService.contactNumber.number
            .substr(0, this.createVerifiedAccountService.contactNumber.number.length - 4);
        obscuredContactNumber += '-****';

        this.mobileNumberFormControl = new UntypedFormControl(obscuredContactNumber, [
          Validators.required,
            Validators.pattern("^[0-9]*$"),
          Validators.maxLength(9)
        ]);
      } else {
        this.mobileNumberFormControl = new UntypedFormControl(this.createVerifiedAccountService.contactNumber.number, [
          Validators.required,
            Validators.pattern("^[0-9]*$"),
          Validators.maxLength(9)
        ]);
      }
    } else {
      this.createVerifiedAccountService.contactNumber = new ContactNumber();
      this.mobileNumberFormControl = new UntypedFormControl('', [
        Validators.required,
          Validators.pattern("^[0-9]*$"),
        Validators.maxLength(9)
      ]);
    }

    this.mobileNumberCountryCodeFormControl = new UntypedFormControl(this.selectedCountry.dialingCode, [
      Validators.required
    ]);

    this.mobileNumberFormGroup = new UntypedFormGroup({
      mobileNumberFormControl: this.mobileNumberFormControl,
      mobileNumberCountryCodeFormControl: this.mobileNumberCountryCodeFormControl
    });

    /* Email Address */
    if (this.createVerifiedAccountService.emailAddress) {
      this.emailFormControl = new UntypedFormControl(this.createVerifiedAccountService.emailAddress.address, [
        this.customEmailValidator,
        Validators.maxLength(254),
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
      ]);
    } else {
      this.emailFormControl = new UntypedFormControl('', [
        this.customEmailValidator,
        Validators.maxLength(254),
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
      ]);
    }

    this.emailFormGroup = new UntypedFormGroup({
      emailFormControl: this.emailFormControl
    });

    /* Physical Address */
    this.createPhysicalAddressForms();
  }

  isValid(): boolean {
    document.getElementById('form-submit-contact-number-mobile-number').click();
    document.getElementById('form-submit-email-address').click();
    document.getElementById('form-submit-address-residential').click();
    // document.getElementById('form-submit-address-postal').click();

    if (!this.mobileNumberFormGroup.valid) {
      document.getElementById('mobileNumberFormGroup').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    } else if (!this.emailFormGroup.valid) {
      document.getElementById('emailFormGroup').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    } else if (!this.physicalAddressFormGroup.valid) {
      document.getElementById('physicalAddressFormGroup').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }

    return (this.mobileNumberFormGroup.valid && this.emailFormGroup.valid && this.physicalAddressFormGroup.valid);
  }

  /* Mobile number functions */
  getMobileNumber(): ContactNumber {
    if (this.mobileNumberFormGroup.valid && this.mobileNumberFormControl.value) {
      const contactNumber = new ContactNumber();
      contactNumber.number = this.mobileNumberFormControl.value.replace(/\s/g, '');
      contactNumber.country = this.mobileNumberCountryCodeFormControl.value;
      contactNumber.contactNumberType = "Cell";

      return contactNumber;
    }

    return null;
  }

  onMobileNumberCountryChanged(event): void {
    this.selectedCountry = this.getCountryByDialingCode(this.mobileNumberCountryCodeFormControl.value);
  }

  getCountryByDialingCode(code: string): Country {
    return this.createVerifiedAccountService.dropdownCountries.find(value => {
      return value.dialingCode === code;
    });
  }

  editMobileNumber(event) {
    console.log(event);
  }

  validateMobileNumberForm() {

  }

  getMask(mask): any {
    // noinspection TsLint
    return eval(mask);
  }

  /* Email address functions */
  getEmailAddress(): EmailAddress {
    if (this.emailFormGroup.valid && this.emailFormControl.value) {
      const emailAddress = new EmailAddress();
      emailAddress.emailAddressType = 'Primary';
      emailAddress.address = this.emailFormControl.value;

      return emailAddress;
    }

    return null;
  }

  validateEmailForm() {

  }

  customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }

  /* Physical Address functions */
  getPhysicalAddress(): BasicPhysicalAddress {
    if (this.physicalAddressFormGroup.valid) {
      const address = new BasicPhysicalAddress();
      address.lineOne = this.residentialLineOne.value;
      address.lineTwo = this.residentialLineTwo.value;
      address.city = this.residentialLineThree.value;
      address.code = this.residentialLineFive.value;
      address.addressIdentifier = 'BasicPhysicalAddress';

      const countryIdentifier = new CountryDescriptionIdentifier();
      countryIdentifier.description = this.residentialSelectedCountry.description;

      address.countryIdentifier = countryIdentifier;
      return address;
    }

    return null;
  }

  createPhysicalAddressForms() {
    this.residentialLineOne = new UntypedFormControl('', [
      Validators.maxLength(50)
    ]);

    this.residentialLineTwo = new UntypedFormControl('', [
      Validators.maxLength(50)
    ]);

    this.residentialLineThree = new UntypedFormControl('', [
      Validators.maxLength(50),
      Validators.pattern('^([^0-9]*)$')
    ]);

    this.residentialCountry = new UntypedFormControl(this.selectedCountry.alphaTwo);

    this.residentialLineFive = new UntypedFormControl('', [
      Validators.maxLength(5),
      Validators.pattern('^[A-Z0-9]+$')
    ]);

    this.physicalAddressFormGroup = new UntypedFormGroup({
      residentialLineOne: this.residentialLineOne,
      residentialLineTwo: this.residentialLineTwo,
      residentialLineThree: this.residentialLineThree,
      residentialCountry: this.residentialCountry,
      residentialLineFive: this.residentialLineFive
    });

    this.setResidentialValues();

    if (this.createVerifiedAccountService.physicalAddressRequired) {
      this.residentialLineOne.setValidators([Validators.required, Validators.maxLength(50)]);
      this.residentialLineThree.setValidators([Validators.required, Validators.maxLength(50), Validators.pattern('^([^0-9]*)$')]);
      this.residentialLineFive.setValidators([Validators.required, Validators.maxLength(5), Validators.pattern('^[A-Z0-9]+$')]);
    }
  }

  setResidentialValues() {
    if (this.createVerifiedAccountService.residentialAddress) {
      const address = this.createVerifiedAccountService.residentialAddress;
      if (address instanceof BasicPhysicalAddress) {
        this.residentialLineOne.setValue(address.lineOne);
        this.residentialLineTwo.setValue(address.lineTwo);
        this.residentialLineThree.setValue(address.city);
        if (address.code && address.code.length) {
          this.residentialLineFive.setValue(address.code);
        }
        if (this.selectedCountry) {
          this.createVerifiedAccountService.residentialAddressCountrySelected = this.selectedCountry;
        } else {
          this.createVerifiedAccountService.setResidentialCountryByIdentifier(address.countryIdentifier);
        }
      } else if (address instanceof GenericAddress) {
        this.residentialLineOne.setValue(address.lineOne);
        this.residentialLineTwo.setValue(address.lineTwo);
        this.residentialLineThree.setValue(address.lineThree);
        this.residentialLineFive.setValue(address.lineFive);

        const countryIdentifier = new CountryDescriptionIdentifier();
        countryIdentifier.description = address.lineFour;
        this.createVerifiedAccountService.setResidentialCountryByIdentifier(countryIdentifier);
      } else if (address instanceof MalawiPhysicalAddress) {
        this.residentialLineOne.setValue(address.lineOne);
        this.residentialLineTwo.setValue(address.lineTwo);
        this.residentialLineThree.setValue(address.city);
        const countryIdentifier = new CountryDescriptionIdentifier();
        countryIdentifier.description = address.country;
        this.createVerifiedAccountService.setResidentialCountryByIdentifier(countryIdentifier);
      } else {
        console.error('Unknown address type :: ', address);
        RaygunErrorHandler.sendError('Unknown address type');
      }
    } else {
      if (this.selectedCountry) {
        this.createVerifiedAccountService.residentialAddressCountrySelected = this.selectedCountry;
      } else if (this.runtimeConfigurationService.defaultCountryDialingCode) {
        const identifier = new CountryDialingIdentifier();
        identifier.dialingCode = this.runtimeConfigurationService.defaultCountryDialingCode;
        this.createVerifiedAccountService.setResidentialCountryByIdentifier(identifier);
      } else {
        if (this.createVerifiedAccountService.dropdownCountries) {
          this.createVerifiedAccountService.residentialAddressCountrySelected =
            this.createVerifiedAccountService.dropdownCountries[0];
        }
      }
    }

    this.residentialSelectedCountry = this.createVerifiedAccountService.residentialAddressCountrySelected;
    this.residentialCountry.setValue(this.residentialSelectedCountry.alphaTwo);
  }

  validatePhysicalAddressForm() {
    console.log('Residential valid :: ', this.physicalAddressFormGroup.valid);
  }

  onResidentialCountryChanged(event) {
    const identifier = new AlphaTwo();
    identifier.alphaTwo = this.residentialCountry.value;
    this.residentialSelectedCountry = this.createVerifiedAccountService.getCountryByIdentifier(identifier);
  }

  markAllAsTouched(): void {
    this.mobileNumberFormGroup.markAllAsTouched();
    this.physicalAddressFormGroup.markAllAsTouched();
  }
}
