import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";
import { InsuranceCategory } from "../../models/insurance-category.model";

export class UnderwriterParameters {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.UnderwriterParameters';
    }
   
    public proposalAssessmentCheckoutReleaseHours: number;
    public underwriterIdentifier: UnderwriterIdentifier;
}