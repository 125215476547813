import {Response} from '../../../../../../urdad/services/models/response.model';
import { CoveredCountries } from '../../../models/CoveredCountries.model';

export class ProvideCoveredCountriesResponse extends Response {
    constructor() {
      super();
  }

  coveredCountries: CoveredCountries[] = [];
}
