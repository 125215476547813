import { filter } from 'rxjs/operators';
import { ValidationErrors, NgControl } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appNoWhiteSpace]'
})
export class NoWhiteSpaceDirective {

    @HostListener('input', ['$event']) onInput($event: InputEvent) {
        if($event.data && $event.data.includes(' ')){
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (this.control.control) {
            const value: string = this.control.value;
            const spilt = value.split('');
            const newValue = spilt.filter(i => i !== ' ').join('');
            this.control.control.setValue(newValue);
            $event.target['value'] = newValue;
        }
    }

    constructor(private control: NgControl) { }

    static noWhiteSpaceValidator(control: AbstractControl): ValidationErrors | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }
}
