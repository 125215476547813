import { Criteria } from "../../../criteria/models/criteria.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class RetailProfileLegalEntityCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.profile.RetailProfileLegalEntityCriteria";
    } 

    public tpiIdentifier: TpiIdentifier;
}
