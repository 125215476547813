export class Card {
   
    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.Card";
    }
   
    public cardNumber: string;
    public cardHolderName: string;
    public cardVerificationValue: string;
    public expiryMonth: number;
    public expiryYear: number;
}
