import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {
    ProvideAccountStatementReportRequest,
    ProvideRevenueReportRequest,
    ProvideTransactionReportRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class FinancialReportProviderService {

    basePath = '/ledger/reporting/financialreportprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideAccountStatementReport(provideAccountStatementReportRequest: ProvideAccountStatementReportRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideaccountstatementreport';
        return this.http.post(path, provideAccountStatementReportRequest, this.options);
    }

    provideRevenueReport(provideRevenueReportRequest: ProvideRevenueReportRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providerevenuereport';
        return this.http.post(path, provideRevenueReportRequest, this.options);
    }

    provideTransactionReport(provideTransactionReportRequest: ProvideTransactionReportRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providetransactionreport';
        return this.http.post(path, provideTransactionReportRequest, this.options);
    }

}
