import { Criteria } from "../../../criteria/criteria_api";
import { RetailProfileIdentifier } from "../../profile/profile_api";

export class OrderSummaryRetailProfileCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderSummaryRetailProfileCriteria";
    }
    
    public retailProfileIdentifier: RetailProfileIdentifier;
}