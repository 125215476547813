import {Money} from '../../../financial/models/money.model';

export class RefundSummary {
    amount: Money;
    orderItemNumber: string;
    levy: Money;

    constructor() {
        this['@class']="za.co.magnabc.tpi.retail.payment.RefundSummary"
    }

}
