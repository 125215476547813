import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {from} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ComprehensiveInsuranceProduct, Product, ThirdPartyInsuranceProduct, YCInsuranceProduct} from '@magnabc/tpi';
@Pipe({name: 'productType'})
export class ProductTypePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    static isComprehensive(value: Product) {
        if (value) {
            return value['@class'] === new ComprehensiveInsuranceProduct()['@class'];
        } else {
            return false;
        }
    }

    static isThirdParty(value: Product) {
        if (value) {
            return value['@class'] === new ThirdPartyInsuranceProduct()['@class'];
        } else {
            return false
        }
    }

    static isYellowCard(value: Product) {
        return value['@class'] === new YCInsuranceProduct()['@class'];
    }

    transform(value: Product): string {
        if (value['@class'] === new ComprehensiveInsuranceProduct()['@class']) {
            return 'Comprehensive'
        } else if (value['@class'] === new ThirdPartyInsuranceProduct()['@class']) {
            return 'Third Party'
        }else if (value['@class'] === new YCInsuranceProduct()['@class']) {
            return 'Yellow Card'
        }
        return 'Unknown Product Type';
    }

}
