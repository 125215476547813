import { EmailAddress, RetailProfileIdentifier} from '../../../../../../../../../index';
import {Response} from '../../../../../../../../urdad/services/models/response.model';
import { CardPaymentOption } from '../../../../../models/card-payment-option.model';
import { Card } from '../../../../../../models/card.model';

export class CreatePaymentProfileResponse extends Response{
    constructor(){
        super();
    }

    public cardPaymentOption: CardPaymentOption;
}
