import { ExtensionInsuranceProductCartItem } from "./extension-insurance-product-cart-item.model";
import { SnapshotVehicle } from "../../checkout/models/snapshot-vehicle.model";
import { InsuranceCategory } from "./insurance-category.model";
import { Money } from "../../../financial/models/money.model";
import { InsuranceCartItem } from "./insurance-cart-item.model";

export class ExtensionInsurancePolicyCartItem extends InsuranceCartItem {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.ExtensionInsurancePolicyCartItem";
  }

  public price: Money;
  public startDate: Date;
  public expiryDate: Date;
  public vehicleValue: Money;
  public category: InsuranceCategory;
  public policyNumber: string;
  public snapshotVehicle: SnapshotVehicle;
  public extensionInsuranceProductCartItem: ExtensionInsuranceProductCartItem;
}
