import { NaturalPersonIdentifier } from "../../../entity/identification/models/natural-person-identifier.model";
import { Criteria } from "../../../criteria/models/criteria.model";

export class AccountNaturalPersonCriteria extends Criteria
{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.account.AccountNaturalPersonCriteria";
    }

    public naturalPersonIdentifiers: NaturalPersonIdentifier[] = [];
}
