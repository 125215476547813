/*
 * Public API Surface of media
 */

export * from './models/image.model';

/* Scanned Document */
export * from './scanneddocument/identification/models/document-type-description-identifier.model';
export * from './scanneddocument/identification/models/document-type-identifier.model';
export * from './scanneddocument/identification/models/scanned-document-identifier.model';
export * from './scanneddocument/identification/models/scanned-document-identifier-identifier.model';
export * from './scanneddocument/identification/models/scanned-document-type-description-identifier.model';
export * from './scanneddocument/models/all-document-type-criteria.model';
export * from './scanneddocument/models/document-type.model';
export * from './scanneddocument/models/document-type-criteria.model';
export * from './scanneddocument/models/scanned-document.model';
export * from './scanneddocument/models/scanned-document-criteria.model';
export * from './scanneddocument/services/scanneddocumentmanager/createscanneddocument/response/models/create-scanned-document-response.model';
export * from './scanneddocument/services/scanneddocumentmanager/createscanneddocument/request/models/create-scanned-document-request.model';
export * from './scanneddocument/services/scanneddocumentmanager/insertscanneddocument/response/models/insert-scanned-document-response.model';
export * from './scanneddocument/services/scanneddocumentmanager/insertscanneddocument/request/models/insert-scanned-document-request.model';
export * from './scanneddocument/services/scanneddocumentprovider/providescanneddocument/request/models/provide-scanned-document-request';
export * from './scanneddocument/services/scanneddocumentprovider/providescanneddocument/response/models/provide-scanned-document-response';
