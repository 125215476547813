import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";

export class BankAccount{

    constructor() {
        this['@class']="za.co.magnabc.tpi.retail.payment.BankAccount"
    }

    public bankName: string;
    public accountNumber: string;
    public accountName: string;
    public accountType: string;
    public branchCode: string;
    public swiftCode: string;
    public countryOfRegistration: CountryIdentifier;
    public legalEntityIdentifier: LegalEntityIdentifier;
}
