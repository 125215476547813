import { FinancialAccountIdentifier } from "./financial-account-identifier.model";
import { CountryDescriptionIdentifier } from "../../../../location/location_api";

export class FinancialAccountNumberIdentifier extends FinancialAccountIdentifier{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.identification.FinancialAccountNumberIdentifier";
    }

    public number : string;
}