import {LegalEntityIdentifier} from '../../../../entity/identification/models/legal-entity-identifier.model'
import {CountryRegistrationConfigurationInformation} from './country-registration-configuration-information.model'

export class JudicialPersonCountryRegistrationConfigurationInformation extends CountryRegistrationConfigurationInformation {
    constructor() {
        super()
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.JudicialPersonCountryRegistrationConfigurationInformation';
    }

    public authorisedSignatoryName: string
    public authorisedSignatoryPosition: string
    public proofOfExistenceIdentifier: string
    public seniorManagerNames: string[] = []
    public legalEntityIdentifier: LegalEntityIdentifier
    public ciproDocumentIdentifier: string
    public companyLetterHeadIdentifier: string

}
