import {Elaboration} from "../../../security/models/elaboration.model";

export class PaymentAdvice{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.payment.PaymentAdvice";
    } 

    public paymentSuccessful: boolean;
    public elaboration: Elaboration
}
