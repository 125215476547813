import { BehaviorSubject } from 'rxjs';
import {Injectable} from '@angular/core'
import {
    BrokerLegalEntityCriteria, LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideUnderwriterInformationRequest,
    ProvideUnderwriterInformationResponse, TpiIdentifier,
    Underwriter,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService
} from '@magnabc/tpi'
import {Utils} from '../../../view.components/shared/Utils/Utils'
import {AgentManagerService} from '../agent-manager/agent-manager.service'
import {
    BrokerInformationProviderService
} from '../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {AuthenticationService} from '../authentication/authentication.service'
import {
    UnderwriterInformationProviderService
} from '../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'

@Injectable({
    providedIn: 'root'
})
export class UnderwriterManagerService {

    selectedUnderwriter: Underwriter;
    underwriters: Underwriter[] = [];
    underwriterMap: {[id: string]: Underwriter} = {}
    selectedUnderwriterLegalEntityIdentifier: UnderwriterLegalEntityIdentifier;
    underwriterListeners = [];
    $selectedUnderwriterLegalEntityIdentifier = new BehaviorSubject<UnderwriterLegalEntityIdentifier>(null);

    constructor(private authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private brokerInformationService: BrokerInformationProviderService) {

        this.authenticationService.subscriptionListener.subscribe((response) => {
            if (response) {
                this.getUnderwriters()
            }
        })

    }

    getUnderwriters() {

        let brokerRequest = new ProvideBrokerInformationRequest();
        let criteria = new BrokerLegalEntityCriteria();
        criteria.legalEntity = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers())
        brokerRequest.criteria = criteria;
        this.brokerInformationService.provideBroker(brokerRequest).toPromise().then(response => {

            const promises = []
            promises.push(this.provideUnderwriters(Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers())))

            if (response && response.body) {
                const result = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse);
                if (result.brokers.length > 0) {
                    for (const underwriterIdentifier of result.brokers[0].underwriterIdentifiers) {
                        const tpiIdentifier = (underwriterIdentifier as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier
                        promises.push(this.provideUnderwriters(tpiIdentifier))
                    }
                }
            }

            Promise.all(promises).then((results) => {
                this.underwriters = []
                this.underwriterMap = {}
                for (const underwriters of results) {
                    for (const underwriter of underwriters) {
                        if (!this.underwriterMap[(underwriter as Underwriter).name]) {
                            this.underwriters.push(underwriter)
                            this.underwriterMap[(underwriter as Underwriter).name] = underwriter
                        }
                    }
                }
                this.underwriters.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                })
                if (this.underwriters.length > 0) {
                    this.selectedUnderwriter = this.underwriters[0];
                    const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier()
                    legalEntityDescriptionIdentifier.name = this.selectedUnderwriter.name
                    legalEntityDescriptionIdentifier.tpiIdentifier = this.selectedUnderwriter.legalEntityIdentifier as TpiIdentifier
                    this.selectedUnderwriterLegalEntityIdentifier = new UnderwriterLegalEntityIdentifier(this.selectedUnderwriter.name, legalEntityDescriptionIdentifier);
                    this.$selectedUnderwriterLegalEntityIdentifier.next(this.selectedUnderwriterLegalEntityIdentifier);
                    this.onUnderwriterChange()
                }
            })
        })

    }

    provideUnderwriters(legalEntityIdentifier: LegalEntityIdentifier): Promise<Underwriter[]> {
        return new Promise<Underwriter[]>((resolve) => {
            const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
            underwriterLegalEntityCriteria.legalEntityIdentifiers = [legalEntityIdentifier];
            const provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
            provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
            this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
                if(response && response.body){
                    const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                    resolve(provideUnderwriterInformationResponse.underwriters)
                } else {
                    resolve([])
                }
            });
        })
    }

    onUnderwriterChange(): void {
        for (const listener of this.underwriterListeners) {
            listener(this.selectedUnderwriter);
        }
    }

    registerUnderwriterListener(listener): void {
        this.underwriterListeners.push(listener);
        if (this.selectedUnderwriter) {
            listener(this.selectedUnderwriter);
        }
    }

}
