import { InsuranceTypeSelectorPageComponent } from './view.pages/retail/insurance/insurance-type-selector-page/insurance-type-selector-page/insurance-type-selector-page.component';
import { YellowCardProductPricingPageComponent } from './view.pages/retail/insurance/insurance-product-manager/yellow-card-product/yellow-card-product-pricing-page/yellow-card-product-pricing-page.component';
import { ProductResolver } from './app.services/common/guards/product-resolver/product.resolver';
import { ProductGuard } from './app.services/common/guards/product-guard/product.guard';
import {ModuleWithProviders} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
    InsurancePersonalQuickTransactManagerPageComponent
} from './view.pages/retail/insurance/quick-transact/insurance-personal-quick-transact-manager-page/insurance-personal-quick-transact-manager-page.component'
import {AuthGuard} from './app.services/common/guards/auth-guard'
import {ServiceList} from './common/model/service-list.model'
import {StepGuard} from './view.components/shared/guards/step-guard'
import {
    RetailProductsPageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-list-page/retail.products.list.component'
import {
    RetailProductsUpdatePageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-update-page/retail-products-update-page.component'
import {RetailProfileCreatePageComponent} from './view.pages/retail/profile/retail-profile-create--page/retail-profile-create-page.component'
import {RetailProfileUpdatePageComponent} from './view.pages/retail/profile/retail-profile-update-page/retail-profile-update-page.component'
import {VehicleCreatePageComponent} from './view.pages/retail/profile/vehicle-create-page/vehicle-create-page.component'
import {RetailProfilePageComponent} from './view.pages/retail/profile/retail-profile-page/retail-profile-page.component'
import {VehicleUpdatePageComponent} from './view.pages/retail/profile/vehicle-update-page/vehicle-update-page.component'
import {CanDeactivateGuard} from './view.components/shared/guards/can-deactivate.guard.component'
import {
    BrowseProductsPageComponent
} from './view.pages/retail/insurance/browse-products-page/browse-products-page.component'
import {CartPageComponent} from './view.pages/retail/checkout/cart-page/cart-page.component'
import {CheckoutPageComponent} from './view.pages/retail/checkout/checkout-page/checkout-page.component'
import {OrderHistoryPageComponent} from './view.pages/retail/checkout/order-history-page/order-history-page.component'
import {OrderPageComponent} from './view.pages/retail/checkout/order-page/order-page.component'
import {
    QueryPolicyPageComponent
} from './view.pages/retail/insurance/third-party-insurance/query-policy-page/query-policy-page.component'
import {
    PublicQueryPageComponent
} from './view.pages/retail/insurance/third-party-insurance/public-query-page/public-query-page.component'
import {
    InsuranceAssistedQuickTransactManagerPageComponent
} from './view.pages/retail/insurance/quick-transact/insurance-assisted-quick-transact-manager-page/insurance-assisted-quick-transact-manager-page.component'
import {LegalPageComponent} from './view.pages/legal/legal-page/legal-page.component'
import {IndexPageComponent} from './view.pages/index-page/index-page.component'
import {DropdownsPageComponent} from './view.pages/dropdowns-page/dropdowns-page.component'
import {VehicleMakePageComponent} from './view.pages/vehicle/vehicle-make-page/vehicle-make-page.component'
import {VehicleModelPageComponent} from './view.pages/vehicle/vehicle-model-page/vehicle-model-page.component'
import {
    CreateMakeModelTrimPageComponent
} from './view.pages/vehicle/make-model-trim-create-page/make-model-trim-create-page.component'
import {
    UpdateMakeModelTrimPageComponent
} from './view.pages/vehicle/make-model-trim-update-page/make-model-trim-update-page.component'
import {
    ServiceGroupCreatePageComponent
} from './view.pages/security/authorisation/service-group-create-page/service-group-create-page.component'
import {
    ServiceGroupListPageComponent
} from './view.pages/security/authorisation/service-group-list-page/service-group-list-page.component'
import {
    ServiceGroupUpdatePageComponent
} from './view.pages/security/authorisation/service-group-update-page/service-group-update-page.component'
import {
    SubscriptionCreatePageComponent
} from './view.pages/security/authorisation/subscription-create-page/subscription-create-page.component'
import {
    SubscriptionListPageComponent
} from './view.pages/security/authorisation/subscription-list-page/subscription-list-page.component'
import {
    BackOfficeAuthenticatorPageComponent
} from './view.pages/security/back-office-authenticator-page/back-office-authenticator-page.component'
import {
    PublicAuthenticatorPageComponent
} from './view.pages/security/public-authenticator-page/public-authenticator-page.component'
import {UnAuthGuard} from './app.services/common/guards/unauth-guard'
import {
    InsuranceCertificateIssuanceAdministratorPageComponent
} from './view.pages/retail/insurance/certificate/insurance-certificate-issuance-administrator-page/insurance-certificate-issuance-administrator-page.component'
import {
    InsurancePolicyManagerPageComponent
} from './view.pages/retail/insurance/insurance-policy-manager-page/insurance-policy-manager-page.component'
import {
    VerifiedAccountManagerPageComponent
} from './view.pages/security/account/verified-account-manager/verified-account-manager-page/verified-account-manager-page.component'
import {
    PublicOneTimePinAuthenticatorPageComponent
} from './view.pages/security/public-one-time-pin-authenticator-page/public-one-time-pin-authenticator-page.component'
import {
    PublicPasswordResetManagerPageComponent
} from './view.pages/security/account/public-password-reset-manager-page/public-password-reset-manager-page.component'
import {ChangeNumberPageComponent} from './view.pages/security/account/change-number-page/change-number-page.component'
import {
    PrincipalManagerPageComponent
} from './view.pages/security/authorisation/principal-manager-page/principal-manager-page.component'
import {DashboardPageComponent} from './view.pages/ledger/reporting/dashboard/dashboard-page/dashboard-page.component'
import {
    BackOfficeAgentAuthenticatorPageComponent
} from "./view.pages/security/back-office-agent-authenticator-page/back-office-agent-authenticator-page.component"
import {
    AccountStatementsPageComponent
} from './view.pages/ledger/accounting-transaction/account-statements-page/account-statements-page.component'
import {
    UserManagementPageComponent
} from './view.pages/user-account-management/user-management-page/user-management-page.component'
import {
    CreateBackOfficeAccountPageComponent
} from './view.pages/user-account-management/create-back-office-account-page/create-back-office-account-page.component'
import {
    AccountLinkVerificationPageComponent
} from './view.pages/user-account-management/account-link-verification-page/account-link-verification-page.component'
import {
    FinancialReportPageComponent
} from './view.pages/ledger/reporting/financial-report-page/financial-report-page.component'
import {
    RevenueReportPageComponent
} from './view.pages/ledger/reporting/revenue-report-page/revenue-report-page.component'
import {
    TransactionReportPageComponent
} from './view.pages/ledger/reporting/transaction-report-page/transaction-report-page.component'
import {
    AccountReportsPageComponent
} from './view.pages/ledger/accounting-transaction/account-reports-page/account-reports-page.component'
import {
    AccountingTransactionPageComponent
} from './view.pages/ledger/accounting-transaction/accounting-transations-page/accounting-transaction-page.component'
import {
    SalesReportPageComponent
} from "./view.pages/ledger/reporting/sales-report-page/sales-report-page.component"
import {
    ProposalAssessmentManagerComponent
} from './view.pages/retail/insurance/comprehensive-insurance/proposal-assessment-manager-page/proposal-assessment-manager.component'
import {ProposalListPageComponent} from './view.pages/retail/checkout/proposal-list-page/proposal-list-page.component'
import {
    DirectPayOnlineCardPaymentVerifierPageComponent
} from "./view.pages/retail/payment/dpo/direct-pay-online-card-payment-verifier-page/direct-pay-online-card-payment-verifier-page.component"
import {
    BackOfficeInvoicesPageComponent
} from "./view.pages/retail/invoice/back-office-invoices-page/back-office-invoices-page.component"
import {MaintenancePageComponent} from './view.pages/maintenance-page/maintenance-page.component'
import {MaintenanceGuard} from './app.services/common/guards/maintenance-guard'
import {
    BackOfficeForgotPasswordManagerPageComponent
} from './view.pages/security/account/back-office-forgot-password-manager-page/back-office-forgot-password-manager-page.component'
import {
    QueryProposalPageComponent
} from './view.pages/retail/checkout/query-proposal-page/query-proposal-page.component'
import {FeatureToggle210304Guard} from "./app.services/common/guards/feature-toggle-210304"
import {
    ProposalHistoryPageComponent
} from './view.pages/retail/checkout/proposal-history-page/proposal-history-page.component'
import {
    ProductOverviewPageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-overview-page/product-overview-page.component'
import {
    CreateProductDetailsPageComponent
} from './view.pages/retail/insurance/insurance-product-manager/create-product-details-page/create-product-details-page.component'
import {
    ProductPricingSchedulePageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-pricing-schedule-page/product-pricing-schedule-page.component'
import {
    RetailProductExcessPageComponent
} from './view.pages/retail/insurance/retail-excess-page/retail-product-excess-page.component'
import {
    ProductLimitPageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-limit-page/product-limit-page.component'
import {
    AssessmentsPageComponent
} from './view.pages/retail/insurance/assessment/assessments-page/assessments-page.component'
import {
    YcAssessmentManagerPageComponent
} from './view.pages/retail/insurance/yellow-card-insurance/yc-assessment-manager-page/yc-assessment-manager-page.component'
import { ImportFleetPageComponent } from './view.pages/retail/profile/import-fleet-page/import-fleet-page/import-fleet-page.component';
import { SelectPaymentMethodPageComponent } from './view.pages/retail/payment/select-payment-method-page/select-payment-method-page.component';
import { CoveredCountriesSelectorPageComponent } from './view.pages/retail/covered-countries-selector-page/covered-countries-selector-page.component';
import {
    ProductAddOnPageComponent
} from './view.pages/retail/insurance/insurance-product-manager/product-add-on-page/product-add-on-page.component'

export const AppRoutes: Routes = [

    /** Main routes */
    {path: '', component: IndexPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'maintenance', component: MaintenancePageComponent},

    {path: 'console/home', component: DashboardPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},

    /**User Management routes **/
    {path: 'manage/users', component: UserManagementPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'manage/users/:id', component: UserManagementPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'manage/create-user', component: CreateBackOfficeAccountPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},

    /** Authentication routes **/
    {path: 'service-group/create', component: ServiceGroupCreatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.CREATESERVICEGROUP]}},
    {path: 'service-groups', component: ServiceGroupListPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.UPDATESERVICEGROUP]}},
    {path: 'service-groups/:type/:name', component: ServiceGroupUpdatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.UPDATESERVICEGROUP]}},
    {path: 'subscription/create', component: SubscriptionCreatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.CREATESUBSCRIPTION]}},
    {path: 'subscriptions', component: SubscriptionListPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.PROVIDESUBSCRIPTIONS]}},
    {path: 'login', component: PublicAuthenticatorPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard]},
    {path: 'console-login', component: BackOfficeAuthenticatorPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard]},

    /** Account routes **/
    {path: 'security/account/create', component: VerifiedAccountManagerPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard], canDeactivate: [StepGuard]},
    {path: 'security/account/verify', component: PublicOneTimePinAuthenticatorPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard], canDeactivate: [StepGuard]},
    {path: 'security/account/backoffice-reset-password', component: BackOfficeForgotPasswordManagerPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard], canDeactivate: [StepGuard]},
    {path: 'security/account/reset-password', component: PublicPasswordResetManagerPageComponent, canActivate: [UnAuthGuard, MaintenanceGuard, AuthGuard], canDeactivate: [StepGuard]},
    {path: 'security/account/change-number', component: ChangeNumberPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard]},
    {path: 'create/company', component: PrincipalManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard, FeatureToggle210304Guard], canDeactivate: [StepGuard]},
    {path: 'account/verification/:id', component: AccountLinkVerificationPageComponent, canActivate: [MaintenanceGuard]},
    {path: 'account/verification', component: AccountLinkVerificationPageComponent, canActivate: [MaintenanceGuard]},
    {path: 'account/reset-password/:id', component: AccountLinkVerificationPageComponent, canActivate: [MaintenanceGuard]},

    /** Agent routes **/
    { path: 'agent', component: BackOfficeAgentAuthenticatorPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'agent/order', component: InsuranceCertificateIssuanceAdministratorPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.ISSUEINSURANCECERTIFICATE] } },
    { path: 'agent/order/:number', component: InsuranceCertificateIssuanceAdministratorPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.PROVIDEINSURANCEPOLICIES] } },
    { path: 'agent/payment', component: InsurancePolicyManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.RECEIVECASHIERPAYMENT] } },
    { path: 'agent/payment/:number', component: InsurancePolicyManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.RECEIVECASHIERPAYMENT] } },
    { path: 'agent/payment/:id/:country/:queryBy/:idType', component: InsurancePolicyManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.RECEIVECASHIERPAYMENT] } },
    { path: 'verify-orders', component: DirectPayOnlineCardPaymentVerifierPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.UPDATESERVICEGROUP] } },

    /** Report routes **/
    {path:'reports',component:FinancialReportPageComponent,
        children: [
            {path: 'revenue', component: RevenueReportPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
            {path: 'transaction', component: TransactionReportPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
            {path: 'sales', component: SalesReportPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
            {path: '',redirectTo: 'revenue',pathMatch: 'full'},

        ]},
    {path: 'account/statements', component: AccountStatementsPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: {services: [ServiceList.PROVIDEINSURANCEPOLICIES]}},
    {path: 'account/report/:type/:number', component: AccountReportsPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'account/report', component: AccountingTransactionPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'supplier/invoices', component: BackOfficeInvoicesPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},



    /** Quickstart routes **/
    {path: 'quickstart/user', component: InsurancePersonalQuickTransactManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard]},
    {path: 'quickstart/assisted', component: InsuranceAssistedQuickTransactManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard]},

    /** Legal routes **/
    {path: 'legal', component: LegalPageComponent, canActivate: [AuthGuard]},

    /** Retail routes **/
    { path: 'retail/insurance/product-overview/:number', component: ProductOverviewPageComponent, canActivate: [AuthGuard, MaintenanceGuard], resolve: { product: ProductResolver }, data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'retail/insurance/product-pricing-schedule/:number', component: ProductPricingSchedulePageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], canMatch: [ProductGuard], data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'retail/insurance/product-excess/:index/:number', component: RetailProductExcessPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canMatch: [ProductGuard], data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT, ServiceList.UPDATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'retail/insurance/create-product-limit/:number', component: ProductLimitPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], canMatch: [ProductGuard], data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'retail/insurance/product-add-on/:number', component: ProductAddOnPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], canMatch: [ProductGuard], data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'broker/products', component: RetailProductsPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT, ServiceList.UPDATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'broker/products/create/:number', component: CreateProductDetailsPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], resolve: { product: ProductResolver }, data: { services: [ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'broker/products/update/:id', component: RetailProductsUpdatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: { services: [ServiceList.UPDATETHIRDPARTYINSURANCEPRODUCT] } },
    { path: 'retail/profile/create', component: RetailProfileCreatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.PROVIDERETAILPROFILEINFORMATION] } },
    { path: 'retail/profile/update', component: RetailProfileUpdatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [CanDeactivateGuard], data: { services: [ServiceList.UPDATELEGALENTITY] } },
    { path: 'retail/profile/link', component: VehicleCreatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard] },
    { path: 'retail/profile', component: RetailProfilePageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/profile/vehicle/:vrn', component: VehicleUpdatePageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [CanDeactivateGuard] },
    { path: 'retail/browse/products', component: BrowseProductsPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/browse/select-insurance', component: InsuranceTypeSelectorPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/browse/select-covered-countries', component: CoveredCountriesSelectorPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/cart', component: CartPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/checkout', component: CheckoutPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard] },
    { path: 'retail/orders', component: OrderHistoryPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/orders/proposals', component: ProposalHistoryPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'retail/order/:reference', component: OrderPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    { path: 'policy/query', component: QueryPolicyPageComponent, canActivate: [AuthGuard, MaintenanceGuard], canDeactivate: [StepGuard], data: { services: [ServiceList.PROVIDEINSURANCEPOLICIES] } },
    { path: 'public/query', component: PublicQueryPageComponent, canDeactivate: [StepGuard] },
    {
        path: 'retail/insurance/yellow-card-product-pricing-schedule/:number', component: YellowCardProductPricingPageComponent,
        canActivate: [AuthGuard, MaintenanceGuard], data: { services: [ServiceList.CREATEYELLOWCARDINSURANCEPRODUCT, ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT] },
        resolve: { product: ProductResolver }
    },
    { path: 'retail/profile/vehicles/import', component: ImportFleetPageComponent ,},
    { path: 'retail/checkout/payment/:number', component: SelectPaymentMethodPageComponent, canActivate: [AuthGuard, MaintenanceGuard] },
    /** Make Model Trim routes **/
    {path: 'dropdowns', component: DropdownsPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.PROVIDEVEHICLEMAKES, ServiceList.PROVIDEVEHICLEMODELS]}},
    {path: 'makemodeltrim/make', component: VehicleMakePageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.PROVIDEVEHICLEMAKES, ServiceList.PROVIDEVEHICLEMODELS]}},
    {path: 'makemodeltrim/model', component: VehicleModelPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.PROVIDEVEHICLEMODELS, ServiceList.PROVIDEVEHICLEMODELS]}},
    {path: 'makemodeltrim/create', component: CreateMakeModelTrimPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.CREATEVEHICLEMAKE, ServiceList.CREATEVEHICLEMODEL]}},
    {path: 'makemodeltrim/create/:id', component: CreateMakeModelTrimPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.CREATEVEHICLEMAKE, ServiceList.CREATEVEHICLEMODEL]}},
    {path: 'makemodeltrim/:id', component: UpdateMakeModelTrimPageComponent, canActivate: [AuthGuard, MaintenanceGuard], data: {services: [ServiceList.UPDATEVEHICLEMAKE, ServiceList.UPDATEVEHICLEMODEL]}},

    /** Proposal routes **/
    {path: 'proposal/query', component: QueryProposalPageComponent, canActivate: [MaintenanceGuard]},
    {path: 'proposal-list/:status', component: ProposalListPageComponent, canActivate: [MaintenanceGuard]},
    {path: 'proposal-list/:status/:quantity', component: ProposalListPageComponent, canActivate: [MaintenanceGuard]},

    {path: 'assessment', component: AssessmentsPageComponent, children: [
        {path: 'proposals', component: ProposalAssessmentManagerComponent, canActivate: [AuthGuard, MaintenanceGuard]},
        {path: 'proposals/:proposalid', component: ProposalAssessmentManagerComponent, canActivate: [MaintenanceGuard]},
        {path: 'yellow-card', component: YcAssessmentManagerPageComponent, canActivate: [AuthGuard, MaintenanceGuard]},
        {path: '', redirectTo: 'revenue', pathMatch: 'full'},
    ]},

    /** Safe Guard route **/
    {path: '', redirectTo: '/', pathMatch: 'full'}

];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(AppRoutes, {});
