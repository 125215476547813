import {NgModule} from "@angular/core"
import {RouterModule, Routes} from "@angular/router"

import {TranslateModule} from "@ngx-translate/core"
import {NgSelectModule} from "@ng-select/ng-select"
import {BrowserModule} from "@angular/platform-browser"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {NgxDatatableModule} from "@swimlane/ngx-datatable"
import {LoadingModule} from "ngx-loading"
import {SharedModule} from "../shared/shared.module"
import {MatAutocompleteModule} from "@angular/material/autocomplete"
import {MatDatepickerModule} from "@angular/material/datepicker"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {HttpClientModule} from "@angular/common/http"
import {MatMomentDateModule} from "@angular/material-moment-adapter"
import {VehicleEngineComponent} from "./engine/vehicle.engine.component"
import {VehicleGeneralComponent} from "./general/vehicle_general.component"
import {VehicleQueryComponent} from "./query/vehicle.query.component"
import {VehicleTrimComponent} from './vehicle-trim/vehicle-trim.component';
import { MakeModelTrimCreateComponent } from './make-model-trim-create/make-model-trim-create.component';
import { MakeModelTrimUpdateComponent } from './make-model-trim-update/make-model-trim-update.component';
import { VehicleMakeComponent } from './vehicle-make/vehicle-make.component';
import { VehicleModelComponent } from './vehicle-model/vehicle-model.component'

const routes: Routes = [];

@NgModule({
    declarations: [
        VehicleEngineComponent,
        VehicleGeneralComponent,
        VehicleQueryComponent,
        VehicleTrimComponent,
        MakeModelTrimCreateComponent,
        MakeModelTrimUpdateComponent,
        VehicleMakeComponent,
        VehicleModelComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxDatatableModule,
        LoadingModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatMomentDateModule,
        NgxDatatableModule,
    ],
    exports: [
        VehicleQueryComponent,
        VehicleEngineComponent,
        VehicleGeneralComponent,
        VehicleTrimComponent,
        MakeModelTrimCreateComponent,
        MakeModelTrimUpdateComponent,
        VehicleMakeComponent,
        VehicleModelComponent
    ],
    providers: [
    ]
})
export class VehicleModule { }
