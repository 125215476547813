import { Subscription } from 'rxjs';
import { ObscuredValidationService } from './../../services/obscured-validation.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AllCountryCriteria, ContactNumber, Country, CountryDescriptionIdentifier, Criteria, DriverTrafficRegisterNumber, FinaliseSyncVehicleRequest, FinaliseSyncVehicleResponse, InitialiseSyncVehicleRequest, InitialiseSyncVehicleResponse, IssueOneTimePinRequest, IssueOneTimePinResponse, LegalEntity, LegalEntityIdentifier, LegalEntityProviderCriteria, NaturalPerson, OneTimePinContext, PinContext, ProvideCountryInformationRequest, ProvideCountryInformationResponse, ProvideSecuredLegalEntityObscuredContactNumberRequest, ProvideSecuredLegalEntityObscuredContactNumberResponse, RetailProfileOwnerIdentifier, TrafficRegisterNumber, UnMarshallerService, ValidateOneTimePinRequest, ValidateOneTimePinResponse, ValidateSecuredLegalEntityObscuredContactNumberRequest, ValidateSecuredLegalEntityObscuredContactNumberResponse } from "@magnabc/tpi";
import { FormComponent } from "../../../../shared/form/form.component";
import { RaygunErrorHandler } from "../../../../../common/utils/utils.raygun";
import { CountryProviderService } from "../../../../../http.services/location/geographic/country-provider/country-provider.service";
import { CountryService } from "../../../../shared/country/services/country.service";
import { SyncVehicleDataModel } from "./sync-vehicle-data.model";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { VerifiedAccountManagerService } from "../../../../../http.services/security/account/verified-account-manager/verified-account-manager.service";
import { SecureLegalEntityInformationManagerService } from "../../../../../http.services/entity/secure-legal-entity-information-manager/secure-legal-entity-information-manager.service";
import { CountryComponent } from "../../../../shared/country/country.component";
import { OTPVerifyComponent } from "../../../../security/opt-verify/opt-verify.component";
import { OTPComponent } from "../../../../security/otp.component";
import { OneTimePinIssuanceManagerService } from "../../../../../http.services/security/onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service";
import { OneTimePinValidationManagerProviderService } from "../../../../../http.services/security/onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service";
import { RetailProfileFinalisationManagerService } from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import { Utils } from "../../../../shared/Utils/Utils";
import { AuthenticationService } from "../../../../../app.services/managers/authentication/authentication.service";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { ToastComponent } from "../../../../shared/toast/toast.component";
import { environment } from "../../../../../../environments/environment";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { SyncVehicleRetailProfileFinalisationManagerService } from "../../../../../http.services/retail/profile/sync-vehicle-retail-profile-finalisation-manager/sync-vehicle-retail-profile-finalisation-manager.service";
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'


@Component({
    selector: 'app-sync-vehicle-dialog',
    templateUrl: './sync-vehicle-dialog.component.html',
    styleUrls: ['./sync-vehicle-dialog.component.scss']
})

export class SyncVehicleDialogComponent extends FormComponent implements OnInit, OnDestroy {

    countries: Country[];
    errorMessage: string;
    obfuscated: string;
    signUpFormGroup: UntypedFormGroup;
    identifier: UntypedFormControl;
    selectedCountry: Country = null;
    formGroup: UntypedFormGroup;
    one: UntypedFormControl;
    two: UntypedFormControl;
    three: UntypedFormControl;
    four: UntypedFormControl;
    otpFormGroup: UntypedFormGroup;
    otp: UntypedFormControl;
    externalLegalEntityProviderCriteria: Criteria = null;
    foundExtContactNumber: boolean;
    obscuredIncorrectCounter: number = 0;
    spinnerPercentage: number = 0;
    spinnerMode: ProgressSpinnerMode = 'indeterminate';
    obscuredContactCountry: string;
    obscuredContact: any;
    externalContactNumber: ContactNumber;
    contactNumberOTP: string;
    externalEntity: boolean;
    naturalPerson: NaturalPerson;
    externalTRN: any;
    legalEntityContactNumber: string;
    @Input() legalEntity: LegalEntity;
    activeToast: ActiveToast<any>;

    @ViewChild(CountryComponent) countryComponent: CountryComponent;
    @ViewChild('form', { static: true }) form: FormGroupDirective;
    @ViewChild(OTPComponent) otpComponent: OTPComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;
    @Output() onValid = new EventEmitter<string>();
    invalidOTP: boolean;
    oneTimePinIdentifier: any;
    utils = Utils;
    countDown = 60;
    resendDisabled = false;
    successful = true;
    currentStep = 0;
    totalNumberOfVehicles = 0;
    allowObscuredValidation = true;
    obscuredValidationDisplayCountDown = '';
    subscriptions = new Subscription();

    constructor(
        public translate: TranslateService,
        private translateParser: TranslateParser,
        public dialogRef: MatDialogRef<SyncVehicleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SyncVehicleDataModel,
        private countryProviderService: CountryProviderService,
        private unMarshallerService: UnMarshallerService,
        private countryService: CountryService,
        private createVerifiedAccountService: VerifiedAccountManagerService,
        private securedLegalEntityProviderWrapperService: SecureLegalEntityInformationManagerService,
        private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
        private oneTimePinValidationManagerService: OneTimePinValidationManagerProviderService,
        private retailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
        private syncRetailProfileFinalisationManagerService: SyncVehicleRetailProfileFinalisationManagerService,
        private toastr: ErrorToastService,
        public authenticationService: AuthenticationService,
        private obscuredValidationService: ObscuredValidationService

    ) {
        super(translate, translateParser);
    }


    ngOnInit(): void {

        this.one = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.two = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.three = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.four = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.formGroup = new UntypedFormGroup({
            one: this.one,
            two: this.two,
            three: this.three,
            four: this.four,
        });


        this.identifier = new UntypedFormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
        ]);

        this.signUpFormGroup = new UntypedFormGroup({
            identifier: this.identifier
        });

        this.otp = new UntypedFormControl({ value: this.otp }, [
            Validators.required,
            Validators.pattern(/^[0-9]\d*$/),
            Validators.maxLength(4),
            Validators.minLength(4)
        ]);


        this.otpFormGroup = new UntypedFormGroup({
            otp: this.otp
        });


        this.countries = this.countryService.getCountries();
        this.identifier.setValue(this.data.trn_number);

        const subscription = this.obscuredValidationService.data.subscribe(data => {
            this.obscuredValidationDisplayCountDown = data.countDownDisplay;
            this.allowObscuredValidation = data.allowObscuredValidation;
            if (!this.allowObscuredValidation) {
                this.errorMessage = this.obscuredValidationError;
            } else {
                this.errorMessage = null;
                this.obscuredIncorrectCounter = 0;
            }
        });
        this.subscriptions.add(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    isValid(): boolean {
        document.getElementById('form-service-group').click();


        if (!this.otpFormGroup.valid) {
            document.getElementById('otpFormGroup').scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        }

        return this.otpFormGroup.valid;
    }

    checkInputClicked() {
        this.errorMessage = null;
    }

    startLoad(): void {
        this.loading = true;
    }

    stopLoad(): void {
        this.loading = false;
    }

    onCountry(country): void {
        this.selectedCountry = country;
    }

    onCountryValid(_): void {
        document.getElementById('form-submit-query').click();
    }

    otpInvalid() {
        this.invalidOTP = true;
        this.stopLoad();
    }

    validateTRN() {
        this.selectedCountry = this.countryComponent.country;
        const countryIdentifier = new CountryDescriptionIdentifier();
        countryIdentifier.description = this.selectedCountry.description;

        const trn = new DriverTrafficRegisterNumber();
        trn.countryOfIssue = this.selectedCountry.description;
        trn.number = this.identifier.value.toLocaleUpperCase()

        // Check if TRN already exist internally
        const criteriaInternal = new LegalEntityProviderCriteria();
        criteriaInternal.type = 'INTERNAL_PUBLIC';
        criteriaInternal.legalEntityIdentifier = trn;
        criteriaInternal.countryIdentifier = countryIdentifier;

        const requestInternal = new ProvideSecuredLegalEntityObscuredContactNumberRequest();
        requestInternal.criteria = criteriaInternal;

        this.startLoad();
        this.securedLegalEntityProviderWrapperService.provideSecuredLegalEntityObscuredContactNumber(requestInternal).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSecuredLegalEntityObscuredContactNumberResponse) as ProvideSecuredLegalEntityObscuredContactNumberResponse);
                if (response.obscuredContactNumber) {
                    RaygunErrorHandler.sendError("TRN number already exist");
                    this.errorMessage = "TRN number already exist";
                    this.stopLoad();
                } else {
                    const criteria = new LegalEntityProviderCriteria();
                    criteria.type = 'EXTERNAL';
                    criteria.legalEntityIdentifier = trn;
                    criteria.countryIdentifier = countryIdentifier;

                    const request = new ProvideSecuredLegalEntityObscuredContactNumberRequest();
                    request.criteria = criteria;

                    this.startLoad();
                    this.securedLegalEntityProviderWrapperService.provideSecuredLegalEntityObscuredContactNumber(request).then(httpResponse => {
                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.
                                unMarshallFromJson(httpResponse.body, ProvideSecuredLegalEntityObscuredContactNumberResponse) as ProvideSecuredLegalEntityObscuredContactNumberResponse);
                            if (response.obscuredContactNumber) {
                                if (response.obscuredContactNumber === "none" || response.obscuredContactNumber === "ProviderNotAvailable") {
                                    // this.externalTRN = trn;
                                    this.foundExtContactNumber = false;
                                    this.errorMessage = 'Unable to find your Traffic Registration Number with the specified country.';
                                }
                                else {
                                    this.errorMessage = null;
                                    this.obscuredContactCountry = response.country;
                                    this.obscuredContact = response.obscuredContactNumber;
                                    this.obfuscated = response.country + ' ';
                                    this.obfuscated += response.obscuredContactNumber;
                                    this.foundExtContactNumber = true;
                                    this.externalLegalEntityProviderCriteria = criteria;
                                    this.data.message = '';
                                    this.currentStep++;
                                }

                            } else {
                                this.foundExtContactNumber = false;
                                this.errorMessage = 'Unable to find your Traffic Registration Number with the specified country.';
                            }
                        }
                        this.stopLoad();
                    }).catch(error => {
                        this.stopLoad();
                        this.toastr.errorToast(error);
                        RaygunErrorHandler.sendError(error);
                    });
                }
            }
        }).catch(error => {
            this.stopLoad();
            console.error(error);
            RaygunErrorHandler.sendError(error);
        });
    }

    validateObscured() {
        if (!this.allowObscuredValidation) {
            return;
        }

        if (!this.externalLegalEntityProviderCriteria) {
            console.error('No criteria to validate against.');
            RaygunErrorHandler.sendError('No criteria to validate against.');
            return;
        }

        this.errorMessage = null;
        const request = new ValidateSecuredLegalEntityObscuredContactNumberRequest();
        const captured = this.one.value + this.two.value + this.three.value + this.four.value;
        request.capturedObscuredContactNumber = captured;
        request.criteria = this.externalLegalEntityProviderCriteria;

        this.startLoad();
        this.securedLegalEntityProviderWrapperService.validateSecuredLegalEntityObscuredContactNumber(request).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body, ValidateSecuredLegalEntityObscuredContactNumberResponse) as ValidateSecuredLegalEntityObscuredContactNumberResponse);
                if (response.valid) {
                    this.obscuredIncorrectCounter = 0;
                    this.errorMessage = null;
                    const contactNumber = new ContactNumber();
                    contactNumber.number = this.obscuredContact + captured;
                    this.legalEntityContactNumber = this.obscuredContactCountry + ' ' + this.obscuredContact + captured;
                    contactNumber.contactNumberType = 'Cell';
                    contactNumber.country = this.obscuredContactCountry;

                    this.issueOneTimePin(contactNumber).then(otpResponse => {
                        if (otpResponse) {
                            this.externalContactNumber = contactNumber;
                            this.contactNumberOTP = contactNumber.country + contactNumber.number;
                            this.currentStep++;

                            this.stopLoad();
                        }
                    }).catch(err => {
                        RaygunErrorHandler.sendError(err);

                        if (err) {
                            this.errorMessage = err;
                        }
                        this.stopLoad();
                    });
                } else {
                    this.obscuredIncorrectCounter++;
                    if (this.obscuredIncorrectCounter >= 3) {
                        this.allowObscuredValidation = false;
                        this.obscuredValidationService.startValidationCounter();
                        this.externalEntity = false;
                        this.errorMessage = this.obscuredValidationError;
                    } else {
                        const attemptsLeft = 3 - this.obscuredIncorrectCounter;
                        let attemptString = 'attempt';
                        if (attemptsLeft > 1) {
                            attemptString = 'attempts';
                        }
                        this.errorMessage = 'Incorrect number captured. ' + attemptsLeft + ' ' + attemptString + ' left.';
                    }
                    this.stopLoad();
                }
            }
        }).catch(error => {
            this.stopLoad();
            this.toastr.errorToast(error);
            RaygunErrorHandler.sendError(error);
        });
    }

    issueOneTimePin(contactNumber: ContactNumber): Promise<boolean> {
        const context = new OneTimePinContext();
        context.context = PinContext.VERIFY_ACCOUNT;

        const issueOneTimePinRequest = new IssueOneTimePinRequest();
        issueOneTimePinRequest.contactNumber = contactNumber;
        issueOneTimePinRequest.oneTimePinContext = context;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinIssuanceManagerService.issueOneTimePin(issueOneTimePinRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);
                    this.oneTimePinIdentifier = response.oneTimePinIdentifier;
                    resolve(true);
                    this.onCountDown()
                    return;
                }

                resolve(false);
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not issue one time pin.');

                return;
            });
        });
    }

    validateOneTimePin(capturedOneTimePin: string) {
        const validateOneTimePinRequest = new ValidateOneTimePinRequest();
        validateOneTimePinRequest.oneTimePinIdentifier = this.oneTimePinIdentifier;
        validateOneTimePinRequest.capturedOneTimePin = capturedOneTimePin;


        return new Promise((resolve, reject) => {
            if (!this.oneTimePinIdentifier) {
                reject('Seems like no OTP was issued.');
                return;
            }

            this.oneTimePinValidationManagerService.validateOneTimePin(validateOneTimePinRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinResponse) as ValidateOneTimePinResponse);

                    resolve(response.valid);
                    return;
                }

                resolve(false);
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not validate one time pin.');

                return;
            });
        });
    }

    validateExternalOTP(otp: string) {
        this.startLoad();
        this.validateOneTimePin(otp).then(response => {
            if (response !== undefined) {
                if (!response) {
                    this.otpInvalid();
                } else {
                    this.currentStep++;
                    this.initialiseSyncVehicle(true);
                    this.stopLoad();
                }
            }
        });
    }

    resendExternalOTP(event) {
        if (this.externalContactNumber) {
            this.startLoad();
            this.issueOneTimePin(this.externalContactNumber).then(otpResponse => {
                if (otpResponse) {
                    this.otpResent();
                }
                this.stopLoad();
            }).catch(err => {
                RaygunErrorHandler.sendError(err);

                if (err) {
                    this.errorMessage = err;
                }
                this.stopLoad();
            });
        }
    }

    otpResent() {
        this.invalidOTP = false;
        this.toast();
        this.countDown = 60;
        this.onCountDown();
        this.stopLoad();
    }

    private toast() {

        this.translate.get('TOAST.OTP_SENT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.legalEntityContactNumber }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });

            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.activeToast.portal.destroy();
            });
        });

    }

    onCountDown(): void {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown--;
                this.onCountDown();
            }, 1000);
        }
    }

    disable() {
        this.countDown = 0;
        this.resendDisabled = true;
    }

    onSubmit(event): void {

        if (this.currentStep === 0) {

            //select country get TRN
            if (this.data.trn_number !== null) {
                console.log('I am here');
                this.identifier.setValue(this.data.trn_number);
                this.currentStep += 4;
                this.initialiseSyncVehicle(false);
            }
            else {
                this.currentStep++;
            }

        } else if (this.currentStep === 1) {

            //submit trn
            // get obfuscatedNumber
            this.validateTRN();
        }
        else if (this.currentStep === 2) {

            //validate user input of number
            // generate OTP
            // show OTP
            //user input of OTP
            this.validateObscured();


        }
        else if (this.currentStep === 3) {

            //Validate OTP
            this.validateExternalOTP(this.otp.value)

        }
        else if (this.currentStep === 4) {
            //Sync Vehicle
            this.initialiseSyncVehicle(true);

        }
        else if (this.currentStep === 5) {

        }
        else {
            this.currentStep = 0;
        }
    }

    processSyncVehicle(offset: number, requestingUser: string, size: number) {

        const syncVehicleSearchRequest = new FinaliseSyncVehicleRequest();
        syncVehicleSearchRequest.syncedVehicles = offset;
        syncVehicleSearchRequest.batchSize = 10;
        syncVehicleSearchRequest.retailProfileOwnerIdentifier = requestingUser;



        if (offset === this.totalNumberOfVehicles) {
            //done
            this.currentStep++;
            return;
        } else {


            this.spinnerPercentage = Math.round(offset / size * 100);
            this.spinnerMode = 'determinate';

            this.syncRetailProfileFinalisationManagerService.finaliseSyncVehicle(syncVehicleSearchRequest).toPromise().then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, FinaliseSyncVehicleResponse) as FinaliseSyncVehicleResponse);
                    offset = response.syncedVehicles;
                    this.processSyncVehicle(offset, requestingUser, size);
                }

            }).catch(err => {
                RaygunErrorHandler.sendError(err);

                if (err) {
                    this.errorMessage = err;
                    this.successful = false;
                    //this.currentStep ++;
                    offset = size;
                }
                if (this.loading) {
                    this.stopLoad();
                }

            });

        }


    }


    initialiseSyncVehicle(loading: boolean) {

        if (this.identifier) {

            if (loading) {
                this.startLoad();
            }
            const syncVehicleRequest = new InitialiseSyncVehicleRequest();
            const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
            retailProfileOwnerIdentifier.tpiIdentifier = this.utils.getTpiIdentifier(this.data.legalEntity.legalEntityIdentifiers)
            syncVehicleRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
            syncVehicleRequest.trafficRegisterNumber = this.identifier.value;
            syncVehicleRequest.trnProvided = this.data.trn_number === null ? false : true;

            this.syncRetailProfileFinalisationManagerService.initialiseSyncVehicle(syncVehicleRequest).toPromise().then(httpResponse => {

                if (loading) {
                    this.stopLoad();
                }

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, InitialiseSyncVehicleResponse) as InitialiseSyncVehicleResponse);
                    const size = response.totalNumberOfVehicles;
                    this.totalNumberOfVehicles = response.totalNumberOfVehicles;
                    const requestingUser = this.utils.getTpiIdentifier(this.data.legalEntity.legalEntityIdentifiers).tpiIdentifier;
                    let offset = response.totalNumberOfVehicles;

                    this.processSyncVehicle(0, requestingUser, size);

                }

            }).catch(err => {
                RaygunErrorHandler.sendError(err);

                if (err) {
                    this.errorMessage = err;
                    this.successful = false;
                    this.currentStep++;
                }
                if (loading) {
                    this.stopLoad();
                }
            });
        }
    }

    submit(): void {
        document.getElementById('form-submit-obfuscated').click();
    }


    close(refreshVehicleComponent): void {

        this.dialogRef.close(refreshVehicleComponent);

    }

    getCountries(): void {
        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                this.countries = response.countries;
                this.countryService.setCountries(response.countries);
                this.createVerifiedAccountService.setDropdownCountries(response.countries);
            }
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: ' + err);
            RaygunErrorHandler.sendError(err);

            this.stopLoad();
        });

    }

    onKeyUp(event): void {

        if (event.keyCode !== 32 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40) {
            switch (event.srcElement.id) {
                case "one":
                    if (this.one.value.length === 1) {
                        document.getElementById("two").focus();
                    }
                    break;
                case "two":
                    if (this.two.value.length === 1) {
                        document.getElementById("three").focus();
                    }
                    break;
                case "three":
                    if (this.three.value.length === 1) {
                        document.getElementById("four").focus();
                    }
                    break;
                case "four":
                    break;
            }
        }
        else if (event.keyCode === 37 || event.keyCode === 39) {
            switch (event.srcElement.id) {
                case "one":
                    if (event.keyCode === 39) {
                        document.getElementById("two").focus();
                    }
                    break;
                case "two":
                    if (event.keyCode === 37) {
                        document.getElementById("one").focus();
                    }
                    else if (event.keyCode === 39) {
                        document.getElementById("three").focus();
                    }
                    break;
                case "three":
                    if (event.keyCode === 37) {
                        document.getElementById("two").focus();
                    }
                    else if (event.keyCode === 39) {
                        document.getElementById("four").focus();
                    }
                    break;
                case "four":
                    if (event.keyCode === 37) {
                        document.getElementById("three").focus();
                    }

                    break;
            }
        }
    }

    onKeyPress(event): void {

        if (event.keyCode !== 8 && event.keyCode !== 32) {
            switch (event.srcElement.id) {
                case "one":
                    document.getElementById("one").focus();
                    this.one.setValue(event.key);
                    break;
                case "two":
                    document.getElementById("two").focus();
                    this.two.setValue(event.key);
                    break;
                case "three":
                    document.getElementById("three").focus();
                    this.three.setValue(event.key);
                    break;
                case "four":
                    document.getElementById("four").focus();
                    this.four.setValue(event.key);
                    break;
            }
        }
    }

    onCheckValidation(event): void {
        this.errorMessage = null;

        if (this.form.valid) {
            const captured = this.one.value + this.two.value + this.three.value + this.four.value;
            this.onValid.emit(captured);
        }
    }


    getTRN(legalEntity: LegalEntity): string {

        for (var legalEntityIdentifier of legalEntity.legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TrafficRegisterNumber) {
                this.data.trn_number = legalEntityIdentifier.number;
                return legalEntityIdentifier.number;
            }
        }

        return this.identifier.value;
    }

    getTrafficRegisterNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TrafficRegisterNumber || legalEntityIdentifier["@class"] === 'za.co.magnabc.tpi.entity.driver.identification.TrafficRegisterNumber') {
                return (legalEntityIdentifier as TrafficRegisterNumber).number;
            }
        }

        return null;
    }

    get obscuredValidationError(): string {
        const time = this.obscuredValidationService.durationToTime(this.obscuredValidationService.blockDuration, false, true, false);
        return `Maximum Verification attempts reached. Please try again in ${time} minute${time === '1' ? '' : 's'}.`;
    }

}



