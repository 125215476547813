import {Request} from "../../../../../../../urdad/services/models/request.model"
import {
    CountryStartDateConfiguration
} from "../../../../../../insurance/setup/models/country-start-date-configuration.model"
import {PolicyDate} from '../../../../../models/policy-date.model'

export class CaptureUpdatedStartDateGroupingRequest extends Request{
    constructor(){
        super();
    }

    public countryStartDateConfiguration : CountryStartDateConfiguration;
    public policyDates : PolicyDate[] = [];
}
