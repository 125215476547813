import { BehaviorSubject } from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms'
import {
    ComprehensiveInsurancePolicyCartItem,
    DocumentType,
    InsuranceCategory,
    Money,
    MotorInsuranceVehicleDetails,
    RetailVehicle,
    ScannedDocument,
    ScannedDocumentIdentifierIdentifier,
    ScannedDocumentTypeDescriptionIdentifier,
    Vehicle,
    YCInsurancePolicyCartItem
} from '@magnabc/tpi'
import {Utils} from '../../../../../shared/Utils/Utils'
import {FormComponent} from '../../../../../shared/form/form.component'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    InsuranceProductService
} from '../../../../../../app.services/managers/insurance-product/insurance-product.service'
import { YCMotorVehicleInsuranceDetails } from '@magnabc/tpi/src/retail/insurance/yellowcardinsurance/models/yc-motor-vehicle-insurance-details.model';

declare const jQuery: any

@Component({
    selector: 'app-capture-motor-insurance-details',
    templateUrl: './capture-motor-insurance-details.component.html',
    styleUrls: ['./capture-motor-insurance-details.component.scss']
})
export class CaptureMotorInsuranceDetailsComponent extends FormComponent implements OnInit {

    @Output() submit = new EventEmitter<void>();
    @Output() uploadImages = new EventEmitter<string>();
    @Output() uploadDocuments = new EventEmitter<string>();
    @Output() vehicleValueChanged = new EventEmitter<CaptureMotorInsuranceDetailsComponentResult>();

    @Input() $formGroup: BehaviorSubject<UntypedFormGroup>;

    formGroup: UntypedFormGroup

    get motorInsuranceVehicles() {
        return this.formGroup.controls["motorInsuranceVehicles"] as UntypedFormArray
    }

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                public insuranceProductService: InsuranceProductService,
                private formBuilder: UntypedFormBuilder) {
        super(translate, translateParser)
    }

    ngOnInit(): void {

        this.formGroup = this.formBuilder.group({
            motorInsuranceVehicles: this.formBuilder.array([])
        })

        for (const vehicle of this.insuranceProductService.vehicles) {
            this.addMotorInsuranceVehicles(vehicle)
        }

        this.insuranceProductService.selectedVehicle.subscribe((vehicle) => {
            this.addMotorInsuranceVehicles(vehicle)
        })

        this.insuranceProductService.deselectedVehicle.subscribe((tpiIdentifier) => {
            this.removeMotorInsuranceVehicles(tpiIdentifier)
        })
        this.$formGroup.next(this.formGroup);
        this.updateFromItem();
    }

    updateFromItem() {
        if (this.insuranceProductService.item) {

            let vehicleValue = (this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).vehicleValue
            let alarmImmobiliser = (this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).alarmImmobiliser

            if (vehicleValue) {
                for (const motorInsuranceVehicle of this.motorInsuranceVehicles.controls) {
                    motorInsuranceVehicle.get('vehicleValue').setValue(vehicleValue.amount / 100)
                }
            }
            if (alarmImmobiliser) {
                for (const motorInsuranceVehicle of this.motorInsuranceVehicles.controls) {
                    motorInsuranceVehicle.get('alarmImmobiliser').setValue(true)
                }
            }
            for (const motorInsuranceVehicle of this.motorInsuranceVehicles.controls) {
                if ((this.insuranceProductService.item as YCInsurancePolicyCartItem).scannedDocumentIdentifiers) {
                    const item = this.insuranceProductService.item as YCInsurancePolicyCartItem;
                    const doc = item.scannedDocumentIdentifiers.at(0);
                    const docIdent = new ScannedDocumentIdentifierIdentifier();
                    docIdent.documentIdentifier = doc;
                    motorInsuranceVehicle.get('vehicleRegistrationDocumentId')
                        .setValue(docIdent);
                }
            }
        }
    }

    addMotorInsuranceVehicles(vehicle: RetailVehicle | Vehicle) {
        const motorInsuranceVehicle = this.formBuilder.group({
            platNumber: [this.insuranceProductService.getVehiclePlateNumber(vehicle)],
            tpiIdentifier: [this.insuranceProductService.getTpiVehicleIdentifier(vehicle)],
        });

        if (!this.isYCProduct) {
            motorInsuranceVehicle.addControl("vehicleValue", new FormControl(null, Validators.required));
            motorInsuranceVehicle.addControl("alarmImmobiliser", new FormControl(false));
        }
        else {
            motorInsuranceVehicle.addControl("vehicleRegistrationDocumentId", new FormControl(null));
        }

        this.motorInsuranceVehicles.push(motorInsuranceVehicle)
    }

    removeMotorInsuranceVehicles(tpiIdentifier: string) {

        let index = 0
        for (const motorInsuranceVehicle of this.motorInsuranceVehicles.controls) {
            if (motorInsuranceVehicle.get('tpiIdentifier').value === tpiIdentifier) {
                break
            }
            index++
        }
        this.motorInsuranceVehicles.removeAt(index)

    }

    motorInsuranceVehiclesValidation(_) {
        if (this.formGroup.valid) {
            this.submit.emit()
        } else {

            const container = jQuery('.tpi-product-container')
            const scrollTo = jQuery('#motor-insurance-vehicles .ng-invalid:first')
            if (scrollTo) {
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top - 15 + container.scrollTop()
                })
            }

        }
    }

    getFormGroup(tpiIdentifier: string) {
        for (const motorInsuranceVehicle of this.motorInsuranceVehicles.controls) {
            if (motorInsuranceVehicle.get('tpiIdentifier').value === tpiIdentifier) {
                return motorInsuranceVehicle
            }
        }
    }

    onSubmit() {
        if(!this.isYCProduct){
            this.formGroup.markAllAsTouched();
        }
        document.getElementById('form-submit-motor-insurance-vehicles').click()
    }

    createMotorInsuranceDetails() {

        const motorInsuranceVehicleDetails = []

        for (const vehicle of this.insuranceProductService.selectedVehicles) {

            const vehicleIdentifier = Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)
            const motorInsuranceVehicleDetail = new MotorInsuranceVehicleDetails()
            motorInsuranceVehicleDetail.vehicleIdentifier = vehicleIdentifier

            const formGroup = this.getFormGroup(vehicleIdentifier.number)
            motorInsuranceVehicleDetail.vehicleValue = new Money()
            motorInsuranceVehicleDetail.vehicleValue.currency = this.insuranceProductService.baseCurrency
            motorInsuranceVehicleDetail.vehicleValue.amount = parseInt(formGroup.get('vehicleValue').value, 10) * 100
            motorInsuranceVehicleDetail.alarmImmobiliser = formGroup.get('alarmImmobiliser').value
            motorInsuranceVehicleDetail.scannedDocumentIdentifiers = []
            if (this.insuranceProductService.vehicleImageFilesMap[vehicleIdentifier.number]) {
                for (const vehicleImageFiles of this.insuranceProductService.vehicleImageFilesMap[vehicleIdentifier.number]) {
                    const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
                    scannedDocumentIdentifier.documentIdentifier = (vehicleImageFiles.scannedDocument as ScannedDocument).documentIdentifier
                    const documentType = new DocumentType()
                    documentType.description = vehicleImageFiles.description
                    scannedDocumentIdentifier.documentTypeDescription = documentType
                    motorInsuranceVehicleDetail.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
                }
            }

            motorInsuranceVehicleDetails.push(motorInsuranceVehicleDetail)

        }

        return motorInsuranceVehicleDetails

    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {
        return Utils.getTpiVehicleIdentifier(vehicleIdentifiers).number
    }

    getVehiclePlateNumber(vehicle: Vehicle | RetailVehicle): string {
        return Utils.getVehiclePlateNumber(vehicle)
    }

    onUploadImages(tpiIdentifier: string) {
        this.insuranceProductService.tpiIdentifier = tpiIdentifier;
        this.uploadImages.emit(tpiIdentifier);
        return false
    }

    onUploadDocuments(tpiIdentifier: string) {
        this.insuranceProductService.tpiIdentifier = tpiIdentifier;
        this.uploadDocuments.emit(tpiIdentifier);
        return false
    }

    onVehicleValueChange(formGroup: UntypedFormGroup): void{
        const plateNumber = formGroup.get('platNumber').value;
        const tpiIdentifier = formGroup.get('tpiIdentifier').value;
        const vehicleValue = formGroup.get('vehicleValue').value;

        const result: CaptureMotorInsuranceDetailsComponentResult = {
            plateNumber,
            tpiIdentifier,
            vehicleValue
        }

        this.vehicleValueChanged.emit(result);
    }

    get isComprehensiveProduct(): boolean {
        return this.insuranceProductService.insuranceCategory === InsuranceCategory.COMPREHENSIVE;
    }

    get isYCProduct(): boolean {
        return this.insuranceProductService.insuranceCategory === InsuranceCategory.YELLOWCARD;
    }
}

export interface CaptureMotorInsuranceDetailsComponentResult{
    plateNumber: string;
    tpiIdentifier: string;
    vehicleValue: number;
}
