import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Item} from '../../../../../models/item.model';
import {MotorInsuranceDetails} from '../../../../../models/motor-insurance-details.model';
import {ScannedDocument} from '../../../../../../../media/scanneddocument/models/scanned-document.model';
import {ItemIdentifier} from '../../../../../identification/models/item-identifier.model';
import {ScannedDocumentIdentifier} from "../../../../../../../media/scanneddocument/identification/models/scanned-document-identifier.model";
import {Product} from '../../../../../../products/models/product.model';
import {PricePerVehicle} from '../../../../../../insurance/thirdpartyinsurance/models/price-per-vehicle.model';
import {RetailProfileIdentifier} from '../../../../../../profile/identification/retail-profile-identifier.model';

export class FinaliseCheckoutOrderRequest extends Request {
    constructor() {
        super();
    }
    public retailProfileIdentifier: RetailProfileIdentifier;
    public uploadImages: ScannedDocument[] = [];
    public removeImages: ScannedDocumentIdentifier[] = [];
    public updateItem: Item;
    public removeItem: ItemIdentifier;
    public motorInsuranceDetails: MotorInsuranceDetails;
    public pricePerVehicle: PricePerVehicle;
    public product: Product;
}
