
import {Response} from '../../../../../../../urdad/services/models/response.model';
import { CountryCheckoutItemGrouping } from '../../../../../models/country-checkout-item-grouping.model';
import { CountryStartDateConfiguration } from '../../../../../../insurance/setup/models/country-start-date-configuration.model';
import { CountryRegistrationConfiguration } from '../../../../../../insurance/setup/models/country-registration-configuration.model';
export class InitialiseCheckoutResponse extends Response {
    constructor() {
        super();
    }
    public countryCheckoutItemGroupings: CountryCheckoutItemGrouping[] = [];
    public countryStartDateConfiguration: CountryStartDateConfiguration;
    public countryRegistrationConfigurations: CountryRegistrationConfiguration[] = [];
}
