import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'
import {
    Account,
    AccountNaturalPersonCriteria,
    PlainTextUsername, ProvideAccountInformationRequest, ProvideAccountInformationResponse,
    UnMarshallerService,
} from '@magnabc/tpi';
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {BehaviorSubject, Subscription} from 'rxjs'

import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'
import {FormComponent} from '../../shared/form/form.component'
import {AccountUpdateEvent, AccountUpdateType} from './user-update.model'
import {UserData} from './user-data.model'
import {PublicAccountInformationProvider} from "../../../http.services/security/account/public-account-information-provider/public-account-information-provider.service";

@Component({
    selector: 'app-user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent extends FormComponent implements OnInit, OnDestroy {
    $done = new BehaviorSubject<AccountUpdateEvent>(
        {
            updateType: AccountUpdateType.NONE,
            dialog: this.dialog
        }
    );
    selectedTabIndex = 0;
    username = "";
    $account = new BehaviorSubject<Account>(null);
    subscriptions = new Subscription();

    constructor(private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: UserData,
        private publicAccountInformationProvider: PublicAccountInformationProvider,
        private unMarshallerService: UnMarshallerService,
        private errorToastService: ErrorToastService,
        private translate: TranslateService,
        private translateParser: TranslateParser) {
        super(translate, translateParser);
    }

    ngOnInit() {
        if (this.data) {
            var userNameSurname = `${this.data.userName} ${this.data.userSurname}`;
            if (this.data.isPublicUser) {
                this.username = userNameSurname;
            } else {
                this.username = this.data.userEmail ? this.data.userEmail : userNameSurname;
            }
            if (this.data.userEmail) {
                this.findAccount(this.data.userEmail);
            }
        }
        this.subscriptions.add(
            this.$done.subscribe((data) => {
                if (data.updateType === AccountUpdateType.DONE) {
                    this.stopLoad();
                    this.setDoneToNone();
                    this.dialog.closeAll();
                } else if (data.updateType === AccountUpdateType.ERROR) {
                    this.setDoneToNone();
                    this.stopLoad();
                }
            })
        );
    }

    private setDoneToNone(): void {
        this.$done.next({
            dialog: this.dialog,
            updateType: AccountUpdateType.NONE
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    doneClicked(): void {
        let updateType = AccountUpdateType.NONE;
        switch (this.selectedTabIndex) {
            case 0:
                updateType = AccountUpdateType.SERVICE_GROUPS;
                break;
            case 1:
                updateType = AccountUpdateType.BRANCH;
                break;
        }

        this.startLoad();
        this.$done.next({
            updateType,
            dialog: this.dialog
        });
    }

    cancelClicked(): void {
        this.dialog.closeAll();
    }

    showBranchTab(): boolean {
        return !this.data.isPublicUser;
    }

    private findAccount(userName: string): void {

        const plainTextUsername = new PlainTextUsername();
        plainTextUsername.username = userName;
        const criteria = new AccountNaturalPersonCriteria();
        criteria.naturalPersonIdentifiers.push(plainTextUsername);
        const provideAccountInformationRequest = new ProvideAccountInformationRequest();
        provideAccountInformationRequest.criteria = criteria;
        this.publicAccountInformationProvider.provideAccountInformation(provideAccountInformationRequest)
            .then((response) => {
                const provideAccountInformationResponse: ProvideAccountInformationResponse =
                    this.unMarshallerService.unMarshallFromJson(response.body, ProvideAccountInformationResponse);
                const account = provideAccountInformationResponse.accounts[0];
                this.$account.next(account);
            }).catch((error) => {
                this.errorToastService.errorToast(error);
            });
    }
}
