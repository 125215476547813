import { IStepComponent } from './../../../../view.components/shared/guards/step-guard';
import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-back-office-forgot-password-manager-page',
    templateUrl: './back-office-forgot-password-manager-page.component.html',
    styleUrls: ['./back-office-forgot-password-manager-page.component.scss']
})
export class BackOfficeForgotPasswordManagerPageComponent implements IStepComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        this.title.setTitle(`Forgot Password - Console - ${this.runtimeConfigurationService.title}`);
    }

    backPressed: boolean;
    onDeactivate(): boolean {
        return true
    }

  }
