import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
    MarshallerService,
    ProvideInsurancePoliciesRequest,
    ProvideInsurancePolicyItemRequest,
    ProvideInsurancePolicySummaryRequest,
    ProvideMotorInsuranceVehiclePolicyRequest,
    ProvideSuitableInsurancePolicyRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyProviderService {

    basePath = '/retail/insurance/insurancepolicyprovider';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    providePolicy(provideInsurancePoliciesRequest: ProvideInsurancePoliciesRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancepolicies';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideInsurancePoliciesRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideInsurancePolicyItem(provideInsurancePolicyItemRequest: ProvideInsurancePolicyItemRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancepolicyitem';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideInsurancePolicyItemRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideInsurancePolicySummary(provideInsurancePolicySummaryRequest: ProvideInsurancePolicySummaryRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancepolicysummary';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideInsurancePolicySummaryRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideMotorInsuranceVehiclePolicy(provideMotorInsuranceVehiclePolicyRequest: ProvideMotorInsuranceVehiclePolicyRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providemotorinsurancevehiclepolicy';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideMotorInsuranceVehiclePolicyRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideSuitableInsurancePolicy(provideSuitableInsurancePolicyRequest: ProvideSuitableInsurancePolicyRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesuitableinsurancepolicy';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSuitableInsurancePolicyRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }
}
