import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {Payment} from "../../../../../../../../financial/models/payment.model";
import {Transaction} from "../../../../../models/transaction.model";
import {PaymentAdvice} from "../../../../../../models/payment-advice.model";

export class VerifyCardPaymentResponse extends Response {
    constructor (){
        super();
    }

    payment: Payment;
    transaction: Transaction;
    paymentAdvice: PaymentAdvice;

}
