import { Request } from "../../../../../../../urdad/services/models/request.model";
import { EmailAddress } from "../../../../../../../entity/models/email-address.model";
import { OneTimeLinkContext } from "../../../../../models/one-time-link-context.model";

export class DetermineOneTimeLinkIssueCountRequest extends Request {
    constructor(){ 
        super();
    }

    public emailAddress: EmailAddress;
    public oneTimeLinkContext: OneTimeLinkContext;
}