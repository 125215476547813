import { Response } from "../../../../urdad/urdad_api";
import { VehicleImportError } from "../../models/vehicle-import-error";

export class ImportFleetResponse extends Response {
    constructor() {
        super();
    }

    vehicleImportErrors: VehicleImportError[] = [];
    totalValidVehicles: number;
    totalVehicles: number;
}