import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../../../shared/form/form.component";
import {
    Order,
    OrderNumberCriteria,
    OrderNumberIdentifier,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    UnMarshallerService
} from "@magnabc/tpi";
import { OrderFinalisationManagerService } from '../../../../../http.services/retail/checkout/order-finalisation-manager/order-finalisation-manager.service';
import { OrderInformationProviderService } from '../../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';


@Component({
  selector: 'app-order-query',
  templateUrl: './order-query.component.html',
  styleUrls: ['./order-query.component.scss']
})
export class OrderQueryComponent extends FormComponent implements OnInit {

    @Input() transparent = false;
    @Input() verifyCardPayment = false;
    @Output() onOrder = new EventEmitter<Order>();
    @Output() onExists = new EventEmitter<boolean>();

    formGroup: UntypedFormGroup;
    identifier: UntypedFormControl;

    exist = true;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private orderInformationProviderService: OrderInformationProviderService,
                private orderFinalisationManagerService: OrderFinalisationManagerService,
                private unMarshallerService: UnMarshallerService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        this.identifier = new UntypedFormControl('', [
            Validators.required
        ]);
        this.formGroup = new UntypedFormGroup({
            'identifier': this.identifier
        });

    }

    submit(): void {
        document.getElementById('form-submit-query').click();
    }

    query(number: string): void {
        this.identifier.setValue(number);
        this.onCheckValidation(null);
    }

    onCheckValidation(event): void {

        if (this.form.valid) {
            this.getOrder();
        }

    }

    getOrder(): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(this.identifier.value.toLocaleUpperCase()));

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderReferenceNumberCriteria;

        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {

            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            if (response.orders.length > 0) {

                if (response.orders[0].orderItems.length === 0) {
                    this.getOrder();
                } else {
                    this.onOrder.emit(response.orders[0]);
                    //order set on service for reloading pursposes is not set to be referenced
                    this.orderFinalisationManagerService.order = response.orders[0];
                    this.exist = true;
                    this.onExists.emit(true);
                }
            } else {
                this.exist = false;
                this.onExists.emit(false);
            }
        });

    }

}
