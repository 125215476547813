import {Request } from '../../../../../../../urdad/services/models/request.model';
import { AccountIdentifier } from '../../../../../identification/models/account-identifier.model';

export class UpdatePasswordRequest extends Request{
    constructor(){
        super();
    }

    public passwordNew: string;
    public passwordOld: string;
    public accountIdentifier: AccountIdentifier;

}