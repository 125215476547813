import { Request } from '../../../../../../../urdad/services/models/request.model';
import { RetailProfileIdentifier } from '../../../../../profile_api';

export class InitialiseSyncVehicleRequest extends Request{

    constructor() {
        super();
    }

    public trafficRegisterNumber: string;
    public trnProvided: boolean;
    public retailProfileIdentifier: RetailProfileIdentifier;
}
