import { Money } from "../../../../financial/models/money.model";
import { ScannedDocumentIdentifier } from "../../../../media/media_api";
import { Vehicle } from "../../../../vehicle/models/vehicle.model";
import { InsuranceCartItem } from "../../models/insurance-cart-item.model";
import { YCInsuranceProductCartItem } from "./yc-insurance-product-cart-item.model";

export class YCInsurancePolicyCartItem extends InsuranceCartItem{

    constructor(){
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyCartItem";
    }

    public price: Money;
    public startDate: Date;
    public expiryDate: Date;
    public vehicle: Vehicle;
    public ycInsuranceProductCartItem: YCInsuranceProductCartItem;
    public scannedDocumentIdentifiers: string[];
}
