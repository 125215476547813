import { CountryIdentifier } from "./country-identifier.model";


export class CountryDescriptionIdentifier extends CountryIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.location.geographic.identification.CountryDescriptionIdentifier";
    }

    public description:string;

}
