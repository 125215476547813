import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {FormComponent} from "../../../../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {Excess, Money, UniversalExcess} from '@magnabc/tpi';
import {InsuranceProductManagerService} from "../../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service";

@Component({
    selector: 'app-universal-excess',
    templateUrl: './universal-excess.component.html',
    styleUrls: ['./universal-excess.component.scss']
})
export class UniversalExcessComponent extends FormComponent implements OnInit {

    @Input() excess: Excess[];
    @Output() onExcess= new EventEmitter<Excess[]>();
    @Output() onValid = new EventEmitter<void>();

    excessDuplicateDescription = false;
    formGroup: UntypedFormGroup;
    excessValue: UntypedFormControl;
    excessDescription: UntypedFormControl;
    excessPercentage: UntypedFormControl;

    @ViewChild('form') form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public retailProductService: InsuranceProductManagerService) {
        super(translate, translateParser);

    }

    ngOnInit() {
        if (this.excess && this.excess[0] && (this.excess[0] instanceof UniversalExcess || this.excess[0]['@class'] == new UniversalExcess('')['@class'])) {
            const value = (this.excess[0] as UniversalExcess).value ? (this.excess[0] as UniversalExcess).value.amount : 0;
            this.excessValue = new UntypedFormControl(value / 100, [
                Validators.required,
                Validators.min(0)
            ]);
            this.excessDescription = new UntypedFormControl((this.excess[0] as UniversalExcess).description, [
                Validators.required,
                Validators.maxLength(250)
            ]);
            this.excessPercentage = new UntypedFormControl((this.excess[0] as UniversalExcess).percentage, [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
            ]);

        } else {
            this.excessValue = new UntypedFormControl('', [
                Validators.required,
                Validators.min(0)
            ]);
            this.excessDescription = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(250)
            ]);
            this.excessPercentage = new UntypedFormControl('', [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
            ]);
        }

        this.formGroup = new UntypedFormGroup({
            'excessValue': this.excessValue,
            'excessDescription': this.excessDescription,
            'excessPercentage': this.excessPercentage
        });

    }

    submit(): void {
        document.getElementById('form-submit-excess').click();
    }

    onCheckValidation(event): void {

        this.excessDuplicateDescription = false;
        if (!this.form.invalid) {

            var newExcess = new UniversalExcess(this.excessDescription.value);

            const money = new Money;
            money.amount = this.excessValue.value * 100;
            money.currency = this.retailProductService.currency;

            newExcess.value = money;
            newExcess.percentage = this.excessPercentage.value;

            this.excess = [];
            this.retailProductService.product.excess = [];

            for (const excess of this.retailProductService.product.excess) {
                if ((excess as any).description === newExcess.description) {
                    this.excessDuplicateDescription = true;
                    return;
                }
            }

            this.excess.push(newExcess);

            this.onExcess.emit(this.excess);
        }

        if(this.form.valid){
            this.onValid.emit();
        }
    }

}
