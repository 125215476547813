import { Money } from "../../../financial/models/money.model";
export class RoleRevenue
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.retail.reporting.RoleRevenue";
    }

    public underwriterRevenue: Money;
    public brokerRevenue: Money;
    public adminRevenue: Money;
    public printingAgentRevenue: Money;
    public sellingAgentRevenue: Money;
    public paymentAgentRevenue: Money;
    public revenueAuthorityRevenue: Money;
    public roadAuthorityRevenue: Money;

}