import {Request} from '../../../../../../../urdad/services/models/request.model';
import {NaturalPerson} from '../../../../../../../entity/models/natural-person.model';
import {Credentials} from '../../../../../../credentials/models/credentials.model';

export class CreateVerifiedAccountRequest extends Request {
    constructor(){
        super();
    }

    naturalPerson: NaturalPerson;
    credentials: Credentials;
}
