import {Request} from "../../../../../../urdad/services/models/request.model";
import { Vehicle } from "../../../../../models/vehicle.model";
import { Model, Make } from "../../../../../vehicle_api";

export class FinaliseMaintainVehicleInformationRequest extends Request {

    constructor() {
        super();
    }

    public vehicle : Vehicle;
    public unverifiedMake: Make;
    public unverifiedModel: Model;
}

