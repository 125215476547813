import { ProposalRecordStatus } from "./proposal-record-status.model";

export class ProposalSummaryInformation {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.proposalsummaryinformation";
    }

    public proposalRecordStatus: ProposalRecordStatus;
    public tpiVehicleIdentifier: string;
    public proposalId: string;
}