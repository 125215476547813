import { AuthenticationService } from './../../../../../app.services/managers/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Country, InsuranceCategory, CountryDescriptionIdentifier, CountryIdentifier } from '@magnabc/tpi';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    InsuranceProductService,
} from '../../../../../app.services/managers/insurance-product/insurance-product.service';
import { CountryService } from '../../../../shared/country/services/country.service';

@Component({
    selector: 'app-product-information',
    templateUrl: './product-information.component.html',
    styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit {

    insuranceCategory = InsuranceCategory
    $countries = new BehaviorSubject<Country[]>(null);

    constructor(public insuranceProductService: InsuranceProductService,
        private countryService: CountryService,
        private authService: AuthenticationService) { }

    ngOnInit(): void {
        const countries = this.countryService.getCountries();
        if(countries.length === 0){
            this.countryService.populateCountries().then(() => {
                this.$countries.next(this.countryService.getCountries());
            })
        }else{
            this.$countries.next(countries);
        }
    }

    getCountry(name: string): Observable<Country>{
        return this.$countries.pipe(
            map(data => {
                if(data){
                    return data.find(item => item.description === name)
                }
            })
        );
    }

    get coveredCountries(): CountryIdentifier[] {
        if (this.authService.publicAuthentication && this.authService.getLoggedInNaturalPerson !== null) {
            const selection = this.insuranceProductService.selectedCoveredCountries;
            return this.insuranceProductService.coveredCountries.filter((item: CountryDescriptionIdentifier) => selection.includes(item.description));
        } else {
            return this.insuranceProductService.coveredCountries;
        }
    }
}
