import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, ProvideCountryUnderwriterProductInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class CountryUnderwritersProviderService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  provideCountryUnderwriterProductInformation(provideCountryUnderwriterProductInformationRequest: ProvideCountryUnderwriterProductInformationRequest) {
    const path = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/countryunderwritersprovider/providecountryunderwriterproductinformation';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryUnderwriterProductInformationRequest);
    return this.httpClient.post(path, jsonRequest, this.options).toPromise();
  }
}
