import {Location} from '@angular/common'
import {AfterViewInit, Component} from '@angular/core'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Router} from '@angular/router'
import {ComprehensiveInsuranceProduct, DurationType, ThirdPartyInsuranceProduct} from "@magnabc/tpi"
import {
    YCInsuranceProduct
} from '@magnabc/tpi/src/retail/insurance/yellowcardinsurance/models/yc-insurance-product.model'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {environment} from '../../../../../../environments/environment'
import {Dropdown} from '../../../../../common/model/dropdown.model'
import {
    InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {IStepComponent} from '../../../../../view.components/shared/guards/step-guard'
import {ProductPriceScheduleDurationPipe} from '../../../../../view.components/shared/pipes/product-price-schedule.pipe'
import {ProductTypePipe} from '../../../../../view.components/shared/pipes/product-type.pipe'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {ProductPricingScheduleEnum} from './product-pricing-schedule.enum'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-product-pricing-schedule',
    templateUrl: './product-pricing-schedule-page.component.html',
    styleUrls: ['./product-pricing-schedule-page.component.scss']
})
export class ProductPricingSchedulePageComponent extends FormComponent implements AfterViewInit, IStepComponent {

    backPressed: boolean
    steps = []
    currentStep = 0
    breadcrumbStep = 0
    disableExactSelect = false;
    disableIntervalToSelect = false;
    disableIntervalFromSelect = false;
    gracePeriodDaysSelect = false;

    states = ProductPricingScheduleEnum
    currentState: ProductPricingScheduleEnum = ProductPricingScheduleEnum.CAPTURE_PRICING

    private activeToast: ActiveToast<any>

    productTypePipe = ProductTypePipe
    durationTypeList = DurationType
    durationTypes = [
        new Dropdown(DurationType.INTERVAL, "Interval"),
        new Dropdown(DurationType.EXACT, "Exact")
    ]
    invalidInterval = false
    invalidExact = false
    showProRata = false
    showPercentage = false
    showExtension = false
    invalidDuplicateDuration = false
    invalidMinimumValue = false
    showExtensionTab = true

    invalidMinimumValueAnnual = false
    invalidMinimumValueNewPolicy = false
    invalidMinimumValueAdditional = false
    invalidNewPolicyValueAnnual = false
    invalidNewPolicyValueAdditional = false
    invalidAdditionalVehicleToPolicyValueAnnual = false
    invalidPolicyDuration = false

    months = []
    days = []
    disabledExtension = true;

    formGroupPricing: UntypedFormGroup
    formGroupExtension: UntypedFormGroup

    constructor(private translate: TranslateService,
        private translateParser: TranslateParser,
        private title: Title,
        private router: Router,
        private toastr: ErrorToastService,
        public insuranceProductManagerService: InsuranceProductManagerService,
        private location: Location,
        private activatedRoute: ActivatedRoute,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser)

        title.setTitle(`Product Pricing Schedule - Console - ${this.runtimeConfigurationService.title}`)

        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_PRICING").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-money", borderColor: "#1592E6" })
        })

        this.showExtensionMenu();

        for (let i = 0; i <= ProductPriceScheduleDurationPipe.MONTHS_IN_YEAR; i++) {
            this.months.push(new Dropdown(i, `${i}`))
        }

        for (let i = 0; i <= ProductPriceScheduleDurationPipe.DAYS_IN_YEAR; i++) {
            this.days.push(new Dropdown(i, `${i}`))
        }

        this.sortDisplayList();

    }

    ngOnInit() {

        this.showPercentage = this.productTypePipe.isComprehensive(this.insuranceProductManagerService.product) || this.insuranceProductManagerService.priceSchedule.percentageOfAnnualAmount
        this.showExtension = this.insuranceProductManagerService.priceSchedule.extension
        this.showProRata = this.insuranceProductManagerService.priceSchedule.proRata

        let minimumPolicyValue = this.insuranceProductManagerService.priceSchedule.minimumPolicyValue
        if (!this.showPercentage && this.insuranceProductManagerService.priceSchedule.minimumPolicyValue) {
            minimumPolicyValue = this.insuranceProductManagerService.priceSchedule.minimumPolicyValue / 100
        }

        if (this.showPercentage && this.insuranceProductManagerService.priceSchedule.minimumPolicyValue && this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {
            minimumPolicyValue = this.insuranceProductManagerService.priceSchedule.minimumPolicyValue / 100
        }

        if (this.productTypePipe.isComprehensive(this.insuranceProductManagerService.product) && this.showProRata && this.insuranceProductManagerService.priceSchedule.minimumPolicyValue) {
            minimumPolicyValue = this.insuranceProductManagerService.priceSchedule.minimumPolicyValue / 100
        }

        let newPolicyValue = this.insuranceProductManagerService.priceSchedule.newPolicyValue
        if (!this.showPercentage && this.insuranceProductManagerService.priceSchedule.newPolicyValue) {
            newPolicyValue = this.insuranceProductManagerService.priceSchedule.newPolicyValue / 100
        }

        let additionalVehicleToPolicyValue = this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue
        if (!this.showPercentage && this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue) {
            additionalVehicleToPolicyValue = this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue / 100
        }

        let additionalVehicleToPolicyExtensionValue = this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyExtensionValue
        if (!this.showPercentage && this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue) {
            additionalVehicleToPolicyExtensionValue = this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyExtensionValue / 100
        }


        this.formGroupPricing = new UntypedFormGroup({
            durationType: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.durationType, [Validators.required]),
            exactDurationMonths: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.exactDuration.months, []),
            exactDurationDays: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.exactDuration.days, []),
            intervalDurationFromMonths: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.intervalDurationFrom.months, []),
            intervalDurationFromDays: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.intervalDurationFrom.days, []),
            intervalDurationToMonths: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.intervalDurationTo.months, []),
            intervalDurationToDays: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.intervalDurationTo.days, []),
            proRata: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.proRata, []),
            percentageOfAnnualAmount: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.percentageOfAnnualAmount, []),
            minimumPolicyValue: new UntypedFormControl(minimumPolicyValue, []),
            newPolicyValue: new UntypedFormControl(newPolicyValue, [Validators.required]),
            additionalVehicleToPolicyValue: new UntypedFormControl(additionalVehicleToPolicyValue, [Validators.required]),
        })

        this.formGroupExtension = new UntypedFormGroup({
            extension: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.extension, [Validators.required]),
            gracePeriodDays: new UntypedFormControl(this.insuranceProductManagerService.priceSchedule.gracePeriod.days, []),
            lengthOfExtensionMonths: new UntypedFormControl({ value: this.insuranceProductManagerService.priceSchedule.lengthOfExtension.months, disabled: true }, []),
            lengthOfExtensionDays: new UntypedFormControl({ value: this.insuranceProductManagerService.priceSchedule.lengthOfExtension.days, disabled: true }, []),
            newPolicyExtensionValue: new UntypedFormControl(null, []),
            additionalVehicleToPolicyExtensionValue: new UntypedFormControl(additionalVehicleToPolicyExtensionValue, []),
        })

        this.formGroupExtension.get('newPolicyExtensionValue').disable()

        this.checkFinalMonth();
    }

    ngAfterViewInit(): void {

        this.toggleProRata(this.showProRata)
        this.togglePercentage(this.showPercentage)
        this.toggleExtension(this.showExtension)
        this.enableOrDisableExtension()
    }

    isDuplicateDuration() {
        this.invalidDuplicateDuration = false
        if (this.insuranceProductManagerService.product instanceof ThirdPartyInsuranceProduct || this.insuranceProductManagerService.product instanceof ComprehensiveInsuranceProduct) {
            for (const priceSchedule of this.insuranceProductManagerService.product.priceSchedules) {
                if (priceSchedule.priceScheduleId !== this.insuranceProductManagerService.priceSchedule.priceScheduleId) {
                    if (priceSchedule.durationType === this.formGroupPricing.get('durationType').value) {
                        if (priceSchedule.durationType === DurationType.EXACT) {
                            if (priceSchedule.exactDuration.months === parseInt(this.formGroupPricing.get('exactDurationMonths').value, 10) &&
                                priceSchedule.exactDuration.days === parseInt(this.formGroupPricing.get('exactDurationDays').value, 10)) {
                                this.invalidDuplicateDuration = true
                            }
                        } else if (priceSchedule.durationType === DurationType.INTERVAL) {
                            if (priceSchedule.intervalDurationFrom.months === parseInt(this.formGroupPricing.get('intervalDurationFromMonths').value, 10) &&
                                priceSchedule.intervalDurationFrom.days === parseInt(this.formGroupPricing.get('intervalDurationFromDays').value, 10) &&
                                priceSchedule.intervalDurationTo.months === parseInt(this.formGroupPricing.get('intervalDurationToMonths').value, 10) &&
                                priceSchedule.intervalDurationTo.days === parseInt(this.formGroupPricing.get('intervalDurationToDays').value, 10)) {
                                this.invalidDuplicateDuration = true
                            }
                        }
                    }
                }
            }
        }
    }

    onChangeDurationType(event) {
        if (event.value === DurationType.EXACT) {
            this.formGroupPricing.get('proRata').setValue(false)
        }
        this.setLengthOfExtension()
        this.isDuplicateDuration()
    }

    onExactDurationMonths(_) {
        this.setLengthOfExtension()
        this.isDuplicateDuration()
        this.enableOrDisableExtension()
    }

    onExactDurationDays(_) {
        this.setLengthOfExtension()
        this.isDuplicateDuration()
        this.enableOrDisableExtension()
    }

    setLengthOfExtension() {
        let months = this.formGroupPricing.get('exactDurationMonths').value
        let days = this.formGroupPricing.get('exactDurationDays').value
        if (this.formGroupPricing.get('durationType').value === DurationType.EXACT && months === 12) {
            this.formGroupPricing.get('exactDurationDays').setValue(0);
            this.disableExactSelect = true;
        } else {
            this.disableExactSelect = false;
        }
        if (this.formGroupPricing.get('durationType').value === DurationType.INTERVAL) {
            months = this.formGroupPricing.get('intervalDurationToMonths').value
            days = this.formGroupPricing.get('intervalDurationToDays').value
        }
        if (days > 0) {
            months++
        } else {
            days = ProductPriceScheduleDurationPipe.DAYS_IN_YEAR
        }
        this.formGroupExtension.get('lengthOfExtensionMonths').setValue(ProductPriceScheduleDurationPipe.MONTHS_IN_YEAR - months)
        this.formGroupExtension.get('lengthOfExtensionDays').setValue(ProductPriceScheduleDurationPipe.DAYS_IN_YEAR - days)
        this.isDuplicateDuration()
    }

    onIntervalDurationFromMonths(event) {
        if (event.value > this.formGroupPricing.get('intervalDurationToMonths').value) {
            this.formGroupPricing.get('intervalDurationToMonths').setValue(event.value)
        }
        if (this.formGroupPricing.get('intervalDurationFromDays').value > this.formGroupPricing.get('intervalDurationToDays').value &&
            this.formGroupPricing.get('intervalDurationToMonths').value !== 12) {
            this.formGroupPricing.get('intervalDurationToDays').setValue(this.formGroupPricing.get('intervalDurationFromDays').value)
        }
        // Always set days to 0 when duration is 12 months and disable the days field
        if (event.value === 12) {
            this.formGroupPricing.get('intervalDurationFromDays').setValue(0);
            this.formGroupPricing.get('intervalDurationToDays').setValue(0);
            this.disableIntervalFromSelect = true;
            this.disableIntervalToSelect = true;

        } else {
            this.disableIntervalFromSelect = false;
        }
        this.setLengthOfExtension();
        this.isDuplicateDuration()
        this.enableOrDisableExtension()
    }

    onIntervalDurationFromDays(event) {
        if (this.formGroupPricing.get('intervalDurationFromMonths').value === this.formGroupPricing.get('intervalDurationToMonths').value
            && event.value > this.formGroupPricing.get('intervalDurationToDays').value) {
            this.formGroupPricing.get('intervalDurationToDays').setValue(event.value)
        }
        this.isDuplicateDuration()
    }

    onIntervalDurationToMonths(event) {
        if (event.value < this.formGroupPricing.get('intervalDurationFromMonths').value) {
            this.formGroupPricing.get('intervalDurationFromMonths').setValue(event.value)
        }
        if (this.formGroupPricing.get('intervalDurationFromDays').value > this.formGroupPricing.get('intervalDurationToDays').value) {
            this.formGroupPricing.get('intervalDurationToDays').setValue(this.formGroupPricing.get('intervalDurationFromDays').value)
        }
        // Always set days to 0 when duration is 12 months and disable the days field
        if (event.value === 12 || this.formGroupPricing.get('intervalDurationToMonths').value === 12) {
            this.formGroupPricing.get('intervalDurationToDays').setValue(0);
            this.disableIntervalToSelect = true;

        } else {
            this.disableIntervalToSelect = false;
            this.disableIntervalFromSelect = false;
        }
        this.setLengthOfExtension();
        this.isDuplicateDuration();
        this.enableOrDisableExtension();
    }

    /**
     * Determines whether grace duration is 12 months
     * @param event
     */
    onGraceDurationMonths(event) {
        if (event.value === 12) {
            this.formGroupExtension.get('gracePeriodDays').setValue(0);
            this.gracePeriodDaysSelect = true
        } else {
            this.gracePeriodDaysSelect = false
        }
    }

    onIntervalDurationToDays(event) {
        if (this.formGroupPricing.get('intervalDurationFromMonths').value === this.formGroupPricing.get('intervalDurationToMonths').value
            && event.value < this.formGroupPricing.get('intervalDurationFromDays').value) {
            this.formGroupPricing.get('intervalDurationFromDays').setValue(event.value)
        }
        this.setLengthOfExtension()
        this.isDuplicateDuration()
        this.enableOrDisableExtension();
    }

    onPercentageOfAnnualAmountToggle(event) {
        this.togglePercentage(event.checked)
    }

    onProRateToggle(event) {
        this.toggleProRata(event.checked)
    }

    toggleProRata(showProRata: boolean) {
        this.showProRata = showProRata
        if (this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {
            if (showProRata) {
                this.formGroupPricing.get('percentageOfAnnualAmount').setValue(false)
                this.formGroupPricing.get('percentageOfAnnualAmount').disable()
            } else {
                this.formGroupPricing.get('percentageOfAnnualAmount').enable()
            }
        }
        if (this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product) || this.showProRata) {
            this.formGroupPricing.get('minimumPolicyValue').setValidators([Validators.required])
        } else if (this.productTypePipe.isComprehensive(this.insuranceProductManagerService.product)) {
            this.formGroupPricing.get('minimumPolicyValue').clearValidators()
            this.formGroupPricing.get('minimumPolicyValue').setValue(0)
        }
        this.formGroupPricing.get('minimumPolicyValue').updateValueAndValidity()
        this.toggleExtension(this.showExtension)
    }

    togglePercentage(showPercentage: boolean) {

        this.showPercentage = showPercentage

        if (this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {

            if (showPercentage) {

                this.formGroupPricing.get('proRata').setValue(false)
                this.formGroupPricing.get('proRata').disable()

                this.formGroupPricing.get('newPolicyValue').setValidators([Validators.required, Validators.max(100)])
                this.formGroupPricing.get('additionalVehicleToPolicyValue').setValidators([Validators.required, Validators.max(100)])
                if (this.formGroupPricing.get('newPolicyValue').value > 100) {
                    this.formGroupPricing.get('newPolicyValue').setValue(100)
                }
                if (this.formGroupPricing.get('additionalVehicleToPolicyValue').value > 100) {
                    this.formGroupPricing.get('additionalVehicleToPolicyValue').setValue(100)
                }

            } else {

                const annualAmount = (this.insuranceProductManagerService.product as ThirdPartyInsuranceProduct).annualAmount.amount / 100
                this.formGroupPricing.get('proRata').enable()
                this.formGroupPricing.get('newPolicyValue').setValidators([Validators.required])
                this.formGroupPricing.get('additionalVehicleToPolicyValue').setValidators([Validators.required])

            }

        } else if (this.productTypePipe.isComprehensive(this.insuranceProductManagerService.product)) {
            const annualPercentage = (this.insuranceProductManagerService.product as ComprehensiveInsuranceProduct).annualPercentage
            this.formGroupPricing.get('newPolicyValue').setValidators([Validators.required, Validators.max(100)])
            this.formGroupPricing.get('additionalVehicleToPolicyValue').setValidators([Validators.required, Validators.max(100)])
        }

        this.toggleExtension(this.showExtension)

    }

    onExtensionToggle(event) {
        this.toggleExtension(event.checked)
    }

    toggleExtension(showExtension: boolean) {
        this.showExtension = showExtension

        if (!showExtension) {
            this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').setValue(null)
        }
        if (this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {

            if (this.showPercentage) {

                this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').setValidators([Validators.required, Validators.max(100)])
                if (this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').value > 100) {
                    this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').setValue(100)
                }

            } else {

                const annualAmount = (this.insuranceProductManagerService.product as ThirdPartyInsuranceProduct).annualAmount.amount / 100
                this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').setValidators([Validators.required])

            }

        } else if (this.productTypePipe.isComprehensive(this.insuranceProductManagerService.product)) {

            this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').setValidators([Validators.required, Validators.max(100)])

        }

    }

    /**
     * Enables or disable extension
     * If the original product is for a year, the extension should be disabled
     */
    enableOrDisableExtension() {
        if ((this.formGroupPricing.get('durationType').value === DurationType.EXACT && (this.formGroupPricing.get('exactDurationMonths').value === 12 ||
            (this.formGroupPricing.get('exactDurationMonths').value === 11 && this.formGroupPricing.get('exactDurationDays').value === 31))) ||
            (this.formGroupPricing.get('durationType').value === DurationType.INTERVAL && (this.formGroupPricing.get('intervalDurationToMonths').value === 12 ||
                (this.formGroupPricing.get('intervalDurationToMonths').value === 11 && this.formGroupPricing.get('intervalDurationToDays').value === 31)))) {
            this.formGroupExtension.get('extension').setValue(false)
            this.insuranceProductManagerService.priceSchedule.extension = this.formGroupExtension.get('extension').value
            this.showExtensionTab = false
        } else {
            this.showExtensionTab = true
            this.formGroupExtension.get('extension').enable()
        }
        this.showExtensionMenu();
    }

    showExtensionMenu() {
        const indexOfObject = this.steps.findIndex((item) => {
            return item.id === 1
        })

        if (this.showExtensionTab) {
            if (indexOfObject === -1) {
                this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_EXTENSION").subscribe((response) => {
                    this.steps.push({ id: 1, name: response, icon: "fa-angle-double-up", borderColor: "#3171c3" })
                })
            }
        } else {
            if (indexOfObject !== -1) {
                this.steps.splice(indexOfObject, 1);
            }
        }
    }

    onSubmitPricing() {

        this.invalidMinimumValueAnnual = false
        this.invalidMinimumValueNewPolicy = false
        this.invalidMinimumValueAdditional = false
        this.invalidNewPolicyValueAnnual = false
        this.invalidNewPolicyValueAdditional = false
        this.invalidAdditionalVehicleToPolicyValueAnnual = false

        if (this.formGroupPricing.valid) {
            this.invalidMinimumValue = false
            this.invalidExact = false
            this.invalidInterval = false
            this.invalidPolicyDuration = false

            if (this.formGroupPricing.get('durationType').value === DurationType.EXACT
                && this.formGroupPricing.get('exactDurationMonths').value === 0
                && this.formGroupPricing.get('exactDurationDays').value === 0) {
                this.invalidExact = true
                return
            }

            if (this.formGroupPricing.get('durationType').value === DurationType.INTERVAL) {
                if (this.formGroupPricing.get('intervalDurationFromMonths').value === this.formGroupPricing.get('intervalDurationToMonths').value
                    && this.formGroupPricing.get('intervalDurationFromDays').value === this.formGroupPricing.get('intervalDurationToDays').value) {
                    this.invalidInterval = true
                    return
                }
                if (this.formGroupPricing.get('intervalDurationFromMonths').value === 11 && this.formGroupPricing.get('intervalDurationFromDays').value === 31 && this.formGroupPricing.get('intervalDurationToMonths').value === 12) {
                    this.invalidPolicyDuration = true
                    return
                }
            }
            if (this.invalidDuplicateDuration) {
                return
            }

            this.enableOrDisableExtension();

            let annualAmount = 0;
            if (ProductTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {
                annualAmount = (this.insuranceProductManagerService.product as ThirdPartyInsuranceProduct).annualAmount.amount / 100
            }
            else {
                annualAmount = (this.insuranceProductManagerService.product as ComprehensiveInsuranceProduct).annualPercentage

            }
            if (ProductTypePipe.isThirdParty(this.insuranceProductManagerService.product) && !this.formGroupPricing.get('percentageOfAnnualAmount').value) {
                if (parseFloat(this.formGroupPricing.get('minimumPolicyValue').value) > annualAmount) {
                    this.invalidMinimumValueAnnual = true
                }

                if (parseFloat(this.formGroupPricing.get('minimumPolicyValue').value) > parseFloat(this.formGroupPricing.get('newPolicyValue').value)) {
                    this.invalidMinimumValueNewPolicy = true
                }

                if (parseFloat(this.formGroupPricing.get('minimumPolicyValue').value) > parseFloat(this.formGroupPricing.get('additionalVehicleToPolicyValue').value)) {
                    this.invalidMinimumValueAdditional = true
                }
            }


            if (parseFloat(this.formGroupPricing.get('newPolicyValue').value) > annualAmount) {
                this.invalidNewPolicyValueAnnual = true
            }

            if (parseFloat(this.formGroupPricing.get('newPolicyValue').value) < parseFloat(this.formGroupPricing.get('additionalVehicleToPolicyValue').value)) {
                this.invalidNewPolicyValueAdditional = true
            }

            if (parseFloat(this.formGroupPricing.get('additionalVehicleToPolicyValue').value) > annualAmount) {
                this.invalidAdditionalVehicleToPolicyValueAnnual = true
            }

            if (this.invalidMinimumValueAnnual || this.invalidMinimumValueNewPolicy || this.invalidMinimumValueAdditional || this.invalidNewPolicyValueAnnual || this.invalidNewPolicyValueAdditional || this.invalidAdditionalVehicleToPolicyValueAnnual) {
                return
            }

            let newPolicyValueExtension = 0
            if (ProductTypePipe.isThirdParty(this.insuranceProductManagerService.product)) {
                if (this.formGroupPricing.get('percentageOfAnnualAmount').value) {
                    newPolicyValueExtension = 100 - parseFloat(this.formGroupPricing.get('newPolicyValue').value)
                } else {
                    newPolicyValueExtension = (this.insuranceProductManagerService.product as ThirdPartyInsuranceProduct).annualAmount.amount - (parseFloat(this.formGroupPricing.get('newPolicyValue').value) * 100)
                    newPolicyValueExtension = newPolicyValueExtension / 100
                }
            } else if (ProductTypePipe.isComprehensive(this.insuranceProductManagerService.product)) {
                newPolicyValueExtension = (this.insuranceProductManagerService.product as ComprehensiveInsuranceProduct).annualPercentage - parseFloat(this.formGroupPricing.get('newPolicyValue').value)
            }

            this.formGroupExtension.get('newPolicyExtensionValue').setValue(newPolicyValueExtension.toFixed(2))

            if (this.showExtensionTab) {
                this.currentState = this.states.CAPTURE_EXTENSION
            } else {
                this.onSubmitExtension();
            }

        }

    }

    onSubmitExtension() {
        if (!this.showExtension || this.formGroupPricing.get('proRata').value || (this.showExtension && this.formGroupExtension.valid)) {

            this.insuranceProductManagerService.priceSchedule.durationType = this.formGroupPricing.get('durationType').value
            this.insuranceProductManagerService.priceSchedule.exactDuration.months = this.formGroupPricing.get('exactDurationMonths').value
            this.insuranceProductManagerService.priceSchedule.exactDuration.days = this.formGroupPricing.get('exactDurationDays').value
            this.insuranceProductManagerService.priceSchedule.intervalDurationFrom.months = this.formGroupPricing.get('intervalDurationFromMonths').value
            this.insuranceProductManagerService.priceSchedule.intervalDurationFrom.days = this.formGroupPricing.get('intervalDurationFromDays').value
            this.insuranceProductManagerService.priceSchedule.intervalDurationTo.months = this.formGroupPricing.get('intervalDurationToMonths').value
            this.insuranceProductManagerService.priceSchedule.intervalDurationTo.days = this.formGroupPricing.get('intervalDurationToDays').value
            this.insuranceProductManagerService.priceSchedule.proRata = this.formGroupPricing.get('proRata').value
            this.insuranceProductManagerService.priceSchedule.percentageOfAnnualAmount = this.formGroupPricing.get('percentageOfAnnualAmount').value
            if (this.productTypePipe.isThirdParty(this.insuranceProductManagerService.product) || this.showProRata) {
                this.insuranceProductManagerService.priceSchedule.minimumPolicyValue = parseFloat(this.formGroupPricing.get('minimumPolicyValue').value) * 100
            } else {
                this.insuranceProductManagerService.priceSchedule.minimumPolicyValue = 0;
            }

            if (this.showPercentage) {
                this.insuranceProductManagerService.priceSchedule.newPolicyValue = parseFloat(this.formGroupPricing.get('newPolicyValue').value)
                if (this.formGroupPricing.get('additionalVehicleToPolicyValue').value) {
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue = parseFloat(this.formGroupPricing.get('additionalVehicleToPolicyValue').value)
                } else {
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue = 0
                }
            } else {
                this.insuranceProductManagerService.priceSchedule.newPolicyValue = parseFloat(this.formGroupPricing.get('newPolicyValue').value) * 100
                if (this.formGroupPricing.get('additionalVehicleToPolicyValue').value) {
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue = parseFloat(this.formGroupPricing.get('additionalVehicleToPolicyValue').value) * 100
                } else {
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyValue = 0
                }
            }

            this.insuranceProductManagerService.priceSchedule.extension = this.formGroupExtension.get('extension').value
            this.insuranceProductManagerService.priceSchedule.gracePeriod.days = this.formGroupExtension.get('gracePeriodDays').value
            this.insuranceProductManagerService.priceSchedule.lengthOfExtension.months = this.formGroupExtension.get('lengthOfExtensionMonths').value
            this.insuranceProductManagerService.priceSchedule.lengthOfExtension.days = this.formGroupExtension.get('lengthOfExtensionDays').value

            if (!this.showProRata || (this.insuranceProductManagerService.priceSchedule.durationType === DurationType.INTERVAL && this.showProRata)) {
                if (this.showPercentage) {
                    this.insuranceProductManagerService.priceSchedule.extensionValue = parseFloat(this.formGroupExtension.get('newPolicyExtensionValue').value)
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyExtensionValue = parseFloat(this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').value)
                } else {
                    this.insuranceProductManagerService.priceSchedule.extensionValue = parseFloat(this.formGroupExtension.get('newPolicyExtensionValue').value) * 100
                    this.insuranceProductManagerService.priceSchedule.additionalVehicleToPolicyExtensionValue = parseFloat(this.formGroupExtension.get('additionalVehicleToPolicyExtensionValue').value) * 100
                }
            } else {
                this.insuranceProductManagerService.priceSchedule.extensionValue = null
            }

            let product = this.insuranceProductManagerService.product;
            if (this.insuranceProductManagerService.editingIndex >= 0) {
                if (product['@class'] === 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsuranceProduct') {
                    product = product as YCInsuranceProduct;
                    product.ycPriceSchedules[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.ycPriceSchedule
                } else {
                    product = product as ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct;
                    product.priceSchedules[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.priceSchedule
                }
            } else {
                if (product['@class'] === 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsuranceProduct') {
                    product = product as YCInsuranceProduct;
                    product.ycPriceSchedules.push(this.insuranceProductManagerService.ycPriceSchedule)
                } else {
                    product = product as ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct;
                    product.priceSchedules.push(this.insuranceProductManagerService.priceSchedule)
                }
            }
            this.activatedRoute.params.subscribe((data) => {
                const productNumber = data['number'];
                this.router.navigate([`retail/insurance/product-overview/${productNumber}`])
            });
        }
    }

    onBack() {
        if (this.currentState === this.states.CAPTURE_EXTENSION) {
            this.currentState = this.states.CAPTURE_PRICING
            this.checkFinalMonth();
        }
        else {
            this.location.back();
        }
    }

    onNext() {
        if (this.currentState === this.states.CAPTURE_PRICING) {
            document.getElementById('form-submit-pricing').click();
        } else {
            document.getElementById('form-submit-extension').click();
        }
    }

    onStep(step: any): void {
        this.currentStep = step.id
        window.scrollTo(0, 0)
    }

    toast(response) {

        this.translate.get('TOAST.CREATE_PRICING_SCHEDULE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.insuranceProductManagerService.product.name }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            })
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.currentStep = 0
                        this.activeToast.portal.destroy()
                        window.scrollTo(0, 0)
                        break
                    case ToastComponent.SECONDARY:
                        this.router.navigate(['/retail/insurance/insurance-product-manager/product-overview/1'])
                        this.activeToast.portal.destroy()
                        break
                }
            })
            this.activeToast.onHidden.subscribe(() => {
                this.router.navigate(['/retail/insurance/insurance-product-manager/product-overview/1'])
            })
        })

    }

    onDeactivate(): boolean {
        return true
    }

    onRemoveUserIncrementError(event) {
        this.invalidMinimumValueAnnual = false
        this.invalidMinimumValueNewPolicy = false
        this.invalidMinimumValueAdditional = false
        this.invalidNewPolicyValueAnnual = false
        this.invalidNewPolicyValueAdditional = false
        this.invalidAdditionalVehicleToPolicyValueAnnual = false
    }

    sortDisplayList() {
        this.durationTypes.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    checkFinalMonth(): void {
        if (this.formGroupPricing.get('exactDurationMonths').value === 12) {
            this.formGroupPricing.get('exactDurationDays').setValue(0);
            this.formGroupPricing.get('exactDurationDays').disable();
            this.disableExactSelect = true;
        } else {
            this.formGroupPricing.get('exactDurationDays').enable();
            this.disableExactSelect = false;
        }
        if (this.formGroupPricing.get('intervalDurationToMonths').value === 12) {
            this.formGroupPricing.get('intervalDurationToDays').setValue(0);
            this.formGroupPricing.get('intervalDurationToDays').disable();
            this.disableIntervalToSelect = true;
        } else {
            this.formGroupPricing.get('intervalDurationToDays').enable();
            this.disableIntervalToSelect = false;
        }
    }
}
