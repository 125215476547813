import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    Broker,
    InsurancePolicy,
    JudicialPerson,
    LegalEntity,
    NaturalPerson,
    Order,
    OrderStatus,
    PdfDocument,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    RenderedDocumentType,
    RenderedDocumentTypeIdCriteria,
    Underwriter,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import {
    RenderedConceptualDocumentProviderService,
} from '../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service';
import { FormComponent } from '../form/form.component';
import { PrintService, PDFDisplayModel } from '../../../app.services/common/printing/print.service';

declare const jQuery: any;
@Component({
    selector: 'app-document-render',
    templateUrl: './document-render.component.html',
    styleUrls: ['./document-render.component.scss']
})
export class DocumentRenderComponent extends FormComponent implements OnInit {

    @Input() order: Order;
    @Input() presentToCashier = true;
    @Output() numOfPages = new EventEmitter<number>();
    legalEntity: LegalEntity | NaturalPerson | JudicialPerson;
    previewDocument = false;
    documentToPreview: any;
    numberOfPages;

    env = environment;

    orderStatus = OrderStatus;
    underwriters: {[id: string]: Underwriter} = {};
    brokers: {[id: string]: Broker} = {};

    insurancePolicyMap: {[id: string]: InsurancePolicy[]} = {};

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private unMarshallerService: UnMarshallerService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private printService: PrintService)
    {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.onPreview();
    }

    getRenderedDocument(callback): void {
        const renderedDocumentTypeIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentTypeIdCriteria.documentId = this.order.number;
        renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.ORDER];

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentTypeIdCriteria;
        this.startLoad();
        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback((provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument));
            } else {
                callback({});
            }
        });

    }

    onPreview(): void {

        this.getRenderedDocument((pdfDocument) => {
            if (pdfDocument.type === RenderedDocumentType.ORDER) {
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, () => {
                    this.stopLoad();
                });

            });
        });
    }

    displayPDF(base64, complete) {
        const canvasId = `#print-section`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numOfPages.emit(numPages);
        });
    }

}
