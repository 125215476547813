import {LegalEntityIdentifier} from '../../entity/identification/models/legal-entity-identifier.model';
import {CountryIdentifier} from '../../location/geographic/identification/models/country-identifier.model';
import {BrokerIdentifier} from '../insurance/identification/models/broker-identifier.model';

export class Agent {

    agentId: string;
    name: string;
    legalEntityIdentifier: LegalEntityIdentifier;
    countryIdentifier: CountryIdentifier;
    brokerIdentifiers: BrokerIdentifier[] = [];
}
