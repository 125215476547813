import { Response } from "../../../../../../../urdad/services/models/response.model";
import { Image } from "../../../../../../../media/media_api";

export class ProvideUnderwriterWhiteLabelInformationResponse extends Response{
    constructor(){
        super();
    }

    public name : string;
    public redirectUrl : string;
    public hashId : string;
    public logo : Image;
}