import {Response} from "../../../../../../../urdad/services/models/response.model";
import { Model } from "../../../../../models/model.model";

export class ProvideVehicleModelsResponse extends Response{
    constructor(){
        super();
    }

    public models: Model[] =[];
}
