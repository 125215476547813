import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    FinaliseCreateVehicleMakeRequest,
    FinaliseCreateVehicleModelRequest,
    FinaliseUpdateVehicleMakeRequest,
    FinaliseUpdateVehicleModelRequest
} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class MakeModelTrimFinalisationManagerService {

    basePath = '/vehicle/makemodeltrim/makemodeltrimfinalisationmanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCreateVehicleMake(request: FinaliseCreateVehicleMakeRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreatevehiclemake';
        return this.http.post(path, request, this.options);
    }

    finaliseCreateVehicleModel(request: FinaliseCreateVehicleModelRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreatevehiclemodel';
        return this.http.post(path, request, this.options);
    }

    finaliseUpdateVehicleMake(request: FinaliseUpdateVehicleMakeRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdatevehiclemake';
        return this.http.post(path, request, this.options);
    }

    finaliseupdatevehiclemodel(request: FinaliseUpdateVehicleModelRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdatevehiclemodel';
        return this.http.post(path, request, this.options);
    }
}
