import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ProvideScannedDocumentRequest,
    ProvideScannedDocumentResponse,
    ScannedDocumentCriteria,
    ScannedDocumentIdentifier,
    ScannedDocumentIdentifierIdentifier,
    ScannedDocumentTypeDescriptionIdentifier,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import {
    ScannedDocumentProviderService,
} from '../../../../../http.services/media/scanneddocument/scanned-document-provider/scanned-document-provider.service';
import { FormComponent } from '../../../../shared/form/form.component';
import { PrintService, PDFDisplayModel } from '../../../../../app.services/common/printing/print.service';
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;
@Component({
  selector: 'app-attached-document-dialog',
  templateUrl: './attached-document-dialog.component.html',
  styleUrls: ['./attached-document-dialog.component.scss']
})
export class AttachedDocumentDialogComponent extends FormComponent implements OnInit {

  scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
  numberOfPages: number;
  documentIdentifier;

  constructor(public dialogRef: MatDialogRef<AttachedDocumentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) result,
              private scannedDocumentProviderService: ScannedDocumentProviderService,
              private unMarshallerService: UnMarshallerService,
              private translate: TranslateService,
              private translateParser: TranslateParser,
              private errorToastService: ErrorToastService,
              private printService: PrintService)
  {
    super(translate, translateParser);
    this.scannedDocumentIdentifiers = result;
  }

  ngOnInit() {
    var scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier();
    scannedDocumentIdentifierIdentifier.documentIdentifier =
    (this.scannedDocumentIdentifiers[0] as ScannedDocumentTypeDescriptionIdentifier).documentIdentifier;
    this.getScannedDocument(scannedDocumentIdentifierIdentifier);
  }

  getScannedDocument(identifier: ScannedDocumentIdentifierIdentifier): void {

    var scannedDocumentCriteria = new ScannedDocumentCriteria();
    scannedDocumentCriteria.scannedDocumentIdentifier = identifier;
    var provideScannedDocumentRequest = new ProvideScannedDocumentRequest();
    provideScannedDocumentRequest.criteria = scannedDocumentCriteria;
    this.startLoad();
    this.scannedDocumentProviderService.provideScannedDocument(provideScannedDocumentRequest).toPromise().then((data) => {
      const response: ProvideScannedDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideScannedDocumentResponse);
      this.documentIdentifier = response.scannedDocuments[0].documentIdentifier;
      this.displayPDF(response.scannedDocuments[0].document, () => {});

    }).catch((error) =>{
        this.errorToastService.success('Scanned document not found.', 'Server Error')
        this.stopLoad();
    });
  }

    displayPDF(base64, complete) {
        const canvasId = '#canvas-' + this.documentIdentifier;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numberOfPages = numPages;
            this.stopLoad();
        });
    }

  onSwitchTap(selectedTab): void{
    var scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier();
    scannedDocumentIdentifierIdentifier.documentIdentifier =
    (this.scannedDocumentIdentifiers[selectedTab.index] as ScannedDocumentTypeDescriptionIdentifier).documentIdentifier;

    this.getScannedDocument(scannedDocumentIdentifierIdentifier);
  }

  close(): void {
    this.dialogRef.close();
  }

}
