import { Criteria } from "../../../criteria/models/criteria.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";

export class InsurancePolicyVehicleCriteria extends Criteria {

    public plateNumber: string;
    public engineNumber: string;
    public vehicleIdentificationNumber: string;
    public tpiVehicleIdentifier: string;
    public plateNumberCountryOfIssue: CountryIdentifier;
    public date: Date;

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyVehicleCriteria";
    }
}
