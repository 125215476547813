export class ProposalRecordSummary {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalRecordSummary";
    }

    public status: string;
    public statusDate: Date;
    public capturedDate: Date;
    public proposalId: string;

}