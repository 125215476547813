import {OrderItem} from "../../../checkout/models/order-item.model"

export abstract class ComprehensiveInsuranceOrderItem extends OrderItem{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsuranceOrderItem";
    }

}
