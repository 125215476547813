import {Request} from '../../../../../../urdad/services/models/request.model';

export class CloseSessionTrackingRequest extends Request {
    constructor(){
        super();
    }

    public accountNumber: string;
    public sessionTrackingId: string;
    public endDate: Date;
}