import { PriceSchedule } from '../../insurance/models/price-schedule.model';
import { MotorInsuranceLimitDetails } from './motor-insurance-limit-details.model';
import { InsuranceType } from '../../insurance/setup/models/insurance-type.model';
import { OrderProduct } from "./order-product.model";
import { BrokerIdentifier } from "../../insurance/identification/models/broker-identifier.model";
import { Excess } from "../../insurance/models/excess.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";
import { UnderwriterIdentifier } from "../../insurance/identification/models/underwriter-identifier.model";
import { Money } from "../../../financial/models/money.model";

export class OrderThirdPartyInsuranceProduct extends OrderProduct {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderThirdPartyInsuranceProduct";
    }

    public insuranceVehicleCategoryDescription: string;
    public brokerIdentifier: BrokerIdentifier;
    public conditions: string;
    public excess: Excess[];
    public coveredCountries: CountryIdentifier[];
    public underwriterIdentifier: UnderwriterIdentifier;
    public price: Money;
    public insuranceVehicleCategorySubDescription: string;
    public insuranceTypeDescription: string
    public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[];
    public priceSchedule: PriceSchedule;
}
