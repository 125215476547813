import { AccountIdentifier } from "./account-identifier.model";

export class AccountUsernameIdentifier extends AccountIdentifier{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.account.identification.AccountNumberIdentifier";
    }

    public accountNumber:string;
    public username:string;
}