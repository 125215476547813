import {Request} from "../../../../../../../../urdad/services/models/request.model";
import {Criteria} from "../../../../../../../../criteria/models/criteria.model";

export class ProvideServiceIdentifiersRequest extends Request {
    constructor() {
        super();
    }

    public criteria: Criteria;
}
