import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
  InitialiseCreateVerifiedAccountRequest,
  MarshallerService,
  NaturalPersonIdentifier,
  UnMarshallerService,
  WrapperInitialiseResponse
} from '@magnabc/tpi'
import {TokenManagerService} from '../../../../app.services/managers/token-manager/token-manager.service'
import {VerifiedAccountManagerService} from '../verified-account-manager/verified-account-manager.service'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'

@Injectable({
    providedIn: 'root'
})
export class VerifiedAccountManagerWrapperService {

    constructor(private httpClient: HttpClient,
                private marshallerService: MarshallerService,
                private unMarshallerService: UnMarshallerService,
                private tokenManagerService: TokenManagerService,
                private createVerifiedAccountService: VerifiedAccountManagerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseCreateVerifiedAccount(initialiseCreateVerifiedAccountRequest: InitialiseCreateVerifiedAccountRequest): Promise<WrapperInitialiseResponse> {

        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/verifiedaccountmanagerwrapper/initialisecreateverifiedaccount';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseCreateVerifiedAccountRequest);

        return new Promise<WrapperInitialiseResponse>((resolve, reject) => {
            return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise().then(httpResponse => {
                if (httpResponse && httpResponse.body){
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, WrapperInitialiseResponse) as WrapperInitialiseResponse);

                    if (response.tempToken) {
                        this.tokenManagerService.setTempToken(response.tempToken.token);
                    }

                    resolve(response);
                    return;
                }
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                reject(error);
                return;
            });
        });
    }

    issueOneTimePin(nationalIdentityNumber: NaturalPersonIdentifier): Promise<boolean> {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/verifiedaccountmanagerwrapper/resendinternalonetimepin';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(nationalIdentityNumber);

        return new Promise<boolean>((resolve, reject) => {
            return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise().then(httpResponse => {
                if (httpResponse && httpResponse.body){
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, WrapperInitialiseResponse) as WrapperInitialiseResponse);

                    if (response.oneTimePinIdentifier) {
                        this.createVerifiedAccountService.setOneTimePinIdentifier(response.oneTimePinIdentifier);
                        resolve(true);
                        return;
                    }

                    resolve(false);
                    return;
                }
            }).catch(err => {
                console.error('Issue OTP error', err);
                RaygunErrorHandler.sendError(err);

                resolve(false);
                return;
            });
        });
    }
}
