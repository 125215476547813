import { Money } from '../../../financial/models/money.model';
import { ScannedDocument } from '../../../media/scanneddocument/models/scanned-document.model';
import {Item} from '../../checkout/models/item.model';
import { SnapshotVehicle } from '../../checkout/models/snapshot-vehicle.model';
import { Agent } from '../../models/agent.model';
import { InsuranceCategory } from './insurance-category.model';
import { UpdateInsuranceProductCartItem } from './update-insurance-product-cart-item.model';

export class InsurancePolicyAmendmentItem extends Item {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyAmendmentItem";
    }

    public alarmImmobiliser: boolean;
    public price: Money;
    public startDate: Date;
    public expiryDate: Date;
    public policyNumber: string;
    public category: InsuranceCategory;
    public vehicleValue: Money;
    public sellingAgent: Agent;
    public vehicleImages: ScannedDocument[] = [];
    public snapshotVehicle: SnapshotVehicle;
    public updateInsuranceProductCartItem: UpdateInsuranceProductCartItem;
}