import {Response} from '../../../../../../urdad/services/models/response.model';
import {Item} from "../../../../models/item.model";
import {Order} from "../../../../models/order.model";
import {InsurancePolicy} from "../../../../../insurance/models/insurance-policy.model";
import {Proposal} from '../../../../../insurance/comprehensiveinsurance/models/proposal.model';

export class ValidateCoveragePeriodResponse extends Response {
    constructor(){
        super();
    }

    public overlappingInsuranceItem: Item[] = [];
    public overlappingOrder: Order[] = [];
    public valid: Item[];
    public overlappingPolicy: InsurancePolicy[] = [];
    public overlappingProposal: Proposal[] = [];
}
