import { RetailProfileIdentifier } from "./retail-profile-identifier.model";
import { TpiIdentifier } from "../../../entity/identification/models/tpi-identifier.model";

export class RetailProfileOwnerIdentifier extends RetailProfileIdentifier{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.profile.identification.RetailProfileOwnerIdentifier";
    }

    public tpiIdentifier: TpiIdentifier;
}