import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";
import {Product} from "../../../products/models/product.model";
import {Excess} from "../../models/excess.model";
import {BrokerIdentifier} from "../../identification/models/broker-identifier.model";
import {UnderwriterIdentifier} from "../../identification/models/underwriter-identifier.model";
import {InsuranceVehicleCategory} from "../../setup/models/insurance-vehicle-category.model";
import {Limit} from "../../models/limit.model";
import { PriceSchedule } from "../../models/price-schedule.model";
import { AgentIdentifier } from "../../../../entity/identification/models/agent-identifier.model";

export class ComprehensiveInsuranceProduct extends Product {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsuranceProduct";
    }

    public brokerIdentifier: BrokerIdentifier;
    public conditions: string;
    public excess: Excess[] = [];
    public limit: Limit[] =  [];
    public coveredCountries: CountryIdentifier[] = [];
    public underwriterIdentifier: UnderwriterIdentifier;
    public insuranceVehicleCategory: InsuranceVehicleCategory;
    public priceSchedules: PriceSchedule[] = [];
    public agentIdentifiers: AgentIdentifier [] = [];
    public annualPercentage: number;
}
