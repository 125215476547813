import { JudicialPersonIdentifier } from "../../models/judicial-person-identifier.model";

export class TaxNumber extends JudicialPersonIdentifier
{

  constructor(){
     super();
     this['@class'] = "za.co.magnabc.tpi.entity.identification.judicialperson.TaxNumber";
  }

  public taxNumber: string;

}
