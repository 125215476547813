import {AbstractControl, ValidatorFn} from "@angular/forms";

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if ((/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/).test(control.value)) {
            return null;
        }
        return {'password': {value: control.value}};
    };
}
