import { BankAccount } from '../../../../../models/bank-account.model';
import { Money } from '../../../../../../../financial/financial_api';
import { Response } from '../../../../../../../urdad/services/models/response.model';

export class RetrieveBankTransferInformationResponse extends Response{
    constructor(){
        super();
    }

    public totalDue: Money;
    public orderNumber: string;
    public bankAccounts: BankAccount[] = [];
}