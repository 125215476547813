import { Response } from "../../../../../../urdad/urdad_api";
import { Vehicle } from "../../../../../../vehicle/vehicle_api";

export class AddYCToCartResponse extends Response {
    constructor(){
        super();
    }

    verifiedVehicles: Vehicle[] = [];
    unverifiedVehicleMessage: string[] = [];
}