import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {MotorInsurancePolicy, MotorInsurancePolicyItem, PublicInsurancePolicySummary} from "@magnabc/tpi"
import {ActiveToast} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import * as moment from 'moment/moment'
import {FormComponent} from '../../../../shared/form/form.component'
import {environment} from '../../../../../../environments/environment'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-policy-view',
  templateUrl: './policy-view.component.html',
  styleUrls: ['./policy-view.component.scss']
})
export class PolicyViewComponent extends FormComponent implements OnInit {

    @Input() policies: MotorInsurancePolicy[] | PublicInsurancePolicySummary[];
    @Input() queryBy: number;
    @Input() isPolicyView: boolean;
    @Input() printButtonVisible: boolean;
    @Input() isSummary = false;
    @Output() onClose = new EventEmitter<void>()
    @Output() onAction = new EventEmitter<any>()
    @Input() isPublicUserView: boolean;

    websiteTitle: string
    active: any;
    previewDocument = false;
    documentToPreview: any;
    numberOfPages:number;
    activeToast: ActiveToast<any>;
    showButtons: boolean;
    summaryExtensionMap: {[id: string]: PublicInsurancePolicySummary} = {}
    newExtensions: any[] = [];

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
        this.websiteTitle = runtimeConfigurationService.title

    }

    onActive(active): void {
        this.active = active;
        if (this.policyHasFullDetails(active)) {
            this.showButtons = true;
        } else {
            this.showButtons = false;
        }
    }

    ngOnInit(): void {
        if (this.isSummary) {
            const policies = []
            let newPolicy = {}
            for (let policy of this.policies) {
                if ((policy as PublicInsurancePolicySummary).insurancePolicyItemType === 'EXTENSION') {
                    newPolicy = policy;
                    this.newExtensions.push(newPolicy)
                } else {
                    policies.push(policy)
                }
            }
            this.checkIfMoreThanOneExtensions(newPolicy);
            this.policies = policies
        }
        this.onActive(this.policies[0]);
    }

    /**
     * Checks if more than one extensions available
     * @param newPolicy
     */
    checkIfMoreThanOneExtensions(newPolicy) {
        // If the policy has more than one extension
        // One still active and the rest as cancelled
        if ((newPolicy as PublicInsurancePolicySummary).insurancePolicyItemType === 'EXTENSION') {
            if (this.newExtensions.length !== 1) {
                this.newExtensions.forEach(element => {
                    if (!element.cancelled && moment(element.effectiveDate).isAfter(new Date)) {
                    newPolicy = element
                    } else if (element.cancelled) {
                    // If there is more than 1 cancelled extensions
                    // Take the latest one
                    newPolicy = this.newExtensions.reduce((a, b) => a.effectiveDate > b.effectiveDate ? a : b);
                    }
                });
            }
            this.summaryExtensionMap[(newPolicy as any).plateNumber] = newPolicy as any
        }
    }

    close(): void {
        this.onClose.emit();
    }

    getDate(date): string {
        return moment(date).format("DD MMM YYYY");
    }

    policyHasFullDetails(insurancePolicy): boolean {
        if (insurancePolicy.insurancePolicyItems) {
            for (const insurancePolicyItem of insurancePolicy.insurancePolicyItems) {
                const motorInsurancePolicyItem = (insurancePolicyItem as MotorInsurancePolicyItem)

                if (motorInsurancePolicyItem.conditions == null
                    && (motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.colour == null)
                    && motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.engineNumber == null
                    && (motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.make == null)
                    && (motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.model == null)
                    && motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber == null) {
                    return false;
                }
            }
        }
        return true;
    }

}
