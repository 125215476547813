import {Component, Input, OnInit} from '@angular/core';
import {StringUtils} from "../../../../../common/utils/utils.string";
import {environment} from "../../../../../../environments/environment";
import {ToastComponent} from "../../../../shared/toast/toast.component";
import {
    Broker,
    BrokerLegalEntityCriteria, PlateNumber,
    ProvideBrokerInformationRequest, ProvideBrokerInformationResponse,
    RetailProfile,
    RetailVehicle,
    ThirdPartyInsuranceProduct,
    Underwriter, UnMarshallerService,
    FinaliseUpdateRetailProfileRequest,
    Vehicle, VehiclePlateNumber,
    BrokerLegalEntityIdentifier
} from "@magnabc/tpi";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {RetailProfileFinalisationManagerService} from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {FormComponent} from "../../../../shared/form/form.component";

import {ServiceList} from "../../../../../common/model/service-list.model";
import {ServiceGroupFinalisationManagerService} from "../../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service";
import {Utils} from "../../../../shared/Utils/Utils";
import { BrokerInformationProviderService } from '../../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service';
import { UnderwriterInformationProviderService } from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import { ErrorToastService } from '../../../../../app.services/common/error-toast/error-toast.service';
@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent extends FormComponent implements OnInit {

    @Input() product: any;
    @Input() vehicles: RetailVehicle[];
    @Input() retailProfile: RetailProfile;

    underwriter: Underwriter;
    broker: Broker;
    activeToast: ActiveToast<any>;
    thirdPartyInsuranceTypeIdentifier: string;
    thirdPartyInsuranceVehicleCategoryIdentifier: string;


    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                public profileRetailService: RetailProfileFinalisationManagerService,
                private toastr: ErrorToastService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private authenticationService: ServiceGroupFinalisationManagerService,
                private errorToastService: ErrorToastService) {
        super(translate, translateParser);
    }

    ngOnInit() {

        this.underwriterInformationProviderService.getUnderwriter(this.product.underwriterIdentifier, (underwriter) => {
            this.underwriter = underwriter;
        });

        const provideBrokerRequest = new ProvideBrokerInformationRequest();
        const brokerLegalEntityCriteria = new BrokerLegalEntityCriteria();
        brokerLegalEntityCriteria.legalEntity = (this.product.brokerIdentifier as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier;
        provideBrokerRequest.criteria = brokerLegalEntityCriteria;

        this.brokerInformationProviderService.provideBroker(provideBrokerRequest).subscribe((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse;
            if (response.brokers.length > 0) {
                this.broker = response.brokers[0];
            }
        });

        if (this.product instanceof ThirdPartyInsuranceProduct) {
            this.thirdPartyInsuranceTypeIdentifier = this.product.insuranceType.description;
        }
        this.thirdPartyInsuranceVehicleCategoryIdentifier = this.product.insuranceVehicleCategory.description;

    }

    formatCurrency(value): string {
        return StringUtils.formatCents(value);
    }

    getLimitType(value): string { // FIXME use utility
        return value.description;
    }

    onRemoveVehicle(vehicle): boolean {

        this.translate.get('TOAST.REMOVE_VEHICLE_FROM_CART_CONFIRM').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        this.removeVehicle(vehicle);
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {

            });
        });

        return false;

    }

    removeVehicle(vehicle: Vehicle) {

        for (let j = 0; j < this.vehicles.length; j++) {
            if (Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers).number === Utils.getTpiVehicleIdentifier(this.vehicles[j].vehicleIdentifiers).number) {
                this.vehicles.splice(j, 1);
                break;
            }
        }

        this.updateRetailProfile();

    }

    updateRetailProfile(): void {

        const createRetailProfile = new FinaliseUpdateRetailProfileRequest();
        createRetailProfile.retailProfile = this.retailProfile;
        this.startLoad();
        this.profileRetailService.finaliseUpdateRetailProfile(createRetailProfile).subscribe(response => {
            this.stopLoad();
            this.toast(response);
        },(error) => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
        });

    }

    toast(response) {

        this.translate.get('TOAST.REMOVE_FROM_CART').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {

            });
        });
    }

    servicesRemoveFromCart(): boolean {
        return !!(this.authenticationService.services[ServiceList.REMOVEITEM] || this.authenticationService.services[ServiceList.ASSISTEDQUICKTRANSACT]);
    }

    public getPlateNumber(vehicle: RetailVehicle): string {
        let plateNo = '';
        vehicle.vehicleIdentifiers.forEach(
            identifier => {
                if (identifier instanceof VehiclePlateNumber) {
                    plateNo = identifier.number;
                } else if (identifier instanceof PlateNumber) {
                    plateNo = identifier.number;
                }
            }
        )
        return plateNo;
    }

}
