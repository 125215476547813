import {Injectable} from "@angular/core"
import {CanActivate, Router} from "@angular/router"
import {
  OneTimePinVerificationManagerProviderService
} from '../../../http.services/security/onetimepin/one-time-pin-verification-manager-provider/one-time-pin-verification-manager-provider.service'

@Injectable({
    providedIn: 'root'
})
export class UnAuthGuard implements CanActivate {

    constructor(private securityService: OneTimePinVerificationManagerProviderService, private router: Router) {}

    canActivate(): boolean {
        if (this.securityService.jwt) {
            this.router.navigate(['/retail/profile']);
            return false;
        }
        return true;
    }

}
