import { AccountStatementPeriod } from "./account-statement-period.model";
import { AccountStatementReportEntry } from "./account-statement-report-entry.model";
import { JournalEntryType } from "../../models/journal-entry-type.model";

export class AccountStatementReport
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.AccountStatementReport";
    }

    public accountHolder: string;
    public closingBalance: number;
    public closingBalanceType: JournalEntryType;
    public currency: string;
    public dateFrom: Date;
    public dateTo: Date;
    public openingBalance: number;
    public openingBalanceType: JournalEntryType;
    public period: AccountStatementPeriod;
    public accountStatementReportEntries: AccountStatementReportEntry[] = [];

}