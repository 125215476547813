import { Elaboration } from "./elaboration.model";

export class TextualElaboration extends Elaboration {

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.security.TextualElaboration";
  }

  public message: string;

}
