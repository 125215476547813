import {Component} from "@angular/core"
import {TranslateService} from "@ngx-translate/core"
import {ToastrService} from "ngx-toastr"
import {Router} from "@angular/router"
import {ServiceList} from "../../../../../common/model/service-list.model"
import {
    AuthenticationService
} from "../../../../../app.services/managers/authentication/authentication.service"
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

    constructor(private translate: TranslateService,
                private title: Title,
                private toastr: ToastrService,
                private router: Router,
                public authenticationService: AuthenticationService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Home Page - Console - ${runtimeConfigurationService.title}`);
    }

    servicesDashboard(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEPOPULARPRODUCTS]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDEROLEREVENUE]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDESALESSUMMARY]));
    }
}
