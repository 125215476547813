import { Criteria } from "../../criteria/criteria_api";

export class RenderedDocumentIdCriteria extends Criteria {

  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.rendering.RenderedDocumentIdCriteria";
  }

  public documentId: string;
  public userID: string;
}