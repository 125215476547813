import { ConceptualDocument } from "../../../conceptualdocument/models/conceptual-document.model";
import { AccountStatementReport } from "../../reporting/models/account-statement-report.model"

export class AccountStatementReportConceptualDocument extends ConceptualDocument {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.conceptualdocument.AccountStatementReportConceptualDocument";
    }

    public accountStatementReport : AccountStatementReport ;
}