import {Criteria} from '../../criteria/models/criteria.model';
import {BrokerIdentifier} from '../insurance/identification/models/broker-identifier.model';

export class AgentBrokerCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.AgentBrokerCriteria";
    }

    brokerIdentifier: BrokerIdentifier;
}
