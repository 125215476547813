import {Response} from '../../../../../../urdad/services/models/response.model';
import {JsonWebToken} from '../../../../../../security/token/json-web-token.model';

export class ValidateSecuredLegalEntityObscuredContactNumberResponse extends Response {
    constructor() {
        super();
    }

    valid: boolean;
    tempToken: JsonWebToken;
}
