import {Response} from "../../../../../../urdad/services/models/response.model";
import { Vehicle } from "../../../../../models/vehicle.model";

export class FinaliseMaintainVehicleInformationResponse extends Response {

    constructor() {
        super();
    }

    public vehicle : Vehicle;
}

