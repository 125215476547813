import {Criteria} from '../../../../criteria/models/criteria.model';
import { BrokerIdentifier } from '../../identification/models/broker-identifier.model';

export class InsuranceTypeBrokerCriteria extends Criteria {

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.InsuranceTypeBrokerCriteria";
    }

    public brokerIdentifier: BrokerIdentifier;
}
