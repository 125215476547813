import {Request} from '../../../../../../../urdad/services/models/request.model';
import {NaturalPerson} from '../../../../../../../entity/models/natural-person.model';

export class FinaliseCreateVerifiedAccountByUpdateRequest extends Request {
    constructor() {
        super();
    }

    passwordNew: string;
    naturalPerson: NaturalPerson;
}
