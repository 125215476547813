import {Response} from "../../../../../../../../urdad/services/models/response.model";
import { ProposalRecord } from "../../../../../models/proposal-record.model";
import { Proposal } from "../../../../../models/proposal.model";
import { CountryRegistrationConfigurationInformation } from "../../../../../../../insurance/setup/setup_api";

export class InitialiseProposalAssessmentResponse extends Response{
    constructor(){
        super();
    }

    public proposalRecord : ProposalRecord;
    public proposal : Proposal;
    public countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation[] = [];
}