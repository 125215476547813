import { AfterViewInit } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RetailProfileFinalisationManagerService } from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import { DocumentRenderComponent } from "../../../../view.components/shared/document-render/document-render.component";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { OrderQueryComponent } from "../../../../view.components/retail/insurance/third-party-insurance/order-query/order-query.component";
import { FormComponent } from "../../../../view.components/shared/form/form.component";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import {
    ContactNumber,
    FinaliseCashierPaymentRequest,
    LegalEntity,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    Money,
    Order,
    OrderNumberCriteria,
    OrderNumberIdentifier,
    OrderReferenceNumber,
    OrderStatus,
    Payment,
    PaymentPlan,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    ProvideOrderLegalEntityRequest,
    ProvideOrderSummaryRequest,
    ProvideOrderSummaryResponse,
    OrderSummaryLegalEntityCriteria,
    RegistrationNumber,
    PassportNumber,
    NationalIdentityNumber,
    TpiIdentifier,
    UnMarshallerService,
    OrderSummary
} from "@magnabc/tpi";
import { ServiceList } from "../../../../common/model/service-list.model";
import { IStepComponent } from "../../../../view.components/shared/guards/step-guard";
import { LocationStrategy } from "@angular/common";
import { Utils } from "../../../../view.components/shared/Utils/Utils";
import { AuthenticationService } from '../../../../app.services/managers/authentication/authentication.service';
import { Dropdown } from "../../../../common/model/dropdown.model";
import { SupervisorOverrideDialogComponent } from '../../../../view.components/security/supervisor-override-dialog/supervisor-override-dialog.component';
import { OrderLegalEntityProviderService } from '../../../../http.services/retail/insurance/order-legal-entity-provider/order-legal-entity-provider.service';
import { OrderInformationProviderService } from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';
import { CashierPaymentFinalisationManagerService } from '../../../../http.services/retail/payment/cashier-payment-finalisation-manager/cashier-payment-finalisation-manager.service';
import { ErrorToastService } from '../../../../app.services/common/error-toast/error-toast.service';
import { QueryNaturalPersonComponent } from '../../../../view.components/entity/query-natural-person/query-natural-person.component';
import { QueryJudicialPersonComponent } from '../../../../view.components/entity/query-judicial-person/query-judicial-person.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";
@Component({
    selector: 'app-insurance-policy-manager',
    templateUrl: './insurance-policy-manager-page.component.html',
    styleUrls: ['./insurance-policy-manager-page.component.scss']
})
export class InsurancePolicyManagerPageComponent extends FormComponent implements OnInit, IStepComponent, AfterViewInit {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;
    paymentPlans = [
        new Dropdown(PaymentPlan.NOW, 'Now'),
        new Dropdown(PaymentPlan.LATER, 'Later')
    ];

    notEqual = false;

    orderStatus = OrderStatus;
    order: Order;
    contactNumber: ContactNumber;
    totalElements = 0;
    pageNumber = 0;
    pageSize = 8;
    noResults = false;
    entityIdentifier: LegalEntityIdentifier;
    queryBy = 0;

    temp: OrderSummary[] = [];
    rows: OrderSummary[] = [];

    @ViewChild(OrderQueryComponent) orderQueryComponent: OrderQueryComponent;
    @ViewChild(DocumentRenderComponent) invoiceComponent: DocumentRenderComponent;
    @ViewChild(QueryNaturalPersonComponent) naturalPersonQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(QueryJudicialPersonComponent) queryJudicialComponent: QueryJudicialPersonComponent;
    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    legalEntity: LegalEntityIdentifier;
    payment: Payment;
    passport;

    formGroup: UntypedFormGroup;
    amount: UntypedFormControl;
    paymentPlan: UntypedFormControl;
    @ViewChild('form') form: FormGroupDirective;

    orderNumber: string;
    identifier: string;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private orderLegalEntityProviderService: OrderLegalEntityProviderService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                private router: Router,
                private unMarshallerService: UnMarshallerService,
                private cashierPaymentFinalisationManager: CashierPaymentFinalisationManagerService,
                private route: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private orderInformationProviderService: OrderInformationProviderService,
                private dialog: MatDialog,
                locationStrategy: LocationStrategy,
                private errorToastService: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Cash Payment - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ORDER.TITLE_ORDER_QUERY").subscribe((response) => {
                this.steps.push({ id: 0, name: response, icon: "fa-search", borderColor: "#1592E6" });
            });
            this.translate.get("ORDER.TITLE_INVOICE").subscribe((response) => {
                this.steps.push({ id: 1, name: response, icon: "fa-file", borderColor: "#4069b9" });
            });

        });

        this.amount = new UntypedFormControl('', [
            Validators.required,
            Validators.min(1),
            Validators.max(999999999999)
        ]);

        this.paymentPlan = new UntypedFormControl(this.getDropdownByCode(this.paymentPlans, PaymentPlan.NOW), [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            'amount': this.amount,
            'paymentPlan': this.paymentPlan
        });
    }

    ngAfterViewInit(): void {
        this.applyState();
    }

    private applyState(): void {
        let orderNum: string;
        let queryBy: number;
        let country: string;
        let identifierType: string;

        this.route.params.forEach((param: Params) => {
            if (param["number"]) {
                orderNum = param["number"];
            }

            if (param["country"]) {
                country = param["country"];
            }

            if (param["id"]) {
                this.identifier = param["id"];
            }

            if (param["idType"]) {
                identifierType = param["idType"];
            }

            if (param["queryBy"]) {
                queryBy = Number(param["queryBy"]);
            }
        });

        if (this.identifier && country && queryBy) {
            this.queryBy = queryBy;

            switch (identifierType) {
                case 'National':
                    this.setNationalIdentifier(this.identifier, country);
                    break;
                case 'Passport':
                    this.setPassportIdentifier(this.identifier, country);
                    break;
                case 'Business':
                    this.setBusinessIdentifier(this.identifier, country);
                    break;
            }
            setTimeout(() => {
                this.provideOrderSummary();
            }, 200)
        } else if (orderNum) {
            setTimeout(() => {
                this.orderQueryComponent.query(orderNum);
            }, 100);
        }
    }

    onPaymentPlanSelected(event) {

        if (event.value === PaymentPlan.LATER) {
            this.amount.setValue(this.order.totalDue.amount / 100);
            this.amount.disable();
            this.amount.clearValidators();
        } else {
            this.amount.setValue(null);
            this.amount.enable();
            this.amount.setValidators([
                Validators.required,
                Validators.min(1),
                Validators.max(999999999999)
            ]);
        }

    }

    onQueryType(type): boolean {
        this.noResults = false;
        this.queryBy = type;

        return false;
    }

    onCheckValidation(event): void {

        this.notEqual = false;
        if (Math.round(this.amount.value * 100) !== this.order.totalDue.amount) {
            this.notEqual = true;
            return;
        }

        if (this.paymentPlan.value.value === PaymentPlan.LATER) {
            this.dialog.open(SupervisorOverrideDialogComponent, {
                width: '500px',
                panelClass: 'padded-modal'
            }).afterClosed().subscribe((overrideResponse) => {
                if (overrideResponse) {
                    this.finalisePayment();
                }
            });
            return;
        }

        if (this.form.valid) {
            this.finalisePayment();
        }
    }

    finalisePayment() {

        const naturalPersonIdentifier = new LegalEntityDescriptionIdentifier();
        const legalEntityIdentifier = new LegalEntityDescriptionIdentifier();
        const legalEntityTpiIdentifier = new TpiIdentifier();
        const naturalPersonTpiIdentifier = new TpiIdentifier();

        naturalPersonTpiIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;

        legalEntityTpiIdentifier.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers()).tpiIdentifier;

        legalEntityIdentifier.name = Utils.getLegalEntityDescriptionIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers()).name;
        legalEntityIdentifier.tpiIdentifier = legalEntityTpiIdentifier;

        naturalPersonIdentifier.tpiIdentifier = naturalPersonTpiIdentifier;
        naturalPersonIdentifier.name = this.authenticationService.getLoggedInName();

        const paymentReference = new OrderReferenceNumber(this.order.number);
        const amount = new Money();
        amount.amount = Math.round(this.amount.value * 100);
        amount.currency = this.getCurrencyCode();
        delete amount["@class"];

        const finaliseCashierPaymentRequest = new FinaliseCashierPaymentRequest();
        finaliseCashierPaymentRequest.legalEntityIdentifier = legalEntityIdentifier
        finaliseCashierPaymentRequest.naturalPersonIdentifier = naturalPersonIdentifier;
        finaliseCashierPaymentRequest.paymentReference = paymentReference;
        finaliseCashierPaymentRequest.amount = amount;
        finaliseCashierPaymentRequest.paymentPlan = this.paymentPlan.value.value;

        this.startLoad();

        this.cashierPaymentFinalisationManager.finaliseCashierPayment(finaliseCashierPaymentRequest).then(response => {

            if (response) {
                window.scrollTo(0, 0);
                this.refreshOrder(this.order.number);
                // this.stopLoad();
            }
        }).catch(error => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
        });

    }

    onOrder(order): void {
        this.order = order;
        if (this.order && this.order.status !== OrderStatus.CANCELLED) {
            this.contactNumber = this.legalEntityFinalisationManagerService.legalEntity.contactNumbers[0];
            // this.getPayment();
            this.getLegalEntity();
            this.currentStep = 1;
            this.orderNumber = order.number;
            this.router.navigate([`agent/payment/${order.number}`]);
        }
    }

    getLegalEntity(): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(this.order.number));

        const provideOrderLegalEntityRequest = new ProvideOrderLegalEntityRequest();
        provideOrderLegalEntityRequest.criteria = orderReferenceNumberCriteria;

        this.orderLegalEntityProviderService.provideOrderLegalEntity(provideOrderLegalEntityRequest).subscribe((response) => {
            this.legalEntity = Utils.getNationalIdentityNumber(response.body.legalEntity.legalEntityIdentifiers);
            this.passport = Utils.getPassportNumberString(response.body.legalEntity.legalEntityIdentifiers);
        });

    }

    onOrderExists(exists): void {
        // if (exists) {
        // }
    }

    onStep(step: any): void {
        if (step.id === 0) {
            this.router.navigate([`agent/payment/`]);
        } else {
            this.currentStep = step.id;
            window.scrollTo(0, 0);
        }
    }

    onNext($event): void {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    onBack(event): void {
        if (this.currentStep === 1 || this.currentStep === 4) {
            this.currentStep = 0;
            setTimeout(() => {
                window.history.back();
            }, 100)
        } else if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        }
    }

    onSubmit(event): void {
        const step = this.currentStep;
        this.orderInformationProviderService.orderSummaries = undefined;
        this.orderInformationProviderService.totalNumberOfResults = 0;
        switch (step) {
            case 0:

                if (this.queryBy === 1) {
                    if (this.naturalPersonQueryComponent.type.value.name === "National Identity Number") {
                        const number = this.naturalPersonQueryComponent.identifier.value;
                        const countryOfIssue = this.naturalPersonQueryComponent.countryComponent.country.alphaTwo;
                        this.setNationalIdentifier(number, countryOfIssue);
                        this.provideOrderSummary().then((result) => {
                            if (result)
                                this.router.navigate([`agent/payment/${number}/${countryOfIssue}/${this.queryBy}/National`]);
                        });
                    } else {
                        const number = this.naturalPersonQueryComponent.identifier.value;
                        const countryOfIssue = this.naturalPersonQueryComponent.countryComponent.country.alphaTwo;
                        this.setPassportIdentifier(number, countryOfIssue);
                        this.provideOrderSummary().then((result) => {
                            if (result)
                                this.router.navigate([`agent/payment/${number}/${countryOfIssue}/${this.queryBy}/Passport`]);
                        });
                    }

                } else if (this.queryBy === 2) {
                    const number = this.queryJudicialComponent.identifier.value;
                    const countryOfIssue = this.queryJudicialComponent.countryComponent.country.alphaTwo;
                    this.setBusinessIdentifier(number, countryOfIssue);
                    this.provideOrderSummary().then((result) => {
                        if (result)
                            this.router.navigate([`agent/payment/${number}/${countryOfIssue}/${this.queryBy}/Business`]);
                    });

                } else {
                    this.orderQueryComponent.submit();
                }
                break;
            case 1:
                this.currentStep = 0;
                this.router.navigate(['/agent/payment']);
                break;
            case 4:
                this.currentStep = 0;
                this.router.navigate(['/agent/payment']);
                break;
        }
    }

    private setNationalIdentifier(number: string, countryOfIssue: string): void{
        const nationalIdentityNumber = new NationalIdentityNumber
        nationalIdentityNumber.number = number;
        nationalIdentityNumber.countryOfIssue = countryOfIssue;
        this.entityIdentifier = nationalIdentityNumber;
    }

    private setPassportIdentifier(number: string, countryOfIssue: string): void{
        const passportNumber = new PassportNumber();
        passportNumber.number = number;
        passportNumber.countryOfIssue = countryOfIssue;
        this.entityIdentifier = passportNumber;
    }

    private setBusinessIdentifier(number: string, countryOfIssue: string): void{
        const registrationNumber = new RegistrationNumber(number, countryOfIssue);
        this.entityIdentifier = registrationNumber;
    }

    provideOrderSummary(): Promise<boolean> {
        const orderSummaries = this.orderInformationProviderService.orderSummaries;
        const totalNumberOfResults = this.orderInformationProviderService.totalNumberOfResults;
        return new Promise((resolve, reject) => {
            if(orderSummaries && totalNumberOfResults){
                this.setOrderSummaryData(orderSummaries, totalNumberOfResults);
                resolve(true);
                return;
            }
            const orderSummaryLegalEntityCriteria = new OrderSummaryLegalEntityCriteria();
            orderSummaryLegalEntityCriteria.legalEntityIdentifier = this.entityIdentifier;
            orderSummaryLegalEntityCriteria.status = OrderStatus.PENDING_PAYMENT;

            const provideOrderSummaryRequest = new ProvideOrderSummaryRequest();
            provideOrderSummaryRequest.batchNumber = this.pageNumber + 1;
            provideOrderSummaryRequest.batchSize = this.pageSize;
            provideOrderSummaryRequest.criteria = orderSummaryLegalEntityCriteria;

            this.startLoad();
            this.orderInformationProviderService.provideOrderSummary(provideOrderSummaryRequest).then((data) => {
                this.stopLoad();
                const response: ProvideOrderSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderSummaryResponse);

                if (response.orderSummaries.length > 0) {
                    this.setOrderSummaryData(response.orderSummaries,  response.totalNumberOfResults);
                    resolve(true);
                } else {
                    this.noResults = true;
                    resolve(false);
                }
            }).catch((error) => {
                this.stopLoad();
                reject(error);
            });;
        })
    }

    private setOrderSummaryData(orderSummaries: OrderSummary[], totalNumberOfResults: number): void {
        this.orderInformationProviderService.orderSummaries = orderSummaries;
        this.orderInformationProviderService.totalNumberOfResults = totalNumberOfResults;
        this.rows = orderSummaries;
        this.temp = this.rows;
        this.rows = [...this.rows];
        this.totalElements = totalNumberOfResults;
        this.currentStep = 4;
    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideOrderSummary();
    }

    receivePayment(row) {
        const orderNumberIdentifier = new OrderNumberIdentifier(row.orderNumber);
        const orderNumberCriteria = new OrderNumberCriteria();
        orderNumberCriteria.orderIdentifiers.push(orderNumberIdentifier);

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderNumberCriteria;
        this.startLoad();
        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {
            this.stopLoad();
            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            for (const order of response.orders) {
                if (order.status === OrderStatus.PENDING_PAYMENT) {
                    this.order = order;
                    this.onOrder(order);
                }
            }

        }).catch((error) => {
            this.stopLoad();
        });
    }

    refreshOrder(reference: string): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(reference));

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderReferenceNumberCriteria;

        this.startLoad();
        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {

            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            if (response.orders[0].orderItems.length > 0) {

                this.stopLoad();
                this.order = response.orders[0];

                if (this.currentStep === 2 && this.order.status === "PAID") {
                    this.amount.disable();
                }

            } else {
                this.refreshOrder(reference);
            }

        });

    }

    serviceReceiveCashPayments(): boolean {
        return !!this.authenticationService.isAuthorised([ServiceList.RECEIVECASHIERPAYMENT]);
    }

    getCurrencyCode(): string {
        for (const orderItem of this.order.orderItems) {
            return orderItem.price.currency;
        }
        return null;
    }

}
