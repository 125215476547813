import { MatRadioModule } from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list'
import {CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule} from "@angular/core"

import {StepsComponent} from "./steps/steps.component"
import {BrowserModule} from "@angular/platform-browser"
import {TranslateModule} from "@ngx-translate/core"
import {FormComponent} from "./form/form.component"
import {LoadingModule} from "ngx-loading"
import {AddressComponent} from "./address/address.component"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContactNumberComponent} from "./contactnumber/contact_number.component"
import {EmailAddressComponent} from "./emailaddress/email_address.component"
import {MaskDateDirective} from "./directives/mask.date.directive"
import {MaskContactNumberDirective} from "./directives/mask.contact.directive"
import {ContactPersonComponent} from './contact-person/contact-person.component'
import {CountryContactNumberComponent} from "./country-code-number/country_contact_number.component"
import {CountryComponent} from "./country/country.component"
import {MaskDateYearDirective} from "./directives/mask.date.year.directive"
import {CanDeactivateGuard} from "./guards/can-deactivate.guard.component"
import {
  ClaimSpecificExcessComponent
} from "../retail/insurance/insurance-product-manager/product-excess/claim-specific-excess/claim-specific-excess.component"
import {FormatCreditCardDatePipe} from "./pipes/format-credit-card-date.pipe"
import {MaskCardNumberDirective} from "./directives/mask.card-number.directive"
import {MaskCvvDirective} from "./directives/mask.vcc.directive"
import {UppercaseDirective} from "./directives/uppercase.directive"
import {StepGuard} from "./guards/step-guard"
import {TextMaskModule} from "angular2-text-mask"
import {Base64ImagePipe} from "./pipes/base64-image.pipe"
import {FormatDatePipe} from "./pipes/format-date.pipe"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {ImageFilePipe} from "./pipes/image-file.pipe"
import {FormatPricePipe} from "./pipes/format-price.pipe"
import {FormatDateTimePipe} from './pipes/format-date-time.pipe'
import {DecimaNumberDirective} from "./directives/decimal.number.directive"
import {BackOfficeSideMenuComponent} from './back-office-side-menu/back-office-side-menu.component'
import {RouterModule} from '@angular/router'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {FooterComponent} from './footer/footer.component'
import {ToastComponent} from './toast/toast.component'
import {SessionTrackingModalComponent} from './session-tracking-modal/session-tracking-modal.component'
import {ProductTypePipe} from './pipes/product-type.pipe'
import {FormatSimplePricePipe} from './pipes/format-simple-price.pipe'
import {ProductExcessTypePipe} from './pipes/product-excess-type.pipe'
import {ProductPriceScheduleDurationPipe} from './pipes/product-price-schedule.pipe'
import {
  UniversalExcessComponent
} from "../retail/insurance/insurance-product-manager/product-excess/universal-excess/universal-excess.component"
import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button'
import {FormatAmountPipe} from './pipes/format-amount.pipe'
import {NoWhiteSpaceDirective} from './directives/no-white-space.directive'
import {DocumentRenderComponent} from './document-render/document-render.component'
import {CountrySelectorComponent} from './country-selector/country-selector.component'
import {ShortCountryNamePipe} from './pipes/short-country-name.pipe/short-country-name.pipe'
import {ShipmentCaptureComponent} from './shipment-capture/shipment-capture.component'
import {MatFormFieldModule} from '@angular/material/form-field'
import {
  AutocompleteShowOptionsDirective
} from './directives/autocomplete-show-options/autocomplete-show-options.directive'
import { MapComponent } from './map/map.component'
import { NgxEchartsModule } from 'ngx-echarts'

@NgModule({
    declarations: [
        StepsComponent,
        FormComponent,
        FooterComponent,
        AddressComponent,
        ContactNumberComponent,
        EmailAddressComponent,
        ContactPersonComponent,
        CountryContactNumberComponent,
        CountryComponent,
        MaskDateDirective,
        MaskDateYearDirective,
        MaskContactNumberDirective,
        UniversalExcessComponent,
        ClaimSpecificExcessComponent,
        FormatCreditCardDatePipe,
        Base64ImagePipe,
        FormatDatePipe,
        ProductTypePipe,
        FormatSimplePricePipe,
        ProductExcessTypePipe,
        ProductPriceScheduleDurationPipe,
        MaskCardNumberDirective,
        MaskCvvDirective,
        UppercaseDirective,
        ImageFilePipe,
        FormatPricePipe,
        FormatDateTimePipe,
        FormatAmountPipe,
        DecimaNumberDirective,
        BackOfficeSideMenuComponent,
        ToastComponent,
        SessionTrackingModalComponent,
        NoWhiteSpaceDirective,
        DocumentRenderComponent,
        CountrySelectorComponent,
        ShortCountryNamePipe,
        ShipmentCaptureComponent,
        AutocompleteShowOptionsDirective,
        MapComponent
    ],
    imports: [
        BrowserModule,
        TranslateModule,
        LoadingModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        TextMaskModule,
        RouterModule,
        NgxDatatableModule,
        MatDialogModule,
        MatButtonModule,
        MatGridListModule,
        MatFormFieldModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
    ],
    exports: [
        StepsComponent,
        FormComponent,
        AddressComponent,
        FooterComponent,
        ContactNumberComponent,
        EmailAddressComponent,
        ContactPersonComponent,
        CountryComponent,
        UniversalExcessComponent,
        ClaimSpecificExcessComponent,
        MaskDateDirective,
        MaskDateYearDirective,
        MaskContactNumberDirective,
        FormatCreditCardDatePipe,
        Base64ImagePipe,
        FormatDatePipe,
        ProductTypePipe,
        FormatSimplePricePipe,
        ProductExcessTypePipe,
        ProductPriceScheduleDurationPipe,
        MaskCardNumberDirective,
        MaskCvvDirective,
        UppercaseDirective,
        CountryContactNumberComponent,
        ImageFilePipe,
        FormatPricePipe,
        FormatDateTimePipe,
        FormatAmountPipe,
        DecimaNumberDirective,
        BackOfficeSideMenuComponent,
        ToastComponent,
        SessionTrackingModalComponent,
        NoWhiteSpaceDirective,
        DocumentRenderComponent,
        CountrySelectorComponent,
        ShortCountryNamePipe,
        ShipmentCaptureComponent,
        AutocompleteShowOptionsDirective,
        MapComponent
    ],
    providers: [CanDeactivateGuard, StepGuard]
})
export class SharedModule { }
