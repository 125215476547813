import { Component, OnInit } from '@angular/core';
import { Money, ProvideSalesSummaryRequest, ProvideSalesSummaryResponse, SalesSummary, SalesSummaryLegalEntityCriteria,
          TimeFrame, UnMarshallerService } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../../app.services/managers/authentication/authentication.service';
import { DashboardProviderService } from '../../../../../http.services/ledger/reporting/dashboard-provider/dashboard-provider.service';
import {FormComponent} from "../../../../shared/form/form.component";
import {Utils} from "../../../../shared/Utils/Utils";
import {StringUtils} from "../../../../../common/utils/utils.string";
import {environment} from "../../../../../../environments/environment";
import { ErrorToastService } from '../../../../../app.services/common/error-toast/error-toast.service';
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss']
})
export class SalesSummaryComponent extends FormComponent implements OnInit {

  saleSummary : SalesSummary;
  balance : Money;
  balanceType;
  title: string;

  constructor(  private translate: TranslateService,
                private translateParser: TranslateParser,
                private dashboardProviderService: DashboardProviderService,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private errorToastService: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
  }

  ngOnInit() {
    Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
    this.title = this.runtimeConfigurationService.title;
  }

  getDropDownValue(timeFrame: string): void {
    this.getSaleSummary(timeFrame);
  }

  getSaleSummary(timeframe: string): void {
    this.saleSummary = null;
    const salesSummaryLegalEntityCriteria = new SalesSummaryLegalEntityCriteria();
    salesSummaryLegalEntityCriteria.dashboardPeriod = this.getTimeFramePeriod(timeframe);
    salesSummaryLegalEntityCriteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

    const provideSalesSummaryRequest = new ProvideSalesSummaryRequest();
    provideSalesSummaryRequest.criteria = salesSummaryLegalEntityCriteria;
    this.startLoad();
    this.dashboardProviderService.provideSalesSummary(provideSalesSummaryRequest).then((httpResponse) => {
      this.stopLoad();
      if (httpResponse && httpResponse.body) {
          const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSalesSummaryResponse) as ProvideSalesSummaryResponse);
          if(response.salesSummary){
            this.saleSummary = response.salesSummary;
          }
       }
    }).catch((error) => {
        this.errorToastService.errorToast(error);
        this.stopLoad();
    });
  }

  getTimeFramePeriod(preferredPeriod: string): TimeFrame{
    if(preferredPeriod === "Today"){
        return TimeFrame.TODAY;

    }
    else if(preferredPeriod === "Yesterday"){
        return TimeFrame.YESTERDAY;

    }
    else if(preferredPeriod === "Last 7 Days"){
      return TimeFrame.LAST7DAYS;

    }
    else if(preferredPeriod === "Current Month"){
      return TimeFrame.CURRENTMONTH;

    }
    else{
        return TimeFrame.LAST30DAYS;
    }
  }

  formatCurrency(amount): string {
    return StringUtils.formatCents(amount);
  }

}
