import {Component, OnInit, ViewChild} from '@angular/core'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Params, Router} from '@angular/router'
import {
    MakeModelTrimProviderService
} from '../../../http.services/vehicle/makemodeltrim/make-model-trim-provider/make-model-trim-provider.service'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    MakeDescriptionCriteria,
    MakeDescriptionIdentifier,
    ProvideVehicleModelsRequest,
    ProvideVehicleModelsResponse,
    UnMarshallerService
} from "@magnabc/tpi"

declare const jQuery: any;

@Component({
  selector: 'app-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements OnInit {

    rows = [];
    columns = [
        { prop: 'name' },
        { name: 'make' },
        { name: 'edit' },
    ];
    temp = [];

    make: string;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private title: Title,
                private router: Router,
                private route: ActivatedRoute,
                private makeModelTrimProviderService: MakeModelTrimProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {

        title.setTitle(`Vehicle Models - Console - ${this.runtimeConfigurationService.title}`);

    }

    ngOnInit(): void {
        this.onToggleSearch();

        //noinspection JSIgnoredPromiseFromCall
        this.route.queryParams.forEach((params: Params) => {

            this.make = params['make'];

            const makeDescriptionCriteria = new MakeDescriptionCriteria();
            const makeDescriptionIdentifier = new MakeDescriptionIdentifier();
            makeDescriptionIdentifier.description = this.make;
            makeDescriptionCriteria.makeIdentifier = makeDescriptionIdentifier;
            const provideModelRequest = new ProvideVehicleModelsRequest();
            provideModelRequest.criteria = makeDescriptionCriteria;
            this.makeModelTrimProviderService.provideModel(provideModelRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleModelsResponse) as ProvideVehicleModelsResponse);
                    this.rows = response.models;
                    this.rows.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        }
                        if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });
                    this.temp = this.rows;
                }

            });

        });

    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter(function(d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    onSelect(event): void {
        // this.router.navigate(['/makemodeltrim/trim'], {queryParams: {make: this.make, model: event.selected[0].id}}).then();
    }

    onEdit(event, id): boolean {
        event.stopPropagation();
        this.router.navigate(['/makemodeltrim/' + id], {queryParams: {type: 'model'}}).then();
        return false;
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/makemodeltrim/create/' + this.make], {queryParams: {type: 'model'}}).then();
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

}
