import { StringUtils } from './../../../../../common/utils/utils.string'
import { InsuranceCategory, PriceSchedule } from '@magnabc/tpi'
import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-insurance-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

    @Input() priceSchedule: PriceSchedule;
    @Input() insuranceCategory: InsuranceCategory
    @Input() isEdit = false;

    insuranceCategories = InsuranceCategory

    constructor() { }

    ngOnInit(): void {
    }

    formatAmount(amount): string {
        return StringUtils.formatCents(amount);
    }

    prorataTag(priceSchedule: PriceSchedule): string {
        return priceSchedule.proRata ? 'max premium' : 'fixed premium';
    }

    showAdditionalValue(): boolean {
        if (this.priceSchedule.additionalVehicleToPolicy === null || this.isEdit) {
            return true;
        } else if (this.priceSchedule.extension && !this.priceSchedule.additionalVehicleToPolicy) {
            return false;
        } else {
            return true;
        }
    }

    showNewValue(): boolean {
        if (this.priceSchedule.additionalVehicleToPolicy === null || this.isEdit) {
            return true;
        } else {
            return false;
        }
    }

    showOriginalValue(): boolean {
        if (this.priceSchedule.additionalVehicleToPolicy === null || this.isEdit) {
            return false;
        } else if (this.priceSchedule.extension && this.priceSchedule.additionalVehicleToPolicy) {
            return false;
        } else {
            return true;
        }
    }
}
