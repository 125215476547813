import { LimitInterval } from "./limit-interval.model";
import { PremiumAdjustmentCategory } from "./premium-adjustment-category.model";
import {LimitValueType} from './value-type.model';

export class Limit {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.Limit";
    }

    public currency: string;
    public customisable: boolean;
    public description: string;
    public increment: number;
    public maximumValue: number;
    public minimumValue: number;
    public premiumAdjustmentCategory: PremiumAdjustmentCategory;
    public premiumFixedAdjustmentValue: number;
    public pricePerSeat: number;
    public proRata: boolean;
    public type: string;
    public userIncrementValue: number;
    public value: number;
    public valueType: LimitValueType;
    public limitIntervals: LimitInterval [] = [];
}
