import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {FinaliseCashierPaymentRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class CashierPaymentFinalisationManagerService {

    basePath = '/retail/payment/cashierpaymentfinalisationmanager';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCashierPayment(finaliseCashierPaymentRequest: FinaliseCashierPaymentRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecashierpayment';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCashierPaymentRequest);

        return this.http.post(path, jsonRequest, this.options).toPromise();
    }
}
