import { Injectable } from '@angular/core';
import { Agent, AgentLegalEntityIdentifier, BrokerLegalEntityIdentifier, LegalEntityDescriptionIdentifier, LegalEntityIdentifier, TpiIdentifier } from "@magnabc/tpi";

@Injectable({
    providedIn: 'root'
})
export class AgentManagerService {

    agent: Agent;
    agentIdentifier: AgentLegalEntityIdentifier;
    brokerIdentifier: BrokerLegalEntityIdentifier;
    agentLogin = false;

    constructor() {
        const storageAgentLogin = localStorage.getItem('agentLogin');
        if (storageAgentLogin && storageAgentLogin === 'true') {
            this.agentLogin = true;
            this.agent = JSON.parse(localStorage.getItem('agent'));
            this.agentIdentifier = JSON.parse(localStorage.getItem('agentIdentifier'));
        }
    }

    get defautlAgent(): AgentLegalEntityIdentifier {
        let agentIdentifier = new AgentLegalEntityIdentifier();
        let legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
        legalEntityDescriptionIdentifier.name = "FCA Ltd";
        legalEntityDescriptionIdentifier.surname = "FCA Ltd";
        let tpiIdentifier = new TpiIdentifier();
        tpiIdentifier.tpiIdentifier = "ENT-2";
        legalEntityDescriptionIdentifier.tpiIdentifier = tpiIdentifier;
        agentIdentifier.legalEntityDescriptionIdentifier = legalEntityDescriptionIdentifier;
        agentIdentifier.name = "FCA Ltd";
        return agentIdentifier;
    }
}
