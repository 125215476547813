import {AfterViewInit, Component, OnDestroy} from "@angular/core"
import {TranslateService} from "@ngx-translate/core"
import {Router} from "@angular/router"
import {
    ServiceGroupFinalisationManagerService
} from "../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service"
import {Order} from "@magnabc/tpi"
import {AgentManagerService} from "../../app.services/managers/agent-manager/agent-manager.service"
import {AuthenticationService} from '../../app.services/managers/authentication/authentication.service'
import {
    QuickTransactManagerService
} from "../../app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {Title} from '@angular/platform-browser'
import {RuntimeConfigurationService} from '../../app.services/common/runtime-configuration/runtime-configuration.service'
import {WhiteLabelService} from '../../app.services/managers/white-label/white-label.service'

declare const jQuery: any;

@Component({
    templateUrl: './index-page.component.html',
    styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements AfterViewInit, OnDestroy {

    test = {value: 'Sterling Archer'};

    number: string;

    orderExists = true;

    constructor(translate: TranslateService,
                private title: Title,
                private router: Router,
                public auth: ServiceGroupFinalisationManagerService,
                public agentService: AgentManagerService,
                public whiteLabelService: WhiteLabelService,
                public securityService: AuthenticationService,
                private quickStartService: QuickTransactManagerService,
                public runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Home Page - ${runtimeConfigurationService.title}`);
    }

    onQuickTransaction(event): void {
        this.quickStartService.loadQuickTransact = true;
        this.router.navigate(['/security/account/create'], {queryParams: {quickTransact: true}});
    }

    onSignUp(event): void {
        this.quickStartService.loadQuickTransact = true;
        this.router.navigate(['/security/account/create']);
    }

    onLogin(event): void {
        this.router.navigate(['/login']);
    }

    viewPricing(): void {
        jQuery('html,body').animate({ scrollTop: jQuery('#pricing').offset().top - 80 }, 'slow');
    }

    onPolicyQuery(event): void {
        this.router.navigate(['public/query']);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    onOrder(order: Order): void {
        this.router.navigate([`retail/order/${order.number}`]);
    }

    onExists(exists): void {
        this.orderExists = exists;
    }

}
