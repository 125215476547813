import {Component} from "@angular/core"
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    templateUrl: './make-model-trim-create-page.component.html',
    styleUrls: ['./make-model-trim-create-page.component.scss']
})
export class CreateMakeModelTrimPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Create Vehicle Make - Console - ${this.runtimeConfigurationService.title}`);
    }

}
