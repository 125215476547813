import { Component, OnInit } from '@angular/core';
import { ProvideRoleRevenueRequest, ProvideRoleRevenueResponse, RoleRevenue, RoleRevenueLegalEntityCriteria,
  TimeFrame, UnMarshallerService } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { FormComponent } from "../../../../shared/form/form.component";
import { Utils } from "../../../../shared/Utils/Utils";
import { Chart } from '../../../../../../../node_modules/chart.js';
import { DashboardProviderService } from '../../../../../http.services/ledger/reporting/dashboard-provider/dashboard-provider.service';
import { AuthenticationService } from '../../../../../app.services/managers/authentication/authentication.service';
import { ErrorToastService } from '../../../../../app.services/common/error-toast/error-toast.service';
@Component({
  selector: 'app-role-revenue',
  templateUrl: './role-revenue.component.html',
  styleUrls: ['./role-revenue.component.scss']
})
export class RoleRevenueComponent extends FormComponent implements OnInit {

  roleRevenue: RoleRevenue;
  pieChart;

  constructor(  private translate: TranslateService,
                private translateParser: TranslateParser,
                private dashboardProviderService: DashboardProviderService,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private errorToastService: ErrorToastService) {
      super(translate, translateParser);
  }

  ngOnInit() {}

  getDropDownValue(timeFrame: string): void {
    this.getRoleRevenue( timeFrame );
  }

  getRoleRevenue(timeframe: string): void {

    this.roleRevenue = null;
    const roleRevenueLegalEntityCriteria = new RoleRevenueLegalEntityCriteria();
    roleRevenueLegalEntityCriteria.dashboardPeriod = this.getTimeFramePeriod(timeframe);
    roleRevenueLegalEntityCriteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

    const provideRoleRevenueRequest = new ProvideRoleRevenueRequest();
    provideRoleRevenueRequest.criteria = roleRevenueLegalEntityCriteria;
    this.startLoad();
    this.dashboardProviderService.provideRoleRevenue(provideRoleRevenueRequest).then((httpResponse) => {
      this.stopLoad();
      if (httpResponse && httpResponse.body) {
          const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideRoleRevenueResponse) as ProvideRoleRevenueResponse);
          this.roleRevenue = response.roleRevenue
          this.populatePieChart();
       }
    }).catch((error) => {
        this.errorToastService.errorToast(error);
        this.stopLoad();
    });
  }

  populatePieChart(){
    var ctx = document.getElementById('pieChart');
    var revenueData;
    if(this.roleRevenue){
      revenueData = {
        labels: [
            "Underwriter Revenue",
            "Broker Revenue",
            "Admin Revenue",
            "Printing Agent Revenue",
            "Selling Agent Revenue",
            "Payment Agent Revenue",
            "Revenue Authority Revenue",
            "Road AuthorityRevenue"
        ],
        datasets: [
            {
                data: [ this.formatCurrency(this.roleRevenue.underwriterRevenue.amount),
                        this.formatCurrency(this.roleRevenue.brokerRevenue.amount),
                        this.formatCurrency(this.roleRevenue.adminRevenue.amount),
                        this.formatCurrency(this.roleRevenue.printingAgentRevenue.amount),
                        this.formatCurrency(this.roleRevenue.sellingAgentRevenue.amount),
                        this.formatCurrency(this.roleRevenue.paymentAgentRevenue.amount),
                        this.formatCurrency(this.roleRevenue.revenueAuthorityRevenue.amount),
                        this.formatCurrency(this.roleRevenue.roadAuthorityRevenue.amount)],
                backgroundColor: [
                    "#EBAD44",
                    "#B9BE35",
                    "#4DBFED",
                    "#68296F",
                    "#EF3232",
                    "#178FE3",
                    "#808080",
                    "#178562"
                ],
                borderWidth: 0
            }]
      };
    }else{
      revenueData = {
        labels: [],
        datasets: [
            {
                data: [0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
                backgroundColor: [],
                borderWidth: 0
            }]
      };
    }

    if (this.pieChart) {
      this.pieChart.destroy();
    }

    this.pieChart = new Chart(ctx, {
      type: 'pie',
      data: revenueData,
      options: {
        legend: {
          position: 'right',
          align: 'start',
          labels: {
              fontColor: '#555555',
              boxWidth: 13
          }
        }
      },
      plugins: [{
        afterDraw: function(pieChart) {
          if ((pieChart.data.datasets[0].data[0] == 0.00) &&  (pieChart.data.datasets[0].data[1] == 0.00) &&
            (pieChart.data.datasets[0].data[2] == 0.00) &&  (pieChart.data.datasets[0].data[3] == 0.00) &&
            (pieChart.data.datasets[0].data[4] == 0.00) &&  (pieChart.data.datasets[0].data[5] == 0.00)) {
            // No data is present
            var ctx = pieChart.chart.ctx;
            var width = pieChart.chart.width;
            var height = pieChart.chart.height;
            pieChart.clear();

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "12px";
            ctx.fillText('No available data to display', width / 2, height / 2);
            ctx.restore();
          }
        }
      }]
    });

  }

  getTimeFramePeriod(preferredPeriod: string): TimeFrame{
    if(preferredPeriod === "Today"){
        return TimeFrame.TODAY;

    }
    else if(preferredPeriod === "Yesterday"){
        return TimeFrame.YESTERDAY;

    }
    else if(preferredPeriod === "Last 7 Days"){
      return TimeFrame.LAST7DAYS;

    }
    else if(preferredPeriod === "Current Month"){
      return TimeFrame.CURRENTMONTH;

    }
    else{
        return TimeFrame.LAST30DAYS;
    }
  }

  formatCurrency(amount){
    return (amount/100).toFixed(2);
  }

}
