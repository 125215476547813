import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {CheckoutProposalRequest, MarshallerService} from '@magnabc/tpi'
import {Observable} from 'rxjs'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class ProposalCheckoutManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  checkoutProposal(checkoutProposalRequest: CheckoutProposalRequest): Observable<any> {
      const pathURL = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/comprehensiveinsurance/proposalcheckoutmanager/checkoutproposal';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(checkoutProposalRequest);
      return this.httpClient.post(pathURL, jsonRequest, this.options);
  }
}
