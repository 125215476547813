import {Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {Utils} from '../../../shared/Utils/Utils'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {Router} from '@angular/router'
import {
    SubscriptionManagerService
} from '../../../../http.services/security/authorisation/subscription-manager/subscription-manager.service'
import {
    SubscriptionInformationProviderService
} from '../../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service'
import {
    QueryLegalEntityInformationManagerService
} from '../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
    CompareEntityIdentifiersService
} from '../../../../app.services/managers/compare-entity-identifiers/compare-entity-identifiers.service'
import {MatDialog} from '@angular/material/dialog'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ServiceGroupData, UserData} from '../../../user-account-management/user-update/user-data.model'
import {UserUpdateComponent} from '../../../user-account-management/user-update/user-update.component'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {ServiceList} from '../../../../common/model/service-list.model'
import {environment} from '../../../../../environments/environment'
import {
    DeleteSubscriptionRequest,
    LegalEntityProviderCriteria,
    NaturalPerson,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsResponse,
    ProxySubscriptionOwnedByLegalEntityCriteria,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    ServiceGroup,
    Subscription,
    TpiIdentifier,
    UnMarshallerService
} from "@magnabc/tpi"
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent extends FormComponent implements OnInit {

    rows: SubscriptionExt[] = [];
    columns = [
        { serviceGroup: 'serviceGroup'},
        { usedBy: 'usedBy' }
    ];
    userMap: {[id: string]: NaturalPerson} = {};
    utils = Utils;

    activeToast: ActiveToast<any>;
    temp: SubscriptionExt[] = [];
    allSubscriptions: Subscription[];

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                private subscriptionManagerService: SubscriptionManagerService,
                private subscriptionInformationProviderService: SubscriptionInformationProviderService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                public authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService,
                private compareEntityIdentifierService: CompareEntityIdentifiersService,
                public addServiceGroupDialog: MatDialog,
                private runtimeConfigurationService: RuntimeConfigurationService
    ) {
        super(translate, translateParser);

        title.setTitle(`Subscriptions - Console - ${this.runtimeConfigurationService.title}`);

        this.temp = this.rows;
    }

    ngOnInit(): void {

        this.getSubscriptions();

        this.onToggleSearch();
    }

    updateFilter(event) {

        const val = event.target.value.trim().toLowerCase();

        this.rows = this.temp.filter(subscription => {
            const user = this.userMap[subscription.usedBy.tpiIdentifier];
            const fullName = `${user.firstNames} ${user.surname}`.toLowerCase();
            const reverseFullName = `${user.surname} ${user.firstNames}`.toLowerCase();
            return (fullName.toLowerCase().indexOf(val) !== -1) || (reverseFullName.toLowerCase().indexOf(val) !== -1) || !val;
        });

        this.table.offset = 0;

    }

    onSelect(event): void {

    }

    onManageUser(event: Event, row): void {
        event.stopPropagation();
        event.preventDefault();

        const foundUser = this.userMap[row.usedBy.tpiIdentifier];

        const user: UserData = {
            userName: foundUser.firstNames,
            userSurname: foundUser.surname,
            userEmail: "1234567",
            status: row.status,
            usedBy: row.usedBy.tpiIdentifier,
            ownedBy: row.ownedBy.tpiIdentifier,
            serviceGroupDataList: [],
            subscriptions: []
        };


        row.serviceGroups.forEach((serviceGroup, index) => {
            const serviceGroupData: ServiceGroupData = {
                defaultGroup: serviceGroup.defaultGroup,
                serviceGroupName: serviceGroup.name
            };
            user.serviceGroupDataList.push(serviceGroupData);
        });

        user.isPublicUser = true;

        this.addServiceGroupDialog.open(UserUpdateComponent,
            { data: user, maxWidth: '1024px !important' }
        ).afterClosed()
            .subscribe(() => {
                this.getSubscriptions();
            });
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/subscription/create']);
    }

    onRemove(event, subscription: Subscription): boolean {
        event.stopPropagation();

        this.translate.get('TOAST.REMOVE_SUBSCRIPTION').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: subscription.serviceGroup}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        const subscriptions = this.allSubscriptions.filter(item =>
                            item.usedBy.tpiIdentifier === subscription.usedBy.tpiIdentifier
                            && item.ownedBy.tpiIdentifier === subscription.ownedBy.tpiIdentifier);

                        this.removeSubscription(subscriptions);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

        return false;

    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    getNaturalPerson(identifier: TpiIdentifier): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = identifier;
        legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest();
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse);
                if (response.legalEntities.length>0){
                    this.userMap[identifier.tpiIdentifier] = response.legalEntities[0];
                }
            }
        });

    }

    servicesCreateSubscriptions(): boolean {
        return !!this.authenticationService.isAuthorised([ServiceList.CREATESUBSCRIPTION]);
    }

    servicesRemoveSubscriptions(row: any): boolean {
        return ((Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).
                tpiIdentifier === row.ownedBy.tpiIdentifier) &&
            !!this.authenticationService.isAuthorised([ServiceList.UPDATESUBSCRIPTION]));
    }

    onDetailToggle(event) { }

    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
    }

    private removeSubscription(subscriptions: Subscription[]) {
        const deleteSubscriptionRequest = new DeleteSubscriptionRequest();
        deleteSubscriptionRequest.subscriptions = subscriptions;
        this.subscriptionManagerService.deleteSubscription(deleteSubscriptionRequest).then((response) => {
            this.toast(response);
            this.getSubscriptions();
        });

    }

    private getSubscriptions() {
        let naturalPersonId: TpiIdentifier;
        const criteria = new ProxySubscriptionOwnedByLegalEntityCriteria();
        criteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = criteria;
        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest)
            .then((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body, ProvideSubscriptionsResponse) as ProvideSubscriptionsResponse);

                    this.rows = [];
                    for (const o of response.subscriptions) {
                        this.rows.push(o);
                    }

                    for (const user of this.rows) {
                        naturalPersonId = user.usedBy;
                        this.getNaturalPerson(naturalPersonId);
                    }

                    this.allSubscriptions = this.rows;
                    this.temp = this.rows;
                    this.rows = [];
                    this.temp.forEach(item => {
                        const found = this.rows.filter(row => row.usedBy.tpiIdentifier === item.usedBy.tpiIdentifier);
                        if(found.length === 0){
                            item.serviceGroups = [];
                            item.serviceGroups.push(item.serviceGroup)
                            this.rows.push(item);
                        }else{
                            found[0].serviceGroups.push(item.serviceGroup);
                        }
                    });

                    this.rows.sort((a, b) => this.compareServiceGroups(a,b));
                    this.temp = this.rows;
                }
            });

    }

    private toast(title) {

        this.translate.get('TOAST.SUBSCRIPTION_REMOVED').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: title}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    private compareServiceGroups(value: SubscriptionExt, compare: SubscriptionExt): number{
        return value.usedBy.tpiIdentifier.localeCompare(compare.usedBy.tpiIdentifier);
    }

    getRowIndex(row: SubscriptionExt): number {
        let indx = 0;
        this.rows.forEach((val, index) => {
            if (val.usedBy.tpiIdentifier === row.usedBy.tpiIdentifier) {
                indx = index;
            }
        })
        return indx;
    }
}

interface SubscriptionExt extends Subscription {
    serviceGroups?: ServiceGroup[]
}
