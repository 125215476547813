import {InsuranceType} from "../setup/models/insurance-type.model"
import {Country} from "../../../location/geographic/models/country.model"
import {MotorInsuranceLimitDetails} from "../../checkout/models/motor-insurance-limit-details.model"
import {PriceSchedule} from "./price-schedule.model"
import {Money} from "../../../financial/models/money.model"
import {InsuranceProductCartItem} from "./insurance-product-cart-item.model"
import {Excess} from "./excess.model"
import {AgentIdentifier} from "../../../entity/identification/models/agent-identifier.model"
import {BrokerIdentifier} from '../identification/models/broker-identifier.model'
import {UnderwriterIdentifier} from '../identification/models/underwriter-identifier.model'

export class ExtensionInsuranceProductCartItem extends InsuranceProductCartItem {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.ExtensionInsuranceProductCartItem";
  }

  public conditions: string;
  public annualAmount: Money;
  public annualPricePercentage: Money;
  public alarmImmobiliser: boolean;
  public insuranceVehicleCategoryDescription: string;
  public insuranceVehicleCategorySubDescription: string;
  public priceSchedule: PriceSchedule;
  public excess: Excess[] = [];
  public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[];
  public coveredCountries: Country[];
  public agentIdentifiers: AgentIdentifier[];
  public brokerIdentifier: BrokerIdentifier;
  public underwriterIdentifier: UnderwriterIdentifier;
  public insuranceType: InsuranceType;
}
