import {Request} from '../../../../../../../../urdad/services/models/request.model'
import {CertificateType} from '../../../../../models/certificate-type.model'
import {MotorInsurancePolicyItem} from '../../../../../../models/motor-insurance-policy-item.model'
import {AgentIdentifier} from '../../../../../../../../entity/identification/models/agent-identifier.model'

export class FinaliseIssueInsuranceCertificateRequest extends Request {
    constructor() {
        super();
    }

    public insurancePolicyNumber: string
    public agentIdentifier: AgentIdentifier
    public certificateNumber: string
    public userId: string
    public tpiVehicleIdentifier: string
    public certificateType: CertificateType
    public motorInsurancePolicyItem: MotorInsurancePolicyItem
    public issueDate: Date
}
