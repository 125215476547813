import { ProposalResult } from '../../../../../models/proposal-result.model';
import { Request } from "../../../../../../../../urdad/services/models/request.model";
import { UnderwriterIdentifier } from '../../../../../../identification/models/underwriter-identifier.model';
import { LegalEntityIdentifier } from "../../../../../../../../entity/identification/models/legal-entity-identifier.model";


export class FinaliseProposalAssessmentRequest extends Request{
    constructor(){
        super();
    }

    public underwriterIdentifier : UnderwriterIdentifier;
    public assessor: LegalEntityIdentifier;
    public proposalId: string;
    public proposalResults : ProposalResult [] = [];
    public orderNumber: string;
    public proposalIdentifier: string;
}
