import { RenderedDocumentType } from '../../../../../../../rendering/models/rendered-document-type.model';
import {Request} from "../../../../../../../urdad/services/models/request.model";
import {LegalEntityIdentifier} from "../../../../../../../entity/identification/models/legal-entity-identifier.model";
import {MotorInsurancePolicyItem} from '../../../../../models/motor-insurance-policy-item.model';

export class InitialiseIssueInsuranceCertificateRequest extends Request{
    constructor(){ 
        super();
    }

    public motorInsurancePolicyItem: MotorInsurancePolicyItem
    public legalEntityIdentifier: LegalEntityIdentifier
    public userId: String
    public insurancePolicyNumber: String
    public types: RenderedDocumentType [] = []
}
