import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, ProvideCountryInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class CountryProviderService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideCountryInformation(provideCountryInformationRequest: ProvideCountryInformationRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/location/geographic/countryprovider/providecountryinformation';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryInformationRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }
}
