import { Criteria } from "../../../criteria/models/criteria.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";
import { VehicleIdentifier } from "../../../vehicle/identification/models/vehicle-identifier.model";

export class RetailProfileVehicleInsurancePolicyCriteria extends Criteria {
    constructor() {
        super();

        this['@class'] = "za.co.magnabc.tpi.retail.insurance.RetailProfileVehicleInsurancePolicyCriteria";
    }

    public date: Date;
    public countryOfRegistration: CountryIdentifier;
    public vehicleIdentifiers: VehicleIdentifier[] = [];
}