export class Engine{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.vehicle.Engine";
    }

    public engineNumber: string;
	public engineCapacity: number;
	public netPower: number;
	public transmission: string;
	public fuelType: string;
}