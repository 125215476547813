import { CountryIdentifier } from "./country-identifier.model";


export class CountryDialingIdentifier extends CountryIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.location.geographic.identification.CountryDialingIdentifier";
    }

    public description:string;
    public dialingCode:string;
    public dialingMask:string;
    public alphaTwo:string;
    public flagUrl:string;

}
