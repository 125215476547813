import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {IStepComponent} from '../../../shared/guards/step-guard'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {MatDialog} from '@angular/material/dialog'
import {
    OneTimePinVerificationManagerProviderService
} from '../../../../http.services/security/onetimepin/one-time-pin-verification-manager-provider/one-time-pin-verification-manager-provider.service'
import {AgentManagerService} from '../../../../app.services/managers/agent-manager/agent-manager.service'
import {
    ServiceGroupFinalisationManagerService
} from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service'
import {
    FinancialReportProviderService
} from '../../../../http.services/ledger/reporting/financial-report-provider/financial-report-provider.service'
import {
    UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
    PayoutMethodProviderService
} from '../../../../http.services/ledger/payout/payout-method-provider/payout-method-provider.service'
import {
    DepositMethodProviderService
} from '../../../../http.services/ledger/deposit/deposit-method-provider/deposit-method-provider.service'
import {
    FinancialAccountInformationProviderService
} from '../../../../http.services/ledger/financialaccount/financial-account-information-provider/financial-account-information-provider.service'
import {
    AccountingTransactionInformationProviderService
} from '../../../../http.services/ledger/accountingtransaction/accounting-transaction-information-provider/accounting-transaction-information-provider.service'
import {ServiceList} from '../../../../common/model/service-list.model'
import * as moment from 'moment/moment'
import {environment} from '../../../../../environments/environment'
import * as XLSX from 'xlsx'
import {PayoutDialogComponent} from '../../accounting-transaction/dialogs/payout-dialog.component'
import {DepositDialogComponent} from '../../accounting-transaction/dialogs/deposit-dialog.component'
import {
    AccountingTransactionReferenceDateCriteria,
    AccountingTransactionReferenceIdentifier,
    AccountingTransactionReferenceLatestCriteria,
    AccountReportLegalEntityDateCriteria,
    Agent,
    AllFinancialAccountCriteria,
    CountryDescriptionIdentifier,
    DepositMethodCountryCriteria,
    LegalEntityPayoutMethodCriteria,
    ProvideAccountingTransactionInformationRequest,
    ProvideAccountStatementReportRequest,
    ProvideDepositMethodRequest,
    ProvideFinancialAccountInformationRequest,
    ProvidePayoutMethodRequest,
    ProvideUnderwriterInformationRequest,
    TpiIdentifier,
    UnderwriterLegalEntityCriteria
} from "@magnabc/tpi"

type AOA = any[][];

declare const jQuery: any;

export interface PayoutValues {
    name: string;
    country : string;
    balance: string;
    identifier: string;
}

export interface PayoutData {
    bankName: string;
    accountName : string;
    accountNumber: string;
    swiftCode:string;
    branchCode:string;
    accountType:string;
    country: string;
}

export interface DepositValues {
    country : string;
    balance: string;
    identifier: string;
}

export interface DepositData {
    bankName: string;
    accountName : string;
    accountNumber: string;
    swiftCode:string;
    branchCode:string;
    accountType:string;
    country:string;
}

@Component({
  selector: 'app-account-statements',
  templateUrl: './account-statements.component.html',
  styleUrls: ['./account-statements.component.scss']
})
export class AccountStatementsComponent extends FormComponent implements OnInit,IStepComponent {
    backPressed:boolean;
    zambeziInsureIdentifier = 'ENT-2'; // FIXME hardcoded

    rows = [];
    accountSettlementData: AOA = [];
    settlementHistoryData: AOA = [];
    fileName = 'export.xlsx';
    accountType = "nan";
    checked = false;
    period;
    currency;

    agent: Agent;
    entity = "Entity";
    from;
    to;

    noResults = false;
    queryBy = 0;
    payoutValues : PayoutValues [] = [];
    depositValues : DepositValues [] = [];
    payoutData: PayoutData [] = [];
    depositData: DepositData [] = [];

    roleMap: {[id: string]: string[]} = {};

    constructor(private translate: TranslateService, private translateParser: TranslateParser, public dialog: MatDialog,
                private securityService: OneTimePinVerificationManagerProviderService,
                private agentService: AgentManagerService,
                public authenticationService: ServiceGroupFinalisationManagerService,
                public financialReportProviderService:FinancialReportProviderService,
                public brokerService : UnderwriterInformationProviderService,
                public payoutService : PayoutMethodProviderService,
                public depositService : DepositMethodProviderService,
                public financialAccountInformationProviderService : FinancialAccountInformationProviderService,
                public accountingTransactionInformationProvider: AccountingTransactionInformationProviderService) {
        super(translate, translateParser);

    }

    ngOnInit() {
        this.checked =false;
        this.accountType = "Back office user"
        if(!(this.authenticationService.services[ServiceList.PROVIDESERVICEGROUPS])){
            this.accountType = "Administrator";
        }
        this.from = moment();
        this.to = moment();

        setTimeout(value =>{
            console.log("TIMEOUT")
            this.getSettlements();
        },1000)

    }

    checkAndGetAccountStatements():void{

        this.period = Math.floor((Date.UTC((this.to._d as Date).getFullYear(), (this.to._d as Date).getMonth(), (this.to._d as Date).getDate()) - Date.UTC((this.from._d as Date).getFullYear(), (this.from._d as Date).getMonth(), (this.from._d as Date).getDate()) ) /(1000 * 60 * 60 * 24));

        if(this.period < 1){
            alert("Please specify a valid period.");
            return;
        }

        //FUTURE EXPANSION ON PROCESS
        if(this.accountType.match("Back office user") || this.accountType.match("Administrator")){

            this.checked = true;
            this.getAccountStatementReport();
            this.getAccountSettlementHistoryReport();
        }
        else{
            console.log("Not a valid account type.")
        }
    }


    getSettlements():void{

        if(this.accountType.match("Back office user")){

            var accountingTransactionReferenceLatestCriteria = new AccountingTransactionReferenceLatestCriteria()
            var accountingTransactionReferenceIdentifier = new AccountingTransactionReferenceIdentifier();
            var provideAccountingTransactionInformationRequest = new ProvideAccountingTransactionInformationRequest();


            accountingTransactionReferenceIdentifier.reference = this.securityService.account.id;
            accountingTransactionReferenceLatestCriteria.accountingTransactionIdentifiers.push(accountingTransactionReferenceIdentifier);
            provideAccountingTransactionInformationRequest.batchNumber = -1;
            provideAccountingTransactionInformationRequest.batchSize = -1;
            provideAccountingTransactionInformationRequest.criteria = accountingTransactionReferenceLatestCriteria;

            var accountingTransactionInfo;
            var accountingBalance;

            this.accountingTransactionInformationProvider.provideAccountingTransactionInformation(provideAccountingTransactionInformationRequest).subscribe(response=>{
                accountingTransactionInfo = response;

                accountingBalance = (response.accountingTransactions[0].entries[0].amount.amount/100) + " " + response.accountingTransactions[0].entries[0].amount.currency;
                if(response.accountingTransactions[0].entries[0].entryType === 'DEBIT'){
                    accountingBalance = "-" + accountingBalance;
                }

                this.depositValues.push({
                    country : (this.agentService.agent.countryIdentifier as CountryDescriptionIdentifier).description,
                    balance: accountingBalance,
                    identifier: (this.agentService.agent.legalEntityIdentifier as TpiIdentifier).tpiIdentifier,
                })
            })

            const legalEntityDepositMethodCriteria = new DepositMethodCountryCriteria();

            legalEntityDepositMethodCriteria.countryIdentifier = (this.agentService.agent.legalEntityIdentifier as TpiIdentifier).tpiIdentifier;
        }
        else if(this.accountType.match("Administrator")){

            const provideFinancialAccountInformationRequest = new ProvideFinancialAccountInformationRequest();
            provideFinancialAccountInformationRequest.criteria = new AllFinancialAccountCriteria();
            provideFinancialAccountInformationRequest.batchNumber = -1;
            provideFinancialAccountInformationRequest.batchSize = -1;

            this.financialAccountInformationProviderService.provideFinancialAccountInformation(provideFinancialAccountInformationRequest).subscribe(response =>{

                response.financialAccounts.forEach(element => {

                    var underwriterName = "-";
                    var provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
                    var underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
                    var tpiIdentifier = new TpiIdentifier();
                    tpiIdentifier.tpiIdentifier = element.legalEntityIdentifier
                    underwriterLegalEntityCriteria.legalEntityIdentifiers = [tpiIdentifier];
                    provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
                    this.brokerService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe(underwriterResponse=>{
                        underwriterName = underwriterResponse.name;
                    })

                    var accountTypeDisplay = (element.balance.amount/100) + " " + element.balance.currency;
                    if(element.balanceType === "DEBIT"){
                        accountTypeDisplay ="-" + accountTypeDisplay;
                    }
                    this.payoutValues.push({
                        identifier : element.legalEntityIdentifier,
                        name : underwriterName,
                        country : element.countryIdentifier.description,
                        balance : accountTypeDisplay,
                    });
                });

            })
        }


    }

    openDepositDialog(identifier: string){

        this.depositData = [];
        var depositMethodCountryCriteria = new DepositMethodCountryCriteria();
        var countryDescriptionIdentifier = new CountryDescriptionIdentifier();
        var depositMethodRequest = new ProvideDepositMethodRequest();
        countryDescriptionIdentifier.description = identifier;
        depositMethodCountryCriteria.countryIdentifier = countryDescriptionIdentifier;
        depositMethodRequest.criteria = depositMethodCountryCriteria;

        this.depositService.provideDepositMethod(depositMethodRequest).subscribe(depositResponse =>{
            depositResponse.depositMethod.forEach(element => {
                this.depositData.push({
                    bankName: element.bankName,
                    accountName : element.accountName,
                    accountNumber: element.accountNumber,
                    swiftCode:element.swiftBicCode,
                    branchCode:element.branchCode,
                    accountType:element.accountType,
                    country: element.countryIdentifier.description
                })
            });
        })

        setTimeout(response =>{
            this.showDepositDialog(this.depositData);
        },1000)
    }

    openPayoutDialog(identifier:string){

        this.payoutData = [];
        var legalEntityPayoutMethodCriteria = new LegalEntityPayoutMethodCriteria();
        var payoutMethodRequest = new ProvidePayoutMethodRequest();
        legalEntityPayoutMethodCriteria.legalEntityIdentifier = identifier;
        payoutMethodRequest.criteria = legalEntityPayoutMethodCriteria;

        this.payoutService.providePayoutMethod(payoutMethodRequest).subscribe(payoutResponse=>{

            payoutResponse.payoutMethod.forEach(element => {
                this.payoutData.push({
                    bankName: element.bankName,
                    accountName : element.accountName,
                    accountNumber: element.accountNumber,
                    swiftCode:element.swiftBicCode,
                    branchCode:element.branchCode,
                    accountType:element.accountType,
                    country: element.countryIdentifier.description
                })
            });

        })

        setTimeout(response =>{
            this.showPayoutDialog(this.payoutData);
        },1000)
    }

    getAccountStatementReport(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        const accountReportLegalEntityDateCriteria = new AccountReportLegalEntityDateCriteria();
        const provideAccountStatementReportRequest = new ProvideAccountStatementReportRequest();

        accountReportLegalEntityDateCriteria.dateFrom = this.from._d as Date;
        accountReportLegalEntityDateCriteria.dateTo = this.to._d as Date;
        accountReportLegalEntityDateCriteria.period = this.period.toString();
        console.log(this.brokerService.selectedUnderwriter);
        console.log(this.agentService.agent);
        if(this.accountType.match("Back office user")){
            accountReportLegalEntityDateCriteria.legalEntityIdentifier = this.brokerService.selectedUnderwriter.legalEntityIdentifier;
        }
        else if(this.accountType.match("Administrator")){
            accountReportLegalEntityDateCriteria.legalEntityIdentifier = this.agentService.agent.legalEntityIdentifier;
        }

        provideAccountStatementReportRequest.criteria = accountReportLegalEntityDateCriteria;

        if (this.brokerService.selectedUnderwriter) {
            this.entity = this.brokerService.selectedUnderwriter.name;
        } else if (this.brokerService.broker) {
            this.entity = this.brokerService.broker.id;
        }
        if (this.agentService.agent) {
            if(this.accountType.match("Back office user")){
                this.entity = this.brokerService.selectedUnderwriter.name;
            }
            else if(this.accountType.match("Administrator")){
                this.entity = this.agentService.agent.name;
            }

        }

        this.startLoad();

        /** MOCK DATA : DELETE AFTER TESTING BACKEND */
        this.accountSettlementData.push([this.entity])
        this.accountSettlementData.push([""]);
        this.accountSettlementData.push(["From Date", "Date To", "Period", "Currency"]);
        this.accountSettlementData.push([this.getDate(this.from), this.getDate(this.to), this.period.toString() + " day(s)", "MWK" ]);
        this.accountSettlementData.push([""]);
        this.accountSettlementData.push(["Date", "Description", "Amount Debit", "Amount Credit"]);
        this.accountSettlementData.push([this.getDate(this.from),"Opening Balance", -67, "" ]);
        this.accountSettlementData.push([this.getDate(this.from), "Payable", "",100 ]);
        this.accountSettlementData.push([this.getDate(this.from),"Opening Balance", -67, "" ]);
        this.accountSettlementData.push([this.getDate(this.from), "Payable", "",100 ]);
        this.accountSettlementData.push([this.getDate(this.from),"Opening Balance", -67, "" ]);
        this.accountSettlementData.push([this.getDate(this.from), "Payable", "",100 ]);
        this.accountSettlementData.push([this.getDate(this.from),"Opening Balance", -67, "" ]);
        this.accountSettlementData.push([this.getDate(this.from), "Payable", "",100 ]);
        this.accountSettlementData.push([""]);
        this.accountSettlementData.push(["End"]);
        /****************************************** */

        this.financialReportProviderService.provideAccountStatementReport(provideAccountStatementReportRequest).subscribe(response =>{

            this.stopLoad();

            this.accountSettlementData = [];

            this.accountSettlementData.push([this.entity])
            this.accountSettlementData.push([""]);
            this.accountSettlementData.push(["From Date", "Date To", "Period", "Currency"]);
            for(const r of response.accountStatementReport){
                this.currency = r.currency;
                this.accountSettlementData.push([this.getDate(this.from), this.getDate(this.to), r.period.toString() + "day(s)", this.currency.toString() ]);
            }

            this.accountSettlementData.push([""]);
            this.accountSettlementData.push(["Date", "Description", "Amount Debit", "Amount Credit"]);

            response.accountStatementReport.sort((a,b) => {
                if(moment(a.dateTime) > moment(b.dateTime)){
                    return 1;
                }
                if(moment(a.dateTime) < moment(b.dateTime)){
                    return -1;
                }
                return 0;
            });

            for(const r of response.accountStatementReport){

                for(const i of r.accountStatementReportEntry){

                    if(i.amountType.contains("Debit")){
                        this.accountSettlementData.push([i.date, i.description, i.amount, "" ]);
                    }
                    else if(i.amountType.contains("Credit")){
                        this.accountSettlementData.push([i.date, i.description, "",i.amount ]);
                    }
                }
            }

            this.accountSettlementData.push([""]);
            this.accountSettlementData.push(["End"]);

            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 100);

        }, exception => {
            this.stopLoad();
            console.log(exception);
        });
    }

    getAccountSettlementHistoryReport(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        const accountingTransactionIdentifier = new AccountingTransactionReferenceIdentifier();
        accountingTransactionIdentifier.reference = this.securityService.account.id;
        const accountingTransactionReferenceDateCriteria = new AccountingTransactionReferenceDateCriteria();
        accountingTransactionReferenceDateCriteria.accountingTransactionIdentifiers.push(accountingTransactionIdentifier);
        accountingTransactionReferenceDateCriteria.dateFrom = this.from._d as Date;
        accountingTransactionReferenceDateCriteria.dateTo = this.to._d as Date;

        const provideAccountingTransactionInformationRequest = new ProvideAccountingTransactionInformationRequest();
        provideAccountingTransactionInformationRequest.criteria = accountingTransactionReferenceDateCriteria;
        provideAccountingTransactionInformationRequest.batchNumber = -1;  //-1 indicates returning the full list of accounting transactions
        provideAccountingTransactionInformationRequest.batchSize = -1; //-1 indicates returning the full list of accounting transactions

        this.settlementHistoryData = [];

        /** MOCK DATA : DELETE AFTER TESTING BACKEND */
        this.settlementHistoryData.push([this.entity])
        this.settlementHistoryData.push([""]);
        this.settlementHistoryData.push(["From Date", "Date To", "Account Number", "Currency"]);
        this.settlementHistoryData.push([this.getDate(this.from), this.getDate(this.to), this.securityService.account.id, "MWK" ]);
        this.settlementHistoryData.push([""]);
        this.settlementHistoryData.push(["Date","Amount Debit", "Amount Credit"]);
        this.settlementHistoryData.push([this.getDate(this.from), -67, "" ]);
        this.settlementHistoryData.push([this.getDate(this.from), "",100 ]);
        this.settlementHistoryData.push([this.getDate(this.from),-67, "" ]);
        this.settlementHistoryData.push([this.getDate(this.from), "",100 ]);
        this.settlementHistoryData.push([this.getDate(this.from), -67, "" ]);
        this.settlementHistoryData.push([this.getDate(this.from), "",100 ]);
        this.settlementHistoryData.push([""]);
        this.settlementHistoryData.push(["End"]);
        /****************************************** */

        this.accountingTransactionInformationProvider.provideAccountingTransactionInformation(provideAccountingTransactionInformationRequest).subscribe(response =>{

            this.settlementHistoryData.push([this.entity])
            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["From Date", "Date To", "Account Number", "Currency"]);

            for(const r of response.accountingTransaction){
                this.settlementHistoryData.push([this.getDate(this.from), this.getDate(this.to), r.date, this.currency.toString() ]);
            }

            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["Date","Amount Debit", "Amount Credit"]);
            for(const r of response.accountingTransaction){
                for(const i of r.entry){
                    if(i.amountType.contains("Debit")){
                        this.settlementHistoryData.push([i.date, i.amount, "" ]);
                    }
                    else if(i.amountType.contains("Credit")){
                        this.settlementHistoryData.push([i.date, "",i.amount ]);
                    }
                }

            }

            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["End"]);

            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 100);

        }, exception =>{
            console.log(exception)
        });

    }

    onQueryType(type): boolean {

        this.noResults = false;

        this.queryBy = type;

        return false;
    }

    getDate(date): string {
        return moment(date).format(environment.formatDate);
    }

    export(value: string): void {
        console.log(this.accountSettlementData);
        console.log(this.settlementHistoryData);
        if(value.match("Account Statement Report")){

            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.accountSettlementData);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, value);

            this.fileName = value+".xlsx";

            XLSX.writeFile(wb, this.fileName);
        }else{
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.settlementHistoryData);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, value);

            this.fileName = value+".xlsx";

            XLSX.writeFile(wb, this.fileName);
        }


    }

    showPayoutDialog(data : PayoutData []) {
        return this.dialog.open(PayoutDialogComponent, {
            data: data
        });
    }

    showDepositDialog(data : DepositData []) {
        return this.dialog.open(DepositDialogComponent, {
            data: data
        });
    }
    onDeactivate():boolean{return true};

}
