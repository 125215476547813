import {Injectable} from '@angular/core'
import {
  BackOfficeOTPAuthenticateCredentialsRequest,
  BackOfficeOTPAuthenticateCredentialsResponse,
  BackOfficeOTPInitialiseAuthenticationRequest,
  BackOfficeOTPInitialiseAuthenticationResponse,
  BackOfficeOTPValidateObscuredContactNumberRequest,
  BackOfficeOTPValidateObscuredContactNumberResponse,
  Credentials,
  NaturalPersonIdentifierCredential,
  OneTimePinIdentifier,
  PlainTextUsername,
  UnMarshallerService
} from '@magnabc/tpi'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {AuthenticationService} from "../../../../app.services/managers/authentication/authentication.service"
import {
  BackOfficeOtpAuthenticatorProviderService
} from '../back-office-otp-authenticator-provider/back-office-otp-authenticator-provider.service'

@Injectable({
    providedIn: 'root'
})
export class BackOfficeOtpAuthenticatorService {

    private oneTimePinIdentifier: OneTimePinIdentifier;
    private credentials: Credentials;

    constructor(private backOfficeOtpAuthenticatorProviderService: BackOfficeOtpAuthenticatorProviderService,
                private authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService) {
    }

    initialiseBackOfficeOTPAuthentication(username: string) {
        const nationalIdNumber = new PlainTextUsername();
        nationalIdNumber.username = username;

        const credentials = new NaturalPersonIdentifierCredential();
        credentials.naturalPersonIdentifier = nationalIdNumber;

        const request = new BackOfficeOTPInitialiseAuthenticationRequest();
        request.credentials = credentials;

        return new Promise<string>((resolve, reject) => {
            this.backOfficeOtpAuthenticatorProviderService.initialiseAuthentication(request).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    this.credentials = credentials;
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, BackOfficeOTPInitialiseAuthenticationResponse) as BackOfficeOTPInitialiseAuthenticationResponse);
                    resolve(response.obscuredContactNumber);
                    return;
                }

                reject();
                return;
            }).catch(error => {
                console.error('Error while issuing OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                reject('Account could not be found.');
                return;
            });
        });
    }

    validateBackOfficeOTPObscured(fullContactNumber: string) {
        const request = new BackOfficeOTPValidateObscuredContactNumberRequest();
        request.obscuredNumbers = fullContactNumber;
        request.credentials = this.credentials;

        return new Promise<boolean>((resolve, reject) => {
            this.backOfficeOtpAuthenticatorProviderService.validateObscuredContactNumber(request).then(httpResponse => {
                if (httpResponse && httpResponse.body){
                    const validateContactNumberResponse = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, BackOfficeOTPValidateObscuredContactNumberResponse) as BackOfficeOTPValidateObscuredContactNumberResponse);
                    this.oneTimePinIdentifier = validateContactNumberResponse.oneTimePinIdentifier;

                    resolve(true);
                    return;
                }
                resolve(false);
                return;
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                console.log(error);
                resolve(false);
                return;
            });
        });
    }

    authenticateCredentialsByOTP(otp: string) {
        const request = new BackOfficeOTPAuthenticateCredentialsRequest();
        request.capturedOneTimePin = otp;
        request.oneTimePinIdentifier = this.oneTimePinIdentifier;
        request.credentials = this.credentials;

        return new Promise<boolean>((resolve, reject) => {
            if (!this.credentials || !this.oneTimePinIdentifier){
                resolve(false);
                return;
            }

            this.backOfficeOtpAuthenticatorProviderService.authenticateCredentials(request).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, BackOfficeOTPAuthenticateCredentialsResponse) as BackOfficeOTPAuthenticateCredentialsResponse);

                    if (response.authenticationAdvice.authentic){
                        console.log('Logged in!');
                        return this.authenticationService.setBackOfficeAuthentication(response.authenticationAdvice);
                    } else {
                        console.log('Login failure');
                        resolve(false);
                        return;
                    }
                }
            }).then(authenticationAdvice => {
                resolve(authenticationAdvice);
                return;
            }).catch(error => {
                console.error('Error while authenticating OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return;
            })
        });
    }
}
