import { CountryIdentifier } from "../identification/models/country-identifier.model";
import { Address } from "./address.model";

export class BasicPhysicalAddress extends Address {
  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.location.geographic.BasicPhysicalAddress";
  }

  public city: string;
  public code: string;
  public addressIdentifier: string;
  public lineOne: string;
  public lineTwo: string;
  public lineThree: string;
  public countryIdentifier: CountryIdentifier;

}
