import {Response} from '../../../../../../../urdad/services/models/response.model';
import { PublicInsurancePolicySummary } from '../../../../../models/public-insurance-policy-summary.model';

export class QueryPublicInsurancePolicyInformationResponse extends Response{
    constructor(){
        super();
    }

    public publicInsurancePolicySummaries: PublicInsurancePolicySummary [] = [];
}
