import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Make} from '../../../../../models/make.model';

export class FinaliseCreateVehicleMakeRequest extends Request {
    constructor() {
        super();
    }

    public make: Make;
}
