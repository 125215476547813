import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";
import {Product} from "../../../products/models/product.model";
import {Excess} from "../../models/excess.model";
import {Limit} from "../../models/limit.model";
import {BrokerIdentifier} from "../../identification/models/broker-identifier.model";
import {UnderwriterIdentifier} from "../../identification/models/underwriter-identifier.model";
import {InsuranceType} from "../../setup/models/insurance-type.model";
import {InsuranceVehicleCategory} from "../../setup/models/insurance-vehicle-category.model";
import {Money} from "../../../../financial/models/money.model";
import { PriceSchedule } from "../../models/price-schedule.model";
import { AgentIdentifier } from "../../../../entity/identification/models/agent-identifier.model";

export class ThirdPartyInsuranceProduct extends Product {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsuranceProduct";
    }

    public brokerIdentifier: BrokerIdentifier;
    public conditions: string;
    public excess: Excess[]=[];
    public limit: Limit[]=[];
    public coveredCountries: CountryIdentifier[]=[];
    public underwriterIdentifier: UnderwriterIdentifier;
    public insuranceType: InsuranceType;
    public insuranceVehicleCategory: InsuranceVehicleCategory;
    public priceSchedules: PriceSchedule[] = [];
    public agentIdentifiers: AgentIdentifier[] = [];
    public annualAmount: Money;
}
