import { Response } from '../../../../../../../urdad/services/models/response.model';

export class FinaliseSyncVehicleResponse extends Response{

    constructor() {
        super();
    }

    public retailProfileOwnerIdentifier: string;
    public syncedVehicles: number;
}
