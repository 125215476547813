import { OneTimePinIdentifier } from "./one-time-pin-identifier.model";

export class OneTimePin
{
  constructor(){
    this['@class'] = "za.co.magnabc.tpi.security.onetimepin.OneTimePin";
  }

  public pin: string;
  public oneTimePinIdentifier: OneTimePinIdentifier;
  public expiryDate: Date;

}
