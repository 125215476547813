import { VehicleIdentifier } from "./vehicle-identifier.model";

export class TpiVehicleIdentifier extends VehicleIdentifier {
    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.vehicle.identification.TpiVehicleIdentifier";
    }

    public number: string;

}