import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'ngx-loading';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InsuranceTypeSelectorComponent } from './insurance-type-selector.component';
import { NgModule } from '@angular/core';
import { InsuranceTypeCardComponent } from './insurance-type-card/insurance-type-card/insurance-type-card.component';

@NgModule({
    declarations: [
        InsuranceTypeSelectorComponent,
        InsuranceTypeCardComponent
    ],
    imports: [
        TranslateModule,
        RouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        LoadingModule,
        SharedModule,
    ],
    exports: [
        InsuranceTypeSelectorComponent,
        InsuranceTypeCardComponent
    ]
})
export class InsuranceTypeSelectorModule { }
