import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {PublishEventRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
  })
export class EventPublisherService {

    basePath = '/events/eventpublisher';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    publishEvent(publishEventRequest: PublishEventRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/publishevent';
        return this.http.post(path, publishEventRequest, this.options).toPromise();
    }

}
