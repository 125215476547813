import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {Duration, DurationType, PriceSchedule} from '@magnabc/tpi';
import moment from 'moment'

@Pipe({name: 'productPriceScheduleDuration'})
export class ProductPriceScheduleDurationPipe implements PipeTransform {

    static MONTHS_IN_YEAR = 12;
    static DAYS_IN_YEAR = 365;

    private startDate = moment().startOf('day')

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: PriceSchedule, startDate: any = null): string {

        if (startDate) {
            this.startDate = moment(startDate)
        }

        if (value.durationType === DurationType.EXACT) {
            /**
             * TPI-6138
             * Display of coverage period when there are months and days
            */
            if(value.exactDuration.months && value.exactDuration.days) {
                let durationMonths = this.checkMonths(value.exactDuration.months) + ' ' + this.checkDays(value.exactDuration.days);
                return durationMonths;
            }
            if (value.exactDuration.days) {
                if (value.exactDuration.days === 1) {
                    return `${this.convertToDays(value.exactDuration)} Day`
                }
                return `${this.convertToDays(value.exactDuration)} Days`
            }
            if (value.exactDuration.months) {
                if (value.exactDuration.months === 1) {
                    return `${this.convertToMonths(value.exactDuration)} Month`
                }
                return `${this.convertToMonths(value.exactDuration)} Months`
            }
            if (value.exactDuration.years) {
                if (value.exactDuration.years === 1) {
                    return `${this.convertToYears(value.exactDuration)} Year`
                }
                return `${this.convertToYears(value.exactDuration)} Years`
            }
        } else if (value.durationType === DurationType.INTERVAL) {
            if(value.intervalDurationFrom || value.intervalDurationTo) {
                let durationMonths = '';
                if (value.intervalDurationFrom.days) {
                    durationMonths += this.checkInterval(value.intervalDurationFrom.months, value.intervalDurationFrom.days);
                } else {
                    durationMonths += this.checkMonths(value.intervalDurationFrom.months);
                }

                if (value.intervalDurationTo.days) {
                    durationMonths += this.checkFromIsAvailable(value.intervalDurationFrom) + this.checkInterval(value.intervalDurationTo.months, value.intervalDurationTo.days)
                } else {
                    durationMonths += this.checkFromIsAvailable(value.intervalDurationFrom) + this.checkMonths(value.intervalDurationTo.months);
                }
                return durationMonths;
            }

            if (value.intervalDurationFrom.days || value.intervalDurationTo.days) {
                return `${this.convertToDays(value.intervalDurationFrom)} to ${this.convertToDays(value.intervalDurationTo)} Days`
            }
            if (value.intervalDurationFrom.months || value.intervalDurationTo.months) {
                return `${this.convertToMonths(value.intervalDurationFrom)} to ${this.convertToMonths(value.intervalDurationTo)} Months`
            }
            if (value.intervalDurationFrom.years || value.intervalDurationTo.years) {
                return `${this.convertToYears(value.intervalDurationFrom)} to ${this.convertToYears(value.intervalDurationTo)} Years`
            }
        }

    }

    checkFromIsAvailable(intervalDurationFrom): string {
        if (intervalDurationFrom.days !== 0 || intervalDurationFrom.months !== 0) {
            return ' to <br/>';
        }
        return '0  to <br/>';
    }

    /**
     * Checks interval
     * @param months selected months
     * @param days selected months
     * @returns months + days
     */
    checkInterval(months, days) {
        return this.checkMonths(months) + ' ' + this.checkDays(days);
    }

    /**
     * Checks months
     * @param months
     * @returns number of month/s
     */
    checkMonths(months) {
        if (months !== 0) {
            if (months === 1) {
                return `${months} Month`
            }
            return `${months} Months`
        }
        return ''
    }

    /**
     * Checks days
     * @param days
     * @returns  number of day/s
     */
    checkDays(days) {
        if (days !== 0) {
            if (days === 1) {
                return `${days} Day`
            }
            return `${days} Days`
        }
        return ''
    }

    convertToDays(duration: Duration): number {
        const years = duration.years ? duration.years : 0;
        const months = duration.months ? duration.months : 0;
        const days = duration.days ? duration.days : 0;
        const difference = this.startDate.diff(moment().startOf('day').add(years, 'years').add(months, 'months').add(days, 'days'), 'days', false)
        return Math.abs(difference)
        // return (years * ProductPriceScheduleDurationPipe.DAYS_IN_YEAR) + (months * ProductPriceScheduleDurationPipe.DAYS_IN_MONTH) + days;
    }

    convertToMonths(duration: Duration): number {
        const years = duration.years ? duration.years : 0;
        const months = duration.months ? duration.months : 0;
        const difference = this.startDate.diff(moment().startOf('day').add(years, 'years').add(months, 'months'), 'months', false)
        return Math.abs(difference)
    }

    convertToYears(duration: Duration): number {
        return duration.years ? duration.years : 0;
    }

}
