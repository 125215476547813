import {Response} from '../../../../../../../urdad/services/models/response.model';
import {InsurancePolicyItemGrouping} from '../../../../../models/insurance-policy-item-grouping.model';


export class ProvideInsurancePolicyItemResponse extends Response{

    constructor(){
        super();
    }
    
    public resultsFrom: number;
    public resultsTo : number;
    public totalNumberOfResults : number;
    public insurancePolicyItemGroupings: InsurancePolicyItemGrouping[] = [];

}
