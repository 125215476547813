import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProposalPolicyItem, ScannedDocument } from '@magnabc/tpi';

@Component({
  selector: 'app-vehicle-image-dialog',
  templateUrl: './vehicle-image-dialog.component.html',
  styleUrls: ['./vehicle-image-dialog.component.scss']
})
export class VehicleImageDialogComponent implements OnInit {

  proposalPolicyItem: ProposalPolicyItem;
  scannedDocuments:  ScannedDocument []= [];
  plateNumber : string
  slides = []

  constructor(public dialogRef: MatDialogRef<VehicleImageDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) result ) 
  {
      this.slides = result.scannedDocuments
      this.plateNumber = result.plateNumber
  }

  ngOnInit() {
   
  }

  onClose() {
    this.dialogRef.close();
  }

}
