import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    CustomerInvoiceSummary,
    InvoiceDateLegalEntityCriteria,
    PdfDocument,
    ProvideCustomerInvoiceSummaryRequest,
    ProvideCustomerInvoiceSummaryResponse,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    RenderedDocumentIdCriteria,
    RenderedDocumentType,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActiveToast } from 'ngx-toastr';

import { environment } from '../../../../../environments/environment';
import {
    RenderedConceptualDocumentProviderService,
} from '../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service';
import {
    CustomerInvoiceInformationProviderService,
} from '../../../../http.services/retail/invoice/customer-invoice-information-provider/customer-invoice-information-provider.service';
import { AuthenticationService } from '../../../../app.services/managers/authentication/authentication.service';
import { FormComponent } from '../../../shared/form/form.component';
import { Utils } from '../../../shared/Utils/Utils';
import { PrintService, PDFPrintModel, PDFDisplayModel } from '../../../../app.services/common/printing/print.service';

declare const jQuery: any;
@Component({
    selector: 'app-customer-invoice-list',
    templateUrl: './customer-invoices.component.html',
    styleUrls: ['./customer-invoices.component.scss']
})
export class CustomerInvoicesComponent extends FormComponent implements OnInit {

    rows: CustomerInvoiceSummary[] = [];
    columns = [
        { duty: 'duty'},
        { invoiceNumber: 'invoiceNumber' },
        { orderDate: 'orderDate' },
        { orderNumber: 'orderNumber' },
        { subTotal: 'subTotal' },
        { totalDue: 'totalDue' },
        { vat: 'vat' }
    ];

    totalElements = 0;
    pageNumber = 0;
    pageSize = 8;
    selectedStatus = [];
    numberOfPages: number;

    activeToast: ActiveToast<any>;
    temp: CustomerInvoiceSummary[] = [];

    from;
    to;

    previewDocument = false;
    documentToPreview: any;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private router: Router,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private customerInvoiceInformationProviderService: CustomerInvoiceInformationProviderService,
                private printService: PrintService) {
        super(translate, translateParser);

        this.from = moment().startOf('month').startOf('day');
        this.to = moment();

    }

    ngOnInit() {
        this.provideInvoices();
    }

    provideInvoices() {

        const criteria = new InvoiceDateLegalEntityCriteria();
        criteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
        criteria.dateFrom = this.from.startOf('day').format(environment.formatDateServer) as Date;
        criteria.dateTo = this.to.endOf('day').format(environment.formatDateServer) as Date;

        const provideCustomerInvoiceSummaryRequest = new ProvideCustomerInvoiceSummaryRequest();
        provideCustomerInvoiceSummaryRequest.batchNumber = this.pageNumber + 1;
        provideCustomerInvoiceSummaryRequest.batchSize = this.pageSize;
        provideCustomerInvoiceSummaryRequest.criteria = criteria;

        this.startLoad();
        this.customerInvoiceInformationProviderService.provideCustomerInvoiceSummary(provideCustomerInvoiceSummaryRequest).subscribe((data) => {
            const response: ProvideCustomerInvoiceSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideCustomerInvoiceSummaryResponse);
            this.stopLoad();
            this.rows = response.customerInvoiceSummaries;

            this.temp = this.rows;
            this.rows = [...this.rows];

            this.totalElements = response.totalNumberOfResults;

        });
    }

    getProvideInvoicesOnClick() {
        this.pageNumber = 0;
        this.provideInvoices();
    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideInvoices();
    }

    onStatus(status): boolean {
        if (this.selectedStatus.indexOf(status) > -1) {
            this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        } else {
            this.selectedStatus.push(status);
        }
        this.provideInvoices();
        return false;
    }

    onSelectionChanged() {
        this.provideInvoices();
    }

    isStatusSelected(status): boolean {
        return this.selectedStatus.indexOf(status) > -1;
    }

    onSelect(row) {
        // DO NOTHING FOR NOW
    }

    onPreview(event, customerInvoice: CustomerInvoiceSummary): boolean {

        if (event) {
            event.stopPropagation();
        }

        this.startLoad();
        this.getRenderedDocument(customerInvoice, (pdfDocument) => {
            this.stopLoad();
            if(pdfDocument.type === RenderedDocumentType.CUSTOMER_INVOICE){
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, () => {});
            });
        });

        return false;

    }

    getRenderedDocument(customerInvoice: CustomerInvoiceSummary, callback): void {

        const renderedDocumentIdCriteria = new RenderedDocumentIdCriteria();
        renderedDocumentIdCriteria.documentId = customerInvoice.invoiceNumber;
        renderedDocumentIdCriteria.userID = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentIdCriteria;

        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback((provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument));
            } else {
                callback({});
            }

        });

    }

    onPrintPreview(): void {
        this.displayPDF(this.documentToPreview, () => {
            setTimeout(() => {
                this.printDocument();
            }, 100);
        });
    }

    displayPDF(base64, complete) {
        const canvasId = `#canvas`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numberOfPages = numPages;
        });
    }

    printDocument(): void {
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId: 'canvas',
            features: 'top=0,height=1024px,width=800px',
            openBlank: false
        };
        const printWin = this.printService.printDocument(pdfPrintData);

        setTimeout(() => {
            printWin.print();
        }, 1000);
    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPreview = null;

    }

}
