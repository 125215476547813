import {
  YCMotorVehicleInsuranceDetails
} from '../../../retail/insurance/yellowcardinsurance/models/yc-motor-vehicle-insurance-details.model'
import {RenderedDocumentTypeIdCriteria} from '../../../rendering/models/rendered-document-type-id-criteria.model'
import {
  InitialiseExtensionValidationResponse
} from '../../../retail/insurance/services/extensionvalidationinitialisationmanager/models/response/initialise-extension-validation-response.model'
import {
  InitialiseExtensionValidationRequest
} from '../../../retail/insurance/services/extensionvalidationinitialisationmanager/models/request/initialise-extension-validation-request.model'

import {VehicleInsurancePolicy} from '../../../retail/insurance/models/vehicle-insurance-policy.model'
import {
  ProvideCountryIdentifiersRequest
} from '../../../location/geographic/identification/services/countryidentifierprovider/providecountryidentifiers/request/models/provide-country-identifiers-request.model'
import {Injectable} from "@angular/core"
import {Exception, RequestNotValidException, ServiceProviderEvent, UnexpectedServiceError} from "../../urdad_api"
import {
  YCSellingCountryRule,
  ProvideCountrySellingRuleRequest,
  ProvideCountrySellingRuleResponse
} from "../../../retail/insurance/yellowcardinsurance/yellowcardinsurance_api"
import {Criteria} from "../../../criteria/criteria_api"
import {
  ConceptualDocument,
  ConceptualDocumentIdCriteria,
  ConceptualDocumentTypeIdCriteria,
  ProvideConceptualDocumentRequest,
  ProvideConceptualDocumentResponse
} from "../../../conceptualdocument/conceptualdocument_api"
import {
  Category,
  Engine,
  EngineNumber,
  FinaliseCreateVehicleMakeRequest,
  FinaliseCreateVehicleMakeResponse,
  FinaliseCreateVehicleModelRequest,
  FinaliseCreateVehicleModelResponse,
  FinaliseDeleteVehicleMakeRequest,
  FinaliseDeleteVehicleMakeResponse,
  FinaliseDeleteVehicleModelRequest,
  FinaliseDeleteVehicleModelResponse,
  FinaliseMaintainVehicleInformationRequest,
  FinaliseMaintainVehicleInformationResponse,
  FinaliseUpdateVehicleMakeRequest,
  FinaliseUpdateVehicleMakeResponse,
  FinaliseUpdateVehicleModelRequest,
  FinaliseUpdateVehicleModelResponse,
  FuelType,
  MakeDescriptionIdentifier,
  ModelDescriptionIdentifier,
  PlateNumber,
  ProvideCategoryIdentifiersRequest,
  ProvideCategoryIdentifiersResponse,
  ProvideUsageIdentifiersRequest,
  ProvideUsageIdentifiersResponse,
  ProvideVehicleInformationRequest,
  ProvideVehicleInformationResponse,
  ProvideVehicleMakesRequest,
  ProvideVehicleMakesResponse,
  ProvideVehicleModelsRequest,
  ProvideVehicleModelsResponse,
  QueryVehicleInformationRequest,
  QueryVehicleInformationResponse,
  TpiVehicleIdentifier,
  Transmission,
  UpdateVehicleMakeAlreadyExistsException,
  UpdateVehicleModelAlreadyExistsException,
  Usage,
  UsagesCriteria,
  Vehicle,
  VehicleCertificate,
  VehicleCriteria,
  VehicleDescriptionIdentifier,
  VehicleIdentificationNumber,
  VehicleIdentifier,
  VehicleMakeAlreadyExistsException,
  VehicleMakeDoesNotExistException,
  VehicleModelAlreadyExistsException,
  VehicleModelDoesNotExistException,
  VehiclePlateNumber,
  VehicleProviderCriteria,
  VehicleRegisterNumber,
  VehicleRegistration
} from "../../../vehicle/vehicle_api"
import {RegistrationNumber} from "../../../entity/identification/judicial-person/models/registration-number.model"
import {TaxNumber} from "../../../entity/identification/natural-person/models/tax-number.model"
import {
  CreateIdentifierRequest,
  CreateIdentifierResponse,
  GenerateIdentifierRequest,
  GenerateIdentifierResponse,
  Identifier
} from "../../../identification/identification_api"
import {
  Address,
  AlphaThree,
  AlphaTwo,
  BasicPhysicalAddress,
  BasicPostalAddress,
  CheckUpdateCountryIdentifiersRequest,
  CheckUpdateCountryIdentifiersResponse,
  CityTown,
  CityTownDescriptionIdentifier,
  CityTownIdentifier,
  CityTownIdentifierTypeCriteria,
  Country,
  CountryDescriptionIdentifier,
  CountryDialingIdentifier,
  CountryIdentifier,
  CountryIdentifierTypeCriteria,
  FindCityTownIdentifierRequest,
  FindCityTownIdentifierResponse,
  GenericAddress,
  Locality,
  ProvideCountryIdentifiersResponse
} from "../../../location/location_api"
import {GpsLocation} from "../../../location/geographic/models/gps-location.model"
import {MalawiPhysicalAddress} from "../../../location/geographic/models/malawi-physical-address.model"
import {
  Image,
  ProvideScannedDocumentRequest,
  ProvideScannedDocumentResponse,
  ScannedDocumentIdentifier
} from "../../../media/media_api"
import {Metadata} from "../../../metadata/metadata_api"
import {Event, PublishEventRequest, PublishEventResponse, ServiceEvent} from "../../events/events_api"
import {
  RenderDocumentResponse
} from "../../../rendering/services/renderer/response/models/render-document-response.model"
import {PdfDocument} from "../../../rendering/models/pdf-document.model"
import {RenderedDocument} from "../../../rendering/models/rendered-document.model"
import {
  AuthenticateCredentialsResponse
} from "../../../security/services/authenticator/authenticate-credentials/authenticate-credentials-response/models/authenticate-credentials-response.model"
import {Token} from "../../../security/token/token.model"
import {AuthenticationAdvice} from "../../../security/models/authentication-advice.model"
import {TextualElaboration} from "../../../security/models/textual-elaboration.model"
import {TokenElaboration} from "../../../security/models/token-elaboration.model"
import {VehicleLicence} from "../../../vehicle/models/vehicle-licence.model"
import {
  CountryTaxes,
  CountryTaxesCountryCriteria,
  Money,
  OrderReferenceNumber,
  Payment,
  PaymentIdentifier,
  PaymentReference,
  ProvideCountryTaxesInformationRequest,
  ProvideCountryTaxesInformationResponse,
  RetrieveExchangeRateRequest,
  RetrieveExchangeRateResponse
} from "../../../financial/financial_api"
import {NaturalPerson} from '../../../entity/models/natural-person.model'
import {
  CompileAccountRequest
} from '../../../security/account/services/accountcompiler/compileaccount/request/models/compile-account-request.model'
import {
  CompileAccountResponse
} from '../../../security/account/services/accountcompiler/compileaccount/response/models/compile-account-response.model'
import {Subscription} from '../../../security/authorisation/models/subscription.model'
import {
  ServiceGroupLegalEntityCriteria
} from '../../../security/authorisation/models/service-group-legal-entity-criteria.model'
import {
  SubscriptionAllAccessCriteria
} from '../../../security/authorisation/models/subscription-all-access-criteria.model'
import {AccountCriteria} from '../../../security/account/models/account-criteria.model'
import {AccountNumberIdentifier} from '../../../security/account/identification/models/account-number-identifier.model'
import {AccountIdentifier} from '../../../security/account/identification/models/account-identifier.model'
import {
  AccountAlreadyVerifiedException
} from '../../../security/account/services/accountlinkverifier/preconditions/models/account-already-verified-exception.model'
import {
  TokenExpiredException
} from '../../../security/account/services/accountlinkverifier/preconditions/models/token-expired-exception.model'
import {
  TokenNotFoundException
} from '../../../security/account/services/accountlinkverifier/preconditions/models/token-not-found-exception.model'
import {
  VerifyAccountLinkRequest
} from '../../../security/account/services/accountlinkverifier/verifyaccountlink/request/models/verify-account-link-request.model'
import {
  VerifyAccountLinkResponse
} from '../../../security/account/services/accountlinkverifier/verifyaccountlink/response/models/verify-account-link-response.model'
import {Email} from '../../../email/models/email.model'
import {
  SendEmailResponse
} from '../../../email/services/emailsender/sendemail/response/models/send-email-response.model'
import {SendEmailRequest} from '../../../email/services/emailsender/sendemail/request/models/email-sender.model'
import {
  NoRecipientException
} from '../../../email/services/emailsender/sendemail/preconditions/models/no-recipient-exception.model'
import {OneTimeLinkContext} from '../../../security/onetimelink/models/one-time-link-context.model'
import {
  DetermineOneTimeLinkIssueCountRequest
} from '../../../security/onetimelink/services/onetimelinkcountdeterminer/determineonetimelinkissuecount/request/models/determine-one-time-link-issue-count-request.model'
import {
  DetermineOneTimeLinkIssueCountResponse
} from '../../../security/onetimelink/services/onetimelinkcountdeterminer/determineonetimelinkissuecount/response/models/determine-one-time-link-issue-count-response.model'
import {
  IssueOneTimeLinkRequest
} from '../../../security/onetimelink/services/onetimelinkissuancemanager/issueonetimelink/request/models/issue-one-time-link-request.model'
import {
  IssueOneTimeLinkResponse
} from '../../../security/onetimelink/services/onetimelinkissuancemanager/issueonetimelink/response/models/issue-one-time-link-response.model'
import {
  IssueOnetimeLinkLimitReachedException
} from '../../../security/onetimelink/services/onetimelinkissuancemanager/issueonetimelink/preconditions/models/issue-one-time-link-limit-reached-exception.model'
import {TokenCriteria} from '../../../security/token/models/token-criteria.model'
import {IdentifierToken} from '../../../security/token/models/identifier-token.model'
import {TokenGenerateCriteria} from '../../../security/token/models/token-generate-criteria.model'
import {
  GenerateIdentifierTokenResponse
} from '../../../security/token/services/identifiertokengenerator/generateidentifiertoken/response/models/generate-identifier-token-response.model'
import {IdentifierTokenIdentifier} from '../../../security/token/models/identifier-token-identifier.model'
import {
  GenerateIdentifierTokenRequest
} from '../../../security/token/services/identifiertokengenerator/generateidentifiertoken/request/models/generate-identifier-token-request.model'
import {
  ServiceNameTypeIdentifier
} from '../../../security/authorisation/identification/models/service-name-type-identifier.model'
import {
  CheckUpdateServiceIdentifiersRequest
} from '../../../security/authorisation/identification/services/serviceidentifierprovider/checkupdateserviceidentifiers/request/models/check-update-service-identifiers-request.model'
import {
  CheckUpdateServiceIdentifiersResponse
} from '../../../security/authorisation/identification/services/serviceidentifierprovider/checkupdateserviceidentifiers/response/models/check-update-service-identifiers-response.model'
import {
  ServiceIdentifierTypeCriteria
} from '../../../security/authorisation/identification/models/service-identifier-type-criteria.model'
import {
  ProvideServiceGroupsRequest
} from '../../../security/authorisation/services/servicegroupinformationprovider/provideservicegroups/request/models/provide-service-groups-request.model'
import {
  ProvideServiceGroupsResponse
} from '../../../security/authorisation/services/servicegroupinformationprovider/provideservicegroups/response/models/provide-service-groups-response.model'
import {
  ProvideServiceIdentifiersRequest
} from '../../../security/authorisation/identification/services/serviceidentifierprovider/provideserviceidentifiers/request/models/provide-service-identifiers-request.model'
import {
  ProvideServiceIdentifiersResponse
} from '../../../security/authorisation/identification/services/serviceidentifierprovider/provideserviceidentifiers/response/models/provide-service-identifiers-response.model'
import {
  ProvideSubscriptionsRequest
} from '../../../security/authorisation/services/subscriptioninformationprovider/providesubscriptions/request/models/provide-subscriptions-request.model'
import {
  ProvideSubscriptionsResponse
} from '../../../security/authorisation/services/subscriptioninformationprovider/providesubscriptions/response/models/provide-subscriptions-response.model'
import {
  SubscriptionsServiceGroupTypeLegalEntityCriteria
} from '../../../security/authorisation/models/subscriptions-service-group-type-legal-entity-criteria.model'
import {
  SubscriptionUsedByLegalEntityCriteria
} from '../../../security/authorisation/models/subscription-used-by-legal-entity-criteria.model'
import {
  SubscriptionOwnedByLegalEntityCriteria
} from '../../../security/authorisation/models/subscription-owned-by-legal-entity-criteria.model'
import {
  ServiceGroupNameTypeCriteria
} from '../../../security/authorisation/models/service-group-name-type-criteria.model'
import {
  ProvideServiceGroupIdentifierResponse
} from '../../../security/authorisation/identification/services/servicegroupidentifierprovider/provideservicegroupidentifier/response/models/provide-service-group-identifier-response.model'
import {
  ProvideServiceGroupIdentifierRequest
} from '../../../security/authorisation/identification/services/servicegroupidentifierprovider/provideservicegroupidentifier/request/models/provide-service-group-identifier-request.model'
import {
  CheckUpdateServiceGroupIdentifiersRequest
} from '../../../security/authorisation/identification/services/servicegroupidentifierprovider/checkupdateservicegroupidentifiers/request/models/check-update-service-group-identifiers-request.model'
import {
  CheckUpdateServiceGroupIdentifiersResponse
} from '../../../security/authorisation/identification/services/servicegroupidentifierprovider/checkupdateservicegroupidentifiers/response/models/check-update-service-group-identifiers-response.model'
import {
  ServiceGroupNameIdentifier
} from '../../../security/authorisation/identification/models/service-group-name-identifier.model'
import {
  VerifyAccountAndCreatePasswordRequest
} from '../../../security/account/services/accountandpasswordmanager/verifyaccountandcreatepassword/request/models/verify-account-and-create-password-request.model'
import {
  VerifyAccountAndCreatePasswordResponse
} from '../../../security/account/services/accountandpasswordmanager/verifyaccountandcreatepassword/response/models/verify-account-and-create-password-response.model'
import {
  NoAuthorityControlCentreOrCourtFoundException
} from '../../../security/account/services/useraccountmanager/preconditions/models/no-authority-control-centre-or-court-found-exception.model'

import {
  AddExtensionToCartRequest,
  AddExtensionToCartResponse,
  AdditionalDriversAnswers,
  AdditionalDriversQuestions,
  AddToCartRequest,
  AddYCToCartRequest,
  AddYCToCartResponse,
  Agent,
  AllInsuranceTypeCriteria,
  AllowablePrintsExceededException,
  Answers,
  AssessProposalRequest,
  AssessProposalResponse,
  BrokerUnderwriterCriteria,
  CalculateRealTimeYCPriceRequest,
  CalculateRealTimeYCPriceResponse,
  CaptureProposalResultRequest,
  CaptureProposalResultResponse,
  CertificateIdentifier,
  CertificateNumberIdentifier,
  CheckoutProposalRequest,
  CheckoutProposalResponse,
  ClaimsHistoryAnswers,
  ClaimsHistoryQuestions,
  ComprehensiveInsuranceCartItem,
  ComprehensiveInsurancePolicyCartItem,
  ComprehensiveInsurancePolicyOrderItem,
  ComprehensiveInsuranceProduct,
  ComprehensiveInsuranceProductCartItem,
  CountryIdentificationConfiguration,
  CountryIdentificationConfigurationCriteria,
  CountryRegistrationConfiguration,
  CountryStartDateConfiguration,
  CountryStartDateConfigurationCriteria,
  DuplicateInsuranceCertificateCreatedEvent,
  Excess,
  ExtensionInsuranceOrderItem,
  ExtensionInsurancePolicyCartItem,
  ExtensionInsurancePolicyOrderItem,
  ExtensionInsuranceProductCartItem,
  FinaliseCancelInsurancePolicyRequest,
  FinaliseCancelInsurancePolicyResponse,
  FinaliseCreateInsuranceProductRequest,
  FinaliseCreateYCInsuranceProductRequest,
  FinaliseCreateYCInsuranceProductResponse,
  FinaliseInsuranceProductResponse,
  FinaliseIssueInsuranceCertificateRequest,
  FinaliseIssueInsuranceCertificateResponse,
  FinaliseProposalAssessmentRequest,
  FinaliseProposalAssessmentResponse,
  FinaliseUpdateInsuranceProductRequest,
  FinaliseUpdateInsuranceProductResponse,
  FindProposalGroupEntryRequest,
  FindProposalGroupEntryResponse,
  FindProposalRecordRequest,
  FindProposalRecordResponse,
  FindProposalRecordSummaryRequest,
  FindProposalRecordSummaryResponse,
  FindProposalRequest,
  FindProposalResponse,
  InitialiseCancelInsurancePolicyRequest,
  InitialiseCancelInsurancePolicyResponse,
  InitialiseExtendInsurancePolicyRequest,
  InitialiseExtendInsurancePolicyResponse,
  InitialiseProposalAssessmentRequest,
  InitialiseProposalAssessmentResponse,
  InsuranceCartItem,
  InsuranceCertificateCreatedEvent,
  InsuranceCertificateEvent,
  InsuranceCertificateNumberCriteria,
  InsuranceCertificatePolicyNumberCriteria,
  InsuranceHistoryAnswers,
  InsuranceHistoryQuestions,
  InsuranceLevy,
  InsurancePolicyAlreadyExpiredException,
  InsurancePolicyAmendmentItem,
  InsurancePolicyItemAlreadyExpiredException,
  InsurancePolicyLegalEntityCriteria,
  InsurancePolicyNumberCriteria,
  InsurancePolicyVehicleCategoryCriteria,
  InsurancePolicyVehicleCriteria,
  InsuranceProductBrokerCriteria,
  InsuranceProductCartItem,
  InsuranceProductUnderwriterCriteria,
  InsuranceType,
  InsuranceTypeBrokerCriteria,
  InsuranceCategoryCriteria,
  InsuranceVehicleCategory,
  InsuranceVehicleCategoryClassification,
  InsuranceVehicleCategorySummary,
  LimitInterval,
  MotorInsurancePolicy,
  MotorInsurancePolicyItem,
  MotorInsuranceVehiclePolicy,
  NoPrintConfigurationFoundException,
  NotAllProposalPolicyItemsAssessedException,
  orderExtensionInsuranceProduct,
  OrderYCInsuranceProduct,
  PickUpPoint,
  PolicyWording,
  PolicyWordingUnderwriterCategoryCriteria,
  PriceSchedule,
  Product,
  ProductCriteria,
  ProductIdentifier,
  ProductNumberIdentifier,
  Proposal,
  ProposalCriteria,
  ProposalForm,
  ProposalFormCountryCriteria,
  ProposalFormCriteria,
  ProposalGroupEntry,
  ProposalGroupEntryAssessorCriteria,
  ProposalGroupEntryProposalCriteria,
  ProposalPolicyItem,
  ProposalRecord,
  ProposalRecordStatusCriteria,
  ProposalRecordStatusLegalEntityCriteria,
  ProposalRecordSummary,
  ProposalRecordSummaryDateCriteria,
  ProposalRecordSummaryLegalEntityCriteria,
  ProposalRecordSummaryStatusCriteria,
  ProposalResult,
  ProposalSummary,
  ProvideAgentBrokersProductsRequest,
  ProvideAgentBrokersProductsResponse,
  ProvideAgentInformationRequest,
  ProvideAgentInformationResponse,
  ProvideBrokerInformationRequest,
  ProvideBrokerInformationResponse,
  ProvideCountryStartDateConfigurationRequest,
  ProvideCountryStartDateConfigurationResponse,
  ProvideCountryUnderwriterProductInformationRequest,
  ProvideCountryUnderwriterProductInformationResponse,
  ProvideCoveredCountriesRequest,
  ProvideCoveredCountriesResponse,
  ProvideInsuranceCertificatesRequest,
  ProvideInsuranceCertificatesResponse,
  ProvideInsurancePoliciesRequest,
  ProvideInsurancePoliciesResponse,
  ProvideInsuranceProductInformationRequest,
  ProvideInsuranceProductInformationResponse,
  ProvideInsuranceTypeRequest,
  ProvideInsuranceTypeResponse,
  ProvidePolicyWordingRequest,
  ProvidePolicyWordingResponse,
  ProvideProposalInformationRequest,
  ProvideProposalInformationResponse,
  ProvideProposalSummaryRequest,
  ProvideProposalSummaryResponse,
  ProvidePublicInsurancePolicyInformationRequest,
  ProvidePublicInsurancePolicyInformationResponse,
  ProvidePublicMotorInsuranceVehiclePolicyRequest,
  ProvidePublicMotorInsuranceVehiclePolicyResponse,
  ProvideRefundSummaryRequest,
  ProvideRefundSummaryResponse,
  ProvideRetailProfileProposalSummaryRequest,
  ProvideRetailProfileProposalSummaryResponse,
  ProvideSuitableInsurancePolicyRequest,
  ProvideSuitableInsurancePolicyResponse,
  ProvideUnderwriterInformationRequest,
  ProvideUnderwriterInformationResponse,
  ProvideUnderwriterWhiteLabelInformationRequest,
  ProvideUnderwriterWhiteLabelInformationResponse,
  PublicInsurancePolicySummary,
  QueryInsurancePolicyInformationRequest,
  QueryInsurancePolicyInformationResponse,
  QueryPublicInsurancePolicyInformationRequest,
  QueryPublicInsurancePolicyInformationResponse,
  Questions,
  RefundPolicyNumberCriteria,
  RefundSummary,
  ReleaseProposalRequest,
  ReleaseProposalResponse,
  ReportLegalEntityDateCriteria,
  RequiredJudicialPersonCountryRegistrationInformation,
  RequiredNaturalPersonCountryRegistrationInformation,
  RetailProfileVehicleInsurancePolicyCriteria,
  RoleRevenue,
  SetupLimitType,
  ShownJudicialPersonCountryRegistrationInformation,
  ShownNaturalPersonCountryRegistrationInformation,
  ThirdPartyInsuranceCartItem,
  ThirdPartyInsuranceOrderItem,
  ThirdPartyInsurancePolicyCartItem,
  ThirdPartyInsurancePolicyOrderItem,
  ThirdPartyInsuranceProduct,
  ThirdPartyInsuranceProductCartItem,
  Underwriter,
  UnderwriterIdentifier,
  UnderwriterIdentifierIdentifier,
  UnderwriterLegalEntityCriteria,
  UnderwriterLegalEntityIdentifier,
  UnderwriterParameters,
  VehicleEvaluation,
  VehicleInformationAnswers,
  VehicleInformationQuestions,
  YCInsuranceOrderItem,
  YCInsurancePolicyOrderItem,
  YCInsuranceProduct,
  YCMotorInsuranceDetails,
  YCPriceInterval,
  YCPriceSchedule,
  CoveredCountries,
  UnderwriterCountryCriteria,
  ProvideProposalRecordSummaryRequest,
  ProvideProposalRecordSummaryResponse
} from '../../../retail/retail_api'

import {Limit} from '../../../retail/insurance/models/limit.model'
import {Sms} from '../../../sms/models/sms.model'
import {SendSmsRequest} from '../../../sms/services/smssender/request/models/send-sms-request.model'
import {SendSmsResponse} from '../../../sms/services/smssender/response/models/send-sms-response.model'
import {AgentIdentifierIdentifier} from '../../../entity/identification/models/agent-identifier-identifier.model'
import {AgentLegalEntityIdentifier} from '../../../entity/identification/models/agent-legal-entity-identifier.model'
import {MotorInsuranceCertificate} from '../../../retail/insurance/certificate/models/motor-insurance-certificate.model'
import {
  ProvideSecuredLegalEntityInformationRequest
} from '../../../entity/services/securelegalentityinformationmanager/provideSecuredLegalEntityInformation/request/models/provide-secured-legal-entity-information-request.model'
import {ContactNumberType} from '../../../entity/models/contact-number-type.model'
import {OneTimePinContext} from '../../../security/onetimepin/models/one-time-pin-context.model'
import {
  CaptureProposalRequest,
  CaptureProposalResponse,
  CaptureStartDateGroupingRequest,
  CaptureStartDateGroupingResponse,
  CaptureUpdatedStartDateGroupingRequest,
  CaptureUpdatedStartDateGroupingResponse,
  CartIsEmptyException,
  CheckoutDatedItemGrouping,
  CheckoutNonDatedItemGrouping,
  CompileCheckoutItemGroupingRequest,
  CompileCheckoutItemGroupingResponse,
  CountryCheckoutItemGrouping,
  CreateOrderRequest,
  CreateOrderResponse,
  CreateProposalRequest,
  CreateProposalResponse,
  FinaliseCheckoutOrderRequest,
  FinaliseCheckoutOrderResponse,
  FinaliseExtendCheckoutOrderRequest,
  FinaliseExtendCheckoutOrderResponse,
  InitialiseCheckoutRequest,
  InitialiseCheckoutResponse,
  Item,
  ItemIdentifier,
  ItemNumberIdentifier,
  LegalEntityOrderCriteria,
  MotorInsuranceDetails,
  MotorInsuranceLimitDetails,
  MotorInsuranceVehicleDetails,
  NoOrderFoundException,
  OrderIdentifier,
  OrderItem,
  OrderItemIdentifier,
  OrderItemNumberIdentifier,
  OrderItemOrderNumberIdentifier,
  OrderNumberIdentifier,
  OrderSummary,
  OrderSummaryLegalEntityCriteria,
  OrderSummaryRetailProfileCriteria,
  ProvideOrderInformationRequest,
  ProvideOrderInformationResponse,
  ProvideOrderRequest,
  ProvideOrderResponse,
  ProvideOrderSummaryRequest,
  ProvideOrderSummaryResponse,
  RetailProfileOrderDateCriteria,
  RetailProfileOrderNumberCriteria,
  RetailProfileOrderStatusCriteria,
  SnapshotVehicle,
  UnderwriterCheckoutItemGrouping,
  FinaliseYCCheckoutOrderRequest,
  FinaliseYCCheckoutOrderResponse,
  FinaliseCancelOrderRequest,
  FinaliseCancelOrderResponse
} from '../../../retail/checkout/checkout_api';
import { Order } from '../../../retail/checkout/models/order.model';
import {
  BankAccount,
  Card,
  CardPaymentOption,
  CardToken,
  CompanyToken,
  CustomerToken,
  FinaliseCashierPaymentRequest,
  FinaliseCashierPaymentResponse,
  PayfastDetails,
  PayfastSignatureRequest,
  PayfastSignatureResponse,
  ReceivePayfastPaymentRequest,
  ReceivePayfastPaymentResponse,
  PaymentAdvice,
  PaymentOption,
  PaymentProfile,
  PaymentProfileLegalEntityCriteria,
  ProcessCardPaymentRequest,
  ProcessCardPaymentResponse,
  ProcessTokenPaymentRequest,
  ProcessTokenPaymentResponse,
  ProvidePaymentProfileInformationRequest,
  ProvidePaymentProfileInformationResponse,
  RefundOrderItemNumberCriteria,
  RefundOrderItemNumberStatusCriteria,
  RemovePaymentOptionRequest,
  RemovePaymentOptionResponse,
  RetrieveBankTransferInformationRequest,
  RetrieveBankTransferInformationResponse,
  Services,
  Transaction,
  TransactionToken
} from '../../../retail/payment/payment_api'
import {RetailProfileIdentifierCriteria} from '../../../retail/profile/models/retail-profile-identifier-criteria.model'
import {
  Cart,
  CartDoesNotExistException,
  FinaliseClearCartRequest,
  FinaliseClearCartResponse,
  FinaliseImportFleetRequest,
  FinaliseImportFleetResponse,
  FinaliseLinkVehicleRequest,
  FinaliseLinkVehicleResponse,
  FinaliseSyncVehicleRequest,
  FinaliseSyncVehicleResponse,
  ImportFleetRequest,
  IncorrectDocumentFormat,
  InitialiseSyncVehicleRequest,
  InitialiseSyncVehicleResponse,
  FinaliseInsertRetailProfileRequest,
  FinaliseInsertRetailProfileResponse,
  InvalidDocumentStructureException,
  InvalidVehiclePlateNumber,
  ItemDoesNotExistException,
  ProvideOrderLegalEntityRequest,
  ProvideRetailProfileVehiclesRequest,
  ProvideRetailProfileVehiclesResponse,
  FinaliseRemoveItemRequest,
  FinaliseRemoveItemResponse,
  RetailProfile,
  RetailProfileAlreadyExistsException,
  RetailProfileDoesNotExistException,
  RetailProfileIdentifier,
  RetailProfileLegalEntityCriteria,
  RetailProfileVehicleLinkDoesNotExistException,
  RetailProfileVehicleLinkExistsException,
  RetailVehicle,
  FinaliseUpdateItemRequest,
  FinaliseUpdateItemResponse,
  FinaliseUpdateRetailProfileRequest,
  FinaliseUpdateRetailProfileResponse,
  ValidateImportFleetDocumentRequest,
  ValidateImportFleetDocumentResponse
} from '../../../retail/profile/profile_api'
import {
  RetailProfileOwnerIdentifier
} from '../../../retail/profile/identification/retail-profile-owner-identifier.model'
import {
  ProvideOrderLegalEntityResponse
} from '../../../retail/profile/service/retailprofileinformationprovider/provideorderlegalentity/response/models/provide-order-legal-entity-response.model'
import {
  ProvideRetailProfileInformationRequest
} from '../../../retail/profile/service/retailprofileinformationprovider/provideretailprofileinformation/request/models/provide-retail-profile-information-request.model'
import {
  ProvideRetailProfileInformationResponse
} from '../../../retail/profile/service/retailprofileinformationprovider/provideretailprofileinformation/response/models/provide-retail-profile-information-response.model'
import {
  ProvideRetailProfileVehicleInformationRequest
} from '../../../retail/profile/service/retailprofileinformationprovider/provideretailprofilevehicleinformation/request/models/provide-retail-profile-vehicle-information-request.model'
import {
  ProvideRetailProfileVehicleInformationResponse
} from '../../../retail/profile/service/retailprofileinformationprovider/provideretailprofilevehicleinformation/response/models/provide-retail-profile-vehicle-information-response.model'
import {Duration} from '../../../temporal/models/duration.model'
import {
  AccountingTransaction,
  AccountingTransactionAccountDateCriteria,
  AccountingTransactionIdentifier,
  AccountingTransactionReferenceCriteria,
  AccountingTransactionReferenceDateCriteria,
  AccountingTransactionReferenceIdentifier,
  AccountingTransactionReferenceLatestCriteria,
  AccountReportLegalEntityDateCriteria,
  AccountStatementReport,
  AccountStatementReportEntry,
  AllFinancialAccountCriteria,
  DepositBankTransfer,
  DepositMethod,
  DepositMethodBankTransferIdentifier,
  DepositMethodCountryCriteria,
  DepositMethodIdentifier,
  Entry,
  FinancialAccount,
  FinancialAccountCountryCriteria,
  FinancialAccountCountryLegalEntityCriteria,
  FinancialAccountCountryOnlyCriteria,
  FinancialAccountIdentifier,
  FinancialAccountLegalEntityCriteria,
  FinancialAccountNumberCountryLegalEntityIdentifier,
  FinancialAccountNumberCriteria,
  FinancialAccountNumberIdentifier,
  ImportOfxRequest,
  ImportOfxResponse,
  InsuranceSalesByProductEntry,
  InsuranceSalesByProductReport,
  InsuranceSalesByProductReportLegalEntityDateCriteria,
  InsuranceSalesBySalesChannelEntry,
  InsuranceSalesBySalesChannelReport,
  InsuranceSalesBySalesChannelReportLegalEntityDateCriteria,
  LegalEntityPayoutMethodCriteria,
  OfxEntry,
  OfxEntryLog,
  PayoutBankTransfer,
  PayoutMethod,
  PayoutMethodBankTransferIdentifier,
  PayoutMethodIdentifier,
  PopularProducts,
  PopularProductsLegalEntityCriteria,
  ProvideAccountingTransactionInformationRequest,
  ProvideAccountingTransactionInformationResponse,
  ProvideAccountStatementReportRequest,
  ProvideAccountStatementReportResponse,
  ProvideDepositMethodRequest,
  ProvideDepositMethodResponse,
  ProvideFinancialAccountInformationRequest,
  ProvideFinancialAccountInformationResponse,
  ProvideInsuranceSalesByProductReportRequest,
  ProvideInsuranceSalesByProductReportResponse,
  ProvideInsuranceSalesBySalesChannelReportRequest,
  ProvideInsuranceSalesBySalesChannelReportResponse,
  ProvidePayoutMethodRequest,
  ProvidePayoutMethodResponse,
  ProvidePopularProductsRequest,
  ProvidePopularProductsResponse,
  ProvideRevenueReportRequest,
  ProvideRevenueReportResponse,
  ProvideRoleRevenueRequest,
  ProvideRoleRevenueResponse,
  ProvideSalesSummaryRequest,
  ProvideSalesSummaryResponse,
  ProvideTransactionReportRequest,
  ProvideTransactionReportResponse,
  ReportDateCriteria,
  RevenueReport,
  RoleRevenueLegalEntityCriteria,
  SalesSummary,
  SalesSummaryLegalEntityCriteria,
  SingleDepositMethodCriteria,
  SinglePayoutMethodCriteria,
  TransactionReport
} from '../../../ledger/ledger_api'
import {RecommendedPrice} from '../../../retail/insurance/thirdpartyinsurance/models/recommended-price.model'
import {
  ThirdPartyInsuranceProductTemplate
} from '../../../retail/insurance/thirdpartyinsurance/models/third-party-insurance-product-template.model'
import {
  ConceptualDocumentCannotBeRenderedException,
  ConceptualDocumentNotFoundException,
  ProvideRenderedConceptualDocumentRequest,
  ProvideRenderedConceptualDocumentResponse,
  RenderedDocumentIdCriteria
} from '../../../rendering/rendering_api'
import {BrokerLegalEntityCriteria} from '../../../retail/insurance/models/broker-legal-entity-criteria.model'
import {
  CustomerInvoiceSummary,
  InvoiceDateLegalEntityCriteria,
  InvoiceNumberCriteria,
  ProvideCustomerInvoiceSummaryRequest,
  ProvideCustomerInvoiceSummaryResponse,
  ProvideSupplierInvoiceSummaryRequest,
  ProvideSupplierInvoiceSummaryResponse,
  SupplierInvoiceSummary,
} from '../../../retail/invoice/invoice_api'
import {AllCategoryIdentifierCriteria} from '../../../vehicle/identification/models/all-category-identifier-criteria.model'
import {
  BrokerNotFoundException
} from '../../../retail/insurance/services/insuranceproductinitialisationmanager/initialisetpiproduct/preconditions/models/broker-not-found-exception.model'
import {
  InitialiseInsuranceProductRequest
} from '../../../retail/insurance/services/insuranceproductinitialisationmanager/initialisetpiproduct/request/models/initialise-insurance-product-request.model'
import {
  InitialiseInsuranceProductResponse
} from '../../../retail/insurance/services/insuranceproductinitialisationmanager/initialisetpiproduct/response/models/initialise-insurance-product-response.model'
import {SubscriptionOwner} from '../../../security/authorisation/models/subscription-owner.model'
import {
  AccountStatementReportConceptualDocument
} from '../../../ledger/conceptualdocument/models/account-statement-report-conceptual-document'
import {
  AgentIdentifier,
  ContactNumber,
  ContactNumberIdentifier,
  ContactNumberVerificationFailedException,
  ContactPerson,
  CountryIdentificationConfigurationNotProvidedException,
  CountryNotProvidedException,
  EmailAddressIdentifier,
  FinaliseUpdateLegalEntityRequest,
  FinaliseUpdateLegalEntityResponse,
  JudicialPerson,
  JudicialPersonIdentifier,
  LegalEntity,
  LegalEntityDescriptionIdentifier,
  LegalEntityDetails,
  LegalEntityIdentifier,
  LegalEntityIdentifierCriteria,
  LegalEntityNotReturnedException,
  MobileContactNumberNotReturnedException,
  NationalIdentityNumber,
  NaturalPersonIdentifier,
  NaturalPersonIdentifierCriteria,
  Occupation,
  PassportNumber,
  PersonVerificationFailedException,
  PreferredContactMethod,
  ProvideSecuredLegalEntityInformationResponse,
  FinaliseInsertLegalEntityRequest,
  FinaliseInsertLegalEntityResponse,
  QueryLegalEntityInformationResponse,
  TpiIdentifier,
  TrafficRegisterNumber,
  UpdateLegalEntityRequest,
  UpdateLegalEntityResponse
} from "../../../entity/entity_api"
import {
  Account,
  AccountUsernameIdentifier,
  AllServiceGroupCriteria,
  AuthenticationResponse,
  AuthorisedUserMatchesLoggedInUsernameException,
  AuthoriseOverrideRequest,
  AuthoriseOverrideResponse,
  CloseSessionTrackingResponse,
  CreateUserAccountRequest,
  CreateUserAccountResponse,
  FinaliseCreatePrincipalByInsertRequest,
  FinaliseCreatePrincipalByInsertResponse,
  FinaliseCreatePrincipalByUpdateRequest,
  FinaliseCreatePrincipalByUpdateResponse,
  FinaliseCreateUserAccountRequest,
  FinaliseCreateUserAccountResponse,
  FinaliseResetPasswordRequest,
  FinaliseResetPasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  InitialiseCreateUserAccountRequest,
  InitialiseCreateUserAccountResponse,
  InvalidCredentialsException,
  IssueOneTimePinLimitReachedException,
  IssueOneTimePinRequest,
  IssueOneTimePinResponse,
  ManagerNoAuthorityControlCentreOrCourtFoundException,
  NaturalPersonIdentifierCredential,
  NoAccountProvidedException,
  NoServiceGroupsAvailableException,
  PermissionToGrantOverrideNotAllowedException,
  ProvideAccountInformationRequest,
  ProvideAccountInformationResponse,
  ProvideDefaultServiceGroupsRequest,
  ProvideDefaultServiceGroupsResponse,
  ProvideSubscriptionsOwnersBackOfficeRequest,
  ProvideSubscriptionsOwnersBackOfficeResponse,
  ProvideSubscriptionsOwnersRequest,
  ProvideSubscriptionsOwnersResponse,
  ProvideSubscriptionsSummaryRequest,
  ProvideSubscriptionsSummaryResponse,
  ProxySubscriptionOwnedByLegalEntityCriteria,
  ResendVerificationLinkRequest,
  ResendVerificationLinkResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  Service,
  ServiceGroup,
  ServiceGroupAlreadyExistsException,
  ServiceGroupDoesNotExistException,
  ServiceGroupIdentifier,
  ServiceGroupNameTypeIdentifier,
  ServiceGroupTypeCriteria,
  ServiceGroupTypeLegalEntityCriteria,
  ServiceIdentifier,
  SubscriptionSummary,
  UpdateBackOfficePasswordRequest,
  UpdateBackOfficePasswordResponse,
  UpdatedServiceGroupAlreadyExistException,
  UpdatePublicPasswordRequest,
  UpdatePublicPasswordResponse,
  UpdateResetPasswordRequest,
  UpdateResetPasswordResponse,
  ValidateOneTimePinRequest,
  ValidateOneTimePinResponse,
  VerifyOneTimePinRequest,
  VerifyOneTimePinResponse
} from '../../../security/security_api'
import {Levy} from '../../../financial/models/levy.model'
import {
  CloseSessionTrackingRequest,
  CreateSessionTrackingRequest,
  CreateSessionTrackingResponse,
  ProvideSessionTrackingRequest,
  ProvideSessionTrackingResponse,
  SessionTracking,
  UpdateSessionTrackingRequest,
  UpdateSessionTrackingResponse
} from '../../../security/sessiontracking/session-tracking_api'

import {
  FinaliseCreateServiceGroupRequest,
  FinaliseCreateServiceGroupResponse,
  FinaliseDeleteServiceGroupRequest,
  FinaliseDeleteServiceGroupResponse,
  FinaliseUpdateServiceGroupRequest,
  FinaliseUpdateServiceGroupResponse

} from '../../../security/authorisation/authorization_api'
import {
  ActiveSystemParameterNameCriteria,
  ProvideFeatureToggleSystemParameterRequest,
  ProvideFeatureToggleSystemParameterResponse,
  ProvideSystemParameterRequest,
  ProvideSystemParameterResponse,
  SystemParameter,
  SystemParameterNameCriteria,
  SystemParameterNameTypeCriteria
} from '../../../system/system_api'
import {InitialiseCountryCheckoutRequest, InitialiseCountryCheckoutResponse} from '../../../malawi/malawi_api'
import { UniqueCoveredCountryCriteria } from '../../../retail/insurance/setup/models/unique-covered-country-criteria.model'

@Injectable()
export class UnMarshallerService {

  constructor() {

    //conceptual document
    addClassesToBeBound(ConceptualDocument);
    addClassesToBeBound(ConceptualDocumentIdCriteria);
    addClassesToBeBound(ConceptualDocumentTypeIdCriteria);
    addClassesToBeBound(ProvideConceptualDocumentRequest);
    addClassesToBeBound(ProvideConceptualDocumentResponse);


    //criteria
    addClassesToBeBound(Criteria);

    //Email -> models
    addClassesToBeBound(Email);
    addClassesToBeBound(SendEmailResponse);
    addClassesToBeBound(SendEmailRequest);
    addClassesToBeBound(NoRecipientException);

    //entity
    addClassesToBeBound(NaturalPersonIdentifier);
    addClassesToBeBound(NaturalPerson);
    addClassesToBeBound(Occupation);
    addClassesToBeBound(RegistrationNumber);
    addClassesToBeBound(TaxNumber);
    addClassesToBeBound(TrafficRegisterNumber);
    addClassesToBeBound(JudicialPersonIdentifier);
    addClassesToBeBound(LegalEntityIdentifier);
    addClassesToBeBound(LegalEntity);
    addClassesToBeBound(JudicialPerson);
    addClassesToBeBound(NationalIdentityNumber);
    addClassesToBeBound(NaturalPersonIdentifier);
    addClassesToBeBound(PassportNumber);
    addClassesToBeBound(TaxNumber);
    addClassesToBeBound(ContactPerson);
    addClassesToBeBound(LegalEntityIdentifierCriteria);
    addClassesToBeBound(NaturalPersonIdentifierCriteria);
    addClassesToBeBound(FinaliseInsertLegalEntityRequest);
    addClassesToBeBound(FinaliseInsertLegalEntityResponse);
    addClassesToBeBound(FinaliseUpdateLegalEntityRequest);
    addClassesToBeBound(FinaliseUpdateLegalEntityResponse);
    addClassesToBeBound(UpdateLegalEntityRequest);
    addClassesToBeBound(UpdateLegalEntityResponse);
    addClassesToBeBound(ProvideSecuredLegalEntityInformationRequest);
    addClassesToBeBound(ProvideSecuredLegalEntityInformationResponse);
    addClassesToBeBound(QueryLegalEntityInformationResponse);
    addClassesToBeBound(ContactNumberVerificationFailedException);
    addClassesToBeBound(CountryIdentificationConfigurationNotProvidedException);
    addClassesToBeBound(CountryNotProvidedException);
    addClassesToBeBound(LegalEntityNotReturnedException);
    addClassesToBeBound(MobileContactNumberNotReturnedException);
    addClassesToBeBound(PersonVerificationFailedException);
    addClassesToBeBound(TpiIdentifier);
    addClassesToBeBound(LegalEntityDescriptionIdentifier);
    addClassesToBeBound(PreferredContactMethod);
    addClassesToBeBound(EmailAddressIdentifier);
    addClassesToBeBound(ContactNumberIdentifier);
    addClassesToBeBound(LegalEntityDescriptionIdentifier);

    //Payment
    addClassesToBeBound(CustomerToken);
    addClassesToBeBound(CardToken);
    addClassesToBeBound(Card);
    addClassesToBeBound(CompanyToken);
    addClassesToBeBound(PaymentOption);
    addClassesToBeBound(PaymentProfileLegalEntityCriteria);
    addClassesToBeBound(PaymentProfile);
    addClassesToBeBound(Services);
    addClassesToBeBound(TransactionToken);
    addClassesToBeBound(Transaction);
    addClassesToBeBound(BankAccount);
    addClassesToBeBound(ProvidePaymentProfileInformationRequest);
    addClassesToBeBound(ProvidePaymentProfileInformationResponse);
    addClassesToBeBound(RetrieveBankTransferInformationRequest);
    addClassesToBeBound(RetrieveBankTransferInformationResponse);
    addClassesToBeBound(LegalEntityDetails);
    addClassesToBeBound(PayfastDetails);
    addClassesToBeBound(PayfastSignatureRequest);
    addClassesToBeBound(PayfastSignatureResponse);
    addClassesToBeBound(ReceivePayfastPaymentRequest);
    addClassesToBeBound(ReceivePayfastPaymentResponse);

    //Finacial
    addClassesToBeBound(Money);
    addClassesToBeBound(PaymentIdentifier);
    addClassesToBeBound(PaymentReference);
    addClassesToBeBound(Payment);
    addClassesToBeBound(CardPaymentOption);
    addClassesToBeBound(PaymentAdvice)
    addClassesToBeBound(OrderReferenceNumber);
    addClassesToBeBound(FinaliseCashierPaymentRequest);
    addClassesToBeBound(FinaliseCashierPaymentResponse);
    addClassesToBeBound(ProcessCardPaymentRequest);
    addClassesToBeBound(ProcessCardPaymentResponse);
    addClassesToBeBound(ProcessTokenPaymentRequest);
    addClassesToBeBound(ProcessTokenPaymentResponse);
    addClassesToBeBound(RemovePaymentOptionRequest);
    addClassesToBeBound(RemovePaymentOptionResponse)
    addClassesToBeBound(CountryTaxes);
    addClassesToBeBound(CountryTaxesCountryCriteria);
    addClassesToBeBound(Levy);
    addClassesToBeBound(ProvideCountryTaxesInformationRequest);
    addClassesToBeBound(ProvideCountryTaxesInformationResponse);
    addClassesToBeBound(RetrieveExchangeRateRequest);
    addClassesToBeBound(RetrieveExchangeRateResponse);

    //identification -> models
    addClassesToBeBound(Identifier);
    addClassesToBeBound(GenerateIdentifierRequest);
    addClassesToBeBound(GenerateIdentifierResponse)
    addClassesToBeBound(CreateIdentifierRequest);
    addClassesToBeBound(CreateIdentifierResponse);

    //Location models
    addClassesToBeBound(AlphaThree);
    addClassesToBeBound(AlphaTwo);
    addClassesToBeBound(Country);
    addClassesToBeBound(CountryDescriptionIdentifier);
    addClassesToBeBound(CountryDialingIdentifier);
    addClassesToBeBound(CountryIdentifier);
    addClassesToBeBound(BasicPhysicalAddress);
    addClassesToBeBound(GenericAddress);
    addClassesToBeBound(Address);
    addClassesToBeBound(Country);
    addClassesToBeBound(GpsLocation);
    addClassesToBeBound(MalawiPhysicalAddress);
    addClassesToBeBound(CityTown);
    addClassesToBeBound(Locality);
    addClassesToBeBound(CountryIdentifierTypeCriteria);
    addClassesToBeBound(CheckUpdateCountryIdentifiersRequest);
    addClassesToBeBound(CheckUpdateCountryIdentifiersResponse);
    addClassesToBeBound(ProvideCountryIdentifiersRequest);
    addClassesToBeBound(ProvideCountryIdentifiersResponse);
    addClassesToBeBound(BasicPostalAddress);

    //Malawi
    addClassesToBeBound(InitialiseCountryCheckoutRequest);
    addClassesToBeBound(InitialiseCountryCheckoutResponse);

    //Media
    addClassesToBeBound(Image);
    addClassesToBeBound(ScannedDocumentIdentifier);
    addClassesToBeBound(ProvideScannedDocumentRequest);
    addClassesToBeBound(ProvideScannedDocumentResponse);

    //Metadata
    addClassesToBeBound(Metadata);

    //Rendering -> Renderer
    addClassesToBeBound(RenderDocumentResponse);
    addClassesToBeBound(PdfDocument);
    addClassesToBeBound(RenderedDocument);
    addClassesToBeBound(RenderedDocumentTypeIdCriteria);
    addClassesToBeBound(RenderedDocumentIdCriteria);
    addClassesToBeBound(RenderedDocument);
    addClassesToBeBound(RenderedDocument);
    addClassesToBeBound(RenderDocumentResponse);
    addClassesToBeBound(ProvideRenderedConceptualDocumentRequest);
    addClassesToBeBound(ProvideRenderedConceptualDocumentResponse);
    addClassesToBeBound(ConceptualDocumentNotFoundException);
    addClassesToBeBound(ConceptualDocumentCannotBeRenderedException);

    //Sms
    addClassesToBeBound(Sms);
    addClassesToBeBound(SendSmsRequest);
    addClassesToBeBound(SendSmsResponse);

    //Security
    addClassesToBeBound(CompileAccountRequest);
    addClassesToBeBound(CompileAccountResponse);
    addClassesToBeBound(Account);
    addClassesToBeBound(AccountCriteria);
    addClassesToBeBound(AccountIdentifier);
    addClassesToBeBound(AccountNumberIdentifier);
    addClassesToBeBound(AccountUsernameIdentifier);
    addClassesToBeBound(FinaliseResetPasswordRequest);
    addClassesToBeBound(FinaliseResetPasswordResponse);
    addClassesToBeBound(ServiceIdentifier);
    addClassesToBeBound(ServiceGroupLegalEntityCriteria);
    addClassesToBeBound(ServiceGroupTypeCriteria);
    addClassesToBeBound(ServiceGroupTypeLegalEntityCriteria);
    addClassesToBeBound(ServiceGroup);
    addClassesToBeBound(ProvideDefaultServiceGroupsRequest);
    addClassesToBeBound(ProvideDefaultServiceGroupsResponse);

    addClassesToBeBound(ServiceGroupAlreadyExistsException);
    addClassesToBeBound(ServiceGroupDoesNotExistException);
    addClassesToBeBound(ServiceGroupIdentifier);
    addClassesToBeBound(ServiceGroupNameTypeIdentifier);
    addClassesToBeBound(Subscription);
    addClassesToBeBound(SubscriptionOwner);
    addClassesToBeBound(CompileAccountResponse);
    addClassesToBeBound(ProvideAccountInformationResponse);
    addClassesToBeBound(ProvideAccountInformationRequest);
    addClassesToBeBound(AccountAlreadyVerifiedException);
    addClassesToBeBound(NoAccountProvidedException);
    addClassesToBeBound(TokenExpiredException);
    addClassesToBeBound(TokenNotFoundException);
    addClassesToBeBound(VerifyAccountLinkRequest);
    addClassesToBeBound(VerifyAccountLinkResponse);
    addClassesToBeBound(Service);
    addClassesToBeBound(ServiceGroupLegalEntityCriteria);
    addClassesToBeBound(SubscriptionAllAccessCriteria);
    addClassesToBeBound(OneTimeLinkContext);
    addClassesToBeBound(DetermineOneTimeLinkIssueCountRequest);
    addClassesToBeBound(DetermineOneTimeLinkIssueCountResponse);
    addClassesToBeBound(IssueOneTimeLinkRequest);
    addClassesToBeBound(IssueOneTimeLinkResponse);
    addClassesToBeBound(IssueOnetimeLinkLimitReachedException);
    addClassesToBeBound(TokenCriteria);
    addClassesToBeBound(IdentifierTokenIdentifier);
    addClassesToBeBound(IdentifierToken);
    addClassesToBeBound(TokenGenerateCriteria);
    addClassesToBeBound(GenerateIdentifierTokenRequest);
    addClassesToBeBound(GenerateIdentifierTokenResponse);
    addClassesToBeBound(UpdateResetPasswordRequest);
    addClassesToBeBound(UpdateResetPasswordResponse);
    addClassesToBeBound(FinaliseCreateUserAccountRequest);
    addClassesToBeBound(FinaliseCreateUserAccountResponse);
    addClassesToBeBound(ManagerNoAuthorityControlCentreOrCourtFoundException);
    addClassesToBeBound(NoAuthorityControlCentreOrCourtFoundException);
    addClassesToBeBound(InitialiseCreateUserAccountRequest);
    addClassesToBeBound(InitialiseCreateUserAccountResponse);
    addClassesToBeBound(CreateUserAccountRequest);
    addClassesToBeBound(CreateUserAccountResponse);
    addClassesToBeBound(NoServiceGroupsAvailableException);
    addClassesToBeBound(VerifyAccountAndCreatePasswordRequest);
    addClassesToBeBound(VerifyAccountAndCreatePasswordResponse);
    addClassesToBeBound(ServiceNameTypeIdentifier);
    addClassesToBeBound(CheckUpdateServiceIdentifiersRequest);
    addClassesToBeBound(CheckUpdateServiceIdentifiersResponse);
    addClassesToBeBound(ProvideServiceIdentifiersRequest);
    addClassesToBeBound(ProvideServiceIdentifiersResponse);
    addClassesToBeBound(ServiceIdentifierTypeCriteria);
    addClassesToBeBound(ProvideServiceGroupsRequest);
    addClassesToBeBound(ProvideServiceGroupsResponse);
    addClassesToBeBound(ProvideSubscriptionsRequest);
    addClassesToBeBound(ProvideSubscriptionsResponse);
    addClassesToBeBound(SubscriptionSummary);
    addClassesToBeBound(ProvideSubscriptionsSummaryRequest);
    addClassesToBeBound(ProvideSubscriptionsSummaryResponse);
    addClassesToBeBound(SubscriptionsServiceGroupTypeLegalEntityCriteria);
    addClassesToBeBound(SubscriptionUsedByLegalEntityCriteria);
    addClassesToBeBound(SubscriptionOwnedByLegalEntityCriteria);
    addClassesToBeBound(ProxySubscriptionOwnedByLegalEntityCriteria);
    addClassesToBeBound(ServiceGroupNameTypeCriteria);
    addClassesToBeBound(ProvideServiceGroupIdentifierResponse);
    addClassesToBeBound(ProvideServiceGroupIdentifierRequest);
    addClassesToBeBound(CheckUpdateServiceGroupIdentifiersRequest);
    addClassesToBeBound(CheckUpdateServiceGroupIdentifiersResponse);
    addClassesToBeBound(ServiceGroupNameIdentifier);
    addClassesToBeBound(AuthenticateCredentialsResponse);
    addClassesToBeBound(AuthenticationAdvice);
    addClassesToBeBound(TextualElaboration);
    addClassesToBeBound(TokenElaboration);
    addClassesToBeBound(Token);
    addClassesToBeBound(UpdatedServiceGroupAlreadyExistException);
    addClassesToBeBound(AuthenticationResponse);
    addClassesToBeBound(ContactNumberType);
    addClassesToBeBound(ContactNumber);
    addClassesToBeBound(NaturalPersonIdentifierCredential);
    addClassesToBeBound(OneTimePinContext);
    addClassesToBeBound(VerifyOneTimePinRequest);
    addClassesToBeBound(VerifyOneTimePinResponse);
    addClassesToBeBound(ValidateOneTimePinRequest);
    addClassesToBeBound(ValidateOneTimePinResponse);
    addClassesToBeBound(IssueOneTimePinLimitReachedException);
    addClassesToBeBound(IssueOneTimePinRequest);
    addClassesToBeBound(IssueOneTimePinResponse);
    addClassesToBeBound(FinaliseCreatePrincipalByInsertRequest);
    addClassesToBeBound(FinaliseCreatePrincipalByInsertResponse);
    addClassesToBeBound(FinaliseCreatePrincipalByUpdateRequest);
    addClassesToBeBound(FinaliseCreatePrincipalByUpdateResponse);
    addClassesToBeBound(ProvideSubscriptionsOwnersResponse);
    addClassesToBeBound(ProvideSubscriptionsOwnersRequest);
    addClassesToBeBound(ProvideSubscriptionsOwnersBackOfficeResponse);
    addClassesToBeBound(ProvideSubscriptionsOwnersBackOfficeRequest);
    addClassesToBeBound(AuthoriseOverrideRequest);
    addClassesToBeBound(AuthoriseOverrideResponse);
    addClassesToBeBound(AuthorisedUserMatchesLoggedInUsernameException);
    addClassesToBeBound(InvalidCredentialsException);
    addClassesToBeBound(PermissionToGrantOverrideNotAllowedException);
    addClassesToBeBound(AllServiceGroupCriteria);
    addClassesToBeBound(SessionTracking);
    addClassesToBeBound(CreateSessionTrackingRequest);
    addClassesToBeBound(CreateSessionTrackingResponse);
    addClassesToBeBound(CloseSessionTrackingRequest);
    addClassesToBeBound(CloseSessionTrackingResponse);
    addClassesToBeBound(ProvideSessionTrackingRequest);
    addClassesToBeBound(ProvideSessionTrackingResponse);
    addClassesToBeBound(UpdateSessionTrackingRequest);
    addClassesToBeBound(UpdateSessionTrackingResponse);
    addClassesToBeBound(ResendVerificationLinkResponse);
    addClassesToBeBound(ResendVerificationLinkRequest);
    addClassesToBeBound(ResetPasswordRequest);
    addClassesToBeBound(ResetPasswordResponse);
    addClassesToBeBound(ForgotPasswordRequest);
    addClassesToBeBound(ForgotPasswordResponse);
    addClassesToBeBound(UpdateBackOfficePasswordRequest);
    addClassesToBeBound(UpdateBackOfficePasswordResponse);
    addClassesToBeBound(UpdatePublicPasswordResponse);
    addClassesToBeBound(UpdatePublicPasswordRequest);

    //Security -> Authorisation
    addClassesToBeBound(FinaliseUpdateServiceGroupRequest);
    addClassesToBeBound(FinaliseUpdateServiceGroupResponse);
    addClassesToBeBound(FinaliseCreateServiceGroupRequest);
    addClassesToBeBound(FinaliseCreateServiceGroupResponse);
    addClassesToBeBound(FinaliseDeleteServiceGroupRequest);
    addClassesToBeBound(FinaliseDeleteServiceGroupResponse);

    //Location -> Identification
    addClassesToBeBound(CityTownIdentifier);
    addClassesToBeBound(CityTownDescriptionIdentifier);
    addClassesToBeBound(CityTownIdentifierTypeCriteria);
    addClassesToBeBound(FindCityTownIdentifierRequest);
    addClassesToBeBound(FindCityTownIdentifierResponse);

    //urdad
    addClassesToBeBound(RequestNotValidException);
    addClassesToBeBound(UnexpectedServiceError);
    addClassesToBeBound(Exception);
    addClassesToBeBound(PublishEventResponse);
    addClassesToBeBound(PublishEventRequest);
    addClassesToBeBound(Event)
    addClassesToBeBound(ServiceEvent)
    addClassesToBeBound(ServiceProviderEvent)

    //vehicle -> identification
    addClassesToBeBound(AllCategoryIdentifierCriteria);
    addClassesToBeBound(EngineNumber);
    addClassesToBeBound(VehiclePlateNumber);
    addClassesToBeBound(VehicleDescriptionIdentifier);
    addClassesToBeBound(VehicleIdentificationNumber);
    addClassesToBeBound(VehicleRegisterNumber);
    addClassesToBeBound(PlateNumber);
    addClassesToBeBound(VehicleIdentifier);
    addClassesToBeBound(TpiVehicleIdentifier);

    //Vehicle -> services
    addClassesToBeBound(ProvideVehicleMakesRequest);
    addClassesToBeBound(ProvideVehicleMakesResponse);
    addClassesToBeBound(ProvideVehicleModelsRequest);
    addClassesToBeBound(ProvideVehicleModelsResponse);
    addClassesToBeBound(FinaliseCreateVehicleMakeRequest);
    addClassesToBeBound(FinaliseCreateVehicleMakeResponse);
    addClassesToBeBound(FinaliseCreateVehicleModelRequest);
    addClassesToBeBound(FinaliseCreateVehicleModelResponse);
    addClassesToBeBound(FinaliseDeleteVehicleMakeRequest);
    addClassesToBeBound(FinaliseDeleteVehicleMakeResponse);
    addClassesToBeBound(FinaliseDeleteVehicleModelRequest);
    addClassesToBeBound(FinaliseDeleteVehicleModelResponse);
    addClassesToBeBound(FinaliseUpdateVehicleMakeRequest);
    addClassesToBeBound(FinaliseUpdateVehicleMakeResponse);
    addClassesToBeBound(FinaliseUpdateVehicleModelRequest);
    addClassesToBeBound(FinaliseUpdateVehicleModelResponse);
    addClassesToBeBound(UpdateVehicleMakeAlreadyExistsException);
    addClassesToBeBound(UpdateVehicleModelAlreadyExistsException);
    addClassesToBeBound(VehicleMakeAlreadyExistsException);
    addClassesToBeBound(VehicleModelAlreadyExistsException);
    addClassesToBeBound(VehicleModelDoesNotExistException);
    addClassesToBeBound(VehicleMakeDoesNotExistException);

    //Retail
    addClassesToBeBound(AgentIdentifier);
    addClassesToBeBound(AgentIdentifierIdentifier);
    addClassesToBeBound(AgentLegalEntityIdentifier);
    addClassesToBeBound(Excess);
    addClassesToBeBound(MotorInsuranceCertificate);
    addClassesToBeBound(Limit);
    addClassesToBeBound(ItemIdentifier);
    addClassesToBeBound(ItemNumberIdentifier);
    addClassesToBeBound(Item);
    addClassesToBeBound(Order);
    addClassesToBeBound(OrderItem);
    addClassesToBeBound(InsuranceType);
    addClassesToBeBound(InsuranceTypeBrokerCriteria);
    addClassesToBeBound(InsuranceVehicleCategory);
    addClassesToBeBound(InsuranceVehicleCategoryClassification);
    addClassesToBeBound(RetailProfileIdentifierCriteria);
    addClassesToBeBound(RetailProfileIdentifier);
    addClassesToBeBound(RetailProfileOwnerIdentifier);
    addClassesToBeBound(Cart);
    addClassesToBeBound(RetailProfileLegalEntityCriteria);
    addClassesToBeBound(RetailProfile);
    addClassesToBeBound(RetailVehicle);
    addClassesToBeBound(ProvideOrderLegalEntityRequest);
    addClassesToBeBound(ProvideOrderLegalEntityResponse);
    addClassesToBeBound(ProvideRetailProfileInformationRequest);
    addClassesToBeBound(ProvideRetailProfileInformationResponse);
    addClassesToBeBound(ProvideRetailProfileVehicleInformationRequest);
    addClassesToBeBound(ProvideRetailProfileVehicleInformationResponse);
    addClassesToBeBound(ProvideRetailProfileVehiclesRequest);
    addClassesToBeBound(ProvideRetailProfileVehiclesResponse);
    addClassesToBeBound(FinaliseClearCartRequest);
    addClassesToBeBound(FinaliseClearCartResponse);
    addClassesToBeBound(FinaliseInsertRetailProfileResponse);
    addClassesToBeBound(FinaliseInsertRetailProfileRequest);
    addClassesToBeBound(FinaliseRemoveItemRequest);
    addClassesToBeBound(FinaliseRemoveItemResponse);
    addClassesToBeBound(FinaliseUpdateItemRequest);
    addClassesToBeBound(FinaliseUpdateItemResponse);
    addClassesToBeBound(FinaliseUpdateRetailProfileRequest);
    addClassesToBeBound(FinaliseUpdateRetailProfileResponse);
    addClassesToBeBound(CartDoesNotExistException);
    addClassesToBeBound(ItemDoesNotExistException);
    addClassesToBeBound(RetailProfileAlreadyExistsException);
    addClassesToBeBound(RetailProfileDoesNotExistException);
    addClassesToBeBound(RetailProfileVehicleLinkDoesNotExistException);
    addClassesToBeBound(RetailProfileVehicleLinkExistsException);
    addClassesToBeBound(CompileCheckoutItemGroupingRequest);
    addClassesToBeBound(CompileCheckoutItemGroupingResponse);
    addClassesToBeBound(CountryStartDateConfiguration);
    addClassesToBeBound(CountryStartDateConfigurationCriteria);
    addClassesToBeBound(ProvideCountryStartDateConfigurationRequest);
    addClassesToBeBound(ProvideCountryStartDateConfigurationResponse);
    addClassesToBeBound(ProvideCoveredCountriesRequest);
    addClassesToBeBound(ProvideCoveredCountriesResponse);
    addClassesToBeBound(ThirdPartyInsuranceProduct);
    addClassesToBeBound(ComprehensiveInsuranceProduct);
    addClassesToBeBound(Product);
    addClassesToBeBound(AllowablePrintsExceededException);
    addClassesToBeBound(NoPrintConfigurationFoundException);
    addClassesToBeBound(ProvideOrderRequest);
    addClassesToBeBound(ProvideOrderResponse);
    addClassesToBeBound(ProvideOrderInformationRequest);
    addClassesToBeBound(ProvideOrderInformationResponse);
    addClassesToBeBound(ProvideOrderSummaryRequest);
    addClassesToBeBound(ProvideOrderSummaryResponse);
    addClassesToBeBound(NoOrderFoundException);
    addClassesToBeBound(ThirdPartyInsuranceOrderItem);
    addClassesToBeBound(ThirdPartyInsurancePolicyOrderItem);
    addClassesToBeBound(ComprehensiveInsurancePolicyOrderItem);
    addClassesToBeBound(OrderSummary);
    addClassesToBeBound(OrderSummaryRetailProfileCriteria);
    addClassesToBeBound(RetailProfileOrderDateCriteria);
    addClassesToBeBound(RetailProfileOrderNumberCriteria);
    addClassesToBeBound(RetailProfileOrderStatusCriteria);
    addClassesToBeBound(ProvideBrokerInformationRequest);
    addClassesToBeBound(ProvideBrokerInformationResponse)
    addClassesToBeBound(BrokerUnderwriterCriteria);
    addClassesToBeBound(UnderwriterLegalEntityCriteria);
    addClassesToBeBound(ProvideUnderwriterInformationRequest);
    addClassesToBeBound(ProvideUnderwriterInformationResponse);
    addClassesToBeBound(ProvideUnderwriterWhiteLabelInformationRequest);
    addClassesToBeBound(ProvideUnderwriterWhiteLabelInformationResponse)
    addClassesToBeBound(OrderIdentifier);
    addClassesToBeBound(OrderNumberIdentifier);
    addClassesToBeBound(BrokerLegalEntityCriteria);
    addClassesToBeBound(InsurancePolicyNumberCriteria);
    addClassesToBeBound(InsurancePolicyVehicleCriteria);
    addClassesToBeBound(FinaliseLinkVehicleRequest);
    addClassesToBeBound(FinaliseLinkVehicleResponse);
    addClassesToBeBound(FinaliseSyncVehicleRequest);
    addClassesToBeBound(FinaliseSyncVehicleResponse);
    addClassesToBeBound(InitialiseSyncVehicleRequest);
    addClassesToBeBound(InitialiseSyncVehicleResponse);
    addClassesToBeBound(ProvideInsurancePoliciesRequest);
    addClassesToBeBound(ProvideInsurancePoliciesResponse);
    addClassesToBeBound(ProvidePublicMotorInsuranceVehiclePolicyRequest);
    addClassesToBeBound(ProvidePublicMotorInsuranceVehiclePolicyResponse);
    addClassesToBeBound(ProvideSuitableInsurancePolicyRequest);
    addClassesToBeBound(ProvideSuitableInsurancePolicyResponse);
    addClassesToBeBound(InsurancePolicyVehicleCategoryCriteria);
    addClassesToBeBound(ProvideInsuranceProductInformationResponse);
    addClassesToBeBound(ProvideInsuranceProductInformationRequest);
    addClassesToBeBound(InsuranceCertificatePolicyNumberCriteria);
    addClassesToBeBound(DuplicateInsuranceCertificateCreatedEvent);
    addClassesToBeBound(InsuranceCertificateCreatedEvent);
    addClassesToBeBound(InsuranceCertificateEvent);
    addClassesToBeBound(ProvideInsuranceCertificatesRequest);
    addClassesToBeBound(ProvideInsuranceCertificatesResponse);
    addClassesToBeBound(FinaliseIssueInsuranceCertificateRequest);
    addClassesToBeBound(FinaliseIssueInsuranceCertificateResponse)
    addClassesToBeBound(InsuranceProductBrokerCriteria);
    addClassesToBeBound(InsuranceProductUnderwriterCriteria);
    addClassesToBeBound(AddToCartRequest);
    addClassesToBeBound(CountryRegistrationConfiguration);
    addClassesToBeBound(ShownJudicialPersonCountryRegistrationInformation);
    addClassesToBeBound(ShownNaturalPersonCountryRegistrationInformation);
    addClassesToBeBound(RequiredJudicialPersonCountryRegistrationInformation);
    addClassesToBeBound(RequiredNaturalPersonCountryRegistrationInformation);
    addClassesToBeBound(CheckoutDatedItemGrouping);
    addClassesToBeBound(CheckoutNonDatedItemGrouping);
    addClassesToBeBound(CaptureProposalRequest);
    addClassesToBeBound(CaptureProposalResponse);
    addClassesToBeBound(CaptureStartDateGroupingRequest);
    addClassesToBeBound(CaptureStartDateGroupingResponse);
    addClassesToBeBound(CaptureUpdatedStartDateGroupingRequest);
    addClassesToBeBound(CaptureUpdatedStartDateGroupingResponse);
    addClassesToBeBound(CreateProposalRequest);
    addClassesToBeBound(CreateProposalResponse);
    addClassesToBeBound(CartIsEmptyException);
    addClassesToBeBound(InsurancePolicyLegalEntityCriteria);
    addClassesToBeBound(QueryInsurancePolicyInformationRequest);
    addClassesToBeBound(QueryInsurancePolicyInformationResponse);
    addClassesToBeBound(QueryPublicInsurancePolicyInformationRequest);
    addClassesToBeBound(QueryPublicInsurancePolicyInformationResponse);
    addClassesToBeBound(OrderItemIdentifier);
    addClassesToBeBound(OrderItemNumberIdentifier);
    addClassesToBeBound(OrderItemOrderNumberIdentifier);
    addClassesToBeBound(ProposalFormCountryCriteria);
    addClassesToBeBound(ProposalFormCriteria);
    addClassesToBeBound(PublicInsurancePolicySummary);
    addClassesToBeBound(ProvidePublicInsurancePolicyInformationRequest);
    addClassesToBeBound(ProvidePublicInsurancePolicyInformationResponse);
    addClassesToBeBound(CountryIdentificationConfigurationCriteria);
    addClassesToBeBound(CountryIdentificationConfiguration);
    addClassesToBeBound(SetupLimitType);
    addClassesToBeBound(InitialiseCheckoutRequest);
    addClassesToBeBound(InitialiseCheckoutResponse);
    addClassesToBeBound(AssessProposalRequest);
    addClassesToBeBound(AssessProposalResponse);
    addClassesToBeBound(FinaliseProposalAssessmentResponse);
    addClassesToBeBound(FinaliseProposalAssessmentRequest);
    addClassesToBeBound(ProvideConceptualDocumentRequest);
    addClassesToBeBound(ProvideConceptualDocumentResponse);
    addClassesToBeBound(ProposalSummary);
    addClassesToBeBound(ProposalRecordStatusCriteria);
    addClassesToBeBound(ProposalRecordStatusLegalEntityCriteria);
    addClassesToBeBound(ProvideProposalInformationRequest);
    addClassesToBeBound(ProvideProposalInformationResponse);
    addClassesToBeBound(ProvideProposalSummaryRequest);
    addClassesToBeBound(ProvideProposalSummaryResponse);
    addClassesToBeBound(ProvideRetailProfileProposalSummaryRequest);
    addClassesToBeBound(ProvideRetailProfileProposalSummaryResponse);
    addClassesToBeBound(ProvideProposalRecordSummaryRequest);
    addClassesToBeBound(ProvideProposalRecordSummaryResponse);
    addClassesToBeBound(FindProposalRecordRequest);
    addClassesToBeBound(FindProposalRecordResponse);
    addClassesToBeBound(PolicyWording);
    addClassesToBeBound(PolicyWordingUnderwriterCategoryCriteria);
    addClassesToBeBound(ProvidePolicyWordingResponse);
    addClassesToBeBound(ProvidePolicyWordingRequest);
    addClassesToBeBound(CertificateIdentifier);
    addClassesToBeBound(CertificateNumberIdentifier);
    addClassesToBeBound(InsuranceCertificateNumberCriteria);
    addClassesToBeBound(OrderSummaryLegalEntityCriteria);
    addClassesToBeBound(FindProposalRecordSummaryRequest);
    addClassesToBeBound(FindProposalRecordSummaryResponse);
    addClassesToBeBound(ProposalRecordSummary);
    addClassesToBeBound(ProposalRecordSummaryLegalEntityCriteria);
    addClassesToBeBound(ProposalRecordSummaryDateCriteria);
    addClassesToBeBound(ProposalRecordSummaryStatusCriteria);
    addClassesToBeBound(ProvideAgentBrokersProductsResponse);
    addClassesToBeBound(ProvideAgentBrokersProductsRequest);
    addClassesToBeBound(InsuranceVehicleCategorySummary);
    addClassesToBeBound(PriceSchedule);
    addClassesToBeBound(UnderwriterParameters);
    addClassesToBeBound(MotorInsuranceDetails);
    addClassesToBeBound(MotorInsuranceVehicleDetails);
    addClassesToBeBound(MotorInsuranceLimitDetails);
    addClassesToBeBound(LegalEntityOrderCriteria);
    addClassesToBeBound(CountryCheckoutItemGrouping);
    addClassesToBeBound(UnderwriterCheckoutItemGrouping);
    addClassesToBeBound(ComprehensiveInsuranceCartItem);
    addClassesToBeBound(ComprehensiveInsurancePolicyCartItem);
    addClassesToBeBound(ComprehensiveInsuranceProductCartItem);
    addClassesToBeBound(InsuranceProductCartItem);
    addClassesToBeBound(FinaliseCheckoutOrderRequest);
    addClassesToBeBound(FinaliseCheckoutOrderResponse);
    addClassesToBeBound(FinaliseYCCheckoutOrderRequest);
    addClassesToBeBound(FinaliseYCCheckoutOrderResponse);
    addClassesToBeBound(FinaliseCancelOrderRequest);
    addClassesToBeBound(FinaliseCancelOrderResponse);
    addClassesToBeBound(MotorInsurancePolicy);
    addClassesToBeBound(MotorInsurancePolicyItem);
    addClassesToBeBound(MotorInsuranceVehiclePolicy);
    addClassesToBeBound(SnapshotVehicle);
    addClassesToBeBound(ProvideCountryUnderwriterProductInformationRequest);
    addClassesToBeBound(ProvideCountryUnderwriterProductInformationResponse);
    addClassesToBeBound(RetailProfileVehicleInsurancePolicyCriteria);
    addClassesToBeBound(ExtensionInsurancePolicyCartItem);
    addClassesToBeBound(ExtensionInsuranceProductCartItem);
    addClassesToBeBound(ExtensionInsurancePolicyOrderItem);
    addClassesToBeBound(ExtensionInsuranceOrderItem);
    addClassesToBeBound(orderExtensionInsuranceProduct);
    addClassesToBeBound(InsuranceCartItem);
    addClassesToBeBound(InsurancePolicyAmendmentItem);
    addClassesToBeBound(InsuranceLevy);
    addClassesToBeBound(AddExtensionToCartRequest);
    addClassesToBeBound(AddExtensionToCartResponse);
    addClassesToBeBound(InitialiseExtendInsurancePolicyRequest);
    addClassesToBeBound(InitialiseExtendInsurancePolicyResponse);
    addClassesToBeBound(InitialiseExtensionValidationRequest)
    addClassesToBeBound(InitialiseExtensionValidationResponse)
    addClassesToBeBound(VehicleInsurancePolicy)
    addClassesToBeBound(InsuranceCategoryCriteria)
    addClassesToBeBound(CoveredCountries)
    addClassesToBeBound(UniqueCoveredCountryCriteria)
    addClassesToBeBound(YCSellingCountryRule)
    addClassesToBeBound(ProvideCountrySellingRuleRequest)
    addClassesToBeBound(ProvideCountrySellingRuleResponse)

    //Retail/Profile

    addClassesToBeBound(ValidateImportFleetDocumentRequest);
    addClassesToBeBound(ValidateImportFleetDocumentResponse);
    addClassesToBeBound(ImportFleetRequest);
    addClassesToBeBound(IncorrectDocumentFormat);
    addClassesToBeBound(InvalidVehiclePlateNumber);
    addClassesToBeBound(FinaliseImportFleetRequest);
    addClassesToBeBound(FinaliseImportFleetResponse);
    addClassesToBeBound(InvalidDocumentStructureException);

    //

    addClassesToBeBound(UnderwriterCountryCriteria);
    addClassesToBeBound(ThirdPartyInsuranceCartItem);
    addClassesToBeBound(ThirdPartyInsurancePolicyCartItem);
    addClassesToBeBound(ThirdPartyInsuranceProductCartItem);
    addClassesToBeBound(LimitInterval);

    addClassesToBeBound(InsurancePolicyAlreadyExpiredException);
    addClassesToBeBound(InsurancePolicyItemAlreadyExpiredException);
    addClassesToBeBound(InitialiseCancelInsurancePolicyRequest);
    addClassesToBeBound(InitialiseCancelInsurancePolicyResponse);
    addClassesToBeBound(AddYCToCartRequest);
    addClassesToBeBound(AddYCToCartResponse);

    addClassesToBeBound(RefundOrderItemNumberCriteria);
    addClassesToBeBound(RefundOrderItemNumberStatusCriteria);
    addClassesToBeBound(RefundSummary);
    addClassesToBeBound(RefundPolicyNumberCriteria);
    addClassesToBeBound(ProvideRefundSummaryRequest);
    addClassesToBeBound(ProvideRefundSummaryResponse);

    addClassesToBeBound(FinaliseCancelInsurancePolicyRequest);
    addClassesToBeBound(FinaliseCancelInsurancePolicyResponse);

    addClassesToBeBound(FinaliseExtendCheckoutOrderRequest);
    addClassesToBeBound(FinaliseExtendCheckoutOrderResponse);

    addClassesToBeBound(ProvideInsuranceTypeRequest);
    addClassesToBeBound(ProvideInsuranceTypeResponse);

    addClassesToBeBound(YCMotorInsuranceDetails);
    addClassesToBeBound(CalculateRealTimeYCPriceRequest);
    addClassesToBeBound(CalculateRealTimeYCPriceResponse);

    //Retail/Setup
    addClassesToBeBound(PickUpPoint);
    addClassesToBeBound(AllInsuranceTypeCriteria);

    //Invoice
    addClassesToBeBound(SupplierInvoiceSummary);
    addClassesToBeBound(InvoiceNumberCriteria);
    addClassesToBeBound(InvoiceDateLegalEntityCriteria);
    addClassesToBeBound(ProvideSupplierInvoiceSummaryRequest);
    addClassesToBeBound(ProvideSupplierInvoiceSummaryResponse);

    addClassesToBeBound(CustomerInvoiceSummary);
    addClassesToBeBound(InvoiceDateLegalEntityCriteria);
    addClassesToBeBound(InvoiceNumberCriteria);
    addClassesToBeBound(ProvideCustomerInvoiceSummaryRequest);
    addClassesToBeBound(ProvideCustomerInvoiceSummaryResponse);

    //Products
    addClassesToBeBound(ProductIdentifier);
    addClassesToBeBound(ProductNumberIdentifier);
    addClassesToBeBound(ProductCriteria);

    //Vehicle -> models
    addClassesToBeBound(Engine);
    addClassesToBeBound(Vehicle);
    addClassesToBeBound(VehicleCertificate);
    addClassesToBeBound(VehicleRegistration);
    addClassesToBeBound(VehicleLicence);
    addClassesToBeBound(Category);
    addClassesToBeBound(Usage);
    addClassesToBeBound(QueryVehicleInformationRequest);
    addClassesToBeBound(QueryVehicleInformationResponse)
    addClassesToBeBound(VehicleCriteria);
    addClassesToBeBound(UsagesCriteria);
    addClassesToBeBound(ModelDescriptionIdentifier);
    addClassesToBeBound(MakeDescriptionIdentifier);
    addClassesToBeBound(ProvideCategoryIdentifiersRequest);
    addClassesToBeBound(ProvideCategoryIdentifiersResponse);
    addClassesToBeBound(ProvideUsageIdentifiersRequest);
    addClassesToBeBound(ProvideUsageIdentifiersResponse)
    addClassesToBeBound(ProvideVehicleMakesRequest);
    addClassesToBeBound(ProvideVehicleMakesResponse);
    addClassesToBeBound(ProvideVehicleModelsRequest);
    addClassesToBeBound(ProvideVehicleModelsResponse);
    addClassesToBeBound(VehicleProviderCriteria);
    addClassesToBeBound(ProvideVehicleInformationRequest);
    addClassesToBeBound(ProvideVehicleInformationResponse);
    addClassesToBeBound(FinaliseMaintainVehicleInformationResponse);
    addClassesToBeBound(FinaliseMaintainVehicleInformationRequest);
    addClassesToBeBound(CreateOrderRequest);
    addClassesToBeBound(CreateOrderResponse);
    addClassesToBeBound(FuelType);
    addClassesToBeBound(Transmission);

    //temporal
    addClassesToBeBound(Duration);

    //Ledger
    addClassesToBeBound(AccountingTransactionIdentifier);
    addClassesToBeBound(AccountingTransactionReferenceIdentifier);
    addClassesToBeBound(AccountingTransactionAccountDateCriteria);
    addClassesToBeBound(AccountingTransactionReferenceCriteria);
    addClassesToBeBound(AccountingTransactionReferenceDateCriteria);
    addClassesToBeBound(AccountingTransactionReferenceLatestCriteria);
    addClassesToBeBound(AccountingTransaction);
    addClassesToBeBound(Entry);
    addClassesToBeBound(ProvideAccountingTransactionInformationRequest);
    addClassesToBeBound(ProvideAccountingTransactionInformationResponse);
    addClassesToBeBound(DepositMethodBankTransferIdentifier);
    addClassesToBeBound(DepositMethodIdentifier);
    addClassesToBeBound(DepositBankTransfer);
    addClassesToBeBound(DepositMethodCountryCriteria);
    addClassesToBeBound(DepositMethod);
    addClassesToBeBound(SingleDepositMethodCriteria);
    addClassesToBeBound(ProvideDepositMethodRequest);
    addClassesToBeBound(ProvideDepositMethodResponse);

    addClassesToBeBound(PayoutMethodBankTransferIdentifier);
    addClassesToBeBound(PayoutMethodIdentifier);
    addClassesToBeBound(PayoutBankTransfer);
    addClassesToBeBound(LegalEntityPayoutMethodCriteria);
    addClassesToBeBound(PayoutMethod);
    addClassesToBeBound(SinglePayoutMethodCriteria);
    addClassesToBeBound(ProvidePayoutMethodRequest);
    addClassesToBeBound(ProvidePayoutMethodResponse);

    addClassesToBeBound(FinancialAccountIdentifier);
    addClassesToBeBound(FinancialAccountNumberCountryLegalEntityIdentifier);
    addClassesToBeBound(FinancialAccountNumberIdentifier);
    addClassesToBeBound(AllFinancialAccountCriteria);
    addClassesToBeBound(FinancialAccountCountryLegalEntityCriteria);
    addClassesToBeBound(FinancialAccountCountryCriteria);
    addClassesToBeBound(FinancialAccountCountryOnlyCriteria);
    addClassesToBeBound(FinancialAccountLegalEntityCriteria);
    addClassesToBeBound(FinancialAccountNumberCriteria);
    addClassesToBeBound(FinancialAccount);
    addClassesToBeBound(ProvideFinancialAccountInformationRequest);
    addClassesToBeBound(ProvideFinancialAccountInformationResponse);
    addClassesToBeBound(UnderwriterIdentifierIdentifier);
    addClassesToBeBound(UnderwriterIdentifier);
    addClassesToBeBound(UnderwriterLegalEntityIdentifier);
    addClassesToBeBound(RecommendedPrice);
    addClassesToBeBound(ThirdPartyInsuranceProductTemplate);
    addClassesToBeBound(BrokerNotFoundException);
    addClassesToBeBound(InitialiseInsuranceProductRequest);
    addClassesToBeBound(InitialiseInsuranceProductResponse);
    addClassesToBeBound(FinaliseCreateInsuranceProductRequest);
    addClassesToBeBound(FinaliseInsuranceProductResponse);
    addClassesToBeBound(ProvideAgentInformationRequest);
    addClassesToBeBound(ProvideAgentInformationResponse);
    addClassesToBeBound(Agent);
    addClassesToBeBound(Underwriter);
    addClassesToBeBound(FinaliseCreateYCInsuranceProductRequest);
    addClassesToBeBound(FinaliseCreateYCInsuranceProductResponse);
    addClassesToBeBound(FinaliseUpdateInsuranceProductRequest);
    addClassesToBeBound(FinaliseUpdateInsuranceProductResponse);

    addClassesToBeBound(AdditionalDriversAnswers);
    addClassesToBeBound(AdditionalDriversQuestions);
    addClassesToBeBound(Answers);
    addClassesToBeBound(ClaimsHistoryAnswers);
    addClassesToBeBound(ClaimsHistoryQuestions);
    addClassesToBeBound(InsuranceHistoryAnswers);
    addClassesToBeBound(InsuranceHistoryQuestions);
    addClassesToBeBound(ProposalForm);
    addClassesToBeBound(ProposalGroupEntry);
    addClassesToBeBound(Proposal);
    addClassesToBeBound(Questions);
    addClassesToBeBound(VehicleInformationAnswers);
    addClassesToBeBound(VehicleInformationQuestions);
    addClassesToBeBound(CheckoutProposalRequest);
    addClassesToBeBound(CheckoutProposalResponse);
    addClassesToBeBound(ProposalPolicyItem);
    addClassesToBeBound(ProposalResult);
    addClassesToBeBound(VehicleEvaluation);
    addClassesToBeBound(CaptureProposalResultRequest);
    addClassesToBeBound(CaptureProposalResultResponse);
    addClassesToBeBound(NotAllProposalPolicyItemsAssessedException);
    addClassesToBeBound(ProposalRecord);
    addClassesToBeBound(InitialiseProposalAssessmentRequest);
    addClassesToBeBound(InitialiseProposalAssessmentResponse);
    addClassesToBeBound(ReleaseProposalRequest);
    addClassesToBeBound(ReleaseProposalResponse);
    addClassesToBeBound(FindProposalGroupEntryRequest);
    addClassesToBeBound(FindProposalGroupEntryResponse);
    addClassesToBeBound(ProposalGroupEntryAssessorCriteria);
    addClassesToBeBound(ProposalGroupEntryProposalCriteria);
    addClassesToBeBound(ProposalCriteria);
    addClassesToBeBound(FindProposalRequest);
    addClassesToBeBound(FindProposalResponse);

    //Ledger -> Conceptual Document
    addClassesToBeBound(AccountStatementReportConceptualDocument);

    //Ledger -> Ofx
    addClassesToBeBound(OfxEntryLog);
    addClassesToBeBound(OfxEntry);
    addClassesToBeBound(ImportOfxRequest);
    addClassesToBeBound(ImportOfxResponse);

    //Retail -> Reporting
    addClassesToBeBound(RoleRevenue);

    //Ledger -> Reporting
    addClassesToBeBound(AccountReportLegalEntityDateCriteria);
    addClassesToBeBound(AccountStatementReportEntry);
    addClassesToBeBound(AccountStatementReport);
    addClassesToBeBound(ReportDateCriteria);
    addClassesToBeBound(ReportLegalEntityDateCriteria);
    addClassesToBeBound(RevenueReport);
    addClassesToBeBound(InsuranceSalesByProductEntry);
    addClassesToBeBound(InsuranceSalesByProductReportLegalEntityDateCriteria);
    addClassesToBeBound(InsuranceSalesByProductReport);
    addClassesToBeBound(InsuranceSalesBySalesChannelEntry);
    addClassesToBeBound(InsuranceSalesBySalesChannelReportLegalEntityDateCriteria);
    addClassesToBeBound(InsuranceSalesBySalesChannelReport);
    addClassesToBeBound(TransactionReport);
    addClassesToBeBound(ProvideAccountStatementReportRequest);
    addClassesToBeBound(ProvideAccountStatementReportResponse);
    addClassesToBeBound(ProvideRevenueReportRequest);
    addClassesToBeBound(ProvideRevenueReportResponse);
    addClassesToBeBound(ProvideTransactionReportRequest);
    addClassesToBeBound(ProvideTransactionReportResponse);
    addClassesToBeBound(ProvideInsuranceSalesByProductReportRequest);
    addClassesToBeBound(ProvideInsuranceSalesByProductReportResponse);
    addClassesToBeBound(ProvideInsuranceSalesBySalesChannelReportRequest);
    addClassesToBeBound(ProvideInsuranceSalesBySalesChannelReportResponse);
    addClassesToBeBound(PopularProducts);
    addClassesToBeBound(PopularProductsLegalEntityCriteria);
    addClassesToBeBound(RoleRevenueLegalEntityCriteria);
    addClassesToBeBound(SalesSummary);
    addClassesToBeBound(SalesSummaryLegalEntityCriteria);
    addClassesToBeBound(ProvideSalesSummaryRequest);
    addClassesToBeBound(ProvideSalesSummaryResponse);
    addClassesToBeBound(ProvideRoleRevenueRequest);
    addClassesToBeBound(ProvideRoleRevenueResponse);
    addClassesToBeBound(ProvidePopularProductsRequest);
    addClassesToBeBound(ProvidePopularProductsResponse);

    //System
    addClassesToBeBound(ProvideSystemParameterResponse);
    addClassesToBeBound(ProvideSystemParameterRequest);
    addClassesToBeBound(ProvideFeatureToggleSystemParameterRequest);
    addClassesToBeBound(ProvideFeatureToggleSystemParameterResponse);
    addClassesToBeBound(SystemParameter);
    addClassesToBeBound(ActiveSystemParameterNameCriteria);
    addClassesToBeBound(SystemParameterNameCriteria);
    addClassesToBeBound(SystemParameterNameTypeCriteria);

    addClassesToBeBound(YCInsuranceProduct);
    addClassesToBeBound(YCPriceSchedule);
    addClassesToBeBound(YCPriceInterval);
    addClassesToBeBound(YCMotorVehicleInsuranceDetails);
    addClassesToBeBound(YCInsuranceOrderItem);
    addClassesToBeBound(OrderYCInsuranceProduct);
    addClassesToBeBound(YCInsurancePolicyOrderItem);
  }

  unMarshallFromJson(Object: JSON, clazz) {
    return unmarshall(Object, clazz);//takes a json string, call marshaller before demarshalling

  }
}

function addClassesToBeBound(clazz: any) {

  try {
    const obj = new clazz.prototype.constructor();

    if (obj['@class'] == undefined) {

      classes[clazz.prototype.constructor.name] = clazz;
    } else {
      classes[obj['@class']] = clazz;
    }
  } catch (e) {
    console.error('Unmarshaller error occurred while adding class :: ', e, clazz);
  }
}

const classes = new Array();

const atIdUnMarshallingMap = new Map();

//Unmarshall from JSON to object
function unmarshall(jsonObject, clazz) {
  atIdUnMarshallingMap.clear();

  return unmarshallRecursive(jsonObject, clazz);
}


function unmarshallRecursive(jsonObject, clazz) {
  //If primitive, return jsonObject
  if (typeof jsonObject != "object") {

    if (atIdUnMarshallingMap.get(jsonObject) != null && atIdUnMarshallingMap.get(jsonObject) != undefined) {
      return atIdUnMarshallingMap.get(jsonObject);
    } else {
      return jsonObject;
    }
  }

  //handle collections
  if (Array.isArray(jsonObject)) {
    for (let index = 0; index < jsonObject.length; ++index) {
      jsonObject[index] = unmarshallRecursive(jsonObject[index], undefined);

    }
    return jsonObject;
  }

  const myKeys: string[] = [];
  let theClass = undefined;

  if (jsonObject === null || jsonObject === undefined) {
    //console.log("\nundefined jsonObject: ", jsonObject);
    jsonObject = null;
  } else {
    // console.log('jsonObject:', JSON.stringify(jsonObject));
    // myKeys = Object.keys(jsonObject);
    for (const key in jsonObject) {
      myKeys.push(key);
    }
    theClass = jsonObject['@class'];

  }

  if (theClass != undefined) {
    clazz = classes[theClass];
  } else if (theClass == undefined) {
    // console.log("Object value is null");
    // throw new RequestNotValidException();
  }


  let clazzObject;

  if (clazz !== undefined && clazz !== null) {
    clazzObject = new clazz.prototype.constructor();
  } else {
    //console.log("\nclazzObject creation: ", clazzObject);

    clazzObject = {};
    // if(clazzObject != undefined && clazzObject != null)
    // {
    //    clazzObject = new Object();
    // }
    // else {
    //   clazzObject = null;
    // }

  }

  // var objectKeys = Object.keys(clazzObject);

  for (const i in myKeys) {
    clazzObject[myKeys[i]] = unmarshallRecursive(jsonObject[myKeys[i]], undefined);
  }

  if (clazzObject['@Id'] != undefined && clazzObject['@Id'] != null) {
    const id = clazzObject['@Id'];
    atIdUnMarshallingMap.set(id, clazzObject);
  }

  if (JSON.stringify(clazzObject) === '{}') {
    return null;
  }

  return clazzObject;
}
