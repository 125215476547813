import { CountryStartDateConfiguration } from "../../../../setup/models/country-start-date-configuration.model";
import { Response } from "../../../../../../urdad/services/models/response.model";
import { OrderItem } from "../../../../../checkout/models/order-item.model";
import { UnderwriterIdentifier } from "../../../../identification/models/underwriter-identifier.model";

export class InitialiseExtendInsurancePolicyResponse extends Response {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.InsurancePolicyInitialisationManager.InitialiseExtendInsurancePolicyResponse";
  }

  public countryStartDateConfiguration: CountryStartDateConfiguration;
  public stored: OrderItem[] = [];
  public underwriterIdentifier: UnderwriterIdentifier;
}
