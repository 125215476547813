import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    MarshallerService,
    ProvideFeatureToggleSystemParameterRequest,
    ProvideSystemParameterRequest,
    ProvideSystemParameterResponse,
    SystemParameterNameTypeCriteria,
    UnMarshallerService,
    ValueType
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class SystemParameterProviderService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,  private unMarshallerService: UnMarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  provideSystemParameter(provideSystemParameterRequest: ProvideSystemParameterRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/system/systemparameterprovider/providesystemparameter';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSystemParameterRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }


  provideFeatureToggleSystemParameter(provideFeatureToggleSystemParameterRequest: ProvideFeatureToggleSystemParameterRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/system/systemparameterprovider/providefeaturetogglesystemparameter';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideFeatureToggleSystemParameterRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

  getSystemParameter(parameterName:string): Promise<boolean> {
    const systemParameterNameTypeCriteria = new SystemParameterNameTypeCriteria();
    systemParameterNameTypeCriteria.systemParameterName = parameterName;
    systemParameterNameTypeCriteria.systemParameterType = "Feature Toggle";

    const provideFeatureToggleSystemParameterRequest = new ProvideFeatureToggleSystemParameterRequest();
    provideFeatureToggleSystemParameterRequest.criteria = systemParameterNameTypeCriteria;

    return new Promise<boolean>((resolve, reject) => {

      this.provideFeatureToggleSystemParameter(provideFeatureToggleSystemParameterRequest).then((httpResponse) => {
        if (httpResponse && httpResponse.body) {
            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSystemParameterResponse) as ProvideSystemParameterResponse);
            if (response.systemParameters && response.systemParameters.length > 0) {

              const systemParam = response.systemParameters[0];

              if(systemParam && systemParam.valueType === this.getKeyByValue(ValueType, ValueType.BOOLEAN))
              {
                resolve(systemParam.parameterValue ==='TRUE');
              }
            }
            resolve(false);
        }
      });
    });
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
}
