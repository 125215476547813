import { RetailProfileOwnerIdentifier } from '../../../../../profile/identification/retail-profile-owner-identifier.model';
import { VehicleInsurancePolicy } from '../../../../models/vehicle-insurance-policy.model';
import { Request } from "../../../../../../urdad/urdad_api";

export class InitialiseExtensionValidationRequest extends Request {
    constructor() {
        super();
    }

    public retailProfileOwnerIdentifier: RetailProfileOwnerIdentifier;
    public vehicleInsurancePolicies: VehicleInsurancePolicy[] = [];
}