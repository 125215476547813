import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Model} from '../../../../../models/model.model';

export class FinaliseCreateVehicleModelRequest extends Request {
    constructor() {
        super();
    }

    public model: Model;
}
