import { CountryIdentifier } from "../../../../location/geographic/identification/models/country-identifier.model";
import { Criteria } from "../../../../criteria/models/criteria.model";


export class UnderwriterCountryCriteria extends Criteria{
    constructor(){
        super();
        this['@class']="za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.quicktransact.UnderwriterCountryCriteria"
    }

    public countryIdentifiers: CountryIdentifier[] = [];
}