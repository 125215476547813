import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'


declare const jQuery: any;
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              public runtimeConfigurationService: RuntimeConfigurationService) { }

  ngOnInit() {

      this.route.queryParams.subscribe(param => {
          if (param['coUser']) {
              jQuery([document.documentElement, document.body]).animate({
                  scrollTop: jQuery("#co-user").offset().top - 100
              }, 1000);
          } else if (param['coVendor']) {
              jQuery([document.documentElement, document.body]).animate({
                  scrollTop: jQuery("#co-vendor").offset().top - 100
              }, 1000);
          }
      });

  }

}
