import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
  MarshallerService,
  PublicOTPAuthenticateCredentialsRequest,
  PublicOTPInitialiseAuthenticationRequest,
  PublicOTPValidateObscuredContactNumberRequest
} from '@magnabc/tpi'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class PublicOtpAuthenticatorProvider {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseAuthentication(initialiseAuthenticationRequest: PublicOTPInitialiseAuthenticationRequest) {
        const initialiseAuthenticationURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/publicauthenticator/initialiseauthentication';

        const jsonInitialiseAuthenticationRequest = this.marshallerService.marshallObjectToJSON(initialiseAuthenticationRequest);

        return this.httpClient.post<any>(initialiseAuthenticationURI, jsonInitialiseAuthenticationRequest, this.options).toPromise();
    }

    validateObscuredContactNumber(validateObscuredContactNumberRequest: PublicOTPValidateObscuredContactNumberRequest) {
        const validateObscuredContactNumberURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/publicauthenticator/validateobscuredcontactnumber';

        const jsonValidateObscuredContactNumberRequest = this.marshallerService.marshallObjectToJSON(validateObscuredContactNumberRequest);

        return this.httpClient.post<any>(validateObscuredContactNumberURI, jsonValidateObscuredContactNumberRequest, this.options).toPromise();
    }

    authenticateCredentials(authenticateCredentialsRequest: PublicOTPAuthenticateCredentialsRequest) {
        const authenticateCredentialsURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/publicauthenticator/authenticatecredentials';

        const jsonAuthenticateCredentialsRequest = this.marshallerService.marshallObjectToJSON(authenticateCredentialsRequest);

        return this.httpClient.post<any>(authenticateCredentialsURI, jsonAuthenticateCredentialsRequest, this.options).toPromise();
    }
}
