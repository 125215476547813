import { VehicleIdentifier } from "./vehicle-identifier.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";

export class PlateNumber extends VehicleIdentifier
{
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.identification.PlateNumber";
      }

    public countryOfIssue:CountryIdentifier;
    public number:string;

}
