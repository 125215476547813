import { SnapshotLegalEntity } from "./snapshot-legal-entity.model";
import { InsurancePolicyItem } from './insurance-policy-item.model';
import { UnderwriterIdentifier } from '../identification/models/underwriter-identifier.model';

export abstract class InsurancePolicy {
    constructor(){}

    public number: string;
    public snapshotLegalEntity: SnapshotLegalEntity;
    public insurancePolicyItems: InsurancePolicyItem[] = [];
    public underwriterIdentifier: UnderwriterIdentifier;
}
