import {Component, OnInit, ViewChild} from "@angular/core"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {ActivatedRoute} from "@angular/router"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component"
import {environment} from "../../../../../../environments/environment"
import {Location} from "@angular/common"
import {ErrorUtils} from "../../../../../common/utils/utils.error"
import {
  RetailProductsPriceComponent
} from "../../../../../view.components/retail/insurance/insurance-product-manager/product-price/retail.products.price.component"
import {
  ComprehensiveInsuranceProduct,
  FinaliseUpdateInsuranceProductRequest,
  ProductCriteria,
  ProductNumberIdentifier,
  ProvideInsuranceProductInformationRequest,
  ProvideInsuranceProductInformationResponse,
  ProvideUnderwriterInformationRequest,
  ProvideUnderwriterInformationResponse,
  ThirdPartyInsuranceProduct,
  TpiIdentifier,
  Underwriter,
  UnderwriterLegalEntityCriteria,
  UniversalExcess,
  UnMarshallerService
} from "@magnabc/tpi"
import {AuthenticationService} from "../../../../../app.services/managers/authentication/authentication.service"
import {AgentManagerService} from "../../../../../app.services/managers/agent-manager/agent-manager.service"
import {
  InsuranceProductManagerService
} from "../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service"
import {
  InsuranceProductInformationProviderService
} from "../../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service"
import {
  UnderwriterInformationProviderService
} from "../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {Title} from '@angular/platform-browser'
import {
  RetailProductsDetailsComponent
} from "../../../../../view.components/retail/insurance/insurance-product-manager/create-product-details/product-details/retail.products.details.component"
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
  InsuranceProductFinalisationManagerService
} from '../../../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    templateUrl: './retail-products-update-page.component.html',
    styleUrls: ['./retail-products-update-page.component.scss']
})
export class RetailProductsUpdatePageComponent extends FormComponent implements OnInit {

    product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct;
    underwriters: Underwriter[] = [];
    isValid = false;

    activeToast: ActiveToast<any>;

    @ViewChild(RetailProductsDetailsComponent) detailsComponent: RetailProductsDetailsComponent;
    @ViewChild(RetailProductsPriceComponent) priceComponent: RetailProductsPriceComponent;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private route: ActivatedRoute,
                private location: Location,
                private agentService: AgentManagerService,
                private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
                private retailProductService: InsuranceProductManagerService,
                private thirdPartyInsuranceProductInformationProviderService: InsuranceProductInformationProviderService,
                private authenticationService: AuthenticationService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private toastr: ErrorToastService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Update Product - Console - ${this.runtimeConfigurationService.title}`);

    }

    ngOnInit(): void {

        this.getUnderwriters();

    }

    getUnderwriters() {

        let legalEntityIdentifier = null;
        for (const identifier of this.authenticationService.getLinkedToLegalEntityIdentifiers()) {
            if (identifier['@class'] === new TpiIdentifier()['@class']) {
                legalEntityIdentifier = identifier;
            }
        }

        const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
        underwriterLegalEntityCriteria.legalEntityIdentifiers = [this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier];
        const provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
        provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
        this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
            if (response && response.body) {
                const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                this.underwriters = provideUnderwriterInformationResponse.underwriters;
            }
        });

    }

    remove(): void {

        this.translate.get('TOAST.DELETE_PRODUCT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.product.name}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.product.active = false;
                        this.submit();
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    submit(): void {
        this.detailsComponent.submit();
    }

    onDetailsValid(event): void {
        this.priceComponent.submit();
    }

    onPriceValid(event): void {

        const updateProductRequest = new FinaliseUpdateInsuranceProductRequest();
        for(const exess of this.product.excess){
            if(exess instanceof UniversalExcess ||
                exess['@class'] === 'za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.UniversalExcess'){
                if((exess as UniversalExcess).description && (exess as UniversalExcess).percentage){
                    this.isValid = true
                }else {
                    //Excess is not required, If not set no need to have it;
                    delete this.product.excess;
                }
            }
        }

        updateProductRequest.product = this.product;
        this.startLoad();
        this.insuranceProductFinalisationManagerService.finaliseUpdateInsuranceProduct(updateProductRequest).subscribe(response => {
            this.stopLoad();
            if (response.messages) {
                this.toast();
            }
        });

    }

    onProduct(product): void {
        this.product = product;
    }

    getProduct(number): void {

        const productNumberIdentifier = new ProductNumberIdentifier();
        productNumberIdentifier.number = number;

        const provideInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
        const insuranceProductCriteria = new ProductCriteria();
        insuranceProductCriteria.productIdentifier = productNumberIdentifier;
        provideInsuranceProductInformationRequest.criteria = insuranceProductCriteria;

        this.startLoad();
        this.thirdPartyInsuranceProductInformationProviderService.provideInsuranceProductInformation(provideInsuranceProductInformationRequest).toPromise().then(response => {
            if (response && response.body) {
                const provideThirdPartyInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
                // this.retailProductService.initialiseInsuranceProduct().then(() => {
                //     this.product = provideThirdPartyInsuranceProductInformationResponse.products[0] as any;
                //     this.stopLoad();
                // }).catch(() =>{
                //     this.stopLoad();
                // });
            }
        }).catch(() =>{
            this.stopLoad();
        });

    }

    toast() {

        this.translate.get('TOAST.UPDATE_PRODUCT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.product.name}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });

    }

}
