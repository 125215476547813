import { Money } from "../../../financial/financial_api";
import { FinancialAccountIdentifier } from "../../financialaccount/identification/models/financial-account-identifier.model";
import { JournalEntryType } from "../../models/journal-entry-type.model";
export class Entry
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.accountingtransaction.Entry";
    }

    public amount: Money;
    public entryType: JournalEntryType;
    public accountIdentifier: FinancialAccountIdentifier;
}