import {Request} from '../../../../../../urdad/services/models/request.model';
import {CountryIdentifier} from '../../../../../../location/geographic/identification/models/country-identifier.model';
import { Item } from '../../../../models/item.model';

export class CompileCheckoutItemGroupingRequest extends Request {
    constructor(){
        super();
    } 

    public countryIdentifier: CountryIdentifier;
    public items: Item [] = [];
}