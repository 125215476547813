import { InsurancePolicy } from '../../../../../../insurance/models/insurance-policy.model';
import { Response } from '../../../../../../../urdad/services/models/response.model';
import { RetailVehicle } from '../../../../../models/retail-vehicle.model';
import { Vehicle } from '../../../../../../../vehicle/models/vehicle.model';

export class ProvideRetailProfileVehiclesResponse extends Response {
    constructor(){
        super();
    }

    public vehicles: Vehicle[] = [];
}