import { Payment} from '../../../../../../../../../index';
import {Response} from '../../../../../../../../urdad/services/models/response.model';
import { PaymentAdvice } from '../../../../../../models/payment-advice.model';

export class ProcessTokenPaymentResponse extends Response{
    constructor(){
        super();
    }

    public payment: Payment;
    public paymentAdvice: PaymentAdvice;
}
