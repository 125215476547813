import { CountryIdentifier } from "../../../location/location_api";
import { Criteria } from "../../../criteria/criteria_api";
import { DepositMethodName } from "./deposit-method-name.model";

export class SingleDepositMethodCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.deposit.SingleDepositMethodCriteria";
    }

    public countryIdentifier : CountryIdentifier;
    public depositMethodName : DepositMethodName;
}