import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthoriseOverrideRequest, JudicialPerson, LegalEntityDescriptionIdentifier, LegalEntityIdentifier, PlainTextUsernameAndPassword } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../app.services/managers/authentication/authentication.service';
import { SupervisorOverrideManagerService } from '../../../http.services/security/supervisor-override-manager/supervisor-override-manager.service';
import { FormComponent } from '../../shared/form/form.component';
import { Utils } from '../../shared/Utils/Utils';

@Component({
  selector: 'app-supervisor-override-dialog',
  templateUrl: './supervisor-override-dialog.component.html',
  styleUrls: ['./supervisor-override-dialog.component.scss']
})
export class SupervisorOverrideDialogComponent extends FormComponent {

  formGroup: UntypedFormGroup;
  username: UntypedFormControl;
  password: UntypedFormControl;
  authentic: boolean;
  returnCredentials: boolean = false;

  legalEntityDescriptionIdentifier: LegalEntityIdentifier;
  judicialPerson: JudicialPerson;

  dialogTitle: string;
  dialogSubTitle: String;

  @ViewChild('form', { static: true }) form: FormGroupDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef:MatDialogRef<SupervisorOverrideDialogComponent>,
    private supervisorOverrideManagerService: SupervisorOverrideManagerService,
    private translate: TranslateService,
    private translateParser: TranslateParser,
    private authenticationService: AuthenticationService,
    ){
      super(translate, translateParser);

      this.username = new UntypedFormControl('', [
          Validators.required
      ]);

      this.password = new UntypedFormControl('', [
          Validators.required
      ]);

      this.formGroup = new UntypedFormGroup({
          'username': this.username,
          'password': this.password
      });

      this.legalEntityDescriptionIdentifier = this.authenticationService.getLegalEntityDescriptionIdentifier().tpiIdentifier;
      this.judicialPerson = this.authenticationService.getLinkedToJudicialPerson();

      this.authenticationService.getLinkedToJudicialPerson().name,
      Utils.getLegalEntityDescriptionIdentifier(
          this.authenticationService.getLinkedToJudicialPerson()
              .legalEntityIdentifiers
      )

      if(data != undefined && data != null){
        this.returnCredentials = data.returnCredentials;
      }

      if(data != undefined && data != null){
        this.dialogTitle = data.title;
      }
      else{
        this.translate.get("SECURITY.SUPERVISOR").subscribe((response) => {
          this.dialogTitle = response;
        });
      }

      if(data != undefined && data != null){
        this.dialogSubTitle = data.message + "?";
      }
      else{
        this.dialogSubTitle = "";
      }
    }

    closeDialog() {
      this.dialogRef.close();
    }

    onCheckValidation(event): void {

      if(this.form.valid) {

        const authoriseOverrideRequest = new AuthoriseOverrideRequest();
        const emailAddressAndPassword = new PlainTextUsernameAndPassword();
        emailAddressAndPassword.username = this.username.value;
        emailAddressAndPassword.password = this.password.value;
        authoriseOverrideRequest.credentials = emailAddressAndPassword;

        var legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
        const naturalPerson = this.authenticationService.getLoggedInNaturalPerson();

        legalEntityDescriptionIdentifier.name = naturalPerson.firstNames;
        legalEntityDescriptionIdentifier.surname = naturalPerson.surname;
        legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier(naturalPerson.legalEntityIdentifiers)

        authoriseOverrideRequest.overrideUser =  Utils.getLegalEntityDescriptionIdentifier(this.authenticationService.getLinkedToJudicialPerson().legalEntityIdentifiers);
        authoriseOverrideRequest.loggedInUser =  legalEntityDescriptionIdentifier;

        this.startLoad();
        this.supervisorOverrideManagerService.authoriseOverride(authoriseOverrideRequest).then(httpResponse => {
          this.stopLoad();

          if (httpResponse && httpResponse.body) {
            this.authentic = true;

            if(this.returnCredentials){
              setTimeout(() => {
                this.dialogRef.close(emailAddressAndPassword);
              }, 1000);
            }
            else{
              setTimeout(() => {
                this.dialogRef.close(true);
              }, 2500);
            }
          }
          else{
            this.authentic = false;
          }
        }).catch(exception => {
          this.stopLoad();
          this.authentic = false;
        });
      }
  }
}
