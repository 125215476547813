
import { Criteria } from "../../../criteria/criteria_api";
import { UnderwriterIdentifier } from "../identification/identification_api";
export class BrokerUnderwriterCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.BrokerUnderwriterCriteria";
    }

    public underwriterIdentifier: UnderwriterIdentifier;
}
