import { Component, Input, OnInit } from '@angular/core';
import { ComprehensiveInsuranceProduct, CountryDescriptionIdentifier, InsuranceType, InsuranceVehicleCategory, Product, ThirdPartyInsuranceProduct, Underwriter, YCInsuranceProduct, CountryIdentifier, ProductNumberIdentifier } from '@magnabc/tpi';
import { StringUtils } from "../../../../../common/utils/utils.string";
import { InsuranceProductManagerService } from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

    @Input() product: Product;
    @Input() underwriter: Underwriter;
    @Input() associatedCoveredCountries: CountryIdentifier[];

    underwriterName = "";
    annualAmount;
    annualPercentage;
    insuranceType: InsuranceType;
    insuranceVehicleCategory: InsuranceVehicleCategory;
    showCoveredCountries = false


    constructor(public insuranceProductManagerService: InsuranceProductManagerService) { }

    ngOnInit() {
        this.underwriterName = this.underwriter.name;

        if (this.product instanceof ThirdPartyInsuranceProduct) {
            this.annualAmount = this.product.annualAmount;
            this.insuranceType = this.product.insuranceType;
            this.insuranceVehicleCategory = this.product.insuranceVehicleCategory;
        } else if (this.product instanceof ComprehensiveInsuranceProduct) {
            this.annualPercentage = this.product.annualPercentage;
            this.insuranceType = new InsuranceType();
            this.insuranceType.description = "Comprehensive";
            this.insuranceVehicleCategory = this.product.insuranceVehicleCategory;
        } else if (this.product instanceof YCInsuranceProduct) {
            this.insuranceType = new InsuranceType();
            this.insuranceType.description = "Yellow Card";
            this.insuranceVehicleCategory = this.product['insuranceVehicleCategories'];
            this.getCoveredCountries();
            this.showCoveredCountries = true;
        }

    }

    getCoveredCountries() {
        this.product['coveredCountries'] = [];
        const coveredCountries = this.product['ycPriceSchedules'] as any;
        for (const country of coveredCountries) {
            const index = this.product['coveredCountries'].findIndex(object => object['description'] === country.country)
            if (country.country && index === -1) {
                const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                countryDescriptionIdentifier.description = country.country
                this.product['coveredCountries'].push(countryDescriptionIdentifier);
            }
        }
        if (this.product instanceof YCInsuranceProduct) {
            const productIdentifiers = this.product.productIdentifiers.map((item: ProductNumberIdentifier) => item.number);
            const countryIdentifiers = this.insuranceProductManagerService.addOnProductOptions.filter((item) => productIdentifiers.includes(item.number))
                .flatMap((item: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct) => item.coveredCountries);
                this.product['coveredCountries'] = this.product['coveredCountries'].concat(countryIdentifiers);
        }
    }

    formatCurrency(value): string {
        return StringUtils.formatCents(value);
    }

    getFlagUrl(description: string): string {
        if (description === 'Democratic Republic of the Congo') {
            return '/assets/images/flags/flag-of-Congo-Democratic-Republic-of.png';
        }

        return '/assets/images/flags/flag-of-' + description + '.png';
    }

    get coveredCountries(): CountryIdentifier[] {
        if (this.product instanceof YCInsuranceProduct || this.product instanceof ThirdPartyInsuranceProduct || this.product instanceof ComprehensiveInsuranceProduct) {
            const items: CountryIdentifier[] = [];
            let extraCountries = this.associatedCoveredCountries.map((item: CountryDescriptionIdentifier) => item.description);
            extraCountries = [... new Set(extraCountries)];
            this.product.coveredCountries.forEach((country: CountryDescriptionIdentifier) => {
                if (!extraCountries.includes(country.description) && !items.find((x) => (x as CountryDescriptionIdentifier).description
                    === country.description)) {
                    items.push(country);
                }
            });
            return items.concat(this.associatedCoveredCountries);
        }
        return [];
    }
}
