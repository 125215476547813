import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrderQueryComponent} from './order-query/order-query.component'
import {PolicyViewComponent} from './policy-view/policy-view.component'
import {SummaryDialogComponent} from './summary-dialog/summary-dialog.component'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {LoadingModule} from 'ngx-loading'
import {SharedModule} from '../../../shared/shared.module'
import {MatFormFieldModule} from '@angular/material/form-field'
import {PolicyModule} from '../policy/policy.module'
import { MatInputModule } from '@angular/material/input';



@NgModule({
    declarations: [
        OrderQueryComponent,
        PolicyViewComponent,
        SummaryDialogComponent
    ],
    exports: [
        OrderQueryComponent,
        OrderQueryComponent,
        PolicyViewComponent
    ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        SharedModule,
        MatFormFieldModule,
        PolicyModule,
        MatInputModule
    ]
})
export class ThirdPartyInsuranceModule { }
