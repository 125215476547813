import {Request} from "../../../../../../../urdad/services/models/request.model";
import {ServiceGroupIdentifier} from "../../../../../identification/models/service-group-identifier.model";

export class FinaliseDeleteServiceGroupRequest extends Request {
    constructor() {
        super();
    }

    public serviceGroupIdentifier: ServiceGroupIdentifier;
}
