import {Component, Inject, OnInit, ViewChild} from '@angular/core'
import {
    ExtensionInsurancePolicyCartItem,
    InitialiseExtendInsurancePolicyRequest,
    InitialiseExtendInsurancePolicyResponse,
    Money,
    OrderItem,
    UnMarshallerService
} from '@magnabc/tpi'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {
    ServiceGroupFinalisationManagerService
} from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service'
import {
    InsurancePolicyInitialisationManagerService
} from '../../../../http.services/retail/insurance/insurance-policy-initialisation-manager/insurance-policy-initialisation-manager.service'
import {ProductActionEnum} from '../insurance-product-information-provider/product/product-action.enum'
import {ExtendInsurancePolicyComponent} from '../extend-insurance-policy/extend-insurance-policy.component'

@Component({
  selector: 'app-insurance-policy-manager-extension-dialog',
  templateUrl: './insurance-policy-manager-extension-dialog.component.html',
  styleUrls: ['./insurance-policy-manager-extension-dialog.component.scss']
})
export class InsurancePolicyManagerExtensionDialogComponent implements OnInit {

    item: ExtensionInsurancePolicyCartItem
    orderItems: OrderItem[]
    action = ProductActionEnum.ADD_TO_CART
    policyNumber: string
    orderItemNumbers: string[]
    editOrderItem: OrderItem
    endDate: any
    total: Money

    @ViewChild(ExtendInsurancePolicyComponent) extendInsurancePolicyComponent: ExtendInsurancePolicyComponent

    constructor(public dialogRef: MatDialogRef<InsurancePolicyManagerExtensionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: any, public authenticationService: ServiceGroupFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private insurancePolicyInitialisationManagerService: InsurancePolicyInitialisationManagerService) {
        this.action = data.action
        this.policyNumber = data.policyNumber
        this.orderItemNumbers = data.orderItemNumbers
        this.editOrderItem = data.editOrderItem
        this.endDate = data.endDate
        this.item = data.item
    }

    ngOnInit(): void {
        if (this.action == ProductActionEnum.ADD_TO_CART) {
            const initialiseExtendInsurancePolicyRequest = new InitialiseExtendInsurancePolicyRequest()
            initialiseExtendInsurancePolicyRequest.policyNumber = this.policyNumber;
            initialiseExtendInsurancePolicyRequest.orderItemNumber = null; // FIXME DCE this.orderItemNumbers[0]
            this.insurancePolicyInitialisationManagerService.initialiseExtendInsurancePolicy(initialiseExtendInsurancePolicyRequest).subscribe((httpResponse) => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, InitialiseExtendInsurancePolicyResponse) as InitialiseExtendInsurancePolicyResponse);
                    let result: OrderItem[] = [];
                    if (this.orderItemNumbers && this.orderItemNumbers !== null) {
                        result = response.stored.filter(item => this.orderItemNumbers.includes(item.number));
                    }
                    this.orderItems = result;
                }
            })
        } else if (this.action == ProductActionEnum.UPDATE_ITEM) {
            this.orderItems = [this.editOrderItem]
        }
    }

    close(reload: boolean): void {
        console.log(reload)
        if (this.extendInsurancePolicyComponent.captureImages) {
            this.extendInsurancePolicyComponent.onCloseCaptureImages(null)
        } else {
            this.dialogRef.close(reload)
        }
    }

}
