import {Criteria} from "../../../criteria/criteria_api"
import {InsuranceCategory} from './insurance-category.model'

export class YCInsurancePolicyItemCertificateCriteria extends Criteria {

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.YCInsurancePolicyItemCertificateCriteria";
    }

    public certificateNumber = false;
    public insuranceCategory = InsuranceCategory.YELLOWCARD;
    public ycPolicyNumber = false;
}
