import {Request} from '../../../../../../../../urdad/services/models/request.model';
import {Limit} from '../../../../../../models/limit.model';
import {MotorInsuranceDetails} from '../../../../../../../checkout/models/motor-insurance-details.model';
import {LegalEntityIdentifier} from '../../../../../../../../entity/identification/models/legal-entity-identifier.model';

export class CalculateRealTimePriceRequest extends Request{

    constructor(){
        super();
    }

    public limit: Limit[];
    public motorInsuranceDetails: MotorInsuranceDetails;
    public legalEntityIdentifier: LegalEntityIdentifier;

}
