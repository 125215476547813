import { RenderedDocumentType } from "../../rendering/models/rendered-document-type.model";
import {ConceptualDocumentType} from './conceptual-document-type.model'

export abstract class ConceptualDocument
{
  constructor() {
  }

  public documentId: string;
  public conceptualDocumentType: ConceptualDocumentType;
}
