export enum ConceptualDocumentType {
    INSURANCE_CERTIFICATE = "INSURANCE_CERTIFICATE",
    ORDER = "ORDER",
    ACCOUNT_STATEMENT_REPORT = "ACCOUNT_STATEMENT_REPORT",
    INVOICE = "INVOICE",
    SUPPLIER_INVOICE = "SUPPLIER_INVOICE",
    PROPOSAL = "PROPOSAL",
    PROPOSAL_CONFIRMATION = "PROPOSAL_CONFIRMATION",
    CUSTOMER_INVOICE = "CUSTOMER_INVOICE",
    POLICYSCHEDULE = "POLICYSCHEDULE"
}