
import { Criteria } from "../../../criteria/criteria_api";
import { LegalEntityIdentifier } from "../../../entity/entity_api";
export class BrokerLegalEntityCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.BrokerLegalEntityCriteria";
    }

    public legalEntity: LegalEntityIdentifier;
}
