import { Dropdown } from './../../../../common/model/dropdown.model';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormComponent } from "../../../shared/form/form.component"
import {
  CountryRegistrationConfigurationInformation,
  JudicialPersonCountryRegistrationConfigurationInformation,
  LegalEntityDetails,
  RequiredJudicialPersonCountryRegistrationInformation,
  RequiredNaturalPersonCountryRegistrationInformation,
  ShownJudicialPersonCountryRegistrationInformation,
  ShownNaturalPersonCountryRegistrationInformation
} from "@magnabc/tpi"
import { FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { TranslateParser, TranslateService } from "@ngx-translate/core"

declare const jQuery: any;
@Component({
  selector: 'app-proof-of-existence',
  templateUrl: './proof-of-existence.component.html',
  styleUrls: ['./proof-of-existence.component.scss']
})
export class ProofOfExistenceComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() show: ShownJudicialPersonCountryRegistrationInformation | ShownNaturalPersonCountryRegistrationInformation;
  @Input() hideBorder: boolean;
  @Input() required: RequiredJudicialPersonCountryRegistrationInformation | RequiredNaturalPersonCountryRegistrationInformation;
  @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
  @Output() onProofOfExistence = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<void>();
  @Input() legalEntityDetails: LegalEntityDetails;
  @Output() onLegalEntityDetails = new EventEmitter<LegalEntityDetails>();

  formGroup: UntypedFormGroup;
  authorisedSignatoryName: UntypedFormControl;
  vatNumber: UntypedFormControl;
  authorisedSignatoryPosition: UntypedFormControl;
  seniorManagerNameOne: UntypedFormControl;
  seniorManagerNameTwo: UntypedFormControl;
  seniorManagerNameThree: UntypedFormControl;
  seniorManagerNameFour: UntypedFormControl;
  occupation: UntypedFormControl;
  politicallyExposedPerson: UntypedFormControl;
  otherOccupation: UntypedFormControl;

  showOccupation = false;
  showPolicallyExposed = false;

  politicallyExposedOptions = [
    new Dropdown(true, "Yes"),
    new Dropdown(false, "No")
  ];
  occupations = [
    new Dropdown(1, "Civil Servant"),
    new Dropdown(2, "Consultant"),
    new Dropdown(3, "Educator"),
    new Dropdown(4, "Farmer"),
    new Dropdown(5, "Finance"),
    new Dropdown(6, "Lawyer"),
    new Dropdown(7, "Marketer"),
    new Dropdown(8, "Medical"),
    new Dropdown(9, "Miner"),
    new Dropdown(10, "Politicial"),
    new Dropdown(11, "Retailer"),
    new Dropdown(12, "Student"),
    new Dropdown(13, "Technical"),
    new Dropdown(14, "Technology"),
    new Dropdown(15, "Tourism"),
    new Dropdown(16, "Other")
  ];
  showTextbox = false;

  @ViewChild('form') form: FormGroupDirective;

  constructor(translateService: TranslateService, translateParser: TranslateParser) {
    super(translateService, translateParser);
  }

  ngOnInit() {
    this.setShowLegalEntityDetailFields();

    let authorisedSignatoryName = ''
    let authorisedSignatoryPosition = ''
    let seniorManagerNames: string[] = []
    let vatNumber = this.countryRegistrationConfigurationInformation.vatNumber

    if (this.countryRegistrationConfigurationInformation instanceof JudicialPersonCountryRegistrationConfigurationInformation) {
      authorisedSignatoryName = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).authorisedSignatoryName
      authorisedSignatoryPosition = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).authorisedSignatoryPosition
      seniorManagerNames = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).seniorManagerNames
    }

    this.authorisedSignatoryName = new UntypedFormControl(authorisedSignatoryName, [
      Validators.maxLength(30)
    ]);

    this.authorisedSignatoryPosition = new UntypedFormControl(authorisedSignatoryPosition, [
      Validators.maxLength(30)
    ]);

    this.vatNumber = new UntypedFormControl(vatNumber, [
      Validators.maxLength(30)
    ]);

    if (seniorManagerNames.length > 0) {
      this.seniorManagerNameOne = new UntypedFormControl(seniorManagerNames[0], [
        Validators.maxLength(30)
      ]);
    } else {
      this.seniorManagerNameOne = new UntypedFormControl('', [
        Validators.maxLength(30)
      ]);
    }

    if (seniorManagerNames.length > 1) {
      this.seniorManagerNameTwo = new UntypedFormControl(seniorManagerNames[1], [
        Validators.maxLength(30)
      ]);
    } else {
      this.seniorManagerNameTwo = new UntypedFormControl('', [
        Validators.maxLength(30)
      ]);
    }

    if (seniorManagerNames.length > 2) {
      this.seniorManagerNameThree = new UntypedFormControl(seniorManagerNames[2], [
        Validators.maxLength(30)
      ]);
    } else {
      this.seniorManagerNameThree = new UntypedFormControl('', [
        Validators.maxLength(30)
      ]);
    }

    if (seniorManagerNames.length > 3) {
      this.seniorManagerNameFour = new UntypedFormControl(seniorManagerNames[3], [
        Validators.maxLength(30)
      ]);
    } else {
      this.seniorManagerNameFour = new UntypedFormControl('', [
        Validators.maxLength(30)
      ]);
    }

    if (this.required !== undefined) {
        if ((this.required as RequiredJudicialPersonCountryRegistrationInformation).authorisedSignatoryName) {
            this.authorisedSignatoryName.setValidators([
              Validators.required,
              Validators.maxLength(30)
            ]);
          }
          if ((this.required as RequiredJudicialPersonCountryRegistrationInformation).authorisedSignatoryPosition) {
            this.authorisedSignatoryPosition.setValidators([
              Validators.required,
              Validators.maxLength(30)
            ]);
          }
          if ((this.required as RequiredJudicialPersonCountryRegistrationInformation).seniorManagerName) {
            this.seniorManagerNameOne.setValidators([
              Validators.required,
              Validators.maxLength(30)
            ]);
          }
          if ((this.required as RequiredJudicialPersonCountryRegistrationInformation).vatNumber) {
            this.vatNumber.setValidators([
              Validators.required,
              Validators.maxLength(30)
            ]);
          }
    }

    this.occupation = new UntypedFormControl(null, [this.showOccupation? Validators.required : Validators.nullValidator]);
    this.politicallyExposedPerson = new UntypedFormControl(null, [this.showPolicallyExposed? Validators.required : Validators.nullValidator]);
    this.otherOccupation = new UntypedFormControl('', []);

    this.formGroup = new UntypedFormGroup({
      'authorisedSignatoryName': this.authorisedSignatoryName,
      'authorisedSignatoryPosition': this.authorisedSignatoryPosition,
      'seniorManagerNameOne': this.seniorManagerNameOne,
      'seniorManagerNameTwo': this.seniorManagerNameTwo,
      'seniorManagerNameThree': this.seniorManagerNameThree,
      'seniorManagerNameFour': this.seniorManagerNameFour,
      'vatNumber': this.vatNumber,
      'occupation': this.occupation,
      'otherOccupation': this.otherOccupation,
      'politicallyExposedPerson': this.politicallyExposedPerson,
    });

    this.setLegalEntityDetailControls();
  }

  private setLegalEntityDetailControls(): void {
    if (this.legalEntityDetails) {
      const occupation = this.legalEntityDetails.occupation;
      if (occupation) {
        const occupationCheck = this.getDropdown(this.occupations, occupation);
        if (occupationCheck === null) {
          this.otherOccupation.setValue(occupation);
          this.showTextbox = true;
          this.occupation.setValue(this.occupations[15]);
        } else {
          this.occupation.setValue(occupationCheck)
        }
        const politicallyExposedPerson = this.legalEntityDetails.politicallyExposedPerson;
        if (politicallyExposedPerson !== null) {
          this.politicallyExposedPerson.setValue(this.getDropdown(this.politicallyExposedOptions, politicallyExposedPerson ?
            this.politicallyExposedOptions.at(0).name : this.politicallyExposedOptions.at(1).name));
        }
      }
    }
  }

  private setShowLegalEntityDetailFields(): void {
      if(this.show instanceof ShownNaturalPersonCountryRegistrationInformation){
        this.showOccupation = this.show.occupation;
        this.showPolicallyExposed = this.show.politicallyExposed;
      }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngOnDestroy(): void {
    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
  }

  canDeactivate(): boolean {
    return !this.form || this.form.submitted || !this.form.dirty;
  }

  submit(): void {
    document.getElementById('form-submit-proof-of-existence').click();
  }

  onCheckValidation(event): void {
    if (!this.form.invalid) {
      let authorisedSignatoryName = this.authorisedSignatoryName.value;
      let authorisedSignatoryPosition = this.authorisedSignatoryPosition.value;
      let seniorManagerNames: string[] = []
      let vatNumber = this.vatNumber.value;

      if (this.seniorManagerNameOne.value.length > 0) {
        seniorManagerNames.push(this.seniorManagerNameOne.value);
      }
      if (this.seniorManagerNameTwo.value.length > 0) {
        seniorManagerNames.push(this.seniorManagerNameTwo.value);
      }
      if (this.seniorManagerNameThree.value.length > 0) {
        seniorManagerNames.push(this.seniorManagerNameThree.value);
      }
      if (this.seniorManagerNameFour.value.length > 0) {
        seniorManagerNames.push(this.seniorManagerNameFour.value);
      }

      this.onProofOfExistence.emit({
        authorisedSignatoryName: authorisedSignatoryName,
        authorisedSignatoryPosition: authorisedSignatoryPosition,
        seniorManagerNames: seniorManagerNames,
        vatNumber: vatNumber
      });
      this.onValid.emit();

    } else {

      const target = jQuery('form .ng-invalid:first');

      if (target) {
        jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
        target.focus();
      }

    }

  }

  valid(): boolean {
    return this.form.valid;
  }

  onPoliticallyExposedChange(): void {
    this.emitLegalEntityDetails();
  }

  onOccupationChanged(occupation) {
    if (occupation.value.name === "Other") {
      this.showTextbox = true;
      if (this.otherOccupation) {
        this.otherOccupation.setValidators(Validators.required);
        if(!this.formGroup.controls['otherOccupation']){
          this.formGroup.addControl('otherOccupation', this.otherOccupation);
        }
      }
    } else {
      this.showTextbox = false;
      if (this.otherOccupation) {
        this.otherOccupation.removeValidators(Validators.required);
        this.formGroup.removeControl('otherOccupation');
      }
    }
    this.otherOccupation.reset();
    this.emitLegalEntityDetails();
  }

    private emitLegalEntityDetails(): void {
        this.legalEntityDetails = new LegalEntityDetails();

        if (this.occupation.value) {
            const occupationCheck = this.getDropdown(this.occupations, this.occupation.value.name);
            if (occupationCheck.name === this.occupations[15].name) {
                this.legalEntityDetails.occupation = this.otherOccupation.value;
            } else {
                this.legalEntityDetails.occupation = this.occupation.value.name;
            }
        }
        if (this.politicallyExposedPerson.value) {
            this.legalEntityDetails.politicallyExposedPerson = this.politicallyExposedPerson.value.value;
        }
        this.onLegalEntityDetails.emit(this.legalEntityDetails);
    }
}

export interface PoliticallyExposedOption {
  name: string,
  value: boolean
}
