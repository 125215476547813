import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AccountIdentifier,
    AccountNumberIdentifier,
    AccountUsernameIdentifier,
    PlainTextUsernameAndPassword,
    UnMarshallerService,
    UpdateResetPasswordRequest,
    VerifyAccountLinkResponse
} from "@magnabc/tpi";
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {AccountLinkVerifierManagerService} from '../../../http.services/security/account/account-link-verifier-manager/account-link-verifier-manager.service';
import {FormComponent} from '../../../view.components/shared/form/form.component';
import {CredentialsComponent} from '../../../view.components/security/credentials/credentials.component';
import {ActiveToast, ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";
import {ToastComponent} from "../../../view.components/shared/toast/toast.component";
import {BackOfficePasswordUpdateManagerService} from '../../../http.services/security/account/back-office-password-update-manager/back-office-password-update-manager.service';
import {Title} from '@angular/platform-browser';
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-account-link-verification',
    templateUrl: './account-link-verification-page.component.html',
    styleUrls: ['./account-link-verification-page.component.scss']
})
export class AccountLinkVerificationPageComponent extends FormComponent implements OnInit {

    hideErrorState = false;
    passwordForm: UntypedFormGroup;
    entityFormGroup: UntypedFormGroup;
    passwordVerify: UntypedFormControl;
    password: UntypedFormControl;
    username: UntypedFormControl;
    passwordLogin: UntypedFormControl;
    token: string;
    accountIdentifier: AccountIdentifier
    accountUsernameIdentifier: AccountUsernameIdentifier
    accountUsername: string;
    accountIsValid = false;
    resetPassword = false;

    activeToast: ActiveToast<any>;

    @ViewChild(CredentialsComponent) credentialsComponent: CredentialsComponent;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private title: Title,
                private router: Router, private route: ActivatedRoute,
                private accountLinkVerified: AccountLinkVerifierManagerService,
                private unMarshallerService: UnMarshallerService,
                private backOfficePasswordUpdateManagerService:BackOfficePasswordUpdateManagerService,
                private toastr: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
                super(translate, translateParser);

    }
    onDeactivate(): boolean {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {
        const paramId = this.route.snapshot.paramMap.get('id');
        if (paramId === null || paramId === undefined) {
            this.hideErrorState = true;
            alert('The account verification could not be found.');
        }
        else if(this.route.snapshot.routeConfig.path === "account/reset-password/:id"){
            this.resetPassword = true;
            this.title.setTitle(`Reset Password - ${this.runtimeConfigurationService.title}`);
        }
        else{
            this.verifyAccount(paramId);
            this.title.setTitle(`Create Password - ${this.runtimeConfigurationService.title}`);
        }
    }

    verifyAccount(token: string){
        return new Promise<AccountIdentifier>((resolve, reject) => {
            this.accountLinkVerified.verifyAccountLink(token).then(httpResponse => {
                if (httpResponse && httpResponse.body) {

                    this.accountIsValid = true;
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                    VerifyAccountLinkResponse) as VerifyAccountLinkResponse);

                    this.accountUsername = response.account.username;

                    const accountNumberIdentifier = new AccountNumberIdentifier();
                    accountNumberIdentifier.number = response.account.accountNumber;
                    this.accountIdentifier = accountNumberIdentifier;

                    return;
                }
              }).catch(error => {
                  console.log(error)
                this.translate.get('TOAST.CHECKOUT_ERROR').subscribe((res: any) => {
                    this.activeToast = this.toastr.success(error, res.TITLE, {
                        toastComponent: ToastComponent,
                        timeOut: environment.toast_time_out,
                        progressBar: true

                    });
                    this.stopLoad();
                });
                resolve(null);
                return;

            });
        });
    }

    onSubmit(event): void {
        this.credentialsComponent.submit();
    }

    submitCredentials() {
        //own request
        const token = this.route.snapshot.paramMap.get('id');

        const credentials = new PlainTextUsernameAndPassword();
        credentials.username = this.credentialsComponent.plainTextUsernameAndPassword.username;
        credentials.password = this.credentialsComponent.plainTextUsernameAndPassword.password;

        const updatePasswordRequest = new UpdateResetPasswordRequest();
        updatePasswordRequest.passwordNew =  credentials.password;
        updatePasswordRequest.token = token
        this.startLoad();
        this.backOfficePasswordUpdateManagerService.updateResetPassword(updatePasswordRequest).then((response) => {
            this.stopLoad();
            this.router.navigate(['/console-login']);
        }, (error) => {
            this.translate.get('TOAST.CHECKOUT_ERROR').subscribe((res: any) => {
                this.activeToast = this.toastr.success(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true

                });
                this.stopLoad();
            });
        });
    }

}
