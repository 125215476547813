import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-account-reports-page',
  templateUrl: './account-reports-page.component.html',
  styleUrls: ['./account-reports-page.component.scss']
})
export class AccountReportsPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Accounts Report - Console - ${runtimeConfigurationService.title}`);
    }

}
