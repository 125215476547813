import { Request } from "../../../../../../../urdad/services/models/request.model";
import { OneTimeLinkContext } from "../../../../../models/one-time-link-context.model";
import { EmailAddress } from "../../../../../../../entity/models/email-address.model";

export class IssueOneTimeLinkRequest extends Request{
    constructor(){ 
        super();
    }

    public emailAddress: EmailAddress;
    public transactionIdentifier: string;
    public context: OneTimeLinkContext;

}