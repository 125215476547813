import {Request} from '../../../../../../../urdad/services/models/request.model';
import { RetailProfileIdentifier } from '../../../../../identification/retail-profile-identifier.model';
import { ItemIdentifier } from '../../../../../../checkout/identification/models/item-identifier.model';

export class FinaliseRemoveItemRequest extends Request{
    constructor(){
        super();
    }

    public itemIdentifier: ItemIdentifier;
    public retailProfileIdentifier: RetailProfileIdentifier;
}
