import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"
import {FormComponent} from "../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {FormGroupDirective} from "@angular/forms"
import {ContactNumberComponent} from "../shared/contactnumber/contact_number.component"
import {ToastComponent} from "../shared/toast/toast.component"
import {environment} from "../../../environments/environment"
import {ActiveToast} from "ngx-toastr"
import {ContactNumber, IssueOneTimePinRequest, OneTimePinContext, PinContext} from '@magnabc/tpi'
import {
    OneTimePinIssuanceManagerService
} from '../../http.services/security/onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service'
import {OneTimePinService} from './services/one-time-pin.service'
import {RaygunErrorHandler} from '../../common/utils/utils.raygun'
import {ErrorToastService} from '../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;
declare const document: any;

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
})
export class OTPComponent extends FormComponent implements OnInit {

    @Input() contact: ContactNumber;
    @Input() context: PinContext;
    @Output() onValid = new EventEmitter<void>();
    @Output() onContact = new EventEmitter<ContactNumber>();

    @ViewChild(ContactNumberComponent, { static: true }) contactNumber: ContactNumberComponent;
    @ViewChild('form') form: FormGroupDirective;
    activeToast: ActiveToast<any>;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private toastr: ErrorToastService,
                private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
                private oneTimePinService: OneTimePinService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {
    }

    submit(): void {
        this.contactNumber.submit();
    }

    onContactNumber(contact): void {
        this.contact = contact;
    }

    onSubmit(event): void {
        this.contactNumber.submit();
    }

    onValidContactNumber(event): void {
        const contactNumber = new ContactNumber();
        contactNumber.number = this.contact.number;
        contactNumber.contactNumberType = this.contact.contactNumberType;
        contactNumber.country = this.contact.country;

        const context = new OneTimePinContext();
        context.context = this.context;

        const issueOneTimePinRequest = new IssueOneTimePinRequest();
        issueOneTimePinRequest.contactNumber = contactNumber;
        issueOneTimePinRequest.oneTimePinContext = context;

        this.startLoad();
        this.oneTimePinIssuanceManagerService.issueOneTimePin(issueOneTimePinRequest).then((response) => {
            if (response && response.body) {
                try {
                    this.oneTimePinService.setOneTimePinIdentifier(response)
                    .then(result => {
                        if (result){
                            this.toast();
                            this.stopLoad();
                        }
                    }).catch(reason => {
                        this.stopLoad();
                        RaygunErrorHandler.sendError(reason);
                    });
                } catch (e) {
                    console.error(e);
                    RaygunErrorHandler.sendError(e);
                    this.stopLoad();
                }
            } else {
                throw new Error('Could not retrieve OTP details from server.');
            }
        }).catch((e) => {
            this.toastr.errorToast(e);
            RaygunErrorHandler.sendError(e);
            this.stopLoad();
        });
    }

    private toast() {

        this.translate.get('TOAST.OTP_SENT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.contact.country + this.contact.number}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            // this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        this.onContact.emit(this.contact);
                        this.onValid.emit();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.activeToast.portal.destroy();
                this.onContact.emit(this.contact);
                this.onValid.emit();
            });
        });

    }
}
