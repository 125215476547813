import {Criteria} from '../../../criteria/models/criteria.model';
import {ModelIdentifier} from '../../identification/models/model-identifier.model';

export class ModelDescriptionCriteria extends Criteria {
    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.vehicle.makemodeltrim.ModelDescriptionCriteria";
    }

    public modelIdentifier: ModelIdentifier;
}
