import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-supplier-invoices',
    templateUrl: './back-office-invoices-page.component.html',
    styleUrls: ['./back-office-invoices-page.component.scss']
})
export class BackOfficeInvoicesPageComponent implements OnInit {

    currentTab = 'supplier-invoices';
    constructor(private title: Title,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Supplier Invoices - Console - ${this.runtimeConfigurationService.title}`);
    }

    ngOnInit() {
    }

    onTab(tab: string): boolean {
        this.currentTab = tab;
        if (this.currentTab === 'supplier-invoices') {
            this.title.setTitle(`Supplier Invoices - Console - ${this.runtimeConfigurationService.title}`);
        } else {
            this.title.setTitle(`Customer Invoices - Console - ${this.runtimeConfigurationService.title}`);
        }
        return false;
    }

}
