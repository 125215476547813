import {Request} from '../../../../../../../urdad/services/models/request.model';
import {SupportedCountry} from "../../../../../models/supported-country.model";

export class ProvideSupportedCountriesResponse extends Request{
    constructor(){
        super();
    }

    public supportedCountries: SupportedCountry[];
}
