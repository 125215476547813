import {Criteria} from "../../../criteria/criteria_api";
import {LegalEntityIdentifier} from "../../../entity/identification/models/legal-entity-identifier.model";

export class InsurancePolicyLegalEntityCriteria extends Criteria {

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyLegalEntityCriteria";
    }

    public legalEntityIdentifier: LegalEntityIdentifier;
}
