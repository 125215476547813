import { Money } from "../../../financial/models/money.model";

export class SupplierInvoiceSummary{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.invoice.SupplierInvoiceSummary";
    }

    public number: string;
    public dateFrom: Date;
    public dateTo: Date;
    public totalTransactionAmount: Money;
    public totalThirdPartyFee: Money;
    public totalAdminFee: Money;
    public totalPaidOut: Money;
}