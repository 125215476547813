import { Request } from '../../../../../../../urdad/services/models/request.model';
import { AccountIdentifier } from '../../../../../identification/models/account-identifier.model';


export class UpdatePasswordRequest  extends Request {

    constructor(){
        super()
    }

    public passwordOld: string;
    public passwordNew: string;
    public accountIdentifier: AccountIdentifier;
}