import { Questions } from "./questions.model";

export class ProposalForm {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalForm";
    }

    public proposalFormId: string;
    public dateFrom: Date;
    public dateTo: Date;
    public allowableScore: number;
    public totalScore: number;

    public countryDescription:string;
    public questionsList: Questions[] = [];
}