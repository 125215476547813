import {Injectable} from '@angular/core';
import {
    Address,
    AlphaThree,
    AlphaTwo,
    AuthenticationAdvice,
    BasicPhysicalAddress,
    BasicPostalAddress,
    ContactNumber,
    Country,
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    CountryIdentifier,
    CreateIdentifierRequest,
    CreateIdentifierResponse,
    EmailAddress,
    FinaliseCreateVerifiedAccountByInsertRequest,
    FinaliseCreateVerifiedAccountByInsertResponse,
    FinaliseCreateVerifiedAccountByUpdateRequest,
    FinaliseCreateVerifiedAccountByUpdateResponse,
    IdentifierType,
    InitialiseCreateVerifiedAccountRequest,
    InitialiseCreateVerifiedAccountResponse,
    IssueOneTimePinRequest,
    IssueOneTimePinResponse,
    MalawiPhysicalAddress,
    NaturalPerson,
    NaturalPersonIdentifier,
    OneTimePinContext,
    OneTimePinIdentifier, PinContext,
    PlainTextUsernameAndPassword,
    ProvideLegalEntityInformationRequest,
    ProvideLegalEntityInformationResponse,
    TpiIdentifier,
    UnMarshallerService,
    ValidateOneTimePinRequest,
    ValidateOneTimePinResponse
} from '@magnabc/tpi';
import {VerifiedAccountInitialisationManagerService} from '../verified-account-initialisation-manager/verified-account-initialisation-manager.service';
import {VerifiedAccountFinalisationManagerService} from '../verified-account-finalisation-manager/verified-account-finalisation-manager.service';
import {OneTimePinIssuanceManagerService} from '../../onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service';
import {OneTimePinValidationManagerProviderService} from '../../onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service';
import {PublicLegalEntityInformationProviderService} from '../../../entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service';
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun';
import {IdentifierManagerService} from "../../../identification/identifier-manager/identifier-manager.service";

@Injectable({
    providedIn: 'root'
})
export class VerifiedAccountManagerService {

    constructor(private verifiedAccountInitialisationManagerService: VerifiedAccountInitialisationManagerService,
                private verifiedAccountFinalisationManagerService: VerifiedAccountFinalisationManagerService,
                private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
                private oneTimePinValidationManagerProviderService: OneTimePinValidationManagerProviderService,
                private identifierManagerService: IdentifierManagerService,
                private publicLegalEntityInformationProviderService: PublicLegalEntityInformationProviderService,
                private unMarshallerService: UnMarshallerService) {
    }

    /* Capture Entity Information Layout control variables */
    canChangeContactNumber = true;
    showObscuredContactNumber = false;
    dropdownCountries: Country[];
    dropdownCountrySelected: Country = null;

    contactNumber: ContactNumber;
    emailAddress: EmailAddress;

    /* Residential Address */
    residentialAddress: Address;
    residentialAddressTooltip = "HELP.SIGN_UP_RESIDENTIAL";
    residentialAddressCountrySelected: Country = null;
    physicalAddressRequired = true;

    /* Postal Address */
    postalAddress: Address;
    postalAddressTooltip = "HELP.SIGN_UP_POSTAL";
    postalAddressCountrySelected: Country = null;
    postalAddressRequired = true;

    issuedOTPIdentifier: OneTimePinIdentifier = null;
    capturedCredentials: PlainTextUsernameAndPassword = null;

    setDropdownCountries(countries: Country[]) {
        countries.sort((a, b) => {
            if (a.description > b.description) {
                return 1;
            }
            if (a.description < b.description) {
                return -1;
            }
            return 0;
        });
        this.dropdownCountries = countries;
    }

    setNaturalPerson(naturalPerson: NaturalPerson) {
        this.contactNumber = naturalPerson.contactNumbers[0];
        this.emailAddress = naturalPerson.emailAddresses[0];
        naturalPerson.addresses.forEach(address => {
            // The MalTIS response might have two different (non-tpi-)MalawiPhysicalAddress,
            //  even if the MalTIS console claims that one is a physical address and the other
            //  a postal address
            //  This code arbitrarily sets the address to the final one in the payload
            if (address instanceof BasicPhysicalAddress) {
                this.residentialAddress = address;
            } else if (address instanceof BasicPostalAddress) {
                this.postalAddress = address;
            } else if (
                address instanceof MalawiPhysicalAddress ||
                address['@class'] === 'za.co.magnabc.tpi.location.geographic.MalawiPhysicalAddress' ||
                address['@class'] === 'za.co.magnabc.location.geographic.MalawiPhysicalAddress'
            ) {
                const newAddress = new BasicPhysicalAddress();
                const malaPhysicalAddress = address as MalawiPhysicalAddress;
                newAddress.lineOne = malaPhysicalAddress.lineOne;
                newAddress.lineTwo = malaPhysicalAddress.lineTwo;
                newAddress.lineThree = malaPhysicalAddress.city;
                const countryDescription = new CountryDescriptionIdentifier();
                countryDescription.description = malaPhysicalAddress.country;
                newAddress.countryIdentifier = countryDescription;
                newAddress.addressIdentifier = 'BasicPhysicalAddress';
                this.residentialAddress = newAddress;
            } else {
                console.error('Unrecognized address error :: ', address);
                RaygunErrorHandler.sendError('Unrecognized address error');
            }
        });
    }

    setResidentialCountryByIdentifier(countryIdentifier: CountryIdentifier) {
        this.residentialAddressCountrySelected = this.getCountryByIdentifier(countryIdentifier);
    }

    setPostalCountryByIdentifier(countryIdentifier: CountryIdentifier) {
        this.postalAddressCountrySelected = this.getCountryByIdentifier(countryIdentifier);
    }

    getCountryByIdentifier(countryIdentifier: CountryIdentifier): Country {
        if (countryIdentifier instanceof AlphaTwo) {
            return this.dropdownCountries.find(country => {
                return country.alphaTwo.toUpperCase() === countryIdentifier.alphaTwo.toUpperCase();
            });
        }

        if (countryIdentifier instanceof AlphaThree) {
            return this.dropdownCountries.find(country => {
                return country.alphaThree.toUpperCase() === countryIdentifier.alphaThree.toUpperCase();
            })
        }

        if (countryIdentifier instanceof CountryDialingIdentifier) {
            return this.dropdownCountries.find(country => {
                return country.dialingCode.toUpperCase() === countryIdentifier.dialingCode.toUpperCase();
            })
        }

        if (countryIdentifier instanceof CountryDescriptionIdentifier) {
            return this.dropdownCountries.find(country => {
                return country.description.toUpperCase() === countryIdentifier.description.toUpperCase();
            })
        }

        console.log('No country found for ... ', countryIdentifier);

        return null;
    }

    initialiseCreateVerifiedAccount(naturalPersonIdentifier: NaturalPersonIdentifier) {

        const initialiseRequest = new InitialiseCreateVerifiedAccountRequest();
        initialiseRequest.naturalPersonIdentifier = naturalPersonIdentifier;

        return new Promise<NaturalPerson>((resolve, reject) => {

            this.verifiedAccountInitialisationManagerService.initialiseCreateVerifiedAccount(initialiseRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, InitialiseCreateVerifiedAccountResponse) as InitialiseCreateVerifiedAccountResponse);
                    resolve(response.naturalPerson);
                    return;
                }
            }).catch((err) => {
                RaygunErrorHandler.sendError(err);

                resolve(null);
                return;
            })
        });
    }

    finaliseCreateVerifiedAccountByInsert(naturalPerson: NaturalPerson, password: string) {

        const finaliseByInsertRequest = new FinaliseCreateVerifiedAccountByInsertRequest();
        finaliseByInsertRequest.naturalPerson = naturalPerson;
        finaliseByInsertRequest.passwordNew = password;

        return new Promise<AuthenticationAdvice>((resolve, reject) => {

            const createIdentifierRequest = new CreateIdentifierRequest();
            createIdentifierRequest.quantity = 1;
            createIdentifierRequest.type = IdentifierType.TPI_IDENTIFIER;

            this.identifierManagerService.createIdentifier(createIdentifierRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {

                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateIdentifierResponse) as CreateIdentifierResponse);

                    const tpiIdentifier = new TpiIdentifier();
                    tpiIdentifier.tpiIdentifier = response.identifier[0];

                    finaliseByInsertRequest.naturalPerson.legalEntityIdentifiers.push(tpiIdentifier);

                    this.verifiedAccountFinalisationManagerService.finaliseCreateVerifiedAccountByInsert(finaliseByInsertRequest).then(httpResponse => {
                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.
                            unMarshallFromJson(httpResponse.body, FinaliseCreateVerifiedAccountByInsertResponse) as FinaliseCreateVerifiedAccountByInsertResponse);
                            resolve(response.authenticationAdvice);
                            return;
                        }
                    });

                }

            });

        });

    }

    finaliseCreateVerifiedAccountByUpdate(naturalPerson: NaturalPerson, password: string) {

        return new Promise<AuthenticationAdvice>((resolve, reject) => {

            const finaliseByUpdateRequest = new FinaliseCreateVerifiedAccountByUpdateRequest();
            finaliseByUpdateRequest.naturalPerson = naturalPerson;
            finaliseByUpdateRequest.passwordNew = password;

            this.verifiedAccountFinalisationManagerService.finaliseCreateVerifiedAccountByUpdate(finaliseByUpdateRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body, FinaliseCreateVerifiedAccountByUpdateResponse) as FinaliseCreateVerifiedAccountByUpdateResponse);
                    resolve(response.authenticationAdvice);
                    return;
                }
            }).catch(error => {
                console.error('Error while updating :: ', error);
                RaygunErrorHandler.sendError(error);
                resolve(null);
                return;
            });

        });

    }

    setOneTimePinIdentifier(oneTimePinIdentifier: OneTimePinIdentifier) {
        this.issuedOTPIdentifier = oneTimePinIdentifier;
    }

    issueOTP(contactNumber: ContactNumber) {
        const context = new OneTimePinContext();
        context.context = PinContext.VERIFY_ACCOUNT;

        const issueRequest = new IssueOneTimePinRequest();
        issueRequest.oneTimePinContext = context;
        issueRequest.contactNumber = contactNumber;

        return new Promise<any>((resolve, reject) => {
            this.oneTimePinIssuanceManagerService.issueOneTimePin(issueRequest).then(httpResponse => {
                console.log(httpResponse);
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);
                    this.issuedOTPIdentifier = response.oneTimePinIdentifier;
                    resolve({successful: true});
                    return;
                }
                resolve({successful: false, error: 'Failed to send One Time Pin'});
                return;
            }).catch(error => {
                console.error('Error while issuing OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                resolve({successful: false, error: error.error});
                return;
            });
        });
    }

    validateOTP(capturedOTP: string) {

        const validateOTPRequest = new ValidateOneTimePinRequest();
        validateOTPRequest.oneTimePinIdentifier = this.issuedOTPIdentifier;
        validateOTPRequest.capturedOneTimePin = capturedOTP;

        return new Promise<boolean>((resolve, reject) => {
            if (!this.issuedOTPIdentifier) {
                resolve(false);
                return;
            }

            this.oneTimePinValidationManagerProviderService.validateOneTimePin(validateOTPRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinResponse) as ValidateOneTimePinResponse);
                    resolve(response.valid);
                    return;
                }
            }).catch(error => {
                console.error('Error while validating OTP', error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return;
            });
        });
    }

    setCredentials(credentials: PlainTextUsernameAndPassword) {
        this.capturedCredentials = credentials;
    }

    getNaturalPersonInformation(nationalIdentityNumber: NaturalPersonIdentifier) {
        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(nationalIdentityNumber);

        return new Promise<NaturalPerson>((resolve, reject) => {
            this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);
                    if (response.legalEntities.length>0) {
                        const naturalPerson = (response.legalEntities[0] as NaturalPerson);
                        this.setNaturalPerson(naturalPerson);
                        resolve(naturalPerson);
                        return;
                    }
                }

                resolve(null);
                return;
            }).catch(error => {
                console.error('Provide legal entity info error :: ', error);
                RaygunErrorHandler.sendError(error);

                resolve(null);
                return;
            })
        })
    }

    clear() {
        this.contactNumber = null;
        this.emailAddress = null;
        this.residentialAddress = null;
        this.residentialAddressCountrySelected = null;
        this.postalAddress = null;
        this.postalAddressCountrySelected = null;
        this.issuedOTPIdentifier = null;
        this.capturedCredentials = null;
    }
}
