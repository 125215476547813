import { FinancialAccountIdentifier } from "./financial-account-identifier.model";
import { CountryIdentifier } from "../../../../location/geographic/identification/models/country-identifier.model";
import { LegalEntityIdentifier } from "../../../../entity/identification/models/legal-entity-identifier.model";

export class FinancialAccountNumberCountryLegalEntityIdentifier extends FinancialAccountIdentifier{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.identification.FinancialAccountNumberCountryLegalEntityIdentifier";
    }

    public number : string;
    public legalEntityIdentifier : LegalEntityIdentifier;
    public countryIdentifier: CountryIdentifier;
}