import { ContactNumber } from "./contact-number.model";
import { EmailAddress } from "./email-address.model";

export class ContactPerson{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.entity.ContactPerson";
    }
    public firstNames: string;
    public surname: string;
    public contactNumbers: ContactNumber[] = [];
    public emailAddresses: EmailAddress[]= [];
    public relationship: string;
}