import {Request} from '../../../../../../../urdad/services/models/request.model';
import {LegalEntityIdentifier} from "../../../../../../../entity/identification/models/legal-entity-identifier.model";
import { Money } from '../../../../../../../financial/models/money.model';
import { PaymentReference } from '../../../../../../../financial/models/payment-reference.model';
import { PaymentPlan } from '../../../../../models/payment-plan.model';

export class FinaliseCashierPaymentRequest extends Request{
    constructor(){
        super();
    }

    public amount: Money;
    public paymentReference: PaymentReference;
    public naturalPersonIdentifier: LegalEntityIdentifier;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public paymentPlan: PaymentPlan;
}
