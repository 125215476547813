import { ProposalResult } from "./proposal-result.model";

export class RetailProfileProposalSummary {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.retailprofileproposalsummary";
    }

    public plateNumber: string;
    public make: string;
    public model: string;
    public colour: string;
    public result: ProposalResult;
    public comment: string;
}