import { ExtensionInsurancePolicyCartItem } from '../../insurance/models/extension-insurance-policy-cart-item.model';
import { ComprehensiveInsurancePolicyCartItem } from "../../insurance/comprehensiveinsurance/models/comprehensive-insurance-policy-cart-item.model";
import { ThirdPartyInsurancePolicyCartItem } from "../../insurance/thirdpartyinsurance/models/third-party-insurance-policy-cart-item.model";
import { YCInsurancePolicyCartItem } from '../../insurance/yellowcardinsurance/models/yc-insurance-policy-cart-item.model';

export class CheckoutDatedItemGrouping{
    
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.CheckoutDatedItemGrouping";
    } 

    public comprehensiveInsurancePolicyCartItems: ComprehensiveInsurancePolicyCartItem[] = [];
    public thirdPartyInsurancePolicyCartItems: ThirdPartyInsurancePolicyCartItem[] = [];
    public extensionInsurancePolicyCartItems: ExtensionInsurancePolicyCartItem[] = [];
    public ycinsurancePolicyCartItems: YCInsurancePolicyCartItem[] = [];
}
