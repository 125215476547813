import { InsuranceProductService } from './../../../app.services/managers/insurance-product/insurance-product.service';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InsuranceConfigurationProviderService } from '../../../http.services/retail/insurance/setup/insurance-configuration-provider/insurance-configuration-provider.service';
import { AllCountryCriteria, Country, CoveredCountries, InsuranceCategory, InsuranceCategoryCriteria, ProvideCountryInformationRequest, ProvideCountryInformationResponse, ProvideCoveredCountriesRequest, ProvideCoveredCountriesResponse, UniqueCoveredCountryCriteria, UnMarshallerService } from '@magnabc/tpi';
import { InsuranceTypeManagerService } from '../../../app.services/managers/insurance-type-manager/insurance-type-manager.service';
import { FormComponent } from '../../../view.components/shared/form/form.component';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { CountryService } from '../../../view.components/shared/country/services/country.service';
import { CountryProviderService } from '../../../http.services/location/geographic/country-provider/country-provider.service';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { africaJson } from '../../../../assets/map-data/africa-geoJson';
import { EChartsOption } from 'echarts';
import { InsuranceProductFinalisationManagerService } from '../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service';


@Component({
    selector: 'app-covered-countries-selector-page',
    templateUrl: './covered-countries-selector-page.component.html',
    styleUrls: ['./covered-countries-selector-page.component.css']
})
export class CoveredCountriesSelectorPageComponent extends FormComponent implements OnInit, OnChanges {
    coveredCountries: CoveredCountries[] = [];
    supportedCountries: Country[];
    countries: Country[] = [];
    selectedCountries: string[] = [];
    geoJsonUrl = africaJson;
    newRegions: any[] = [];
    mapName = "Africa"
    multiSelect = true;
    showErrorMessage = false;

    readonly routes = {
        selectVehicles: "/retail/browse/products"
    };
    mapOption: EChartsOption = {
        tooltip: {
            show: false
        },
        series: [{ silent: true, selectedMode: false, emphasis: { disabled: true } }],
        emphasis: { focus: 'series' },
        hover: { mode: null },
        geo: {
            name: 'Africa',
            type: 'geo',
            map: 'Africa',
            zoom: 1,
            label: {
                show: false
            },
            regions: this.newRegions,
            emphasis: {
                disabled: true
            },
            layoutCenter: ["30%", "50%"],
            layoutSize: 400
        },
        legend: {
            selectedMode: false,
            selectorLabel: {
                show: false
            },
            emphasis: {
                selectorLabel: { show: false }
            }
        }
    }


    constructor(
        private insuranceConfigurationProviderService: InsuranceConfigurationProviderService,
        private unMarshallerService: UnMarshallerService,
        private insuranceTypeManagerService: InsuranceTypeManagerService,
        private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private countryService: CountryService,
        private countryProviderService: CountryProviderService,
        private location: Location,
        private router: Router,
        private insuranceProductService: InsuranceProductService,
        private route: ActivatedRoute
    ) {
        super(translate, translateParser);
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.findCoveredCountries();
    }

    ngOnInit(): void {
        this.findCoveredCountries();
    }

    onNext() {
        if (this.selectedCountries.length > 0) {
            if (this.route.snapshot.queryParams.quickBuy && this.insuranceProductFinalisationManagerService.vehicles.length > 0) {
                this.router.navigate([this.routes.selectVehicles], {queryParams: {quickBuy: true}});
            } else {
                this.insuranceProductFinalisationManagerService.vehicles = [];
                this.router.navigate([this.routes.selectVehicles]);
            }
        } else {
            this.showErrorMessage = true;
        }
    }

    onBack() {
        this.location.back();
    }

    findCoveredCountries() {
        const request = new ProvideCoveredCountriesRequest();
        if (this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.YELLOWCARD) {
            const criteria = new UniqueCoveredCountryCriteria();
            request.criteria = criteria;
        } else {
            const insuranceCategoryCriteria = new InsuranceCategoryCriteria();
            insuranceCategoryCriteria.insuranceCategory = this.insuranceTypeManagerService.selectedInsuranceCategory;
            request.criteria = insuranceCategoryCriteria;
        }

        this.startLoad();
        this.insuranceConfigurationProviderService.provideCoveredCountries(request).then((data) => {
            const response: ProvideCoveredCountriesResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideCoveredCountriesResponse);
            if (response) {
                this.coveredCountries = response.coveredCountries;
                this.populateCountries();
            }
            if (this.insuranceTypeManagerService.selectedInsuranceCategory !== InsuranceCategory.YELLOWCARD) {
                this.multiSelect = false
            } else {
                this.multiSelect = true;
            }

            if (this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.COMPREHENSIVE) {
                this.coveredCountries = this.coveredCountries.filter(value => value.coveredCountry === "Malawi")
            }

            if (this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.THIRDPARTY) {
                this.coveredCountries = this.coveredCountries.filter(value => value.coveredCountry !== "Zambia")
            }
            this.stopLoad();
        }).catch((error) => {
            this.stopLoad();
        });
    }

    onSelectCountry(event: Country[]) {
        this.selectedCountries = [... new Set(event.map(item => item.description))];
        this.insuranceProductService.selectedCoveredCountries = this.selectedCountries;

        if (this.insuranceTypeManagerService.selectedInsuranceCategory !== InsuranceCategory.YELLOWCARD) {
            this.supportedCountries.forEach(element => {
                if (event.length > 0) {
                    event.forEach(selected => {
                        if (element.description !== selected.description) {
                            element['disabled'] = true;
                        }
                    });
                } else {
                    element['disabled'] = false;
                }
            });
        }
        this.showErrorMessage = false;
    }

    private getCoveredCountries() {
        let uniqueCountries = new Set(this.coveredCountries.map(item => item.coveredCountry));
        this.supportedCountries = this.countryService.getCountries()
            .filter(item => uniqueCountries.has(item.description))
            .sort((a, b) => a.description.localeCompare(b.description));
        this.supportedCountries.forEach(element => {
            (element as any).disabled = false
        })
    }

    populateCountries() {
        this.startLoad();
        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse));
                this.countries = response.countries;
                this.countryService.setCountries(response.countries);
            }
            this.getCoveredCountries();
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: ', err);
            RaygunErrorHandler.sendError(err);
            this.stopLoad();
        });
    }

    updateMapOptionOnCountrySelect() {
        this.newRegions = [];
        if (this.selectedCountries && this.selectedCountries.length > 0) {
            this.selectedCountries.forEach(element => {
                this.newRegions.push({ name: element, itemStyle: { areaColor: 'blue', color: 'blue' } })
            });
        }

        return {
            tooltip: {
                show: false
            },
            series: [{ silent: true, selectedMode: false, emphasis: { disabled: true } }],
            hover: { mode: null },
            geo: {
                name: 'Africa',
                type: 'geo',
                map: this.mapName,
                zoom: 1,
                label: {
                    show: false
                },
                regions: this.newRegions,
                emphasis: {
                    disabled: true
                },
                layoutCenter: ["30%", "50%"],
                layoutSize: 400
            },
            legend: {
                selectedMode: false,
                selectorLabel: {
                    show: false
                },
                emphasis: {
                    selectorLabel: { show: false }
                }
            }
        };
    }


}
