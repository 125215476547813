import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators, FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import {
    AllMakeCriteria, AllModelCriteria,
    AllCategoryIdentifierCriteria,
    CountryDialingIdentifier,
    Engine,
    EngineNumber,
    Make,
    MakeDescriptionCriteria,
    MakeDescriptionIdentifier,
    Model,
    PlateNumber,
    ProvideCategoryIdentifiersRequest,
    ProvideCategoryIdentifiersResponse,
    ProvideUsageIdentifiersRequest,
    ProvideUsageIdentifiersResponse,
    ProvideVehicleMakesRequest, ProvideVehicleMakesResponse,
    ProvideVehicleModelsRequest,
    ProvideVehicleModelsResponse,
    UnMarshallerService,
    UsagesCriteria,
    Vehicle,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Dropdown } from "../../../common/model/dropdown.model";
import { MY_FORMATS_YEAR } from "../../../common/utils/utils.date";
import { DropdownUtils } from "../../../common/utils/utils.dropdowns";
import { autoCompleteValidator, manufactureDateValidator } from "../../../common/utils/utils.validation";
import { CategoryIdentifierProviderService } from "../../../http.services/vehicle/identification/category-identifier-provider/category-identifier-provider.service";
import { UsageIdentifierProviderService } from "../../../http.services/vehicle/identification/usage-identifier-provider/usage-identifier-provider.service";
import { MakeModelTrimProviderService } from "../../../http.services/vehicle/makemodeltrim/make-model-trim-provider/make-model-trim-provider.service";
import { FormComponent } from "../../shared/form/form.component";

declare const jQuery: any;
@Component({
    selector: 'app-vehicle-general',
    templateUrl: './vehicle_general.component.html',
    styleUrls: ['./vehicle_general.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_YEAR },
    ]
})
export class VehicleGeneralComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    VIN_VALIDATION = '^(?:(?![IO])[A-Z0-9])+$';
    engineNumberPattern = '^[a-hj-np-zA-HJ-NP-Z0-9/]+$';

    @Input() vehicle: Vehicle;
    @Input() title = "";
    @Input() editing = false;
    @Input() country: CountryDialingIdentifier;
    @Output() onVehicle = new EventEmitter<Vehicle>();
    @Output() onUnverifiedMake = new EventEmitter<Make>();
    @Output() onUnverifiedModel = new EventEmitter<Model>();
    @Output() onPropulsion = new EventEmitter<string>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onEngine = new EventEmitter<Engine>();

    displayDropDown = FormComponent.displayDropDown;

    makes = [];
    filteredMakes: Observable<Dropdown[]>;

    models = [];
    filteredModels: Observable<Dropdown[]>;

    categories = [];
    filteredCategories: Observable<Dropdown[]>;
    showAsterisk = false

    allTypes = [
        new Dropdown('01', 'Motorcycle (no sidecar)'),
        new Dropdown('02', 'Motorcycle (with sidecar)'),
        new Dropdown('03', 'Scooter'),
        new Dropdown('04', 'Motor tricycle'),
        new Dropdown('05', 'Motor quadricycle'),
        new Dropdown('06', 'Moped'),
        new Dropdown('11', 'Beach buggy'),
        new Dropdown('12', 'Saloon (closed top)'),
        new Dropdown('13', 'Saloon (open top)'),
        new Dropdown('14', 'Coupe (closed top)'),
        new Dropdown('15', 'Coupe (open top)'),
        new Dropdown('16', 'Station wagon'),
        new Dropdown('17', 'SUV'),
        new Dropdown('18', 'Hatch back'),
        new Dropdown('21', 'Combi / Micro bus / Minibus (9-16 passengers)'),
        new Dropdown('22', 'Midibus (17-35 passengers)'),
        new Dropdown('23', 'Bus (single deck) (≥ 36 passengers)'),
        new Dropdown('24', 'Bus (double deck)'),
        new Dropdown('25', 'Bendi bus / Bus-train'),
        new Dropdown('31', 'Pick-up'),
        new Dropdown('32', 'Panel Van'),
        new Dropdown('33', 'RV / Caravan'),
        new Dropdown('41', 'Box body'),
        new Dropdown('42', 'Van body'),
        new Dropdown('43', 'Flat deck / Low bed / Platform deck'),
        new Dropdown('44', 'Dropside'),
        new Dropdown('45', 'Tipper'),
        new Dropdown('46', 'Compactor body'),
        new Dropdown('47', 'Logger body'),
        new Dropdown('48', 'Sheet glass body'),
        new Dropdown('49', 'Mixer'),
        new Dropdown('50', 'Tanker'),
        new Dropdown('51', 'Mesh side body'),
        new Dropdown('52', 'Truck tractor'),
        new Dropdown('53', 'Chassis-cab'),
        new Dropdown('54', 'Chassis / Skeletal'),
        new Dropdown('55', 'Adapter dolly'),
        new Dropdown('56', 'Converter dolly'),
        new Dropdown('57', 'Vehicle carrier'),
        new Dropdown('61', 'Tractor'),
        new Dropdown('62', 'Hearse'),
        new Dropdown('63', 'Breakdown'),
        new Dropdown('64', 'Fire engine'),
        new Dropdown('65', 'Ambulance'),
        new Dropdown('66', 'Rescue Vehicle'),
        new Dropdown('67', 'Grader'),
        new Dropdown('68', 'Compactor / Roller'),
        new Dropdown('69', 'Tarmac spreader'),
        new Dropdown('70', 'Sweeper'),
        new Dropdown('71', 'Generator'),
        new Dropdown('72', 'Compressor'),
        new Dropdown('73', 'Hammer'),
        new Dropdown('74', 'Loader'),
        new Dropdown('75', 'Digger'),
        new Dropdown('76', 'Backacter'),
        new Dropdown('77', 'Drill / Borer'),
        new Dropdown('78', 'Crane'),
        new Dropdown('79', 'Pump Lifter'),
        new Dropdown('80', 'Drain cleaner'),
        new Dropdown('81', 'Pipelaying'),
        new Dropdown('82', 'Harvester'),
        new Dropdown('83', 'Baler'),
        new Dropdown('84', 'Mower'),
        new Dropdown('85', 'Planter'),
        new Dropdown('86', 'Crop sprayer'),
        new Dropdown('A1', 'Hearse / Ambulance'),
        new Dropdown('A2', 'Roadmaking'),
        new Dropdown('A3', 'Earthmoving'),
        new Dropdown('A4', 'Excavation'),
        new Dropdown('A5', 'Construction'),
        new Dropdown('A6', 'Mass/ Diesel cart farming'),
        new Dropdown('A7', 'Utility vehicle'),
        new Dropdown('A8', 'Agriculture machine'),
        new Dropdown('A9', 'Mobile equipment')
    ];
    types = this.allTypes;
    usages = [];
    propulsions = [
        new Dropdown('self_propelled', 'Self-propelled'),
        new Dropdown('semi_trailer', 'Semi-trailer'),
        new Dropdown('draw_bar_trailer', 'Draw Bar - Trailer')
    ];

    invalidSelfPropelled = ['55', '56', '46'];
    invalidSemiTrailer = ['01', '65', '76', '11', '25', '63', '23', '24', '53', '21', '14', '15', 'A3', 'A4', '64', '18', '17', 'A1', 'A6', '49', '02', '03', '04', '05', '06', '31', '32', '66', '12', '13', '16', '61', '52'];
    invalidDrawbar = ['01', '65', '76', '11', '25', '63', '23', '24', '53', '21', '14', '15', 'A3', 'A4', '64', '18', '17', 'A1', 'A6', '49', '02', '03', '04',
        '05', '06', '31', '32', '66', '12', '13', '16', '61', '52'];

    validMotorCycle = ['01', '02', '03', '04', '05', '06'];
    validLightPassenger = ['11', '12', '13', '14', '15', '16', '17', '18', '21', '31', '33', 'A7'];
    validLightGoods = ['41', '42', '43', '44', '45', '48', '49', '50', '51', '52', '53', '31'];
    validMediumPassenger = ['22'];
    validMediumGoods = ['41', '43', '44', '32', '45', '46', '47', '48', '49', '50', '51', '52', '53', '57'];
    validHeavyPassenger = ['22', '23', '24', '25'];
    validHeavyGoods = ['41', '42', '43', '44', '45', '47', '49', '50', '51', '52', '57'];
    validSpecial = ['65', '76', '83', '63', '54', '46', '72', 'A5', '56', '78', '86', '75', '80', '77', 'A3', 'A4', '64', '71', '67', '73', '82', '62', 'A1', '74', 'A6', 'A9', '84', '81', '85', '79', '66', 'A2', '33', '70', '69', '61', 'A7', '57'];

    colours = [
        new Dropdown('black', 'Black'),
        new Dropdown('blue', 'Blue'),
        new Dropdown('charcoal', 'Charcoal'),
        new Dropdown('grey', 'Grey'),
        new Dropdown('red', 'Red'),
        new Dropdown('silver', 'Silver'),
        new Dropdown('white', 'White'),
        new Dropdown('beige', 'Beige'),
        new Dropdown('brown', 'Brown'),
        new Dropdown('burgundy', 'Burgundy'),
        new Dropdown('cream', 'Cream'),
        new Dropdown('gold', 'Gold'),
        new Dropdown('green', 'Green'),
        new Dropdown('maroon', 'Maroon'),
        new Dropdown('mustard', 'Mustard'),
        new Dropdown('navy', 'Navy'),
        new Dropdown('orange', 'Orange'),
        new Dropdown('peach', 'Peach'),
        new Dropdown('pearl', 'Pearl'),
        new Dropdown('pink', 'Pink'),
        new Dropdown('purple', 'Purple'),
        new Dropdown('turquoise', 'Turquoise'),
        new Dropdown('yellow', 'Yellow'),
        new Dropdown('unverified', 'Other Colour')
    ];
    filteredColours: Observable<Dropdown[]>;

    today = new Date();

    formGroup: UntypedFormGroup;
    vehiclePlateNumber: UntypedFormControl;
    vehicleIdentificationNumber: UntypedFormControl;
    make: UntypedFormControl;
    unverifiedMake: UntypedFormControl;
    unverifiedColour: UntypedFormControl;
    model: UntypedFormControl;
    unverifiedModel: UntypedFormControl;
    manufactureDate: UntypedFormControl;
    category: UntypedFormControl;
    vehicleType: UntypedFormControl;
    usage: UntypedFormControl;
    propulsion: UntypedFormControl;
    seatingCapacity: UntypedFormControl;
    grossVehicleMass: UntypedFormControl;
    tare: UntypedFormControl;
    colour: UntypedFormControl;
    engineNumber: UntypedFormControl;

    dateOptions = {
        formatYear: 'yyyy',
        startingDay: 1,
        minMode: 'year'
    };

    unverifiedMakeSelected = false;
    unverifiedModelSelected = false;
    unverifiedColourSelected = false;

    seatingCapacityInvalid = false
    seatingCapacityMin = 0
    seatingCapacityMax = 999

    @ViewChild('form') form: FormGroupDirective;

    constructor(
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private unMarshallerService: UnMarshallerService,
        private makeModelTrimProviderService: MakeModelTrimProviderService,
        private categoryIdentifierProviderService: CategoryIdentifierProviderService,
        private usageIdentifierProviderService: UsageIdentifierProviderService,) {
        super(translate, translateParser);

    }

    ngOnInit(): void {
        this.types = DropdownUtils.sortPreferredName(this.types, ["14", "15", "18", "17", "01", "31", "12", "13", "16"]);
        this.types.push(new Dropdown('00', 'Not Applicable'));

        const promises = [];
        promises.push(this.getMakes());
        promises.push(this.getModels());
        promises.push(this.getCategories());
        promises.push(this.getUsages());

        this.startLoad();
        Promise.all(promises).then(() => {
            this.stopLoad();
            this.init();
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        }, 1000);
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    private setEngineNumberValidation(): void {
        if (this.engineNumber) {
            this.engineNumber.setValidators([
                Validators.required,
                Validators.maxLength(20),
                Validators.pattern(this.engineNumberPattern)
            ]);
        }
    }

    getMakes(): Promise<void> {

        return new Promise<void>((resolve) => {

            const provideMakeRequest = new ProvideVehicleMakesRequest();
            provideMakeRequest.criteria = new AllMakeCriteria();

            this.makeModelTrimProviderService.provideMakes(provideMakeRequest).then((httpResponse) => {
                const response: ProvideVehicleMakesResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleMakesResponse);

                if (response.makes) {

                    const unverifiedMakeDropDown = new Dropdown('unverified', 'Other Make');
                    this.makes.push(unverifiedMakeDropDown);
                    response.makes.forEach(
                        make => {
                            this.makes.push(new Dropdown(make.name, make.name));
                        }
                    );

                    this.makes = this.removeDuplicates(this.makes, 'name');

                    resolve();

                }
            });

        });


    }

    getModels(): Promise<void> {

        return new Promise<void>((resolve) => {

            const provideModelRequest = new ProvideVehicleModelsRequest();

            if (this.vehicle && this.vehicle.make) {
                const makeDescriptionCriteria = new MakeDescriptionCriteria();
                const makeDescriptionIdentifier = new MakeDescriptionIdentifier();
                makeDescriptionIdentifier.description = this.vehicle.make;
                makeDescriptionCriteria.makeIdentifier = makeDescriptionIdentifier;
                provideModelRequest.criteria = makeDescriptionCriteria;
            } else {
                provideModelRequest.criteria = new AllModelCriteria();
            }

            this.models.push(new Dropdown('unverified', 'Other Model'));

            this.makeModelTrimProviderService.provideModel(provideModelRequest).then((httpResponse) => {
                const modelsResponse: ProvideVehicleModelsResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleModelsResponse);

                for (const model of modelsResponse.models) {
                    this.models.push(new Dropdown(model.makeIdentifier, model.name));
                }

                const unverifiedMakeDropDown = new Dropdown('unverified', 'Other Model');
                this.models.push(unverifiedMakeDropDown);
                this.models = this.removeDuplicates(this.models, 'name');

                resolve();

            });

        });

    }

    removeDuplicates(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    getCategories(): Promise<void> {

        return new Promise<void>((resolve) => {

            const provideVehicleCategories = new ProvideCategoryIdentifiersRequest();
            provideVehicleCategories.criteria = new AllCategoryIdentifierCriteria();
            this.categoryIdentifierProviderService.provideCategories(provideVehicleCategories).subscribe((httpResponse) => {

                const response: ProvideCategoryIdentifiersResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCategoryIdentifiersResponse);

                const results = DropdownUtils.sortPreferredCode(response.categoryIdentifiers, ["A", "BP", "BG", "D1", "C1", "D", "C", "S"]);

                this.categories = [];
                for (const category of results) {
                    this.categories.push(new Dropdown(category.code, category.description));
                }

                resolve();

            });

        });

    }

    getUsages(): Promise<void> {

        return new Promise<void>((resolve) => {

            const provideVehicleUsages = new ProvideUsageIdentifiersRequest();
            provideVehicleUsages.criteria = new UsagesCriteria();
            this.usageIdentifierProviderService.provideUsages(provideVehicleUsages).subscribe((httpResponse) => {
                const response: ProvideUsageIdentifiersResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideUsageIdentifiersResponse);

                const results = DropdownUtils.sortPreferredCode(response.usageIdentifiers, ["P", "GR", "PSV", "T", "H"]);

                this.usages = [];
                for (const usage of results) {
                    this.usages.push(new Dropdown(usage.code, usage.description));
                }

                resolve();

            });

        });

    }

    chosenYearHandler(normalizedYear, datepicker) {

        const ctrlValue = this.manufactureDate.value;
        ctrlValue.year(normalizedYear.year());
        this.manufactureDate.setValue(ctrlValue);
        datepicker.close();

        this.onYearChange(null);

    }

    init(): void {

        if (this.vehicle) {

            var plateNumber = new PlateNumber();

            for (const vehicleIdentifier of this.vehicle.vehicleIdentifiers) {
                if (vehicleIdentifier instanceof PlateNumber) {
                    plateNumber = vehicleIdentifier;
                }
            }

            this.vehiclePlateNumber = new UntypedFormControl(plateNumber.number, [
                Validators.required,
                Validators.maxLength(8),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9]+$')
            ]);
            this.vehicleIdentificationNumber = new UntypedFormControl(this.vehicle.vehicleIdentificationNumber, [
                Validators.required,
                Validators.maxLength(17),
                Validators.minLength(7),
                Validators.pattern(`${this.VIN_VALIDATION}`)
            ]);

            if (this.editing === true) {
                this.vehicleIdentificationNumber.disable();
            }

            this.make = new UntypedFormControl(this.getDropdown(this.makes, this.vehicle.make), [
                Validators.required,
                autoCompleteValidator(this.makes)
            ]);
            this.unverifiedMake = new UntypedFormControl(this.vehicle.make, [
                Validators.maxLength(35),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.model = new UntypedFormControl(this.getDropdown(this.models, this.vehicle.model), [
                Validators.required,
                autoCompleteValidator(this.models)
            ]);
            this.unverifiedModel = new UntypedFormControl(this.vehicle.model, [
                Validators.maxLength(20),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            if (this.vehicle.manufactureDate) {
                this.manufactureDate = new UntypedFormControl(moment(this.vehicle.manufactureDate), [
                    Validators.required,
                    manufactureDateValidator()
                ]);
            } else {
                this.manufactureDate = new UntypedFormControl('', [
                    Validators.required,
                    manufactureDateValidator()
                ]);
            }
            this.category = new UntypedFormControl(this.getDropdown(this.categories, this.vehicle.category), [
                Validators.required,
                autoCompleteValidator(this.categories)
            ]);
            this.vehicleType = new UntypedFormControl(this.getDropdown(this.types, this.vehicle.vehicleType), [
                Validators.required,
                autoCompleteValidator(this.types)
            ]);
            this.usage = new UntypedFormControl(this.getDropdown(this.usages, this.vehicle.usage), [
                Validators.required,
                autoCompleteValidator(this.usages)
            ]);
            this.propulsion = new UntypedFormControl(this.getDropdown(this.propulsions, this.vehicle.propulsion), [
                Validators.required,
                autoCompleteValidator(this.propulsions)
            ]);
            this.seatingCapacity = new UntypedFormControl(this.vehicle.seatingCapacity, [
                Validators.required,
                Validators.max(999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.grossVehicleMass = new UntypedFormControl(this.vehicle.grossVehicleMass, [
                Validators.max(999999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.tare = new UntypedFormControl(this.vehicle.tare, [
                Validators.max(999999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);

            if (!this.getDropdown(this.colours, this.vehicle.colour) && this.vehicle.colour) {
                this.unverifiedColourSelected = true;
                this.colour = new UntypedFormControl(this.colours[23], [
                    Validators.maxLength(30)
                ]);
                this.unverifiedColour = new UntypedFormControl(this.vehicle.colour, [
                    Validators.required
                ]);
            } else {
                this.colour = new UntypedFormControl(this.getDropdown(this.colours, this.vehicle.colour), [
                    Validators.required,
                    autoCompleteValidator(this.colours)
                ]);
                this.unverifiedColour = new UntypedFormControl('', [
                    Validators.required
                ]);
                this.unverifiedColour.setValue(null)
            }

            if (this.unverifiedMake.value) {
                this.updateUnverifiedMake(this.unverifiedMake.value.value);
            } else {
                this.updateUnverifiedMake(null);
            }
            if (this.unverifiedModel.value) {
                this.updateUnverifiedModel(this.unverifiedModel.value.value);
            } else {
                this.updateUnverifiedModel(null);
            }

            if (this.canCaptureForCountry) {
                this.engineNumber = new UntypedFormControl('', [
                    Validators.maxLength(20),
                    Validators.pattern(this.engineNumberPattern)
                ]);
                if (this.vehicle.engine) {
                    this.engineNumber.setValue(this.vehicle.engine.engineNumber);
                }
                if (this.isSelfPropelled) {
                    this.setEngineNumberValidation();
                }
            }
        } else {

            this.vehicle = new Vehicle();
            this.vehicle.vehicleIdentifiers.push(new PlateNumber());
            this.vehiclePlateNumber = new UntypedFormControl(this.getVehiclePlateNumber(this.vehicle), [
                Validators.required,
                Validators.maxLength(17),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9]+$')
            ]);
            this.vehicleIdentificationNumber = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(17),
                Validators.minLength(7),
                Validators.pattern(`${this.VIN_VALIDATION}`)
            ]);
            this.make = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.makes)
            ]);
            this.unverifiedMake = new UntypedFormControl('', [
                Validators.maxLength(35),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.model = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.models)
            ]);
            this.unverifiedModel = new UntypedFormControl('', [
                Validators.maxLength(20),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.manufactureDate = new UntypedFormControl('', [
                Validators.required,
                manufactureDateValidator()
            ]);
            this.category = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.categories)
            ]);
            this.vehicleType = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.types)
            ]);
            this.usage = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.usages)
            ]);
            this.propulsion = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.propulsions)
            ]);
            this.seatingCapacity = new UntypedFormControl('', [
                Validators.required,
                Validators.max(999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.grossVehicleMass = new UntypedFormControl('', [
                Validators.max(999999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.tare = new UntypedFormControl('', [
                Validators.max(999999),
                Validators.min(0),
                Validators.pattern('^[0-9]+$')
            ]);
            this.colour = new UntypedFormControl('', [
                Validators.required,
                autoCompleteValidator(this.colours)
            ]);
            this.unverifiedColour = new UntypedFormControl('', [
                Validators.required
            ]);

            this.vehicle.engine = new Engine();
            this.engineNumber = new UntypedFormControl('', [
                Validators.maxLength(20),
                Validators.pattern(this.engineNumberPattern)
            ]);
        }

        if (this.vehicle && this.vehicle.make) {
            const dropdown = this.getDropdown(this.makes, this.vehicle.make);
            if (dropdown) {
                this.make.setValue(dropdown);
            } else {
                this.unverifiedMakeSelected = true;
                this.make.setValue(this.getDropdown(this.makes, 'Other Make'));
                this.unverifiedMake.setValue(this.vehicle.make);
            }
        }

        if (this.vehicle && this.vehicle.model) {
            const dropdown = this.getDropdown(this.models, this.vehicle.model);
            if (dropdown) {
                this.model.setValue(dropdown);
            } else {
                this.unverifiedModelSelected = true;
                this.model.setValue(this.getDropdown(this.models, 'Other Model'));
                this.unverifiedModel.setValue(this.vehicle.model);
            }
        }

        this.category.setValue('');
        this.category.setValidators([
            Validators.required,
            autoCompleteValidator(this.categories)
        ]);

        if (this.vehicle && this.vehicle.category) {
            this.category.setValue(this.getDropdown(this.categories, this.vehicle.category));
        }

        this.usage.setValue('');
        this.usage.setValidators([
            Validators.required,
            autoCompleteValidator(this.usages)
        ]);

        if (this.vehicle && this.vehicle.usage) {
            this.usage.setValue(this.getDropdown(this.usages, this.vehicle.usage));
        }

        this.setModelFilter();

        this.formGroup = new UntypedFormGroup({
            vehiclePlateNumber: this.vehiclePlateNumber,
            vehicleIdentificationNumber: this.vehicleIdentificationNumber,
            make: this.make,
            unverifiedMake: this.unverifiedMake,
            model: this.model,
            unverifiedModel: this.unverifiedModel,
            manufactureDate: this.manufactureDate,
            category: this.category,
            vehicleType: this.vehicleType,
            usage: this.usage,
            propulsion: this.propulsion,
            seatingCapacity: this.seatingCapacity,
            grossVehicleMass: this.grossVehicleMass,
            tare: this.tare,
            colour: this.colour,
            unverifiedColour: this.unverifiedColour
        });

        if (this.canCaptureForCountry) {
            this.formGroup.addControl('engineNumber', this.engineNumber);
        }
    }

    onEngineNumberChange(): void {
        if (this.vehicle.engine && this.vehicle.engine.engineNumber) {
            this.vehicle.engine.engineNumber = this.engineNumber.value;
        } else {
            this.vehicle.engine = new Engine();
            this.vehicle.engine.engineNumber = this.engineNumber.value;
        }
    }

    private setModelFilter(): void {
        this.filteredModels = this.model.valueChanges.pipe(
            startWith<string | Dropdown>(''),
            map((value: any) => typeof value === 'string' ? value : value.name),
            map((name: any) => name ? FormComponent.filterDropDown(this.models, name) : this.models.slice())
        );
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    onYearChange(event): void {

        setTimeout(() => {

            const year = this.manufactureDate.value ? this.manufactureDate.value.get("year") : 0;

            if (year <= 2010) {

                this.vehicleIdentificationNumber.setValidators([
                    Validators.required,
                    Validators.maxLength(17),
                    Validators.minLength(7),
                    Validators.pattern(`${this.VIN_VALIDATION}`)
                ]);

            } else {

                this.vehicleIdentificationNumber.setValidators([
                    Validators.required,
                    Validators.maxLength(17),
                    Validators.minLength(17),
                    Validators.pattern(`${this.VIN_VALIDATION}`)
                ]);

            }

            this.vehicleIdentificationNumber.updateValueAndValidity({ onlySelf: true });

        }, 100);

    }

    onModelChange(event): void {
        if (event.isUserInput) {
            this.updateUnverifiedModel(event.source.value.value);
        }
    }

    updateUnverifiedModel(value: string) {
        if (value && value === 'unverified') {
            this.unverifiedModelSelected = true;
            this.unverifiedModel.setValidators([
                Validators.required,
                Validators.maxLength(20),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.unverifiedModel.setValue("");
        } else {
            this.unverifiedModelSelected = false;
            this.unverifiedModel.setValidators([]);
        }
    }

    updateUnverifiedMake(value: string) {

        if (value && value === 'unverified') {
            this.unverifiedMakeSelected = true;
            this.unverifiedMake.setValidators([
                Validators.required,
                Validators.maxLength(35),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.unverifiedModel.setValue("");
        } else {
            this.unverifiedMakeSelected = false;
            this.unverifiedModelSelected = false;
            this.unverifiedMake.setValidators([]);
            this.unverifiedModel.setValidators([]);
        }

    }

    updateUnverifiedColour(value: string) {

        if (value && value === 'unverified') {
            this.unverifiedColourSelected = true;
            this.unverifiedColour.setValidators([
                Validators.required,
                Validators.maxLength(35),
                Validators.minLength(1),
                Validators.pattern('^[a-zA-Z0-9 ]+$')
            ]);
            this.unverifiedColour.setValue('');
        } else {
            this.unverifiedColourSelected = false;
            this.unverifiedColour.setValidators([]);
            this.unverifiedColour.setValue('');
        }

    }

    onMakeChange(event): void {

        if (event.isUserInput) {
            this.updateUnverifiedMake(event.source.value.value);
        }

        setTimeout(() => {

            this.startLoad();

            if (this.make.value.name && this.make.value.name === 'Other Make') {
                this.models = [];
                this.models.push(new Dropdown('unverified', 'Other Model'));
                this.stopLoad();
                return;
            }

            const vehicleMakeNameIdentifier = new MakeDescriptionIdentifier();
            vehicleMakeNameIdentifier.description = this.make.value.name;

            const vehicleMakeCriteria = new MakeDescriptionCriteria();
            vehicleMakeCriteria.makeIdentifier = vehicleMakeNameIdentifier;

            const provideModelRequest = new ProvideVehicleModelsRequest();
            provideModelRequest.criteria = vehicleMakeCriteria;

            this.makeModelTrimProviderService.provideModel(provideModelRequest).then((httpResponse) => {
                const response: ProvideVehicleModelsResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleModelsResponse);

                this.stopLoad();

                this.models = [];
                this.models.push(new Dropdown('unverified', 'Other Model'));
                response.models.forEach(
                    (model: Model) => {
                        this.models.push(new Dropdown(model.makeIdentifier, model.name));
                    }
                )
                this.model.reset('');
                this.model.setValidators([
                    Validators.required,
                    autoCompleteValidator(this.models)
                ]);
                this.filteredModels = of(this.models);
                this.setModelFilter();
            });

        }, 100);

    }

    onPropulsionChange(event) {
        if (event.isUserInput) {
            const propulsion = event.source.value.value;
            this.onPropulsion.emit(propulsion);
            this.vehicle.propulsion = event.source.value.name;
            if (propulsion === 'self_propelled') {
                this.setEngineNumberValidation();
            } else {
                if (this.engineNumber) {
                    this.engineNumber.clearValidators();
                    this.clearEngine();
                }
            }
            if (this.canCaptureForCountry && this.vehicle.engine && this.vehicle.engine.engineNumber) {
                this.engineNumber.setValue(this.vehicle.engine.engineNumber)
            }
        }
    }

    onColourChange(event): void {

        if (event.isUserInput) {
            this.updateUnverifiedColour(event.source.value.value);
        }

    }

    submit(): void {
        document.getElementById('form-submit-vehicle-general-' + this.title).click();
    }

    tareExceedsGVM(): boolean {
        if (parseInt(this.tare.value, 10) > parseInt(this.grossVehicleMass.value, 10)) {
            this.tare.setErrors({ exceedsgvm: true });
            return true;
        }
        if (this.tare.value && !isNaN(this.tare.value) && this.tare.value.toString().indexOf('-') < 0) {
            this.tare.setErrors(null);
        }
        return false;
    }

    validateGVMtoCategory(): boolean {

        if (this.grossVehicleMass.value < 0) {
            this.grossVehicleMass.setErrors({ validategvmlessthanzero: true });
            return true;
        }

        if (this.category.value.name === "Heavy Goods (GVM > 7500Kg)") {
            this.showAsterisk = true
            if (this.grossVehicleMass.value <= 7500) {
                this.grossVehicleMass.setErrors({ validategvmless: true });
                return true;
            }
        }

        if (this.category.value.name === "Medium Goods (GVM 3500Kg < X <= 7500Kg)") {
            this.showAsterisk = true
            if (this.grossVehicleMass.value > 7500 || this.grossVehicleMass.value <= 3500) {
                this.grossVehicleMass.setErrors({ validategvmbetween: true });
                return true;
            }
        }

        if (this.category.value.name === 'Light Goods (GVM <= 3500Kg)') {
            this.showAsterisk = true
            if (this.grossVehicleMass.value > 3500) {
                this.grossVehicleMass.setErrors({ validategvmmore: true });
                return true;
            }
        }

        if (this.category.value.name === 'Light Goods (GVM <= 3500Kg)') {
            this.showAsterisk = true
            if (this.grossVehicleMass.value === null || !this.grossVehicleMass.value) {
                this.grossVehicleMass.setErrors({ required: true });
                return true;
            }
        }

        this.grossVehicleMass.setErrors(null);
        this.showAsterisk = false
        return false;
    }

    validateSeatingtoCategory(): boolean {

        if (this.seatingCapacity.value < 0) {
            this.seatingCapacity.setErrors({ validateseatlessthanzero: true });
            return true;
        }

        if (this.seatingCapacity.value < this.seatingCapacityMin) {
            this.seatingCapacity.setErrors({ validateseatless: true });
            return true;
        }

        if (this.seatingCapacity.value > this.seatingCapacityMax || this.seatingCapacity.value < this.seatingCapacityMin) {
            this.seatingCapacity.setErrors({ validateseatbetween: true });
            return true;
        }

        if (this.seatingCapacity.value > this.seatingCapacityMax) {
            this.seatingCapacity.setErrors({ validateseatmore: true });
            return true;
        }

        if (!this.seatingCapacity.value) {
            this.seatingCapacity.setErrors({ required: true });
            return true;
        }

        this.seatingCapacity.setErrors(null);
        return false;
    }

    onTypeChange(event): void {

        this.propulsions = [];

        if (this.invalidSelfPropelled.indexOf(this.vehicleType.value.value) === -1) {
            this.propulsions.push(new Dropdown('self_propelled', 'Self-propelled'));
        }
        if (this.invalidDrawbar.indexOf(this.vehicleType.value.value) === -1) {
            this.propulsions.push(new Dropdown('semi_trailer', 'Semi-trailer'));
        }
        if (this.invalidSemiTrailer.indexOf(this.vehicleType.value.value) === -1) {
            this.propulsions.push(new Dropdown('draw_bar_trailer', 'Draw Bar - Trailer'));
        }


        this.propulsion.setValidators([
            Validators.required,
            autoCompleteValidator(this.propulsions)
        ]);

        this.propulsion.updateValueAndValidity({ onlySelf: true });

        setTimeout(() => {
            this.updateSeatingCapacityValidation()
        }, 0)

        // this.propulsions = [
        //     new Dropdown('self_propelled', 'Self-propelled'),
        //     new Dropdown('semi_trailer', 'Semi-trailer'),
        //     new Dropdown('draw_bar_trailer', 'Draw Bar - Trailer'),
        // ];

    }

    onCategoryChange(event): void {
        setTimeout(() => {

            this.types = [];

            switch (this.category.value.name) {
                case 'Motorcycle':
                    this.grossVehicleMass.setValidators([
                        Validators.max(3500),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validMotorCycle) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Light Pass (Seats <= 8)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(3500),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validLightPassenger) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Light Goods (GVM <= 3500Kg)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(3500),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validLightGoods) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Medium Pass (Seats 8 < X <= 16)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(7500),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validMediumPassenger) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Medium Goods (GVM 3500Kg < X <= 7500Kg)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(7500),
                        Validators.min(3501),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validMediumGoods) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Heavy Pass (Seats > 16)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(999999),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validHeavyPassenger) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Heavy Goods (GVM > 7500Kg)':
                    this.grossVehicleMass.setValidators([
                        Validators.max(999999),
                        Validators.min(7501),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validHeavyGoods) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                case 'Special':
                    this.grossVehicleMass.setValidators([
                        Validators.max(999999),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    for (const valid of this.validSpecial) {
                        this.types.push(this.getDropdownByCode(this.allTypes, valid));
                    }
                    break;
                default:
                    this.grossVehicleMass.setValidators([
                        Validators.max(999999),
                        Validators.min(0),
                        Validators.pattern('^[0-9]+$')
                    ]);
                    this.types = this.allTypes;
                    break;
            }

            this.types = DropdownUtils.sortPreferredName(this.types, ["14", "15", "18", "17", "01", "31", "12", "13", "16"]);
            this.types.push(new Dropdown('00', 'Not Applicable'));

            this.vehicleType.setValidators([
                Validators.required,
                autoCompleteValidator(this.types)
            ]);


            if (this.formGroup.controls['seatingCapacity'].value !== null) {
                this.formGroup.controls['seatingCapacity'].markAsTouched();
            }

            if (this.formGroup.controls['grossVehicleMass'].value !== null) {
                this.formGroup.controls['grossVehicleMass'].markAsTouched();
            }

            this.grossVehicleMass.updateValueAndValidity({ onlySelf: true });
            this.vehicleType.updateValueAndValidity({ onlySelf: true });

            this.updateSeatingCapacityValidation()


        }, 0);
    }

    getMinSeatingCapacity(): number {

        if (this.vehicleType.value && this.vehicleType.value.value) {
            switch (this.vehicleType.value.value) {
                case '01':
                    return 1
                case '02':
                    return 1
            }
        }

        if (this.category.value && this.category.value.name) {
            switch (this.category.value.name) {
                case 'Motorcycle':
                    return 1
                case 'Light Pass (Seats <= 8)':
                    return 1
                case 'Medium Pass (Seats 8 < X <= 16)':
                    return 8
                case 'Heavy Pass (Seats > 16)':
                    return 16
                case 'Light Goods (GVM <= 3500Kg)':
                    return 0
                case 'Medium Goods (GVM 3500Kg < X <= 7500Kg)':
                    return 0
                case 'Heavy Goods (GVM > 7500Kg)':
                    return 0
                case 'Special':
                    return 0
            }
        }

        return 0

    }

    getMaxSeatingCapacity(): number {

        if (this.vehicleType.value && this.vehicleType.value.value) {
            switch (this.vehicleType.value.value) {
                case '01':
                    return 2
                case '02':
                    return 3
            }
        }

        if (this.category.value && this.category.value.name) {
            switch (this.category.value.name) {
                case 'Motorcycle':
                    return 2
                case 'Light Pass (Seats <= 8)':
                    return 8
                case 'Medium Pass (Seats 8 < X <= 16)':
                    return 16
                case 'Heavy Pass (Seats > 16)':
                    return 999
                case 'Light Goods (GVM <= 3500Kg)':
                    return 8
                case 'Medium Goods (GVM 3500Kg < X <= 7500Kg)':
                    return 8
                case 'Heavy Goods (GVM > 7500Kg)':
                    return 8
                case 'Special':
                    return 8
            }
        }

        return 999

    }

    updateSeatingCapacityValidation() {

        this.seatingCapacityMin = this.getMinSeatingCapacity()
        this.seatingCapacityMax = this.getMaxSeatingCapacity()

        this.seatingCapacity.setValidators([
            Validators.required,
            Validators.max(this.seatingCapacityMax),
            Validators.min(this.seatingCapacityMin),
            Validators.pattern('^[0-9]+$')
        ]);

        this.seatingCapacity.updateValueAndValidity({ onlySelf: true });

        this.seatingCapacityInvalid = this.validateSeatingtoCategory()

    }

    onCheckValidation(event): void {

        this.seatingCapacityInvalid = this.validateSeatingtoCategory()

        if (!this.unverifiedColourSelected) {
            if (this.form.control) {
                this.form.control.removeControl('unverifiedColour');
            }
        }

        if (!this.seatingCapacityInvalid && !this.validateGVMtoCategory() && !this.form.invalid) {

            if (this.tareExceedsGVM()) {
                return;
            }

            if (this.validateGVMtoCategory()) {
                return;
            }

            let plateFound = false;
            for (const identifier of this.vehicle.vehicleIdentifiers) {
                if (identifier instanceof PlateNumber) {
                    identifier.number = this.vehiclePlateNumber.value;
                    plateFound = true;
                    break;
                }
            }
            if (!plateFound) {
                const plate = new PlateNumber();
                plate.number = this.vehiclePlateNumber.value;
                this.vehicle.vehicleIdentifiers.push(plate);
            }

            this.vehicle.vehicleIdentificationNumber = this.vehicleIdentificationNumber.value;
            this.vehicle.manufactureDate = this.manufactureDate.value.format(environment.formatDate);
            this.vehicle.seatingCapacity = this.seatingCapacity.value ? parseInt(this.seatingCapacity.value, 10) : null;
            this.vehicle.grossVehicleMass = this.grossVehicleMass.value ? parseInt(this.grossVehicleMass.value, 10) : null;
            this.vehicle.tare = this.tare.value ? parseInt(this.tare.value, 10) : null;

            if (this.category.value.name) {
                this.vehicle.category = this.category.value.name;
            }
            else {
                this.vehicle.category = this.category.value;
            }

            if (this.vehicleType.value.name) {
                this.vehicle.vehicleType = this.vehicleType.value.name;
            }
            else {
                this.vehicle.vehicleType = this.vehicleType.value;
            }

            if (this.usage.value.name) {
                this.vehicle.usage = this.usage.value.name;
            }
            else {
                this.vehicle.usage = this.usage.value;
            }

            if (this.propulsion.value.name) {
                this.vehicle.propulsion = this.propulsion.value.name;
            }
            else {
                this.vehicle.propulsion = this.propulsion.value;
            }

            if (this.unverifiedColour.value) {
                this.vehicle.colour = this.unverifiedColour.value;
            } else {
                if (this.colour.value.name) {
                    this.vehicle.colour = this.colour.value.name;
                }
                else {
                    this.vehicle.colour = this.colour.value;
                }
            }

            if (this.unverifiedMakeSelected) {
                this.vehicle.make = this.unverifiedMake.value;
                const make = new Make();
                make.name = this.vehicle.make;
                this.onUnverifiedMake.emit(make);
            } else {
                if (this.make.value.name) {
                    this.vehicle.make = this.make.value.name;
                }
                else {
                    this.vehicle.make = this.make.value;
                }
            }

            if (this.unverifiedModelSelected) {
                this.vehicle.model = this.unverifiedModel.value;
                const model = new Model();
                const makeDescriptionIdentifier = new MakeDescriptionIdentifier();
                makeDescriptionIdentifier.description = this.vehicle.make;
                model.makeIdentifier = makeDescriptionIdentifier;
                model.name = this.vehicle.model;
                this.onUnverifiedModel.emit(model);
            } else {
                if (this.model.value.name) {
                    this.vehicle.model = this.model.value.name;
                }
                else {
                    this.vehicle.model = this.model.value;
                }
            }

            if (this.engineNumber && this.engineNumber.valid) {
                this.vehicle.engine = new Engine();
                this.vehicle.engine.transmission = null;
                this.vehicle.engine.engineCapacity = null;
                this.vehicle.engine.netPower = null;
                this.vehicle.engine.fuelType = null;
                this.vehicle.engine.engineNumber = this.engineNumber.value;
            }

            this.onVehicle.emit(this.vehicle);


            setTimeout(() => {
                this.onValid.emit();
            });

        } else {

            const target = jQuery('mat-form-field.ng-invalid.ng-star-inserted');

            if (target && jQuery(target).position()) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).position().top }, 'slow');
                target.focus();
            }

        }

    }

    getVehiclePlateNumber(vehicle: Vehicle): string {
        if (!vehicle || !vehicle.vehicleIdentifiers) {
            return '';
        }
        for (const identifier of vehicle.vehicleIdentifiers) {
            if (identifier instanceof PlateNumber) {
                return identifier.number;
            }
        }
        return '';
    }

    optionsId(event) {
        return event.replace(/\s/g, "").toLowerCase();
    }

    get canCaptureForCountry(): boolean {
        const easyCaptureAlpaTwo = ['ZA'];
        return easyCaptureAlpaTwo.map((alpha) => alpha.toLocaleLowerCase())
            .includes(this.country.alphaTwo.toLocaleLowerCase());
    }

    clearEngine(): void {
        this.engineNumber.setValue('');
        const identifiers = this.vehicle.vehicleIdentifiers;
        this.vehicle.vehicleIdentifiers = identifiers.filter((identifier) => {
            if (!(identifier instanceof EngineNumber)) {
                return identifier;
            }
        });
    }

    get isSelfPropelled(): boolean {
        if (this.vehicle.propulsion === "Self-propelled") {
            return true;
        } else {
            return false;
        }
    }

    setEngine(): void {
        let engine = new Engine();
        if (this.engineNumber && this.engineNumber.valid) {
            engine.engineNumber = this.engineNumber.value;
            engine.transmission = null;
            engine.engineCapacity = null;
            engine.netPower = null;
            engine.fuelType = null;
            this.onEngine.emit(engine);
        }
    }


    filterMakes(value: string): void {
        console.log(value)
        this.filteredMakes = of(FormComponent.filterDropDown(this.makes, value));
    }

    filterModes(value: string): void {
        this.filteredModels = of(FormComponent.filterDropDown(this.models, value));
    }
}
