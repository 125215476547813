import {Response} from "../../../../../../../urdad/services/models/response.model";
import {Account} from '../../../../../models/account.model';

export class ProvideAccountInformationResponse extends Response {
    constructor() {
        super();
    }

    public accounts: Account[] = [];
}
