import { Criteria } from "../../../../criteria/models/criteria.model";
import { TpiIdentifier } from "../../../../entity/identification/models/tpi-identifier.model";

export class ReportLegalEntityDateCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.revenuesplit.ReportLegalEntityDateCriteria";
    }

    public dateFrom: Date;
    public dateTo: Date;
    public tpiIdentifier: TpiIdentifier;
}