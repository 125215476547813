import { Criteria } from '../../../../../../../criteria/criteria_api';
import { Request } from '../../../../../../../urdad/services/models/request.model';

export class ProvideRetailProfileVehiclesRequest extends Request{
    constructor(){
        super();
    }

    public criteria: Criteria;

    public vehicleIdentificationNumber: string;
}
