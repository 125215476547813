import {Response} from '../../../../../../../urdad/services/models/response.model';
import { OrderSummary } from '../../../../../checkout_api';

export class ProvideOrderSummaryResponse extends Response {
    constructor() {
        super();
    }

    public orderSummaries: OrderSummary [] = [];
    public totalNumberOfResults: number;
    public resultsTo: number;
    public resultsFrom: number;
}
