import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    FinaliseCreateVerifiedAccountByInsertRequest,
    FinaliseCreateVerifiedAccountByUpdateRequest,
    MarshallerService
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class VerifiedAccountFinalisationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCreateVerifiedAccountByInsert(finaliseCreateVerifiedAccountByInsertRequest: FinaliseCreateVerifiedAccountByInsertRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/verifiedaccountfinalisationmanager/finalisecreateverifiedaccountbyinsert';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateVerifiedAccountByInsertRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    finaliseCreateVerifiedAccountByUpdate(finaliseCreateVerifiedAccountByUpdateRequest: FinaliseCreateVerifiedAccountByUpdateRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/verifiedaccountfinalisationmanager/finalisecreateverifiedaccountbyupdate';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateVerifiedAccountByUpdateRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }
}
