import {Response} from '../../../../../../urdad/services/models/response.model'
import {CountryCheckoutItemGrouping} from '../../../../models/country-checkout-item-grouping.model'

export class CompileCheckoutItemGroupingResponse extends Response {
    constructor(){
        super();
    }

    public countryCheckoutItemGrouping: CountryCheckoutItemGrouping;
}
