import {Component, Inject, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ActivatedRoute, Router} from '@angular/router'
import {
    OrderInformationProviderService
} from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
    DirectPayOnlineCardPaymentInitialisationManagerService
} from '../../../../http.services/retail/payment/dpo/direct-pay-online-card-payment-initialisation-manager/direct-pay-online-card-payment-initialisation-manager.service'
import {
    CustomerInvoiceInformationProviderService
} from '../../../../http.services/retail/invoice/customer-invoice-information-provider/customer-invoice-information-provider.service'
import {
    RenderedConceptualDocumentProviderService
} from '../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {PDFDisplayModel, PDFPrintModel, PrintService} from '../../../../app.services/common/printing/print.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import * as moment from 'moment/moment'
import {environment} from '../../../../../environments/environment'
import {Utils} from '../../../shared/Utils/Utils'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {
    ServiceGroupFinalisationManagerService
} from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {
    CustomerInvoiceSummary,
    DpoPaymentProcess,
    EmailAddress,
    FinaliseUpdateLegalEntityRequest,
    InitialiseProcessCardPaymentRequest,
    InitialiseProcessCardPaymentResponse,
    InvoiceDateLegalEntityCriteria,
    LegalEntityIdentifier,
    LegalEntityPersistenceManagerType,
    Money,
    Order,
    OrderReferenceNumber,
    OrderStatus,
    OrderSummary,
    OrderSummaryRetailProfileCriteria,
    PdfDocument,
    ProvideCustomerInvoiceSummaryRequest,
    ProvideCustomerInvoiceSummaryResponse,
    ProvideOrderSummaryRequest,
    ProvideOrderSummaryResponse,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    RenderedDocumentIdCriteria,
    RenderedDocumentType,
    RetailProfileOrderDateCriteria,
    RetailProfileOrderStatusCriteria,
    RetailProfileOwnerIdentifier,
    UnMarshallerService,
} from '@magnabc/tpi'
import {
    PayfastPaymentManagerService
} from '../../../../app.services/managers/payafast-payament-manager/payfast-payment-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

declare const jQuery: any;

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent  extends FormComponent implements OnInit {

    FILTER_ALL = 0;
    FILTER_STATUS = 1;
    FILTER_DATE = 2;

    rows: OrderSummary[] = [];
    columns = [
        { date: 'date' },
        { orderNumber: 'orderNumber' },
        { status: 'status' },
        { total: 'total' }
    ];

    totalElements = 0;
    pageNumber = 0;
    pageSize = 8;
    selectedStatus = [];
    invoicePages = 1;
    filter = this.FILTER_ALL;
    orderStatus = OrderStatus;
    activeToast: ActiveToast<any>;
    temp: OrderSummary[] = [];

    from;
    to;

    selected: string;
    onlinePaymentEnabled: boolean;

    previewDocument = false;
    documentToPreview: any;
    invoiceSummaries: CustomerInvoiceSummary[] = [];
    active: any;
    showUnderwriterInvoice = false;
    backToOrderHistory: string;
    mainOrder: Order;
    orderNumber: string;
    payfastOrderCancelled: string;


    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private router: Router,
                private entityService: LegalEntityFinalisationManagerService,
                private orderInformationProviderService: OrderInformationProviderService,
                private retailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private dialog: MatDialog,
                private toastr: ErrorToastService,
                public quickstartService: QuickTransactManagerService,
                private directPayOnlineCardPaymentInitialisationManagerService: DirectPayOnlineCardPaymentInitialisationManagerService,
                private customerInvoiceInformationProviderService: CustomerInvoiceInformationProviderService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private authenticationService: AuthenticationService,
                private printService: PrintService,
                private runtimeConfigurationService: RuntimeConfigurationService,
                private route: ActivatedRoute,
                private payfastPaymentManager: PayfastPaymentManagerService
                ) {
        super(translate, translateParser);

        this.onlinePaymentEnabled = runtimeConfigurationService.onlinePayment

        this.temp = this.rows;
        this.from = moment();
        this.to = moment();
    }

    ngOnInit(): void {
        this.onToggleSearch();
        this.startLoad();
        this.route.queryParams.forEach((params: any) => {
            this.backToOrderHistory = params.backToOrderHistory
            this.orderNumber = params.orderNumber
            this.payfastOrderCancelled = params.cancelled
        })

        if (this.backToOrderHistory && !this.payfastOrderCancelled) {
            this.payfastPaymentManager.finalizePayfastPayment(this.orderNumber).then(() => {
                this.startLoad();
                this.retailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
                    this.provideOrders();
                });
            });
        } else {
            this.retailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
                this.provideOrders();
            });
        }

    }

    provideOrders(): void {

        this.loading = true;
        this.rows = [];

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;

        let criteria;

        if (this.filter === this.FILTER_DATE) {
            this.from.startOf('day');
            this.to.endOf('day');
            criteria = new RetailProfileOrderDateCriteria();
            criteria.dateFrom = this.from.startOf('day').format(environment.formatDateServer) as Date;
            criteria.dateTo = this.to.endOf('day').format(environment.formatDateServer) as Date;
            criteria.retailProfileIdentifier = retailProfileOwnerIdentifier;

            for (const orderIdentifier of this.retailProfileFinalisationManagerService.retailProfile.orderIdentifiers) {
                criteria.orderIdentifiers.push(orderIdentifier);
            }
        } else if (this.filter === this.FILTER_STATUS && this.selectedStatus.length > 0) {
            criteria = new RetailProfileOrderStatusCriteria();
            criteria.statuses = this.selectedStatus;
            criteria.retailProfileIdentifier = retailProfileOwnerIdentifier;

            for (const orderIdentifier of this.retailProfileFinalisationManagerService.retailProfile.orderIdentifiers) {
                criteria.orderIdentifiers.push(orderIdentifier);
            }
        } else {
            criteria = new OrderSummaryRetailProfileCriteria();
            criteria.retailProfileIdentifier = retailProfileOwnerIdentifier;
        }

        const provideOrderSummaryRequest = new ProvideOrderSummaryRequest();
        provideOrderSummaryRequest.batchNumber = this.pageNumber + 1;
        provideOrderSummaryRequest.batchSize = this.pageSize;
        provideOrderSummaryRequest.criteria = criteria;

        this.orderInformationProviderService.provideOrderSummary(provideOrderSummaryRequest).then((data) => {
            this.stopLoad();
            const response: ProvideOrderSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderSummaryResponse);
            for (const order of response.orderSummaries) {
                this.rows.push(order);
            }

            this.rows.sort((first, second) => {
                if (first.date > second.date) {
                    return -1;
                } else if (first.date < second.date) {
                    return 1;
                }
                return 0;
            });
            this.temp = this.rows;
            this.rows = [...this.rows];

            this.totalElements = response.totalNumberOfResults;

            this.loading = false;
        }).catch(() => {
            this.loading = false;
        });

    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideOrders();
    }

    onStatus(status): boolean {
        if (this.selectedStatus.indexOf(status) > -1) {
            this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        } else {
            this.selectedStatus.push(status);
        }
        this.provideOrders();
        return false;
    }

    onSelectionChanged() {
        this.pageNumber = 0;
        this.provideOrders();
    }

    onFilter(filter: number): boolean {
        this.filter = filter;
        this.provideOrders();
        return false;
    }

    isStatusSelected(status): boolean {
        return this.selectedStatus.indexOf(status) > -1;
    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter(function (d) {
            return d.orderNumber.replace(/ /g, "").toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    getFormatedDate(value): string {
        return value.year + "-" + value.monthValue + "-" + value.dayOfMonth;
    }

    viewOrder(event, row): void {
        this.router.navigate(['/retail/order/' + row.orderNumber]);
    }

    viewInvoice(event, row): void {
        let customerInvoiceSummary: CustomerInvoiceSummary;

        this.invoiceSummaries = [];

        const criteria = new InvoiceDateLegalEntityCriteria();

        if (this.authenticationService.getActiveProxy()) {
            criteria.legalEntityIdentifier = this.getLegalEntityIdentifier(this.authenticationService.getActiveProxyLegalEntityIdentifiers());
        }
        else {
            criteria.legalEntityIdentifier = this.getLegalEntityIdentifier(this.entityService.legalEntity.legalEntityIdentifiers);
        }

        const provideCustomerInvoiceSummaryRequest = new ProvideCustomerInvoiceSummaryRequest();
        provideCustomerInvoiceSummaryRequest.batchNumber = -1;
        provideCustomerInvoiceSummaryRequest.batchSize = -1;
        provideCustomerInvoiceSummaryRequest.criteria = criteria;

        this.startLoad();
        this.customerInvoiceInformationProviderService.provideCustomerInvoiceSummary(provideCustomerInvoiceSummaryRequest).subscribe((data) => {
            this.stopLoad();

            const response: ProvideCustomerInvoiceSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideCustomerInvoiceSummaryResponse);

            for (const customerInvoice of response.customerInvoiceSummaries.sort()) {
                if (customerInvoice.orderNumber === row.orderNumber) {
                    this.invoiceSummaries.push(customerInvoice);
                }
            }

            if (this.invoiceSummaries.length > 0) {
                this.active = this.invoiceSummaries[0];
                this.onPreview(event, this.active);
            }

        }, (error) => {
            this.stopLoad();
        });
    }

    getLegalEntityIdentifier(identifiers: LegalEntityIdentifier[]) {
        var legalEntityIdentifier;

        if (Utils.getRegistrationNumber(identifiers) != null) {
            legalEntityIdentifier = Utils.getRegistrationNumber(identifiers);
        }
        else if (Utils.getNationalIdentityNumber(identifiers) != null) {
            legalEntityIdentifier = Utils.getNationalIdentityNumber(identifiers);
        }
        else if (Utils.getPassportNumber(identifiers) != null) {
            legalEntityIdentifier = Utils.getPassportNumber(identifiers);
        }

        return legalEntityIdentifier;
    }

    onPreview(event, customerInvoice: CustomerInvoiceSummary): boolean {
        if (event) {
            event.stopPropagation();
        }
        this.startLoad();
        this.getRenderedDocument(customerInvoice, (pdfDocument) => {
            this.stopLoad();
            if (pdfDocument.type === RenderedDocumentType.CUSTOMER_INVOICE) {
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, () => { });
            });
        });

        return false;

    }

    getRenderedDocument(customerInvoiceSummary: CustomerInvoiceSummary, callback): void {

        const renderedDocumentIdCriteria = new RenderedDocumentIdCriteria();
        renderedDocumentIdCriteria.documentId = customerInvoiceSummary.invoiceNumber;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentIdCriteria;

        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                this.showUnderwriterInvoice = true;

                callback((provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument));
            } else {
                callback({});
            }

        });

    }

    onPrintPreview(): void {
        this.printDocument();
    }

    displayPDF(base64, complete) {
        const canvasId = '#canvas-' + this.active.invoiceNumber;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData);
    }

    printDocument(): void {
        const targetId = 'canvas-' + this.active.invoiceNumber;
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.invoicePages,
            targetId,
            openBlank: true
        };
        this.printService.printDocument(pdfPrintData);
    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPreview = null;

    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    /*
    * Navigate to payment page
    */
    onPay(event, order: OrderSummary): void {
        localStorage.setItem('checkOutPage', 'false');
        this.router.navigate(['/retail/checkout/payment/' + order.orderNumber]);
    }

    payOnline(event, order: OrderSummary): void {

        switch (this.entityService.legalEntity['@class']) {
            case 'za.co.magnabc.tpi.entity.NaturalPerson':

                if (this.entityService.legalEntity.emailAddresses.length === 0 || !this.entityService.legalEntity.emailAddresses[0]) {
                    this.openCaptureEmailDialog(order);
                    event.stopPropagation();
                    return;
                }

                break;
            case 'za.co.magnabc.tpi.entity.JudicialPerson':

                if (this.entityService.legalEntity.contactPersons[0].emailAddresses.length === 0 || !this.entityService.legalEntity.contactPersons[0].emailAddresses[0]) {
                    this.openCaptureEmailDialog(order);
                    event.stopPropagation();
                    return;
                }

                break;
        }

        this.initialiseOnlinePayment(order);
        // this.onOnlinePaymentDialog(order);
        event.stopPropagation();

    }

    initialiseOnlinePayment(order: OrderSummary): void {

        const initialiseProcessCardPaymentRequest: InitialiseProcessCardPaymentRequest = new InitialiseProcessCardPaymentRequest();
        initialiseProcessCardPaymentRequest.amount = new Money();
        initialiseProcessCardPaymentRequest.amount.currency = order.totalDue.currency;
        initialiseProcessCardPaymentRequest.amount.amount = order.totalDue.amount;
        initialiseProcessCardPaymentRequest.backUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/order/${order.orderNumber}?noRedirect=true`;
        initialiseProcessCardPaymentRequest.declinedUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/order/${order.orderNumber}?noRedirect=true`;
        initialiseProcessCardPaymentRequest.redirectUrl = `${this.runtimeConfigurationService.hostUrl}/#/retail/order/${order.orderNumber}?noRedirect=true`;
        initialiseProcessCardPaymentRequest.paymentReference = new OrderReferenceNumber(order.orderNumber);
        initialiseProcessCardPaymentRequest.retailProfile = this.retailProfileFinalisationManagerService.retailProfile;

        this.startLoad();
        this.directPayOnlineCardPaymentInitialisationManagerService.initialiseProcessCardPayment(initialiseProcessCardPaymentRequest).subscribe((httpResponse) => {

            const response: InitialiseProcessCardPaymentResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, InitialiseProcessCardPaymentResponse) as InitialiseProcessCardPaymentResponse;
            if (response.dpoPaymentProcess.toUpperCase() === DpoPaymentProcess.REDIRECT.toUpperCase()) {
                window.location.href = `https://secure.3gdirectpay.com/dpopayment.php?ID=${response.transactionToken.value}`;
            } else {
                this.onOnlinePaymentDialog(order);
            }
            this.stopLoad();

        }, () => {
            this.toastPaymentGatewayError();
            this.stopLoad();
        });
    }

    toastPaymentGatewayError() {

        this.activeToast = this.toastr.success("Could not connect to payment gateway. Please try again or contact support if the problem persists.", "Payment Gateway", {
            toastComponent: ToastComponent,
            timeOut: 5000,
            progressBar: true,
            closeButton: true
        });

    }

    onOnlinePaymentDialog(order: OrderSummary): void {
        if (this.onlinePaymentEnabled) {

            this.selected = 'online';
            const dialogRef = this.dialog.open(DialogOnlinePaymentComponent, {
                width: '96%',
                panelClass: 'padded-modal',
                maxWidth: '600px',
                position: { top: "2%" },
                data: { order: order }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.provideOrders();
                }
            });

        }

    }

    openCaptureEmailDialog(order: OrderSummary): void {
        const dialogRef = this.dialog.open(DialogCaptureEmailComponent, {
            width: '80%',
            panelClass: 'padded-modal',
            position: { top: "5%" },
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                if (result.updated) {
                    this.initialiseOnlinePayment(order);
                    // this.onOnlinePaymentDialog(order);
                }
            }
        });
    }

    onActive(active): void {
        this.active = active;
        this.onPreview(false, active);
    }
}



@Component({
    selector: 'app-dialog-online-payment-2',
    templateUrl: './dialog-online-payment.html',
    styleUrls: ['./online-payment.component.scss']
})
export class DialogOnlinePaymentComponent {

    paid = false;
    order: OrderSummary;

    constructor(public dialogRef: MatDialogRef<DialogOnlinePaymentComponent>,
                @Inject(MAT_DIALOG_DATA) data: any,
                public authenticationService: ServiceGroupFinalisationManagerService) {
        this.paid = false;
        this.order = data.order;
    }

    close(): void {
        this.dialogRef.close();
    }

    getTotal(): number {
        return this.order.totalDue.amount;
    }

    getCurrencyCode(): string {
        return this.order.totalDue.currency;
    }

    onPaid(event): void {
        this.paid = true;
    }

    onContinue(event): void {
        this.dialogRef.close(true);
    }

}

@Component({
    selector: 'app-dialog-capture-email-2',
    templateUrl: './dialog-capture-email.html',
})
export class DialogCaptureEmailComponent extends FormComponent {

    formGroup: UntypedFormGroup;
    email: UntypedFormControl;

    private activeToast: ActiveToast<any>;
    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(
        public dialogRef: MatDialogRef<DialogCaptureEmailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
        private toastr: ToastrService,
    ) {
        super(translate, translateParser);

        this.email = new UntypedFormControl('', [
            Validators.email
        ]);

        this.formGroup = new UntypedFormGroup({
            'email': this.email
        });

    }

    close(): void {
        this.dialogRef.close({ success: false, updated: false });
    }

    onUpdate(event): void {

        if (this.formGroup.valid) {
            this.onUpdateAccount();
        }

    }

    onUpdateAccount(): void {

        const finaliseUpdateLegalEntityRequest = new FinaliseUpdateLegalEntityRequest();
        const legalEntity = this.legalEntityFinalisationManagerService.legalEntity;
        const newEmailAddress = new EmailAddress();

        switch (this.legalEntityFinalisationManagerService.legalEntity['@class']) {
            case 'za.co.magnabc.tpi.entity.NaturalPerson':
                newEmailAddress.address = this.email.value;
                newEmailAddress.emailAddressType = "primary";
                legalEntity.emailAddresses[0] = newEmailAddress;
                legalEntity.emailAddresses[0].address = this.email.value;
                break;
            case 'za.co.magnabc.tpi.entity.JudicialPerson':
                newEmailAddress.address = this.email.value;
                newEmailAddress.emailAddressType = "primary";
                legalEntity.contactPersons[0].emailAddresses = [];
                legalEntity.contactPersons[0].emailAddresses[0] = newEmailAddress;
                legalEntity.contactPersons[0].emailAddresses[0].address = this.email.value;
                break;
        }
        finaliseUpdateLegalEntityRequest.legalEntity = legalEntity;
        finaliseUpdateLegalEntityRequest.pmType = LegalEntityPersistenceManagerType.PUBLIC;

        this.startLoad();
        this.legalEntityFinalisationManagerService.finaliseUpdateLegalEntity(finaliseUpdateLegalEntityRequest).subscribe(response => {
            this.stopLoad();
            this.legalEntityFinalisationManagerService.refreshLegalEntity(
                Utils.getLegalEntityIdentifier(
                    this.legalEntityFinalisationManagerService.legalEntity.legalEntityIdentifiers));
            this.toast(response);
        });

    }

    toast(response) {

        this.translate.get('TOAST.UPDATE_RETAIL_PROFILE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: "User Profile" }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.dialogRef.close({ success: true, updated: true });
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.dialogRef.close({ success: true, updated: true });
            });
        });

    }

}
