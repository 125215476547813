import {CertificateIdentifier} from '../../identification/models/certificate-identifier.model'

export class InsuranceCertificateNumberCriteria {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.certificate.InsuranceCertificateNumberCriteria";
    }

    certificateIdentifier: CertificateIdentifier[] = [];

}
