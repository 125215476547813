import { Response } from "../../../../../../../urdad/services/models/response.model";
import {ScannedDocumentIdentifier} from '../../../../../identification/models/scanned-document-identifier.model';

export class InsertScannedDocumentResponse extends Response{
  constructor(){
    super();
  }

  public scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
}
