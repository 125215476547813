import { Criteria } from "../../../criteria/criteria_api";

export class InsurancePolicyNumberCriteria extends Criteria {

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyNumberCriteria";
    }

    public insurancePolicyNumber: string;
    public ycInsurancePolicyNumber: string;
    public date: Date;
}
