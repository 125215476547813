import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {Router} from "@angular/router";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {environment} from "../../../../../environments/environment";
import {SubscriptionComponent} from "../../../../view.components/security/authorisation/subscription/subscription.component";
import {Location} from "@angular/common";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {SubscriptionInformationProviderService} from "../../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {
    LegalEntityIdentifier,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsResponse, ServiceNameTypeIdentifier,
    Subscription,
    SubscriptionUsedByLegalEntityCriteria,
    UnMarshallerService
} from "@magnabc/tpi";
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service';
import { UnderwriterInformationProviderService } from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import {Title} from '@angular/platform-browser';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'


@Component({
    selector: 'app-subscription-create',
    templateUrl: './subscription-create-page.component.html',
    styleUrls: ['./subscription-create-page.component.scss']
})
export class SubscriptionCreatePageComponent extends FormComponent implements OnInit {

    currentSubscriptions: Subscription[] = [];
    subscription: Subscription;

    termsNotAccepted = false;
    formGroup: UntypedFormGroup;
    terms: UntypedFormControl;

    @ViewChild(SubscriptionComponent) subscriptionComponent: SubscriptionComponent;

    activeToast: ActiveToast<any>;
    owner: LegalEntityIdentifier;
    oneOrMoreDuplicateSubscriptions = false;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private toastr: ErrorToastService,
                private router: Router,
                private brokerService: UnderwriterInformationProviderService,
                private location: Location,
                private authenticationService: AuthenticationService,
                private subscriptionInformationProviderService: SubscriptionInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Create Subscription - Console - ${this.runtimeConfigurationService.title}`);

        this.subscription = new Subscription();

    }

    ngOnInit() {
        this.owner = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());
        this.getSubscriptions();

        this.terms = new UntypedFormControl(false, [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            terms: this.terms
        });

    }

    getSubscriptions() {
        const criteria = new SubscriptionUsedByLegalEntityCriteria();
        criteria.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        const provideSubscriptionsRequest: ProvideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = criteria;
        this.startLoad();
        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSubscriptionsResponse) as ProvideSubscriptionsResponse);
                this.currentSubscriptions = [];
                for (const subscription of response.subscriptions){
                    this.currentSubscriptions.push(subscription);
                }
            }

            this.stopLoad();
        }).catch(error => {
            this.stopLoad();
        });
    }

    onSubscription(subscription: Subscription): void {

        this.subscription = subscription;
        this.toast(subscription);

    }

    onCheckValidation(): void {

        this.termsNotAccepted = false;
        if (this.formGroup.valid && this.terms.value) {
            this.submit();
        } else {
            this.termsNotAccepted = true;
        }

    }

    submit(): void {
        this.subscriptionComponent.submit();
    }

    compareServiceGroupIdentifiers(subscription: Subscription): boolean {
        let result = false;
        this.authenticationService.getSubscriptions().forEach(sub => {
            const existingServiceGroup = sub.serviceGroup;
            const newServiceGroup = subscription.serviceGroup;

            newServiceGroup.linked.forEach(newService => {
                existingServiceGroup.linked.forEach(existingService => {
                    if (
                        (newService as ServiceNameTypeIdentifier).name ===
                        (existingService as ServiceNameTypeIdentifier).name
                    ) {
                        // ensure function returns true when subscription already exist
                        result = true;
                        return result;
                    }
                })
            })
        });
        return result;
    }

    toast(response) {

        this.translate.get('TOAST.CREATE_SUBSCRIPTION').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        this.subscriptionComponent.initSubscription();
                        this.terms.setValue(false);
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });
    }

    onCoUsers(): boolean {
        this.router.navigate(['legal'], {fragment: 'terms-and-conditions', queryParams: {coUser: true}});
        return false;
    }

    onCoVendors(): boolean {
        this.router.navigate(['legal'], {fragment: 'terms-and-conditions', queryParams: {coVendor: true}});
        return false;
    }

    isCoVendor(): boolean {
        return !!(this.authenticationService.getAgent() || this.brokerService.selectedUnderwriter);
    }

}
