import { NaturalPersonIdentifier } from './natural-person-identifier.model';
import { Country } from '../../../location/geographic/models/country.model';

export class DriverTrafficRegisterNumber extends NaturalPersonIdentifier
{
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.driver.identification.TrafficRegisterNumber";
  }
  public number: string;
  public countryOfIssue: string;

}
