import {Response} from '../../../../../../../urdad/services/models/response.model';

export class ProvideSuitableInsurancePolicyResponse extends Response{

    constructor(){
        super();
    }
    
    public insurancePolicyFound: boolean;
}
