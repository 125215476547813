import { Criteria } from "../../criteria/models/criteria.model";
import { CountryIdentifier } from "../../location/geographic/identification/models/country-identifier.model";

export class CountryTaxesCountryCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.financial.CountryTaxesCountryCriteria";
    }

	public countryIdentifier: CountryIdentifier[] = [];
}