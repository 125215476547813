import { VehicleIdentifier } from "./vehicle-identifier.model";


export class VehicleIdentificationNumber extends VehicleIdentifier {

    constructor() {
      super();
      this['@class'] = "za.co.magnabc.tpi.vehicle.identification.VehicleIdentificationNumber";
    }

    public number:string;

}
