import { Criteria } from "../../../criteria/models/criteria.model";
import {RetailProfileIdentifier} from '../identification/retail-profile-identifier.model';

export class RetailProfileIdentifierCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.profile.RetailProfileIdentifierCriteria";
    } 

    public retailProfileIdentifier: RetailProfileIdentifier;
}
