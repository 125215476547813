import { Injectable } from '@angular/core';
import { Order, OrderNumberCriteria, OrderNumberIdentifier, ProvideOrderInformationRequest, ProvideOrderInformationResponse, ReceivePayfastPaymentRequest, ReceivePayfastPaymentResponse, RetrieveExchangeRateRequest, RetrieveExchangeRateResponse, UnMarshallerService } from '@magnabc/tpi';
import { OrderInformationProviderService } from '../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';
import { ReivePayfastPaymentService } from '../../../http.services/retail/payment/payfast/payfast-payment-manager/receive-payfast-payment.service';
import { environment } from '../../../../environments/environment';
import moment from 'moment';
import { RetrieveExchangeRateService } from '../../../http.services/financial/retrieve-exchange-rate-provider/retrieve-exchange-rate.service';

@Injectable({
    providedIn: 'root'
})
export class PayfastPaymentManagerService {

    order: Order;
    exchangeRate: any;

    constructor(
        private unMarshallerService: UnMarshallerService,
        private orderInformationProviderService: OrderInformationProviderService,
        private receivePayfastPaymentService: ReivePayfastPaymentService,
        private retrieveExchangeRateService: RetrieveExchangeRateService
    ) { }


    getOrder(reference: string): Promise<void> {

        return new Promise<void>((resolve) => {
            const orderReferenceNumberCriteria = new OrderNumberCriteria();
            orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(reference));

            const provideOrdersRequest = new ProvideOrderInformationRequest();
            provideOrdersRequest.criteria = orderReferenceNumberCriteria;

            this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {
                const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
                this.removeScannedDocumentKey(response)
                this.order = response.orders[0];
                resolve()
            }).catch((error) => {
                console.log(error)
            });
        })
    }

    finalizePayfastPayment(element): Promise<any> {
        return new Promise((resolve, reject) => {
            // Get the order you want to update/finalise payment for
            this.getOrder(element).then(() => {
                const receivePayfastPaymentRequest = new ReceivePayfastPaymentRequest();
                receivePayfastPaymentRequest.payload = this.getPayload();
                this.receivePayfastPaymentService.receivePayfastPayment(receivePayfastPaymentRequest).then((data) => {
                    const response: ReceivePayfastPaymentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ReceivePayfastPaymentResponse);
                    if (response.message !== null) {
                        localStorage.setItem('receivePayfastPaymentMessage', response.message);
                    } else {
                        localStorage.removeItem('receivePayfastPaymentMessage')
                    }
                    resolve(response.message);
                }).catch(error => {
                    reject(error);
                })
            });
        });
    }

    retrieveExchangeRate(toCurrency): Promise<any> {
        return new Promise((resolve, reject) => {
            const retrieve = new RetrieveExchangeRateRequest();
            retrieve.baseCurrency = "USD";
            retrieve.quoteCurrency = toCurrency;
            retrieve.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
            this.retrieveExchangeRateService.retrieveExchangeRate(retrieve).then((data: any) => {
                const response: RetrieveExchangeRateResponse = this.unMarshallerService.unMarshallFromJson(data.body, RetrieveExchangeRateResponse);
                if (response) {
                    this.exchangeRate = response.exchangeRate;
                }
                resolve(this.exchangeRate)
            }).catch(err => {
                console.log(err)
            })
        })
    }

    private removeScannedDocumentKey(response: ProvideOrderInformationResponse) {
        for (const orderItem of response.orders[0].orderItems) {
            if ((orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem") && (orderItem["scannedDocument"].length === 0)) {
                delete orderItem["scannedDocument"]
            }
        }
    }

    private getPayload() {
        return {
          merchant_id: environment.merchant_id,
          merchant_key: environment.merchant_key,
          amount: this.order.totalDue.amount / 100,
          currency: this.order.totalDue.currency,
          item_name: this.order.number,
          return_url: '',
          cancel_url: '',
          notify_url: ''
        }
    }

}
