import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {TranslateModule} from "@ngx-translate/core"
import {RouterModule} from "@angular/router"
import {FormsModule} from "@angular/forms"
import {BrowserModule} from "@angular/platform-browser"
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component'
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component'
import {RefundPolicyComponent} from './refund-policy/refund-policy.component'
import {PaymentTermsConditionsComponent} from './payment-terms-conditions/payment-terms-conditions.component'
import {TpiTermsAndConditionsComponent} from './tpi-terms-and-conditions/tpi-terms-and-conditions.component'
import {DeclarationComponent} from './declaration/declaration.component'
import {SharedModule} from '../shared/shared.module'

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        SharedModule
    ],
    exports: [
        TermsConditionsComponent,
        TpiTermsAndConditionsComponent,
        PrivacyPolicyComponent,
        DeclarationComponent
    ],
    declarations: [
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        RefundPolicyComponent,
        PaymentTermsConditionsComponent,
        TpiTermsAndConditionsComponent,
        DeclarationComponent
    ]
})
export class LegalModule { }
