import { PayoutMethodIdentifier } from "./payout-method-identifier.model";

export class PayoutMethodBankTransferIdentifier extends PayoutMethodIdentifier{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.payout.identification.PayoutMethodBankTransferIdentifier";
    }

    public accountNumber : string;
    public bankName : string;
}