import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {
    InitialiseCancelInsurancePolicyRequest,
    InitialiseExtendInsurancePolicyRequest,
    MarshallerService
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsurancePolicyInitialisationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private readonly basePath = `/retail/insurance/insurancepolicyinitialisationmanager`;
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseCancelInsurancePolicy(initialiseCancelInsurancePolicyRequest: InitialiseCancelInsurancePolicyRequest): Observable<any> {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + `${this.basePath}/initialisecancelinsurancepolicy`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseCancelInsurancePolicyRequest);
        return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
    }

    initialiseExtendInsurancePolicy(initialiseExtendInsurancePolicyRequest: InitialiseExtendInsurancePolicyRequest): Observable<any> {
        const URI = this.runtimeConfigurationService.apiBaseUrl + `${this.basePath}/initialiseextendinsurancepolicy`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseExtendInsurancePolicyRequest);
        return this.httpClient.post(URI, jsonRequest, this.options);
    }

}
