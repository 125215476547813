import {TpiIdentifier} from "../../../entity/identification/models/tpi-identifier.model";

export class SubscriptionSummary {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionSummary";
    }
    
    public activeSubscriptions: number;
    public email: string;
    public name: string;
    public surname: string;
    public tpiIdentifier:TpiIdentifier;
}
