import {Component, OnInit, ViewChild} from '@angular/core';
import {
    ContactNumber,
    LegalEntityIdentifier,
    Order,
    OrderNumberCriteria,
    OrderNumberIdentifier,
    OrderReferenceNumber,
    OrderStatus,
    Payment,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    ProvideOrderLegalEntityRequest,
    UnMarshallerService,
    VerifyCardPaymentRequest,
    VerifyCardPaymentResponse
} from "@magnabc/tpi";
import {OrderQueryComponent} from "../../../../../view.components/retail/insurance/third-party-insurance/order-query/order-query.component";
import {DocumentRenderComponent} from "../../../../../view.components/shared/document-render/document-render.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {RetailProfileFinalisationManagerService} from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../../../app.services/managers/authentication/authentication.service";
import {LocationStrategy} from "@angular/common";
import {Utils} from "../../../../../view.components/shared/Utils/Utils";
import {ServiceList} from "../../../../../common/model/service-list.model";
import {FormComponent} from "../../../../../view.components/shared/form/form.component";
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard";
import {OrderLegalEntityProviderService} from '../../../../../http.services/retail/insurance/order-legal-entity-provider/order-legal-entity-provider.service';
import {OrderInformationProviderService} from '../../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';
import {DirectPayOnlineCardPaymentVerifierService} from '../../../../../http.services/retail/payment/dpo/direct-pay-online-card-payment-verifier/direct-pay-online-card-payment-verifier.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../../environments/environment';
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
  selector: 'app-verify-order',
  templateUrl: './direct-pay-online-card-payment-verifier-page.component.html',
  styleUrls: ['./direct-pay-online-card-payment-verifier-page.component.scss']
})
export class DirectPayOnlineCardPaymentVerifierPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;

    notEqual = false;

    orderStatus = OrderStatus;
    order: Order;
    contactNumber: ContactNumber;

    @ViewChild(OrderQueryComponent) orderQueryComponent: OrderQueryComponent;
    @ViewChild(DocumentRenderComponent) invoiceComponent: DocumentRenderComponent;

    legalEntity: LegalEntityIdentifier;
    payment: Payment;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private orderLegalEntityProviderService: OrderLegalEntityProviderService,
                private entityService: LegalEntityFinalisationManagerService,
                private router: Router,
                private toastr: ToastrService,
                private unMarshallerService: UnMarshallerService,
                private route: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private orderInformationProviderService: OrderInformationProviderService,
                private directPayOnlineCardPaymentVerifierService: DirectPayOnlineCardPaymentVerifierService,
                locationStrategy: LocationStrategy,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Verify Order - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ORDER.TITLE_ORDER_QUERY").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });
            this.translate.get("ORDER.TITLE_INVOICE").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-file", borderColor: "#4069b9"});
            });

        });

        this.route.params.forEach((param: Params) => {

            if (param["number"]) {
                setTimeout(() => {
                    this.orderQueryComponent.query(param["number"]);
                }, 200);
            }

        });

    }

    onOrder(order): void {

        this.order = order;
        if (this.order && this.order.status !== OrderStatus.CANCELLED) {

            const verifyCardPaymentRequest = new VerifyCardPaymentRequest();
            verifyCardPaymentRequest.paymentReference = new OrderReferenceNumber(this.order.number);

            this.startLoad();
            this.directPayOnlineCardPaymentVerifierService.verifyCardPayment(verifyCardPaymentRequest).subscribe((httpResponse) => {

                const response: VerifyCardPaymentResponse = this.unMarshallerService.unMarshallFromJson(httpResponse.body, VerifyCardPaymentResponse);
                if (response.paymentAdvice && response.paymentAdvice.paymentSuccessful) {
                    this.refreshOrder(this.order.number);
                } else {
                    this.contactNumber = this.entityService.legalEntity.contactNumbers[0];
                    this.getLegalEntity();
                    this.currentStep = 1;
                }

            }, (error) => {
                this.stopLoad();
                this.refreshOrder(this.order.number);
            });
        }

    }

    getLegalEntity(): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(this.order.number));

        const provideOrderLegalEntityRequest = new ProvideOrderLegalEntityRequest();
        provideOrderLegalEntityRequest.criteria = orderReferenceNumberCriteria;

        this.orderLegalEntityProviderService.provideOrderLegalEntity(provideOrderLegalEntityRequest).subscribe((response) => {
            this.legalEntity = Utils.getNationalIdentityNumber(response.body.legalEntity.legalEntityIdentifiers);
        });

    }

    onOrderExists(exists): void {
        // if (exists) {
        // }
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    onNext($event): void {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        }
    }

    onSubmit(event): void {
        const step = this.currentStep;
        switch (step) {
            case 0:
                this.orderQueryComponent.submit();
                break;
            case 1:
                this.currentStep = 0;
                this.router.navigate(['/agent/payment']);
                break;
        }
    }

    refreshOrder(reference: string): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(reference));

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderReferenceNumberCriteria;

        this.startLoad();
        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {

            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            if (response.orders[0].orderItems.length > 0) {

                this.stopLoad();
                this.order = response.orders[0];

                this.contactNumber = this.entityService.legalEntity.contactNumbers[0];
                this.getLegalEntity();
                this.currentStep = 1;

            }

        },(error) => {
            this.stopLoad();
        })

    }

    serviceReceiveCashPayments(): boolean {
        return !!this.authenticationService.isAuthorised([ServiceList.RECEIVECASHIERPAYMENT]);
    }

    getCurrencyCode(): string {
        for (const orderItem of this.order.orderItems) {
            return orderItem.price.currency;
        }
        return null;
    }

}
