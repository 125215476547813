import { Criteria } from "../../../criteria/models/criteria.model";

export class SubscriptionAllAccessCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionAllAccessCriteria";
    }

    public allAccessRequest: boolean;

}