import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from 'rxjs';
import {StatusService} from '../status/status.service';

@Injectable({providedIn: 'root'})
export class MaintenanceGuard implements CanActivate {

    constructor(private statusService: StatusService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise<boolean>(resolve => {
            if (!this.statusService.activeMaintenanceList) {
                const listener = this.statusService.subscribeActiveMaintenances().subscribe((scheduledMaintenances) => {
                    listener.unsubscribe();
                    if (scheduledMaintenances.length > 0) {
                        this.router.navigate(['maintenance']);
                    }
                    resolve(scheduledMaintenances.length === 0);
                });
                this.statusService.notifyActive();
            } else {
                if (this.statusService.activeMaintenanceList.length > 0) {
                    this.router.navigate(['maintenance']);
                }
                resolve(this.statusService.activeMaintenanceList.length === 0);
            }
        });
    }
}
