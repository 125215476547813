import { Criteria } from "../../../criteria/models/criteria.model";

export class CityTownIdentifierTypeCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.location.identification.CityTownIdentifierTypeCriteria";
    }

    public identifierType: String;
}