import { UnderwriterIdentifier } from '../../insurance/identification/models/underwriter-identifier.model';
import { CheckoutDatedItemGrouping } from './checkout-dated-item-grouping.model';
import { CheckoutNonDatedItemGrouping } from './checkout-non-dated-item-grouping.model';

export class UnderwriterCheckoutItemGrouping {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.UnderwriterCheckoutItemGrouping";
    } 

    public underwriterIdentifier: UnderwriterIdentifier;
    public checkoutDatedItemGroupings: CheckoutDatedItemGrouping[] = [];
    public checkoutNonDatedItemGroupings: CheckoutNonDatedItemGrouping[] = [];
}
