import {Pipe, PipeTransform} from '@angular/core';
import {
    CountryTaxesCountryCriteria,
    ProvideCountryTaxesInformationRequest,
    Product,
    CountryIdentifier
} from '@magnabc/tpi';
import { CountryTaxesInformationProviderService } from '../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service';

@Pipe({name: 'formatPrice', pure: false})
export class FormatPricePipe implements PipeTransform {
    private static callProvideCountryTaxesInformation = true;

    constructor(private countryTaxesInformationProviderService: CountryTaxesInformationProviderService) {}

    transform(productPriceAmount: number, coveredCountries: CountryIdentifier[]): number {
        return FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService, productPriceAmount, coveredCountries)
    }

    static provideCountryTaxesInformation(countryTaxesInformationProviderService: CountryTaxesInformationProviderService, productPriceAmount: number, coveredCountries: CountryIdentifier[]) {

        var calculatedVal = productPriceAmount;

        if(countryTaxesInformationProviderService.countryVat === undefined){
            var countryIdentifier = coveredCountries[0];
            var countryTaxesCountryCriteria = new CountryTaxesCountryCriteria();
            var provideCountryTaxesInformationRequest = new ProvideCountryTaxesInformationRequest();

            countryTaxesCountryCriteria.countryIdentifier.push(countryIdentifier);
            provideCountryTaxesInformationRequest.criteria = countryTaxesCountryCriteria;

            if(this.callProvideCountryTaxesInformation){
                this.callProvideCountryTaxesInformation = false;
                countryTaxesInformationProviderService.provideCountryTaxesInformation(provideCountryTaxesInformationRequest).then(countryVat =>{
                    if(countryVat){
                        calculatedVal = (productPriceAmount + (productPriceAmount * (countryVat/100)));
                    }
                    this.callProvideCountryTaxesInformation = false;
                });
            }
        }
        else if(countryTaxesInformationProviderService.countryVat !== undefined){
            calculatedVal = (productPriceAmount);
        }

        return calculatedVal;

    }

}
