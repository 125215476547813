import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { PricePerVehicle } from '../../../../../models/price-per-vehicle.model';

export class CalculateRealTimeExtensionPriceResponse extends Response{

    constructor(){
        super();
    }

    public pricePerVehicle: PricePerVehicle;
}