import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {Dropdown} from "../../../../../common/model/dropdown.model"
import * as moment from 'moment'
import {autoCompleteValidator} from "../../../../../common/utils/utils.validation"
import {FormComponent} from "../../../../shared/form/form.component"
import {Card} from '@magnabc/tpi'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-direct-pay-online',
    templateUrl: './direct-pay-online.component.html',
    styleUrls: ['./direct-pay-online.component.scss']
})
export class DirectPayOnlineComponent extends FormComponent implements OnInit {

    @Input() buttonLabel = "";
    @Output() onCard = new EventEmitter<Card>();
    @Output() onRemember = new EventEmitter<boolean>();

    card: Card;

    months = [
        new Dropdown(1, 'January'),
        new Dropdown(2, 'February'),
        new Dropdown(3, 'March'),
        new Dropdown(4, 'April'),
        new Dropdown(5, 'May'),
        new Dropdown(6, 'June'),
        new Dropdown(7, 'July'),
        new Dropdown(8, 'August'),
        new Dropdown(9, 'September'),
        new Dropdown(10, 'October'),
        new Dropdown(11, 'November'),
        new Dropdown(12, 'December'),
    ];

    years = [];

    formGroup: UntypedFormGroup;
    cardNumber: UntypedFormControl;
    nameOnCard: UntypedFormControl;
    expiryMonth: UntypedFormControl;
    expiryYear: UntypedFormControl;
    cvv: UntypedFormControl;
    remember: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
    }

    ngOnInit() {

        this.card = new Card();

        const currentYear = moment().get('year');

        for (let i = currentYear; i < (currentYear + 5); i++) {

            const str = i.toString();
            const yearOfEra = str.substr(str.length - 2, str.length);

            // noinspection TsLint
            this.years.push(new Dropdown(parseInt(yearOfEra), str));

        }

        this.cardNumber = new UntypedFormControl('', [
            Validators.required
        ]);
        this.nameOnCard = new UntypedFormControl('', [
            Validators.required, Validators.maxLength(30),
            Validators.pattern("^[a-zA-Z\\s]+$")
        ]);
        this.expiryMonth = new UntypedFormControl(this.months[0], [
            Validators.required,
            autoCompleteValidator(this.months)
        ]);
        this.expiryYear = new UntypedFormControl(this.years[0], [
            Validators.required,
            autoCompleteValidator(this.years)
        ]);
        this.cvv = new UntypedFormControl('', [
            Validators.required
        ]);
        this.remember = new UntypedFormControl(false, []);

        this.formGroup = new UntypedFormGroup({
            'cardNumber': this.cardNumber,
            'nameOnCard': this.nameOnCard,
            'expiryMonth': this.expiryMonth,
            'expiryYear': this.expiryYear,
            'cvv': this.cvv,
            'remember': this.remember
        });

        if (this.runtimeConfigurationService.releaseStage !== 'Production') {
            this.cardNumber.setValue('5436886269848367');
            this.nameOnCard.setValue('Test Card');
            this.cvv.setValue('123');
            this.expiryMonth.setValue(this.months[11]);
            this.expiryYear.setValue(this.getDropdown(this.years, '2024'));
        }

    }

    submit(): void {
        document.getElementById('form-submit-credit-card').click();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {

            this.card.cardNumber = this.cardNumber.value.replace(/ /g, '');
            this.card.cardHolderName = this.nameOnCard.value;
            this.card.expiryMonth = this.expiryMonth.value.value;
            this.card.expiryYear = this.expiryYear.value.value;
            this.card.cardVerificationValue = this.cvv.value.replace(/ /g, '');

            this.onCard.emit(this.card);
            this.onRemember.emit(this.remember.value);

        }

    }

}
