import {
    JudicialPerson,
    LegalEntity,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    NationalIdentityNumber,
    NaturalPerson,
    NaturalPersonIdentifier,
    PassportNumber,
    PlateNumber,
    RegistrationNumber, RetailVehicle,
    TpiIdentifier,
    TpiVehicleIdentifier,
    TrafficRegisterNumber,
    DriverTrafficRegisterNumber,
    UnderwriterIdentifier,
    UnderwriterLegalEntityIdentifier,
    Vehicle,
    VehicleIdentifier
} from "@magnabc/tpi";

export class Utils {

    static getLegalEntityIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]): LegalEntityIdentifier {

        let legalEntityIdentifierToReturn: LegalEntityIdentifier = null;
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TpiIdentifier){
                legalEntityIdentifierToReturn = legalEntityIdentifier;
            } else if (legalEntityIdentifier instanceof NationalIdentityNumber){
                legalEntityIdentifierToReturn = legalEntityIdentifier;
            } else if(legalEntityIdentifier instanceof PassportNumber){
                legalEntityIdentifierToReturn = legalEntityIdentifier;
            }
        }

        if (!legalEntityIdentifierToReturn) {
            for (const legalEntityIdentifier of legalEntityIdentifiers) {
                if (legalEntityIdentifier["@class"] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier'){
                    return legalEntityIdentifier;
                } else if (legalEntityIdentifier["@class"] === 'za.co.magnabc.entity.identification.NationalIdentityNumber'){
                    legalEntityIdentifierToReturn = legalEntityIdentifier;
                }else if (legalEntityIdentifier["@class"] === 'za.co.magnabc.entity.identification.PassportNumber'){
                    legalEntityIdentifierToReturn = legalEntityIdentifier;
                }
            }
        }
        return legalEntityIdentifierToReturn;
    }

    static getTpiIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]): TpiIdentifier {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TpiIdentifier){
                return legalEntityIdentifier;
            }
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {
                return (legalEntityIdentifier as TpiIdentifier);
            }
        }
    }

    static getTpiVehicleIdentifier(vehicleIdentifiers: VehicleIdentifier[]): TpiVehicleIdentifier {
        for (const vehicleIdentifier of vehicleIdentifiers) {
            if (vehicleIdentifier instanceof TpiVehicleIdentifier){
                return vehicleIdentifier;
            }
            if (vehicleIdentifier['@class'] === 'za.co.magnabc.tpi.vehicle.identification.TpiVehicleIdentifier') {
                return (vehicleIdentifier as TpiVehicleIdentifier);
            }
        }
    }

    static getVehiclePlateNumber(vehicle: Vehicle | RetailVehicle): string {
        if (vehicle && vehicle.vehicleIdentifiers) {
            for (const identifier of vehicle.vehicleIdentifiers) {
                if (identifier instanceof PlateNumber || identifier['@class'] === new PlateNumber()['@class']) {
                    return (identifier as PlateNumber).number;
                }
            }
        }
        return '';
    }

    static getRegistrationNumber(legalEntityIdentifiers: LegalEntityIdentifier[]): RegistrationNumber {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof RegistrationNumber || legalEntityIdentifier['@class'] === new RegistrationNumber('', '')['@class']) {
                return legalEntityIdentifier as RegistrationNumber;
            }
        }

        return null;
    }

    static getNationalIdentityNumber(legalEntityIdentifiers: LegalEntityIdentifier[]): NationalIdentityNumber {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof NationalIdentityNumber
                || legalEntityIdentifier["@class"] === "za.co.magnabc.tpi.entity.identification.NationalIdentityNumber")
            {
                return (legalEntityIdentifier as NationalIdentityNumber);
            }
        }

        return null;
    }

    static getPassportNumber(legalEntityIdentifiers: LegalEntityIdentifier[]): PassportNumber {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof PassportNumber || legalEntityIdentifier["@class"] === new PassportNumber()['@class'])
            {
                return (legalEntityIdentifier as PassportNumber);
            }
        }

        return null;
    }

    static getNationalPersonIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]): NaturalPersonIdentifier {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof NationalIdentityNumber
                || legalEntityIdentifier["@class"] === "za.co.magnabc.tpi.entity.identification.NationalIdentityNumber")
            {
                return (legalEntityIdentifier as NationalIdentityNumber);
            }else if(legalEntityIdentifier instanceof PassportNumber || legalEntityIdentifier["@class"] === new PassportNumber()['@class']){
                return (legalEntityIdentifier as PassportNumber);
            }
        }

        return null;
    }

    static getNationalIdentityNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof NationalIdentityNumber || legalEntityIdentifier["@class"] === new NationalIdentityNumber()['@class']) {
                return (legalEntityIdentifier as NationalIdentityNumber).number;
            }
        }

        return null;
    }

    static getPassportNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof PassportNumber || legalEntityIdentifier["@class"] === new PassportNumber()['@class']) {
                return (legalEntityIdentifier as PassportNumber).number;
            }
        }

        return null;
    }

    static getTrafficRegisterNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TrafficRegisterNumber || legalEntityIdentifier["@class"] === new TrafficRegisterNumber()['@class']) {
                return (legalEntityIdentifier as TrafficRegisterNumber).number;
            }
            if (legalEntityIdentifier instanceof DriverTrafficRegisterNumber|| legalEntityIdentifier["@class"] === new DriverTrafficRegisterNumber()['@class']) {
                return (legalEntityIdentifier as DriverTrafficRegisterNumber).number;
            }
        }

        return null;
    }

    static getRegistrationNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof RegistrationNumber || legalEntityIdentifier["@class"] === new RegistrationNumber('', '')['@class']) {
                return (legalEntityIdentifier as RegistrationNumber).number;
            }
        }

        return null;
    }

    static getLegalEntityDescriptionIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]): LegalEntityDescriptionIdentifier {
        for (const identifier of legalEntityIdentifiers) {
            if (identifier instanceof LegalEntityDescriptionIdentifier) {
                return identifier;
            }
        }

        for (const identifier of legalEntityIdentifiers) {
            if (identifier["@class"] === "za.co.magnabc.tpi.entity.identification.LegalEntityDescriptionIdentifier"){
                return (identifier as LegalEntityDescriptionIdentifier);
            }
        }

        return null;
    }

    static getString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {

        if (!legalEntityIdentifiers || legalEntityIdentifiers.length < 1){
            return null;
        }

        let response = '';
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TpiIdentifier) {
                response = (legalEntityIdentifier as TpiIdentifier).tpiIdentifier;
                break;
            } else if (legalEntityIdentifier instanceof NationalIdentityNumber) {
                response = (legalEntityIdentifier as NationalIdentityNumber).number;
                break;
            }

            /* if (legalEntityIdentifier['@class'] !== 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {
                if (legalEntityIdentifier.type) {
                    return legalEntityIdentifier.type + legalEntityIdentifier.number;
                } else if (legalEntityIdentifier.countryOfIssue) {
                    return legalEntityIdentifier.countryOfIssue + legalEntityIdentifier.number;
                }
            }*/
        }
        return response;
    }

    static isNaturalPersonByIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]): boolean {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof NationalIdentityNumber){
                return true;
            }
        }
        return false;
    }

    static isNaturalPerson(legalEntity: LegalEntity): boolean {
        return (legalEntity instanceof NaturalPerson);
    }

    static isJudicialPerson(legalEntity: LegalEntity): boolean {
        return (legalEntity instanceof JudicialPerson);
    }

    static equalUnderwriters(first: UnderwriterIdentifier, second: UnderwriterIdentifier): boolean {
        return (first as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier ===
            (second as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier
    }

}
