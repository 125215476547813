import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../shared/form/form.component'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ToastrService} from 'ngx-toastr'
import {Router} from '@angular/router'
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service'
import {
  QuickTransactManagerService
} from '../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {MatDialog} from '@angular/material/dialog'
import {TokenManagerService} from '../../../app.services/managers/token-manager/token-manager.service'
import {SessionTrackingManagerService} from '../../../http.services/security/session-tracking/session-tracking.service'
import {Utils} from '../../shared/Utils/Utils'
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import * as moment from 'moment/moment'
import {SessionTrackingModalComponent} from '../../shared/session-tracking-modal/session-tracking-modal.component'
import {
  Broker,
  CloseSessionTrackingRequest,
  CloseSessionTrackingResponse,
  CreateSessionTrackingRequest,
  CreateSessionTrackingResponse,
  ProvideSessionTrackingRequest,
  ProvideSessionTrackingResponse,
  SessionTracking,
  UnMarshallerService
} from '@magnabc/tpi'
import {v4 as uuidv4} from 'uuid'

declare const jQuery: any;

@Component({
  selector: 'app-public-authenticator',
  templateUrl: './public-authenticator.component.html',
  styleUrls: ['./public-authenticator.component.scss']
})
export class PublicAuthenticatorComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

  formGroup: UntypedFormGroup;
  identificationNumber: UntypedFormControl;
  password: UntypedFormControl;

  @ViewChild('form', { static: true }) form: FormGroupDirective;

  broker: Broker;
  authentic = true;
  errorMessage: string;
  sessionTracking = new SessionTracking;
  sessionTrackings: SessionTracking[] = [];

  constructor(private translate: TranslateService,
              translateParser: TranslateParser,
              private toastr: ToastrService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private quickTransactService: QuickTransactManagerService,
              private dialog: MatDialog,
              private tokenManagerService: TokenManagerService,
              private sessionTrackingManagerService: SessionTrackingManagerService,
              private unMarshallerService: UnMarshallerService) {
    super(translate, translateParser);

    this.identificationNumber = new UntypedFormControl('', [
      Validators.required
    ]);
    this.password = new UntypedFormControl('', [
      Validators.required
    ]);

    this.formGroup = new UntypedFormGroup({
      identificationNumber: this.identificationNumber,
      password: this.password
    });
  }

  ngOnInit() {

    if (this.quickTransactService.loadQuickTransact && this.quickTransactService.npIdentifier) {
      this.identificationNumber.setValue(Utils.getNationalIdentityNumber([this.quickTransactService.npIdentifier]).number);
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngOnDestroy(): void {
    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
  }

  onCheckValidation(event): void {

    this.authentic = true;
    this.errorMessage = null;
    if (this.form.valid) {
      this.startLoad();
      this.authenticationService.publicAuthentication(this.identificationNumber.value.toLocaleUpperCase(), this.password.value).then((response) => {
        if (response && response.authentic === true){
          this.authentic = true;
          this.processSession();
        } else {
          this.authentic = false;
          this.stopLoad();
        }
      }).catch(error => {
        console.error('Error while authenticating :: ', error);
        RaygunErrorHandler.sendError(error);

        this.errorMessage = error;
        this.stopLoad();
      });
    }
  }

  public processSession() {

    // Create a new SessionTracking
    let currentTokenExpDate = this.tokenManagerService.getTokens().expirationDate;
    this.sessionTracking.accountNumber = this.tokenManagerService.getAccountNumber();
    this.sessionTracking.sessionTrackingId = uuidv4();
    let addExpDate = new Date(currentTokenExpDate.getTime() + 15000);
    this.sessionTracking.endDate = moment(addExpDate).format('YYYY-MM-DDTHH:mm:ss') as any;

    sessionStorage.setItem('sessiontrackingid', this.sessionTracking.sessionTrackingId);
    sessionStorage.setItem('sessiontrackingenddate', this.sessionTracking.endDate.toString());

    const provideSessionTrackingRequest = new ProvideSessionTrackingRequest();
    provideSessionTrackingRequest.accountNumber = this.tokenManagerService.getAccountNumber();
    this.sessionTrackingManagerService.provideSessionTracking(provideSessionTrackingRequest).then(sessionTrackingHttpResponse => {
      if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
        const provideSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, ProvideSessionTrackingResponse) as ProvideSessionTrackingResponse);
        if(provideSessionTrackingResponse.sessionTrackings.length > 0)
        {
          this.sessionTrackings = provideSessionTrackingResponse.sessionTrackings;
          this.stopLoad();
          let dialogRef = this.dialog.open(SessionTrackingModalComponent, {
            width: '400px',
            panelClass: 'padded-modal',
            disableClose: true,
            data:  {message: "Another session is open, do you want to continue?", displayButton: true}
          });
          dialogRef.afterOpened().subscribe(_ => {
            sessionStorage.setItem("sessionyesnopopupbutton", "SKIP");
          });
          dialogRef.beforeClosed().subscribe(beforeCloseResult => {
            sessionStorage.setItem("sessionyesnopopupbutton", null);
            if(beforeCloseResult === 'Y'){
              sessionStorage.setItem("sessionyesnopopupbutton", "Y");
            }
            if(beforeCloseResult === 'N'){
              sessionStorage.setItem("sessionyesnopopupbutton", "N");
            }
          });
          dialogRef.afterClosed().subscribe( afterClosedResult => {
            if(afterClosedResult === 'Y'){
              this.nextRoute();
            }
            if(afterClosedResult === 'N') {
              this.authenticationService.logout();
              this.tokenManagerService.logoutSessionTracking();
            }
            sessionStorage.setItem("sessionyesnopopupbutton", null);
          });
        } else {
          this.nextRoute();
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  public nextRoute () {
    this.startLoad();

    if(this.sessionTrackings.length>0){
      // Remove all duplicates
      this.sessionTrackings.forEach(session => {
        const closeSessionTrackingRequest = new CloseSessionTrackingRequest();
        closeSessionTrackingRequest.accountNumber = session.accountNumber;

        this.sessionTrackingManagerService.closeSessionTracking(closeSessionTrackingRequest).then(sessionTrackingHttpResponse => {
          if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
            const closeSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CloseSessionTrackingResponse) as CloseSessionTrackingResponse);
          }

          this.createSession();
        });
      });
    } else {
      this.createSession();
    }

    if (this.quickTransactService.loadQuickTransact) {
      if (!this.quickTransactService.npIdentifier) {
        this.quickTransactService.npIdentifier = Utils.getNationalIdentityNumber(this.authenticationService.getLoggedInNaturalPerson().legalEntityIdentifiers);
      }
      this.quickTransactService.legalEntity = this.authenticationService.getLoggedInNaturalPerson();
      this.quickTransactService.exists = true;
      this.quickTransactService.currentStep = 1;
      this.router.navigate(['/quickstart/user']).then(() => {
        this.stopLoad();
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.router.navigate(['/retail/profile']).then(() => {
        this.stopLoad();
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  private createSession() {
    const createSessionTrackingRequest = new CreateSessionTrackingRequest();
    createSessionTrackingRequest.sessionTracking = this.sessionTracking;
    this.sessionTrackingManagerService.createSessionTracking(createSessionTrackingRequest).then(sessionTrackingHttpResponse => {
      if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
        const createSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CreateSessionTrackingResponse) as CreateSessionTrackingResponse);
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
