import { Metadata } from "../../metadata/models/metadata.model";

export class Image
{
  constructor(){
    this['@class'] = "za.co.magnabc.tpi.media.Image";
  }
  public imageData: Int8Array[] = [];
  public metadata: Metadata[] = [];

}
