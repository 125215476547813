import { Money } from "../../../financial/models/money.model";
import { OrderStatus } from "./order-status.model";

export abstract class OrderItem{
    constructor(){} 

    public number: string;
    public status: OrderStatus;
    public statusDate: Date;
    public price: Money;
}