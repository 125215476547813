
import { Criteria } from "../../../criteria/models/criteria.model";
import { OrderIdentifier } from "../identification/models/order-identifier.model";

export class LegalEntityOrderCriteria extends Criteria {

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.LegalEntityOrderCriteria";
    } 

    public orderIdentifier: OrderIdentifier;
}