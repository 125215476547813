import {FormGroup, UntypedFormGroup} from '@angular/forms'
import {BehaviorSubject} from 'rxjs'
import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core'
import {
    AddToCartRequest,
    AddToCartResponse,
    AddYCToCartRequest,
    AddYCToCartResponse,
    ComprehensiveInsuranceProduct,
    CountryIdentifier,
    CreateIdentifierRequest,
    CreateIdentifierResponse,
    DurationType,
    FinaliseCheckoutOrderRequest,
    FinaliseCheckoutOrderResponse,
    FinaliseYCCheckoutOrderRequest,
    IdentifierType,
    InsuranceCategory,
    Item,
    MotorInsuranceDetails,
    Product,
    RetailProfileOwnerIdentifier,
    RetailVehicle,
    ThirdPartyInsuranceProduct,
    UnMarshallerService,
    Vehicle,
    YCInsurancePolicyCartItem,
    YCInsuranceProduct,
    YCMotorInsuranceDetails
} from '@magnabc/tpi'
import {FormComponent} from '../../../../shared/form/form.component'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    RetailProfileFinalisationManagerService
} from '../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {ActiveToast} from 'ngx-toastr'
import {
    UnderwriterInformationProviderService
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
    BrokerInformationProviderService
} from '../../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {
    ServiceGroupFinalisationManagerService
} from '../../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import {StringUtils} from '../../../../../common/utils/utils.string'
import {ToastComponent} from '../../../../shared/toast/toast.component'
import {environment} from '../../../../../../environments/environment'
import {
    QuickTransactManagerService
} from '../../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
    IdentifierManagerService
} from '../../../../../http.services/identification/identifier-manager/identifier-manager.service'
import {
    InsuranceRetailManagerService
} from '../../../../../http.services/retail/insurance/insurance-retail-manager/insurance-retail-manager.service'
import {ProductActionEnum} from './product-action.enum'
import {
    CheckoutOrderFinalisationManagerService
} from '../../../../../http.services/retail/checkout/checkout-order-finalisation-manager/checkout-order-finalisation-manager.service'
import {
    CaptureMotorInsuranceDetailsComponent,
    CaptureMotorInsuranceDetailsComponentResult
} from '../../quick-transact/insurance-quick-transact-user/capture-motor-insurance-details/capture-motor-insurance-details.component'
import {InsuranceProductService} from '../../../../../app.services/managers/insurance-product/insurance-product.service'
import {
    ProductInformationPriceScheduleComponent
} from '../product-information-price-schedule/product-information-price-schedule.component'
import {v4 as uuidv4} from 'uuid'
import {Router} from '@angular/router'
import { Utils } from '../../../../../view.components/shared/Utils/Utils'

declare const jQuery: any

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent extends FormComponent implements OnInit, AfterViewInit {

    @Input() product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct;
    @Input() vehicles: RetailVehicle[]
    @Input() action = ProductActionEnum.ADD_TO_CART
    @Input() item: Item
    @Input() canRemoveItem = false;

    @Output() onClose = new EventEmitter<boolean>()
    @Output() onRemove = new EventEmitter<Product>();

    $motoInsuranceDetailsFormGroup: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject(null);

    insuranceCategory = InsuranceCategory
    productActionEnum = ProductActionEnum
    durationType = DurationType

    activeToast: ActiveToast<any>

    invalidVehicleSelect = false
    captureImages = false;
    captureVehicleRegistration = false;
    scrollPosition = 0

    showBreakdown = false
    submitting = false

    ycPricingSelected = false;
    allowItemRemoval = this.canRemoveItem;

    @HostListener('window:resize', ['$event'])
    onResize(_) {
        this.insuranceProductService.updateVehicleSelectDisplay()
    }

    @ViewChild(CaptureMotorInsuranceDetailsComponent) captureMotorInsuranceDetailsComponent: CaptureMotorInsuranceDetailsComponent

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                public insuranceProductService: InsuranceProductService,
                public RetailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private toastr: ErrorToastService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private ServiceGroupManagerService: ServiceGroupFinalisationManagerService,
                private errorToastService: ErrorToastService,
                private insuranceRetailManagerService: InsuranceRetailManagerService,
                private quickStartService: QuickTransactManagerService,
                private checkoutOrderFinalisationManagerService: CheckoutOrderFinalisationManagerService,
                private identifierManagerService: IdentifierManagerService,
                private router: Router) {
        super(translate, translateParser)
    }

    ngOnInit() {
        this.insuranceProductService.initialiseProduct(this.product, this.vehicles, this.action, this.item)
    }

    ngAfterViewInit(): void {
        this.insuranceProductService.updateVehicleSelectDisplay()
        setTimeout(() => {
            if(this.isYCProduct){
                this.calculateYCPricing();
            }else{
                this.insuranceProductService.calculatePrice();
            }
        })
        if (this.insuranceProductService.selectedYCPriceSchedules.length > 0) {
            this.onYCPricingSelected(true)
        }
    }

    toggleBreakDown(show: boolean) {
        this.showBreakdown = show
        return false
    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {
        return this.insuranceProductService.getTpiVehicleIdentifier(vehicleIdentifiers)
    }

    getVehiclePlateNumber(vehicle: Vehicle | RetailVehicle): string {
        return this.insuranceProductService.getVehiclePlateNumber(vehicle)
    }

    uploadImages(tpiIdentifier: string): boolean {
        this.scrollPosition = jQuery('.tpi-product-container').scrollTop()
        this.captureImages = true
        return false
    }

    uploadDocuments(tpiIdentifier: string): boolean {
        this.scrollPosition = jQuery('.tpi-product-container').scrollTop()
        this.captureVehicleRegistration = true
        return false
    }

    scrollToVehicleSelect() {
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            const scrollTo = jQuery('#vehicle-select')
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top - 50 + container.scrollTop()
            })
        })
    }

    formatCurrency(value): string {
        return StringUtils.formatCents(value)
    }

    onSubmit() {

        if (this.insuranceProductService.insuranceCategory === InsuranceCategory.COMPREHENSIVE) {
            if (this.captureMotorInsuranceDetailsComponent) {
                this.captureMotorInsuranceDetailsComponent.onSubmit()
            }
        } else {
            this.submit()
        }

    }

    removeItem():void{
        this.onRemove.emit(this.product);
    }

    submit(): void {

        if (!this.submitting) {
            this.submitting = true
            this.invalidVehicleSelect = false
            if (this.insuranceProductService.selectedVehicles.length === 0) {
                this.invalidVehicleSelect = true
                this.scrollToVehicleSelect()
                this.submitting = false;
                return
            }
            this.insuranceProductService.invalidPriceSchedule = false
            if (!this.insuranceProductService.selectedPriceSchedule && !this.ycPricingSelected) {
                this.insuranceProductService.invalidPriceSchedule = true
                ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
                this.submitting = false;
                return
            }

            let motorInsuranceDetails: MotorInsuranceDetails | YCMotorInsuranceDetails;
            if (this.isYCProduct) {
                motorInsuranceDetails = this.insuranceProductService.getYCMotorInsuranceDetails();
            } else {
                motorInsuranceDetails = this.insuranceProductService.getMotorInsuranceDetails();
                if (this.captureMotorInsuranceDetailsComponent) {
                    motorInsuranceDetails.motorInsuranceVehicleDetails = this.captureMotorInsuranceDetailsComponent.createMotorInsuranceDetails();
                }
            }

            switch (this.insuranceProductService.action) {
                case ProductActionEnum.ADD_TO_CART:
                    this.addToCart(motorInsuranceDetails)
                    break
                case ProductActionEnum.UPDATE_ITEM:
                    this.updateItem(motorInsuranceDetails)
                    break
            }
        }

    }

    updateItem(motorInsuranceDetails: MotorInsuranceDetails | YCMotorInsuranceDetails) {

        if (motorInsuranceDetails instanceof MotorInsuranceDetails) {
            const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
            retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

            const finaliseCheckoutOrderRequest = new FinaliseCheckoutOrderRequest()
            finaliseCheckoutOrderRequest.updateItem = this.insuranceProductService.item
            finaliseCheckoutOrderRequest.retailProfileIdentifier = retailProfileOwnerIdentifier
            if (motorInsuranceDetails instanceof MotorInsuranceDetails) {
                finaliseCheckoutOrderRequest.motorInsuranceDetails = motorInsuranceDetails;
            }

            finaliseCheckoutOrderRequest.removeImages = this.insuranceProductService.removeImages
            finaliseCheckoutOrderRequest.uploadImages = []
            for (const key in this.insuranceProductService.vehicleImageFilesMap) {
                for (const vehicleImageFiles of this.insuranceProductService.vehicleImageFilesMap[key]) {
                    if (!vehicleImageFiles.isBase64) {
                        finaliseCheckoutOrderRequest.uploadImages.push(vehicleImageFiles.scannedDocument)
                    }
                }
            }
            finaliseCheckoutOrderRequest.product = this.product
            finaliseCheckoutOrderRequest.pricePerVehicle = this.insuranceProductService.pricePerVehicles[0]

            this.startLoad()
            this.checkoutOrderFinalisationManagerService.finaliseCheckoutOrder(finaliseCheckoutOrderRequest).then((data) => {

                const response = this.unMarshallerService.unMarshallFromJson(data.body, FinaliseCheckoutOrderResponse) as FinaliseCheckoutOrderResponse
                this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage

                this.stopLoad()
                this.refreshRetailProfile(response.verifiedVehicles)
                this.submitting = false

            }).catch(() => {
                this.stopLoad()
                this.submitting = false
            })
        } else if (motorInsuranceDetails instanceof YCMotorInsuranceDetails) {
            const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
            retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

            const finaliseYCCheckoutOrderRequest = new FinaliseYCCheckoutOrderRequest()
            if (this.isYCProduct) {
                if ((this.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem.ycPriceSchedules) {
                    (this.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem.ycPriceSchedules.forEach(value =>
                        value.ycPriceScheduleId = uuidv4()
                    );
                }

                if ((this.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem['ycPriceInterval']) {
                    (this.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem['ycPriceInterval'].ycPriceIntervalId = uuidv4();
                }
            }
            finaliseYCCheckoutOrderRequest.updateItem = this.insuranceProductService.item
            finaliseYCCheckoutOrderRequest.retailProfileIdentifier = retailProfileOwnerIdentifier
            finaliseYCCheckoutOrderRequest.motorInsuranceDetails = motorInsuranceDetails
            finaliseYCCheckoutOrderRequest.scannedDocuments = []

            for (const vehicleImageFiles of this.insuranceProductService.vehicleRegistrationFileMap) {
                let base64result = (vehicleImageFiles.document.document.toString()).split(',')[1]
                if (base64result) {
                    base64result = base64result.replace(/\s/g, '');
                }
                vehicleImageFiles.document.document = base64result as any;
                finaliseYCCheckoutOrderRequest.scannedDocuments.push(vehicleImageFiles.document)
            }
            finaliseYCCheckoutOrderRequest.product = this.product
            finaliseYCCheckoutOrderRequest.pricePerVehicle = this.insuranceProductService.pricePerVehicles[0]

            this.startLoad()
            this.checkoutOrderFinalisationManagerService.finaliseYCCheckoutOrder(finaliseYCCheckoutOrderRequest).then((data) => {

                const response = this.unMarshallerService.unMarshallFromJson(data.body, FinaliseCheckoutOrderResponse) as FinaliseCheckoutOrderResponse
                this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage

                this.stopLoad()
                this.refreshRetailProfile(response.verifiedVehicles)
                this.submitting = false

            }).catch(() => {
                this.stopLoad()
                this.submitting = false
            })
        }
    }

    addToCart(motorInsuranceDetails: MotorInsuranceDetails | YCMotorInsuranceDetails) {
        if (this.$motoInsuranceDetailsFormGroup.value && this.$motoInsuranceDetailsFormGroup.value.status === 'INVALID') {
            this.submitting = false;
            return;
        }

        this.insuranceProductService.unverifiedVehicleMessage = []

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
        if (this.quickStartService.retailProfile) {
            retailProfileOwnerIdentifier.tpiIdentifier = this.quickStartService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        } else {
            retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        }

        const createIdentifierRequest = new CreateIdentifierRequest()
        if (motorInsuranceDetails instanceof YCMotorInsuranceDetails) {
            motorInsuranceDetails.ycMotorVehicleInsuranceDetails.length;
            createIdentifierRequest.quantity = motorInsuranceDetails.ycMotorVehicleInsuranceDetails.length;
        } else {
            createIdentifierRequest.quantity = motorInsuranceDetails.motorInsuranceVehicleDetails.length;
        }
        createIdentifierRequest.type = IdentifierType.ORDER_ITEM_NUMBER

        this.startLoad()
        this.identifierManagerService.createIdentifier(createIdentifierRequest).then((response) => {

            const createIdentifierResponse = response.body as CreateIdentifierResponse

            if (this.isYCProduct) {
                this.addYCProductToCart(createIdentifierResponse, motorInsuranceDetails as YCMotorInsuranceDetails, retailProfileOwnerIdentifier);
            } else {
                this.addStandardProductToCart(createIdentifierResponse, motorInsuranceDetails as MotorInsuranceDetails, retailProfileOwnerIdentifier);
            }

        }).catch((error) => {
            this.errorToastService.errorToast(error)
            this.stopLoad()
            this.submitting = false
        })

    }

    private addStandardProductToCart(createIdentifierResponse, motorInsuranceDetails, retailProfileOwnerIdentifier): void {
        const addToCartRequest = new AddToCartRequest()
        addToCartRequest.identifier = createIdentifierResponse.identifier
        addToCartRequest.retailProfileIdentifier = retailProfileOwnerIdentifier
        addToCartRequest.motorInsuranceDetails = motorInsuranceDetails
        addToCartRequest.pricePerVehicles = this.insuranceProductService.pricePerVehicles
        addToCartRequest.scannedDocuments = []
        for (const key in this.insuranceProductService.vehicleImageFilesMap) {
            if (this.insuranceProductService.vehicleImageFilesMap[key]) {
                for (const vehicleImageFiles of this.insuranceProductService.vehicleImageFilesMap[key]) {
                    addToCartRequest.scannedDocuments.push(vehicleImageFiles.scannedDocument)
                }
            }
        }

        this.insuranceRetailManagerService.addToCart(addToCartRequest).then((data) => {
            this.allowItemRemoval = false;
            const response = this.unMarshallerService.unMarshallFromJson(data.body, AddToCartResponse) as AddToCartResponse
            this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage
            if (response.verifiedVehicles.length > 0) {
                this.toastAddToCart();
                this.allowItemRemoval = this.canRemoveItem ? true : this.allowItemRemoval;
            }

            this.stopLoad()
            this.refreshRetailProfile(response.verifiedVehicles)
            this.submitting = false

        }).catch((error) => {
            this.errorToastService.errorToast(error)
            this.stopLoad()
            this.submitting = false
        });
    }

    private addYCProductToCart(createIdentifierResponse, ycMotorInsuranceDetails: YCMotorInsuranceDetails, retailProfileOwnerIdentifier): void {
        let addYCToCartRequest = new AddYCToCartRequest();
        addYCToCartRequest.ycMotorInsuranceDetails = this.insuranceProductService.ycMotorInsuranceDetails;
        addYCToCartRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
        addYCToCartRequest.pricePerVehicles = this.insuranceProductService.pricePerVehicles;
        addYCToCartRequest.identifiers = createIdentifierResponse.identifier;
        addYCToCartRequest.ycMotorInsuranceDetails = this.insuranceProductService.getYCMotorInsuranceDetails();
        addYCToCartRequest.scannedDocuments = []

        for (const doc of this.insuranceProductService.vehicleRegistrationFileMap) {
            if (doc.document) {
                addYCToCartRequest.scannedDocuments.push(doc.document)
            }
        }

        this.insuranceRetailManagerService.addYCToCart(addYCToCartRequest).then((data) => {
            this.allowItemRemoval = false;
            const response = this.unMarshallerService.unMarshallFromJson(data.body, AddYCToCartResponse) as AddYCToCartResponse
            this.insuranceProductService.unverifiedVehicleMessage = response.unverifiedVehicleMessage
            if (response.verifiedVehicles.length > 0) {
                this.toastAddToCart();
                this.allowItemRemoval = this.canRemoveItem ? true : this.allowItemRemoval;
            }

            this.stopLoad()
            this.refreshRetailProfile(response.verifiedVehicles)
            this.submitting = false

        }).catch((error) => {
            this.errorToastService.errorToast(error)
            this.stopLoad()
            this.submitting = false
        });
    }

    refreshRetailProfile(verifiedVehicles) {
        this.RetailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
            this.insuranceProductService.clearVehicles(verifiedVehicles)
            if (this.insuranceProductService.unverifiedVehicleMessage.length === 0) {
                this.scrollToVehicleSelect()
            } else {
                ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
            }
            if(this.allowItemRemoval){
                this.onRemove.emit(this.product);
            }else{
                if (this.insuranceProductService.vehicles.length === 0) {
                    this.router.navigate(['/retail/checkout']);
                    this.onClose.emit(true)
                }
            }
        })
    }

    toastAddToCart() {

        this.translate.get('TOAST.ADD_TO_CART').subscribe((res) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            })
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy()
                        break
                }
            })
            this.activeToast.onHidden.subscribe(() => {

            })
        })
    }

    onCloseCaptureImages(vehicleImageFilesMap) {
        if (vehicleImageFilesMap) {
            this.insuranceProductService.vehicleImageFilesMap = vehicleImageFilesMap
        }
        this.captureImages = false
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            container.animate({
                scrollTop: this.scrollPosition
            }, 0)
        })
    }

    onCloseCaptureVehicleRegistrationDoc(data: {tpiIdentifier: string,
        documentId: string}): void {
        this.setCaputeVehicleRegistationValues(data);
        this.captureVehicleRegistration = false;
    }

    private setCaputeVehicleRegistationValues(data: {tpiIdentifier: string,
        documentId: string}): void{
        const form1 = this.$motoInsuranceDetailsFormGroup.value.controls;
        if (form1) {
            const forms: FormGroup[] = form1.motorInsuranceVehicles['controls'];
            forms.forEach(form2 => {
                const ctrlIdentifier = form2.get('tpiIdentifier');
                if(ctrlIdentifier && ctrlIdentifier.value === data.tpiIdentifier){
                    const ctrlRegDoc = form2.get('vehicleRegistrationDocumentId');
                    if(ctrlRegDoc){
                        ctrlRegDoc.setValue(data.documentId);
                    }
                }
            });
        }
    }

    updateVehicleDetails(data: CaptureMotorInsuranceDetailsComponentResult): void {
        const motorInsuranceDetails = this.insuranceProductService.getMotorInsuranceDetails()
        motorInsuranceDetails.motorInsuranceVehicleDetails.forEach((details) => {
            if (details.vehicleIdentifier === data.tpiIdentifier) {
                details.vehicleValue.amount === data.vehicleValue;
            }
        })
    }

    onYCPricingSelected(data: boolean): void {
        this.ycPricingSelected = data;
    }

    calculateYCPricing(): void {
        if (this.product && this.product instanceof YCInsuranceProduct && this.insuranceProductService.selectedVehicles.length > 0) {
            const countryNames = this.insuranceProductService.selectedCoveredCountries;
            const vehicleCategoryDescriptions = this.insuranceProductService.getVehicleCategoryDescriptions();
            const selectedPriceSchedules = this.product.ycPriceSchedules.filter((schedule) =>
                vehicleCategoryDescriptions.includes(schedule.insuranceVehicleCategorySubDescription) &&
                countryNames.includes(schedule.country) && schedule.price !== 0);
            //TODO calculation implementation
            if (selectedPriceSchedules.length > 0) {
                this.insuranceProductService.selectedYCPriceSchedules = selectedPriceSchedules;
                this.insuranceProductService.ycPriceIntervals = this.product.ycPriceIntervals;
                this.insuranceProductService.calculateRealTimeYCPrice();
                this.insuranceProductService.buildBreakdownForYcProduct();
                this.ycPricingSelected = true;
                this.insuranceProductService.updateCalendars();
            }
        }
    }

    get isYCProduct(): boolean {
        return this.product instanceof YCInsuranceProduct;
    }

    showSubmitButton(): boolean {
        const pricingNotSelected = this.isYCProduct ? !this.ycPricingSelected : !this.insuranceProductService.selectedPriceSchedule;
        return this.insuranceProductService.loadingPrice || this.insuranceProductService.vehicles.length === 0
            || pricingNotSelected;
    }

    get showCaptureMotorInsuranceDetails(): boolean {
        return this.insuranceProductService.insuranceCategory.toString() ===
            InsuranceCategory.COMPREHENSIVE.toString();
    }

    onDocumentRemoved(data): void{
        const tpiIdentifier = data.tpiIdentifier;

        this.setCaputeVehicleRegistationValues({
            documentId: null,
            tpiIdentifier
        });
    }

    getBrokerCountries(): CountryIdentifier[] {
        const brokerCountries: CountryIdentifier[] = [];
        if (this.insuranceProductService.broker) {
            brokerCountries.push(this.insuranceProductService.broker.countryIdentifier);
        }

        return brokerCountries;
    }
}
