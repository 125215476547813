import {MotorInsuranceCertificate} from "../models/motor-insurance-certificate.model";
import {InsuranceCertificateEvent} from "./insurance-certificate-event.model";

export class InsuranceCertificateCreatedEvent extends InsuranceCertificateEvent{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.certificate.events.InsuranceCertificateCreatedEvent";
    }

    public motorInsuranceCertificate: MotorInsuranceCertificate;
}
