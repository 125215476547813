import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
    AdditionalDriversAnswers,
    AdditionalDriversQuestions,
    Answers,
    ClaimsHistoryAnswers,
    ClaimsHistoryQuestions,
    InsuranceHistoryAnswers,
    InsuranceHistoryQuestions,
    ProposalForm,
    UnMarshallerService,
    VehicleInformationAnswers,
    VehicleInformationQuestions
} from "@magnabc/tpi";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ToastComponent} from '../../../shared/toast/toast.component';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {v4 as uuidv4} from 'uuid';
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-capture-proposal',
    templateUrl: './capture-proposal.component.html',
    styleUrls: ['./capture-proposal.component.scss']
})
export class CaptureProposalComponent implements OnInit {

    @Output() oncaptureProposalData = new EventEmitter<{ answers: Answers[], proposalForm: ProposalForm }>();
    @Input() proposalForm: ProposalForm;

    vehicleInformationQuestions: VehicleInformationQuestions[] = [];
    insuranceHistoryQuestions: InsuranceHistoryQuestions[] = [];
    claimsHistoryQuestions: ClaimsHistoryQuestions[] = [];
    additionalDriversQuestions: AdditionalDriversQuestions[] = [];
    additionalDriversAnswers: AdditionalDriversAnswers[] = [];
    answersList: Answers[] = [];

    myForm: UntypedFormGroup;
    step = 0;
    displayMessage: boolean;
    displayError: boolean
    yearError: boolean;

    maxDate: Date;

    constructor(
        private unMarshallerService: UnMarshallerService,
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private errorToastService: ErrorToastService,
        private translateParser: TranslateParser)
    {   this.maxDate = new Date(); }

    ngOnInit() {

        this.getProposalForm()
        this.myForm = this.fb.group({
            vehicleInfoArray: this.fb.array(this.createItems(this.vehicleInformationQuestions)),
            insuranceInfoArray: this.fb.array(this.createItems(this.insuranceHistoryQuestions)),
            claimsInfoArray: this.fb.array([]),
            addditionalInfoArray: this.fb.array([])
        });

    }

    setStep(index: number) { this.step = index; }

    validateVehicleQuestions() {

       if(this.myForm.controls.vehicleInfoArray.invalid){
            this.displayMessage = true;
       }
       else {
        this.displayMessage = false;
           this.step++ ;
        }
    }

    validateInsuranceQuestions() {
        if(this.myForm.controls.insuranceInfoArray.invalid){
            this.displayMessage = true;
        }
        else {
            this.displayMessage = false;
            this.step++ ;
        }
    }

    validateClaimQuestions() {
        if(!this.checkYear){

            this.yearError = true
        }
        if(this.myForm.controls.claimsInfoArray.invalid ){
            this.displayMessage = true;
        }else{
            this.displayMessage = false;
        }
        if(!this.myForm.controls.claimsInfoArray.invalid && this.checkYear){
            this.step++ ;
            this.yearError = false
        }
    }

    prevStep() { this.step--; }

    getProposalForm(): void {

        for(const questions of this.proposalForm.questionsList) {

            if(questions instanceof VehicleInformationQuestions) {
                this.vehicleInformationQuestions.push(questions);
            } else if (questions instanceof InsuranceHistoryQuestions) {
                this.insuranceHistoryQuestions.push(questions);
            } else if (questions instanceof ClaimsHistoryQuestions) {
                this.claimsHistoryQuestions.push(questions);
            } else if (questions instanceof AdditionalDriversQuestions) {
                this.additionalDriversQuestions.push(questions);
            }
        }
    }

    createItem(info: any): UntypedFormGroup {

        return this.fb.group({
            radioButton: '',
            input: '',
            year: '',
            identifierNumber: '',
            label: info.questionLabel,
            commentLabel: info.commentLabel,
            commentRequiredWhenAnswer: info.commentRequiredWhenAnswer,
            commentRequired: info.commentRequired,
            mandatoryQuestion: info.mandatoryQuestion,
            questionType: info.questionType,
            questionsId: info.questionsId
        });
    }

    private createItems(questionInfo: any): UntypedFormGroup[] {
        const arr = [];
        for (let i = 0; i < questionInfo.length; i++) {
            arr.push(this.createItem(questionInfo[i]));
        }
        return arr;
    }

    onChangeVehicleInfo(val, whenAnswer, index: number, commentRequired) {
        if (val === 'true' && commentRequired == true && whenAnswer == true) {
            (<UntypedFormGroup>this.vehicleInfoControl.at(index)).addControl('textArea', this.fb.control([]));

        } else if (val === 'false' && commentRequired == true && whenAnswer == false) {
            (<UntypedFormGroup>this.vehicleInfoControl.at(index)).addControl('textArea', this.fb.control([]));
        } else {
            (<UntypedFormGroup>this.vehicleInfoControl.at(index)).removeControl('textArea');
        }
    }

    onChangeInsuranceInfo(val, whenAnswer, index: number, commentRequired) {
        if (val === 'true' && commentRequired == true && whenAnswer == true) {
            (<UntypedFormGroup>this.insuranceInfoControl.at(index)).addControl('textArea', this.fb.control([]));

        } else if (val === 'false' && commentRequired == true && whenAnswer == false) {
            (<UntypedFormGroup>this.insuranceInfoControl.at(index)).addControl('textArea', this.fb.control([]));
        } else {
            (<UntypedFormGroup>this.insuranceInfoControl.at(index)).removeControl('textArea');
        }
    }

    yearInput(event, claimsInfoArray) {
        const val = event.target.value
        if(val){
            this.yearError = false
        }else{
            this.yearError = true
        }
        for (const formgroup of claimsInfoArray) {
            formgroup.controls.year.setValue(val);
        }
    }

    textFieldData(event, addditionalInfoArray) {
        if(event.target.value){
            this.displayError = false
        }else{
            this.displayError = true
        }
        for (const formgroup of addditionalInfoArray) {
            formgroup.controls.identifierNumber.setValue(event.target.value);
        }
    }

    onChangeClaimInfo(val, whenAnswer, commentRequired, claimsInfoArray) {
        if (val === 'true' && commentRequired == true && whenAnswer == true) {
            claimsInfoArray.addControl('textArea', this.fb.control([]));

        } else if (val === 'false' && commentRequired == true && whenAnswer == false) {
            claimsInfoArray.addControl('textArea', this.fb.control([]));
        } else {
            claimsInfoArray.removeControl('textArea');
        }
    }

    onChangeAdditionalnfo(val, whenAnswer, commentRequired, addditionalInfoArray) {
        if (val === 'true' && commentRequired == true && whenAnswer == true) {
            addditionalInfoArray.addControl('textArea', this.fb.control([]));;

        } else if (val === 'false' && commentRequired == true && whenAnswer == false) {
            addditionalInfoArray.addControl('textArea', this.fb.control([]));
        } else {

            addditionalInfoArray.removeControl('textArea');
        }
    }

    hasVehicleTextArea(index) {
        return (<UntypedFormGroup>this.vehicleInfoControl.at(index)).get('textArea') ? true : false;
    }

    hasInsuranceTextArea(index) {
        return (<UntypedFormGroup>this.insuranceInfoControl.at(index)).get('textArea') ? true : false;
    }

    hasClaimTextArea(claimsInfoArray) {

        if(claimsInfoArray.textArea){
            return true
        }else{
            return false
        }
    }

    hasAdditionalTextArea(addditionalInfoArray) {
        if(addditionalInfoArray.textArea){
            return true
        }else{
            return false
        }
    }

    get vehicleInfoControl() {
        const control = <UntypedFormArray>(this.myForm.get('vehicleInfoArray'));
        return control;
    }

    get insuranceInfoControl() {
        const control = <UntypedFormArray>(this.myForm.get('insuranceInfoArray'));
        return control;
    }

    get claimInfoControl() {
        const control = <UntypedFormArray>(this.myForm.get('claimsInfoArray'));
        return control;
    }

    get addditionalInfoControl() {
        const control = <UntypedFormArray>(this.myForm.get('addditionalInfoArray'));
        return control;
    }

    get fa() { return this.myForm.get('claimsInfoArray') as UntypedFormArray; }
    get fc() { return this.myForm.get('addditionalInfoArray') as UntypedFormArray; }

    addMoreClaims() {
        this.fa.push(this.fb.array(this.createItems(this.claimsHistoryQuestions)));
    }

    addMoreAdditionalInfo() {
        this.fc.push(this.fb.array(this.createItems(this.additionalDriversQuestions)));
    }

    onRemoveClaimInfo(index: number): boolean {

        this.translate.get('TOAST.REMOVE_CLAIM_HISTORY').subscribe((res: any) => {
            const activeToast = this.errorToastService.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                disableTimeOut: true,
                closeButton: true
            })
            activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        (<UntypedFormArray>this.myForm.get('claimsInfoArray')).removeAt(index);
                        activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        activeToast.portal.destroy();
                        break;
                }
            });
        });
        return false;
    }

    onRemoveAdditionalInfo(index: number): boolean {

        this.translate.get('TOAST.REMOVE_ADDITIONAL_HISTORY').subscribe((res: any) => {
            const activeToast = this.errorToastService.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                disableTimeOut: true,
                closeButton: true
            })
            activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        (<UntypedFormArray>this.myForm.get('addditionalInfoArray')).removeAt(index);
                        activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        activeToast.portal.destroy();
                        break;
                }
            });
        });
        return false;
    }

    get checkYear() {
        let year;
        if((<HTMLInputElement>document.getElementById("year"))){

            year = (<HTMLInputElement>document.getElementById("year")).value;
        }else {
            year = true;
        }
        return year
    }

    get checkIdentificationNumber() {
        let identificationNumber;
        if((<HTMLInputElement>document.getElementById("identificationNumber"))){

            identificationNumber = (<HTMLInputElement>document.getElementById("identificationNumber")).value;
        }else {
            identificationNumber = true;
        }
        return identificationNumber
    }

    submitAnswers(): boolean {

        this.answersList = [];

        if(this.myForm.valid && this.checkIdentificationNumber && this.checkYear)
        {
            this.displayMessage = false;
            if (this.myForm.value.vehicleInfoArray) {
                this.displayError = false;
                this.displayMessage = false;
                for (const vehicleInfoAnswers of this.myForm.value.vehicleInfoArray) {
                    const vehicleInformationAnswers = new VehicleInformationAnswers();
                    if (vehicleInfoAnswers.radioButton || vehicleInfoAnswers.textArea || vehicleInfoAnswers.input) {
                        if (vehicleInfoAnswers.radioButton && vehicleInfoAnswers.textArea) {
                            vehicleInformationAnswers.questionsId = vehicleInfoAnswers.questionsId;
                            vehicleInformationAnswers.yesOrNoAnswer = vehicleInfoAnswers.radioButton;
                            vehicleInformationAnswers.comment = vehicleInfoAnswers.textArea;
                            vehicleInformationAnswers.answersId = uuidv4()
                        } else if (vehicleInfoAnswers.radioButton) {
                            vehicleInformationAnswers.questionsId = vehicleInfoAnswers.questionsId;
                            vehicleInformationAnswers.yesOrNoAnswer = vehicleInfoAnswers.radioButton;
                            vehicleInformationAnswers.answersId = uuidv4();
                        } else if (vehicleInfoAnswers.input) {
                            vehicleInformationAnswers.questionsId = vehicleInfoAnswers.questionsId;
                            vehicleInformationAnswers.freeTextAnswer = vehicleInfoAnswers.input;
                            vehicleInformationAnswers.answersId = uuidv4();
                        }
                        this.answersList.push(vehicleInformationAnswers);
                    }
                }
            }

            if (this.myForm.value.insuranceInfoArray) {
                for (const insuranceInfoAnswer of this.myForm.value.insuranceInfoArray) {
                    const insuranceHistoryAnswers = new InsuranceHistoryAnswers();
                    if (insuranceInfoAnswer.radioButton || insuranceInfoAnswer.textArea || insuranceInfoAnswer.input) {
                        if (insuranceInfoAnswer.radioButton && insuranceInfoAnswer.textArea) {
                            insuranceHistoryAnswers.questionsId = insuranceInfoAnswer.questionsId;
                            insuranceHistoryAnswers.yesOrNoAnswer = insuranceInfoAnswer.radioButton;
                            insuranceHistoryAnswers.comment = insuranceInfoAnswer.textArea;
                            insuranceHistoryAnswers.answersId = uuidv4();
                        } else if (insuranceInfoAnswer.radioButton) {
                            insuranceHistoryAnswers.questionsId = insuranceInfoAnswer.questionsId;
                            insuranceHistoryAnswers.yesOrNoAnswer = insuranceInfoAnswer.radioButton;
                            insuranceHistoryAnswers.answersId = uuidv4();
                        } else if (insuranceInfoAnswer.input) {
                            insuranceHistoryAnswers.questionsId = insuranceInfoAnswer.questionsId;
                            insuranceHistoryAnswers.freeTextAnswer = insuranceInfoAnswer.input;
                            insuranceHistoryAnswers.answersId = uuidv4();
                        }
                        this.answersList.push(insuranceHistoryAnswers);
                    }
                }
            }

            if (this.myForm.value.claimsInfoArray) {
                for (const claimsInfoAnswerList of this.myForm.value.claimsInfoArray) {
                    for (const claimsInfoAnswer of claimsInfoAnswerList) {
                        const claimsHistoryAnswers = new ClaimsHistoryAnswers();
                        if (claimsInfoAnswer.radioButton || claimsInfoAnswer.textArea || claimsInfoAnswer.input) {
                            if (claimsInfoAnswer.radioButton && claimsInfoAnswer.textArea) {
                                claimsHistoryAnswers.questionsId = claimsInfoAnswer.questionsId;
                                claimsHistoryAnswers.yesOrNoAnswer = claimsInfoAnswer.radioButton;
                                claimsHistoryAnswers.comment = claimsInfoAnswer.textArea;
                                claimsHistoryAnswers.year = claimsInfoAnswer.year;
                                claimsHistoryAnswers.answersId = uuidv4();
                            } else if (claimsInfoAnswer.radioButton) {
                                claimsHistoryAnswers.questionsId = claimsInfoAnswer.questionsId;
                                claimsHistoryAnswers.yesOrNoAnswer = claimsInfoAnswer.radioButton;
                                claimsHistoryAnswers.year = claimsInfoAnswer.year;
                                claimsHistoryAnswers.answersId = uuidv4();
                            } else if (claimsInfoAnswer.input) {
                                claimsHistoryAnswers.questionsId = claimsInfoAnswer.questionsId;
                                claimsHistoryAnswers.freeTextAnswer = claimsInfoAnswer.input;
                                claimsHistoryAnswers.year = claimsInfoAnswer.year;
                                claimsHistoryAnswers.answersId = uuidv4();
                            }
                            this.answersList.push(claimsHistoryAnswers);
                        }
                    }
                }
            }

            if (this.myForm.value.addditionalInfoArray) {

                for (const addditionalAnswersList of this.myForm.value.addditionalInfoArray) {
                    for (const addditionalAnswer of addditionalAnswersList) {
                        const additionalDriversAnswers = new AdditionalDriversAnswers();
                        if (addditionalAnswer.radioButton || addditionalAnswer.textArea || addditionalAnswer.input) {
                            if (addditionalAnswer.radioButton && addditionalAnswer.textArea) {
                                additionalDriversAnswers.questionsId = addditionalAnswer.questionsId;
                                additionalDriversAnswers.yesOrNoAnswer = addditionalAnswer.radioButton;
                                additionalDriversAnswers.comment = addditionalAnswer.textArea;
                                additionalDriversAnswers.identificationNumber = addditionalAnswer.identifierNumber;
                                additionalDriversAnswers.answersId = uuidv4();
                            } else if (addditionalAnswer.radioButton) {
                                additionalDriversAnswers.questionsId = addditionalAnswer.questionsId;
                                additionalDriversAnswers.yesOrNoAnswer = addditionalAnswer.radioButton;
                                additionalDriversAnswers.identificationNumber = addditionalAnswer.identifierNumber;
                                additionalDriversAnswers.answersId = uuidv4();
                            } else if (addditionalAnswer.input) {
                                additionalDriversAnswers.questionsId = addditionalAnswer.questionsId;
                                additionalDriversAnswers.freeTextAnswer = addditionalAnswer.input;
                                additionalDriversAnswers.identificationNumber = addditionalAnswer.identifierNumber;
                                additionalDriversAnswers.answersId = uuidv4();
                            }
                            this.answersList.push(additionalDriversAnswers);
                        }
                    }
                }
            }
            this.oncaptureProposalData.emit({ answers: this.answersList, proposalForm: this.proposalForm });
            return true;
        }else {
            this.displayMessage = true;
            if(this.checkIdentificationNumber){
                this.displayError = false;
            }else {
                this.displayError = true;
            }
            if(this.checkYear){
                this.yearError = false;
            }else {
                this.yearError = true;
            }
            return false;

        }

    }
}
