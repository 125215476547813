import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {FormComponent} from "../../../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ToastComponent} from "../../../shared/toast/toast.component"
import {ActiveToast} from "ngx-toastr"
import {Router} from "@angular/router"
import {
  Address,
  ComprehensiveInsurancePolicyCartItem,
  CountryCheckoutItemGrouping,
  CountryDescriptionIdentifier,
  CountryIdentifier,
  ExtensionInsurancePolicyCartItem,
  FinaliseCheckoutOrderRequest,
  Item,
  ItemNumberIdentifier,
  JudicialPerson,
  LegalEntity,
  NaturalPerson,
  Product,
  ProductCriteria,
  ProductIdentifier,
  ProvideInsuranceProductInformationRequest,
  ProvideInsuranceProductInformationResponse,
  RetailProfileOwnerIdentifier,
  RetailVehicle,
  ThirdPartyInsurancePolicyCartItem,
  Underwriter,
  UnderwriterCheckoutItemGrouping,
  UnderwriterLegalEntityIdentifier,
  UnMarshallerService,
  Vehicle,
  YCInsurancePolicyCartItem,
  YCPriceSchedule
} from "@magnabc/tpi"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {Utils} from '../../../shared/Utils/Utils'
import {
  InsurancePolicyManagerExtensionDialogComponent
} from '../../insurance/insurance-policy-manager-extension-dialog/insurance-policy-manager-extension-dialog.component'
import {
  UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
  CheckoutOrderFinalisationManagerService
} from '../../../../http.services/retail/checkout/checkout-order-finalisation-manager/checkout-order-finalisation-manager.service'
import {
  RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
  InsuranceProductInformationProviderService
} from '../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service'
import {
  CountryTaxesInformationProviderService
} from '../../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service'
import {
  CheckoutManagerService
} from '../../../../http.services/retail/checkout/checkout-manager/checkout-manager.service'
import {ProductDialogComponent} from '../product-dialog/product-dialog.component'
import {ProductActionEnum} from '../../insurance/insurance-product-information-provider/product/product-action.enum'
import {FormatPricePipe} from '../../../shared/pipes/format-price.pipe'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent extends FormComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() legalEntity: LegalEntity | NaturalPerson | JudicialPerson
    @Input() underwriterCheckoutItemGroupings: UnderwriterCheckoutItemGrouping[] = []
    @Input() countryCheckoutItemGroupings: CountryCheckoutItemGrouping[] = []

    @Output() onChanged = new EventEmitter<void>()

    underwriters: {[id: string]: Underwriter} = {}
    activeToast: ActiveToast<any>
    entityAddress: Address
    product: Product

    thirdPartyInsurance = false
    comprehensiveInsurance = false
    extensionInsurance = false
    yellowCardInsurance = false

    dialogRef: MatDialogRef<any, any>;
    countries: CountryIdentifier[] = [];
    newCountryCheckoutItemGroupings: CountryCheckoutItemGrouping[] = [];
    tabName: string
    sameCurrencyProduct: any[] = [];
    otherCurrencies: any[] = [];

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private brokerService: UnderwriterInformationProviderService,
                private toastr: ErrorToastService,
                private router: Router,
                private dialog: MatDialog,
                private unMarshallerService: UnMarshallerService,
                private checkoutOrderFinalisationManagerService: CheckoutOrderFinalisationManagerService,
                private RetailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private insuranceProductInformationProviderService: InsuranceProductInformationProviderService,
                private countryTaxesInformationProviderService: CountryTaxesInformationProviderService,
                private checkoutManagerService: CheckoutManagerService) {
        super(translate, translateParser)

    }

    ngOnInit() {
        this.entityAddress = this.getAddress() ? this.getAddress() : this.legalEntity.addresses[0]
        this.getUnderwriters()
        this.newCountryCheckoutItemGroupings = this.mergeGroupings();
        this.removeEmptyCountries();
    }

    ngOnChanges(changes: SimpleChanges) {
       if (changes.countryCheckoutItemGroupings) {
        this.newCountryCheckoutItemGroupings = this.mergeGroupings();
        this.removeEmptyCountries();
       }
    }

    removeEmptyCountries() {
        this.newCountryCheckoutItemGroupings = this.newCountryCheckoutItemGroupings.filter(countryCheckoutItemGrouping => {
            const newCountryCheckoutItemGrouping = countryCheckoutItemGrouping.underwriterCheckoutItemGroupings.filter(underwriterGrouping => {
                const newUnderwriterGrouping = underwriterGrouping.checkoutDatedItemGroupings.filter(datedGrouping => {
                    return datedGrouping.ycinsurancePolicyCartItems.length > 0 || datedGrouping.thirdPartyInsurancePolicyCartItems.length > 0
                        || datedGrouping.comprehensiveInsurancePolicyCartItems.length > 0 || datedGrouping.extensionInsurancePolicyCartItems.length > 0;
                });
                return newUnderwriterGrouping.length > 0;
            });
            return newCountryCheckoutItemGrouping.length > 0;
        });
    }

    disableTheTab(): boolean {

        this.countries = [];
        this.newCountryCheckoutItemGroupings.forEach(item => {
            
            let countryAlreadyExists = false
            if (this.countries.length !== 0) {
                this.countries.forEach(tt => {
                    if ((item.countryIdentifier as CountryDescriptionIdentifier).description === (tt as CountryDescriptionIdentifier).description) {
                        countryAlreadyExists = true
                    } else {
                        this.countries.push(item.countryIdentifier as CountryDescriptionIdentifier);
                    }
                })
            } else {
                this.countries.push(item.countryIdentifier as CountryDescriptionIdentifier);
            }
        });
    
        // Check if all countries with single policy items are similar
        if (this.countries.length === 1) {
            return true;
        } else {
            return false;
        }
        
    }

    mergeGroupings() {
        let ycGroupingUnderwriterItemGrouping: UnderwriterCheckoutItemGrouping[] = [];
        let otherCheckoutItemGrouping: CountryCheckoutItemGrouping[] = [];
        let allCountryCheckoutItemGrouping: CountryCheckoutItemGrouping[] = [];

        for (let grouping of this.countryCheckoutItemGroupings) {
            for (let underwriterGrouping of grouping.underwriterCheckoutItemGroupings) {
                for (let groupItems of underwriterGrouping.checkoutDatedItemGroupings) {
                    const ycDatedItemGrouping = JSON.parse(JSON.stringify(groupItems));
                    const otherItemGrouping = JSON.parse(JSON.stringify(groupItems));

                    if (groupItems.ycinsurancePolicyCartItems.length > 0) {

                        ycDatedItemGrouping.comprehensiveInsurancePolicyCartItems = []
                        ycDatedItemGrouping.thirdPartyInsurancePolicyCartItems = []
                        ycDatedItemGrouping.extensionInsurancePolicyCartItems = []

                        let ycUnderwriterCheckoutGrouping = new UnderwriterCheckoutItemGrouping();
                        ycUnderwriterCheckoutGrouping.checkoutDatedItemGroupings = [ycDatedItemGrouping]
                        ycUnderwriterCheckoutGrouping.underwriterIdentifier = underwriterGrouping.underwriterIdentifier
                        ycUnderwriterCheckoutGrouping.checkoutNonDatedItemGroupings = underwriterGrouping.checkoutNonDatedItemGroupings

                        ycGroupingUnderwriterItemGrouping.push(ycUnderwriterCheckoutGrouping);
                    }

                    otherItemGrouping.ycinsurancePolicyCartItems = []
                    let otherUnderwriterCheckoutGrouping = new UnderwriterCheckoutItemGrouping();
                    otherUnderwriterCheckoutGrouping.checkoutDatedItemGroupings = [otherItemGrouping]
                    otherUnderwriterCheckoutGrouping.underwriterIdentifier = underwriterGrouping.underwriterIdentifier
                    otherUnderwriterCheckoutGrouping.checkoutNonDatedItemGroupings = underwriterGrouping.checkoutNonDatedItemGroupings

                    let countryAlreadyExists = false
                    for (let other of otherCheckoutItemGrouping) {
                        if ((other.countryIdentifier as CountryDescriptionIdentifier).description === (grouping.countryIdentifier as CountryDescriptionIdentifier).description) {
                            other.underwriterCheckoutItemGroupings.push(otherUnderwriterCheckoutGrouping)
                            countryAlreadyExists = true
                        }
                    }
                    if (!countryAlreadyExists) {
                        otherCheckoutItemGrouping.push({countryIdentifier: grouping.countryIdentifier, underwriterCheckoutItemGroupings: [otherUnderwriterCheckoutGrouping]})
                    }
                }
            }

        }


        allCountryCheckoutItemGrouping.push(...otherCheckoutItemGrouping);

        const customCountry = this.customYellowCardCountry();

        let mergedCountryCheckoutItemGrouping: CountryCheckoutItemGrouping[] = [];
        mergedCountryCheckoutItemGrouping.push({countryIdentifier: customCountry, underwriterCheckoutItemGroupings: ycGroupingUnderwriterItemGrouping})
        mergedCountryCheckoutItemGrouping.push(...allCountryCheckoutItemGrouping)

        return mergedCountryCheckoutItemGrouping;
    }

    getTitle(countryIdentifier: CountryIdentifier): string {
        return (countryIdentifier as CountryDescriptionIdentifier).description
    }

    customYellowCardCountry(): CountryIdentifier {
        const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
        countryDescriptionIdentifier.description = 'Yellow Card';

        return countryDescriptionIdentifier;
    }

    ngAfterViewInit(): void {}

    checkThirdPartyInsurance(groupingEvent) {
        for (const underwriterCheckoutItemGrouping of groupingEvent.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.thirdPartyInsurancePolicyCartItems && checkoutDatedItemGrouping.thirdPartyInsurancePolicyCartItems.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    getPolicyDuration(_startDate, _expiryDate): string {
        let duration;
        const startDate = new Date(_startDate);
        const expiryDate = new Date(_expiryDate);
        const totalDays = (expiryDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

        duration = this.formatDuration(Math.ceil(totalDays), 'Day');

        return duration;
    }

    getExtensionDuration(item: ThirdPartyInsurancePolicyCartItem | ComprehensiveInsurancePolicyCartItem) {
        let duration = null
        if ((item as any).thirdPartyInsuranceProductCartItem) {
            duration = (item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.priceSchedule.lengthOfExtension
        } else if ((item as any).comprehensiveInsuranceProductCartItem) {
            duration = (item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.priceSchedule.lengthOfExtension
        }
        if (duration) {
            if (duration.days && duration.months) {
                return `${duration.days} DAYS AND ${duration.months} MONTH POLICY EXTENSION`
            } else if (duration.days) {
                return `${duration.days} DAYS POLICY EXTENSION`
            } else if (duration.months) {
                return `${duration.months} MONTH POLICY EXTENSION`
            }
        }
        return 'EXTENSION'
    }

    formatDuration(value: number, type: string): string {
        if (value === undefined) return "";
        let formatted = value === 0 ? "" : `${value} ${type}`;
        formatted = value > 1 ? `${formatted}s` : formatted;
        return formatted === "" ? formatted : `${formatted} `;
    }

    checkComprehensiveInsurance(groupingEvent) {
        for (const underwriterCheckoutItemGrouping of groupingEvent.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.comprehensiveInsurancePolicyCartItems && checkoutDatedItemGrouping.comprehensiveInsurancePolicyCartItems.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    checkExtensionInsurance(groupingEvent) {
        for (const underwriterCheckoutItemGrouping of groupingEvent.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.extensionInsurancePolicyCartItems && checkoutDatedItemGrouping.extensionInsurancePolicyCartItems.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    checkYellowCardInsurance(groupingEvent) {
        for (const underwriterCheckoutItemGrouping of groupingEvent.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.ycinsurancePolicyCartItems && checkoutDatedItemGrouping.ycinsurancePolicyCartItems.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    updateExtensionItem(item: ExtensionInsurancePolicyCartItem) {
        this.openExtensionDialog(item)
    }

    updateItem(item: ThirdPartyInsurancePolicyCartItem | ComprehensiveInsurancePolicyCartItem | YCInsurancePolicyCartItem): void {

        let productIdentifier: ProductIdentifier
        if ((item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem) {
            productIdentifier = (item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.productIdentifier
        } else if ((item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem) {
            productIdentifier = (item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.productIdentifier
        } else if ((item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem) {
            productIdentifier = (item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem.productIdentifier
        }

        this.provideInsuranceProductInformation(productIdentifier).then((product) => {
            this.openProductDialog(product, item.vehicle, item)
        })

    }

    removeItem(item: ThirdPartyInsurancePolicyCartItem | ComprehensiveInsurancePolicyCartItem | ExtensionInsurancePolicyCartItem | YCInsurancePolicyCartItem, index: number): void {

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
        retailProfileOwnerIdentifier.tpiIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

        const itemNumberIdentifier = new ItemNumberIdentifier()
        itemNumberIdentifier.itemNumber = item.number

        const finaliseCheckoutOrderRequest = new FinaliseCheckoutOrderRequest()
        finaliseCheckoutOrderRequest.removeItem = itemNumberIdentifier
        finaliseCheckoutOrderRequest.retailProfileIdentifier = retailProfileOwnerIdentifier

        this.startLoad()
        this.checkoutOrderFinalisationManagerService.finaliseCheckoutOrder(finaliseCheckoutOrderRequest).then(() => {
            this.stopLoad()
            this.onChanged.emit()
        });

    }

    onRemoveItem(): void {

        this.translate.get('TOAST.REMOVE_LAST_ORDER_ITEM').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                closeButton: true
            })
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.router.navigate(['/retail/cart'])
                        this.activeToast.portal.destroy()
                        break
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy()
                        break
                }
            })
            this.activeToast.onHidden.subscribe(() => {

            })
        })
    }

    getUnderwriters() {

        for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
            this.brokerService.getUnderwriter(underwriterCheckoutItemGrouping.underwriterIdentifier, (underwriter) => {
                this.underwriters[(underwriterCheckoutItemGrouping.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name] = underwriter
            })
        }

    }

    provideInsuranceProductInformation(productIdentifier: ProductIdentifier): Promise<Product> {

        return new Promise<Product>((resolve) => {

            const provideInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
            const insuranceProductCriteria = new ProductCriteria();
            insuranceProductCriteria.productIdentifier = productIdentifier;
            provideInsuranceProductInformationRequest.criteria = insuranceProductCriteria;

            this.startLoad();
            this.insuranceProductInformationProviderService.provideInsuranceProductInformation(provideInsuranceProductInformationRequest).toPromise().then(response => {
                if (response && response.body) {
                    const provideThirdPartyInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
                    this.stopLoad();
                    resolve(provideThirdPartyInsuranceProductInformationResponse.products[0] as any)
                }
            }).catch(() =>{
                this.stopLoad();
                resolve(null)
            });

        })

    }

    openProductDialog(product: Product, vehicle: RetailVehicle | Vehicle, item: Item): void {

        const data = {product, vehicles: [vehicle], action: ProductActionEnum.UPDATE_ITEM, item: item}

        this.dialogRef = this.dialog.open(ProductDialogComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "1%" },
            panelClass: 'product-dialog',
            data
        });

        this.dialogRef.afterClosed().subscribe((reload) => {
            if (reload) {
                this.onChanged.emit()
            }
        });

    }

    openExtensionDialog(item: ExtensionInsurancePolicyCartItem): void {

        const data = {
            action: ProductActionEnum.UPDATE_ITEM,
            policyNumber: item.policyNumber,
            editOrderItem: item,
            endDate: item.expiryDate,
            item: item
        }

        this.dialog.open(InsurancePolicyManagerExtensionDialogComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "1%" },
            panelClass: 'product-dialog',
            data
        });

    }

    groupItemsByCurrency(items) {
        return items.reduce(function (r, a) {
            r[a.price.currency] = r[a.price.currency] || [];
            r[a.price.currency].push(a);
            return r;
        }, Object.create(null));
    }

    getAvailableCurrency(items) {
        const currenciesArray =  items.reduce((r, a) => {
            r[a.price.currency] = r[a.price.currency] || [];
            return r.set(a.price.currency, a.price.currency);
        }, new Map());
        return currenciesArray;
    }

    getAvailableCurrencyForComprehensive(items) {
        const currenciesArray =  items.reduce((r, a) => {
            r[a.vehicleValue.currency] = r[a.vehicleValue.currency] || [];
            return r.set(a.vehicleValue.currency, a.vehicleValue.currency);
        }, new Map());
        return currenciesArray;
    }

    groupItemsByCurrencyComprehensive(items) {
        return items.reduce(function (r, a) {
            r[a.vehicleValue.currency] = r[a.vehicleValue.currency] || [];
            r[a.vehicleValue.currency].push(a);
            return r;
        }, Object.create(null));
    }

    getItemsCurrency(grouping): string {
        for (const cartItems of grouping) {
            return cartItems.price.currency
        }
        return null;
    }

    getTotalYellowCardInsurance(groupingByCurrency): number {
        let total = 0
        for (const cartItem of groupingByCurrency) {
            const coveredCountries = this.getYellowCardSupportedCountries();
            total += FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService,  cartItem.price.amount, coveredCountries)
        }
        return total
    }

    getTotalThirdPartyInsurance(groupingByCurrency): number {
        let total = 0
        for (const cartItems of groupingByCurrency) {
            total += FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService,  cartItems.price.amount, cartItems.thirdPartyInsuranceProductCartItem.coveredCountries)
        }
        return total
    }

    getTotalExtensionsInsurance(groupingByCurrency): number {
        let total = 0
        for (const cartItems of groupingByCurrency) {
            total += FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService,  cartItems.price.amount, cartItems.extensionInsuranceProductCartItem.coveredCountries)
        }
        return total

    }

    public getPlateNumber(vehicle: RetailVehicle | Vehicle): string {
        return Utils.getVehiclePlateNumber(vehicle)
    }

    onTabClick(event){
        this.checkoutManagerService.onTabClick(event)
    }

    /**
     * Returns the list of countries that support yellow card
     * @returns {CountryDescriptionIdentifier[]} the list of countries that support yellow card
     */
    getYellowCardSupportedCountries(): CountryDescriptionIdentifier[] {
        const countries = [];
        const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
        countryDescriptionIdentifier.description = 'South Africa';
        countries.push(countryDescriptionIdentifier);

        return countries;
    }

    getAddress() {
        return this.legalEntity.addresses.find(value => value["@class"] === "za.co.magnabc.tpi.location.geographic.BasicPhysicalAddress")
    }

    filterYCCoveredCountries(ycPriceSchedules: YCPriceSchedule[]): string[] {
        const countries: string[] = [];

        for(let ycPriceSchedule of ycPriceSchedules) {
            if (!countries.includes(ycPriceSchedule.country) && ycPriceSchedule.price > 0) {
                countries.push(ycPriceSchedule.country)
            }
        }

        return countries;
    }
}

