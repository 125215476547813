import { DepositMethodIdentifier } from "./deposit-method-identifier.model";

export class DepositMethodBankTransferIdentifier extends DepositMethodIdentifier{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.deposit.identification.DepositMethodBankTransferIdentifier";
    }

    public accountNumber : string;
    public bankName : string;
}