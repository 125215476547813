import { InsurancePolicy } from '../../../../../../insurance/models/insurance-policy.model';
import { Response } from '../../../../../../../urdad/services/models/response.model';
import { RetailVehicle } from '../../../../../models/retail-vehicle.model';

export class ProvideRetailProfileVehicleInformationResponse extends Response {
    constructor(){
        super();
    }

    public batchFrom : number;
    public batchTo: number;
    public totalNumberOfResults: number ;

    public retailVehicles: RetailVehicle[] = [];
    public insurancePolicies: InsurancePolicy[] = [];
}
