export abstract class Product {
    constructor(){}

    public number: string;
    public name: string;
    public description: string;
    public active: boolean;
    public baseCurrency: string;

}
