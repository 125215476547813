import { LegalEntityIdentifier } from '../../../../../../entity/identification/models/legal-entity-identifier.model';
import {Request} from '../../../../../../urdad/services/models/request.model';
import {Credentials} from '../../../../../credentials/models/credentials.model';

export class AuthoriseOverrideRequest extends Request {

    constructor() {
        super();
    }

    credentials: Credentials;
    loggedInUser: LegalEntityIdentifier;
    overrideUser: LegalEntityIdentifier;
}
