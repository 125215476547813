import { Address } from './address.model';
export class MalawiPhysicalAddress extends Address
{
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.location.geographic.MalawiPhysicalAddress";
  }

  public lineOne: string;
  public lineTwo: string;
  public city: string;
  public country: string;

}
