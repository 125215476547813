import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Order, OrderStatus } from "@magnabc/tpi";
import { Observable } from "rxjs";

@Component({
    selector: 'app-confirmation-accordion',
    templateUrl: './confirmation-accordion.component.html',
    styleUrls: ['./confirmation-accordion.component.scss']
})
export class ConfirmationAccordion implements OnInit {


    @Input() order: Order;
    @Input() proposals: any;
    @Output() openedAccordion = new EventEmitter();
    @Output() closedAccordion = new EventEmitter();
    @Input() itemId: string;
    @Output() printDocumentBtn = new EventEmitter<void>();
    @Output() payOnlineBtn = new EventEmitter();
    @Input() showPrintButton: boolean;
    @Input() showPayOnlineButton: boolean;
    @Input() printDocumentId: string;
    @Input() showProposal: boolean;
    @Input() showOrder: boolean;
    @Output() cancelOrder = new EventEmitter<void>();
    orderStatus = OrderStatus;


    ngOnInit(): void {
    }

    onPrint(orderNumber) {
        this.printDocumentBtn.emit(orderNumber);
    }

    onPayOnline(orderNumber) {
        this.payOnlineBtn.emit(orderNumber);
    }

    onOpened(event, itemId) {
        this.openedAccordion.emit({event, itemId});
    }

    onCancel() {
        this.cancelOrder.emit();
    }

    onAfterExpand() {
        this.openedAccordion.emit();
    }

    getOrderStatus(orderStatus: string) {
        let status = ''
        if (orderStatus === this.orderStatus.PENDING_PAYMENT) {
            status = 'Pending Payment'
            this.showPayOnlineButton = true
        } else if (orderStatus === this.orderStatus.CANCELLED) {
            status = 'Cancelled'
            this.showPayOnlineButton = false
        } else if (orderStatus === this.orderStatus.PAID) {
            status = 'Paid'
            this.showPayOnlineButton = false
        }
        return status
    }

 }
