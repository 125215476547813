import { Criteria } from "../../../../criteria/models/criteria.model";
import { InsuranceCategory } from "../../models/insurance-category.model";

export class InsuranceCategoryCriteria extends Criteria {
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.CoveredCountryInsuranceCategoryCriteria";
} 

  public insuranceCategory: InsuranceCategory;
}
