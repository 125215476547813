import {BrokerIdentifier} from "../../identification/models/broker-identifier.model"
import {UnderwriterIdentifier} from "../../identification/models/underwriter-identifier.model"
import {Excess} from "../../models/excess.model"
import {InsuranceProductCartItem} from "../../models/insurance-product-cart-item.model"
import {YCPriceInterval} from "./yc-price-interval.model"
import {YCPriceSchedule} from "./yc-price-schedule.model"
import {YCLimitDetails} from "./yc-limit-details.model"
import {AgentIdentifier} from '../../../../entity/identification/models/agent-identifier.model'

export class YCInsuranceProductCartItem extends InsuranceProductCartItem {
    constructor(){
        super()
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsuranceProductCartItem"
    }

    public conditions: string;
    public insuranceVehicleCategoryDescription: string;
    public insuranceVehicleCategorySubDescription: string;
    public agentIdentifiers: AgentIdentifier[] = [];
    public brokerIdentifier: BrokerIdentifier;
    public underwriterIdentifier: UnderwriterIdentifier;
    public ycPriceSchedules: YCPriceSchedule[] = [];
    public ycPriceIntervals: YCPriceInterval[] = []; //TTE 06-Jul-2023 FIXME: This should be a singular
    public ycLimitDetails: YCLimitDetails[] = [];
    public excesses: Excess[] = [];
}
