import {OrderIdentifier} from "./order-identifier.model";

export class OrderNumberIdentifier extends OrderIdentifier {

    orderNumber: string;

    constructor(orderNumber: string) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.checkout.identification.OrderNumberIdentifier";
        this.orderNumber = orderNumber;
    }
}

