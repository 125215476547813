import {Request} from '../../../../../../../urdad/services/models/request.model';

export class ResetPasswordRequest extends Request
{

   constructor(){
    super();
   }

    public token: string;
}
