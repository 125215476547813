import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, QueryLegalEntityInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class QueryLegalEntityInformationManagerService {

    basePath = '/entity/querylegalentityinformationmanager';

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideLegalEntity(queryLegalEntityInformationRequest: QueryLegalEntityInformationRequest) {

        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/querylegalentityinformation';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(queryLegalEntityInformationRequest);

        return this.httpClient.post<any>(path, jsonRequest, this.options).toPromise();
    }

}
