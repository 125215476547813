import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({name: 'formatDateTime'})
export class FormatDateTimePipe implements PipeTransform {

    transform(date: any): string {
        return moment(date).format("DD MMM YYYY HH:mm");
    }

}
