import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../shared/form/form.component'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {Location} from '@angular/common'
import {ActivatedRoute, Params} from '@angular/router'
import {
    MakeModelTrimProviderService
} from '../../../http.services/vehicle/makemodeltrim/make-model-trim-provider/make-model-trim-provider.service'
import {
    MakeModelTrimFinalisationManagerService
} from '../../../http.services/vehicle/makemodeltrim/make-model-trim-finalisation-manager/make-model-trim-finalisation-manager.service'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ToastComponent} from '../../shared/toast/toast.component'
import {environment} from '../../../../environments/environment'
import {
    Model,
    Make,
    ProvideVehicleMakesRequest,
    ProvideVehicleModelsRequest,
    FinaliseCreateVehicleMakeRequest,
    FinaliseCreateVehicleModelRequest,
    UnMarshallerService,
    ProvideVehicleMakesResponse, ProvideVehicleModelsResponse, MakeDescriptionCriteria, MakeDescriptionIdentifier,
    AllMakeCriteria
} from "@magnabc/tpi";
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-make-model-trim-create',
  templateUrl: './make-model-trim-create.component.html',
  styleUrls: ['./make-model-trim-create.component.scss']
})
export class MakeModelTrimCreateComponent  extends FormComponent implements OnInit, OnDestroy {

    id: string;
    type: string;
    exists = false;

    formGroup: UntypedFormGroup;
    nameFormControl: UntypedFormControl;
    modelFormControl: UntypedFormControl;
    makeFormControl: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;
    activeToast: ActiveToast<any>;
    displayDropDown = FormComponent.displayDropDown;

    makes: Make[];
    make: Make;

    models: Model[];
    model: Model;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private toastr: ErrorToastService,
                private location: Location,
                private route: ActivatedRoute,
                private makeModelTrimProviderService: MakeModelTrimProviderService,
                private makeModelTrimFinalisationManagerService: MakeModelTrimFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Create Vehicle Make - Console - ${this.runtimeConfigurationService.title}`);

        this.nameFormControl = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]);
        this.modelFormControl = new UntypedFormControl('', []);
        this.makeFormControl = new UntypedFormControl('', []);

        this.formGroup = new UntypedFormGroup({
            'nameControl': this.nameFormControl,
            'modelControl': this.modelFormControl,
            'makeControl': this.makeFormControl
        });

    }

    ngOnInit(): void {

        this.route.queryParams.forEach((params: Params) => {

            this.type = params['type'];

        });

        //noinspection JSIgnoredPromiseFromCall
        this.route.params.forEach((params: Params) => {

            this.id = params['id'];
            // this.nameFormControl.setValue(StringUtils.applySentenceCase(params['id']));

            switch (this.type) {
                case 'make':
                    this.make = new Make();

                    const provideMakeRequest = new ProvideVehicleMakesRequest();
                    const criteria = new AllMakeCriteria();

                    provideMakeRequest.criteria = criteria;
                    this.makeModelTrimProviderService.provideMakes(provideMakeRequest).then(httpResponse => {
                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleMakesResponse) as ProvideVehicleMakesResponse);
                            this.makes = response.makes;
                        }

                    });

                    break;
                case 'model':
                    this.model = new Model();

                    const vehicleMakeCriteria = new MakeDescriptionCriteria();
                    const makeDescriptionIdentifier = new MakeDescriptionIdentifier()
                    makeDescriptionIdentifier.description = this.id;
                    vehicleMakeCriteria.makeIdentifier = makeDescriptionIdentifier;

                    const provideModelRequest = new ProvideVehicleModelsRequest();
                    provideModelRequest.criteria = vehicleMakeCriteria;

                    this.makeModelTrimProviderService.provideMakes(provideModelRequest).then(httpResponse => {

                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleMakesResponse) as ProvideVehicleMakesResponse);
                            const makeDescriptionIdentifier = new MakeDescriptionIdentifier()
                            makeDescriptionIdentifier.description = response.makes[0].name;
                            this.model.makeIdentifier = makeDescriptionIdentifier;
                            this.makeFormControl.setValue((this.model.makeIdentifier as MakeDescriptionIdentifier).description);
                            this.makeFormControl.disable();
                        }
                    });

                    const provideModelsRequest = new ProvideVehicleModelsRequest();
                    provideModelsRequest.criteria = vehicleMakeCriteria;
                    this.makeModelTrimProviderService.provideModel(provideModelsRequest).then(httpResponse => {

                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleModelsResponse) as ProvideVehicleModelsResponse);
                            this.models = response.models;
                        }
                    });

                    break;
            }

        });

    }

    ngOnDestroy(): void {
        if (this.activeToast) {
            this.activeToast.portal.destroy();
        }
    }

    onSubmitCreateDropdown(event): void {

        if (!this.form.invalid) {

            if (this.make) {

                if (this.contains(this.makes, this.nameFormControl.value)) {
                    this.exists = true;
                    return;
                } else {
                    this.exists = false;
                }

                this.make.name = this.nameFormControl.value;
                this.make.name = this.createIdentifier(this.make.name);

                const finaliseCreateVehicleMakeRequest = new FinaliseCreateVehicleMakeRequest();
                finaliseCreateVehicleMakeRequest.make = this.make;
                this.startLoad();
                this.makeModelTrimFinalisationManagerService.finaliseCreateVehicleMake(finaliseCreateVehicleMakeRequest).subscribe((response) => {
                    this.stopLoad();
                    this.toast();
                },(error) => {
                    this.stopLoad();
                });

            }

            if (this.model) {

                if (this.contains(this.models, this.nameFormControl.value)) {
                    this.exists = true;
                    return;
                } else {
                    this.exists = false;
                }

                this.model.name = this.nameFormControl.value;

                const finaliseCreateVehicleModelRequest = new FinaliseCreateVehicleModelRequest();
                finaliseCreateVehicleModelRequest.model = this.model;
                this.startLoad();
                this.makeModelTrimFinalisationManagerService.finaliseCreateVehicleModel(finaliseCreateVehicleModelRequest).subscribe((response) => {
                    this.stopLoad();
                    this.toast();
                }, (error) => {
                    this.stopLoad();
                });

            }

        }

    }

    contains(list, item): boolean {

        for (const i of list) {
            if (i.name.toLowerCase() === item.toLowerCase()) {
                return true;
            }
        }

        return false;

    }

    createIdentifier(name: string) {
        return name.split(' ').join('-');
    }

    toast() {

        this.translate.get('TOAST.CREATE_DROPDOWN').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.nameFormControl.value}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.addAnother();
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });

    }

    addAnother(): void {
        this.nameFormControl.setValue("");
        this.id = "";
    }

    onCancel(): void {
        this.location.back();
    }

}
