import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
    DocumentType,
    ScannedDocument,
    ScannedDocumentIdentifier,
    ScannedDocumentTypeDescriptionIdentifier,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { error } from 'protractor';

import { ErrorToastService } from '../../../../app.services/common/error-toast/error-toast.service';
import { FileUploadService } from '../../../../app.services/common/file-upload/file-upload.service';
import { FormComponent } from '../../../shared/form/form.component';


declare const jQuery: any;
@Component({
  selector: 'app-scanned-document',
  templateUrl: './scanned-document.component.html',
  styleUrls: ['./scanned-document.component.scss']
})
export class ScannedDocumentComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() required = false;
    @Input() description: string;
    @Input() uploadPdf: boolean;
    @Input() scannedDocumentIdentifier: ScannedDocumentIdentifier;
    @Output() onScannedDocument = new EventEmitter<ScannedDocument>();
    @Output() onRemoveDocument = new EventEmitter<ScannedDocumentIdentifier>();
    @Output() onFile = new EventEmitter<any>();
    @Output() onValid = new EventEmitter<void>();

    uploading = false;
    documentRequired = false;
    scannedDocument: ScannedDocument;
    validFileTypes: string[] = [];
    maxFileSizeInMB: number = 10;
    maxFileSize: number = this.maxFileSizeInMB*1024*1024;

    formGroup: UntypedFormGroup;
    scannedDocumentControl: UntypedFormControl;

    @ViewChild('form') form: FormGroupDirective;

    constructor(translateService: TranslateService, translateParser: TranslateParser, private fileUploadService: FileUploadService, private translate: TranslateService, private errorToastService: ErrorToastService) {
        super(translateService, translateParser);
    }

    ngOnInit() {

        this.scannedDocument = new ScannedDocument();
        this.scannedDocumentControl = new UntypedFormControl(undefined, []);
        this.formGroup = new UntypedFormGroup({
            'scannedDocumentControl': this.scannedDocumentControl
        });

        this.initialiseFileTypeList();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    submit(): void {
        document.getElementById('form-submit-scanned-document').click();
    }

    onRemoveScannedDocument(): void {
        var identifierTemp = this.scannedDocumentIdentifier;

        this.scannedDocumentIdentifier = null;

        if (identifierTemp) {
            this.scannedDocument = new ScannedDocument();
            this.onRemoveDocument.emit(identifierTemp);
        } else {
            var scannedDocumentTypeDescriptionIdentifier = new ScannedDocumentTypeDescriptionIdentifier();
            scannedDocumentTypeDescriptionIdentifier.documentIdentifier = this.scannedDocument.documentIdentifier;
            scannedDocumentTypeDescriptionIdentifier.documentTypeDescription = this.scannedDocument.documentType;
            this.scannedDocument = new ScannedDocument();
            this.onRemoveDocument.emit(scannedDocumentTypeDescriptionIdentifier);

        }

        this.scannedDocumentControl.setValue(null)

    }

    initialiseFileTypeList(): void {
        this.validFileTypes.push(".pdf");
        this.validFileTypes.push(".jpeg");
        this.validFileTypes.push(".jpg");
        this.validFileTypes.push(".png");
    }

    validFileType() : boolean {

        for (let i = 0; i < this.validFileTypes.length; i++) {
            if(this.scannedDocumentControl.value.files[0].name.toLowerCase().indexOf(this.validFileTypes[i]) !== -1)
            {
                return true;
            }
        }
        return false;

    }

    validFileTypeDisplay() : string {

        let validFile : string='';

        for (let i = 0; i < this.validFileTypes.length; i++) {
            console.log('valid fle',validFile);
            validFile +=this.validFileTypes[i].slice(1,this.validFileTypes[i].length) + ' , ';
        }
        return validFile.slice(0,validFile.length-2);
    }

    uploadFile(): void {
        this.uploading = true;
        const fileSizeError = `Max file size exceeded. The maximum file size is ${this.maxFileSizeInMB} MB.`;
        const fileTypeError = `Invalid file type. Valid file types are: ${this.validFileTypeDisplay()}`;

        if (this.validFileType()) {
            if (this.scannedDocumentControl.value.files[0].size < this.maxFileSize) {

                this.fileUploadService.uploadFile(this.scannedDocumentControl.value.files[0]).subscribe((response) => {
                    this.uploading = false;
                    this.scannedDocument.documentName = this.scannedDocumentControl.value.files[0].name;
                    this.scannedDocument.documentIdentifier = response.body.identifier;
                    this.scannedDocument.documentType = new DocumentType();
                    this.scannedDocument.documentType.description = this.description;
                    this.onFile.emit(this.scannedDocumentControl.value.files[0]);
                    this.onScannedDocument.emit(this.scannedDocument);
                }, (error: HttpErrorResponse) => {
                    let message = 'Unable to upload file.';
                    if (error.error) {
                        let errorMessage: string = error.error;
                        if (errorMessage === 'Max file size exceeded' || errorMessage.includes('Connection terminated as request was larger than')) {
                            message = fileSizeError;
                        } else if (errorMessage.includes('File type not supported ')) {
                            message = fileTypeError;
                        }
                    }
                    this.uploading = false;
                    this.translate.get('TOAST.FILEUPLOAD_ERROR').subscribe((res: any) => {
                        this.errorToastService.success(message, res.TITLE)
                    });
                });
            } else {

                this.translate.get('TOAST.FILEUPLOAD_ERROR').subscribe((res: any) => {
                    this.errorToastService.success(fileSizeError, res.TITLE)
                });

                this.uploading = false;

            }

        } else {

            this.translate.get('TOAST.FILEUPLOAD_ERROR').subscribe((res: any) => {
                this.errorToastService.success(fileTypeError, res.TITLE)
            });

            this.uploading = false;
        }

    }

    onCheckValidation(event): void {

        this.documentRequired = false;

        if (!this.form.invalid) {

            if (this.required && !this.scannedDocumentIdentifier && !this.scannedDocument.documentIdentifier) {
                this.documentRequired = true;
                jQuery('html,body').animate({ scrollTop: jQuery('#scannedDocumentControl').offset().top - 300 }, 'slow');
                return;
            }

            this.onScannedDocument.emit(this.scannedDocument);
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');
            if (target) {
                jQuery('html,body').animate({scrollTop: jQuery(target).offset().top - 300}, 'slow');
                target.focus();
            }

        }

    }

    valid(): boolean {
        if (this.required && !this.scannedDocumentIdentifier && !this.scannedDocument.documentIdentifier) {
            this.documentRequired = true;
            jQuery('html,body').animate({ scrollTop: jQuery('#scannedDocumentControl').offset().top - 300 }, 'slow');
            return false;
        }
        return this.form.valid;
    }

}
