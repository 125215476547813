import { Response } from "../../../../urdad/urdad_api";
import { VehicleImportError } from "../../profile_api";

export class FinaliseImportFleetResponse extends Response {
    constructor() {
        super();
        this['@class'] = 'za.co.magnabc.tpi.retail.profile.ImportFleetFinalisationManager.FinaliseImportFleetResponse';
    }

    totalValidVehicles: number;
    totalVehicles: number;
    vehicleImportErrors: VehicleImportError[] = [];
}