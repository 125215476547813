import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {ProvideAccountingTransactionInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class AccountingTransactionInformationProviderService {

    basePath = '/ledger/accountingtransaction/accountingtransactioninformationprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideAccountingTransactionInformation(provideAccountingTransactionInformationRequest: ProvideAccountingTransactionInformationRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideaccountingtransactioninformation';
        return this.http.post(path, provideAccountingTransactionInformationRequest, this.options);
    }

}
