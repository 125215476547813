import { Money } from "../../../../financial/models/money.model";

export class RecommendedPrice{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.RecommendedPrice";
    }

    public value : Money;
    public validTo: Date;
    public validFrom: Date;
}
