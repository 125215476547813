import { TransactionReportEntry } from "../../../retail/insurance/thirdpartyinsurance/reporting/models/transaction-report-entry.model";

export class TransactionReport
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.TransactionReport";
    }

    public dateTo: Date;
    public dateFrom: Date;
    public transactionReportEntries: TransactionReportEntry[] = [];
}
