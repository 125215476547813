import {Response} from '../../../../../../../urdad/services/models/response.model';
import {CountryIdentifier} from '../../../../../../../location/geographic/identification/models/country-identifier.model';
import {Country} from '../../../../../../../location/geographic/models/country.model';
import {ClaimSpecificExcess} from '../../../../../models/claim-specific-excess.model';
import {SetupLimitType} from '../../../../../setup/models/limit-type.model';
import {InsuranceType} from "../../../../../setup/models/insurance-type.model";
import { AgentIdentifier } from '../../../../../../../entity/identification/models/agent-identifier.model';
import { InsuranceVehicleCategorySummary } from '../../../../../setup/models/insurance-vehicle-category-summary.model';
import { Broker } from '../../../../../models/broker.model';
import {Product} from '../../../../../../products/models/product.model'

export class InitialiseInsuranceProductResponse extends Response{
    constructor(){
        super();
    }

    public agentIdentifiers: AgentIdentifier[] = [];
    public brokerOwnAgent: AgentIdentifier[] = [];
    public insuranceTypes: InsuranceType[] = [];
    public insuranceVehicleCategorySummaries: InsuranceVehicleCategorySummary [] = [];
    public limitTypes: SetupLimitType [] = [];
    public claimSpecificExcesses: ClaimSpecificExcess [] = [];
    public brokerCountry: CountryIdentifier;
    public countries: Country[] = [];
    public broker: Broker;
    public countryCurrency: string;
    public sellingToCountries: Country[] = [];
    public products: Product[] = [];
}
