import { AuthenticationService } from './app.services/managers/authentication/authentication.service';
import {BrowserModule} from "@angular/platform-browser"
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core"
import {RouterModule} from "@angular/router"
import {AppComponent} from "./app.component"
import {NgbModule} from "@ng-bootstrap/ng-bootstrap"
import {TranslateLoader, TranslateModule} from "@ngx-translate/core"
import {TranslateHttpLoader} from "@ngx-translate/http-loader"
import {NgSelectModule} from "@ng-select/ng-select"
import {ToastrModule, ToastrService} from "ngx-toastr"
import {ToastComponent} from "./view.components/shared/toast/toast.component"
import {NgxDatatableModule} from "@swimlane/ngx-datatable"
import {LoadingModule} from "ngx-loading"
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http"
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog"
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common"
import {AppRoutes} from './routes'
import {SharedModule} from "./view.components/shared/shared.module"
import {HttpInterceptorService} from './app.services/common/http-interceptor/http-interceptor.service'
import {MarshallerService, UnMarshallerService} from '@magnabc/tpi'
import {RaygunErrorHandler} from './common/utils/utils.raygun'
import {CustomDialogComponent} from './view.components/shared/dialogs/custom-dialog/custom-dialog.component'
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics'
import {environment} from '../environments/environment'
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {CustomToastrService} from "./app.services/common/custom-toast/custom-toastr.service"
import {PagesModule} from './view.pages/pages.module'
import {RuntimeConfigurationService} from './app.services/common/runtime-configuration/runtime-configuration.service'

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

function initializeAppFactory(authService: AuthenticationService, runtimeConfigurationService: RuntimeConfigurationService){
    return () => {
        return runtimeConfigurationService.initialise().then(() => {
            return authService.applySession()
        })
    }
}

@NgModule({
    declarations: [
        AppComponent,
        CustomDialogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        HttpClientModule,
        RouterModule.forRoot(AppRoutes,
            { enableTracing: false, useHash: true }),// <-- debugging purposes only
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            toastComponent: ToastComponent,
        }),
        SharedModule,
        PagesModule,
        LoadingModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
        NgxGoogleAnalyticsRouterModule,
        NgSelectModule,
        MatDialogModule,
        NgxDatatableModule,
    ],
    providers: [
        MarshallerService,
        UnMarshallerService,
        {
            provide: LocationStrategy, useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: RaygunErrorHandler
        },
        { provide: MatDialogRef, useValue: {} },
        {
            provide: ToastrService,
            useClass: CustomToastrService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            multi: true,
            deps: [AuthenticationService, RuntimeConfigurationService]
        },
        AuthenticationService,
        RuntimeConfigurationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
