import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarshallerService, ProvideProposalGroupEntryRequest } from '@magnabc/tpi';
import { RuntimeConfigurationService } from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalGroupEntryProviderService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideProposalGroupEntry(provideProposalGroupEntryRequest: ProvideProposalGroupEntryRequest): Promise<any> {
        const pathURL = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/comprehensiveinsurance/proposalgroupentryprovider/provideproposalgroupentry';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideProposalGroupEntryRequest);
        return this.httpClient.post(pathURL, jsonRequest, this.options).toPromise();
    }
}
