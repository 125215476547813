import {Request} from '../../../../../../../urdad/services/models/request.model';
import {OneTimePinIdentifier} from '../../../../../models/one-time-pin-identifier.model';
import {Credentials} from '../../../../../../credentials/models/credentials.model';

export class AuthenticateCredentialsRequest extends Request {

    constructor(){
        super();
    }

    capturedOneTimePin: string;
    oneTimePinIdentifier: OneTimePinIdentifier;
    credentials: Credentials;
}
