import { Response } from "../../../../../../../urdad/services/models/response.model";
import { SubscriptionOwner } from "../../../../../models/subscription-owner.model";

export class ProvideSubscriptionsOwnersResponse extends Response {
    constructor(){
        super();
    }

    public naturalPersons: SubscriptionOwner[] = [];
    public judicialPersons: SubscriptionOwner[] = [];
}