import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
    selector: '[appMaskContactNumber]'
})
export class MaskContactNumberDirective implements OnDestroy, OnInit {

    // mask = [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    @Input() appMaskContactNumber = [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    maskedInputController;
    element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngOnInit(): void {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.appMaskContactNumber,
            placeholderChar: " "
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

}
