import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormComponent} from "../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-obfuscated-number',
    templateUrl: './obfuscated-number.component.html',
    styleUrls: ['./obfuscated-number.component.scss']
})
export class ObfuscatedNumberComponent extends FormComponent implements OnInit {

    @Input() obfuscated: string;
    @Output() onValid = new EventEmitter<string>();

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    errorMessage = null;
    invalidNumber = false;

    formGroup: UntypedFormGroup;
    one: UntypedFormControl;
    two: UntypedFormControl;
    three: UntypedFormControl;
    four: UntypedFormControl;

    constructor(private translate: TranslateService, private translateParser: TranslateParser, private toastr: ToastrService) {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.one = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.two = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.three = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.four = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.formGroup = new UntypedFormGroup({
            one: this.one,
            two: this.two,
            three: this.three,
            four: this.four,
        });

        document.getElementById("one").focus();
    }

    onKeyUp(event): void {

        if(event.keyCode !== 32 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40){
            switch (event.srcElement.id) {
                case "one":
                    if (this.one.value.length === 1) {
                        document.getElementById("two").focus();
                    }
                    break;
                case "two":
                    if (this.two.value.length === 1) {
                        document.getElementById("three").focus();
                    }
                    break;
                case "three":
                    if (this.three.value.length === 1) {
                        document.getElementById("four").focus();
                    }
                    break;
                case "four":
                    break;
            }
        }
        else if(event.keyCode === 37 || event.keyCode === 39){
            switch (event.srcElement.id) {
                case "one":
                    if (event.keyCode === 39 ) {
                        document.getElementById("two").focus();
                    }
                    break;
                case "two":
                    if (event.keyCode === 37 ) {
                        document.getElementById("one").focus();
                    }
                    else if(event.keyCode === 39 ){
                        document.getElementById("three").focus();
                    }
                    break;
                case "three":
                    if (event.keyCode === 37 ) {
                        document.getElementById("two").focus();
                    }
                    else if(event.keyCode === 39 ){
                        document.getElementById("four").focus();
                    }
                    break;
                case "four":
                    if (event.keyCode === 37 ) {
                        document.getElementById("three").focus();
                    }

                    break;
            }
        }
    }

    onKeyPress(event): void {

        if (event.keyCode !== 8 && event.keyCode !== 32) {
            switch (event.srcElement.id) {
                case "one":
                    document.getElementById("one").focus();
                    this.one.setValue(event.key);
                    break;
                case "two":
                    document.getElementById("two").focus();
                    this.two.setValue(event.key);
                    break;
                case "three":
                    document.getElementById("three").focus();
                    this.three.setValue(event.key);
                    break;
                case "four":
                    document.getElementById("four").focus();
                    this.four.setValue(event.key);
                    break;
            }
        }
    }

    submit(): void {
        document.getElementById('form-submit-obfuscated').click();
    }

    onCheckValidation(event): void {
        this.errorMessage = null;

        if (this.form.valid) {
            const captured = this.one.value + this.two.value + this.three.value + this.four.value;
            this.onValid.emit(captured);
        }
    }

    setErrorMessage(message:string) {
        this.errorMessage = message;

        if (message) {
            this.one.setValue('');
            this.two.setValue('');
            this.three.setValue('');
            this.four.setValue('');
        }
    }
}
