import {Request} from "../../../../../../../urdad/services/models/request.model";
import {Account} from "../../../../../models/account.model";

export class FinaliseMaintainUserAccountRequest extends Request {

    constructor() {
        super();
    }

    public account: Account;
}
