import { ContactNumber } from "../../../entity/models/contact-number.model";
import { EmailAddress } from "../../../entity/models/email-address.model";

export class SnapshotLegalEntity {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.SnapshotLegalEntity";
    }

    public tpiIdentifier: string
    public countryOfIssue: string
    public passportNumber: string
    public businessRegistrationNumber: string
    public nationalIdentityNumber: string
    public name: string
    public surname: string
    public contactNumber: string
    public emailAddress: string
    public residentialAddressLineOne: string
    public residentialAddressLineTwo: string
    public residentialAddressLineThree: string
    public residentialAddressCity: string
    public residentialAddressCode: string
    public residentialCountryDescription: string
    public postalAddressLineOne: string
    public postalAddressLineTwo: string
    public postalAddressLineThree: string
    public postalAddressCity: string
    public postalAddressCode: string
    public postalCountryDescription: string

}
