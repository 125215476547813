import { Criteria } from "../../../criteria/models/criteria.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class SubscriptionUsedByLegalEntityCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionUsedByLegalEntityCriteria";
    }
    public tpiIdentifier: TpiIdentifier;
}
