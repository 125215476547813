import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, ProvideLegalEntityInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class BackOfficeLegalEntityInformationProviderService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideBackOfficeLegalEntityInformation(provideLegalEntityInformationRequest: ProvideLegalEntityInformationRequest) {
        const providePublicLegalEntityInformationURI = this.runtimeConfigurationService.apiBaseUrl + '/entity/backofficelegalentityinformationprovider/providelegalentityinformation';

        const jsonProvidePublicLegalEntityInformationRequest = this.marshallerService.marshallObjectToJSON(provideLegalEntityInformationRequest);

        return this.httpClient.post<any>(providePublicLegalEntityInformationURI, jsonProvidePublicLegalEntityInformationRequest, this.options).toPromise();
    }
}
