import { Request } from "../../../../../../../urdad/services/models/request.model";
import { CountryStartDateConfiguration } from "../../../../../../insurance/setup/models/country-start-date-configuration.model";
import { CheckoutDatedItemGrouping } from "../../../../../models/checkout-dated-item-grouping.model";

export class CaptureStartDateGroupingRequest extends Request{
    constructor(){
        super();
    }

    public countryStartDateConfiguration : CountryStartDateConfiguration;
    public checkoutDateItemGrouping : CheckoutDatedItemGrouping[] = [];
}