import { Criteria } from "../../../criteria/models/criteria.model";

export class ServiceGroupNameTypeCriteria extends Criteria {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.ServiceGroupNameTypeCriteria";
    }

    public name: string;
    public type: string;

}