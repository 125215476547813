import {Request} from "../../../../../../../../urdad/services/models/request.model"
import {ScannedDocument} from "../../../../../../../../media/scanneddocument/models/scanned-document.model"
import {
  CountryRegistrationConfigurationInformation
} from '../../../../../../../../retail/insurance/setup/models/country-registration-configuration-information.model'
import { LegalEntityDetails } from "../../../../../../../../entity/models/legal-entity-details.model";

export class FinaliseCheckoutInformationRequest extends Request {

  constructor(){
    super();
  }
  public scannedDocument: ScannedDocument[] = [];
  public countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation
  public countryRegistrationConfigurationInformationProvided: boolean
  public countryRegistrationConfigurationInformationCaptured: boolean
  public legalEntityDetails: LegalEntityDetails;
}
