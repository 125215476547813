import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    InvoiceDateLegalEntityCriteria,
    PdfDocument,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    ProvideSupplierInvoiceSummaryRequest,
    ProvideSupplierInvoiceSummaryResponse,
    RenderedDocumentIdCriteria,
    RenderedDocumentType,
    SupplierInvoiceSummary,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ActiveToast } from 'ngx-toastr';

import { environment } from '../../../../../environments/environment';
import {
    RenderedConceptualDocumentProviderService,
} from '../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service';
import {
    SupplierInvoiceInformationProviderService,
} from '../../../../http.services/retail/invoice/supplier-invoice-information-provider/supplier-invoice-information-provider.service';
import { AuthenticationService } from '../../../../app.services/managers/authentication/authentication.service';
import { FormComponent } from '../../../shared/form/form.component';
import { Utils } from '../../../shared/Utils/Utils';
import { PrintService, PDFPrintModel, PDFDisplayModel } from '../../../../app.services/common/printing/print.service';

declare const jQuery: any;
@Component({
    selector: 'app-supplier-invoice-list',
    templateUrl: './supplier-invoices.component.html',
    styleUrls: ['./supplier-invoices.component.scss']
})
export class SupplierInvoicesComponent extends FormComponent implements OnInit {

    rows: SupplierInvoiceSummary[] = [];
    columns = [
        { number: 'number'},
        { dateFrom: 'dateFrom' },
        { dateTo: 'dateTo' },
        { totalTransactionAmount: 'totalTransactionAmount' },
        { totalThirdPartyFee: 'totalThirdPartyFee' },
        { totalAdminFee: 'totalAdminFee' },
        { totalPaidOut: 'totalPaidOut' }
    ];

    totalElements = 0;
    pageNumber = 0;
    pageSize = 8;
    selectedStatus = [];
    numberOfPages: number;

    activeToast: ActiveToast<any>;
    temp: SupplierInvoiceSummary[] = [];

    from;
    to;

    previewDocument = false;
    documentToPreview: any;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private router: Router,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private supplierInvoiceInformationProviderService: SupplierInvoiceInformationProviderService,
                private printService: PrintService) {
        super(translate, translateParser);

        this.temp = this.rows;
        this.from = moment().startOf('month').startOf('day');
        this.to = moment();
    }

    ngOnInit() {
        this.provideInvoices();
    }

    provideInvoices(): void {

        this.rows = [];

        const criteria = new InvoiceDateLegalEntityCriteria();
        criteria.dateFrom = this.from.startOf('day').format(environment.formatDateServer) as Date;
        criteria.dateTo = this.to.endOf('day').format(environment.formatDateServer) as Date;
        criteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

        const provideSupplierInvoiceSummaryRequest = new ProvideSupplierInvoiceSummaryRequest();
        provideSupplierInvoiceSummaryRequest.batchNumber = this.pageNumber + 1;
        provideSupplierInvoiceSummaryRequest.batchSize = this.pageSize;
        provideSupplierInvoiceSummaryRequest.criteria = criteria;

        this.startLoad();
        this.supplierInvoiceInformationProviderService.provideSupplierInvoiceSummary(provideSupplierInvoiceSummaryRequest).subscribe((data) => {
            this.stopLoad();

            const response: ProvideSupplierInvoiceSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideSupplierInvoiceSummaryResponse);

            this.rows = response.supplierInvoiceSummaries;

            this.temp = this.rows;
            this.rows = [...this.rows];

            this.totalElements = response.totalNumberOfResults;

        });

    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideInvoices();
    }

    onStatus(status): boolean {
        if (this.selectedStatus.indexOf(status) > -1) {
            this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        } else {
            this.selectedStatus.push(status);
        }
        this.provideInvoices();
        return false;
    }

    getProvideInvoicesOnClick() {
        this.pageNumber = 0;
        this.provideInvoices();
    }

    onSelectionChanged() {
        this.provideInvoices();
    }

    isStatusSelected(status): boolean {
        return this.selectedStatus.indexOf(status) > -1;
    }

    onSelect(row) {
        // DO NOTHING FOR NOW
    }

    onPreview(event, supplierInvoice: SupplierInvoiceSummary): boolean {

        if (event) {
            event.stopPropagation();
        }

        this.startLoad();
        this.getRenderedDocument(supplierInvoice, (pdfDocument) => {
            this.stopLoad();
            if(pdfDocument.type === RenderedDocumentType.SUPPLIER_INVOICE){
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, () => {});
            });
        });

        return false;

    }

    getRenderedDocument(supplierInvoice: SupplierInvoiceSummary, callback): void {

        const renderedDocumentIdCriteria = new RenderedDocumentIdCriteria();
        renderedDocumentIdCriteria.documentId = supplierInvoice.number;
        renderedDocumentIdCriteria.userID = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentIdCriteria;

        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback((provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument));
            } else {
                callback({});
            }

        });

    }

    onPrintPreview(): void {
        this.displayPDF(this.documentToPreview, () => {
            setTimeout(() => {
                this.printDocument();
            }, 100);
        });
    }

    displayPDF(base64, complete) {
        const canvasId = `#canvas`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numberOfPages = numPages;
        });
    }

    printDocument(): void {
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId: 'canvas',
            features: 'top=0,height=1024px,width=800px',
            openBlank: false
        };
        const printWin = this.printService.printDocument(pdfPrintData);

        setTimeout(() => {
            printWin.print();
        }, 1000);

    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPreview = null;

    }

}
