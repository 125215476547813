import {Criteria} from '../../criteria/models/criteria.model';
import {CountryIdentifier} from '../../location/geographic/identification/models/country-identifier.model';
import {LegalEntityIdentifier} from '../identification/models/legal-entity-identifier.model';

export class LegalEntityProviderCriteria extends Criteria {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.LegalEntityProviderCriteria";
    }

    countryIdentifier: CountryIdentifier;
    type: string;
    legalEntityIdentifier: LegalEntityIdentifier;
}
