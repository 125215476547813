import {Criteria} from "../../criteria/models/criteria.model";
import { VehicleIdentifier } from "../vehicle_api";
import { CountryIdentifier } from "../../location/geographic/identification/models/country-identifier.model";

export class VehicleProviderCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.VehicleProviderCriteria";
    }

    public type: string;
    public vehicleIdentifier: VehicleIdentifier;
    public countryIdentifier: CountryIdentifier;
}