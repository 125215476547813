import {Response} from '../../../../../../../urdad/services/models/response.model';
import {MotorInsurancePolicyItem} from '../../../../../models/motor-insurance-policy-item.model';
import {SnapshotLegalEntity} from '../../../../../models/snapshot-legal-entity.model';


export class ProvideMotorInsuranceVehiclePolicyResponse extends Response{

    constructor(){
        super();
    }

    public expiryDate: Date;
    public financialRegistrationNumber : string;
    public policyNumber : string;
    public underwriterName : string;
    public motorInsurancePolicyItems: MotorInsurancePolicyItem[];
    public snapshotLegalEntity: SnapshotLegalEntity;

}
