import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Criteria, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationLocalService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    authenticatePublicUserFromLocalStorage(criteria: Criteria) {
        const authenticatorUri = this.runtimeConfigurationService.apiBaseUrl + '/security/authentication/authenticatepublicfromlocalstorage';

        const jsonAuthenticationRequest = this.marshallerService.marshallObjectToJSON(criteria);

        return this.httpClient.post<any>(authenticatorUri, jsonAuthenticationRequest, this.options).toPromise();
    }

    authenticateBackOfficeUserFromLocalStorage(criteria: Criteria) {
        const authenticatorUri = this.runtimeConfigurationService.apiBaseUrl + '/security/authentication/authenticatebackofficefromlocalstorage';

        const jsonAuthenticationRequest = this.marshallerService.marshallObjectToJSON(criteria);

        return this.httpClient.post<any>(authenticatorUri, jsonAuthenticationRequest, this.options).toPromise();
    }
}
