import { LegalEntityIdentifier } from '../../../../../../entity/identification/models/legal-entity-identifier.model';
import { Request } from '../../../../../../urdad/services/models/request.model';

export class ResendVerificationLinkRequest extends Request {

    constructor(){
        super()
    }

    public legalEntityIdentifier: LegalEntityIdentifier;

}