import {Response} from "../../../../../../../urdad/services/models/response.model";
import {Underwriter} from "../../../../../models/underwriter.model";

export class ProvideUnderwriterInformationResponse extends Response{
    constructor(){
        super();
    }

    public underwriters : Underwriter [] = [];
}
