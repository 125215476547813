import { Credentials } from "../../credentials/models/credentials.model";
import { NaturalPersonIdentifier } from "../../../entity/identification/models/natural-person-identifier.model";

export class NaturalPersonIdentifierCredential extends Credentials {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.onetimepin.NaturalPersonIdentifierCredential";
    }

    public naturalPersonIdentifier: NaturalPersonIdentifier;
}