import {Response} from '../../../../../../urdad/services/models/response.model';
import {Agent} from '../../../../../models/agent.model';

export class ProvideAgentInformationResponse extends Response {
    constructor() {
        super();
    }

    agents: Agent[] = [];
}
