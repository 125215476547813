import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";
import { FinancialAccountIdentifier } from "../../financialaccount/identification/models/financial-account-identifier.model";
import { FinancialAccount } from "../../financialaccount/models/financial-account.model";
import { AccountStatementPeriod } from "./account-statement-period.model";

export class AccountReportLegalEntityDateCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.AccountReportLegalEntityDateCriteria";
    }

    public period: AccountStatementPeriod;
    public dateFrom: Date;
    public dateTo: Date;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public financialAccountIdentifier: FinancialAccountIdentifier;
}