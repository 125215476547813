import { Excess } from "../../insurance/models/excess.model";
import { PriceSchedule } from "../../insurance/models/price-schedule.model";
import { ProductIdentifier } from "../../products/identification/models/product-identifier.model";
import { MotorInsuranceLimitDetails } from "./motor-insurance-limit-details.model";
import { MotorInsuranceVehicleDetails } from "./motor-insurance-vehicle-details.model";

export class MotorInsuranceDetails {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.MotorInsuranceDetails";
    } 

    public startDate: Date;
    public expiryDate: Date;
    public productIdentifier: ProductIdentifier;
    public excesses: Excess[] = [];
    public selectedPriceSchedule: PriceSchedule;
    public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[] = [];
    public motorInsuranceVehicleDetails: MotorInsuranceVehicleDetails[] = [];
}