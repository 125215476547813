import {Response} from '../../../../../../urdad/services/models/response.model';
import {LegalEntity} from '../../../../../models/legal-entity.model';

export class QueryLegalEntityInformationResponse extends Response {
    constructor() {
        super();
    }

    legalEntities: LegalEntity[] = [];
}
