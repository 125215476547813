import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Criteria} from '../../../../../../../criteria/models/criteria.model';
import { UnderwriterIdentifier } from '../../../../../identification/models/underwriter-identifier.model';

export class ProvideMotorInsuranceVehiclePolicyRequest extends Request{

    constructor(){
        super();
    }

    public criteria: Criteria;

}
