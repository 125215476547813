import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    MarshallerService,
    ProvideSecuredLegalEntityObscuredContactNumberRequest,
    ValidateSecuredLegalEntityObscuredContactNumberRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SecureLegalEntityInformationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideSecuredLegalEntityObscuredContactNumber(provideSecuredLegalEntityObscuredContactNumberRequest: ProvideSecuredLegalEntityObscuredContactNumberRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/entity/securedlegalentityinformationproviderwrapper/providesecuredlegalentityobscuredcontactnumber';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSecuredLegalEntityObscuredContactNumberRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    validateSecuredLegalEntityObscuredContactNumber(validateSecuredLegalEntityObscuredContactNumberRequest: ValidateSecuredLegalEntityObscuredContactNumberRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/entity/securedlegalentityinformationproviderwrapper/validatesecuredlegalentityobscuredcontactnumber';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(validateSecuredLegalEntityObscuredContactNumberRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }
}
