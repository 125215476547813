import {Injectable} from '@angular/core';
import {
    Account,
    CreateIdentifierRequest,
    CreateIdentifierResponse,
    FinaliseCreateUserAccountRequest,
    FinaliseCreateUserAccountResponse,
    IdentifierType,
    InitialiseCreateUserAccountRequest,
    InitialiseCreateUserAccountResponse,
    JudicialPersonIdentifier,
    NaturalPerson,
    ServiceGroup,
    ServiceGroupNameTypeIdentifier,
    TpiIdentifier,
    UnMarshallerService
} from '@magnabc/tpi';
import {UserAccountInitialisationManagerService} from '../user-account-initialisation-manager/user-account-initialisation-manager.service';
import {UserAccountFinalisationManagerService} from '../user-account-finalisation-manager/user-account-finalisation-manager.service';
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun';
import {IdentifierManagerService} from "../../../identification/identifier-manager/identifier-manager.service";

@Injectable({
    providedIn: 'root'
})
export class UserAccountManagerService {

    constructor(private userAccountInitialisationProvider: UserAccountInitialisationManagerService,
                private userAccountFinalisationProvider: UserAccountFinalisationManagerService,
                private identifierManagerService: IdentifierManagerService,
                private unMarshallerService: UnMarshallerService) {
    }

    personsToAdd: NaturalPerson[] = [];
    judicialPerson: TpiIdentifier;
    serviceGroupsSelected: ServiceGroupNameTypeIdentifier[] = [];
    branchCode: string;

    initialiseCreateAccount(judicialPersonIdentifier: JudicialPersonIdentifier) {
        return new Promise<ServiceGroup[]>((resolve, reject) => {
            const request = new InitialiseCreateUserAccountRequest();
            request.judicialPersonIdentifier = judicialPersonIdentifier;

            this.userAccountInitialisationProvider.initialiseCreateUserAccount(request).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, InitialiseCreateUserAccountResponse) as InitialiseCreateUserAccountResponse);

                    resolve(response.serviceGroups);
                    return;
                } else {
                    resolve([]);
                    return;
                }
            }).catch(err => {
                RaygunErrorHandler.sendError(err);

                if (err.error) {
                    reject(err.error);
                    return;
                } else {
                    console.error('Unable to initialise create user account ', err);
                    reject('Unable to initialise create user account');
                    return;
                }
            });
        });
    }

    addNaturalPerson(naturalPerson: NaturalPerson) {
        this.personsToAdd.push(naturalPerson);
    }

    setBranch(branchCode: string): void{
        this.branchCode = branchCode;
    }

    addServiceGroup(serviceGroup: ServiceGroup) {
        const identifier = new ServiceGroupNameTypeIdentifier();
        identifier.name = serviceGroup.name;
        identifier.type = serviceGroup.type;
        this.serviceGroupsSelected.push(identifier);
    }

    clearServiceGroups() {
        this.serviceGroupsSelected = [];
    }

    setOwnedBy(tpiIdentifier: TpiIdentifier) {
        this.judicialPerson = tpiIdentifier;
    }

    finaliseCreateAccount() {

        return new Promise<Account[]>((resolve, reject) => {

            const createIdentifierRequest = new CreateIdentifierRequest();
            createIdentifierRequest.quantity = this.personsToAdd.length;
            createIdentifierRequest.type = IdentifierType.TPI_IDENTIFIER;

            this.identifierManagerService.createIdentifier(createIdentifierRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {

                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateIdentifierResponse) as CreateIdentifierResponse);
                    let count = 0;
                    for (const naturalPerson of this.personsToAdd) {
                        const tpiIdentifier = new TpiIdentifier();
                        tpiIdentifier.tpiIdentifier = response.identifier[count];
                        naturalPerson.legalEntityIdentifiers.push(tpiIdentifier);
                        count++;
                    }

                    const finaliseRequest = new FinaliseCreateUserAccountRequest();
                    finaliseRequest.naturalPersons = this.personsToAdd;
                    finaliseRequest.ownedBy = this.judicialPerson;
                    finaliseRequest.serviceGroups = this.serviceGroupsSelected;
                    finaliseRequest.branch = this.branchCode;

                    this.userAccountFinalisationProvider.finaliseCreateUserAccount(finaliseRequest).then(httpResponse => {

                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, FinaliseCreateUserAccountResponse) as FinaliseCreateUserAccountResponse);
                            resolve(response.accounts);
                            return;
                        }

                        resolve([]);
                        return;
                    }).catch(err => {
                        RaygunErrorHandler.sendError(err);
                        console.log('An error occurred while finalising the create user account.', err);
                        reject('An error occurred while finalising the create user account.');
                        return;
                    });

                } else {
                    RaygunErrorHandler.sendError('An error occurred while generating the TpiIdentifiers.');
                    console.log('An error occurred while generating the TpiIdentifiers.');
                    reject('An error occurred while generating the TpiIdentifiers.');
                    return;
                }

            });

        });

    }

}
