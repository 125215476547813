import {Request} from '../../../../../../urdad/services/models/request.model';

export class ProvideSessionTrackingRequest extends Request {

    constructor() {
        super();
    }

    public accountNumber: string;
    public sessionTrackingId: string;
}