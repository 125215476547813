import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
    CreatePaymentProfileRequest,
    MarshallerService,
    ProcessCardPaymentRequest,
    ProcessTokenPaymentRequest,
    RemovePaymentOptionRequest
} from '@magnabc/tpi'
import * as moment from "moment"
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class DirectPayOnlinePaymentGatewayManagerService {

    basePath = '/retail/payment/dpo/directpayonlinepaymentgatewaymanager';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    createPaymentProfile(createPaymentProfileRequest: CreatePaymentProfileRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/createpaymentprofile';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(createPaymentProfileRequest);

        return this.http.post(path, jsonRequest, this.options).toPromise();
    }

    processCardPayment(processCardPaymentRequest: ProcessCardPaymentRequest): Promise<any>  {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/processcardpayment';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(processCardPaymentRequest);

        return this.http.post(path, jsonRequest, this.options).toPromise();
    }

    processTokenPayment(processTokenPaymentRequest: ProcessTokenPaymentRequest): Promise<any>  {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/processtokenpayment';

        const date = moment(processTokenPaymentRequest.cardPaymentOption.cardExpiryDate).format('YYYY-MM-DD');
        console.log(date);
        const jsonRequest = this.marshallerService.marshallObjectToJSON(processTokenPaymentRequest);
        jsonRequest['cardPaymentOption']['cardExpiryDate'] = date;

        return this.http.post(path, jsonRequest, this.options).toPromise();
    }

    removePaymentOption(removePaymentOptionRequest: RemovePaymentOptionRequest): Promise<any>  {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/removepaymentoption';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(removePaymentOptionRequest);

        return this.http.post(path, jsonRequest, this.options).toPromise();
    }
}
