import {Component, OnDestroy, OnInit} from '@angular/core'
import {StatusService} from '../../app.services/common/status/status.service'
import {ScheduledMaintenance} from "@magnabc/tpi"
import {Router} from '@angular/router'
import {Subscription} from 'rxjs'
import {Title} from '@angular/platform-browser'
import {RuntimeConfigurationService} from '../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance-page.component.html',
    styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent implements OnInit, OnDestroy {

    activeScheduleMaintenance: ScheduledMaintenance;
    listener: Subscription;

    constructor(public statusService: StatusService,
                private title: Title,
                private router: Router,
                public runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Maintenance - ${runtimeConfigurationService.title}`);
    }

    ngOnInit() {

        this.listener = this.statusService.subscribeActiveMaintenances().subscribe((scheduledMaintenances) => {
            if (scheduledMaintenances.length > 0) {
                this.activeScheduleMaintenance = scheduledMaintenances[0];
            } else {
                this.router.navigate(['/']);
            }
        });
        this.statusService.notifyActive();

    }

    ngOnDestroy(): void {
        if (this.listener) {
            this.listener.unsubscribe();
        }
    }

}
