import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {FinaliseCancelOrderRequest, MarshallerService, Order} from '@magnabc/tpi'
import { Observable } from 'rxjs';
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class OrderFinalisationManagerService {

  basePath = '/retail/checkout/orderfinalisationmanager';
  order: Order;
  orders: Order[] = [];

  constructor(private http: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  finaliseCancelOrder(finaliseCancelOrderRequest: FinaliseCancelOrderRequest): Observable<any> {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecancelorder';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCancelOrderRequest);
      return this.http.post<any>(path, jsonRequest, this.options);
  }

}
