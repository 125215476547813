import {PaymentProviderIdentifier} from "./payment-provider-identifier.model";

export class AgentPaymentProviderIdentifier extends PaymentProviderIdentifier {

    name: string;
    agentIdentifier: string;

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.financial.identification.AgentPaymentProviderIdentifier";
    }
}
