import {Component, OnInit, ViewChild} from '@angular/core'
import {WhiteLabelService} from "../../app.services/managers/white-label/white-label.service"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {FormComponent} from "../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {environment} from "../../../environments/environment"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {ToastComponent} from "../shared/toast/toast.component"
import {
    ProvideBrokerInformationRequest,
    ProvideUnderwriterInformationResponse,
    TpiIdentifier,
    Underwriter,
    UnderwriterLegalEntityCriteria,
    UnMarshallerService
} from "@magnabc/tpi"
import {AuthenticationService} from "../../app.services/managers/authentication/authentication.service"
import {
    UnderwriterInformationProviderService
} from '../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {Title} from '@angular/platform-browser'
import {RuntimeConfigurationService} from '../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
    selector: 'app-white-label',
    templateUrl: './white-label.component.html',
    styleUrls: ['./white-label.component.scss']
})
export class WhiteLabelComponent extends FormComponent implements OnInit {

    selectedUnderwriter: Underwriter;

    formGroup: UntypedFormGroup;

    title: UntypedFormControl;
    javascript: UntypedFormControl;
    iframeHtml: UntypedFormControl;
    url: UntypedFormControl;

    activeToast: ActiveToast<any>;
    @ViewChild('form') form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private titleMeta: Title,
                public whiteLabelService: WhiteLabelService,
                public underwriterInformationProviderService: UnderwriterInformationProviderService,
                public authenticationService: AuthenticationService,
                private toastr: ErrorToastService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        titleMeta.setTitle(`White Label - Console - ${runtimeConfigurationService.title}`);

    }

    ngOnInit() {

        this.title = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]);
        this.javascript = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]);
        this.iframeHtml = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]);
        this.url = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]);

        this.formGroup = new UntypedFormGroup({
            title: this.title,
            javascript: this.javascript,
            iframeHtml: this.iframeHtml,
            url: this.url
        });

        this.title.disable();

        this.getUnderwriter();

    }

    getUnderwriter() {

        let legalEntityIdentifier = null;
        for (const identifier of this.authenticationService.getLinkedToLegalEntityIdentifiers()) {
            if (identifier['@class'] === new TpiIdentifier()['@class']) {
                legalEntityIdentifier = identifier;
            }
        }

        const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
        underwriterLegalEntityCriteria.legalEntityIdentifiers = [legalEntityIdentifier];
        const provideUnderwriterInformationRequest = new ProvideBrokerInformationRequest();
        provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;

        this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
            if(response && response.body){
                const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                if (provideUnderwriterInformationResponse.underwriters.length > 0) {
                    this.selectedUnderwriter = provideUnderwriterInformationResponse.underwriters[0];
                    this.onUnderwriter();
                }
            }
        });

    }

    onUnderwriter(): void {
        this.title.setValue(this.selectedUnderwriter.name);
        this.url.setValue(`${this.runtimeConfigurationService.hostUrl}/#/?underwriter=${this.selectedUnderwriter.hashId}`);
        this.iframeHtml.setValue(`<iframe src="${this.runtimeConfigurationService.hostUrl}/#/?underwriter=${this.selectedUnderwriter.hashId}" width="100%" height="800"></iframe>`);
        this.javascript.setValue(`<script>function showZI() {var height  = window.innerHeight - 30;var container = document.createElement('div');container.id = 'zi-container';container.setAttribute('style', 'position: fixed;left: 0;right: 0;top: 0;bottom: 0;z-index: 999999;background: rgba(0,0,0,0.5);padding: 15px');var inner = document.createElement('div');inner.setAttribute('style', 'background: #fff;border-radius: 15px;overflow: hidden;');var button = document.createElement('button');button.type = 'button';button.setAttribute('style', 'position: absolute;right: 5px;top: 5px;background: #fff;border-radius: 50%;width: 35px;height: 35px');button.onclick = function() {document.getElementById('zi-container').innerHTML = '';};button.innerHTML = 'X';var iframe = document.createElement('iframe');iframe.src = '${this.runtimeConfigurationService.hostUrl}/#/?underwriter=${this.selectedUnderwriter.hashId}';iframe.width = '100%';iframe.height = height + '';inner.appendChild(button);inner.appendChild(iframe);container.appendChild(inner);document.getElementsByTagName('body')[0].appendChild(container);}</script>`);
    }

    onCopyToClipboard(elementId: string): boolean {
        jQuery(`#${elementId}`).select();
        document.execCommand('copy');
        this.toast();
        return false;
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

        }

    }

    toast() {

        this.translate.get('TOAST.COPIED_TO_CLIPBOARD').subscribe((res: any) => {
            this.activeToast = this.toastr.success(res.MESSAGE, res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }

            });
        });

    }

}
