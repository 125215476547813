import {Criteria} from "../../../criteria/criteria_api";

export class InsurancePolicyNumberVehicleCriteria extends Criteria {

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyNumberVehicleCriteria";
    }

    public tpiVehicleIdentifier: string;
    public policyNumber: string;
    public date: Date;
}
