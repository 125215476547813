import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {
    AuthenticationService
} from '../../../../../app.services/managers/authentication/authentication.service'
import {Title} from '@angular/platform-browser'
import {
    FinaliseYCAssessmentRequest,
    FinaliseYCAssessmentResponse, InsurancePolicyVehicleCriteria, MotorInsurancePolicy,
    MotorInsurancePolicyItem, OrderItemNumberIdentifier,
    OrderNumberIdentifier,
    OrderOrderItemNumberIdentifier, ProvideInsurancePoliciesRequest, ProvideInsurancePoliciesResponse,
    ProvideMotorInsuranceVehiclePolicyRequest,
    ProvideMotorInsuranceVehiclePolicyResponse,
    UnMarshallerService,
    YCInsurancePolicyItemCertificateCriteria
} from '@magnabc/tpi'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    InsurancePolicyProviderService
} from '../../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service'
import {
    YCFinalisationManagerService
} from '../../../../../http.services/retail/insurance/yellow-card-insurance/yc-finalisation-manager/yc-finalisation-manager.service'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {AgentManagerService} from '../../../../../app.services/managers/agent-manager/agent-manager.service'
import {Utils} from '../../../../../view.components/shared/Utils/Utils'
import {PolicyAction} from '../../third-party-insurance/query-policy-page/policy-action-enum'
import * as moment from 'moment/moment'
import {environment} from '../../../../../../environments/environment'
import {
    VerhoeffChecksumService
} from '../../../../../view.components/retail/insurance/certificate/issue-certificates/service/verhoeff-checkssum.service'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {ToastrService} from 'ngx-toastr'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-yc-assessment-manager-page',
    templateUrl: './yc-assessment-manager-page.component.html',
    styleUrls: ['./yc-assessment-manager-page.component.scss']
})
export class YcAssessmentManagerPageComponent extends FormComponent implements OnInit {

    targetPolicies: MotorInsurancePolicy[]
    columns = [
        { orderItemNumber: 'Order' },
        { viewPolicy: 'View Policy' },
        { policyNumber: 'Policy Number' },
        { certificateName: 'Certificate Number' },
        { action: 'Action' },
    ]
    rows: MotorInsurancePolicyItem[] = []
    showMessage = false
    valueMap: {[id: string]: {policy: string, certificate: string}} = {}
    loading = false
    alphaNumericRegex = /^[a-zA-Z0-9]+$/;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private unMarshallerService: UnMarshallerService,
                private toastr: ErrorToastService,
                private authenticationService: AuthenticationService,
                private insurancePolicyProviderService: InsurancePolicyProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService,
                private verhoeffChecksumService: VerhoeffChecksumService,
                private agentService: AgentManagerService,
                private ycFinalisationManagerService: YCFinalisationManagerService) {
        super(translate, translateParser);
        title.setTitle(`Yellow Card Assessment - Console - ${runtimeConfigurationService.title}`);
    }

    ngOnInit(): void {
        this.provideMotorInsuranceVehiclePolicies()
    }

    provideMotorInsuranceVehiclePolicies() {

        this.rows = []

        const provideMotorInsuranceVehiclePolicyRequest = new ProvideMotorInsuranceVehiclePolicyRequest()
        provideMotorInsuranceVehiclePolicyRequest.criteria = new YCInsurancePolicyItemCertificateCriteria()
        this.loading = true
        this.insurancePolicyProviderService.provideMotorInsuranceVehiclePolicy(provideMotorInsuranceVehiclePolicyRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideMotorInsuranceVehiclePolicyResponse) as ProvideMotorInsuranceVehiclePolicyResponse)
                this.rows = []
                for (const item of response.motorInsurancePolicyItems) {
                    if (!this.valueMap[this.getOrderItemNumber(item)]) {
                        this.valueMap[this.getOrderItemNumber(item)] = {policy: null, certificate: null}
                    }
                    this.rows.push(item)
                }
            }
            this.loading = false
        })

    }

    getOrderItemNumber(insurancePolicyItem: MotorInsurancePolicyItem) {
        return (((insurancePolicyItem as MotorInsurancePolicyItem).orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier as OrderItemNumberIdentifier).orderItemNumber
    }

    getOrderNumber(insurancePolicyItem: MotorInsurancePolicyItem) {
        var orderItemNumber = ((insurancePolicyItem as MotorInsurancePolicyItem).orderIdentifier as OrderOrderItemNumberIdentifier).orderNumber
    }

    finaliseYCAssessment(orderItemNumber: string, ycPolicyNumber: string, certificateNumber: string) {

        const finaliseYCAssessmentRequest = new FinaliseYCAssessmentRequest()
        if (this.agentService.agentIdentifier) {
            finaliseYCAssessmentRequest.agentIdentifier = this.agentService.agentIdentifier;
        } else {
            finaliseYCAssessmentRequest.agentIdentifier = this.agentService.defautlAgent
        }
        finaliseYCAssessmentRequest.userId = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;
        finaliseYCAssessmentRequest.certificateNumber = certificateNumber
        finaliseYCAssessmentRequest.orderItemNumber = orderItemNumber
        finaliseYCAssessmentRequest.ycPolicyNumber = ycPolicyNumber

        this.loading = true
        this.ycFinalisationManagerService.finaliseYCAssessment(finaliseYCAssessmentRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, FinaliseYCAssessmentResponse) as FinaliseYCAssessmentResponse)
                this.toastr.success( 'Policy was successfully updated', 'Policy Updated', {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
                this.provideMotorInsuranceVehiclePolicies()
            } else {
                this.toastr.success( 'Please contact support','Server Error', {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            }
            this.loading = false
        }).catch((error) => {
            this.toastr.success(error.message, 'Server Error', {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.loading = false
        })

    }

    providePolicy(motorInsurancePolicyItem: MotorInsurancePolicyItem) {

        const tpiVehicleIdentifier = motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier
        const orderItemNumber = this.getOrderItemNumber(motorInsurancePolicyItem)

        const provideInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
        const insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
        insurancePolicyVehicleCriteria.tpiVehicleIdentifier = tpiVehicleIdentifier;
        insurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
        provideInsurancePoliciesRequest.criteria = insurancePolicyVehicleCriteria;
        provideInsurancePoliciesRequest.batchNumber = -1;
        provideInsurancePoliciesRequest.batchSize = -1;

        this.insurancePolicyProviderService.providePolicy(provideInsurancePoliciesRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);
                this.targetPolicies = [];
                for (const policy of response.insurancePolicies) {
                    (policy as MotorInsurancePolicy).expiryDate = this.addExtraDay((policy as MotorInsurancePolicy).expiryDate);
                    for (const item of (policy as MotorInsurancePolicy).insurancePolicyItems) {
                        (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate = moment(this.addExtraDay((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate)).format(environment.formatDateServer) as any;
                        if ((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === tpiVehicleIdentifier && this.getOrderItemNumber((item as MotorInsurancePolicyItem)) === orderItemNumber) {
                            if (this.targetPolicies.filter(item => item.number === policy.number).length === 0) {
                                let insurancePolicy = policy as MotorInsurancePolicy;
                                insurancePolicy.insurancePolicyItems = insurancePolicy.insurancePolicyItems.filter((vehicleItem) => (vehicleItem as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === tpiVehicleIdentifier)
                                this.targetPolicies.push(insurancePolicy)
                            }
                        }
                    }
                }
            }
        })

        return false
    }

    addExtraDay(date) {
        return moment(date).add(1, 'day').startOf('day').toDate()
    }

    onSubmit(row) {
        const orderItemNumber = this.getOrderItemNumber(row)
        if (this.validateInput(this.valueMap[orderItemNumber].policy) && this.validateInput(this.valueMap[orderItemNumber].certificate)) {
            this.finaliseYCAssessment(orderItemNumber, this.valueMap[orderItemNumber].policy.toUpperCase(), this.valueMap[orderItemNumber].certificate.toUpperCase())
        } else {
            this.toastr.success( 'Please make sure both the policy number and certificate number is valid.', 'Validation', {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
        }
        return false
    }

    validateInput(number: string) {
        if (!number) {
            return false
        }
        if (number.length < 1 || number.length > 9) {
            return false
        }
        if (!this.alphaNumericRegex.test(number)) {
            return false
        }
        return true
    }

    validateAlphaNum(event) {
        const pattern = /^[a-zA-Z0-9]*$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    onClosePolicyView() {
        this.targetPolicies = null
    }

}
