import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import {
  ServiceGroupInformationProviderService
} from "../service-group-information-provider/service-group-information-provider.service"
import {
  SubscriptionInformationProviderService
} from "../subscription-information-provider/subscription-information-provider.service"
import {
  FinaliseCreateServiceGroupRequest,
  FinaliseUpdateServiceGroupRequest,
  MarshallerService,
  Service,
  ServiceGroup,
  Subscription,
  FinaliseDeleteServiceGroupRequest,
} from '@magnabc/tpi'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ServiceGroupFinalisationManagerService {

    basePath = '/security/authorisation/servicegroupfinalisationmanager';

    subscriptionListeners = [];
    activeSubscriptionListeners = [];

    serviceGroups: {[id: string]: ServiceGroup} = {};
    services: {[id: string]: Service} = {};

    subscriptions: Subscription[] = [];
    activeSubscriptions: Subscription[];
    invites: {[id: string]: Subscription[]} = {};
    inviteIds: string[] = [];
    setInitialActiveSubscription = true;

    constructor(private http: HttpClient,
                private securityService: AuthenticationService,
                private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {

    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCreateServiceGroup(finaliseCreateServiceGroupRequest: FinaliseCreateServiceGroupRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreateservicegroup';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateServiceGroupRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    finaliseUpdateServiceGroup(finaliseUpdateServiceGroupRequest: FinaliseUpdateServiceGroupRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdateservicegroup';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseUpdateServiceGroupRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    finaliseDeleteServiceGroup(finaliseDeleteServiceGroupRequest: FinaliseDeleteServiceGroupRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisedeleteservicegroup';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseDeleteServiceGroupRequest);
        return this.http.post<any>(path, jsonRequest, this.options);
    }
}
