import {PreconditionViolation} from "../../../../../../urdad/services/models/precondition-violation.model";

export class VehicleMakeAlreadyExistsException extends PreconditionViolation {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.makemodeltrim.VehicleMakeAlreadyExistsException";
    }

    public duplicate: string;
}
