import {LegalEntityIdentifier} from '../../../entity/identification/models/legal-entity-identifier.model';
import {UnderwriterIdentifier} from '../identification/models/underwriter-identifier.model';
import {CountryIdentifier} from '../../../location/geographic/identification/models/country-identifier.model';
import {Image} from '../../../media/models/image.model';

export class Broker {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.Broker"; 
    }

    id: string;
    name: string;
    financialRegistrationNumber: string;
    legalEntityIdentifier: LegalEntityIdentifier;
    underwriterIdentifiers: UnderwriterIdentifier[] = [];
    countryIdentifier: CountryIdentifier;
    image: Image;
}
