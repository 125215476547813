import { Criteria } from "../../../criteria/models/criteria.model";
import { OneTimeLinkContext } from "../../onetimelink/models/one-time-link-context.model";

export class TokenGenerateCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.token.TokenGenerateCriteria";
    }

    public oneTimeLinkContext: OneTimeLinkContext;
    public issueDate: Date;
    public oneTimeLinkIdentifier: string;
}