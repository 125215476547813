import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {FormComponent} from "../../../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {
    CountryRegistrationConfigurationInformation,
    RequiredJudicialPersonCountryRegistrationInformation,
    RequiredNaturalPersonCountryRegistrationInformation,
    ShownJudicialPersonCountryRegistrationInformation,
    ShownNaturalPersonCountryRegistrationInformation
} from "@magnabc/tpi"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"

declare const jQuery: any;
@Component({
    selector: 'app-source-of-funds',
    templateUrl: './source-of-funds.component.html',
    styleUrls: ['./source-of-funds.component.scss']
})
export class SourceOfFundsComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() show: ShownJudicialPersonCountryRegistrationInformation | ShownNaturalPersonCountryRegistrationInformation;
    @Input() required: RequiredJudicialPersonCountryRegistrationInformation | RequiredNaturalPersonCountryRegistrationInformation;
    @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    @Input() hideBorder: boolean;
    @Output() onSourceOfFunds = new EventEmitter<any>();
    @Output() onValid = new EventEmitter<void>();

    formGroup: UntypedFormGroup;
    bankName: UntypedFormControl;
    bankAccountNumber: UntypedFormControl;

    @ViewChild('form') form: FormGroupDirective;

    constructor(translateService: TranslateService, translateParser: TranslateParser) {
        super(translateService, translateParser);
    }

    ngOnInit() {

        this.bankName = new UntypedFormControl(this.countryRegistrationConfigurationInformation.bankName, [
            Validators.maxLength(30)
        ]);
        this.bankAccountNumber = new UntypedFormControl(this.countryRegistrationConfigurationInformation.bankAccountNumber, [
            Validators.maxLength(30)
        ]);

        if (this.required !== undefined) {
            if (this.required.bankName) {
                this.bankName.setValidators([
                    Validators.required,
                    Validators.maxLength(30)
                ]);
            }
            if (this.required.bankAccountNumber) {
                this.bankAccountNumber.setValidators([
                    Validators.required,
                    Validators.maxLength(30)
                ]);
            }
        }

        this.formGroup = new UntypedFormGroup({
            'bankName': this.bankName,
            'bankAccountNumber': this.bankAccountNumber
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    submit(): void {
        document.getElementById('form-submit-source-of-funds').click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            this.onSourceOfFunds.emit({bankName: this.bankName.value, bankAccountNumber: this.bankAccountNumber.value});
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    valid(): boolean {
        return this.form.valid;
    }

}
