import { Token } from "./token.model";

export class JsonWebToken extends Token {

    constructor() {
      super();
      this['@class'] = "za.co.magnabc.tpi.security.JsonWebToken";
    }

    public token: string;
}
