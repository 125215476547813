import { Criteria } from "../../../criteria/criteria_api";
import { PayoutMethodName } from "./payout-method-name.model";

export class SinglePayoutMethodCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.payout.SinglePayoutMethodCriteria";
    }

    public payoutMethodName : PayoutMethodName;
    public legalEntityIdentifier : string; //string is used until backend is updated to use a legalEntityIdentifier object
}