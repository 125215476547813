import {LegalEntityIdentifier} from './legal-entity-identifier.model';
import {TpiIdentifier} from './tpi-identifier.model';

export class LegalEntityDescriptionIdentifier extends LegalEntityIdentifier {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.identification.LegalEntityDescriptionIdentifier";
    }

    name: string;
    surname: string;
    tpiIdentifier: TpiIdentifier;
}
