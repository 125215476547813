import {Request} from '../../../../../../../../urdad/services/models/request.model'
import {InsuranceCartItem} from '../../../../../../models/insurance-cart-item.model'
import {OrderItem} from '../../../../../../../checkout/models/order-item.model'

export class CalculateRealTimeExtensionPriceRequest extends Request{

    constructor(){
        super();
    }

    public insuranceCartItem: InsuranceCartItem;
    public orderItem: OrderItem;
    public expiryDate: Date;
    public policyNumber: string;

}
