import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-account-statements-page',
    templateUrl: './account-statements-page.component.html',
    styleUrls: ['./account-statements-page.component.scss']
})
export class AccountStatementsPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Account Statements - Console - ${runtimeConfigurationService.title}`);
    }

}
