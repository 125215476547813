import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {ProvideInsuranceTypeRequest, MarshallerService} from '@magnabc/tpi'
import {Observable} from 'rxjs'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsuranceTypeProviderService{
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    provideInsuranceTypes(provideInsuranceTypeRequest: ProvideInsuranceTypeRequest): Observable<any> {
        const uri = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/setup/insurancetypeprovider/provideinsurancetype';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideInsuranceTypeRequest);
        return this.httpClient.post<unknown>(uri, jsonRequest, this.options);
    }
}
