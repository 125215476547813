import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {InitialiseCreateUserAccountRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class UserAccountInitialisationManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  initialiseCreateUserAccount(initialiseCreateUserAccountRequest: InitialiseCreateUserAccountRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/useraccountinitialisationmanager/initialisecreateuseraccount';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseCreateUserAccountRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }
}
