import { Request } from "../../../../../../../urdad/services/models/request.model";
import {JudicialPersonIdentifier} from '../../../../../../../entity/identification/models/judicial-person-identifier.model';

export class InitialiseCreateUserAccountRequest extends Request{
  constructor(){
    super();
  }

  judicialPersonIdentifier: JudicialPersonIdentifier;
}
