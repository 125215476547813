import { VehicleIdentifier } from "./vehicle-identifier.model";
import { PlateNumber } from "./plate-number.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";


export class VehicleDescriptionIdentifier extends VehicleIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.identification.VehicleDescriptionIdentifier";
    }

    public make:string;
    public model:string;
    public vehicleIdentifier:VehicleIdentifier;
    public plateNumbers:PlateNumber[] = [];
    public titleHolder:LegalEntityIdentifier;
    public owner:LegalEntityIdentifier;

}
