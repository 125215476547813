import {Response} from '../../../../../../../urdad/services/models/response.model';
import {RefundSummary} from '../../../../../models/refund-summary.model';

export class ProvideRefundSummaryResponse extends Response{
    constructor(){
        super();
    }

    public refundSummaries: RefundSummary[];
}
