import { Token } from "../token.model";
import { OneTimeLinkContext } from "../../onetimelink/models/one-time-link-context.model";
import { IdentifierTokenIdentifier } from "./identifier-token-identifier.model";

export class IdentifierToken extends Token {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.token.IdentifierToken";
    }

    public token: string;
    public expiryDate: Date;
    public transactionIdentifier: string;
    public identifierTokenIdentifier: IdentifierTokenIdentifier;
    public context: OneTimeLinkContext;

}
