import { InsuranceCategory } from "./insurance-category.model";
import { OrderItem } from "../../checkout/models/order-item.model";

export class ExtensionInsuranceOrderItem extends OrderItem {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.ExtensionInsuranceOrderItem";
  }

  public category: InsuranceCategory;
}
