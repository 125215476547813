import {Criteria} from '../../../criteria/models/criteria.model';
import {MakeIdentifier} from '../../identification/models/make-identifier.model';

export class MakeDescriptionCriteria extends Criteria {
    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.vehicle.makemodeltrim.MakeDescriptionCriteria";
    }

    public makeIdentifier: MakeIdentifier;
}
