import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, QueryPublicInsurancePolicyInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class QueryPublicInsurancePolicyInformationService {

  basePath = '/retail/insurance/querypublicinsurancepolicyinformationmanager';

  constructor(private http: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  queryPublicInsurancePolicyInformation(queryPublicInsurancePolicyInformationRequest: QueryPublicInsurancePolicyInformationRequest) {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/querypublicinsurancepolicyinformation';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(queryPublicInsurancePolicyInformationRequest);

      return this.http.post<any>(path, jsonRequest, this.options).toPromise();
  }

}
