import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";

export class SetupLimitType {

    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.LimitType';
    }

    public description: string;
    public countryIdentifier: CountryIdentifier;
}
