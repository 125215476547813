import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {IStepComponent} from '../../../shared/guards/step-guard'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Router} from '@angular/router'
import {CountryService} from '../../../shared/country/services/country.service'
import {
    BackOfficeForgotPasswordManagerService
} from '../../../../http.services/security/account/back-office-forgot-password-manager/back-office-forgot-password-manager.service'
import {LocationStrategy} from '@angular/common'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {environment} from '../../../../../environments/environment'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {ForgotPasswordRequest, PlainTextUsername} from '@magnabc/tpi'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-back-office-forgot-password-manager',
  templateUrl: './back-office-forgot-password-manager.component.html',
  styleUrls: ['./back-office-forgot-password-manager.component.scss']
})
export class BackOfficeForgotPasswordManagerComponent  extends FormComponent implements IStepComponent, OnInit {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;

    activeToast: ActiveToast<any>;
    errorMessage = null;

    npIdentifierFormGroup: UntypedFormGroup;
    username: UntypedFormControl;
    typePlaceholder: string;
    exists = true;
    resendDisabled = false;
    linkSent = false;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                public countryService: CountryService,
                private route: ActivatedRoute,
                private backOfficeForgotPasswordManagerService: BackOfficeForgotPasswordManagerService,
                locationStrategy: LocationStrategy,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Forgot Password - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: "Query", icon: "fa-search", borderColor: "#1592E6"});
            });
        });

        this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";

    }

    ngOnInit(): void {

        this.username = new UntypedFormControl('', [
            Validators.required,
            Validators.email,
            Validators.maxLength(254),
            Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
        ]);
        this.npIdentifierFormGroup = new UntypedFormGroup({
            username: this.username,
        });

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.backPressed = false;
            return false;
        }
        return true;
    }

    onSubmit(event): void {

        var forgotPasswordRequest = new ForgotPasswordRequest();
        var plainTextUsername = new PlainTextUsername();
        this.resendDisabled = true;

        plainTextUsername.username = this.username.value;
        forgotPasswordRequest.naturalPersonIdentifier = plainTextUsername;

        this.startLoad();
        this.backOfficeForgotPasswordManagerService.forgotPassword(forgotPasswordRequest).then(response => {
            this.stopLoad();
            this.linkSent = true;
            this.exists = true;
            setTimeout(() => {
                this.resendDisabled = false;
            }, 6000);

            this.translate.get('TOAST.RESET_LINK').subscribe((res: any) => {
                this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });

                setTimeout(() => {
                    this.router.navigate(['/console-login']);
                }, 3000);
            });

        }).catch(error => {
            setTimeout(() => {
                this.resendDisabled = false;
            }, 5000);
            this.stopLoad();
            this.exists = false;
            this.translate.get("ERROR_INVALID_CREDENTIALS").subscribe((response) => {
                RaygunErrorHandler.sendError(response);
            });
        });
    }
}
