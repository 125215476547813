import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OTPComponent} from "../../../../view.components/security/otp.component";
import {ActiveToast} from "ngx-toastr";
import {OTPVerifyComponent} from "../../../../view.components/security/opt-verify/opt-verify.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {environment} from "../../../../../environments/environment";
import {Location, LocationStrategy} from "@angular/common";
import {IStepComponent} from "../../../../view.components/shared/guards/step-guard";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {
    ContactNumber,
    FinaliseUpdateLegalEntityRequest,
    LegalEntity,
    LegalEntityPersistenceManagerType,
    OneTimePinStringIdentifier,
    PinContext,
    UnMarshallerService,
    ValidateOneTimePinRequest,
    ValidateOneTimePinResponse
} from "@magnabc/tpi";
import {
    OneTimePinVerificationManagerService
} from '../../../../http.services/security/onetimepin/one-time-pin-verification-manager/one-time-pin-verification-manager.service';
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service';
import {
    LegalEntityFinalisationManagerService
} from '../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun';
import {
    OneTimePinValidationManagerProviderService
} from '../../../../http.services/security/onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service';
import {OneTimePinService} from '../../../../view.components/security/services/one-time-pin.service';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-change-number',
    templateUrl: './change-number-page.component.html',
    styleUrls: ['./change-number-page.component.scss']
})
export class ChangeNumberPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    @Input() legalEntity: LegalEntity;
    @Output() onComplete = new EventEmitter<LegalEntity>();

    pinContext = PinContext;
    steps: any[] = [];
    currentStep = 0;

    contactNumber: ContactNumber;
    @ViewChild(OTPComponent) otpComponent: OTPComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;

    activeToast: ActiveToast<any>;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ErrorToastService,
                private location: Location,
                private entityService: LegalEntityFinalisationManagerService,
                locationStrategy: LocationStrategy,
                private oneTimePinVerificationManagerService: OneTimePinVerificationManagerService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private oneTimePinValidationManagerProviderService: OneTimePinValidationManagerProviderService,
                private oneTimePinService : OneTimePinService,
                private authenticationService: AuthenticationService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 3, name: "One Time Pin", icon: "fa-mobile", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 4, name: "Verification", icon: "fa-check", borderColor: "#3f5baa"});
            });

            this.setTitle();

        });

        const contact = new ContactNumber();
        contact.contactNumberType = "Cell";
        contact.country = this.runtimeConfigurationService.defaultCountryDialingCode;
        this.contactNumber = contact;
    }

    setTitle() {
        let title = `${this.steps[this.currentStep].name} - Change Number - ${this.runtimeConfigurationService.title}`;
        if (this.currentStep === 0) {
            title = `Change Number - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    onSubmit(event): void {
        switch (this.currentStep) {
            case 0:
                this.otpComponent.submit();
                break;
            case 1:
                this.otpVerifyComponent.submit();
                break;
        }
    }

    onNext(event): void {

        if (this.currentStep === 1) {

        } else {
            this.currentStep++;
            window.scrollTo(0, 0);
            this.setTitle();
        }

    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
            this.setTitle();
        } else {
            this.onComplete.emit(this.legalEntity);
        }

    }

    onVerifyContact(contactNumber: ContactNumber): void {
        this.contactNumber = contactNumber;
    }

    onValidateOTP(event) {
        this.startLoad();
        this.validateOTP(event).then(response => {
            if (response !== undefined) {
                if (!response){
                    this.otpVerifyComponent.otpInvalid();
                    this.stopLoad();
                } else {
                    this.onNumberValid(event)
                }
            }
        });
    }

    validateOTP(capturedOTP: string) {

        const oneTimePinIdentifier = new OneTimePinStringIdentifier();
        oneTimePinIdentifier.identifier = this.oneTimePinService.otpIdentifier;

        const validateOTPRequest = new ValidateOneTimePinRequest();
        validateOTPRequest.oneTimePinIdentifier = oneTimePinIdentifier;
        validateOTPRequest.capturedOneTimePin = capturedOTP;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinValidationManagerProviderService.validateOneTimePin(validateOTPRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinRequest) as ValidateOneTimePinResponse);
                    resolve(response.valid);
                    return;
                }
            }).catch(error => {
                console.error('Error while validating OTP', error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return;
            });
        });
    }

    onNumberValid(event): void {

        if(this.authenticationService.isBackOfficeUser()){
            const updateLegalEntity = new FinaliseUpdateLegalEntityRequest();

            if (Utils.isJudicialPerson(this.legalEntity)) {
                this.legalEntity.contactPersons[0].contactNumbers[0] = this.contactNumber;
            } else {
                this.legalEntity.contactNumbers[0] = this.contactNumber;
            }
            updateLegalEntity.legalEntity = this.legalEntity;
            updateLegalEntity.pmType = LegalEntityPersistenceManagerType.BACKOFFICE;

            if (this.legalEntity.addresses[1] && !(this.legalEntity.addresses[1] as any).lineOne) {
                this.legalEntity.addresses.splice(1, 1);
            }
            this.startLoad();
            this.legalEntityFinalisationManagerService.finaliseUpdateLegalEntity(updateLegalEntity).subscribe((response) => {
                this.stopLoad();
                this.toast(response);
            });
        }
        else{
            const finaliseUpdateLegalEntityRequest = new FinaliseUpdateLegalEntityRequest();

            if (Utils.isJudicialPerson(this.legalEntity)) {
                this.legalEntity.contactPersons[0].contactNumbers[0] = this.contactNumber;
            } else {
                this.legalEntity.contactNumbers[0] = this.contactNumber;
            }
            finaliseUpdateLegalEntityRequest.legalEntity = this.legalEntity;
            finaliseUpdateLegalEntityRequest.pmType = LegalEntityPersistenceManagerType.PUBLIC;

            if (this.legalEntity.addresses[1] && !(this.legalEntity.addresses[1] as any).lineOne) {
                this.legalEntity.addresses.splice(1, 1);
            }
            this.startLoad();
            this.entityService.finaliseUpdateLegalEntity(finaliseUpdateLegalEntityRequest).subscribe((response) => {
                this.stopLoad();
                this.toast(response);
            });
        }
    }

    toast(response) {

        this.translate.get('TOAST.UPDATED_NUMBER').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.contactNumber.country + this.contactNumber.number}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.onComplete.emit(this.legalEntity);
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.onComplete.emit(this.legalEntity);
            });
        });
    }

    resendOTP(){
        this.oneTimePinVerificationManagerService.resendOtp(this.contactNumber);
    }
}
