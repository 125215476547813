import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";
import {DialogDataModel} from "../manage-users-list/dialog-data.model";

@Component({
    selector: 'app-verify-disable-user',
    templateUrl: './verify-disable-user-dialog.component.html',
    styleUrls: ['./verify-disable-user-dialog.component.scss']
})
export class VerifyDisableUserDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<VerifyDisableUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataModel) {}
}
