import { ShortCountryNamePipe } from './../../../../view.components/shared/pipes/short-country-name.pipe/short-country-name.pipe';
import { InsuranceTypeManagerService } from './../../../../app.services/managers/insurance-type-manager/insurance-type-manager.service';
import { VehicleSelectorCloseAction } from './../../../../view.components/retail/insurance/vehicle-selector/vehicle-selector.component';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {ActivatedRoute, Router} from "@angular/router"
import {
    AgentIdentifier,
    AgentLegalEntityIdentifier,
    AllSupportedCountriesCriteria,
    Broker,
    BrokerIdentifier,
    BrokerLegalEntityCriteria,
    BrokerLegalEntityIdentifier,
    ComprehensiveInsuranceProduct,
    Country,
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    CountryIdentifierTypeCriteria,
    ProvideCountryIdentifiersRequest,
    ProvideCountryIdentifiersResponse,
    Image,
    InsuranceCategory,
    InsuranceProductBrokerCriteria,
    InsuranceProductUnderwriterCriteria,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    NationalIdentityNumber,
    Product, ProductNumberIdentifier,
    ProvideAgentBrokersProductsRequest,
    ProvideAgentBrokersProductsResponse,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideCountryUnderwriterProductInformationRequest,
    ProvideCountryUnderwriterProductInformationResponse,
    ProvideInsuranceProductInformationRequest,
    ProvideInsuranceProductInformationResponse,
    ProvideSupportedCountriesRequest,
    ProvideSupportedCountriesResponse,
    RetailVehicle,
    ThirdPartyInsuranceProduct,
    TpiIdentifier,
    Underwriter,
    UnderwriterIdentifier,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    YCInsuranceProduct
} from "@magnabc/tpi"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {DatatableComponent} from "@swimlane/ngx-datatable"
import {StringUtils} from "../../../../common/utils/utils.string"
import {
  CountryIdentifierProviderService
} from "../../../../http.services/location/geographic/identification/country-identifier-provider/country-identifier-provider.service"
import {
  RetailProfileFinalisationManagerService
} from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import {
  SupportedCountriesProviderService
} from '../../../../http.services/retail/setup/supported-countries-provider/supported-countries-provider.service'
import {FormComponent} from "../../../../view.components/shared/form/form.component"
import {AgentManagerService} from "../../../../app.services/managers/agent-manager/agent-manager.service"
import {AuthenticationService} from "../../../../app.services/managers/authentication/authentication.service"
import {
  InsuranceProductInformationProviderService
} from '../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service'
import {
  UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {Title} from '@angular/platform-browser'
import {GoogleAnalyticsService} from 'ngx-google-analytics'
import {
  ProductDialogComponent
} from '../../../../view.components/retail/checkout/product-dialog/product-dialog.component'
import {
  ProductActionEnum
} from '../../../../view.components/retail/insurance/insurance-product-information-provider/product/product-action.enum'
import {
  CountryUnderwritersProviderService
} from '../../../../http.services/retail/insurance/country-underwriters-provider/country-underwriters-provider.service'
import {CountryService} from '../../../../view.components/shared/country/services/country.service'
import {FormatPricePipe} from '../../../../view.components/shared/pipes/format-price.pipe'
import {
  CountryTaxesInformationProviderService
} from '../../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
  OneTimePinVerificationManagerProviderService
} from '../../../../http.services/security/onetimepin/one-time-pin-verification-manager-provider/one-time-pin-verification-manager-provider.service'
import {
  AgentBrokersProductsProviderService
} from '../../../../http.services/retail/insurance/agent-brokers-products-provider/agent-brokers-products-provider.service'
import {
  InsuranceProductFinalisationManagerService
} from '../../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service'
import {WhiteLabelService} from '../../../../app.services/managers/white-label/white-label.service'
import {InsuranceProductService} from '../../../../app.services/managers/insurance-product/insurance-product.service'
import { BrokerInformationProviderService } from '../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service';

declare const jQuery: any;

@Component({
  selector: 'app-browse-products',
  templateUrl: './browse-products-page.component.html',
  styleUrls: ['./browse-products-page.component.scss']
})
export class BrowseProductsPageComponent extends FormComponent implements OnInit, OnDestroy {

  @Input() quickTransact = false;

  rows: Product[] = [];
  temp: Product[] = [];
  pageSize = 9;
  offset = 0;
  subTitle = 'Buy Insurance';

  @Input() vehicleRows: RetailVehicle[] = [];


  @ViewChild(DatatableComponent) table: DatatableComponent;
  term = { from: 0, to: 12 };
  type = "Any Type";
  price = { from: 0, to: 99999999 };
  percentange = { from: 0, to: 100 };

  filterValue: string;
  selectProduct: any;

  underwriterMap: { [id: string]: boolean } = {};
  underwriters: Underwriter[] = [];
  underwriter: Underwriter;
  underwriterIdentifiers = [];
  selectVehicles = false;

  sortBy = 'Price';
  orderBy = 'Ascending';
  countryDialing = "Country";
  countryTitle = "Country";

  insuranceCategory: InsuranceCategory = InsuranceCategory.THIRDPARTY;
  insuranceCategoryDescription = "Third Party";
  insuranceCategories = InsuranceCategory;

  supportedCountries: Country[];
  defaultCountry: string

  @Output() onCountry = new EventEmitter<CountryDialingIdentifier>();
  countries = [];
  country: CountryDialingIdentifier;
  dialogRef: MatDialogRef<any, any>;
  componentDestroyed = false
  allProducts: any[] = [];
  prestineProducts: Product[] = [];

  productTypeState = {
    yellowcard: {
      isDefault: false,
      isAvailable: false
    },
    thirdparty: {
      isDefault: false,
      isAvailable: false
    },
    comprehensive: {
      isDefault: false,
      isAvailable: false
    }
  };
  countryOfRegistration: Country;

  readonly thirdPartyInsuranceTypes = [
    "Any Type",
    "Full Third Party",
    "Act Only"
  ];
  vehiclesDialogCloseAction: VehicleSelectorCloseAction = null;
  broker: Broker;
  brokers: Broker[] = [];
  filterBroker: Broker = null;
  allBrokersSet = false;

  constructor(public translate: TranslateService,
              public translateParser: TranslateParser,
              private title: Title,
              private googleAnalyticsService: GoogleAnalyticsService,
              private router: Router,
              private dialog: MatDialog,
              public insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
              public profileRetailService: RetailProfileFinalisationManagerService,
              public securityService: OneTimePinVerificationManagerProviderService,
              private agentService: AgentManagerService,
              private countryService: CountryService,
              public authenticationService: AuthenticationService,
              public underwriterInformationProviderService: UnderwriterInformationProviderService,
              private insuranceProductInformationProviderService: InsuranceProductInformationProviderService,
              private countryUnderwritersProductInformationProviderService: CountryUnderwritersProviderService,
              private locationService: CountryIdentifierProviderService,
              public supportedCountriesProviderService: SupportedCountriesProviderService,
              private unMarshallerService: UnMarshallerService,
              private route: ActivatedRoute,
              private agentBrokersProductsProviderService: AgentBrokersProductsProviderService,
              private countryTaxesInformationProviderService: CountryTaxesInformationProviderService,
              private authService: AuthenticationService,
              private whiteLabelService: WhiteLabelService,
              public runtimeConfigurationService: RuntimeConfigurationService,
              private insuranceProductService: InsuranceProductService,
              private insuranceTypeManagerService: InsuranceTypeManagerService,
              public shortNamePipe: ShortCountryNamePipe,
              private brokerInformationProviderService: BrokerInformationProviderService
              ) {
    super(translate, translateParser);

    this.defaultCountry = runtimeConfigurationService.defaultCountryAlphaTwo
    this.setInsuranceCategory();

    this.temp = this.rows;
    this.getSupportedCountries().then(() => {
      this.getCountries().then(() => {
        this.setCountry();
        // this.getProducts();
      });
    });

  }

  ngOnInit(): void {
    this.onToggleSearch();
    this.clearBrokerFilter();

    if (!this.componentDestroyed) {
      this.openVehiclesDialog(VehicleSelectorCloseAction.GO_HOME);
    }

    this.route.queryParams.forEach((param) => {
      if (param.showVehicles) {
        this.openVehiclesDialog();
      }

      if (param.quickBuy) {
        this.onVehiclesSelected(this.insuranceProductFinalisationManagerService.vehicles)
      }
    });
  }

    setInsuranceCategory(): void {
        if (this.insuranceTypeManagerService.selectedInsuranceCategory === undefined) {
            this.router.navigate(["/retail/browse/select-insurance"])
        }
        switch (this.insuranceTypeManagerService.selectedInsuranceCategory) {
            case InsuranceCategory.COMPREHENSIVE:
                this.translate.get("ADMIN.PRODUCTS.LABEL_COMPREHENSIVE").subscribe((res) => {
                    this.insuranceCategoryDescription = res;
                });
                break;
            case InsuranceCategory.THIRDPARTY:
                this.translate.get("ADMIN.PRODUCTS.LABEL_THIRD_PARTY").subscribe((res) => {
                    this.insuranceCategoryDescription = res;
                });
                break;
            case InsuranceCategory.YELLOWCARD:
                this.translate.get("ADMIN.PRODUCTS.LABEL_CROSS_BORDER").subscribe((res) => {
                    this.insuranceCategoryDescription = res;
                });
                break;
            default:
                this.insuranceCategoryDescription = '';
                break
        }
    }

setCountry(): void {
    const naturalPerson = this.authService.getLoggedInNaturalPerson();
    naturalPerson.legalEntityIdentifiers.forEach((identifier) => {
      if (identifier instanceof NationalIdentityNumber) {
        this.defaultCountry = identifier.countryOfIssue;
        if (this.supportedCountries) {
          const selectedCountry = this.supportedCountries.find((country) => country.alphaTwo === identifier.countryOfIssue);
          if (selectedCountry) {
            this.supportedCountriesProviderService.setSelectedCountry(selectedCountry);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed = true
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  init(): void {
    this.profileRetailService.showPopup = 0
    this.onCountry.emit(this.country);
  }

  getSupportedCountries(): Promise<void> {

    return new Promise<void>((resolve) => {

      const provideSupportedCountriesRequest = new ProvideSupportedCountriesRequest();
      provideSupportedCountriesRequest.criteria = new AllSupportedCountriesCriteria();

      this.supportedCountriesProviderService.provideSupportedCountries(provideSupportedCountriesRequest).then((httpResponse) => {
        const supportedCountries: Country[] = [];
        if (httpResponse && httpResponse.body) {
          const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSupportedCountriesResponse) as ProvideSupportedCountriesResponse);
          this.provideCountries().then((countries) => {
            for (const supportedCountry of response.supportedCountries) {
              supportedCountries.push(countries.find((country) => country.description === supportedCountry.description));
            }
            if (!this.supportedCountriesProviderService.selectedCountry) {
              for (const country of supportedCountries) {
                if (this.defaultCountry === country.alphaTwo) {
                  this.onSelectedCountry(country, false);
                }
              }
            }
            this.supportedCountries = supportedCountries;
            resolve()
          })
        }
      }).catch(() => {
        resolve()
      })

    })
  }

  provideCountries(): Promise<Country[]> {
    return new Promise<Country[]>((resolve) => {
      if (this.countryService.getCountries().length < 1) {
        this.countryService.populateCountries().then((response: Country[]) => {
          if (response) {
            resolve(response);
          }
        });
      } else {
        resolve(this.countryService.getCountries());
      }
    })
  }

  onSelectedCountry(country: Country, getProducts = true) {
    this.supportedCountriesProviderService.selectedCountry = country;
    if (getProducts) {
      this.getProducts();
    }
  }

  isConsole(): boolean {

    const backOfficeAccountNumber = sessionStorage.getItem('backofficeaccountnumber');
    return !!backOfficeAccountNumber;

  }

  //This filter has been removed/commented out on the markup
//   onSelectInsuranceCategory(insuranceCategory: InsuranceCategory) {
//     this.insuranceCategory = insuranceCategory;
//     if (this.insuranceCategory === InsuranceCategory.THIRDPARTY) {
//       this.insuranceCategoryDescription = 'Third Party';
//       this.filterOptionDefault();
//       this.sortBy = 'Price';
//     } else if (this.insuranceCategory === InsuranceCategory.COMPREHENSIVE) {
//       this.insuranceCategoryDescription = 'Comprehensive';
//       this.filterOptionDefault();
//       this.sortBy = 'Percentage';
//     } else if (this.insuranceCategory === InsuranceCategory.YELLOWCARD) {
//       this.insuranceCategoryDescription = 'Yellow Card';
//       this.sortBy = 'Alphabetic';
//       // this.filterOptionDefault();
//     }

//     this.getProducts(false);
//   }

  getImage(image: Image) {
    if (image != null) {
      return `${this.runtimeConfigurationService.hostUrl}${image.imageData.toString()}`;
    }
  }

  private get agentIdentifier(): AgentLegalEntityIdentifier {
    if (this.agentService.agentLogin) {
      return this.agentService.agentIdentifier;
    } else {
      return this.agentService.defautlAgent;
    }
  }

  getProducts(setCategories = true): void {
    this.startLoad();
    const promises = []
    const insuranceCategory = this.insuranceTypeManagerService.selectedInsuranceCategory;

    this.productTypeState.comprehensive.isAvailable = false;
    this.productTypeState.thirdparty.isAvailable = false;
    this.productTypeState.yellowcard.isAvailable = false;

    if (this.agentService.brokerIdentifier) {
      promises.push(this.provideInsuranceProductInformationBroker(InsuranceCategory.THIRDPARTY, [this.agentService.brokerIdentifier]));
      promises.push(this.provideInsuranceProductInformationBroker(InsuranceCategory.COMPREHENSIVE, [this.agentService.brokerIdentifier]));
      promises.push(this.provideInsuranceProductInformationBroker(InsuranceCategory.YELLOWCARD, [this.agentService.brokerIdentifier]));
    } else if (this.whiteLabelService.active && this.whiteLabelService.underwriter) {
      const underwriterIdentifiers = [this.createUnderwriterIdentifier(this.whiteLabelService.underwriter.name, this.whiteLabelService.underwriter.legalEntityIdentifier)]
      promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.THIRDPARTY, underwriterIdentifiers));
      promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.COMPREHENSIVE, underwriterIdentifiers));
      promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.YELLOWCARD, underwriterIdentifiers));
    } else if (this.underwriterIdentifiers.length > 0) {
        promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.THIRDPARTY, this.underwriterIdentifiers));
        promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.COMPREHENSIVE, this.underwriterIdentifiers));
        promises.push(this.provideInsuranceProductInformationUnderwriter(InsuranceCategory.YELLOWCARD, this.underwriterIdentifiers));
    } else if (this.agentIdentifier) {
        promises.push(this.provideAgentBrokersProducts(InsuranceCategory.THIRDPARTY, this.agentIdentifier));
        promises.push(this.provideAgentBrokersProducts(InsuranceCategory.COMPREHENSIVE, this.agentIdentifier));
        promises.push(this.provideAgentBrokersProducts(InsuranceCategory.YELLOWCARD, this.agentIdentifier));
    }  else {
      promises.push(this.provideCountryUnderwriterProductInformation(InsuranceCategory.THIRDPARTY));
      promises.push(this.provideCountryUnderwriterProductInformation(InsuranceCategory.COMPREHENSIVE));
      promises.push(this.provideCountryUnderwriterProductInformation(InsuranceCategory.YELLOWCARD));
    }

    Promise.all(promises).then((result) => {

      this.allProducts = []
      this.prestineProducts = [];

      for (const products of result) {
        this.filterProductsOnSelectedInsuranceType(products);
      }

      this.rows = [];

        if (this.allProducts.length === 0) {
            this.stopLoad();
            return;
        }

      const provideBrokerPromises: Promise<any>[] = [];

      for (const product of this.allProducts) {
        if (product instanceof ThirdPartyInsuranceProduct) {
          this.productTypeState.thirdparty.isAvailable = true;
        } else if (product instanceof ComprehensiveInsuranceProduct) {
          this.productTypeState.comprehensive.isAvailable = true;
        } else if (product instanceof YCInsuranceProduct) {
          this.productTypeState.yellowcard.isAvailable = true;
        }

        const provideBrokerRequest = new ProvideBrokerInformationRequest();
        const brokerLegalEntityCriteria = new BrokerLegalEntityCriteria();
        brokerLegalEntityCriteria.legalEntity = (product.brokerIdentifier as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier;
        provideBrokerRequest.criteria = brokerLegalEntityCriteria;

        const provideBrokerPromise = this.brokerInformationProviderService.provideBroker(provideBrokerRequest).toPromise().then((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse;
            if (response.brokers.length > 0) {
                const broker = response.brokers[0];
                this.broker = broker;
                product['brokerInfo'] = this.broker;
                const foundBroker = this.brokers.find((item) => item.name === broker.name);
                if(!this.allBrokersSet && !foundBroker){
                    this.brokers.push(broker);
                }
            }

            if(product instanceof YCInsuranceProduct){
                if(product.sellingToCountry.description.toLowerCase() === this.vehicleCountryOfRegistration.toLowerCase()){
                    if(product.active){
                        this.rows.push((product));
                        this.addUnderwriter(product.underwriterIdentifier);
                    }
                }
            }else{
                for (const agent of product.agentIdentifiers) {
                    // FIXME IN FUTURE, HARD CODED Agent ID/name
                    // Making sure only display products that have "FCA Ltd" as a broker agent to the public user.
                    if (product.active && (agent.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier === 'ENT-2' || agent.name === 'FCA Ltd')) {
                      this.rows.push((product as ThirdPartyInsuranceProduct));
                      this.addUnderwriter((product as ThirdPartyInsuranceProduct).underwriterIdentifier);
                    }
                }
            }

            if (setCategories) {
                if (!this.productTypeState.thirdparty.isAvailable
                  && !this.productTypeState.comprehensive.isAvailable
                  && this.productTypeState.yellowcard.isAvailable) {
                  this.insuranceCategory = InsuranceCategory.YELLOWCARD;
                  this.insuranceCategoryDescription = "Yellow Card";
                } else if (this.productTypeState.thirdparty.isAvailable) {
                  this.insuranceCategory = InsuranceCategory.THIRDPARTY;
                  this.insuranceCategoryDescription = "Third Party";
                } else if (this.productTypeState.comprehensive.isAvailable) {
                  this.insuranceCategory = InsuranceCategory.COMPREHENSIVE;
                  this.insuranceCategoryDescription = "Comprehensive";
                }
              }


        });

        provideBrokerPromises.push(provideBrokerPromise);
      }

      Promise.all(provideBrokerPromises).then((data) => {
        this.allBrokersSet = true;
        this.temp = this.rows;
        this.clearAllSort();
        this.filter();
        this.stopLoad();
      })
    })

  }

  filterProductsOnSelectedInsuranceType(products: Product[]): void {
    for (const product of products) {
        this.prestineProducts.push(product);
        const category = this.insuranceTypeManagerService.selectedInsuranceCategory;
        switch (category) {
            case InsuranceCategory.COMPREHENSIVE:
                if (product instanceof ComprehensiveInsuranceProduct) {
                    this.getTpiAndCompProductsBasedOnSelectedCountriesAndVehicleCategory(product);
                }
                break;
            case InsuranceCategory.THIRDPARTY:
                if (product instanceof ThirdPartyInsuranceProduct) {
                    this.getTpiAndCompProductsBasedOnSelectedCountriesAndVehicleCategory(product);
                }
                break;
            case InsuranceCategory.YELLOWCARD:
                if (product instanceof YCInsuranceProduct) {
                    // this.allProducts.push(product)
                    this.getYCProductBaseOnVehicleCategory(product);
                }
                break;
        }
    }
  }

  createUnderwriterIdentifier(name: string, legalEntityIdentifier: LegalEntityIdentifier) {
    const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier()
    legalEntityDescriptionIdentifier.name = name
    legalEntityDescriptionIdentifier.tpiIdentifier = legalEntityIdentifier as TpiIdentifier
    return new UnderwriterLegalEntityIdentifier(name, legalEntityDescriptionIdentifier);
  }

  provideInsuranceProductInformationUnderwriter(insuranceCategory: InsuranceCategory, underwriterIdentifiers: UnderwriterIdentifier[]): Promise<Product[]> {
    return new Promise<Product[]>((resolve) => {

      const insuranceProductsCriteria = new InsuranceProductUnderwriterCriteria()
      insuranceProductsCriteria.insuranceCategory = insuranceCategory;
      insuranceProductsCriteria.underwriterIdentifiers = underwriterIdentifiers;

      const provideThirdPartyInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
      provideThirdPartyInsuranceProductInformationRequest.criteria = insuranceProductsCriteria;

      //
      this.insuranceProductInformationProviderService.provideInsuranceProductInformation(provideThirdPartyInsuranceProductInformationRequest).subscribe(response => {
        if (response && response.body) {
          const provideThirdPartyInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
          resolve(provideThirdPartyInsuranceProductInformationResponse.products)
        }

      }, (_) => {
        resolve([])
      });

    })
  }

  provideInsuranceProductInformationBroker(insuranceCategory: InsuranceCategory, brokerIdentifiers: BrokerIdentifier[]): Promise<Product[]> {
    return new Promise<Product[]>((resolve) => {

      const insuranceProductBrokerCriteria = new InsuranceProductBrokerCriteria()
      insuranceProductBrokerCriteria.insuranceCategory = insuranceCategory;
      insuranceProductBrokerCriteria.brokers = brokerIdentifiers;

      const provideThirdPartyInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
      provideThirdPartyInsuranceProductInformationRequest.criteria = insuranceProductBrokerCriteria;

      //
      this.insuranceProductInformationProviderService.provideInsuranceProductInformation(provideThirdPartyInsuranceProductInformationRequest).subscribe(response => {
        if (response && response.body) {
          const provideThirdPartyInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
          resolve(provideThirdPartyInsuranceProductInformationResponse.products)
        }

      }, (_) => {
        resolve([])
      });

    })
  }

  provideCountryUnderwriterProductInformation(insuranceCategory: InsuranceCategory) {
    return new Promise<Product[]>((resolve) => {
      const provideCountryUnderwriterProductInformationRequest = new ProvideCountryUnderwriterProductInformationRequest();
      provideCountryUnderwriterProductInformationRequest.insuranceCategory = insuranceCategory;
      const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
      if (this.countryOfRegistration) {
        countryDescriptionIdentifier.description = this.countryOfRegistration.description;
      } else {
        countryDescriptionIdentifier.description = this.supportedCountriesProviderService.selectedCountry.description;
      }

      provideCountryUnderwriterProductInformationRequest.countryIdentifier = countryDescriptionIdentifier;
      this.countryUnderwritersProductInformationProviderService.provideCountryUnderwriterProductInformation(provideCountryUnderwriterProductInformationRequest).then((response) => {
        if (response && (response as any).body) {
          const provideThirdPartyInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson((response as any).body, ProvideCountryUnderwriterProductInformationResponse) as ProvideCountryUnderwriterProductInformationResponse);
          resolve(provideThirdPartyInsuranceProductInformationResponse.products)
        }
      }).catch(() => {
        resolve([]);
      });
    });
  }

  provideAgentBrokersProducts(insuranceCategory: InsuranceCategory, agentIdentifier: AgentIdentifier): Promise<Product[]> {

    return new Promise<Product[]>((resolve) => {

      const provideAgentBrokersProductsRequest = new ProvideAgentBrokersProductsRequest()
      provideAgentBrokersProductsRequest.insuranceCategory = insuranceCategory;
      provideAgentBrokersProductsRequest.agentIdentifier = agentIdentifier;

      this.agentBrokersProductsProviderService.provideAgentBrokersProducts(provideAgentBrokersProductsRequest).subscribe(response => {
        if (response && response.body) {
          const provideAgentBrokersProductsResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideAgentBrokersProductsResponse) as ProvideAgentBrokersProductsResponse);
          resolve(provideAgentBrokersProductsResponse.products)
        }
      }, (_) => {
        resolve([])
      });

    })

  }

  getCountries(): Promise<void> {
    return new Promise((resolve, reject) => {
      const criteria = new CountryIdentifierTypeCriteria();
      criteria.identifierType = 'CountryDialingIdentifier';

      const provideCountryIdentifiersRequest = new ProvideCountryIdentifiersRequest();
      provideCountryIdentifiersRequest.criteria = criteria;

      this.locationService.provideCountryIdentifiers(provideCountryIdentifiersRequest).then((countryHttpResponse) => {

        const response =
            (this.unMarshallerService.unMarshallFromJson(countryHttpResponse.body, ProvideCountryIdentifiersResponse) as ProvideCountryIdentifiersResponse);

        const allSupportedCountriesCriteria = new AllSupportedCountriesCriteria();
        const provideSupportedCountriesRequest = new ProvideSupportedCountriesRequest();
        provideSupportedCountriesRequest.criteria = allSupportedCountriesCriteria;
        this.supportedCountriesProviderService.provideSupportedCountries(provideSupportedCountriesRequest).then((httpResponse) => {

          if (httpResponse && httpResponse.body) {
            const supportedCountriesResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSupportedCountriesResponse) as ProvideSupportedCountriesResponse);

            for (const supportedCountry of supportedCountriesResponse.supportedCountries) {
              for (const countryIdentifier of response.countryIdentifiers) {
                if (countryIdentifier instanceof CountryDescriptionIdentifier) {
                  if (supportedCountry.description === countryIdentifier.description) {
                    this.countries.push(countryIdentifier);
                  }
                }
              }
            }

            this.countries.sort((a, b) => {
              if (a.description > b.description) {
                return 1;
              }
              if (a.description < b.description) {
                return -1;
              }
              return 0;
            });
            this.init();
            resolve();
          }
        });
      });
    })
  }

    addUnderwriter(identifier: UnderwriterIdentifier) {

        const underwriterLegalEntityIdentifier = identifier as UnderwriterLegalEntityIdentifier;
        this.underwriterInformationProviderService.getUnderwriter(identifier, (underwriter: Underwriter) => {
            if (!this.underwriterMap[underwriterLegalEntityIdentifier.name]) {
                this.underwriters.push(underwriter);
                this.underwriterMap[underwriterLegalEntityIdentifier.name] = true;
            }
        });


    }

  hasApplicableProducts(underwriter: Underwriter): boolean {
    return true
    for (const row of this.rows) {
      if (((row as any).underwriterIdentifier as UnderwriterLegalEntityIdentifier).name === underwriter.name) {
        return true;
      }
    }
    return false;
  }

  emptyResults(): boolean {
    for (const underwriter of this.underwriters) {
      if (this.hasApplicableProducts(underwriter)) {
        return false;
      }
    }
    return true;
  }

  onUnderwriter(underwriter): void {

    if (underwriter === null) {
      this.onAllUnderwriters();
    } else {

      this.underwriter = underwriter;
      this.rows = [];
      this.underwriterIdentifiers = [this.getUnderwriterLegalEntityIdentifier(this.underwriter)];

      this.getProducts();

      setTimeout(() => {
        this.onToggleSearch();
      });

      this.updateTitle();
    }
  }

  onBroker(broker: Broker): void {

    if (broker === null) {
      this.onAllBrokers();
    } else {

      this.filterBroker = broker;
      this.rows = [];
      const legalEntityIdentifier  = new LegalEntityDescriptionIdentifier();
      legalEntityIdentifier.name = broker.name;
      legalEntityIdentifier.surname = broker.name;

      const tpiId = new TpiIdentifier();
      tpiId.tpiIdentifier = broker.legalEntityIdentifier['tpiIdentifier'];

      legalEntityIdentifier.tpiIdentifier = tpiId;
      const brokerIdentifier = new BrokerLegalEntityIdentifier(broker.name, legalEntityIdentifier);
      this.agentService.brokerIdentifier = brokerIdentifier;

      this.getProducts();

      setTimeout(() => {
        this.onToggleSearch();
      });

      this.updateTitle();
    }
  }

  onAllBrokers() {
    this.filterBroker = null;
    this.agentService.brokerIdentifier = null;
    this.rows = [];
    this.getProducts();
    setTimeout(() => {
      this.onToggleSearch();
    });
    this.updateTitle();
  }

  getUnderwriterLegalEntityIdentifier(underwriter: Underwriter): UnderwriterLegalEntityIdentifier {
    const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
    legalEntityDescriptionIdentifier.name = underwriter.name;
    legalEntityDescriptionIdentifier.tpiIdentifier = (underwriter.legalEntityIdentifier as TpiIdentifier);
    return new UnderwriterLegalEntityIdentifier(underwriter.name, legalEntityDescriptionIdentifier);
  }

  onAllUnderwriters() {
    this.underwriter = new Underwriter();
    this.underwriterIdentifiers = [];
    this.rows = [];
    this.getProducts();
    setTimeout(() => {
      this.onToggleSearch();
    });
    this.updateTitle();
  }

  onInsuranceType(type): void {

    this.type = type;
    this.filter();

  }

  onTerm(term): void {

    this.term = term;
    this.filter();

  }

  onPercentage(percentage): void {

    this.percentange = percentage;
    this.filter();

  }

  onPrice(price): void {

    this.price = price;
    this.filter();

  }

  onCountryFilter(countryDialing, countryDescription): void {
    this.countryDialing = countryDialing;
    this.countryTitle = countryDescription;
    this.filter();
  }

  filter(): void {

    this.rows = [];

    if (!this.insuranceProductFinalisationManagerService.vehicles || this.insuranceProductFinalisationManagerService.vehicles.length === 0) {

      let products = this.filterTerm(this.temp);
      products = this.filterType(products);
      products = this.filterPrice(products, this.insuranceCategory);
      products = this.filterCountries(products);

      let filterProducts = products;
      if (this.filterValue && this.filterValue !== "") {
        filterProducts = products.filter((d) => {
          return d.name.toLowerCase().indexOf(this.filterValue) !== -1
            // || d.broker.toLowerCase().indexOf(this.filterValue) !== -1
            // || d.underwriter.toLowerCase().indexOf(this.filterValue) !== -1
            || !this.filterValue;
        });
      }

      switch (this.sortBy) {
        case 'Underwriter':
          filterProducts.sort((a: ThirdPartyInsuranceProduct, b: ThirdPartyInsuranceProduct) => {
            if ((a.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name > (b.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name) {
              return 1;
            }
            if ((a.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name < (b.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Term':
          filterProducts.sort((a, b) => {
            const aPeriod = parseInt(a.coveragePeriod.months);
            const bPeriod = parseInt(b.coveragePeriod.months);
            if (aPeriod > bPeriod) {
              return 1;
            }
            if (aPeriod < bPeriod) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price':
          filterProducts.sort((a, b) => {
            if (a.annualAmount.amount !== undefined && a.annualAmount.amount !== null) {
              const firstAmount = parseInt(a.annualAmount.amount);
              const secondAmount = parseInt(b.annualAmount.amount);

              if (firstAmount > secondAmount) {
                return 1;
              } else if (firstAmount < secondAmount) {
                return -1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });
          break;
        case 'Alphabetic':
          filterProducts.sort((a, b) => {
            if (a.name) {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
            }
            return 0;
          });
          break;
        default:
          break;
      }

      if (this.orderBy === 'Descending') {
        filterProducts.reverse();
      }

      this.rows = filterProducts;
      return;
    }

    let products = this.filterProducts(this.temp);
    products = this.filterTerm(products);
    products = this.filterType(products);
    products = this.filterPrice(products, this.insuranceCategory);
    products = this.filterCountries(products);

    let filterProducts = products;
    if (this.filterValue && this.filterValue !== "") {
      filterProducts = products.filter((d) => {
        return d.name.toLowerCase().indexOf(this.filterValue) !== -1
          // || d.broker.toLowerCase().indexOf(this.filterValue) !== -1
          // || d.underwriter.toLowerCase().indexOf(this.filterValue) !== -1
          || !this.filterValue;
      });
    }

    switch (this.sortBy) {
      case 'Underwriter':
        filterProducts.sort((a: ThirdPartyInsuranceProduct, b: ThirdPartyInsuranceProduct) => {
          if ((a.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name > (b.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name) {
            return 1;
          }
          if ((a.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name < (b.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name) {
            return -1;
          }
          return 0;
        });
        break;
      case 'Term':
        filterProducts.sort((a, b) => {
          // noinspection TsLint
          const aPeriod = parseInt(a.coveragePeriod.months);
          // noinspection TsLint
          const bPeriod = parseInt(b.coveragePeriod.months);
          if (aPeriod > bPeriod) {
            return 1;
          }
          if (aPeriod < bPeriod) {
            return -1;
          }
          return 0;
        });
        break;
      case 'Price':
        filterProducts.sort((a, b) => {
          if (a instanceof YCInsuranceProduct) return 0;
          if (a.annualAmount.amount) {
            if (a.annualAmount.amount > b.annualAmount.amount) {
              return 1;
            }
            if (a.annualAmount.amount < b.annualAmount.amount) {
              return -1;
            }
          }
          return 0;
        });
        break;
      case 'Alphabetic':
        filterProducts.sort((a, b) => {
          if (a.name) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
          }
          return 0;
        });
        break;
      default:
        break;
    }

    if (this.orderBy === 'Ascending') {
      filterProducts.reverse();
    }

    this.rows = filterProducts;

  }

  setSortBy(sortBy: string): void {
    this.sortBy = sortBy;
    this.filter();
  }

  toggleOrder(): void {
    if (this.orderBy === 'Descending') {
      this.orderBy = 'Ascending';
    } else {
      this.orderBy = 'Descending';
    }
    this.filter();
  }

  filterPrice(temp: Product[], insuranceCatecory): any {

    const products = [];
    if (insuranceCatecory === "THIRDPARTY") {
      for (const item of temp) {
        if (item instanceof ThirdPartyInsuranceProduct) {
          const total = FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService, item.annualAmount.amount, item.coveredCountries);
          if (total >= (this.price.from * 100) && total <= (this.price.to * 100)) {
            products.push(item);
          }
        }
      }
    } else if (insuranceCatecory === InsuranceCategory.YELLOWCARD) {
      for (const item of temp) {
        if (item instanceof YCInsuranceProduct) {
          products.push(item);
        }
      }
    } else {
      for (const item of temp) {
        if (item instanceof ComprehensiveInsuranceProduct) {
          if (item.annualPercentage >= this.percentange.from && item.annualPercentage <= this.percentange.to) {
            products.push(item);
          }
        }
      }
    }

    return products;
  }

  filterType(temp: Product[]): any {

    const products = [];
    if (this.type === 'Any Type') {
      return temp;
    } else {
      for (const item of temp) {
        if ((item as any).insuranceType && (item as any).insuranceType.description === this.type) {
          products.push(item);
        }
      }
    }
    return products;

  }

  //TODO : Product coverageperiod to be fixed
  filterTerm(temp: Product[]): any {

    const products = [];

    for (const item of temp) {
      if (item instanceof ThirdPartyInsuranceProduct) {
        //if (item.coveragePeriod.months >= this.term.from && item.coveragePeriod.months <= this.term.to) {
        products.push(item);
        //}
      } else if (item instanceof ComprehensiveInsuranceProduct) {
        //if (item.coveragePeriod.months >= this.term.from && item.coveragePeriod.months <= this.term.to) {
        products.push(item);
        //}
      } else if (item instanceof YCInsuranceProduct) {
        //if (item.coveragePeriod.months >= this.term.from && item.coveragePeriod.months <= this.term.to) {
        products.push(item);
        //}
      }
    }
    return products;

  }

    clearAllFilter() {
        this.filterOptionDefault();
        this.onInsuranceType('Any Type');
        if (this.insuranceCategoryDescription === 'Third Party') {
            this.onPrice({ from: 0, to: 99999999 });
        } else if (this.insuranceCategoryDescription === 'Comprehensive') {
            this.onPercentage({ from: 0, to: 100 });
        }
    }

    clearBrokerFilter(): void {
        this.agentService.brokerIdentifier = null;
        if (this.filterBroker) {
            this.filterBroker = null;
            this.onAllBrokers();
        }
    }

  clearAllSort() {
    this.orderBy = 'Ascending';
    if (this.insuranceCategoryDescription === 'Third Party') {
      this.sortBy = 'Price';
      this.setSortBy('Price');
    } else if (this.insuranceCategoryDescription === 'Comprehensive') {
      this.sortBy = 'Percentage';
      this.setSortBy('Percentage');
    } else if (this.insuranceCategoryDescription === 'Yellow Card') {
      this.sortBy = 'Alphabetic';
      this.setSortBy('Alphabetic');
    }
  }

  filterOptionDefault() {
    this.term = { from: 0, to: 12 };
    this.type = "Any Type";
    this.price = { from: 0, to: 99999999 };
    this.percentange = { from: 0, to: 100 };
  }

  filterProducts(temp: Product[]): any {

    const products = [];

    for (const item of temp) {
      if (item instanceof YCInsuranceProduct || this.appliesToCategory(item)) {
        products.push(item);
      }
    }

    return products;
  }

  appliesToCategory(item: Product): boolean {
    let categoryDescriptions: string[];
    if(this.insuranceProductFinalisationManagerService.vehicles[0].insuranceVehicleCategories &&
        this.insuranceProductFinalisationManagerService.vehicles[0].insuranceVehicleCategories.length > 0){
        categoryDescriptions = this.insuranceProductFinalisationManagerService.vehicles[0].insuranceVehicleCategories.flatMap(item => item.description)
    }else{
        const usage = this.insuranceProductFinalisationManagerService.vehicles.at(0).usage;
        categoryDescriptions = [usage.toLocaleLowerCase() === 'private' ? usage : 'Commercial'];
    }

    for (const category of categoryDescriptions) {
      if (item instanceof YCInsuranceProduct) {
        const validPriceSchedules = item.ycPriceSchedules.filter((schedule) => {
          return schedule.price > 0 ? schedule : null;
        });

        const foundSchedules = validPriceSchedules.filter((schedule) =>
          schedule.insuranceVehicleCategorySubDescription.includes(category)
        );

        return foundSchedules.length > 0;
      } else {
        if (category === (item as any).insuranceVehicleCategory.description) {
          return true;
        }
      }
    }
  }

  filterCountries(temp: Product[]): any {

    const products = [];

    for (const item of temp) {
      if (this.coveredCountry(item)) {
        products.push(item);
      }
    }

    return products;

  }

  //TODO Figure out what this code actually checks
  //This should check if the product is part of the covered countries the user specified
  coveredCountry(item: Product): boolean {
    if (item instanceof YCInsuranceProduct) {
      return true;
    } else {
      const coveredCountries = this.insuranceProductService.selectedCoveredCountries;
      for (const countryIdentifier of (item as any).coveredCountries) {
        const description = (countryIdentifier as CountryDescriptionIdentifier).description ;
        if (coveredCountries.includes(description)) {
          return true;
        }
        // else if (this.countryDialing === "Country") {
        //   //This methods will always default to this for all products except YC
        //   return true;
        // }
      }
      return false;
    }
  }

  updateFilter(event) {

    this.filterValue = event.target.value.toLowerCase();
    this.filter();

    // this.table.offset = 0; FIXME

  }

  //noinspection JSUnusedLocalSymbols
  onSelect(event): void {
    this.router.navigate(['/retail/products/' + event.identifer]).then();
  }

  onFooterPage(event): void {
    this.pageSize = event.pageSize;
    this.offset = event.offset;
  }

  onToggleSearch(): void {
    const input = jQuery('#input-search');
    input.toggle();
    input.val('');
    input.focus();
    this.filterValue = null;
  }

  onProductSelect(product): void {
    if (!this.insuranceProductFinalisationManagerService.vehicles || this.insuranceProductFinalisationManagerService.vehicles.length === 0) {
      this.openVehiclesDialog();
      this.selectProduct = product;
    } else {
      this.insuranceProductService.selectedYCPriceSchedules = [];
      setTimeout(() => {
        this.openProductDialog(product);
      })
    }
  }

  formatCurrency(value): string {
    return StringUtils.formatCents(value);
  }

  updateTitle() {
    if (this.underwriter) {
      const _title = `Select Product - ${this.subTitle} - ${this.runtimeConfigurationService.title}`;
      this.title.setTitle(_title);
      this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), _title);
    } else {
      const _title = `Select Underwriter - ${this.subTitle} - ${this.runtimeConfigurationService.title}`;
      this.title.setTitle(_title);
      this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), _title);
    }
  }

  openVehiclesDialog(closeAction: VehicleSelectorCloseAction = null): void {
    this.vehiclesDialogCloseAction = closeAction;
    if (!this.selectVehicles) {
      this.selectVehicles = true;
      const _title = `Select Vehicle - ${this.subTitle} - ${this.runtimeConfigurationService.title}`;
      this.title.setTitle(_title);
      this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), _title);
    }

  }

  onVehiclesSelected(retailsVehicle: RetailVehicle[]) {
    this.updateTitle();
    if (retailsVehicle) {

      this.insuranceProductFinalisationManagerService.vehicles = retailsVehicle;
      this.filter();
      if (this.insuranceProductFinalisationManagerService.vehicles.length > 0 && this.selectProduct) {
        for (const r of this.rows) {
          if (r.name === this.selectProduct.name) {
            this.openProductDialog(this.selectProduct);
            break;
          }
        }
      }
      this.countryOfRegistration = this.countryService.getCountries()
        .find(item => item.description.toLowerCase() === retailsVehicle[0].countryOfRegistration.toLowerCase());
      if (this.countryOfRegistration) {
        this.defaultCountry = this.countryOfRegistration.alphaTwo;
      }
    }
    this.selectProduct = null;
    this.selectVehicles = false;
    this.underwriters = [];
    this.underwriterMap = {};
    this.agentService.brokerIdentifier = null;
    this.filterBroker = null;
    this.clearAllFilter();
    this.onAllUnderwriters();
  }

    openProductDialog(product: Product): void {

        const _title = `Confirm Order - ${this.subTitle} - ${this.runtimeConfigurationService.title}`;
        this.title.setTitle(_title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), _title);

        const data = {
            product,
            vehicles: this.insuranceProductFinalisationManagerService.vehicles,
            action: ProductActionEnum.ADD_TO_CART,
            extraProducts: this.getAssociatedProducts(product)
        }

        const classes = ['product-dialog'];
        if (data.extraProducts.length > 0) {
            classes.push('multi');
        }

        this.dialogRef = this.dialog.open(ProductDialogComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "1%" },
            panelClass: classes,
            data
        });


        this.dialogRef.afterClosed().subscribe(() => {
            this.updateTitle();
        });
    }

    getAssociatedProducts(product: Product): Product[] {
        if (product instanceof YCInsuranceProduct) {
            const vehicleUsages = this.insuranceProductFinalisationManagerService.vehicles.map((item) => {
                if(item.usage.toLowerCase() !== 'private'){
                    return 'Other';
                }
                return  item.usage;
            });

            const selectedCountries = this.insuranceProductService.selectedCoveredCountries;

            const productNumbers: string[] = []
            for (const productIdentifier of product.productIdentifiers) {
                productNumbers.push((productIdentifier as ProductNumberIdentifier).number)
            }
            let showCountries = this.insuranceProductService.selectedCoveredCountries;
            let extraProducts: any[] = [];
            const products = this.prestineProducts.filter((item: Product) => productNumbers.includes(item.number));
            showCountries.forEach((country) => {
                const temp = products.filter((item: ThirdPartyInsuranceProduct) => {
                    const countries = item.coveredCountries.map((x: CountryDescriptionIdentifier) => x.description);
                    return countries.includes(country);
                });
                temp.forEach((item: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct) => {
                    let insuranceVehicleCategory = item.insuranceVehicleCategory.description;
                    insuranceVehicleCategory = insuranceVehicleCategory.toLocaleLowerCase() === 'private' ? insuranceVehicleCategory : 'Other';
                    const coveredCountries = item.coveredCountries.map((item) => item['description']);
                    const countriesMatch = coveredCountries.filter((item) => selectedCountries.includes(item)).length > 0;
                    const categoriesMatch = vehicleUsages.includes(insuranceVehicleCategory);
                    if (countriesMatch && categoriesMatch) {
                        extraProducts.push(item)
                    }
                })
            });
            return extraProducts;
        }
        return [];
    }

  isYellowCard(item: any) {
    return item instanceof YCInsuranceProduct;
  }

    getYCCoveredCountries(product: any): any {
        if (product instanceof YCInsuranceProduct) {
            const identifiers = product.productIdentifiers.map((item: ProductNumberIdentifier) => item.number);
            const extraProducts = this.prestineProducts.filter((item) => identifiers.includes(item.number));
            const extraCountries = extraProducts.flatMap((item: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct) => item.coveredCountries)
                .map((item) => item['description']);
            const countries = [... new Set(product.ycPriceSchedules.map((item) => item.country))].concat(extraCountries);
            const selectedCountries = this.insuranceProductService.selectedCoveredCountries;
            const availableCountries = this.countryService.getCountries().filter((country) => selectedCountries.includes(country.description));
            if (availableCountries.length > 0) {
                return availableCountries.filter(item => countries.includes(item.description));
            } else {
                return [];
            }
        }
        return [];
    }

  underWriterName(event) {
    return event.name.replace(/\s/g, "");
  }

    private getYCProductBaseOnVehicleCategory(product: YCInsuranceProduct) {
        let uniqueCountries = Array.from(new Set(product.ycPriceSchedules.map(item => item.country)));
        const coveredCountries: string[] = [];
        uniqueCountries
        .forEach((item) => {
            if(this.insuranceProductService.selectedCoveredCountries.includes(item)){
                coveredCountries.push(item)
            }
        });
        const vehicleCategoryDescriptions = this.getVehicleCategoryDescriptions();
        const selectedPriceSchedules = product.ycPriceSchedules.filter((schedule) =>
            vehicleCategoryDescriptions.filter(item => schedule.insuranceVehicleCategorySubDescription.includes(item)).length > 0 &&
            coveredCountries.includes(schedule.country) && schedule.price !== 0)
        if (selectedPriceSchedules.length > 0) {
            this.allProducts.push(product)
        }
    }

    /**
     * Filter products based on selected countries and vehicle category
     * @param product - product to be filtered
     */
    private getTpiAndCompProductsBasedOnSelectedCountriesAndVehicleCategory(product: Product) {
        if (product instanceof ThirdPartyInsuranceProduct || product instanceof ComprehensiveInsuranceProduct) {
            const vehicleCategoryDescriptions = this.getVehicleCategoryDescriptions();
            const coveredCountries = product.coveredCountries.map((item) => item['description']);
            const countriesMatch = coveredCountries.filter((item) => this.insuranceProductService.selectedCoveredCountries.includes(item)).length > 0;
            const categoriesMatch = vehicleCategoryDescriptions.includes(product.insuranceVehicleCategory.subDescription);
            if (countriesMatch && categoriesMatch) {
                this.allProducts.push(product)
            }
        }
    }

  private getVehicleCategoryDescriptions() {
    const filteredDescriptions = this.insuranceProductFinalisationManagerService.vehicles.map(vehicle => { return this.getVehicleCategories(vehicle); }).flat();
    const vehicleCategoryDescriptions = [...new Set(filteredDescriptions)];
    return vehicleCategoryDescriptions;
  }

  private getVehicleCategories(vehicle: RetailVehicle): string[] {
    if (vehicle.insuranceVehicleCategories) {
      return vehicle.insuranceVehicleCategories.map(category => category.subDescription)
    } else {
      const result = [];
      const items = this.allProducts;
      if (items) {
        items.forEach((item) => {
          if (item as YCInsuranceProduct) {
            const testVehicle = item.vehicle;
            if (testVehicle.vehicleIdentificationNumber === vehicle.vehicleIdentificationNumber) {
              result.push(item.insuranceVehicleCategorySubDescription);
            }
          }
        });
      }
      return result;
    }
  }

  get vehicleCountryOfRegistration(): string {
    return this.insuranceProductFinalisationManagerService.vehicles.at(0).countryOfRegistration;
  }
}
