import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormComponent} from "../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";

@Component({
    selector: 'app-obscure-number',
    templateUrl: './obscure-number.component.html',
    styleUrls: ['./obscure-number.component.scss']
})
export class ObscureNumberComponent extends FormComponent implements OnInit {

    @Input() label: string;
    @Input() number: string;
    @Output() onValid = new EventEmitter<void>();
    @Output() onSkip = new EventEmitter<void>();

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    errorMessage: null;

    obfuscated: string;
    positionNine: string;
    invalidNumber = false;

    formGroup: UntypedFormGroup;
    one: UntypedFormControl;
    two: UntypedFormControl;
    three: UntypedFormControl;
    four: UntypedFormControl;

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {
        super(translate, translateParser);
    }

    ngOnInit() {

        if (this.number.length === 17) {
            this.obfuscated = this.number.substr(0, 8);
            this.positionNine = this.number.substr(9, 5);
        } else {
            this.obfuscated = this.number.substr(0, this.number.length - 4);
        }

        this.one = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.two = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.three = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(1)
        ]);

        this.four = new UntypedFormControl('', [
            Validators.maxLength(1)
        ]);

        this.formGroup = new UntypedFormGroup({
            'one': this.one,
            'two': this.two,
            'three': this.three,
            'four': this.four,
        });

    }

    onKeyUp(event): void {

        if (event.keyCode === 8) {

            switch (event.srcElement.id) {
                case "one":
                    break;
                case "two":
                    if (this.two.value.length === 0) {
                        document.getElementById("one").focus();
                    }
                    break;
                case "three":
                    if (this.three.value.length === 0) {
                        document.getElementById("two").focus();
                    }
                    break;
                case "four":
                    if (this.four.value.length === 0) {
                        document.getElementById("three").focus();
                    }
                    break;
            }

        }


    }

    onKeyPress(event): void {

        if (event.keyCode !== 8) {

            switch (event.srcElement.id) {
                case "one":
                    document.getElementById("two").focus();
                    if (this.one.value.length === 1) {
                        this.two.setValue(event.key);
                    }
                    break;
                case "two":
                    document.getElementById("three").focus();
                    if (this.two.value.length === 1) {
                        this.three.setValue(event.key);
                    }
                    break;
                case "three":
                    document.getElementById("four").focus();
                    if (this.three.value.length === 1) {
                        this.four.setValue(event.key);
                    }
                    break;
                case "four":
                    break;
            }

        }

    }

    onSkipSelected(event): boolean {
        this.onSkip.emit();
        return false;
    }

    submit(): void {
        document.getElementById('form-submit-obfuscated').click();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {

            let number;
            if (this.positionNine) {
                number = this.obfuscated + this.one.value + this.positionNine + this.two.value + this.three.value + this.four.value;
            } else {
                number = this.obfuscated + this.one.value + this.two.value + this.three.value + this.four.value;
            }
            if (number.toUpperCase() === this.number.toUpperCase()) {
                this.onValid.emit();
            } else {
                this.invalidNumber = true;
            }

        }

    }

}
