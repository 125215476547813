import {Injectable} from '@angular/core'
import {
    Broker,
    BrokerIdentifier,
    BrokerLegalEntityCriteria,
    BrokerLegalEntityIdentifier,
    CalculateRealTimeExtensionPriceRequest,
    CalculateRealTimeExtensionPriceResponse,
    CalculateRealTimePriceRequest,
    CalculateRealTimePriceResponse,
    CalculateRealTimeYCPriceRequest,
    CalculateRealTimeYCPriceResponse,
    ClaimSpecificExcess,
    ComprehensiveInsurancePolicyCartItem,
    ComprehensiveInsurancePolicyOrderItem,
    ComprehensiveInsuranceProduct,
    CountryDescriptionIdentifier,
    CountryIdentifier,
    DurationType,
    Excess,
    InsuranceCategory,
    Item,
    Limit,
    LimitValueType,
    Money,
    MotorInsuranceDetails,
    MotorInsuranceLimitDetails,
    MotorInsuranceVehicleDetails,
    OrderItem,
    PremiumAdjustmentCategory,
    PricePerVehicle,
    PriceSchedule,
    ProductNumberIdentifier,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideScannedDocumentRequest,
    ProvideScannedDocumentResponse,
    RetailProfile,
    RetailVehicle,
    ScannedDocument,
    ScannedDocumentCriteria,
    ScannedDocumentIdentifier,
    ScannedDocumentIdentifierIdentifier,
    ThirdPartyInsurancePolicyCartItem,
    ThirdPartyInsurancePolicyOrderItem,
    ThirdPartyInsuranceProduct,
    TpiVehicleIdentifier,
    Underwriter,
    UnderwriterIdentifier,
    UniversalExcess,
    UnMarshallerService,
    Vehicle,
    VehicleIdentificationNumber,
    VehicleIdentifier,
    YCInsurancePolicyCartItem,
    YCInsuranceProduct,
    YCLimitDetails,
    YCMotorInsuranceDetails,
    YCMotorVehicleInsuranceDetails,
    YCPriceInterval,
    YCPriceSchedule,
    YCInsurancePolicyOrderItem
} from '@magnabc/tpi'
import {
    ProductActionEnum
} from '../../../view.components/retail/insurance/insurance-product-information-provider/product/product-action.enum'
import {Utils} from '../../../view.components/shared/Utils/Utils'
import * as moment from 'moment/moment'
import {ProductTypePipe} from '../../../view.components/shared/pipes/product-type.pipe'
import {
    QuickTransactManagerService
} from '../quick-transact-manager/quick-transact-manager.service'
import {
    RetailProfileFinalisationManagerService
} from '../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
    RealTimePricingCalculationManagerService
} from '../../../http.services/retail/insurance/thirdpartyinsurance/real-time-pricing-calculation-manager/real-time-pricing-calculation-manager.service'
import {
    BrokerInformationProviderService
} from '../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {environment} from '../../../../environments/environment'
import {Subject} from 'rxjs'

import {
    UnderwriterInformationProviderService
} from '../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import { CountryTaxesInformationProviderService } from '../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service';
import { HttpResponse } from '@angular/common/http';
import {
  InsuranceProductFinalisationManagerService
} from '../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service'
import {
  ScannedDocumentProviderService
} from '../../../http.services/media/scanneddocument/scanned-document-provider/scanned-document-provider.service'

@Injectable({
    providedIn: 'root'
})
export class InsuranceProductService {

    retailProfile: RetailProfile
    action = ProductActionEnum.ADD_TO_CART
    item: Item

    policyNumber: string
    orderItems: OrderItem[]
    isExtension = false
    isExtensionInsurancePolicyCartItem = false
    orderItemMap: {[id:string]: OrderItem} = {}

    insuranceVehicleCategoryDescription: string = null
    insuranceTypeDescription: string = null
    annualAmount: Money = null
    name: string = null
    description: string = null
    conditions: string = null
    productNumber: string = null
    insuranceCategory: InsuranceCategory = null
    priceSchedules: PriceSchedule[] = []
    displayPriceSchedules: PriceSchedule[] = []
    selectedPriceSchedule: PriceSchedule = null
    annualPercentage: number = null
    baseCurrency: string = null
    coveredCountries: CountryIdentifier[] = [];
    broker: Broker = null
    underwriter: Underwriter = null

    limits: Limit[] = []
    originalLimits: Limit[] = []
    excesses: Excess[] = []

    vehicles: RetailVehicle[]
    displayVehicles: RetailVehicle[]
    selectedVehicles: RetailVehicle[]
    selectedVehicleMap: {[id: string]: Vehicle | RetailVehicle} = {}
    vehiclePlateMap: {[id: string]: string} = {}
    visibleVehicleButtonCount = 0
    overflowVehicleButtonCount = 0
    invalidVehicleSelect = false
    unverifiedVehicleMessage: string[] = []
    filterValue: string
    vehicleButtons: any = null

    tpiIdentifier: string
    vehicleImageFilesMap: {[id: string]: {file: any, description: string, scannedDocument: ScannedDocument, isBase64: boolean}[]} = {}
    pricePerVehicles: PricePerVehicle[] = []
    removeImages: ScannedDocumentIdentifier[] = []
    invalidPriceSchedule = false

    loadingPrice = false
    total: Money
    minimumPolicyValue: number
    startDate = moment()
    endDate = moment()
    showStartDate = false
    showEndDate = false
    today = moment()
    maxEndDate = moment()
    minEndDate = moment()
    maxStartDate = this.startDate.add(5, 'year')
    intervalMaxEndDate = moment()

    selectedVehicle = new Subject<Vehicle | RetailVehicle>()
    deselectedVehicle = new Subject<string>()

    ycPriceSchedules: YCPriceSchedule[] = [];
    ycPriceIntervals: YCPriceInterval[] = [];

    selectedYCPriceSchedules: YCPriceSchedule[] = [];

    ycMotorInsuranceDetails: YCMotorInsuranceDetails;
    vehicleRegistrationFileMap: IYCVehicleDocument[] = [];
    vehicleSelected: Vehicle | RetailVehicle;
    product: ComprehensiveInsuranceProduct | YCInsuranceProduct | ThirdPartyInsuranceProduct;
    selectedCoveredCountries: any[] = [];
    brokerInfo: Broker;
    ycPriceBreakdown: Map<string, any[]> = new Map<string, any[]>();

    constructor(private unMarshallerService: UnMarshallerService,
                private RetailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private realTimePricingCalculationManagerService: RealTimePricingCalculationManagerService,
                private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private insuranceAssistedQuickTransactManagerService: QuickTransactManagerService,
                private realTimePricingCalculator: RealTimePricingCalculationManagerService,
                private countryTaxesInformationProviderService: CountryTaxesInformationProviderService,
                private scannedDocumentProviderService: ScannedDocumentProviderService) {
                    // this.selectedVehicle.subscribe((data) => {this.vehicleSelected = data});
                }

    initialiseOrderItems(orderItems: OrderItem[], action: ProductActionEnum, policyNumber: string, endDate: any) {

        this.reset()

        this.action = action
        this.policyNumber = policyNumber
        this.orderItems = orderItems
        this.isExtension = true

        const vehicles: any[] = []
        for (const orderItem of orderItems) {
            if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyOrderItem") {
                const thirdPartyInsurancePolicyOrderItem = orderItem as ThirdPartyInsurancePolicyOrderItem
                vehicles.push(thirdPartyInsurancePolicyOrderItem.snapshotVehicle)
                this.orderItemMap[thirdPartyInsurancePolicyOrderItem.snapshotVehicle.tpiVehicleIdentifier] = orderItem
            } else if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsurancePolicyOrderItem") {
                const comprehensiveInsurancePolicyOrderItem = orderItem as ComprehensiveInsurancePolicyOrderItem
                vehicles.push(comprehensiveInsurancePolicyOrderItem.snapshotVehicle)
                this.orderItemMap[comprehensiveInsurancePolicyOrderItem.snapshotVehicle.tpiVehicleIdentifier] = orderItem
            } else if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.ExtensionInsurancePolicyCartItem") {
                const extensionInsurancePolicyCartItem = orderItem as any
                vehicles.push(extensionInsurancePolicyCartItem.snapshotVehicle)
                this.orderItemMap[extensionInsurancePolicyCartItem.snapshotVehicle.tpiVehicleIdentifier] = orderItem
            } else if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem") {
                const ycInsurancePolicyCartItem = orderItem as YCInsurancePolicyOrderItem
                vehicles.push(ycInsurancePolicyCartItem.snapshotVehicle)
                this.orderItemMap[ycInsurancePolicyCartItem.snapshotVehicle.tpiVehicleIdentifier] = orderItem
            }
        }
        this.vehicles = vehicles


        for (const vehicle of this.vehicles) {
            this.vehiclePlateMap[this.getTpiVehicleIdentifier(vehicle)] = this.getVehiclePlateNumber(vehicle)
        }

        this.displayVehicles = this.vehicles

        this.selectVehicles()
        this.displaySummaryPrices()

        if (this.displayPriceSchedules.length === 1) {
            setTimeout(() => {
                if (endDate) {
                    this.endDate = moment(endDate)
                }
                this.onSelectPriceSchedule(this.displayPriceSchedules[0])
            })
        }

    }

    setCurrentOrderItem(orderItem: OrderItem) {
        if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyOrderItem") {
            const thirdPartyInsurancePolicyOrderItem = orderItem as ThirdPartyInsurancePolicyOrderItem
            this.excesses = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.excess
            this.insuranceVehicleCategoryDescription = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.insuranceVehicleCategoryDescription
            this.insuranceCategory = InsuranceCategory.THIRDPARTY
            this.productNumber = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.number
            this.priceSchedules = [thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.priceSchedule]
            this.annualPercentage = null
            this.coveredCountries = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.coveredCountries
            this.insuranceTypeDescription = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.insuranceTypeDescription
            this.annualAmount = thirdPartyInsurancePolicyOrderItem.annualAmount
            this.name = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.name
            this.description = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.description
            this.conditions = thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.conditions
            this.baseCurrency = thirdPartyInsurancePolicyOrderItem.price.currency
            const limits: Limit[] = []
            for (const motorInsuranceLimitDetail of thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.motorInsuranceLimitDetails) {
                const limit = new Limit()
                limit.description = motorInsuranceLimitDetail.description
                limit.value = motorInsuranceLimitDetail.value
                limit.type = motorInsuranceLimitDetail.type
                limit.currency = motorInsuranceLimitDetail.currency
                limit.customisable = false
                limit.valueType = limit.currency ? LimitValueType.STANDARD_AMOUNT : LimitValueType.STANDARD_PERCENTAGE
                limits.push(limit)
            }
            this.limits = limits;
            this.originalLimits = [...this.limits];
            this.provideUnderwriter(thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.underwriterIdentifier)
            this.provideBroker(thirdPartyInsurancePolicyOrderItem.orderThirdPartyInsuranceProduct.brokerIdentifier)
        } else if (orderItem["@class"] == "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsurancePolicyOrderItem") {
            const comprehensiveInsurancePolicyOrderItem = orderItem as ComprehensiveInsurancePolicyOrderItem
            this.excesses = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.excess
            this.insuranceVehicleCategoryDescription = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.insuranceVehicleCategoryDescription
            this.insuranceCategory = InsuranceCategory.COMPREHENSIVE
            this.productNumber = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.number
            this.priceSchedules = [comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.priceSchedule]
            this.annualPercentage = null
            this.coveredCountries = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.coveredCountries
            this.annualAmount = comprehensiveInsurancePolicyOrderItem.price
            this.name = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.name
            this.description = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.description
            this.conditions = comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.conditions
            this.baseCurrency = comprehensiveInsurancePolicyOrderItem.price.currency
            const limits: Limit[] = []
            for (const motorInsuranceLimitDetail of comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.motorInsuranceLimitDetails) {
                const limit = new Limit()
                limit.description = motorInsuranceLimitDetail.description
                limit.value = motorInsuranceLimitDetail.value
                limit.type = motorInsuranceLimitDetail.type
                limit.currency = motorInsuranceLimitDetail.currency
                limit.customisable = false
                limit.valueType = limit.currency ? LimitValueType.STANDARD_AMOUNT : LimitValueType.STANDARD_PERCENTAGE
                limits.push(limit)
            }
            this.limits = limits;
            this.originalLimits = [...this.limits];
            this.provideUnderwriter(comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.underwriterIdentifier)
            this.provideBroker(comprehensiveInsurancePolicyOrderItem.orderComprehensiveInsuranceProduct.brokerIdentifier)
        } else if (orderItem["@class"] == "za.co.magnabc.tpi.retail.insurance.ExtensionInsurancePolicyCartItem") {
            this.isExtensionInsurancePolicyCartItem = true
            const comprehensiveInsurancePolicyOrderItem = orderItem as any
            this.excesses = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.excess
            this.insuranceVehicleCategoryDescription = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.insuranceVehicleCategoryDescription
            this.insuranceCategory = comprehensiveInsurancePolicyOrderItem.category
            this.priceSchedules = [comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.priceSchedule]
            this.annualPercentage = null
            this.coveredCountries = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.coveredCountries
            this.annualAmount = comprehensiveInsurancePolicyOrderItem.price
            this.name = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.name
            this.description = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.description
            this.conditions = comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.conditions
            this.baseCurrency = comprehensiveInsurancePolicyOrderItem.price.currency
            const limits: Limit[] = []
            for (const motorInsuranceLimitDetail of comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.motorInsuranceLimitDetails) {
                const limit = new Limit()
                limit.description = motorInsuranceLimitDetail.description
                limit.value = motorInsuranceLimitDetail.value
                limit.type = motorInsuranceLimitDetail.type
                limit.currency = motorInsuranceLimitDetail.currency
                limit.customisable = false
                limit.valueType = limit.currency ? LimitValueType.STANDARD_AMOUNT : LimitValueType.STANDARD_PERCENTAGE
                limits.push(limit)
            }
            this.limits = limits;
            this.originalLimits = [...this.limits];
            this.provideUnderwriter(comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.underwriterIdentifier)
            this.provideBroker(comprehensiveInsurancePolicyOrderItem.extensionInsuranceProductCartItem.brokerIdentifier)
        }
        this.onSelectPriceSchedule(this.priceSchedules[0])

    }

    initialiseProduct(product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct, vehicles: RetailVehicle[], action: ProductActionEnum, item: Item) {
        this.product = product;
        this.reset()

        this.isExtension = false
        this.vehicles = vehicles
        this.action = action
        this.item = item
        this.excesses = product.excess;
        this.insuranceCategory = ProductTypePipe.isThirdParty(product) ? InsuranceCategory.THIRDPARTY : InsuranceCategory.COMPREHENSIVE
        this.productNumber = product.number
        this.annualPercentage = ProductTypePipe.isComprehensive(product) ? (product as ComprehensiveInsuranceProduct).annualPercentage : null
        this.coveredCountries = product.coveredCountries
        this.insuranceVehicleCategoryDescription = product.insuranceVehicleCategory ? product.insuranceVehicleCategory.description : null
        this.insuranceTypeDescription = product instanceof ThirdPartyInsuranceProduct ? product.insuranceType.description : null
        this.annualAmount = ProductTypePipe.isThirdParty(product) ? (product as ThirdPartyInsuranceProduct).annualAmount : null
        this.name = product.name
        this.description = product.description
        this.conditions = product.conditions
        this.baseCurrency = product.baseCurrency
        this.brokerInfo = product['brokerInfo'];

        for (const vehicle of this.vehicles) {
            this.vehiclePlateMap[this.getTpiVehicleIdentifier(vehicle)] = this.getVehiclePlateNumber(vehicle)
        }

        if(product instanceof YCInsuranceProduct){
            this.limits = JSON.parse(JSON.stringify(product.limits));
            this.originalLimits = JSON.parse(JSON.stringify(product.limits));
            this.ycPriceSchedules = product.ycPriceSchedules;
            const countries = [... new Set(this.ycPriceSchedules.map((item) => item.country))];
            this.coveredCountries = countries.map((item) => {
                let identifier = new CountryDescriptionIdentifier();
                identifier.description = item;
                return identifier;
            });
        }else{
            this.limits = JSON.parse(JSON.stringify(product.limit));
            this.originalLimits = JSON.parse(JSON.stringify(product.limit));
            this.priceSchedules = product.priceSchedules;
        }

        if (product instanceof ThirdPartyInsuranceProduct) {
            this.insuranceCategory = InsuranceCategory.THIRDPARTY;
        } else if (product instanceof ComprehensiveInsuranceProduct) {
            this.insuranceCategory = InsuranceCategory.COMPREHENSIVE;
        } else if (product instanceof YCInsuranceProduct) {
            this.insuranceCategory = InsuranceCategory.YELLOWCARD;
        }

        this.provideBroker(product.brokerIdentifier);
        this.displayVehicles = this.vehicles
        this.provideUnderwriter(product.underwriterIdentifier)
        this.selectVehicles()
        this.displaySummaryPrices()

        if (this.displayPriceSchedules.length === 1) {
            setTimeout(() => {
                this.onSelectPriceSchedule(this.displayPriceSchedules[0])
            })
        }

        this.updateFromItem()

    }

    reset() {

        this.policyNumber = null
        this.orderItems = null
        this.isExtension = false
        this.orderItemMap = {}

        this.vehicles = null
        this.action = null
        this.item = null

        this.insuranceVehicleCategoryDescription = null
        this.insuranceTypeDescription = null
        this.annualAmount = null
        this.name = null
        this.description = null
        this.conditions = null
        this.productNumber = null
        this.insuranceCategory = null
        this.priceSchedules = []
        this.displayPriceSchedules = []
        this.broker = null
        this.selectedPriceSchedule = null
        this.annualPercentage = null
        this.coveredCountries = []
        this.baseCurrency = null

        this.excesses = []
        this.limits = []
        this.originalLimits = []

        this.selectedVehicles = null
        this.selectedVehicleMap = {}
        this.visibleVehicleButtonCount = 0
        this.overflowVehicleButtonCount = 0
        this.invalidVehicleSelect = false
        this.unverifiedVehicleMessage = []
        this.filterValue = null
        this.vehicleButtons = null
        this.displayVehicles = null

        this.tpiIdentifier = null
        this.vehicleImageFilesMap = {}
        this.pricePerVehicles = []
        this.removeImages = []

        this.invalidPriceSchedule = false
        this.startDate = moment()
        this.endDate = moment()
        this.showStartDate = false
        this.showEndDate = false
        this.today = moment()
        this.maxEndDate = moment()
        this.minEndDate = moment()

        this.loadingPrice = false
        this.isExtensionInsurancePolicyCartItem = false
        this.vehicleRegistrationFileMap = [];
    }

    updateFromItem() {

        if (this.item) {

            let startDate: Date
            let expiryDate: Date
            let motorInsuranceLimitDetails: MotorInsuranceLimitDetails[] = []
            let priceSchedule: PriceSchedule
            let tpiIdentifier: string
            let vehicleImages: string[] = []

            if ((this.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem) {
                startDate = (this.item as ThirdPartyInsurancePolicyCartItem).startDate
                expiryDate = (this.item as ThirdPartyInsurancePolicyCartItem).expiryDate
                motorInsuranceLimitDetails = (this.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.motorInsuranceLimitDetails
                priceSchedule = (this.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.priceSchedule
                tpiIdentifier = this.getTpiVehicleIdentifier((this.item as ThirdPartyInsurancePolicyCartItem).vehicle)
            } else if ((this.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem) {
                startDate = (this.item as ComprehensiveInsurancePolicyCartItem).startDate
                expiryDate = (this.item as ComprehensiveInsurancePolicyCartItem).expiryDate
                motorInsuranceLimitDetails = (this.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.motorInsuranceLimitDetails
                priceSchedule = (this.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.priceSchedule
                tpiIdentifier = this.getTpiVehicleIdentifier((this.item as ComprehensiveInsurancePolicyCartItem).vehicle)
                vehicleImages = (this.item as ComprehensiveInsurancePolicyCartItem).vehicleImages
            } else if ((this.item as YCInsurancePolicyCartItem).ycInsuranceProductCartItem){
                const item  = this.item as YCInsurancePolicyCartItem;
                    startDate = item.startDate;
                    expiryDate = item.expiryDate;
                    tpiIdentifier = this.getTpiVehicleIdentifier(item.vehicle);
                    this.selectedYCPriceSchedules = item.ycInsuranceProductCartItem.ycPriceSchedules;
                    this.vehicleRegistrationFileMap = [];
                    this.addYCScannedDocuments(tpiIdentifier, item.scannedDocumentIdentifiers);
                    if(this.product){
                        const product = this.product as YCInsuranceProduct;
                        this.ycPriceIntervals = product.ycPriceIntervals;
                        this.ycPriceSchedules = product.ycPriceSchedules;
                    }
            }

            this.startDate = moment(startDate).startOf('day');
            this.endDate = moment(expiryDate).endOf('day');
            this.onSelectPriceSchedule(priceSchedule)

            for (const itemLimit of motorInsuranceLimitDetails) {
                for (const productLimit of this.limits) {
                    if (itemLimit.type === productLimit.type && itemLimit.description === productLimit.description) {
                        productLimit.value = itemLimit.value
                    }
                }
            }

            if (vehicleImages) {
                this.vehicleImageFilesMap[tpiIdentifier] = []

                for (const vehicleImage of vehicleImages) {
                    this.findScannedDocument(vehicleImage).then((scannedDocument) => {
                        this.vehicleImageFilesMap[tpiIdentifier].push({file: scannedDocument.document, description: scannedDocument.documentType.description, scannedDocument, isBase64: true})
                    })
                }
            }

        }

    }

    private addYCScannedDocuments(tpiIdentifier: string, documentIdentifiers: string[]) {
        documentIdentifiers.forEach((identifier) => {
            let doc = new ScannedDocument();
            doc.documentIdentifier = identifier;
            const criteria = new ScannedDocumentCriteria();
            const scDocIdent = new ScannedDocumentIdentifierIdentifier();
            scDocIdent.documentIdentifier = identifier;
            criteria.scannedDocumentIdentifier = scDocIdent;
            const request = new ProvideScannedDocumentRequest();
            request.criteria = criteria;
            this.scannedDocumentProviderService.provideScannedDocument(request).toPromise()
                .then((data: HttpResponse<any>) => {
                    if (data.body) {
                        const response =
                            this.unMarshallerService.unMarshallFromJson(data.body, ProvideScannedDocumentResponse) as ProvideScannedDocumentResponse;
                        doc = response.scannedDocuments.at(0);
                        doc.document = this.prependBase64Tag(doc);
                        const file = this.dataURLtoFile(doc.document, doc.documentName);
                        const newItem = {
                            document: doc,
                            tpiId: tpiIdentifier,
                            file
                        };
                        this.vehicleRegistrationFileMap.push(newItem);
                    }
                });
        });
    }

    private prependBase64Tag(scannedDocument: ScannedDocument): any {
        let tag;
        if(!scannedDocument.documentName.toLowerCase().includes('.pdf')){
            tag = ('data:image;base64, ' + scannedDocument.document.toString()) as any;
        }else{
            tag = ('data:application/pdf;base64, ' + scannedDocument.document.toString()) as any;
        }
        return tag;
    }

    private dataURLtoFile(dataurl, filename): File {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    calculatePrice() {
        this.total = null
        this.minimumPolicyValue = null
        if (this.orderItems) {
            this.calculateRealTimeExtensionPrice()
        } else if (this.selectedPriceSchedule && this.insuranceCategory === InsuranceCategory.THIRDPARTY) {
            this.calculateRealTimePrice()
        } else if (this.ycPriceSchedules && this.insuranceCategory === InsuranceCategory.YELLOWCARD) {
            this.calculateRealTimeYCPrice()
            this.buildBreakdownForYcProduct();
        }
    }

    buildBreakdownForYcProduct() {
        this.ycPriceBreakdown = new Map<string, any[]>();
        this.selectedVehicles.forEach(vehicle => {
            this.selectedYCPriceSchedules.forEach(pricing => {
                const trimCategory = pricing.insuranceVehicleCategorySubDescription.includes(" - ")?
                pricing.insuranceVehicleCategorySubDescription.split(" - ")[1] : pricing.insuranceVehicleCategorySubDescription;
                if (trimCategory === vehicle.usage) {
                    if (this.ycPriceBreakdown.get(pricing.country)) {
                        this.ycPriceBreakdown.get(pricing.country).push(
                            {  price: { amount: pricing.price, currency: pricing.currency }, vehicleIdentifier: Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)}
                        );
                    } else {
                        this.ycPriceBreakdown.set(pricing.country, [{ price: {amount: pricing.price, currency: pricing.currency }, vehicleIdentifier: Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)}])
                    }
                }
            })
        })
    }


    setThirdPartyPriceScheduleAdditionalVehicle(value: boolean): void {
        if(this.selectedPriceSchedule){
            this.selectedPriceSchedule.additionalVehicleToPolicy = value;
        }
    }

    calculateRealTimePrice() {

        const calculateRealTimePriceRequest = new CalculateRealTimePriceRequest()
        calculateRealTimePriceRequest.motorInsuranceDetails = this.getMotorInsuranceDetails()
        calculateRealTimePriceRequest.limit = this.originalLimits
        if (this.insuranceAssistedQuickTransactManagerService.retailProfile) {
            calculateRealTimePriceRequest.legalEntityIdentifier = this.insuranceAssistedQuickTransactManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        } else {
            calculateRealTimePriceRequest.legalEntityIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        }
        this.loadingPrice = true
        this.realTimePricingCalculationManagerService.calculateRealTimePrice(calculateRealTimePriceRequest).then((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, CalculateRealTimePriceResponse) as CalculateRealTimePriceResponse
            this.pricePerVehicles = response.pricePerVehicle
            this.total = response.totalPrice
            this.loadingPrice = false
            this.setThirdPartyPriceScheduleAdditionalVehicle(response.insurancePolicyFound);
        });

    }

    getSelectedOrderItems() {
        const orderItems = []
        for (const vehicle of this.selectedVehicles) {
            orderItems.push(this.orderItemMap[this.getTpiVehicleIdentifier(vehicle)])
        }
        return orderItems
    }

    calculateRealTimeExtensionPrice() {

        const calculateRealTimeExtensionPriceRequest = new CalculateRealTimeExtensionPriceRequest()
        calculateRealTimeExtensionPriceRequest.policyNumber = this.policyNumber
        calculateRealTimeExtensionPriceRequest.expiryDate = this.endDate.format(environment.formatDateServer) as any
        if (this.isExtensionInsurancePolicyCartItem) {
            calculateRealTimeExtensionPriceRequest.insuranceCartItem = this.getSelectedOrderItems()[0];
        } else {
            calculateRealTimeExtensionPriceRequest.orderItem = this.getSelectedOrderItems()[0];
        }

        this.loadingPrice = true
        this.realTimePricingCalculationManagerService.calculateRealTimeExtensionPrice(calculateRealTimeExtensionPriceRequest).then((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, CalculateRealTimeExtensionPriceResponse) as CalculateRealTimeExtensionPriceResponse
            this.pricePerVehicles = [];
            this.pricePerVehicles.push(response.pricePerVehicle);
            this.loadingPrice = false
        });

    }

    getMotorInsuranceDetails(): MotorInsuranceDetails {

        const productNumberIdentifier = new ProductNumberIdentifier()
        productNumberIdentifier.number =  this.productNumber

        const motorInsuranceDetails = new MotorInsuranceDetails()
        motorInsuranceDetails.motorInsuranceLimitDetails = []
        motorInsuranceDetails.startDate = this.startDate.startOf('day').format(environment.formatDateServer) as any
        motorInsuranceDetails.expiryDate = this.endDate.endOf('day').format(environment.formatDateServer) as any
        motorInsuranceDetails.selectedPriceSchedule = this.selectedPriceSchedule
        motorInsuranceDetails.productIdentifier = productNumberIdentifier
        motorInsuranceDetails.motorInsuranceVehicleDetails = []
        motorInsuranceDetails.motorInsuranceLimitDetails = []
        motorInsuranceDetails.excesses = JSON.parse(JSON.stringify(this.excesses))

        // Add cents
        for (const excess of motorInsuranceDetails.excesses) {
            (excess as UniversalExcess | ClaimSpecificExcess).value.amount = parseInt(((excess as UniversalExcess | ClaimSpecificExcess).value.amount as any), 10) * 100
        }

        for (const limit of this.limits) {
            const motorInsuranceLimitDetails = new MotorInsuranceLimitDetails()
            motorInsuranceLimitDetails.value = limit.value
            motorInsuranceLimitDetails.currency = limit.currency
            motorInsuranceLimitDetails.type = limit.type
            motorInsuranceLimitDetails.description = limit.description
            motorInsuranceDetails.motorInsuranceLimitDetails.push(motorInsuranceLimitDetails)
        }

        for (const vehicle of this.selectedVehicles) {
            const vehicleIdentifier = Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)
            const motorInsuranceVehicleDetails = new MotorInsuranceVehicleDetails()
            motorInsuranceVehicleDetails.vehicleIdentifier = vehicleIdentifier
            motorInsuranceDetails.motorInsuranceVehicleDetails.push(motorInsuranceVehicleDetails)
        }

        return motorInsuranceDetails

    }

    clearVehicles(verifiedVehicles: VehicleIdentifier[]): void {
        if (verifiedVehicles.length > 0) {
            const newVehicleList = []
            for (const vehicle of this.vehicles) {
                if (!this.selectedVehicleMap[this.getTpiVehicleIdentifier(vehicle)] || !this.isVerified(vehicle, verifiedVehicles)) {
                    newVehicleList.push(vehicle)
                } else if (this.selectedVehicleMap[this.getTpiVehicleIdentifier(vehicle)] && this.isVerified(vehicle, verifiedVehicles)) {
                    this.toggleVehicle(this.selectedVehicleMap[this.getTpiVehicleIdentifier(vehicle)])
                }
            }
            this.vehicles = newVehicleList
            this.insuranceProductFinalisationManagerService.vehicles = this.vehicles
            this.filterValue = ''
            this.filterVehicles()
            this.selectVehicles()
        } else {
            this.insuranceProductFinalisationManagerService.vehicles = []
        }
    }

    isVerified(vehicle: RetailVehicle, verifiedVehicles: VehicleIdentifier[]) {
        for (const verifiedVehicle of verifiedVehicles) {
            if (this.getTpiVehicleIdentifier(vehicle) === (verifiedVehicle as TpiVehicleIdentifier).number) {
                return true
            }
        }
        return false
    }

    toggleVehicle(vehicle: Vehicle | RetailVehicle) {
        if (this.vehicles.length === 1) {
            return
        }
        this.invalidVehicleSelect = false
        const tpiIdentifier = this.getTpiVehicleIdentifier(vehicle)
        if (this.isExtension) {
            this.selectedVehicleMap = {}
            this.selectedVehicleMap[tpiIdentifier] = vehicle
            this.selectedVehicle.next(vehicle)
        } else {
            if (this.selectedVehicleMap[tpiIdentifier]) {
                delete this.selectedVehicleMap[tpiIdentifier]
                this.deselectedVehicle.next(tpiIdentifier)
            } else {
                this.selectedVehicleMap[tpiIdentifier] = vehicle
                this.selectedVehicle.next(vehicle)
            }
        }
        this.selectedVehicles = []
        for (const key in this.selectedVehicleMap) {
            this.selectedVehicles.push(this.selectedVehicleMap[key] as RetailVehicle)
        }
        if (this.isExtension) {
            this.setCurrentOrderItem(this.orderItemMap[tpiIdentifier])
        }
        var orderItem = this.orderItemMap[tpiIdentifier];
        if (orderItem instanceof ComprehensiveInsurancePolicyOrderItem) {
            this.displayPriceSchedules = [];
            this.displayPriceSchedules.push(orderItem.orderComprehensiveInsuranceProduct.priceSchedule);
        } else if (orderItem instanceof ThirdPartyInsurancePolicyOrderItem) {
            this.displayPriceSchedules = [];
            this.displayPriceSchedules.push(orderItem.orderThirdPartyInsuranceProduct.priceSchedule);
        } else if (orderItem instanceof YCInsurancePolicyOrderItem) {
             this.ycPriceSchedules = [];
            //  this.ycPriceSchedules.push(orderItem.orderTYCInsuranceProduct.ycPriceSchedule);
        }

        this.calculatePrice()
    }

    filterVehicles(): void {

        let filterVehicles = this.vehicles
        if (this.filterValue && this.filterValue !== "") {
            filterVehicles = this.vehicles.filter((vehicle) => {
                return this.getVehiclePlateNumber(vehicle).toLowerCase().indexOf(this.filterValue) !== -1 || !this.filterValue
            })
        }

        this.displayVehicles = filterVehicles

        setTimeout(() => {
            this.updateVehicleSelectDisplay()
        })

    }

    onShowAllPrices() {
        this.displayPriceSchedules = this.priceSchedules
        this.selectedPriceSchedule = null
        this.total = null
        return false
    }

    onSelectPriceSchedule(priceSchedule: PriceSchedule) {

        this.invalidPriceSchedule = false

        if (!this.selectedPriceSchedule || this.selectedPriceSchedule.priceScheduleId !== priceSchedule.priceScheduleId) {

            this.selectedPriceSchedule = priceSchedule
            this.displayPriceSchedules = [this.selectedPriceSchedule]

            if (this.selectedPriceSchedule) {
                this.updateCalendars();
            } else {
                this.minEndDate = moment(this.startDate);
                this.maxEndDate = moment(this.startDate).add(environment.daysInYear, 'days');
                // this.maxEndDate = moment(this.startDate).add(1, 'year');
            }
        }

        setTimeout(() => {
            this.calculatePrice()
        }, 100)

    }

    selectVehicles() {
        if (this.vehicles.length > 0) {
            if (this.isExtension) {
                if (!this.selectedVehicleMap[this.getTpiVehicleIdentifier(this.vehicles[0])]) {
                    this.selectedVehicle.next(this.vehicles[0])
                }
                this.selectedVehicleMap[this.getTpiVehicleIdentifier(this.vehicles[0])] = this.vehicles[0]
                this.selectedVehicles = [this.vehicles[0]]
            } else {
                for (const vehicle of this.vehicles) {
                    if (!this.selectedVehicleMap[this.getTpiVehicleIdentifier(vehicle)]) {
                        this.selectedVehicle.next(vehicle)
                    }
                    this.selectedVehicleMap[this.getTpiVehicleIdentifier(vehicle)] = vehicle
                }
                this.selectedVehicles = this.vehicles
            }
            if (this.isExtension) {
                this.setCurrentOrderItem(this.orderItemMap[this.getTpiVehicleIdentifier(this.vehicles[0])])
            }
        }
        this.calculatePrice()
    }

    updateVehicleSelectDisplay() {
        let visibleVehicleButtonCount = 0
        let overflowVehicleButtonCount = 0
        for (const vehicleButton of this.vehicleButtons) {
            if (vehicleButton.offsetTop < 45) {
                visibleVehicleButtonCount++
            } else {
                overflowVehicleButtonCount++
            }
        }
        this.visibleVehicleButtonCount = visibleVehicleButtonCount
        this.overflowVehicleButtonCount = overflowVehicleButtonCount
    }

    displaySummaryPrices() {
        this.displayPriceSchedules = []
        if (this.priceSchedules.length > 3) {
            for (let i = 0; i < 3; i++) {
                this.displayPriceSchedules.push(this.priceSchedules[i])
            }
        } else {
            this.displayPriceSchedules = this.priceSchedules
        }
    }

    getTpiVehicleIdentifier(vehicle: any): string {
        if (vehicle.vehicleIdentifiers) {
            return Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers).number
        } else if (vehicle.tpiVehicleIdentifier) {
            return vehicle.tpiVehicleIdentifier
        }
        throw new Error('No tpi vehicle identifier')
    }

    getVehiclePlateNumber(vehicle: any): string {
        if (vehicle.vehicleIdentifiers) {
            return Utils.getVehiclePlateNumber(vehicle)
        } else if (vehicle.plateNumber) {
            return vehicle.plateNumber
        }
        throw new Error('No plate number identifier')
    }

    updateCalendars() {
        if (!this.isExtension) {
            if(this.selectedPriceSchedule){
                this.updateCalenderInformation(this.selectedPriceSchedule);
            }else{
                //Default the end date (intended for yellow card products)
                this.maxEndDate = this.startDate.clone().add(1, 'years').add(-1, 'days');
                this.endDate = this.maxEndDate.endOf('day');
            }
        } else {

            const selectedOrderItem: ThirdPartyInsurancePolicyOrderItem | ComprehensiveInsurancePolicyCartItem | YCInsurancePolicyCartItem = this.orderItemMap[this.getTpiVehicleIdentifier(this.selectedVehicles[0])] as any
            let startDate = new Date(moment(selectedOrderItem.startDate).startOf('day').toDate());
            let expiryDate = new Date(moment(selectedOrderItem.expiryDate).endOf('day').toDate());

            const monthsTo = this.selectedPriceSchedule.lengthOfExtension.months ? this.selectedPriceSchedule.lengthOfExtension.months : 0
            const daysTo = this.selectedPriceSchedule.lengthOfExtension.days ? this.selectedPriceSchedule.lengthOfExtension.days : 0

            if (this.isExtensionInsurancePolicyCartItem) {

                if (this.selectedPriceSchedule.durationType === DurationType.EXACT) {

                    this.minEndDate = moment(expiryDate)
                    this.maxEndDate = moment(expiryDate)

                } else if (this.selectedPriceSchedule.durationType === DurationType.INTERVAL) {

                    this.minEndDate = moment(startDate)
                    this.maxEndDate = moment(startDate).add(monthsTo, 'months').add(daysTo, 'days').add(-1, 'days')

                }

            } else {

                if (this.selectedPriceSchedule.durationType === DurationType.EXACT) {

                    this.minEndDate = moment(startDate).add(12, 'months').add(-1, 'day')
                    this.maxEndDate = moment(startDate).add(12, 'months').add(-1, 'day')

                } else if (this.selectedPriceSchedule.durationType === DurationType.INTERVAL) {

                    this.minEndDate = moment(expiryDate).isBefore(new Date())? moment(new Date()) : moment(expiryDate).add(1, 'day');
                    this.maxEndDate = moment(expiryDate).add(monthsTo, 'months').add(daysTo, 'days').add(-1, 'day')
                    this.intervalMaxEndDate = moment(expiryDate).add(monthsTo, 'months').add(daysTo, 'days').add(-1, 'day')
                }

            }

        }

        if (this.action == ProductActionEnum.ADD_TO_CART) {
            this.endDate = this.maxEndDate.endOf('day');
            if (this.isExtension && this.selectedPriceSchedule.durationType === DurationType.INTERVAL) {
                this.maxEndDate = this.intervalMaxEndDate.endOf('day');
            }
        }
    }

    private updateCalenderInformation(schedule: PriceSchedule | YCPriceSchedule): void {
        let selectedPriceSchedule: PriceSchedule;
        if(schedule instanceof YCPriceSchedule){

        }else{
            selectedPriceSchedule = schedule;
            if (selectedPriceSchedule.durationType === DurationType.EXACT) {

                const months = selectedPriceSchedule.exactDuration.months ? selectedPriceSchedule.exactDuration.months : 0
                const days = selectedPriceSchedule.exactDuration.days ? selectedPriceSchedule.exactDuration.days : 0

                this.maxEndDate = moment(this.startDate).add(months, 'months').add(days, 'days')
                this.minEndDate = moment(this.startDate).add(months, 'months').add(days, 'days')

                if (months > 0) {
                    this.minEndDate.add(-1, 'days')
                    this.maxEndDate.add(-1, 'days')
                }

            } else if (this.selectedPriceSchedule.durationType === DurationType.INTERVAL) {

                const monthsFrom = selectedPriceSchedule.intervalDurationFrom.months ? selectedPriceSchedule.intervalDurationFrom.months : 0
                const daysFrom = selectedPriceSchedule.intervalDurationFrom.days ? selectedPriceSchedule.intervalDurationFrom.days : 0

                const monthsTo = selectedPriceSchedule.intervalDurationTo.months ? selectedPriceSchedule.intervalDurationTo.months : 0
                const daysTo = selectedPriceSchedule.intervalDurationTo.days ? selectedPriceSchedule.intervalDurationTo.days : 0

                this.minEndDate = moment(this.startDate).add(monthsFrom, 'months').add(daysFrom, 'days')
                this.maxEndDate = moment(this.startDate).add(monthsTo, 'months').add(daysTo, 'days')

                if (monthsFrom > 0) {
                    this.minEndDate = this.minEndDate.add(-1, 'days')
                }

                if (monthsTo > 0) {
                    this.maxEndDate = this.maxEndDate.add(-1, 'days')
                }
                this.endDate = this.maxEndDate.endOf('day');
            }
        }
    }

    findScannedDocument(documentIdentifier: string): Promise<ScannedDocument> {
        return new Promise<ScannedDocument>((resolve) => {

            const scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier();
            scannedDocumentIdentifierIdentifier.documentIdentifier = documentIdentifier;

            const scannedDocumentCriteria = new ScannedDocumentCriteria();
            scannedDocumentCriteria.scannedDocumentIdentifier = scannedDocumentIdentifierIdentifier;

            const provideScannedDocumentRequest = new ProvideScannedDocumentRequest();
            provideScannedDocumentRequest.criteria = scannedDocumentCriteria;

            this.scannedDocumentProviderService.provideScannedDocument(provideScannedDocumentRequest).toPromise().then((data) => {
                const response =
                    this.unMarshallerService.unMarshallFromJson(data.body, ProvideScannedDocumentResponse) as ProvideScannedDocumentResponse;
                if (response.scannedDocuments.length > 0) {
                    resolve(response.scannedDocuments[0]);
                } else {
                    resolve(null);
                }
            }).catch(() => {
                resolve(null);
            });

        });
    }

    provideBroker(brokerIdentifier: BrokerIdentifier) {

        const provideBrokerRequest = new ProvideBrokerInformationRequest()
        const brokerLegalEntityCriteria = new BrokerLegalEntityCriteria()
        brokerLegalEntityCriteria.legalEntity = (brokerIdentifier as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier
        provideBrokerRequest.criteria = brokerLegalEntityCriteria

        this.brokerInformationProviderService.provideBroker(provideBrokerRequest).subscribe((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse
            if (response.brokers.length > 0) {
                this.broker = response.brokers[0]
            }
        })

    }

    provideUnderwriter(underwriterIdentifier: UnderwriterIdentifier) {
        this.underwriterInformationProviderService.getUnderwriter(underwriterIdentifier, (underwriter) => {
            this.underwriter = underwriter
        })
    }

    setDateForYCProduct(product: YCInsuranceProduct): void {
        let startDate: Date = new Date();
        let expiryDate: Date = new Date();

        const intervals = product.ycPriceIntervals.filter(interval => interval.durationType === DurationType.INTERVAL);
        if (intervals.length > 0) {
            const firstInterval = intervals.sort((a, b) => a.intervalDurationFrom.days - b.intervalDurationFrom.days)[0];
            const lastInterval = intervals[intervals.length - 1];
            expiryDate.setDate(expiryDate.getDate() + (firstInterval.intervalDurationTo.days - firstInterval.intervalDurationFrom.days));

            this.startDate = moment(startDate).startOf('day');
            this.endDate = moment(expiryDate).endOf('day');
            this.maxEndDate = moment(this.startDate).add(lastInterval.intervalDurationTo.days, 'days');
        }else {
            expiryDate.setDate(moment().add(1, 'years').date());
            this.startDate = moment(startDate).startOf('day');
            this.endDate = moment(expiryDate).endOf('day');
            this.maxEndDate = moment(this.startDate).add(environment.daysInYear, 'days');
        }
    }

    updateDatesForYCProduct(): void{
        this.minEndDate = moment(this.startDate);
        this.maxEndDate = moment(this.startDate).add(environment.daysInYear, 'days');
        this.endDate = this.maxEndDate.endOf('day');

        // this.endDate = this.endDate.endOf('day');
        // this.startDate = this.startDate.startOf('day');

        if(this.endDate.isAfter(this.maxEndDate)){
            this.endDate = this.maxEndDate;
        }
    }

    /**
     * Get the best fitting interval based on the duration of the policy
     * @param ycPriceIntervals - All the intervals for the product
     * @returns The best fitting interval
     */
    private getFittingInterval(ycPriceIntervals: YCPriceInterval[]): YCPriceInterval {
        let result: YCPriceInterval;
        if (ycPriceIntervals.length > 0 && ycPriceIntervals[0].durationType === DurationType.PRO_RATA) {
            result = ycPriceIntervals[0];
        } else {
            const duration = this.endDate.diff(this.startDate, 'days') + 1;
            if (duration == 366) {return ycPriceIntervals[ycPriceIntervals.length - 1];}

            let sumDuration = 0;
            //Get the best fitting interval based on the incremental value of the duration to days
            ycPriceIntervals.sort((a, b) => a.intervalDurationFrom.days - b.intervalDurationFrom.days);
            result = ycPriceIntervals.find((interval) => {
                sumDuration = interval.intervalDurationTo.days;
                if (duration <= sumDuration) {
                    return interval;
                }
            });
        }

        result.proRata = !result.proRata ? false : result.proRata;
        return result;
    }

    calculateRealTimeYCPrice(): void {
        this.total = null;
        this.minimumPolicyValue = null;

        if (this.ycPriceSchedules.length === 0) {
            return;
        }
        if(this.selectedYCPriceSchedules.length === 0){
            return;
        }
        if (this.ycPriceIntervals.length === 0) {
            return;
        }

        this.ycMotorInsuranceDetails = this.getYCMotorInsuranceDetails();

        const noDocuments = this.ycMotorInsuranceDetails.ycMotorVehicleInsuranceDetails.flatMap(item => {
            return item.scannedDocumentIdentifiers.length > 0;
        }).includes(false);

        this.minimumPolicyValue = this.ycMotorInsuranceDetails.ycPriceInterval.minimumPolicyValue;

        let calculateRealTimeYCPriceRequest = new CalculateRealTimeYCPriceRequest();
        calculateRealTimeYCPriceRequest.limits = this.originalLimits;
        this.limits.forEach(item => {
            item.proRata = item.proRata ? item.proRata : false;
            item.premiumAdjustmentCategory = item.premiumAdjustmentCategory ? item.premiumAdjustmentCategory : PremiumAdjustmentCategory.FIXED;
            item.premiumFixedAdjustmentValue = item.premiumFixedAdjustmentValue ? item.premiumFixedAdjustmentValue : 0;
            item.increment = item.increment ? item.increment : 0;
        });
        calculateRealTimeYCPriceRequest.ycMotorInsuranceDetails = this.getYCMotorInsuranceDetails();
        this.realTimePricingCalculator.calculateRealTimeYCPrice(calculateRealTimeYCPriceRequest).then((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, CalculateRealTimeYCPriceResponse) as CalculateRealTimeYCPriceResponse;
            this.pricePerVehicles = response.pricePerVehicles;
            this.total = new Money();
            this.total.amount = response.totalPrice.amount;
            this.total.currency = response.totalPrice.currency;
        })
    }

    getYCMotorInsuranceDetails(): YCMotorInsuranceDetails {
        const ycLimitDetails: YCLimitDetails[] = this.limits.map((limit) => {
            let ycLimit = new YCLimitDetails();
            ycLimit.description = limit.description;
            ycLimit.currency = limit.currency;
            ycLimit.value = limit.value;
            ycLimit.type = limit.type;
            return ycLimit;
        });

        let ycMotorInsuranceDetails = new YCMotorInsuranceDetails();
        ycMotorInsuranceDetails.excesses = this.excesses;
        ycMotorInsuranceDetails.ycLimitDetails = ycLimitDetails;
        ycMotorInsuranceDetails.ycPriceSchedules = this.selectedYCPriceSchedules;
        ycMotorInsuranceDetails.ycPriceInterval = this.getFittingInterval(this.ycPriceIntervals);

        ycMotorInsuranceDetails.startDate = this.startDate.startOf('day').format(environment.formatDateServer) as any;
        ycMotorInsuranceDetails.expiryDate = this.endDate.endOf('day').format(environment.formatDateServer) as any;

        let productIdentifier = new ProductNumberIdentifier();
        productIdentifier.number = this.productNumber;
        ycMotorInsuranceDetails.productIdentifier = productIdentifier;
        ycMotorInsuranceDetails.vehicleIdentifiers = this.selectedVehicles.map((vehicle) => {
            let vin = new VehicleIdentificationNumber();
            vin.number = vehicle.vehicleIdentificationNumber;
            return vin;
        });

        ycMotorInsuranceDetails.ycMotorVehicleInsuranceDetails = this.getYCMotorVehicleInsuranceDetails();


        return ycMotorInsuranceDetails;
    }

    getYCMotorVehicleInsuranceDetails(): YCMotorVehicleInsuranceDetails[] {
        const ycMotorVehicleInsuranceDetails: YCMotorVehicleInsuranceDetails[] = [];
        for (const vehicle of this.selectedVehicles) {
            const vehicleIdentifier = Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers);
            let ycMotorVehicleInsuranceDetail = new YCMotorVehicleInsuranceDetails();
            ycMotorVehicleInsuranceDetail.scannedDocumentIdentifiers = [];
            ycMotorVehicleInsuranceDetail.vehicleIdentifier = vehicleIdentifier;
            const doc = this.vehicleRegistrationFileMap.find(item => item.tpiId === vehicleIdentifier.number);
            if (doc) {
                const documentId = new ScannedDocumentIdentifierIdentifier();
                documentId.documentIdentifier = doc.document.documentIdentifier;
                ycMotorVehicleInsuranceDetail.scannedDocumentIdentifiers.push(documentId);
            }
            ycMotorVehicleInsuranceDetails.push(ycMotorVehicleInsuranceDetail);
        }
        return ycMotorVehicleInsuranceDetails;
    }

    getVehicleCategoryDescriptions() {
        const filteredDescriptions = this.selectedVehicles.map(vehicle => this.getVehicleCategories(vehicle)).flat();
        return [...new Set(filteredDescriptions)];
    }

    getVehicleCategories(vehicle: RetailVehicle): string[] {
        if (vehicle.insuranceVehicleCategories) {
            return vehicle.insuranceVehicleCategories.map(category => {
                if (category.description === category.subDescription) {
                    return `${category.description}`
                } else {
                    return `${category.description} - ${category.subDescription}`
                }
            })
        } else {
            const result = [];
            const items = this.RetailProfileFinalisationManagerService.retailProfile.cart.items;
            if (items) {
                items.forEach((item) => {
                    if ((item as YCInsurancePolicyCartItem).vehicle) {
                        const testItem = (item as YCInsurancePolicyCartItem);
                        const testVehicle = testItem.vehicle;
                        if (testVehicle.vehicleIdentificationNumber === vehicle.vehicleIdentificationNumber) {
                            result.push(`${testItem.ycInsuranceProductCartItem.insuranceVehicleCategoryDescription} - ${testItem.ycInsuranceProductCartItem.insuranceVehicleCategorySubDescription}`);
                        }
                    }
                });
            }
            return result;
        }
    }
}


export interface IYCVehicleDocument {
    tpiId: string;
    document: ScannedDocument;
    file?: File;
}
