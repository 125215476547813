import { Money } from "../../../financial/models/money.model";
import { JournalEntryType } from "../../models/journal-entry-type.model";

export class SalesSummary
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.SalesSummary";
    }

    public totalSalesRevenue: Money;
    public totalSaleInvolved: number;
    public averageItemSaleRevenue: Money;
    public balance: Money;
    public balanceType: JournalEntryType;

}