import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {CreateIdentifierRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class IdentifierManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {
  }

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  createIdentifier(createIdentifierRequest: CreateIdentifierRequest) {

      const URI = this.runtimeConfigurationService.apiBaseUrl + '/identification/identifiermanager/createidentifier';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(createIdentifierRequest);
      return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }
}
