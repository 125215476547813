import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {CreateSubscriptionRequest, DeleteSubscriptionRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SubscriptionManagerService {
    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    createSubscription(createSubscriptionRequest: CreateSubscriptionRequest) {

        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const createSubscriptionURI = this.runtimeConfigurationService.apiBaseUrl + '/security/authorisation/subscriptionmanager/createsubscription';

        const jsonCreateSubscriptionRequest = this.marshallerService.marshallObjectToJSON(createSubscriptionRequest);

        return this.http.post<any>(createSubscriptionURI, jsonCreateSubscriptionRequest, options).toPromise();
    }

    deleteSubscription(deleteSubscriptionRequest: DeleteSubscriptionRequest): Promise<any> {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const uri = this.runtimeConfigurationService.apiBaseUrl + '/security/authorisation/subscriptionmanager/deletesubscription';
        const jsonDeleteSubscriptionRequest = this.marshallerService.marshallObjectToJSON(deleteSubscriptionRequest);
        return this.http.post<any>(uri, jsonDeleteSubscriptionRequest, options).toPromise();
    }
}
