import { ServiceGroup } from './service-group.model';
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class Subscription {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.Subscription";
    }

    public expiryDate: Date;
    public name: string;
    public ownedBy: TpiIdentifier;
    public status: string;
    public subscriptionIdentifier: string;
    public usedBy: TpiIdentifier;
    public serviceGroup: ServiceGroup;
}
