
import { Criteria } from "../../../criteria/models/criteria.model";
import { RetailProfileIdentifier } from "../../profile/identification/retail-profile-identifier.model";
import { OrderIdentifier } from "../identification/models/order-identifier.model";

export class RetailProfileOrderDateCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.RetailProfileOrderDateCriteria";
    }
    
    public dateFrom: Date;
    public dateTo:Date;
    public retailProfileIdentifier: RetailProfileIdentifier;
    public orderIdentifiers: OrderIdentifier[] = [];
}