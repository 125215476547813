import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {CreateProposalRequest} from '@magnabc/tpi'
import {Observable} from 'rxjs'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class ProposalManagerService {

    basePath = '/retail/checkout/proposalmanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    createProposal(createProposalRequest: CreateProposalRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/createproposal';
        return this.http.post(path, createProposalRequest, this.options);
    }
}
