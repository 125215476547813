import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {CardPaymentOption} from "../../../../../models/card-payment-option.model";
import {EmailAddress} from "../../../../../../../../entity/models/email-address.model";
import {TransactionToken} from "../../../../../models/transaction-token.model";

export class InitialiseProcessCardPaymentResponse extends Response {
    constructor (){
        super();
    }

    cardPaymentOptions: CardPaymentOption[] = [];
    emailAddress: EmailAddress;
    dpoPaymentProcess: string;
    transactionToken: TransactionToken;

}
