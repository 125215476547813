import { VehicleIdentifier } from '../../../vehicle/identification/models/vehicle-identifier.model';
import {Engine} from '../../../vehicle/models/engine.model';
import {InsuranceVehicleCategory} from "../../insurance/setup/models/insurance-vehicle-category.model";
export class RetailVehicle {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.profile.RetailVehicle";
    }

    public vehicleRegisterNumber: string;
    public vehicleIdentificationNumber: string;
    public manufactureDate: Date;
    public introductionDate: Date;
    public tare: number;
    public grossVehicleMass: number;
    public seatingCapacity: number;
    public standingCapacity: number;
    public colour: string;
    public make: string;
    public model: string;
    public usage: string;
    public vehicleType: string;
    public category: string;
    public propulsion: string;
    public engine: Engine;
    public vehicleIdentifiers: VehicleIdentifier[] = [];
    public insuranceVehicleCategories: InsuranceVehicleCategory[] = [];
    public countryOfRegistration: string;

}
