export class ImportVehicle {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.profile.ImportVehicle";
    }

    rowNumber: number;
    vehicleIdentificationNumber: string;
    plateNumber: string;
    manufactureDate: string;
    engineNumber: string;
    category: string;
    vehicleType: string;
    usage: string;
    propulsion: string;
    seatingCapacity: string;
    grossVehicleMass: string;
    countryOfRegistration: string;
    make: string;
    model: string;
    colour: string;
}