import {Component, OnInit, ViewChild} from '@angular/core'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {Router} from '@angular/router'
import {
    MakeModelTrimProviderService
} from '../../../http.services/vehicle/makemodeltrim/make-model-trim-provider/make-model-trim-provider.service'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {AllMakeCriteria, ProvideVehicleMakesRequest, ProvideVehicleMakesResponse, UnMarshallerService} from "@magnabc/tpi";

declare const jQuery: any;

@Component({
  selector: 'app-vehicle-make',
  templateUrl: './vehicle-make.component.html',
  styleUrls: ['./vehicle-make.component.scss']
})
export class VehicleMakeComponent implements OnInit {

    rows = [];
    columns = [
        { prop: 'name' },
        { name: 'edit' }
    ];

    temp = [];

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private title: Title,
                private router: Router,
                private makeModelTrimProviderService: MakeModelTrimProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {

        title.setTitle(`Vehicle Makes - Console - ${this.runtimeConfigurationService.title}`);

        this.temp = this.rows;

        const criteria = new AllMakeCriteria();
        const request = new ProvideVehicleMakesRequest();
        request.criteria = criteria;

        this.makeModelTrimProviderService.provideMakes(request).then(httpResponse => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleMakesResponse) as ProvideVehicleMakesResponse);

                this.rows = response.makes;
                this.rows.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
                this.temp = this.rows;
            }
        });

    }

    ngOnInit(): void {
        this.onToggleSearch();
    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter(function(d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    onSelect(event): void {
        this.router.navigate(['/makemodeltrim/model'], {queryParams: {make: event.selected[0].name}}).then();
    }

    onEdit(event, id): boolean {
        event.stopPropagation();
        this.router.navigate(['/makemodeltrim/' + id], {queryParams: {type: 'make'}}).then();
        return false;
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/makemodeltrim/create'], {queryParams: {type: 'make'}}).then();
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

}
