/**
 * Created by dylancrombie on 2018/02/14.
 */
export interface IDropdown {
    value: any;
    name: string;
}

export class Dropdown implements IDropdown {
    value: any;
    name: string;

    constructor(value: any, name: string) {
        this.value = value;
        this.name = name;
    }
}
