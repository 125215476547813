import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;

@Component({
    selector: 'app-proposal-history-page',
    templateUrl: './proposal-history-page.component.html',
    styleUrls: ['./proposal-history-page.component.scss']
  })
export class ProposalHistoryPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Proposal History - ${this.runtimeConfigurationService.title}`);
    }

}
