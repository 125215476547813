import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {
    MarshallerService,
    ProvideCountryTaxesInformationRequest,
    ProvideCountryTaxesInformationResponse,
    UnMarshallerService
} from '@magnabc/tpi'
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class CountryTaxesInformationProviderService {

  basePath = '/financial/countrytaxesinformationprovider';
  public countryVat: number;

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService, private unMarshallerService: UnMarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  provideCountryTaxesInformation(provideCountryTaxesInformationRequest: ProvideCountryTaxesInformationRequest): Promise<number>{
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providecountrytaxesinformation';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryTaxesInformationRequest);

    return new Promise<number>((resolve, reject) => {
      this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise().then(httpResponse =>{
          if (httpResponse && httpResponse.body) {
              const provideCountryTaxesInformationResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryTaxesInformationResponse) as ProvideCountryTaxesInformationResponse);
              const vats = provideCountryTaxesInformationResponse.countryTaxes;
              if (vats.length > 0) {
                  this.countryVat = vats[0].vat;
              }

              resolve(this.countryVat);
              return;
          }
      }).catch(error => {
        console.error(error);
        RaygunErrorHandler.sendError(error);

        return;
      });
    });
  }
}
