import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ElementRef } from "@angular/core"
import { TranslateParser, TranslateService } from "@ngx-translate/core"
import { FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { FormComponent } from "../form/form.component"
import { StringUtils } from "../../../common/utils/utils.string"
import {
    ContactNumber,
    CountryDialingIdentifier,
    CountryIdentifierTypeCriteria,
    ProvideCountryIdentifiersRequest,
    ProvideCountryIdentifiersResponse,
    UnMarshallerService
} from '@magnabc/tpi'
import {environment} from "../../../../environments/environment"
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    CountryIdentifierProviderService
} from '../../../http.services/location/geographic/identification/country-identifier-provider/country-identifier-provider.service'

declare const jQuery: any;
@Component({
    selector: 'app-country-contact-number',
    styleUrls: ['./country_contact_number.component.scss'],
    templateUrl: './country_contact_number.component.html'
})
export class CountryContactNumberComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() contactNumber: ContactNumber;
    @Input() title = "";
    @Input() disabled = false;
    @Input() obfuscated = false;
    @Output() onContactNumber = new EventEmitter<ContactNumber>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onCountry = new EventEmitter<CountryDialingIdentifier>();

    countries = [];
    country: CountryDialingIdentifier;

    formGroup: UntypedFormGroup;
    number: UntypedFormControl;
    countryCode: UntypedFormControl;

    obfuscatedNumber: string;

    @ViewChild('form') form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser, private locationService: CountryIdentifierProviderService,
        private unMarshallerService: UnMarshallerService, private runtimeConfigurationService: RuntimeConfigurationService,
        private elementRef: ElementRef) {
        super(translate, translateParser);

        this.getCountries();

    }

    ngOnInit(): void {
    }

    init(): void {

        if (this.contactNumber && this.contactNumber.country) {
            this.country = this.getCountryByCode(this.contactNumber.country);
        }

        if (!this.country) {
            this.country = this.getCountryByCode(this.runtimeConfigurationService.defaultCountryDialingCode);
        }

        if (this.contactNumber) {
            if (this.obfuscated) {
                this.obfuscatedNumber = this.contactNumber.number.substr(0, this.contactNumber.number.length - 4);
                this.obfuscatedNumber += "****";
                this.number = new UntypedFormControl(this.obfuscatedNumber, [
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                    Validators.maxLength(9)
                ]);
            } else {
                this.number = new UntypedFormControl(this.contactNumber.number, [
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                    Validators.maxLength(9)
                ]);
            }
        } else {

            this.contactNumber = new ContactNumber(); // this.country.dialingCode);
            this.number = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(15)
            ]);
        }
        this.countryCode = new UntypedFormControl(this.country.dialingCode, [
            Validators.required
        ]);

        if (this.disabled) {
            this.number.disable();
            this.countryCode.disable();
        }

        this.formGroup = new UntypedFormGroup({
            number: this.number,
            countryCode: this.countryCode,
        });

    }

    getCountries(): void {

        const criteria = new CountryIdentifierTypeCriteria();
        criteria.identifierType = 'CountryDialingIdentifier';

        const provideCountryIdentifiersRequest = new ProvideCountryIdentifiersRequest();
        provideCountryIdentifiersRequest.criteria = criteria;

        this.locationService.provideCountryIdentifiers(provideCountryIdentifiersRequest).then(httpResponse => {

            if (httpResponse && httpResponse.body) {
                const response =
                    (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryIdentifiersResponse) as ProvideCountryIdentifiersResponse);

                this.countries = response.countryIdentifiers;
                this.countries.sort((a, b) => {
                    if (a.description > b.description) {
                        return 1;
                    }
                    if (a.description < b.description) {
                        return -1;
                    }
                    return 0;
                });
                this.init();
            }
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    onCountryChange(event): void {
        this.country = null;
        setTimeout(() => {
            this.country = this.getCountryByCode(this.countryCode.value);
            this.onCountry.emit(this.country);
        }, 0);
    }

    getCountryByCode(code): any {

        for (const country of this.countries) {
            if (country.dialingCode === code) {
                return country;
            }
        }

        return null;

    }

    submit(): void {
        document.getElementById('form-submit-contact-number-' + this.title).click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            if (!this.obfuscated) {
                this.contactNumber.number = StringUtils.sanitiseNumber(this.number.value);
            }
            this.contactNumber.country = this.country.dialingCode;
            this.contactNumber.contactNumberType = "Cell";

            this.onContactNumber.emit(this.contactNumber);
            this.onValid.emit();

        } else {
            this.markAllAsTouched();
            if (this.elementRef && this.elementRef.nativeElement) {
                jQuery('html,body').animate({ scrollTop: this.elementRef.nativeElement.offsetTop }, 'slow');
                this.elementRef.nativeElement.focus();
            }
        }
    }

    markAllAsTouched(): void {
        this.formGroup.markAllAsTouched();
    }

    isDirty() {
        return this.form && this.form.dirty;
    }

    getContactNumber(): ContactNumber {
        if (!this.form.invalid) {

            const contactNumber = new ContactNumber();

            contactNumber.number = StringUtils.sanitiseNumber(this.number.value);
            contactNumber.country = this.country.dialingCode;
            contactNumber.contactNumberType = "Cell";

            return contactNumber;

        }
        return null;
    }

    getMask(mask): any {
        // noinspection TsLint
        return eval(mask);
    }
}
