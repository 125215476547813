import { CountryIdentifier } from "./country-identifier.model";

export class AlphaTwo extends CountryIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.location.geographic.identification.AlphaTwo";
    }

    public description:string;
    public alphaTwo:string;
    public flagUrl:string;

}
