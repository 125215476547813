
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import {
    InitialiseExtensionValidationRequest,
    InitialiseExtensionValidationResponse,
    InsuranceCategory,
    InsurancePolicy,
    InsurancePolicyItem,
    MotorInsurancePolicy,
    MotorInsurancePolicyItem,
    OrderOrderItemNumberIdentifier,
    PlateNumber,
    RetailProfile,
    RetailProfileOwnerIdentifier,
    RetailVehicle,
    UnMarshallerService,
    Vehicle,
    VehicleInsurancePolicy,
} from "@magnabc/tpi"
import { DatatableComponent } from "@swimlane/ngx-datatable"
import * as moment from "moment"
import { ActiveToast } from "ngx-toastr"
import { ServiceList } from "../../../../common/model/service-list.model"
import {
    RetailProfileFinalisationManagerService
} from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import { FormComponent } from "../../../shared/form/form.component"
import { Utils } from "../../../shared/Utils/Utils"
import { AuthenticationService } from '../../../../app.services/managers/authentication/authentication.service'
import { MatDialog } from "@angular/material/dialog"
import {
    QuickTransactManagerService
} from "../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {
    SystemParameterProviderService
} from "../../../../http.services/system/system-parameter-provider/system-parameter-provider.service"
import {
    UnderwriterInformationProviderService
} from "../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {Subject} from "rxjs"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {
    InsurancePolicyManagerExtensionDialogComponent
} from '../../insurance/insurance-policy-manager-extension-dialog/insurance-policy-manager-extension-dialog.component'
import { ProductActionEnum } from '../../insurance/insurance-product-information-provider/product/product-action.enum'
import { ExtensionValidationInitialisationManagerService } from "../../../../http.services/retail/insurance/extension-validation-initialisation-manager/extension-validation-initialisation-manager.service"

@Component({
    selector: 'app-policy-list',
    templateUrl: './policy.list.component.html',
    styleUrls: ['../../../vehicle/vehicle.component.scss', './policy.list.component.scss']
})
export class PolicyListComponent extends FormComponent implements OnInit {

    @Input() insurancePoliciesPerPolicy: InsurancePolicy[] = [];
    @Input() vehicles: RetailVehicle[];
    @Input() totalVehicleElements: number = 0
    @Input() filter: Subject<string>;
    @Output() onVehicleSelect = new EventEmitter<Vehicle>();

    retailProfile: RetailProfile;
    insurancePoliciesList: InsuranceList[] = [];
    rows: InsuranceList[] = [];
    columns: any = [
        { details: 'details' }
    ];
    expanded: any = {};

    policies: { [id: string]: InsurancePolicy[] } = {};

    activeToast: ActiveToast<any>;
    temp: InsuranceList[] = [];
    quickTransact: boolean;
    targetPolicies: MotorInsurancePolicy[]

    totalPolicyInfoCount: number = 0;
    totalPolicyVehicleMatchedCount: number = 0;
    vehicleInsurancePolicies: VehicleInsurancePolicy[] = [];
    extensionPolicyMap: {[id: string]: MotorInsurancePolicyItem} = {};
    originalPolicyMap: {[id: string]: MotorInsurancePolicyItem} = {};
    isPublicUserView = true;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(
        public translate: TranslateService,
        private translateParser: TranslateParser,
        private retailProfileService: RetailProfileFinalisationManagerService,
        private quickStartService: QuickTransactManagerService,
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private systemParameterService: SystemParameterProviderService,
        public brokerService: UnderwriterInformationProviderService,
        private route: ActivatedRoute,
        private extensionValidationInitialisationManagerService: ExtensionValidationInitialisationManagerService,
        private unMarshallerService: UnMarshallerService
    ) {
        super(translate, translateParser);

        this.temp = this.rows;
    }

    ngOnInit(): void {
        let tempCount = 0;
        let tempVehicleMatchedCount = 0;
        setTimeout(() => {

        }, 200);
        this.insurancePoliciesList = []
        if (this.insurancePoliciesPerPolicy) {
            this.insurancePoliciesPerPolicy.forEach(policy => {
                (policy as MotorInsurancePolicy).expiryDate = this.addExtraDay((policy as MotorInsurancePolicy).expiryDate)
                const tempList: InsuranceList = {
                    insurancePolicy: policy,
                    number: policy.number,
                    usage: '',
                    expiryDate: (policy as MotorInsurancePolicy).expiryDate,
                    insuranceCategory: (policy.insurancePolicyItems[0] as MotorInsurancePolicyItem).insuranceCategory,
                    insurancePolicies: []
                };

                policy.insurancePolicyItems.forEach(item => {
                  this.startLoad()
                    tempCount++;
                    this.vehicles.forEach(retailVehicle => {
                        if (Utils.getTpiVehicleIdentifier(retailVehicle.vehicleIdentifiers).number === (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier) {
                            //getPolicyInfo determine how many policyItems in list
                            // count is total results
                            // count vehicle matches and increment vehicleMatched Counter
                            tempVehicleMatchedCount++;
                            const motorInsurancePolicyItem = (item as MotorInsurancePolicyItem);
                            motorInsurancePolicyItem.motorInsuranceVehiclePolicy.expiryDate = this.addExtraDay(motorInsurancePolicyItem.motorInsuranceVehiclePolicy.expiryDate);
                            if (tempList.usage === '') {
                                tempList.usage = retailVehicle.usage;
                            }
                            const tempPolicyList = {
                                effectiveDate: motorInsurancePolicyItem.motorInsuranceVehiclePolicy.effectiveDate,
                                manufactureDate: moment(retailVehicle.manufactureDate).format("YYYY"),
                                plateNumber: motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber,
                                colour: retailVehicle.colour,
                                make: retailVehicle.make,
                                model: retailVehicle.model,
                                category: retailVehicle.category,
                                tpiVehicleIdentifier: this.getTpiVehicleIdentifier(retailVehicle.vehicleIdentifiers),
                                expiryDate: motorInsurancePolicyItem.motorInsuranceVehiclePolicy.expiryDate,
                                insurancePolicyItemType: motorInsurancePolicyItem.insurancePolicyItemType,
                                motorInsurancePolicyItem: motorInsurancePolicyItem
                            }
                            const identifier = motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier+'-'+policy.number;
                            if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'EXTENSION') {
                                this.extensionPolicyMap[identifier] = item as MotorInsurancePolicyItem;
                            }
                            if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'NEW') {
                                this.originalPolicyMap[identifier] = item as MotorInsurancePolicyItem;
                            }
                            tempList.insurancePolicies.push(tempPolicyList);
                        }
                    });
                });
                this.insurancePoliciesList.push(tempList);
            });
            this.totalPolicyInfoCount = tempCount;
            this.totalPolicyVehicleMatchedCount = tempVehicleMatchedCount;
        }

        this.filter.subscribe(
            filter => {
                const val = filter.toLowerCase();
                this.rows = this.temp.filter((policy) => {
                    return policy.number.replace(/ /g, "").toLowerCase().indexOf(val) !== -1 || !val;
                });
                this.table.offset = 0;
            }
        );

        if (this.quickStartService.retailProfile) {
            this.retailProfile = this.quickStartService.retailProfile;
        } else {
            this.retailProfile = this.retailProfileService.retailProfile;
        }

        this.initialiseExtensionValidation();
    }

    private initialiseExtensionValidation(): void {
      this.startLoad()
        let vehicleInsurancePolicies: VehicleInsurancePolicy[] = [];

        this.insurancePoliciesList.forEach(policy => {
            if (policy.insuranceCategory !== InsuranceCategory.YELLOWCARD) {
                policy.insurancePolicies.forEach((policyItem) => {
                    let vehicleInsurancePolicy = new VehicleInsurancePolicy();
                    vehicleInsurancePolicy.policyNumber = policy.number;
                    vehicleInsurancePolicy.tpiVehicleIdentifier = policyItem.tpiVehicleIdentifier;
                    vehicleInsurancePolicies.push(vehicleInsurancePolicy);
                })
            }
        });

        let initialiseExtensionValidationRequest = new InitialiseExtensionValidationRequest();
        let retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        initialiseExtensionValidationRequest.retailProfileOwnerIdentifier = retailProfileOwnerIdentifier;
        initialiseExtensionValidationRequest.vehicleInsurancePolicies = vehicleInsurancePolicies;
        this.extensionValidationInitialisationManagerService.initialiseExtensionValidation(initialiseExtensionValidationRequest)
            .toPromise().then((data) => {
                const response = this.unMarshallerService.unMarshallFromJson(data.body, InitialiseExtensionValidationResponse) as InitialiseExtensionValidationResponse;
                this.vehicleInsurancePolicies = response['stored'];
                this.refresh();
            });
    }

    viewPolicyDetails(event, policy): boolean {
        event.stopPropagation();
        this.targetPolicies = [policy]
        return false;
    }

    onClosePolicyView() {
        this.targetPolicies = null
    }

    refresh(): void {

        if (this.insurancePoliciesPerPolicy && this.insurancePoliciesPerPolicy.length > 0) {
            this.insurancePoliciesPerPolicy.sort((first, second) => {
                if (first.number > second.number) {
                    return 1;
                }

                if (first.number < second.number) {
                    return -1;
                }
            });

            this.rows = this.insurancePoliciesList;
            this.temp = this.rows;
        }
        this.stopLoad();
    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {

        return Utils.getTpiVehicleIdentifier(vehicleIdentifiers).number;

    }

    isExpired(expiryDate: Date): boolean {
        return moment(new Date()).isAfter(moment(expiryDate).add(-1, 'days').endOf('day'));
    }

    isValidItem(tpiVehicleIdentifier, policyNumber): boolean {
        const identifier = tpiVehicleIdentifier+'-'+policyNumber;

        let policyItem: MotorInsurancePolicyItem = null;
        if (this.originalPolicyMap[identifier]) {
            policyItem = this.originalPolicyMap[identifier];
        }
        let extensionPolicyItem: MotorInsurancePolicyItem = null;
        if (this.extensionPolicyMap[identifier]) {
            extensionPolicyItem = this.extensionPolicyMap[identifier];
        }

        if (policyItem && extensionPolicyItem) {
            if(policyItem.motorInsuranceVehiclePolicy.cancelled && extensionPolicyItem.motorInsuranceVehiclePolicy.cancelled) {
                return false;
            }
            if(this.isExpired(extensionPolicyItem.motorInsuranceVehiclePolicy.expiryDate)) {
                return false;
            }
        }else if (policyItem && !extensionPolicyItem) {
            if(policyItem.motorInsuranceVehiclePolicy.cancelled) {
                return false;
            }
            if(this.isExpired(policyItem.motorInsuranceVehiclePolicy.expiryDate)) {
                return false;
            }
        }else if (!policyItem && extensionPolicyItem) {
            if(extensionPolicyItem.motorInsuranceVehiclePolicy.cancelled) {
                return false;
            }
            if(this.isExpired(extensionPolicyItem.motorInsuranceVehiclePolicy.expiryDate)) {
                return false;
            }
        }
        return true;
    }

    markPolicyAsCancelled(policy: any): boolean{
        let cancelledCount = 0;
        let insurancePolicyItems: any[] = policy.insurancePolicyItems;
        insurancePolicyItems.forEach((item) => {
            if(item.motorInsuranceVehiclePolicy.cancelled){
                cancelledCount++;
            }
        });
       if(cancelledCount === insurancePolicyItems.length){
            return true;
       }else{
            return false;
       }
    }

    isPolicyCancelled(tpiVehicleIdentifier, policyNumber) {
        const identifier = tpiVehicleIdentifier+'-'+policyNumber;

        let policyItem: MotorInsurancePolicyItem = null;
        if (this.originalPolicyMap[identifier]) {
            policyItem = this.originalPolicyMap[identifier];
        }
        let extensionPolicyItem: MotorInsurancePolicyItem = null;
        if (this.extensionPolicyMap[identifier]) {
            extensionPolicyItem = this.extensionPolicyMap[identifier];
        }

        if (extensionPolicyItem && policyItem) {
            return extensionPolicyItem.motorInsuranceVehiclePolicy.cancelled && policyItem.motorInsuranceVehiclePolicy.cancelled;
        } else if (extensionPolicyItem) {
            return extensionPolicyItem.motorInsuranceVehiclePolicy.cancelled;
        } else if (policyItem) {
            return policyItem.motorInsuranceVehiclePolicy.cancelled;
        }

        return false;
    }

    formatDate(date: Date): string {
        return moment(date).format("DD MMM YYYY");
    }

    formatPeriod(date: Date): string {
        const period = moment(date);
        if (period.month() === 0) {
            return period.format("MMM YYYY");
        }
        return period.format("MMM");
    }

    toggleExpandRow(event, row): boolean {
        event.stopPropagation();
        this.table.rowDetail.toggleExpandRow(row);
        return false;
    }

    onSelect(event): void {
        this.onVehicleSelect.emit(event.selected[0]);
    }

    servicesLinkVehicles(): boolean {
        return this.authenticationService.isAuthorised([ServiceList.LINKVEHICLE]) || this.authenticationService.isAuthorised([ServiceList.ASSISTEDQUICKTRANSACT]);
    }

    getVehiclePlateNumber(vehicle: Vehicle): string {
        if (vehicle && vehicle.vehicleIdentifiers) {
            for (const identifier of vehicle.vehicleIdentifiers) {
                if (identifier instanceof PlateNumber || identifier['@class'] === "za.co.magnabc.tpi.vehicle.identification.PlateNumber") {
                    return (identifier as PlateNumber).number;
                }
            }
        }
        return '';
    }

    onAddVehicle() {
        this.route.queryParams.forEach((params) => {
            this.quickTransact = params.quickTransact;
        });
        // TODO For when add vehicle to policy is implented
    }

    onExtend(insurancePolicy: InsurancePolicy, insurancePolicyItem: MotorInsurancePolicyItem) {
        let orderItemNumbers: string[] = [];

        if (insurancePolicyItem) {
            const orderItemNumber = (insurancePolicyItem.orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier.orderItemNumber;
            orderItemNumbers.push(orderItemNumber)
        } else {
            insurancePolicy.insurancePolicyItems.forEach((item) => {
                const policyItem = item as MotorInsurancePolicyItem;
                const tpiVehicleIdentifier = policyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier;
                if (this.vehicleInsurancePolicies.find(policy => policy.tpiVehicleIdentifier === tpiVehicleIdentifier)) {
                    const orderItemNumber = (policyItem.orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier.orderItemNumber;
                    orderItemNumbers.push(orderItemNumber)
                }
            })
        }

        const data = {
            action: ProductActionEnum.ADD_TO_CART,
            policyNumber: insurancePolicy.number,
            orderItemNumbers
        }

        this.dialog.open(InsurancePolicyManagerExtensionDialogComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "1%" },
            panelClass: 'product-dialog',
            data
        }).afterClosed().subscribe(() => {
            this.initialiseExtensionValidation();
        });
    }

    canPoliyBeExtended(insurancePolicy: InsurancePolicy): boolean {
        let tpiVehicleIdentifier: string = undefined;
        if(insurancePolicy.insurancePolicyItems.length === 1){
            tpiVehicleIdentifier = (insurancePolicy.insurancePolicyItems[0] as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier;
        }

        const policyNumber = insurancePolicy.number;
        let vehicleIdentifiers = insurancePolicy.insurancePolicyItems.map((item) => {
            if (item['motorInsuranceVehiclePolicy']) {
                let identifier = item['motorInsuranceVehiclePolicy']['snapshotVehicle']['tpiVehicleIdentifier'];
                return identifier;
            }
        });

        let validPolicyItems = [];
        vehicleIdentifiers.forEach((item) => {
            if(tpiVehicleIdentifier){
                validPolicyItems = validPolicyItems.concat(this.vehicleInsurancePolicies.filter(item => item.policyNumber === policyNumber && item.tpiVehicleIdentifier === tpiVehicleIdentifier))
            }else{
                validPolicyItems = validPolicyItems.concat(this.vehicleInsurancePolicies.filter(item => item.policyNumber === policyNumber))
            }
        })
        const result = validPolicyItems.length > 0;
        return result;
    }

    canPolicyItemBeExtended(insurancePolicy: InsurancePolicy, insurancePolicyItem: MotorInsurancePolicyItem): boolean {
        const policyNumber = insurancePolicy.number;
        const tpiVehicleIdentifier = insurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier;
        const validPolicyItems = this.vehicleInsurancePolicies.filter(item => item.policyNumber === policyNumber && item.tpiVehicleIdentifier === tpiVehicleIdentifier);
        const policyList= this.insurancePoliciesList.find(policyItem => policyItem.number === policyNumber);
        const result = validPolicyItems.length > 0 && policyList.insurancePolicies.length > 1;
        return result;
    }

    addExtraDay(date) {
         const newDate = new Date(date)
         return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1, 0, 0, 0);
    }

    removeExtraDay(date) {
        const newDate = new Date(date)
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1, 0, 0, 0);
    }

    getPolicyExtensions(tpiVehicleIdentifier: string, policyNumber: string) {
        if (this.extensionPolicyMap[tpiVehicleIdentifier+'-'+policyNumber]) {
            return [this.extensionPolicyMap[tpiVehicleIdentifier+'-'+policyNumber]]
        }
        return []
    }

    getPolicyOriginals(tpiVehicleIdentifier: string, policyNumber: string) {
        if (this.originalPolicyMap[tpiVehicleIdentifier+'-'+policyNumber]) {
            return [this.originalPolicyMap[tpiVehicleIdentifier+'-'+policyNumber]]
        }
        return []
    }

}

export interface InsuranceList {
    insurancePolicy: InsurancePolicy;
    number: string;
    usage: string;
    insuranceCategory: string;
    expiryDate: Date;
    insurancePolicies: {
        effectiveDate: Date;
        manufactureDate: string;
        plateNumber: string;
        colour: string;
        make: string;
        model: string;
        category: string;
        tpiVehicleIdentifier: string;
        expiryDate: Date;
        motorInsurancePolicyItem: MotorInsurancePolicyItem;
    }[];
}
