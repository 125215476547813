import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";

export class InsuranceSalesByProductReportLegalEntityDateCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.InsuranceSalesByProductReportLegalEntityDateCriteria";
    }

    public dateFrom: Date;
    public dateTo: Date;
    public legalEntityIdentifier: LegalEntityIdentifier;
}