import { Criteria } from '../../../../../../../criteria/criteria_api';
import { Request } from '../../../../../../../urdad/services/models/request.model';
import { InsuranceCategory } from '../../../../../../retail_api';

export class ProvideRetailProfileVehicleInformationRequest extends Request{
    constructor(){
        super();
    }

    public criteria: Criteria;

    public batchSize: number ;

    public batchNumber: number;

    public insuranceCategory: InsuranceCategory;
}
