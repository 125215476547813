import {Injectable} from '@angular/core';
import {UnMarshallerService, VerifyAccountLinkRequest, VerifyAccountLinkResponse} from '@magnabc/tpi';
import { RaygunErrorHandler } from '../../../../common/utils/utils.raygun';
import {AccountLinkVerifierService} from '../account-link-verifier/account-link-verifier.service'

@Injectable({
    providedIn: 'root'
})
export class AccountLinkVerifierManagerService {

    constructor(private accountLinkVerifierProvider: AccountLinkVerifierService,
                private unMarshallerService: UnMarshallerService) {
    }

    verifyAccountLink(token: string): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            const verifyAccountLinkRequest = new VerifyAccountLinkRequest();
            verifyAccountLinkRequest.token = token;

            this.accountLinkVerifierProvider.verifyAccountLink(verifyAccountLinkRequest).then(httpResponse => {

                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse, VerifyAccountLinkResponse) as VerifyAccountLinkResponse);
                resolve(response);
                return;
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
                reject(err.error);
                return;
            })
        }));
    }
}
