import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddExtensionToCartRequest, AddExtensionToCartResponse, AddToCartRequest, AddYCToCartRequest, MarshallerService } from '@magnabc/tpi';
import { environment } from "../../../../../environments/environment";
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsuranceRetailManagerService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private readonly basePath = `/retail/insurance/insuranceretailmanager`;
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    addToCart(addToCartRequest: AddToCartRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + `${this.basePath}/addtocart`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(addToCartRequest);
        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    addToCartExtension(addExtensionToCartRequest: AddExtensionToCartRequest): Observable<any> {
        const URI = this.runtimeConfigurationService.apiBaseUrl + `${this.basePath}/addextensiontocart`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(addExtensionToCartRequest);
        return this.httpClient.post(URI, jsonRequest, this.options);
    }

    addYCToCart(addYCToCartRequest: AddYCToCartRequest): Promise<any> {
        const URI = this.runtimeConfigurationService.apiBaseUrl + `${this.basePath}/addyctocart`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(addYCToCartRequest);
        return this.httpClient.post(URI, jsonRequest, this.options).toPromise();
    }
}
