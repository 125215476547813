import {CountryRegistrationConfiguration} from "./country-registration-configuration.model";

export abstract class ShownJudicialPersonCountryRegistrationInformation extends CountryRegistrationConfiguration {
    constructor() {
        super();
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.ShownJudicialPersonCountryRegistrationInformation';
    }

    public authorisedSignatoryName: boolean;
    public authorisedSignatoryPosition: boolean;
    public bankAccountNumber: boolean;
    public bankName: boolean;
    public dateOfIncorporation: boolean;
    public income: boolean;
    public industry: boolean;
    public registrationNumber: boolean;
    public scannedDocument: boolean;
    public seniorManagerName: boolean;
    public taxNumber: boolean;
    public vatNumber: boolean;
    public shownDocuments: string[] = [];
    public shipping: boolean
}
