import { Request } from "../../../../urdad/urdad_api";
import { RetailProfileIdentifier } from "../../profile_api";

export class ImportFleetRequest extends Request {
    constructor() {
        super();
    }

    retailProfileIdentifier: RetailProfileIdentifier;
    document: File;
}