import {Response} from '../../../../../../urdad/services/models/response.model';
import { SystemParameter } from '../../../../../models/system-parameter.model';


export class ProvideSystemParameterResponse extends Response {
    constructor() {
        super();
    }

    systemParameters: SystemParameter[] = [];
}