import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import {ProvideScannedDocumentRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ScannedDocumentProviderService {

    basePath = '/media/scanneddocument/scanneddocumentprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideScannedDocument(provideScannedDocumentRequest: ProvideScannedDocumentRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providescanneddocument';
        return this.http.post(path, provideScannedDocumentRequest, this.options);
    }

}
