import {Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {MatTableDataSource} from '@angular/material/table'
import {MatPaginator} from '@angular/material/paginator'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {
    FinancialReportProviderService
} from '../../../../http.services/ledger/reporting/financial-report-provider/financial-report-provider.service'
import {AgentManagerService} from '../../../../app.services/managers/agent-manager/agent-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import * as moment from 'moment/moment'
import {StringUtils} from '../../../../common/utils/utils.string'
import {environment} from '../../../../../environments/environment'
import * as XLSX from 'xlsx'
import {
    Agent,
    AgentCriteria,
    ProvideAgentInformationRequest,
    ProvideTransactionReportRequest,
    ReportDateCriteria
} from "@magnabc/tpi"

type AOA = any[][];
declare const jQuery: any;

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent extends FormComponent implements OnInit {

    rows = [];
    data: AOA = [];
    fileName = 'transaction-report.xlsx';
    agents: {[id: string]: Agent} = {};
    retrievingAgent: {[id: string]: boolean} = {};

    entity = "Entity";
    from;
    to;

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["date", "policyNumber", "transactionAmount", "coverITShare", "authorityShare",
        "underwriterName", "underwriterShare", "brokerName", "brokerShare", "sellingAgentName", "sellingAgentShare",
        "paymentProviderName", "paymentProviderShare", "printingAgentName", "printingAgentShare", "currency"];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private title: Title,
                private financialReportProviderService: FinancialReportProviderService,
                private agentService: AgentManagerService,
                private errorToastService: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Transaction Report - Console - ${runtimeConfigurationService.title}`);

        this.dataSource = new MatTableDataSource(this.rows);
    }

    ngOnInit() {
        this.from = moment();
        this.to = moment();
        this.getTransactions();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    getTransactions(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        const reportDateCriteria = new ReportDateCriteria();
        reportDateCriteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
        reportDateCriteria.dateTo =  this.to.startOf('day').format('YYYY-MM-DD') as Date;

        this.entity = this.agentService.agent.name;

        const provideTransactionReportRequest = new ProvideTransactionReportRequest();
        provideTransactionReportRequest.criteria = reportDateCriteria;

        this.startLoad();
        this.financialReportProviderService.provideTransactionReport(provideTransactionReportRequest).subscribe((response) => {

            this.stopLoad();

            this.data = [];
            this.rows = [];

            this.data.push(["Transaction Report"]);
            this.data.push([""]);
            this.data.push(["From Date", "To Date"]);
            this.data.push([this.getDate(this.from), this.getDate(this.to)]);
            this.data.push([""]);
            this.data.push(["Date", "Policy Number", "Transaction Amount", "CoverIT Share", "Authority Share", "Underwriter Name", "Underwriter Share",
                "Broker Name", "Broker Share", "Selling Agent Name", "Selling Agent Share", "Payment Provider Name", "Payment Provider Share", "Printing Agent Name", "Printing Agent Share", "Currency"]);

            let thirdPartyInsuranceRevenueSplits = [];
            if(response){
                if(response.body){
                    if(response.body.transactionReport){
                        thirdPartyInsuranceRevenueSplits = response.body.transactionReport.transactionReportEntries;
                    }
                }
            }
            thirdPartyInsuranceRevenueSplits.sort((a, b) => {
                if (moment(a.date) > moment(b.date)) {
                    return 1;
                }
                if (moment(a.date) < moment(b.date)) {
                    return -1;
                }
                return 0;
            });

            for (const r of thirdPartyInsuranceRevenueSplits) {
                const item = [
                    r.date,
                    r.policyNumber,
                    r.transactionAmount.toFixed(2),
                    r.adminShare.toFixed(2),
                    r.authorityShare.toFixed(2),
                    r.underwriterName,
                    r.underwriterShare.toFixed(2),
                    r.brokerName,
                    r.brokerShare.toFixed(2),
                    r.sellingAgentName,
                    r.sellingAgentShare.toFixed(2),
                    r.paymentProviderName,
                    r.paymentProviderShare.toFixed(2),
                    r.printingAgentName,
                    r.printingAgentShare.toFixed(2),
                    r.currency
                ];
                const row = {
                    date: r.date,
                    policyNumber: r.policyNumber,
                    transactionAmount: r.transactionAmount.toFixed(2),
                    adminShare: r.adminShare.toFixed(2),
                    authorityShare: r.authorityShare.toFixed(2),
                    underwriterName: r.underwriterName,
                    underwriterShare: r.underwriterShare.toFixed(2),
                    brokerName: r.brokerName,
                    brokerShare: r.brokerShare.toFixed(2),
                    sellingAgentName: r.sellingAgentName,
                    sellingAgentShare: r.sellingAgentShare.toFixed(2),
                    paymentProviderName: r.paymentProviderName,
                    paymentProviderShare: r.paymentProviderShare.toFixed(2),
                    printingAgentName: r.printingAgentName,
                    printingAgentShare: r.printingAgentShare.toFixed(2),
                    currency: r.currency
                };
                this.data.push(item);
                this.rows.push(row);
                this.dataSource.data = this.rows;
            }
            this.data.push([""]);
            this.data.push([""]);
            this.data.push(["End"]);

            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 100);

        },(error) => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
        });

    }

    getAgent(identifier: string): void {

        if (!this.retrievingAgent[identifier]) {
            this.retrievingAgent[identifier] = true;

            const agentCriteria = new AgentCriteria();
            agentCriteria.agent = identifier;

            const provideAgentRequest = new ProvideAgentInformationRequest();
            provideAgentRequest.criteria = agentCriteria;

        }
    }

    getOrderMinusLevy(orderItemAmount: number): number {
        return orderItemAmount - Math.floor(orderItemAmount * (3 / 103));
    }

    getZI(orderItemAmount: any, total: number): string {
        return StringUtils.formatCents((orderItemAmount.amount - this.getLevyValue(orderItemAmount) - this.getRtsaValue(orderItemAmount) - total));
    }

    getLevy(orderItemAmount: any): string {
        return StringUtils.formatCents(this.getLevyValue(orderItemAmount));
    }

    getRtsa(orderItemAmount: any): string {
        return StringUtils.formatCents(this.getRtsaValue(orderItemAmount));
    }

    getRtsaValue(orderItemAmount): number {
        return Math.floor(this.getOrderMinusLevy(orderItemAmount.amount) * (5 / 100));
    }

    getLevyValue(orderItemAmount): number {
        return (orderItemAmount.amount - this.getOrderMinusLevy(orderItemAmount.amount));
    }

    getDate(date): string {
        return moment(date).format(environment.formatDate);
    }

    formatCurrency(amount): string {
        return StringUtils.formatCents(amount);
    }

    export(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report');
        XLSX.writeFile(wb, this.fileName);
    }

    onSubmit(): void {
        this.getTransactions();
    }

}
