import { error } from 'protractor';
import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {environment} from "../../../../../../environments/environment";
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NaturalPersonComponent} from "../../../../../view.components/entity/natural-person/natural-person.component";
import {FormComponent} from "../../../../../view.components/shared/form/form.component";
import {QueryNaturalPersonComponent} from "../../../../../view.components/entity/query-natural-person/query-natural-person.component";
import {OTPComponent} from "../../../../../view.components/security/otp.component";
import {OTPVerifyComponent} from "../../../../../view.components/security/opt-verify/opt-verify.component";
import {CredentialsComponent} from "../../../../../view.components/security/credentials/credentials.component";
import {ObfuscatedNumberComponent} from "../../../../../view.components/security/obfuscated-number/obfuscated-number.component";
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard";
import {LocationStrategy} from "@angular/common";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {
    AllCountryCriteria,
    AuthenticationAdvice,
    BasicPhysicalAddress,
    BasicPostalAddress,
    ContactNumber,
    ContactPerson,
    Country,
    CountryDescriptionIdentifier,
    CreateSessionTrackingRequest,
    CreateSessionTrackingResponse,
    Criteria,
    DriverTrafficRegisterNumber,
    InitialiseCreateVerifiedAccountRequest,
    LegalEntityIdentifier,
    LegalEntityProviderCriteria,
    NationalIdentityNumber,
    NaturalPerson,
    NaturalPersonIdentifier,
    PassportNumber,
    PlainTextUsernameAndPassword,
    ProvideCountryInformationRequest,
    ProvideCountryInformationResponse,
    ProvideSecuredLegalEntityObscuredContactNumberRequest,
    ProvideSecuredLegalEntityObscuredContactNumberResponse,
    SessionTracking,
    UnMarshallerService,
    ValidateSecuredLegalEntityObscuredContactNumberRequest,
    ValidateSecuredLegalEntityObscuredContactNumberResponse
} from "@magnabc/tpi";
import {Utils} from "../../../../../view.components/shared/Utils/Utils";
import {Dropdown} from '../../../../../common/model/dropdown.model';
import {VerifiedAccountManagerEnum} from '../../../../../view.components/security/account/verified-account-manager/verified-account-manager.enum';
import {CountryComponent} from '../../../../../view.components/shared/country/country.component';
import {SecuredLegalEntityService} from '../../../../../app.services/managers/secured-legal-entity/secured-legal-entity.service';
import {CountryProviderService} from '../../../../../http.services/location/geographic/country-provider/country-provider.service';
import {SecureLegalEntityInformationManagerService} from '../../../../../http.services/entity/secure-legal-entity-information-manager/secure-legal-entity-information-manager.service';
import {CountryService} from '../../../../../view.components/shared/country/services/country.service';
import {VerifiedAccountManagerService} from '../../../../../http.services/security/account/verified-account-manager/verified-account-manager.service';
import {CaptureEntityInformationComponent} from '../../../../../view.components/security/account/verified-account-manager/capture-entity-information/capture-entity-information.component';
import {CompareEntityIdentifiersService} from '../../../../../app.services/managers/compare-entity-identifiers/compare-entity-identifiers.service';
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service';
import {VerifiedAccountManagerWrapperService} from '../../../../../http.services/security/account/verified-account-manager-wrapper/verified-account-manager-wrapper.service';
import {TokenManagerService} from '../../../../../app.services/managers/token-manager/token-manager.service';
import {RetailProfileFinalisationManagerService} from '../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import {RaygunErrorHandler} from '../../../../../common/utils/utils.raygun';
import {QuickTransactManagerService} from '../../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';
import {SessionTrackingManagerService} from '../../../../../http.services/security/session-tracking/session-tracking.service';
import {v4 as uuidv4} from 'uuid';
import * as moment from "moment";
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import { ContactPersonComponent } from '../../../../../view.components/shared/contact-person/contact-person.component';
import { SystemParameterProviderService } from '../../../../../http.services/system/system-parameter-provider/system-parameter-provider.service';
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-create-verified-account',
  templateUrl: './verified-account-manager-page.component.html',
  styleUrls: ['./verified-account-manager-page.component.scss']
})
export class VerifiedAccountManagerPageComponent extends FormComponent implements OnInit, IStepComponent {

    utils = Utils;
    naturalPerson: NaturalPerson;
    obscuredContact: string = null;
    obscuredContactCountry: string = null;
    externalTRN: LegalEntityIdentifier;
    foundExtContactNumber: boolean;
    private naturalPersonIdentifier: NaturalPersonIdentifier = null;
    subTitle = 'Sign Up';
    maxlength = 15;

    @ViewChild(QueryNaturalPersonComponent) entityQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    @ViewChild(CredentialsComponent) credentialsComponent: CredentialsComponent;
    @ViewChild(OTPComponent) otpComponent: OTPComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;
    @ViewChild(ObfuscatedNumberComponent) obfuscatedNumberComponent: ObfuscatedNumberComponent;
    @ViewChild(CaptureEntityInformationComponent) captureEntityComponent: CaptureEntityInformationComponent;
    @ViewChild(ContactPersonComponent) contactPersonComponent: ContactPersonComponent;

    activeToast: ActiveToast<any>;
    accountExists = false;
    termsNotAccepted = false;
    formGroup: UntypedFormGroup;
    terms: UntypedFormControl;

    /* Steps */
    currentState: VerifiedAccountManagerEnum = VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID;
    backPressed: boolean;
    steps: any = [];
    breadcrumbStep = 0;

    /* */
    contactNumberOTP: string;
    message: string;
    errorMessage = null;
    obscuredIncorrectCounter = 0;
    quickTransact = false;

    /* First step - NaturalPerson identifier */
    @ViewChild('signUpForm', { static: true }) signUpForm: FormGroupDirective;
    @ViewChild(CountryComponent) countryComponent: CountryComponent;
    signUpFormGroup: UntypedFormGroup;
    identifierType: UntypedFormControl;
    identifierTypePlaceholder: string;
    identifier: UntypedFormControl;
    country: Country;
    countries: Country[] = null;
    identifierTypes = [
        new Dropdown(1, "National Identity Number"),
        new Dropdown(2, "Passport Number")
    ];

    /* New Legal Entity */
    newLegalEntityContactNumber: ContactNumber = null;

    /* Internal Legal Entity */
    internalEntity = false;
    internalContactNumber: ContactNumber = null;

    /* External Legal Entity */
    @ViewChild('provideExternalLegalEntityForm', { static: true }) provideExternalLegalEntityForm: FormGroupDirective;
    externalEntity = false;
    provideExternalLegalEntityFormGroup: UntypedFormGroup;
    externalIdentifierType: UntypedFormControl;
    externalIdentifierTypes = [];
    externalIdentifierTypePlaceholder: string;
    externalIdentifier: UntypedFormControl;
    externalCountry: Country;
    externalCountries: Country[] = [];
    externalLegalEntityProviderCriteria: Criteria = null;
    externalContactNumber: ContactNumber = null;
    capturedExternalIdentifier: DriverTrafficRegisterNumber;
    addEmergencyContact = false;

    //Prevent multi firing when click on next button in automated tests
    stateChangeLocked = false;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private router: Router,
                private toastr: ErrorToastService,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private authenticationService: AuthenticationService,
                private verifiedAccountManagerService: VerifiedAccountManagerWrapperService,
                private countryProviderService: CountryProviderService,
                private countryService: CountryService,
                private securedLegalEntityInformationService: SecuredLegalEntityService,
                private securedLegalEntityProviderWrapperService: SecureLegalEntityInformationManagerService,
                private compareEntityIdentifiersService: CompareEntityIdentifiersService,
                private unMarshallerService: UnMarshallerService,
                public createVerifiedAccountService: VerifiedAccountManagerService,
                private tokenManagerService: TokenManagerService,
                private quickTransactService: QuickTransactManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private locationStrategy: LocationStrategy,
                private route: ActivatedRoute,
                private sessionTrackingManagerService: SessionTrackingManagerService,
                private systemParameterService : SystemParameterProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.steps = [];

        setTimeout(() => {
            this.naturalPerson = new NaturalPerson();
            this.setSteps("default");
        });

        this.identifierTypePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
        this.externalIdentifierTypePlaceholder = "ENTITY.LABEL_TRN_NUMBER";
    }

    ngOnInit(): void {

        this.route.queryParams.forEach((params) => {
            this.quickTransact = params.quickTransact;
            if (this.quickTransact) {
                this.subTitle = 'Quick Start';
            }
        });

        this.internalEntity = false;
        this.externalEntity = false;

        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.startLoad();
        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                this.countries = response.countries;
                this.countryService.setCountries(response.countries);
                this.createVerifiedAccountService.setDropdownCountries(response.countries);
            }
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: '+ err);
            RaygunErrorHandler.sendError(err);

            this.stopLoad();
        });

        this.terms = new UntypedFormControl(false, [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            terms: this.terms
        });

        this.identifierType = new UntypedFormControl(this.identifierTypes[0], []);
        this.identifier = new UntypedFormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/),
            Validators.maxLength(this.maxlength)
        ]);
        this.signUpFormGroup = new UntypedFormGroup({
            identifierType: this.identifierType,
            identifier: this.identifier,
        });
    }

    onCheckValidation(): void {
        this.termsNotAccepted = false;
        if (this.formGroup.valid && this.terms.value) {
            this.onSubmit(null);
        } else {
            this.termsNotAccepted = true;
        }
    }

    onIdentifierTypeChanged(_): void {
        if (this.identifierType.value.value === 1) {
            this.identifierTypePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
            this.maxlength = 15;
            this.signUpFormGroup.controls["identifier"].setValidators([Validators.required, Validators.maxLength(15)]);
            this.signUpFormGroup.controls["identifier"].updateValueAndValidity();
        }else if(this.identifierType.value.value === 2){
            this.identifierTypePlaceholder = "ENTITY.LABEL_PASSPORT_NUMBER";
            this.maxlength = 9;
            this.signUpFormGroup.controls["identifier"].setValidators([Validators.required, Validators.maxLength(9)]);
            this.signUpFormGroup.controls["identifier"].updateValueAndValidity();
        }
    }

    onCountry(country): void {
        this.country = country;
    }

    onCaptureExternalIdentifierCountry(country): void {
        this.externalCountry = country;
    }

    onCountryValid(_): void {
        document.getElementById('form-submit-query').click();
    }

    onDeactivate(): boolean {
        if (this.breadcrumbStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    setSteps(value: string): void {

        this.steps = [];

        if(value.match("default"))
        {
            this.translate.get("NAV.SIGN_UP").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-user", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-phone", borderColor: "#3171c3"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 3, name: "One Time Pin", icon: "fa-mobile", borderColor: "#3e67b5"});
            });

            this.translate.get("ACCOUNT.TITLE_CREATE_PASSWORD").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-lock", borderColor: "#3f5baa"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 5, name: "Capture Identifier", icon: "fa-check", borderColor: "#318cdf", hidden:true});
            });
        }
        else if(value.match("internal")){
            this.translate.get("NAV.SIGN_UP").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 1, name: "One Time Pin", icon: "fa-mobile", borderColor: "#318cdf"});
            });

            this.translate.get("ACCOUNT.TITLE_CREATE_PASSWORD").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-lock", borderColor: "#3171c3"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-user", borderColor: "#3e67b5"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-phone", borderColor: "#3f5baa"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 5, name: "Verification", icon: "fa-check", borderColor: "#3f5baa", hidden:true});
            });
        }
        else if(value.match("external")){
            this.translate.get("NAV.SIGN_UP").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-check", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 2, name: "Verification", icon: "fa-check", borderColor: "#3f5baa"});
            });

            this.translate.get("TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 3, name: "One Time Pin", icon: "fa-mobile", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-user", borderColor: "#3e67b5"});
            });
            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-phone", borderColor: "#3f5baa"});
            });

            this.translate.get("ACCOUNT.TITLE_CREATE_PASSWORD").subscribe((response) => {
                this.steps.push({id: 6, name: response, icon: "fa-lock", borderColor: "#3171c3"});
            });

        }
        else if(value.match("updatedExternal")){
            this.translate.get("NAV.SIGN_UP").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-check", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 2, name: "Verification", icon: "fa-check", borderColor: "#3f5baa"});
            });

            this.translate.get("TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 3, name: "One Time Pin", icon: "fa-mobile", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-user", borderColor: "#3e67b5"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-phone", borderColor: "#3f5baa"});
            });

            this.translate.get("TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 6, name: "One Time Pin", icon: "fa-mobile", borderColor: "#318cdf"});
            });

            this.translate.get("ACCOUNT.TITLE_CREATE_PASSWORD").subscribe((response) => {
                this.steps.push({id: 7, name: response, icon: "fa-lock", borderColor: "#3171c3"});
            });

        }
        else{
            this.translate.get("NAV.SIGN_UP").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-check", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-user", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-phone", borderColor: "#3171c3"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe(() => {
                this.steps.push({id: 4, name: "One Time Pin", icon: "fa-mobile", borderColor: "#3e67b5"});
            });

            this.translate.get("ACCOUNT.TITLE_CREATE_PASSWORD").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-lock", borderColor: "#3f5baa"});
            });
        }

        this.setTitle();

    }

    setTitle() {
        let title = `${this.steps[this.breadcrumbStep].name} - ${this.subTitle} - ${this.runtimeConfigurationService.title}`;
        if (this.breadcrumbStep === VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID) {
            title = `${this.subTitle} - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    onCurrentStateChange(state:VerifiedAccountManagerEnum){
         //Prevent multi firing when click on next button in automated tests
        if (this.stateChangeLocked) return;
        this.lockStateChange();
        this.currentState = state;
        this.breadcrumbStep++;
        this.setTitle();
    }

    //Prevent multi firing when click on next button in automated tests
    private lockStateChange(): void {
        this.stateChangeLocked = true;
        setTimeout(() => {
            this.stateChangeLocked = false;
        }, 500);
    }

    onSubmit(_): void {
        switch (this.currentState) {
            case VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID: {
                this.countryComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP: {
                this.otpVerifyComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PASSWORD: {
                this.credentialsComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON: {
                this.naturalPersonComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON_CONTACT: {
                this.validateLegalEntityInformation();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP_UPDATED: {
                this.otpVerifyComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_PERSON_ID: {
                this.validateExternalLegalEntity();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OBSCURED_NUMBER: {
                this.obfuscatedNumberComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OTP: {
                this.otpVerifyComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON: {
                this.naturalPersonComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON_CONTACT: {
                this.validateAddContactDetails();
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXT_OTP_UPDATED: {
                this.otpVerifyComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON: {
                this.naturalPersonComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_CONTACT: {
               this.validateAddContactDetails();
            }
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_OTP: {
                this.otpVerifyComponent.submit();
                return;
            }
            case VerifiedAccountManagerEnum.CAPTURE_PASSWORD: {
                this.credentialsComponent.submit();
                return;
            }
            default: {
                break;
            }
        }
    }

    validateAddContactDetails(): void {
        if (this.addEmergencyContact && this.contactPersonComponent) {
            this.contactPersonComponent.onCheckValidation({});
        }
        if (this.captureEntityComponent) {
            this.captureEntityComponent.markAllAsTouched();
        }
        if (!this.addEmergencyContact || (this.contactPersonComponent && this.contactPersonComponent.form.valid
            && this.contactPersonComponent.contactNumber?.number)) {
            this.validateLegalEntityInformation();
        }
        return;
    }

    checkInputClicked() {
        this.errorMessage = null;
    }

    onBack(_): void {
        if(this.breadcrumbStep > 0){
            this.breadcrumbStep--;
        }

        this.errorMessage = null;

        switch (this.currentState) {
            case VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID: {
                this.ngOnInit();
                break;
            }

            // Internal Legal Entity
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PASSWORD: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PASSWORD;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON_CONTACT: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP_UPDATED: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON;
                break;
            }

            // External Legal Entity
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_PERSON_ID: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OBSCURED_NUMBER: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_PERSON_ID;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OTP: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OBSCURED_NUMBER;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OTP;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON_CONTACT: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_EXT_OTP_UPDATED: {
                this.setSteps("external");
                this.breadcrumbStep = 4;
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON;
                break;
            }

            // New Legal Entity
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON: {
                this.breadcrumbStep=0;
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_CONTACT: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON;
                break;
            }
            case VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_OTP: {
                this.currentState = VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_CONTACT;
                break;
            }

            // Universal
            case VerifiedAccountManagerEnum.CAPTURE_PASSWORD: {
                break;
            }
            default: {
                this.breadcrumbStep++;
                break;
            }
        }
        window.scrollTo(0, 0);
        this.setTitle();
    }

    initialiseCreateVerifiedAccount() {
        this.termsNotAccepted = false;
        if (this.formGroup.invalid || !this.terms.value) {
            this.termsNotAccepted = true;
            return;
        }

        if (this.signUpFormGroup.valid) {
            this.setSteps("default");

            if(this.identifierType.value.value === 1){
                 const nationalIdentityNumber = new NationalIdentityNumber();
                 nationalIdentityNumber.number = this.identifier.value.toLocaleUpperCase();
                 nationalIdentityNumber.countryOfIssue = this.country.alphaTwo;
                 this.naturalPersonIdentifier = nationalIdentityNumber;

            }else{
                const passportNumber = new PassportNumber();
                passportNumber.number = this.identifier.value.toLocaleUpperCase();
                passportNumber.countryOfIssue = this.country.alphaTwo;
                this.naturalPersonIdentifier = passportNumber;

            }

            this.createVerifiedAccountService.dropdownCountrySelected = this.country;

            const initialiseRequest = new InitialiseCreateVerifiedAccountRequest();
            initialiseRequest.naturalPersonIdentifier = this.naturalPersonIdentifier;

            this.startLoad();
            this.verifiedAccountManagerService.initialiseCreateVerifiedAccount(initialiseRequest).then(response => {
                this.errorMessage = null;

                if (response.oneTimePinIdentifier) {
                    this.setSteps("internal");
                    this.contactNumberOTP = '[redacted]';
                    this.createVerifiedAccountService.setOneTimePinIdentifier(response.oneTimePinIdentifier);
                    this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP);
                    this.stopLoad();
                } else {
                    // now check if the person does not exist from the external LE provider
                    const countryIdentifier = new CountryDescriptionIdentifier();
                    countryIdentifier.description = this.country.description;
                    this.securedLegalEntityInformationService.getCountryIdentificationConfiguration(countryIdentifier).then(countryConfigResponse => {
                        this.setSteps("malawi");
                        if (countryConfigResponse && countryConfigResponse.identificationType) {
                            this.externalCountries = [];
                            this.externalCountries.push(this.country);

                            if (countryConfigResponse.identificationType === 'TRAFFIC_REGISTRATION_NUMBER') {
                                if(this.externalIdentifierTypes.length === 0)
                                {
                                    this.externalIdentifierTypes.push(new Dropdown(1, "Traffic Register Number"));
                                }

                            } else {

                                console.error('Identifier of type ', countryConfigResponse.identificationType, ' not supported.');
                                RaygunErrorHandler.sendError('Identifier of type '+ countryConfigResponse.identificationType + ' not supported.');

                                this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON);
                                this.stopLoad();
                                return;
                            }

                            this.externalIdentifierType = new UntypedFormControl(this.externalIdentifierTypes[0], []);
                            this.externalIdentifier = new UntypedFormControl('', [
                                Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
                            ]);
                            this.provideExternalLegalEntityFormGroup = new UntypedFormGroup({
                                externalIdentifierType: this.externalIdentifierType,
                                externalIdentifier: this.externalIdentifier,
                            });
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_PERSON_ID);
                            this.stopLoad();
                        } else {
                            this.setSteps("default");
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON);
                            this.stopLoad();
                        }
                    });
                }
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                if (error && error.error) {
                    if (error.error === 'Account already exist.') {
                        if (this.quickTransactService.loadQuickTransact) {
                            this.quickTransactService.npIdentifier = this.naturalPersonIdentifier;
                            this.router.navigate(['/login']);
                            return;
                        }
                        if (this.quickTransact) {
                            this.quickTransactService.loadQuickTransact = true;
                            this.quickTransactService.npIdentifier = this.naturalPersonIdentifier;
                            this.router.navigate(['/login']);
                            return;
                        }
                    }

                    this.errorMessage = error.error;
                } else {
                    this.errorMessage = 'Unknown server error. Please try again later.';
                }
                this.stopLoad();
            });
        }
    }

    submitCredentials() {
        const credentials = new PlainTextUsernameAndPassword();
        credentials.username = this.credentialsComponent.plainTextUsernameAndPassword.username;
        credentials.password = this.credentialsComponent.plainTextUsernameAndPassword.password;

        if (this.internalEntity) {

            this.createVerifiedAccountService.setCredentials(credentials);
            this.startLoad();
            this.createVerifiedAccountService.getNaturalPersonInformation(this.naturalPersonIdentifier).then(response => {
                if (response) {
                    this.naturalPerson = response;
                    this.internalContactNumber = this.naturalPerson.contactNumbers[0];

                    this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON);
                    this.stopLoad();
                    return;
                }

                console.error('Could not get entity info.');
                RaygunErrorHandler.sendError('Could not get entity info.');
                this.stopLoad();
                return;
            });
        } else if (this.externalEntity) {
            let identifierFound = false;
            this.naturalPerson.legalEntityIdentifiers.forEach(identifier => {
                if (this.compareEntityIdentifiersService.compareIdentifiers(this.naturalPersonIdentifier, identifier)) {
                    identifierFound = true;
                }
            });

            if (!identifierFound) {
                this.naturalPerson.legalEntityIdentifiers.push(this.naturalPersonIdentifier);
            }

            this.startLoad();
            this.createVerifiedAccountService.finaliseCreateVerifiedAccountByInsert(this.naturalPerson, credentials.password).then(authAdvice => {
                if (authAdvice) {
                    this.validateAuthentication(authAdvice);
                } else {
                    console.log('Authentication advice :: ', authAdvice);
                    this.stopLoad();
                }
            });
        } else {
            // new legal entity
            this.startLoad();
            this.createVerifiedAccountService.finaliseCreateVerifiedAccountByInsert(this.naturalPerson, credentials.password).then(authAdvice => {
                if (authAdvice) {
                    this.validateAuthentication(authAdvice);
                } else {
                    console.log('Authentication advice :: ', authAdvice);
                    this.stopLoad();
                }
            });
        }

    }

    validateLegalEntityInformation() {

        if (this.captureEntityComponent.isValid()) {
            const contactNumber = this.captureEntityComponent.getMobileNumber();
            const emailAddress = this.captureEntityComponent.getEmailAddress();
            const physicalAddress = this.captureEntityComponent.getPhysicalAddress();
            // const postalAddress = this.captureEntityComponent.getPostalAddress();


            const contactPerson = this.contactPersonComponent.getContactPerson();
            const contactPersonCheck = this.contactPersonComponent.personCheckOne;

            if (this.internalEntity) {
                this.naturalPerson.addresses = [];
                this.naturalPerson.contactPersons = [];

                if(contactPersonCheck){
                    this.naturalPerson.contactPersons[0] = contactPerson;
                }


                this.naturalPerson.addresses.push(physicalAddress);

                if (emailAddress && emailAddress.address) {
                    this.naturalPerson.emailAddresses = [];
                    this.naturalPerson.emailAddresses.push(emailAddress);
                }

                // check if number changed
                if (this.checkIfContactNumberChanged(contactNumber, this.internalContactNumber)) {
                    this.startLoad();
                    this.naturalPerson.contactNumbers = [];
                    this.naturalPerson.contactNumbers.push(contactNumber);
                    this.createVerifiedAccountService.issueOTP(contactNumber).then(response => {
                        if (response === true) {
                            this.contactNumberOTP = contactNumber.country + contactNumber.number;
                            this.internalContactNumber = contactNumber;
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP_UPDATED);
                        }else {
                            this.toastOtpError(response.error);
                        }
                        this.stopLoad();
                        return;
                    });
                } else {
                    this.startLoad();
                    if (this.capturedExternalIdentifier) {
                        this.naturalPerson.legalEntityIdentifiers.push(this.capturedExternalIdentifier);
                    }
                    this.createVerifiedAccountService.finaliseCreateVerifiedAccountByUpdate(this.naturalPerson,
                        this.createVerifiedAccountService.capturedCredentials.password).then(finaliseResponse => {
                            if (finaliseResponse){
                                this.validateAuthentication(finaliseResponse);
                                return;
                            }
                            console.log('Authentication advice :: ', finaliseResponse);
                            this.stopLoad();
                            return;
                    });
                    return;
                }

            } else if (this.externalEntity) {
                this.naturalPerson.addresses = [];
                this.naturalPerson.addresses.push(physicalAddress);

                if(contactPerson!=null)
                {
                    this.naturalPerson.contactPersons.push(contactPerson);
                }

                if (emailAddress && emailAddress.address) {
                    this.naturalPerson.emailAddresses = [];
                    this.naturalPerson.emailAddresses.push(emailAddress);
                }

                // check if number changed
                if(!this.foundExtContactNumber)
                {
                    this.startLoad();
                    this.naturalPerson.contactNumbers = [];
                    this.naturalPerson.contactNumbers.push(contactNumber);
                    this.securedLegalEntityInformationService.issueOneTimePin(contactNumber).then(otpResponse => {
                        if (otpResponse === true) {
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_EXT_OTP_UPDATED);
                            this.contactNumberOTP = contactNumber.country + contactNumber.number;
                            this.externalContactNumber = contactNumber;
                        }
                        this.stopLoad();
                    }).catch(err => {
                        RaygunErrorHandler.sendError(err);

                        if (err) {
                            this.errorMessage = err;
                        }
                        this.stopLoad();
                    });
                }
                else if (this.checkIfContactNumberChanged(contactNumber, this.externalContactNumber)){
                    this.startLoad();
                    this.naturalPerson.contactNumbers = [];
                    this.naturalPerson.contactNumbers.push(contactNumber);
                    this.setSteps("updatedExternal");
                    this.securedLegalEntityInformationService.issueOneTimePin(contactNumber).then(otpResponse => {
                        if (otpResponse === true) {
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_EXT_OTP_UPDATED);
                            this.contactNumberOTP = contactNumber.country + contactNumber.number;
                        }
                        this.stopLoad();
                    }).catch(err => {
                        RaygunErrorHandler.sendError(err);

                        if (err) {
                            this.errorMessage = err;
                        }
                        this.stopLoad();
                    });
                } else {
                    this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_PASSWORD);
                }
            } else {

                if(contactPerson!=null)
                {
                    this.naturalPerson.contactPersons.push(contactPerson);
                }

                this.naturalPerson.contactNumbers.push(contactNumber);
                this.naturalPerson.addresses.push(physicalAddress);

                if (emailAddress && emailAddress.address) {
                    this.naturalPerson.emailAddresses.push(emailAddress);
                }

                if (this.externalEntity && this.capturedExternalIdentifier) {
                    this.naturalPerson.legalEntityIdentifiers.push(this.capturedExternalIdentifier);
                }

                this.startLoad();
                this.createVerifiedAccountService.issueOTP(contactNumber).then(response => {
                    if (response.successful === true) {
                        this.contactNumberOTP = contactNumber.country + contactNumber.number;
                        this.newLegalEntityContactNumber = contactNumber;
                        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_OTP);
                    }else {
                        this.toastOtpError(response.error);
                    }
                    this.stopLoad();
                    return;
                });
            }

            this.createVerifiedAccountService.emailAddress = this.naturalPerson.emailAddresses[0];
            this.createVerifiedAccountService.postalAddress =  this.naturalPerson.addresses.filter(address => address instanceof BasicPostalAddress)[0];
            this.createVerifiedAccountService.residentialAddress = this.naturalPerson.addresses.filter(address => address instanceof BasicPhysicalAddress)[0];
            this.createVerifiedAccountService.contactNumber = this.naturalPerson.contactNumbers[0];

        }

    }

    /* --------------- Internal Existing Legal Entity --------------- */
    validateInternalOTP(event) {
        this.startLoad();
        this.createVerifiedAccountService.validateOTP(event).then(response => {
            if (response !== undefined) {
                if (!response){
                    this.otpVerifyComponent.otpInvalid();
                    this.stopLoad();
                } else {
                    if (this.currentState === VerifiedAccountManagerEnum.CAPTURE_INTERNAL_OTP) {
                        this.createVerifiedAccountService.getNaturalPersonInformation(this.naturalPersonIdentifier).then((naturalPersonResponse) => {
                            if (naturalPersonResponse) {
                                this.naturalPerson = naturalPersonResponse;
                                this.internalContactNumber = this.naturalPerson.contactNumbers[0];
                                this.internalEntity = true;
                                this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PASSWORD);
                            }
                            this.stopLoad();
                        });
                    } else {
                        this.naturalPerson.contactNumbers = [];
                        this.naturalPerson.contactNumbers.push(this.internalContactNumber);
                        this.createVerifiedAccountService.finaliseCreateVerifiedAccountByUpdate(this.naturalPerson,
                            this.createVerifiedAccountService.capturedCredentials.password).then(finaliseResponse => {
                                if (finaliseResponse) {
                                    this.validateAuthentication(finaliseResponse);
                                } else {
                                    console.log('Authentication advice :: ', finaliseResponse);
                                    this.stopLoad();
                                }
                        });
                    }
                }
            }
        });
    }

    resendInternalOTP() {
        this.startLoad();
        this.verifiedAccountManagerService.issueOneTimePin(this.naturalPersonIdentifier).then(otpResponse => {
            if (otpResponse === true) {
                this.otpVerifyComponent.otpResent();
            } else {
                this.errorMessage = 'Unable to resend OTP, please try again.';
            }
            this.stopLoad();
        });
    }

    onInternalLegalEntity(event) {
        if (event instanceof NaturalPerson) {
            this.naturalPerson = event;
            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_INTERNAL_PERSON_CONTACT);
        } else {
            console.error('No natural person from component :: ', event);
            RaygunErrorHandler.sendError('No natural person from component');

        }
    }

    /* --------------- External Legal Entity --------------- */
    validateExternalLegalEntity() {
        if (this.provideExternalLegalEntityFormGroup.valid) {
            const countryIdentifier = new CountryDescriptionIdentifier();
            countryIdentifier.description = this.country.description;
            this.errorMessage = null;

            const trn = new DriverTrafficRegisterNumber();
            trn.number = this.externalIdentifier.value.toLocaleUpperCase();
            trn.countryOfIssue = this.country.description;
            this.capturedExternalIdentifier = trn;
            this.tokenManagerService.setTrnHeader(trn.number);

            // Check if TRN already exist internally
            const criteriaInternal = new LegalEntityProviderCriteria();
            criteriaInternal.type = 'INTERNAL_PUBLIC';
            criteriaInternal.legalEntityIdentifier = trn;
            criteriaInternal.countryIdentifier = countryIdentifier;

            const requestInternal = new ProvideSecuredLegalEntityObscuredContactNumberRequest();
            requestInternal.criteria = criteriaInternal;

            this.securedLegalEntityProviderWrapperService.provideSecuredLegalEntityObscuredContactNumber(requestInternal).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body,
                        ProvideSecuredLegalEntityObscuredContactNumberResponse) as ProvideSecuredLegalEntityObscuredContactNumberResponse);

                    if (response.obscuredContactNumber) {
                        RaygunErrorHandler.sendError("TRN number already exist");
                        this.errorMessage = "TRN number already exist";
                        this.stopLoad();
                    } else {
                        const criteria = new LegalEntityProviderCriteria();
                        criteria.type = 'EXTERNAL';
                        criteria.legalEntityIdentifier = trn;
                        criteria.countryIdentifier = countryIdentifier;

                        const request = new ProvideSecuredLegalEntityObscuredContactNumberRequest();
                        request.criteria = criteria;

                        this.startLoad();
                        this.securedLegalEntityProviderWrapperService.provideSecuredLegalEntityObscuredContactNumber(request).then(httpResponse => {
                            if (httpResponse && httpResponse.body) {
                                const response = (this.unMarshallerService.
                                    unMarshallFromJson(httpResponse.body,
                                    ProvideSecuredLegalEntityObscuredContactNumberResponse) as ProvideSecuredLegalEntityObscuredContactNumberResponse);

                                if (response.obscuredContactNumber) {
                                    if(response.obscuredContactNumber === "none" || response.obscuredContactNumber === "ProviderNotAvailable")
                                    {
                                        this.externalTRN = trn;
                                        this.foundExtContactNumber = false;
                                        this.setSteps("external");
                                        this.externalEntity = true;
                                        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON);
                                        this.currentState = 23;
                                    }
                                    else
                                    {
                                        this.foundExtContactNumber = true;
                                        this.setSteps("external");
                                        this.externalLegalEntityProviderCriteria = criteria;
                                        this.obscuredContact = response.obscuredContactNumber;
                                        this.obscuredContactCountry = response.country;
                                        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OBSCURED_NUMBER);
                                        this.externalEntity = true;
                                    }

                                } else {
                                    this.externalEntity = false;
                                    this.setSteps("malawi");
                                    this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON);
                                }
                            }
                            this.stopLoad();
                        }).catch(error => {
                            this.stopLoad();
                            this.toastr.errorToast(error);
                            RaygunErrorHandler.sendError(error);
                        });
                    }
                }
            }).catch(error => {
                this.stopLoad();
                this.toastr.errorToast(error);
                RaygunErrorHandler.sendError(error);
            });
        }else {
            this.errorMessage = "Traffic Register Number is required.";
        }
    }

    validateObscured(event) {
        if (!this.externalLegalEntityProviderCriteria) {
            console.error('No criteria to validate against.');
            RaygunErrorHandler.sendError('No criteria to validate against.');

            return;
        }

        this.obfuscatedNumberComponent.setErrorMessage(null);
        const request = new ValidateSecuredLegalEntityObscuredContactNumberRequest();
        request.capturedObscuredContactNumber = event;
        request.criteria = this.externalLegalEntityProviderCriteria;

        this.startLoad();
        this.securedLegalEntityProviderWrapperService.validateSecuredLegalEntityObscuredContactNumber(request).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body,
                    ValidateSecuredLegalEntityObscuredContactNumberResponse) as ValidateSecuredLegalEntityObscuredContactNumberResponse);
                if (response.valid) {
                    this.obscuredIncorrectCounter = 0;
                    const contactNumber = new ContactNumber();
                    contactNumber.number = this.obscuredContact + event;
                    contactNumber.contactNumberType = 'Cell';
                    // TODO fix after changes on providers in MalTIS for legal entity
                    contactNumber.country = this.obscuredContactCountry;

                    if (response.tempToken) {
                        this.tokenManagerService.setTempToken(response.tempToken.token);
                    }

                    this.securedLegalEntityInformationService.issueOneTimePin(contactNumber).then(otpResponse => {
                        if (otpResponse) {
                            this.externalContactNumber = contactNumber;
                            this.contactNumberOTP = contactNumber.country + contactNumber.number;
                            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OTP);
                            this.stopLoad();
                        }
                    }).catch(err => {
                        RaygunErrorHandler.sendError(err);

                        if (err) {
                            this.errorMessage = err;
                        }
                        this.stopLoad();
                    });
                } else {
                    this.obscuredIncorrectCounter++;
                    if (this.obscuredIncorrectCounter >= 3) {
                        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON);
                        this.externalEntity = false;
                    } else {
                        this.obfuscatedNumberComponent.setErrorMessage('Incorrect number captured.');
                    }
                    this.stopLoad();
                }
            }
        }).catch(error => {
            this.stopLoad();
            console.error(error);
            RaygunErrorHandler.sendError(error);
        });
    }

    validateExternalOTP(event) {
        this.startLoad();
        this.securedLegalEntityInformationService.validateOneTimePin(event).then(response => {
            if (response !== undefined) {
                if (!response){
                    this.otpVerifyComponent.otpInvalid();
                    this.stopLoad();
                } else {
                    if (this.currentState === VerifiedAccountManagerEnum.CAPTURE_EXTERNAL_OTP) {
                        this.securedLegalEntityInformationService.queryLegalEntityInformation(
                            this.externalLegalEntityProviderCriteria).then(queryLegalEntityResponse => {
                            if (queryLegalEntityResponse.legalEntities.length > 0){
                                this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON);
                                if (queryLegalEntityResponse.legalEntities[0] instanceof NaturalPerson){
                                    this.naturalPerson = (queryLegalEntityResponse.legalEntities[0] as NaturalPerson);
                                    this.externalContactNumber = this.naturalPerson.contactNumbers[0];

                                    for (const emailAddress of this.naturalPerson.emailAddresses) {
                                        if (emailAddress.address.toLowerCase() === 'n/a') {
                                            this.naturalPerson.emailAddresses.splice(this.naturalPerson.emailAddresses
                                                .indexOf(emailAddress), 1);
                                        }
                                    }
                                    for (const identifier of this.naturalPerson.legalEntityIdentifiers) {
                                        if (identifier['@class'] === 'za.co.magnabc.tpi.entity.driver.identification.TrafficRegisterNumber') {
                                            this.externalTRN = identifier;
                                        }
                                    }
                                }
                            }
                            this.stopLoad();
                        })
                    } else if (this.currentState === VerifiedAccountManagerEnum.CAPTURE_EXT_OTP_UPDATED) {
                        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_PASSWORD);
                        this.stopLoad();
                    }
                }
            }
        });
    }

    resendExternalOTP() {
        if (this.externalContactNumber) {
            this.startLoad();
            this.securedLegalEntityInformationService.issueOneTimePin(this.externalContactNumber).then(otpResponse => {
                if (otpResponse) {
                    this.otpVerifyComponent.otpResent();
                }
                this.stopLoad();
            }).catch(err => {
                RaygunErrorHandler.sendError(err);

                if (err) {
                    this.errorMessage = err;
                }
                this.stopLoad();
            });
        }
    }

    onExternalLegalEntity(naturalPerson) {
        this.naturalPerson = naturalPerson;
        if (this.naturalPerson.contactNumbers && this.naturalPerson.contactNumbers[0]) {
            if (!this.naturalPerson.contactNumbers[0].number) {
                this.naturalPerson.contactNumbers[0].number = this.naturalPerson.contactPersons[0].contactNumbers[0].number;
            } else if (this.naturalPerson.contactNumbers[0].number.startsWith('265')) {
                this.naturalPerson.contactNumbers[0].number = this.naturalPerson.contactNumbers[0].number.substr(2);
                this.naturalPerson.contactNumbers[0].country = '+265';
            }
            if (!this.externalContactNumber) {
                this.externalContactNumber = this.naturalPerson.contactNumbers[0];
            }
        }
        if (this.naturalPerson.contactPersons && this.naturalPerson.contactPersons[0]) {
            if (!this.naturalPerson.contactPersons[0].firstNames) {
                this.naturalPerson.contactPersons[0].firstNames = this.naturalPerson.firstNames;
            }
            if (!this.naturalPerson.contactPersons[0].surname) {
                this.naturalPerson.contactPersons[0].surname = this.naturalPerson.surname;
            }
        }

        const identifiers = [];
        if(this.externalTRN)
        {
            identifiers.push(this.externalTRN);
        }
        for (const identifier of this.naturalPerson.legalEntityIdentifiers) {
            if (identifier instanceof NationalIdentityNumber || identifier instanceof PassportNumber ||
                identifier instanceof DriverTrafficRegisterNumber) {
                identifiers.push(identifier);
            }
        }

        this.naturalPerson.legalEntityIdentifiers = identifiers;

        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_UPDATED_EXT_PERSON_CONTACT);
        this.createVerifiedAccountService.setNaturalPerson(this.naturalPerson);
    }

    changeExternalLegalEntity(event) {
        console.log('Change external legal entity :: ', event);
    }

    changeExternalContactNumber(event) {
        console.log('Change external contact number :: ', event);
    }

    /* --------------- New Legal Entity --------------- */
    onNewLegalEntity(naturalPerson) {
        if (naturalPerson) {
            this.createVerifiedAccountService.setNaturalPerson(naturalPerson);
            this.naturalPerson = naturalPerson;
            this.naturalPerson.legalEntityIdentifiers.push(this.naturalPersonIdentifier);
            this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON_CONTACT);
        }
    }

    validateNewLegalEntityOTP(event) {
        if (event) {
            this.startLoad();
            this.createVerifiedAccountService.validateOTP(event).then(response => {
                if (response === true) {
                    this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_PASSWORD);
                } else {
                    this.otpVerifyComponent.otpInvalid();
                }
                this.stopLoad();
            });
        }
    }

    resendNewLegalEntityOTP() {
        if (this.newLegalEntityContactNumber) {
            this.startLoad();
            this.createVerifiedAccountService.issueOTP(this.newLegalEntityContactNumber).then(response => {
                if (response.successful) {
                    this.otpVerifyComponent.otpResent();
                } else {
                    this.toastOtpError(response.error);
                }
                this.stopLoad();
            });
        }
    }

    getLegalEntityUsername():string {
        if(this.naturalPersonIdentifier instanceof PassportNumber){
            return (this.naturalPersonIdentifier as PassportNumber).number;
        }else if( this.naturalPersonIdentifier instanceof NationalIdentityNumber){
            return (this.naturalPersonIdentifier as NationalIdentityNumber).number;
        }

    }

    checkIfContactNumberChanged(contactNumber1: ContactNumber, contactNumber2: ContactNumber): boolean {
        return ((contactNumber1.number !== contactNumber2.number));// && (contactNumber1.contactNumberType === contactNumber2.contactNumberType));
    }

    /* -------------------- */
    validateAuthentication(authenticationAdvice: AuthenticationAdvice) {
        if (authenticationAdvice.authentic) {
            this.startLoad();
            if(this.naturalPersonIdentifier instanceof PassportNumber ){
                this.authenticationService.setLoggedInUsername((this.naturalPersonIdentifier as PassportNumber).number);
            }else if(this.naturalPersonIdentifier instanceof NationalIdentityNumber){
                this.authenticationService.setLoggedInUsername((this.naturalPersonIdentifier as NationalIdentityNumber).number);
            }

            this.authenticationService.setAuthentication(authenticationAdvice).then(() => {

                // Create a session
                let sessionTracking = new SessionTracking();
                let currentTokenExpDate = this.tokenManagerService.getTokens().expirationDate;

                sessionTracking.accountNumber = this.tokenManagerService.getAccountNumber();
                sessionTracking.sessionTrackingId = uuidv4()

                let addExpDate = new Date(currentTokenExpDate.getTime() + 15000);
                sessionTracking.endDate = moment(addExpDate).format('YYYY-MM-DDTHH:mm:ss') as any;
                sessionStorage.setItem('sessiontrackingid', sessionTracking.sessionTrackingId);
                sessionStorage.setItem('accountnumber', sessionTracking.accountNumber);
                sessionStorage.setItem('sessiontrackingenddate', sessionTracking.endDate.toString());

                const createSessionTrackingRequest = new CreateSessionTrackingRequest();
                createSessionTrackingRequest.sessionTracking = sessionTracking;
                this.sessionTrackingManagerService.createSessionTracking(createSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                    if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                        const createSessionTrackingResponse =
                            (this.unMarshallerService
                                .unMarshallFromJson(sessionTrackingHttpResponse.body, CreateSessionTrackingResponse) as CreateSessionTrackingResponse);
                    }
                })

                if (this.quickTransactService.loadQuickTransact ||  this.quickTransact) {
                    if (!this.quickTransactService.npIdentifier) {
                        this.quickTransactService.npIdentifier = Utils.getNationalIdentityNumber(this.authenticationService.getLoggedInNaturalPerson().legalEntityIdentifiers);
                    }

                    this.quickTransactService.legalEntity = this.authenticationService.getLoggedInNaturalPerson();
                    this.retailProfileService.refreshRetailProfile().then(retailProfile => {
                        this.quickTransactService.retailProfile = retailProfile;
                        this.quickTransactService.exists = true;
                        this.quickTransactService.currentStep = 1;
                        this.router.navigate(['/quickstart/user']).then(() => {
                            this.stopLoad();
                            return;
                        });
                    })

                } else {
                    this.router.navigate(['/retail/profile']).then(() => {
                        this.stopLoad();
                        this.clear();
                    });
                }
            }).catch(error => {
                console.error('Error occurred ', error);
                RaygunErrorHandler.sendError(error);

                this.clear();
                this.router.navigate(['/login']).then(() => {
                    this.stopLoad();
                });
            });
        } else {
            this.message = 'Could not authenticate user. Please try again.';
        }
    }

    clear() {
        this.naturalPerson = null;
        this.obscuredContact = null;
        this.naturalPersonIdentifier = null;
        this.accountExists = false;
        this.termsNotAccepted = false;
        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NATURAL_PERSON_ID);
        this.message = null;
        this.errorMessage = null;
        this.country = null;
        this.newLegalEntityContactNumber = null;
        this.internalEntity = false;
        this.internalContactNumber = null;
        this.externalEntity = false;
        this.externalCountry = null;
        this.externalLegalEntityProviderCriteria = null;
        this.externalContactNumber = null;
        this.capturedExternalIdentifier = null;
        this.createVerifiedAccountService.clear();
    }

    toast(_) {

        this.translate.get('TOAST.CREATE_RETAIL_PROFILE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.router.navigate(['/retail/profile']);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.router.navigate(['/retail/profile']);
            });
        });
    }

    onContactPerson(contactPerson: ContactPerson): void {
        if(contactPerson != null){
            this.naturalPerson.contactPersons[0] = contactPerson;
        }
        else{
            this.naturalPerson.contactPersons = [];
        }
    }

    onValidContactPerson(event){
        this.validateLegalEntityInformation();
    }

    toastOtpError(message: string) {

        this.toastr.success(message, "Error", {
            toastComponent: ToastComponent,
            timeOut: environment.toast_time_out,
            progressBar: true,
            closeButton: true
        });
    }

    skipTRNCapturing(): boolean{
        this.onCurrentStateChange(VerifiedAccountManagerEnum.CAPTURE_NEW_PERSON);
        return false
    }

    onToggleShowEmergencyContact(value): void {
        this.addEmergencyContact = value;
    }
}
