import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Broker } from '@magnabc/tpi';
import { Observable } from 'rxjs';

import {
    InsuranceProductManagerService,
} from '../../../managers/insurance-product-manager/insurance-product-manager.service';

@Injectable({
    providedIn: 'root'
})
export class ProductGuard implements CanMatch {
        í

    broker: Broker;

    constructor(
        public insuranceProductManagerService: InsuranceProductManagerService,
        private router: Router,
    ) { }

    canMatch(
        route: Route,
        segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (segments.length > 0) {
            const productNumber = segments[segments.length - 1].path;
            if (route.path && route.path.includes('retail/insurance/create-product-limit') && !this.insuranceProductManagerService.limit) {
                this.router.navigate([`retail/insurance/product-overview/${productNumber}`]);
                return false;
            } else if (route.path && route.path.includes('retail/insurance/product-pricing-schedule') && !this.insuranceProductManagerService.priceSchedule) {
                this.router.navigate([`retail/insurance/product-overview/${productNumber}`]);
                return false;
            } else if (route.path && route.path.includes('retail/insurance/product-excess') && !this.insuranceProductManagerService.excess) {
                this.router.navigate([`retail/insurance/product-overview/${productNumber}`]);
                return false;
            } else {
                return true;
            }
        }
        else {
            return false;
        }
    }
}
