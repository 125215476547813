import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {
    DirectPayOnlinePaymentGatewayManagerComponent
} from './dpo/direct-pay-online-payment-gateway-manager/direct-pay-online-payment-gateway-manager.component'
import {DirectPayOnlineComponent} from './dpo/direct-pay-online/direct-pay-online.component'
import {LoadingModule} from 'ngx-loading'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {SharedModule} from '../../shared/shared.module'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatSelectModule} from '@angular/material/select'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {InsuranceModule} from '../insurance/insurance.module'
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { BankTransferInformationComponent } from './bank-transfer-information/bank-transfer-information.component'
import { MatInputModule } from '@angular/material/input'


@NgModule({
  declarations: [
      DirectPayOnlinePaymentGatewayManagerComponent,
      DirectPayOnlineComponent,
      BankTransferInformationComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LoadingModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        InsuranceModule,
        NgxDatatableModule,
        MatInputModule
    ],
    exports: [
        DirectPayOnlinePaymentGatewayManagerComponent
    ]
})
export class PaymentModule { }
