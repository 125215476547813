import { Criteria } from "../../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../../entity/identification/models/legal-entity-identifier.model";
import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";

export class ProposalGroupEntryAssessorCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalGroupEntryAssessorCriteria";
    }
    
    public underwriterIdentifier: UnderwriterIdentifier;
    public assessor: LegalEntityIdentifier;
}