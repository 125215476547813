import {OrderIdentifier} from "./order-identifier.model";
import {OrderItemNumberIdentifier} from './order-item-number-identifier.model';

export class OrderOrderItemNumberIdentifier extends OrderIdentifier {

    orderNumber: string;
    orderItemNumberIdentifier: OrderItemNumberIdentifier;

    constructor(orderNumber: string, orderItemNumberIdentifier: OrderItemNumberIdentifier) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.checkout.identification.OrderOrderItemNumberIdentifier";
        this.orderNumber = orderNumber;
        this.orderItemNumberIdentifier = orderItemNumberIdentifier;
    }

}

