import {CountryIdentifier} from '../../../../location/geographic/identification/models/country-identifier.model';

export class CountryIdentificationConfiguration {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.CountryIdentificationConfiguration';
    }

    description: string;
    identificationType: string;
    countryIdentifier: CountryIdentifier;
}
