import {Response} from "../../../../../../urdad/services/models/response.model";
import { Vehicle } from "../../../../../vehicle_api";

export class ProvideVehicleInformationResponse extends Response {

    constructor() {
        super();
    }

    public vehicleInformation: Vehicle [] = [];
}