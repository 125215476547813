import { JudicialPersonIdentifier } from "../../models/judicial-person-identifier.model";

export class RegistrationNumber extends JudicialPersonIdentifier
{

  constructor(number: string, countryOfIssue: string){
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.identification.judicialperson.RegistrationNumber";
    this.number = number;
    this.countryOfIssue = countryOfIssue;
  }

  public countryOfIssue: string;
  public number: string;

}
