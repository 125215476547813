import {Response} from "../../../../../../../urdad/services/models/response.model";
import { Answers } from "../../../../../../insurance/comprehensiveinsurance/models/answers.model";

export class CaptureProposalResponse extends Response{
    constructor(){
        super();
    }

    public answers : Answers[] = [];
}
