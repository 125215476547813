export class DropdownUtils {

    static sortPreferredCode(list, preferred): any {

        const results = [];

        list.sort((a, b) => {
            if (preferred.indexOf(a.code) >= 0 || preferred.indexOf(b.code) >= 0) {
                return preferred.indexOf(a.code) - preferred.indexOf(b.code);
            }
            if (a.code > b.code) {
                return 1;
            }
            if (a.code < b.code) {
                return -1;
            }
            return 0;
        });

        for (let i = list.length - preferred.length; i < list.length; i++) {
            results.push(list[i]);
        }
        for (let i = 0; i < list.length - preferred.length; i++) {
            results.push(list[i]);
        }

        return results;

    }

    static sortPreferredValue(list, preferred): any {

        const results = [];

        list.sort((a, b) => {
            if (preferred.indexOf(a.value) >= 0 || preferred.indexOf(b.value) >= 0) {
                return preferred.indexOf(a.value) - preferred.indexOf(b.value);
            }
            if (a.value > b.value) {
                return 1;
            }
            if (a.value < b.value) {
                return -1;
            }
            return 0;
        });

        for (let i = list.length - preferred.length; i < list.length; i++) {
            results.push(list[i]);
        }
        for (let i = 0; i < list.length - preferred.length; i++) {
            results.push(list[i]);
        }

        return results;

    }

    static sortPreferredName(list, preferred): any {

        const results = [];
        let preferredItemsInList = 0;

        for (const item of list) {
            if (preferred.indexOf(item.value) >= 0) {
                preferredItemsInList++;
            }
        }

        list.sort((a, b) => {
            if (preferred.indexOf(a.value) >= 0 || preferred.indexOf(b.value) >= 0) {
                return preferred.indexOf(a.value) - preferred.indexOf(b.value);
            }
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

        for (let i = list.length - preferredItemsInList; i < list.length; i++) {
            results.push(list[i]);
        }
        for (let i = 0; i < list.length - preferredItemsInList; i++) {
            results.push(list[i]);
        }

        return results;

    }

}
