import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {from} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({name: 'imageFile'})
export class ImageFilePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: any): Observable<SafeHtml> {
        return from(new Promise<SafeHtml>((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as any));
            }
            reader.readAsDataURL(value);
        }));
    }

}
