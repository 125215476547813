import {Criteria} from "../../../criteria/criteria_api";


export class VehicleMakeCriteria extends Criteria {
    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.vehicle.makemodeltrim.VehicleMakeCriteria";
    }

    name: string;
}
