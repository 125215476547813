import { Criteria } from "../../../criteria/models/criteria.model";
import {ScannedDocumentIdentifier} from '../identification/models/scanned-document-identifier.model';

export class ScannedDocumentCriteria extends Criteria {

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.media.scanneddocument.ScannedDocumentCriteria";
  }

  public scannedDocumentIdentifier: ScannedDocumentIdentifier;
}
