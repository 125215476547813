import { Criteria } from '../../../../criteria/models/criteria.model';
import { LegalEntityIdentifier } from '../../../../entity/identification/models/legal-entity-identifier.model';

export class ProposalRecordSummaryDateCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalRecordSummaryDateCriteria";
    }

        
    public dateFrom: Date;
    public dateTo:Date;
    public legalEntityIdentifier: LegalEntityIdentifier;

}