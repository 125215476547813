import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CountryDescriptionIdentifier, CountryRegistrationConfigurationInformation, Money, PickUpPoint, ShipmentType, ShippingAddress } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { FormComponent } from '../form/form.component';
import { MatSelectChange } from '@angular/material/select';

declare const jQuery: any;

@Component({
    selector: 'app-shipment-capture',
    templateUrl: './shipment-capture.component.html',
    styleUrls: ['./shipment-capture.component.scss']
})
export class ShipmentCaptureComponent extends FormComponent implements OnInit {

    @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    @Input() pickUpPoints: PickUpPoint[];
    @Output() onShipmentCapture = new EventEmitter<any>();
    @Output() onValid = new EventEmitter<void>();
    @Output() form = new EventEmitter<FormGroup>();

    formGroup: FormGroup;
    formGroupPickUp: FormGroup;
    loading: boolean = false;
    shipmentType: ShipmentType;
    textPath = ShipmentType.PICKUP;
    shipmentTypes = ShipmentType

    addressLineOne: UntypedFormControl;
    addressLineTwo: UntypedFormControl;
    city: UntypedFormControl;
    country: UntypedFormControl;
    postalCode: UntypedFormControl;
    pickUpPointCtrl: UntypedFormControl;

    disableControls = false;
    deliveryCost: Money;

    constructor(public translate: TranslateService,
        public translateParser: TranslateParser,) {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.buildPickUpForm();
        this.setDeliveryCost();
    }

    private setDeliveryCost(): void {
        this.deliveryCost = new Money();
        this.deliveryCost.amount = 0;
        this.deliveryCost.currency = 'USD';
    }

    private buildPickUpForm(): void {
        this.buildForm(true);
        this.disableControls = true;
        this.shipmentType = ShipmentType.PICKUP;
    }

    private buildDeliveryForm(): void {
        this.buildForm();
        this.disableControls = false;
        this.shipmentType = ShipmentType.DELIVER;
    }

    private updateForm(address: ShippingAddress, country: CountryDescriptionIdentifier): void {
        this.addressLineOne.setValue(address.shippingAddressLineOne);
        this.addressLineTwo.setValue(address.shippingAddressLineTwo);
        this.city.setValue(address.shippingAddressCity);
        this.country.setValue(country.description);
        this.postalCode.setValue(address.shippingAddressCode);
    }

    private buildForm(isPickUp = false, addressLineOne = '', addressLineTwo = '', city = '', country = '', postalCode = ''): void {
        const addressLineOneCtrl = new UntypedFormControl(addressLineOne, [Validators.required]);
        const addressLineTwoCtrl = new UntypedFormControl(addressLineTwo, [Validators.required])
        const cityCtrl = new UntypedFormControl(city, [Validators.required]);
        const countryCtrl = new UntypedFormControl(country, [Validators.required]);
        const postalCodeCtrl = new UntypedFormControl(postalCode, [Validators.required]);
        const pickUpPointCtrl = new UntypedFormControl(null, [Validators.required]);

        if (isPickUp) {
            pickUpPointCtrl.addValidators([Validators.required]);
        }

        this.formGroup = new UntypedFormGroup({
            addressLineOneCtrl,
            addressLineTwoCtrl,
            cityCtrl,
            countryCtrl,
            postalCodeCtrl,
            pickUpPointCtrl
        });

        this.addressLineOne = addressLineOneCtrl;
        this.addressLineTwo = addressLineTwoCtrl;
        this.city = cityCtrl;
        this.country = countryCtrl;
        this.postalCode = postalCodeCtrl;
        this.pickUpPointCtrl = pickUpPointCtrl;

        this.form.emit(this.formGroup);
    }

    get titleShipmentType(): Observable<any> {
        return this.translate.get(`SHIPMENT.${this.textPath}.TITLE`);
    }

    get helpSuburbText(): Observable<any> {
        return this.translate.get(`HELP.ADDRESS_${this.textPath}.SUBURB`);
    }

    get helpAddressText(): Observable<any> {
        return this.translate.get(`HELP.ADDRESS_${this.textPath}.STREET_ADDRESS`);
    }

    get helpCityText(): Observable<any> {
        return this.translate.get(`HELP.ADDRESS_${this.textPath}.CITY`);
    }

    get helpCountryText(): Observable<any> {
        return this.translate.get(`HELP.ADDRESS_${this.textPath}.COUNTRY`);
    }

    get helpPostalText(): Observable<any> {
        return this.translate.get(`HELP.ADDRESS_${this.textPath}.POSTAL_CODE`);
    }

    switchShipmentType(event: MatCheckboxChange, type: ShipmentType) {
        if (type === this.shipmentType) {
            this.shipmentType = null;
            setTimeout(() => {
                this.shipmentType = type;
            });
            return;
        }
        if (event.checked && this.shipmentType !== type) {
            this.shipmentType = type;
            this.textPath = type;
        }
        if (type === ShipmentType.DELIVER) {
            this.buildDeliveryForm();
        } else if (type === ShipmentType.PICKUP) {
            this.buildPickUpForm();
            this.pickUpPointCtrl.reset();
        }

    }

    submit(): void {
        document.getElementById('form-submit-shipment-capture').click();
    }

    valid(): boolean {
        return this.formGroup.valid;
    }

    onCheckValidation(event): void {

        if (!this.formGroup.invalid) {

            this.onShipmentCapture.emit({
                shippingAddressLineOne: this.addressLineOne.value,
                shippingAddressLineTwo: this.addressLineTwo.value,
                shippingAddressCity: this.city.value,
                shippingAddressLineThree: this.country.value,
                shippingAddressCode: this.postalCode.value,
                shipmentType: this.shipmentType,
            });
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    onPickUpAddressChanged(event: MatSelectChange): void {
        const selectedPickUpPoint = this.pickUpPoints.find(point => point.shippingAddress.shippingAddressName === event.value);
        if (selectedPickUpPoint) {
            this.updateForm(selectedPickUpPoint.shippingAddress, selectedPickUpPoint.countryDescriptionIdentifier);
        }
    }

    getAddressSelectViewValue(address: ShippingAddress): string {
        let value = [];
        if (address.shippingAddressLineOne) {
            value.push(address.shippingAddressLineOne);
        }
        if (address.shippingAddressLineTwo) {
            value.push(address.shippingAddressLineTwo);
        }
        if (address.shippingAddressCity) {
            value.push(address.shippingAddressCity);
        }

        return value.join(", ");
    }
}
