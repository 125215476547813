import { LegalEntityIdentifier } from "../../../../entity/identification/models/legal-entity-identifier.model";
import { Money } from "../../../../financial/models/money.model";
import { ProposalResultsEnum } from "./proposal-results.model";
import { VehicleEvaluation } from "./vehicle-evaluation.model";

export class ProposalResult {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalResult";
    }

    public proposalId: string;
    public resultDate: Date;
    public result: ProposalResultsEnum;
    public annualPricePercentage: number;
    public periodPricePercentage: number;
    public additionalPrice: Money;
    public ncdPercentage: number;
    public assessor: LegalEntityIdentifier;
    public vehicleEvaluation: VehicleEvaluation;
    public proposalPolicyItemNumber: string;
    public proposalResultId: string;
}