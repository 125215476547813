import { Criteria } from "../../../criteria/models/criteria.model";
import {DocumentTypeIdentifier} from '../identification/models/document-type-identifier.model';

export class DocumentTypeCriteria extends Criteria {

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.media.scanneddocument.DocumentTypeCriteria";
  }

  public documentTypeIdentifier: DocumentTypeIdentifier;
}
