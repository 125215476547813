import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../../../shared/form/form.component";
import {
    InsurancePolicyNumberCriteria,
    ProvideInsurancePoliciesRequest,
    UnMarshallerService,
    ProvideInsurancePoliciesResponse,
    InsurancePolicy,
    LegalEntityDescriptionIdentifier,
    UnderwriterLegalEntityIdentifier,
} from "@magnabc/tpi";
import { InsurancePolicyProviderService } from '../../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service';
import { SystemParameterProviderService } from '../../../../../http.services/system/system-parameter-provider/system-parameter-provider.service';
import { UnderwriterInformationProviderService } from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import { Utils } from '../../../../shared/Utils/Utils';
import * as moment from 'moment';

@Component({
    selector: 'app-query-insurance-policy',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss']
})
export class InsurancePolicyQueryComponent extends FormComponent implements OnInit {

    @Output() onInsurancePolicy = new EventEmitter<InsurancePolicy[]>();

    formGroup: UntypedFormGroup;
    identifier: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private insurancePolicyProviderService: InsurancePolicyProviderService,
                private unMarshallerService: UnMarshallerService,
                private systemParameterService : SystemParameterProviderService,
                public brokerService : UnderwriterInformationProviderService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        this.identifier = new UntypedFormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9]+')]);
        this.formGroup = new UntypedFormGroup({
            'identifier': this.identifier,
        });

    }

    submit(): void {
        document.getElementById('form-submit-query').click();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {

            const thirdPartyInsurancePolicyNumberCriteria = new InsurancePolicyNumberCriteria();
            thirdPartyInsurancePolicyNumberCriteria.insurancePolicyNumber = this.identifier.value.toLocaleUpperCase()
            thirdPartyInsurancePolicyNumberCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
            const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
            provideThirdPartyInsurancePoliciesRequest.criteria = thirdPartyInsurancePolicyNumberCriteria;
            provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
            provideThirdPartyInsurancePoliciesRequest.batchSize = -1;


            const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

            legalEntityDescriptionIdentifier.name = this.brokerService.selectedUnderwriter.name;
            legalEntityDescriptionIdentifier.surname = this.brokerService.selectedUnderwriter.name;
            legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier([this.brokerService.selectedUnderwriter.legalEntityIdentifier]);

            provideThirdPartyInsurancePoliciesRequest.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
                this.brokerService.selectedUnderwriter.name,
                legalEntityDescriptionIdentifier
            );

            this.startLoad();
            this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson
                        (httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                    this.stopLoad();
                    this.onInsurancePolicy.emit(response.insurancePolicies);
                }
            }).catch((error) => {
                this.stopLoad();
            });

        }

    }

}
