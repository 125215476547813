import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {
    Broker,
    BrokerLegalEntityCriteria,
    BrokerLegalEntityIdentifier,
    ComprehensiveInsuranceProduct,
    InsuranceProductBrokerCriteria,
    LegalEntityDescriptionIdentifier,
    Product,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideInsuranceProductInformationRequest,
    ProvideInsuranceProductInformationResponse,
    ThirdPartyInsuranceProduct,
    TpiIdentifier,
    UnMarshallerService,
    YCInsuranceProduct
} from '@magnabc/tpi';

import { Utils } from '../../../../view.components/shared/Utils/Utils';
import {
    BrokerInformationProviderService,
} from '../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service';
import {
    InsuranceProductInformationProviderService,
} from '../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service';
import {
    InsuranceProductManagerService,
} from '../../../managers/insurance-product-manager/insurance-product-manager.service';
import { AuthenticationService } from '../../../managers/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class ProductResolver implements Resolve<any>{
    private broker: Broker;

    constructor(
        private unMarshallerService: UnMarshallerService,
        private brokerInformationProviderService: BrokerInformationProviderService,
        private authenticationService: AuthenticationService,
        public insuranceProductManagerService: InsuranceProductManagerService,
        private insuranceProductInformationProviderService: InsuranceProductInformationProviderService,
        private router: Router
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<TProductResolver> | boolean {
        const product = this.insuranceProductManagerService.product;
        if (product) {
            return true;
        } else {
            const isNew = route.params.number === 'new';
            if (isNew && product === null) {
                return true;
            } else if (isNew && product === undefined) {
                this.router.navigate(['/broker/products']);
                return false;
            } else {
                const productNumber = route.params.number;
                return this.provideProduct(productNumber);
            }
        }
    }

    private provideProduct(productNumber: string): Promise<TProductResolver> {
        return new Promise((resolve, reject) => {
            this.getBroker().then(() => {
                this.getProduct(productNumber).then(product => resolve(product));
            })
        });
    };

    getProduct(number: string): Promise<TProductResolver> {
        return new Promise((resolve, reject) => {
            const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier()
            legalEntityDescriptionIdentifier.name = this.broker.name;
            legalEntityDescriptionIdentifier.tpiIdentifier = this.broker.legalEntityIdentifier as TpiIdentifier;
            const brokerIdentifier = new BrokerLegalEntityIdentifier(this.broker.name, legalEntityDescriptionIdentifier);

            const provideInsuranceProductInformationRequest = new ProvideInsuranceProductInformationRequest();
            const insuranceProductBrokerCriteria = new InsuranceProductBrokerCriteria();
            insuranceProductBrokerCriteria.insuranceCategory = this.insuranceProductManagerService.insuranceCategory;
            insuranceProductBrokerCriteria.brokers.push(brokerIdentifier);
            provideInsuranceProductInformationRequest.criteria = insuranceProductBrokerCriteria;

            this.insuranceProductInformationProviderService.provideInsuranceProductInformation(provideInsuranceProductInformationRequest).toPromise().then(response => {

                if (response && response.body) {
                    const provideInsuranceProductInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceProductInformationResponse) as ProvideInsuranceProductInformationResponse);
                    const products: Product[] = provideInsuranceProductInformationResponse.products;
                    const product = products.find((item) => item.number === number);
                    if (product instanceof ThirdPartyInsuranceProduct) {
                        this.insuranceProductManagerService.product = product;
                    } else if (product instanceof ComprehensiveInsuranceProduct) {
                        this.insuranceProductManagerService.product = product;
                    } else if (product instanceof YCInsuranceProduct) {
                        this.insuranceProductManagerService.product = product;
                        this.insuranceProductManagerService.ycPriceIntervals.next(product.ycPriceIntervals);
                        this.insuranceProductManagerService.ycPriceSchedules.next(product.ycPriceSchedules);
                    }
                    this.insuranceProductManagerService.disabledFields = true;
                    resolve(product);
                }
            }).catch(err => {
                reject(err)
            });
        })
    }


    getBroker(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const provideBrokerRequest = new ProvideBrokerInformationRequest();
            const brokerLegalEntityCriteria = new BrokerLegalEntityCriteria();
            if (this.authenticationService.getLoggedInNaturalPerson() != null) {
                brokerLegalEntityCriteria.legalEntity = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
            }

            provideBrokerRequest.criteria = brokerLegalEntityCriteria;

            this.brokerInformationProviderService.provideBroker(provideBrokerRequest).subscribe((response) => {

                if (response && response.body) {
                    const provideBrokerInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse);
                    this.broker = provideBrokerInformationResponse.brokers[0];
                    resolve(true);
                } else {
                    reject();
                }
            },
                (error) => {
                    reject(error);
                });
        })

    }
}

type TProductResolver = Product | ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct;
