import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, PublicAuthenticateCredentialsRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class PublicAuthenticatorService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    authenticateCredentials(authenticateCredentialsRequest: PublicAuthenticateCredentialsRequest) {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const authenticateCredentialsURI = this.runtimeConfigurationService.apiBaseUrl + '/security/publicauthenticator/authenticatecredentials';

        const jsonAuthenticateCredentialsRequest = this.marshallerService.marshallObjectToJSON(authenticateCredentialsRequest);

        return this.httpClient.post<any>(authenticateCredentialsURI, jsonAuthenticateCredentialsRequest, options).toPromise();
    }
}
