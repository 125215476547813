import {PricePerVehicle} from "../../../../thirdpartyinsurance/models/price-per-vehicle.model"
import {RetailProfileIdentifier} from "../../../../../profile/identification/retail-profile-identifier.model"
import {Request} from "../../../../../../urdad/services/models/request.model"
import {OrderItem} from '../../../../../checkout/models/order-item.model'

export class AddExtensionToCartRequest extends Request {
  constructor() {
    super();
  }

  public retailProfileIdentifier: RetailProfileIdentifier;
  public orderItems: OrderItem[];
  public expiryDate: Date;
  public orderItemNumbers: string[];
  public pricePerVehicles: PricePerVehicle[];
  public policyNumber: string;
}
