export class InsurancePolicyVehicleSummary {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyVehicleSummary";
    }

    public tpiVehicleIdentifier: string
    public plateNumber: string
    public manufactureDate: string
    public make: string
    public model: string
    public colour: string
    public expiryDate: Date
    public certificateNumber: string;
    public cancelled: boolean;
}
