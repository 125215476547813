import {Response} from '../../../../../../../urdad/services/models/response.model';
import {InsurancePolicySummary} from '../../../../../models/insurance-policy-summary.model';


export class ProvideInsurancePolicySummaryResponse extends Response{

    constructor(){
        super();
    }
    
    public resultsFrom: number;
    public resultsTo : number;
    public totalNumberOfResults : number;
    public insurancePolicySummaries: InsurancePolicySummary[] = [];
}
