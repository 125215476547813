import {Component, OnInit, ViewChild} from '@angular/core';
import {FormComponent} from "../../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../app.services/managers/authentication/authentication.service";
import * as XLSX from "xlsx";
import {SalesReportProviderService} from "../../../../http.services/ledger/reporting/sales-report-provider/sales-report-provider.service";
import {
    ProvideInsuranceSalesByProductReportRequest,
    ProvideInsuranceSalesByProductReportResponse,
    ProvideInsuranceSalesBySalesChannelReportRequest,
    ProvideInsuranceSalesBySalesChannelReportResponse, InsuranceSalesByProductReport,
    InsuranceSalesByProductReportLegalEntityDateCriteria,
    InsuranceSalesBySalesChannelReport,
    InsuranceSalesBySalesChannelReportLegalEntityDateCriteria,
    UnMarshallerService,
    InsuranceCategory
} from "@magnabc/tpi";
import * as moment from "moment";
import {Utils} from "../../../shared/Utils/Utils";
import {UntypedFormControl} from "@angular/forms";
import {Dropdown} from "../../../../common/model/dropdown.model";
import { ErrorToastService } from '../../../../app.services/common/error-toast/error-toast.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

type AOA = any[][];

@Component({
    selector: 'app-sales-report',
    templateUrl: './sales-report.component.html',
    styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent extends FormComponent implements OnInit {

    SALES_BY_PRODUCT_REPORT = 0;
    SALES_BY_SALES_CHANNEL = 1;

    SALES_BY_PRODUCT_REPORT_COLUMNS = ["Product Name", "Product Description", "Insurance Category", "Insurance Type", "Vehicle Category", "Net Quantity", "Gross Sales", "Net Sales"];
    SALES_BY_SALES_CHANNEL_COLUMNS = ["Channel Name", "Order Quantity", "Avergae Order Size", "Gross Sales", "Net Sales"];

    rows: InsuranceSalesBySalesChannelReport[] |InsuranceSalesByProductReport[] = [];
    data: AOA = [];
    fileName = 'Sales Report.xlsx';
    columns = this.SALES_BY_PRODUCT_REPORT_COLUMNS;
    reportType = this.SALES_BY_PRODUCT_REPORT;

    from;
    to;

    period; selectFormControl: UntypedFormControl;
    timeFrames = [
        new Dropdown(0, "By Product"),
        new Dropdown(1, "By Sales Channel")
    ];

    salesByProductDataSource: MatTableDataSource<any>;
    salesByChannelDataSource: MatTableDataSource<any>;
    salesByProductDisplayedColumns: string[] = ['productName', 'productDescription', 'insuranceCategory',	'insuranceType',
    'insuranceVehicleCategory', 'netQuantity', 'grossSales', 'netSales'];
    salesByChannelDisplayedColumns: string[] = ["channelName", "orderQuantity", "averageOrderSize", "grossSales", "netSales"];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService,
                private salesReportProviderService: SalesReportProviderService,
                private errorToastService: ErrorToastService) {
        super(translate, translateParser);

        this.salesByProductDataSource = new MatTableDataSource(this.rows as InsuranceSalesByProductReport[]);
        this.salesByChannelDataSource = new MatTableDataSource(this.rows as InsuranceSalesBySalesChannelReport[]);
    }

    ngOnInit() {
        this.period = new UntypedFormControl(this.timeFrames[0], []);
        this.from = moment();
        this.to = moment();
        this.getSalesReport();
    }

    ngAfterViewInit() {
        this.salesByProductDataSource.paginator = this.paginator;
        this.salesByChannelDataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.salesByProductDataSource.filter = filterValue.trim().toLowerCase();
        this.salesByChannelDataSource.filter = filterValue.trim().toLowerCase();

        if (this.salesByProductDataSource.paginator) {
            this.salesByProductDataSource.paginator.firstPage();
        }
        if (this.salesByChannelDataSource.paginator) {
            this.salesByChannelDataSource.paginator.firstPage();
        }
    }

    export(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report');
        XLSX.writeFile(wb, this.fileName);
    }

    onChangeSelected(event) {
        this.reportType = event.value.value;

        if (this.reportType === this.SALES_BY_PRODUCT_REPORT) {
            this.columns = this.SALES_BY_PRODUCT_REPORT_COLUMNS;
        } else if (this.reportType === this.SALES_BY_SALES_CHANNEL) {
            this.columns = this.SALES_BY_SALES_CHANNEL_COLUMNS;
        }
        this.getSalesReport();

    }

    getSalesReport() {

        this.from.startOf('day');
        this.to.endOf('day');

        if (this.reportType === this.SALES_BY_PRODUCT_REPORT) {

            const insuranceSalesByProductReportLegalEntityDateCriteria = new InsuranceSalesByProductReportLegalEntityDateCriteria();
            insuranceSalesByProductReportLegalEntityDateCriteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
            insuranceSalesByProductReportLegalEntityDateCriteria.dateTo = this.to.startOf('day').format('YYYY-MM-DD') as Date;
            insuranceSalesByProductReportLegalEntityDateCriteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

            const provideInsuranceSalesByProductReportRequest = new ProvideInsuranceSalesByProductReportRequest();
            provideInsuranceSalesByProductReportRequest.criteria = insuranceSalesByProductReportLegalEntityDateCriteria;

            this.startLoad();
            this.salesReportProviderService.provideInsuranceSalesByProductReport(provideInsuranceSalesByProductReportRequest).subscribe((httpResponse) => {

                const response = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsuranceSalesByProductReportResponse) as ProvideInsuranceSalesByProductReportResponse;

                this.rows = []
                this.salesByProductDataSource.data = [];
                if(response.insuranceSalesByProductReport != null){
                    const report = response.insuranceSalesByProductReport;
                    this.rows = [report];
                    this.data = [];
                    this.data.push([report.dateFrom, report.dateTo]);
                    this.data.push(this.columns);

                    this.salesByProductDataSource.data = this.rows[0].insuranceSalesByProductEntries;
                    for (const item of report.insuranceSalesByProductEntries) {
                        this.data.push([item.productName, item.productDescription, item.insuranceCategory, item.insuranceType, item.insuranceVehicleCategoryDescription, `${item.netQuantity}`, `${(item.grossSales.amount / 100).toFixed(2)} ${item.grossSales.currency}`, `${(item.netSales.amount / 100).toFixed(2)} ${item.netSales.currency}`]);
                    }
                }

                this.stopLoad();
            },(error) => {
                this.errorToastService.errorToast(error);
                this.stopLoad();
            });

        } else if (this.reportType === this.SALES_BY_SALES_CHANNEL) {

            const insuranceSalesBySalesChannelReportLegalEntityDateCriteria = new InsuranceSalesBySalesChannelReportLegalEntityDateCriteria();
            insuranceSalesBySalesChannelReportLegalEntityDateCriteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
            insuranceSalesBySalesChannelReportLegalEntityDateCriteria.dateTo = this.to.startOf('day').format('YYYY-MM-DD') as Date;
            insuranceSalesBySalesChannelReportLegalEntityDateCriteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());

            const provideInsuranceSalesBySalesChannelReportRequest = new ProvideInsuranceSalesBySalesChannelReportRequest();
            provideInsuranceSalesBySalesChannelReportRequest.criteria = insuranceSalesBySalesChannelReportLegalEntityDateCriteria;

            this.startLoad();
            this.salesReportProviderService.provideInsuranceSalesBySalesChannelReport(provideInsuranceSalesBySalesChannelReportRequest).subscribe((httpResponse) => {

                const response = this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsuranceSalesBySalesChannelReportResponse) as ProvideInsuranceSalesBySalesChannelReportResponse;

                this.rows = []
                this.salesByChannelDataSource.data = [];
                if(response.insuranceSalesBySalesChannelReport != null){

                    const report = response.insuranceSalesBySalesChannelReport;
                    this.rows = [report];
                    this.data = [];
                    this.data.push([report.dateFrom, report.dateTo]);
                    this.data.push(this.columns);

                    this.salesByChannelDataSource.data = this.rows[0].insuranceSalesBySalesChannelEntries;
                    for (const item of report.insuranceSalesBySalesChannelEntries) {
                        this.data.push([item.channelName, `${item.orderQuantity}`, `${item.averageOrderSize}`, `${(item.grossSales.amount / 100).toFixed(2)} ${item.grossSales.currency}`, `${(item.netSales.amount / 100).toFixed(2)} ${item.netSales.currency}`]);
                    }
                }

                this.stopLoad();
            },(error) => {
                this.errorToastService.errorToast(error);
                this.stopLoad();
            });

        }

    }

    onSubmit(): void {
        this.getSalesReport();
    }

    /**
     * Get the insurance type based on the insurance category
     * @param insuranceCategory The insurance category
     * @param type The insurance type
     * @returns A string representing the insurance type
     */
    getInsuranceType(insuranceCategory: InsuranceCategory, type: string): string {
        if (insuranceCategory == InsuranceCategory.THIRDPARTY) {
            return type;
        } else if (insuranceCategory == InsuranceCategory.COMPREHENSIVE) {
            return 'Comprehensive';
        } else if (insuranceCategory == InsuranceCategory.YELLOWCARD) {
            return 'Yellow Card';
        }

        return '';
    }

}
