import { Request } from "../../../../../../urdad/services/models/request.model";
import { LegalEntity } from "../../../../../models/legal-entity.model";

export class UpdateLegalEntityRequest extends Request{
    constructor(){
        super();
      }

    public legalEntity: LegalEntity;
    public pmType: string; 
}
