import { ImportFleetResultComponent } from './import-fleet-manager/import-fleet-result/import-fleet-result.component';
import { MatInputModule } from '@angular/material/input'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PolicyListComponent } from './policy-list/policy.list.component'
import { SyncVehicleDialogComponent } from './dialogs/sync-vehicle-dialog/sync-vehicle-dialog.component'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '../../shared/shared.module'
import { LoadingModule } from 'ngx-loading'
import { ObscuredValidationService } from './services/obscured-validation.service'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { EntityModule } from '../../entity/entity.module'
import { SecurityModule } from '../../security/security.module'
import { VehicleModule } from '../../vehicle/vehicle.module'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { InsuranceModule } from '../insurance/insurance.module'
import { ThirdPartyInsuranceModule } from '../insurance/third-party-insurance/third-party-insurance.module'
import { FleetTemplateComponent } from './import-fleet-manager/fleet-template/fleet-template/fleet-template.component'
import { UploadFleetComponent } from './import-fleet-manager/upload-fleet/upload-fleet/upload-fleet.component'
import { MaterialFileInputModule } from 'ngx-material-file-input'
import { DialogUpdatePasswordComponent } from '../../../view.pages/retail/profile/retail-profile-update-page/dialog-update-password/dialog-update-password';
import { RetailProfileComponent } from './retail-profile/retail-profile.component'
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
    declarations: [
        PolicyListComponent,
        SyncVehicleDialogComponent,
        FleetTemplateComponent,
        UploadFleetComponent,
        ImportFleetResultComponent,
        DialogUpdatePasswordComponent,
        RetailProfileComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LoadingModule,
        MatDialogModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        EntityModule,
        SecurityModule,
        VehicleModule,
        NgxDatatableModule,
        InsuranceModule,
        MatInputModule,
        ThirdPartyInsuranceModule,
        MaterialFileInputModule,
        MatExpansionModule
    ],
    exports: [
        PolicyListComponent,
        FleetTemplateComponent,
        ImportFleetResultComponent,
        UploadFleetComponent,
        RetailProfileComponent
    ],
    providers: [ObscuredValidationService]
})
export class ProfileModule { }
