import { Criteria } from "../../../criteria/models/criteria.model";
import { OrderIdentifier } from "../identification/models/order-identifier.model";
import { OrderStatus } from "./order-status.model";
export class OrderStatusCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderStatusCriteria";
    }
    
    public status: OrderStatus [] = [];
    public orderIdentifiers: OrderIdentifier [] = [];
}