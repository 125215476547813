import {Criteria} from "../../criteria/models/criteria.model";
import { VehicleIdentifier } from "../vehicle_api";

export class VehicleCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.VehicleCriteria";
    }

	public vehicleIdentifiers: VehicleIdentifier [] = [];
}