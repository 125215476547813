import { Money } from "../../../../financial/models/money.model";
import { Vehicle } from "../../../../vehicle/models/vehicle.model";
import { ThirdPartyInsuranceCartItem } from "./third-party-insurance-cart-item.model";
import { ThirdPartyInsuranceProductCartItem } from "./third-party-insurance-product-cart-item.model";

export class ThirdPartyInsurancePolicyCartItem extends ThirdPartyInsuranceCartItem{

    constructor(){
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyCartItem";
    }

    public price: Money;
    public startDate: Date;
    public expiryDate: Date;
    public extensionExpiryDate: Date;
    public vehicle: Vehicle;
    public thirdPartyInsuranceProductCartItem: ThirdPartyInsuranceProductCartItem;
    public additionalVehicleToPolicy: boolean;
}
