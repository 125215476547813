import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs"
import {MarshallerService, ProvideRefundSummaryRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class RefundProviderService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideRefundSummary(provideRefundSummaryRequest: ProvideRefundSummaryRequest): Observable<any> {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/payment/refundprovider/providerefundsummary';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRefundSummaryRequest);
        return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
    }

}
