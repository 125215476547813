import {Request} from '../../../../../../../urdad/services/models/request.model';
import {Credentials} from '../../../../../../credentials/models/credentials.model';

export class ValidateObscuredContactNumberRequest extends Request {
    constructor(){
        super();
    }

    credentials: Credentials;
    obscuredNumbers: string;
}
