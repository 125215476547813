import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";
import { OrderIdentifier } from "../../checkout/identification/models/order-identifier.model";
import { BrokerIdentifier } from "../identification/models/broker-identifier.model";
import { InsuranceCategory } from "./insurance-category.model";
import { InsurancePolicyItem } from "./insurance-policy-item.model";
import { MotorInsuranceVehiclePolicy } from "./motor-insurance-vehicle-policy.model";

export class MotorInsurancePolicyItem extends InsurancePolicyItem {
    
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.MotorInsurancePolicyItem"; 
    }
    
    public thirdPartyInsuranceType: string;
    public conditions: string;
    public productDescription: string;
    public insuranceCategory: InsuranceCategory;
    public productName: string;
    public orderIdentifier: OrderIdentifier;
    public brokerIdentifier: BrokerIdentifier;
    public coveredCountries: CountryIdentifier[] = [];
    public motorInsuranceVehiclePolicy: MotorInsuranceVehiclePolicy;
    public insurancePolicyItemType: string;
}
