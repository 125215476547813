import { Component, Input, OnInit } from '@angular/core';
import { Vehicle, ImportFleetResponse, VehicleImportError, ImportVehicle } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-import-fleet-result',
    templateUrl: './import-fleet-result.component.html',
    styleUrls: ['./import-fleet-result.component.scss']
})
export class ImportFleetResultComponent implements OnInit {
    @Input() data: ImportFleetResponse;

    columns = [
        { key: "rowNumber", name: "Row Number" },
        { key: "make", name: "Make" },
        { key: "model", name: "Model" },
        { key: "plateNumber", name: "Plate Number" },
        { key: "colour", name: "Colour" },
        { key: "manufactureDate", name: "Manufacture Date" },
        { key: "vehicleIdentificationNumber", name: "VIN Number" },
        { key: "engineNumber", name: "Engine Number" },
        { key: "category", name: "Category" },
        { key: "vehicleType", name: "Vehicle Type" },
        { key: "usage", name: "Usage" },
        { key: "propulsion", name: "Propulsion" },
        { key: "seatingCapacity", name: "Seating Capacity" },
        { key: "grossVehicleMass", name: "Gross Vehicle Mass" },
        { key: "countryOfRegistration", name: "Country Of Registration" },
    ];

    rows: VehicleImportError[] = [];

    constructor(private translate: TranslateService,
        private translateParser: TranslateParser) { }

    ngOnInit() {
        this.rows = this.data ? this.data.vehicleImportErrors : [];
        this.rows.sort((a, b) => (a as any).rowNumber - (b as any).rowNumber);
    }

    getDisplayValue(row: ImportVehicle, key: any): string {
        return String(row[key]);
    }

    getErrorMessage(row: VehicleImportError, key: any): string {
        const message = row[key];
        return message ? String(message) : '';
    }

    getClassValue(row: VehicleImportError, key: any): string {
        const errorField = row[key];
        if (errorField) {
            return 'has-error'
        }
        return '';
    }

    hasValidData(): boolean {
        return this.data ? this.data.vehicleImportErrors.length > 0
            && this.data.vehicleImportErrors.at(0) !== null : false;
    }

    get totalVehicles(): number {
        return this.data ? this.data.totalVehicles : 0;
    }

    get vehiclesAdded(): number {
        return this.data ? this.data.totalValidVehicles : 0;
    }
}
