import { Answers } from "./answers.model";

export class ClaimsHistoryAnswers extends Answers{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ClaimsHistoryAnswers";
    }

    public year: string;
    public yesOrNoAnswer: boolean;
    public freeTextAnswer: string;
    public comment: string;
}
