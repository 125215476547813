import { CountryIdentifier } from '../../../location/geographic/identification/models/country-identifier.model';

export class SnapshotVehicle {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.SnapshotVehicle";
    }

    public vehicleRegisterNumber: string
    public vehicleIdentificationNumber: string
    public manufactureDate: Date
    public introductionDate: Date
    public tare: number
    public grossVehicleMass: number
    public seatingCapacity: number
    public standingCapacity: number
    public colour: string
    public make: string
    public model: string
    public usage: string
    public vehicleType: string
    public category: string
    public propulsion: string
    public tpiVehicleIdentifier: string
    public plateNumber: string
    public plateNumberCountryOfIssue: CountryIdentifier
    public engineNumber: string
    public engineCapacity: number
    public netPower: number
    public transmission: string
    public fuelType: string
}
