import { EventEmitter, Input, Output } from '@angular/core';
import { InsuranceTypeCardModel } from './insurance-type-card/insurance-type-card/insurance-type-card.component';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-insurance-type-selector',
    templateUrl: './insurance-type-selector.component.html',
    styleUrls: ['./insurance-type-selector.component.scss']
})
export class InsuranceTypeSelectorComponent implements OnInit {
    @Input() data: InsuranceTypeCardModel[] = [];
    constructor() { }

    ngOnInit() { }

}
