import {Response} from '../../urdad/services/models/response.model';

export class IncidentUpdate extends Response {
    constructor(){
       super();
    }

    public body: string;
    public created_at: Date;
    public display_at: Date;
    public id: string;
    public incident_id: string;
    public status: string;
    public updated_at: Date;

}
