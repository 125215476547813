import { Money } from "../../../financial/models/money.model";
import { Excess } from "./excess.model";
import { UnderwriterIdentifier } from "../identification/models/underwriter-identifier.model";
import { MotorInsuranceLimitDetails } from "../../checkout/models/motor-insurance-limit-details.model";
import { PriceSchedule } from "./price-schedule.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";
import { BrokerIdentifier } from "../identification/models/broker-identifier.model";
import { OrderProduct } from "../../checkout/models/order-product.model";

export class orderExtensionInsuranceProduct extends OrderProduct {
  constructor() {
    super();
    this["@class"] =
      "za.co.magnabc.tpi.retail.insurance.OrderExtensionInsuranceProduct";
  }

  public insuranceVehicleCategoryDescription: string;
  public brokerIdentifier: BrokerIdentifier;
  public conditions: string;
  public alarmImmobiliser: boolean;
  public annualAmount: Money;
  public annualPricePercentage: number;
  public excess: Excess[];
  public coveredCountries: CountryIdentifier[];
  public underwriterIdentifier: UnderwriterIdentifier;
  public price: Money;
  public insuranceVehicleCategorySubDescription: string;
  public insuranceTypeDescription: string
  public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[];
  public priceSchedule: PriceSchedule;
}
