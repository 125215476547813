import {Response} from '../../../../../../../../urdad/services/models/response.model';
import { PolicyWording } from '../../../../../models/policy-wording.model';

export class ProvidePolicyWordingResponse extends Response {
    constructor(){
        super();
    }

    public policyWording: PolicyWording[] = [];

}