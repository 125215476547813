import {Response} from '../../../../urdad/services/models/response.model';
import {ScheduledMaintenance} from '../../../model/scheduled-maintenance.model';

export class ActiveMaintenanceResponse extends Response {
    constructor(){
       super();
    }

    public scheduled_maintenances: ScheduledMaintenance[] = [];
}
