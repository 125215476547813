import { Request } from "../../../../../../../urdad/services/models/request.model";
import {DocumentTypeIdentifier} from '../../../../../identification/models/document-type-identifier.model';

export class CreateScannedDocumentRequest extends Request{
  constructor(){
    super();
  }

  public shownDocuments: DocumentTypeIdentifier[] = [];
  public requiredDocuments: DocumentTypeIdentifier[] = [];
}
