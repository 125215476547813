import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    CloseSessionTrackingRequest,
    CreateSessionTrackingRequest,
    MarshallerService,
    ProvideSessionTrackingRequest,
    UpdateSessionTrackingRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SessionTrackingManagerService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    createSessionTracking(createSessionTrackingRequest: CreateSessionTrackingRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/sessiontracking/sessiontrackingmanager/createsessiontracking';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(createSessionTrackingRequest);
        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    closeSessionTracking(closeSessionTrackingRequest: CloseSessionTrackingRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/sessiontracking/sessiontrackingmanager/closesessiontracking';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(closeSessionTrackingRequest);
        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    provideSessionTracking(provideSessionTrackingRequest: ProvideSessionTrackingRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/sessiontracking/sessiontrackingmanager/providesessiontracking';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSessionTrackingRequest);
        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }

    updateSessionTracking(updateSessionTrackingRequest: UpdateSessionTrackingRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/sessiontracking/sessiontrackingmanager/updatesessiontracking';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(updateSessionTrackingRequest);
        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }
}
