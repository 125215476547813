import { VehicleIdentifier } from "./vehicle-identifier.model";


export class EngineNumber extends VehicleIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.identification.EngineNumber";
    }

    public number:string;

}
