import { Criteria } from "../../criteria/models/criteria.model";
import { NaturalPersonIdentifier } from "../identification/models/natural-person-identifier.model";

export class NaturalPersonIdentifierCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.NaturalPersonIdentifierCriteria";
    }

    public naturalPersonIdentifier: NaturalPersonIdentifier;
}