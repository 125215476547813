import { ProductNumberIdentifier, YCInsuranceProduct, CountryIdentifier } from '@magnabc/tpi';
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Router} from '@angular/router'
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service'
import {
    BrokerInformationProviderService
} from '../../../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {AgentManagerService} from '../../../../../app.services/managers/agent-manager/agent-manager.service'
import {
    UnderwriterManagerService
} from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {
    InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {
    CountryTaxesInformationProviderService
} from '../../../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service'
import {FormatPricePipe} from '../../../../shared/pipes/format-price.pipe'
import {
    ComprehensiveInsuranceProduct,
    InsuranceCategory,
    Product,
    ThirdPartyInsuranceProduct,
    Underwriter,
    UnMarshallerService
} from '@magnabc/tpi'

declare const jQuery: any

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, AfterViewInit {

    @Input() insuranceCategories: InsuranceCategory[] = []
    @Input() underwriterMap: { [id: string]: Underwriter } = {}
    @Input() hideNewButton = false
    @Output() onProductSelect = new EventEmitter<Product>()
    @Output() onNew = new EventEmitter<void>()
    @Output() onSelectInsuranceCategory = new EventEmitter<InsuranceCategory>()

    products: Product[] = []
    filteredProducts: Product[] = []
    selectedProducts: { [id: string]: Product } = {}
    pageSize = 8
    offset = 0
    insuranceCategory = InsuranceCategory
    categoryOptions = []
    price = { from: 0, to: 99999999 }
    percentange = { from: 0, to: 100 }

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent

    constructor(public translate: TranslateService,
                public translateParser: TranslateParser,
                private router: Router,
                private authenticationService: AuthenticationService,
                private brokerInformationProviderService: BrokerInformationProviderService,
                private agentService: AgentManagerService,
                private underwriterManagerService: UnderwriterManagerService,
                public insuranceProductManagerService: InsuranceProductManagerService,
                private unMarshallerService: UnMarshallerService,
                private countryTaxesInformationProviderService: CountryTaxesInformationProviderService) {
    }

    ngOnInit(): void {
        if (this.insuranceCategories.length > 0) {
            this.categoryOptions.push({ value: null, description: "All", id: "filter-product-list-all" })
        }
        if (this.insuranceCategories.indexOf(InsuranceCategory.THIRDPARTY) > -1) {
            this.categoryOptions.push({ value: InsuranceCategory.THIRDPARTY, description: "Third Party", id: "filter-product-list-thirdparty" })
        }
        if (this.insuranceCategories.indexOf(InsuranceCategory.COMPREHENSIVE) > -1) {
            this.categoryOptions.push({ value: InsuranceCategory.COMPREHENSIVE, description: "Comprehensive", id: "filter-product-list-comprehensive" })
        }
        if (this.insuranceCategories.indexOf(InsuranceCategory.YELLOWCARD) > -1) {
            this.categoryOptions.push({ value: InsuranceCategory.YELLOWCARD, description: "Yellow Card", id: "filter-product-list-yellow-card" })
        }
    }

    ngAfterViewInit(): void {
        jQuery('#input-search').val("")
        this.onToggleSearch()
    }

    setProducts(products: Product[]) {
        this.products = products
        this.filteredProducts = this.products
        this.pageSize = 8;
        this.offset = 0;
    }

    setSelectedProducts(products: { [id: string]: Product }) {
        this.selectedProducts = products
    }

    updateFilter(event) {
        if (event) {
            const val = event.target.value.toLowerCase()
            this.filteredProducts = this.products.filter(function (d) {
                return d.name.toLowerCase().indexOf(val) !== -1 || !val
            })
        } else {
            this.filteredProducts = this.products
        }
    }

    onFooterPage(event): void {
        this.pageSize = event.pageSize
        this.offset = event.offset
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search')
        input.toggle()
        input.val('')
        input.focus()
        this.updateFilter(null)
    }

    onPercentage(percentage): void {
        this.percentange = percentage
        this.filteredProducts = this.filterPrice(this.products)
    }

    onPrice(price): void {
        this.price = price
        this.filteredProducts = this.filterPrice(this.products)
    }

    filterPrice(temp: Product[]): any {
        const products = []
        if (this.insuranceProductManagerService.insuranceCategory === InsuranceCategory.THIRDPARTY) {
            for (const item of temp) {
                if (item instanceof ThirdPartyInsuranceProduct) {
                    const total = FormatPricePipe.provideCountryTaxesInformation(this.countryTaxesInformationProviderService, item.annualAmount.amount, item.coveredCountries)
                    if (total >= (this.price.from * 100) && total <= (this.price.to * 100)) {
                        products.push(item)
                    }
                }
            }
        } else {
            for (const item of temp) {
                if (item instanceof ComprehensiveInsuranceProduct) {
                    if (item.annualPercentage >= this.percentange.from && item.annualPercentage <= this.percentange.to) {
                        products.push(item)
                    }
                }
            }
        }

        return products
    }

    getYCProductCoveredCountries(product: Product): CountryIdentifier[] {
        let countryIdentifiers: CountryIdentifier[] = [];
        if (product instanceof YCInsuranceProduct) {
            const productIdentifiers = product.productIdentifiers.map((item: ProductNumberIdentifier) => item.number);
            const products = this.products.filter((product) => productIdentifiers.includes(product.number));
            countryIdentifiers = products.flatMap((product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct) => product.coveredCountries);
        }
        return countryIdentifiers;
    }
}
