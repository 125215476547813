import {PreconditionViolation} from "../../../../../../urdad/services/models/precondition-violation.model";

export class UpdateVehicleMakeAlreadyExistsException extends PreconditionViolation {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.makemodeltrim.UpdateVehicleMakeAlreadyExistsException";
    }

    public duplicate: string;
}
