import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {FormComponent} from "../../shared/form/form.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast} from "ngx-toastr"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {ToastComponent} from "../../shared/toast/toast.component"
import {environment} from "../../../../environments/environment"
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
    selector: 'app-opt-verify',
    templateUrl: './opt-verify.component.html',
    styleUrls: ['./opt-verify.component.scss']
})
export class OTPVerifyComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() contactNumber: string;
    @Output() onValid = new EventEmitter<string>();
    @Output() resendOTP = new EventEmitter<any>();

    errorMessage = null;

    formGroup: UntypedFormGroup;
    otp: UntypedFormControl;
    activeToast: ActiveToast<any>;
    resendDisabled = false;

    @ViewChild('form', { static: true }) form: FormGroupDirective;
    invalidOTP = false;
    countDown = 60;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private toastr: ErrorToastService) {
        super(translate, translateParser);

    }

    ngOnInit() {

        this.resendDisabled = false;
        this.otp = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(4)
        ]);

        this.formGroup = new UntypedFormGroup({
            otp: this.otp,
        });

        this.onCountDown();

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    onCountDown(): void {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown--;
                this.onCountDown();
            }, 1000);
        }
    }

    onResend(event): void {
        this.resendOTP.emit();
        this.otpResent();
    }

    otpResent() {
        this.invalidOTP = false;
        if(!this.activeToast){
            this.toast();
            this.countDown = 60;
            this.onCountDown();
            this.stopLoad();
        }else{
            this.activeToast = null;
        }
    }

    onCheckValidation(event): void {

        this.errorMessage = null;
        this.invalidOTP = false;

        if (!this.form.invalid) {
            this.onValid.emit(this.otp.value);
        }
    }

    otpInvalid() {
        this.invalidOTP = true;
        this.stopLoad();
    }

    submit() {
        document.getElementById('form-submit-otp').click();
    }

    setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    disable() {
        this.countDown = 0;
        this.resendDisabled = true;
    }

    private toast() {

        this.translate.get('TOAST.OTP_SENT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.contactNumber}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            // this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.activeToast.portal.destroy();
            });
        });

    }

}
