import { Component, OnInit } from '@angular/core';
import { PopularProductsLegalEntityCriteria, ProvidePopularProductsRequest, ProvidePopularProductsResponse, TimeFrame, UnMarshallerService, TpiIdentifier, PopularProducts } from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { DashboardProviderService } from '../../../../../http.services/ledger/reporting/dashboard-provider/dashboard-provider.service';
import { AuthenticationService } from '../../../../../app.services/managers/authentication/authentication.service';
import { FormComponent } from "../../../../shared/form/form.component";
import { Utils } from "../../../../shared/Utils/Utils";
import { Chart } from '../../../../../../../node_modules/chart.js';
import { ErrorToastService } from '../../../../../app.services/common/error-toast/error-toast.service';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
  styleUrls: ['./popular-products.component.scss']
})
export class PopularProductsComponent extends FormComponent implements OnInit {

  tpiIdentifier : TpiIdentifier;
  popularProducts : PopularProducts [] = [];
  barChart;

  constructor(  private translate: TranslateService,
                private translateParser: TranslateParser,
                private dashboardProviderService: DashboardProviderService,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private errorToastService: ErrorToastService) {
    super(translate, translateParser);
  }

  ngOnInit() {}

  getDropDownValue(timeFrame: string): void {
    this.getPopularProducts(timeFrame);
  }

  getPopularProducts(timeframe: string): void {
    this.popularProducts = [];
    const popularProductsLegalEntityCriteria = new PopularProductsLegalEntityCriteria();
    popularProductsLegalEntityCriteria.dashboardPeriod = this.getTimeFramePeriod(timeframe);
    popularProductsLegalEntityCriteria.legalEntityIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());;

    const providePopularProductsRequest = new ProvidePopularProductsRequest();
    providePopularProductsRequest.criteria = popularProductsLegalEntityCriteria;
    this.startLoad();
    this.dashboardProviderService.providePopularProducts(providePopularProductsRequest).then((httpResponse) => {
      this.stopLoad();
      if (httpResponse && httpResponse.body) {
          const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvidePopularProductsResponse) as ProvidePopularProductsResponse);
          this.popularProducts = response.popularProducts;
          this.polulateBarCart();

       }
    }).catch((error) => {
        this.errorToastService.errorToast(error);
        this.stopLoad();
    });
  }

  polulateBarCart(){
    var ctx = document.getElementById('barChart');
    const colors = [
      "#09BF9C",
      "#499BD5",
      "#4DBFED",
      "#EBAD44",
      "#EF3232"
    ]
    var barChartData ;

    if(this.popularProducts.length > 0){
      barChartData = {
        labels: [''],
        datasets: []
      };
      for (var i = 0; i < this.popularProducts.length; i++) {
        barChartData.datasets.push(
          {
            label: this.popularProducts[i].productName ,
            backgroundColor: colors[i % colors.length],
            data: [this.popularProducts[i].netQuantity]
          }
        )
      }
    }

  if (this.barChart) {
    this.barChart.destroy();
  }

  this.barChart = new Chart(ctx, {
    type: 'bar',
    data: barChartData,
    options: {
        scales: {
          xAxes: [{
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            }
          }],
          yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
            }
          }]
        },
        legend: {
          display: true,
          position: 'right',
          align: 'start',
          labels: {
              fontColor: '#555555',
              boxWidth: 10
          },

        }
      },
      plugins: [{
        afterDraw: function(barChart) {
          if (barChart.data.datasets.length === 0) {
            // No data is present
            var ctx = barChart.chart.ctx;
            var width = barChart.chart.width;
            var height = barChart.chart.height
            barChart.clear();

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "12px";
            ctx.fillText('No available data to display', width / 2, height / 2);
            ctx.restore();
          }
        }
      }]
    });

  }

  getTimeFramePeriod(preferredPeriod: string): TimeFrame{
    if(preferredPeriod === "Today"){
        return TimeFrame.TODAY;

    }
    else if(preferredPeriod === "Yesterday"){
        return TimeFrame.YESTERDAY;

    }
    else if(preferredPeriod === "Last 7 Days"){
      return TimeFrame.LAST7DAYS;

    }
    else if(preferredPeriod === "Current Month"){
      return TimeFrame.CURRENTMONTH;

    }
    else{
        return TimeFrame.LAST30DAYS;
    }
  }

  ngOnDestroy() {
    Chart.pluginService.unregister(this.barChart);
  }

}
