export class CardPaymentOption {

    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.dpo.CardPaymentOption";
    }

    public cardExpiryDate: Date;
    public cardLastFourDigits: string;
    public cardType: string;
}
