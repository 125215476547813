import {OneTimePinIdentifier} from './one-time-pin-identifier.model';

export class OneTimePinStringIdentifier extends OneTimePinIdentifier {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.onetimepin.OneTimePinStringIdentifier";
    }

    identifier: string;
}
