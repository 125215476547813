import {Request} from '../../../../../../../../urdad/services/models/request.model';
import {RetailProfile} from "../../../../../../../profile/models/retail-profile.model";
import {PaymentReference} from "../../../../../../../../financial/models/payment-reference.model";
import {Money} from "../../../../../../../../financial/models/money.model";

export class InitialiseProcessCardPaymentRequest extends Request {
    constructor (){
        super();
    }

    paymentReference: PaymentReference;
    retailProfile: RetailProfile;
    amount: Money;
    redirectUrl: string;
    backUrl: string;
    declinedUrl: string;

}
