export enum RenderedDocumentType {
    INSURANCE_CERTIFICATE_AUDIT = "INSURANCE_CERTIFICATE_AUDIT",
    INSURANCE_CERTIFICATE_DUPLICATE_PRINT = "INSURANCE_CERTIFICATE_DUPLICATE_PRINT",
    INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW = "INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW",
    INSURANCE_CERTIFICATE_PREVIEW = "INSURANCE_CERTIFICATE_PREVIEW",
    INSURANCE_CERTIFICATE_PRINT = "INSURANCE_CERTIFICATE_PRINT",
    YELLOW_CARD_INSURANCE_CERTIFICATE_PRINT = "YELLOW_CARD_INSURANCE_CERTIFICATE_PRINT",
    YELLOW_CARD_INSURANCE_CERTIFICATE_PREVIEW = "YELLOW_CARD_INSURANCE_CERTIFICATE_PREVIEW",
    YELLOW_CARD_INSURANCE_CERTIFICATE_AUDIT = "YELLOW_CARD_INSURANCE_CERTIFICATE_AUDIT",
    YELLOW_CARD_INSURANCE_CERTIFICATE_DUPLICATE_PRINT = "YELLOW_CARD_INSURANCE_CERTIFICATE_DUPLICATE_PRINT",
    YELLOW_CARD_INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW = "YELLOW_CARD_INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW",
    ORDER = "ORDER",
    ACCOUNT_STATEMENT_REPORT = "ACCOUNT_STATEMENT_REPORT",
    INVOICE = "INVOICE",
    SUPPLIER_INVOICE = "SUPPLIER_INVOICE",
    PROPOSAL = "PROPOSAL",
    PROPOSAL_CONFIRMATION = "PROPOSAL_CONFIRMATION",
    CUSTOMER_INVOICE = "CUSTOMER_INVOICE",
    POLICYSCHEDULE = "POLICY_SCHEDULE"
  }
