import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../form/form.component";
import {CountryContactNumberComponent} from "../country-code-number/country_contact_number.component";
import {ContactNumber, CountryDialingIdentifier} from '@magnabc/tpi';

declare const jQuery: any;
@Component({
    selector: 'app-contact-number',
    templateUrl: './contact_number.component.html'
})
export class ContactNumberComponent extends FormComponent implements OnInit {

    @Input() contactNumber: ContactNumber;
    @Input() title = "";
    @Input() disabled = false;
    @Input() obfuscated = false;
    @Output() onContactNumber = new EventEmitter<ContactNumber>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onCountry = new EventEmitter<CountryDialingIdentifier>();

    @ViewChild('countyContactNumber', { static: true }) countyContactNumber: CountryContactNumberComponent;

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {
        super(translate, translateParser);

    }

    ngOnInit(): void {
    }

    submit(): void {
        this.countyContactNumber.submit();
    }

    onCountryContactNumber(event): void {
        this.onContactNumber.emit((event as ContactNumber));
    }

    onCountryChanged(event): void {
        this.onCountry.emit(event);
    }

    onCountryNumberValid(event): void {
        this.onValid.emit();
    }

    isDirty() {
        return this.countyContactNumber.isDirty();
    }
}
