import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BrowserModule} from '@angular/platform-browser'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatInputModule} from '@angular/material/input'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatMomentDateModule} from '@angular/material-moment-adapter'
import {LoadingModule} from 'ngx-loading'
import {MatDialogModule} from '@angular/material/dialog'
import {RouterModule} from '@angular/router'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {PayoutDialogComponent} from './accounting-transaction/dialogs/payout-dialog.component'
import {DepositDialogComponent} from './accounting-transaction/dialogs/deposit-dialog.component'
import {SalesSummaryComponent} from './reporting/dashboard/sales-summary/sales-summary.component'
import {PopularProductsComponent} from './reporting/dashboard/popular-products/popular-products.component'
import {RoleRevenueComponent} from './reporting/dashboard/role-revenue/role-revenue.component'
import {DropdownComponent} from './reporting/dashboard/dropdown/dropdown.component'
import {TranslateModule} from '@ngx-translate/core'
import {NgSelectModule} from '@ng-select/ng-select'
import {MatCardModule} from '@angular/material/card'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatIconModule} from '@angular/material/icon'
import {SalesReportComponent} from './reporting/sales-report/sales-report.component'
import {SharedModule} from '../shared/shared.module'
import {MatSelectModule} from '@angular/material/select'
import {MatTabsModule} from '@angular/material/tabs'
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatTableModule} from '@angular/material/table';
import { AccountReportsComponent } from './account-transaction/account-reports/account-reports.component';
import { AccountStatementsComponent } from './account-transaction/account-statements/account-statements.component';
import { AccountingTransationsComponent } from './account-transaction/accounting-transations/accounting-transations.component';
import { RevenueReportComponent } from './reporting/revenue-report/revenue-report.component';
import { TransactionReportComponent } from './reporting/transaction-report/transaction-report.component'

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        LoadingModule,
        MatTabsModule,
        MatDialogModule,
        RouterModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        TranslateModule,
        NgSelectModule,
        MatCardModule,
        MatTooltipModule,
        MatIconModule,
        SharedModule,
        MatPaginatorModule,
        MatTableModule
    ],
    declarations: [
        PayoutDialogComponent,
        DepositDialogComponent,
        SalesSummaryComponent,
        PopularProductsComponent,
        RoleRevenueComponent,
        DropdownComponent,
        SalesReportComponent,
        AccountReportsComponent,
        AccountStatementsComponent,
        AccountingTransationsComponent,
        RevenueReportComponent,
        TransactionReportComponent,
    ],
    exports: [
        SalesReportComponent,
        SalesSummaryComponent,
        PopularProductsComponent,
        RoleRevenueComponent,
        AccountReportsComponent,
        AccountStatementsComponent,
        AccountingTransationsComponent,
        RevenueReportComponent,
        TransactionReportComponent
    ]
})
export class LedgerModule { }
