import {Component, OnInit} from '@angular/core'
import {
    ClaimSpecificExcess,
    ComprehensiveInsurancePolicyCartItem,
    Excess,
    ThirdPartyInsurancePolicyCartItem,
    UniversalExcess
} from '@magnabc/tpi'
import {ProductExcessTypePipe} from '../../../../shared/pipes/product-excess-type.pipe'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'

@Component({
    selector: 'app-product-information-excess',
    templateUrl: './product-information-excess.component.html',
    styleUrls: ['./product-information-excess.component.scss']
})
export class ProductInformationExcessComponent implements OnInit {

    productExcessTypePipe = ProductExcessTypePipe

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {

        this.insuranceProductService.excesses = JSON.parse(JSON.stringify(this.insuranceProductService.excesses))

        if (this.insuranceProductService.item) {
            let excesses: Excess[] = []

            if ((this.insuranceProductService.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem) {
                excesses = (this.insuranceProductService.item as ThirdPartyInsurancePolicyCartItem).thirdPartyInsuranceProductCartItem.excesses
            } else if ((this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem) {
                excesses = (this.insuranceProductService.item as ComprehensiveInsurancePolicyCartItem).comprehensiveInsuranceProductCartItem.excesses
            }

            for (const itemExcess of excesses) {
                for (const productExcess of this.insuranceProductService.excesses) {
                    if ((itemExcess as any).description === (productExcess as any).description) {
                        (productExcess as any).value.amount = (itemExcess as any).value.amount;
                        (productExcess as any).percentage = (itemExcess as any).percentage;
                    }
                }
            }
        }

        // Remove cents
        for (const excess of this.insuranceProductService.excesses) {
            (excess as UniversalExcess | ClaimSpecificExcess).value.amount = (excess as UniversalExcess | ClaimSpecificExcess).value.amount / 100
        }

    }

    onExcessChange(event, excess) {
        excess.percentage = event
    }

    onExcessValidate(event, excess) {
        if (!event.value) {
            excess.value.amount = 0
        }
    }

}
