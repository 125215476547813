import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import {
    ContactNumber,
    JudicialPerson,
    NaturalPerson,
    Order,
    OrderNumberCriteria,
    OrderNumberIdentifier,
    Payment,
    ProvideOrderLegalEntityRequest,
    ProvideOrderLegalEntityResponse,
    RegistrationNumber,
    TaxNumber,
    TrafficRegisterNumber,
    UnMarshallerService
} from "@magnabc/tpi";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { RetailProfileFinalisationManagerService } from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OrderQueryComponent } from "../../../../../view.components/retail/insurance/third-party-insurance/order-query/order-query.component";
import { FormComponent } from "../../../../../view.components/shared/form/form.component";
import { IStepComponent } from "../../../../../view.components/shared/guards/step-guard";
import { Location, LocationStrategy } from "@angular/common";
import { Utils } from "../../../../../view.components/shared/Utils/Utils";
import { OrderLegalEntityProviderService } from '../../../../../http.services/retail/insurance/order-legal-entity-provider/order-legal-entity-provider.service';
import { environment } from '../../../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
    selector: 'app-insurance-certificate-issuance-administrator',
    templateUrl: './insurance-certificate-issuance-administrator-page.component.html',
    styleUrls: ['./insurance-certificate-issuance-administrator-page.component.scss']
})
export class InsuranceCertificateIssuanceAdministratorPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;

    orderStatus = "";
    order: Order;
    contactNumber: ContactNumber;

    @ViewChild(OrderQueryComponent) orderQueryComponent: OrderQueryComponent;

    naturalPerson: NaturalPerson = null;
    judicialPerson: JudicialPerson = null;
    payment: Payment;

    formGroup: UntypedFormGroup;
    amount: UntypedFormControl;
    orderNumber: string;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                private router: Router,
                private toastr: ToastrService,
                private orderLegalEntityProviderService: OrderLegalEntityProviderService,
                private unMarshallerService: UnMarshallerService,
                private route: ActivatedRoute,
                private locationStrategy: LocationStrategy,
                private location: Location,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Issue Certificate - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.route.data.subscribe((data) => {
            if (data.order) {
                this.onOrder(data.order);
            }
        });

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ORDER.TITLE_ORDER_QUERY").subscribe((response) => {
                this.steps.push({ id: 0, name: response, icon: "fa-search", borderColor: "#1592E6" });
            });
            this.translate.get("ORDER.TITLE_CERTIFICATE").subscribe((response) => {
                this.steps.push({ id: 1, name: response, icon: "fa-certificate", borderColor: "#3f5baa" });
            });

        });

        this.amount = new UntypedFormControl('', [
            Validators.required,
            Validators.min(1),
            Validators.max(9999999)
        ]);

        this.formGroup = new UntypedFormGroup({
            'amount': this.amount
        });

        this.route.params.forEach((param: Params) => {

            if (param["number"]) {
                setTimeout(() => {
                    this.orderQueryComponent.query(param["number"]);
                }, 200);
            }
        });

    }

    isLocal(): boolean {
        for (const identifier of this.naturalPerson.legalEntityIdentifiers) {
            if (identifier instanceof TrafficRegisterNumber) {
                return identifier.countryOfIssue === 'MW';
            }
        }
    }

    getNaturalPersonIDNumber(): string {
        return Utils.getNationalIdentityNumber(this.naturalPerson.legalEntityIdentifiers).number;
    }

    getNaturalPersonPassport(): string {
        return Utils.getPassportNumberString(this.naturalPerson.legalEntityIdentifiers);
    }

    getNaturalPersonTRN(): string {
        return Utils.getTrafficRegisterNumberString(this.naturalPerson.legalEntityIdentifiers);
    }

    getJudicialPersonBusinessRegistrationNumber(): string {
        for (const identifier of this.judicialPerson.legalEntityIdentifiers) {
            if (identifier instanceof RegistrationNumber) {
                return identifier.number;
            }
        }
        return null;
    }

    getJudicialPersonTaxNumber(): string {
        for (const identifier of this.judicialPerson.legalEntityIdentifiers) {
            if (identifier instanceof TaxNumber) {
                return identifier.taxNumber;
            }
        }
        return null;
    }

    onOrder(order): void {
        this.order = order;
        if (order.status) {
            this.orderStatus = order.status;
        }
        if (this.order && (this.order.status === "PAID" || this.order.status === "FULFILLED")) {
            this.contactNumber = this.legalEntityFinalisationManagerService.legalEntity.contactNumbers[0];
            this.naturalPerson = null;
            this.judicialPerson = null;
            this.getLegalEntity();
            this.currentStep = 1;
        }
        this.orderNumber = order.number;
        this.router.navigate([`agent/order/${order.number}`]);
    }

    getLegalEntity(): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(this.order.number));

        const provideOrderLegalEntityRequest = new ProvideOrderLegalEntityRequest();
        provideOrderLegalEntityRequest.criteria = orderReferenceNumberCriteria;

        this.orderLegalEntityProviderService.provideOrderLegalEntity(provideOrderLegalEntityRequest).subscribe((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideOrderLegalEntityResponse) as ProvideOrderLegalEntityResponse);
                if (!response.legalEntity) {
                    alert('Unable to load order legal entity.');
                    return;
                }
                if (response.legalEntity instanceof NaturalPerson) {
                    this.naturalPerson = response.legalEntity;
                } else if (response.legalEntity instanceof JudicialPerson) {
                    this.judicialPerson = response.legalEntity;
                }
            }
        });

    }

    onOrderExists(exists): void {
        if (exists) {
            // this.getPayment();
        }
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    onNext($event): void {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    onBack(event): void {
        if (this.currentStep === 1 && this.orderNumber) {
            this.currentStep = 0;
            setTimeout(() => {
                window.history.back();
            }, 100)
        } else if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        }
    }

    onSubmit(event): void {
        const step = this.currentStep;
        switch (step) {
            case 0:
                this.orderQueryComponent.submit();
                break;
            case 1:
                this.currentStep = 0;
                this.router.navigate(['/agent/order']);
                break;
        }
    }

}
