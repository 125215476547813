import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from '@magnabc/tpi';

@Component({
    selector: 'app-yellow-card-covered-countries',
    templateUrl: './yellow-card-covered-countries.component.html',
    styleUrls: ['./yellow-card-covered-countries.component.scss']
})
export class YellowCardCoveredCountriesComponent {
    @Input() selectedCountries: Country[];
    @Input() errors: Error[];
    @Input() supportedCountries: Country[];
    @Output() onSelect = new EventEmitter<Country[]>();
    @Output() countryOptions = new EventEmitter<Country[]>();

    onCountrySelect(countries: Country[]): void {
        this.onSelect.emit(countries);
    }

    onCountryOptions(data: Country[]): void {
        this.countryOptions.emit(data);
    }
}
