import { VehicleIdentifier } from "../../../../vehicle/vehicle_api";
import { ProductIdentifier } from "../../../retail_api";
import { Excess, YCPriceInterval, YCPriceSchedule } from "../../insurance_api";
import { YCLimitDetails } from "./yc-limit-details.model";
import { YCMotorVehicleInsuranceDetails } from "./yc-motor-vehicle-insurance-details.model";

export class YCMotorInsuranceDetails {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCMotorInsuranceDetails';
    }

    startDate: Date;
    expiryDate: Date;
    ycLimitDetails: YCLimitDetails[];
    vehicleIdentifiers: VehicleIdentifier[];
    excesses: Excess[] = [];
    productIdentifier: ProductIdentifier;
    ycPriceSchedules: YCPriceSchedule[];
    ycPriceInterval: YCPriceInterval;
    ycMotorVehicleInsuranceDetails: YCMotorVehicleInsuranceDetails[];
}