import {CanDeactivate} from "@angular/router";
import {Injectable} from "@angular/core";

export interface IStepComponent {
    backPressed: boolean;
    onDeactivate(): boolean;
}

@Injectable()
export class StepGuard implements CanDeactivate<IStepComponent> {

    canDeactivate(component: IStepComponent) {
        return component.onDeactivate();
    }

}
