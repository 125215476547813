import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
  BackOfficeOTPAuthenticateCredentialsRequest,
  BackOfficeOTPInitialiseAuthenticationRequest,
  BackOfficeOTPValidateObscuredContactNumberRequest,
  MarshallerService
} from '@magnabc/tpi'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class BackOfficeOtpAuthenticatorProviderService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseAuthentication(initialiseAuthenticationRequest: BackOfficeOTPInitialiseAuthenticationRequest) {
        const initialiseAuthenticationURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/backofficeauthenticator/initialiseauthentication';

        const jsonInitialiseAuthenticationRequest = this.marshallerService.marshallObjectToJSON(initialiseAuthenticationRequest);

        return this.httpClient.post<any>(initialiseAuthenticationURI, jsonInitialiseAuthenticationRequest, this.options).toPromise();
    }

    validateObscuredContactNumber(validateObscuredContactNumberRequest: BackOfficeOTPValidateObscuredContactNumberRequest) {
        const validateObscuredContactNumberURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/backofficeauthenticator/validateobscuredcontactnumber';

        const jsonValidateObscuredContactNumberRequest = this.marshallerService.marshallObjectToJSON(validateObscuredContactNumberRequest);

        return this.httpClient.post<any>(validateObscuredContactNumberURI, jsonValidateObscuredContactNumberRequest, this.options).toPromise();
    }

    authenticateCredentials(authenticateCredentialsRequest: BackOfficeOTPAuthenticateCredentialsRequest) {
        const authenticateCredentialsURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/backofficeauthenticator/authenticatecredentials';

        const jsonAuthenticateCredentialsRequest = this.marshallerService.marshallObjectToJSON(authenticateCredentialsRequest);

        return this.httpClient.post<any>(authenticateCredentialsURI, jsonAuthenticateCredentialsRequest, this.options).toPromise();
    }
}
