import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {MotorInsuranceCertificate} from '../../../../../models/motor-insurance-certificate.model';

export class FinaliseIssueInsuranceCertificateResponse extends Response{
    constructor(){
        super();
    }

    public motorInsuranceCertificate: MotorInsuranceCertificate;
}
