import { MatSelectChange } from '@angular/material/select';
import { OnInit } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { FormComponent } from './../../../../shared/form/form.component';
import { InsuranceProductManagerService } from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';
import { DurationType } from '@magnabc/tpi';
import { ProductTypePipe } from './../../../../shared/pipes/product-type.pipe';
import { Dropdown } from './../../../../../common/model/dropdown.model';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-product-price-schedule',
    templateUrl: './product-price-schedule.component.html',
    styleUrls: ['./product-price-schedule.component.scss']
})
export class ProductPriceScheduleComponent extends FormComponent implements OnInit {

    DAYS_IN_A_YEAR: number = 365;

    @Input() formGroup: UntypedFormGroup;
    @Input() durationTypes: Dropdown[];
    @Input() invalidMinimumValueAnnual = false
    @Input() invalidMinimumValueNewPolicy = false
    @Input() invalidMinimumValueAdditional = false
    @Input() invalidNewPolicyValueAnnual = false
    @Input() invalidNewPolicyValueAdditional = false
    @Input() invalidAdditionalVehicleToPolicyValueAnnual = false
    @Input() invalidPolicyDuration = false
    @Input() invalidInterval = false
    @Input() invalidExact = false
    @Input() invalidDuplicateDuration = false
    @Input() showPercentage = false;
    @Input() months = []
    @Input() days = []
    @Input() formType: ProductPriceScheduleType = ProductPriceScheduleType.STANDARD;
    @Input() intervalIDs = [''];
    @Input() errorTrack: { id: string, hasErrors: boolean }[] = [];
    @Input() disableIntervalFromSelect = false
    @Input() disableIntervalToSelect = false
    @Input() disableExactSelect = false

    @Output() onChangeDurationType = new EventEmitter<any>();
    @Output() onExactDurationMonths = new EventEmitter<any>();
    @Output() onExactDurationDays = new EventEmitter<any>();
    @Output() onIntervalDurationFromMonths = new EventEmitter<any>();
    @Output() onIntervalDurationFromDays = new EventEmitter<any>();
    @Output() onIntervalDurationToMonths = new EventEmitter<any>();
    @Output() onIntervalDurationToDays = new EventEmitter<any>();
    @Output() onProRateToggle = new EventEmitter<any>();
    @Output() onPercentageOfAnnualAmountToggle = new EventEmitter<any>();
    @Output() onRemoveUserIncrementError = new EventEmitter<any>();
    @Output() onSubmitPricing = new EventEmitter<any>();
    @Output() onAddInterval = new EventEmitter<any>();
    @Output() onRemoveInterval = new EventEmitter<any>();

    productTypePipe = ProductTypePipe;
    durationTypeList = DurationType;
    errorYellowCard: string = null;
    yellowCardIntervalOptions = [
        { value: 7, text: '7 Days' },
        { value: 14, text: '14 Days' },
        { value: 31, text: '1 Month' },
        { value: 92, text: '3 Months' },
        { value: 184, text: '6 Months' },
        { value: this.DAYS_IN_A_YEAR, text: '1 Year' }
    ];
    totalDuration = 0;

    constructor(private translate: TranslateService,
        private translateParser: TranslateParser,
        public insuranceProductManagerService: InsuranceProductManagerService) {
        super(translate, translateParser)
    }

    ngOnInit(): void {
        this.validateYellowCardDuration();
    }

    get isYellowCard(): boolean {
        return this.formType === ProductPriceScheduleType.YELLOW_CARD;
    }

    get currency(): string {
        if (this.isYellowCard) {
            return this.insuranceProductManagerService.currency;
        } else if (this.insuranceProductManagerService.priceSchedule) {
            return this.insuranceProductManagerService.priceSchedule.currency
        } else {
            null
        }
    }

    showDeleteButton(intervalID: string): boolean {
        const lastIntervalID = this.intervalIDs[this.intervalIDs.length - 1];
        return this.intervalIDs.length > 1 && intervalID === lastIntervalID;
    }

    changeDurationType(event: MatSelectChange): void {
        this.onChangeDurationType.emit(event);
        if (this.isYellowCard) {
            this.errorYellowCard = null;
            this.formGroup.setErrors(null);
            this.formGroup.reset();
            this.formGroup.get(event.source.id).setValue(event.value);
        }
    }

    changeExactDurationMonths(event): void {
        this.onExactDurationMonths.emit(event);
        this.onExactDurationDays.emit(0);
        this.formGroup.get('exactDurationDays').setValue(0);
    }

    changeExactDurationDays(event): void {
        this.onExactDurationDays.emit(event);
        this.onExactDurationMonths.emit(0);
        this.formGroup.get('exactDurationMonths').setValue(0);
    }

    changeIntervalDurationFromMonths(event): void {
        this.onIntervalDurationFromMonths.emit(event);
        this.onIntervalDurationFromDays.emit(0);
        this.onIntervalDurationToDays.emit(0);
        this.formGroup.get('intervalDurationFromDays').setValue(0);
        this.formGroup.get('intervalDurationToDays').setValue(0);
    }

    changeIntervalDurationFromDays(event): void {
        this.onIntervalDurationFromDays.emit(event);
        this.onIntervalDurationFromMonths.emit(0);
        this.onIntervalDurationToMonths.emit(0);
        this.formGroup.get('intervalDurationFromMonths').setValue(0);
        this.formGroup.get('intervalDurationToMonths').setValue(0);
    }

    changeIntervalDurationToMonths(event): void {
        this.onIntervalDurationToMonths.emit(event);
        this.onIntervalDurationFromDays.emit(0);
        this.onIntervalDurationToDays.emit(0);;
        this.formGroup.get('intervalDurationFromDays').setValue(0);
        this.formGroup.get('intervalDurationToDays').setValue(0);
    }

    changeIntervalDurationToDays(event): void {
        this.onIntervalDurationToDays.emit(event);
        this.onIntervalDurationFromMonths.emit(0);
        this.onIntervalDurationToMonths.emit(0);
        this.validateYellowCardDuration();
    }

    toggleProRate(event): void {
        this.onProRateToggle.emit(event);
    }

    togglePercentageOfAnnualAmount(event): void {
        this.onPercentageOfAnnualAmountToggle.emit(event);
    }

    removeUserIncrementError(event): void {
        this.onRemoveUserIncrementError.emit(event);
    }

    submitPricing(): void {
        this.validateYellowCardDuration();
        this.onSubmitPricing.emit();
    }

    addInterval(event: Event): void {
        if (this.totalDuration > this.DAYS_IN_A_YEAR) return;

        event.preventDefault();
        event.stopPropagation();
        if (this.validFormFields()) {
            this.onAddInterval.emit();
        }
    }

    removeInterval(event: Event, suffix: string): void {
        this.onRemoveInterval.emit({ event, suffix });
        this.validateYellowCardDuration();
    }


    validFormFields(): boolean {
        let result = [];
        this.formGroup.markAllAsTouched();
        for (const field in this.formGroup.controls) {
            result.push(!this.formGroup.get(field).invalid);
        }
        return !result.includes(false);
    }

    validateYellowCardDuration(): void {
        if (!this.isYellowCard) return;

        this.errorYellowCard = null;
        this.formGroup.setErrors({ invalidIntervals: false });
        this.formGroup.setErrors({ exceedsmaxinterval: false });

        let currentInterval = this.intervalIDs[this.intervalIDs.length - 1];
        let durations = [];
        this.intervalIDs.forEach((suffix) => {
            const controlFrom = this.formGroup.get('intervalDurationFromDays' + suffix);
            const controlTo = this.formGroup.get('intervalDurationToDays' + suffix);
            let fromValue = Number(controlFrom.value);
            let duration = Number(controlTo.value) - fromValue;
            duration = fromValue > 0 ? duration + 1 : duration;
            if (duration >= 1) {
                durations.push(duration);
            }
        });

        const intervalDuration = durations.reduce((prevVal, currentVal) => prevVal + currentVal, 0);
        this.totalDuration = intervalDuration;
        const durationType = this.formGroup.get('durationType');
        if (durationType.value === DurationType.INTERVAL) {
            if (intervalDuration === this.DAYS_IN_A_YEAR) {
                this.errorYellowCard = null;
                this.formGroup.setErrors(null);
            } else if (intervalDuration > this.DAYS_IN_A_YEAR) {
                this.formGroup.get('intervalDurationToDays' + currentInterval).setErrors({ exceedsmaxinterval: true });
                this.errorYellowCard = `The intervals must add up to a full year. Current intervals add up to ${intervalDuration} days.`;
                this.formGroup.setErrors({ invalidIntervals: true });
            } else {
                this.errorYellowCard = `The intervals must add up to a full year. Current intervals add up to ${intervalDuration} days.`;
                this.formGroup.setErrors({ invalidIntervals: true });
            }
        }
    }

    get ycIntervalOptions(): IntervalOption[] {
        return this.yellowCardIntervalOptions.filter(item => item.value > this.totalDuration);
    }

    get newPolicyValuePlaceholder(): string {
        return this.isYellowCard ? 'YELLOW_CARD.INTERVALS.ANNUAL_VALUE' : 'ADMIN.PRODUCTS.LABEL_NEW_POLICY_VALUE';
    }

    get minimumPolicyValuePlaceholder(): string {
        return this.isYellowCard ? 'YELLOW_CARD.INTERVALS.MINIMUM_VALUE' : 'ADMIN.PRODUCTS.LABEL_MINIMUM_POLICY_VALUE';
    }

    /**
     * Allow only numeric values.
     * @param event The event to check.
     * @returns true if the key pressed is a number, false otherwise.
     */
    numericOnly(event: Event): boolean {
        const charCode = (event as KeyboardEvent).charCode;
        return charCode >= 48 && charCode <= 57;
    }
}

type IntervalOption = {
    value: number,
    text: string
}

export enum ProductPriceScheduleType {
    STANDARD,
    YELLOW_CARD
}
