import {Request} from '../../../../../../../urdad/services/models/request.model';
import {NaturalPersonIdentifier} from '../../../../../../../entity/identification/models/natural-person-identifier.model';

export class InitialiseCreateVerifiedAccountRequest extends Request {
    constructor() {
        super();
    }

    naturalPersonIdentifier: NaturalPersonIdentifier;
}
