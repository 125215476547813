import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WhiteLabelService} from "../../app.services/managers/white-label/white-label.service";
import {WhiteLabelComponent} from './white-label.component';
import {RouterModule, Routes} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {BrowserModule} from "@angular/platform-browser";
import {LoadingModule} from "ngx-loading";
import {SharedModule} from "../shared/shared.module";
import {AuthGuard} from "../../app.services/common/guards/auth-guard";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";

const routes: Routes = [
    {path: 'white-label', component: WhiteLabelComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule,
        ReactiveFormsModule,
        BrowserModule,
        LoadingModule,
        SharedModule,
        MatInputModule,
    ],
    exports: [
        WhiteLabelComponent
    ],
    providers: [WhiteLabelService],
    declarations: [WhiteLabelComponent]
})
export class WhiteLabelModule { }
