import { Request } from "../../../../../../urdad/services/models/request.model";
import { Criteria } from "../../../../../../criteria/models/criteria.model";

export class ProvideAccountingTransactionInformationRequest extends Request{
    constructor(){ 
        super();
    }

    public criteria: Criteria;
    public batchNumber: number;
    public batchSize: number;
}