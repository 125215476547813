import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { ProposalGroupEntry } from '../../../../../models/proposal-group-entry.model';

export class ProvideProposalGroupEntryResponse extends Response {
    constructor(){
        super();
    }

    public proposalGroupEntries: ProposalGroupEntry [] = [];

}