import {Location} from "@angular/common"
import {Component, OnInit, ViewChild} from "@angular/core"
import {ActivatedRoute, Params} from "@angular/router"
import {
    Country,
    Engine,
    EngineNumber,
    FinaliseMaintainVehicleInformationRequest,
    Make,
    Model,
    PlateNumber,
    ProvideRetailProfileVehiclesRequest,
    ProvideRetailProfileVehiclesResponse,
    RetailProfile,
    RetailProfileIdentifierCriteria,
    RetailProfileOwnerIdentifier,
    RetailVehicle,
    UnMarshallerService,
    Vehicle,
    VehiclePlateNumber,
} from "@magnabc/tpi"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast} from "ngx-toastr"
import {environment} from "../../../../../environments/environment"
import {ServiceList} from "../../../../common/model/service-list.model"
import {
    RetailProfileInformationProviderService
} from "../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service"
import {
    RetailProfileFinalisationManagerService
} from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import {
    VehicleInformationFinalisationManagerService
} from "../../../../http.services/vehicle/vehicle-information-finalisation-manager/vehicle-information-finalisation-manager.service"
import {FormComponent} from "../../../../view.components/shared/form/form.component"
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component"
import {VehicleEngineComponent} from "../../../../view.components/vehicle/engine/vehicle.engine.component"
import {VehicleGeneralComponent} from "../../../../view.components/vehicle/general/vehicle_general.component"
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {Title} from '@angular/platform-browser'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {CountryService} from "../../../../view.components/shared/country/services/country.service"


@Component({
    templateUrl: './vehicle-update-page.component.html',
    styleUrls: ['../../../../view.components/vehicle/vehicle.component.scss']
})
export class VehicleUpdatePageComponent extends FormComponent implements OnInit {

    tpiVehicleIdentifier: string;
    vehicle: Vehicle;
    unverifiedModel: Model;
    unverifiedMake: Make;
    isSelfPropelled: boolean;
    country: Country;

    @ViewChild(VehicleEngineComponent) engineComponent: VehicleEngineComponent;
    @ViewChild(VehicleGeneralComponent) vehicleGeneralComponent: VehicleGeneralComponent;

    private activeToast: ActiveToast<any>;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private route: ActivatedRoute,
                private toastr: ErrorToastService,
                private unMarshallerService: UnMarshallerService,
                private location: Location,
                private vehicleInformationFinalisationManagerService: VehicleInformationFinalisationManagerService,
                private quickstartService: QuickTransactManagerService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private authenticationService: AuthenticationService,
                private errorToastService: ErrorToastService,
                private countryService: CountryService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Vehicle - Update - ${this.runtimeConfigurationService.title}`);

    }

    ngOnInit(): void {

        this.route.params.forEach((params: Params) => {
            this.tpiVehicleIdentifier = params.vrn;

        });

        this.retailProfileService.registerRetailProfileListener((retailProfile) => {
            if (retailProfile) {
                this.getRetailProfileVehicles(retailProfile);
            }
        });

    }

    canDeactivate(): boolean {
        try {
            return this.vehicleGeneralComponent.canDeactivate() && this.engineComponent.canDeactivate();
        } catch (e) {
            return true;
        }
    }

    private getRetailProfileVehicles(retailProfile: RetailProfile) {

        const provideRetailProfileVehiclesRequest: ProvideRetailProfileVehiclesRequest = new ProvideRetailProfileVehiclesRequest();
        const criteria = new RetailProfileIdentifierCriteria();
        const retailProfileOwner = new RetailProfileOwnerIdentifier();
        retailProfileOwner.tpiIdentifier=retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        criteria.retailProfileIdentifier = retailProfileOwner;
        provideRetailProfileVehiclesRequest.criteria = criteria;
        provideRetailProfileVehiclesRequest.vehicleIdentificationNumber=this.tpiVehicleIdentifier.toString();

        this.retailProfileInformationProviderService.provideRetailProfileVehicles(provideRetailProfileVehiclesRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response: ProvideRetailProfileVehiclesResponse =
                    this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideRetailProfileVehiclesResponse);
                for (const v of response.vehicles) {
                    //if (this.tpiVehicleIdentifier === Utils.getTpiVehicleIdentifier(v.vehicleIdentifiers).number) {
                    this.vehicle = v;
                    this.isSelfPropelled = this.vehicle.propulsion.toLowerCase() === 'self-propelled';
                    if(v.vehicleRegistrations && v.vehicleRegistrations.length > 0 && this.vehicle.vehicleRegistrations.at(0).countryOfRegistration){
                        this.country = this.countryService.getCountries()
                            .find(country => country.description === v.vehicleRegistrations.at(0).countryOfRegistration);
                    }
                    break;
                    // }
                }
            }

        });

    }

    convertRetailVehicle(retail: RetailVehicle): Vehicle {
        const vehicle = new Vehicle();

        vehicle.cargo = null;
        vehicle.category = retail.category;
        vehicle.colour = retail.colour;
        vehicle.engine = retail.engine;
        vehicle.grossVehicleMass = retail.grossVehicleMass;
        vehicle.introductionDate = retail.introductionDate;
        vehicle.make = retail.make;
        vehicle.manufactureDate = retail.manufactureDate;
        vehicle.model = retail.model;
        vehicle.position = 0;
        vehicle.propulsion = retail.propulsion;
        vehicle.seatingCapacity = retail.seatingCapacity;
        vehicle.standingCapacity = retail.standingCapacity;
        vehicle.tare = retail.tare;
        vehicle.usage = retail.usage;
        vehicle.vehicleIdentificationNumber = retail.vehicleIdentificationNumber;
        vehicle.vehicleIdentifiers = retail.vehicleIdentifiers;
        vehicle.vehicleRegisterNumber = retail.vehicleRegisterNumber;
        vehicle.vehicleType = retail.vehicleType;

        retail.vehicleIdentifiers.forEach(
            identifier => {
                if (identifier instanceof VehiclePlateNumber) {
                    vehicle.plateNumbers.push(identifier);
                }
            }
        );

        return vehicle;
    }

    onEngine(engine: Engine): void {
        this.vehicle.engine = engine;
    }

    onVehicle(vehicle: Vehicle): void {
        this.vehicle = vehicle;
    }

    onUnverifiedMake(unverifiedMake: Make) {
        this.unverifiedMake = unverifiedMake;
    }

    onUnverifiedModel(unverifiedModel: Model) {
        this.unverifiedModel = unverifiedModel;
    }

    onPropulsion(propulsion: string) {
        if(propulsion.toLowerCase() === 'self_propelled') {
            this.isSelfPropelled = true;
        } else if(propulsion.toLowerCase() === 'semi_trailer' || propulsion.toLowerCase() === 'draw_bar_trailer') {
            this.isSelfPropelled = false;
        }
    }

    submit(): void {
        if (this.canCaptureForCountry) {
            this.vehicleGeneralComponent.submit();
        } else {
            this.engineComponent.submit();
        }
    }

    onEngineValid(): void {
        this.vehicleGeneralComponent.submit();
    }

    onVehicleValid(): void {

        const finaliseMaintainVehicleInformationRequest = new FinaliseMaintainVehicleInformationRequest();
        this.vehicle = this.clearEngineIdentifier(this.vehicle);
        finaliseMaintainVehicleInformationRequest.vehicle = this.vehicle;
        if (this.unverifiedMake) {
            finaliseMaintainVehicleInformationRequest.unverifiedMake = this.unverifiedMake;
        }
        if (this.unverifiedModel) {
            finaliseMaintainVehicleInformationRequest.unverifiedModel = this.unverifiedModel;
        }
        this.startLoad();
        this.vehicleInformationFinalisationManagerService.finaliseMaintainVehicleInformation(finaliseMaintainVehicleInformationRequest).subscribe((response) => {
            this.retailProfileService.refreshRetailProfile().then(() => {
                this.toast();
                this.stopLoad();
            });
        }, exception => {
            this.errorToastService.errorToast(exception);
            console.error(exception);
            this.stopLoad();
        });

    }

    getVehicleIdentification(vehicle: Vehicle): string {
        let vehicleIdentifier: string = null;

        if (vehicle && vehicle.vehicleIdentifiers && vehicle.vehicleIdentifiers.length > 0) {
            vehicle.vehicleIdentifiers.forEach(identifier => {
                if (identifier instanceof PlateNumber) {
                    vehicleIdentifier = identifier.number;
                }
            });
        }

        return vehicleIdentifier;
    }

    toast() {

        this.translate.get('TOAST.UPDATE_VEHICLE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.vehicle.make }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });

    }

    servicesMaintainVehicle(): boolean {
        return !!this.authenticationService.isAuthorised([ServiceList.MAINTAINVEHICLEINFORMATION]) || !!this.quickstartService.retailProfile;
    }

    private clearEngineIdentifier(vehicle: Vehicle): Vehicle {
        if(vehicle.engine && vehicle.engine.engineNumber === ''){
            vehicle.vehicleIdentifiers = vehicle.vehicleIdentifiers.filter((data) => {
                return !(data instanceof EngineNumber);
            })
        }
        return vehicle;
    }


    get canCaptureForCountry(): boolean {
        const easyCaptureAlpaTwo = ['ZA'];
        return easyCaptureAlpaTwo.map((alpha) => alpha.toLocaleLowerCase())
            .includes(this.country.alphaTwo.toLocaleLowerCase());
    }
}
