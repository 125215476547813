import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    InsuranceCategory,
    InsurancePolicy,
    InsurancePolicyVehicleCriteria,
    MotorInsurancePolicy,
    MotorInsurancePolicyItem,
    Order,
    OrderNumberCriteria,
    OrderStatus,
    PlateNumber,
    ProposalRecordStatus,
    ProposalRecordSummaryStatusCriteria,
    ProposalSummaryInformation,
    ProvideInsurancePoliciesRequest,
    ProvideInsurancePoliciesResponse,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    ProvideProposalSummaryRequest,
    ProvideProposalSummaryResponse,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehicleInformationResponse,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    RetailVehicle,
    ThirdPartyInsurancePolicyOrderItem,
    Underwriter,
    UnMarshallerService,
    VehiclePlateNumber,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment/moment';
import { ActiveToast } from 'ngx-toastr';

import {
    QuickTransactManagerService,
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';
import { ServiceList } from '../../../../common/model/service-list.model';
import {
    OrderInformationProviderService,
} from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service';
import {
    ProposalInformationProviderService,
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service';
import {
    InsurancePolicyProviderService,
} from '../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service';
import {
    InsuranceProductFinalisationManagerService,
} from '../../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service';
import {
    UnderwriterInformationProviderService,
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import {
    RetailProfileInformationProviderService,
} from '../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service';
import {
    RetailProfileFinalisationManagerService,
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import {
    ServiceGroupFinalisationManagerService,
} from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service';
import {
    SystemParameterProviderService,
} from '../../../../http.services/system/system-parameter-provider/system-parameter-provider.service';
import { FormComponent } from '../../../shared/form/form.component';
import { Utils } from '../../../shared/Utils/Utils';
import { InsuranceProductService } from './../../../../app.services/managers/insurance-product/insurance-product.service';
import {
    InsuranceTypeManagerService,
} from './../../../../app.services/managers/insurance-type-manager/insurance-type-manager.service';

declare const jQuery: any;

@Component({
    selector: 'app-vehicle-selector',
    templateUrl: './vehicle-selector.component.html',
    styleUrls: ['./vehicle-selector.component.scss']
})
export class VehicleSelectorComponent extends FormComponent {

    @Input() quickTransact: boolean;
    @Output() onClose = new EventEmitter<RetailVehicle[]>()
    @Input() closeAction: VehicleSelectorCloseAction = null

    rows: RetailVehicle[] = [];
    columns = [
        { details: 'details' }
    ];
    expanded: any = {};

    retailProfile: RetailProfile;

    policies: { [id: string]: InsurancePolicy[] } = {};
    orders: { [id: string]: Order[] } = {};
    ordersTemp: Order[] = [];
    proposals: { [id: string]: ProposalSummaryInformation[] } = {};
    loadingPolicies = true;

    activeToast: ActiveToast<any>;
    temp: RetailVehicle[] = [];
    selectedVehicles: RetailVehicle[] = [];
    underwriters: { [id: string]: Underwriter } = {};

    category = 'All Categories';
    filterValue: string;

    selectedUsage: string;
    selectedCategory: string;
    selectedCountry: string;


    totalElements: number = 0;
    loadComplete: boolean = false;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    notSelectable = false;
    extensionPolicyMap: { [id: string]: MotorInsurancePolicyItem[] } = {};
    originalPolicyMap: { [id: string]: MotorInsurancePolicyItem[] } = {};
    loadedPolicies = false

    selectableCountries: string[] = [];
    selectableCategories: string[] = [];
    selectionGroups: SelectionGroup[] = [];
    selectedOptions: any[] = [];
    readonly selectionGroupOneName = 'Country';
    readonly selectionGroupTwoName = 'Vehicle Usage';

    loading = false;

    constructor(public translate: TranslateService,
        private router: Router,
        private unMarshallerService: UnMarshallerService,
        public retailProfileService: RetailProfileFinalisationManagerService,
        private retailProfileInformationProviderService: RetailProfileInformationProviderService,
        private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
        public authenticationService: ServiceGroupFinalisationManagerService,
        private insurancePolicyProviderService: InsurancePolicyProviderService,
        public quickStartService: QuickTransactManagerService,
        private orderInformationProviderService: OrderInformationProviderService,
        private proposalInformationProviderService: ProposalInformationProviderService,
        private route: ActivatedRoute,
        private brokerService: UnderwriterInformationProviderService,
        private systemParameterService: SystemParameterProviderService,
        private translateParser: TranslateParser,
        public insuranceTypeManagerService: InsuranceTypeManagerService,
        private insuranceProductService: InsuranceProductService) {
        super(translate, translateParser);
        this.temp = this.rows;
    }

    close(): void {
        this.onClose.emit(this.selectedVehicles);
    }

    ngOnInit(): void {
        // FIXME
        this.brokerService.getAllUnderwriters((underwriters: Underwriter[]) => {
            for (const underwriter of underwriters) {
                this.underwriters[underwriter.id] = underwriter;
            }
        });

        this.startLoad();
        this.retailProfileService.refreshRetailProfile().then((retailProfile) => {

            if (this.quickTransact) {
                this.retailProfile = this.quickStartService.retailProfile;
            } else {
                this.retailProfile = retailProfile;
            }

            this.selectedVehicles = this.insuranceProductFinalisationManagerService.vehicles;

            if (this.selectedVehicles && this.selectedVehicles.length > 0) {
                this.selectedUsage = this.getActualClassification(this.selectedVehicles[0]);
                this.selectedCategory = this.selectedVehicles[0].category;
                this.selectedCountry = this.selectedVehicles[0].countryOfRegistration;
            }

            this.onToggleSearch();
            this.getRetailProfileVehicles();
            setTimeout(() => {
                if (this.rows.length === 0) {
                    this.getRetailProfileVehicles();
                }
            }, 3000);

        });

    }

    provideProposals(): void {
        const proposalRecordSummaryStatusCriteria = new ProposalRecordSummaryStatusCriteria();
        if (this.retailProfileService.retailProfile) {
            proposalRecordSummaryStatusCriteria.legalEntityIdentifier = this.retailProfileService.retailProfile.legalEntityDescriptionIdentifier;
        }
        const status = [ProposalRecordStatus.OPEN, ProposalRecordStatus.PENDING]
        proposalRecordSummaryStatusCriteria.status = status;

        const provideProposalSummaryRequest = new ProvideProposalSummaryRequest();
        provideProposalSummaryRequest.criteria = proposalRecordSummaryStatusCriteria;

        this.proposalInformationProviderService.provideProposalSummary(provideProposalSummaryRequest).then((data) => {
            this.proposals = {};
            const response: ProvideProposalSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideProposalSummaryResponse);

            for (const item of response.proposalSummaryInformation) {
                if (!this.proposals[item.tpiVehicleIdentifier]) {
                    this.proposals[item.tpiVehicleIdentifier] = [];
                }
                this.proposals[item.tpiVehicleIdentifier].push(item);
            }

        }).catch(err => {
            console.error(err);
        });
    }

    provideOrders(): void {

        this.orders = {};

        const orderNumberCriteria = new OrderNumberCriteria();
        if (this.retailProfileService.retailProfile) {
            orderNumberCriteria.orderIdentifiers = this.retailProfileService.retailProfile.orderIdentifiers;
        }

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderNumberCriteria;

        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {

            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            for (const order of response.orders) {
                if (order.status === OrderStatus.PENDING_PAYMENT) {
                    for (const item of order.orderItems) {
                        if ((item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle) {
                            if (!this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier]) {
                                this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier] = [];
                            }
                            this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].push(order);
                            this.ordersTemp = this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier];
                            this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier] = [];
                            this.ordersTemp.forEach(itemCheck => {
                                const found = this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].filter(vehicle => vehicle.number === itemCheck.number);
                                if (found.length === 0) {
                                    this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].push(itemCheck);
                                }
                            })
                        }
                    }
                }
            }
        });

    }

    providePolicies(): void {

        if (this.rows.length > 0) {

            this.policies = {};
            this.loadingPolicies = true;
            const promises = []

            for (const vehicle of this.rows) {

                const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
                const thirdPartyInsurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                thirdPartyInsurancePolicyVehicleCriteria.tpiVehicleIdentifier = Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers).number.toString();
                thirdPartyInsurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
                provideThirdPartyInsurancePoliciesRequest.criteria = thirdPartyInsurancePolicyVehicleCriteria;
                provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
                provideThirdPartyInsurancePoliciesRequest.batchSize = -1;

                promises.push(this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {

                    if (httpResponse && httpResponse.body) {
                        const response = (this.unMarshallerService.unMarshallFromJson
                            (httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                        if (response.insurancePolicies.length > 0) {
                            for (const policy of response.insurancePolicies) {
                                if (policy instanceof MotorInsurancePolicy) {
                                    policy.expiryDate = this.addExtraDay(policy.expiryDate);
                                    policy.insurancePolicyItems.forEach((item) => {
                                        (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate = this.addExtraDay((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate);

                                        if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'EXTENSION') {
                                            if (!this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier]) {
                                                this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier] = [];
                                            }
                                            this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier].push((item as MotorInsurancePolicyItem));
                                        }
                                        if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'NEW') {
                                            if (!this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier]) {
                                                this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier] = [];
                                            }
                                            this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier].push((item as MotorInsurancePolicyItem));
                                        }
                                    });
                                    if (!moment(policy.expiryDate).isBefore(moment().subtract(4, 'months'))) {
                                        if (!this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)]) {
                                            this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)] = [];
                                        }
                                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)].push(policy);
                                    }
                                }
                            }
                        }
                        this.loadingPolicies = false;
                    }
                }, () => {
                    this.loadingPolicies = false;
                }))

            }

            this.loadedPolicies = false
            Promise.allSettled(promises).then(() => {
                this.loadedPolicies = true
            })

        }

    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {

        return Utils.getTpiVehicleIdentifier(vehicleIdentifiers).number;

    }

    onCategory(category): boolean {
        this.notSelectable = false;
        this.category = category;
        this.filter();
        return false;
    }

    isValid(policy: InsurancePolicy, tpiVehicleIdentifier): boolean {
        const motorInsurancePolicy = (policy as MotorInsurancePolicy)
        for (const insurancePolicyItem of motorInsurancePolicy.insurancePolicyItems) {
            const motorInsurancePolicyItem = insurancePolicyItem as MotorInsurancePolicyItem
            if (tpiVehicleIdentifier === motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier) {
                if (!motorInsurancePolicyItem.motorInsuranceVehiclePolicy.cancelled && this.isValidExpiryDate(motorInsurancePolicyItem.motorInsuranceVehiclePolicy.expiryDate)) {
                    return true
                }
            }
        }
        return false
    }

    isCancelled(policy: InsurancePolicy, tpiVehicleIdentifier): boolean {
        const motorInsurancePolicy = (policy as MotorInsurancePolicy)
        for (const insurancePolicyItem of motorInsurancePolicy.insurancePolicyItems) {
            const motorInsurancePolicyItem = insurancePolicyItem as MotorInsurancePolicyItem
            if (tpiVehicleIdentifier === motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier) {
                if (!motorInsurancePolicyItem.motorInsuranceVehiclePolicy.cancelled) {
                    return false
                }
            }
        }
        return true
    }

    isValidExpiryDate(expiryDate: Date): boolean {
        return moment(expiryDate).isAfter(moment());
    }

    formatDate(date): string {
        return moment(date).format("DD MMM YYYY");
    }

    formatPeriod(date): string {
        const period = moment(date);
        if (period.month() === 0) {
            return period.format("YYYY");
        }
        return period.format("MMM");
    }

    filter(): void {

        this.loadedPolicies = false

        setTimeout(() => {

            this.rows = [];

            const vehicles = this.filterVehicles(this.temp);

            let filterVehicles = vehicles;
            if (this.filterValue && this.filterValue !== "") {
                filterVehicles = vehicles.filter((d) => {
                    let index = d.vehicleIdentifiers.findIndex((vehicleIdentifier) => vehicleIdentifier instanceof PlateNumber);
                    return d.vehicleIdentifiers[index].number.replace(/ /g, "").toLowerCase().indexOf(this.filterValue) !== -1 || !this.filterValue;
                });
            }

            this.rows = filterVehicles;
            this.loadedPolicies = true

        })

    }

    filterVehicles(temp): any {

        const vehicles = [];
        if (this.category === 'All Categories') {
            return temp;
        } else {
            for (const item of temp) {
                if (item.category === this.category) {
                    vehicles.push(item);
                }
            }
        }
        return vehicles;

    }

    addVehicles(): void {
        this.onClose.emit([]);
        if (this.quickTransact) {
            this.router.navigate(['/retail/profile/link'], { queryParams: { quickTransact: true } });
        } else {
            this.router.navigate(['/retail/profile/link']);
        }
    }

    toggleExpandRow(event, row): boolean {
        event.stopPropagation();
        this.table.rowDetail.toggleExpandRow(row);
        return false;
    }

    onDetailToggle(event) {

    }

    onSelect(event): void {
        this.selectedVehicles = [];
        if (this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.COMPREHENSIVE
            || this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.THIRDPARTY) {
            this.doSameCategorySelect(event);
        } else {
            this.doMultiCategorySelect(event);
        }

        this.notSelectable = false;
    }

    doSameCategorySelect(event: any): void {
        for (const vehicle of event.selected) {
            if (this.checkSelectable(vehicle)) {
                this.selectedVehicles.push(vehicle);
            }
        }
        this.table.selected = this.selectedVehicles;

        if (this.selectedVehicles && this.selectedVehicles.length > 0) {
            this.selectedUsage = this.getActualClassification(this.selectedVehicles[0]);
            this.selectedCategory = this.selectedVehicles[0].category;
            this.selectedCountry = this.selectedVehicles[0].countryOfRegistration;
        } else {
            this.selectedUsage = null;
            this.selectedCategory = null;
            this.selectedCountry = null;
        }
    }

    doMultiCategorySelect(event: any): void {
        for (const vehicle of event.selected) {
            const retailVehicle = vehicle as RetailVehicle;
            let canAdd = false;

            if (this.selectedCountry) {
                if (this.selectedCountry.toLowerCase() === retailVehicle.countryOfRegistration.toLowerCase()) {
                    canAdd = true;
                }
            } else {
                this.selectedCountry = retailVehicle.countryOfRegistration;
                canAdd = true;
            }

            if (canAdd) {
                this.selectedVehicles.push(vehicle);
            }
        }
        this.table.selected = this.selectedVehicles;
        if (this.selectedVehicles.length === 0) {
            this.selectedCountry = null;
        }

        this.selectedUsage = null;
        this.selectedCategory = null;
    }

    selectAll(): void {
        if (this.checkAllSelectable()) {

            this.selectedVehicles = [];
            for (const item of this.rows) {
                if (this.checkSelectable(item)) {
                    this.selectedVehicles.push(item);
                }
            }
            this.table.selected = this.selectedVehicles;

        } else {
            this.notSelectable = true;
        }
    }

    deselectAll(): void {
        this.selectedOptions = [];
        this.selectedVehicles = [];
        this.table.selected = this.selectedVehicles;
        this.resetRows();
        this.selectedCountry = null;
        this.selectedUsage = null;
        this.selectedCategory = null;
    }

    private resetRows(): void {
        this.rows = [];
        setTimeout(() => {
            this.rows = this.temp
        });
    }

    checkSelectable(event): boolean {
        const isYellowCard = this.insuranceTypeManagerService.selectedInsuranceCategory === InsuranceCategory.YELLOWCARD;

        if (this.loadingPolicies) {
            return false;
        }

        if (!this.policiesSelectable(event.vehicleIdentificationNumber)) {
            return false;
        }

        if (!this.selectedUsage && !this.selectedCategory && !this.selectedCountry) {
            return true;
        }

        if (isYellowCard) {
            return this.selectedCountry === event.countryOfRegistration;
        }
        else {
            return this.selectedUsage === this.getActualClassification(event) && this.selectedCategory === event.category
                && this.selectedCountry === event.countryOfRegistration;
        }
    }

    getActualClassification(vehicle: RetailVehicle): string {
        if (vehicle.vehicleType && vehicle.seatingCapacity) { // If Malawi?
            if (vehicle.vehicleType === 'Pick-up' && vehicle.seatingCapacity <= 3) {
                return 'Commercial';
            }
        }
        return vehicle.usage;
    }

    policiesSelectable(vehicleIdentificationNumber): boolean {

        const currentPeriod = moment().subtract(moment().month() % 3, 'months').startOf('month');
        const currentPeriodEnd = moment(currentPeriod).add(3, 'months').subtract(1, 'days');
        const nextPeriod = moment().subtract(moment().month() % 3, 'months').add(3, 'months').startOf('month');
        const nextPeriodEnd = moment(nextPeriod).add(3, 'months').subtract(1, 'days');

        if (this.policies[vehicleIdentificationNumber]) {
            let current = false;
            for (const policy of this.policies[vehicleIdentificationNumber]) {
                // if (currentPeriodEnd.isAfter(policy.effectiveDate) && currentPeriod.isBefore(policy.expiryDate)) { FIXME uncomment
                //     current = true;
                //     break;
                // }
            }

            let next = false;
            for (const policy of this.policies[vehicleIdentificationNumber]) {
                // if (nextPeriodEnd.isAfter(policy.effectiveDate) && nextPeriod.isBefore(policy.expiryDate)) { FIXME uncomment
                //     next = true;
                //     break;
                // }
            }

            return !(current && next);

        } else {
            return true;
        }

    }

    checkAllSelectable(): boolean {

        if (this.rows.length === 0) {
            return false;
        }

        let usageAndCategory;
        for (const v of this.rows) {
            const vehicleUsageAndCategory = v.usage + v.category;
            if (!usageAndCategory) {
                usageAndCategory = vehicleUsageAndCategory;
            }
            if (usageAndCategory !== vehicleUsageAndCategory) {
                return false;
            }
        }

        return true;

    }

    isSelected(vehicle: RetailVehicle): boolean {
        if (this.selectedVehicles) {
            for (const selectedVehicle of this.selectedVehicles) {
                if (this.getTpiVehicleIdentifier(selectedVehicle.vehicleIdentifiers) === this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)) {
                    return true;
                }
            }
        }
        return false;
    }

    updateFilter(event) {

        this.filterValue = event.target.value.toLowerCase();
        this.filter();

        // this.table.offset = 0; FIXME

    }

    onToggleSearch(): void {
        const input = jQuery('#input-search-vehicles');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    private getRetailProfileVehicles() {
        this.startLoad();
        const provideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;
        provideRetailProfileVehicleInformationRequest.batchSize = 20;
        provideRetailProfileVehicleInformationRequest.batchNumber = 1;
        provideRetailProfileVehicleInformationRequest.insuranceCategory = this.insuranceTypeManagerService.selectedInsuranceCategory;

        this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then((data) => {
            const response: ProvideRetailProfileVehicleInformationResponse =
                this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileVehicleInformationResponse);

            this.totalElements = response.totalNumberOfResults ? response.totalNumberOfResults : 0;
            this.rows = response.retailVehicles;

            this.sortRows();

            this.temp = this.rows;

            this.table.selected = [];
            for (const v1 of this.selectedVehicles) {
                for (const v2 of this.temp) {
                    if (this.getTpiVehicleIdentifier(v1.vehicleIdentifiers) === this.getTpiVehicleIdentifier(v2.vehicleIdentifiers)) {
                        this.table.selected.push(v2);
                    }
                }
            }

            this.providePolicies();
            this.provideOrders();
            this.provideProposals();
            this.getRetailProfileVehiclesBatch(20, response.batchTo);
            this.stopLoad();
        });

    }

    private sortRows(): void {
        this.rows.sort((a, b) => {
            if (a.category > b.category) {
                return 1;
            }
            if (a.category < b.category) {
                return -1;
            }
            if (a.usage > b.usage) {
                return 1;
            }
            if (a.usage < b.usage) {
                return -1;
            }
            return 0;
        });
    }

    onOptionSelected(value): void {
        const selectedGroup = this.selectionGroups.find(group => group.options.includes(value));

        if (selectedGroup) {
            selectedGroup.options.forEach(opt => {
                const index = this.selectedOptions.indexOf(opt);
                if (index > -1) {
                    this.selectedOptions.splice(index, 1);
                }
            });
            const temp = [...this.selectedOptions]
            this.selectedOptions = [];
            setTimeout(() => {
                this.selectedOptions = temp;
                this.selectedOptions.push(value);
                this.setAllSelectedVehicles(this.selectedOptions);
            })
        }
    }

    setAllSelectedVehicles(items: string[]): void {
        let country: string;
        let usage: string;
        let category: string;
        items.forEach((item) => {
            const match = this.selectionGroups.find((x) => x.options.includes(item));
            if (match && match.name === this.selectionGroupOneName) {
                country = item;
            } else if (match && match.name === this.selectionGroupTwoName) {
                const itemParts = item.split('-').map(x => x.trim());
                usage = itemParts.at(0);
                category = itemParts.at(1);
            }
        });

        if (country && usage && category) {
            const selectedVehicles = this.rows.filter((vehicle) => vehicle.countryOfRegistration === country
                && vehicle.usage === usage && vehicle.category === category);
            this.selectedVehicles = selectedVehicles;
            this.table.selected = this.selectedVehicles;

            if (this.selectedVehicles && this.selectedVehicles.length > 0) {
                this.selectedUsage = this.getActualClassification(this.selectedVehicles[0]);
                this.selectedCategory = this.selectedVehicles[0].category;
                this.selectedCountry = this.selectedVehicles[0].countryOfRegistration;
            } else {
                this.selectedUsage = null;
                this.selectedCategory = null;
                this.selectedCountry = null;
            }

            this.sortAllSelected();
        }
    }

    private sortAllSelected(): void {
        if (this.selectedVehicles.length === 0) {
            this.resetRows();
            return;
        }

        const temp = this.rows;
        this.rows = [];
        let firstList = [];
        let lastList = [];
        temp.forEach((item) => {
            const match = this.selectedVehicles.find((v) => {
                return v.vehicleIdentificationNumber === item.vehicleIdentificationNumber;
            });
            if (match) {
                firstList.push(item);
            } else {
                lastList.push(item);
            }
        });

        setTimeout(() => {
            this.rows = [...firstList, ...lastList];
        });
    }

    getRetailProfileVehiclesBatch(batchSize: number, batchTo: number) {


        const provideRetailProfileVehicleInformationRequest: ProvideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;
        provideRetailProfileVehicleInformationRequest.batchSize = 20;
        provideRetailProfileVehicleInformationRequest.batchNumber = Math.ceil(batchTo / batchSize) + 1;
        provideRetailProfileVehicleInformationRequest.insuranceCategory = this.insuranceTypeManagerService.selectedInsuranceCategory;

        if (this.rows.length === this.totalElements || (this.rows.length > this.totalElements)) {
            this.selectionGroups = [];
            this.table.recalculate();
            this.loadComplete = true;
            this.selectionGroups.push({
                name: this.selectionGroupOneName,
                options: [... new Set(this.rows.map(item => item.countryOfRegistration))]
            });
            this.selectionGroups.push({
                name: this.selectionGroupTwoName,
                options: [... new Set(this.rows.map(item => `${item.usage} - ${item.category}`))]
            });
        } else {

            //this.startLoad();
            this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then(
                (data) => {
                    const response: ProvideRetailProfileVehicleInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileVehicleInformationResponse);

                    const currentList = response.retailVehicles;
                    for (const retailVehicle of currentList) {
                        this.rows.push(retailVehicle);
                    }
                    this.temp = this.rows;
                    this.temp = [...this.temp];
                    this.rows = this.rows;
                    this.rows = [...this.rows];
                    this.providePolicies();

                    this.getRetailProfileVehiclesBatch(20, response.batchTo);

                }, (error) => {
                    console.log('eror ', error);
                }

            ).catch((error) => {
                console.log('eror ', error);
            });

        }
    }


    public getPlateNumber(vehicle: RetailVehicle): string {
        let plateNo = '';
        vehicle.vehicleIdentifiers.forEach(
            identifier => {
                if (identifier instanceof VehiclePlateNumber) {
                    plateNo = identifier.number;
                } else if (identifier instanceof PlateNumber) {
                    plateNo = identifier.number;
                }
            }
        )
        return plateNo;
    }

    servicesLinkVehicles(): boolean {
        return !!(this.authenticationService.services != undefined || this.authenticationService.services[ServiceList.LINKVEHICLE] || this.authenticationService.services[ServiceList.ASSISTEDQUICKTRANSACT] || this.quickTransact);
    }

    onCart(event): boolean {

        if (this.route.snapshot.queryParams.quickTransact) {
            this.router.navigate(['/retail/cart'], { queryParams: { quickTransact: true } });
        } else {
            this.router.navigate(['/retail/cart']);
        }
        this.onClose.emit([]);

        return false;
    }

    onCheckout(event): boolean {

        if (this.route.snapshot.queryParams.quickTransact) {
            this.router.navigate(['/retail/checkout'], { queryParams: { quickTransact: true } });
        } else {
            this.router.navigate(['/retail/checkout']);
        }
        this.onClose.emit([]);

        return false;
    }

    private formatPolicyExpiry(policy: MotorInsurancePolicy, policyItem: MotorInsurancePolicyItem): string {
        let expiryDate: string;
        if ('expiryDate' in policyItem.motorInsuranceVehiclePolicy) {
            expiryDate = this.formatDate(policyItem.motorInsuranceVehiclePolicy.expiryDate)
        } else {
            expiryDate = this.formatDate(policy.expiryDate);
        }
        return expiryDate;
    }

    getPolicyExpiry(policy: MotorInsurancePolicy): string {
        let expiryDate: string = "";

        for (let item of policy.insurancePolicyItems) {
            const policyItem = item as MotorInsurancePolicyItem;
            if (policyItem.insurancePolicyItemType === 'EXTENSION' && !policyItem.motorInsuranceVehiclePolicy.cancelled) {
                expiryDate = this.formatPolicyExpiry(policy, policyItem);
                break;
            } else if (policyItem.insurancePolicyItemType === 'NEW') {
                expiryDate = this.formatPolicyExpiry(policy, policyItem);
            }
        }
        return expiryDate;
    }

    addExtraDay(date) {
        const newDate = new Date(date)
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1, 0, 0, 0);
    }

    removeExtraDay(date) {
        const newDate = new Date(date);
        return this.formatDate(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1, 0, 0, 0));
    }

    /**
     * Close the dialog
     */
    closeDialog() {
        const retrieve = localStorage.getItem('selectProductScreen');
        if (retrieve !== 'true' || (this.closeAction && this.closeAction === VehicleSelectorCloseAction.GO_HOME)) {
            this.router.navigate(['/retail/profile']);
        } else {
            this.close();
        }
    }
}

interface SelectionGroup {
    name: string
    options: string[];
}

export enum VehicleSelectorCloseAction {
    CLOSE_DIALOG,
    GO_HOME
}
