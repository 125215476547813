import {Inject, Injectable} from '@angular/core';
import {OneTimePinIssuanceManagerService} from '../one-time-pin-issuance-manager/one-time-pin-issuance-manager.service';
import {
    ContactNumber,
    IssueOneTimePinRequest,
    IssueOneTimePinResponse,
    OneTimePinContext,
    OneTimePinIdentifier,
    PinContext,
    UnMarshallerService,
    ValidateOneTimePinRequest,
    ValidateOneTimePinResponse
} from '@magnabc/tpi';
import {OneTimePinValidationManagerProviderService} from '../one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service';
import { RaygunErrorHandler } from '../../../../common/utils/utils.raygun';

@Injectable({
    providedIn: 'root'
})
export class OneTimePinVerificationManagerService {

    private oneTimePinIdentifier: OneTimePinIdentifier = null;

    constructor(private oneTimePinIssuanceManager: OneTimePinIssuanceManagerService,
                private oneTimePinValidationManager: OneTimePinValidationManagerProviderService,
                private unMarshallerService: UnMarshallerService) {
    }

    issueOneTimePin(contactNumber: ContactNumber, context: string = PinContext.VERIFY_ACCOUNT): Promise<boolean> {
        const oneTimePinContext = new OneTimePinContext();
        oneTimePinContext.context = context;

        const issueOneTimePinRequest = new IssueOneTimePinRequest();
        issueOneTimePinRequest.oneTimePinContext = oneTimePinContext;
        issueOneTimePinRequest.contactNumber = contactNumber;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinIssuanceManager.issueOneTimePin(issueOneTimePinRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {

                    const issueOneTimePinResponse = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);

                    this.oneTimePinIdentifier = issueOneTimePinResponse.oneTimePinIdentifier;
                    resolve(true);
                    return;
                }

                console.log(httpResponse);
                reject('Could not retrieve one time pin issuance result.');
                return;
            }).catch(exception => {
                RaygunErrorHandler.sendError(exception);

                console.log('Exception :: ', exception);
                reject(false);
                return;
            });
        });
    }

    resendOtp(contactNumber: ContactNumber, context: string = PinContext.VERIFY_ACCOUNT): Promise<boolean> {
        return this.issueOneTimePin(contactNumber, context);
    }

    validateOneTimePin(oneTimePin: string): Promise<boolean> {

        const validateOneTimePinRequest = new ValidateOneTimePinRequest();
        validateOneTimePinRequest.capturedOneTimePin = oneTimePin;
        validateOneTimePinRequest.oneTimePinIdentifier = this.oneTimePinIdentifier;

        return new Promise<boolean>((resolve, reject) => {
            if (!this.oneTimePinIdentifier) {
                reject('One time pin could not be validated.');
                return;
            }

            this.oneTimePinValidationManager.validateOneTimePin(validateOneTimePinRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const validateOneTimePinResponse = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, ValidateOneTimePinResponse) as ValidateOneTimePinResponse);

                    resolve(validateOneTimePinResponse.valid);
                    return;
                }

                console.log(httpResponse);
                reject('Could not retrieve one time pin result.');
                return;
            }).catch(exception => {
                RaygunErrorHandler.sendError(exception);

                console.log('Exception :: ', exception);
                reject(false);
                return;
            });
        });
    }
}
