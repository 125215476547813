import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {FinaliseCheckoutOrderRequest, FinaliseExtendCheckoutOrderRequest, FinaliseYCCheckoutOrderRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class CheckoutOrderFinalisationManagerService {

  basePath = '/retail/checkout/checkoutorderfinalisationmanager';

  constructor(private http: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  finaliseCheckoutOrder(finaliseCheckoutOrderRequest: FinaliseCheckoutOrderRequest): Promise<any> {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecheckoutorder';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCheckoutOrderRequest);
      return this.http.post<any>(path, jsonRequest, this.options).toPromise();
  }

  finaliseExtendCheckoutOrder(finaliseExtendCheckoutOrderRequest: FinaliseExtendCheckoutOrderRequest): Promise<any> {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseextendcheckoutorder';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseExtendCheckoutOrderRequest);
      return this.http.post<any>(path, jsonRequest, this.options).toPromise();
  }

  finaliseYCCheckoutOrder(finaliseYCCheckoutOrderRequest: FinaliseYCCheckoutOrderRequest): Promise<any> {
    const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseyccheckoutorder';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseYCCheckoutOrderRequest);
    return this.http.post<any>(path, jsonRequest, this.options).toPromise();
  }

}
