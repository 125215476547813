import {Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {MatTableDataSource} from '@angular/material/table'
import {MatPaginator} from '@angular/material/paginator'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {
    FinancialReportProviderService
} from '../../../../http.services/ledger/reporting/financial-report-provider/financial-report-provider.service'
import {
    UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {AgentManagerService} from '../../../../app.services/managers/agent-manager/agent-manager.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import * as moment from 'moment/moment'
import {ServiceList} from '../../../../common/model/service-list.model'
import {Utils} from '../../../shared/Utils/Utils'
import {environment} from '../../../../../environments/environment'
import * as XLSX from 'xlsx'
import {
    Agent,
    BrokerLegalEntityIdentifier,
    ProvideRevenueReportRequest,
    ReportLegalEntityDateCriteria,
    RevenueReportEntry,
    TpiIdentifier
} from "@magnabc/tpi"

type AOA = any[][];
declare const jQuery: any;

@Component({
  selector: 'app-revenue-report',
  templateUrl: './revenue-report.component.html',
  styleUrls: ['./revenue-report.component.scss']
})
export class RevenueReportComponent extends FormComponent implements OnInit {

    zambeziInsureIdentifier = 'AGT-2'; // FIXME hardcoded

    rows = [];
    data: AOA = [];
    fileName = 'Revenue Report.xlsx';

    agent: Agent;
    entity = "Entity";
    from;
    to;

    roleMap: {[id: string]: string[]} = {};

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['date', 'policyNumber', 'certificatNumber', 'transactionAmount', 'payable', 'receivable', 'settlement',
        'cumulativeTotal', 'roles','currency', 'insuredName', 'invoiceNumber','userId', 'startDate', 'expiryDate'];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private title: Title,
                private financialReportProviderService: FinancialReportProviderService,
                private brokerService: UnderwriterInformationProviderService,
                private agentService: AgentManagerService,
                private authenticationService: AuthenticationService,
                private errorToastService: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Revenue Report - Console - ${runtimeConfigurationService.title}`);

        this.dataSource = new MatTableDataSource(this.rows);
    }

    ngOnInit() {
        this.from = moment();
        this.to = moment();
        this.getRevenueSplit();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    getRevenueSplit(): void {

        this.from.startOf('day');
        this.to.endOf('day');
        let criteria: ReportLegalEntityDateCriteria = null;

        if(!!(this.authenticationService.isAuthorised([ServiceList.PROVIDEREVENUEREPORT]))) {
            criteria = new ReportLegalEntityDateCriteria();
            criteria.tpiIdentifier =  Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
        } else
        if(!!(this.authenticationService.isAuthorised([ServiceList.PROVIDEACCOUNTINFORMATION]) &&
            this.authenticationService.isAuthorised([ServiceList.PROVIDEACCOUNTINGTRANSACTIONINFORMATION])&&
            this.authenticationService.isAuthorised([ServiceList.PROVIDEACCOUNTSNAPSHOTINFORMATION])&&
            this.authenticationService.isAuthorised([ServiceList.PROVIDEDEPOSITMETHOD]))) {
            const newCriteria = new ReportLegalEntityDateCriteria();
            newCriteria.tpiIdentifier =  Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers());
            criteria = newCriteria;
        }
        if(criteria){

            criteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
            criteria.dateTo =  this.to.startOf('day').format('YYYY-MM-DD') as Date;
        }
        const provideRevenueReportRequest = new ProvideRevenueReportRequest();

        if (this.agentService.agent) {
            this.entity = this.agentService.agent.name;
        }
        provideRevenueReportRequest.criteria = criteria;

        this.roleMap = {};
        this.startLoad();
        this.financialReportProviderService.provideRevenueReport(provideRevenueReportRequest).subscribe((response) => {

            this.stopLoad();

            this.data = [];
            this.rows = [];

            this.data.push([this.entity]);
            this.data.push([""]);
            this.data.push(["From Date","To Date"]);
            this.data.push([this.getDate(this.from), this.getDate(this.to)]);
            this.data.push([""]);
            this.data.push(["Date", "Policy Number", "Certificate Number", "Transaction Amount", "Payable",'Receivable', "Settlement", "Cumulative Total", "Roles", "Currency", "Insured Name", "Invoice Number", "User ID", "Start Date", "Expiry Date"]);

            let revenueReportEntries: RevenueReportEntry[] = [];
            if(response){
                if(response.body){
                    if(response.body.revenueReport){
                        revenueReportEntries = response.body.revenueReport.revenueReportEntries;
                    }
                }
            }
            for (const r of revenueReportEntries) {
                const item = [
                    this.getDate(r.date),
                    r.policyNumber,
                    `${(r.transactionAmount / 100).toFixed(2)}`,
                    `${(r.payable / 100).toFixed(2)}`,
                    `${(r.receivable / 100).toFixed(2)}`,
                    `${(r.settlement / 100).toFixed(2)}`,
                    `${(r.cumulativeTotal / 100).toFixed(2)}`,
                    r.roles,
                    r.currency,
                    r.insuredName,
                    r.certificateNumber,
                    r.invoiceNumber,
                    r.userId,
                    this.getDate(r.startDate),
                    this.getDate(r.expiryDate)
                ];
                const row ={
                    date: this.getDate(r.date),
                    policyNumber: r.policyNumber,
                    transactionAmount: `${(r.transactionAmount / 100).toFixed(2)}`,
                    payable: `${(r.payable / 100).toFixed(2)}`,
                    receivable: `${(r.receivable / 100).toFixed(2)}`,
                    settlement: `${(r.settlement / 100).toFixed(2)}`,
                    cumulativeTotal: `${(r.cumulativeTotal / 100).toFixed(2)}`,
                    roles: r.roles,
                    currency: r.currency,
                    insuredName: r.insuredName,
                    certificateNumber: r.certificateNumber,
                    invoiceNumber: r.invoiceNumber,
                    userId: r.userId,
                    startDate: this.getDate(r.startDate),
                    expiryDate: this.getDate(r.expiryDate)
                };

                this.data.push(item);
                this.rows.push(row);
                this.dataSource.data = this.rows;
            }
            this.data.push([""]);
            this.data.push([""]);
            this.data.push(["End"]);

            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 100);

        }, (error) => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
        });

    }

    getDate(date): string {
        return moment(date).format(environment.formatDate);
    }

    checkApplicable(r: any, i: any): boolean {
        if (!this.roleMap[r.date]) {
            this.roleMap[r.date] = [];
        }


        const listIdentifiers = this.authenticationService.getLinkedToLegalEntityIdentifiers();
        if (listIdentifiers.length > 0 && i.underwriter){
            if(Utils.getTpiIdentifier(listIdentifiers).tpiIdentifier === i.underwriter.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier) {
                this.roleMap[r.date].push("Underwriter");
                return true;
            }
        }
        if (listIdentifiers.length > 0 && i.admin){
            if(Utils.getTpiIdentifier(listIdentifiers).tpiIdentifier === i.admin.tpiIdentifier.tpiIdentifier) {
                this.roleMap[r.date].push("Admin");
                return true;
            }
        }
        /*if (this.brokerService.selectedUnderwriterLegalEntityIdentifier && i.underwriter &&
            this.brokerService.selectedUnderwriterLegalEntityIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier === i.underwriter.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.date].push("Underwriter");
            return true;
        }*/
        if (this.agentService.agent.brokerIdentifiers[0] && i.broker &&
            (this.agentService.agent.brokerIdentifiers[0] as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier ===
            i.broker.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier) {
            this.roleMap[r.date].push("Broker");
            return true;
        }
        if (this.agentService.agentIdentifier && i.sellingAgent &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier as TpiIdentifier).tpiIdentifier === i.sellingAgent.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.date].push("Selling Agent");
            return true;
        }
        if (this.agentService.agentIdentifier && i.printingAgent &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier as TpiIdentifier).tpiIdentifier === i.printingAgent.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.date].push("Printing Agent");
            return true;
        }
        if (this.agentService.agentIdentifier && i.paymentAgent &&
            (this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier as TpiIdentifier).tpiIdentifier === i.paymentAgent.legalEntityIdentifier.tpiIdentifier) {
            this.roleMap[r.date].push("Payment Provider");
            return true;
        }
        if (!this.brokerService.selectedUnderwriterLegalEntityIdentifier && this.agentService.agent &&
            (this.agentService.agent.legalEntityIdentifier as TpiIdentifier).tpiIdentifier === this.zambeziInsureIdentifier) {
            this.roleMap[r.date].push("10% Commission");
        }
        return false;

    }

    export(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report');
        XLSX.writeFile(wb, this.fileName);
    }

    onSubmit(): void {
        this.getRevenueSplit();
    }

}
