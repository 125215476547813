import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FormComponent } from "../form/form.component";
import { CountryComponent } from "../country/country.component";
import {
  BasicPhysicalAddress, BasicPostalAddress, CountryIdentifier, Address, GenericAddress, CountryDialingIdentifier,
  CountryDescriptionIdentifier, MalawiPhysicalAddress, Country
} from '@magnabc/tpi';
import { CountryService } from '../country/services/country.service';

declare const jQuery: any;
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html'
})
export class AddressComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() address: Address;
  @Input() type: string;
  @Input() title = "";
  @Input() tooltip;
  @Input() required = true;
  @Output() onAddress = new EventEmitter<Address>();
  @Output() onValid = new EventEmitter<void>();
  @Input() codeLabel = "COMMON.ADDRESS.LABEL_STREET";
  @Input() selectedCountry: Country;

  formGroup: UntypedFormGroup;
  lineOne: UntypedFormControl;
  lineTwo: UntypedFormControl;
  lineThree: UntypedFormControl;
  lineFive: UntypedFormControl;

  country: CountryIdentifier;

  @ViewChild('form', { static: true }) form: FormGroupDirective;
  @ViewChild(CountryComponent) countryComponent: CountryComponent;

  addressAlphaTwo: string;

  constructor(private translate: TranslateService, private translateParser: TranslateParser, private countryService: CountryService) {
    super(translate, translateParser);

  }

  ngOnInit(): void {

    if (this.address) {

      this.lineOne = new UntypedFormControl('', [
        Validators.maxLength(50)
      ]);
      this.lineTwo = new UntypedFormControl('', [
        Validators.maxLength(50)
      ]);
      this.lineThree = new UntypedFormControl('', [
        Validators.maxLength(50),
        Validators.pattern('^([^0-9]*)$')
      ]);
      this.lineFive = new UntypedFormControl('', [
        Validators.maxLength(5),
        Validators.pattern('^[A-Z0-9]+$')
      ]);

      const temp = this.address;
      setTimeout(() => {
        this.setAddress(temp);
      }, 200);

    } else {

      this.lineOne = new UntypedFormControl('', [
        Validators.maxLength(50)
      ]);
      this.lineTwo = new UntypedFormControl('', [
        Validators.maxLength(50)
      ]);
      this.lineThree = new UntypedFormControl('', [
        Validators.maxLength(50),
        Validators.pattern('^([^0-9]*)$')
      ]);
      this.lineFive = new UntypedFormControl('', [
        Validators.maxLength(5),
        Validators.pattern('^[A-Z0-9]+$')
      ]);

    }

    switch (this.type) {
      case 'BasicPhysicalAddress':
        this.address = new BasicPhysicalAddress();
        (this.address as BasicPhysicalAddress).addressIdentifier = "BasicPhysicalAddress";
        break;
      case 'BasicPostalAddress':
        this.address = new BasicPostalAddress();
        (this.address as BasicPostalAddress).addressIdentifier = "BasicPostalAddress";
        break;
      default:
        this.address = new GenericAddress();
        break;
    }

    if (this.required) {
      this.lineOne.setValidators([Validators.required, Validators.maxLength(50)]);
      this.lineThree.setValidators([Validators.required, Validators.maxLength(50), Validators.pattern('^([^0-9]*)$')]);
      this.lineFive.setValidators([Validators.required, Validators.maxLength(5), Validators.pattern('^[A-Z0-9]+$')]);
    }

    this.formGroup = new UntypedFormGroup({
      'lineOne': this.lineOne,
      'lineTwo': this.lineTwo,
      'lineThree': this.lineThree,
      'lineFive': this.lineFive
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('[data-toggle="tooltip"]').tooltip();
    });
  }

  onCountry(country): void {
    this.country = country;
  }

  onCountryValid(event): void {
    document.getElementById('form-submit-address-' + this.title).click();
  }

  ngOnDestroy(): void {
    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
  }

  submit(): void {
    this.countryComponent.submit();
    // document.getElementById('form-submit-address-' + this.title).click();
  }

  onCheckValidation(event): void {

    if (!this.form.invalid) {

      this.getAddress();

      this.onAddress.emit(this.address);
      this.onValid.emit();

    } else {

      const target = jQuery('form .ng-invalid:first');

      if (target) {
        jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
        target.focus();
      }

    }

  }

  getAddress(): Address {

    switch (this.type) {
      case 'BasicPhysicalAddress':

        (this.address as BasicPhysicalAddress).lineOne = this.lineOne.value;
        (this.address as BasicPhysicalAddress).lineTwo = this.lineTwo.value;
        (this.address as BasicPhysicalAddress).city = this.lineThree.value;
        (this.address as BasicPhysicalAddress).code = this.lineFive.value;
        (this.address as BasicPhysicalAddress).countryIdentifier = this.countryComponent.getCountryAsDescriptionIdentifier();
        (this.address as BasicPhysicalAddress).addressIdentifier = this.type;

        break;
      case 'BasicPostalAddress':

        (this.address as BasicPostalAddress).lineOne = this.lineOne.value;
        (this.address as BasicPostalAddress).lineTwo = this.lineTwo.value;
        (this.address as BasicPostalAddress).city = this.lineThree.value;
        (this.address as BasicPostalAddress).code = this.lineFive.value;
        (this.address as BasicPostalAddress).countryIdentifier = this.countryComponent.getCountryAsDescriptionIdentifier();
        (this.address as BasicPostalAddress).addressIdentifier = this.type;

        break;
    }

    return this.address;

  }

  setAddress(address: Address) {

    let lineOne = '';
    let lineTwo = '';
    let city = '';
    let code = '';
    let countryIdentifier = (address as any).countryIdentifier;

    // @class added quick fix
    if (address instanceof BasicPhysicalAddress ||
      address["@class"] === "za.co.magnabc.tpi.location.geographic.BasicPhysicalAddress") {
      lineOne = (address as BasicPhysicalAddress).lineOne;
      lineTwo = (address as BasicPhysicalAddress).lineTwo;
      city = (address as BasicPhysicalAddress).city;
      code = (address as BasicPhysicalAddress).code;
    } else if (address instanceof BasicPostalAddress ||
      address["@class"] === "za.co.magnabc.tpi.location.geographic.BasicPostalAddress") {
      lineOne = (address as BasicPostalAddress).lineOne;
      lineTwo = (address as BasicPostalAddress).lineTwo;
      city = (address as BasicPostalAddress).city;
      code = (address as BasicPostalAddress).code;
    } else if (address instanceof GenericAddress) {
      lineOne = (address as GenericAddress).lineOne;
      lineTwo = (address as GenericAddress).lineTwo;
      city = (address as GenericAddress).lineThree;
      code = (address as GenericAddress).lineFive;
      countryIdentifier = new CountryDialingIdentifier();
      countryIdentifier.description = "Legacy";
      countryIdentifier.alphaTwo = (address as GenericAddress).lineFour;
    } else if (address instanceof MalawiPhysicalAddress ||
      address["@class"] === "za.co.magnabc.tpi.location.geographic.MalawiPhysicalAddress") {
      lineOne = (address as MalawiPhysicalAddress).lineOne;
      lineTwo = (address as MalawiPhysicalAddress).lineTwo;
      city = (address as MalawiPhysicalAddress).city;

    }

    this.lineOne.setValue(lineOne);
    this.lineTwo.setValue(lineTwo);
    this.lineThree.setValue(city);
    this.lineFive.setValue(code);

    if (countryIdentifier || this.selectedCountry) {
      if (countryIdentifier) {
        if (countryIdentifier instanceof CountryDescriptionIdentifier ||
          countryIdentifier["@class"] === "za.co.magnabc.tpi.location.geographic.identification.CountryDescriptionIdentifier") {
          this.getSelectedCountry(countryIdentifier);
        } else if (countryIdentifier instanceof CountryDialingIdentifier ||
          countryIdentifier["@class"] === "za.co.magnabc.tpi.location.geographic.identification.CountryDialingIdentifier") {
          this.getSelectedCountry(countryIdentifier);
        }
      } else if (this.selectedCountry) {
        this.getSelectedCountry(this.selectedCountry);

      }
      if (this.countryComponent) {
        this.countryComponent.setCountry(this.country);
      }
    }

  }

  private getSelectedCountry(countryIdentifier: any) {
    this.country = this.countryService.getCountries().find((country) => {
      return country.description === countryIdentifier.description;
    });
  }

  isDirty() {
    return this.form.dirty || (this.countryComponent && this.countryComponent.isDirty());
  }
}
