import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {CountryIdentificationConfiguration} from '../../../../../models/country-identification-configuration.model';

export class ProvideCountryIdentificationConfigurationResponse extends Response {

    constructor() {
        super();
    }

    countryIdentificationConfiguration: CountryIdentificationConfiguration;
}
