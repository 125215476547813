import { DrivingCertificate } from "./driving-certificate.model";
import { LegalEntity } from "./legal-entity.model";
import { Occupation } from './occupation.model';

export class NaturalPerson extends LegalEntity {
  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.NaturalPerson";
  }
  public firstNames: string;
  public surname: string;
  public initials: string;
  public preferredName: string;
  public photo: string[] = [];
  public gender: string;
  public dateOfBirth: Date;
  public drivingCertificates: DrivingCertificate[] = [];
  public occupation: Occupation;
  public politicallyExposedPerson: boolean;
}
