import {Excess} from "./excess.model";
import {Money} from "../../../financial/models/money.model";

export class UniversalExcess extends Excess {

    description: string;
    percentage = 0;
    value: Money;

    constructor(description: string) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.UniversalExcess";
        this.description = description;
    }
}
