import { ValueType } from "./value-type.model";


export class SystemParameter {

    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.security.account.Account";
    }

    public name: string;
    public description: string;
    public displayable: boolean;
    public parameterType: string;
    public valueType: ValueType;
    public parameterValue: string;

}