import {Request} from "../../../../../../urdad/services/models/request.model"

export class InitialiseExtendInsurancePolicyRequest extends Request {
  constructor() {
    super();
  }

  public policyNumber: string;
  public orderItemNumber: string;
}
