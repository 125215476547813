import {Response} from '../../urdad/services/models/response.model';
import {IncidentUpdate} from './incident-update.model';

export class ScheduledMaintenance extends Response {
    constructor(){
       super();
    }

    public created_at: Date;
    public id: string;
    public impact: string;
    public incident_updates: IncidentUpdate[] = [];
    public monitoring_at: Date;
    public name: string;
    public page_id: string;
    public resolved_at: string;
    public scheduled_for: Date;
    public scheduled_until: Date;
    public shortlink: string;
    public status: string;
    public updated_at: Date;

}
