import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    BrokerLegalEntityIdentifier,
    CertificateType,
    ComprehensiveInsurancePolicyOrderItem,
    DuplicateInsuranceCertificateCreatedEvent,
    ExtensionInsurancePolicyOrderItem,
    FinaliseIssueInsuranceCertificateRequest,
    FinaliseIssueInsuranceCertificateResponse,
    InitialiseIssueInsuranceCertificateRequest,
    InitialiseIssueInsuranceCertificateResponse,
    InsuranceCategory,
    InsuranceCertificateCreatedEvent,
    InsuranceCertificatePolicyNumberCriteria,
    InsurancePolicy,
    InsurancePolicyItem,
    InsurancePolicyNumberIdentifier,
    MotorInsuranceCertificate,
    MotorInsurancePolicyItem,
    MotorInsurancePolicyOrderCertificateCriteria,
    Order,
    OrderIdentifier,
    OrderItemNumberIdentifier,
    OrderOrderItemNumberIdentifier,
    ProvideInsuranceCertificatesRequest,
    ProvideInsuranceCertificatesResponse,
    ProvideInsurancePolicyItemRequest,
    ProvideInsurancePolicyItemResponse,
    PublishEventRequest,
    RenderedDocumentType,
    ThirdPartyInsurancePolicyOrderItem,
    UnderwriterIdentifier,
    UnMarshallerService,
} from '@magnabc/tpi';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { environment } from '../../../../../../environments/environment';
import { AgentManagerService } from '../../../../../app.services/managers/agent-manager/agent-manager.service';
import {
    InsuranceCertificateFinalisationIssuanceManagerService,
} from '../../../../../http.services/retail/insurance/certificate/insurance-certificate-finalisation-issuance-manager/insurance-certificate-finalisation-issuance-manager.service';
import {
    InsuranceCertificateProviderService,
} from '../../../../../http.services/retail/insurance/certificate/insurance-certificate-provider/insurance-certificate-provider.service';
import {
    InsuranceCertificateInitialisationIssuanceManagerService,
} from '../../../../../http.services/retail/insurance/certificate/insurance-certificate-initialisation-issuance-manager/insurance-certificate-initialisation-issuance-manager.service';
import {
    InsurancePolicyProviderService,
} from '../../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service';
import {
    RetailProfileFinalisationManagerService,
} from '../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import {
    AuthenticationService,
} from '../../../../../app.services/managers/authentication/authentication.service';
import {
    SystemParameterProviderService,
} from '../../../../../http.services/system/system-parameter-provider/system-parameter-provider.service';
import { EventPublisherService } from '../../../../../http.services/urdad/events/services/http/event-publisher.service';
import {
    SupervisorOverrideDialogComponent,
} from '../../../../security/supervisor-override-dialog/supervisor-override-dialog.component';
import { FormComponent } from '../../../../shared/form/form.component';
import { ToastComponent } from '../../../../shared/toast/toast.component';
import { Utils } from '../../../../shared/Utils/Utils';
import { PrintService, PDFPrintModel, PDFDisplayModel } from '../../../../../app.services/common/printing/print.service';
import { VerhoeffChecksumService } from './service/verhoeff-checkssum.service';
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
    selector: 'app-issue-certificates',
    templateUrl: './issue-certificates.component.html',
    styleUrls: ['./issue-certificates.component.scss']
})
export class IssueCertificatesComponent extends FormComponent implements OnInit, OnDestroy {

    @Input() order: Order;

    rows: InsurancePolicyItem[] = [];
    columns = [
        { plateNumber: 'plateNumber'},
        { name: 'name' },
        { coveragePeriod: 'coveragePeriod' },
        { insuranceType: 'manufactureDate' },
        { insuranceVehicleCategory: 'insuranceVehicleCategory' },
    ];

    insuranceCategory = InsuranceCategory;
    activeToast: ActiveToast<any>;
    temp: InsurancePolicyItem[] = [];

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    // orderItems: {[id: string]: OrderItem} = {};
    certificates: {[id: string]: MotorInsuranceCertificate} = {};
    loadingCertificates = true

    previewDocument = false;
    documentToPreview: any;
    documentToPrint: any;

    selectedInsurancePolicyItem: InsurancePolicyItem;
    dialogRef: MatDialogRef<any, any>;
    issueDate: Date;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private toastr: ErrorToastService,
                private insuranceCertificateProviderService: InsuranceCertificateProviderService,
                private insurancePolicyProviderService: InsurancePolicyProviderService,
                private unMarshallerService: UnMarshallerService,
                private dialog: MatDialog,
                private insuranceCertificateInitialisationIssuanceManagerService :InsuranceCertificateInitialisationIssuanceManagerService,
                public authenticationService: AuthenticationService,
                private systemParameterService : SystemParameterProviderService,
                private printService: PrintService,
                private agentService: AgentManagerService) {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.providePolicyItems(this.order);
        this.onToggleSearch();
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    onSelect(event): void {}

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        this.rows = this.temp.filter(function(d) {
            return (d as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber.replace(/ /g, "").toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.table.offset = 0;
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    provideCertificate(insurancePolicy: InsurancePolicy): void {

        const provideInsuranceCertificatesRequest = new ProvideInsuranceCertificatesRequest();
        const insuranceCertificatePolicyNumberCriteria = new InsuranceCertificatePolicyNumberCriteria();
        const insurancePolicyNumberIdentifier = new InsurancePolicyNumberIdentifier();
        insurancePolicyNumberIdentifier.number = insurancePolicy.number;
        insuranceCertificatePolicyNumberCriteria.insurancePolicyIdentifiers.push(insurancePolicyNumberIdentifier);
        provideInsuranceCertificatesRequest.criteria = insuranceCertificatePolicyNumberCriteria;

        this.loadingCertificates = true;
        this.insuranceCertificateProviderService.provideInsuranceCertificates(provideInsuranceCertificatesRequest).subscribe((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProvideInsuranceCertificatesResponse) as ProvideInsuranceCertificatesResponse;
            for (const insuranceCertificates of response.motorInsuranceCertificates) {
                this.certificates[insurancePolicy.number] = insuranceCertificates;
            }
            this.loadingCertificates = false;
        });

    }

    providePolicyItems(order: Order): void {

        this.rows = []
        this.temp = []

        const promises = []

        for (const orderItem of order.orderItems) {
            if(orderItem.status !== "FULFILLED")
            {
                let underwriterIdentifier: UnderwriterIdentifier
                if (orderItem instanceof ThirdPartyInsurancePolicyOrderItem || orderItem['@class'] === new ThirdPartyInsurancePolicyOrderItem()['@class']) {
                underwriterIdentifier = (orderItem as ThirdPartyInsurancePolicyOrderItem).orderThirdPartyInsuranceProduct.underwriterIdentifier
                } else if (orderItem instanceof ComprehensiveInsurancePolicyOrderItem || orderItem['@class'] === new ComprehensiveInsurancePolicyOrderItem()['@class']) {
                underwriterIdentifier = (orderItem as ComprehensiveInsurancePolicyOrderItem).orderComprehensiveInsuranceProduct.underwriterIdentifier
                } else if (orderItem instanceof ExtensionInsurancePolicyOrderItem || orderItem['@class'] === new ExtensionInsurancePolicyOrderItem()['@class']) {
                underwriterIdentifier = (orderItem as ExtensionInsurancePolicyOrderItem).orderExtensionInsuranceProduct.underwriterIdentifier;
                }
                promises.push(this.providePolicy(new OrderOrderItemNumberIdentifier(order.number, new OrderItemNumberIdentifier(orderItem.number)), underwriterIdentifier))
            }
        }

        Promise.all(promises).then((results) => {

            const insurancePolicies = []
            for (const result of results) {
                for (const insurancePolicy of result) {
                    if (this.checkIsPolicyActive(insurancePolicy.motorInsuranceVehiclePolicy.expiryDate)) {
                        insurancePolicies.push(insurancePolicy)
                    }
                }
            }

            this.rows = insurancePolicies
            this.temp = this.rows

        })

    }

    checkIsPolicyActive(expiryDate) {
        return moment(new Date()).isBefore(moment(this.addExtraDay(expiryDate)))
    }

    addExtraDay(date) {
        const newDate = new Date(date)
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1, 0, 0, 0);
   }

    providePolicy(orderIdentifier: OrderIdentifier, underwriterIdentifier: UnderwriterIdentifier): Promise<InsurancePolicyItem[]> {

        return new Promise<InsurancePolicyItem[]>((resolve) => {

            const motorInsurancePolicyOrderCertificateCriteria = new MotorInsurancePolicyOrderCertificateCriteria()
            motorInsurancePolicyOrderCertificateCriteria.orderIdentifier = orderIdentifier

            const provideInsurancePolicyItemRequest = new ProvideInsurancePolicyItemRequest();
            provideInsurancePolicyItemRequest.underwriterIdentifier = underwriterIdentifier
            provideInsurancePolicyItemRequest.criteria = motorInsurancePolicyOrderCertificateCriteria
            provideInsurancePolicyItemRequest.batchNumber = -1
            provideInsurancePolicyItemRequest.batchSize = -1

            this.insurancePolicyProviderService.provideInsurancePolicyItem(provideInsurancePolicyItemRequest).then((httpResponse) => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePolicyItemResponse) as ProvideInsurancePolicyItemResponse);
                    const insurancePolicyItems = []
                    for (const insurancePolicyItemGrouping of response.insurancePolicyItemGroupings) {
                        for (const insurancePolicyItem of insurancePolicyItemGrouping.insurancePolicyItems) {
                            (insurancePolicyItem as any).insurancePolicyNumber = insurancePolicyItemGrouping.insurancePolicyNumber
                            insurancePolicyItems.push(insurancePolicyItem)
                        }
                    }
                    resolve(insurancePolicyItems)
                }
            });

        })

    }

    onPreview(event, insurancePolicyItem: InsurancePolicyItem): boolean {

        if (event) {
            event.stopPropagation();
        }

        this.selectedInsurancePolicyItem = insurancePolicyItem;

        this.startLoad();
        this.getRenderedDocument(insurancePolicyItem, (response: InitialiseIssueInsuranceCertificateResponse) => {
            this.stopLoad();

            this.issueDate = response.issueDate;
            for(const pdfDocument of response.renderedDocuments) {
                if (pdfDocument.type === RenderedDocumentType.INSURANCE_CERTIFICATE_PREVIEW ||
                    pdfDocument.type === RenderedDocumentType.YELLOW_CARD_INSURANCE_CERTIFICATE_PREVIEW) {
                        this.documentToPreview = (pdfDocument as any).encodedPdf;
                } else if (pdfDocument.type === RenderedDocumentType.INSURANCE_CERTIFICATE_PRINT ||
                    pdfDocument.type === RenderedDocumentType.YELLOW_CARD_INSURANCE_CERTIFICATE_PRINT) {
                        this.documentToPrint = (pdfDocument as any).encodedPdf;
                }
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, (document.getElementById('canvas') as HTMLCanvasElement), () => {});
            });
        });

        return false;

    }

    onPrintPreview(): void {
        this.displayPDF(this.documentToPrint, (document.getElementById('canvas-print') as HTMLCanvasElement), () => {
            setTimeout(() => {
                this.printDocument();
            }, 100);
        });
    }

    getRenderedDocument(insurancePolicyItem: InsurancePolicyItem, callback): void {
        let documentTypes: RenderedDocumentType[] = [];
        if (insurancePolicyItem instanceof MotorInsurancePolicyItem) {
            if (insurancePolicyItem.insuranceCategory === InsuranceCategory.YELLOWCARD) {
                documentTypes = [RenderedDocumentType.YELLOW_CARD_INSURANCE_CERTIFICATE_PREVIEW, RenderedDocumentType.YELLOW_CARD_INSURANCE_CERTIFICATE_PRINT];
            }
            else {
                documentTypes = [RenderedDocumentType.INSURANCE_CERTIFICATE_PREVIEW, RenderedDocumentType.INSURANCE_CERTIFICATE_PRINT];
            }
        }

        const agentIdentifier = this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier;
        const policyBrokerIdentifier =  ((insurancePolicyItem as MotorInsurancePolicyItem).brokerIdentifier as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier;
        const initialiseIssueInsuranceCertificateRequest = new InitialiseIssueInsuranceCertificateRequest();

        initialiseIssueInsuranceCertificateRequest.types = documentTypes;
        initialiseIssueInsuranceCertificateRequest.insurancePolicyNumber = (insurancePolicyItem as any).insurancePolicyNumber;
        initialiseIssueInsuranceCertificateRequest.userId = agentIdentifier.tpiIdentifier;
        initialiseIssueInsuranceCertificateRequest.legalEntityIdentifier = policyBrokerIdentifier;
        initialiseIssueInsuranceCertificateRequest.motorInsurancePolicyItem = JSON.parse(JSON.stringify((insurancePolicyItem)))
        delete (initialiseIssueInsuranceCertificateRequest.motorInsurancePolicyItem as any).insurancePolicyNumber

        this.insuranceCertificateInitialisationIssuanceManagerService.initialiseIssueInsuranceCertificate(initialiseIssueInsuranceCertificateRequest).subscribe((data) => {
            callback(data.body);
        }, (error) => {
            this.stopLoad();
            this.translate.get('TOAST.CERTIFICATE').subscribe((res: any) => {
                this.activeToast = this.toastr.success(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        });

    }

    popups() {

        this.translate.get('TOAST.POPUPS').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 10000,
                progressBar: true
            });
            this.activeToast.portal.instance.imagePath = '/assets/images/common/popups.png';
        });

    }

    displayPDF(base64, canvas, complete) {
        const pdfDisplayData: PDFDisplayModel = {
            canvas,
            base64,
            complete,
            styleWidth: '50%',
            scale: 8
        };
        this.printService.displayPDF(pdfDisplayData);
    }

    printDocument(): void {
        this.popups();

        setTimeout(() => {
            this.openAcknowledgePrintSuccessful();
        }, 1000);

        const dataUrl = (document.getElementById('canvas-print') as any).toDataURL();
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: 1,
            dataUrl,
            features: 'top=0,height=1024px,width=800px',
            openBlank: false
        };
        this.printService.printDocument(pdfPrintData);
    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPrint = null;

    }

    openAcknowledgePrintSuccessful(): void {

        this.dialogRef = this.dialog.open(DialogAcknowledgePrintSuccessfulComponent, {
            width: '96%',
            panelClass: 'padded-modal',
            maxWidth: '800px',
            position: {top: "2%"},
            disableClose: true
        });

        this.dialogRef.afterClosed().subscribe(result => {

            switch (result) {
                case 0:
                    // Cancelled
                    this.previewDocument = false;
                    break;
                case 1:
                    this.dialog.open(SupervisorOverrideDialogComponent, {
                        panelClass: 'padded-modal'
                    }).afterClosed().subscribe((overrideResponse) => {
                        if(overrideResponse){
                            this.printDocument();
                        } else {
                            this.openAcknowledgePrintSuccessful();
                        }
                    });
                    break;
                case 2:
                    this.openCaptureDialog();
                    this.previewDocument = false;
                    break;
            }

        });
    }

    openCaptureDialog(): void {

        this.dialogRef = this.dialog.open(DialogCaptureCertificateNumberComponent, {
            width: '96%',
            panelClass: 'padded-modal',
            maxWidth: '800px',
            position: {top: "2%"},
            disableClose: true,
            data: { selectedInsurancePolicyItem: this.selectedInsurancePolicyItem,
                    certificateType: CertificateType.NEW,
                    insuracePolicyNumber:  (this.selectedInsurancePolicyItem as any).insurancePolicyNumber,
                    issueDate: this.issueDate}
        });

        this.dialogRef.afterClosed().subscribe(result => {

        });

    }

}

@Component({
    selector: 'app-dialog-capture-certificate-number',
    templateUrl: 'dialog-capture-certificate-number.html',
    styleUrls: ['./issue-certificates.component.scss']
})
export class DialogCaptureCertificateNumberComponent extends FormComponent {

    formGroup: UntypedFormGroup;
    identifier: UntypedFormControl;
    showMessage = false;
    showMessageCertAlreadyExist = false;
    selectedInsurancePolicyItem: InsurancePolicyItem;
    certificateType: CertificateType;
    insuracePolicyNumber:string
    issueDate: Date;

    activeToast: ActiveToast<any>;
    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(public dialogRef: MatDialogRef<DialogCaptureCertificateNumberComponent>,
                @Inject(MAT_DIALOG_DATA) data: any,
                private translate: TranslateService,
                private agentService: AgentManagerService,
                private toastr: ToastrService,
                private verhoeffChecksumService: VerhoeffChecksumService,
                private translateParser: TranslateParser,
                private unMarshallerService: UnMarshallerService,
                private authenticationService: AuthenticationService,
                private insuranceCertificateFinalisationIssuanceManagerService: InsuranceCertificateFinalisationIssuanceManagerService,
                private eventPublisherService :EventPublisherService,) {
        super(translate, translateParser);

        this.selectedInsurancePolicyItem = data.selectedInsurancePolicyItem
        this.certificateType = data.certificateType
        this.insuracePolicyNumber = data.insuracePolicyNumber
        this.issueDate = data.issueDate

        this.identifier = new UntypedFormControl('', [
            Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[0-9]*$")
        ]);
        this.formGroup = new UntypedFormGroup({
            identifier: this.identifier,
        });

    }

    checkInputClicked() {
        this.showMessage = false;
        this.showMessageCertAlreadyExist = false;
    }

    onCheckValidation(event): void {

        this.showMessage = false

        if (!this.form.invalid) {

            let certificateString = String(this.identifier.value);
            let certificateNum = Array.from(certificateString.substring(0,certificateString.length - 1));
            let checkdigit = this.verhoeffChecksumService.generate(certificateNum);

            if(this.verhoeffChecksumService.validate(Array.from(certificateString)) && checkdigit === parseInt(certificateString.substring(certificateString.length - 1,certificateString.length))) {
                this.finaliseIssueThirdPartyInsuranceCertificate(String(this.identifier.value));
            } else {
                this.showMessage = true
            }

        }
    }

    finaliseIssueThirdPartyInsuranceCertificate(certificateNumber: string) {

        const finaliseIssueInsuranceCertificateRequest = new FinaliseIssueInsuranceCertificateRequest();
        finaliseIssueInsuranceCertificateRequest.insurancePolicyNumber = this.insuracePolicyNumber;
        finaliseIssueInsuranceCertificateRequest.agentIdentifier = this.agentService.agentIdentifier;
        finaliseIssueInsuranceCertificateRequest.certificateNumber = certificateNumber;
        finaliseIssueInsuranceCertificateRequest.userId = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;
        finaliseIssueInsuranceCertificateRequest.tpiVehicleIdentifier = (this.selectedInsurancePolicyItem as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier;
        finaliseIssueInsuranceCertificateRequest.certificateType = this.certificateType;
        delete (this.selectedInsurancePolicyItem as any).insurancePolicyNumber
        finaliseIssueInsuranceCertificateRequest.motorInsurancePolicyItem = (this.selectedInsurancePolicyItem as MotorInsurancePolicyItem)
        finaliseIssueInsuranceCertificateRequest.issueDate = this.issueDate;

        this.startLoad();
        this.insuranceCertificateFinalisationIssuanceManagerService
            .finaliseIssueInsuranceCertificate(finaliseIssueInsuranceCertificateRequest).then((httpResponse) => {
            this.stopLoad();
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, FinaliseIssueInsuranceCertificateResponse) as FinaliseIssueInsuranceCertificateResponse);

                const publishEventRequest = new PublishEventRequest();
                if(this.certificateType === CertificateType.NEW){
                    const insuranceCertificateCreatedEvent = new InsuranceCertificateCreatedEvent();
                    insuranceCertificateCreatedEvent.motorInsuranceCertificate = response.motorInsuranceCertificate;
                    insuranceCertificateCreatedEvent.service = "finaliseIssueInsuranceCertificate";
                    insuranceCertificateCreatedEvent.serviceProviderType = "IssueCertificatesComponent";

                    publishEventRequest.event = insuranceCertificateCreatedEvent;
                }else{
                    const duplicateInsuranceCertificateCreatedEvent = new DuplicateInsuranceCertificateCreatedEvent();
                    duplicateInsuranceCertificateCreatedEvent.motorInsuranceCertificate = response.motorInsuranceCertificate;
                    duplicateInsuranceCertificateCreatedEvent.service = "finaliseIssueInsuranceCertificate";
                    duplicateInsuranceCertificateCreatedEvent.serviceProviderType = "IssueDuplicateCertificatesComponent";

                    publishEventRequest.event = duplicateInsuranceCertificateCreatedEvent;
                }

                this.eventPublisherService.publishEvent(publishEventRequest);
            }
            (this.selectedInsurancePolicyItem as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.certificateNumber = certificateNumber
            this.toast();
            this.dialogRef.close();
        }, (error) => {
            this.stopLoad();
            this.translate.get('TOAST.CERTIFICATE').subscribe((res: any) => {
                this.activeToast = this.toastr.success(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        });
    }

    toast() {

        this.translate.get('TOAST.CERTIFICATE_CREATED').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true
            });
        });

    }

}


@Component({
    selector: 'app-dialog-acknowledge-print-successful',
    templateUrl: 'dialog-acknowledge-print-successful.html',
    styleUrls: ['./issue-certificates.component.scss']
})
export class DialogAcknowledgePrintSuccessfulComponent {

    constructor(public dialogRef: MatDialogRef<DialogAcknowledgePrintSuccessfulComponent>) {

    }

    onCaptureNumber(): void {
        this.dialogRef.close(2);
    }

    onRePrint(): void {
        this.dialogRef.close(1);
    }

    onCancel(): void {
        this.dialogRef.close(0);
    }

}
