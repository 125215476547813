import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DepositData} from '../../account-transaction/account-statements/account-statements.component'

@Component({
    selector: 'deposit-dialog',
    templateUrl: './deposit-dialog.component.html',
  })
  export class DepositDialogComponent {

    constructor(private dialogRef:MatDialogRef<DepositDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: DepositData []){ }

    closeDialog(){
    this.dialogRef.close();
    }
  }
