import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";

export class SubscriptionsServiceGroupTypeLegalEntityCriteria extends Criteria {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionsServiceGroupTypeLegalEntityCriteria";
    }

    public serviceGroupType: string [] =[];
    public legalEntityIdentifier: LegalEntityIdentifier

}