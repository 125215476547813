import {Request} from "../../../../../../../urdad/services/models/request.model";
import {ServiceGroup} from '../../../../../models/service-group.model';

export class FinaliseCreateServiceGroupRequest extends Request {
    constructor() {
        super();
    }

    public serviceGroup: ServiceGroup;
}
