import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {InitialiseCountryCheckoutRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class CountryCheckoutInitialisationManagerService {

  basePath = '/malawi/retail/checkout/countrycheckoutinitialisationmanager';

  constructor(private http: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  initialiseCountryCheckout(initialiseCountryCheckoutRequest: InitialiseCountryCheckoutRequest): Promise<any> {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/initialisecountrycheckout';
      const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseCountryCheckoutRequest);
      return this.http.post<any>(path, jsonRequest, this.options).toPromise();
  }
}
