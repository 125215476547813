import { Request } from '../../../../../../../urdad/services/models/request.model';
import { RetailProfileIdentifier } from '../../../../../profile_api';

export class FinaliseSyncVehicleRequest extends Request{

    constructor() {
        super();
    }

    public retailProfileOwnerIdentifier: string;
    public syncedVehicles: number;
    public batchSize: number;
}
