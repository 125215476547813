import {Component, OnDestroy, ViewChild} from "@angular/core"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {ActivatedRoute, Router} from "@angular/router"
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component"
import {environment} from "../../../../../../environments/environment"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {
  RetailProductsDetailsComponent
} from "../../../../../view.components/retail/insurance/insurance-product-manager/create-product-details/product-details/retail.products.details.component"
import {
  RetailProductsTemplateComponent
} from "../../../../../view.components/retail/insurance/insurance-product-manager/product-template/retail.products.template.component"
import {
  RetailProductsPriceComponent
} from "../../../../../view.components/retail/insurance/insurance-product-manager/product-price/retail.products.price.component"
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard"
import {Location, LocationStrategy} from "@angular/common"
import {InsuranceCategory, YCInsuranceProduct,} from "@magnabc/tpi"
import {
  InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {
  UnderwriterInformationProviderService
} from "../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {Title} from '@angular/platform-browser'
import {
  ProductDetailsEnum
} from "../../../../../view.components/retail/insurance/insurance-product-manager/create-product-details/product-details.enum"
import {Subscription} from "rxjs"
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
    templateUrl: './create-product-details-page.component.html',
    styleUrls: ['./create-product-details-page.component.scss']
})
export class CreateProductDetailsPageComponent extends FormComponent implements OnDestroy, IStepComponent {

    backPressed: boolean;
    steps = [];
    currentStep = 0;
    breadcrumbStep = 0;
    insuranceProductInitialised = false;
    insuranceCategory = InsuranceCategory;
    refreshProduct = false;
    ycProduct = false;

    @ViewChild(RetailProductsTemplateComponent) templateGridComponent: RetailProductsTemplateComponent;
    @ViewChild(RetailProductsDetailsComponent) detailsComponent: RetailProductsDetailsComponent;
    @ViewChild(RetailProductsPriceComponent) priceComponent: RetailProductsPriceComponent;

    currentState: ProductDetailsEnum = ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_TYPES;

    private activeToast: ActiveToast<any>;
    private subscriptions = new Subscription();
    private editing = false;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                public underwriterInformationProviderService: UnderwriterInformationProviderService,
                public insuranceProductManagerService: InsuranceProductManagerService,
                locationStrategy: LocationStrategy,
                private activatedRoute: ActivatedRoute,
                private loacation: Location,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
        title.setTitle(`Create Product - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        const subscription = this.activatedRoute.params.subscribe((data) => {
            const productNumber = data['number'];
            if (productNumber !== 'new') {
                this.editing = true;
            }
        });
        this.subscriptions.add(subscription);

        if (!this.editing) {
            this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_DETAILS_TYPES").subscribe((response) => {
                this.steps.push({ id: 0, name: response, icon: "fa-list", borderColor: "#1592E6" });
            });
        } else {
            if(this.editing){
              this.breadcrumbStep = -1;
            }
            this.onCurrentStateChange(ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO);
        }

        const idAdd = this.editing ? 0 : 1;
        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_DETAILS").subscribe((response) => {
            this.steps.push({ id: 0 + idAdd, name: response, icon: "fa-indent", borderColor: "#3171c3" });
        });
        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_ASSIGN_BROKERS").subscribe((response) => {
            this.steps.push({ id: 1 + idAdd, name: response, icon: "fa-users", borderColor: "#3f5baa" });
        });
        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_CONDITIONS").subscribe((response) => {
            this.steps.push({ id: 2 + idAdd, name: response, icon: "fa-file-text-o", borderColor: "#3f5baa" });
        });

        this.initProduct().then(() => { });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onCurrentStateChange(state: ProductDetailsEnum) {
        this.currentState = state;
        this.breadcrumbStep++;
    }

    initProduct(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.startLoad();
            this.insuranceProductManagerService.initProduct().then(() => {
                this.stopLoad();
                this.insuranceProductInitialised = true;
                resolve();
            });
        })

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    onSubmit(event): void {

        switch (this.currentState) {
            case ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_TYPES:
                this.detailsComponent.submit();
                return;
            case ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO:
                this.detailsComponent.submit();
                return;
            case ProductDetailsEnum.ASSIGN_BROKERS:
                this.detailsComponent.assignBrokers();
                return;
            case ProductDetailsEnum.VIEW_CONDITIONS:
                this.detailsComponent.submit();
                return;
            case ProductDetailsEnum.VIEW_CONDITIONS:
                return;
            default: {
                break;
            }

        }

    }

    onBack(_): void {
        if((this.editing && this.breadcrumbStep === 1) || this.breadcrumbStep === 0){
            this.loacation.back();
            return;
        }

        if (this.breadcrumbStep > 0) {
            this.breadcrumbStep--;
        }
        switch (this.currentState) {
            case ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO: {
                this.currentState = ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_TYPES;
                break;
            }
            case ProductDetailsEnum.ASSIGN_BROKERS: {
                this.currentState = ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO;
                break;
            }
            case ProductDetailsEnum.VIEW_CONDITIONS: {
                this.currentState = ProductDetailsEnum.ASSIGN_BROKERS;
                break;
            }
            case ProductDetailsEnum.PRODUCT_OVERVIEW: {
                this.currentState = ProductDetailsEnum.VIEW_CONDITIONS;
                break;
            }
        }
    }

    onNext(event): void {
        switch (this.currentState) {
            case ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_TYPES: {
                this.onCurrentStateChange(ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO);
                break;
            }
            case ProductDetailsEnum.CAPTURE_PRODUCT_DETAILS_INFO: {
                this.onCurrentStateChange(ProductDetailsEnum.ASSIGN_BROKERS);
                break;
            }
            case ProductDetailsEnum.ASSIGN_BROKERS: {
                this.onCurrentStateChange(ProductDetailsEnum.VIEW_CONDITIONS);
                break;
            }
            case ProductDetailsEnum.VIEW_CONDITIONS: {
                this.insuranceProductInitialised = false;
                this.insuranceProductManagerService.refreshProduct = false;
                const subscription = this.activatedRoute.params.subscribe((data) => {
                    const productNumber = data['number'];
                    this.insuranceProductManagerService.refreshProduct = false;
                    this.router.navigate([`retail/insurance/product-overview/${productNumber}`]);
                });
                this.subscriptions.add(subscription);
                break;
            }
        }

    }

    onProduct(product): void {
        this.insuranceProductManagerService.product = product;
        if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
            this.ycProduct = true;
        }
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    toast(response) {

        this.translate.get('TOAST.CREATE_PRODUCT').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.insuranceProductManagerService.product.name }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.initProduct().then(() => {
                            this.currentStep = 0;
                            this.activeToast.portal.destroy();
                            window.scrollTo(0, 0);
                        })
                        break;
                    case ToastComponent.SECONDARY:
                        this.router.navigate(['/broker/products']);
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.router.navigate(['/broker/products']);
            });
        });

    }

    getDescription(object: any): string {
        return object.description;
    }

}
