import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {MarshallerService, ProvideInsuranceCertificatesRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class InsuranceCertificateProviderService {

    basePath = '/retail/insurance/certificate/insurancecertificateprovider';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideInsuranceCertificates(provideInsuranceCertificatesRequest: ProvideInsuranceCertificatesRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancecertificates';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideInsuranceCertificatesRequest);
        return this.http.post(path, jsonRequest, this.options);
    }
}
