import { Money } from "../../../../financial/models/money.model";
import { Item } from "../../../checkout/models/item.model";
import { SnapshotVehicle } from "../../../checkout/models/snapshot-vehicle.model";
import { ComprehensiveInsuranceProductCartItem } from "./comprehensive-insurance-product-cart-item.model";

export class ProposalPolicyItem extends Item {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalPolicyItem";
    }

    public vehicleValue: Money;
    public startDate: Date;
    public expiryDate: Date;
    public annualPricePercentage: number;
    public periodPricePercentage: number;
    public additionalPrice: Money;
    public ncdPercentage: number;
    public alarmImmobiliser: boolean;
    public comprehensiveInsuranceProductCartItem: ComprehensiveInsuranceProductCartItem;
    public snapshotVehicle: SnapshotVehicle;
    public vehicleImages: string[] = [];
    public sellerBranch: string;
}
