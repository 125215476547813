import {Response} from '../../../../../../../urdad/services/models/response.model';
import {Product} from "../../../../../../products/models/product.model";

export class ProvideInsuranceProductInformationResponse extends Response{

    constructor(){
        super();
    }

    public products: Product[];
}
