import { ServiceGroupIdentifier } from "./service-group-identifier.model";

export class ServiceGroupNameIdentifier extends ServiceGroupIdentifier {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.identification.ServiceGroupNameIdentifier";
    }

    public name: string;

}