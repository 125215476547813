import {Component, OnInit} from '@angular/core'
import {ActivatedRoute} from "@angular/router"
import {Title} from '@angular/platform-browser'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;

@Component({
    selector: 'app-legal',
    templateUrl: './legal-page.component.html',
    styleUrls: ['./legal-page.component.scss']
})
export class LegalPageComponent implements OnInit {

    currentTab = 'terms-and-conditions';

    constructor(private route: ActivatedRoute,
                private title: Title,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Legal - ${runtimeConfigurationService.title}`);
    }

    ngOnInit() {

        this.route.fragment.subscribe(fragment => {
            if (fragment) {
                this.onTabSelected(fragment);
            } else {
                this.onTabSelected(this.currentTab);
            }
        });

    }

    onTabSelected(tab): void {
        this.currentTab = tab;
        jQuery(`#${tab}`).tab('show');
    }

}
