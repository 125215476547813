import {Location, LocationStrategy} from '@angular/common'
import {Component, OnInit, ViewChild} from '@angular/core'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {Title} from '@angular/platform-browser'
import {
  BrokerLegalEntityIdentifier,
  BrokerUnderwriterCriteria,
  CertificateType,
  CountryDescriptionIdentifier,
  Credentials,
  Criteria,
  FinaliseCancelInsurancePolicyRequest,
  InitialiseCancelInsurancePolicyRequest,
  InitialiseIssueInsuranceCertificateRequest,
  InitialiseIssueInsuranceCertificateResponse,
  InsurancePolicy,
  InsurancePolicyItem,
  InsurancePolicyLegalEntityCriteria,
  InsurancePolicyNumberCriteria,
  InsurancePolicyNumberVehicleCriteria,
  InsurancePolicySummary,
  InsurancePolicyVehicleCriteria,
  InsurancePolicyVehicleSummary,
  LegalEntity,
  LegalEntityDescriptionIdentifier,
  MotorInsurancePolicy,
  MotorInsurancePolicyItem,
  NationalIdentityNumber,
  OrderOrderItemNumberIdentifier,
  PassportNumber,
  ProvideInsurancePoliciesRequest,
  ProvideInsurancePoliciesResponse,
  ProvideInsurancePolicySummaryRequest,
  ProvideInsurancePolicySummaryResponse,
  ProvideMotorInsuranceVehiclePolicyRequest,
  ProvideMotorInsuranceVehiclePolicyResponse,
  ProvideRefundSummaryRequest,
  ProvideRefundSummaryResponse,
  ProvideRetailProfileInformationRequest,
  ProvideRetailProfileInformationResponse,
  ProvideRetailProfileVehicleInformationRequest,
  ProvideRetailProfileVehicleInformationResponse,
  QueryVehicleInformationRequest,
  RefundOrderItemNumberStatusCriteria,
  RefundPolicyNumberCriteria,
  RefundStatus,
  RefundSummary,
  RegistrationNumber,
  RenderedDocumentType,
  RetailProfile,
  RetailProfileLegalEntityCriteria,
  RetailVehicle,
  TpiIdentifier,
  UnderwriterLegalEntityIdentifier,
  UnMarshallerService,
  VehicleIdentificationNumber,
  VehicleProviderCriteria,
} from '@magnabc/tpi'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import * as moment from 'moment'
import {ActiveToast, ToastrService} from 'ngx-toastr'

import {environment} from '../../../../../../environments/environment'
import {
  QueryJudicialPersonComponent,
} from '../../../../../view.components/entity/query-judicial-person/query-judicial-person.component'
import {
  QueryNaturalPersonComponent,
} from '../../../../../view.components/entity/query-natural-person/query-natural-person.component'
import {
  DialogAcknowledgePrintSuccessfulComponent,
  DialogCaptureCertificateNumberComponent,
} from '../../../../../view.components/retail/insurance/certificate/issue-certificates/issue-certificates.component'
import {
  InsurancePolicyQueryComponent
} from '../../../../../view.components/retail/insurance/policy/query/query.component'
import {
  SummaryDialogComponent,
} from '../../../../../view.components/retail/insurance/third-party-insurance/summary-dialog/summary-dialog.component'
import {VehicleListComponent} from '../../../../../view.components/retail/insurance/vehicle-list/vehicle.list.component'
import {
    SupervisorOverrideDialogComponent,
} from '../../../../../view.components/security/supervisor-override-dialog/supervisor-override-dialog.component';
import {AgentManagerService} from '../../../../../app.services/managers/agent-manager/agent-manager.service'
import {
  RetailProfileInformationProviderService
} from '../../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service'
import {
  QueryVehicleInformationManagerService
} from '../../../../../http.services/vehicle/query-vehicle-information-manager/query-vehicle-information-manager.service'
import {
  InsurancePolicyProviderService
} from '../../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service'
import {
  UnderwriterInformationProviderService
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
  SystemParameterProviderService
} from '../../../../../http.services/system/system-parameter-provider/system-parameter-provider.service'
import {
  InsurancePolicyInitialisationManagerService
} from '../../../../../http.services/retail/insurance/insurance-policy-initialisation-manager/insurance-policy-initialisation-manager.service'
import {
  RefundProviderService
} from '../../../../../http.services/retail/payment/refund-provider/refund-provider.service'
import {
  InsurancePolicyFinalisationManagerService
} from '../../../../../http.services/retail/insurance/insurance-policy-finalisation-manager/insurance-policy-finalisation-manager.service'
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service'
import {
  InsuranceCertificateInitialisationIssuanceManagerService
} from '../../../../../http.services/retail/insurance/certificate/insurance-certificate-initialisation-issuance-manager/insurance-certificate-initialisation-issuance-manager.service'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {IStepComponent} from '../../../../../view.components/shared/guards/step-guard'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {Utils} from '../../../../../view.components/shared/Utils/Utils'
import {VehicleQueryComponent} from '../../../../../view.components/vehicle/query/vehicle.query.component'
import {PolicyAction} from './policy-action-enum'
import {
  UnderwriterManagerService
} from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {PDFDisplayModel, PDFPrintModel, PrintService} from '../../../../../app.services/common/printing/print.service'
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-query-policy',
    templateUrl: './query-policy-page.component.html',
    styleUrls: ['./query-policy-page.component.scss']
})
export class QueryPolicyPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps = [];
    currentStep = 0;
    dropdownValue: string;

    queryBy = 0; // 0 - vehicle, 1 - person, 2 - judicial

    legalEntity: LegalEntity;
    vehicles: RetailVehicle[];
    insurancePolicySummaries: InsurancePolicySummary[];
    vehicleMap: { [id: string]: boolean } = {};
    queryCount = 0;
    totalQueryCount = 0;
    vehicleSummaryMap: { [id: string]: InsurancePolicyVehicleSummary[] } = {}

    previewDocument = false;
    documentToPreview: any;
    documentToPrint: any;
    selectedInsurancePolicyItem: InsurancePolicyItem;
    insuracePolicyNumber: string;

    noResults = false;
    activeToast: ActiveToast<any>;
    supervisorCredentials: Credentials;
    showRefundButton: boolean = false;
    provideInsurancePolicySummariesRequest;
    policyAction = PolicyAction;
    policyNumbersList: string[] = [];

    columns = [
        { plateNumber: 'Plate Number' },
        { manufactureDate: 'Manufacture Date' },
        { make: 'Make' },
        { model: 'Model' },
        { colour: 'Colour' },
    ];

    targetPolicies: MotorInsurancePolicy[]
    dialogRef: MatDialogRef<any, any>;
    isPublicUserView = false;

    @ViewChild(VehicleQueryComponent) vehicleQueryComponent: VehicleQueryComponent;
    @ViewChild(QueryNaturalPersonComponent) naturalPersonQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(QueryJudicialPersonComponent) queryJudicialComponent: QueryJudicialPersonComponent;
    @ViewChild(InsurancePolicyQueryComponent) insurancePolicyQueryComponent: InsurancePolicyQueryComponent;
    @ViewChild(VehicleListComponent) vehicleListComponent: VehicleListComponent;
    issueDate: Date;

    constructor(private translate: TranslateService,
        private translateParser: TranslateParser,
                private toastr: ErrorToastService,
        private title: Title,
        private retailProfileInformationProviderService: RetailProfileInformationProviderService,
        private location: Location,
        private dialog: MatDialog,
        private queryVehicleInformationManagerService: QueryVehicleInformationManagerService,
        private insurancePolicyProviderService: InsurancePolicyProviderService,
        private unMarshallerService: UnMarshallerService,
        private locationStrategy: LocationStrategy,
        public brokerService: UnderwriterInformationProviderService,
        private underwriterManagerService: UnderwriterManagerService,
        private systemParameterService: SystemParameterProviderService,
        private insurancePolicyInitialisationManagerService: InsurancePolicyInitialisationManagerService,
        private refundProviderService: RefundProviderService,
        private cancelInsurancePolicyFinalisationManagerService: InsurancePolicyFinalisationManagerService,
        private authenticationService: AuthenticationService,
        private insuranceCertificateInitialisationIssuanceManagerService: InsuranceCertificateInitialisationIssuanceManagerService,
        private printService: PrintService,
        private agentService: AgentManagerService,
        private runtimeConfigurationService: RuntimeConfigurationService
    ) {

        super(translate, translateParser);

        this.title.setTitle(`Query Policy - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.translate.get("RTSA.TITLE_QUERY_VEHICLE").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-search", borderColor: "#1592E6" });
        });
        this.translate.get("RTSA.TITLE_DETAILS").subscribe((response) => {
            this.steps.push({ id: 1, name: response, icon: "fa-bars", borderColor: "#3f5baa" });
        });
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {
    }

    onLegalEntity(legalEntity: LegalEntity): void {

        this.legalEntity = legalEntity;
        this.noResults = false;
        if (this.legalEntity) {
            this.getRetailProfile(this.legalEntity);
        } else {
            this.noResults = true;
        }
    }

    onExists(event): void {
        this.noResults = !event;
    }

    onVehicle(vehicle): void {

        this.vehicles = vehicle;
        this.noResults = false;
        if (this.vehicles && this.vehicles.length > 0) {
            this.currentStep++;
        } else {
            this.noResults = true;
        }
    }

    onInsurancePolicy(event: InsurancePolicy[]): void {

        const promises = [];

        for (const policy of event) {

            const vehicleIdentificationNumber = new VehicleIdentificationNumber();
            vehicleIdentificationNumber.number = (policy as any).vehicleIdentificationNumber;

            const queryVehicleInformationRequest = new QueryVehicleInformationRequest();
            const vehicleProviderCriteria = new VehicleProviderCriteria();
            const countryIdentifier = new CountryDescriptionIdentifier();
            countryIdentifier.description = ((policy as any).coveredCountry[0] as CountryDescriptionIdentifier).description;
            vehicleProviderCriteria.countryIdentifier = countryIdentifier;
            if (countryIdentifier.description === 'MW') {
                vehicleProviderCriteria.type = 'EXTERNAL';
            } else {
                vehicleProviderCriteria.type = 'INTERNAL';
            }
            vehicleProviderCriteria.vehicleIdentifier = vehicleIdentificationNumber;
            queryVehicleInformationRequest.criteria = vehicleProviderCriteria;

            this.startLoad();

            promises.push(this.getVehicle(queryVehicleInformationRequest));

        }

        this.vehicles = null;
        Promise.all(promises).then((results) => {
            this.stopLoad();
            const vehicles = [];
            const vehicleMap: { [id: string]: RetailVehicle } = {};
            for (const result of results) {
                for (const v of result) {
                    vehicleMap[Utils.getTpiVehicleIdentifier(v.vehicleIdentifiers).number] = v;
                }
            }
            for (const v in vehicleMap) {
                vehicles.push(vehicleMap[v]);
            }
            if (vehicles.length > 0 && this.currentStep === 0) {
                this.vehicles = vehicles;
                this.currentStep++;
            }

        });

        if (event.length === 0) {
            this.noResults = true;
        }

    }

    getVehicle(queryVehicleInformationRequest: QueryVehicleInformationRequest): Promise<void> {
        return new Promise<void>((resolve) => {
            this.queryVehicleInformationManagerService.provideVehicle(queryVehicleInformationRequest).subscribe((response) => {
                resolve(response.body.vehicles);
            });
        });
    }

    onQueryType(type): boolean {

        this.noResults = false;
        if (type === 4) {
            // this.getUnderwriterPolicies(); Not used
        } else {
            this.queryBy = type;
        }

        return false;
    }

    getDropDownValue(searchType: string): void {
        this.dropdownValue = searchType;
    }

    getUnderwriterPolicies(): void {

        const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();

        const underwriterCriteria = new BrokerUnderwriterCriteria();
        underwriterCriteria.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriter.id;
        provideThirdPartyInsurancePoliciesRequest.criteria = underwriterCriteria;

        this.startLoad();
        this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {
            this.stopLoad();

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson
                    (httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                this.vehicles = null;
                const vehicleList = [];
                this.vehicleMap = {};
                this.totalQueryCount = response.insurancePolicies.length;
                this.queryCount = 0;

                for (const policy of response.insurancePolicies) {

                    // if (!moment(policy.expiryDate).isBefore(moment().subtract(4, 'months'))) { FIXME uncomment
                    //
                    //     const queryVehicleInformationRequest = new QueryVehicleInformationRequest();
                    //     const vehicleProviderCriteria = new VehicleProviderCriteria();
                    //     vehicleProviderCriteria.countryIdentifier = 'MWI';
                    //     vehicleProviderCriteria.type = 'INTERNAL';
                    //     if(policy instanceof ThirdPartyInsurancePolicy){
                    //         vehicleProviderCriteria.vehicleIdentifier = policy.vehicleIdentificationNumber;
                    //     }else if(policy instanceof ComprehensiveInsurancePolicy){
                    //         vehicleProviderCriteria.vehicleIdentifier = policy.vehicleIdentificationNumber;
                    //     }
                    //
                    //     queryVehicleInformationRequest.criteria = vehicleProviderCriteria;
                    //
                    //     this.startLoad();
                    //     this.queryVehicleInformationManagerService.provideVehicle(queryVehicleInformationRequest).subscribe((res) => {
                    //         this.stopLoad();
                    //         for (const v of res.vehicles) {
                    //             if (!this.vehicleMap[this.getTpiVehicleIdentifier(v.vehicleIdentifiers)]) {
                    //                 this.vehicleMap[this.getTpiVehicleIdentifier(v.vehicleIdentifiers)] = true;
                    //                 vehicleList.push(v);
                    //             }
                    //         }
                    //
                    //         this.queryCount++;
                    //
                    //         if (vehicleList.length > 0 && this.queryCount === this.totalQueryCount && this.currentStep === 0) {
                    //             this.vehicles = vehicleList;
                    //             this.currentStep++;
                    //         }
                    //
                    //     },(error) => {
                    //         this.stopLoad();
                    //     });
                    //
                    // }

                }
            }

        }).catch((error) => {
            this.stopLoad();
        });;

    }

    isExpired(policies: InsurancePolicyVehicleSummary[]) {
        let itemsExpired = 0;
        policies.forEach((item) => {
            if (this.isItemExpired(item.expiryDate)) {
                itemsExpired++
            }
        });

        if (itemsExpired == policies.length) {
            return true;
        } else {
            return false;
        }
    }

    isItemExpired(expiryDate): boolean {
        return moment(new Date()).isAfter(moment(expiryDate).add(-1, 'days').endOf('day'))
    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {

        return Utils.getTpiVehicleIdentifier(vehicleIdentifiers).number;

    }

    onSubmit(event): void {
        if (this.currentStep === 0) {

            var country;
            var identifierName;
            var identifier;

            if (this.vehicleQueryComponent) {
                identifierName = this.vehicleQueryComponent.type.value.name;
                identifier = this.vehicleQueryComponent.identifier.value;
                country = this.vehicleQueryComponent.countryComponent.country.description;
            } else if (this.naturalPersonQueryComponent) {
                identifierName = this.naturalPersonQueryComponent.type.value.name;
                identifier = this.naturalPersonQueryComponent.identifier.value;
                country = this.naturalPersonQueryComponent.countryComponent.country.alphaTwo;
            } else if (this.queryJudicialComponent) {
                identifierName = this.queryJudicialComponent.type.value.name;
                identifier = this.queryJudicialComponent.identifier.value;
                country = this.queryJudicialComponent.countryComponent.country.alphaTwo;
            } else if (this.insurancePolicyQueryComponent) {
                identifier = this.insurancePolicyQueryComponent.identifier.value;
                identifierName = 'Insurance Number';
            }

            this.makeQueryInsurancePolicyInformationRequest(identifierName, identifier, country);
        } else {
            this.currentStep = 0;
        }
    }

    makeQueryInsurancePolicyInformationRequest(identifierName: string, identifierValue: string, identifierCountry?: string) {

        var provideInsurancePolicySummaryRequest = new ProvideInsurancePolicySummaryRequest();
        var insurancePolicyCriteria: Criteria;

        switch (identifierName) {
            case 'Engine Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                insurancePolicyVehicleCriteria.engineNumber = identifierValue.toUpperCase();
                insurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;

                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'Plate Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                var countryOfIssue = new CountryDescriptionIdentifier();

                countryOfIssue.description = identifierCountry;
                insurancePolicyVehicleCriteria.plateNumber = identifierValue.toUpperCase();
                insurancePolicyVehicleCriteria.plateNumberCountryOfIssue = countryOfIssue;
                insurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;

                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'Vehicle Identification Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                insurancePolicyVehicleCriteria.vehicleIdentificationNumber = identifierValue.toUpperCase();
                insurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;;

                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'National Identity Number': {
                var insurancePolicyLegalEntityCriteria = new InsurancePolicyLegalEntityCriteria();
                var nationalIdentity = new NationalIdentityNumber();

                nationalIdentity.number = identifierValue.toUpperCase();
                nationalIdentity.countryOfIssue = identifierCountry;
                insurancePolicyLegalEntityCriteria.legalEntityIdentifier = nationalIdentity;
                insurancePolicyCriteria = insurancePolicyLegalEntityCriteria;
                break;
            }
            case 'Passport Number': {
                var insurancePolicyLegalEntityCriteria = new InsurancePolicyLegalEntityCriteria();
                var passportNumber = new PassportNumber();

                passportNumber.number = identifierValue.toUpperCase();
                passportNumber.countryOfIssue = identifierCountry;
                insurancePolicyLegalEntityCriteria.legalEntityIdentifier = passportNumber;
                insurancePolicyCriteria = insurancePolicyLegalEntityCriteria;
                break;
            }
            case 'Business Registration Number': {
                var insurancePolicyLegalEntityCriteria = new InsurancePolicyLegalEntityCriteria();
                var registrationNumber = new RegistrationNumber(identifierValue.toUpperCase(), identifierCountry);

                insurancePolicyLegalEntityCriteria.legalEntityIdentifier = registrationNumber;
                insurancePolicyCriteria = insurancePolicyLegalEntityCriteria
                break;
            }
            case 'Insurance Number': {
                var insurancePolicyNumberCriteria = new InsurancePolicyNumberCriteria();

                insurancePolicyNumberCriteria.insurancePolicyNumber = identifierValue.toUpperCase();
                insurancePolicyNumberCriteria.ycInsurancePolicyNumber = identifierValue.toUpperCase();
                insurancePolicyNumberCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;

                insurancePolicyCriteria = insurancePolicyNumberCriteria;
                break;
            }
            default: {
                break;
            }
        }

        provideInsurancePolicySummaryRequest.criteria = insurancePolicyCriteria;
        provideInsurancePolicySummaryRequest.batchNumber = -1;
        provideInsurancePolicySummaryRequest.batchSize = -1;

        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

        legalEntityDescriptionIdentifier.name = this.underwriterManagerService.selectedUnderwriter.name;
        legalEntityDescriptionIdentifier.surname = this.underwriterManagerService.selectedUnderwriter.name;
        legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier([this.underwriterManagerService.selectedUnderwriter.legalEntityIdentifier]);

        provideInsurancePolicySummaryRequest.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
            this.underwriterManagerService.selectedUnderwriter.name,
            legalEntityDescriptionIdentifier
        );

        this.getInsurancePolicies(true, provideInsurancePolicySummaryRequest);
    }

    onStep(event): void {
        this.currentStep = event.id;
    }

    onNext(event): void {

        // if (this.currentStep === 1) {
        //     this.currentStep = 0;
        // } else {
        //     this.currentStep++;
        //     window.scrollTo(0, 0);
        // }

    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        } else {
            this.location.back();
        }
    }

    providePolicy(tpiVehicleIdentifier: string, policyNumber: string) {
        const provideInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
        const insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
        insurancePolicyVehicleCriteria.tpiVehicleIdentifier = tpiVehicleIdentifier;
        insurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
        provideInsurancePoliciesRequest.criteria = insurancePolicyVehicleCriteria;
        provideInsurancePoliciesRequest.batchNumber = -1;
        provideInsurancePoliciesRequest.batchSize = -1;

        this.insurancePolicyProviderService.providePolicy(provideInsurancePoliciesRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);
                this.targetPolicies = [];
                for (const policy of response.insurancePolicies) {
                    (policy as MotorInsurancePolicy).expiryDate = this.addExtraDay((policy as MotorInsurancePolicy).expiryDate);
                    for (const item of (policy as MotorInsurancePolicy).insurancePolicyItems) {
                        (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate = moment(this.addExtraDay((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate)).format(environment.formatDateServer) as any;
                        if ((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === tpiVehicleIdentifier
                            && policy.number === policyNumber) {
                            if (this.targetPolicies.filter(item => item.number === policy.number).length === 0) {
                                let insurancePolicy = policy as MotorInsurancePolicy;
                                insurancePolicy.insurancePolicyItems = insurancePolicy.insurancePolicyItems.filter((vehicleItem) => (vehicleItem as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === tpiVehicleIdentifier)
                                this.targetPolicies.push(insurancePolicy)
                            }
                        }
                    }
                }
            }
        })
    }

    onSelect(policyAction: PolicyAction, insurancePolicySummary: InsurancePolicySummary, insurancePolicyVehicleSummary: InsurancePolicyVehicleSummary): boolean {

        if (policyAction === PolicyAction.VIEW_POLICY) {
            this.providePolicy(insurancePolicyVehicleSummary.tpiVehicleIdentifier, insurancePolicySummary.policyNumber);
        } else {

            const insurancePolicyNumberVehicleCriteria = new InsurancePolicyNumberVehicleCriteria()
            insurancePolicyNumberVehicleCriteria.policyNumber = insurancePolicySummary.policyNumber
            insurancePolicyNumberVehicleCriteria.tpiVehicleIdentifier = insurancePolicyVehicleSummary.tpiVehicleIdentifier
            insurancePolicyNumberVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;

            const provideMotorInsuranceVehiclePolicyRequest = new ProvideMotorInsuranceVehiclePolicyRequest()
            provideMotorInsuranceVehiclePolicyRequest.criteria = insurancePolicyNumberVehicleCriteria

            this.startLoad()
            this.insurancePolicyProviderService.provideMotorInsuranceVehiclePolicy(provideMotorInsuranceVehiclePolicyRequest).then((httpResponse) => {
                this.stopLoad()

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideMotorInsuranceVehiclePolicyResponse) as ProvideMotorInsuranceVehiclePolicyResponse);

                    const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

                    legalEntityDescriptionIdentifier.name = this.underwriterManagerService.selectedUnderwriter.name;
                    legalEntityDescriptionIdentifier.surname =this.underwriterManagerService.selectedUnderwriter.name;
                    legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier([this.underwriterManagerService.selectedUnderwriter.legalEntityIdentifier]);

                    const motorInsurancePolicy = new MotorInsurancePolicy()
                    motorInsurancePolicy.snapshotLegalEntity = response.snapshotLegalEntity
                    motorInsurancePolicy.insurancePolicyItems.push(response.motorInsurancePolicyItems.at(0))
                    motorInsurancePolicy.insuranceVehicleCategoryDescription = insurancePolicySummary.insuranceVehicleCategoryDescription
                    motorInsurancePolicy.number = insurancePolicySummary.policyNumber
                    motorInsurancePolicy.expiryDate = insurancePolicySummary.expiryDate
                    motorInsurancePolicy.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
                        this.underwriterManagerService.selectedUnderwriter.name,
                        legalEntityDescriptionIdentifier
                    );

                    this.onPolicyAction(policyAction, motorInsurancePolicy, response.motorInsurancePolicyItems.at(0))

                }

            }).catch(() => {
                this.stopLoad()
            })

        }

        return false

    }

    onAction(event) {
        this.onPolicyAction(event.policyAction, event.policy, event.motorInsurancePolicyItem)
    }

    onPolicyAction(policyAction: PolicyAction, motorInsurancePolicy: MotorInsurancePolicy, motorInsurancePolicyItem: MotorInsurancePolicyItem) {

        switch (policyAction) {
            case PolicyAction.CANCEL_POLICY:
                this.cancelPolicy(motorInsurancePolicy, motorInsurancePolicy.snapshotLegalEntity.tpiIdentifier, [motorInsurancePolicyItem]);
                break;

            case PolicyAction.VIEW_REFUND:
                this.provideRefundSummary(false, motorInsurancePolicy, [motorInsurancePolicyItem], null);
                break;

            case PolicyAction.ISSUE_DUPLICATE_CERTIFICATE:
                this.selectedInsurancePolicyItem = motorInsurancePolicyItem;
                this.insuracePolicyNumber = motorInsurancePolicy.number;
                if (motorInsurancePolicyItem.motorInsuranceVehiclePolicy.certificateNumber) {
                    this.issueDuplicateCertificate(motorInsurancePolicy, motorInsurancePolicyItem)
                } else {

                    this.translate.get('TOAST.NO_CERTIFICATE_ISSUED').subscribe((res: any) => {
                        this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                            toastComponent: ToastComponent,
                            progressBar: true,
                            closeButton: true
                        });
                    });
                }

                break;

        }

    }

    getRetailProfile(legalEntity): void {

        const provideRetailProfileRequest = new ProvideRetailProfileInformationRequest();
        for (const legalEntityIdentifier of legalEntity.legalEntityIdentifiers) {
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {
                const retailProfileLegalEntityCriteria = new RetailProfileLegalEntityCriteria();
                retailProfileLegalEntityCriteria.tpiIdentifier = (legalEntityIdentifier as TpiIdentifier);
                provideRetailProfileRequest.criteria = retailProfileLegalEntityCriteria;
                break;
            }
        }

        this.noResults = false;
        this.retailProfileInformationProviderService.provideRetailProfileInformation(provideRetailProfileRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response =
                    (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                        ProvideRetailProfileInformationResponse) as ProvideRetailProfileInformationResponse);
                if (response.retailProfile) {
                    this.getRetailProfileVehicles(response.retailProfile);
                } else {
                    this.noResults = true;
                }
            }

        }).catch(error => {
        });

    }

    getRetailProfileVehicles(retailProfile: RetailProfile) {

        const provideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;
        this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response =
                    (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                        ProvideRetailProfileVehicleInformationResponse) as ProvideRetailProfileVehicleInformationResponse);
                this.vehicles = response.retailVehicles;
                if (this.vehicles.length > 0) {
                    this.currentStep++;
                }
            }
        }, (error) => {
        });

    }

    onClosePolicyView() {
        this.targetPolicies = null
    }

    toast() {

        this.translate.get('TOAST.NO_INSURANCE_POLICY').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
        });

    }

    getInsurancePolicies(incrementStep: boolean, provideInsurancePolicySummaryRequest: ProvideInsurancePolicySummaryRequest) {
        this.noResults = false;
        this.provideInsurancePolicySummariesRequest = provideInsurancePolicySummaryRequest;
        this.insurancePolicyProviderService.provideInsurancePolicySummary(provideInsurancePolicySummaryRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePolicySummaryResponse) as ProvideInsurancePolicySummaryResponse);

                this.insurancePolicySummaries = response.insurancePolicySummaries
                this.policyNumbersList = [];

                if (this.insurancePolicySummaries.length > 0) {

                    for (var policySummary of this.insurancePolicySummaries) {
                        policySummary.expiryDate = this.addExtraDay(policySummary.expiryDate);
                        if (!this.vehicleSummaryMap[policySummary.policyNumber]) {
                            this.vehicleSummaryMap[policySummary.policyNumber] = []
                        }
                        const vehicleIdentifiers: string[] = []
                        this.vehicleSummaryMap[policySummary.policyNumber] = [];
                        for (const vehicleSummary of policySummary.insurancePolicyVehicleSummaries) {
                            vehicleSummary.expiryDate = this.addExtraDay(vehicleSummary.expiryDate);
                            if (vehicleIdentifiers.indexOf(vehicleSummary.tpiVehicleIdentifier) === -1) {
                                this.vehicleSummaryMap[policySummary.policyNumber].push(vehicleSummary)
                                vehicleIdentifiers.push(vehicleSummary.tpiVehicleIdentifier)
                            }
                        }
                        this.getRefundInformation(policySummary.policyNumber);
                    }

                    if (incrementStep) {
                        this.currentStep++
                    }
                    this.insurancePolicySummaries.sort((a, b) => {
                        if (b.expiryDate > a.expiryDate) {
                            return 1;
                        }
                        if (b.expiryDate < a.expiryDate) {
                            return -1;
                        }
                        return 0;
                    });
                }
                else {
                    this.noResults = true;
                }
            }

        }).catch(error => {
        });
    }

    viewRefund(refundConfirmation: boolean, refundSummaries: RefundSummary[], motorInsurancePolicy: MotorInsurancePolicy, motorInsurancePolicyItems?: MotorInsurancePolicyItem[], insurancePolicySummary?: InsurancePolicySummary) {

        var motorInsurancePolicyItemList = [];
        if (motorInsurancePolicyItems != undefined && motorInsurancePolicyItems != null && motorInsurancePolicy != undefined && motorInsurancePolicy != null) {
            this.showSummaryDialog(refundConfirmation, refundSummaries, motorInsurancePolicy, motorInsurancePolicyItems)
        }
        else {
            const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();

            const insurancePolicyNumberCriteria = new InsurancePolicyNumberCriteria();
            insurancePolicyNumberCriteria.insurancePolicyNumber = insurancePolicySummary.policyNumber;

            provideThirdPartyInsurancePoliciesRequest.criteria = insurancePolicyNumberCriteria;
            provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
            provideThirdPartyInsurancePoliciesRequest.batchSize = -1;

            this.startLoad();
            this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {
                this.stopLoad();

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                    if (response.insurancePolicies.length > 0) {
                        const motorInsurancePolicy = response.insurancePolicies[0] as MotorInsurancePolicy;

                        for (var item of motorInsurancePolicy.insurancePolicyItems) {
                            if (item instanceof MotorInsurancePolicyItem || item['@class'] === new MotorInsurancePolicyItem['@class']) {
                                if (!this.isPolicyActive((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate)) {
                                    motorInsurancePolicyItemList.push(item as MotorInsurancePolicyItem);
                                }
                            }
                        }
                        this.showSummaryDialog(refundConfirmation, refundSummaries, motorInsurancePolicy, motorInsurancePolicyItemList);
                    }
                }
            })
        }
    }

    showSummaryDialog(refundConfirmation: boolean, refundSummaries: RefundSummary[], motorInsurancePolicy: MotorInsurancePolicy, motorInsurancePolicyItems: MotorInsurancePolicyItem[]) {

        var refundItems = [];
        for (var refundSummary of refundSummaries) {
            if ((motorInsurancePolicyItems === undefined || motorInsurancePolicyItems.length === 0) && motorInsurancePolicy) {
                var items = motorInsurancePolicy.insurancePolicyItems.reduce((filtered, policy) => {
                    if (policy instanceof MotorInsurancePolicyItem) {
                        const orderItemNumber = policy.orderIdentifier['orderItemNumberIdentifier']['orderItemNumber'];
                        if (orderItemNumber && orderItemNumber === refundSummary.orderItemNumber) {
                            const mappedItem = {
                                plateNumber: policy.motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber,
                                isExtension: policy.insurancePolicyItemType === 'EXTENSION'
                            }
                            filtered.push(mappedItem);
                        }
                    }
                    return filtered;
                }, []);

                if (items.length > 0) {
                    refundItems.push(
                        {
                            refundAmount: refundSummary.amount,
                            refundVehiclePlate: items[0].plateNumber,
                            isExtension: items[0].isExtension,
                            levy: refundSummary.levy
                        }
                    );
                }
            } else {
                for (var motorInsurancePolicyItem of motorInsurancePolicyItems) {
                    if (motorInsurancePolicyItem.orderIdentifier instanceof OrderOrderItemNumberIdentifier || motorInsurancePolicyItem.orderIdentifier['@class'] == new OrderOrderItemNumberIdentifier('', null)['@class']) {
                        if ((motorInsurancePolicyItem.orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier.orderItemNumber == refundSummary.orderItemNumber) {
                            refundItems.push(
                                {
                                    refundAmount: refundSummary.amount,
                                    refundVehiclePlate: motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber,
                                    levy: refundSummary.levy,
                                    isExtension: motorInsurancePolicyItem.insurancePolicyItemType === 'EXTENSION'
                                }
                            );
                        }
                    }
                }
            }
        }

        this.dialog.open(SummaryDialogComponent, {
            width: '600px',
            panelClass: 'padded-modal',
            height: 'auto',
            disableClose: refundConfirmation,
            data: {
                policyNumber: motorInsurancePolicy.number,
                underwriterName: (motorInsurancePolicy.underwriterIdentifier as UnderwriterLegalEntityIdentifier).name,
                refundList: refundItems,
                refundPaid: refundConfirmation
            }
        }).afterClosed().subscribe(res => {
            if (res != null && res) {

                if (motorInsurancePolicyItems.length > 1) {
                    this.finaliseCancelInsurancePolicy(motorInsurancePolicy.number)
                }
                else {
                    this.finaliseCancelInsurancePolicy(motorInsurancePolicy.number, motorInsurancePolicyItems.at(0))
                }
            }
        });
    }

    provideRefundSummary(refundConfirmation: boolean, motorInsurancePolicy?: MotorInsurancePolicy, motorInsurancePolicyItems?: MotorInsurancePolicyItem[], insurancePolicySummary?: InsurancePolicySummary) {

        var provideRefundSummaryRequest = new ProvideRefundSummaryRequest();

        var criteria;

        if (motorInsurancePolicyItems != undefined && motorInsurancePolicyItems != null && motorInsurancePolicyItems.length == 1) {
            criteria = new RefundOrderItemNumberStatusCriteria();

            if (motorInsurancePolicyItems.at(0).orderIdentifier instanceof OrderOrderItemNumberIdentifier ||
                motorInsurancePolicyItems.at(0).orderIdentifier['@class'] === new OrderOrderItemNumberIdentifier('', null)['@class']) {
                criteria.orderItemNumber = (motorInsurancePolicyItems.at(0).orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier.orderItemNumber;
            }
        }
        else {
            criteria = new RefundPolicyNumberCriteria();
            if (motorInsurancePolicy != null && motorInsurancePolicy != undefined)
                criteria.policyNumber = motorInsurancePolicy.number;
            else if (insurancePolicySummary != null && insurancePolicySummary != undefined)
                criteria.policyNumber = insurancePolicySummary.policyNumber;
        }

        if (refundConfirmation) {
            criteria.status = RefundStatus.UNPAID;
        }
        else {
            criteria.status = RefundStatus.PAID;
        }

        provideRefundSummaryRequest.criteria = criteria;

        this.refundProviderService.provideRefundSummary(provideRefundSummaryRequest).toPromise().then(httpResponse => {
            this.stopLoad();
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideRefundSummaryResponse) as ProvideRefundSummaryResponse);

                if (response.refundSummaries && response.refundSummaries.length > 0) {
                    this.viewRefund(refundConfirmation, response.refundSummaries, motorInsurancePolicy, motorInsurancePolicyItems, insurancePolicySummary)
                }
            }
        }).catch(err => {
            this.stopLoad();
        });

    }

    cancelFullPolicy(insurancePolicySummary: InsurancePolicySummary) {

        const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();

        const insurancePolicyNumberCriteria = new InsurancePolicyNumberCriteria();
        insurancePolicyNumberCriteria.insurancePolicyNumber = insurancePolicySummary.policyNumber;

        provideThirdPartyInsurancePoliciesRequest.criteria = insurancePolicyNumberCriteria;
        provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
        provideThirdPartyInsurancePoliciesRequest.batchSize = -1;

        this.startLoad();
        this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {
            this.stopLoad();

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);
                const policy = response.insurancePolicies.find(policy => policy.number == insurancePolicySummary.policyNumber) as MotorInsurancePolicy;

                if (policy != null && policy != undefined) {
                    this.stopLoad()
                    const motorInsurancePolicyItems: MotorInsurancePolicyItem[] = [];
                    this.cancelPolicy(policy, policy.snapshotLegalEntity.tpiIdentifier, motorInsurancePolicyItems);
                }
            }
        })
    }

    cancelPolicy(motorInsurancePolicy: MotorInsurancePolicy, customerTpiIdentifier: string, motorInsurancePolicyItems?: MotorInsurancePolicyItem[]) {

        var confirmationMessage;

        this.translate.get("REFUND.CONFIRMATION_MESSAGE_POLICY", { value: motorInsurancePolicy.number }).subscribe((response) => {
            confirmationMessage = response;
        });

        if (motorInsurancePolicyItems != undefined && motorInsurancePolicyItems != null && motorInsurancePolicyItems.length == 1) {
            this.translate.get("REFUND.CONFIRMATION_MESSAGE_VEHICLE", { value: motorInsurancePolicyItems.at(0).motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber }).subscribe((response) => {
                confirmationMessage = confirmationMessage + response;
            });
        }

        this.dialog.open(SupervisorOverrideDialogComponent, {
            panelClass: 'padded-modal',
            data: {
                message: confirmationMessage,
                title: "Confirmation - Supervisor",
                returnCredentials: true
            }
        }).afterClosed().subscribe((overrideResponse) => {
            if (overrideResponse) {
                this.startLoad();
                this.supervisorCredentials = overrideResponse;
                this.initialiseCancel(motorInsurancePolicy, customerTpiIdentifier, overrideResponse, motorInsurancePolicyItems);
            }
            if (this.targetPolicies.length > 0) {
                this.targetPolicies = [];
                setTimeout(() => {
                    if (motorInsurancePolicyItems != undefined && motorInsurancePolicyItems != null && motorInsurancePolicyItems.length == 1) {
                        this.providePolicy(motorInsurancePolicyItems.at(0).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier, motorInsurancePolicy.number);
                    }
                }, 1000);
            }
        });
    }

    issueDuplicateCertificate(motorInsurancePolicy: MotorInsurancePolicy, motorInsurancePolicyItem: MotorInsurancePolicyItem) {

        var confirmationMessage;

        this.translate.get("ISSUE.CONFIRMATION_MESSAGE_DUPLICATE_CERTIFICATE", { value: motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber }).subscribe((response) => {
            confirmationMessage = response;
        });

        this.dialog.open(SupervisorOverrideDialogComponent, {
            panelClass: 'padded-modal',
            data: {
                message: confirmationMessage,
                title: "Confirmation - Supervisor",
                returnCredentials: true
            }
        }).afterClosed().subscribe((overrideResponse) => {
            if (overrideResponse) {
                this.startLoad();
                this.onPreview(motorInsurancePolicy, motorInsurancePolicyItem);
            }
        });
    }

    onPrintPreview(): void {
        this.displayPDF(this.documentToPrint, (document.getElementById('canvas-print') as HTMLCanvasElement), () => {
            setTimeout(() => {
                this.printDocument();
            }, 100);
        });
    }

    onPreview(motorInsurancePolicy: MotorInsurancePolicy, motorInsurancePolicyItem: MotorInsurancePolicyItem): boolean {

        this.getRenderedDocument(motorInsurancePolicy, motorInsurancePolicyItem, (response: InitialiseIssueInsuranceCertificateResponse) => {
            this.stopLoad();

            this.issueDate = response.issueDate;
            for (const pdfDocument of response.renderedDocuments) {
                if (pdfDocument.type === RenderedDocumentType.INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW) {
                    this.documentToPreview = (pdfDocument as any).encodedPdf;
                } else if (pdfDocument.type === RenderedDocumentType.INSURANCE_CERTIFICATE_DUPLICATE_PRINT) {
                    this.documentToPrint = (pdfDocument as any).encodedPdf;
                }
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, (document.getElementById('canvas') as HTMLCanvasElement), () => { });
            });
            this.targetPolicies = null
        });

        return false;
    }

    getRenderedDocument(motorInsurancePolicy: MotorInsurancePolicy, motorInsuranceCertificate: MotorInsurancePolicyItem, callback): void {
        const agentIdentifier = this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier;
        const policyBrokerIdentifier =  ((motorInsuranceCertificate as MotorInsurancePolicyItem).brokerIdentifier as BrokerLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier;
        const initialiseIssueInsuranceCertificateRequest = new InitialiseIssueInsuranceCertificateRequest();
        initialiseIssueInsuranceCertificateRequest.types = [RenderedDocumentType.INSURANCE_CERTIFICATE_DUPLICATE_PREVIEW, RenderedDocumentType.INSURANCE_CERTIFICATE_DUPLICATE_PRINT]
        initialiseIssueInsuranceCertificateRequest.insurancePolicyNumber = motorInsurancePolicy.number;
        initialiseIssueInsuranceCertificateRequest.userId = agentIdentifier.tpiIdentifier;
        initialiseIssueInsuranceCertificateRequest.legalEntityIdentifier = policyBrokerIdentifier;
        initialiseIssueInsuranceCertificateRequest.motorInsurancePolicyItem = JSON.parse(JSON.stringify((motorInsuranceCertificate)))
        delete (initialiseIssueInsuranceCertificateRequest.motorInsurancePolicyItem as any).insurancePolicyNumber

        this.insuranceCertificateInitialisationIssuanceManagerService.initialiseIssueInsuranceCertificate(initialiseIssueInsuranceCertificateRequest).subscribe((data) => {
            callback(data.body);
        }, (error) => {
            this.stopLoad();
            this.translate.get('TOAST.CERTIFICATE').subscribe((res: any) => {
                this.activeToast = this.toastr.success(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        });

    }

    displayPDF(base64, canvas, complete) {
        const pdfDisplayData: PDFDisplayModel = {
            canvas,
            base64,
            complete,
            styleWidth: '50%',
            scale: 8
        };
        this.printService.displayPDF(pdfDisplayData);
    }

    printDocument(): void {
        this.popups();

        setTimeout(() => {
            this.openAcknowledgePrintSuccessful();
        }, 1000);
        const dataUrl = (document.getElementById('canvas-print') as any).toDataURL();
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: 1,
            dataUrl,
            features: 'top=0,height=1024px,width=800px',
            openBlank: false
        };

        this.printService.printDocument(pdfPrintData);
    }

    popups() {

        this.translate.get('TOAST.POPUPS').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 10000,
                progressBar: true
            });
            this.activeToast.portal.instance.imagePath = '/assets/images/common/popups.png';
        });

    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPrint = null;

    }

    openAcknowledgePrintSuccessful(): void {

        this.dialogRef = this.dialog.open(DialogAcknowledgePrintSuccessfulComponent, {
            width: '96%',
            panelClass: 'padded-modal',
            maxWidth: '800px',
            position: { top: "2%" },
            disableClose: true
        });

        this.dialogRef.afterClosed().subscribe(result => {

            switch (result) {
                case 0:
                    // Cancelled
                    this.previewDocument = false;
                    break;
                case 1:
                    this.dialog.open(SupervisorOverrideDialogComponent, {
                        panelClass: 'padded-modal'
                    }).afterClosed().subscribe((overrideResponse) => {
                        if (overrideResponse) {
                            this.printDocument();
                        } else {
                            this.openAcknowledgePrintSuccessful();
                        }
                    });
                    break;
                case 2:
                    this.openCaptureDialog();
                    this.previewDocument = false;
                    break;
            }

        });
    }

    openCaptureDialog(): void {

        this.dialogRef = this.dialog.open(DialogCaptureCertificateNumberComponent, {
            width: '96%',
            panelClass: 'padded-modal',
            maxWidth: '800px',
            position: { top: "2%" },
            disableClose: true,
            data: {
                selectedInsurancePolicyItem: this.selectedInsurancePolicyItem,
                certificateType: CertificateType.DUPLICATE,
                insuracePolicyNumber: this.insuracePolicyNumber,
                issueDate: this.issueDate
            }
        });

        this.dialogRef.afterClosed().subscribe(result => {

        });

    }

    initialiseCancel(motorInsurancePolicy: MotorInsurancePolicy, customerTpiIdentifier: string, credentials: Credentials, motorInsurancePolicyItems?: MotorInsurancePolicyItem[]) {
        var initialiseCancelInsurancePolicyRequest = new InitialiseCancelInsurancePolicyRequest();
        var legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

        const naturalPerson = this.authenticationService.getLoggedInNaturalPerson();

        legalEntityDescriptionIdentifier.name = naturalPerson.firstNames;
        legalEntityDescriptionIdentifier.surname = naturalPerson.surname;
        legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier(naturalPerson.legalEntityIdentifiers)

        initialiseCancelInsurancePolicyRequest.policyNumber = motorInsurancePolicy.number;
        initialiseCancelInsurancePolicyRequest.legalEntityIdentifier = Utils.getLegalEntityDescriptionIdentifier(this.authenticationService.getLinkedToJudicialPerson().legalEntityIdentifiers);
        initialiseCancelInsurancePolicyRequest.credentials = credentials;
        initialiseCancelInsurancePolicyRequest.naturalPerson = legalEntityDescriptionIdentifier;
        initialiseCancelInsurancePolicyRequest.tpiIdentifier = customerTpiIdentifier;

        if (motorInsurancePolicyItems != undefined && motorInsurancePolicyItems != null && motorInsurancePolicyItems.length == 1) {
            initialiseCancelInsurancePolicyRequest.motorInsurancePolicyItem = motorInsurancePolicyItems.at(0);
        }

        this.insurancePolicyInitialisationManagerService.initialiseCancelInsurancePolicy(initialiseCancelInsurancePolicyRequest).toPromise().then(httpResponse => {

            if (httpResponse && httpResponse.body) {
                this.getInsurancePolicies(false, this.provideInsurancePolicySummariesRequest);

                this.provideRefundSummary(true, motorInsurancePolicy, motorInsurancePolicyItems, null);
            }
        }).catch(err => {
            this.toastr.errorToast(err);
            this.stopLoad();
        });
    }

    finaliseCancelInsurancePolicy(policyNumber: string, motorInsurancePolicyItem?: MotorInsurancePolicyItem) {
        var finaliseCancelInsurancePolicyRequest = new FinaliseCancelInsurancePolicyRequest();

        finaliseCancelInsurancePolicyRequest.policyNumber = policyNumber;

        if (motorInsurancePolicyItem != null && motorInsurancePolicyItem != undefined) {
            if (motorInsurancePolicyItem.orderIdentifier instanceof OrderOrderItemNumberIdentifier) {
                finaliseCancelInsurancePolicyRequest.orderItemNumber = (motorInsurancePolicyItem.orderIdentifier as OrderOrderItemNumberIdentifier).orderItemNumberIdentifier.orderItemNumber;
            }
        }

        this.cancelInsurancePolicyFinalisationManagerService.finaliseCancelInsurancePolicy(finaliseCancelInsurancePolicyRequest).toPromise().then(httpResponse => {
            this.stopLoad();
            return;
        }).catch(err => {
            this.stopLoad();
        });
    }

    isPolicyActive(expiryDate: Date, insurancePolicy?: InsurancePolicySummary) {
        if (insurancePolicy) {
            if (this.isPolicyCancelled(insurancePolicy)) return false;
        }
        return moment(new Date()).isBefore(moment(expiryDate));
    }

    getRefundInformation(policyNumber: string) {
        var provideRefundSummaryRequest = new ProvideRefundSummaryRequest();
        var criteria = new RefundPolicyNumberCriteria();

        criteria.policyNumber = policyNumber;

        provideRefundSummaryRequest.criteria = criteria;

        this.refundProviderService.provideRefundSummary(provideRefundSummaryRequest).toPromise().then(httpResponse => {
            this.stopLoad();
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideRefundSummaryResponse) as ProvideRefundSummaryResponse);

                if (response.refundSummaries && response.refundSummaries.length > 0) {
                    this.policyNumbersList.push(policyNumber);
                }
            }
        }).catch(err => {
            this.stopLoad();
        });
    }

    refundApplies(policyNumber: string) {
        return this.policyNumbersList.includes(policyNumber);
    }

    addExtraDay(date) {
        return moment(date).add(1, 'day').startOf('day').toDate()
    }

    removeExtraDay(date) {
        const newDate = new Date(date);
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1, 0, 0, 0);
    }

    isPolicyCancelled(insurancePolicy: InsurancePolicySummary): boolean {
        const cancelledItems = insurancePolicy.insurancePolicyVehicleSummaries.filter(policy => policy.cancelled);
        return cancelledItems.length === insurancePolicy.insurancePolicyVehicleSummaries.length;
    }

    showCancelPolicyButton(insurancePolicySummary): boolean {
        return this.hasActivePolicyItem(insurancePolicySummary) &&
            !this.isPolicyCancelled(insurancePolicySummary)
    }

    showCancelledBadge(insurancePolicy: InsurancePolicySummary): boolean {
        insurancePolicy = this.sortVehicleSummaries(insurancePolicy);
        let invalidCount = 0;
        let hasCancelledItems = insurancePolicy.insurancePolicyVehicleSummaries.filter(item => item.cancelled).length > 0;
        insurancePolicy.insurancePolicyVehicleSummaries.forEach(item => {
            if(this.isItemExpired(item.expiryDate) || item.cancelled){
                invalidCount++;
            }
        });

        if (invalidCount >= insurancePolicy.insurancePolicyVehicleSummaries.length && hasCancelledItems) {
            return true;
        } else {
            let extensionItem = insurancePolicy.insurancePolicyVehicleSummaries.slice(-1)[0];
            let extenstionCancelled = false;
            if (extensionItem) {
                extenstionCancelled = extensionItem.cancelled;
            }
            return this.refundApplies(insurancePolicy.policyNumber) && (extenstionCancelled || this.isPolicyCancelled(insurancePolicy));
        }
    }

    showExpiredBadge(insurancePolicy: InsurancePolicySummary): boolean {
        insurancePolicy = this.sortVehicleSummaries(insurancePolicy);
        let expiredCount = 0;
        let cancelledCount = 0;
        insurancePolicy.insurancePolicyVehicleSummaries.forEach(item => {
            if (this.isItemExpired(item.expiryDate)) {
                expiredCount++;
            }
            if (item.cancelled) {
                cancelledCount++;
            }
        });

        if (cancelledCount > 0) {
            const invalidItems = cancelledCount + expiredCount;
            if (invalidItems >= insurancePolicy.insurancePolicyVehicleSummaries.length) {
                return false;
            }
        } else {
            return this.isExpired(insurancePolicy.insurancePolicyVehicleSummaries) &&
                !this.isPolicyCancelled(insurancePolicy)
        }
    }

    private sortVehicleSummaries(insurancePolicy: InsurancePolicySummary): InsurancePolicySummary {
        insurancePolicy.insurancePolicyVehicleSummaries.sort((a, b) => {
            if (moment(a.expiryDate).isBefore(b.expiryDate)) {
                return -1
            }
            if (moment(a.expiryDate).isAfter(b.expiryDate)) {
                return 1;
            }
            return 0;

        });
        return insurancePolicy;
    }

    private hasActivePolicyItem(insurancePolicy: InsurancePolicySummary): boolean {
        const activeItems = insurancePolicy.insurancePolicyVehicleSummaries.filter((item) => {
            return moment(new Date()).isBefore(moment(item.expiryDate)) && !item.cancelled;
        });
        return activeItems.length > 0 ? true : false;
    }
}
