import { LegalEntityIdentifier } from "./legal-entity-identifier.model";

export class TpiIdentifier extends LegalEntityIdentifier{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.identification.TpiIdentifier";
    }

    public tpiIdentifier: string;
}
