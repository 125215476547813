import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {

    transform(date: any): string {
        return moment(date).format("DD MMM YYYY");
    }

}
