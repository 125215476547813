import { Criteria } from '../../../../criteria/models/criteria.model';
import { UnderwriterIdentifier } from '../../identification/models/underwriter-identifier.model';
import { InsuranceCategory } from '../../models/insurance-category.model';

export class PolicyWordingUnderwriterCategoryCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.PolicyWordingUnderwriterCategoryCriteria";
    } 

    public insuranceCategory: InsuranceCategory;
    public insuranceVehicleCategory: string[];
    public underwriterIdentifier: UnderwriterIdentifier;
}
