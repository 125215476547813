export const africaJson: any = {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "Ethiopia",
                sov_a3: "ETH",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Ethiopia",
                adm0_a3: "ETH",
                geou_dif: 0,
                geounit: "Ethiopia",
                gu_a3: "ETH",
                su_dif: 0,
                subunit: "Ethiopia",
                su_a3: "ETH",
                brk_diff: 0,
                name: "Ethiopia",
                name_long: "Ethiopia",
                brk_a3: "ETH",
                brk_name: "Ethiopia",
                brk_group: null,
                abbrev: "Eth.",
                postal: "ET",
                formal_en: "Federal Democratic Republic of Ethiopia",
                formal_fr: null,
                name_ciawf: "Ethiopia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Ethiopia",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 4,
                mapcolor9: 1,
                mapcolor13: 13,
                pop_est: 112078730,
                pop_rank: 17,
                pop_year: 2019,
                gdp_md: 95912,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "ET",
                iso_a2: "ET",
                iso_a2_eh: "ET",
                iso_a3: "ETH",
                iso_a3_eh: "ETH",
                iso_n3: "231",
                iso_n3_eh: "231",
                un_a3: "231",
                wb_a2: "ET",
                wb_a3: "ETH",
                woe_id: 23424808,
                woe_id_eh: 23424808,
                woe_note: "Exact WOE match as country",
                adm0_iso: "ETH",
                adm0_diff: null,
                adm0_tlc: "ETH",
                adm0_a3_us: "ETH",
                adm0_a3_fr: "ETH",
                adm0_a3_ru: "ETH",
                adm0_a3_es: "ETH",
                adm0_a3_cn: "ETH",
                adm0_a3_tw: "ETH",
                adm0_a3_in: "ETH",
                adm0_a3_np: "ETH",
                adm0_a3_pk: "ETH",
                adm0_a3_de: "ETH",
                adm0_a3_gb: "ETH",
                adm0_a3_br: "ETH",
                adm0_a3_il: "ETH",
                adm0_a3_ps: "ETH",
                adm0_a3_sa: "ETH",
                adm0_a3_eg: "ETH",
                adm0_a3_ma: "ETH",
                adm0_a3_pt: "ETH",
                adm0_a3_ar: "ETH",
                adm0_a3_jp: "ETH",
                adm0_a3_ko: "ETH",
                adm0_a3_vn: "ETH",
                adm0_a3_tr: "ETH",
                adm0_a3_id: "ETH",
                adm0_a3_pl: "ETH",
                adm0_a3_gr: "ETH",
                adm0_a3_it: "ETH",
                adm0_a3_nl: "ETH",
                adm0_a3_se: "ETH",
                adm0_a3_bd: "ETH",
                adm0_a3_ua: "ETH",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2,
                max_label: 7,
                label_x: 39.0886,
                label_y: 8.032795,
                ne_id: 1159320617,
                wikidataid: "Q115",
                name_ar: "إثيوبيا",
                name_bn: "ইথিওপিয়া",
                name_de: "Äthiopien",
                name_en: "Ethiopia",
                name_es: "Etiopía",
                name_fa: "اتیوپی",
                name_fr: "Éthiopie",
                name_el: "Αιθιοπία",
                name_he: "אתיופיה",
                name_hi: "इथियोपिया",
                name_hu: "Etiópia",
                name_id: "Ethiopia",
                name_it: "Etiopia",
                name_ja: "エチオピア",
                name_ko: "에티오피아",
                name_nl: "Ethiopië",
                name_pl: "Etiopia",
                name_pt: "Etiópia",
                name_ru: "Эфиопия",
                name_sv: "Etiopien",
                name_tr: "Etiyopya",
                name_uk: "Ефіопія",
                name_ur: "ایتھوپیا",
                name_vi: "Ethiopia",
                name_zh: "埃塞俄比亚",
                name_zht: "衣索比亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ETH.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [47.78942, 8.003],
                        [44.9636, 5.00162],
                        [43.66087, 4.95755],
                        [42.76967, 4.25259],
                        [42.12861, 4.23413],
                        [41.85508309264397, 3.918911920483727],
                        [41.1718, 3.91909],
                        [40.76848, 4.25702],
                        [39.85494, 3.83879],
                        [39.55938425876585, 3.42206],
                        [38.89251, 3.50074],
                        [38.67114, 3.61607],
                        [38.43697, 3.58851],
                        [38.120915, 3.598605],
                        [36.85509323800812, 4.447864127672769],
                        [36.159078632855646, 4.447864127672769],
                        [35.817447662353516, 4.77696566346189],
                        [35.817447662353516, 5.338232082790797],
                        [35.29800711823298, 5.506],
                        [34.70702, 6.594220000000121],
                        [34.25032, 6.82607],
                        [34.0751, 7.22595],
                        [33.568290000000104, 7.71334],
                        [32.95418, 7.784970000000101],
                        [33.29480000000012, 8.35458],
                        [33.82550000000015, 8.37916],
                        [33.97498, 8.68456],
                        [33.96162, 9.58358],
                        [34.25745, 10.63009],
                        [34.73115000000013, 10.910170000000107],
                        [34.83163000000013, 11.318960000000118],
                        [35.26049, 12.08286],
                        [35.86363, 12.57828],
                        [36.27022, 13.563330000000121],
                        [36.42951, 14.42211],
                        [37.59377, 14.2131],
                        [37.90607000000011, 14.959430000000168],
                        [38.51295, 14.50547],
                        [39.0994, 14.74064],
                        [39.34061, 14.53155],
                        [40.02625000000012, 14.51959],
                        [40.8966, 14.118640000000141],
                        [41.1552, 13.77333],
                        [41.59856, 13.452090000000112],
                        [42.00975, 12.86582],
                        [42.35156000000012, 12.542230000000131],
                        [42.000000000000114, 12.100000000000136],
                        [41.66176000000013, 11.6312],
                        [41.73959000000019, 11.355110000000138],
                        [41.755570000000205, 11.050910000000101],
                        [42.31414000000012, 11.0342],
                        [42.55493000000013, 11.105110000000195],
                        [42.77685184100096, 10.92687856693442],
                        [42.55876, 10.57258000000013],
                        [42.92812, 10.021940000000143],
                        [43.29699000000011, 9.540480000000173],
                        [43.67875, 9.18358000000012],
                        [46.94834, 7.99688],
                        [47.78942, 8.003],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "Kenya",
                sov_a3: "KEN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Kenya",
                adm0_a3: "KEN",
                geou_dif: 0,
                geounit: "Kenya",
                gu_a3: "KEN",
                su_dif: 0,
                subunit: "Kenya",
                su_a3: "KEN",
                brk_diff: 0,
                name: "Kenya",
                name_long: "Kenya",
                brk_a3: "KEN",
                brk_name: "Kenya",
                brk_group: null,
                abbrev: "Ken.",
                postal: "KE",
                formal_en: "Republic of Kenya",
                formal_fr: null,
                name_ciawf: "Kenya",
                note_adm0: null,
                note_brk: null,
                name_sort: "Kenya",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 2,
                mapcolor9: 7,
                mapcolor13: 3,
                pop_est: 52573973,
                pop_rank: 16,
                pop_year: 2019,
                gdp_md: 95503,
                gdp_year: 2019,
                economy: "5. Emerging region: G20",
                income_grp: "5. Low income",
                fips_10: "KE",
                iso_a2: "KE",
                iso_a2_eh: "KE",
                iso_a3: "KEN",
                iso_a3_eh: "KEN",
                iso_n3: "404",
                iso_n3_eh: "404",
                un_a3: "404",
                wb_a2: "KE",
                wb_a3: "KEN",
                woe_id: 23424863,
                woe_id_eh: 23424863,
                woe_note: "Exact WOE match as country",
                adm0_iso: "KEN",
                adm0_diff: null,
                adm0_tlc: "KEN",
                adm0_a3_us: "KEN",
                adm0_a3_fr: "KEN",
                adm0_a3_ru: "KEN",
                adm0_a3_es: "KEN",
                adm0_a3_cn: "KEN",
                adm0_a3_tw: "KEN",
                adm0_a3_in: "KEN",
                adm0_a3_np: "KEN",
                adm0_a3_pk: "KEN",
                adm0_a3_de: "KEN",
                adm0_a3_gb: "KEN",
                adm0_a3_br: "KEN",
                adm0_a3_il: "KEN",
                adm0_a3_ps: "KEN",
                adm0_a3_sa: "KEN",
                adm0_a3_eg: "KEN",
                adm0_a3_ma: "KEN",
                adm0_a3_pt: "KEN",
                adm0_a3_ar: "KEN",
                adm0_a3_jp: "KEN",
                adm0_a3_ko: "KEN",
                adm0_a3_vn: "KEN",
                adm0_a3_tr: "KEN",
                adm0_a3_id: "KEN",
                adm0_a3_pl: "KEN",
                adm0_a3_gr: "KEN",
                adm0_a3_it: "KEN",
                adm0_a3_nl: "KEN",
                adm0_a3_se: "KEN",
                adm0_a3_bd: "KEN",
                adm0_a3_ua: "KEN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 1.7,
                max_label: 6.7,
                label_x: 37.907632,
                label_y: 0.549043,
                ne_id: 1159320971,
                wikidataid: "Q114",
                name_ar: "كينيا",
                name_bn: "কেনিয়া",
                name_de: "Kenia",
                name_en: "Kenya",
                name_es: "Kenia",
                name_fa: "کنیا",
                name_fr: "Kenya",
                name_el: "Κένυα",
                name_he: "קניה",
                name_hi: "कीनिया",
                name_hu: "Kenya",
                name_id: "Kenya",
                name_it: "Kenya",
                name_ja: "ケニア",
                name_ko: "케냐",
                name_nl: "Kenia",
                name_pl: "Kenia",
                name_pt: "Quénia",
                name_ru: "Кения",
                name_sv: "Kenya",
                name_tr: "Kenya",
                name_uk: "Кенія",
                name_ur: "کینیا",
                name_vi: "Kenya",
                name_zh: "肯尼亚",
                name_zht: "肯亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "KEN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [39.20222, -4.67677],
                        [37.7669, -3.67712],
                        [37.69869, -3.09699],
                        [34.07262, -1.05982],
                        [33.90371119710453, -0.95],
                        [33.893568969666944, 0.109813537861896],
                        [34.18, 0.515],
                        [34.6721, 1.17694],
                        [35.03599, 1.90584],
                        [34.59607, 3.053740000000118],
                        [34.47913, 3.5556],
                        [34.005, 4.249884947362048],
                        [34.62019626785388, 4.847122742081988],
                        [35.29800711823298, 5.506],
                        [35.817447662353516, 5.338232082790797],
                        [35.817447662353516, 4.77696566346189],
                        [36.159078632855646, 4.447864127672769],
                        [36.85509323800812, 4.447864127672769],
                        [38.120915, 3.598605],
                        [38.43697, 3.58851],
                        [38.67114, 3.61607],
                        [38.89251, 3.50074],
                        [39.55938425876585, 3.42206],
                        [39.85494, 3.83879],
                        [40.76848, 4.25702],
                        [41.1718, 3.91909],
                        [41.85508309264397, 3.918911920483727],
                        [40.98105, 2.78452],
                        [40.993, -0.85829],
                        [41.58513, -1.68325],
                        [40.88477, -2.08255],
                        [40.63785, -2.49979],
                        [40.26304, -2.57309],
                        [40.12119, -3.27768],
                        [39.80006, -3.68116],
                        [39.60489, -4.34653],
                        [39.20222, -4.67677],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Somalia",
                sov_a3: "SOM",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Somalia",
                adm0_a3: "SOM",
                geou_dif: 0,
                geounit: "Somalia",
                gu_a3: "SOM",
                su_dif: 0,
                subunit: "Somalia",
                su_a3: "SOM",
                brk_diff: 0,
                name: "Somalia",
                name_long: "Somalia",
                brk_a3: "SOM",
                brk_name: "Somalia",
                brk_group: null,
                abbrev: "Som.",
                postal: "SO",
                formal_en: "Federal Republic of Somalia",
                formal_fr: null,
                name_ciawf: "Somalia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Somalia",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 8,
                mapcolor9: 6,
                mapcolor13: 7,
                pop_est: 10192317.3,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 4719,
                gdp_year: 2016,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "SO",
                iso_a2: "SO",
                iso_a2_eh: "SO",
                iso_a3: "SOM",
                iso_a3_eh: "SOM",
                iso_n3: "706",
                iso_n3_eh: "706",
                un_a3: "706",
                wb_a2: "SO",
                wb_a3: "SOM",
                woe_id: -90,
                woe_id_eh: 23424949,
                woe_note:
                    "Includes Somaliland (2347021, 2347020, 2347017 and portion of 2347016)",
                adm0_iso: "SOM",
                adm0_diff: null,
                adm0_tlc: "SOM",
                adm0_a3_us: "SOM",
                adm0_a3_fr: "SOM",
                adm0_a3_ru: "SOM",
                adm0_a3_es: "SOM",
                adm0_a3_cn: "SOM",
                adm0_a3_tw: "SOM",
                adm0_a3_in: "SOM",
                adm0_a3_np: "SOM",
                adm0_a3_pk: "SOM",
                adm0_a3_de: "SOM",
                adm0_a3_gb: "SOM",
                adm0_a3_br: "SOM",
                adm0_a3_il: "SOM",
                adm0_a3_ps: "SOM",
                adm0_a3_sa: "SOM",
                adm0_a3_eg: "SOM",
                adm0_a3_ma: "SOM",
                adm0_a3_pt: "SOM",
                adm0_a3_ar: "SOM",
                adm0_a3_jp: "SOM",
                adm0_a3_ko: "SOM",
                adm0_a3_vn: "SOM",
                adm0_a3_tr: "SOM",
                adm0_a3_id: "SOM",
                adm0_a3_pl: "SOM",
                adm0_a3_gr: "SOM",
                adm0_a3_it: "SOM",
                adm0_a3_nl: "SOM",
                adm0_a3_se: "SOM",
                adm0_a3_bd: "SOM",
                adm0_a3_ua: "SOM",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 45.19238,
                label_y: 3.568925,
                ne_id: 1159321261,
                wikidataid: "Q1045",
                name_ar: "الصومال",
                name_bn: "সোমালিয়া",
                name_de: "Somalia",
                name_en: "Somalia",
                name_es: "Somalia",
                name_fa: "سومالی",
                name_fr: "Somalie",
                name_el: "Σομαλία",
                name_he: "סומליה",
                name_hi: "सोमालिया",
                name_hu: "Szomália",
                name_id: "Somalia",
                name_it: "Somalia",
                name_ja: "ソマリア",
                name_ko: "소말리아",
                name_nl: "Somalië",
                name_pl: "Somalia",
                name_pt: "Somália",
                name_ru: "Сомали",
                name_sv: "Somalia",
                name_tr: "Somali",
                name_uk: "Сомалі",
                name_ur: "صومالیہ",
                name_vi: "Somalia",
                name_zh: "索马里",
                name_zht: "索馬利亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SOM.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [41.58513, -1.68325],
                        [40.993, -0.85829],
                        [40.98105, 2.78452],
                        [41.85508309264397, 3.918911920483727],
                        [42.12861, 4.23413],
                        [42.76967, 4.25259],
                        [43.66087, 4.95755],
                        [44.9636, 5.00162],
                        [47.78942, 8.003],
                        [48.48673587422695, 8.837626247589995],
                        [48.93812951029645, 9.451748968946617],
                        [48.93823286316103, 9.973500067581512],
                        [48.938491245322496, 10.982327378783467],
                        [48.94200524271835, 11.394266058798138],
                        [48.94820475850974, 11.410617281697963],
                        [48.94820475850985, 11.41061728169797],
                        [49.26776, 11.43033],
                        [49.72862, 11.5789],
                        [50.25878, 11.67957],
                        [50.73202, 12.0219],
                        [51.1112, 12.02464],
                        [51.13387, 11.74815],
                        [51.04153, 11.16651],
                        [51.04531, 10.6409],
                        [50.83418, 10.27972],
                        [50.55239, 9.19874],
                        [50.07092, 8.08173],
                        [49.4527, 6.80466],
                        [48.59455, 5.33911],
                        [47.74079, 4.2194],
                        [46.56476, 2.85529],
                        [45.56399, 2.04576],
                        [44.06815, 1.05283],
                        [43.13597, 0.2922],
                        [42.04157, -0.91916],
                        [41.81095, -1.44647],
                        [41.58513, -1.68325],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "South Sudan",
                sov_a3: "SDS",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "South Sudan",
                adm0_a3: "SDS",
                geou_dif: 0,
                geounit: "South Sudan",
                gu_a3: "SDS",
                su_dif: 0,
                subunit: "South Sudan",
                su_a3: "SDS",
                brk_diff: 0,
                name: "S. Sudan",
                name_long: "South Sudan",
                brk_a3: "SDS",
                brk_name: "S. Sudan",
                brk_group: null,
                abbrev: "S. Sud.",
                postal: "SS",
                formal_en: "Republic of South Sudan",
                formal_fr: null,
                name_ciawf: "South Sudan",
                note_adm0: null,
                note_brk: null,
                name_sort: "South Sudan",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 3,
                mapcolor9: 3,
                mapcolor13: 5,
                pop_est: 11062113,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 11998,
                gdp_year: 2015,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "-99",
                iso_a2: "SS",
                iso_a2_eh: "SS",
                iso_a3: "SSD",
                iso_a3_eh: "SSD",
                iso_n3: "728",
                iso_n3_eh: "728",
                un_a3: "728",
                wb_a2: "SS",
                wb_a3: "SSD",
                woe_id: -99,
                woe_id_eh: -99,
                woe_note:
                    "Includes states of 20069899, 20069897, 20069898, 20069901, 20069909, and 20069908 but maybe more?",
                adm0_iso: "SSD",
                adm0_diff: "1",
                adm0_tlc: "SDS",
                adm0_a3_us: "SDS",
                adm0_a3_fr: "SDS",
                adm0_a3_ru: "SDS",
                adm0_a3_es: "SDS",
                adm0_a3_cn: "SDS",
                adm0_a3_tw: "SDS",
                adm0_a3_in: "SDS",
                adm0_a3_np: "SDS",
                adm0_a3_pk: "SDS",
                adm0_a3_de: "SDS",
                adm0_a3_gb: "SDS",
                adm0_a3_br: "SDS",
                adm0_a3_il: "SDS",
                adm0_a3_ps: "SDS",
                adm0_a3_sa: "SDS",
                adm0_a3_eg: "SDS",
                adm0_a3_ma: "SDS",
                adm0_a3_pt: "SDS",
                adm0_a3_ar: "SDS",
                adm0_a3_jp: "SDS",
                adm0_a3_ko: "SDS",
                adm0_a3_vn: "SDS",
                adm0_a3_tr: "SDS",
                adm0_a3_id: "SDS",
                adm0_a3_pl: "SDS",
                adm0_a3_gr: "SDS",
                adm0_a3_it: "SDS",
                adm0_a3_nl: "SDS",
                adm0_a3_se: "SDS",
                adm0_a3_bd: "SDS",
                adm0_a3_ua: "SDS",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 11,
                abbrev_len: 7,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 30.390151,
                label_y: 7.230477,
                ne_id: 1159321235,
                wikidataid: "Q958",
                name_ar: "جنوب السودان",
                name_bn: "দক্ষিণ সুদান",
                name_de: "Südsudan",
                name_en: "South Sudan",
                name_es: "Sudán del Sur",
                name_fa: "سودان جنوبی",
                name_fr: "Soudan du Sud",
                name_el: "Νότιο Σουδάν",
                name_he: "דרום סודאן",
                name_hi: "दक्षिण सूडान",
                name_hu: "Dél-Szudán",
                name_id: "Sudan Selatan",
                name_it: "Sudan del Sud",
                name_ja: "南スーダン",
                name_ko: "남수단",
                name_nl: "Zuid-Soedan",
                name_pl: "Sudan Południowy",
                name_pt: "Sudão do Sul",
                name_ru: "Южный Судан",
                name_sv: "Sydsudan",
                name_tr: "Güney Sudan",
                name_uk: "Південний Судан",
                name_ur: "جنوبی سوڈان",
                name_vi: "Nam Sudan",
                name_zh: "南苏丹",
                name_zht: "南蘇丹",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SSD.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.833852421715427, 3.509171604222463],
                        [29.953500197069474, 4.173699042167684],
                        [29.71599531425602, 4.600804755060153],
                        [29.1590784034465, 4.389267279473231],
                        [28.696677687298802, 4.455077215996937],
                        [28.428993768026913, 4.287154649264494],
                        [27.97997724784281, 4.408413397637375],
                        [27.37422610851749, 5.233944403500061],
                        [27.21340905122517, 5.550953477394557],
                        [26.465909458123235, 5.94671743410187],
                        [26.213418409945117, 6.546603298362072],
                        [25.79664798351118, 6.979315904158071],
                        [25.124130893664727, 7.500085150579437],
                        [25.11493248871679, 7.825104071479174],
                        [24.567369012152085, 8.229187933785468],
                        [23.886979580860668, 8.619729712933065],
                        [24.19406772118765, 8.728696472403897],
                        [24.53741516360202, 8.91753756573172],
                        [24.794925745412684, 9.810240916008695],
                        [25.069603699343986, 10.273759963267992],
                        [25.790633328413946, 10.411098940233728],
                        [25.962307049621018, 10.136420986302426],
                        [26.477328213242515, 9.552730334198088],
                        [26.752006167173818, 9.466893473594496],
                        [27.112520981708883, 9.638567194801624],
                        [27.833550610778786, 9.60423245056029],
                        [27.970889587744352, 9.398223985111656],
                        [28.966597170745786, 9.398223985111656],
                        [29.000931914987177, 9.60423245056029],
                        [29.515953078608618, 9.793073543888056],
                        [29.61895731133285, 10.084918869940225],
                        [29.996639497988554, 10.290927335388687],
                        [30.837840731903384, 9.70723668328452],
                        [31.35286189552488, 9.810240916008695],
                        [31.850715687025513, 10.531270545078826],
                        [32.400071594888345, 11.080626452941488],
                        [32.31423473428475, 11.68148447716652],
                        [32.073891524594785, 11.973329803218519],
                        [32.67474954881965, 12.02483191958072],
                        [32.743419037302544, 12.248007757149992],
                        [33.206938084561784, 12.179338268667095],
                        [33.086766479716744, 11.441141267476496],
                        [33.206938084561784, 10.720111638406593],
                        [33.72195924818311, 10.325262079630193],
                        [33.84213085302815, 9.981914637215993],
                        [33.82496348090751, 9.484060845715362],
                        [33.963392794971185, 9.464285229420625],
                        [33.97498, 8.68456],
                        [33.82550000000015, 8.37916],
                        [33.29480000000012, 8.35458],
                        [32.95418, 7.784970000000101],
                        [33.568290000000104, 7.71334],
                        [34.0751, 7.22595],
                        [34.25032, 6.82607],
                        [34.70702, 6.594220000000121],
                        [35.29800711823298, 5.506],
                        [34.62019626785388, 4.847122742081988],
                        [34.005, 4.249884947362048],
                        [33.3900000000001, 3.79],
                        [32.68642, 3.79232],
                        [31.88145, 3.55827],
                        [31.24556, 3.7819],
                        [30.833852421715427, 3.509171604222463],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Malawi",
                sov_a3: "MWI",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Malawi",
                adm0_a3: "MWI",
                geou_dif: 0,
                geounit: "Malawi",
                gu_a3: "MWI",
                su_dif: 0,
                subunit: "Malawi",
                su_a3: "MWI",
                brk_diff: 0,
                name: "Malawi",
                name_long: "Malawi",
                brk_a3: "MWI",
                brk_name: "Malawi",
                brk_group: null,
                abbrev: "Mal.",
                postal: "MW",
                formal_en: "Republic of Malawi",
                formal_fr: null,
                name_ciawf: "Malawi",
                note_adm0: null,
                note_brk: null,
                name_sort: "Malawi",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 3,
                mapcolor9: 4,
                mapcolor13: 5,
                pop_est: 18628747,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 7666,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "MI",
                iso_a2: "MW",
                iso_a2_eh: "MW",
                iso_a3: "MWI",
                iso_a3_eh: "MWI",
                iso_n3: "454",
                iso_n3_eh: "454",
                un_a3: "454",
                wb_a2: "MW",
                wb_a3: "MWI",
                woe_id: 23424889,
                woe_id_eh: 23424889,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MWI",
                adm0_diff: null,
                adm0_tlc: "MWI",
                adm0_a3_us: "MWI",
                adm0_a3_fr: "MWI",
                adm0_a3_ru: "MWI",
                adm0_a3_es: "MWI",
                adm0_a3_cn: "MWI",
                adm0_a3_tw: "MWI",
                adm0_a3_in: "MWI",
                adm0_a3_np: "MWI",
                adm0_a3_pk: "MWI",
                adm0_a3_de: "MWI",
                adm0_a3_gb: "MWI",
                adm0_a3_br: "MWI",
                adm0_a3_il: "MWI",
                adm0_a3_ps: "MWI",
                adm0_a3_sa: "MWI",
                adm0_a3_eg: "MWI",
                adm0_a3_ma: "MWI",
                adm0_a3_pt: "MWI",
                adm0_a3_ar: "MWI",
                adm0_a3_jp: "MWI",
                adm0_a3_ko: "MWI",
                adm0_a3_vn: "MWI",
                adm0_a3_tr: "MWI",
                adm0_a3_id: "MWI",
                adm0_a3_pl: "MWI",
                adm0_a3_gr: "MWI",
                adm0_a3_it: "MWI",
                adm0_a3_nl: "MWI",
                adm0_a3_se: "MWI",
                adm0_a3_bd: "MWI",
                adm0_a3_ua: "MWI",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 33.608082,
                label_y: -13.386737,
                ne_id: 1159321081,
                wikidataid: "Q1020",
                name_ar: "مالاوي",
                name_bn: "মালাউই",
                name_de: "Malawi",
                name_en: "Malawi",
                name_es: "Malaui",
                name_fa: "مالاوی",
                name_fr: "Malawi",
                name_el: "Μαλάουι",
                name_he: "מלאווי",
                name_hi: "मलावी",
                name_hu: "Malawi",
                name_id: "Malawi",
                name_it: "Malawi",
                name_ja: "マラウイ",
                name_ko: "말라위",
                name_nl: "Malawi",
                name_pl: "Malawi",
                name_pt: "Malawi",
                name_ru: "Малави",
                name_sv: "Malawi",
                name_tr: "Malavi",
                name_uk: "Малаві",
                name_ur: "ملاوی",
                name_vi: "Malawi",
                name_zh: "马拉维",
                name_zht: "馬拉威",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MWI.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [32.75937544122132, -9.23059905358906],
                        [33.73972, -9.41715],
                        [33.940837724096525, -9.693673841980285],
                        [34.28, -10.16],
                        [34.55998904799935, -11.520020033415925],
                        [34.28000613784198, -12.280025323132506],
                        [34.55998904799935, -13.579997653866876],
                        [34.907151320136165, -13.565424899960568],
                        [35.26795617039801, -13.887834161029566],
                        [35.68684533055594, -14.611045830954332],
                        [35.77190473810836, -15.896858819240727],
                        [35.339062941231646, -16.10744028083011],
                        [35.033810255683534, -16.801299737213093],
                        [34.38129194513405, -16.183559665596043],
                        [34.307291294092096, -15.478641452702597],
                        [34.51766604995231, -15.013708591372612],
                        [34.45963341648854, -14.613009535381423],
                        [34.064825473778626, -14.35995004644812],
                        [33.789700148256685, -14.45183074306307],
                        [33.214024692525214, -13.971860039936153],
                        [32.68816531752313, -13.712857761289277],
                        [32.991764357237884, -12.783870537978274],
                        [33.306422153463075, -12.435778090060218],
                        [33.114289178201915, -11.607198174692314],
                        [33.315310499817286, -10.796549981329697],
                        [33.48568769708359, -10.525558770391115],
                        [33.2313879737753, -9.6767216935648],
                        [32.75937544122132, -9.23059905358906],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "United Republic of Tanzania",
                sov_a3: "TZA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "United Republic of Tanzania",
                adm0_a3: "TZA",
                geou_dif: 0,
                geounit: "Tanzania",
                gu_a3: "TZA",
                su_dif: 0,
                subunit: "Tanzania",
                su_a3: "TZA",
                brk_diff: 0,
                name: "Tanzania",
                name_long: "Tanzania",
                brk_a3: "TZA",
                brk_name: "Tanzania",
                brk_group: null,
                abbrev: "Tanz.",
                postal: "TZ",
                formal_en: "United Republic of Tanzania",
                formal_fr: null,
                name_ciawf: "Tanzania",
                note_adm0: null,
                note_brk: null,
                name_sort: "Tanzania",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 6,
                mapcolor9: 2,
                mapcolor13: 2,
                pop_est: 58005463,
                pop_rank: 16,
                pop_year: 2019,
                gdp_md: 63177,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "TZ",
                iso_a2: "TZ",
                iso_a2_eh: "TZ",
                iso_a3: "TZA",
                iso_a3_eh: "TZA",
                iso_n3: "834",
                iso_n3_eh: "834",
                un_a3: "834",
                wb_a2: "TZ",
                wb_a3: "TZA",
                woe_id: 23424973,
                woe_id_eh: 23424973,
                woe_note: "Exact WOE match as country",
                adm0_iso: "TZA",
                adm0_diff: null,
                adm0_tlc: "TZA",
                adm0_a3_us: "TZA",
                adm0_a3_fr: "TZA",
                adm0_a3_ru: "TZA",
                adm0_a3_es: "TZA",
                adm0_a3_cn: "TZA",
                adm0_a3_tw: "TZA",
                adm0_a3_in: "TZA",
                adm0_a3_np: "TZA",
                adm0_a3_pk: "TZA",
                adm0_a3_de: "TZA",
                adm0_a3_gb: "TZA",
                adm0_a3_br: "TZA",
                adm0_a3_il: "TZA",
                adm0_a3_ps: "TZA",
                adm0_a3_sa: "TZA",
                adm0_a3_eg: "TZA",
                adm0_a3_ma: "TZA",
                adm0_a3_pt: "TZA",
                adm0_a3_ar: "TZA",
                adm0_a3_jp: "TZA",
                adm0_a3_ko: "TZA",
                adm0_a3_vn: "TZA",
                adm0_a3_tr: "TZA",
                adm0_a3_id: "TZA",
                adm0_a3_pl: "TZA",
                adm0_a3_gr: "TZA",
                adm0_a3_it: "TZA",
                adm0_a3_nl: "TZA",
                adm0_a3_se: "TZA",
                adm0_a3_bd: "TZA",
                adm0_a3_ua: "TZA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 34.959183,
                label_y: -6.051866,
                ne_id: 1159321337,
                wikidataid: "Q924",
                name_ar: "تنزانيا",
                name_bn: "তানজানিয়া",
                name_de: "Tansania",
                name_en: "Tanzania",
                name_es: "Tanzania",
                name_fa: "تانزانیا",
                name_fr: "Tanzanie",
                name_el: "Τανζανία",
                name_he: "טנזניה",
                name_hi: "तंज़ानिया",
                name_hu: "Tanzánia",
                name_id: "Tanzania",
                name_it: "Tanzania",
                name_ja: "タンザニア",
                name_ko: "탄자니아",
                name_nl: "Tanzania",
                name_pl: "Tanzania",
                name_pt: "Tanzânia",
                name_ru: "Танзания",
                name_sv: "Tanzania",
                name_tr: "Tanzanya",
                name_uk: "Танзанія",
                name_ur: "تنزانیہ",
                name_vi: "Tanzania",
                name_zh: "坦桑尼亚",
                name_zht: "坦尚尼亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "TZA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [33.90371119710453, -0.95],
                        [34.07262, -1.05982],
                        [37.69869, -3.09699],
                        [37.7669, -3.67712],
                        [39.20222, -4.67677],
                        [38.74054, -5.90895],
                        [38.79977, -6.47566],
                        [39.44, -6.839999999999861],
                        [39.47000000000014, -7.1],
                        [39.19469, -7.7039],
                        [39.25203, -8.00781],
                        [39.18652, -8.48551],
                        [39.53574, -9.112369999999885],
                        [39.9496, -10.0984],
                        [40.316586229110854, -10.317097752817492],
                        [40.31659, -10.317099999999868],
                        [39.521, -10.89688],
                        [38.42755659358775, -11.285202325081656],
                        [37.82764, -11.26879],
                        [37.47129, -11.56876],
                        [36.775150994622805, -11.594537448780805],
                        [36.51408165868426, -11.720938002166735],
                        [35.31239790216904, -11.439146416879147],
                        [34.55998904799935, -11.520020033415925],
                        [34.28, -10.16],
                        [33.940837724096525, -9.693673841980285],
                        [33.73972, -9.41715],
                        [32.75937544122132, -9.23059905358906],
                        [32.19186486179194, -8.930358981973257],
                        [31.556348097466497, -8.762048841998642],
                        [31.15775133695005, -8.594578747317366],
                        [30.740009731422095, -8.34000593035372],
                        [30.74001549655179, -8.340007419470915],
                        [30.199996779101696, -7.079980970898163],
                        [29.620032179490014, -6.520015150583426],
                        [29.419992710088167, -5.939998874539434],
                        [29.519986606572928, -5.419978936386315],
                        [29.339997592900346, -4.499983412294092],
                        [29.753512404099865, -4.452389418153302],
                        [30.11632, -4.09012],
                        [30.50554, -3.56858],
                        [30.75224, -3.35931],
                        [30.74301, -3.03431],
                        [30.52766, -2.80762],
                        [30.469673645761223, -2.41385475710134],
                        [30.46967, -2.41383],
                        [30.75830895358311, -2.287250257988369],
                        [30.816134881317712, -1.698914076345389],
                        [30.419104852019245, -1.134659112150416],
                        [30.769860000000108, -1.01455],
                        [31.86617, -1.02736],
                        [33.90371119710453, -0.95],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 5,
                sovereignt: "Somaliland",
                sov_a3: "SOL",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Somaliland",
                adm0_a3: "SOL",
                geou_dif: 0,
                geounit: "Somaliland",
                gu_a3: "SOL",
                su_dif: 0,
                subunit: "Somaliland",
                su_a3: "SOL",
                brk_diff: 0,
                name: "Somaliland",
                name_long: "Somaliland",
                brk_a3: "SOL",
                brk_name: "Somaliland",
                brk_group: null,
                abbrev: "Solnd.",
                postal: "SL",
                formal_en: "Republic of Somaliland",
                formal_fr: null,
                name_ciawf: null,
                note_adm0: "Disputed",
                note_brk: "Self admin.; Claimed by Somalia",
                name_sort: "Somaliland",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 6,
                mapcolor9: 5,
                mapcolor13: 2,
                pop_est: 5096159,
                pop_rank: 13,
                pop_year: 2014,
                gdp_md: 17836,
                gdp_year: 2013,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "-99",
                iso_a2: "-99",
                iso_a2_eh: "-99",
                iso_a3: "-99",
                iso_a3_eh: "-99",
                iso_n3: "-99",
                iso_n3_eh: "-99",
                un_a3: "-099",
                wb_a2: "-99",
                wb_a3: "-99",
                woe_id: -99,
                woe_id_eh: -99,
                woe_note:
                    "Includes old states of 2347021, 2347020, 2347017 and portion of 2347016.",
                adm0_iso: "SOM",
                adm0_diff: "1",
                adm0_tlc: "SOL",
                adm0_a3_us: "SOM",
                adm0_a3_fr: "SOM",
                adm0_a3_ru: "SOM",
                adm0_a3_es: "SOM",
                adm0_a3_cn: "SOM",
                adm0_a3_tw: "SOL",
                adm0_a3_in: "SOM",
                adm0_a3_np: "SOM",
                adm0_a3_pk: "SOM",
                adm0_a3_de: "SOM",
                adm0_a3_gb: "SOM",
                adm0_a3_br: "SOM",
                adm0_a3_il: "SOM",
                adm0_a3_ps: "SOM",
                adm0_a3_sa: "SOM",
                adm0_a3_eg: "SOM",
                adm0_a3_ma: "SOM",
                adm0_a3_pt: "SOM",
                adm0_a3_ar: "SOM",
                adm0_a3_jp: "SOM",
                adm0_a3_ko: "SOM",
                adm0_a3_vn: "SOM",
                adm0_a3_tr: "SOM",
                adm0_a3_id: "SOM",
                adm0_a3_pl: "SOM",
                adm0_a3_gr: "SOM",
                adm0_a3_it: "SOM",
                adm0_a3_nl: "SOM",
                adm0_a3_se: "SOM",
                adm0_a3_bd: "SOM",
                adm0_a3_ua: "SOM",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 10,
                long_len: 10,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 4,
                min_label: 4.5,
                max_label: 9,
                label_x: 46.731595,
                label_y: 9.443889,
                ne_id: 1159321259,
                wikidataid: "Q34754",
                name_ar: "صوماليلاند",
                name_bn: "সোমালিল্যান্ড",
                name_de: "Somaliland",
                name_en: "Somaliland",
                name_es: "Somalilandia",
                name_fa: "سومالیلند",
                name_fr: "Somaliland",
                name_el: "Σομαλιλάνδη",
                name_he: "סומלילנד",
                name_hi: "सोमालीदेश",
                name_hu: "Szomáliföld",
                name_id: "Somaliland",
                name_it: "Somaliland",
                name_ja: "ソマリランド",
                name_ko: "소말릴란드",
                name_nl: "Somaliland",
                name_pl: "Somaliland",
                name_pt: "Somalilândia",
                name_ru: "Сомалиленд",
                name_sv: "Somaliland",
                name_tr: "Somaliland",
                name_uk: "Сомаліленд",
                name_ur: "صومالی لینڈ",
                name_vi: "Somaliland",
                name_zh: "索马里兰",
                name_zht: "索馬利蘭",
                fclass_iso: "Unrecognized",
                tlc_diff: "1",
                fclass_tlc: "Admin-0 country",
                fclass_us: "Unrecognized",
                fclass_fr: "Unrecognized",
                fclass_ru: "Unrecognized",
                fclass_es: "Unrecognized",
                fclass_cn: "Unrecognized",
                fclass_tw: "Admin-0 country",
                fclass_in: "Unrecognized",
                fclass_np: "Unrecognized",
                fclass_pk: "Unrecognized",
                fclass_de: "Unrecognized",
                fclass_gb: "Unrecognized",
                fclass_br: "Unrecognized",
                fclass_il: "Unrecognized",
                fclass_ps: "Unrecognized",
                fclass_sa: "Unrecognized",
                fclass_eg: "Unrecognized",
                fclass_ma: "Unrecognized",
                fclass_pt: "Unrecognized",
                fclass_ar: "Unrecognized",
                fclass_jp: "Unrecognized",
                fclass_ko: "Unrecognized",
                fclass_vn: "Unrecognized",
                fclass_tr: "Unrecognized",
                fclass_id: "Unrecognized",
                fclass_pl: "Unrecognized",
                fclass_gr: "Unrecognized",
                fclass_it: "Unrecognized",
                fclass_nl: "Unrecognized",
                fclass_se: "Unrecognized",
                fclass_bd: "Unrecognized",
                fclass_ua: "Unrecognized",
                filename: "SOL.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [48.94820475850985, 11.41061728169797],
                        [48.94820475850974, 11.410617281697963],
                        [48.94200524271835, 11.394266058798138],
                        [48.938491245322496, 10.982327378783467],
                        [48.93823286316103, 9.973500067581512],
                        [48.93812951029645, 9.451748968946617],
                        [48.48673587422695, 8.837626247589995],
                        [47.78942, 8.003],
                        [46.94834, 7.99688],
                        [43.67875, 9.18358000000012],
                        [43.29699000000011, 9.540480000000173],
                        [42.92812, 10.021940000000143],
                        [42.55876, 10.57258000000013],
                        [42.77685184100096, 10.92687856693442],
                        [43.14530480324214, 11.462039699748857],
                        [43.470659620951665, 11.277709865763882],
                        [43.66666832863484, 10.86416921634816],
                        [44.11780358254282, 10.445538438351605],
                        [44.614259067570856, 10.442205308468942],
                        [45.55694054543915, 10.698029486529776],
                        [46.645401238803004, 10.816549383991173],
                        [47.525657586462785, 11.12722809492999],
                        [48.02159630716778, 11.193063869669743],
                        [48.37878380716927, 11.375481675660126],
                        [48.94820641459347, 11.41062164961852],
                        [48.94820475850985, 11.41061728169797],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Morocco",
                sov_a3: "MAR",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Morocco",
                adm0_a3: "MAR",
                geou_dif: 0,
                geounit: "Morocco",
                gu_a3: "MAR",
                su_dif: 0,
                subunit: "Morocco",
                su_a3: "MAR",
                brk_diff: 0,
                name: "Morocco",
                name_long: "Morocco",
                brk_a3: "MAR",
                brk_name: "Morocco",
                brk_group: null,
                abbrev: "Mor.",
                postal: "MA",
                formal_en: "Kingdom of Morocco",
                formal_fr: null,
                name_ciawf: "Morocco",
                note_adm0: null,
                note_brk: null,
                name_sort: "Morocco",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 2,
                mapcolor9: 3,
                mapcolor13: 9,
                pop_est: 36471769,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 119700,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "MO",
                iso_a2: "MA",
                iso_a2_eh: "MA",
                iso_a3: "MAR",
                iso_a3_eh: "MAR",
                iso_n3: "504",
                iso_n3_eh: "504",
                un_a3: "504",
                wb_a2: "MA",
                wb_a3: "MAR",
                woe_id: 23424893,
                woe_id_eh: 23424893,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MAR",
                adm0_diff: null,
                adm0_tlc: "MAR",
                adm0_a3_us: "MAR",
                adm0_a3_fr: "MAR",
                adm0_a3_ru: "MAR",
                adm0_a3_es: "MAR",
                adm0_a3_cn: "MAR",
                adm0_a3_tw: "MAR",
                adm0_a3_in: "MAR",
                adm0_a3_np: "MAR",
                adm0_a3_pk: "MAR",
                adm0_a3_de: "MAR",
                adm0_a3_gb: "MAR",
                adm0_a3_br: "MAR",
                adm0_a3_il: "MAR",
                adm0_a3_ps: "MAR",
                adm0_a3_sa: "MAR",
                adm0_a3_eg: "MAR",
                adm0_a3_ma: "MAR",
                adm0_a3_pt: "MAR",
                adm0_a3_ar: "MAR",
                adm0_a3_jp: "MAR",
                adm0_a3_ko: "MAR",
                adm0_a3_vn: "MAR",
                adm0_a3_tr: "MAR",
                adm0_a3_id: "MAR",
                adm0_a3_pl: "MAR",
                adm0_a3_gr: "MAR",
                adm0_a3_it: "MAR",
                adm0_a3_nl: "MAR",
                adm0_a3_se: "MAR",
                adm0_a3_bd: "MAR",
                adm0_a3_ua: "MAR",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.7,
                max_label: 8,
                label_x: -7.187296,
                label_y: 31.650723,
                ne_id: 1159321035,
                wikidataid: "Q1028",
                name_ar: "المغرب",
                name_bn: "মরক্কো",
                name_de: "Marokko",
                name_en: "Morocco",
                name_es: "Marruecos",
                name_fa: "مراکش",
                name_fr: "Maroc",
                name_el: "Μαρόκο",
                name_he: "מרוקו",
                name_hi: "मोरक्को",
                name_hu: "Marokkó",
                name_id: "Maroko",
                name_it: "Marocco",
                name_ja: "モロッコ",
                name_ko: "모로코",
                name_nl: "Marokko",
                name_pl: "Maroko",
                name_pt: "Marrocos",
                name_ru: "Марокко",
                name_sv: "Marocko",
                name_tr: "Fas",
                name_uk: "Марокко",
                name_ur: "مراکش",
                name_vi: "Maroc",
                name_zh: "摩洛哥",
                name_zht: "摩洛哥",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MAR.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-2.169913702798624, 35.16839630791668],
                        [-1.792985805661715, 34.527918606091305],
                        [-1.733454555661467, 33.91971283623212],
                        [-1.388049282222596, 32.86401500094138],
                        [-1.124551153966308, 32.65152151135713],
                        [-1.30789913573787, 32.2628889023061],
                        [-2.616604783529567, 32.09434621838619],
                        [-3.068980271812648, 31.724497992473218],
                        [-3.647497931320146, 31.637294012980675],
                        [-3.690441046554724, 30.896951605751156],
                        [-4.859646165374471, 30.501187649043846],
                        [-5.242129278982787, 30.00044302013559],
                        [-6.060632290053774, 29.731699734001694],
                        [-7.059227667661958, 29.5792284205246],
                        [-8.674116176782974, 28.84128896739658],
                        [-8.665589565454809, 27.656425889592356],
                        [-8.817828334986672, 27.656425889592356],
                        [-8.794883999049077, 27.120696316022507],
                        [-9.41303748212448, 27.088476060488574],
                        [-9.735343390328879, 26.860944729107405],
                        [-10.189424200877582, 26.860944729107405],
                        [-10.551262579785273, 26.990807603456886],
                        [-11.392554897497007, 26.883423977154393],
                        [-11.718219773800357, 26.104091701760623],
                        [-12.03075883630163, 26.030866197203068],
                        [-12.50096269372537, 24.7701162785782],
                        [-13.891110398809047, 23.691009019459305],
                        [-14.221167771857253, 22.31016307218816],
                        [-14.630832688851072, 21.860939846274903],
                        [-14.750954555713534, 21.500600083903663],
                        [-17.00296179856109, 21.420734157796577],
                        [-17.02042843267577, 21.422310288981578],
                        [-16.973247849993243, 21.885744533774982],
                        [-16.589136928767687, 22.158234361250095],
                        [-16.261921759495635, 22.679339504481277],
                        [-16.3264139469959, 23.017768459560898],
                        [-15.982610642958036, 23.723358466074046],
                        [-15.426003790742186, 24.35913361256104],
                        [-15.089331834360735, 24.520260728447],
                        [-14.824645148161665, 25.103532619725343],
                        [-14.800925665739726, 25.63626496022232],
                        [-14.439939947964831, 26.254418443297652],
                        [-13.773804897506466, 26.618892320252314],
                        [-13.13994177901435, 27.640147813420526],
                        [-13.121613369914769, 27.65414767171984],
                        [-12.618836635783111, 28.03818553314869],
                        [-11.688919236690765, 28.148643907172527],
                        [-10.900956997104402, 28.83214223888092],
                        [-10.399592251008642, 29.09858592377782],
                        [-9.564811163765683, 29.93357371674989],
                        [-9.814718390329176, 31.17773550060906],
                        [-9.434793260119363, 32.038096421836485],
                        [-9.300692918321886, 32.564679266890664],
                        [-8.657476365585012, 33.240245266242425],
                        [-7.654178432638219, 33.69706492770251],
                        [-6.912544114601417, 34.110476386037476],
                        [-6.244342006851411, 35.145865383437524],
                        [-5.92999426921989, 35.75998810479399],
                        [-5.193863491222032, 35.75518219659085],
                        [-4.591006232105144, 35.330711981745594],
                        [-3.640056525070065, 35.399855048152006],
                        [-2.604305792644084, 35.179093329401155],
                        [-2.169913702798624, 35.16839630791668],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 7,
                sovereignt: "Western Sahara",
                sov_a3: "SAH",
                adm0_dif: 0,
                level: 2,
                type: "Indeterminate",
                tlc: "1",
                admin: "Western Sahara",
                adm0_a3: "SAH",
                geou_dif: 0,
                geounit: "Western Sahara",
                gu_a3: "SAH",
                su_dif: 0,
                subunit: "Western Sahara",
                su_a3: "SAH",
                brk_diff: 1,
                name: "W. Sahara",
                name_long: "Western Sahara",
                brk_a3: "B28",
                brk_name: "W. Sahara",
                brk_group: null,
                abbrev: "W. Sah.",
                postal: "WS",
                formal_en: "Sahrawi Arab Democratic Republic",
                formal_fr: null,
                name_ciawf: "Western Sahara",
                note_adm0: null,
                note_brk: "Self admin.; Claimed by Morocco",
                name_sort: "Western Sahara",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 7,
                mapcolor9: 4,
                mapcolor13: 4,
                pop_est: 603253,
                pop_rank: 11,
                pop_year: 2017,
                gdp_md: 907,
                gdp_year: 2007,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "WI",
                iso_a2: "EH",
                iso_a2_eh: "EH",
                iso_a3: "ESH",
                iso_a3_eh: "ESH",
                iso_n3: "732",
                iso_n3_eh: "732",
                un_a3: "732",
                wb_a2: "-99",
                wb_a3: "-99",
                woe_id: 23424990,
                woe_id_eh: 23424990,
                woe_note: "Exact WOE match as country",
                adm0_iso: "B28",
                adm0_diff: null,
                adm0_tlc: "B28",
                adm0_a3_us: "SAH",
                adm0_a3_fr: "MAR",
                adm0_a3_ru: "SAH",
                adm0_a3_es: "SAH",
                adm0_a3_cn: "SAH",
                adm0_a3_tw: "SAH",
                adm0_a3_in: "MAR",
                adm0_a3_np: "SAH",
                adm0_a3_pk: "SAH",
                adm0_a3_de: "SAH",
                adm0_a3_gb: "SAH",
                adm0_a3_br: "SAH",
                adm0_a3_il: "SAH",
                adm0_a3_ps: "MAR",
                adm0_a3_sa: "MAR",
                adm0_a3_eg: "SAH",
                adm0_a3_ma: "MAR",
                adm0_a3_pt: "SAH",
                adm0_a3_ar: "SAH",
                adm0_a3_jp: "SAH",
                adm0_a3_ko: "SAH",
                adm0_a3_vn: "SAH",
                adm0_a3_tr: "MAR",
                adm0_a3_id: "MAR",
                adm0_a3_pl: "MAR",
                adm0_a3_gr: "SAH",
                adm0_a3_it: "SAH",
                adm0_a3_nl: "MAR",
                adm0_a3_se: "SAH",
                adm0_a3_bd: "SAH",
                adm0_a3_ua: "SAH",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 9,
                long_len: 14,
                abbrev_len: 7,
                tiny: -99,
                homepart: 1,
                min_zoom: 4.7,
                min_label: 6,
                max_label: 11,
                label_x: -12.630304,
                label_y: 23.967592,
                ne_id: 1159321223,
                wikidataid: "Q6250",
                name_ar: "الصحراء الغربية",
                name_bn: "পশ্চিম সাহারা",
                name_de: "Westsahara",
                name_en: "Western Sahara",
                name_es: "Sahara Occidental",
                name_fa: "صحرای غربی",
                name_fr: "Sahara occidental",
                name_el: "Δυτική Σαχάρα",
                name_he: "סהרה המערבית",
                name_hi: "पश्चिमी सहारा",
                name_hu: "Nyugat-Szahara",
                name_id: "Sahara Barat",
                name_it: "Sahara Occidentale",
                name_ja: "西サハラ",
                name_ko: "서사하라",
                name_nl: "Westelijke Sahara",
                name_pl: "Sahara Zachodnia",
                name_pt: "Sara Ocidental",
                name_ru: "Западная Сахара",
                name_sv: "Västsahara",
                name_tr: "Batı Sahra",
                name_uk: "Західна Сахара",
                name_ur: "مغربی صحارا",
                name_vi: "Tây Sahara",
                name_zh: "西撒哈拉",
                name_zht: "西撒哈拉",
                fclass_iso: "Admin-0 dependency",
                tlc_diff: null,
                fclass_tlc: "Admin-0 dependency",
                fclass_us: null,
                fclass_fr: "Unrecognized",
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: "Unrecognized",
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: "Unrecognized",
                fclass_sa: "Unrecognized",
                fclass_eg: null,
                fclass_ma: "Unrecognized",
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: "Unrecognized",
                fclass_id: "Unrecognized",
                fclass_pl: "Unrecognized",
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: "Unrecognized",
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ESH.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.665589565454809, 27.656425889592356],
                        [-8.665124477564191, 27.589479071558227],
                        [-8.684399786809053, 27.395744126896005],
                        [-8.6872936670174, 25.881056219988906],
                        [-11.96941891117116, 25.933352769468268],
                        [-11.937224493853321, 23.374594224536168],
                        [-12.874221564169575, 23.284832261645178],
                        [-13.118754441774712, 22.771220201096256],
                        [-12.929101935263532, 21.327070624267563],
                        [-16.845193650773993, 21.33332347257488],
                        [-17.06342322434257, 20.999752102130827],
                        [-17.02042843267577, 21.422310288981578],
                        [-17.00296179856109, 21.420734157796577],
                        [-14.750954555713534, 21.500600083903663],
                        [-14.630832688851072, 21.860939846274903],
                        [-14.221167771857253, 22.31016307218816],
                        [-13.891110398809047, 23.691009019459305],
                        [-12.50096269372537, 24.7701162785782],
                        [-12.03075883630163, 26.030866197203068],
                        [-11.718219773800357, 26.104091701760623],
                        [-11.392554897497007, 26.883423977154393],
                        [-10.551262579785273, 26.990807603456886],
                        [-10.189424200877582, 26.860944729107405],
                        [-9.735343390328879, 26.860944729107405],
                        [-9.41303748212448, 27.088476060488574],
                        [-8.794883999049077, 27.120696316022507],
                        [-8.817828334986672, 27.656425889592356],
                        [-8.665589565454809, 27.656425889592356],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Republic of the Congo",
                sov_a3: "COG",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Republic of the Congo",
                adm0_a3: "COG",
                geou_dif: 0,
                geounit: "Republic of the Congo",
                gu_a3: "COG",
                su_dif: 0,
                subunit: "Republic of the Congo",
                su_a3: "COG",
                brk_diff: 0,
                name: "Congo",
                name_long: "Republic of the Congo",
                brk_a3: "COG",
                brk_name: "Republic of the Congo",
                brk_group: null,
                abbrev: "Rep. Congo",
                postal: "CG",
                formal_en: "Republic of the Congo",
                formal_fr: null,
                name_ciawf: "Congo, Republic of the",
                note_adm0: null,
                note_brk: null,
                name_sort: "Congo, Rep.",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 1,
                mapcolor9: 3,
                mapcolor13: 10,
                pop_est: 5380508,
                pop_rank: 13,
                pop_year: 2019,
                gdp_md: 12267,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "CF",
                iso_a2: "CG",
                iso_a2_eh: "CG",
                iso_a3: "COG",
                iso_a3_eh: "COG",
                iso_n3: "178",
                iso_n3_eh: "178",
                un_a3: "178",
                wb_a2: "CG",
                wb_a3: "COG",
                woe_id: 23424779,
                woe_id_eh: 23424779,
                woe_note: "Exact WOE match as country",
                adm0_iso: "COG",
                adm0_diff: null,
                adm0_tlc: "COG",
                adm0_a3_us: "COG",
                adm0_a3_fr: "COG",
                adm0_a3_ru: "COG",
                adm0_a3_es: "COG",
                adm0_a3_cn: "COG",
                adm0_a3_tw: "COG",
                adm0_a3_in: "COG",
                adm0_a3_np: "COG",
                adm0_a3_pk: "COG",
                adm0_a3_de: "COG",
                adm0_a3_gb: "COG",
                adm0_a3_br: "COG",
                adm0_a3_il: "COG",
                adm0_a3_ps: "COG",
                adm0_a3_sa: "COG",
                adm0_a3_eg: "COG",
                adm0_a3_ma: "COG",
                adm0_a3_pt: "COG",
                adm0_a3_ar: "COG",
                adm0_a3_jp: "COG",
                adm0_a3_ko: "COG",
                adm0_a3_vn: "COG",
                adm0_a3_tr: "COG",
                adm0_a3_id: "COG",
                adm0_a3_pl: "COG",
                adm0_a3_gr: "COG",
                adm0_a3_it: "COG",
                adm0_a3_nl: "COG",
                adm0_a3_se: "COG",
                adm0_a3_bd: "COG",
                adm0_a3_ua: "COG",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 21,
                abbrev_len: 10,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 15.9005,
                label_y: 0.142331,
                ne_id: 1159320515,
                wikidataid: "Q971",
                name_ar: "جمهورية الكونغو",
                name_bn: "কঙ্গো প্রজাতন্ত্র",
                name_de: "Republik Kongo",
                name_en: "Republic of the Congo",
                name_es: "República del Congo",
                name_fa: "جمهوری کنگو",
                name_fr: "République du Congo",
                name_el: "Δημοκρατία του Κονγκό",
                name_he: "הרפובליקה של קונגו",
                name_hi: "कांगो गणराज्य",
                name_hu: "Kongói Köztársaság",
                name_id: "Republik Kongo",
                name_it: "Repubblica del Congo",
                name_ja: "コンゴ共和国",
                name_ko: "콩고 공화국",
                name_nl: "Congo-Brazzaville",
                name_pl: "Kongo",
                name_pt: "República do Congo",
                name_ru: "Республика Конго",
                name_sv: "Kongo-Brazzaville",
                name_tr: "Kongo Cumhuriyeti",
                name_uk: "Республіка Конго",
                name_ur: "جمہوریہ کانگو",
                name_vi: "Cộng hòa Congo",
                name_zh: "刚果共和国",
                name_zht: "剛果共和國",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "COG.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [18.45306521980993, 3.504385891123349],
                        [18.393792351971143, 2.90044342692822],
                        [18.094275750407434, 2.365721543788055],
                        [17.898835483479587, 1.741831976728278],
                        [17.774191928791566, 0.855658677571085],
                        [17.826540154703252, 0.288923244626105],
                        [17.66355268725468, -0.058083998213817],
                        [17.638644646889986, -0.424831638189247],
                        [17.523716261472856, -0.743830254726987],
                        [16.865306837642123, -1.225816338713287],
                        [16.407091912510054, -1.740927015798682],
                        [15.972803175529151, -2.712392266453612],
                        [16.0062895036543, -3.535132744972529],
                        [15.753540073314753, -3.855164890156097],
                        [15.170991652088444, -4.343507175314301],
                        [14.582603794013181, -4.97023894615014],
                        [14.209034864975223, -4.793092136253598],
                        [14.144956088933299, -4.510008640158716],
                        [13.600234816144678, -4.50013844159097],
                        [13.258240187237048, -4.882957452009165],
                        [12.995517205465177, -4.781103203961884],
                        [12.620759718484493, -4.438023369976136],
                        [12.318607618873926, -4.606230157086188],
                        [11.91496300624209, -5.037986748884791],
                        [11.093772820691925, -3.978826592630547],
                        [11.855121697648116, -3.426870619321051],
                        [11.478038771214303, -2.765618991714241],
                        [11.820963575903193, -2.514161472181982],
                        [12.495702752338161, -2.391688327650243],
                        [12.575284458067642, -1.948511244315135],
                        [13.109618767965628, -2.428740329603514],
                        [13.99240726080771, -2.4708049454891],
                        [14.299210239324566, -1.998275648612214],
                        [14.425455763413595, -1.333406670744971],
                        [14.316418491277744, -0.552627455247048],
                        [13.843320753645656, 0.038757635901149],
                        [14.276265903386957, 1.196929836426619],
                        [14.026668735417218, 1.395677395021153],
                        [13.282631463278818, 1.31418366129688],
                        [13.003113641012078, 1.83089630778332],
                        [13.075822381246752, 2.267097072759015],
                        [14.33781253424658, 2.227874660649491],
                        [15.146341993885244, 1.964014797367184],
                        [15.940918816805066, 1.727672634280296],
                        [16.012852410555354, 2.267639675298085],
                        [16.537058139724138, 3.198254706226279],
                        [17.133042433346304, 3.728196519379452],
                        [17.809900343505262, 3.56019643799857],
                        [18.45306521980993, 3.504385891123349],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "Democratic Republic of the Congo",
                sov_a3: "COD",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Democratic Republic of the Congo",
                adm0_a3: "COD",
                geou_dif: 0,
                geounit: "Democratic Republic of the Congo",
                gu_a3: "COD",
                su_dif: 0,
                subunit: "Democratic Republic of the Congo",
                su_a3: "COD",
                brk_diff: 0,
                name: "Democratic Republic of the Congo",
                name_long: "Democratic Republic of the Congo",
                brk_a3: "COD",
                brk_name: "Democratic Republic of the Congo",
                brk_group: null,
                abbrev: "D.R.C.",
                postal: "DRC",
                formal_en: "Democratic Republic of the Congo",
                formal_fr: null,
                name_ciawf: "Congo, Democratic Republic of the",
                note_adm0: null,
                note_brk: null,
                name_sort: "Congo, Dem. Rep.",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 4,
                mapcolor9: 4,
                mapcolor13: 7,
                pop_est: 86790567,
                pop_rank: 16,
                pop_year: 2019,
                gdp_md: 50400,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "CG",
                iso_a2: "CD",
                iso_a2_eh: "CD",
                iso_a3: "COD",
                iso_a3_eh: "COD",
                iso_n3: "180",
                iso_n3_eh: "180",
                un_a3: "180",
                wb_a2: "ZR",
                wb_a3: "ZAR",
                woe_id: 23424780,
                woe_id_eh: 23424780,
                woe_note: "Exact WOE match as country",
                adm0_iso: "COD",
                adm0_diff: null,
                adm0_tlc: "COD",
                adm0_a3_us: "COD",
                adm0_a3_fr: "COD",
                adm0_a3_ru: "COD",
                adm0_a3_es: "COD",
                adm0_a3_cn: "COD",
                adm0_a3_tw: "COD",
                adm0_a3_in: "COD",
                adm0_a3_np: "COD",
                adm0_a3_pk: "COD",
                adm0_a3_de: "COD",
                adm0_a3_gb: "COD",
                adm0_a3_br: "COD",
                adm0_a3_il: "COD",
                adm0_a3_ps: "COD",
                adm0_a3_sa: "COD",
                adm0_a3_eg: "COD",
                adm0_a3_ma: "COD",
                adm0_a3_pt: "COD",
                adm0_a3_ar: "COD",
                adm0_a3_jp: "COD",
                adm0_a3_ko: "COD",
                adm0_a3_vn: "COD",
                adm0_a3_tr: "COD",
                adm0_a3_id: "COD",
                adm0_a3_pl: "COD",
                adm0_a3_gr: "COD",
                adm0_a3_it: "COD",
                adm0_a3_nl: "COD",
                adm0_a3_se: "COD",
                adm0_a3_bd: "COD",
                adm0_a3_ua: "COD",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 15,
                long_len: 32,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2,
                max_label: 7,
                label_x: 23.458829,
                label_y: -1.858167,
                ne_id: 1159320513,
                wikidataid: "Q974",
                name_ar: "جمهورية الكونغو الديمقراطية",
                name_bn: "গণতান্ত্রিক কঙ্গো প্রজাতন্ত্র",
                name_de: "Demokratische Republik Kongo",
                name_en: "Democratic Republic of the Congo",
                name_es: "República Democrática del Congo",
                name_fa: "جمهوری دموکراتیک کنگو",
                name_fr: "République démocratique du Congo",
                name_el: "Λαϊκή Δημοκρατία του Κονγκό",
                name_he: "הרפובליקה הדמוקרטית של קונגו",
                name_hi: "कांगो लोकतान्त्रिक गणराज्य",
                name_hu: "Kongói Demokratikus Köztársaság",
                name_id: "Republik Demokratik Kongo",
                name_it: "Repubblica Democratica del Congo",
                name_ja: "コンゴ民主共和国",
                name_ko: "콩고 민주 공화국",
                name_nl: "Congo-Kinshasa",
                name_pl: "Demokratyczna Republika Konga",
                name_pt: "República Democrática do Congo",
                name_ru: "Демократическая Республика Конго",
                name_sv: "Kongo-Kinshasa",
                name_tr: "Demokratik Kongo Cumhuriyeti",
                name_uk: "Демократична Республіка Конго",
                name_ur: "جمہوری جمہوریہ کانگو",
                name_vi: "Cộng hòa Dân chủ Congo",
                name_zh: "刚果民主共和国",
                name_zht: "剛果民主共和國",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "COD.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [29.339997592900346, -4.499983412294092],
                        [29.519986606572928, -5.419978936386315],
                        [29.419992710088167, -5.939998874539434],
                        [29.620032179490014, -6.520015150583426],
                        [30.199996779101696, -7.079980970898163],
                        [30.74001549655179, -8.340007419470915],
                        [30.740009731422095, -8.34000593035372],
                        [30.346086053190817, -8.238256524288218],
                        [29.00291222506047, -8.407031752153472],
                        [28.734866570762502, -8.526559340044578],
                        [28.449871046672826, -9.164918308146085],
                        [28.67368167492893, -9.605924981324932],
                        [28.49606977714177, -10.789883721564046],
                        [28.372253045370428, -11.793646742401393],
                        [28.642417433392353, -11.971568698782315],
                        [29.34154788586909, -12.360743910372413],
                        [29.61600141777123, -12.178894545137311],
                        [29.69961388521949, -13.257226657771831],
                        [28.934285922976837, -13.248958428605135],
                        [28.523561639121027, -12.698604424696683],
                        [28.155108676879987, -12.272480564017897],
                        [27.388798862423783, -12.132747491100666],
                        [27.164419793412463, -11.608748467661075],
                        [26.553087599399618, -11.924439792532127],
                        [25.752309604604733, -11.784965101776358],
                        [25.418118116973204, -11.330935967659961],
                        [24.78316979340295, -11.238693536018964],
                        [24.31451622894795, -11.26282642989927],
                        [24.25715538910399, -10.951992689663657],
                        [23.912215203555718, -10.926826267137514],
                        [23.45679080576744, -10.867863457892483],
                        [22.83734541188474, -11.01762175867433],
                        [22.402798292742375, -10.993075453335692],
                        [22.155268182064308, -11.084801120653772],
                        [22.208753289486395, -9.894796237836509],
                        [21.875181919042348, -9.523707777548566],
                        [21.8018013851879, -8.90870655684298],
                        [21.949130893652043, -8.305900974158277],
                        [21.74645592620331, -7.920084730667149],
                        [21.7281107927397, -7.290872491081302],
                        [20.5147481625265, -7.299605808138629],
                        [20.6018229509383, -6.939317722199682],
                        [20.091621534920648, -6.943090101756994],
                        [20.037723016040218, -7.116361179231646],
                        [19.41750247567316, -7.155428562044299],
                        [19.16661339689611, -7.738183688999754],
                        [19.01675174324967, -7.988245944860132],
                        [18.464175652752687, -7.847014255406443],
                        [18.13422163256905, -7.987677504104923],
                        [17.472970004962235, -8.0685511206417],
                        [17.08999596524717, -7.545688978712526],
                        [16.8601908708452, -7.222297865429987],
                        [16.573179965896145, -6.622644545115087],
                        [16.326528354567046, -5.877470391466268],
                        [13.375597364971895, -5.864241224799549],
                        [13.024869419006961, -5.984388929878158],
                        [12.735171339578699, -5.965682061388499],
                        [12.32243167486351, -6.10009246177966],
                        [12.182336866920252, -5.789930515163839],
                        [12.436688266660868, -5.684303887559246],
                        [12.468004184629736, -5.248361504745005],
                        [12.63161176926579, -4.991271254092936],
                        [12.995517205465177, -4.781103203961884],
                        [13.258240187237048, -4.882957452009165],
                        [13.600234816144678, -4.50013844159097],
                        [14.144956088933299, -4.510008640158716],
                        [14.209034864975223, -4.793092136253598],
                        [14.582603794013181, -4.97023894615014],
                        [15.170991652088444, -4.343507175314301],
                        [15.753540073314753, -3.855164890156097],
                        [16.0062895036543, -3.535132744972529],
                        [15.972803175529151, -2.712392266453612],
                        [16.407091912510054, -1.740927015798682],
                        [16.865306837642123, -1.225816338713287],
                        [17.523716261472856, -0.743830254726987],
                        [17.638644646889986, -0.424831638189247],
                        [17.66355268725468, -0.058083998213817],
                        [17.826540154703252, 0.288923244626105],
                        [17.774191928791566, 0.855658677571085],
                        [17.898835483479587, 1.741831976728278],
                        [18.094275750407434, 2.365721543788055],
                        [18.393792351971143, 2.90044342692822],
                        [18.45306521980993, 3.504385891123349],
                        [18.54298221199778, 4.201785183118318],
                        [18.93231245288476, 4.709506130385975],
                        [19.46778364429315, 5.03152781821278],
                        [20.290679152108936, 4.691677761245288],
                        [20.927591180106276, 4.322785549329737],
                        [21.659122755630023, 4.22434194581372],
                        [22.405123732195538, 4.029160061047321],
                        [22.70412356943629, 4.633050848810157],
                        [22.841479526468106, 4.710126247573484],
                        [23.29721398285014, 4.609693101414223],
                        [24.410531040146253, 5.10878408448913],
                        [24.805028924262416, 4.89724660890235],
                        [25.12883344900328, 4.92724477784779],
                        [25.278798455514305, 5.170408229997192],
                        [25.650455356557472, 5.256087754737123],
                        [26.402760857862543, 5.150874538590871],
                        [27.04406538260471, 5.127852688004836],
                        [27.37422610851749, 5.233944403500061],
                        [27.97997724784281, 4.408413397637375],
                        [28.428993768026913, 4.287154649264494],
                        [28.696677687298802, 4.455077215996937],
                        [29.1590784034465, 4.389267279473231],
                        [29.71599531425602, 4.600804755060153],
                        [29.953500197069474, 4.173699042167684],
                        [30.833852421715427, 3.509171604222463],
                        [30.83385989759381, 3.509165961110341],
                        [30.77334679538004, 2.339883327642127],
                        [31.174149204235817, 2.204465236821264],
                        [30.85267011894806, 1.849396470543809],
                        [30.46850752129029, 1.583805446779706],
                        [30.086153598762706, 1.062312730306289],
                        [29.875778842902434, 0.597379868976361],
                        [29.819503208136638, -0.205310153813372],
                        [29.58783776217217, -0.587405694179381],
                        [29.579466180140884, -1.341313164885626],
                        [29.291886834436614, -1.620055840667987],
                        [29.254834832483343, -2.215109958508911],
                        [29.117478875451553, -2.292211195488385],
                        [29.024926385216787, -2.839257907730158],
                        [29.276383904749053, -3.293907159034063],
                        [29.339997592900346, -4.499983412294092],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Guinea",
                sov_a3: "GIN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Guinea",
                adm0_a3: "GIN",
                geou_dif: 0,
                geounit: "Guinea",
                gu_a3: "GIN",
                su_dif: 0,
                subunit: "Guinea",
                su_a3: "GIN",
                brk_diff: 0,
                name: "Guinea",
                name_long: "Guinea",
                brk_a3: "GIN",
                brk_name: "Guinea",
                brk_group: null,
                abbrev: "Gin.",
                postal: "GN",
                formal_en: "Republic of Guinea",
                formal_fr: null,
                name_ciawf: "Guinea",
                note_adm0: null,
                note_brk: null,
                name_sort: "Guinea",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 3,
                mapcolor9: 7,
                mapcolor13: 2,
                pop_est: 12771246,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 12296,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "GV",
                iso_a2: "GN",
                iso_a2_eh: "GN",
                iso_a3: "GIN",
                iso_a3_eh: "GIN",
                iso_n3: "324",
                iso_n3_eh: "324",
                un_a3: "324",
                wb_a2: "GN",
                wb_a3: "GIN",
                woe_id: 23424835,
                woe_id_eh: 23424835,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GIN",
                adm0_diff: null,
                adm0_tlc: "GIN",
                adm0_a3_us: "GIN",
                adm0_a3_fr: "GIN",
                adm0_a3_ru: "GIN",
                adm0_a3_es: "GIN",
                adm0_a3_cn: "GIN",
                adm0_a3_tw: "GIN",
                adm0_a3_in: "GIN",
                adm0_a3_np: "GIN",
                adm0_a3_pk: "GIN",
                adm0_a3_de: "GIN",
                adm0_a3_gb: "GIN",
                adm0_a3_br: "GIN",
                adm0_a3_il: "GIN",
                adm0_a3_ps: "GIN",
                adm0_a3_sa: "GIN",
                adm0_a3_eg: "GIN",
                adm0_a3_ma: "GIN",
                adm0_a3_pt: "GIN",
                adm0_a3_ar: "GIN",
                adm0_a3_jp: "GIN",
                adm0_a3_ko: "GIN",
                adm0_a3_vn: "GIN",
                adm0_a3_tr: "GIN",
                adm0_a3_id: "GIN",
                adm0_a3_pl: "GIN",
                adm0_a3_gr: "GIN",
                adm0_a3_it: "GIN",
                adm0_a3_nl: "GIN",
                adm0_a3_se: "GIN",
                adm0_a3_bd: "GIN",
                adm0_a3_ua: "GIN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: -10.016402,
                label_y: 10.618516,
                ne_id: 1159320795,
                wikidataid: "Q1006",
                name_ar: "غينيا",
                name_bn: "গিনি",
                name_de: "Guinea",
                name_en: "Guinea",
                name_es: "Guinea",
                name_fa: "گینه",
                name_fr: "Guinée",
                name_el: "Γουινέα",
                name_he: "גינאה",
                name_hi: "गिनी",
                name_hu: "Guinea",
                name_id: "Guinea",
                name_it: "Guinea",
                name_ja: "ギニア",
                name_ko: "기니",
                name_nl: "Guinee",
                name_pl: "Gwinea",
                name_pt: "Guiné",
                name_ru: "Гвинея",
                name_sv: "Guinea",
                name_tr: "Gine",
                name_uk: "Гвінея",
                name_ur: "جمہوریہ گنی",
                name_vi: "Guinée",
                name_zh: "几内亚",
                name_zht: "幾內亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GIN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-13.700476040084325, 12.586182969610194],
                        [-13.217818162478238, 12.575873521367967],
                        [-12.499050665730564, 12.332089952031057],
                        [-12.27859900557344, 12.354440008997287],
                        [-12.203564825885634, 12.465647691289405],
                        [-11.658300950557932, 12.386582749882836],
                        [-11.51394283695059, 12.442987575729418],
                        [-11.456168585648271, 12.076834214725338],
                        [-11.297573614944511, 12.077971096235771],
                        [-11.03655595543826, 12.211244615116515],
                        [-10.870829637078215, 12.17788747807211],
                        [-10.593223842806282, 11.92397532800598],
                        [-10.165213792348837, 11.844083563682744],
                        [-9.890992804392013, 12.060478623904972],
                        [-9.567911749703214, 12.194243068892476],
                        [-9.327616339546012, 12.334286200403454],
                        [-9.127473517279583, 12.308060411015333],
                        [-8.90526485842453, 12.088358059126437],
                        [-8.786099005559464, 11.812560939984706],
                        [-8.376304897484914, 11.393645941610629],
                        [-8.581305304386774, 11.136245632364805],
                        [-8.620321010767128, 10.810890814655183],
                        [-8.407310756860028, 10.909256903522762],
                        [-8.282357143578281, 10.792597357623846],
                        [-8.33537716310974, 10.494811916541934],
                        [-8.029943610048619, 10.206534939001713],
                        [-8.229337124046822, 10.1290202905639],
                        [-8.30961646161225, 9.789531968622441],
                        [-8.07911373537435, 9.376223863152035],
                        [-7.832100389019188, 8.575704250518626],
                        [-8.20349890790088, 8.455453192575447],
                        [-8.299048631208564, 8.316443589710303],
                        [-8.221792364932199, 8.123328762235573],
                        [-8.280703497744938, 7.687179673692157],
                        [-8.439298468448698, 7.686042792181738],
                        [-8.722123582382125, 7.71167430259851],
                        [-8.926064622422004, 7.309037380396376],
                        [-9.208786383490846, 7.313920803247953],
                        [-9.40334815106975, 7.526905218938907],
                        [-9.337279832384581, 7.928534450711354],
                        [-9.755342169625834, 8.541055202666925],
                        [-10.016566534861255, 8.428503933135232],
                        [-10.23009355309128, 8.406205552601293],
                        [-10.505477260774668, 8.348896389189605],
                        [-10.494315151399633, 8.715540676300435],
                        [-10.654770473665891, 8.977178452994195],
                        [-10.622395188835041, 9.267910061068278],
                        [-10.839151984083301, 9.688246161330369],
                        [-11.11748124840733, 10.045872911006285],
                        [-11.917277390988659, 10.046983954300558],
                        [-12.150338100625005, 9.858571682164381],
                        [-12.425928514037565, 9.835834051955956],
                        [-12.59671912276221, 9.62018830000197],
                        [-12.71195756677308, 9.342711696810767],
                        [-13.246550258832515, 8.903048610871508],
                        [-13.685153977909792, 9.49474376061346],
                        [-14.074044969122282, 9.886166897008252],
                        [-14.33007585291237, 10.015719712763968],
                        [-14.579698859098258, 10.214467271358515],
                        [-14.693231980843505, 10.656300767454042],
                        [-14.839553798877944, 10.87657156009814],
                        [-15.130311245168173, 11.040411688679526],
                        [-14.685687221728898, 11.527823798056488],
                        [-14.382191534878729, 11.509271958863692],
                        [-14.121406419317779, 11.677117010947697],
                        [-13.900799729863776, 11.678718980348748],
                        [-13.743160773157413, 11.811269029177412],
                        [-13.828271857142125, 12.142644151249044],
                        [-13.718743658899513, 12.24718557377551],
                        [-13.700476040084325, 12.586182969610194],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Namibia",
                sov_a3: "NAM",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Namibia",
                adm0_a3: "NAM",
                geou_dif: 0,
                geounit: "Namibia",
                gu_a3: "NAM",
                su_dif: 0,
                subunit: "Namibia",
                su_a3: "NAM",
                brk_diff: 0,
                name: "Namibia",
                name_long: "Namibia",
                brk_a3: "NAM",
                brk_name: "Namibia",
                brk_group: null,
                abbrev: "Nam.",
                postal: "NA",
                formal_en: "Republic of Namibia",
                formal_fr: null,
                name_ciawf: "Namibia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Namibia",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 1,
                mapcolor9: 1,
                mapcolor13: 7,
                pop_est: 2494530,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 12366,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "WA",
                iso_a2: "NA",
                iso_a2_eh: "NA",
                iso_a3: "NAM",
                iso_a3_eh: "NAM",
                iso_n3: "516",
                iso_n3_eh: "516",
                un_a3: "516",
                wb_a2: "NA",
                wb_a3: "NAM",
                woe_id: 23424987,
                woe_id_eh: 23424987,
                woe_note: "Exact WOE match as country",
                adm0_iso: "NAM",
                adm0_diff: null,
                adm0_tlc: "NAM",
                adm0_a3_us: "NAM",
                adm0_a3_fr: "NAM",
                adm0_a3_ru: "NAM",
                adm0_a3_es: "NAM",
                adm0_a3_cn: "NAM",
                adm0_a3_tw: "NAM",
                adm0_a3_in: "NAM",
                adm0_a3_np: "NAM",
                adm0_a3_pk: "NAM",
                adm0_a3_de: "NAM",
                adm0_a3_gb: "NAM",
                adm0_a3_br: "NAM",
                adm0_a3_il: "NAM",
                adm0_a3_ps: "NAM",
                adm0_a3_sa: "NAM",
                adm0_a3_eg: "NAM",
                adm0_a3_ma: "NAM",
                adm0_a3_pt: "NAM",
                adm0_a3_ar: "NAM",
                adm0_a3_jp: "NAM",
                adm0_a3_ko: "NAM",
                adm0_a3_vn: "NAM",
                adm0_a3_tr: "NAM",
                adm0_a3_id: "NAM",
                adm0_a3_pl: "NAM",
                adm0_a3_gr: "NAM",
                adm0_a3_it: "NAM",
                adm0_a3_nl: "NAM",
                adm0_a3_se: "NAM",
                adm0_a3_bd: "NAM",
                adm0_a3_ua: "NAM",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Southern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 7.5,
                label_x: 17.108166,
                label_y: -20.575298,
                ne_id: 1159321085,
                wikidataid: "Q1030",
                name_ar: "ناميبيا",
                name_bn: "নামিবিয়া",
                name_de: "Namibia",
                name_en: "Namibia",
                name_es: "Namibia",
                name_fa: "نامیبیا",
                name_fr: "Namibie",
                name_el: "Ναμίμπια",
                name_he: "נמיביה",
                name_hi: "नामीबिया",
                name_hu: "Namíbia",
                name_id: "Namibia",
                name_it: "Namibia",
                name_ja: "ナミビア",
                name_ko: "나미비아",
                name_nl: "Namibië",
                name_pl: "Namibia",
                name_pt: "Namíbia",
                name_ru: "Намибия",
                name_sv: "Namibia",
                name_tr: "Namibya",
                name_uk: "Намібія",
                name_ur: "نمیبیا",
                name_vi: "Namibia",
                name_zh: "纳米比亚",
                name_zht: "納米比亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "NAM.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [19.895767856534434, -24.76779021576059],
                        [19.894734327888614, -28.461104831660776],
                        [19.002127312911085, -28.972443129188868],
                        [18.464899122804752, -29.04546192801728],
                        [17.83615197110953, -28.85637786226132],
                        [17.387497185951503, -28.78351409272978],
                        [17.218928663815404, -28.35594329194681],
                        [16.824017368240902, -28.08216155366447],
                        [16.344976840895242, -28.5767050106977],
                        [15.601818068105816, -27.821247247022804],
                        [15.21047244635946, -27.090955905874047],
                        [14.989710727608553, -26.117371921495156],
                        [14.743214145576331, -25.39292001719538],
                        [14.408144158595833, -23.853014011329847],
                        [14.385716586981149, -22.65665292734069],
                        [14.257714064194175, -22.111208184499958],
                        [13.86864220546866, -21.699036960539978],
                        [13.35249799973744, -20.872834161057504],
                        [12.826845330464494, -19.673165785401665],
                        [12.608564080463621, -19.0453488094877],
                        [11.794918654028066, -18.069129327061916],
                        [11.734198846085121, -17.301889336824473],
                        [12.215461460019355, -17.111668389558083],
                        [12.814081251688407, -16.94134286872407],
                        [13.462362094789967, -16.971211846588773],
                        [14.05850141770901, -17.423380629142663],
                        [14.209706658595024, -17.35310068122572],
                        [18.263309360434164, -17.309950860262006],
                        [18.956186964603603, -17.789094740472258],
                        [21.377176141045567, -17.930636488519696],
                        [23.215048455506064, -17.523116143465984],
                        [24.033861525170778, -17.295843194246324],
                        [24.682349074001507, -17.353410739819473],
                        [25.07695031098226, -17.57882333747662],
                        [25.08444339366457, -17.661815687737374],
                        [24.520705193792537, -17.887124932529936],
                        [24.217364536239213, -17.88934701911849],
                        [23.579005568137717, -18.28126108162006],
                        [23.1968583513393, -17.869038181227786],
                        [21.655040317478978, -18.219146010005225],
                        [20.910641310314535, -18.252218926672022],
                        [20.88113406747587, -21.814327080983148],
                        [19.89545779794068, -21.84915699634787],
                        [19.895767856534434, -24.76779021576059],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "South Africa",
                sov_a3: "ZAF",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "South Africa",
                adm0_a3: "ZAF",
                geou_dif: 0,
                geounit: "South Africa",
                gu_a3: "ZAF",
                su_dif: 0,
                subunit: "South Africa",
                su_a3: "ZAF",
                brk_diff: 0,
                name: "South Africa",
                name_long: "South Africa",
                brk_a3: "ZAF",
                brk_name: "South Africa",
                brk_group: null,
                abbrev: "S.Af.",
                postal: "ZA",
                formal_en: "Republic of South Africa",
                formal_fr: null,
                name_ciawf: "South Africa",
                note_adm0: null,
                note_brk: null,
                name_sort: "South Africa",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 3,
                mapcolor9: 4,
                mapcolor13: 2,
                pop_est: 58558270,
                pop_rank: 16,
                pop_year: 2019,
                gdp_md: 351431,
                gdp_year: 2019,
                economy: "5. Emerging region: G20",
                income_grp: "3. Upper middle income",
                fips_10: "SF",
                iso_a2: "ZA",
                iso_a2_eh: "ZA",
                iso_a3: "ZAF",
                iso_a3_eh: "ZAF",
                iso_n3: "710",
                iso_n3_eh: "710",
                un_a3: "710",
                wb_a2: "ZA",
                wb_a3: "ZAF",
                woe_id: 23424942,
                woe_id_eh: 23424942,
                woe_note: "Exact WOE match as country",
                adm0_iso: "ZAF",
                adm0_diff: null,
                adm0_tlc: "ZAF",
                adm0_a3_us: "ZAF",
                adm0_a3_fr: "ZAF",
                adm0_a3_ru: "ZAF",
                adm0_a3_es: "ZAF",
                adm0_a3_cn: "ZAF",
                adm0_a3_tw: "ZAF",
                adm0_a3_in: "ZAF",
                adm0_a3_np: "ZAF",
                adm0_a3_pk: "ZAF",
                adm0_a3_de: "ZAF",
                adm0_a3_gb: "ZAF",
                adm0_a3_br: "ZAF",
                adm0_a3_il: "ZAF",
                adm0_a3_ps: "ZAF",
                adm0_a3_sa: "ZAF",
                adm0_a3_eg: "ZAF",
                adm0_a3_ma: "ZAF",
                adm0_a3_pt: "ZAF",
                adm0_a3_ar: "ZAF",
                adm0_a3_jp: "ZAF",
                adm0_a3_ko: "ZAF",
                adm0_a3_vn: "ZAF",
                adm0_a3_tr: "ZAF",
                adm0_a3_id: "ZAF",
                adm0_a3_pl: "ZAF",
                adm0_a3_gr: "ZAF",
                adm0_a3_it: "ZAF",
                adm0_a3_nl: "ZAF",
                adm0_a3_se: "ZAF",
                adm0_a3_bd: "ZAF",
                adm0_a3_ua: "ZAF",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Southern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 12,
                long_len: 12,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 1.7,
                max_label: 6.7,
                label_x: 23.665734,
                label_y: -29.708776,
                ne_id: 1159321431,
                wikidataid: "Q258",
                name_ar: "جنوب أفريقيا",
                name_bn: "দক্ষিণ আফ্রিকা",
                name_de: "Südafrika",
                name_en: "South Africa",
                name_es: "Sudáfrica",
                name_fa: "آفریقای جنوبی",
                name_fr: "Afrique du Sud",
                name_el: "Νότια Αφρική",
                name_he: "דרום אפריקה",
                name_hi: "दक्षिण अफ़्रीका",
                name_hu: "Dél-afrikai Köztársaság",
                name_id: "Afrika Selatan",
                name_it: "Sudafrica",
                name_ja: "南アフリカ共和国",
                name_ko: "남아프리카 공화국",
                name_nl: "Zuid-Afrika",
                name_pl: "Południowa Afryka",
                name_pt: "África do Sul",
                name_ru: "ЮАР",
                name_sv: "Sydafrika",
                name_tr: "Güney Afrika Cumhuriyeti",
                name_uk: "Південно-Африканська Республіка",
                name_ur: "جنوبی افریقا",
                name_vi: "Cộng hòa Nam Phi",
                name_zh: "南非",
                name_zht: "南非",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ZAF.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [16.344976840895242, -28.5767050106977],
                        [16.824017368240902, -28.08216155366447],
                        [17.218928663815404, -28.35594329194681],
                        [17.387497185951503, -28.78351409272978],
                        [17.83615197110953, -28.85637786226132],
                        [18.464899122804752, -29.04546192801728],
                        [19.002127312911085, -28.972443129188868],
                        [19.894734327888614, -28.461104831660776],
                        [19.895767856534434, -24.76779021576059],
                        [20.16572553882719, -24.91796192800077],
                        [20.75860924651184, -25.86813648855145],
                        [20.66647016773544, -26.477453301704923],
                        [20.88960900237174, -26.828542982695915],
                        [21.605896030369394, -26.726533705351756],
                        [22.105968865657868, -26.280256036079138],
                        [22.57953169118059, -25.979447523708146],
                        [22.8242712745149, -25.50045867279477],
                        [23.312096795350186, -25.26868987396572],
                        [23.73356977712271, -25.390129489851617],
                        [24.211266717228796, -25.670215752873574],
                        [25.025170525825786, -25.7196700985769],
                        [25.66466637543772, -25.486816094669713],
                        [25.76584882986521, -25.17484547292368],
                        [25.94165205252216, -24.69637338633322],
                        [26.4857532081233, -24.616326592713104],
                        [26.786406691197413, -24.240690606383485],
                        [27.119409620886245, -23.574323011979775],
                        [28.01723595552525, -22.82775359465908],
                        [29.43218834810904, -22.091312758067588],
                        [29.839036899542972, -22.102216485281176],
                        [30.322883335091774, -22.271611830333935],
                        [30.65986535006709, -22.151567478119915],
                        [31.19140913262129, -22.2515096981724],
                        [31.670397983534656, -23.658969008073864],
                        [31.930588820124253, -24.36941659922254],
                        [31.75240848158188, -25.484283949487413],
                        [31.83777794772806, -25.84333180105135],
                        [31.333157586397903, -25.66019052500895],
                        [31.04407962415715, -25.731452325139443],
                        [30.949666782359913, -26.02264902110415],
                        [30.67660851412964, -26.398078301704608],
                        [30.68596194837448, -26.743845310169533],
                        [31.28277306491333, -27.285879408478998],
                        [31.86806033705108, -27.177927341421277],
                        [32.07166548028107, -26.73382008230491],
                        [32.830120477028885, -26.742191664336197],
                        [32.580264926897684, -27.470157566031816],
                        [32.46213260267845, -28.301011244420557],
                        [32.20338870619304, -28.75240488049007],
                        [31.521001417778876, -29.257386976846256],
                        [31.325561150851, -29.401977634398914],
                        [30.901762729625347, -29.909956963828037],
                        [30.62281334811382, -30.42377573010613],
                        [30.05571618014278, -31.140269463832958],
                        [28.92555260591954, -32.1720411109725],
                        [28.2197558936771, -32.771952813448856],
                        [27.464608188595975, -33.2269637997788],
                        [26.419452345492825, -33.61495045342619],
                        [25.90966434093349, -33.6670402971764],
                        [25.780628289500697, -33.94464609144834],
                        [25.172861769315972, -33.796851495093584],
                        [24.677853224392123, -33.98717579522455],
                        [23.594043409934642, -33.794474379208154],
                        [22.988188917744736, -33.91643075941698],
                        [22.574157342222236, -33.86408253350531],
                        [21.542799106541025, -34.258838799782936],
                        [20.689052768647002, -34.417175388325234],
                        [20.071261020597632, -34.79513681410799],
                        [19.61640506356457, -34.81916635512371],
                        [19.193278435958717, -34.46259897230979],
                        [18.85531456876987, -34.444305515278465],
                        [18.42464318204938, -33.99787281670897],
                        [18.377410922934615, -34.13652068454807],
                        [18.24449913907992, -33.86775156019803],
                        [18.250080193767445, -33.28143075941444],
                        [17.92519046394844, -32.61129078545343],
                        [18.247909783611192, -32.42913136162457],
                        [18.22176150887148, -31.66163298922567],
                        [17.56691775886887, -30.725721123987547],
                        [17.064416131262703, -29.878641045859162],
                        [17.062917514726223, -29.875953871379984],
                        [16.344976840895242, -28.5767050106977],
                    ],
                    [
                        [28.978262566857243, -28.95559661226171],
                        [28.541700066855498, -28.64750172293757],
                        [28.074338413207784, -28.851468601193588],
                        [27.532511020627478, -29.24271087007536],
                        [26.999261915807637, -29.875953871379984],
                        [27.749397006956485, -30.645105889612225],
                        [28.107204624145425, -30.54573211031495],
                        [28.29106937023991, -30.2262167294543],
                        [28.84839969250774, -30.070050551068256],
                        [29.018415154748027, -29.74376555757737],
                        [29.32516645683259, -29.257386976846256],
                        [28.978262566857243, -28.95559661226171],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Libya",
                sov_a3: "LBY",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Libya",
                adm0_a3: "LBY",
                geou_dif: 0,
                geounit: "Libya",
                gu_a3: "LBY",
                su_dif: 0,
                subunit: "Libya",
                su_a3: "LBY",
                brk_diff: 0,
                name: "Libya",
                name_long: "Libya",
                brk_a3: "LBY",
                brk_name: "Libya",
                brk_group: null,
                abbrev: "Libya",
                postal: "LY",
                formal_en: "Libya",
                formal_fr: null,
                name_ciawf: "Libya",
                note_adm0: null,
                note_brk: null,
                name_sort: "Libya",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 2,
                mapcolor9: 2,
                mapcolor13: 11,
                pop_est: 6777452,
                pop_rank: 13,
                pop_year: 2019,
                gdp_md: 52091,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "LY",
                iso_a2: "LY",
                iso_a2_eh: "LY",
                iso_a3: "LBY",
                iso_a3_eh: "LBY",
                iso_n3: "434",
                iso_n3_eh: "434",
                un_a3: "434",
                wb_a2: "LY",
                wb_a3: "LBY",
                woe_id: 23424882,
                woe_id_eh: 23424882,
                woe_note: "Exact WOE match as country",
                adm0_iso: "LBY",
                adm0_diff: null,
                adm0_tlc: "LBY",
                adm0_a3_us: "LBY",
                adm0_a3_fr: "LBY",
                adm0_a3_ru: "LBY",
                adm0_a3_es: "LBY",
                adm0_a3_cn: "LBY",
                adm0_a3_tw: "LBY",
                adm0_a3_in: "LBY",
                adm0_a3_np: "LBY",
                adm0_a3_pk: "LBY",
                adm0_a3_de: "LBY",
                adm0_a3_gb: "LBY",
                adm0_a3_br: "LBY",
                adm0_a3_il: "LBY",
                adm0_a3_ps: "LBY",
                adm0_a3_sa: "LBY",
                adm0_a3_eg: "LBY",
                adm0_a3_ma: "LBY",
                adm0_a3_pt: "LBY",
                adm0_a3_ar: "LBY",
                adm0_a3_jp: "LBY",
                adm0_a3_ko: "LBY",
                adm0_a3_vn: "LBY",
                adm0_a3_tr: "LBY",
                adm0_a3_id: "LBY",
                adm0_a3_pl: "LBY",
                adm0_a3_gr: "LBY",
                adm0_a3_it: "LBY",
                adm0_a3_nl: "LBY",
                adm0_a3_se: "LBY",
                adm0_a3_bd: "LBY",
                adm0_a3_ua: "LBY",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 18.011015,
                label_y: 26.638944,
                ne_id: 1159321017,
                wikidataid: "Q1016",
                name_ar: "ليبيا",
                name_bn: "লিবিয়া",
                name_de: "Libyen",
                name_en: "Libya",
                name_es: "Libia",
                name_fa: "لیبی",
                name_fr: "Libye",
                name_el: "Λιβύη",
                name_he: "לוב",
                name_hi: "लीबिया",
                name_hu: "Líbia",
                name_id: "Libya",
                name_it: "Libia",
                name_ja: "リビア",
                name_ko: "리비아",
                name_nl: "Libië",
                name_pl: "Libia",
                name_pt: "Líbia",
                name_ru: "Ливия",
                name_sv: "Libyen",
                name_tr: "Libya",
                name_uk: "Лівія",
                name_ur: "لیبیا",
                name_vi: "Libya",
                name_zh: "利比亚",
                name_zht: "利比亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "LBY.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [25, 22],
                        [25.000000000000114, 20.00304],
                        [23.850000000000136, 20],
                        [23.83766000000014, 19.580470000000105],
                        [19.84926, 21.49509],
                        [15.86085, 23.40972],
                        [14.8513, 22.862950000000126],
                        [14.143870883855243, 22.491288967371133],
                        [13.581424594790462, 23.04050608976928],
                        [11.999505649471613, 23.47166840259645],
                        [11.560669386449005, 24.097909247325518],
                        [10.771363559622927, 24.56253205006175],
                        [10.303846876678362, 24.379313259370917],
                        [9.94826134607797, 24.936953640232517],
                        [9.910692579801776, 25.365454616796796],
                        [9.319410841518163, 26.094324856057455],
                        [9.716285841519664, 26.512206325785655],
                        [9.629056023811074, 27.14095347748092],
                        [9.756128370816782, 27.688258571884205],
                        [9.683884718472768, 28.1441738957792],
                        [9.859997999723447, 28.959989732371014],
                        [9.805634392952356, 29.424638373323376],
                        [9.482139926805274, 30.307556057246188],
                        [9.970017124072854, 30.53932485607524],
                        [10.056575148161699, 30.961831366493527],
                        [9.950225050505082, 31.376069647745258],
                        [10.636901482799487, 31.761420803345757],
                        [10.944789666394456, 32.081814683555365],
                        [11.432253452203696, 32.368903103152874],
                        [11.488787469131012, 33.13699575452324],
                        [12.66331, 32.79278],
                        [13.08326, 32.87882],
                        [13.91868, 32.71196],
                        [15.24563, 32.26508],
                        [15.71394, 31.37626],
                        [16.61162, 31.18218],
                        [18.02109, 30.76357],
                        [19.08641, 30.26639],
                        [19.57404, 30.52582],
                        [20.05335, 30.98576],
                        [19.82033, 31.751790000000142],
                        [20.13397, 32.2382],
                        [20.85452, 32.7068],
                        [21.54298, 32.8432],
                        [22.89576, 32.63858],
                        [23.2368, 32.19149],
                        [23.609130000000107, 32.18726],
                        [23.9275, 32.01667],
                        [24.92114, 31.89936],
                        [25.16482, 31.56915],
                        [24.80287, 31.08929],
                        [24.95762, 30.6616],
                        [24.70007, 30.04419],
                        [25, 29.23865452953346],
                        [25, 25.682499996361],
                        [25, 22],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Tunisia",
                sov_a3: "TUN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Tunisia",
                adm0_a3: "TUN",
                geou_dif: 0,
                geounit: "Tunisia",
                gu_a3: "TUN",
                su_dif: 0,
                subunit: "Tunisia",
                su_a3: "TUN",
                brk_diff: 0,
                name: "Tunisia",
                name_long: "Tunisia",
                brk_a3: "TUN",
                brk_name: "Tunisia",
                brk_group: null,
                abbrev: "Tun.",
                postal: "TN",
                formal_en: "Republic of Tunisia",
                formal_fr: null,
                name_ciawf: "Tunisia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Tunisia",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 3,
                mapcolor9: 3,
                mapcolor13: 2,
                pop_est: 11694719,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 38796,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "TS",
                iso_a2: "TN",
                iso_a2_eh: "TN",
                iso_a3: "TUN",
                iso_a3_eh: "TUN",
                iso_n3: "788",
                iso_n3_eh: "788",
                un_a3: "788",
                wb_a2: "TN",
                wb_a3: "TUN",
                woe_id: 23424967,
                woe_id_eh: 23424967,
                woe_note: "Exact WOE match as country",
                adm0_iso: "TUN",
                adm0_diff: null,
                adm0_tlc: "TUN",
                adm0_a3_us: "TUN",
                adm0_a3_fr: "TUN",
                adm0_a3_ru: "TUN",
                adm0_a3_es: "TUN",
                adm0_a3_cn: "TUN",
                adm0_a3_tw: "TUN",
                adm0_a3_in: "TUN",
                adm0_a3_np: "TUN",
                adm0_a3_pk: "TUN",
                adm0_a3_de: "TUN",
                adm0_a3_gb: "TUN",
                adm0_a3_br: "TUN",
                adm0_a3_il: "TUN",
                adm0_a3_ps: "TUN",
                adm0_a3_sa: "TUN",
                adm0_a3_eg: "TUN",
                adm0_a3_ma: "TUN",
                adm0_a3_pt: "TUN",
                adm0_a3_ar: "TUN",
                adm0_a3_jp: "TUN",
                adm0_a3_ko: "TUN",
                adm0_a3_vn: "TUN",
                adm0_a3_tr: "TUN",
                adm0_a3_id: "TUN",
                adm0_a3_pl: "TUN",
                adm0_a3_gr: "TUN",
                adm0_a3_it: "TUN",
                adm0_a3_nl: "TUN",
                adm0_a3_se: "TUN",
                adm0_a3_bd: "TUN",
                adm0_a3_ua: "TUN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 9.007881,
                label_y: 33.687263,
                ne_id: 1159321327,
                wikidataid: "Q948",
                name_ar: "تونس",
                name_bn: "তিউনিসিয়া",
                name_de: "Tunesien",
                name_en: "Tunisia",
                name_es: "Túnez",
                name_fa: "تونس",
                name_fr: "Tunisie",
                name_el: "Τυνησία",
                name_he: "תוניסיה",
                name_hi: "ट्यूनिशिया",
                name_hu: "Tunézia",
                name_id: "Tunisia",
                name_it: "Tunisia",
                name_ja: "チュニジア",
                name_ko: "튀니지",
                name_nl: "Tunesië",
                name_pl: "Tunezja",
                name_pt: "Tunísia",
                name_ru: "Тунис",
                name_sv: "Tunisien",
                name_tr: "Tunus",
                name_uk: "Туніс",
                name_ur: "تونس",
                name_vi: "Tuy-ni-di",
                name_zh: "突尼斯",
                name_zht: "突尼西亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "TUN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [9.482139926805274, 30.307556057246188],
                        [9.05560265466815, 32.10269196220129],
                        [8.439102817426118, 32.50628489840082],
                        [8.430472853233368, 32.74833730725595],
                        [7.612641635782182, 33.34411489514896],
                        [7.524481642292244, 34.09737641045146],
                        [8.140981479534304, 34.65514598239379],
                        [8.376367628623768, 35.479876003555944],
                        [8.217824334352315, 36.433176988260286],
                        [8.420964389691676, 36.94642731378316],
                        [9.509993523810607, 37.349994411766545],
                        [10.210002475636317, 37.230001735984814],
                        [10.180650262094531, 36.724037787415085],
                        [11.02886722173335, 37.09210317641396],
                        [11.100025668999251, 36.899996039368915],
                        [10.600004510143094, 36.410000108377375],
                        [10.593286573945138, 35.94744436293281],
                        [10.939518670300687, 35.698984076473494],
                        [10.807847120821009, 34.83350718844919],
                        [10.149592726287125, 34.33077301689771],
                        [10.339658644256616, 33.78574168551532],
                        [10.856836378633687, 33.76874013929128],
                        [11.108500603895122, 33.293342800422195],
                        [11.488787469131012, 33.13699575452324],
                        [11.432253452203696, 32.368903103152874],
                        [10.944789666394456, 32.081814683555365],
                        [10.636901482799487, 31.761420803345757],
                        [9.950225050505082, 31.376069647745258],
                        [10.056575148161699, 30.961831366493527],
                        [9.970017124072854, 30.53932485607524],
                        [9.482139926805274, 30.307556057246188],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Zambia",
                sov_a3: "ZMB",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Zambia",
                adm0_a3: "ZMB",
                geou_dif: 0,
                geounit: "Zambia",
                gu_a3: "ZMB",
                su_dif: 0,
                subunit: "Zambia",
                su_a3: "ZMB",
                brk_diff: 0,
                name: "Zambia",
                name_long: "Zambia",
                brk_a3: "ZMB",
                brk_name: "Zambia",
                brk_group: null,
                abbrev: "Zambia",
                postal: "ZM",
                formal_en: "Republic of Zambia",
                formal_fr: null,
                name_ciawf: "Zambia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Zambia",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 8,
                mapcolor9: 5,
                mapcolor13: 13,
                pop_est: 17861030,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 23309,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "4. Lower middle income",
                fips_10: "ZA",
                iso_a2: "ZM",
                iso_a2_eh: "ZM",
                iso_a3: "ZMB",
                iso_a3_eh: "ZMB",
                iso_n3: "894",
                iso_n3_eh: "894",
                un_a3: "894",
                wb_a2: "ZM",
                wb_a3: "ZMB",
                woe_id: 23425003,
                woe_id_eh: 23425003,
                woe_note: "Exact WOE match as country",
                adm0_iso: "ZMB",
                adm0_diff: null,
                adm0_tlc: "ZMB",
                adm0_a3_us: "ZMB",
                adm0_a3_fr: "ZMB",
                adm0_a3_ru: "ZMB",
                adm0_a3_es: "ZMB",
                adm0_a3_cn: "ZMB",
                adm0_a3_tw: "ZMB",
                adm0_a3_in: "ZMB",
                adm0_a3_np: "ZMB",
                adm0_a3_pk: "ZMB",
                adm0_a3_de: "ZMB",
                adm0_a3_gb: "ZMB",
                adm0_a3_br: "ZMB",
                adm0_a3_il: "ZMB",
                adm0_a3_ps: "ZMB",
                adm0_a3_sa: "ZMB",
                adm0_a3_eg: "ZMB",
                adm0_a3_ma: "ZMB",
                adm0_a3_pt: "ZMB",
                adm0_a3_ar: "ZMB",
                adm0_a3_jp: "ZMB",
                adm0_a3_ko: "ZMB",
                adm0_a3_vn: "ZMB",
                adm0_a3_tr: "ZMB",
                adm0_a3_id: "ZMB",
                adm0_a3_pl: "ZMB",
                adm0_a3_gr: "ZMB",
                adm0_a3_it: "ZMB",
                adm0_a3_nl: "ZMB",
                adm0_a3_se: "ZMB",
                adm0_a3_bd: "ZMB",
                adm0_a3_ua: "ZMB",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 26.395298,
                label_y: -14.660804,
                ne_id: 1159321439,
                wikidataid: "Q953",
                name_ar: "زامبيا",
                name_bn: "জাম্বিয়া",
                name_de: "Sambia",
                name_en: "Zambia",
                name_es: "Zambia",
                name_fa: "زامبیا",
                name_fr: "Zambie",
                name_el: "Ζάμπια",
                name_he: "זמביה",
                name_hi: "ज़ाम्बिया",
                name_hu: "Zambia",
                name_id: "Zambia",
                name_it: "Zambia",
                name_ja: "ザンビア",
                name_ko: "잠비아",
                name_nl: "Zambia",
                name_pl: "Zambia",
                name_pt: "Zâmbia",
                name_ru: "Замбия",
                name_sv: "Zambia",
                name_tr: "Zambiya",
                name_uk: "Замбія",
                name_ur: "زیمبیا",
                name_vi: "Zambia",
                name_zh: "赞比亚",
                name_zht: "尚比亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ZMB.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.740009731422095, -8.34000593035372],
                        [31.15775133695005, -8.594578747317366],
                        [31.556348097466497, -8.762048841998642],
                        [32.19186486179194, -8.930358981973257],
                        [32.75937544122132, -9.23059905358906],
                        [33.2313879737753, -9.6767216935648],
                        [33.48568769708359, -10.525558770391115],
                        [33.315310499817286, -10.796549981329697],
                        [33.114289178201915, -11.607198174692314],
                        [33.306422153463075, -12.435778090060218],
                        [32.991764357237884, -12.783870537978274],
                        [32.68816531752313, -13.712857761289277],
                        [33.214024692525214, -13.971860039936153],
                        [30.17948123548183, -14.796099134991529],
                        [30.27425581230511, -15.507786960515213],
                        [29.516834344203147, -15.644677829656388],
                        [28.947463413211267, -16.04305144619444],
                        [28.8258687680285, -16.389748630440614],
                        [28.467906121542683, -16.468400160388846],
                        [27.59824344250276, -17.290830580314008],
                        [27.044427117630732, -17.938026218337434],
                        [26.70677330903564, -17.961228936436484],
                        [26.381935255648926, -17.8460421688579],
                        [25.264225701608012, -17.736539808831417],
                        [25.08444339366457, -17.661815687737374],
                        [25.07695031098226, -17.57882333747662],
                        [24.682349074001507, -17.353410739819473],
                        [24.033861525170778, -17.295843194246324],
                        [23.215048455506064, -17.523116143465984],
                        [22.56247846852426, -16.898451429921813],
                        [21.887842644953874, -16.08031015387688],
                        [21.933886346125917, -12.898437188369359],
                        [24.016136508894675, -12.911046237848574],
                        [23.930922072045377, -12.565847670138856],
                        [24.079905226342845, -12.191296888887365],
                        [23.904153680118185, -11.722281589406322],
                        [24.01789350759259, -11.23729827234709],
                        [23.912215203555718, -10.926826267137514],
                        [24.25715538910399, -10.951992689663657],
                        [24.31451622894795, -11.26282642989927],
                        [24.78316979340295, -11.238693536018964],
                        [25.418118116973204, -11.330935967659961],
                        [25.752309604604733, -11.784965101776358],
                        [26.553087599399618, -11.924439792532127],
                        [27.164419793412463, -11.608748467661075],
                        [27.388798862423783, -12.132747491100666],
                        [28.155108676879987, -12.272480564017897],
                        [28.523561639121027, -12.698604424696683],
                        [28.934285922976837, -13.248958428605135],
                        [29.69961388521949, -13.257226657771831],
                        [29.61600141777123, -12.178894545137311],
                        [29.34154788586909, -12.360743910372413],
                        [28.642417433392353, -11.971568698782315],
                        [28.372253045370428, -11.793646742401393],
                        [28.49606977714177, -10.789883721564046],
                        [28.67368167492893, -9.605924981324932],
                        [28.449871046672826, -9.164918308146085],
                        [28.734866570762502, -8.526559340044578],
                        [29.00291222506047, -8.407031752153472],
                        [30.346086053190817, -8.238256524288218],
                        [30.740009731422095, -8.34000593035372],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Sierra Leone",
                sov_a3: "SLE",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Sierra Leone",
                adm0_a3: "SLE",
                geou_dif: 0,
                geounit: "Sierra Leone",
                gu_a3: "SLE",
                su_dif: 0,
                subunit: "Sierra Leone",
                su_a3: "SLE",
                brk_diff: 0,
                name: "Sierra Leone",
                name_long: "Sierra Leone",
                brk_a3: "SLE",
                brk_name: "Sierra Leone",
                brk_group: null,
                abbrev: "S.L.",
                postal: "SL",
                formal_en: "Republic of Sierra Leone",
                formal_fr: null,
                name_ciawf: "Sierra Leone",
                note_adm0: null,
                note_brk: null,
                name_sort: "Sierra Leone",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 4,
                mapcolor9: 1,
                mapcolor13: 7,
                pop_est: 7813215,
                pop_rank: 13,
                pop_year: 2019,
                gdp_md: 4121,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "SL",
                iso_a2: "SL",
                iso_a2_eh: "SL",
                iso_a3: "SLE",
                iso_a3_eh: "SLE",
                iso_n3: "694",
                iso_n3_eh: "694",
                un_a3: "694",
                wb_a2: "SL",
                wb_a3: "SLE",
                woe_id: 23424946,
                woe_id_eh: 23424946,
                woe_note: "Exact WOE match as country",
                adm0_iso: "SLE",
                adm0_diff: null,
                adm0_tlc: "SLE",
                adm0_a3_us: "SLE",
                adm0_a3_fr: "SLE",
                adm0_a3_ru: "SLE",
                adm0_a3_es: "SLE",
                adm0_a3_cn: "SLE",
                adm0_a3_tw: "SLE",
                adm0_a3_in: "SLE",
                adm0_a3_np: "SLE",
                adm0_a3_pk: "SLE",
                adm0_a3_de: "SLE",
                adm0_a3_gb: "SLE",
                adm0_a3_br: "SLE",
                adm0_a3_il: "SLE",
                adm0_a3_ps: "SLE",
                adm0_a3_sa: "SLE",
                adm0_a3_eg: "SLE",
                adm0_a3_ma: "SLE",
                adm0_a3_pt: "SLE",
                adm0_a3_ar: "SLE",
                adm0_a3_jp: "SLE",
                adm0_a3_ko: "SLE",
                adm0_a3_vn: "SLE",
                adm0_a3_tr: "SLE",
                adm0_a3_id: "SLE",
                adm0_a3_pl: "SLE",
                adm0_a3_gr: "SLE",
                adm0_a3_it: "SLE",
                adm0_a3_nl: "SLE",
                adm0_a3_se: "SLE",
                adm0_a3_bd: "SLE",
                adm0_a3_ua: "SLE",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 12,
                long_len: 12,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: -11.763677,
                label_y: 8.617449,
                ne_id: 1159321251,
                wikidataid: "Q1044",
                name_ar: "سيراليون",
                name_bn: "সিয়েরা লিওন",
                name_de: "Sierra Leone",
                name_en: "Sierra Leone",
                name_es: "Sierra Leona",
                name_fa: "سیرالئون",
                name_fr: "Sierra Leone",
                name_el: "Σιέρα Λεόνε",
                name_he: "סיירה לאון",
                name_hi: "सिएरा लियोन",
                name_hu: "Sierra Leone",
                name_id: "Sierra Leone",
                name_it: "Sierra Leone",
                name_ja: "シエラレオネ",
                name_ko: "시에라리온",
                name_nl: "Sierra Leone",
                name_pl: "Sierra Leone",
                name_pt: "Serra Leoa",
                name_ru: "Сьерра-Леоне",
                name_sv: "Sierra Leone",
                name_tr: "Sierra Leone",
                name_uk: "Сьєрра-Леоне",
                name_ur: "سیرالیون",
                name_vi: "Sierra Leone",
                name_zh: "塞拉利昂",
                name_zht: "獅子山",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SLE.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-13.246550258832515, 8.903048610871508],
                        [-12.71195756677308, 9.342711696810767],
                        [-12.59671912276221, 9.62018830000197],
                        [-12.425928514037565, 9.835834051955956],
                        [-12.150338100625005, 9.858571682164381],
                        [-11.917277390988659, 10.046983954300558],
                        [-11.11748124840733, 10.045872911006285],
                        [-10.839151984083301, 9.688246161330369],
                        [-10.622395188835041, 9.267910061068278],
                        [-10.654770473665891, 8.977178452994195],
                        [-10.494315151399633, 8.715540676300435],
                        [-10.505477260774668, 8.348896389189605],
                        [-10.23009355309128, 8.406205552601293],
                        [-10.69559485517648, 7.939464016141088],
                        [-11.146704270868383, 7.396706447779536],
                        [-11.19980180504828, 7.105845648624737],
                        [-11.438779466182055, 6.785916856305747],
                        [-11.70819454593574, 6.860098374860726],
                        [-12.428098924193819, 7.26294200279203],
                        [-12.949049038128194, 7.798645738145738],
                        [-13.124025437868482, 8.163946438016978],
                        [-13.246550258832515, 8.903048610871508],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Sudan",
                sov_a3: "SDN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Sudan",
                adm0_a3: "SDN",
                geou_dif: 0,
                geounit: "Sudan",
                gu_a3: "SDN",
                su_dif: 0,
                subunit: "Sudan",
                su_a3: "SDN",
                brk_diff: 0,
                name: "Sudan",
                name_long: "Sudan",
                brk_a3: "SDN",
                brk_name: "Sudan",
                brk_group: null,
                abbrev: "Sudan",
                postal: "SD",
                formal_en: "Republic of the Sudan",
                formal_fr: null,
                name_ciawf: "Sudan",
                note_adm0: null,
                note_brk: null,
                name_sort: "Sudan",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 6,
                mapcolor9: 4,
                mapcolor13: 1,
                pop_est: 42813238,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 30513,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "SU",
                iso_a2: "SD",
                iso_a2_eh: "SD",
                iso_a3: "SDN",
                iso_a3_eh: "SDN",
                iso_n3: "729",
                iso_n3_eh: "729",
                un_a3: "729",
                wb_a2: "SD",
                wb_a3: "SDN",
                woe_id: -90,
                woe_id_eh: 23424952,
                woe_note: "Almost all FLickr photos are in the north.",
                adm0_iso: "SDZ",
                adm0_diff: null,
                adm0_tlc: "SDZ",
                adm0_a3_us: "SDN",
                adm0_a3_fr: "SDN",
                adm0_a3_ru: "SDN",
                adm0_a3_es: "SDN",
                adm0_a3_cn: "SDN",
                adm0_a3_tw: "SDN",
                adm0_a3_in: "SDN",
                adm0_a3_np: "SDN",
                adm0_a3_pk: "SDN",
                adm0_a3_de: "SDN",
                adm0_a3_gb: "SDN",
                adm0_a3_br: "SDN",
                adm0_a3_il: "SDN",
                adm0_a3_ps: "SDN",
                adm0_a3_sa: "SDN",
                adm0_a3_eg: "SDN",
                adm0_a3_ma: "SDN",
                adm0_a3_pt: "SDN",
                adm0_a3_ar: "SDN",
                adm0_a3_jp: "SDN",
                adm0_a3_ko: "SDN",
                adm0_a3_vn: "SDN",
                adm0_a3_tr: "SDN",
                adm0_a3_id: "SDN",
                adm0_a3_pl: "SDN",
                adm0_a3_gr: "SDN",
                adm0_a3_it: "SDN",
                adm0_a3_nl: "SDN",
                adm0_a3_se: "SDN",
                adm0_a3_bd: "SDN",
                adm0_a3_ua: "SDN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.5,
                max_label: 8,
                label_x: 29.260657,
                label_y: 16.330746,
                ne_id: 1159321229,
                wikidataid: "Q1049",
                name_ar: "السودان",
                name_bn: "সুদান",
                name_de: "Sudan",
                name_en: "Sudan",
                name_es: "Sudán",
                name_fa: "سودان",
                name_fr: "Soudan",
                name_el: "Σουδάν",
                name_he: "סודאן",
                name_hi: "सूडान",
                name_hu: "Szudán",
                name_id: "Sudan",
                name_it: "Sudan",
                name_ja: "スーダン",
                name_ko: "수단",
                name_nl: "Soedan",
                name_pl: "Sudan",
                name_pt: "Sudão",
                name_ru: "Судан",
                name_sv: "Sudan",
                name_tr: "Sudan",
                name_uk: "Судан",
                name_ur: "سوڈان",
                name_vi: "Sudan",
                name_zh: "苏丹",
                name_zht: "蘇丹",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SDN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [24.567369012152085, 8.229187933785468],
                        [23.805813429466752, 8.666318874542526],
                        [23.459012892355986, 8.954285793488893],
                        [23.394779087017184, 9.265067857292223],
                        [23.55724979014283, 9.681218166538684],
                        [23.554304233502194, 10.089255275915308],
                        [22.97754357269261, 10.71446259199854],
                        [22.864165480244225, 11.142395127807546],
                        [22.87622, 11.384610000000123],
                        [22.50869, 11.67936],
                        [22.49762, 12.26024],
                        [22.28801, 12.64605],
                        [21.93681, 12.588180000000136],
                        [22.03759, 12.95546],
                        [22.29658, 13.37232],
                        [22.18329, 13.78648],
                        [22.51202, 14.09318],
                        [22.30351, 14.32682],
                        [22.56795000000011, 14.944290000000137],
                        [23.024590000000103, 15.68072],
                        [23.886890000000108, 15.61084],
                        [23.83766000000014, 19.580470000000105],
                        [23.850000000000136, 20],
                        [25.000000000000114, 20.00304],
                        [25, 22],
                        [29.02, 22],
                        [32.9, 22],
                        [36.86623, 22],
                        [37.1887200000001, 21.01885],
                        [36.96941, 20.83744000000013],
                        [37.11470000000014, 19.80796],
                        [37.4817900000001, 18.61409],
                        [37.86276, 18.36786],
                        [38.410089959473225, 17.998307399970315],
                        [37.90400000000011, 17.42754],
                        [37.16747, 17.263140000000135],
                        [36.852530000000115, 16.95655],
                        [36.75389, 16.29186],
                        [36.32322, 14.82249],
                        [36.42951, 14.42211],
                        [36.27022, 13.563330000000121],
                        [35.86363, 12.57828],
                        [35.26049, 12.08286],
                        [34.83163000000013, 11.318960000000118],
                        [34.73115000000013, 10.910170000000107],
                        [34.25745, 10.63009],
                        [33.96162, 9.58358],
                        [33.97498, 8.68456],
                        [33.963392794971185, 9.464285229420625],
                        [33.82496348090751, 9.484060845715362],
                        [33.84213085302815, 9.981914637215993],
                        [33.72195924818311, 10.325262079630193],
                        [33.206938084561784, 10.720111638406593],
                        [33.086766479716744, 11.441141267476496],
                        [33.206938084561784, 12.179338268667095],
                        [32.743419037302544, 12.248007757149992],
                        [32.67474954881965, 12.02483191958072],
                        [32.073891524594785, 11.973329803218519],
                        [32.31423473428475, 11.68148447716652],
                        [32.400071594888345, 11.080626452941488],
                        [31.850715687025513, 10.531270545078826],
                        [31.35286189552488, 9.810240916008695],
                        [30.837840731903384, 9.70723668328452],
                        [29.996639497988554, 10.290927335388687],
                        [29.61895731133285, 10.084918869940225],
                        [29.515953078608618, 9.793073543888056],
                        [29.000931914987177, 9.60423245056029],
                        [28.966597170745786, 9.398223985111656],
                        [27.970889587744352, 9.398223985111656],
                        [27.833550610778786, 9.60423245056029],
                        [27.112520981708883, 9.638567194801624],
                        [26.752006167173818, 9.466893473594496],
                        [26.477328213242515, 9.552730334198088],
                        [25.962307049621018, 10.136420986302426],
                        [25.790633328413946, 10.411098940233728],
                        [25.069603699343986, 10.273759963267992],
                        [24.794925745412684, 9.810240916008695],
                        [24.53741516360202, 8.91753756573172],
                        [24.19406772118765, 8.728696472403897],
                        [23.886979580860668, 8.619729712933065],
                        [24.567369012152085, 8.229187933785468],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Liberia",
                sov_a3: "LBR",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Liberia",
                adm0_a3: "LBR",
                geou_dif: 0,
                geounit: "Liberia",
                gu_a3: "LBR",
                su_dif: 0,
                subunit: "Liberia",
                su_a3: "LBR",
                brk_diff: 0,
                name: "Liberia",
                name_long: "Liberia",
                brk_a3: "LBR",
                brk_name: "Liberia",
                brk_group: null,
                abbrev: "Liberia",
                postal: "LR",
                formal_en: "Republic of Liberia",
                formal_fr: null,
                name_ciawf: "Liberia",
                note_adm0: null,
                note_brk: null,
                name_sort: "Liberia",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 3,
                mapcolor9: 4,
                mapcolor13: 9,
                pop_est: 4937374,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 3070,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "LI",
                iso_a2: "LR",
                iso_a2_eh: "LR",
                iso_a3: "LBR",
                iso_a3_eh: "LBR",
                iso_n3: "430",
                iso_n3_eh: "430",
                un_a3: "430",
                wb_a2: "LR",
                wb_a3: "LBR",
                woe_id: 23424876,
                woe_id_eh: 23424876,
                woe_note: "Exact WOE match as country",
                adm0_iso: "LBR",
                adm0_diff: null,
                adm0_tlc: "LBR",
                adm0_a3_us: "LBR",
                adm0_a3_fr: "LBR",
                adm0_a3_ru: "LBR",
                adm0_a3_es: "LBR",
                adm0_a3_cn: "LBR",
                adm0_a3_tw: "LBR",
                adm0_a3_in: "LBR",
                adm0_a3_np: "LBR",
                adm0_a3_pk: "LBR",
                adm0_a3_de: "LBR",
                adm0_a3_gb: "LBR",
                adm0_a3_br: "LBR",
                adm0_a3_il: "LBR",
                adm0_a3_ps: "LBR",
                adm0_a3_sa: "LBR",
                adm0_a3_eg: "LBR",
                adm0_a3_ma: "LBR",
                adm0_a3_pt: "LBR",
                adm0_a3_ar: "LBR",
                adm0_a3_jp: "LBR",
                adm0_a3_ko: "LBR",
                adm0_a3_vn: "LBR",
                adm0_a3_tr: "LBR",
                adm0_a3_id: "LBR",
                adm0_a3_pl: "LBR",
                adm0_a3_gr: "LBR",
                adm0_a3_it: "LBR",
                adm0_a3_nl: "LBR",
                adm0_a3_se: "LBR",
                adm0_a3_bd: "LBR",
                adm0_a3_ua: "LBR",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 7,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: -9.460379,
                label_y: 6.447177,
                ne_id: 1159321015,
                wikidataid: "Q1014",
                name_ar: "ليبيريا",
                name_bn: "লাইবেরিয়া",
                name_de: "Liberia",
                name_en: "Liberia",
                name_es: "Liberia",
                name_fa: "لیبریا",
                name_fr: "Liberia",
                name_el: "Λιβερία",
                name_he: "ליבריה",
                name_hi: "लाइबेरिया",
                name_hu: "Libéria",
                name_id: "Liberia",
                name_it: "Liberia",
                name_ja: "リベリア",
                name_ko: "라이베리아",
                name_nl: "Liberia",
                name_pl: "Liberia",
                name_pt: "Libéria",
                name_ru: "Либерия",
                name_sv: "Liberia",
                name_tr: "Liberya",
                name_uk: "Ліберія",
                name_ur: "لائبیریا",
                name_vi: "Liberia",
                name_zh: "利比里亚",
                name_zht: "賴比瑞亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "LBR.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.439298468448698, 7.686042792181738],
                        [-8.48544552248535, 7.39520783124307],
                        [-8.385451626000574, 6.911800645368743],
                        [-8.60288021486862, 6.46756419517166],
                        [-8.311347622094019, 6.193033148621083],
                        [-7.993692592795881, 6.126189683451543],
                        [-7.570152553731688, 5.707352199725904],
                        [-7.539715135111763, 5.313345241716519],
                        [-7.635368211284031, 5.188159084489456],
                        [-7.71215938966975, 4.364565944837722],
                        [-7.974107224957251, 4.355755113131963],
                        [-9.004793667018674, 4.8324185245922],
                        [-9.913420376006684, 5.593560695819207],
                        [-10.765383876986645, 6.140710760925558],
                        [-11.438779466182055, 6.785916856305747],
                        [-11.19980180504828, 7.105845648624737],
                        [-11.146704270868383, 7.396706447779536],
                        [-10.69559485517648, 7.939464016141088],
                        [-10.23009355309128, 8.406205552601293],
                        [-10.016566534861255, 8.428503933135232],
                        [-9.755342169625834, 8.541055202666925],
                        [-9.337279832384581, 7.928534450711354],
                        [-9.40334815106975, 7.526905218938907],
                        [-9.208786383490846, 7.313920803247953],
                        [-8.926064622422004, 7.309037380396376],
                        [-8.722123582382125, 7.71167430259851],
                        [-8.439298468448698, 7.686042792181738],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Central African Republic",
                sov_a3: "CAF",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Central African Republic",
                adm0_a3: "CAF",
                geou_dif: 0,
                geounit: "Central African Republic",
                gu_a3: "CAF",
                su_dif: 0,
                subunit: "Central African Republic",
                su_a3: "CAF",
                brk_diff: 0,
                name: "Central African Rep.",
                name_long: "Central African Republic",
                brk_a3: "CAF",
                brk_name: "Central African Rep.",
                brk_group: null,
                abbrev: "C.A.R.",
                postal: "CF",
                formal_en: "Central African Republic",
                formal_fr: null,
                name_ciawf: "Central African Republic",
                note_adm0: null,
                note_brk: null,
                name_sort: "Central African Republic",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 6,
                mapcolor9: 6,
                mapcolor13: 9,
                pop_est: 4745185,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 2220,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "CT",
                iso_a2: "CF",
                iso_a2_eh: "CF",
                iso_a3: "CAF",
                iso_a3_eh: "CAF",
                iso_n3: "140",
                iso_n3_eh: "140",
                un_a3: "140",
                wb_a2: "CF",
                wb_a3: "CAF",
                woe_id: 23424792,
                woe_id_eh: 23424792,
                woe_note: "Exact WOE match as country",
                adm0_iso: "CAF",
                adm0_diff: null,
                adm0_tlc: "CAF",
                adm0_a3_us: "CAF",
                adm0_a3_fr: "CAF",
                adm0_a3_ru: "CAF",
                adm0_a3_es: "CAF",
                adm0_a3_cn: "CAF",
                adm0_a3_tw: "CAF",
                adm0_a3_in: "CAF",
                adm0_a3_np: "CAF",
                adm0_a3_pk: "CAF",
                adm0_a3_de: "CAF",
                adm0_a3_gb: "CAF",
                adm0_a3_br: "CAF",
                adm0_a3_il: "CAF",
                adm0_a3_ps: "CAF",
                adm0_a3_sa: "CAF",
                adm0_a3_eg: "CAF",
                adm0_a3_ma: "CAF",
                adm0_a3_pt: "CAF",
                adm0_a3_ar: "CAF",
                adm0_a3_jp: "CAF",
                adm0_a3_ko: "CAF",
                adm0_a3_vn: "CAF",
                adm0_a3_tr: "CAF",
                adm0_a3_id: "CAF",
                adm0_a3_pl: "CAF",
                adm0_a3_gr: "CAF",
                adm0_a3_it: "CAF",
                adm0_a3_nl: "CAF",
                adm0_a3_se: "CAF",
                adm0_a3_bd: "CAF",
                adm0_a3_ua: "CAF",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 20,
                long_len: 24,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 20.906897,
                label_y: 6.989681,
                ne_id: 1159320463,
                wikidataid: "Q929",
                name_ar: "جمهورية أفريقيا الوسطى",
                name_bn: "মধ্য আফ্রিকান প্রজাতন্ত্র",
                name_de: "Zentralafrikanische Republik",
                name_en: "Central African Republic",
                name_es: "República Centroafricana",
                name_fa: "جمهوری آفریقای مرکزی",
                name_fr: "République centrafricaine",
                name_el: "Κεντροαφρικανική Δημοκρατία",
                name_he: "הרפובליקה המרכז-אפריקאית",
                name_hi: "मध्य अफ़्रीकी गणराज्य",
                name_hu: "Közép-afrikai Köztársaság",
                name_id: "Republik Afrika Tengah",
                name_it: "Repubblica Centrafricana",
                name_ja: "中央アフリカ共和国",
                name_ko: "중앙아프리카 공화국",
                name_nl: "Centraal-Afrikaanse Republiek",
                name_pl: "Republika Środkowoafrykańska",
                name_pt: "República Centro-Africana",
                name_ru: "Центральноафриканская Республика",
                name_sv: "Centralafrikanska republiken",
                name_tr: "Orta Afrika Cumhuriyeti",
                name_uk: "Центральноафриканська Республіка",
                name_ur: "وسطی افریقی جمہوریہ",
                name_vi: "Cộng hòa Trung Phi",
                name_zh: "中非共和国",
                name_zht: "中非共和國",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "CAF.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [27.37422610851749, 5.233944403500061],
                        [27.04406538260471, 5.127852688004836],
                        [26.402760857862543, 5.150874538590871],
                        [25.650455356557472, 5.256087754737123],
                        [25.278798455514305, 5.170408229997192],
                        [25.12883344900328, 4.92724477784779],
                        [24.805028924262416, 4.89724660890235],
                        [24.410531040146253, 5.10878408448913],
                        [23.29721398285014, 4.609693101414223],
                        [22.841479526468106, 4.710126247573484],
                        [22.70412356943629, 4.633050848810157],
                        [22.405123732195538, 4.029160061047321],
                        [21.659122755630023, 4.22434194581372],
                        [20.927591180106276, 4.322785549329737],
                        [20.290679152108936, 4.691677761245288],
                        [19.46778364429315, 5.03152781821278],
                        [18.93231245288476, 4.709506130385975],
                        [18.54298221199778, 4.201785183118318],
                        [18.45306521980993, 3.504385891123349],
                        [17.809900343505262, 3.56019643799857],
                        [17.133042433346304, 3.728196519379452],
                        [16.537058139724138, 3.198254706226279],
                        [16.012852410555354, 2.267639675298085],
                        [15.907380812247652, 2.557389431158612],
                        [15.862732374747482, 3.013537298998983],
                        [15.405395948964383, 3.33530060466434],
                        [15.036219516671252, 3.851367295747124],
                        [14.950953403389661, 4.210389309094921],
                        [14.47837243008047, 4.732605495620447],
                        [14.558935988023507, 5.03059764243153],
                        [14.459407179429348, 5.4517605656103],
                        [14.536560092841114, 6.22695872642069],
                        [14.776545444404576, 6.408498033062045],
                        [15.279460483469109, 7.421924546737969],
                        [16.106231723706742, 7.497087917506462],
                        [16.290561557691888, 7.754307359239419],
                        [16.456184523187346, 7.734773667832968],
                        [16.705988396886255, 7.508327541529979],
                        [17.964929640380888, 7.890914008002994],
                        [18.38955488452322, 8.281303615751824],
                        [18.911021762780507, 8.630894680206353],
                        [18.812009718509273, 8.982914536978598],
                        [19.09400800952602, 9.07484691002584],
                        [20.05968549976427, 9.012706000194854],
                        [21.000868361096167, 9.475985215691509],
                        [21.723821648859456, 10.567055568885976],
                        [22.23112918466876, 10.97188873946061],
                        [22.864165480244225, 11.142395127807546],
                        [22.97754357269261, 10.71446259199854],
                        [23.554304233502194, 10.089255275915308],
                        [23.55724979014283, 9.681218166538684],
                        [23.394779087017184, 9.265067857292223],
                        [23.459012892355986, 8.954285793488893],
                        [23.805813429466752, 8.666318874542526],
                        [24.567369012152085, 8.229187933785468],
                        [25.11493248871679, 7.825104071479174],
                        [25.124130893664727, 7.500085150579437],
                        [25.79664798351118, 6.979315904158071],
                        [26.213418409945117, 6.546603298362072],
                        [26.465909458123235, 5.94671743410187],
                        [27.21340905122517, 5.550953477394557],
                        [27.37422610851749, 5.233944403500061],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Eritrea",
                sov_a3: "ERI",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Eritrea",
                adm0_a3: "ERI",
                geou_dif: 0,
                geounit: "Eritrea",
                gu_a3: "ERI",
                su_dif: 0,
                subunit: "Eritrea",
                su_a3: "ERI",
                brk_diff: 0,
                name: "Eritrea",
                name_long: "Eritrea",
                brk_a3: "ERI",
                brk_name: "Eritrea",
                brk_group: null,
                abbrev: "Erit.",
                postal: "ER",
                formal_en: "State of Eritrea",
                formal_fr: null,
                name_ciawf: "Eritrea",
                note_adm0: null,
                note_brk: null,
                name_sort: "Eritrea",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 1,
                mapcolor9: 2,
                mapcolor13: 12,
                pop_est: 6081196,
                pop_rank: 13,
                pop_year: 2020,
                gdp_md: 2065,
                gdp_year: 2011,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "ER",
                iso_a2: "ER",
                iso_a2_eh: "ER",
                iso_a3: "ERI",
                iso_a3_eh: "ERI",
                iso_n3: "232",
                iso_n3_eh: "232",
                un_a3: "232",
                wb_a2: "ER",
                wb_a3: "ERI",
                woe_id: 23424806,
                woe_id_eh: 23424806,
                woe_note: "Exact WOE match as country",
                adm0_iso: "ERI",
                adm0_diff: null,
                adm0_tlc: "ERI",
                adm0_a3_us: "ERI",
                adm0_a3_fr: "ERI",
                adm0_a3_ru: "ERI",
                adm0_a3_es: "ERI",
                adm0_a3_cn: "ERI",
                adm0_a3_tw: "ERI",
                adm0_a3_in: "ERI",
                adm0_a3_np: "ERI",
                adm0_a3_pk: "ERI",
                adm0_a3_de: "ERI",
                adm0_a3_gb: "ERI",
                adm0_a3_br: "ERI",
                adm0_a3_il: "ERI",
                adm0_a3_ps: "ERI",
                adm0_a3_sa: "ERI",
                adm0_a3_eg: "ERI",
                adm0_a3_ma: "ERI",
                adm0_a3_pt: "ERI",
                adm0_a3_ar: "ERI",
                adm0_a3_jp: "ERI",
                adm0_a3_ko: "ERI",
                adm0_a3_vn: "ERI",
                adm0_a3_tr: "ERI",
                adm0_a3_id: "ERI",
                adm0_a3_pl: "ERI",
                adm0_a3_gr: "ERI",
                adm0_a3_it: "ERI",
                adm0_a3_nl: "ERI",
                adm0_a3_se: "ERI",
                adm0_a3_bd: "ERI",
                adm0_a3_ua: "ERI",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 38.285566,
                label_y: 15.787401,
                ne_id: 1159320581,
                wikidataid: "Q986",
                name_ar: "إريتريا",
                name_bn: "ইরিত্রিয়া",
                name_de: "Eritrea",
                name_en: "Eritrea",
                name_es: "Eritrea",
                name_fa: "اریتره",
                name_fr: "Érythrée",
                name_el: "Ερυθραία",
                name_he: "אריתריאה",
                name_hi: "इरित्रिया",
                name_hu: "Eritrea",
                name_id: "Eritrea",
                name_it: "Eritrea",
                name_ja: "エリトリア",
                name_ko: "에리트레아",
                name_nl: "Eritrea",
                name_pl: "Erytrea",
                name_pt: "Eritreia",
                name_ru: "Эритрея",
                name_sv: "Eritrea",
                name_tr: "Eritre",
                name_uk: "Еритрея",
                name_ur: "اریتریا",
                name_vi: "Eritrea",
                name_zh: "厄立特里亚",
                name_zht: "厄利垂亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ERI.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [36.42951, 14.42211],
                        [36.32322, 14.82249],
                        [36.75389, 16.29186],
                        [36.852530000000115, 16.95655],
                        [37.16747, 17.263140000000135],
                        [37.90400000000011, 17.42754],
                        [38.410089959473225, 17.998307399970315],
                        [38.990622999840014, 16.840626125551694],
                        [39.26611006038803, 15.92272349696725],
                        [39.814293654140215, 15.435647284400318],
                        [41.17927493669765, 14.491079616753211],
                        [41.73495161313235, 13.921036892141558],
                        [42.27683068214486, 13.343992010954423],
                        [42.58957645037526, 13.000421250861905],
                        [43.08122602720016, 12.699638576707116],
                        [42.77964236834475, 12.455415757695675],
                        [42.35156000000012, 12.542230000000131],
                        [42.00975, 12.86582],
                        [41.59856, 13.452090000000112],
                        [41.1552, 13.77333],
                        [40.8966, 14.118640000000141],
                        [40.02625000000012, 14.51959],
                        [39.34061, 14.53155],
                        [39.0994, 14.74064],
                        [38.51295, 14.50547],
                        [37.90607000000011, 14.959430000000168],
                        [37.59377, 14.2131],
                        [36.42951, 14.42211],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 5,
                sovereignt: "Djibouti",
                sov_a3: "DJI",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Djibouti",
                adm0_a3: "DJI",
                geou_dif: 0,
                geounit: "Djibouti",
                gu_a3: "DJI",
                su_dif: 0,
                subunit: "Djibouti",
                su_a3: "DJI",
                brk_diff: 0,
                name: "Djibouti",
                name_long: "Djibouti",
                brk_a3: "DJI",
                brk_name: "Djibouti",
                brk_group: null,
                abbrev: "Dji.",
                postal: "DJ",
                formal_en: "Republic of Djibouti",
                formal_fr: null,
                name_ciawf: "Djibouti",
                note_adm0: null,
                note_brk: null,
                name_sort: "Djibouti",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 2,
                mapcolor9: 4,
                mapcolor13: 8,
                pop_est: 973560,
                pop_rank: 11,
                pop_year: 2019,
                gdp_md: 3324,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "4. Lower middle income",
                fips_10: "DJ",
                iso_a2: "DJ",
                iso_a2_eh: "DJ",
                iso_a3: "DJI",
                iso_a3_eh: "DJI",
                iso_n3: "262",
                iso_n3_eh: "262",
                un_a3: "262",
                wb_a2: "DJ",
                wb_a3: "DJI",
                woe_id: 23424797,
                woe_id_eh: 23424797,
                woe_note: "Exact WOE match as country",
                adm0_iso: "DJI",
                adm0_diff: null,
                adm0_tlc: "DJI",
                adm0_a3_us: "DJI",
                adm0_a3_fr: "DJI",
                adm0_a3_ru: "DJI",
                adm0_a3_es: "DJI",
                adm0_a3_cn: "DJI",
                adm0_a3_tw: "DJI",
                adm0_a3_in: "DJI",
                adm0_a3_np: "DJI",
                adm0_a3_pk: "DJI",
                adm0_a3_de: "DJI",
                adm0_a3_gb: "DJI",
                adm0_a3_br: "DJI",
                adm0_a3_il: "DJI",
                adm0_a3_ps: "DJI",
                adm0_a3_sa: "DJI",
                adm0_a3_eg: "DJI",
                adm0_a3_ma: "DJI",
                adm0_a3_pt: "DJI",
                adm0_a3_ar: "DJI",
                adm0_a3_jp: "DJI",
                adm0_a3_ko: "DJI",
                adm0_a3_vn: "DJI",
                adm0_a3_tr: "DJI",
                adm0_a3_id: "DJI",
                adm0_a3_pl: "DJI",
                adm0_a3_gr: "DJI",
                adm0_a3_it: "DJI",
                adm0_a3_nl: "DJI",
                adm0_a3_se: "DJI",
                adm0_a3_bd: "DJI",
                adm0_a3_ua: "DJI",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 42.498825,
                label_y: 11.976343,
                ne_id: 1159320541,
                wikidataid: "Q977",
                name_ar: "جيبوتي",
                name_bn: "জিবুতি",
                name_de: "Dschibuti",
                name_en: "Djibouti",
                name_es: "Yibuti",
                name_fa: "جیبوتی",
                name_fr: "Djibouti",
                name_el: "Τζιμπουτί",
                name_he: "ג'יבוטי",
                name_hi: "जिबूती",
                name_hu: "Dzsibuti",
                name_id: "Djibouti",
                name_it: "Gibuti",
                name_ja: "ジブチ",
                name_ko: "지부티",
                name_nl: "Djibouti",
                name_pl: "Dżibuti",
                name_pt: "Djibouti",
                name_ru: "Джибути",
                name_sv: "Djibouti",
                name_tr: "Cibuti",
                name_uk: "Джибуті",
                name_ur: "جبوتی",
                name_vi: "Djibouti",
                name_zh: "吉布提",
                name_zht: "吉布地",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "DJI.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [42.35156000000012, 12.542230000000131],
                        [42.77964236834475, 12.455415757695675],
                        [43.08122602720016, 12.699638576707116],
                        [43.31785241066467, 12.390148423711025],
                        [43.286381463398925, 11.974928290245884],
                        [42.715873650896526, 11.735640570518342],
                        [43.14530480324214, 11.462039699748857],
                        [42.77685184100096, 10.92687856693442],
                        [42.55493000000013, 11.105110000000195],
                        [42.31414000000012, 11.0342],
                        [41.755570000000205, 11.050910000000101],
                        [41.73959000000019, 11.355110000000138],
                        [41.66176000000013, 11.6312],
                        [42.000000000000114, 12.100000000000136],
                        [42.35156000000012, 12.542230000000131],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Ivory Coast",
                sov_a3: "CIV",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Ivory Coast",
                adm0_a3: "CIV",
                geou_dif: 0,
                geounit: "Ivory Coast",
                gu_a3: "CIV",
                su_dif: 0,
                subunit: "Ivory Coast",
                su_a3: "CIV",
                brk_diff: 0,
                name: "Côte d'Ivoire",
                name_long: "Côte d'Ivoire",
                brk_a3: "CIV",
                brk_name: "Côte d'Ivoire",
                brk_group: null,
                abbrev: "I.C.",
                postal: "CI",
                formal_en: "Republic of Ivory Coast",
                formal_fr: "Republic of Cote D'Ivoire",
                name_ciawf: "Cote D'ivoire",
                note_adm0: null,
                note_brk: null,
                name_sort: "Côte d'Ivoire",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 6,
                mapcolor9: 3,
                mapcolor13: 3,
                pop_est: 25716544,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 58539,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "IV",
                iso_a2: "CI",
                iso_a2_eh: "CI",
                iso_a3: "CIV",
                iso_a3_eh: "CIV",
                iso_n3: "384",
                iso_n3_eh: "384",
                un_a3: "384",
                wb_a2: "CI",
                wb_a3: "CIV",
                woe_id: 23424854,
                woe_id_eh: 23424854,
                woe_note: "Exact WOE match as country",
                adm0_iso: "CIV",
                adm0_diff: null,
                adm0_tlc: "CIV",
                adm0_a3_us: "CIV",
                adm0_a3_fr: "CIV",
                adm0_a3_ru: "CIV",
                adm0_a3_es: "CIV",
                adm0_a3_cn: "CIV",
                adm0_a3_tw: "CIV",
                adm0_a3_in: "CIV",
                adm0_a3_np: "CIV",
                adm0_a3_pk: "CIV",
                adm0_a3_de: "CIV",
                adm0_a3_gb: "CIV",
                adm0_a3_br: "CIV",
                adm0_a3_il: "CIV",
                adm0_a3_ps: "CIV",
                adm0_a3_sa: "CIV",
                adm0_a3_eg: "CIV",
                adm0_a3_ma: "CIV",
                adm0_a3_pt: "CIV",
                adm0_a3_ar: "CIV",
                adm0_a3_jp: "CIV",
                adm0_a3_ko: "CIV",
                adm0_a3_vn: "CIV",
                adm0_a3_tr: "CIV",
                adm0_a3_id: "CIV",
                adm0_a3_pl: "CIV",
                adm0_a3_gr: "CIV",
                adm0_a3_it: "CIV",
                adm0_a3_nl: "CIV",
                adm0_a3_se: "CIV",
                adm0_a3_bd: "CIV",
                adm0_a3_ua: "CIV",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 13,
                long_len: 13,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.5,
                max_label: 8,
                label_x: -5.568618,
                label_y: 7.49139,
                ne_id: 1159320507,
                wikidataid: "Q1008",
                name_ar: "ساحل العاج",
                name_bn: "কোত দিভোয়ার",
                name_de: "Elfenbeinküste",
                name_en: "Ivory Coast",
                name_es: "Costa de Marfil",
                name_fa: "ساحل عاج",
                name_fr: "Côte d'Ivoire",
                name_el: "Ακτή Ελεφαντοστού",
                name_he: "חוף השנהב",
                name_hi: "कोत दिव्वार",
                name_hu: "Elefántcsontpart",
                name_id: "Pantai Gading",
                name_it: "Costa d'Avorio",
                name_ja: "コートジボワール",
                name_ko: "코트디부아르",
                name_nl: "Ivoorkust",
                name_pl: "Wybrzeże Kości Słoniowej",
                name_pt: "Costa do Marfim",
                name_ru: "Кот-д’Ивуар",
                name_sv: "Elfenbenskusten",
                name_tr: "Fildişi Sahili",
                name_uk: "Кот-д'Івуар",
                name_ur: "کوت داوواغ",
                name_vi: "Bờ Biển Ngà",
                name_zh: "科特迪瓦",
                name_zht: "象牙海岸",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "CIV.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.029943610048619, 10.206534939001713],
                        [-7.899589809592372, 10.297382106970828],
                        [-7.622759161804809, 10.147236232946796],
                        [-6.850506557635057, 10.138993841996239],
                        [-6.666460944027548, 10.430810655148449],
                        [-6.493965013037268, 10.411302801958271],
                        [-6.205222947606431, 10.524060777219134],
                        [-6.050452032892267, 10.096360785355444],
                        [-5.816926235365287, 10.222554633012194],
                        [-5.404341599946974, 10.370736802609146],
                        [-4.954653286143099, 10.152713934769736],
                        [-4.779883592131966, 9.821984768101743],
                        [-4.330246954760383, 9.610834865757141],
                        [-3.980449184576685, 9.8623440617217],
                        [-3.511898972986273, 9.90032623945622],
                        [-2.827496303712707, 9.642460842319778],
                        [-2.562189500326241, 8.219627793811483],
                        [-2.983584967450327, 7.379704901555513],
                        [-3.244370083011262, 6.250471503113502],
                        [-2.81070146321784, 5.38905121502411],
                        [-2.856125047202397, 4.994475816259509],
                        [-3.311084357100071, 4.984295559098015],
                        [-4.008819545904942, 5.179813340674315],
                        [-4.649917364917911, 5.168263658057086],
                        [-5.834496222344526, 4.993700669775137],
                        [-6.528769090185847, 4.705087795425015],
                        [-7.518941209330436, 4.338288479017308],
                        [-7.71215938966975, 4.364565944837722],
                        [-7.635368211284031, 5.188159084489456],
                        [-7.539715135111763, 5.313345241716519],
                        [-7.570152553731688, 5.707352199725904],
                        [-7.993692592795881, 6.126189683451543],
                        [-8.311347622094019, 6.193033148621083],
                        [-8.60288021486862, 6.46756419517166],
                        [-8.385451626000574, 6.911800645368743],
                        [-8.48544552248535, 7.39520783124307],
                        [-8.439298468448698, 7.686042792181738],
                        [-8.280703497744938, 7.687179673692157],
                        [-8.221792364932199, 8.123328762235573],
                        [-8.299048631208564, 8.316443589710303],
                        [-8.20349890790088, 8.455453192575447],
                        [-7.832100389019188, 8.575704250518626],
                        [-8.07911373537435, 9.376223863152035],
                        [-8.30961646161225, 9.789531968622441],
                        [-8.229337124046822, 10.1290202905639],
                        [-8.029943610048619, 10.206534939001713],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Mali",
                sov_a3: "MLI",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Mali",
                adm0_a3: "MLI",
                geou_dif: 0,
                geounit: "Mali",
                gu_a3: "MLI",
                su_dif: 0,
                subunit: "Mali",
                su_a3: "MLI",
                brk_diff: 0,
                name: "Mali",
                name_long: "Mali",
                brk_a3: "MLI",
                brk_name: "Mali",
                brk_group: null,
                abbrev: "Mali",
                postal: "ML",
                formal_en: "Republic of Mali",
                formal_fr: null,
                name_ciawf: "Mali",
                note_adm0: null,
                note_brk: null,
                name_sort: "Mali",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 4,
                mapcolor9: 1,
                mapcolor13: 7,
                pop_est: 19658031,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 17279,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "ML",
                iso_a2: "ML",
                iso_a2_eh: "ML",
                iso_a3: "MLI",
                iso_a3_eh: "MLI",
                iso_n3: "466",
                iso_n3_eh: "466",
                un_a3: "466",
                wb_a2: "ML",
                wb_a3: "MLI",
                woe_id: 23424891,
                woe_id_eh: 23424891,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MLI",
                adm0_diff: null,
                adm0_tlc: "MLI",
                adm0_a3_us: "MLI",
                adm0_a3_fr: "MLI",
                adm0_a3_ru: "MLI",
                adm0_a3_es: "MLI",
                adm0_a3_cn: "MLI",
                adm0_a3_tw: "MLI",
                adm0_a3_in: "MLI",
                adm0_a3_np: "MLI",
                adm0_a3_pk: "MLI",
                adm0_a3_de: "MLI",
                adm0_a3_gb: "MLI",
                adm0_a3_br: "MLI",
                adm0_a3_il: "MLI",
                adm0_a3_ps: "MLI",
                adm0_a3_sa: "MLI",
                adm0_a3_eg: "MLI",
                adm0_a3_ma: "MLI",
                adm0_a3_pt: "MLI",
                adm0_a3_ar: "MLI",
                adm0_a3_jp: "MLI",
                adm0_a3_ko: "MLI",
                adm0_a3_vn: "MLI",
                adm0_a3_tr: "MLI",
                adm0_a3_id: "MLI",
                adm0_a3_pl: "MLI",
                adm0_a3_gr: "MLI",
                adm0_a3_it: "MLI",
                adm0_a3_nl: "MLI",
                adm0_a3_se: "MLI",
                adm0_a3_bd: "MLI",
                adm0_a3_ua: "MLI",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 4,
                long_len: 4,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 7,
                label_x: -2.038455,
                label_y: 18.692713,
                ne_id: 1159321063,
                wikidataid: "Q912",
                name_ar: "مالي",
                name_bn: "মালি",
                name_de: "Mali",
                name_en: "Mali",
                name_es: "Malí",
                name_fa: "مالی",
                name_fr: "Mali",
                name_el: "Μάλι",
                name_he: "מאלי",
                name_hi: "माली",
                name_hu: "Mali",
                name_id: "Mali",
                name_it: "Mali",
                name_ja: "マリ共和国",
                name_ko: "말리",
                name_nl: "Mali",
                name_pl: "Mali",
                name_pt: "Mali",
                name_ru: "Мали",
                name_sv: "Mali",
                name_tr: "Mali",
                name_uk: "Малі",
                name_ur: "مالی",
                name_vi: "Mali",
                name_zh: "马里",
                name_zht: "馬利共和國",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MLI.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-11.51394283695059, 12.442987575729418],
                        [-11.467899135778524, 12.754518947800975],
                        [-11.55339779300543, 13.141213690641067],
                        [-11.927716030311615, 13.422075100147396],
                        [-12.12488745772126, 13.994727484589788],
                        [-12.170750291380301, 14.616834214735505],
                        [-11.834207526079467, 14.79909699142894],
                        [-11.666078253617854, 15.388208319556298],
                        [-11.349095017939504, 15.411256008358478],
                        [-10.650791388379417, 15.132745876521426],
                        [-10.086846482778213, 15.330485744686271],
                        [-9.700255092802706, 15.264107367407362],
                        [-9.55023840985939, 15.486496893775438],
                        [-5.537744309908447, 15.501689764869257],
                        [-5.315277268891933, 16.20185374599184],
                        [-5.488522508150439, 16.325102037007966],
                        [-5.971128709324248, 20.64083344164763],
                        [-6.453786586930335, 24.956590684503425],
                        [-4.923337368174231, 24.974574082941],
                        [-1.550054897457613, 22.792665920497384],
                        [1.823227573259032, 20.610809434486043],
                        [2.06099083823392, 20.142233384679486],
                        [2.683588494486429, 19.856230170160117],
                        [3.1466610042539, 19.693578599521445],
                        [3.158133172222705, 19.057364203360038],
                        [4.267419467800039, 19.155265204337],
                        [4.270209995143802, 16.852227484601215],
                        [3.723421665063483, 16.184283759012615],
                        [3.638258904646477, 15.568119818580456],
                        [2.749992709981484, 15.409524847876696],
                        [1.385528191746858, 15.323561102759172],
                        [1.015783318698482, 14.96818227788799],
                        [0.374892205414682, 14.92890818934613],
                        [-0.26625729003058, 14.924308986872148],
                        [-0.515854458000348, 15.116157741755728],
                        [-1.066363491205664, 14.973815009007765],
                        [-2.001035122068771, 14.559008287000893],
                        [-2.191824510090385, 14.246417548067356],
                        [-2.967694464520577, 13.79815033615151],
                        [-3.10370683431276, 13.541266791228594],
                        [-3.522802700199861, 13.337661647998615],
                        [-4.006390753587226, 13.472485459848116],
                        [-4.28040503581488, 13.228443508349741],
                        [-4.427166103523803, 12.542645575404295],
                        [-5.220941941743121, 11.713858954307227],
                        [-5.197842576508648, 11.37514577885014],
                        [-5.470564947929006, 10.951269842976048],
                        [-5.404341599946974, 10.370736802609146],
                        [-5.816926235365287, 10.222554633012194],
                        [-6.050452032892267, 10.096360785355444],
                        [-6.205222947606431, 10.524060777219134],
                        [-6.493965013037268, 10.411302801958271],
                        [-6.666460944027548, 10.430810655148449],
                        [-6.850506557635057, 10.138993841996239],
                        [-7.622759161804809, 10.147236232946796],
                        [-7.899589809592372, 10.297382106970828],
                        [-8.029943610048619, 10.206534939001713],
                        [-8.33537716310974, 10.494811916541934],
                        [-8.282357143578281, 10.792597357623846],
                        [-8.407310756860028, 10.909256903522762],
                        [-8.620321010767128, 10.810890814655183],
                        [-8.581305304386774, 11.136245632364805],
                        [-8.376304897484914, 11.393645941610629],
                        [-8.786099005559464, 11.812560939984706],
                        [-8.90526485842453, 12.088358059126437],
                        [-9.127473517279583, 12.308060411015333],
                        [-9.327616339546012, 12.334286200403454],
                        [-9.567911749703214, 12.194243068892476],
                        [-9.890992804392013, 12.060478623904972],
                        [-10.165213792348837, 11.844083563682744],
                        [-10.593223842806282, 11.92397532800598],
                        [-10.870829637078215, 12.17788747807211],
                        [-11.03655595543826, 12.211244615116515],
                        [-11.297573614944511, 12.077971096235771],
                        [-11.456168585648271, 12.076834214725338],
                        [-11.51394283695059, 12.442987575729418],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Senegal",
                sov_a3: "SEN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Senegal",
                adm0_a3: "SEN",
                geou_dif: 0,
                geounit: "Senegal",
                gu_a3: "SEN",
                su_dif: 0,
                subunit: "Senegal",
                su_a3: "SEN",
                brk_diff: 0,
                name: "Senegal",
                name_long: "Senegal",
                brk_a3: "SEN",
                brk_name: "Senegal",
                brk_group: null,
                abbrev: "Sen.",
                postal: "SN",
                formal_en: "Republic of Senegal",
                formal_fr: null,
                name_ciawf: "Senegal",
                note_adm0: null,
                note_brk: null,
                name_sort: "Senegal",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 6,
                mapcolor9: 5,
                mapcolor13: 5,
                pop_est: 16296364,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 23578,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "4. Lower middle income",
                fips_10: "SG",
                iso_a2: "SN",
                iso_a2_eh: "SN",
                iso_a3: "SEN",
                iso_a3_eh: "SEN",
                iso_n3: "686",
                iso_n3_eh: "686",
                un_a3: "686",
                wb_a2: "SN",
                wb_a3: "SEN",
                woe_id: 23424943,
                woe_id_eh: 23424943,
                woe_note: "Exact WOE match as country",
                adm0_iso: "SEN",
                adm0_diff: null,
                adm0_tlc: "SEN",
                adm0_a3_us: "SEN",
                adm0_a3_fr: "SEN",
                adm0_a3_ru: "SEN",
                adm0_a3_es: "SEN",
                adm0_a3_cn: "SEN",
                adm0_a3_tw: "SEN",
                adm0_a3_in: "SEN",
                adm0_a3_np: "SEN",
                adm0_a3_pk: "SEN",
                adm0_a3_de: "SEN",
                adm0_a3_gb: "SEN",
                adm0_a3_br: "SEN",
                adm0_a3_il: "SEN",
                adm0_a3_ps: "SEN",
                adm0_a3_sa: "SEN",
                adm0_a3_eg: "SEN",
                adm0_a3_ma: "SEN",
                adm0_a3_pt: "SEN",
                adm0_a3_ar: "SEN",
                adm0_a3_jp: "SEN",
                adm0_a3_ko: "SEN",
                adm0_a3_vn: "SEN",
                adm0_a3_tr: "SEN",
                adm0_a3_id: "SEN",
                adm0_a3_pl: "SEN",
                adm0_a3_gr: "SEN",
                adm0_a3_it: "SEN",
                adm0_a3_nl: "SEN",
                adm0_a3_se: "SEN",
                adm0_a3_bd: "SEN",
                adm0_a3_ua: "SEN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.7,
                max_label: 8,
                label_x: -14.778586,
                label_y: 15.138125,
                ne_id: 1159321243,
                wikidataid: "Q1041",
                name_ar: "السنغال",
                name_bn: "সেনেগাল",
                name_de: "Senegal",
                name_en: "Senegal",
                name_es: "Senegal",
                name_fa: "سنگال",
                name_fr: "Sénégal",
                name_el: "Σενεγάλη",
                name_he: "סנגל",
                name_hi: "सेनेगल",
                name_hu: "Szenegál",
                name_id: "Senegal",
                name_it: "Senegal",
                name_ja: "セネガル",
                name_ko: "세네갈",
                name_nl: "Senegal",
                name_pl: "Senegal",
                name_pt: "Senegal",
                name_ru: "Сенегал",
                name_sv: "Senegal",
                name_tr: "Senegal",
                name_uk: "Сенегал",
                name_ur: "سینیگال",
                name_vi: "Sénégal",
                name_zh: "塞内加尔",
                name_zht: "塞內加爾",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SEN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-16.71372880702347, 13.594958604379855],
                        [-17.126106736712615, 14.373515733289224],
                        [-17.62504269049066, 14.729540513564073],
                        [-17.18517289882223, 14.919477240452862],
                        [-16.700706346085923, 15.621527411354108],
                        [-16.463098110407884, 16.13503611903846],
                        [-16.12069007004193, 16.455662543193384],
                        [-15.623666144258692, 16.369337063049812],
                        [-15.135737270558819, 16.587282416240782],
                        [-14.577347581428981, 16.59826365810281],
                        [-14.099521450242179, 16.304302273010492],
                        [-13.435737677453062, 16.03938304286619],
                        [-12.830658331747516, 15.303691514542948],
                        [-12.170750291380301, 14.616834214735505],
                        [-12.12488745772126, 13.994727484589788],
                        [-11.927716030311615, 13.422075100147396],
                        [-11.55339779300543, 13.141213690641067],
                        [-11.467899135778524, 12.754518947800975],
                        [-11.51394283695059, 12.442987575729418],
                        [-11.658300950557932, 12.386582749882836],
                        [-12.203564825885634, 12.465647691289405],
                        [-12.27859900557344, 12.354440008997287],
                        [-12.499050665730564, 12.332089952031057],
                        [-13.217818162478238, 12.575873521367967],
                        [-13.700476040084325, 12.586182969610194],
                        [-15.54847693527401, 12.628170070847347],
                        [-15.816574266004254, 12.515567124883347],
                        [-16.147716844130585, 12.547761542201187],
                        [-16.677451951554573, 12.384851589401052],
                        [-16.841524624081273, 13.15139394780256],
                        [-15.931295945692211, 13.130284125211332],
                        [-15.691000535534995, 13.270353094938457],
                        [-15.511812506562933, 13.278569647672867],
                        [-15.141163295949468, 13.509511623585238],
                        [-14.712197231494628, 13.298206691943777],
                        [-14.277701788784555, 13.280585028532244],
                        [-13.844963344772408, 13.505041612192002],
                        [-14.046992356817482, 13.79406789800045],
                        [-14.376713833055788, 13.625680243377374],
                        [-14.687030808968487, 13.630356960499784],
                        [-15.08173539881382, 13.876491807505985],
                        [-15.39877031092446, 13.86036876063092],
                        [-15.624596320039942, 13.62358734786956],
                        [-16.71372880702347, 13.594958604379855],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "Nigeria",
                sov_a3: "NGA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Nigeria",
                adm0_a3: "NGA",
                geou_dif: 0,
                geounit: "Nigeria",
                gu_a3: "NGA",
                su_dif: 0,
                subunit: "Nigeria",
                su_a3: "NGA",
                brk_diff: 0,
                name: "Nigeria",
                name_long: "Nigeria",
                brk_a3: "NGA",
                brk_name: "Nigeria",
                brk_group: null,
                abbrev: "Nigeria",
                postal: "NG",
                formal_en: "Federal Republic of Nigeria",
                formal_fr: null,
                name_ciawf: "Nigeria",
                note_adm0: null,
                note_brk: null,
                name_sort: "Nigeria",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 2,
                mapcolor9: 5,
                mapcolor13: 2,
                pop_est: 200963599,
                pop_rank: 17,
                pop_year: 2019,
                gdp_md: 448120,
                gdp_year: 2019,
                economy: "5. Emerging region: G20",
                income_grp: "4. Lower middle income",
                fips_10: "NI",
                iso_a2: "NG",
                iso_a2_eh: "NG",
                iso_a3: "NGA",
                iso_a3_eh: "NGA",
                iso_n3: "566",
                iso_n3_eh: "566",
                un_a3: "566",
                wb_a2: "NG",
                wb_a3: "NGA",
                woe_id: 23424908,
                woe_id_eh: 23424908,
                woe_note: "Exact WOE match as country",
                adm0_iso: "NGA",
                adm0_diff: null,
                adm0_tlc: "NGA",
                adm0_a3_us: "NGA",
                adm0_a3_fr: "NGA",
                adm0_a3_ru: "NGA",
                adm0_a3_es: "NGA",
                adm0_a3_cn: "NGA",
                adm0_a3_tw: "NGA",
                adm0_a3_in: "NGA",
                adm0_a3_np: "NGA",
                adm0_a3_pk: "NGA",
                adm0_a3_de: "NGA",
                adm0_a3_gb: "NGA",
                adm0_a3_br: "NGA",
                adm0_a3_il: "NGA",
                adm0_a3_ps: "NGA",
                adm0_a3_sa: "NGA",
                adm0_a3_eg: "NGA",
                adm0_a3_ma: "NGA",
                adm0_a3_pt: "NGA",
                adm0_a3_ar: "NGA",
                adm0_a3_jp: "NGA",
                adm0_a3_ko: "NGA",
                adm0_a3_vn: "NGA",
                adm0_a3_tr: "NGA",
                adm0_a3_id: "NGA",
                adm0_a3_pl: "NGA",
                adm0_a3_gr: "NGA",
                adm0_a3_it: "NGA",
                adm0_a3_nl: "NGA",
                adm0_a3_se: "NGA",
                adm0_a3_bd: "NGA",
                adm0_a3_ua: "NGA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 7,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 1.7,
                max_label: 6.7,
                label_x: 7.50322,
                label_y: 9.439799,
                ne_id: 1159321089,
                wikidataid: "Q1033",
                name_ar: "نيجيريا",
                name_bn: "নাইজেরিয়া",
                name_de: "Nigeria",
                name_en: "Nigeria",
                name_es: "Nigeria",
                name_fa: "نیجریه",
                name_fr: "Nigeria",
                name_el: "Νιγηρία",
                name_he: "ניגריה",
                name_hi: "नाईजीरिया",
                name_hu: "Nigéria",
                name_id: "Nigeria",
                name_it: "Nigeria",
                name_ja: "ナイジェリア",
                name_ko: "나이지리아",
                name_nl: "Nigeria",
                name_pl: "Nigeria",
                name_pt: "Nigéria",
                name_ru: "Нигерия",
                name_sv: "Nigeria",
                name_tr: "Nijerya",
                name_uk: "Нігерія",
                name_ur: "نائجیریا",
                name_vi: "Nigeria",
                name_zh: "尼日利亚",
                name_zht: "奈及利亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "NGA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [2.691701694356254, 6.258817246928629],
                        [2.74906253420022, 7.870734361192888],
                        [2.723792758809509, 8.50684540448971],
                        [2.912308383810256, 9.137607937044322],
                        [3.220351596702101, 9.444152533399702],
                        [3.705438266625919, 10.063210354040208],
                        [3.600070021182802, 10.332186184119408],
                        [3.797112257511714, 10.734745591673105],
                        [3.572216424177469, 11.32793935795152],
                        [3.611180454125559, 11.660167141155968],
                        [3.680633579125811, 12.552903347214226],
                        [3.967282749048849, 12.956108710171575],
                        [4.107945997747322, 13.531215725147831],
                        [4.368343540066007, 13.747481594289411],
                        [5.443058302440136, 13.865923977102227],
                        [6.445426059605637, 13.492768459522678],
                        [6.820441928747754, 13.115091254117518],
                        [7.330746697630019, 13.0980380314612],
                        [7.804671258178786, 13.343526923063747],
                        [9.014933302454438, 12.826659247280418],
                        [9.524928012742947, 12.851102199754479],
                        [10.114814487354693, 13.27725189864941],
                        [10.701031935273706, 13.246917832894084],
                        [10.989593133191534, 13.387322699431195],
                        [11.527803175511394, 13.328980007373588],
                        [12.302071160540523, 13.037189032437524],
                        [13.083987257548813, 13.596147162322495],
                        [13.318701613018561, 13.556356309457826],
                        [13.995352817448293, 12.461565253138303],
                        [14.181336297266794, 12.483656927943116],
                        [14.577177768622533, 12.085360826053503],
                        [14.468192172918975, 11.904751695193411],
                        [14.415378859116684, 11.572368882692075],
                        [13.572949659894562, 10.798565985553566],
                        [13.308676385153918, 10.160362046748928],
                        [13.167599724997103, 9.640626328973411],
                        [12.955467970438974, 9.417771714714704],
                        [12.753671502339216, 8.717762762888995],
                        [12.218872104550599, 8.305824082874324],
                        [12.063946160539558, 7.799808457872302],
                        [11.839308709366803, 7.397042344589437],
                        [11.74577436691851, 6.981382961449754],
                        [11.058787876030351, 6.644426784690594],
                        [10.497375115611419, 7.055357774275564],
                        [10.118276808318257, 7.03876963950988],
                        [9.522705926154401, 6.453482367372117],
                        [9.233162876023044, 6.444490668153335],
                        [8.757532993208628, 5.479665839047911],
                        [8.500287713259695, 4.771982937026849],
                        [7.462108188515941, 4.412108262546241],
                        [7.082596469764439, 4.464689032403228],
                        [6.6980721370806, 4.240594183769517],
                        [5.898172641634687, 4.262453314628985],
                        [5.362804803090881, 4.887970689305959],
                        [5.033574252959369, 5.611802476418234],
                        [4.325607130560684, 6.270651149923467],
                        [3.574180128604553, 6.258300482605719],
                        [2.691701694356254, 6.258817246928629],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 5,
                sovereignt: "Benin",
                sov_a3: "BEN",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Benin",
                adm0_a3: "BEN",
                geou_dif: 0,
                geounit: "Benin",
                gu_a3: "BEN",
                su_dif: 0,
                subunit: "Benin",
                su_a3: "BEN",
                brk_diff: 0,
                name: "Benin",
                name_long: "Benin",
                brk_a3: "BEN",
                brk_name: "Benin",
                brk_group: null,
                abbrev: "Benin",
                postal: "BJ",
                formal_en: "Republic of Benin",
                formal_fr: null,
                name_ciawf: "Benin",
                note_adm0: null,
                note_brk: null,
                name_sort: "Benin",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 2,
                mapcolor9: 2,
                mapcolor13: 12,
                pop_est: 11801151,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 14390,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "BN",
                iso_a2: "BJ",
                iso_a2_eh: "BJ",
                iso_a3: "BEN",
                iso_a3_eh: "BEN",
                iso_n3: "204",
                iso_n3_eh: "204",
                un_a3: "204",
                wb_a2: "BJ",
                wb_a3: "BEN",
                woe_id: 23424764,
                woe_id_eh: 23424764,
                woe_note: "Exact WOE match as country",
                adm0_iso: "BEN",
                adm0_diff: null,
                adm0_tlc: "BEN",
                adm0_a3_us: "BEN",
                adm0_a3_fr: "BEN",
                adm0_a3_ru: "BEN",
                adm0_a3_es: "BEN",
                adm0_a3_cn: "BEN",
                adm0_a3_tw: "BEN",
                adm0_a3_in: "BEN",
                adm0_a3_np: "BEN",
                adm0_a3_pk: "BEN",
                adm0_a3_de: "BEN",
                adm0_a3_gb: "BEN",
                adm0_a3_br: "BEN",
                adm0_a3_il: "BEN",
                adm0_a3_ps: "BEN",
                adm0_a3_sa: "BEN",
                adm0_a3_eg: "BEN",
                adm0_a3_ma: "BEN",
                adm0_a3_pt: "BEN",
                adm0_a3_ar: "BEN",
                adm0_a3_jp: "BEN",
                adm0_a3_ko: "BEN",
                adm0_a3_vn: "BEN",
                adm0_a3_tr: "BEN",
                adm0_a3_id: "BEN",
                adm0_a3_pl: "BEN",
                adm0_a3_gr: "BEN",
                adm0_a3_it: "BEN",
                adm0_a3_nl: "BEN",
                adm0_a3_se: "BEN",
                adm0_a3_bd: "BEN",
                adm0_a3_ua: "BEN",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 2.352018,
                label_y: 10.324775,
                ne_id: 1159320399,
                wikidataid: "Q962",
                name_ar: "بنين",
                name_bn: "বেনিন",
                name_de: "Benin",
                name_en: "Benin",
                name_es: "Benín",
                name_fa: "بنین",
                name_fr: "Bénin",
                name_el: "Μπενίν",
                name_he: "בנין",
                name_hi: "बेनिन",
                name_hu: "Benin",
                name_id: "Benin",
                name_it: "Benin",
                name_ja: "ベナン",
                name_ko: "베냉",
                name_nl: "Benin",
                name_pl: "Benin",
                name_pt: "Benim",
                name_ru: "Бенин",
                name_sv: "Benin",
                name_tr: "Benin",
                name_uk: "Бенін",
                name_ur: "بینن",
                name_vi: "Bénin",
                name_zh: "贝宁",
                name_zht: "貝南",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "BEN.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [2.691701694356254, 6.258817246928629],
                        [1.865240512712319, 6.142157701029731],
                        [1.618950636409238, 6.832038072126238],
                        [1.664477573258381, 9.12859039960938],
                        [1.46304284018467, 9.334624335157088],
                        [1.425060662450136, 9.825395412633],
                        [1.077795037448738, 10.175606594275024],
                        [0.772335646171484, 10.470808213742359],
                        [0.899563022474069, 10.99733938236426],
                        [1.243469679376489, 11.110510769083461],
                        [1.447178175471066, 11.547719224488858],
                        [1.935985548519881, 11.641150214072553],
                        [2.154473504249921, 11.940150051313339],
                        [2.49016360841793, 12.233052069543675],
                        [2.848643019226586, 12.23563589115821],
                        [3.611180454125559, 11.660167141155968],
                        [3.572216424177469, 11.32793935795152],
                        [3.797112257511714, 10.734745591673105],
                        [3.600070021182802, 10.332186184119408],
                        [3.705438266625919, 10.063210354040208],
                        [3.220351596702101, 9.444152533399702],
                        [2.912308383810256, 9.137607937044322],
                        [2.723792758809509, 8.50684540448971],
                        [2.74906253420022, 7.870734361192888],
                        [2.691701694356254, 6.258817246928629],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Angola",
                sov_a3: "AGO",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Angola",
                adm0_a3: "AGO",
                geou_dif: 0,
                geounit: "Angola",
                gu_a3: "AGO",
                su_dif: 0,
                subunit: "Angola",
                su_a3: "AGO",
                brk_diff: 0,
                name: "Angola",
                name_long: "Angola",
                brk_a3: "AGO",
                brk_name: "Angola",
                brk_group: null,
                abbrev: "Ang.",
                postal: "AO",
                formal_en: "People's Republic of Angola",
                formal_fr: null,
                name_ciawf: "Angola",
                note_adm0: null,
                note_brk: null,
                name_sort: "Angola",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 2,
                mapcolor9: 6,
                mapcolor13: 1,
                pop_est: 31825295,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 88815,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "3. Upper middle income",
                fips_10: "AO",
                iso_a2: "AO",
                iso_a2_eh: "AO",
                iso_a3: "AGO",
                iso_a3_eh: "AGO",
                iso_n3: "024",
                iso_n3_eh: "024",
                un_a3: "024",
                wb_a2: "AO",
                wb_a3: "AGO",
                woe_id: 23424745,
                woe_id_eh: 23424745,
                woe_note: "Exact WOE match as country",
                adm0_iso: "AGO",
                adm0_diff: null,
                adm0_tlc: "AGO",
                adm0_a3_us: "AGO",
                adm0_a3_fr: "AGO",
                adm0_a3_ru: "AGO",
                adm0_a3_es: "AGO",
                adm0_a3_cn: "AGO",
                adm0_a3_tw: "AGO",
                adm0_a3_in: "AGO",
                adm0_a3_np: "AGO",
                adm0_a3_pk: "AGO",
                adm0_a3_de: "AGO",
                adm0_a3_gb: "AGO",
                adm0_a3_br: "AGO",
                adm0_a3_il: "AGO",
                adm0_a3_ps: "AGO",
                adm0_a3_sa: "AGO",
                adm0_a3_eg: "AGO",
                adm0_a3_ma: "AGO",
                adm0_a3_pt: "AGO",
                adm0_a3_ar: "AGO",
                adm0_a3_jp: "AGO",
                adm0_a3_ko: "AGO",
                adm0_a3_vn: "AGO",
                adm0_a3_tr: "AGO",
                adm0_a3_id: "AGO",
                adm0_a3_pl: "AGO",
                adm0_a3_gr: "AGO",
                adm0_a3_it: "AGO",
                adm0_a3_nl: "AGO",
                adm0_a3_se: "AGO",
                adm0_a3_bd: "AGO",
                adm0_a3_ua: "AGO",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 7,
                label_x: 17.984249,
                label_y: -12.182762,
                ne_id: 1159320323,
                wikidataid: "Q916",
                name_ar: "أنغولا",
                name_bn: "অ্যাঙ্গোলা",
                name_de: "Angola",
                name_en: "Angola",
                name_es: "Angola",
                name_fa: "آنگولا",
                name_fr: "Angola",
                name_el: "Ανγκόλα",
                name_he: "אנגולה",
                name_hi: "अंगोला",
                name_hu: "Angola",
                name_id: "Angola",
                name_it: "Angola",
                name_ja: "アンゴラ",
                name_ko: "앙골라",
                name_nl: "Angola",
                name_pl: "Angola",
                name_pt: "Angola",
                name_ru: "Ангола",
                name_sv: "Angola",
                name_tr: "Angola",
                name_uk: "Ангола",
                name_ur: "انگولا",
                name_vi: "Angola",
                name_zh: "安哥拉",
                name_zht: "安哥拉",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "AGO.geojson",
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [12.995517205465177, -4.781103203961884],
                            [12.63161176926579, -4.991271254092936],
                            [12.468004184629736, -5.248361504745005],
                            [12.436688266660868, -5.684303887559246],
                            [12.182336866920252, -5.789930515163839],
                            [11.91496300624209, -5.037986748884791],
                            [12.318607618873926, -4.606230157086188],
                            [12.620759718484493, -4.438023369976136],
                            [12.995517205465177, -4.781103203961884],
                        ],
                    ],
                    [
                        [
                            [12.32243167486351, -6.10009246177966],
                            [12.735171339578699, -5.965682061388499],
                            [13.024869419006961, -5.984388929878158],
                            [13.375597364971895, -5.864241224799549],
                            [16.326528354567046, -5.877470391466268],
                            [16.573179965896145, -6.622644545115087],
                            [16.8601908708452, -7.222297865429987],
                            [17.08999596524717, -7.545688978712526],
                            [17.472970004962235, -8.0685511206417],
                            [18.13422163256905, -7.987677504104923],
                            [18.464175652752687, -7.847014255406443],
                            [19.01675174324967, -7.988245944860132],
                            [19.16661339689611, -7.738183688999754],
                            [19.41750247567316, -7.155428562044299],
                            [20.037723016040218, -7.116361179231646],
                            [20.091621534920648, -6.943090101756994],
                            [20.6018229509383, -6.939317722199682],
                            [20.5147481625265, -7.299605808138629],
                            [21.7281107927397, -7.290872491081302],
                            [21.74645592620331, -7.920084730667149],
                            [21.949130893652043, -8.305900974158277],
                            [21.8018013851879, -8.90870655684298],
                            [21.875181919042348, -9.523707777548566],
                            [22.208753289486395, -9.894796237836509],
                            [22.155268182064308, -11.084801120653772],
                            [22.402798292742375, -10.993075453335692],
                            [22.83734541188474, -11.01762175867433],
                            [23.45679080576744, -10.867863457892483],
                            [23.912215203555718, -10.926826267137514],
                            [24.01789350759259, -11.23729827234709],
                            [23.904153680118185, -11.722281589406322],
                            [24.079905226342845, -12.191296888887365],
                            [23.930922072045377, -12.565847670138856],
                            [24.016136508894675, -12.911046237848574],
                            [21.933886346125917, -12.898437188369359],
                            [21.887842644953874, -16.08031015387688],
                            [22.56247846852426, -16.898451429921813],
                            [23.215048455506064, -17.523116143465984],
                            [21.377176141045567, -17.930636488519696],
                            [18.956186964603603, -17.789094740472258],
                            [18.263309360434164, -17.309950860262006],
                            [14.209706658595024, -17.35310068122572],
                            [14.05850141770901, -17.423380629142663],
                            [13.462362094789967, -16.971211846588773],
                            [12.814081251688407, -16.94134286872407],
                            [12.215461460019355, -17.111668389558083],
                            [11.734198846085121, -17.301889336824473],
                            [11.64009606288161, -16.67314218512925],
                            [11.778537224991537, -15.793816013250735],
                            [12.12358076340439, -14.878316338767904],
                            [12.175618930722294, -14.449143568583892],
                            [12.50009524908299, -13.54769988368445],
                            [12.738478631245385, -13.137905775609902],
                            [13.312913852601866, -12.483630466362492],
                            [13.6337211442698, -12.03864470789717],
                            [13.738727654686897, -11.297863050993165],
                            [13.686379428775238, -10.73107594161589],
                            [13.387327915102162, -10.373578383020714],
                            [13.120987583069846, -9.766897067914122],
                            [12.875369500386569, -9.166933689005468],
                            [12.92906131353783, -8.959091078327553],
                            [13.236432732809874, -8.562629489784307],
                            [12.93304039882429, -7.596538588087733],
                            [12.728298374083892, -6.927122084178805],
                            [12.227347039446471, -6.294447523629394],
                            [12.32243167486351, -6.10009246177966],
                        ],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Algeria",
                sov_a3: "DZA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Algeria",
                adm0_a3: "DZA",
                geou_dif: 0,
                geounit: "Algeria",
                gu_a3: "DZA",
                su_dif: 0,
                subunit: "Algeria",
                su_a3: "DZA",
                brk_diff: 0,
                name: "Algeria",
                name_long: "Algeria",
                brk_a3: "DZA",
                brk_name: "Algeria",
                brk_group: null,
                abbrev: "Alg.",
                postal: "DZ",
                formal_en: "People's Democratic Republic of Algeria",
                formal_fr: null,
                name_ciawf: "Algeria",
                note_adm0: null,
                note_brk: null,
                name_sort: "Algeria",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 1,
                mapcolor9: 6,
                mapcolor13: 3,
                pop_est: 43053054,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 171091,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "AG",
                iso_a2: "DZ",
                iso_a2_eh: "DZ",
                iso_a3: "DZA",
                iso_a3_eh: "DZA",
                iso_n3: "012",
                iso_n3_eh: "012",
                un_a3: "012",
                wb_a2: "DZ",
                wb_a3: "DZA",
                woe_id: 23424740,
                woe_id_eh: 23424740,
                woe_note: "Exact WOE match as country",
                adm0_iso: "DZA",
                adm0_diff: null,
                adm0_tlc: "DZA",
                adm0_a3_us: "DZA",
                adm0_a3_fr: "DZA",
                adm0_a3_ru: "DZA",
                adm0_a3_es: "DZA",
                adm0_a3_cn: "DZA",
                adm0_a3_tw: "DZA",
                adm0_a3_in: "DZA",
                adm0_a3_np: "DZA",
                adm0_a3_pk: "DZA",
                adm0_a3_de: "DZA",
                adm0_a3_gb: "DZA",
                adm0_a3_br: "DZA",
                adm0_a3_il: "DZA",
                adm0_a3_ps: "DZA",
                adm0_a3_sa: "DZA",
                adm0_a3_eg: "DZA",
                adm0_a3_ma: "DZA",
                adm0_a3_pt: "DZA",
                adm0_a3_ar: "DZA",
                adm0_a3_jp: "DZA",
                adm0_a3_ko: "DZA",
                adm0_a3_vn: "DZA",
                adm0_a3_tr: "DZA",
                adm0_a3_id: "DZA",
                adm0_a3_pl: "DZA",
                adm0_a3_gr: "DZA",
                adm0_a3_it: "DZA",
                adm0_a3_nl: "DZA",
                adm0_a3_se: "DZA",
                adm0_a3_bd: "DZA",
                adm0_a3_ua: "DZA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.5,
                max_label: 7,
                label_x: 2.808241,
                label_y: 27.397406,
                ne_id: 1159320565,
                wikidataid: "Q262",
                name_ar: "الجزائر",
                name_bn: "আলজেরিয়া",
                name_de: "Algerien",
                name_en: "Algeria",
                name_es: "Argelia",
                name_fa: "الجزایر",
                name_fr: "Algérie",
                name_el: "Αλγερία",
                name_he: "אלג'יריה",
                name_hi: "अल्जीरिया",
                name_hu: "Algéria",
                name_id: "Aljazair",
                name_it: "Algeria",
                name_ja: "アルジェリア",
                name_ko: "알제리",
                name_nl: "Algerije",
                name_pl: "Algieria",
                name_pt: "Argélia",
                name_ru: "Алжир",
                name_sv: "Algeriet",
                name_tr: "Cezayir",
                name_uk: "Алжир",
                name_ur: "الجزائر",
                name_vi: "Algérie",
                name_zh: "阿尔及利亚",
                name_zht: "阿爾及利亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "DZA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-8.684399786809053, 27.395744126896005],
                        [-8.665124477564191, 27.589479071558227],
                        [-8.665589565454809, 27.656425889592356],
                        [-8.674116176782974, 28.84128896739658],
                        [-7.059227667661958, 29.5792284205246],
                        [-6.060632290053774, 29.731699734001694],
                        [-5.242129278982787, 30.00044302013559],
                        [-4.859646165374471, 30.501187649043846],
                        [-3.690441046554724, 30.896951605751156],
                        [-3.647497931320146, 31.637294012980675],
                        [-3.068980271812648, 31.724497992473218],
                        [-2.616604783529567, 32.09434621838619],
                        [-1.30789913573787, 32.2628889023061],
                        [-1.124551153966308, 32.65152151135713],
                        [-1.388049282222596, 32.86401500094138],
                        [-1.733454555661467, 33.91971283623212],
                        [-1.792985805661715, 34.527918606091305],
                        [-2.169913702798624, 35.16839630791668],
                        [-1.208602871089056, 35.7148487411871],
                        [-0.127454392894606, 35.888662421200806],
                        [0.503876580415209, 36.30127289483528],
                        [1.466918572606545, 36.605647081034405],
                        [3.161698846050825, 36.78390493422522],
                        [4.81575809084913, 36.86503693292346],
                        [5.320120070017794, 36.71651886651662],
                        [6.261819695672613, 37.11065501560674],
                        [7.330384962603971, 37.11838064223437],
                        [7.737078484741005, 36.885707505840216],
                        [8.420964389691676, 36.94642731378316],
                        [8.217824334352315, 36.433176988260286],
                        [8.376367628623768, 35.479876003555944],
                        [8.140981479534304, 34.65514598239379],
                        [7.524481642292244, 34.09737641045146],
                        [7.612641635782182, 33.34411489514896],
                        [8.430472853233368, 32.74833730725595],
                        [8.439102817426118, 32.50628489840082],
                        [9.05560265466815, 32.10269196220129],
                        [9.482139926805274, 30.307556057246188],
                        [9.805634392952356, 29.424638373323376],
                        [9.859997999723447, 28.959989732371014],
                        [9.683884718472768, 28.1441738957792],
                        [9.756128370816782, 27.688258571884205],
                        [9.629056023811074, 27.14095347748092],
                        [9.716285841519664, 26.512206325785655],
                        [9.319410841518163, 26.094324856057455],
                        [9.910692579801776, 25.365454616796796],
                        [9.94826134607797, 24.936953640232517],
                        [10.303846876678362, 24.379313259370917],
                        [10.771363559622927, 24.56253205006175],
                        [11.560669386449005, 24.097909247325518],
                        [11.999505649471613, 23.47166840259645],
                        [8.572893100629784, 21.565660712159143],
                        [5.677565952180686, 19.601206976799716],
                        [4.267419467800039, 19.155265204337],
                        [3.158133172222705, 19.057364203360038],
                        [3.1466610042539, 19.693578599521445],
                        [2.683588494486429, 19.856230170160117],
                        [2.06099083823392, 20.142233384679486],
                        [1.823227573259032, 20.610809434486043],
                        [-1.550054897457613, 22.792665920497384],
                        [-4.923337368174231, 24.974574082941],
                        [-8.684399786809053, 27.395744126896005],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Botswana",
                sov_a3: "BWA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Botswana",
                adm0_a3: "BWA",
                geou_dif: 0,
                geounit: "Botswana",
                gu_a3: "BWA",
                su_dif: 0,
                subunit: "Botswana",
                su_a3: "BWA",
                brk_diff: 0,
                name: "Botswana",
                name_long: "Botswana",
                brk_a3: "BWA",
                brk_name: "Botswana",
                brk_group: null,
                abbrev: "Bwa.",
                postal: "BW",
                formal_en: "Republic of Botswana",
                formal_fr: null,
                name_ciawf: "Botswana",
                note_adm0: null,
                note_brk: null,
                name_sort: "Botswana",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 5,
                mapcolor9: 7,
                mapcolor13: 3,
                pop_est: 2303697,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 18340,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "BC",
                iso_a2: "BW",
                iso_a2_eh: "BW",
                iso_a3: "BWA",
                iso_a3_eh: "BWA",
                iso_n3: "072",
                iso_n3_eh: "072",
                un_a3: "072",
                wb_a2: "BW",
                wb_a3: "BWA",
                woe_id: 23424755,
                woe_id_eh: 23424755,
                woe_note: "Exact WOE match as country",
                adm0_iso: "BWA",
                adm0_diff: null,
                adm0_tlc: "BWA",
                adm0_a3_us: "BWA",
                adm0_a3_fr: "BWA",
                adm0_a3_ru: "BWA",
                adm0_a3_es: "BWA",
                adm0_a3_cn: "BWA",
                adm0_a3_tw: "BWA",
                adm0_a3_in: "BWA",
                adm0_a3_np: "BWA",
                adm0_a3_pk: "BWA",
                adm0_a3_de: "BWA",
                adm0_a3_gb: "BWA",
                adm0_a3_br: "BWA",
                adm0_a3_il: "BWA",
                adm0_a3_ps: "BWA",
                adm0_a3_sa: "BWA",
                adm0_a3_eg: "BWA",
                adm0_a3_ma: "BWA",
                adm0_a3_pt: "BWA",
                adm0_a3_ar: "BWA",
                adm0_a3_jp: "BWA",
                adm0_a3_ko: "BWA",
                adm0_a3_vn: "BWA",
                adm0_a3_tr: "BWA",
                adm0_a3_id: "BWA",
                adm0_a3_pl: "BWA",
                adm0_a3_gr: "BWA",
                adm0_a3_it: "BWA",
                adm0_a3_nl: "BWA",
                adm0_a3_se: "BWA",
                adm0_a3_bd: "BWA",
                adm0_a3_ua: "BWA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Southern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 24.179216,
                label_y: -22.102634,
                ne_id: 1159320461,
                wikidataid: "Q963",
                name_ar: "بوتسوانا",
                name_bn: "বতসোয়ানা",
                name_de: "Botswana",
                name_en: "Botswana",
                name_es: "Botsuana",
                name_fa: "بوتسوانا",
                name_fr: "Botswana",
                name_el: "Μποτσουάνα",
                name_he: "בוטסואנה",
                name_hi: "बोत्सवाना",
                name_hu: "Botswana",
                name_id: "Botswana",
                name_it: "Botswana",
                name_ja: "ボツワナ",
                name_ko: "보츠와나",
                name_nl: "Botswana",
                name_pl: "Botswana",
                name_pt: "Botsuana",
                name_ru: "Ботсвана",
                name_sv: "Botswana",
                name_tr: "Botsvana",
                name_uk: "Ботсвана",
                name_ur: "بوٹسوانا",
                name_vi: "Botswana",
                name_zh: "博茨瓦纳",
                name_zht: "波札那",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "BWA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [29.43218834810904, -22.091312758067588],
                        [28.01723595552525, -22.82775359465908],
                        [27.119409620886245, -23.574323011979775],
                        [26.786406691197413, -24.240690606383485],
                        [26.4857532081233, -24.616326592713104],
                        [25.94165205252216, -24.69637338633322],
                        [25.76584882986521, -25.17484547292368],
                        [25.66466637543772, -25.486816094669713],
                        [25.025170525825786, -25.7196700985769],
                        [24.211266717228796, -25.670215752873574],
                        [23.73356977712271, -25.390129489851617],
                        [23.312096795350186, -25.26868987396572],
                        [22.8242712745149, -25.50045867279477],
                        [22.57953169118059, -25.979447523708146],
                        [22.105968865657868, -26.280256036079138],
                        [21.605896030369394, -26.726533705351756],
                        [20.88960900237174, -26.828542982695915],
                        [20.66647016773544, -26.477453301704923],
                        [20.75860924651184, -25.86813648855145],
                        [20.16572553882719, -24.91796192800077],
                        [19.895767856534434, -24.76779021576059],
                        [19.89545779794068, -21.84915699634787],
                        [20.88113406747587, -21.814327080983148],
                        [20.910641310314535, -18.252218926672022],
                        [21.655040317478978, -18.219146010005225],
                        [23.1968583513393, -17.869038181227786],
                        [23.579005568137717, -18.28126108162006],
                        [24.217364536239213, -17.88934701911849],
                        [24.520705193792537, -17.887124932529936],
                        [25.08444339366457, -17.661815687737374],
                        [25.264225701608012, -17.736539808831417],
                        [25.649163445750162, -18.53602589281899],
                        [25.85039147309473, -18.714412937090536],
                        [26.164790887158485, -19.29308562589494],
                        [27.296504754350508, -20.391519870691],
                        [27.724747348753255, -20.49905852629039],
                        [27.72722781750326, -20.851801853114715],
                        [28.021370070108617, -21.485975030200585],
                        [28.794656202924216, -21.63945403410745],
                        [29.43218834810904, -22.091312758067588],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Zimbabwe",
                sov_a3: "ZWE",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Zimbabwe",
                adm0_a3: "ZWE",
                geou_dif: 0,
                geounit: "Zimbabwe",
                gu_a3: "ZWE",
                su_dif: 0,
                subunit: "Zimbabwe",
                su_a3: "ZWE",
                brk_diff: 0,
                name: "Zimbabwe",
                name_long: "Zimbabwe",
                brk_a3: "ZWE",
                brk_name: "Zimbabwe",
                brk_group: null,
                abbrev: "Zimb.",
                postal: "ZW",
                formal_en: "Republic of Zimbabwe",
                formal_fr: null,
                name_ciawf: "Zimbabwe",
                note_adm0: null,
                note_brk: null,
                name_sort: "Zimbabwe",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 5,
                mapcolor9: 3,
                mapcolor13: 9,
                pop_est: 14645468,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 21440,
                gdp_year: 2019,
                economy: "5. Emerging region: G20",
                income_grp: "5. Low income",
                fips_10: "ZI",
                iso_a2: "ZW",
                iso_a2_eh: "ZW",
                iso_a3: "ZWE",
                iso_a3_eh: "ZWE",
                iso_n3: "716",
                iso_n3_eh: "716",
                un_a3: "716",
                wb_a2: "ZW",
                wb_a3: "ZWE",
                woe_id: 23425004,
                woe_id_eh: 23425004,
                woe_note: "Exact WOE match as country",
                adm0_iso: "ZWE",
                adm0_diff: null,
                adm0_tlc: "ZWE",
                adm0_a3_us: "ZWE",
                adm0_a3_fr: "ZWE",
                adm0_a3_ru: "ZWE",
                adm0_a3_es: "ZWE",
                adm0_a3_cn: "ZWE",
                adm0_a3_tw: "ZWE",
                adm0_a3_in: "ZWE",
                adm0_a3_np: "ZWE",
                adm0_a3_pk: "ZWE",
                adm0_a3_de: "ZWE",
                adm0_a3_gb: "ZWE",
                adm0_a3_br: "ZWE",
                adm0_a3_il: "ZWE",
                adm0_a3_ps: "ZWE",
                adm0_a3_sa: "ZWE",
                adm0_a3_eg: "ZWE",
                adm0_a3_ma: "ZWE",
                adm0_a3_pt: "ZWE",
                adm0_a3_ar: "ZWE",
                adm0_a3_jp: "ZWE",
                adm0_a3_ko: "ZWE",
                adm0_a3_vn: "ZWE",
                adm0_a3_tr: "ZWE",
                adm0_a3_id: "ZWE",
                adm0_a3_pl: "ZWE",
                adm0_a3_gr: "ZWE",
                adm0_a3_it: "ZWE",
                adm0_a3_nl: "ZWE",
                adm0_a3_se: "ZWE",
                adm0_a3_bd: "ZWE",
                adm0_a3_ua: "ZWE",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.5,
                max_label: 8,
                label_x: 29.925444,
                label_y: -18.91164,
                ne_id: 1159321441,
                wikidataid: "Q954",
                name_ar: "زيمبابوي",
                name_bn: "জিম্বাবুয়ে",
                name_de: "Simbabwe",
                name_en: "Zimbabwe",
                name_es: "Zimbabue",
                name_fa: "زیمبابوه",
                name_fr: "Zimbabwe",
                name_el: "Ζιμπάμπουε",
                name_he: "זימבבואה",
                name_hi: "ज़िम्बाब्वे",
                name_hu: "Zimbabwe",
                name_id: "Zimbabwe",
                name_it: "Zimbabwe",
                name_ja: "ジンバブエ",
                name_ko: "짐바브웨",
                name_nl: "Zimbabwe",
                name_pl: "Zimbabwe",
                name_pt: "Zimbábue",
                name_ru: "Зимбабве",
                name_sv: "Zimbabwe",
                name_tr: "Zimbabve",
                name_uk: "Зімбабве",
                name_ur: "زمبابوے",
                name_vi: "Zimbabwe",
                name_zh: "津巴布韦",
                name_zht: "辛巴威",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "ZWE.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [31.19140913262129, -22.2515096981724],
                        [30.65986535006709, -22.151567478119915],
                        [30.322883335091774, -22.271611830333935],
                        [29.839036899542972, -22.102216485281176],
                        [29.43218834810904, -22.091312758067588],
                        [28.794656202924216, -21.63945403410745],
                        [28.021370070108617, -21.485975030200585],
                        [27.72722781750326, -20.851801853114715],
                        [27.724747348753255, -20.49905852629039],
                        [27.296504754350508, -20.391519870691],
                        [26.164790887158485, -19.29308562589494],
                        [25.85039147309473, -18.714412937090536],
                        [25.649163445750162, -18.53602589281899],
                        [25.264225701608012, -17.736539808831417],
                        [26.381935255648926, -17.8460421688579],
                        [26.70677330903564, -17.961228936436484],
                        [27.044427117630732, -17.938026218337434],
                        [27.59824344250276, -17.290830580314008],
                        [28.467906121542683, -16.468400160388846],
                        [28.8258687680285, -16.389748630440614],
                        [28.947463413211267, -16.04305144619444],
                        [29.516834344203147, -15.644677829656388],
                        [30.27425581230511, -15.507786960515213],
                        [30.338954705534544, -15.880839125230246],
                        [31.17306399915768, -15.860943698797874],
                        [31.636498243951195, -16.071990248277885],
                        [31.8520406430406, -16.319417006091378],
                        [32.32823896661023, -16.392074069893752],
                        [32.847638787575846, -16.713398125884616],
                        [32.84986087416439, -17.97905730557718],
                        [32.65488569512715, -18.672089939043495],
                        [32.61199425632489, -19.419382826416275],
                        [32.772707960752626, -19.715592136313298],
                        [32.65974327976258, -20.304290052982317],
                        [32.50869306817344, -20.395292250248307],
                        [32.244988234188014, -21.116488539313693],
                        [31.19140913262129, -22.2515096981724],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Chad",
                sov_a3: "TCD",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Chad",
                adm0_a3: "TCD",
                geou_dif: 0,
                geounit: "Chad",
                gu_a3: "TCD",
                su_dif: 0,
                subunit: "Chad",
                su_a3: "TCD",
                brk_diff: 0,
                name: "Chad",
                name_long: "Chad",
                brk_a3: "TCD",
                brk_name: "Chad",
                brk_group: null,
                abbrev: "Chad",
                postal: "TD",
                formal_en: "Republic of Chad",
                formal_fr: null,
                name_ciawf: "Chad",
                note_adm0: null,
                note_brk: null,
                name_sort: "Chad",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 1,
                mapcolor9: 8,
                mapcolor13: 6,
                pop_est: 15946876,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 11314,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "CD",
                iso_a2: "TD",
                iso_a2_eh: "TD",
                iso_a3: "TCD",
                iso_a3_eh: "TCD",
                iso_n3: "148",
                iso_n3_eh: "148",
                un_a3: "148",
                wb_a2: "TD",
                wb_a3: "TCD",
                woe_id: 23424777,
                woe_id_eh: 23424777,
                woe_note: "Exact WOE match as country",
                adm0_iso: "TCD",
                adm0_diff: null,
                adm0_tlc: "TCD",
                adm0_a3_us: "TCD",
                adm0_a3_fr: "TCD",
                adm0_a3_ru: "TCD",
                adm0_a3_es: "TCD",
                adm0_a3_cn: "TCD",
                adm0_a3_tw: "TCD",
                adm0_a3_in: "TCD",
                adm0_a3_np: "TCD",
                adm0_a3_pk: "TCD",
                adm0_a3_de: "TCD",
                adm0_a3_gb: "TCD",
                adm0_a3_br: "TCD",
                adm0_a3_il: "TCD",
                adm0_a3_ps: "TCD",
                adm0_a3_sa: "TCD",
                adm0_a3_eg: "TCD",
                adm0_a3_ma: "TCD",
                adm0_a3_pt: "TCD",
                adm0_a3_ar: "TCD",
                adm0_a3_jp: "TCD",
                adm0_a3_ko: "TCD",
                adm0_a3_vn: "TCD",
                adm0_a3_tr: "TCD",
                adm0_a3_id: "TCD",
                adm0_a3_pl: "TCD",
                adm0_a3_gr: "TCD",
                adm0_a3_it: "TCD",
                adm0_a3_nl: "TCD",
                adm0_a3_se: "TCD",
                adm0_a3_bd: "TCD",
                adm0_a3_ua: "TCD",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 4,
                long_len: 4,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 18.645041,
                label_y: 15.142959,
                ne_id: 1159321301,
                wikidataid: "Q657",
                name_ar: "تشاد",
                name_bn: "চাদ",
                name_de: "Tschad",
                name_en: "Chad",
                name_es: "Chad",
                name_fa: "چاد",
                name_fr: "Tchad",
                name_el: "Τσαντ",
                name_he: "צ'אד",
                name_hi: "चाड",
                name_hu: "Csád",
                name_id: "Chad",
                name_it: "Ciad",
                name_ja: "チャド",
                name_ko: "차드",
                name_nl: "Tsjaad",
                name_pl: "Czad",
                name_pt: "Chade",
                name_ru: "Чад",
                name_sv: "Tchad",
                name_tr: "Çad",
                name_uk: "Чад",
                name_ur: "چاڈ",
                name_vi: "Tchad",
                name_zh: "乍得",
                name_zht: "查德",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "TCD.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [23.83766000000014, 19.580470000000105],
                        [23.886890000000108, 15.61084],
                        [23.024590000000103, 15.68072],
                        [22.56795000000011, 14.944290000000137],
                        [22.30351, 14.32682],
                        [22.51202, 14.09318],
                        [22.18329, 13.78648],
                        [22.29658, 13.37232],
                        [22.03759, 12.95546],
                        [21.93681, 12.588180000000136],
                        [22.28801, 12.64605],
                        [22.49762, 12.26024],
                        [22.50869, 11.67936],
                        [22.87622, 11.384610000000123],
                        [22.864165480244225, 11.142395127807546],
                        [22.23112918466876, 10.97188873946061],
                        [21.723821648859456, 10.567055568885976],
                        [21.000868361096167, 9.475985215691509],
                        [20.05968549976427, 9.012706000194854],
                        [19.09400800952602, 9.07484691002584],
                        [18.812009718509273, 8.982914536978598],
                        [18.911021762780507, 8.630894680206353],
                        [18.38955488452322, 8.281303615751824],
                        [17.964929640380888, 7.890914008002994],
                        [16.705988396886255, 7.508327541529979],
                        [16.456184523187346, 7.734773667832968],
                        [16.290561557691888, 7.754307359239419],
                        [16.106231723706742, 7.497087917506462],
                        [15.279460483469109, 7.421924546737969],
                        [15.436091749745742, 7.692812404811889],
                        [15.120865512765306, 8.382150173369439],
                        [14.97999555833769, 8.796104234243472],
                        [14.54446658698177, 8.965861314322268],
                        [13.954218377344006, 9.549494940626687],
                        [14.171466098699028, 10.021378282099931],
                        [14.62720055508106, 9.920919297724538],
                        [14.909353875394716, 9.992129421422732],
                        [15.467872755605242, 9.982336737503545],
                        [14.92356489427496, 10.891325181517473],
                        [14.9601518083376, 11.555574042197224],
                        [14.89336, 12.21905],
                        [14.495787387762846, 12.85939626713733],
                        [14.595781284247607, 13.33042694747786],
                        [13.95447675950561, 13.353448798063766],
                        [13.956698846094127, 13.996691189016929],
                        [13.540393507550789, 14.367133693901224],
                        [13.97217, 15.68437],
                        [15.247731154041844, 16.627305813050782],
                        [15.30044111497972, 17.927949937405003],
                        [15.685740594147774, 19.957180080642388],
                        [15.903246697664315, 20.387618923417506],
                        [15.487148064850146, 20.730414537025638],
                        [15.47106, 21.04845],
                        [15.096887648181848, 21.30851878507491],
                        [14.8513, 22.862950000000126],
                        [15.86085, 23.40972],
                        [19.84926, 21.49509],
                        [23.83766000000014, 19.580470000000105],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Rwanda",
                sov_a3: "RWA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Rwanda",
                adm0_a3: "RWA",
                geou_dif: 0,
                geounit: "Rwanda",
                gu_a3: "RWA",
                su_dif: 0,
                subunit: "Rwanda",
                su_a3: "RWA",
                brk_diff: 0,
                name: "Rwanda",
                name_long: "Rwanda",
                brk_a3: "RWA",
                brk_name: "Rwanda",
                brk_group: null,
                abbrev: "Rwa.",
                postal: "RW",
                formal_en: "Republic of Rwanda",
                formal_fr: null,
                name_ciawf: "Rwanda",
                note_adm0: null,
                note_brk: null,
                name_sort: "Rwanda",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 2,
                mapcolor9: 3,
                mapcolor13: 10,
                pop_est: 12626950,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 10354,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "RW",
                iso_a2: "RW",
                iso_a2_eh: "RW",
                iso_a3: "RWA",
                iso_a3_eh: "RWA",
                iso_n3: "646",
                iso_n3_eh: "646",
                un_a3: "646",
                wb_a2: "RW",
                wb_a3: "RWA",
                woe_id: 23424937,
                woe_id_eh: 23424937,
                woe_note: "Exact WOE match as country",
                adm0_iso: "RWA",
                adm0_diff: null,
                adm0_tlc: "RWA",
                adm0_a3_us: "RWA",
                adm0_a3_fr: "RWA",
                adm0_a3_ru: "RWA",
                adm0_a3_es: "RWA",
                adm0_a3_cn: "RWA",
                adm0_a3_tw: "RWA",
                adm0_a3_in: "RWA",
                adm0_a3_np: "RWA",
                adm0_a3_pk: "RWA",
                adm0_a3_de: "RWA",
                adm0_a3_gb: "RWA",
                adm0_a3_br: "RWA",
                adm0_a3_il: "RWA",
                adm0_a3_ps: "RWA",
                adm0_a3_sa: "RWA",
                adm0_a3_eg: "RWA",
                adm0_a3_ma: "RWA",
                adm0_a3_pt: "RWA",
                adm0_a3_ar: "RWA",
                adm0_a3_jp: "RWA",
                adm0_a3_ko: "RWA",
                adm0_a3_vn: "RWA",
                adm0_a3_tr: "RWA",
                adm0_a3_id: "RWA",
                adm0_a3_pl: "RWA",
                adm0_a3_gr: "RWA",
                adm0_a3_it: "RWA",
                adm0_a3_nl: "RWA",
                adm0_a3_se: "RWA",
                adm0_a3_bd: "RWA",
                adm0_a3_ua: "RWA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 30.103894,
                label_y: -1.897196,
                ne_id: 1159321219,
                wikidataid: "Q1037",
                name_ar: "رواندا",
                name_bn: "রুয়ান্ডা",
                name_de: "Ruanda",
                name_en: "Rwanda",
                name_es: "Ruanda",
                name_fa: "رواندا",
                name_fr: "Rwanda",
                name_el: "Ρουάντα",
                name_he: "רואנדה",
                name_hi: "रवाण्डा",
                name_hu: "Ruanda",
                name_id: "Rwanda",
                name_it: "Ruanda",
                name_ja: "ルワンダ",
                name_ko: "르완다",
                name_nl: "Rwanda",
                name_pl: "Rwanda",
                name_pt: "Ruanda",
                name_ru: "Руанда",
                name_sv: "Rwanda",
                name_tr: "Ruanda",
                name_uk: "Руанда",
                name_ur: "روانڈا",
                name_vi: "Rwanda",
                name_zh: "卢旺达",
                name_zht: "盧旺達",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "RWA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.419104852019245, -1.134659112150416],
                        [30.816134881317712, -1.698914076345389],
                        [30.75830895358311, -2.287250257988369],
                        [30.46967, -2.41383],
                        [30.469673645761223, -2.41385475710134],
                        [29.93835900240794, -2.348486830254238],
                        [29.632176141078588, -2.917857761246097],
                        [29.024926385216787, -2.839257907730158],
                        [29.117478875451553, -2.292211195488385],
                        [29.254834832483343, -2.215109958508911],
                        [29.291886834436614, -1.620055840667987],
                        [29.579466180140884, -1.341313164885626],
                        [29.821518588996014, -1.443322442229785],
                        [30.419104852019245, -1.134659112150416],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Mozambique",
                sov_a3: "MOZ",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Mozambique",
                adm0_a3: "MOZ",
                geou_dif: 0,
                geounit: "Mozambique",
                gu_a3: "MOZ",
                su_dif: 0,
                subunit: "Mozambique",
                su_a3: "MOZ",
                brk_diff: 0,
                name: "Mozambique",
                name_long: "Mozambique",
                brk_a3: "MOZ",
                brk_name: "Mozambique",
                brk_group: null,
                abbrev: "Moz.",
                postal: "MZ",
                formal_en: "Republic of Mozambique",
                formal_fr: null,
                name_ciawf: "Mozambique",
                note_adm0: null,
                note_brk: null,
                name_sort: "Mozambique",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 2,
                mapcolor9: 1,
                mapcolor13: 4,
                pop_est: 30366036,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 15291,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "MZ",
                iso_a2: "MZ",
                iso_a2_eh: "MZ",
                iso_a3: "MOZ",
                iso_a3_eh: "MOZ",
                iso_n3: "508",
                iso_n3_eh: "508",
                un_a3: "508",
                wb_a2: "MZ",
                wb_a3: "MOZ",
                woe_id: 23424902,
                woe_id_eh: 23424902,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MOZ",
                adm0_diff: null,
                adm0_tlc: "MOZ",
                adm0_a3_us: "MOZ",
                adm0_a3_fr: "MOZ",
                adm0_a3_ru: "MOZ",
                adm0_a3_es: "MOZ",
                adm0_a3_cn: "MOZ",
                adm0_a3_tw: "MOZ",
                adm0_a3_in: "MOZ",
                adm0_a3_np: "MOZ",
                adm0_a3_pk: "MOZ",
                adm0_a3_de: "MOZ",
                adm0_a3_gb: "MOZ",
                adm0_a3_br: "MOZ",
                adm0_a3_il: "MOZ",
                adm0_a3_ps: "MOZ",
                adm0_a3_sa: "MOZ",
                adm0_a3_eg: "MOZ",
                adm0_a3_ma: "MOZ",
                adm0_a3_pt: "MOZ",
                adm0_a3_ar: "MOZ",
                adm0_a3_jp: "MOZ",
                adm0_a3_ko: "MOZ",
                adm0_a3_vn: "MOZ",
                adm0_a3_tr: "MOZ",
                adm0_a3_id: "MOZ",
                adm0_a3_pl: "MOZ",
                adm0_a3_gr: "MOZ",
                adm0_a3_it: "MOZ",
                adm0_a3_nl: "MOZ",
                adm0_a3_se: "MOZ",
                adm0_a3_bd: "MOZ",
                adm0_a3_ua: "MOZ",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 10,
                long_len: 10,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 37.83789,
                label_y: -13.94323,
                ne_id: 1159321073,
                wikidataid: "Q1029",
                name_ar: "موزمبيق",
                name_bn: "মোজাম্বিক",
                name_de: "Mosambik",
                name_en: "Mozambique",
                name_es: "Mozambique",
                name_fa: "موزامبیک",
                name_fr: "Mozambique",
                name_el: "Μοζαμβίκη",
                name_he: "מוזמביק",
                name_hi: "मोज़ाम्बीक",
                name_hu: "Mozambik",
                name_id: "Mozambik",
                name_it: "Mozambico",
                name_ja: "モザンビーク",
                name_ko: "모잠비크",
                name_nl: "Mozambique",
                name_pl: "Mozambik",
                name_pt: "Moçambique",
                name_ru: "Мозамбик",
                name_sv: "Moçambique",
                name_tr: "Mozambik",
                name_uk: "Мозамбік",
                name_ur: "موزمبیق",
                name_vi: "Mozambique",
                name_zh: "莫桑比克",
                name_zht: "莫三比克",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MOZ.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [34.55998904799935, -11.520020033415925],
                        [35.31239790216904, -11.439146416879147],
                        [36.51408165868426, -11.720938002166735],
                        [36.775150994622805, -11.594537448780805],
                        [37.47129, -11.56876],
                        [37.82764, -11.26879],
                        [38.42755659358775, -11.285202325081656],
                        [39.521, -10.89688],
                        [40.31659, -10.317099999999868],
                        [40.316586229110854, -10.317097752817492],
                        [40.31658857601719, -10.317096042525698],
                        [40.47838748552303, -10.765440769089993],
                        [40.437253045418686, -11.761710707245015],
                        [40.56081139502857, -12.639176527561027],
                        [40.59962039567975, -14.201975192931862],
                        [40.775475294768995, -14.691764418194241],
                        [40.4772506040126, -15.406294447493972],
                        [40.08926395036522, -16.10077402106446],
                        [39.45255862809705, -16.72089120856694],
                        [38.53835086442152, -17.101023044505958],
                        [37.41113284683888, -17.586368096591237],
                        [36.28127933120936, -18.65968759529345],
                        [35.89649661636406, -18.842260430580637],
                        [35.198399692533144, -19.552811374593894],
                        [34.78638349787005, -19.784011732667736],
                        [34.70189253107284, -20.49704314543101],
                        [35.176127150215365, -21.25436126066841],
                        [35.37342776870574, -21.840837090748877],
                        [35.385848253705404, -22.14],
                        [35.562545536369086, -22.09],
                        [35.533934767404304, -23.070787855727758],
                        [35.37177412287238, -23.5353589820317],
                        [35.60747033055563, -23.706563002214683],
                        [35.45874555841962, -24.12260995859655],
                        [35.04073489761066, -24.478350518493805],
                        [34.21582400893547, -24.81631438568266],
                        [33.01321007663901, -25.357573337507738],
                        [32.574632195777866, -25.727318210556092],
                        [32.66036339695009, -26.148584486599447],
                        [32.91595503106569, -26.215867201443466],
                        [32.830120477028885, -26.742191664336197],
                        [32.07166548028107, -26.73382008230491],
                        [31.98577924981197, -26.291779880480227],
                        [31.83777794772806, -25.84333180105135],
                        [31.75240848158188, -25.484283949487413],
                        [31.930588820124253, -24.36941659922254],
                        [31.670397983534656, -23.658969008073864],
                        [31.19140913262129, -22.2515096981724],
                        [32.244988234188014, -21.116488539313693],
                        [32.50869306817344, -20.395292250248307],
                        [32.65974327976258, -20.304290052982317],
                        [32.772707960752626, -19.715592136313298],
                        [32.61199425632489, -19.419382826416275],
                        [32.65488569512715, -18.672089939043495],
                        [32.84986087416439, -17.97905730557718],
                        [32.847638787575846, -16.713398125884616],
                        [32.32823896661023, -16.392074069893752],
                        [31.8520406430406, -16.319417006091378],
                        [31.636498243951195, -16.071990248277885],
                        [31.17306399915768, -15.860943698797874],
                        [30.338954705534544, -15.880839125230246],
                        [30.27425581230511, -15.507786960515213],
                        [30.17948123548183, -14.796099134991529],
                        [33.214024692525214, -13.971860039936153],
                        [33.789700148256685, -14.45183074306307],
                        [34.064825473778626, -14.35995004644812],
                        [34.45963341648854, -14.613009535381423],
                        [34.51766604995231, -15.013708591372612],
                        [34.307291294092096, -15.478641452702597],
                        [34.38129194513405, -16.183559665596043],
                        [35.033810255683534, -16.801299737213093],
                        [35.339062941231646, -16.10744028083011],
                        [35.77190473810836, -15.896858819240727],
                        [35.68684533055594, -14.611045830954332],
                        [35.26795617039801, -13.887834161029566],
                        [34.907151320136165, -13.565424899960568],
                        [34.55998904799935, -13.579997653866876],
                        [34.28000613784198, -12.280025323132506],
                        [34.55998904799935, -11.520020033415925],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "eSwatini",
                sov_a3: "SWZ",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "eSwatini",
                adm0_a3: "SWZ",
                geou_dif: 0,
                geounit: "eSwatini",
                gu_a3: "SWZ",
                su_dif: 0,
                subunit: "eSwatini",
                su_a3: "SWZ",
                brk_diff: 0,
                name: "eSwatini",
                name_long: "Kingdom of eSwatini",
                brk_a3: "SWZ",
                brk_name: "eSwatini",
                brk_group: null,
                abbrev: "eSw.",
                postal: "ES",
                formal_en: "Kingdom of eSwatini",
                formal_fr: null,
                name_ciawf: "eSwatini",
                note_adm0: null,
                note_brk: null,
                name_sort: "eSwatini",
                name_alt: "Swaziland",
                mapcolor7: 3,
                mapcolor8: 6,
                mapcolor9: 2,
                mapcolor13: 5,
                pop_est: 1148130,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 4471,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "WZ",
                iso_a2: "SZ",
                iso_a2_eh: "SZ",
                iso_a3: "SWZ",
                iso_a3_eh: "SWZ",
                iso_n3: "748",
                iso_n3_eh: "748",
                un_a3: "748",
                wb_a2: "SZ",
                wb_a3: "SWZ",
                woe_id: 23424993,
                woe_id_eh: 23424993,
                woe_note: "Exact WOE match as country",
                adm0_iso: "SWZ",
                adm0_diff: null,
                adm0_tlc: "SWZ",
                adm0_a3_us: "SWZ",
                adm0_a3_fr: "SWZ",
                adm0_a3_ru: "SWZ",
                adm0_a3_es: "SWZ",
                adm0_a3_cn: "SWZ",
                adm0_a3_tw: "SWZ",
                adm0_a3_in: "SWZ",
                adm0_a3_np: "SWZ",
                adm0_a3_pk: "SWZ",
                adm0_a3_de: "SWZ",
                adm0_a3_gb: "SWZ",
                adm0_a3_br: "SWZ",
                adm0_a3_il: "SWZ",
                adm0_a3_ps: "SWZ",
                adm0_a3_sa: "SWZ",
                adm0_a3_eg: "SWZ",
                adm0_a3_ma: "SWZ",
                adm0_a3_pt: "SWZ",
                adm0_a3_ar: "SWZ",
                adm0_a3_jp: "SWZ",
                adm0_a3_ko: "SWZ",
                adm0_a3_vn: "SWZ",
                adm0_a3_tr: "SWZ",
                adm0_a3_id: "SWZ",
                adm0_a3_pl: "SWZ",
                adm0_a3_gr: "SWZ",
                adm0_a3_it: "SWZ",
                adm0_a3_nl: "SWZ",
                adm0_a3_se: "SWZ",
                adm0_a3_bd: "SWZ",
                adm0_a3_ua: "SWZ",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Southern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 19,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 31.467264,
                label_y: -26.533676,
                ne_id: 1159321289,
                wikidataid: "Q1050",
                name_ar: "إسواتيني",
                name_bn: "ইসোয়াতিনি",
                name_de: "Eswatini",
                name_en: "Eswatini",
                name_es: "Suazilandia",
                name_fa: "اسواتینی",
                name_fr: "Eswatini",
                name_el: "Εσουατίνι",
                name_he: "אסוואטיני",
                name_hi: "एस्वातीनी",
                name_hu: "Szváziföld",
                name_id: "Eswatini",
                name_it: "eSwatini",
                name_ja: "エスワティニ",
                name_ko: "에스와티니",
                name_nl: "Swaziland",
                name_pl: "Eswatini",
                name_pt: "Essuatíni",
                name_ru: "Эсватини",
                name_sv: "Swaziland",
                name_tr: "Esvatini",
                name_uk: "Есватіні",
                name_ur: "اسواتینی",
                name_vi: "Eswatini",
                name_zh: "斯威士兰",
                name_zht: "史瓦帝尼",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "SWZ.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [32.07166548028107, -26.73382008230491],
                        [31.86806033705108, -27.177927341421277],
                        [31.28277306491333, -27.285879408478998],
                        [30.68596194837448, -26.743845310169533],
                        [30.67660851412964, -26.398078301704608],
                        [30.949666782359913, -26.02264902110415],
                        [31.04407962415715, -25.731452325139443],
                        [31.333157586397903, -25.66019052500895],
                        [31.83777794772806, -25.84333180105135],
                        [31.98577924981197, -26.291779880480227],
                        [32.07166548028107, -26.73382008230491],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Burundi",
                sov_a3: "BDI",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Burundi",
                adm0_a3: "BDI",
                geou_dif: 0,
                geounit: "Burundi",
                gu_a3: "BDI",
                su_dif: 0,
                subunit: "Burundi",
                su_a3: "BDI",
                brk_diff: 0,
                name: "Burundi",
                name_long: "Burundi",
                brk_a3: "BDI",
                brk_name: "Burundi",
                brk_group: null,
                abbrev: "Bur.",
                postal: "BI",
                formal_en: "Republic of Burundi",
                formal_fr: null,
                name_ciawf: "Burundi",
                note_adm0: null,
                note_brk: null,
                name_sort: "Burundi",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 2,
                mapcolor9: 5,
                mapcolor13: 8,
                pop_est: 11530580,
                pop_rank: 14,
                pop_year: 2019,
                gdp_md: 3012,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "BY",
                iso_a2: "BI",
                iso_a2_eh: "BI",
                iso_a3: "BDI",
                iso_a3_eh: "BDI",
                iso_n3: "108",
                iso_n3_eh: "108",
                un_a3: "108",
                wb_a2: "BI",
                wb_a3: "BDI",
                woe_id: 23424774,
                woe_id_eh: 23424774,
                woe_note: "Exact WOE match as country",
                adm0_iso: "BDI",
                adm0_diff: null,
                adm0_tlc: "BDI",
                adm0_a3_us: "BDI",
                adm0_a3_fr: "BDI",
                adm0_a3_ru: "BDI",
                adm0_a3_es: "BDI",
                adm0_a3_cn: "BDI",
                adm0_a3_tw: "BDI",
                adm0_a3_in: "BDI",
                adm0_a3_np: "BDI",
                adm0_a3_pk: "BDI",
                adm0_a3_de: "BDI",
                adm0_a3_gb: "BDI",
                adm0_a3_br: "BDI",
                adm0_a3_il: "BDI",
                adm0_a3_ps: "BDI",
                adm0_a3_sa: "BDI",
                adm0_a3_eg: "BDI",
                adm0_a3_ma: "BDI",
                adm0_a3_pt: "BDI",
                adm0_a3_ar: "BDI",
                adm0_a3_jp: "BDI",
                adm0_a3_ko: "BDI",
                adm0_a3_vn: "BDI",
                adm0_a3_tr: "BDI",
                adm0_a3_id: "BDI",
                adm0_a3_pl: "BDI",
                adm0_a3_gr: "BDI",
                adm0_a3_it: "BDI",
                adm0_a3_nl: "BDI",
                adm0_a3_se: "BDI",
                adm0_a3_bd: "BDI",
                adm0_a3_ua: "BDI",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 29.917086,
                label_y: -3.332836,
                ne_id: 1159320387,
                wikidataid: "Q967",
                name_ar: "بوروندي",
                name_bn: "বুরুন্ডি",
                name_de: "Burundi",
                name_en: "Burundi",
                name_es: "Burundi",
                name_fa: "بوروندی",
                name_fr: "Burundi",
                name_el: "Μπουρούντι",
                name_he: "בורונדי",
                name_hi: "बुरुण्डी",
                name_hu: "Burundi",
                name_id: "Burundi",
                name_it: "Burundi",
                name_ja: "ブルンジ",
                name_ko: "부룬디",
                name_nl: "Burundi",
                name_pl: "Burundi",
                name_pt: "Burundi",
                name_ru: "Бурунди",
                name_sv: "Burundi",
                name_tr: "Burundi",
                name_uk: "Бурунді",
                name_ur: "برونڈی",
                name_vi: "Burundi",
                name_zh: "布隆迪",
                name_zht: "蒲隆地",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "BDI.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [30.469673645761223, -2.41385475710134],
                        [30.52766, -2.80762],
                        [30.74301, -3.03431],
                        [30.75224, -3.35931],
                        [30.50554, -3.56858],
                        [30.11632, -4.09012],
                        [29.753512404099865, -4.452389418153302],
                        [29.339997592900346, -4.499983412294092],
                        [29.276383904749053, -3.293907159034063],
                        [29.024926385216787, -2.839257907730158],
                        [29.632176141078588, -2.917857761246097],
                        [29.93835900240794, -2.348486830254238],
                        [30.469673645761223, -2.41385475710134],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Burkina Faso",
                sov_a3: "BFA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Burkina Faso",
                adm0_a3: "BFA",
                geou_dif: 0,
                geounit: "Burkina Faso",
                gu_a3: "BFA",
                su_dif: 0,
                subunit: "Burkina Faso",
                su_a3: "BFA",
                brk_diff: 0,
                name: "Burkina Faso",
                name_long: "Burkina Faso",
                brk_a3: "BFA",
                brk_name: "Burkina Faso",
                brk_group: null,
                abbrev: "B.F.",
                postal: "BF",
                formal_en: "Burkina Faso",
                formal_fr: null,
                name_ciawf: "Burkina Faso",
                note_adm0: null,
                note_brk: null,
                name_sort: "Burkina Faso",
                name_alt: null,
                mapcolor7: 2,
                mapcolor8: 1,
                mapcolor9: 5,
                mapcolor13: 11,
                pop_est: 20321378,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 15990,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "UV",
                iso_a2: "BF",
                iso_a2_eh: "BF",
                iso_a3: "BFA",
                iso_a3_eh: "BFA",
                iso_n3: "854",
                iso_n3_eh: "854",
                un_a3: "854",
                wb_a2: "BF",
                wb_a3: "BFA",
                woe_id: 23424978,
                woe_id_eh: 23424978,
                woe_note: "Exact WOE match as country",
                adm0_iso: "BFA",
                adm0_diff: null,
                adm0_tlc: "BFA",
                adm0_a3_us: "BFA",
                adm0_a3_fr: "BFA",
                adm0_a3_ru: "BFA",
                adm0_a3_es: "BFA",
                adm0_a3_cn: "BFA",
                adm0_a3_tw: "BFA",
                adm0_a3_in: "BFA",
                adm0_a3_np: "BFA",
                adm0_a3_pk: "BFA",
                adm0_a3_de: "BFA",
                adm0_a3_gb: "BFA",
                adm0_a3_br: "BFA",
                adm0_a3_il: "BFA",
                adm0_a3_ps: "BFA",
                adm0_a3_sa: "BFA",
                adm0_a3_eg: "BFA",
                adm0_a3_ma: "BFA",
                adm0_a3_pt: "BFA",
                adm0_a3_ar: "BFA",
                adm0_a3_jp: "BFA",
                adm0_a3_ko: "BFA",
                adm0_a3_vn: "BFA",
                adm0_a3_tr: "BFA",
                adm0_a3_id: "BFA",
                adm0_a3_pl: "BFA",
                adm0_a3_gr: "BFA",
                adm0_a3_it: "BFA",
                adm0_a3_nl: "BFA",
                adm0_a3_se: "BFA",
                adm0_a3_bd: "BFA",
                adm0_a3_ua: "BFA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 12,
                long_len: 12,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: -1.36388,
                label_y: 12.673048,
                ne_id: 1159320405,
                wikidataid: "Q965",
                name_ar: "بوركينا فاسو",
                name_bn: "বুর্কিনা ফাসো",
                name_de: "Burkina Faso",
                name_en: "Burkina Faso",
                name_es: "Burkina Faso",
                name_fa: "بورکینافاسو",
                name_fr: "Burkina Faso",
                name_el: "Μπουρκίνα Φάσο",
                name_he: "בורקינה פאסו",
                name_hi: "बुर्किना फासो",
                name_hu: "Burkina Faso",
                name_id: "Burkina Faso",
                name_it: "Burkina Faso",
                name_ja: "ブルキナファソ",
                name_ko: "부르키나파소",
                name_nl: "Burkina Faso",
                name_pl: "Burkina Faso",
                name_pt: "Burkina Faso",
                name_ru: "Буркина-Фасо",
                name_sv: "Burkina Faso",
                name_tr: "Burkina Faso",
                name_uk: "Буркіна-Фасо",
                name_ur: "برکینا فاسو",
                name_vi: "Burkina Faso",
                name_zh: "布基纳法索",
                name_zht: "布基納法索",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "BFA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-5.404341599946974, 10.370736802609146],
                        [-5.470564947929006, 10.951269842976048],
                        [-5.197842576508648, 11.37514577885014],
                        [-5.220941941743121, 11.713858954307227],
                        [-4.427166103523803, 12.542645575404295],
                        [-4.28040503581488, 13.228443508349741],
                        [-4.006390753587226, 13.472485459848116],
                        [-3.522802700199861, 13.337661647998615],
                        [-3.10370683431276, 13.541266791228594],
                        [-2.967694464520577, 13.79815033615151],
                        [-2.191824510090385, 14.246417548067356],
                        [-2.001035122068771, 14.559008287000893],
                        [-1.066363491205664, 14.973815009007765],
                        [-0.515854458000348, 15.116157741755728],
                        [-0.26625729003058, 14.924308986872148],
                        [0.374892205414682, 14.92890818934613],
                        [0.295646396495101, 14.444234930880654],
                        [0.429927605805517, 13.988733018443924],
                        [0.993045688490071, 13.335749620003824],
                        [1.024103224297477, 12.851825669806574],
                        [2.177107781593776, 12.625017808477535],
                        [2.154473504249921, 11.940150051313339],
                        [1.935985548519881, 11.641150214072553],
                        [1.447178175471066, 11.547719224488858],
                        [1.243469679376489, 11.110510769083461],
                        [0.899563022474069, 10.99733938236426],
                        [0.023802524423701, 11.018681748900804],
                        [-0.438701544588582, 11.098340969278722],
                        [-0.761575893548183, 10.936929633015055],
                        [-1.203357713211432, 11.009819240762738],
                        [-2.940409308270461, 10.962690334512558],
                        [-2.963896246747112, 10.395334784380083],
                        [-2.827496303712707, 9.642460842319778],
                        [-3.511898972986273, 9.90032623945622],
                        [-3.980449184576685, 9.8623440617217],
                        [-4.330246954760383, 9.610834865757141],
                        [-4.779883592131966, 9.821984768101743],
                        [-4.954653286143099, 10.152713934769736],
                        [-5.404341599946974, 10.370736802609146],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Uganda",
                sov_a3: "UGA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Uganda",
                adm0_a3: "UGA",
                geou_dif: 0,
                geounit: "Uganda",
                gu_a3: "UGA",
                su_dif: 0,
                subunit: "Uganda",
                su_a3: "UGA",
                brk_diff: 0,
                name: "Uganda",
                name_long: "Uganda",
                brk_a3: "UGA",
                brk_name: "Uganda",
                brk_group: null,
                abbrev: "Uga.",
                postal: "UG",
                formal_en: "Republic of Uganda",
                formal_fr: null,
                name_ciawf: "Uganda",
                note_adm0: null,
                note_brk: null,
                name_sort: "Uganda",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 3,
                mapcolor9: 6,
                mapcolor13: 4,
                pop_est: 44269594,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 35165,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "UG",
                iso_a2: "UG",
                iso_a2_eh: "UG",
                iso_a3: "UGA",
                iso_a3_eh: "UGA",
                iso_n3: "800",
                iso_n3_eh: "800",
                un_a3: "800",
                wb_a2: "UG",
                wb_a3: "UGA",
                woe_id: 23424974,
                woe_id_eh: 23424974,
                woe_note: "Exact WOE match as country",
                adm0_iso: "UGA",
                adm0_diff: null,
                adm0_tlc: "UGA",
                adm0_a3_us: "UGA",
                adm0_a3_fr: "UGA",
                adm0_a3_ru: "UGA",
                adm0_a3_es: "UGA",
                adm0_a3_cn: "UGA",
                adm0_a3_tw: "UGA",
                adm0_a3_in: "UGA",
                adm0_a3_np: "UGA",
                adm0_a3_pk: "UGA",
                adm0_a3_de: "UGA",
                adm0_a3_gb: "UGA",
                adm0_a3_br: "UGA",
                adm0_a3_il: "UGA",
                adm0_a3_ps: "UGA",
                adm0_a3_sa: "UGA",
                adm0_a3_eg: "UGA",
                adm0_a3_ma: "UGA",
                adm0_a3_pt: "UGA",
                adm0_a3_ar: "UGA",
                adm0_a3_jp: "UGA",
                adm0_a3_ko: "UGA",
                adm0_a3_vn: "UGA",
                adm0_a3_tr: "UGA",
                adm0_a3_id: "UGA",
                adm0_a3_pl: "UGA",
                adm0_a3_gr: "UGA",
                adm0_a3_it: "UGA",
                adm0_a3_nl: "UGA",
                adm0_a3_se: "UGA",
                adm0_a3_bd: "UGA",
                adm0_a3_ua: "UGA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 6,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 32.948555,
                label_y: 1.972589,
                ne_id: 1159321343,
                wikidataid: "Q1036",
                name_ar: "أوغندا",
                name_bn: "উগান্ডা",
                name_de: "Uganda",
                name_en: "Uganda",
                name_es: "Uganda",
                name_fa: "اوگاندا",
                name_fr: "Ouganda",
                name_el: "Ουγκάντα",
                name_he: "אוגנדה",
                name_hi: "युगाण्डा",
                name_hu: "Uganda",
                name_id: "Uganda",
                name_it: "Uganda",
                name_ja: "ウガンダ",
                name_ko: "우간다",
                name_nl: "Oeganda",
                name_pl: "Uganda",
                name_pt: "Uganda",
                name_ru: "Уганда",
                name_sv: "Uganda",
                name_tr: "Uganda",
                name_uk: "Уганда",
                name_ur: "یوگنڈا",
                name_vi: "Uganda",
                name_zh: "乌干达",
                name_zht: "烏干達",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "UGA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [33.90371119710453, -0.95],
                        [31.86617, -1.02736],
                        [30.769860000000108, -1.01455],
                        [30.419104852019245, -1.134659112150416],
                        [29.821518588996014, -1.443322442229785],
                        [29.579466180140884, -1.341313164885626],
                        [29.58783776217217, -0.587405694179381],
                        [29.819503208136638, -0.205310153813372],
                        [29.875778842902434, 0.597379868976361],
                        [30.086153598762706, 1.062312730306289],
                        [30.46850752129029, 1.583805446779706],
                        [30.85267011894806, 1.849396470543809],
                        [31.174149204235817, 2.204465236821264],
                        [30.77334679538004, 2.339883327642127],
                        [30.83385989759381, 3.509165961110341],
                        [30.833852421715427, 3.509171604222463],
                        [31.24556, 3.7819],
                        [31.88145, 3.55827],
                        [32.68642, 3.79232],
                        [33.3900000000001, 3.79],
                        [34.005, 4.249884947362048],
                        [34.47913, 3.5556],
                        [34.59607, 3.053740000000118],
                        [35.03599, 1.90584],
                        [34.6721, 1.17694],
                        [34.18, 0.515],
                        [33.893568969666944, 0.109813537861896],
                        [33.90371119710453, -0.95],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Lesotho",
                sov_a3: "LSO",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Lesotho",
                adm0_a3: "LSO",
                geou_dif: 0,
                geounit: "Lesotho",
                gu_a3: "LSO",
                su_dif: 0,
                subunit: "Lesotho",
                su_a3: "LSO",
                brk_diff: 0,
                name: "Lesotho",
                name_long: "Lesotho",
                brk_a3: "LSO",
                brk_name: "Lesotho",
                brk_group: null,
                abbrev: "Les.",
                postal: "LS",
                formal_en: "Kingdom of Lesotho",
                formal_fr: null,
                name_ciawf: "Lesotho",
                note_adm0: null,
                note_brk: null,
                name_sort: "Lesotho",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 5,
                mapcolor9: 2,
                mapcolor13: 8,
                pop_est: 2125268,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 2376,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "4. Lower middle income",
                fips_10: "LT",
                iso_a2: "LS",
                iso_a2_eh: "LS",
                iso_a3: "LSO",
                iso_a3_eh: "LSO",
                iso_n3: "426",
                iso_n3_eh: "426",
                un_a3: "426",
                wb_a2: "LS",
                wb_a3: "LSO",
                woe_id: 23424880,
                woe_id_eh: 23424880,
                woe_note: "Exact WOE match as country",
                adm0_iso: "LSO",
                adm0_diff: null,
                adm0_tlc: "LSO",
                adm0_a3_us: "LSO",
                adm0_a3_fr: "LSO",
                adm0_a3_ru: "LSO",
                adm0_a3_es: "LSO",
                adm0_a3_cn: "LSO",
                adm0_a3_tw: "LSO",
                adm0_a3_in: "LSO",
                adm0_a3_np: "LSO",
                adm0_a3_pk: "LSO",
                adm0_a3_de: "LSO",
                adm0_a3_gb: "LSO",
                adm0_a3_br: "LSO",
                adm0_a3_il: "LSO",
                adm0_a3_ps: "LSO",
                adm0_a3_sa: "LSO",
                adm0_a3_eg: "LSO",
                adm0_a3_ma: "LSO",
                adm0_a3_pt: "LSO",
                adm0_a3_ar: "LSO",
                adm0_a3_jp: "LSO",
                adm0_a3_ko: "LSO",
                adm0_a3_vn: "LSO",
                adm0_a3_tr: "LSO",
                adm0_a3_id: "LSO",
                adm0_a3_pl: "LSO",
                adm0_a3_gr: "LSO",
                adm0_a3_it: "LSO",
                adm0_a3_nl: "LSO",
                adm0_a3_se: "LSO",
                adm0_a3_bd: "LSO",
                adm0_a3_ua: "LSO",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Southern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 7,
                long_len: 7,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 28.246639,
                label_y: -29.480158,
                ne_id: 1159321027,
                wikidataid: "Q1013",
                name_ar: "ليسوتو",
                name_bn: "লেসোথো",
                name_de: "Lesotho",
                name_en: "Lesotho",
                name_es: "Lesoto",
                name_fa: "لسوتو",
                name_fr: "Lesotho",
                name_el: "Λεσότο",
                name_he: "לסוטו",
                name_hi: "लेसोथो",
                name_hu: "Lesotho",
                name_id: "Lesotho",
                name_it: "Lesotho",
                name_ja: "レソト",
                name_ko: "레소토",
                name_nl: "Lesotho",
                name_pl: "Lesotho",
                name_pt: "Lesoto",
                name_ru: "Лесото",
                name_sv: "Lesotho",
                name_tr: "Lesotho",
                name_uk: "Лесото",
                name_ur: "لیسوتھو",
                name_vi: "Lesotho",
                name_zh: "莱索托",
                name_zht: "賴索托",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "LSO.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [28.978262566857243, -28.95559661226171],
                        [29.32516645683259, -29.257386976846256],
                        [29.018415154748027, -29.74376555757737],
                        [28.84839969250774, -30.070050551068256],
                        [28.29106937023991, -30.2262167294543],
                        [28.107204624145425, -30.54573211031495],
                        [27.749397006956485, -30.645105889612225],
                        [26.999261915807637, -29.875953871379984],
                        [27.532511020627478, -29.24271087007536],
                        [28.074338413207784, -28.851468601193588],
                        [28.541700066855498, -28.64750172293757],
                        [28.978262566857243, -28.95559661226171],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Cameroon",
                sov_a3: "CMR",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Cameroon",
                adm0_a3: "CMR",
                geou_dif: 0,
                geounit: "Cameroon",
                gu_a3: "CMR",
                su_dif: 0,
                subunit: "Cameroon",
                su_a3: "CMR",
                brk_diff: 0,
                name: "Cameroon",
                name_long: "Cameroon",
                brk_a3: "CMR",
                brk_name: "Cameroon",
                brk_group: null,
                abbrev: "Cam.",
                postal: "CM",
                formal_en: "Republic of Cameroon",
                formal_fr: null,
                name_ciawf: "Cameroon",
                note_adm0: null,
                note_brk: null,
                name_sort: "Cameroon",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 4,
                mapcolor9: 1,
                mapcolor13: 3,
                pop_est: 25876380,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 39007,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "CM",
                iso_a2: "CM",
                iso_a2_eh: "CM",
                iso_a3: "CMR",
                iso_a3_eh: "CMR",
                iso_n3: "120",
                iso_n3_eh: "120",
                un_a3: "120",
                wb_a2: "CM",
                wb_a3: "CMR",
                woe_id: 23424785,
                woe_id_eh: 23424785,
                woe_note: "Exact WOE match as country",
                adm0_iso: "CMR",
                adm0_diff: null,
                adm0_tlc: "CMR",
                adm0_a3_us: "CMR",
                adm0_a3_fr: "CMR",
                adm0_a3_ru: "CMR",
                adm0_a3_es: "CMR",
                adm0_a3_cn: "CMR",
                adm0_a3_tw: "CMR",
                adm0_a3_in: "CMR",
                adm0_a3_np: "CMR",
                adm0_a3_pk: "CMR",
                adm0_a3_de: "CMR",
                adm0_a3_gb: "CMR",
                adm0_a3_br: "CMR",
                adm0_a3_il: "CMR",
                adm0_a3_ps: "CMR",
                adm0_a3_sa: "CMR",
                adm0_a3_eg: "CMR",
                adm0_a3_ma: "CMR",
                adm0_a3_pt: "CMR",
                adm0_a3_ar: "CMR",
                adm0_a3_jp: "CMR",
                adm0_a3_ko: "CMR",
                adm0_a3_vn: "CMR",
                adm0_a3_tr: "CMR",
                adm0_a3_id: "CMR",
                adm0_a3_pl: "CMR",
                adm0_a3_gr: "CMR",
                adm0_a3_it: "CMR",
                adm0_a3_nl: "CMR",
                adm0_a3_se: "CMR",
                adm0_a3_bd: "CMR",
                adm0_a3_ua: "CMR",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 8,
                long_len: 8,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 12.473488,
                label_y: 4.585041,
                ne_id: 1159320509,
                wikidataid: "Q1009",
                name_ar: "الكاميرون",
                name_bn: "ক্যামেরুন",
                name_de: "Kamerun",
                name_en: "Cameroon",
                name_es: "Camerún",
                name_fa: "کامرون",
                name_fr: "Cameroun",
                name_el: "Καμερούν",
                name_he: "קמרון",
                name_hi: "कैमरुन",
                name_hu: "Kamerun",
                name_id: "Kamerun",
                name_it: "Camerun",
                name_ja: "カメルーン",
                name_ko: "카메룬",
                name_nl: "Kameroen",
                name_pl: "Kamerun",
                name_pt: "Camarões",
                name_ru: "Камерун",
                name_sv: "Kamerun",
                name_tr: "Kamerun",
                name_uk: "Камерун",
                name_ur: "کیمرون",
                name_vi: "Cameroon",
                name_zh: "喀麦隆",
                name_zht: "喀麥隆",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "CMR.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [14.495787387762846, 12.85939626713733],
                        [14.89336, 12.21905],
                        [14.9601518083376, 11.555574042197224],
                        [14.92356489427496, 10.891325181517473],
                        [15.467872755605242, 9.982336737503545],
                        [14.909353875394716, 9.992129421422732],
                        [14.62720055508106, 9.920919297724538],
                        [14.171466098699028, 10.021378282099931],
                        [13.954218377344006, 9.549494940626687],
                        [14.54446658698177, 8.965861314322268],
                        [14.97999555833769, 8.796104234243472],
                        [15.120865512765306, 8.382150173369439],
                        [15.436091749745742, 7.692812404811889],
                        [15.279460483469109, 7.421924546737969],
                        [14.776545444404576, 6.408498033062045],
                        [14.536560092841114, 6.22695872642069],
                        [14.459407179429348, 5.4517605656103],
                        [14.558935988023507, 5.03059764243153],
                        [14.47837243008047, 4.732605495620447],
                        [14.950953403389661, 4.210389309094921],
                        [15.036219516671252, 3.851367295747124],
                        [15.405395948964383, 3.33530060466434],
                        [15.862732374747482, 3.013537298998983],
                        [15.907380812247652, 2.557389431158612],
                        [16.012852410555354, 2.267639675298085],
                        [15.940918816805066, 1.727672634280296],
                        [15.146341993885244, 1.964014797367184],
                        [14.33781253424658, 2.227874660649491],
                        [13.075822381246752, 2.267097072759015],
                        [12.951333855855609, 2.32161570882694],
                        [12.359380323952221, 2.19281220133945],
                        [11.75166548019979, 2.326757513839993],
                        [11.276449008843713, 2.261050930180872],
                        [9.649158155972628, 2.283866075037736],
                        [9.795195753629457, 3.073404445809117],
                        [9.404366896206, 3.734526882335203],
                        [8.948115675501072, 3.904128933117136],
                        [8.744923943729418, 4.35221527751996],
                        [8.48881554529089, 4.495617377129918],
                        [8.500287713259695, 4.771982937026849],
                        [8.757532993208628, 5.479665839047911],
                        [9.233162876023044, 6.444490668153335],
                        [9.522705926154401, 6.453482367372117],
                        [10.118276808318257, 7.03876963950988],
                        [10.497375115611419, 7.055357774275564],
                        [11.058787876030351, 6.644426784690594],
                        [11.74577436691851, 6.981382961449754],
                        [11.839308709366803, 7.397042344589437],
                        [12.063946160539558, 7.799808457872302],
                        [12.218872104550599, 8.305824082874324],
                        [12.753671502339216, 8.717762762888995],
                        [12.955467970438974, 9.417771714714704],
                        [13.167599724997103, 9.640626328973411],
                        [13.308676385153918, 10.160362046748928],
                        [13.572949659894562, 10.798565985553566],
                        [14.415378859116684, 11.572368882692075],
                        [14.468192172918975, 11.904751695193411],
                        [14.577177768622533, 12.085360826053503],
                        [14.181336297266794, 12.483656927943116],
                        [14.213530714584635, 12.802035427293347],
                        [14.495787387762846, 12.85939626713733],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Gabon",
                sov_a3: "GAB",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Gabon",
                adm0_a3: "GAB",
                geou_dif: 0,
                geounit: "Gabon",
                gu_a3: "GAB",
                su_dif: 0,
                subunit: "Gabon",
                su_a3: "GAB",
                brk_diff: 0,
                name: "Gabon",
                name_long: "Gabon",
                brk_a3: "GAB",
                brk_name: "Gabon",
                brk_group: null,
                abbrev: "Gabon",
                postal: "GA",
                formal_en: "Gabonese Republic",
                formal_fr: null,
                name_ciawf: "Gabon",
                note_adm0: null,
                note_brk: null,
                name_sort: "Gabon",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 2,
                mapcolor9: 5,
                mapcolor13: 5,
                pop_est: 2172579,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 16874,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "3. Upper middle income",
                fips_10: "GB",
                iso_a2: "GA",
                iso_a2_eh: "GA",
                iso_a3: "GAB",
                iso_a3_eh: "GAB",
                iso_n3: "266",
                iso_n3_eh: "266",
                un_a3: "266",
                wb_a2: "GA",
                wb_a3: "GAB",
                woe_id: 23424822,
                woe_id_eh: 23424822,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GAB",
                adm0_diff: null,
                adm0_tlc: "GAB",
                adm0_a3_us: "GAB",
                adm0_a3_fr: "GAB",
                adm0_a3_ru: "GAB",
                adm0_a3_es: "GAB",
                adm0_a3_cn: "GAB",
                adm0_a3_tw: "GAB",
                adm0_a3_in: "GAB",
                adm0_a3_np: "GAB",
                adm0_a3_pk: "GAB",
                adm0_a3_de: "GAB",
                adm0_a3_gb: "GAB",
                adm0_a3_br: "GAB",
                adm0_a3_il: "GAB",
                adm0_a3_ps: "GAB",
                adm0_a3_sa: "GAB",
                adm0_a3_eg: "GAB",
                adm0_a3_ma: "GAB",
                adm0_a3_pt: "GAB",
                adm0_a3_ar: "GAB",
                adm0_a3_jp: "GAB",
                adm0_a3_ko: "GAB",
                adm0_a3_vn: "GAB",
                adm0_a3_tr: "GAB",
                adm0_a3_id: "GAB",
                adm0_a3_pl: "GAB",
                adm0_a3_gr: "GAB",
                adm0_a3_it: "GAB",
                adm0_a3_nl: "GAB",
                adm0_a3_se: "GAB",
                adm0_a3_bd: "GAB",
                adm0_a3_ua: "GAB",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: 3,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 11.835939,
                label_y: -0.437739,
                ne_id: 1159320693,
                wikidataid: "Q1000",
                name_ar: "الغابون",
                name_bn: "গ্যাবন",
                name_de: "Gabun",
                name_en: "Gabon",
                name_es: "Gabón",
                name_fa: "گابن",
                name_fr: "Gabon",
                name_el: "Γκαμπόν",
                name_he: "גבון",
                name_hi: "गबॉन",
                name_hu: "Gabon",
                name_id: "Gabon",
                name_it: "Gabon",
                name_ja: "ガボン",
                name_ko: "가봉",
                name_nl: "Gabon",
                name_pl: "Gabon",
                name_pt: "Gabão",
                name_ru: "Габон",
                name_sv: "Gabon",
                name_tr: "Gabon",
                name_uk: "Габон",
                name_ur: "گیبون",
                name_vi: "Gabon",
                name_zh: "加蓬",
                name_zht: "加彭",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GAB.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [11.276449008843713, 2.261050930180872],
                        [11.75166548019979, 2.326757513839993],
                        [12.359380323952221, 2.19281220133945],
                        [12.951333855855609, 2.32161570882694],
                        [13.075822381246752, 2.267097072759015],
                        [13.003113641012078, 1.83089630778332],
                        [13.282631463278818, 1.31418366129688],
                        [14.026668735417218, 1.395677395021153],
                        [14.276265903386957, 1.196929836426619],
                        [13.843320753645656, 0.038757635901149],
                        [14.316418491277744, -0.552627455247048],
                        [14.425455763413595, -1.333406670744971],
                        [14.299210239324566, -1.998275648612214],
                        [13.99240726080771, -2.4708049454891],
                        [13.109618767965628, -2.428740329603514],
                        [12.575284458067642, -1.948511244315135],
                        [12.495702752338161, -2.391688327650243],
                        [11.820963575903193, -2.514161472181982],
                        [11.478038771214303, -2.765618991714241],
                        [11.855121697648116, -3.426870619321051],
                        [11.093772820691925, -3.978826592630547],
                        [10.06613528813574, -2.969482517105682],
                        [9.40524539555497, -2.144313246269043],
                        [8.79799563969317, -1.111301364754496],
                        [8.830086704146424, -0.779073581550037],
                        [9.048419630579588, -0.459351494960217],
                        [9.29135053878369, 0.268666083167687],
                        [9.492888624721985, 1.010119533691494],
                        [9.830284051155644, 1.067893784993799],
                        [11.285078973036462, 1.057661851400013],
                        [11.276449008843713, 2.261050930180872],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Niger",
                sov_a3: "NER",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Niger",
                adm0_a3: "NER",
                geou_dif: 0,
                geounit: "Niger",
                gu_a3: "NER",
                su_dif: 0,
                subunit: "Niger",
                su_a3: "NER",
                brk_diff: 0,
                name: "Niger",
                name_long: "Niger",
                brk_a3: "NER",
                brk_name: "Niger",
                brk_group: null,
                abbrev: "Niger",
                postal: "NE",
                formal_en: "Republic of Niger",
                formal_fr: null,
                name_ciawf: "Niger",
                note_adm0: null,
                note_brk: null,
                name_sort: "Niger",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 5,
                mapcolor9: 3,
                mapcolor13: 13,
                pop_est: 23310715,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 12911,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "NG",
                iso_a2: "NE",
                iso_a2_eh: "NE",
                iso_a3: "NER",
                iso_a3_eh: "NER",
                iso_n3: "562",
                iso_n3_eh: "562",
                un_a3: "562",
                wb_a2: "NE",
                wb_a3: "NER",
                woe_id: 23424906,
                woe_id_eh: 23424906,
                woe_note: "Exact WOE match as country",
                adm0_iso: "NER",
                adm0_diff: null,
                adm0_tlc: "NER",
                adm0_a3_us: "NER",
                adm0_a3_fr: "NER",
                adm0_a3_ru: "NER",
                adm0_a3_es: "NER",
                adm0_a3_cn: "NER",
                adm0_a3_tw: "NER",
                adm0_a3_in: "NER",
                adm0_a3_np: "NER",
                adm0_a3_pk: "NER",
                adm0_a3_de: "NER",
                adm0_a3_gb: "NER",
                adm0_a3_br: "NER",
                adm0_a3_il: "NER",
                adm0_a3_ps: "NER",
                adm0_a3_sa: "NER",
                adm0_a3_eg: "NER",
                adm0_a3_ma: "NER",
                adm0_a3_pt: "NER",
                adm0_a3_ar: "NER",
                adm0_a3_jp: "NER",
                adm0_a3_ko: "NER",
                adm0_a3_vn: "NER",
                adm0_a3_tr: "NER",
                adm0_a3_id: "NER",
                adm0_a3_pl: "NER",
                adm0_a3_gr: "NER",
                adm0_a3_it: "NER",
                adm0_a3_nl: "NER",
                adm0_a3_se: "NER",
                adm0_a3_bd: "NER",
                adm0_a3_ua: "NER",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: 9.504356,
                label_y: 17.446195,
                ne_id: 1159321087,
                wikidataid: "Q1032",
                name_ar: "النيجر",
                name_bn: "নাইজার",
                name_de: "Niger",
                name_en: "Niger",
                name_es: "Níger",
                name_fa: "نیجر",
                name_fr: "Niger",
                name_el: "Νίγηρας",
                name_he: "ניז'ר",
                name_hi: "नाइजर",
                name_hu: "Niger",
                name_id: "Niger",
                name_it: "Niger",
                name_ja: "ニジェール",
                name_ko: "니제르",
                name_nl: "Niger",
                name_pl: "Niger",
                name_pt: "Níger",
                name_ru: "Нигер",
                name_sv: "Niger",
                name_tr: "Nijer",
                name_uk: "Нігер",
                name_ur: "نائجر",
                name_vi: "Niger",
                name_zh: "尼日尔",
                name_zht: "尼日",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "NER.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [14.8513, 22.862950000000126],
                        [15.096887648181848, 21.30851878507491],
                        [15.47106, 21.04845],
                        [15.487148064850146, 20.730414537025638],
                        [15.903246697664315, 20.387618923417506],
                        [15.685740594147774, 19.957180080642388],
                        [15.30044111497972, 17.927949937405003],
                        [15.247731154041844, 16.627305813050782],
                        [13.97217, 15.68437],
                        [13.540393507550789, 14.367133693901224],
                        [13.956698846094127, 13.996691189016929],
                        [13.95447675950561, 13.353448798063766],
                        [14.595781284247607, 13.33042694747786],
                        [14.495787387762846, 12.85939626713733],
                        [14.213530714584635, 12.802035427293347],
                        [14.181336297266794, 12.483656927943116],
                        [13.995352817448293, 12.461565253138303],
                        [13.318701613018561, 13.556356309457826],
                        [13.083987257548813, 13.596147162322495],
                        [12.302071160540523, 13.037189032437524],
                        [11.527803175511394, 13.328980007373588],
                        [10.989593133191534, 13.387322699431195],
                        [10.701031935273706, 13.246917832894084],
                        [10.114814487354693, 13.27725189864941],
                        [9.524928012742947, 12.851102199754479],
                        [9.014933302454438, 12.826659247280418],
                        [7.804671258178786, 13.343526923063747],
                        [7.330746697630019, 13.0980380314612],
                        [6.820441928747754, 13.115091254117518],
                        [6.445426059605637, 13.492768459522678],
                        [5.443058302440136, 13.865923977102227],
                        [4.368343540066007, 13.747481594289411],
                        [4.107945997747322, 13.531215725147831],
                        [3.967282749048849, 12.956108710171575],
                        [3.680633579125811, 12.552903347214226],
                        [3.611180454125559, 11.660167141155968],
                        [2.848643019226586, 12.23563589115821],
                        [2.49016360841793, 12.233052069543675],
                        [2.154473504249921, 11.940150051313339],
                        [2.177107781593776, 12.625017808477535],
                        [1.024103224297477, 12.851825669806574],
                        [0.993045688490071, 13.335749620003824],
                        [0.429927605805517, 13.988733018443924],
                        [0.295646396495101, 14.444234930880654],
                        [0.374892205414682, 14.92890818934613],
                        [1.015783318698482, 14.96818227788799],
                        [1.385528191746858, 15.323561102759172],
                        [2.749992709981484, 15.409524847876696],
                        [3.638258904646477, 15.568119818580456],
                        [3.723421665063483, 16.184283759012615],
                        [4.270209995143802, 16.852227484601215],
                        [4.267419467800039, 19.155265204337],
                        [5.677565952180686, 19.601206976799716],
                        [8.572893100629784, 21.565660712159143],
                        [11.999505649471613, 23.47166840259645],
                        [13.581424594790462, 23.04050608976928],
                        [14.143870883855243, 22.491288967371133],
                        [14.8513, 22.862950000000126],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Togo",
                sov_a3: "TGO",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Togo",
                adm0_a3: "TGO",
                geou_dif: 0,
                geounit: "Togo",
                gu_a3: "TGO",
                su_dif: 0,
                subunit: "Togo",
                su_a3: "TGO",
                brk_diff: 0,
                name: "Togo",
                name_long: "Togo",
                brk_a3: "TGO",
                brk_name: "Togo",
                brk_group: null,
                abbrev: "Togo",
                postal: "TG",
                formal_en: "Togolese Republic",
                formal_fr: "République Togolaise",
                name_ciawf: "Togo",
                note_adm0: null,
                note_brk: null,
                name_sort: "Togo",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 1,
                mapcolor9: 3,
                mapcolor13: 5,
                pop_est: 8082366,
                pop_rank: 13,
                pop_year: 2019,
                gdp_md: 5490,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "TO",
                iso_a2: "TG",
                iso_a2_eh: "TG",
                iso_a3: "TGO",
                iso_a3_eh: "TGO",
                iso_n3: "768",
                iso_n3_eh: "768",
                un_a3: "768",
                wb_a2: "TG",
                wb_a3: "TGO",
                woe_id: 23424965,
                woe_id_eh: 23424965,
                woe_note: "Exact WOE match as country",
                adm0_iso: "TGO",
                adm0_diff: null,
                adm0_tlc: "TGO",
                adm0_a3_us: "TGO",
                adm0_a3_fr: "TGO",
                adm0_a3_ru: "TGO",
                adm0_a3_es: "TGO",
                adm0_a3_cn: "TGO",
                adm0_a3_tw: "TGO",
                adm0_a3_in: "TGO",
                adm0_a3_np: "TGO",
                adm0_a3_pk: "TGO",
                adm0_a3_de: "TGO",
                adm0_a3_gb: "TGO",
                adm0_a3_br: "TGO",
                adm0_a3_il: "TGO",
                adm0_a3_ps: "TGO",
                adm0_a3_sa: "TGO",
                adm0_a3_eg: "TGO",
                adm0_a3_ma: "TGO",
                adm0_a3_pt: "TGO",
                adm0_a3_ar: "TGO",
                adm0_a3_jp: "TGO",
                adm0_a3_ko: "TGO",
                adm0_a3_vn: "TGO",
                adm0_a3_tr: "TGO",
                adm0_a3_id: "TGO",
                adm0_a3_pl: "TGO",
                adm0_a3_gr: "TGO",
                adm0_a3_it: "TGO",
                adm0_a3_nl: "TGO",
                adm0_a3_se: "TGO",
                adm0_a3_bd: "TGO",
                adm0_a3_ua: "TGO",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 4,
                long_len: 4,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 5,
                max_label: 10,
                label_x: 1.058113,
                label_y: 8.80722,
                ne_id: 1159321303,
                wikidataid: "Q945",
                name_ar: "توغو",
                name_bn: "টোগো",
                name_de: "Togo",
                name_en: "Togo",
                name_es: "Togo",
                name_fa: "توگو",
                name_fr: "Togo",
                name_el: "Τόγκο",
                name_he: "טוגו",
                name_hi: "टोगो",
                name_hu: "Togo",
                name_id: "Togo",
                name_it: "Togo",
                name_ja: "トーゴ",
                name_ko: "토고",
                name_nl: "Togo",
                name_pl: "Togo",
                name_pt: "Togo",
                name_ru: "Того",
                name_sv: "Togo",
                name_tr: "Togo",
                name_uk: "Того",
                name_ur: "ٹوگو",
                name_vi: "Togo",
                name_zh: "多哥",
                name_zht: "多哥",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "TGO.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [0.899563022474069, 10.99733938236426],
                        [0.772335646171484, 10.470808213742359],
                        [1.077795037448738, 10.175606594275024],
                        [1.425060662450136, 9.825395412633],
                        [1.46304284018467, 9.334624335157088],
                        [1.664477573258381, 9.12859039960938],
                        [1.618950636409238, 6.832038072126238],
                        [1.865240512712319, 6.142157701029731],
                        [1.060121697604927, 5.928837388528876],
                        [0.836931186536333, 6.279978745952149],
                        [0.570384148774849, 6.914358628767189],
                        [0.490957472342245, 7.411744289576475],
                        [0.712029249686879, 8.31246450442383],
                        [0.461191847342121, 8.677222601756014],
                        [0.365900506195885, 9.465003973829482],
                        [0.367579990245389, 10.19121287682718],
                        [-0.049784715159944, 10.706917832883931],
                        [0.023802524423701, 11.018681748900804],
                        [0.899563022474069, 10.99733938236426],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Ghana",
                sov_a3: "GHA",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Ghana",
                adm0_a3: "GHA",
                geou_dif: 0,
                geounit: "Ghana",
                gu_a3: "GHA",
                su_dif: 0,
                subunit: "Ghana",
                su_a3: "GHA",
                brk_diff: 0,
                name: "Ghana",
                name_long: "Ghana",
                brk_a3: "GHA",
                brk_name: "Ghana",
                brk_group: null,
                abbrev: "Ghana",
                postal: "GH",
                formal_en: "Republic of Ghana",
                formal_fr: null,
                name_ciawf: "Ghana",
                note_adm0: null,
                note_brk: null,
                name_sort: "Ghana",
                name_alt: null,
                mapcolor7: 5,
                mapcolor8: 3,
                mapcolor9: 1,
                mapcolor13: 4,
                pop_est: 30417856,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 66983,
                gdp_year: 2019,
                economy: "6. Developing region",
                income_grp: "4. Lower middle income",
                fips_10: "GH",
                iso_a2: "GH",
                iso_a2_eh: "GH",
                iso_a3: "GHA",
                iso_a3_eh: "GHA",
                iso_n3: "288",
                iso_n3_eh: "288",
                un_a3: "288",
                wb_a2: "GH",
                wb_a3: "GHA",
                woe_id: 23424824,
                woe_id_eh: 23424824,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GHA",
                adm0_diff: null,
                adm0_tlc: "GHA",
                adm0_a3_us: "GHA",
                adm0_a3_fr: "GHA",
                adm0_a3_ru: "GHA",
                adm0_a3_es: "GHA",
                adm0_a3_cn: "GHA",
                adm0_a3_tw: "GHA",
                adm0_a3_in: "GHA",
                adm0_a3_np: "GHA",
                adm0_a3_pk: "GHA",
                adm0_a3_de: "GHA",
                adm0_a3_gb: "GHA",
                adm0_a3_br: "GHA",
                adm0_a3_il: "GHA",
                adm0_a3_ps: "GHA",
                adm0_a3_sa: "GHA",
                adm0_a3_eg: "GHA",
                adm0_a3_ma: "GHA",
                adm0_a3_pt: "GHA",
                adm0_a3_ar: "GHA",
                adm0_a3_jp: "GHA",
                adm0_a3_ko: "GHA",
                adm0_a3_vn: "GHA",
                adm0_a3_tr: "GHA",
                adm0_a3_id: "GHA",
                adm0_a3_pl: "GHA",
                adm0_a3_gr: "GHA",
                adm0_a3_it: "GHA",
                adm0_a3_nl: "GHA",
                adm0_a3_se: "GHA",
                adm0_a3_bd: "GHA",
                adm0_a3_ua: "GHA",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.7,
                max_label: 8,
                label_x: -1.036941,
                label_y: 7.717639,
                ne_id: 1159320793,
                wikidataid: "Q117",
                name_ar: "غانا",
                name_bn: "ঘানা",
                name_de: "Ghana",
                name_en: "Ghana",
                name_es: "Ghana",
                name_fa: "غنا",
                name_fr: "Ghana",
                name_el: "Γκάνα",
                name_he: "גאנה",
                name_hi: "घाना",
                name_hu: "Ghána",
                name_id: "Ghana",
                name_it: "Ghana",
                name_ja: "ガーナ",
                name_ko: "가나",
                name_nl: "Ghana",
                name_pl: "Ghana",
                name_pt: "Gana",
                name_ru: "Гана",
                name_sv: "Ghana",
                name_tr: "Gana",
                name_uk: "Гана",
                name_ur: "گھانا",
                name_vi: "Ghana",
                name_zh: "加纳",
                name_zht: "迦納",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GHA.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [0.023802524423701, 11.018681748900804],
                        [-0.049784715159944, 10.706917832883931],
                        [0.367579990245389, 10.19121287682718],
                        [0.365900506195885, 9.465003973829482],
                        [0.461191847342121, 8.677222601756014],
                        [0.712029249686879, 8.31246450442383],
                        [0.490957472342245, 7.411744289576475],
                        [0.570384148774849, 6.914358628767189],
                        [0.836931186536333, 6.279978745952149],
                        [1.060121697604927, 5.928837388528876],
                        [-0.507637905265938, 5.343472601742675],
                        [-1.063624640294194, 5.000547797053812],
                        [-1.964706590167594, 4.710462144383371],
                        [-2.856125047202397, 4.994475816259509],
                        [-2.81070146321784, 5.38905121502411],
                        [-3.244370083011262, 6.250471503113502],
                        [-2.983584967450327, 7.379704901555513],
                        [-2.562189500326241, 8.219627793811483],
                        [-2.827496303712707, 9.642460842319778],
                        [-2.963896246747112, 10.395334784380083],
                        [-2.940409308270461, 10.962690334512558],
                        [-1.203357713211432, 11.009819240762738],
                        [-0.761575893548183, 10.936929633015055],
                        [-0.438701544588582, 11.098340969278722],
                        [0.023802524423701, 11.018681748900804],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 4,
                sovereignt: "Equatorial Guinea",
                sov_a3: "GNQ",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Equatorial Guinea",
                adm0_a3: "GNQ",
                geou_dif: 0,
                geounit: "Equatorial Guinea",
                gu_a3: "GNQ",
                su_dif: 0,
                subunit: "Equatorial Guinea",
                su_a3: "GNQ",
                brk_diff: 0,
                name: "Eq. Guinea",
                name_long: "Equatorial Guinea",
                brk_a3: "GNQ",
                brk_name: "Eq. Guinea",
                brk_group: null,
                abbrev: "Eq. G.",
                postal: "GQ",
                formal_en: "Republic of Equatorial Guinea",
                formal_fr: null,
                name_ciawf: "Equatorial Guinea",
                note_adm0: null,
                note_brk: null,
                name_sort: "Equatorial Guinea",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 1,
                mapcolor9: 4,
                mapcolor13: 8,
                pop_est: 1355986,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 11026,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "2. High income: nonOECD",
                fips_10: "EK",
                iso_a2: "GQ",
                iso_a2_eh: "GQ",
                iso_a3: "GNQ",
                iso_a3_eh: "GNQ",
                iso_n3: "226",
                iso_n3_eh: "226",
                un_a3: "226",
                wb_a2: "GQ",
                wb_a3: "GNQ",
                woe_id: 23424804,
                woe_id_eh: 23424804,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GNQ",
                adm0_diff: null,
                adm0_tlc: "GNQ",
                adm0_a3_us: "GNQ",
                adm0_a3_fr: "GNQ",
                adm0_a3_ru: "GNQ",
                adm0_a3_es: "GNQ",
                adm0_a3_cn: "GNQ",
                adm0_a3_tw: "GNQ",
                adm0_a3_in: "GNQ",
                adm0_a3_np: "GNQ",
                adm0_a3_pk: "GNQ",
                adm0_a3_de: "GNQ",
                adm0_a3_gb: "GNQ",
                adm0_a3_br: "GNQ",
                adm0_a3_il: "GNQ",
                adm0_a3_ps: "GNQ",
                adm0_a3_sa: "GNQ",
                adm0_a3_eg: "GNQ",
                adm0_a3_ma: "GNQ",
                adm0_a3_pt: "GNQ",
                adm0_a3_ar: "GNQ",
                adm0_a3_jp: "GNQ",
                adm0_a3_ko: "GNQ",
                adm0_a3_vn: "GNQ",
                adm0_a3_tr: "GNQ",
                adm0_a3_id: "GNQ",
                adm0_a3_pl: "GNQ",
                adm0_a3_gr: "GNQ",
                adm0_a3_it: "GNQ",
                adm0_a3_nl: "GNQ",
                adm0_a3_se: "GNQ",
                adm0_a3_bd: "GNQ",
                adm0_a3_ua: "GNQ",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Middle Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 10,
                long_len: 17,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 4,
                max_label: 9,
                label_x: 8.9902,
                label_y: 2.333,
                ne_id: 1159320801,
                wikidataid: "Q983",
                name_ar: "غينيا الاستوائية",
                name_bn: "বিষুবীয় গিনি",
                name_de: "Äquatorialguinea",
                name_en: "Equatorial Guinea",
                name_es: "Guinea Ecuatorial",
                name_fa: "گینه استوایی",
                name_fr: "Guinée équatoriale",
                name_el: "Ισημερινή Γουινέα",
                name_he: "גינאה המשוונית",
                name_hi: "भूमध्यरेखीय गिनी",
                name_hu: "Egyenlítői-Guinea",
                name_id: "Guinea Khatulistiwa",
                name_it: "Guinea Equatoriale",
                name_ja: "赤道ギニア",
                name_ko: "적도 기니",
                name_nl: "Equatoriaal-Guinea",
                name_pl: "Gwinea Równikowa",
                name_pt: "Guiné Equatorial",
                name_ru: "Экваториальная Гвинея",
                name_sv: "Ekvatorialguinea",
                name_tr: "Ekvator Ginesi",
                name_uk: "Екваторіальна Гвінея",
                name_ur: "استوائی گنی",
                name_vi: "Guinea Xích Đạo",
                name_zh: "赤道几内亚",
                name_zht: "赤道幾內亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GNQ.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [9.649158155972628, 2.283866075037736],
                        [11.276449008843713, 2.261050930180872],
                        [11.285078973036462, 1.057661851400013],
                        [9.830284051155644, 1.067893784993799],
                        [9.492888624721985, 1.010119533691494],
                        [9.305613234096256, 1.160911363119183],
                        [9.649158155972628, 2.283866075037736],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Guinea-Bissau",
                sov_a3: "GNB",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Guinea-Bissau",
                adm0_a3: "GNB",
                geou_dif: 0,
                geounit: "Guinea-Bissau",
                gu_a3: "GNB",
                su_dif: 0,
                subunit: "Guinea-Bissau",
                su_a3: "GNB",
                brk_diff: 0,
                name: "Guinea-Bissau",
                name_long: "Guinea-Bissau",
                brk_a3: "GNB",
                brk_name: "Guinea-Bissau",
                brk_group: null,
                abbrev: "GnB.",
                postal: "GW",
                formal_en: "Republic of Guinea-Bissau",
                formal_fr: null,
                name_ciawf: "Guinea-Bissau",
                note_adm0: null,
                note_brk: null,
                name_sort: "Guinea-Bissau",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 5,
                mapcolor9: 3,
                mapcolor13: 4,
                pop_est: 1920922,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 1339,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "PU",
                iso_a2: "GW",
                iso_a2_eh: "GW",
                iso_a3: "GNB",
                iso_a3_eh: "GNB",
                iso_n3: "624",
                iso_n3_eh: "624",
                un_a3: "624",
                wb_a2: "GW",
                wb_a3: "GNB",
                woe_id: 23424929,
                woe_id_eh: 23424929,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GNB",
                adm0_diff: null,
                adm0_tlc: "GNB",
                adm0_a3_us: "GNB",
                adm0_a3_fr: "GNB",
                adm0_a3_ru: "GNB",
                adm0_a3_es: "GNB",
                adm0_a3_cn: "GNB",
                adm0_a3_tw: "GNB",
                adm0_a3_in: "GNB",
                adm0_a3_np: "GNB",
                adm0_a3_pk: "GNB",
                adm0_a3_de: "GNB",
                adm0_a3_gb: "GNB",
                adm0_a3_br: "GNB",
                adm0_a3_il: "GNB",
                adm0_a3_ps: "GNB",
                adm0_a3_sa: "GNB",
                adm0_a3_eg: "GNB",
                adm0_a3_ma: "GNB",
                adm0_a3_pt: "GNB",
                adm0_a3_ar: "GNB",
                adm0_a3_jp: "GNB",
                adm0_a3_ko: "GNB",
                adm0_a3_vn: "GNB",
                adm0_a3_tr: "GNB",
                adm0_a3_id: "GNB",
                adm0_a3_pl: "GNB",
                adm0_a3_gr: "GNB",
                adm0_a3_it: "GNB",
                adm0_a3_nl: "GNB",
                adm0_a3_se: "GNB",
                adm0_a3_bd: "GNB",
                adm0_a3_ua: "GNB",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 13,
                long_len: 13,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 5,
                max_label: 10,
                label_x: -14.52413,
                label_y: 12.163712,
                ne_id: 1159320799,
                wikidataid: "Q1007",
                name_ar: "غينيا بيساو",
                name_bn: "গিনি-বিসাউ",
                name_de: "Guinea-Bissau",
                name_en: "Guinea-Bissau",
                name_es: "Guinea-Bisáu",
                name_fa: "گینه بیسائو",
                name_fr: "Guinée-Bissau",
                name_el: "Γουινέα-Μπισσάου",
                name_he: "גינאה ביסאו",
                name_hi: "गिनी-बिसाऊ",
                name_hu: "Bissau-Guinea",
                name_id: "Guinea-Bissau",
                name_it: "Guinea-Bissau",
                name_ja: "ギニアビサウ",
                name_ko: "기니비사우",
                name_nl: "Guinee-Bissau",
                name_pl: "Gwinea Bissau",
                name_pt: "Guiné-Bissau",
                name_ru: "Гвинея-Бисау",
                name_sv: "Guinea-Bissau",
                name_tr: "Gine-Bissau",
                name_uk: "Гвінея-Бісау",
                name_ur: "گنی بساؤ",
                name_vi: "Guiné-Bissau",
                name_zh: "几内亚比绍",
                name_zht: "幾內亞比索",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GNB.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-16.677451951554573, 12.384851589401052],
                        [-16.147716844130585, 12.547761542201187],
                        [-15.816574266004254, 12.515567124883347],
                        [-15.54847693527401, 12.628170070847347],
                        [-13.700476040084325, 12.586182969610194],
                        [-13.718743658899513, 12.24718557377551],
                        [-13.828271857142125, 12.142644151249044],
                        [-13.743160773157413, 11.811269029177412],
                        [-13.900799729863776, 11.678718980348748],
                        [-14.121406419317779, 11.677117010947697],
                        [-14.382191534878729, 11.509271958863692],
                        [-14.685687221728898, 11.527823798056488],
                        [-15.130311245168173, 11.040411688679526],
                        [-15.664180467175527, 11.458474025920795],
                        [-16.085214199273565, 11.52459402103824],
                        [-16.314786749730203, 11.80651479740655],
                        [-16.30894731288123, 11.95870189050612],
                        [-16.61383826340328, 12.170911159712702],
                        [-16.677451951554573, 12.384851589401052],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 2,
                sovereignt: "Egypt",
                sov_a3: "EGY",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Egypt",
                adm0_a3: "EGY",
                geou_dif: 0,
                geounit: "Egypt",
                gu_a3: "EGY",
                su_dif: 0,
                subunit: "Egypt",
                su_a3: "EGY",
                brk_diff: 0,
                name: "Egypt",
                name_long: "Egypt",
                brk_a3: "EGY",
                brk_name: "Egypt",
                brk_group: null,
                abbrev: "Egypt",
                postal: "EG",
                formal_en: "Arab Republic of Egypt",
                formal_fr: null,
                name_ciawf: "Egypt",
                note_adm0: null,
                note_brk: null,
                name_sort: "Egypt, Arab Rep.",
                name_alt: null,
                mapcolor7: 4,
                mapcolor8: 6,
                mapcolor9: 7,
                mapcolor13: 2,
                pop_est: 100388073,
                pop_rank: 17,
                pop_year: 2019,
                gdp_md: 303092,
                gdp_year: 2019,
                economy: "5. Emerging region: G20",
                income_grp: "4. Lower middle income",
                fips_10: "EG",
                iso_a2: "EG",
                iso_a2_eh: "EG",
                iso_a3: "EGY",
                iso_a3_eh: "EGY",
                iso_n3: "818",
                iso_n3_eh: "818",
                un_a3: "818",
                wb_a2: "EG",
                wb_a3: "EGY",
                woe_id: 23424802,
                woe_id_eh: 23424802,
                woe_note: "Exact WOE match as country",
                adm0_iso: "EGY",
                adm0_diff: null,
                adm0_tlc: "EGY",
                adm0_a3_us: "EGY",
                adm0_a3_fr: "EGY",
                adm0_a3_ru: "EGY",
                adm0_a3_es: "EGY",
                adm0_a3_cn: "EGY",
                adm0_a3_tw: "EGY",
                adm0_a3_in: "EGY",
                adm0_a3_np: "EGY",
                adm0_a3_pk: "EGY",
                adm0_a3_de: "EGY",
                adm0_a3_gb: "EGY",
                adm0_a3_br: "EGY",
                adm0_a3_il: "EGY",
                adm0_a3_ps: "EGY",
                adm0_a3_sa: "EGY",
                adm0_a3_eg: "EGY",
                adm0_a3_ma: "EGY",
                adm0_a3_pt: "EGY",
                adm0_a3_ar: "EGY",
                adm0_a3_jp: "EGY",
                adm0_a3_ko: "EGY",
                adm0_a3_vn: "EGY",
                adm0_a3_tr: "EGY",
                adm0_a3_id: "EGY",
                adm0_a3_pl: "EGY",
                adm0_a3_gr: "EGY",
                adm0_a3_it: "EGY",
                adm0_a3_nl: "EGY",
                adm0_a3_se: "EGY",
                adm0_a3_bd: "EGY",
                adm0_a3_ua: "EGY",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Northern Africa",
                region_wb: "Middle East & North Africa",
                name_len: 5,
                long_len: 5,
                abbrev_len: 5,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 1.7,
                max_label: 6.7,
                label_x: 29.445837,
                label_y: 26.186173,
                ne_id: 1159320575,
                wikidataid: "Q79",
                name_ar: "مصر",
                name_bn: "মিশর",
                name_de: "Ägypten",
                name_en: "Egypt",
                name_es: "Egipto",
                name_fa: "مصر",
                name_fr: "Égypte",
                name_el: "Αίγυπτος",
                name_he: "מצרים",
                name_hi: "मिस्र",
                name_hu: "Egyiptom",
                name_id: "Mesir",
                name_it: "Egitto",
                name_ja: "エジプト",
                name_ko: "이집트",
                name_nl: "Egypte",
                name_pl: "Egipt",
                name_pt: "Egito",
                name_ru: "Египет",
                name_sv: "Egypten",
                name_tr: "Mısır",
                name_uk: "Єгипет",
                name_ur: "مصر",
                name_vi: "Ai Cập",
                name_zh: "埃及",
                name_zht: "埃及",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "EGY.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [36.86623, 22],
                        [32.9, 22],
                        [29.02, 22],
                        [25, 22],
                        [25, 25.682499996361],
                        [25, 29.23865452953346],
                        [24.70007, 30.04419],
                        [24.95762, 30.6616],
                        [24.80287, 31.08929],
                        [25.16482, 31.56915],
                        [26.49533, 31.58568],
                        [27.45762, 31.32126],
                        [28.45048, 31.02577],
                        [28.91353, 30.87005],
                        [29.68342, 31.18686],
                        [30.09503, 31.4734],
                        [30.97693, 31.55586],
                        [31.68796, 31.4296],
                        [31.96041, 30.9336],
                        [32.19247, 31.26034],
                        [32.99392, 31.02407],
                        [33.7734, 30.96746],
                        [34.26543474464621, 31.21935730952032],
                        [34.26544, 31.21936],
                        [34.823243288783814, 29.76108076171822],
                        [34.9226, 29.50133],
                        [34.64174, 29.09942],
                        [34.42655, 28.34399],
                        [34.15451, 27.8233],
                        [33.92136, 27.6487],
                        [33.58811, 27.97136],
                        [33.13676, 28.41765],
                        [32.42323, 29.85108],
                        [32.32046, 29.76043],
                        [32.73482, 28.70523],
                        [33.34876, 27.69989],
                        [34.10455, 26.14227],
                        [34.47387, 25.59856],
                        [34.79507, 25.03375],
                        [35.69241, 23.92671],
                        [35.49372, 23.75237],
                        [35.52598, 23.10244],
                        [36.69069, 22.20485],
                        [36.86623, 22],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Mauritania",
                sov_a3: "MRT",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Mauritania",
                adm0_a3: "MRT",
                geou_dif: 0,
                geounit: "Mauritania",
                gu_a3: "MRT",
                su_dif: 0,
                subunit: "Mauritania",
                su_a3: "MRT",
                brk_diff: 0,
                name: "Mauritania",
                name_long: "Mauritania",
                brk_a3: "MRT",
                brk_name: "Mauritania",
                brk_group: null,
                abbrev: "Mrt.",
                postal: "MR",
                formal_en: "Islamic Republic of Mauritania",
                formal_fr: null,
                name_ciawf: "Mauritania",
                note_adm0: null,
                note_brk: null,
                name_sort: "Mauritania",
                name_alt: null,
                mapcolor7: 3,
                mapcolor8: 3,
                mapcolor9: 2,
                mapcolor13: 1,
                pop_est: 4525696,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 7600,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "MR",
                iso_a2: "MR",
                iso_a2_eh: "MR",
                iso_a3: "MRT",
                iso_a3_eh: "MRT",
                iso_n3: "478",
                iso_n3_eh: "478",
                un_a3: "478",
                wb_a2: "MR",
                wb_a3: "MRT",
                woe_id: 23424896,
                woe_id_eh: 23424896,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MRT",
                adm0_diff: null,
                adm0_tlc: "MRT",
                adm0_a3_us: "MRT",
                adm0_a3_fr: "MRT",
                adm0_a3_ru: "MRT",
                adm0_a3_es: "MRT",
                adm0_a3_cn: "MRT",
                adm0_a3_tw: "MRT",
                adm0_a3_in: "MRT",
                adm0_a3_np: "MRT",
                adm0_a3_pk: "MRT",
                adm0_a3_de: "MRT",
                adm0_a3_gb: "MRT",
                adm0_a3_br: "MRT",
                adm0_a3_il: "MRT",
                adm0_a3_ps: "MRT",
                adm0_a3_sa: "MRT",
                adm0_a3_eg: "MRT",
                adm0_a3_ma: "MRT",
                adm0_a3_pt: "MRT",
                adm0_a3_ar: "MRT",
                adm0_a3_jp: "MRT",
                adm0_a3_ko: "MRT",
                adm0_a3_vn: "MRT",
                adm0_a3_tr: "MRT",
                adm0_a3_id: "MRT",
                adm0_a3_pl: "MRT",
                adm0_a3_gr: "MRT",
                adm0_a3_it: "MRT",
                adm0_a3_nl: "MRT",
                adm0_a3_se: "MRT",
                adm0_a3_bd: "MRT",
                adm0_a3_ua: "MRT",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 10,
                long_len: 10,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 3,
                max_label: 8,
                label_x: -9.740299,
                label_y: 19.587062,
                ne_id: 1159321075,
                wikidataid: "Q1025",
                name_ar: "موريتانيا",
                name_bn: "মৌরিতানিয়া",
                name_de: "Mauretanien",
                name_en: "Mauritania",
                name_es: "Mauritania",
                name_fa: "موریتانی",
                name_fr: "Mauritanie",
                name_el: "Μαυριτανία",
                name_he: "מאוריטניה",
                name_hi: "मॉरीतानिया",
                name_hu: "Mauritánia",
                name_id: "Mauritania",
                name_it: "Mauritania",
                name_ja: "モーリタニア",
                name_ko: "모리타니",
                name_nl: "Mauritanië",
                name_pl: "Mauretania",
                name_pt: "Mauritânia",
                name_ru: "Мавритания",
                name_sv: "Mauretanien",
                name_tr: "Moritanya",
                name_uk: "Мавританія",
                name_ur: "موریتانیہ",
                name_vi: "Mauritanie",
                name_zh: "毛里塔尼亚",
                name_zht: "茅利塔尼亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MRT.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-17.06342322434257, 20.999752102130827],
                        [-16.845193650773993, 21.33332347257488],
                        [-12.929101935263532, 21.327070624267563],
                        [-13.118754441774712, 22.771220201096256],
                        [-12.874221564169575, 23.284832261645178],
                        [-11.937224493853321, 23.374594224536168],
                        [-11.96941891117116, 25.933352769468268],
                        [-8.6872936670174, 25.881056219988906],
                        [-8.684399786809053, 27.395744126896005],
                        [-4.923337368174231, 24.974574082941],
                        [-6.453786586930335, 24.956590684503425],
                        [-5.971128709324248, 20.64083344164763],
                        [-5.488522508150439, 16.325102037007966],
                        [-5.315277268891933, 16.20185374599184],
                        [-5.537744309908447, 15.501689764869257],
                        [-9.55023840985939, 15.486496893775438],
                        [-9.700255092802706, 15.264107367407362],
                        [-10.086846482778213, 15.330485744686271],
                        [-10.650791388379417, 15.132745876521426],
                        [-11.349095017939504, 15.411256008358478],
                        [-11.666078253617854, 15.388208319556298],
                        [-11.834207526079467, 14.79909699142894],
                        [-12.170750291380301, 14.616834214735505],
                        [-12.830658331747516, 15.303691514542948],
                        [-13.435737677453062, 16.03938304286619],
                        [-14.099521450242179, 16.304302273010492],
                        [-14.577347581428981, 16.59826365810281],
                        [-15.135737270558819, 16.587282416240782],
                        [-15.623666144258692, 16.369337063049812],
                        [-16.12069007004193, 16.455662543193384],
                        [-16.463098110407884, 16.13503611903846],
                        [-16.549707810929064, 16.673892116761962],
                        [-16.270551723688357, 17.166962795474873],
                        [-16.14634741867485, 18.108481553616656],
                        [-16.256883307347167, 19.096715806550307],
                        [-16.37765112961327, 19.593817246981985],
                        [-16.277838100641517, 20.0925206568147],
                        [-16.536323614965468, 20.567866319251493],
                        [-17.06342322434257, 20.999752102130827],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 6,
                sovereignt: "Gambia",
                sov_a3: "GMB",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Gambia",
                adm0_a3: "GMB",
                geou_dif: 0,
                geounit: "Gambia",
                gu_a3: "GMB",
                su_dif: 0,
                subunit: "Gambia",
                su_a3: "GMB",
                brk_diff: 0,
                name: "Gambia",
                name_long: "The Gambia",
                brk_a3: "GMB",
                brk_name: "Gambia",
                brk_group: null,
                abbrev: "Gambia",
                postal: "GM",
                formal_en: "Republic of the Gambia",
                formal_fr: null,
                name_ciawf: "Gambia, The",
                note_adm0: null,
                note_brk: null,
                name_sort: "Gambia, The",
                name_alt: null,
                mapcolor7: 1,
                mapcolor8: 4,
                mapcolor9: 1,
                mapcolor13: 8,
                pop_est: 2347706,
                pop_rank: 12,
                pop_year: 2019,
                gdp_md: 1826,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "GA",
                iso_a2: "GM",
                iso_a2_eh: "GM",
                iso_a3: "GMB",
                iso_a3_eh: "GMB",
                iso_n3: "270",
                iso_n3_eh: "270",
                un_a3: "270",
                wb_a2: "GM",
                wb_a3: "GMB",
                woe_id: 23424821,
                woe_id_eh: 23424821,
                woe_note: "Exact WOE match as country",
                adm0_iso: "GMB",
                adm0_diff: null,
                adm0_tlc: "GMB",
                adm0_a3_us: "GMB",
                adm0_a3_fr: "GMB",
                adm0_a3_ru: "GMB",
                adm0_a3_es: "GMB",
                adm0_a3_cn: "GMB",
                adm0_a3_tw: "GMB",
                adm0_a3_in: "GMB",
                adm0_a3_np: "GMB",
                adm0_a3_pk: "GMB",
                adm0_a3_de: "GMB",
                adm0_a3_gb: "GMB",
                adm0_a3_br: "GMB",
                adm0_a3_il: "GMB",
                adm0_a3_ps: "GMB",
                adm0_a3_sa: "GMB",
                adm0_a3_eg: "GMB",
                adm0_a3_ma: "GMB",
                adm0_a3_pt: "GMB",
                adm0_a3_ar: "GMB",
                adm0_a3_jp: "GMB",
                adm0_a3_ko: "GMB",
                adm0_a3_vn: "GMB",
                adm0_a3_tr: "GMB",
                adm0_a3_id: "GMB",
                adm0_a3_pl: "GMB",
                adm0_a3_gr: "GMB",
                adm0_a3_it: "GMB",
                adm0_a3_nl: "GMB",
                adm0_a3_se: "GMB",
                adm0_a3_bd: "GMB",
                adm0_a3_ua: "GMB",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Western Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 6,
                long_len: 10,
                abbrev_len: 6,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 5,
                max_label: 10,
                label_x: -14.998318,
                label_y: 13.641721,
                ne_id: 1159320797,
                wikidataid: "Q1005",
                name_ar: "غامبيا",
                name_bn: "গাম্বিয়া",
                name_de: "Gambia",
                name_en: "The Gambia",
                name_es: "Gambia",
                name_fa: "گامبیا",
                name_fr: "Gambie",
                name_el: "Γκάμπια",
                name_he: "גמביה",
                name_hi: "गाम्बिया",
                name_hu: "Gambia",
                name_id: "Gambia",
                name_it: "Gambia",
                name_ja: "ガンビア",
                name_ko: "감비아",
                name_nl: "Gambia",
                name_pl: "Gambia",
                name_pt: "Gâmbia",
                name_ru: "Гамбия",
                name_sv: "Gambia",
                name_tr: "Gambiya",
                name_uk: "Гамбія",
                name_ur: "گیمبیا",
                name_vi: "Gambia",
                name_zh: "冈比亚",
                name_zht: "甘比亞",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "GMB.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-16.71372880702347, 13.594958604379855],
                        [-15.624596320039942, 13.62358734786956],
                        [-15.39877031092446, 13.86036876063092],
                        [-15.08173539881382, 13.876491807505985],
                        [-14.687030808968487, 13.630356960499784],
                        [-14.376713833055788, 13.625680243377374],
                        [-14.046992356817482, 13.79406789800045],
                        [-13.844963344772408, 13.505041612192002],
                        [-14.277701788784555, 13.280585028532244],
                        [-14.712197231494628, 13.298206691943777],
                        [-15.141163295949468, 13.509511623585238],
                        [-15.511812506562933, 13.278569647672867],
                        [-15.691000535534995, 13.270353094938457],
                        [-15.931295945692211, 13.130284125211332],
                        [-16.841524624081273, 13.15139394780256],
                        [-16.71372880702347, 13.594958604379855],
                    ],
                ],
            },
        },
        {
            type: "Feature",
            properties: {
                featurecla: "Admin-0 country",
                scalerank: 1,
                labelrank: 3,
                sovereignt: "Madagascar",
                sov_a3: "MDG",
                adm0_dif: 0,
                level: 2,
                type: "Sovereign country",
                tlc: "1",
                admin: "Madagascar",
                adm0_a3: "MDG",
                geou_dif: 0,
                geounit: "Madagascar",
                gu_a3: "MDG",
                su_dif: 0,
                subunit: "Madagascar",
                su_a3: "MDG",
                brk_diff: 0,
                name: "Madagascar",
                name_long: "Madagascar",
                brk_a3: "MDG",
                brk_name: "Madagascar",
                brk_group: null,
                abbrev: "Mad.",
                postal: "MG",
                formal_en: "Republic of Madagascar",
                formal_fr: null,
                name_ciawf: "Madagascar",
                note_adm0: null,
                note_brk: null,
                name_sort: "Madagascar",
                name_alt: null,
                mapcolor7: 6,
                mapcolor8: 5,
                mapcolor9: 2,
                mapcolor13: 3,
                pop_est: 26969307,
                pop_rank: 15,
                pop_year: 2019,
                gdp_md: 14114,
                gdp_year: 2019,
                economy: "7. Least developed region",
                income_grp: "5. Low income",
                fips_10: "MA",
                iso_a2: "MG",
                iso_a2_eh: "MG",
                iso_a3: "MDG",
                iso_a3_eh: "MDG",
                iso_n3: "450",
                iso_n3_eh: "450",
                un_a3: "450",
                wb_a2: "MG",
                wb_a3: "MDG",
                woe_id: 23424883,
                woe_id_eh: 23424883,
                woe_note: "Exact WOE match as country",
                adm0_iso: "MDG",
                adm0_diff: null,
                adm0_tlc: "MDG",
                adm0_a3_us: "MDG",
                adm0_a3_fr: "MDG",
                adm0_a3_ru: "MDG",
                adm0_a3_es: "MDG",
                adm0_a3_cn: "MDG",
                adm0_a3_tw: "MDG",
                adm0_a3_in: "MDG",
                adm0_a3_np: "MDG",
                adm0_a3_pk: "MDG",
                adm0_a3_de: "MDG",
                adm0_a3_gb: "MDG",
                adm0_a3_br: "MDG",
                adm0_a3_il: "MDG",
                adm0_a3_ps: "MDG",
                adm0_a3_sa: "MDG",
                adm0_a3_eg: "MDG",
                adm0_a3_ma: "MDG",
                adm0_a3_pt: "MDG",
                adm0_a3_ar: "MDG",
                adm0_a3_jp: "MDG",
                adm0_a3_ko: "MDG",
                adm0_a3_vn: "MDG",
                adm0_a3_tr: "MDG",
                adm0_a3_id: "MDG",
                adm0_a3_pl: "MDG",
                adm0_a3_gr: "MDG",
                adm0_a3_it: "MDG",
                adm0_a3_nl: "MDG",
                adm0_a3_se: "MDG",
                adm0_a3_bd: "MDG",
                adm0_a3_ua: "MDG",
                adm0_a3_un: -99,
                adm0_a3_wb: -99,
                continent: "Africa",
                region_un: "Africa",
                subregion: "Eastern Africa",
                region_wb: "Sub-Saharan Africa",
                name_len: 10,
                long_len: 10,
                abbrev_len: 4,
                tiny: -99,
                homepart: 1,
                min_zoom: 0,
                min_label: 2.7,
                max_label: 7,
                label_x: 46.704241,
                label_y: -18.628288,
                ne_id: 1159321051,
                wikidataid: "Q1019",
                name_ar: "مدغشقر",
                name_bn: "মাদাগাস্কার",
                name_de: "Madagaskar",
                name_en: "Madagascar",
                name_es: "Madagascar",
                name_fa: "ماداگاسکار",
                name_fr: "Madagascar",
                name_el: "Μαδαγασκάρη",
                name_he: "מדגסקר",
                name_hi: "मेडागास्कर",
                name_hu: "Madagaszkár",
                name_id: "Madagaskar",
                name_it: "Madagascar",
                name_ja: "マダガスカル",
                name_ko: "마다가스카르",
                name_nl: "Madagaskar",
                name_pl: "Madagaskar",
                name_pt: "Madagáscar",
                name_ru: "Мадагаскар",
                name_sv: "Madagaskar",
                name_tr: "Madagaskar",
                name_uk: "Мадагаскар",
                name_ur: "مڈغاسکر",
                name_vi: "Madagascar",
                name_zh: "马达加斯加",
                name_zht: "馬達加斯加",
                fclass_iso: "Admin-0 country",
                tlc_diff: null,
                fclass_tlc: "Admin-0 country",
                fclass_us: null,
                fclass_fr: null,
                fclass_ru: null,
                fclass_es: null,
                fclass_cn: null,
                fclass_tw: null,
                fclass_in: null,
                fclass_np: null,
                fclass_pk: null,
                fclass_de: null,
                fclass_gb: null,
                fclass_br: null,
                fclass_il: null,
                fclass_ps: null,
                fclass_sa: null,
                fclass_eg: null,
                fclass_ma: null,
                fclass_pt: null,
                fclass_ar: null,
                fclass_jp: null,
                fclass_ko: null,
                fclass_vn: null,
                fclass_tr: null,
                fclass_id: null,
                fclass_pl: null,
                fclass_gr: null,
                fclass_it: null,
                fclass_nl: null,
                fclass_se: null,
                fclass_bd: null,
                fclass_ua: null,
                filename: "MDG.geojson",
            },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [49.54351891459575, -12.469832858940554],
                        [49.808980747279094, -12.895284925999555],
                        [50.056510857957164, -13.555761407121985],
                        [50.21743126811407, -14.758788750876796],
                        [50.47653689962553, -15.226512139550543],
                        [50.377111443895956, -15.706069431219127],
                        [50.20027469259318, -16.000263360256767],
                        [49.86060550313868, -15.414252618066918],
                        [49.67260664246086, -15.710203545802479],
                        [49.863344354050156, -16.451036879138776],
                        [49.77456424337271, -16.8750420060936],
                        [49.49861209493412, -17.106035658438273],
                        [49.435618523970305, -17.953064060134366],
                        [49.041792433473944, -19.118781019774445],
                        [48.54854088724801, -20.496888116134127],
                        [47.93074913919867, -22.391501153251085],
                        [47.54772342305131, -23.781958916928517],
                        [47.095761346226595, -24.941629733990453],
                        [46.282477654817086, -25.178462823184105],
                        [45.40950768411045, -25.60143442149309],
                        [44.833573846217554, -25.34610116953894],
                        [44.03972049334976, -24.988345228782308],
                        [43.76376834491117, -24.46067717864999],
                        [43.697777540874455, -23.574116306250602],
                        [43.345654331237625, -22.776903985283873],
                        [43.254187046081, -22.057413018484123],
                        [43.43329756040464, -21.33647511158019],
                        [43.893682895692926, -21.16330738697013],
                        [43.896370070172104, -20.830459486578174],
                        [44.37432539243966, -20.07236622485639],
                        [44.46439741392439, -19.435454196859048],
                        [44.23242190936617, -18.961994724200906],
                        [44.042976108584156, -18.33138722094317],
                        [43.96308434426091, -17.409944756746782],
                        [44.31246870298628, -16.850495700754955],
                        [44.4465173683514, -16.216219170804507],
                        [44.94493655780653, -16.1793738745804],
                        [45.50273196796499, -15.97437346767854],
                        [45.87299360533626, -15.793454278224687],
                        [46.31224327981721, -15.780018405828798],
                        [46.882182651564285, -15.210182386946315],
                        [47.70512983581236, -14.594302666891764],
                        [48.005214878131255, -14.091232598530375],
                        [47.869047479042166, -13.663868503476586],
                        [48.29382775248138, -13.784067884987486],
                        [48.84506025573879, -13.089174899958664],
                        [48.86350874206698, -12.48786793381042],
                        [49.194651320193316, -12.04055673589197],
                        [49.54351891459575, -12.469832858940554],
                    ],
                ],
            },
        },
    ],
};
