import { RetailProfileIdentifier } from '../../../../../retail/profile/identification/retail-profile-identifier.model';
import {Request} from '../../../../../urdad/services/models/request.model';
import { Item } from '../../../../../retail/checkout/models/item.model';
import { ItemIdentifier } from '../../../../../retail/checkout/identification/models/item-identifier.model';
import { PricePerVehicle } from '../../../../../retail/insurance/thirdpartyinsurance/models/price-per-vehicle.model';
import { YCMotorInsuranceDetails } from '../../../../../retail/insurance/yellowcardinsurance/models/yc-motor-insurance-details.model';
import { ScannedDocument } from '../../../../../media/scanneddocument/models/scanned-document.model';
import { ScannedDocumentIdentifier } from '../../../../../media/scanneddocument/identification/models/scanned-document-identifier.model';
import { Product } from '../../../../../retail/products/models/product.model';

export class FinaliseYCCheckoutOrderRequest extends Request {
  constructor() {
      super();
  }

  public retailProfileIdentifier: RetailProfileIdentifier;
  public updateItem: Item;
  public removeItem: ItemIdentifier;
  public pricePerVehicle: PricePerVehicle;
  public motorInsuranceDetails: YCMotorInsuranceDetails;
  public scannedDocuments: ScannedDocument[] = [];
  public product: Product;

}