import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {MarshallerService, RenderDocumentRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class RenderService {

    basePath = '/rendering';

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    renderDocument(renderDocumentRequest: RenderDocumentRequest) {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const url = this.runtimeConfigurationService.apiBaseUrl + '/rendering/renderdocument';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(renderDocumentRequest);

        return this.httpClient.post<any>(url, jsonRequest, options).toPromise();

    }

}
