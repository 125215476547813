import {Criteria} from "../../../criteria/models/criteria.model";
import {OrderIdentifier} from '../../checkout/identification/models/order-identifier.model';

export class MotorInsurancePolicyOrderCertificateCriteria extends Criteria{
    constructor(){
        super();
        this['@class']="za.co.magnabc.tpi.retail.insurance.MotorInsurancePolicyOrderCertificateCriteria"
    }

    public orderIdentifier: OrderIdentifier

}
