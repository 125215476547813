import {Location, LocationStrategy} from "@angular/common";
import {Component, OnInit, ViewChild} from '@angular/core';
import {
    CheckoutProposalRequest,
    CheckoutProposalResponse,
    LegalEntity,
    NationalIdentityNumber,
    PassportNumber,
    ProposalRecordStatus,
    ProposalRecordStatusLegalEntityCriteria,
    ProvideProposalInformationRequest,
    ProvideProposalInformationResponse,
    RegistrationNumber,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
} from "@magnabc/tpi";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {IStepComponent} from "../../../../view.components/shared/guards/step-guard";
import {QueryJudicialPersonComponent} from "../../../../view.components/entity/query-judicial-person/query-judicial-person.component";
import {QueryNaturalPersonComponent} from "../../../../view.components/entity/query-natural-person/query-natural-person.component";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {InsurancePolicyQueryComponent} from "../../../../view.components/retail/insurance/policy/query/query.component";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {ProposalInformationProviderService} from "../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service";
import {AuthenticationService} from "../../../../app.services/managers/authentication/authentication.service";
import {RaygunErrorHandler} from "../../../../common/utils/utils.raygun";
import {ProposalCheckoutManagerService} from "../../../../http.services/retail/insurance/comprehensiveinsurance/proposalcheckoutmanager/proposal-checkout-manager.service";
import {Router} from "@angular/router";
import {environment} from '../../../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ProposalEntry} from '../../../../view.components/retail/checkout/proposal-list/proposal-list.component'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-query-proposal',
    templateUrl: './query-proposal-page.component.html',
    styleUrls: ['./query-proposal-page.component.scss']
})
export class QueryProposalPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps = [];
    currentStep = 0;
    dropdownValue: string;

    queryBy = 1; //1 - person, 2 - judicial

    legalEntity: LegalEntity;
    vehicleMap: { [id: string]: boolean } = {};
    queryCount = 0;
    totalQueryCount = 0;

    noResults = false;
    activeToast: ActiveToast<any>;
    proposalList: ProposalEntry[] = [];
    provideProposalInformationRequest : ProvideProposalInformationRequest;
    pageNumber = 0;
    pageSize = 5;
    totalElements = 0;

    @ViewChild(QueryNaturalPersonComponent) naturalPersonQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(QueryJudicialPersonComponent) queryJudicialComponent: QueryJudicialPersonComponent;
    @ViewChild(InsurancePolicyQueryComponent) insurancePolicyQueryComponent: InsurancePolicyQueryComponent;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private toastr: ErrorToastService,
                private router: Router,
                private location: Location,
                private unMarshallerService: UnMarshallerService,
                private locationStrategy: LocationStrategy,
                private proposalInformationProviderService: ProposalInformationProviderService,
                private authenticationService: AuthenticationService,
                private proposalCheckoutManagerService: ProposalCheckoutManagerService,
                private runtimeConfigurationService: RuntimeConfigurationService
    ) {

        super(translate, translateParser);

        title.setTitle(`Query Proposal - Console - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.translate.get("PROPOSAL.QUERY").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-search", borderColor: "#1592E6" });
        });
        this.translate.get("PROPOSAL.RESULTS").subscribe((response) => {
            this.steps.push({ id: 1, name: response, icon: "fa-bars", borderColor: "#3f5baa" });
        });
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {

    }

    onExists(event): void {
        this.noResults = !event;
    }

    onQueryType(type): boolean {

        this.noResults = false;
        this.queryBy = type;

        return false;
    }

    getDropDownValue(searchType: string): void {
        this.dropdownValue = searchType;
    }

    onSubmit(event): void {
        if (this.currentStep === 0) {

            var country;
            var identifierName;
            var identifier;

            if (this.naturalPersonQueryComponent) {
                identifierName = this.naturalPersonQueryComponent.type.value.name;
                identifier = this.naturalPersonQueryComponent.identifier.value;
                country = this.naturalPersonQueryComponent.countryComponent.country.alphaTwo;
            } else if (this.queryJudicialComponent) {
                identifierName = this.queryJudicialComponent.type.value.name;
                identifier = this.queryJudicialComponent.identifier.value;
                country = this.queryJudicialComponent.countryComponent.country.alphaTwo;
            }
            this.makeProvideProposalInformationRequest(identifierName, identifier, country);
        } else {
            this.currentStep = 0;
        }
    }

    makeProvideProposalInformationRequest(identifierName: string, identifierValue: string, identifierCountry?: string){

        var proposalRecordStatusLegalEntityCriteria = new ProposalRecordStatusLegalEntityCriteria();
        this.provideProposalInformationRequest = new ProvideProposalInformationRequest();

        proposalRecordStatusLegalEntityCriteria.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
            this.authenticationService.getLinkedToJudicialPerson().name,
            Utils.getLegalEntityDescriptionIdentifier(
                this.authenticationService.getLinkedToJudicialPerson()
                    .legalEntityIdentifiers
            )
        );

        proposalRecordStatusLegalEntityCriteria.status = ProposalRecordStatus.OPEN;

        switch(identifierName){

            case 'National Identity Number': {
                var nationalIdentity = new NationalIdentityNumber();

                nationalIdentity.number = identifierValue.toUpperCase();
                nationalIdentity.countryOfIssue = identifierCountry;
                proposalRecordStatusLegalEntityCriteria.legalEntityIdentifier = nationalIdentity;
                break;
            }
            case 'Passport Number': {
                var passportNumber = new PassportNumber();

                passportNumber.number = identifierValue.toUpperCase();
                passportNumber.countryOfIssue = identifierCountry;
                proposalRecordStatusLegalEntityCriteria.legalEntityIdentifier = passportNumber;
                break;
            }
            case 'Business Registration Number': {
                var registrationNumber = new RegistrationNumber(identifierValue.toUpperCase(), identifierCountry);

                proposalRecordStatusLegalEntityCriteria.legalEntityIdentifier = registrationNumber;
                break;
            }
            default: {
                break;
            }
        }

        this.provideProposalInformationRequest.criteria = proposalRecordStatusLegalEntityCriteria;
        this.provideProposalInformationRequest.batchNumber = this.pageNumber + 1;
        this.provideProposalInformationRequest.batchSize = 5;

        this.getProposalInformation(this.provideProposalInformationRequest);
    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideProposalInformationRequest.batchNumber = this.pageNumber + 1;
        this.getProposalInformation(this.provideProposalInformationRequest);
    }

    onStep(event): void {
        this.currentStep = event.id;
    }

    onNext(event): void {
    }

    onBack(event): void {
        this.proposalList = [];
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        } else {
            this.location.back();
        }
    }

    toast() {

        this.translate.get('TOAST.NO_INSURANCE_POLICY').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
        });

    }

    getProposalInformation(provideProposalInformationRequest: ProvideProposalInformationRequest) {
        this.noResults = false;
        this.proposalInformationProviderService.provideProposalInformation(provideProposalInformationRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideProposalInformationResponse) as ProvideProposalInformationResponse);

                if (response.proposalSummary.length > 0) {
                    this.proposalList = [];

                    for(var proposalSummary of response.proposalSummary){
                        this.proposalList = [
                            ...this.proposalList,
                            {
                                proposalId: proposalSummary.proposalId,
                                proposalScore: 0,
                                proposalDate: proposalSummary.captureDateTime
                            },
                        ];
                    }
                    this.totalElements = response.totalNumberOfResults;

                    if(this.currentStep == 0){
                        this.currentStep++;
                    }
                }
                else{
                    this.noResults = true;
                }
            }

        }).catch(error=>{
            this.toastr.errorToast(error);
        });
    }

    checkoutProposal(proposalValue) {
        var checkoutProposalRequest = new CheckoutProposalRequest();

        checkoutProposalRequest.assessor = this.authenticationService.getLegalEntityDescriptionIdentifier().tpiIdentifier;
        checkoutProposalRequest.proposalId = proposalValue;

        this.proposalList = [];

        checkoutProposalRequest.underwriterIdentifier =  new UnderwriterLegalEntityIdentifier(
            this.authenticationService.getLinkedToJudicialPerson().name,
            Utils.getLegalEntityDescriptionIdentifier(
                this.authenticationService.getLinkedToJudicialPerson()
                    .legalEntityIdentifiers
            )
        );

        return new Promise<boolean>(
            (resolve, reject) => {
                this.proposalCheckoutManagerService
                    .checkoutProposal(checkoutProposalRequest)
                    .toPromise()
                    .then((httpResponse) => {
                        if (httpResponse && httpResponse.body) {
                            const response = this.unMarshallerService.unMarshallFromJson(
                                httpResponse.body,
                                CheckoutProposalResponse
                            ) as CheckoutProposalResponse;

                            for(var entryId of response.proposalGroupEntry.proposalIds){
                                this.proposalList = [
                                    ...this.proposalList,
                                    {
                                        proposalId: entryId,
                                        proposalScore: 0,
                                        proposalDate: null
                                    },
                                ];
                            }

                            this.router.navigate(["/proposal-list/open/" + checkoutProposalRequest.quantity]);

                            resolve(true);
                        } else {
                            console.log("not done");
                            this.router.navigate(["/proposal-list/open/" + checkoutProposalRequest.quantity]);

                        }
                    })
                    .catch((error) => {
                        RaygunErrorHandler.sendError(error);
                        console.log("not done");
                        this.router.navigate(["/proposal-list/open/" + checkoutProposalRequest.quantity]);

                    });

            }
        );
    }
}
