import {Request} from "../../../../../../urdad/services/models/request.model";
import {LegalEntity} from "../../../../../models/legal-entity.model";

export class FinaliseInsertLegalEntityRequest extends Request {
    constructor() {
        super();
    }

    public legalEntities: LegalEntity[] = [];
}
