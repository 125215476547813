import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {FinaliseCheckoutInformationRequest, FinaliseCheckoutInformationResponse} from "@magnabc/tpi"
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class CountryCheckoutFinalisationManagerService {

    basePath = '/malawi/retail/checkout/countrycheckoutfinalisationmanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseCheckoutInformation(finaliseCheckoutInformationRequest: FinaliseCheckoutInformationRequest): Observable<FinaliseCheckoutInformationResponse | any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecheckoutinformation';
        return this.http.post(path, finaliseCheckoutInformationRequest, this.options);
    }

    // finaliseNewJudicialPersonCheckoutInformation(finaliseNewJudicialPersonCheckoutInformationRequest: FinaliseNewJudicialPersonCheckoutInformationRequest): Observable<FinaliseNewJudicialPersonCheckoutInformationResponse | any> {
    //     const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisenewjudicialpersoncheckoutinformation';
    //     return this.http.post(path, finaliseNewJudicialPersonCheckoutInformationRequest, this.options);
    // }
    //
    // finaliseNewNaturalPersonCheckoutInformation(finaliseNewNaturalPersonCheckoutInformationRequest: FinaliseNewNaturalPersonCheckoutInformationRequest): Observable<FinaliseNewNaturalPersonCheckoutInformationResponse | any> {
    //     const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisenewnaturalpersoncheckoutinformation';
    //     return this.http.post(path, finaliseNewNaturalPersonCheckoutInformationRequest, this.options);
    // }
    //
    // finaliseUpdatedJudicialPersonCheckoutInformation(finaliseUpdatedJudicialPersonCheckoutInformationRequest: FinaliseUpdatedJudicialPersonCheckoutInformationRequest): Observable<FinaliseUpdatedJudicialPersonCheckoutInformationResponse | any> {
    //     const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdatedjudicialpersoncheckoutinformation';
    //     return this.http.post(path, finaliseUpdatedJudicialPersonCheckoutInformationRequest, this.options);
    // }
    //
    // finaliseUpdatedNaturalPersonCheckoutInformation(finaliseUpdatedNaturalPersonCheckoutInformationRequest: FinaliseUpdatedNaturalPersonCheckoutInformationRequest): Observable<FinaliseUpdatedNaturalPersonCheckoutInformationResponse | any> {
    //     const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdatednaturalpersoncheckoutinformation';
    //     return this.http.post(path, finaliseUpdatedNaturalPersonCheckoutInformationRequest, this.options);
    // }

}
