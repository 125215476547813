import {Request} from '../../../../../urdad/services/models/request.model';

export class PayfastSignatureRequest extends Request{

  constructor(){
      super();
  }

  public payload: any;

}