import {Subscription} from './subscription.model';

export class SubscriptionOwner {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.SubscriptionOwner";
    }

    public name: string;
    public surname: string;
    public subscriptions: Subscription[] = [];
}
