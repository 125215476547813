import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConfigurationService } from '../../../../app.services/common/runtime-configuration/runtime-configuration.service';
import { FinaliseImportFleetRequest } from '@magnabc/tpi';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImportFleetFinalisationManagerService {
    private readonly options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };
    private readonly basePath = '/retail/profile/importfleetfinalisationmanager';

    constructor(private http: HttpClient,
        private runtimeConfigurationService: RuntimeConfigurationService) { }

    finaliseImportFleet(finaliseImportFleetRequest: FinaliseImportFleetRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseimportfleet';
        return this.http.post(path, finaliseImportFleetRequest, this.options);
    }
}
