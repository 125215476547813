import { VehicleCertificate } from "./vehicle-certificate.model";

export class CertificateOfFitness extends VehicleCertificate{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.EmailAddress";
    }

    public effectiveFrom: Date;
	public expiryDate: Date;
	public certificateNumber: string;

}