import { Credentials } from "./credentials.model";

export class PlainTextUsernameAndPassword extends Credentials{

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.security.credentials.PlainTextUsernameAndPassword";
  }

    public username: string;
    public password: string;
}
