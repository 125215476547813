import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortCountryName'
})
export class ShortCountryNamePipe implements PipeTransform {

    transform(value: string): string {
        if (value === 'Democratic Republic of the Congo') {
            return 'DRC'
        } else {
            return value;
        }
    }
}
