import {Injectable} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs/internal/Observable";
import {HttpClient} from "@angular/common/http";
import { ProvideServiceIdentifiersRequest } from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ServiceIdentifierProviderService {

    basePath = '/security/authorisation/identification/serviceidentifierprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideServiceIdentifiers(provideServiceIdentifiersRequest: ProvideServiceIdentifiersRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideserviceidentifiers';
        return this.http.post(path, provideServiceIdentifiersRequest, this.options);
    }
}
