import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormComponent} from "../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormGroupDirective} from "@angular/forms";
import {AddressComponent} from "../../shared/address/address.component";
import {ContactNumberComponent} from "../../shared/contactnumber/contact_number.component";
import {EmailAddressComponent} from "../../shared/emailaddress/email_address.component";
import {ContactPersonComponent} from "../../shared/contact-person/contact-person.component";
import {environment} from "../../../../environments/environment";
import {BasicPhysicalAddress, BasicPostalAddress, ContactNumber, ContactPerson, Country, CountryIdentifier, EmailAddress, LegalEntity} from '@magnabc/tpi';
import {Utils} from "../../shared/Utils/Utils";
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service';
import { SystemParameterProviderService } from "../../../http.services/system/system-parameter-provider/system-parameter-provider.service";
import {QuickTransactManagerService} from '../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;
@Component({
    selector: 'app-entity-legal-entity',
    templateUrl: './legal-entity.component.html',
    styleUrls: ['./legal-entity.component.scss']
})
export class LegalEntityComponent extends FormComponent implements OnInit {

    @Input() legalEntity: LegalEntity;
    @Input() editing = false;
    @Input() quickTransact = false;
    @Input() changeNumber = true;
    @Input() primaryAddressLabel = "Residential";
    @Input() primaryAddressTooltip = "HELP.SIGN_UP_RESIDENTIAL";
    @Input() secondaryAddressTooltip = "HELP.SIGN_UP_POSTAL";
    @Input() selectedCountry: Country;
    @Output() onLegalEntity = new EventEmitter<LegalEntity>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onChangeMobile = new EventEmitter<void>();

    utils = Utils;

    @ViewChild('form') form: FormGroupDirective;

    @ViewChild('contactPerson') contactPerson: ContactPersonComponent;
    @ViewChild('contactNumber') contactNumber: ContactNumberComponent;
    @ViewChild('emailAddress') emailAddress: EmailAddressComponent;
    @ViewChild('addressResidential') addressResidential: AddressComponent;
    @ViewChild('addressPostal') addressPostal: AddressComponent;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                public quickstartService: QuickTransactManagerService,
                public authenticationService: AuthenticationService, private systemParameterService : SystemParameterProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
    }

    ngOnInit(): void {

        if (this.quickstartService.retailProfile) {
            this.quickTransact = true
        }

        if(!this.authenticationService.isBackOfficeUser() || this.quickTransact){
            if (this.legalEntity.addresses.length === 1) {
                this.legalEntity.addresses.push(new BasicPostalAddress());
            } else if (this.legalEntity.addresses.length === 0) {
                const basicPhysicalAddress = new BasicPhysicalAddress();
                basicPhysicalAddress.addressIdentifier = "BasicPhysicalAddress";
                this.legalEntity.addresses.push(basicPhysicalAddress);
                const basicPostalAddress = new BasicPostalAddress();
                basicPostalAddress.addressIdentifier = "BasicPostalAddress";
                this.legalEntity.addresses.push(basicPostalAddress);
            }

            this.legalEntity.addresses.sort((a, b) => {
                if (a['@class'] === 'za.co.magnabc.tpi.location.geographic.BasicPhysicalAddress') {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        if (!Utils.isJudicialPerson(this.legalEntity)) {

            if (!this.legalEntity.contactNumbers) {
                this.legalEntity.contactNumbers = [];
                const contactNumber = new ContactNumber();
                contactNumber.country = this.runtimeConfigurationService.defaultCountryDialingCode;
                contactNumber.contactNumberType = "Cell";
                this.legalEntity.contactNumbers.push(contactNumber);
            }
            if (!this.legalEntity.emailAddresses) {
                this.legalEntity.emailAddresses = [];
                const emailAddress = new EmailAddress();
                emailAddress.emailAddressType = "primary";
                this.legalEntity.emailAddresses.push(emailAddress);
            }

        }

    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    onContactPerson(contactPerson: ContactPerson): void {

        this.legalEntity.contactPersons[0] = contactPerson;

        if (this.legalEntity.contactNumbers && !this.legalEntity.contactNumbers[0]) {
            this.legalEntity.contactNumbers[0] = contactPerson.contactNumbers[0];
        }
    }

    onAddressResidential(address): void {
        this.legalEntity.addresses[0] = address;
    }

    onAddressPostal(address): void {
        this.legalEntity.addresses[1] = address;
    }

    onContactNumber(contactNumber): void {
        this.legalEntity.contactNumbers[0] = contactNumber;
    }

    onCountryChange(country): void {
        this.checkCountry(country);
    }

    onEmailAddress(emailAddress): void {
        this.legalEntity.emailAddresses[0] = emailAddress;
    }

    onValidContactPerson(event): void {
        if (Utils.isJudicialPerson(this.legalEntity)) {
            this.addressResidential.submit();
        } else {
            this.contactNumber.submit();
        }
    }

    onValidAddressResidential(event): void {
      // this.addressPostal.submit();
      this.onSubmitValidUpdatedChanges(event)
    }

    onSubmitValidUpdatedChanges(event): void {
        if(Utils.isNaturalPerson(this.legalEntity)) {
            if(this.contactPerson && !this.contactPerson.personCheckOne) {
                this.legalEntity.contactPersons = [];
            }
        }
        else if (!Utils.isJudicialPerson(this.legalEntity)) {
            this.legalEntity.contactPersons = [];
        }

        if (!this.legalEntity.emailAddresses || !this.legalEntity.emailAddresses[0] || !this.legalEntity.emailAddresses[0].address || this.legalEntity.emailAddresses[0].address === "") {
            this.legalEntity.emailAddresses = [];
        }

        if (this.legalEntity.addresses[1] && !(this.legalEntity.addresses[1] as any).lineOne) {
            this.legalEntity.addresses.splice(1, 1);
        }

        this.onLegalEntity.emit(this.legalEntity);
        this.onValid.emit();
    }

    onValidContactNumber(event): void {
        this.emailAddress.submit();
    }

    onValidEmailAddress(event): void {
        if(this.authenticationService.isBackOfficeUser() && !this.quickTransact){
            this.onSubmitValidUpdatedChanges(event);
        }
        else{
            this.addressResidential.submit();
        }
    }

    submit(): void {
        if (Utils.isJudicialPerson(this.legalEntity) || Utils.isNaturalPerson(this.legalEntity)
        && (!this.quickTransact && !this.authenticationService.isBackOfficeUser())
        || (this.quickTransact && this.authenticationService.isBackOfficeUser()))
        {
            this.contactPerson.submit();
        } else {
            this.contactNumber.submit();
        }
    }

    onSameAsResidential(event): void {
        this.addressPostal.setAddress(this.addressResidential.getAddress());
    }

    onChangeNumber(event): void {
        this.onChangeMobile.emit();
    }

    isDirty() {
        return (this.contactPerson && this.contactPerson.isDirty()) || (this.contactNumber && this.contactNumber.isDirty()) ||
            (this.emailAddress && this.emailAddress.isDirty()) || (this.addressResidential && this.addressResidential.isDirty()) ||
            (this.addressPostal && this.addressPostal.isDirty());
    }

    private checkCountry(country: CountryIdentifier) {

        if (!this.addressResidential.isDirty()) {
            const temp = this.legalEntity.addresses[0];
            (temp as any).countryIdentifier = country;
            this.legalEntity.addresses[0] = null;
            setTimeout(() => {
                this.legalEntity.addresses[0] = temp;
            }, 0);
        }

        if (!this.addressPostal.isDirty()) {
            const temp = this.legalEntity.addresses[1];
            (temp as any).countryIdentifier = country;
            this.legalEntity.addresses[1] = null;
            setTimeout(() => {
                this.legalEntity.addresses[1] = temp;
            }, 0);
        }

    }

    canEditEmail(): boolean{
        return this.quickTransact || !this.authenticationService.isBackOfficeUser();
    }
}
