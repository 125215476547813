import {Request} from '../../../../../../../../urdad/services/models/request.model';
import { Card } from '../../../../../../models/card.model';
import {Money} from "../../../../../../../../financial/models/money.model";
import {PaymentReference} from "../../../../../../../../financial/models/payment-reference.model";
import {RetailProfileIdentifier} from "../../../../../../../profile/identification/retail-profile-identifier.model";

export class ProcessCardPaymentRequest extends Request{
    constructor(){
        super();
    }

    public amount: Money;
    public redirectUrl: string;
    public backUrl: string;
    public declineUrl: string;
    public paymentReference: PaymentReference;
    public card: Card;
    public retailProfileIdentifier: RetailProfileIdentifier;
}
