import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'accounting-transaction-page',
  templateUrl: './accounting-transaction-page.component.html',
  styleUrls: ['./accounting-transaction-page.component.scss']
})
export class AccountingTransactionPageComponent {

  constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
      title.setTitle(`Accounts Report - Console - ${runtimeConfigurationService.title}`);
  }

}
