import { DepositBankTransfer } from "./models/deposit-bank-transfer.model";

export * from './identification/models/deposit-method-bank-transfer-identifier.model';
export * from './identification/models/deposit-method-identifier.model';

export { DepositBankTransfer as DepositBankTransfer } from './models/deposit-bank-transfer.model';
export * from './models/deposit-method.model';
export * from './models/deposit-method-country-criteria.model';
export * from './models/single-deposit-method-criteria.model';

export * from './services/depositmethodprovider/providedepositmethod/request/provide-deposit-method-request.model';
export * from './services/depositmethodprovider/providedepositmethod/response/provide-deposit-method-response.model';
