import {CountryIdentifier} from '../../../../location/geographic/identification/models/country-identifier.model';
import { CountryStartDates } from './country-start-dates.model';

export class CountryStartDateConfiguration {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.CountryStartDateConfiguration";
    } 

    public startDates : CountryStartDates [] = [];
    public countryIdentifier: CountryIdentifier;      
}