import {LegalEntityIdentifier} from '../../../entity/identification/models/legal-entity-identifier.model';
import {CountryIdentifier} from '../../../location/geographic/identification/models/country-identifier.model';
import {Image} from '../../../media/models/image.model';
import { UnderwriterParameters } from '../setup/models/underwriter-parameters.model';

export class Underwriter {
    
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.Underwriter"; 
    }
    
    financialRegistrationNumber : string;
    hashId : string;
    id : string;
    logo : Image;
    redirectUrl : string;
    name : string;
    legalEntityIdentifier : LegalEntityIdentifier;
    countryIdentifier : CountryIdentifier
    vatNumber : string;
    documentType : DocumentType;
    underwriterParameters : UnderwriterParameters[] = [];
}
