export class YellowCardProductPricingComponentModel {
    pricing: YellowCardProductPricingComponentScheduleModel[];
}

export interface YellowCardProductPricingComponentScheduleModel {
    categoryName: string;
    categoryId: string;
    coveredCountries: {
        name: string;
        price: number;
        id: string;
    }[];
}
