import {Injectable} from "@angular/core";
import {CanDeactivate} from "@angular/router";
import {FormComponent} from "../form/form.component";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<FormComponent> {
    canDeactivate(component: FormComponent): boolean {

        if (!component.canDeactivate()) {
            if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
