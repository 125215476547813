import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'
import {
    ProductInformationPriceScheduleComponent
} from '../product-information-price-schedule/product-information-price-schedule.component'
import * as moment from 'moment/moment'
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'app-product-date-selection',
    templateUrl: './product-date-selection.component.html',
    styleUrls: ['./product-date-selection.component.scss']
})
export class ProductDateSelectionComponent implements OnInit {
    @Input() isYCProduct: boolean = false;

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {
        if (this.isYCProduct) {
            this.insuranceProductService.updateDatesForYCProduct();
            this.calculatePricing();
        }
    }

    onStartDateSelected(event) {
        this.insuranceProductService.startDate = event;
        if (this.isYCProduct) {
            this.insuranceProductService.updateDatesForYCProduct();
            this.calculatePricing();
        } else {
            this.insuranceProductService.updateCalendars();
        }
        this.calculatePricing();
    }

    onCloseEndDate(_) {
        this.onToggleEndDate()
    }

    onCloseStartDate(_) {
        this.onToggleStartDate()
    }

    onEndDateSelected(event) {
        this.insuranceProductService.endDate = moment(event).endOf('day');
        this.calculatePricing();
    }

    onToggleStartDate() {
        this.insuranceProductService.showStartDate = !this.insuranceProductService.showStartDate
        ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
    }

    onToggleEndDate() {
        this.insuranceProductService.showEndDate = !this.insuranceProductService.showEndDate
        if (this.insuranceProductService.showEndDate) {
            ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
        }
    }

    private calculatePricing(): void {
        if(this.isYCProduct){
            this.insuranceProductService.calculateRealTimeYCPrice();
        }else{
            this.insuranceProductService.calculatePrice();
        }
    }
}
