import { InsuranceCategory } from "../../models/insurance-category.model";

export class CoveredCountries {
  constructor() {
    this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.CoveredCountries";
  }

  insuranceCategory: InsuranceCategory;
  underwriterCountry: string;
  coveredCountry: string;

}
