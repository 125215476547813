import {Response} from "../../../../../urdad/urdad_api";
import {ConceptualDocument} from '../../../../models/conceptual-document.model';

export class ProvideConceptualDocumentResponse extends Response {

  constructor() {
    super();
  }

  public conceptualDocuments: ConceptualDocument[] = [];
}
