import {ActivatedRoute, Router} from '@angular/router'
import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    ComprehensiveInsuranceProduct,
    CountryDescriptionIdentifier,
    CountryIdentifier,
    DurationType,
    Excess,
    FinaliseUpdateInsuranceProductRequest,
    InsuranceCategory,
    Limit,
    PriceSchedule,
    Product,
    ProductNumberIdentifier,
    ProvideUnderwriterInformationRequest,
    ProvideUnderwriterInformationResponse,
    ThirdPartyInsuranceProduct,
    TpiIdentifier,
    Underwriter,
    UnderwriterIdentifier,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    YCInsuranceProduct,
    YCPriceSchedule
} from "@magnabc/tpi"
import {ProductExcessTypePipe} from '../../../../../view.components/shared/pipes/product-excess-type.pipe'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {AgentManagerService} from '../../../../../app.services/managers/agent-manager/agent-manager.service'
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service'
import {ActiveToast} from 'ngx-toastr'
import {environment} from '../../../../../../environments/environment'
import {Title} from '@angular/platform-browser'
import {Location} from '@angular/common'
import {ProductTypePipe} from '../../../../../view.components/shared/pipes/product-type.pipe'
import {StringUtils} from "../../../../../common/utils/utils.string"
import {
    YellowCardProductPricingComponentModel,
    YellowCardProductPricingComponentScheduleModel
} from '../../../../../view.components/retail/insurance/insurance-product-manager/yellow-card-product-view/yellow-card-product-pricing/yellow-card-product-pricing-component.model'
import {Utils} from '../../../../../view.components/shared/Utils/Utils'
import {
    InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {
    InsuranceProductInformationProviderService
} from '../../../../../http.services/retail/insurance/insurance-product-information-provider/insurance-product-information-provider.service'
import {
    UnderwriterInformationProviderService
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
    UnderwriterManagerService
} from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    InsuranceProductFinalisationManagerService
} from '../../../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview-page.component.html',
  styleUrls: ['./product-overview-page.component.scss']
})
export class ProductOverviewPageComponent extends FormComponent implements OnInit {

  progress = 0;
  productExcessTypePipe = ProductExcessTypePipe;
  productTypePipe = ProductTypePipe;
  durationType = DurationType;
  activeToast: ActiveToast<any>;

  imageUrl = "/assets/images/index/partners/partner_libertas.png";
  productNumber: string;
  yellowCard = InsuranceCategory.YELLOWCARD;
  currency: string = '';
  private editing = false;
  underwriterMap: { [id: string]: Underwriter } = {}

  constructor(private translate: TranslateService,
              private translateParser: TranslateParser,
              private title: Title,
              private router: Router,
              private location: Location,
              private agentService: AgentManagerService,
              private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
              public insuranceProductManagerService: InsuranceProductManagerService,
              private insuranceProductInformationProviderService: InsuranceProductInformationProviderService,
              private authenticationService: AuthenticationService,
              private underwriterInformationProviderService: UnderwriterInformationProviderService,
              private underwriterManagerService: UnderwriterManagerService,
              private toastr: ErrorToastService,
              private unMarshallerService: UnMarshallerService,
              private errorToastService: ErrorToastService,
              private activatedRoute: ActivatedRoute,
              private runtimeConfigurationService: RuntimeConfigurationService) {
    super(translate, translateParser)

    title.setTitle(`Product Overview - Console - ${this.runtimeConfigurationService.title}`);


  }

  ngOnInit() {
    this.currency = this.insuranceProductManagerService.product.baseCurrency;
    this.activatedRoute.params.subscribe((data) => {
      this.productNumber = data['number'];
      this.editing = this.productNumber !== 'new';
    });
    this.updateValidation();
    this.provideUnderwriter();
    if (this.editing) {
      this.insuranceProductManagerService.insuranceCategory = this.insuranceCategory;
      this.insuranceProductManagerService.initialiseInsuranceProduct().then(() => {
          const tpiIdentifiers = []
          for (const product of this.insuranceProductManagerService.selectedAddOnProducts) {
              tpiIdentifiers.push((product as any).underwriterIdentifier)
          }
          if(tpiIdentifiers.length > 0){
            this.getUnderwriters(tpiIdentifiers);
          }
      });
    }
  }

  onPublish() {
    if (!this.insuranceProductManagerService.product.number) {
      this.startLoad()
      this.insuranceProductManagerService.finaliseInsuranceProduct().then(() => {
        this.toast('TOAST.PUBLISH_PRODUCT')
        this.insuranceProductManagerService.ycPriceIntervals.next([])
        this.insuranceProductManagerService.ycPriceSchedules.next([])
        this.router.navigate(["/broker/products"]);
        this.stopLoad()
      }).catch((error) => {
        this.stopLoad()
        this.errorToastService.errorToast(error);
        console.log(error)
      });
    } else {
      this.submit()
    }
  }

  provideUnderwriter() {
    this.loading = true;
    let legalEntityIdentifier = null;
    for (const identifier of this.authenticationService.getLinkedToLegalEntityIdentifiers()) {
      if (identifier['@class'] === new TpiIdentifier()['@class']) {
        legalEntityIdentifier = identifier;
      }
    }

    const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
    if (this.insuranceProductManagerService.product) {
      underwriterLegalEntityCriteria.legalEntityIdentifiers = [Utils.getTpiIdentifier([this.underwriterManagerService.selectedUnderwriter.legalEntityIdentifier])];
    } else {
      underwriterLegalEntityCriteria.legalEntityIdentifiers = [this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier];
    }
    const provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
    provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
    this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
      if (response && response.body) {
        const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
        if (provideUnderwriterInformationResponse.underwriters.length > 0) {
          this.insuranceProductManagerService.underwriter = provideUnderwriterInformationResponse.underwriters[0];
        }
      }
      this.loading = false;
    });

  }

  submit(navigate = true) {

    const updateProductRequest = new FinaliseUpdateInsuranceProductRequest();

      const newIntervalIds = this.insuranceProductManagerService.intervalIds;
      this.insuranceProductManagerService.ycPriceIntervals.value.forEach(interval => {
          const isNew = interval.ycPriceIntervalId && newIntervalIds.includes(interval.ycPriceIntervalId)
              && parseInt(interval.ycPriceIntervalId) !== NaN;
          if (isNew) {
              delete interval.ycPriceIntervalId;
          }
      });

    updateProductRequest.product = this.insuranceProductManagerService.product;

    this.startLoad();

    if(updateProductRequest.product instanceof YCInsuranceProduct){
        delete updateProductRequest.product.coveredCountries;
    }

    this.insuranceProductFinalisationManagerService.finaliseUpdateInsuranceProduct(updateProductRequest).subscribe(response => {
      this.stopLoad();
      if (response.messages) {
          this.toastr.success("Server Error", response.message)
      } else {
        this.toast('TOAST.UPDATE_PRODUCT')
        if (navigate) {
          this.router.navigate(["/broker/products"]);
        }
      }
    }, (error) => {
      this.errorToastService.errorToast(error);
      this.stopLoad();
    });

  }

  updateValidation() {
    if (!this.insuranceProductManagerService.product) return;

    this.progress = 100 / 3;
    if (this.insuranceProductManagerService.product['@class'] === 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsuranceProduct') {
      const product = this.insuranceProductManagerService.product as YCInsuranceProduct;
      if (product.limits.length > 0) {
        this.progress += 100 / 3
      }

      if (product && product.ycPriceSchedules && product.ycPriceSchedules.length > 0) {
        this.progress += 100 / 3
      }

    } else {
      const product = this.insuranceProductManagerService.product as ThirdPartyInsuranceProduct;
      if (product.limit.length > 0) {
        this.progress += 100 / 3
      }

      if (product.priceSchedules.length > 0) {
        this.progress += 100 / 3
      }
    }


    this.progress = Math.round(this.progress)

  }

  toast(message: string) {

    this.translate.get(message).subscribe((res: any) => {
      this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.insuranceProductManagerService.product.name }), res.TITLE, {
        toastComponent: ToastComponent,
        timeOut: environment.toast_time_out,
        progressBar: true,
        closeButton: true
      });
      this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
      this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
      this.activeToast.onAction.subscribe((type) => {

        switch (type) {
          case ToastComponent.PRIMARY:
            this.activeToast.portal.destroy();
            break;
          case ToastComponent.SECONDARY:
            this.activeToast.portal.destroy();
            break;
        }
      });

    });

  }

  formatAmount(amount): string {
    return StringUtils.formatCents(amount);
  }

  onBack() {
    this.location.back();
  }

  onEditDetails() {
    this.activatedRoute.params.subscribe((data) => {
      const productNumber = data['number'];
      this.router.navigate([`/broker/products/create/${productNumber}`]);
    });
  }

  onAddNewLimit() {
    this.insuranceProductManagerService.editingIndex = -1;
    this.insuranceProductManagerService.initialiseLimit();
    this.router.navigate([`retail/insurance/create-product-limit/${this.productNumber}`]);
    this.updateValidation()
  }

  onAddNewExcess() {
    const index = -1;
    this.insuranceProductManagerService.editingIndex = index;
    this.insuranceProductManagerService.initaliseExcess();
    this.router.navigate([`retail/insurance/product-excess/${index}/${this.productNumber}`]);
    this.updateValidation();
  }

  onAddNewPriceSchedule() {
    this.insuranceProductManagerService.editingIndex = -1;
    this.insuranceProductManagerService.initialisePriceSchedule();
    this.router.navigate([`retail/insurance/product-pricing-schedule/${this.productNumber}`]);
  }
  onAddNewAddOnProduct () {
    this.router.navigate([`retail/insurance/product-add-on/${this.productNumber}`]);
  }

  openYellowCardPriceSchedule(): void {
    this.router.navigate([`retail/insurance/yellow-card-product-pricing-schedule/${this.productNumber}`]);
  }

  onEditLimit(limit: Limit, index: number) {
    this.insuranceProductManagerService.editingIndex = index;
    this.insuranceProductManagerService.limit = limit;
    this.router.navigate([`retail/insurance/create-product-limit/${this.productNumber}`]);
  }

  onEditExcess(excess: Excess, index: number) {
    this.insuranceProductManagerService.editingIndex = index;
    this.insuranceProductManagerService.initaliseExcess();
    this.router.navigate([`retail/insurance/product-excess/${index}/${this.productNumber}`]);
  }

  onEditPriceSchedule(priceSchedule: PriceSchedule, index: number) {
    this.insuranceProductManagerService.editingIndex = index;
    this.insuranceProductManagerService.priceSchedule = priceSchedule;
    this.router.navigate([`retail/insurance/product-pricing-schedule/${this.productNumber}`]);
  }

  onRemoveLimit(index: number) {
    if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
      this.insuranceProductManagerService.product.limits.splice(index, 1);
    } else {
      this.insuranceProductManagerService.product.limit.splice(index, 1);

    }
    this.updateValidation()
  }

  onRemoveExcess(index: number) {
    this.insuranceProductManagerService.product.excess.splice(index, 1);
    // this.submit();
    this.updateValidation()
  }
  onRemovePriceSchedule(index: number) {
    if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
      this.insuranceProductManagerService.product.ycPriceSchedules.splice(index, 1);

    } else {
      this.insuranceProductManagerService.product.priceSchedules.splice(index, 1);
    }
    // this.submit();
    this.updateValidation()
  }

    onRemoveAddOnProduct(product: Product) {

        let index = 0
        for (const selectedAddOnProduct of this.insuranceProductManagerService.selectedAddOnProducts) {
            if (selectedAddOnProduct.number === product.number) {
                break
            }
            index++
        }
        this.insuranceProductManagerService.selectedAddOnProducts.splice(index, 1);

        (this.insuranceProductManagerService.product as YCInsuranceProduct).productIdentifiers = []
        for (const product of this.insuranceProductManagerService.selectedAddOnProducts) {
            const productNumberIdentifier = new ProductNumberIdentifier()
            productNumberIdentifier.number = product.number;
            (this.insuranceProductManagerService.product as YCInsuranceProduct).productIdentifiers.push(productNumberIdentifier)
        }

    }

  get insuranceCategory(): InsuranceCategory {
    let product: any = this.insuranceProductManagerService.product;
    let type: InsuranceCategory = null;
    if (product instanceof ThirdPartyInsuranceProduct) {
      type = InsuranceCategory.THIRDPARTY;
    } else if (product instanceof ComprehensiveInsuranceProduct) {
      type = InsuranceCategory.COMPREHENSIVE;
    } else if (product instanceof YCInsuranceProduct) {
      type = InsuranceCategory.YELLOWCARD;
    }
    return type;
  }

  getPriceSchedules(): YellowCardProductPricingComponentModel {
    const schedules = this.insuranceProductManagerService.ycPriceSchedules.value;
    const pricing: YellowCardProductPricingComponentScheduleModel[] = [];
    if (schedules.length > 0) {
      schedules.forEach((schedule) => {
        const foundCategories = pricing.filter((item) => item.categoryId === schedule.insuranceVehicleCategoryId);
        if (foundCategories.length > 0) {
          foundCategories[0].coveredCountries.push({
            id: schedule.ycPriceScheduleId,
            name: schedule.country,
            price: schedule.price / 100
          });
        } else {
          const description = [...new Set(schedule.insuranceVehicleCategorySubDescription.split('-')
            .map(item => item.trim()))];
          let categoryName = schedule.insuranceVehicleCategorySubDescription;
          if (description.length < 2) {
            categoryName = description[0];
          }

          pricing.push({
            categoryId: schedule.insuranceVehicleCategoryId,
            categoryName,
            coveredCountries: [{
              id: schedule.ycPriceScheduleId,
              name: schedule.country,
              price: schedule.price / 100
            }]
          });
        }
      });
    }
    return {
      pricing
    };
  }

  getLimits() {
    if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
      return this.insuranceProductManagerService.product.limits;
    } else {
      return this.insuranceProductManagerService.product.limit;
    }
  }

  getFlagUrl(description: string): string {
    if (description === 'Democratic Republic of the Congo') {
      return '/assets/images/flags/flag-of-Congo-Democratic-Republic-of.png';
    }

    return '/assets/images/flags/flag-of-' + description + '.png';
  }

    getUnderwriters(legalEntityIdentifiers: UnderwriterIdentifier[]) {
        const tpiIdentifier: TpiIdentifier[] = []

        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            tpiIdentifier.push((legalEntityIdentifier as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier)
        }
        this.provideUnderwriters(tpiIdentifier).then((underwriters) => {
            this.underwriterMap = {}
            for (const underwriter of underwriters) {
                if (!this.underwriterMap[(underwriter as Underwriter).name]) {
                    this.underwriterMap[(underwriter as Underwriter).name] = underwriter
                }
            }
            console.log(this.underwriterMap)
        })

    }

    provideUnderwriters(tpiIdentifiers: TpiIdentifier[]): Promise<Underwriter[]> {
        return new Promise<Underwriter[]>((resolve) => {
            const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
            underwriterLegalEntityCriteria.legalEntityIdentifiers = tpiIdentifiers
            const provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
            provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
            this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
                if(response && response.body){
                    const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                    resolve(provideUnderwriterInformationResponse.underwriters)
                } else {
                    resolve([])
                }
            });
        })
    }

    get coveredCountries(): CountryIdentifier[] {
        const product = this.insuranceProductManagerService.product;
        const result = [];
        if (product instanceof YCInsuranceProduct) {
            const productIdentifiers = product.productIdentifiers.map((item: ProductNumberIdentifier) => item.number);
            let countryIdentifiers: CountryDescriptionIdentifier[] = [];
            this.insuranceProductManagerService.addOnProductOptions.filter((item) => productIdentifiers.includes(item.number))
            .flatMap((item: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct) => item.coveredCountries)
            .forEach((item: CountryDescriptionIdentifier) => {
                if(!countryIdentifiers.find(x => x.description === item.description)){
                    countryIdentifiers.push(item);
                }
            });

            const mapped = countryIdentifiers.flatMap((item: CountryDescriptionIdentifier) => item.description)
            product.coveredCountries.forEach((item: CountryDescriptionIdentifier) => {
                if(!mapped.includes(item.description)){
                    result.push(item);
                }
            })
            return result.concat(countryIdentifiers);
        }
        return this.insuranceProductManagerService.product.coveredCountries;
    }

    get hasPriceSchedules(): boolean {
        const product = this.insuranceProductManagerService.product;
        if (product instanceof ThirdPartyInsuranceProduct || product instanceof ComprehensiveInsuranceProduct) {
            return product.priceSchedules.length > 0;
        } else {
            return this.getPriceSchedules().pricing.length > 0;
        }
    }
}
