import {Component, Input, OnInit} from '@angular/core'
import * as moment from 'moment/moment'
import {MotorInsurancePolicyItem} from "@magnabc/tpi"
import {Utils} from '../../../shared/Utils/Utils'

@Component({
  selector: 'app-insurance-period',
  templateUrl: './insurance-period.component.html',
  styleUrls: ['./insurance-period.component.scss']
})
export class InsurancePeriodComponent implements OnInit {

    @Input() extensions: MotorInsurancePolicyItem[] = []
    @Input() originals: MotorInsurancePolicyItem[] = []

    uniqueCoveragePeriods: {effectiveDate: moment.Moment, expiryDate: moment.Moment}[] = []
    fullCoveredMap: {[id: string]: boolean} = {}
    partiallyCoveredMap: {[id: string]: boolean} = {}

    logged = 0
    periods: any[] = []

    constructor() {
        for (let i = 0; i < 12; i++) {
            this.periods[i] = moment().add(-1, 'month').subtract(moment().month() % 1, 'months').add(i, 'months').startOf('month')
        }
    }

    ngOnInit(): void {

        this.createUniqueCoveragePeriods()

        if (this.originals) {
            for (const period of this.periods) {
                this.fullCoveredMap[period.toString()] = this.periodInsured(period)
                this.partiallyCoveredMap[period.toString()] = this.periodPartlyInsured(period)
            }
        }

    }

    createUniqueCoveragePeriods() {
        this.uniqueCoveragePeriods = []
        const uniqueCoveragePeriodsTemp: {effectiveDate: moment.Moment, expiryDate: moment.Moment}[] = []
        if (this.originals) {
            for (const original of this.originals) {
                if (!original.motorInsuranceVehiclePolicy.cancelled) {
                    uniqueCoveragePeriodsTemp.push({effectiveDate: moment(original.motorInsuranceVehiclePolicy.effectiveDate), expiryDate: moment(original.motorInsuranceVehiclePolicy.expiryDate)})
                }
            }
        }
        if (this.extensions) {
            for (const extension of this.extensions) {
                if (!extension.motorInsuranceVehiclePolicy.cancelled) {
                    uniqueCoveragePeriodsTemp.push({effectiveDate: moment(extension.motorInsuranceVehiclePolicy.effectiveDate), expiryDate: moment(extension.motorInsuranceVehiclePolicy.expiryDate)})
                }
            }
        }

        // For mocking the dates
        // uniqueCoveragePeriodsTemp.push({effectiveDate: moment('2023-01-01').startOf('day'), expiryDate: moment('2023-03-01').endOf('day')})
        // uniqueCoveragePeriodsTemp.push({effectiveDate: moment('2023-03-02').startOf('day'), expiryDate: moment('2023-06-01').endOf('day')})
        // uniqueCoveragePeriodsTemp.push({effectiveDate: moment('2023-09-15').startOf('day'), expiryDate: moment('2023-12-31').endOf('day')})

        this.uniqueCoveragePeriods = this.connectCoverage(uniqueCoveragePeriodsTemp)

    }

    connectCoverage(uniqueCoveragePeriods: {effectiveDate: moment.Moment, expiryDate: moment.Moment}[]) {

        const uniqueCoveragePeriodsTemp: {effectiveDate: moment.Moment, expiryDate: moment.Moment}[] = []
        const consumedMap: {[id: string]: boolean} = {}

        for (const first of uniqueCoveragePeriods) {
            for (const second of uniqueCoveragePeriods) {
                // if (first.expiryDate.isSame(moment(second.effectiveDate).startOf('day')) || moment(first.expiryDate).add(1, 'day').startOf('day').isSame(moment(second.effectiveDate).startOf('days'))) {
                if (first.expiryDate.isSame(moment(second.effectiveDate).startOf('day'))) {
                    uniqueCoveragePeriodsTemp.push({effectiveDate: first.effectiveDate, expiryDate: second.expiryDate})
                    consumedMap[first.effectiveDate.toString()] = true
                    consumedMap[second.effectiveDate.toString()] = true
                }
            }
            if (!consumedMap[first.effectiveDate.toString()]) {
                uniqueCoveragePeriodsTemp.push({effectiveDate: first.effectiveDate, expiryDate: first.expiryDate})
            }
        }
        if (uniqueCoveragePeriods.length != uniqueCoveragePeriodsTemp.length) {
            return this.connectCoverage(uniqueCoveragePeriodsTemp)
        }
        return uniqueCoveragePeriodsTemp
    }

    periodInsured(startPeriod): boolean {
        if (this.uniqueCoveragePeriods.length > 0) {
            for (const uniqueCoveragePeriod of this.uniqueCoveragePeriods) {
                if (this.isMonthFullyContained(moment(uniqueCoveragePeriod.effectiveDate).startOf('day'), moment(uniqueCoveragePeriod.expiryDate).startOf('day'), startPeriod)) {
                    return true
                }
            }
        }
        return false
    }

    periodPartlyInsured(startPeriod): boolean {
        for (const uniqueCoveragePeriod of this.uniqueCoveragePeriods) {
            if (this.isMonthPartiallyContained(moment(uniqueCoveragePeriod.effectiveDate).startOf('day'), moment(uniqueCoveragePeriod.expiryDate).startOf('day'), startPeriod)) {
                return true
            }
        }
        return false
    }

    isMonthPartiallyContained(startMoment: moment.Moment, endMoment: moment.Moment, startOfPeriod: moment.Moment): boolean {
        const endOfPeriod = moment(startOfPeriod).endOf('month')
        if (startMoment.isAfter(startOfPeriod) && startMoment.isBefore(endOfPeriod) ||
            endMoment.isAfter(startOfPeriod) && endMoment.isBefore(endOfPeriod)) {
            return true
        }
        return false
    }

    isMonthFullyContained(startMoment: moment.Moment, endMoment: moment.Moment, startOfPeriod: moment.Moment): boolean {
        const endOfPeriod = moment(startOfPeriod).endOf('month')
        if (startMoment.startOf('day').isSameOrBefore(startOfPeriod) && endMoment.startOf('day').isSameOrAfter(endOfPeriod)) {
            return true
        }
        return false
    }

    formatPeriod(date: Date): string {
        const period = moment(date)
        if (period.month() === 0) {
            return period.format("YYYY")
        }
        return period.format("MMM")
    }

}
