import { InsuranceCategory } from "../../insurance_api";

export class InsuranceVehicleCategorySummary {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.InsuranceVehicleCategorySummary";
    } 

    public insuranceVehicleCategoryId: string;
    public description: string;
    public subDescription: string;
    public insuranceCategory: InsuranceCategory;
}
