import { Answers } from "./answers.model";

export class AdditionalDriversAnswers extends Answers{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.AdditionalDriversAnswers";
    }

    public identificationNumber: string;
    public yesOrNoAnswer: boolean;
    public freeTextAnswer: string;
    public comment: string;
}
