import { Location } from '@angular/common';
import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ActivatedRoute} from '@angular/router'
import {
    OrderFinalisationManagerService
} from '../../../../http.services/retail/checkout/order-finalisation-manager/order-finalisation-manager.service'
import {
    OrderInformationProviderService
} from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
    DirectPayOnlineCardPaymentVerifierService
} from '../../../../http.services/retail/payment/dpo/direct-pay-online-card-payment-verifier/direct-pay-online-card-payment-verifier.service'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {PDFPrintModel, PrintService} from '../../../../app.services/common/printing/print.service'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {
    FinaliseCancelOrderRequest,
    Order,
    OrderNumberCriteria,
    OrderNumberIdentifier,
    OrderReferenceNumber,
    OrderStatus,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    RetailProfile,
    UnMarshallerService,
    VerifyCardPaymentRequest,
    VerifyCardPaymentResponse,
} from "@magnabc/tpi"
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent  extends FormComponent implements OnInit {

    retailProfile: RetailProfile;
    orderStatus = OrderStatus;
    order: Order;
    activeToast: ActiveToast<any>;
    reference: string;
    numberOfPages: number;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private route: ActivatedRoute,
                private orderFinalisationManagerService: OrderFinalisationManagerService,
                private orderInformationProviderService: OrderInformationProviderService,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private directPayOnlineCardPaymentVerifierService: DirectPayOnlineCardPaymentVerifierService,
                private unMarshallerService: UnMarshallerService,
                private quickStartService: QuickTransactManagerService,
                private toastr: ErrorToastService,
                private printService: PrintService,
                private location: Location) {
        super(translate, translateParser);

    }

    ngOnInit() {

        this.route.params.subscribe((params) => {
            this.reference = params["reference"];
            this.getOrder(this.reference);
        });


        if (this.quickStartService.retailProfile) {
            this.retailProfile = this.quickStartService.retailProfile;
        } else {
            this.retailProfile = this.retailProfileService.retailProfile;
        }

    }

    getOrder(reference: string): void {

        const orderReferenceNumberCriteria = new OrderNumberCriteria();
        orderReferenceNumberCriteria.orderIdentifiers.push(new OrderNumberIdentifier(reference));

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderReferenceNumberCriteria;

        this.startLoad();
        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {
            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            this.stopLoad();
            for (const orderItem of response.orders[0].orderItems) {
                if ((orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem") && (orderItem["scannedDocument"].length === 0)) {
                    delete orderItem["scannedDocument"]
                }
            }
            this.order = response.orders[0];
        }).catch((error) => {
            this.stopLoad();
        });

    }

    onCancel(): void {

        this.translate.get('TOAST.CANCEL_ORDER').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.order.number}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.cancel();
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    cancel(): void {
        const finaliseCancelOrderRequest = new FinaliseCancelOrderRequest();
        finaliseCancelOrderRequest.order = this.order;
        finaliseCancelOrderRequest.order.status = OrderStatus.CANCELLED;

        this.startLoad();
        this.orderFinalisationManagerService.finaliseCancelOrder(finaliseCancelOrderRequest).subscribe(() => {
            this.stopLoad();
            this.getOrder(this.order.number);
        },(error) => {
            this.stopLoad();
        });
    }

    getNumberOfPages(numOfPages){
        this.numberOfPages = numOfPages;
    }

    onPrint(): void {
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId: `print-section`,
            openBlank: true
        };
        this.printService.printDocument(pdfPrintData);
    }

    onBack() {
        this.location.back();
    }
}
