import { LegalEntityIdentifier } from "../../../entity/entity_api";
import { CardToken, CustomerToken } from "../../retail_api";

export class PaymentProfile{
    legalEntity: LegalEntityIdentifier;
    cardTokens: CardToken[] = [];
    customerToken: CustomerToken;

    constructor() {
        this['@class']="za.co.magnabc.tpi.retail.payment.PaymentProfile"
    }

}
