import { Entry } from "./entry.model";

export class AccountingTransaction
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.accountingtransaction.AccountingTransaction";
    }

    public date: Date;
    public reference: string;
    public entries: Entry[];
}