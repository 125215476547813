import { ShortCountryNamePipe } from './../../shared/pipes/short-country-name.pipe/short-country-name.pipe';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProductDialogComponent } from './product-dialog/product-dialog.component'
import { PaymentMethodComponent } from './payment-method/payment-method.component'
import { CountryCheckoutRetrieverComponent } from './country-checkout-retriever/country-checkout-retriever.component'
import { CaptureProposalComponent } from './capture-proposal/capture-proposal.component'
import {
    DialogCaptureResultsComponent
} from '../../../view.pages/retail/insurance/comprehensive-insurance/proposal-assessment-manager-page/proposal-assessment-manager.component'
import { VehicleImageDialogComponent } from './assess-proposal/vehicle-image-dialog/vehicle-image-dialog.component'
import { ImageSlideShowComponent } from './assess-proposal/image-slide-show/image-slide-show.component'
import {
    AttachedDocumentDialogComponent
} from './assess-proposal/attached-document-dialog/attached-document-dialog.component'
import { ProductItemComponent } from '../insurance/insurance-product-manager/product-item/product-item.component'
import { OrderSummaryComponent } from './order-summary/order-summary.component'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '../../shared/shared.module'
import { LoadingModule } from 'ngx-loading'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatRadioModule } from '@angular/material/radio'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MalawiModule } from '../../malawi/malawi.module'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { EntityModule } from '../../entity/entity.module'
import { InsuranceModule } from '../insurance/insurance.module'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatDialogModule } from '@angular/material/dialog'
import { PaymentModule } from '../payment/payment.module'
import { MatInputModule } from '@angular/material/input'
import { ConfirmationAccordion } from './confirmation-accordion/confirmation-accordion.component';
import { CartComponent } from './cart/cart.component';
import {
    DialogCaptureEmailComponent,
    DialogOnlinePaymentComponent,
    OrderHistoryComponent
} from './order-history/order-history.component';
import { OrderComponent } from './order/order.component';
import { ProposalHistoryComponent, ProposalResultsDialogComponent } from './proposal-history/proposal-history.component';
import { ProposalListComponent } from './proposal-list/proposal-list.component'


@NgModule({
    declarations: [
        ProductDialogComponent,
        DialogOnlinePaymentComponent,
        PaymentMethodComponent,
        DialogCaptureEmailComponent,
        CountryCheckoutRetrieverComponent,
        CaptureProposalComponent,
        DialogCaptureResultsComponent,
        ProposalResultsDialogComponent,
        ProductDialogComponent,
        VehicleImageDialogComponent,
        ImageSlideShowComponent,
        AttachedDocumentDialogComponent,
        ProductItemComponent,
        OrderSummaryComponent,
        ConfirmationAccordion,
        CartComponent,
        OrderHistoryComponent,
        OrderComponent,
        ProposalHistoryComponent,
        ProposalListComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LoadingModule,
        NgxDatatableModule,
        MatSelectModule,
        MatTabsModule,
        InsuranceModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatRadioModule,
        MatCheckboxModule,
        MalawiModule,
        MatDatepickerModule,
        EntityModule,
        MatButtonToggleModule,
        MatDialogModule,
        PaymentModule,
        MatInputModule
    ],
    providers: [
        ShortCountryNamePipe
    ],
    exports: [
        OrderSummaryComponent,
        PaymentMethodComponent,
        ProductItemComponent,
        CountryCheckoutRetrieverComponent,
        CaptureProposalComponent,
        ConfirmationAccordion,
        CartComponent,
        OrderHistoryComponent,
        OrderComponent,
        ProposalListComponent,
        ProposalHistoryComponent
    ]
})
export class CheckoutModule { }
