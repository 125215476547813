import {Injectable} from '@angular/core';
import { LegalEntityIdentifier, PlainTextUsername, TpiIdentifier } from '@magnabc/tpi';

@Injectable({
    providedIn: 'root'
})
export class CompareEntityIdentifiersService {
    compareIdentifiers(entity1: LegalEntityIdentifier, entity2: LegalEntityIdentifier) {

        if (entity1 === null || entity2 === null) return false;

        if (entity1['@class'] === 'za.co.magnabc.entity.identification.naturalperson.PlainTextUsername' &&
            entity2['@class'] === 'za.co.magnabc.entity.identification.naturalperson.PlainTextUsername')
        {
            return ((entity1 as PlainTextUsername).username === (entity2 as PlainTextUsername).username);
        }
        else if ((entity1 instanceof TpiIdentifier && entity2 instanceof TpiIdentifier) ||
                (entity1["@class"] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier' &&
                entity2["@class"] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier')) {
                    return ((entity1 as TpiIdentifier).tpiIdentifier === (entity2 as TpiIdentifier).tpiIdentifier);
                }
        else {
            return false;
        }
    }
}
