import { ProposalForm } from "../../../../../../insurance/comprehensiveinsurance/models/proposal-form.model";
import { Request } from "../../../../../../../urdad/services/models/request.model";

export class CaptureProposalRequest extends Request{
    constructor(){
        super();
    }

    public proposalForm : ProposalForm;
}
