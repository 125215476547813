import {Request} from '../../../../../../../urdad/services/models/request.model';
import { RetailProfileIdentifier }  from '../../../../../profile_api';
import { Cart } from '../../../../../models/cart.model';

export class FinaliseClearCartRequest extends Request{
    constructor(){
        super();
    }

    public retailProfileIdentifier: RetailProfileIdentifier;
}