import {Response} from '../../../../../../../urdad/services/models/response.model';
import {OneTimePinIdentifier} from '../../../../../../onetimepin/models/one-time-pin-identifier.model';
import {JsonWebToken} from '../../../../../../token/json-web-token.model';

export class WrapperInitialiseResponse extends Response {
    constructor() {
        super();
    }

    oneTimePinIdentifier: OneTimePinIdentifier;
    tempToken: JsonWebToken;
}
