import { CountryIdentifier } from "../../../location/location_api";
import { Criteria } from "../../../criteria/criteria_api";

export class DepositMethodCountryCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.deposit.DepositMethodCountryCriteria";
    }

    public countryIdentifier : CountryIdentifier;
}