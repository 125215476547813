import {StringUtils} from './../../../../../common/utils/utils.string'
import {DurationType, InsuranceCategory, Money, PriceSchedule} from '@magnabc/tpi'
import {Component, Input, OnInit} from '@angular/core'

@Component({
    selector: 'app-product-extension',
    templateUrl: './product-extension.component.html',
    styleUrls: ['./product-extension.component.scss']
})
export class ProductExtensionComponent implements OnInit {

    @Input() isExtension = false;
    @Input() priceSchedule: PriceSchedule;
    @Input() annualAmount: Money;
    @Input() insuranceCategory: InsuranceCategory

    insuranceCategories = InsuranceCategory

    constructor() { }

    ngOnInit(): void {
    }

    provideExtensionDuration(priceSchedule: PriceSchedule): string {
        const years = this.formatDuration(priceSchedule.lengthOfExtension.years, "Year");
        const months = this.formatDuration(priceSchedule.lengthOfExtension.months, "Month");
        const days = this.formatDuration(priceSchedule.lengthOfExtension.days, "Day");
        const hours = this.formatDuration(priceSchedule.lengthOfExtension.hours, "Hour");
        return years + months + days + hours;
    }

    formatDuration(value: number, type: string): string {
        if (value === undefined) return "";
        let formatted = value === 0 ? "" : `${value} ${type}`;
        formatted = value > 1 ? `${formatted}s` : formatted;
        return formatted === "" ? formatted : `${formatted} `;
    }

    prorataTag(priceSchedule: PriceSchedule): string {
        return priceSchedule.proRata ? 'max premium' : 'fixed premium';
    }

    formatAmount(amount): string {
        return StringUtils.formatCents(amount);
    }

    isIntervalDuration(priceSchedule: PriceSchedule): boolean{
        return priceSchedule.durationType === DurationType.INTERVAL;
    }
}
