import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PublicPasswordResetManagerService {

    private passwordProcess = new BehaviorSubject<boolean>(false);
    currentPasswordProcessCondition = this.passwordProcess.asObservable();

    private hideLogin = new BehaviorSubject<boolean>(false);
    hideLoginProcessCondition = this.hideLogin.asObservable();

    changePasswordProcessCondition(setState: boolean) {
        this.passwordProcess.next(setState);
    }

    getPasswordProcessState(){
        return this.passwordProcess;
    }

    changeHideLoginProcessCondition(setState: boolean) {
        this.hideLogin.next(setState);
    }

    getHideLoginProcessCondition(){
        return this.hideLogin.value;
    }
}
