import { Response } from "../../../../../../urdad/urdad_api";
import { CityTownIdentifier } from "../../../../models/city-town-identifier.model";

export class FindCityTownIdentifierResponse extends Response{

    constructor(){
        super();
    }

    public cityTownIdentifiersSet:CityTownIdentifier[] = [];
}