import {AgentIdentifier} from '../../../../../../../entity/identification/models/agent-identifier.model';
import {Request} from '../../../../../../../urdad/services/models/request.model';
import { Item } from '../../../../../models/item.model';
import { RetailProfileIdentifier } from "../../../../../../profile/identification/retail-profile-identifier.model";
import { CountryIdentifier } from '../../../../../../../location/geographic/identification/models/country-identifier.model';
export class CreateOrderRequest extends Request
{
    constructor() {
        super();
    }

    public item: Item [] = []
    public agentIdentifier : AgentIdentifier;
    public retailProfileIdentifier: RetailProfileIdentifier;
    public countryIdentifiers: CountryIdentifier[] = [];
    public identifiers: string [] =[];

}
