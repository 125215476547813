import {Request} from '../../../../../../../urdad/services/models/request.model';
import {BrokerIdentifier} from "../../../../../identification/models/broker-identifier.model";
import {UnderwriterIdentifier} from "../../../../../identification/models/underwriter-identifier.model";
import {Excess} from "../../../../../models/excess.model";
import {Limit} from "../../../../../models/limit.model";
import {CountryIdentifier} from "../../../../../../../location/geographic/identification/models/country-identifier.model";
import {InsuranceType} from "../../../../../setup/models/insurance-type.model";
import {InsuranceCategory} from "../../../../../models/insurance-category.model";
import { AgentIdentifier } from '../../../../../../../entity/identification/models/agent-identifier.model';
import { PriceSchedule } from '../../../../../models/price-schedule.model';
import { InsuranceVehicleCategorySummary } from '../../../../../setup/models/insurance-vehicle-category-summary.model';
import { Money } from '../../../../../../../financial/models/money.model';
export class FinaliseCreateInsuranceProductRequest extends Request{
    constructor(){
        super();
    }

    public name: string; 
    public description: string; 
    public active: boolean;
    public baseCurrency: string;
    public brokerIdentifier: BrokerIdentifier; 
    public underwriterIdentifier: UnderwriterIdentifier; 
    public conditions: string; 
    public insuranceCategory: InsuranceCategory; 
    public insuranceType: InsuranceType; 
    public excesses: Excess[] = []; 
    public limits: Limit[] = []; 
    public countries: CountryIdentifier[] = [];
    public agentIdentifiers: AgentIdentifier[] = [];
    public priceSchedules: PriceSchedule [] = []
    public insuranceVehicleCategorySummary: InsuranceVehicleCategorySummary;
    public annualAmount: Money;
    public annualPercentage: number;

}
