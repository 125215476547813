import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {MarshallerService, ProvideSupplierInvoiceSummaryRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class SupplierInvoiceInformationProviderService {

  basePath = '/retail/invoice/supplierinvoiceinformationprovider';

  constructor(private httpClient: HttpClient,
              private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
      observe: 'response' as 'body',
      ResponseType: 'json'
  };

  provideSupplierInvoiceSummary(provideSupplierInvoiceSummaryRequest: ProvideSupplierInvoiceSummaryRequest): Observable<any> {

    const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providesupplierinvoicesummary';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(provideSupplierInvoiceSummaryRequest);
    return this.httpClient.post<any>(path, jsonRequest, this.options);
  }
}
