import { Criteria } from "../../../../criteria/models/criteria.model";

export class NaturalPersonIdentifierTypeCriteria extends Criteria
{
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.identification.naturalperson.NaturalPersonIdentifierTypeCriteria";
  }
  public identifierType: string;

}
