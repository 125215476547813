import {Response} from '../../../../../../../urdad/services/models/response.model';
import {AuthenticationAdvice} from '../../../../../../models/authentication-advice.model';

export class FinaliseCreateVerifiedAccountByUpdateResponse extends Response {
    constructor() {
        super();
    }

    authenticationAdvice: AuthenticationAdvice;
}
