import { Criteria } from "../../../criteria/models/criteria.model";
import {TpiIdentifier} from '../../../entity/identification/models/tpi-identifier.model';

export class ServiceGroupLegalEntityCriteria extends Criteria {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.ServiceGroupLegalEntityCriteria";
    }

    public legalEntityIdentifier: TpiIdentifier;
}
