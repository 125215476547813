import { InsurancePolicy } from "./insurance-policy.model";

export class MotorInsurancePolicy extends InsurancePolicy {
    
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.MotorInsurancePolicy"; 
    }
    
    public expiryDate: Date;
    public insuranceVehicleCategoryDescription: string;
}
