import { Money } from "../../../../financial/models/money.model";

export class VehicleEvaluation{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.VehicleEvaluation";
    }

    public vehicleValue: Money;
    public recommendation: boolean;
    public comments: string[] = [];
}