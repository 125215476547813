import { Country } from "../geographic/models/country.model";

export class CityTown{

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.location.CityTown";
    }

    public country: Country;
    public description: string;
}