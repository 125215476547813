import { OrderProduct } from "../../../checkout/models/order-product.model";
import { BrokerIdentifier } from "../../identification/models/broker-identifier.model";
import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";
import { Excess } from "../../models/excess.model";
import { YCLimitDetails } from "./yc-limit-details.model";
import { YCPriceInterval } from "./yc-price-interval.model";
import { YCPriceSchedule } from "./yc-price-schedule.model";

export class OrderYCInsuranceProduct extends OrderProduct {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderYCInsuranceProduct";
    }

    public conditions: string;
    public insuranceVehicleCategoryDescription: string;
    public insuranceVehicleCategorySubDescription: string;
    public excess: Excess[];
    public brokerIdentifier: BrokerIdentifier;
    public underwriterIdentifier: UnderwriterIdentifier;
    public motorInsuranceLimitDetails: YCLimitDetails[] = [];
    public ycPriceInterval: YCPriceInterval;
    public ycPriceSchedule: YCPriceSchedule[] = []
}
