import {ServiceGroupInformationProviderService} from "../../../http.services/security/authorisation/service-group-information-provider/service-group-information-provider.service";
import {SubscriptionInformationProviderService} from "../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service";

import {MatDialog} from "@angular/material/dialog";
import {
    CreateSubscriptionRequest, DeleteSubscriptionRequest,
    ServiceGroup,
    Subscription,
    TpiIdentifier
} from '@magnabc/tpi';
import {Injectable} from "@angular/core";
import {CustomDialogComponent} from "../../shared/dialogs/custom-dialog/custom-dialog.component";
import {SubscriptionManagerService} from "../../../http.services/security/authorisation/subscription-manager/subscription-manager.service";

@Injectable({
    providedIn: 'root'
})
export class UserUpdateService {
    assignedServiceGroups: ServiceGroup[] = [];
    unassignedServiceGroupsBuffer: ServiceGroup[] = [];
    unassignedServiceGroups: ServiceGroup[] = [];
    private entityIdentifier: TpiIdentifier = null;
    private userSubscriptions: Subscription[] = [];
    isPublicUpdate = false;

    constructor(
        private serviceGroupInformationProvider: ServiceGroupInformationProviderService,
        private subscriptionInformationProviderService: SubscriptionInformationProviderService,
        private subscriptionManagerService: SubscriptionManagerService,
        private dialog: MatDialog,
    ) {
    }

    addToUnassigned(serviceGroup: ServiceGroup) {

        let exist = false;
        this.unassignedServiceGroupsBuffer.forEach(unAssignServiceGroup => {
            if (serviceGroup.name === unAssignServiceGroup.name) {
                exist = true;
            }
        });

        if (!exist || this.unassignedServiceGroups.length === 0) {
            this.unassignedServiceGroupsBuffer.push(serviceGroup);
        }
    }

    setAssignedServiceGroups(assigned: ServiceGroup[]) {
        this.assignedServiceGroups = assigned;
    }

    setUnAssignedServiceGroups(unassigned: ServiceGroup[]) {
        this.unassignedServiceGroups = unassigned;
    }

    setUserSubscriptions(subscriptions: Subscription[]) {
        this.userSubscriptions = subscriptions;
    }

    updateUserSubscriptions(assigned: ServiceGroup[], unassigned: ServiceGroup[], usedBy: TpiIdentifier, ownedBy: TpiIdentifier) {

        const addServices: ServiceGroup[] = [];
        const removeServices: ServiceGroup[] = [];
        this.entityIdentifier = usedBy;

        return new Promise<any>((resolve, reject) => {
            assigned.forEach(serviceGroup => {
                let found = false;

                this.assignedServiceGroups.forEach(currentServiceGroup => {
                    if (serviceGroup.name === currentServiceGroup.name) {
                        found = true;
                    }
                });

                if (!found) {
                    addServices.push(serviceGroup);
                }
            });

            unassigned.forEach(serviceGroup => {
                let found = false;

                this.unassignedServiceGroups.forEach(currentServiceGroup => {

                    if (serviceGroup.name === currentServiceGroup.name) {
                        found = true;
                    }
                });

                if (!found) {
                    removeServices.push(serviceGroup);
                }
            });

            if (addServices.length < 1 && removeServices.length < 1) {
                resolve(true);
            } else if (removeServices.length < 1) {
                this.addServices(addServices, ownedBy).then(() => {
                    resolve(true);
                });
            } else if (addServices.length < 1) {
                this.removeServices(removeServices, usedBy).then(() => {
                    resolve(true);
                });
            } else {
                this.addServices(addServices, ownedBy).then(() => {
                    return this.removeServices(removeServices, usedBy);
                }).then(() => {
                    resolve(true);
                });
            }
        });
    }

    private addServices(serviceGroups: ServiceGroup[], ownedBy: TpiIdentifier) {
        let error = false;
        const createSubscriptionRequest = new CreateSubscriptionRequest();
        createSubscriptionRequest.owner = ownedBy;
        createSubscriptionRequest.user = this.entityIdentifier;
        createSubscriptionRequest.serviceGroups = serviceGroups;
        createSubscriptionRequest.type = this.isPublicUpdate ? 'INTERNAL_PUBLIC' : 'INTERNAL_BACKOFFICE';

        return this.subscriptionManagerService.createSubscription(createSubscriptionRequest)
            .catch(err => {
                if (!error) {
                    error = true;
                    this.showDialog(true, 'Error updating subscriptions', 'A server error occurred while updating subscriptions.');
                }
            });
    }

    private removeServices(services: ServiceGroup[], usedBy: TpiIdentifier) {
        if (services.length < 1) {
            return;
        }

        const subscriptionsToRemove: Subscription[] = [];

        this.userSubscriptions.forEach(subscription => {
            if(this.isPublicUpdate){
                subscription.usedBy = usedBy;
            }
            services.forEach(service => {
                if (subscription.serviceGroup !== undefined && subscription.serviceGroup.name === service.name) {
                    subscriptionsToRemove.push(subscription);
                }
            });
        });

        const deleteSubscriptionRequest = new DeleteSubscriptionRequest();
        subscriptionsToRemove.forEach(subscription => {
            subscription.usedBy = null;
            deleteSubscriptionRequest.subscriptions.push(subscription);
        });

        return this.subscriptionManagerService.deleteSubscription(deleteSubscriptionRequest);
    }

    showDialog(error: boolean, title: string, message: string) {
        return this.dialog.open(CustomDialogComponent, {
            width: '400px',
            panelClass: 'padded-modal',
            disableClose: true,
            data: {error, title, message}
        });
    }
}
