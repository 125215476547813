import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ProductPriceScheduleComponent } from './../product-price-schedule/product-price-schedule.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { YellowCardProductPricingComponent } from './yellow-card-product-pricing/yellow-card-product-pricing.component';
import { YellowCardCoveredCountriesComponent } from './yellow-card-covered-countries/yellow-card-covered-countries.component';
import { SharedModule } from '../../../../shared/shared.module';
import { YellowCardProductPriceScheduleComponent } from './yellow-card-product-price-schedule/yellow-card-product-price-schedule.component';
import { YellowCardPriceScheduleSelectorComponent } from './yellow-card-price-schedule-selector/yellow-card-price-schedule-selector.component';



@NgModule({
    declarations: [
        YellowCardProductPricingComponent,
        YellowCardCoveredCountriesComponent,
        YellowCardProductPriceScheduleComponent,
        ProductPriceScheduleComponent,
        YellowCardPriceScheduleSelectorComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgxDatatableModule,
        TranslateModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatAutocompleteModule
    ],
    exports: [
        YellowCardProductPricingComponent,
        YellowCardCoveredCountriesComponent,
        YellowCardProductPriceScheduleComponent,
        ProductPriceScheduleComponent,
        YellowCardPriceScheduleSelectorComponent,
        ProductPriceScheduleComponent
    ],
    providers: [
        DecimalPipe
    ]
})
export class YellowCardProductViewModule { }
