import { OrderItemIdentifier } from "./order-item-identifier.model";

export class OrderItemNumberIdentifier extends OrderItemIdentifier {

    orderItemNumber: string;

    constructor(orderItemNumber: string) {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.checkout.identification.OrderItemNumberIdentifier";
        this.orderItemNumber = orderItemNumber;
    }
}
