import { Credentials } from "./credentials.model";

export class EmailAddressAndPassword extends Credentials {

  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.security.credentials.EmailAddressAndPassword";
  }

  public emailAddress: string;
  public password: string;
}
