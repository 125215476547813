export class RevenueReportEntry
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.RevenueReportEntry";
    }

    public date: Date;
    public policyNumber: number;
    public transactionAmount: number;
    public payable: number;
    public receivable: number;
    public settlement: number;
    public cumulativeTotal: number;
    public roles: string;
    public currency: string;
    public certificateNumber: string;
    public invoiceNumber: string;
    public insuredName: string;
    public userId: string;
    public startDate: Date;
    public expiryDate: Date;
}