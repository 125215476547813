import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {FinaliseCreateUserAccountRequest, FinaliseMaintainUserAccountRequest, MarshallerService} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class UserAccountFinalisationManagerService {

  basePath = '/security/account/useraccountfinalisationmanager';


  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  finaliseCreateUserAccount(finaliseCreateUserAccountRequest: FinaliseCreateUserAccountRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreateuseraccount';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateUserAccountRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

  finaliseMaintainUserAccount(finaliseMaintainUserAccountRequest: FinaliseMaintainUserAccountRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisemaintainuseraccount';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseMaintainUserAccountRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options);
  }
}
