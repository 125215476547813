import { AuthenticationAdvice } from "../../../../../models/authentication-advice.model";
import { Response } from "../../../../../../urdad/services/models/response.model";

export class AuthenticateCredentialsResponse extends Response {
  constructor() {
    super()
  }

  public authenticationAdvice: AuthenticationAdvice;

}
