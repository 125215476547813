import {Request} from "../../../../../../urdad/services/models/request.model";
import {LegalEntity} from "../../../../../models/legal-entity.model";
import {LegalEntityPersistenceManagerType} from "../../../../../models/legal-entity-persistence-manager-type.model";

export class FinaliseUpdateLegalEntityRequest extends Request {
    constructor() {
        super();
    }

    public pmType: LegalEntityPersistenceManagerType;
    public legalEntity: LegalEntity;
}
