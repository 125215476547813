import { Elaboration } from "./elaboration.model";

export class AuthenticationAdvice {

   constructor(){
     this['@class'] = "za.co.magnabc.tpi.security.AuthenticationAdvice";
   }

    public authentic: boolean;
    public elaboration: Elaboration;
}
