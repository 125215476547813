import {Money} from "../../../financial/models/money.model";

export class InsuranceSalesBySalesChannelEntry
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.InsuranceSalesBySalesChannelEntry";
    }

    public channelName: string;
    public orderQuantity: number;
    public averageOrderSize: number;
    public grossSales: Money;
    public netSales: Money
}
