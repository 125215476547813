import {Request} from "../../../../../../../urdad/services/models/request.model";
import {ServiceGroup} from '../../../../../models/service-group.model';
import {ServiceGroupIdentifier} from "../../../../../identification/models/service-group-identifier.model";

export class FinaliseUpdateServiceGroupRequest extends Request {
    constructor() {
        super();
    }

    public newServiceGroup: ServiceGroup;
    public oldServiceGroup: ServiceGroupIdentifier;
}
