import {Injectable} from '@angular/core'
import {
    CountryDescriptionIdentifier,
    CountryStartDateConfiguration,
    CountryStartDateConfigurationCriteria,
    PlateNumber,
    ProvideCountryStartDateConfigurationRequest,
    ProvideCountryStartDateConfigurationResponse,
    Underwriter,
    UnMarshallerService
} from '@magnabc/tpi'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {
    InsuranceConfigurationProviderService
} from '../../insurance/setup/insurance-configuration-provider/insurance-configuration-provider.service'

@Injectable({
    providedIn: 'root'
})
export class CheckoutManagerService {

    overLappingVehiclesMap: {[id: string]: string} = {};
    overLappingVehicles: string[] = [];
    plateNumber: PlateNumber;
    tabSelected: string;

    constructor(private insuranceConfigurationProviderService: InsuranceConfigurationProviderService,
                private unMarshallerService: UnMarshallerService)
    {}

    findCountryStartDateConfiguration(underwriter: Underwriter) {

        return new Promise<CountryStartDateConfiguration>((resolve, reject) => {

            const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
            countryDescriptionIdentifier.description = (underwriter.countryIdentifier as CountryDescriptionIdentifier).description

            const provideCountryStartDateConfigurationRequest = new ProvideCountryStartDateConfigurationRequest();
            const countryStartDateCriteria = new CountryStartDateConfigurationCriteria();
            countryStartDateCriteria.countryIdentifier = countryDescriptionIdentifier;
            provideCountryStartDateConfigurationRequest.criteria = countryStartDateCriteria;

            this.insuranceConfigurationProviderService.provideCountryStartDateConfiguration(provideCountryStartDateConfigurationRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                        ProvideCountryStartDateConfigurationResponse) as ProvideCountryStartDateConfigurationResponse);
                    resolve(response.countryStartDateConfiguration);
                    return;
                }
            }).catch(error => {
                console.error('country configuration start date error :: ', error);
                RaygunErrorHandler.sendError(error);

                resolve(null);
                return;
            })

        });

    }

    onTabClick(event){
        this.tabSelected = event.tab.textLabel;
    }
}
