import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {Subject} from 'rxjs'
import {VehicleListComponent} from '../../insurance/vehicle-list/vehicle.list.component'
import {PolicyListComponent} from '../policy-list/policy.list.component'
import {Utils} from '../../../shared/Utils/Utils'
import {ProgressSpinnerMode} from '@angular/material/progress-spinner'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Router} from '@angular/router'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
    SubscriptionInformationProviderService
} from '../../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service'
import {
    QueryLegalEntityInformationManagerService
} from '../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service'
import {
    PublicLegalEntityInformationProviderService
} from '../../../../http.services/entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service'
import {
    RetailProfileInformationProviderService
} from '../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
    CompareEntityIdentifiersService
} from '../../../../app.services/managers/compare-entity-identifiers/compare-entity-identifiers.service'
import {CountryService} from '../../../shared/country/services/country.service'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
    InsurancePolicyProviderService
} from '../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import * as moment from 'moment/moment'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {environment} from '../../../../../environments/environment'
import {ServiceList} from '../../../../common/model/service-list.model'
import {
    InsurancePolicy,
    JudicialPerson,
    LegalEntity,
    LegalEntityIdentifier,
    LegalEntityProviderCriteria,
    NaturalPerson,
    ProvideInsurancePoliciesRequest,
    ProvideInsurancePoliciesResponse,
    ProvideLegalEntityInformationRequest,
    ProvideLegalEntityInformationResponse,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehicleInformationResponse,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsResponse,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    RetailProfileVehicleInsurancePolicyCriteria,
    RetailVehicle,
    Subscription,
    SubscriptionOwnedByLegalEntityCriteria,
    SubscriptionOwner,
    UnMarshallerService, DeleteSubscriptionRequest
} from "@magnabc/tpi";
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {SubscriptionManagerService} from "../../../../http.services/security/authorisation/subscription-manager/subscription-manager.service";

declare const jQuery: any;

@Component({
  selector: 'app-retail-profile',
  templateUrl: './retail-profile.component.html',
  styleUrls: ['./retail-profile.component.scss']
})
export class RetailProfileComponent  extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    filterText = new Subject<string>();
    @Output() onValid = new EventEmitter<void>();
    @ViewChild(VehicleListComponent) vehicleListComponent: VehicleListComponent;
    @ViewChild(PolicyListComponent) policyListComponent: PolicyListComponent;


    utils = Utils;
    filter: string;
    retailProfile: RetailProfile;
    showVehicles = false;
    showPolicies = false;
    refreshVehicles = false;
    refreshVehiclesTimeout = false;
    vehicles: RetailVehicle[];
    insurancePolicies: InsurancePolicy[];
    insurancePoliciesPerPolicy: InsurancePolicy[];
    legalEntity: any;
    profileCategory = 'Vehicles';
    loadingSpinner = true;
    spinnerMode: ProgressSpinnerMode = 'indeterminate';

    owned: Subscription[] = [];
    userMap = new Map<string, NaturalPerson>();
    proxies: SubscriptionOwnerDisplay[] = [];
    identifierMap: { [id: string]: LegalEntityIdentifier } = {};
    users: NaturalPerson[] = [];

    judicialList: SubscriptionOwner[] = [];
    judicialKey: { [id: string]: boolean } = {};

    legalEntityListener: (legalEntity) => void;
    retailProfileListener: (retailProfile) => void;
    activeToast: ActiveToast<any>;

    subscriptionSubscriber: any;
    proxySubscriber: any;
    currentNaturalPerson: NaturalPerson;
    currentJudicialPerson: JudicialPerson;
    totalElements: number;

    personType = PersonType;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private route: ActivatedRoute,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private router: Router,
                private subscriptionManagerService: SubscriptionManagerService,
                private subscriptionInformationProviderService: SubscriptionInformationProviderService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private publicLegalEntityInformationProviderService: PublicLegalEntityInformationProviderService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                public authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService,
                private compareEntityIdentifierService: CompareEntityIdentifiersService,
                private countryService: CountryService,
                public quickStartService: QuickTransactManagerService,
                private toastr: ErrorToastService,
                private insurancePolicyProviderService: InsurancePolicyProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService
    ) {
        super(translate, translateParser);

        title.setTitle(`Retail Profile - ${this.runtimeConfigurationService.title}`);
    }

    ngOnInit() {
        if (this.authenticationService.isBackOfficeUser()) {
            this.initProfile();
            this.refreshVehicles = true;
        } else {

            this.startLoad();
            if (!this.authenticationService.isAuthenticated()) {
                if (this.authenticationService.busyAuthenticating) {
                    this.subscriptionSubscriber = this.authenticationService.subscriptionListener.subscribe(val => {
                        if (val !== null && val === true) {
                            this.initProfile();
                            this.refreshVehicles = true;
                        }
                    })
                } else {
                    this.router.navigate(['/login']);
                }
            } else {
                this.initProfile();
                this.refreshVehicles = true;
            }
            this.authenticationService.refreshPublicAuthentication();

            this.proxySubscriber = this.authenticationService.proxiesUpdated.subscribe(val => {
                if (val) {
                    if (this.authenticationService.getActiveProxy() !== null)
                        this.loadActiveProxyProxies();
                    else
                        this.loadLoggedInLegalEntityProxies();

                    this.initProfile();
                    this.stopLoad();
                }
            });

            this.quickStartService.reset();
            this.onToggleSearch();

        }
    }


    initProfile() {
        this.vehicles = [];
        this.showVehicles = false;
        this.showPolicies = false
        this.loadingSpinner = true;
        this.retailProfileService.refreshRetailProfile().then(retailProfile => {
            if (retailProfile) {
                this.retailProfile = retailProfile;
                this.countryService.populateCountries();
                const provideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
                const criteria = new RetailProfileLegalEntityCriteria();
                criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
                provideRetailProfileVehicleInformationRequest.criteria = criteria;
                provideRetailProfileVehicleInformationRequest.batchSize = 20;
                provideRetailProfileVehicleInformationRequest.batchNumber = 1;
                this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then(
                    (data) => {
                        const response: ProvideRetailProfileVehicleInformationResponse =
                            this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileVehicleInformationResponse);
                        if (response.retailVehicles && response.retailVehicles.length > 0) {
                            this.vehicles = response.retailVehicles;
                            this.totalElements = response.totalNumberOfResults;
                            this.showVehicles = true;
                            setTimeout(() => {
                                this.vehicleListComponent.page.totalElements = response.totalNumberOfResults;
                            }, 100);

                            if (this.profileCategory === 'Vehicles') {
                                this.loadingSpinner = false;
                            }

                            // wait for component to load before calling refresh
                            if (this.servicesProvideVehicles() && this.showVehicles && response.totalNumberOfResults <= 20) {
                                setTimeout(() => {
                                    if (this.refreshVehicles && this.refreshVehiclesTimeout) {
                                        this.vehicleListComponent.refresh();
                                        this.vehicleListComponent.page.totalElements = response.totalNumberOfResults;
                                    }
                                    this.refreshVehiclesTimeout = true;
                                }, 100)
                            }


                            // wait for component to load before calling refresh
                            if (this.servicesProvideVehicles() && this.showVehicles && response.totalNumberOfResults > 20) {
                                setTimeout(() => {
                                    if (this.refreshVehicles && this.refreshVehiclesTimeout) {
                                        this.vehicleListComponent.page.totalElements = response.totalNumberOfResults;
                                        if (response.totalNumberOfResults > 20) {
                                            this.vehicleListComponent.page.totalElements = response.totalNumberOfResults;
                                            this.vehicleListComponent.getRetailProfileVehiclesBatch(20, response.batchTo);
                                        }
                                    }
                                    else {
                                        if (response.totalNumberOfResults > 20) {
                                            this.vehicleListComponent.page.totalElements = response.totalNumberOfResults;
                                            this.vehicleListComponent.getRetailProfileVehiclesBatch(20, response.batchTo);
                                        }
                                    }
                                    this.refreshVehiclesTimeout = true;
                                }, 300);

                            }

                        }
                        else {
                            this.showVehicles = true;
                            if (this.profileCategory === 'Vehicles') {
                                this.loadingSpinner = false;
                            }
                        }
                        this.insurancePolicies = response.insurancePolicies

                        if (this.authenticationService.getActiveProxy() !== null)
                            this.loadActiveProxyProxies();
                        else
                            this.loadLoggedInLegalEntityProxies();

                        this.loadProxiesSubList();
                        this.providePolicies();
                        this.stopLoad();
                    }, (error) => {
                        this.stopLoad();
                    }
                ).catch((error) => {
                    console.log(error)
                    this.stopLoad();
                });
            } else {
                alert('Unable to load retail profile.');
            }
        })
    }

    providePolicies(): void {
        if (this.retailProfile.vehicleIdentifiers.length === 0) {
            return;
        }

        var newDate = new Date();
        newDate.setMonth(newDate.getMonth() - 1);
        const provideInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
        const retailProfileVehicleInsurancePolicyCriteria = new RetailProfileVehicleInsurancePolicyCriteria();
        retailProfileVehicleInsurancePolicyCriteria.vehicleIdentifiers = this.retailProfile.vehicleIdentifiers;
        retailProfileVehicleInsurancePolicyCriteria.date = moment(newDate).format('YYYY-MM-DDTHH:mm:ss') as any;

        provideInsurancePoliciesRequest.criteria = retailProfileVehicleInsurancePolicyCriteria;
        provideInsurancePoliciesRequest.batchNumber = -1;
        provideInsurancePoliciesRequest.batchSize = -1;
        this.insurancePoliciesPerPolicy = []
        this.insurancePolicyProviderService.providePolicy(provideInsurancePoliciesRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {

                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);
                this.insurancePoliciesPerPolicy = response.insurancePolicies
                this.showPolicies = true;

                if (this.profileCategory === 'Policies') {
                    this.loadingSpinner = false;
                }
            }
            else {
                this.showPolicies = true;
            }
            this.stopLoad();
        });
        if (this.profileCategory === 'Policies') {
            this.loadingSpinner = false;
        }
        this.stopLoad();
    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
        // this.entityService.deregisterLegalEntityListener(this.legalEntityListener);
        this.retailProfileService.deregisterRetailProfileListener(this.retailProfileListener);

        if (this.subscriptionSubscriber) {
            this.subscriptionSubscriber.unsubscribe();
            this.subscriptionSubscriber = null;
        }

        if (this.proxySubscriber) {
            this.proxySubscriber.unsubscribe();
            this.proxySubscriber = null;
        }
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
    }

    updateFilter() {
        this.filterText.next(this.filter);
    }

    onSelectProfileCategory(profileCategory: string) {

        if (profileCategory === 'Vehicles') {
            this.startLoad();
            this.refreshVehicles = true;
            this.initProfile();
        }

        this.profileCategory = profileCategory;
    }

    onSwitchAccount(loginSubscription: SubscriptionOwner): void {
        this.refreshVehiclesTimeout = false;
        this.refreshVehicles = false;
        this.authenticationService.setActiveProxySubscription(loginSubscription.subscriptions);
    }

    switchBackToLoggedInUserAccount() {
        this.authenticationService.clearActiveProxySubscription();
        this.refreshVehiclesTimeout = false;
        this.refreshVehicles = false;
        this.initProfile();
    }

    getCurrentLegalEntity(retailProfile: RetailProfile): LegalEntity {
        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(retailProfile.legalEntityDescriptionIdentifier);
        this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpLegalEntityInformation => {
            const provideLegalEntityInformationResponse = (this.unMarshallerService.
            unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

            if (provideLegalEntityInformationResponse.legalEntities.length > 0) {
                if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                    this.currentJudicialPerson = null;
                    this.currentNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                } else {
                    this.currentNaturalPerson = null;
                    this.currentJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                }

            } else {
                return null;
            }
        });

        if (this.currentNaturalPerson == null) {
            return this.currentJudicialPerson;
        }

        if (this.currentJudicialPerson == null) {
            return this.currentNaturalPerson;
        }

        return null;
    }

    loadActiveProxyProxies() {
        let proxy: SubscriptionOwnerDisplay;
        this.proxies = [];
        this.judicialList = [];
        for (const key of this.authenticationService.getActiveProxyNaturalPersonSubscriptions().keys()) {
            if (key !== Utils.getTpiIdentifier(this.authenticationService.getLoggedInNaturalPersonLegalEntityIdentifiers()).tpiIdentifier) {
                if (!this.authenticationService.getActiveProxy) {
                    proxy = this.authenticationService.getActiveProxyNaturalPersonSubscriptions().get(key);
                    proxy.personType = PersonType.NaturalPerson;
                    this.proxies.push(proxy);
                }
            }
        }

        for (const key of this.authenticationService.getActiveProxyJudicialPersonSubscriptions().keys()) {
            if (!this.authenticationService.getActiveProxy) {
                proxy = this.authenticationService.getActiveProxyJudicialPersonSubscriptions().get(key);
                proxy.personType = PersonType.JudicialPerson;
                this.proxies.push(proxy);
            }
            this.judicialList.push(this.authenticationService.getActiveProxyJudicialPersonSubscriptions().get(key));
        }
    }

    loadLoggedInLegalEntityProxies() {
        let proxy: SubscriptionOwnerDisplay;
        this.proxies = [];
        this.judicialList = [];
        for (const key of this.authenticationService.getLoginSubscriptions().keys()) {
            proxy = this.authenticationService.getLoginSubscriptions().get(key);
            proxy.personType = PersonType.NaturalPerson;
            this.proxies.push(proxy);
        }

        for (const key of this.authenticationService.getJudicialPersonSubscriptions().keys()) {
            proxy = this.authenticationService.getJudicialPersonSubscriptions().get(key);
            proxy.personType = PersonType.JudicialPerson;
            this.proxies.push(proxy);
            this.judicialList.push(this.authenticationService.getJudicialPersonSubscriptions().get(key));
        }

        this.sortDisplayList(this.judicialList);
        this.sortDisplayList(this.proxies);
    }

    loadProxiesSubList() {
        const tpiIdentifier = this.utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());
        const subscriptionOwnedByLegalEntityCriteria = new SubscriptionOwnedByLegalEntityCriteria();
        subscriptionOwnedByLegalEntityCriteria.legalEntityIdentifier = tpiIdentifier;

        this.users = [];
        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = subscriptionOwnedByLegalEntityCriteria;

        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {

                const response = (this.unMarshallerService.
                unMarshallFromJson(httpResponse.body, ProvideSubscriptionsResponse) as ProvideSubscriptionsResponse);

                const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();

                for (const o of response.subscriptions) {
                    if (!this.compareEntityIdentifierService.compareIdentifiers(subscriptionOwnedByLegalEntityCriteria.legalEntityIdentifier, o.usedBy)) {
                        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(o.usedBy);
                    }
                }

                if (provideLegalEntityInformationRequest.legalEntityIdentifiers.length < 1) {
                    return;
                }
                this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpResponseLE => {

                    if (httpResponseLE && httpResponseLE.body) {
                        const responseLE = (this.unMarshallerService.
                        unMarshallFromJson(httpResponseLE.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                        if (responseLE.legalEntities.length > 0) {
                            this.userMap = new Map<string, NaturalPerson>();
                            this.users = [];
                            for (const legalEntity of responseLE.legalEntities) {
                                const tpiId = this.utils.getTpiIdentifier(legalEntity.legalEntityIdentifiers);
                                if (!this.userMap.get(tpiId.tpiIdentifier) && legalEntity instanceof NaturalPerson) {
                                    this.userMap.set(tpiId.tpiIdentifier, (legalEntity as NaturalPerson));
                                }
                            }
                            // noinspection TsLint
                            // tslint:disable-next-line:forin
                            for (const key of this.userMap.keys()) {
                                this.users.push(this.userMap.get(key));
                            }
                        }
                    }
                    this.sortDisplayList(this.users);
                })
            }
        });
    }

    isNaturalPerson(legalEntity: any): boolean {
        return !!legalEntity.firstNames;
    }

    onSubscriptions(): void {
        this.router.navigate(['/subscriptions']);
    }

    onJudicialPerson(event): void {
        this.router.navigate(['/create/company']);
    }

    onRemoveJudicialPerson(event, judicialPerson: SubscriptionOwner, title): void {
        event.stopPropagation();

        this.translate.get('TOAST.REMOVE_JUDICIAL').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: title }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.removeJudicialPerson(event, judicialPerson.subscriptions, name);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    removeJudicialPerson(event, judicialPerson: Subscription[], title): void {

        const deleteSubscriptionRequest = new DeleteSubscriptionRequest();
        deleteSubscriptionRequest.subscriptions = judicialPerson;
        this.startLoad();
        this.subscriptionManagerService.deleteSubscription(deleteSubscriptionRequest).then(() => {
            this.authenticationService.loadEntitySubscriptions().then(() => {
                this.toast(title);
                this.authenticationService.reloadProxyInformation();
            });
        }).catch(() => {
            this.stopLoad();
        });
    }

    toast(title) {

        this.translate.get('TOAST.REMOVED_JUDICIAL').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: title }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    getJudicialPerson(identifier: LegalEntityIdentifier): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = identifier;
        legalEntityProviderCriteria.type = 'INTERNAL_PUBLIC';

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest();
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const provideLegalEntityInformationResponse = (this.unMarshallerService.
                unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                this.legalEntity = provideLegalEntityInformationResponse.legalEntities[0];

            }
        });

        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });

    }

    hasProxies() {
        if (this.proxies) {
            for (const proxy of this.proxies) {
                if (!(this.authenticationService.getActiveProxy() && proxy.subscriptions[0].ownedBy.tpiIdentifier === this.authenticationService.getActiveProxy().tpiIdentifier)) {
                    return true
                }
            }
        }
        return false
    }

    formatDate(value): string {
        return moment(value).format("YYYY-MM-DD");
    }

    provideSubscriptions(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDESUBSCRIPTIONS]));
    }

    createSubscription(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.CREATESUBSCRIPTION]));
    }

    servicesPrincipal(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.CREATEPRINCIPAL]));
    }

    servicesUpdateLegalEntity(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.UPDATELEGALENTITY]));
    }

    servicesDisplayLegalEntity(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDERETAILPROFILEINFORMATION]));
    }

    servicesLinkBusiness(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.CREATEPRINCIPAL]) && !this.authenticationService.isProxyJudicialPerson());
        // return !!(this.authenticationService.isAuthorised([ServiceList.CREATEPRINCIPAL]));
    }

    servicesProvideVehicles(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDERETAILPROFILEVEHICLEINFORMATION])) || this.authenticationService.isBackOfficeUser();
    }

    getLoggedInNaturalPerson(): NaturalPerson {
        return this.authenticationService.getLoggedInNaturalPerson();
    }

    onRetailVehiclesHandler(event: RetailVehicle[]) {
        this.vehicles = [...event];
        this.totalElements = this.vehicles.length;
    }

    sortDisplayList(list) {
        if (list === this.users) {
            // Users List
            list.sort((val1, val2) => {
                return val1.firstNames.localeCompare(val2.firstNames) || val1.surname.localeCompare(val2.surname);
            });
        } else {
            // Judicial List
            list.sort((val1, val2) => {
                return val1.name.localeCompare(val2.name);
            });
        }
    }

    get showAccounts(): boolean {
        const result = this.proxies.filter(x => x.personType === PersonType.NaturalPerson).length > 0;
        return result;
    }

    get showCompanies(): boolean {
        const result = this.proxies.filter(x => x.personType === PersonType.JudicialPerson).length > 0;
        return result;
    }
}

enum PersonType {
    NaturalPerson,
    JudicialPerson
}

class SubscriptionOwnerDisplay extends SubscriptionOwner {
    personType?: PersonType;
}
