import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {FormComponent} from "../../../shared/form/form.component"
import {
    CountryRegistrationConfigurationInformation,
    Money,
    RequiredJudicialPersonCountryRegistrationInformation,
    RequiredNaturalPersonCountryRegistrationInformation,
    ShownJudicialPersonCountryRegistrationInformation,
    ShownNaturalPersonCountryRegistrationInformation
} from "@magnabc/tpi"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {Dropdown} from '../../../../common/model/dropdown.model'

declare const jQuery: any;
@Component({
  selector: 'app-source-of-wealth',
  templateUrl: './source-of-wealth.component.html',
  styleUrls: ['./source-of-wealth.component.scss']
})
export class SourceOfWealthComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() show: ShownJudicialPersonCountryRegistrationInformation | ShownNaturalPersonCountryRegistrationInformation;
    @Input() hideBorder: boolean;
    @Input() required: RequiredJudicialPersonCountryRegistrationInformation | RequiredNaturalPersonCountryRegistrationInformation;
    @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    @Output() onSourceOfWealth = new EventEmitter<any>();
    @Output() onValid = new EventEmitter<void>();

    formGroup: UntypedFormGroup;
    income: UntypedFormControl;
    sourceOfIncome: UntypedFormControl;
    otherSourceOfIncome: UntypedFormControl;
    incomeCurrency: string;
    showTextbox = false;

    @ViewChild('form') form: FormGroupDirective;

    sourceOfIncomeList = [
        new Dropdown(1, "Self – Employment"),
        new Dropdown(2, "Employment Income"),
        new Dropdown(3, "Corporate"),
        new Dropdown(4, "Investment Income / Savings"),
        new Dropdown(5, "Retirement Income / Pension Income"),
        new Dropdown(6, "Sales of Assets"),
        new Dropdown(7, "Trust / Inheritance"),
        new Dropdown(8, "Loan"),
        new Dropdown(9, "Gift"),
        new Dropdown(10, "Grant / Scholarship"),
        new Dropdown(11, "Proceeds from a legal case or action"),
        new Dropdown(12, "Lottery Winnings"),
        new Dropdown(13, "Other")
    ];

    sourceOfIncomes = this.sourceOfIncomeList;

    constructor(translateService: TranslateService, translateParser: TranslateParser) {
        super(translateService, translateParser);
    }

    ngOnInit() {

        const incomeAmount = this.countryRegistrationConfigurationInformation.income ? this.countryRegistrationConfigurationInformation.income.amount : 0;
        this.incomeCurrency = this.countryRegistrationConfigurationInformation.income ? this.countryRegistrationConfigurationInformation.income.currency : 'MWK';
        this.income = new UntypedFormControl(incomeAmount, [
            Validators.maxLength(30),
            Validators.pattern('^[0-9/]+$')
        ]);

        if(!this.getDropdown(this.sourceOfIncomes, this.countryRegistrationConfigurationInformation.sourceOfIncome) && this.countryRegistrationConfigurationInformation.sourceOfIncome){
            this.showTextbox = true;
            this.sourceOfIncome = new UntypedFormControl(this.sourceOfIncomes[12], [
                Validators.maxLength(30)
            ]);
            this.otherSourceOfIncome = new UntypedFormControl(this.countryRegistrationConfigurationInformation.sourceOfIncome, [
                Validators.maxLength(30)
            ]);
        }else{
            this.sourceOfIncome = new UntypedFormControl(this.getDropdown(this.sourceOfIncomes, this.countryRegistrationConfigurationInformation.sourceOfIncome), [
                Validators.maxLength(30)
            ]);
            this.otherSourceOfIncome = new UntypedFormControl('', [
                Validators.maxLength(30)
            ]);
        }

        if (this.required !== undefined) {
            if (this.required.income) {
                this.income.setValidators([
                    Validators.required,
                    Validators.maxLength(30),
                    Validators.pattern('^[0-9/]+$')
                ]);
                this.sourceOfIncome.setValidators([
                    Validators.required,
                    Validators.maxLength(30)
                ]);
                this.otherSourceOfIncome.setValidators([
                    Validators.required,
                    Validators.maxLength(30)
                ]);
            }
        }

        this.formGroup = new UntypedFormGroup({
            'income': this.income,
            'sourceOfIncome': this.sourceOfIncome,
            'otherSourceOfIncome': this.otherSourceOfIncome
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    onSourceOfIncomeChanged(event){

        if(event.value.name === "Other"){
            this.showTextbox = true;
        }else{
            this.showTextbox = false;
            this.sourceOfIncome =event;
        }

    }

    submit(): void {
        document.getElementById('form-submit-source-of-wealth').click();
    }

    onCheckValidation(event): void {

        if(!this.showTextbox){
            this.form.control.removeControl('otherSourceOfIncome');
        }

        if (!this.form.invalid) {

            const money = new Money();
            money.currency = this.incomeCurrency;
            money.amount = parseInt(this.income.value);

            let sourceOfIncome = this.sourceOfIncome.value.name
            if(this.showTextbox){
                sourceOfIncome = this.otherSourceOfIncome.value;
            }

            this.onSourceOfWealth.emit({
                income: money,
                sourceOfIncome: sourceOfIncome
            });
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    valid(): boolean {
        return this.form.valid;
    }

}
