import { ToastrService } from 'ngx-toastr';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { AccountUpdateEvent, AccountUpdateType } from '../user-update/user-update.model'
import {BehaviorSubject, Observable} from 'rxjs';
import { UserUpdateService } from '../user-update/user-update.service'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { TooltipPosition } from "@angular/material/tooltip"
import {
    ServiceGroupInformationProviderService
} from "../../../http.services/security/authorisation/service-group-information-provider/service-group-information-provider.service"
import {
    PlainTextUsername,
    ProvideServiceGroupsRequest,
    ProvideServiceGroupsResponse,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsResponse,
    ServiceGroup,
    ServiceGroupTypeCriteria,
    SubscriptionUsedByOwnedByLegalEntityCriteria,
    TpiIdentifier,
    UnMarshallerService
} from "@magnabc/tpi"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import {
    SubscriptionInformationProviderService
} from "../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service"

import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop"
import { UserData } from '../user-update/user-data.model'
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-account-service-groups',
    templateUrl: './account-service-groups.component.html',
    styleUrls: ['./account-service-groups.component.scss']
})
export class AccountServiceGroupsComponent implements OnInit, OnDestroy {
    @Input() $submit: BehaviorSubject<AccountUpdateEvent> = null;

    serviceGroupFormGroup: UntypedFormGroup;
    positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left'];
    position = new UntypedFormControl(this.positionOptions[0]);
    hideDelay = new UntypedFormControl(200);
    filterIconState = false;
    showCreatedByIndicator = false;
    pageLoadIndicator = true;

    assignedServiceGroups: ServiceGroupWrapper[] = [];
    unassignedServiceGroups: ServiceGroupWrapper[] = [];
    assignedServiceGroupsDisplay: ServiceGroupWrapper[] = [];
    unassignedServiceGroupsDisplay: ServiceGroupWrapper[] = [];

    usedBy: TpiIdentifier;
    ownedBy: TpiIdentifier;

    allServiceGroups: ServiceGroup[] = [];

    constructor(
        private serviceGroupInformationProvider: ServiceGroupInformationProviderService,
        private subscriptionInformationProviderService: SubscriptionInformationProviderService,
        private formBuilder: UntypedFormBuilder,
        private unMarshallerService: UnMarshallerService,
        @Inject(MAT_DIALOG_DATA) public data: UserData,
        private userUpdateService: UserUpdateService,
        private toastr: ErrorToastService,
    ) {
    }

    ngOnInit() {
        this.initComponentData();

        if (this.$submit !== null) {
            this.$submit.subscribe((event) => {
                if (event.updateType === AccountUpdateType.SERVICE_GROUPS || event.updateType === AccountUpdateType.All) {
                    this.assignServiceGroups();
                }
            })
        }
    }

    private initComponentData(): void {
        this.serviceGroupForm();
        this.filterInputListener();

        const plainTextUsername = new PlainTextUsername();
        plainTextUsername.username = this.data.userEmail;

        const usedByOwnedByCriteria = new SubscriptionUsedByOwnedByLegalEntityCriteria();

        const usedBy = new TpiIdentifier();
        const ownedBy = new TpiIdentifier();

        usedBy.tpiIdentifier = this.data.usedBy;
        this.usedBy = usedBy;

        ownedBy.tpiIdentifier = this.data.ownedBy;
        this.ownedBy = ownedBy;

        usedByOwnedByCriteria.usedBy = usedBy;
        usedByOwnedByCriteria.ownedBy = ownedBy;

        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = usedByOwnedByCriteria;

        this.showCreatedByIndicator = false;
        if (this.data.isPublicUser) {
            this.getPublicSubscriptions(provideSubscriptionsRequest);
        } else {
            this.getConsoleSubscriptions(provideSubscriptionsRequest);
        }
    }

    private getConsoleSubscriptions(provideSubscriptionsRequest: ProvideSubscriptionsRequest): void {
        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest)
            .then(httpResponse => {
                if (httpResponse.body) {

                    const provideSubscriptionsResponse: ProvideSubscriptionsResponse =
                        this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSubscriptionsResponse);

                    this.userUpdateService.setUserSubscriptions(provideSubscriptionsResponse.subscriptions);

                    provideSubscriptionsResponse.subscriptions.forEach(
                        subscription => {

                            if (subscription.usedBy != null) {
                                if (!(subscription.expiryDate instanceof Date)) {
                                    subscription.expiryDate = null;
                                }

                                if (subscription.serviceGroup.defaultGroup !== true) {
                                    this.assignedServiceGroups.push({
                                        serviceGroup: subscription.serviceGroup, check: true
                                    });
                                }
                            }
                        });

                    let serviceGroupTypeCriteriaAgent = new ServiceGroupTypeCriteria();
                    serviceGroupTypeCriteriaAgent.serviceGroupType = "AGENT";

                    const provideServiceGroupsRequestAgent = new ProvideServiceGroupsRequest();
                    provideServiceGroupsRequestAgent.criteria = serviceGroupTypeCriteriaAgent;

                    return this.serviceGroupInformationProvider.provideServiceGroups(provideServiceGroupsRequestAgent);
                }
            })
            .then(provideServiceGroupsResponse => {
                provideServiceGroupsResponse.body.serviceGroups.forEach(serviceGroup => {
                    this.allServiceGroups.push(serviceGroup);
                });

                let serviceGroupTypeCriteriaRetail = new ServiceGroupTypeCriteria();
                serviceGroupTypeCriteriaRetail.serviceGroupType = "RETAIL";

                const provideServiceGroupsRequestRetail = new ProvideServiceGroupsRequest();
                provideServiceGroupsRequestRetail.criteria = serviceGroupTypeCriteriaRetail;

                return this.serviceGroupInformationProvider.provideServiceGroups(provideServiceGroupsRequestRetail);

            })
            .then(provideServiceGroupsResponse => {
                provideServiceGroupsResponse.body.serviceGroups.forEach(serviceGroup => {
                    this.allServiceGroups.push(serviceGroup);
                });

                let serviceGroupTypeCriteriaBroker = new ServiceGroupTypeCriteria();
                serviceGroupTypeCriteriaBroker.serviceGroupType = "BROKER";

                const provideServiceGroupsRequestBroker = new ProvideServiceGroupsRequest();
                provideServiceGroupsRequestBroker.criteria = serviceGroupTypeCriteriaBroker;

                return this.serviceGroupInformationProvider.provideServiceGroups(provideServiceGroupsRequestBroker);

            })
            .then(provideServiceGroupsResponse => {
                this.sortServiceGroups(provideServiceGroupsResponse);
            })
            .catch(error => {
                this.toastr.errorToast(error);
                this.pageLoadIndicator = false;
            });
    }

    private getPublicSubscriptions(provideSubscriptionsRequest: ProvideSubscriptionsRequest): void {
        this.subscriptionInformationProviderService.provideSubscriptions(provideSubscriptionsRequest)
            .then(httpResponse => {
                if (httpResponse.body) {

                    const provideSubscriptionsResponse: ProvideSubscriptionsResponse =
                        this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSubscriptionsResponse);

                    this.userUpdateService.setUserSubscriptions(provideSubscriptionsResponse.subscriptions);

                    provideSubscriptionsResponse.subscriptions.forEach(
                        subscription => {

                            if (subscription.usedBy != null) {
                                if (!(subscription.expiryDate instanceof Date)) {
                                    subscription.expiryDate = null;
                                }

                                this.assignedServiceGroups.push({
                                    serviceGroup: subscription.serviceGroup, check: true
                                });
                            }
                        });

                    let serviceGroupTypeCriteria = new ServiceGroupTypeCriteria();
                    serviceGroupTypeCriteria.serviceGroupType = "PUBLIC";

                    const provideServiceGroupsRequest = new ProvideServiceGroupsRequest();
                    provideServiceGroupsRequest.criteria = serviceGroupTypeCriteria;

                    return this.serviceGroupInformationProvider.provideServiceGroups(provideServiceGroupsRequest);
                }
            }).then(provideServiceGroupsResponse => {
                this.sortServiceGroups(provideServiceGroupsResponse, true);
            }).catch(error => {
                this.toastr.errorToast(error);
                this.pageLoadIndicator = false;
            });
    }

    private sortServiceGroups(provideServiceGroupsResponse: any, isPublicSort = false): void {
        const listServiceGroups: ServiceGroupWrapper[] = [];

        provideServiceGroupsResponse.body.serviceGroups.forEach((serviceGroup: ServiceGroup) => {
            this.allServiceGroups.push(serviceGroup);
        });

        this.allServiceGroups.forEach(serviceGroup => {
            if (!serviceGroup.defaultGroup || isPublicSort) {
                listServiceGroups.push({
                    serviceGroup,
                    check: true
                });
            }

            let exist = false;

            listServiceGroups.forEach(unassigned => {
                if (unassigned.serviceGroup.name === serviceGroup.name) {
                    exist = true;
                }
            });

            if (!exist && !serviceGroup.defaultGroup) {
                listServiceGroups.push({
                    serviceGroup,
                    check: true
                });
            }

        });

        listServiceGroups.forEach(unassigned => {
            let exist = false;
            this.assignedServiceGroups.forEach(assigned => {
                if (unassigned.serviceGroup.name === assigned.serviceGroup.name) {
                    exist = true;
                    return;
                }
            });

            if (!exist) {
                this.unassignedServiceGroups.push(unassigned);
            }
        });

        this.assignedServiceGroupsDisplay = [...this.assignedServiceGroups];
        this.unassignedServiceGroupsDisplay = [...this.unassignedServiceGroups];

        this.checkCreatedByIndicator(this.assignedServiceGroups);
        this.checkCreatedByIndicator(this.unassignedServiceGroups);

        const tempAssigned: ServiceGroup[] = [];
        this.assignedServiceGroups.forEach(wrapper => {
            tempAssigned.push(wrapper.serviceGroup);
        });

        const tempUnassigned: ServiceGroup[] = [];
        this.unassignedServiceGroups.forEach(wrapper => {
            tempUnassigned.push(wrapper.serviceGroup);
        });

        this.userUpdateService.setAssignedServiceGroups(tempAssigned);
        this.userUpdateService.setUnAssignedServiceGroups(tempUnassigned);

        this.sortDisplayLists();
    }

    ngOnDestroy(): void {
        if (this.$submit !== null) {
            this.$submit.unsubscribe();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        const assignedBeforeMove = [...this.assignedServiceGroupsDisplay];
        const unassignedBeforeMove = [...this.unassignedServiceGroupsDisplay];

        let updatedServiceGroup = '';
        let newIndex = 0;
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
            newIndex = event.currentIndex;
            updatedServiceGroup = ((event.container.data[event.currentIndex] as unknown) as ServiceGroupWrapper).serviceGroup.name;
        }

        if (assignedBeforeMove.length > this.assignedServiceGroupsDisplay.length) {

            const unassignedItem = assignedBeforeMove.findIndex(value => value.serviceGroup.name.toUpperCase() === updatedServiceGroup.toUpperCase());
            this.unassignedServiceGroups.splice(newIndex, 0, assignedBeforeMove[unassignedItem]);

            let index = -1;
            this.assignedServiceGroups.forEach(serviceGroup => {
                if (serviceGroup.serviceGroup.name.toUpperCase() === updatedServiceGroup.toUpperCase()) {
                    index = this.assignedServiceGroups.indexOf(serviceGroup);
                    return;
                }
            });

            if (index > -1) {
                this.assignedServiceGroups.splice(index, 1);
            }

        } else if (assignedBeforeMove.length < this.assignedServiceGroupsDisplay.length) {

            const assignedItem = unassignedBeforeMove.findIndex(value => value.serviceGroup.name.toUpperCase() === updatedServiceGroup.toUpperCase());
            this.assignedServiceGroups.splice(newIndex, 0, unassignedBeforeMove[assignedItem]);

            let index = -1;
            this.unassignedServiceGroups.forEach(serviceGroup => {
                if (serviceGroup.serviceGroup.name.toUpperCase() === updatedServiceGroup.toUpperCase()) {
                    index = this.unassignedServiceGroups.indexOf(serviceGroup);
                    return;
                }
            });

            if (index > -1) {
                this.unassignedServiceGroups.splice(index, 1);
            }
        }

        this.sortDisplayLists();
    }

    /* Input listeners for filter input **/
    filterInputListener(): void {
        this.serviceGroupFormGroup.get('serviceGroupFilter').valueChanges.subscribe(() => {
            this.filterIconState = true;

            const value = this.serviceGroupFormGroup.controls.serviceGroupFilter.value;

            if (value) {
                this.assignedServiceGroupsDisplay = [...this.assignedServiceGroups.filter(serviceGroup => {
                    return serviceGroup.serviceGroup.name.toUpperCase().includes(value.toUpperCase());
                })];

                this.unassignedServiceGroupsDisplay = [...this.unassignedServiceGroups.filter(serviceGroup => {
                    return serviceGroup.serviceGroup.name.toUpperCase().includes(value.toUpperCase());
                })];
            } else {
                this.assignedServiceGroupsDisplay = [...this.assignedServiceGroups];
                this.unassignedServiceGroupsDisplay = [...this.unassignedServiceGroups];
            }
        });
    }

    serviceGroupForm() {
        this.serviceGroupFormGroup = this.formBuilder.group({
            serviceGroupFilter: ['']
        });
    }

    assignServiceGroups() {
        this.pageLoadIndicator = true;
        const assigned: ServiceGroup[] = [];
        const unassigned: ServiceGroup[] = [];

        if (this.data.isPublicUser) {
            this.userUpdateService.isPublicUpdate = true;
        } else {
            this.userUpdateService.isPublicUpdate = false;
        }

        this.assignedServiceGroups.forEach(serviceGroup => assigned.push(serviceGroup.serviceGroup));
        this.unassignedServiceGroups.forEach(serviceGroup => unassigned.push(serviceGroup.serviceGroup));
        this.userUpdateService.updateUserSubscriptions(assigned, unassigned, this.usedBy, this.ownedBy)
            .then(() => {
                this.$submit.next({
                    updateType: AccountUpdateType.DONE
                });
            });
    }

    checkCreatedByIndicator(list: ServiceGroupWrapper[]) {
        if (this.showCreatedByIndicator) {
            return;
        }
        list.forEach(serviceGroup => {
            if (serviceGroup.check) {
                this.showCreatedByIndicator = true;
                return;
            }
        });
    }

    sortDisplayLists() {
        this.assignedServiceGroupsDisplay.sort((val1, val2) => val1.serviceGroup.name.localeCompare(val2.serviceGroup.name));
        this.unassignedServiceGroupsDisplay.sort((val1, val2) => val1.serviceGroup.name.localeCompare(val2.serviceGroup.name));
    }

    /**
     * generate an element id from a name
     * @param name
     * @returns {string} the element id
     */
    generateElementId(name: string): string {
        return name.toLowerCase().replace(/ /g, '-');
    }

}

export interface ServiceGroupWrapper {
    serviceGroup: ServiceGroup;
    check: boolean;
}

export enum ServiceGroupType{
    PUBLIC,
    BROKER,
    RETAIL
}
