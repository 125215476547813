import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, UpdateBackOfficePasswordRequest, UpdateResetPasswordRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class BackOfficePasswordUpdateManagerService {

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  updateResetPassword(updateResetPasswordRequest: UpdateResetPasswordRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/backofficepasswordupdatemanager/updateresetpassword';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(updateResetPasswordRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

  updatePassword(updatePasswordRequest: UpdateBackOfficePasswordRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/backofficepasswordupdatemanager/updatepassword';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(updatePasswordRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

}
