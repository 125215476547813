import {Response} from '../../../../../../../urdad/services/models/response.model';
import {LegalEntity} from '../../../../../../../entity/models/legal-entity.model';

export class ProvideOrderLegalEntityResponse extends Response{
    constructor(){
        super();
    }

    public legalEntity: LegalEntity;
}
