import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComprehensiveInsuranceProduct, Excess, ThirdPartyInsuranceProduct, UniversalExcess, YCInsuranceProduct } from "@magnabc/tpi";
import {InsuranceProductManagerService} from "../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service";
import { ClaimSpecificExcessComponent } from '../../../../view.components/retail/insurance/insurance-product-manager/product-excess/claim-specific-excess/claim-specific-excess.component';
import { UniversalExcessComponent } from '../../../../view.components/retail/insurance/insurance-product-manager/product-excess/universal-excess/universal-excess.component';
import {Location} from "@angular/common";
import { ObjectUtils } from '../../../../common/utils/utils.object';
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-retail-product-excess',
  templateUrl: './retail-product-excess-page.component.html',
  styleUrls: ['./retail-product-excess-page.component.scss']
})
export class RetailProductExcessPageComponent extends FormComponent implements OnInit  {

  excessType = 0;
  product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct;
  localExcess: Excess[] = [];
  productNumber: string;

  @ViewChild(UniversalExcessComponent) universalExcessComponent: UniversalExcessComponent;
  @ViewChild(ClaimSpecificExcessComponent) claimSpecificExcessComponent: ClaimSpecificExcessComponent;


  constructor(public insuranceProductManagerService: InsuranceProductManagerService, private router: Router, private location: Location, private translate: TranslateService,
    private translateParser: TranslateParser,
    private activatedRoute: ActivatedRoute) {
    super(translate, translateParser);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
        this.productNumber = data['number'];
     });

    this.startLoad();
    this.insuranceProductManagerService.initialiseInsuranceProduct().then(() =>{

      this.stopLoad();

      this.product = this.insuranceProductManagerService.product;
      if(this.insuranceProductManagerService.editingIndex !== -1){
        this.localExcess = [ObjectUtils.createObjectCopy(this.product.excess[this.insuranceProductManagerService.editingIndex])];
      }

      if (this.product && this.product.excess && this.product.excess.length > 0) {
        if (this.product.excess[0]['@class'] !== new UniversalExcess('')['@class']) {
          this.excessType = 1;
        }
      }
    }).catch(error =>{
      this.stopLoad();
    });
  }

  onExcessType(type: number): void {
      this.excessType = type;
  }

  submit(): void {
    if (this.universalExcessComponent && this.excessType === 0) {
        this.universalExcessComponent.submit();
    } else if (this.claimSpecificExcessComponent && this.excessType === 1) {
        this.claimSpecificExcessComponent.submit();
    }
  }

    onExcess(excesses): void {
        excesses.forEach(element => {
            if (element) {
                this.product.excess.push(element);
            }
        });
    }

  onExcessValid(event): void {
    this.router.navigate([`retail/insurance/product-overview/${this.productNumber}`])
  }

  onBack(event): void {
    this.product = this.insuranceProductManagerService.product;
    this.location.back();
  }
}
