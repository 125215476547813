import { SnapshotVehicle } from '../../checkout/models/snapshot-vehicle.model';
import { Money } from "../../../financial/models/money.model";
import { MotorInsuranceLimitDetails } from "../../checkout/models/motor-insurance-limit-details.model";
import { Excess } from "./excess.model";
import { InsuranceLevy } from './insurance-levy.model';

export class MotorInsuranceVehiclePolicy {
    
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.MotorInsuranceVehiclePolicy"; 
    }
    
    public expiryDate: Date;
    public effectiveDate: Date;
    public price: Money;
    public insuranceVehicleCategorySubDescription: string;
    public certificateNumber: string;
    public snapshotVehicle: SnapshotVehicle;
    public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[] = [];
    public excesses: Excess[] = [];
    public insuranceLevy: InsuranceLevy[] = [];
    public vehicleValue: Money;
    public alarmImmobiliser: boolean;
    public cancelled: boolean;
}
