import {Component, OnInit, ViewChild} from '@angular/core';
import {ActiveToast, ToastrService} from "ngx-toastr";
import {QueryNaturalPersonComponent} from "../../../../../view.components/entity/query-natural-person/query-natural-person.component";
import {NaturalPersonComponent} from "../../../../../view.components/entity/natural-person/natural-person.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {LegalEntityComponent} from "../../../../../view.components/entity/legal-entity/legal-entity.component";
import {RetailProfileFinalisationManagerService} from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import {CredentialsComponent} from "../../../../../view.components/security/credentials/credentials.component";
import {FormComponent} from "../../../../../view.components/shared/form/form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component";
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard";
import {LocationStrategy} from "@angular/common";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {RetailProfileInformationProviderService} from "../../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service";
import {
    LegalEntityIdentifier,
    ProvideRetailProfileInformationRequest,
    TpiIdentifier,
    RetailProfileLegalEntityCriteria,
    NaturalPerson,
    LegalEntityProviderCriteria,
    QueryLegalEntityInformationRequest, UnMarshallerService, ProvideRetailProfileInformationResponse
} from "@magnabc/tpi";
import {Utils} from "../../../../../view.components/shared/Utils/Utils";
import {QueryLegalEntityInformationManagerService} from "../../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service";
import {AuthenticationService} from '../../../../../app.services/managers/authentication/authentication.service';
import { RaygunErrorHandler } from '../../../../../common/utils/utils.raygun';
import { QuickTransactManagerService } from '../../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {environment} from '../../../../../../environments/environment';
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
  selector: 'app-insurance-personal-quick-transact-manager',
  templateUrl: './insurance-personal-quick-transact-manager-page.component.html',
  styleUrls: ['./insurance-personal-quick-transact-manager-page.component.scss']
})
export class InsurancePersonalQuickTransactManagerPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps: any = [];
    utils = Utils;

    @ViewChild(QueryNaturalPersonComponent) entityQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    @ViewChild(LegalEntityComponent) legalEntityComponent: LegalEntityComponent;
    @ViewChild(CredentialsComponent) credentialsComponent: CredentialsComponent;

    activeToast: ActiveToast<any>;

    termsNotAccepted = false;
    formGroup: UntypedFormGroup;
    terms: UntypedFormControl;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ErrorToastService,
                private entityService: LegalEntityFinalisationManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private unMarshallerService: UnMarshallerService,
                private authorizationService: AuthenticationService,
                public quickStartService: QuickTransactManagerService, locationStrategy: LocationStrategy,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
        setTimeout(() => {
            this.backPressed = false; // For Firefox
        }, 500);

        this.steps = [];
        setTimeout(() => {

            if (!this.quickStartService.legalEntity) {
                this.quickStartService.legalEntity = new NaturalPerson();
            }

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            if (this.quickStartService.currentStep > 0) {
                this.setSteps();
            } else {
                this.setTitle();
            }


        });

    }

    onDeactivate(): boolean {
        if (this.quickStartService.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    setSteps(): void {

        this.steps = [];

        if (!this.quickStartService.exists) {

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-user", borderColor: "#318cdf"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-phone", borderColor: "#318cdf"});
            });

            this.translate.get("SECURITY.TITLE_ONE_TIME_PIN").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-mobile", borderColor: "#3171c3"});
            });

            this.translate.get("SECURITY.TITLE_VERIFY").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-check", borderColor: "#3171c3", hidden: true});
            });

            this.translate.get("QUICK_TRANSACT.TITLE_TRANSACTION_TYPE").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-arrows-h", borderColor: "#3171c3"});
            });

        }
        else {
            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("QUICK_TRANSACT.TITLE_TRANSACTION_TYPE").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-arrows-h", borderColor: "#3171c3"});
            });

        }

        this.setTitle();

    }

    ngOnInit(): void {
        if (this.quickStartService.type === 'business') {
            this.startLoad();
            this.authorizationService.reloadProxyInformation().then(() => {
                this.authorizationService.setJudicialSubscriptionByIdentifier(this.quickStartService.selectedLegalEntity.legalEntityIdentifiers);
                this.retailProfileService.refreshRetailProfile().then(retailProfile => {
                    this.quickStartService.retailProfile = retailProfile;
                    this.stopLoad();
                });
            });
        }

        this.terms = new UntypedFormControl(false, [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            terms: this.terms
        });

    }

    setTitle() {
        let title = `${this.steps[this.quickStartService.currentStep].name} - Assisted Quick Start - ${this.runtimeConfigurationService.title}`;
        if (this.quickStartService.currentStep === 0) {
            title = `Assisted Quick Start - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    onCheckValidation(): void {

        this.termsNotAccepted = false;
        if (this.formGroup.valid && this.terms.value) {
            this.onSubmit(null);
        } else {
            this.termsNotAccepted = true;
        }

    }

    isLocal(identifier): boolean {
        return identifier.countryOfIssue === 'ZM';
    }

    onSubmit(event): void {

        if (!this.quickStartService.exists) {

            switch (this.quickStartService.currentStep) {
                case 0:
                    this.entityQueryComponent.submit();
                    break;
                case 1:
                    this.naturalPersonComponent.submit();
                    break;
                case 2:
                    this.legalEntityComponent.submit();
                    break;
                case 3:
                    this.onTransactionType();
                    break;
                case 4:
                    this.onCart();
                    break;
            }
        }
        else {

             switch (this.quickStartService.currentStep) {
                 case 0:
                     this.entityQueryComponent.submit();
                     break;
                 case 1:
                     this.onTransactionType();
                     break;
                 case 2:
                     this.onCart();
                     break;
             }
         }
    }

    onCart(): void {

        if (this.quickStartService.retailProfile.cart && this.quickStartService.retailProfile.cart.items.length > 0) {
            this.router.navigate(['/retail/cart'], {queryParams: {quickTransact: true}});
        } else {
            this.toast("TOAST.SELECT_PRODUCT");
        }

    }

    onTransactionType(): void {

        if (this.quickStartService.type === 'personal') {
            this.entityService.legalEntity = this.quickStartService.legalEntity;
            this.getRetailProfile(this.quickStartService.legalEntity.legalEntityIdentifiers);
        } else if (this.quickStartService.selectedLegalEntity) {
            this.entityService.legalEntity = this.quickStartService.selectedLegalEntity;
            this.getRetailProfile(this.quickStartService.legalEntity.legalEntityIdentifiers);
        } else {
            this.toast("TOAST.SELECT_COMPANY");
        }

    }

    toast(message): void {

        this.translate.get(message).subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: false,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {
                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    onExists(exists): void {
        this.termsNotAccepted = false;
        this.quickStartService.exists = exists;
        if (this.terms.value) {
            if (exists === true) {
                this.quickStartService.loadQuickTransact = true;
                this.router.navigate(['login']);
                // this.retailProfileService.refreshRetailProfile().then(() => {
                //     this.quickStartService.retailProfile = this.retailProfileService.retailProfile;
                //     this.setSteps();
                //     this.onNext(null);
                // })
            } else {
                // new user
                alert('User could not be found.');
            }
        } else {
            this.termsNotAccepted = true;
        }

    }

    onNext(event): void {
        this.quickStartService.currentStep++;
        window.scrollTo(0, 0);
        this.setTitle();
    }

    onBack(event): void {
        if (this.quickStartService.currentStep > 0) {
            this.quickStartService.currentStep--;
            window.scrollTo(0, 0);
            this.setTitle();
        }
    }

    onNaturalPerson(naturalPerson): void {
        this.quickStartService.legalEntity = naturalPerson;
        if (this.quickStartService.legalEntity.contactPersons[0]) {
            if (!this.quickStartService.legalEntity.contactPersons[0].firstNames) {
                this.quickStartService.legalEntity.contactPersons[0].firstNames = this.quickStartService.getLegalEntityNames().name;
            }
            if (!this.quickStartService.legalEntity.contactPersons[0].surname) {
                this.quickStartService.legalEntity.contactPersons[0].surname = this.quickStartService.getLegalEntityNames().surname;
            }
        }
        if (this.quickStartService.legalEntity.contactNumbers && this.quickStartService.legalEntity.contactNumbers[0]) {
            this.quickStartService.contactNumber = this.quickStartService.legalEntity.contactNumbers[0];
        }
    }

    onLegalEntity(naturalPerson): void {
        this.quickStartService.legalEntity = naturalPerson;
        if (this.quickStartService.legalEntity.contactNumbers[0]) {
            if (!this.quickStartService.legalEntity.contactNumbers[0].number) {
                this.quickStartService.legalEntity.contactNumbers[0].number = this.quickStartService.legalEntity.contactPersons[0].contactNumbers[0].number;
            }
            this.quickStartService.contactNumber = this.quickStartService.legalEntity.contactNumbers[0];
        }
    }

    onStep(step: any): void {
        this.quickStartService.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    getLegalEntity(legalEntityIdentifier: LegalEntityIdentifier): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = legalEntityIdentifier;

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((response) => {
            this.quickStartService.legalEntity = response.legalEntities[0];
            this.entityService.legalEntity = this.quickStartService.legalEntity;
            this.getRetailProfile(response.legalEntities[0].legalEntityIdentifiers);
        }).catch(exception =>{
            RaygunErrorHandler.sendError(exception);

            console.log(exception);
        });

    }

    getRetailProfile(legalEntityIdentifiers: LegalEntityIdentifier[]) {

        const provideRetailProfileInformationRequest = new ProvideRetailProfileInformationRequest();
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {

                const retailProfileLegalEntityCriteria = new RetailProfileLegalEntityCriteria();
                retailProfileLegalEntityCriteria.tpiIdentifier = (legalEntityIdentifier as TpiIdentifier);
                provideRetailProfileInformationRequest.criteria = retailProfileLegalEntityCriteria;
                break;
            }
        }

        this.retailProfileInformationProviderService.provideRetailProfileInformation(provideRetailProfileInformationRequest).then(httpResponse => {

            if (httpResponse && httpResponse.body) {
                const response =
                    (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                        ProvideRetailProfileInformationResponse) as ProvideRetailProfileInformationResponse);
                this.quickStartService.retailProfile = response.retailProfile;
                this.quickStartService.currentStep++;
            }
        });

    }
}
