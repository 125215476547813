import { CountryIdentifier } from '../../../../location/geographic/identification/models/country-identifier.model'
import { Product } from '../../../products/models/product.model'
import { BrokerIdentifier, UnderwriterIdentifier } from '../../insurance_api'
import { Excess } from '../../models/excess.model'
import { Limit } from '../../models/limit.model'
import { InsuranceVehicleCategory } from '../../setup/models/insurance-vehicle-category.model'
import { YCPriceInterval } from './yc-price-interval.model'
import { YCPriceSchedule } from './yc-price-schedule.model'
import { AgentIdentifier } from '../../../../entity/identification/models/agent-identifier.model'
import { AgentLegalEntityIdentifier } from '../../../../entity/identification/models/agent-legal-entity-identifier.model'
import { Country } from '../../../../location/geographic/models/country.model'
import {ProductIdentifier} from '../../../products/identification/models/product-identifier.model'

export class YCInsuranceProduct extends Product {
    constructor() {
        super()
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsuranceProduct'
    }

    conditions: string
    broker: BrokerIdentifier
    underwriter: UnderwriterIdentifier
    excess: Excess[] = []
    agentIdentifiers: AgentIdentifier[]
    limits: Limit[] = []
    ycPriceSchedules: YCPriceSchedule[]
    ycPriceIntervals: YCPriceInterval[]
    insuranceVehicleCategory: InsuranceVehicleCategory
    coveredCountries: CountryIdentifier[] = []
    underwriterIdentifier: UnderwriterIdentifier
    agentLegalEntityIdentifier: AgentLegalEntityIdentifier
    brokerIdentifier: BrokerIdentifier
    sellingToCountry: Country
    productIdentifiers: ProductIdentifier[] = []
}
