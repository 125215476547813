import { LegalEntityIdentifier } from "../../../../../../../../entity/identification/models/legal-entity-identifier.model";
import { CountryCheckoutItemGrouping } from "../../../../../../../../retail/checkout/models/country-checkout-item-grouping.model";
import {Request} from "../../../../../../../../urdad/services/models/request.model";

export class InitialiseCountryCheckoutRequest extends Request {

  constructor(){
    super();
  }

  public countryCheckoutItemGrouping: CountryCheckoutItemGrouping;
  public legalEntityIdentifier: LegalEntityIdentifier;

}
