import {Request} from '../../../../../../../urdad/services/models/request.model';
import {AccountIdentifier} from '../../../../../identification/models/account-identifier.model';

export class FinaliseResetPasswordRequest extends Request {
    constructor() {
        super();
    }

    public password: string;
    public accountIdentifier: AccountIdentifier;
}
