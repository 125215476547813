import {Request} from '../../../../../../urdad/services/models/request.model';
import {Criteria} from '../../../../../../criteria/models/criteria.model';

export class ProvideAccountInformationRequest extends Request {
    constructor() {
        super();
    }

    criteria: Criteria;
}
