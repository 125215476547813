import {Component, OnInit} from '@angular/core'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    AuthenticationService
} from '../../../../../app.services/managers/authentication/authentication.service'
import {ServiceList} from '../../../../../common/model/service-list.model'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'

@Component({
  selector: 'app-assessments-page',
  templateUrl: './assessments-page.component.html',
  styleUrls: ['./assessments-page.component.scss']
})
export class AssessmentsPageComponent extends FormComponent implements OnInit {
    tabPanel = {};
    loading = false

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public authenticationService: AuthenticationService) {
        super(translate, translateParser);

    }

    ngOnInit() {}

    servicesAssessProposal(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.ASSESSPROPOSAL]));
    }

    servicesAssessYellowCard(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.ASSESSYELLOWCARD]));
    }

}
