import { Response } from "../../../../../../../urdad/services/models/response.model";
import { Subscription } from "../../../../../models/subscription.model";
import { JudicialPerson } from '../../../../../../../entity/models/judicial-person.model';

export class FinaliseCreatePrincipalByUpdateResponse extends Response {
    constructor(){
        super();
    }

    public subscription: Subscription;
    public ownedBy: JudicialPerson;
}
