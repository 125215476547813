import { Response } from "../../../../../urdad/services/models/response.model";
import { RenderedDocument } from "../../../../models/rendered-document.model";

export class RenderDocumentResponse extends Response {

  constructor(){
    super();
  }

  public documentId: string;
  public renderedDocument: RenderedDocument[] = [];

}
