import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {SharedModule} from '../../../shared/shared.module'
import {LoadingModule} from 'ngx-loading'
import {IssueCertificatesComponent} from './issue-certificates/issue-certificates.component'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'


@NgModule({
    declarations: [
        IssueCertificatesComponent,
    ],
    exports: [
        IssueCertificatesComponent
    ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        LoadingModule,
        NgxDatatableModule
    ]
})
export class CertificateModule { }
