import {Response} from "../../urdad/services/models/response.model";


export class RetrieveExchangeRateResponse extends Response {
  constructor(){
      super();
  }

  exchangeRate: any

}