import { UnderwriterIdentifier } from "../../../../../../identification/models/underwriter-identifier.model";
import { Request } from "../../../../../../../../urdad/services/models/request.model";
import { LegalEntityIdentifier } from "../../../../../../../../entity/identification/models/legal-entity-identifier.model";

export class AssessProposalRequest extends Request{
    constructor(){
        super();
    }

    public underwriterIdentifier : UnderwriterIdentifier;
    public assessor: LegalEntityIdentifier;
    public proposalId: string;
}