import {Pipe, PipeTransform} from '@angular/core';
import {CountryTaxesCountryCriteria, ProvideCountryTaxesInformationRequest, Product, Money} from '@magnabc/tpi';
import { CountryTaxesInformationProviderService } from '../../../http.services/financial/country-taxes-information-provider/country-taxes-information-provider.service';
import {StringUtils} from '../../../common/utils/utils.string';

@Pipe({name: 'formatAmount', pure: false})
export class FormatAmountPipe implements PipeTransform {

    constructor() {}

    transform(value: any): string {
        if (value instanceof Money || value.amount) {
            return parseFloat(value.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }
        return `${parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }
}
