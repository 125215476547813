import {Response} from '../../../../../../../urdad/services/models/response.model';
import {OneTimePinIdentifier} from '../../../../../models/one-time-pin-identifier.model';

export class ValidateObscuredContactNumberResponse extends Response {
    constructor(){
        super();
    }

    oneTimePinIdentifier: OneTimePinIdentifier;
}
