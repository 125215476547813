import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {ProvideCategoryIdentifiersRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class UsageIdentifierProviderService {

    basePath = '/vehicle/identification/usageidentifierprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideUsages(provideCategoryIdentifiersRequest: ProvideCategoryIdentifiersRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl +this.basePath + '/provideusageidentifiers';
        return this.http.post(path, provideCategoryIdentifiersRequest, this.options);
    }

}
