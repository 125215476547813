import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'
import { CountryDescriptionIdentifier, InsuranceCategory } from '@magnabc/tpi'

declare const jQuery: any

@Component({
    selector: 'app-product-breakdown',
    templateUrl: './product-breakdown.component.html',
    styleUrls: ['./product-breakdown.component.scss']
})
export class ProductBreakdownComponent implements OnInit {

    @Output() onToggleBreakdown = new EventEmitter<boolean>()
    insuranceCategory = InsuranceCategory;

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {
    }

    toggleBreakDown(show: boolean) {
        this.onToggleBreakdown.emit(show)
        this.scrollToBreakdown()
        return false
    }

    scrollToBreakdown() {
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            const scrollTo = jQuery('#breakdown')
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top - 50 + container.scrollTop()
            })
        })
    }

    /**
     * Returns the list of countries that support yellow card
     * @returns {CountryDescriptionIdentifier[]} the list of countries that support yellow card
     */
    getYellowCardSupportedCountries(): CountryDescriptionIdentifier[] {
        const countries = [];
        const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
        countryDescriptionIdentifier.description = 'South Africa';
        countries.push(countryDescriptionIdentifier);

        return countries;
    }
}
