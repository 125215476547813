import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {Utils} from "../../../../shared/Utils/Utils"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {
  CountryCheckoutFinalisationManagerService
} from "../../../../../http.services/malawi/retail/checkout/country-checkout-finalisation-manager/country-checkout-finalisation-manager.service"
import {SourceOfFundsComponent} from "../../../../entity/kyc/source-of-funds/source-of-funds.component"
import {
  CountryRegistrationConfiguration,
  CountryRegistrationConfigurationInformation,
  DocumentType,
  FinaliseCheckoutInformationRequest,
  JudicialPersonCountryRegistrationConfigurationInformation,
  LegalEntity,
  NaturalPersonCountryRegistrationConfigurationInformation,
  PickUpPoint,
  RequiredJudicialPersonCountryRegistrationInformation,
  RequiredNaturalPersonCountryRegistrationInformation,
  ScannedDocument,
  ScannedDocumentIdentifier,
  ShippingAddress,
  ShownJudicialPersonCountryRegistrationInformation,
  ShownNaturalPersonCountryRegistrationInformation
} from '@magnabc/tpi'
import {CheckoutManagerComponent} from "../../../../retail/checkout/checkout-manager/checkout-manager.component"
import {SourceOfWealthComponent} from "../../../../entity/kyc/source-of-wealth/source-of-wealth.component"
import {ProofOfExistenceComponent} from "../../../../entity/kyc/proof-of-existence/proof-of-existence.component"
import {ScannedDocumentComponent} from "../../../../entity/kyc/scanned-document/scanned-document.component"
import {ShipmentCaptureComponent} from '../../../../shared/shipment-capture/shipment-capture.component'
import {FormGroup} from '@angular/forms'
import {LegalEntityDetails} from '@magnabc/tpi/src/entity/models/legal-entity-details.model'

@Component({
    selector: 'app-malawi-country-checkout-manager',
    templateUrl: './malawi-country-checkout-manager.component.html',
    styleUrls: ['./malawi-country-checkout-manager.component.scss']
})
export class MalawiCountryCheckoutManagerComponent extends CheckoutManagerComponent implements OnInit {

    @Input() legalEntity: LegalEntity;
    @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    @Input() countryRegistrationConfigurationList: CountryRegistrationConfiguration[] = [];
    @Input() naturalPerson: boolean;
    @Input() judicialPerson: boolean;
    @Input() countryRegistrationConfigurationInformationProvided: boolean;
    @Input() scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
    @Input() scannedDocumentIdentifierMap: { [id: string]: ScannedDocumentIdentifier } = {};
    @Input() pickUpPoints: PickUpPoint[];
    @Input() legalEntityDetails: LegalEntityDetails;

    @Output() formCaptureShipment = new EventEmitter<FormGroup>();

    uploadPdf = true;

    utils = Utils;

    activeToast: ActiveToast<any>;

    scannedDocuments: ScannedDocument[] = [];
    show: ShownJudicialPersonCountryRegistrationInformation | ShownNaturalPersonCountryRegistrationInformation;
    showYC: ShownJudicialPersonCountryRegistrationInformation | ShownNaturalPersonCountryRegistrationInformation;

    required: RequiredJudicialPersonCountryRegistrationInformation | RequiredNaturalPersonCountryRegistrationInformation;
    scannedDocumentRequiredMap: { [id: string]: boolean } = {};
    loading = false;
    showYCcaptureShipping: boolean
    showThirdPart: boolean

    @ViewChild(SourceOfFundsComponent) sourceOfFundsComponent: SourceOfFundsComponent;
    @ViewChild(SourceOfWealthComponent) sourceOfWealthComponent: SourceOfWealthComponent;
    @ViewChild(ProofOfExistenceComponent) proofOfExistenceComponent: ProofOfExistenceComponent;
    @ViewChild(ShipmentCaptureComponent) shipmentCaptureComponent: ShipmentCaptureComponent;
    @ViewChild(ScannedDocumentComponent) scannedDocumentComponent: ScannedDocumentComponent;

    private readonly ycDocumentDescriptions = ['Company Letter Head', 'Cipro Document'];

    constructor(private countryCheckoutFinalisationManagerService: CountryCheckoutFinalisationManagerService,
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private toastr: ToastrService) {
        super();
    }

    ngOnInit() {
        if (!this.countryRegistrationConfigurationInformationProvided || !this.countryRegistrationConfigurationInformation) {
            if (this.judicialPerson) {
                this.countryRegistrationConfigurationInformation = new JudicialPersonCountryRegistrationConfigurationInformation()
            } else {
                this.countryRegistrationConfigurationInformation = new NaturalPersonCountryRegistrationConfigurationInformation()
            }
        }
        if (this.judicialPerson) {
            for (const configuration of this.countryRegistrationConfigurationList) {
                if (configuration instanceof ShownJudicialPersonCountryRegistrationInformation) {
                    this.setDocumentsToShow(configuration);
                } else if (configuration instanceof RequiredJudicialPersonCountryRegistrationInformation) {
                    this.required = configuration;
                    for (const scannedDocument of this.required.requiredDocuments) {
                        this.scannedDocumentRequiredMap[(scannedDocument as any).description] = true;
                    }
                }
            }
        } else {
            for (const configuration of this.countryRegistrationConfigurationList) {
                if (configuration instanceof ShownNaturalPersonCountryRegistrationInformation) {
                    this.setDocumentsToShow(configuration);
                } else if (configuration instanceof RequiredNaturalPersonCountryRegistrationInformation) {
                    this.required = configuration;
                    for (const scannedDocument of this.required.requiredDocuments) {
                        this.scannedDocumentRequiredMap[(scannedDocument as any).description] = true;
                    }
                }
            }
        }
    }

    private setDocumentsToShow(configuration: ShownJudicialPersonCountryRegistrationInformation |
        ShownNaturalPersonCountryRegistrationInformation): void {
        if (!this.show) {
            this.show = configuration;
        }

        if (configuration.shipping) {
            this.showYC = configuration;
            const normalDocuments = this.show.shownDocuments.filter(item =>
                !this.ycDocumentDescriptions.includes(item['description'].trim()));
            this.show.shownDocuments = normalDocuments;
        }

        this.updateShowFields(configuration);
    }

    private updateShowFields(configuration: ShownJudicialPersonCountryRegistrationInformation |
        ShownNaturalPersonCountryRegistrationInformation): void {
        const naturalPersonClass = 'za.co.magnabc.tpi.retail.insurance.setup.ShownNaturalPersonCountryRegistrationInformation';
        const judicialPersonClass = 'za.co.magnabc.tpi.retail.insurance.setup.ShownJudicialPersonCountryRegistrationInformation';

        if (this.show && (configuration['@class'] && configuration['@class'] === naturalPersonClass)
            && (this.show['@class'] && this.show['@class'] === naturalPersonClass)) {
            this.show = this.show as ShownNaturalPersonCountryRegistrationInformation;
            configuration = configuration as ShownNaturalPersonCountryRegistrationInformation;
            this.show.contactEmail = configuration.contactEmail ? configuration.contactEmail : this.show.contactEmail;
            this.show.contactNumber = configuration.contactNumber ? configuration.contactNumber : this.show.contactNumber;
            this.show.nationalIdentityNumber = configuration.nationalIdentityNumber
                ? configuration.nationalIdentityNumber : this.show.nationalIdentityNumber;
            this.show.nationality = configuration.nationality ? configuration.nationality : this.show.nationality;
            this.show.nationalityStatus = configuration.nationalityStatus
                ? configuration.nationalityStatus : this.show.nationalityStatus;
            this.show.occupation = configuration.vatNumber ? configuration.vatNumber : this.show.occupation;
            this.show.physicalAddress = configuration.physicalAddress
                ? configuration.physicalAddress : this.show.physicalAddress;
            this.show.postalAddress = configuration.postalAddress
                ? configuration.postalAddress : this.show.postalAddress;
            this.show.sourceOfIncome = configuration.sourceOfIncome ? configuration.sourceOfIncome : this.show.sourceOfIncome;
            this.show.occupation = configuration.occupation ? configuration.occupation : this.show.occupation;
            this.show.politicallyExposed = configuration.politicallyExposed ? configuration.politicallyExposed : this.show.politicallyExposed;
        } else if (this.show && (configuration['@class'] && configuration['@class'] === judicialPersonClass)
            && (this.show['@class'] && this.show['@class'] === judicialPersonClass)) {
            this.show = this.show as ShownJudicialPersonCountryRegistrationInformation;
            configuration = configuration as ShownJudicialPersonCountryRegistrationInformation;

            this.show.authorisedSignatoryName = configuration.authorisedSignatoryName
                ? configuration.authorisedSignatoryName : this.show.authorisedSignatoryName;
            this.show.authorisedSignatoryPosition = configuration.authorisedSignatoryPosition
                ? configuration.authorisedSignatoryPosition : this.show.authorisedSignatoryPosition;
            this.show.dateOfIncorporation = configuration.dateOfIncorporation
                ? configuration.dateOfIncorporation : this.show.dateOfIncorporation;
            this.show.industry = configuration.industry ? configuration.industry : this.show.industry;
            this.show.registrationNumber = configuration.registrationNumber
                ? configuration.registrationNumber : this.show.registrationNumber;
            this.show.seniorManagerName = configuration.seniorManagerName
                ? configuration.seniorManagerName : this.show.seniorManagerName;
            this.show.taxNumber = configuration.taxNumber ? configuration.taxNumber : this.show.taxNumber;
        }

        let showDocuments = this.show.shownDocuments;
        configuration.shownDocuments.forEach((item) => {
            const castItem = (item as any) as DocumentType;
            if (castItem) {
                const found = showDocuments.filter(doc => {
                    const castDoc = (doc as any) as DocumentType;
                    if (castDoc) {
                        return castDoc.description === castItem.description;
                    }
                })
                if (found.length === 0) {
                    showDocuments.push(item);
                }
            }
        });
        this.show.shownDocuments = showDocuments;

        this.show.bankAccountNumber = configuration.bankAccountNumber ? configuration.bankAccountNumber : this.show.bankAccountNumber;
        this.show.bankName = configuration.bankAccountNumber ? configuration.bankAccountNumber : this.show.bankAccountNumber;
        this.show.income = configuration.income ? configuration.income : this.show.income;
        this.show.shipping = configuration.shipping ? configuration.shipping : this.show.shipping;
        this.show.vatNumber = configuration.vatNumber ? configuration.vatNumber : this.show.vatNumber;
    }

    validate(): Promise<any> {
        return this.finaliseCheckoutInformation()
    }

    finaliseCheckoutInformation(): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            if (this.sourceOfFundsComponent) {
                this.sourceOfFundsComponent.submit();
            }

            if (this.sourceOfFundsComponent && !this.sourceOfFundsComponent.valid()) {
                reject();
                return;
            }

            if (this.sourceOfWealthComponent) {
                this.sourceOfWealthComponent.submit();
            }

            if (this.sourceOfWealthComponent && !this.sourceOfWealthComponent.valid()) {
                reject();
                return;
            }

            if (this.proofOfExistenceComponent) {
                this.proofOfExistenceComponent.submit();
            }

            if (this.proofOfExistenceComponent && !this.proofOfExistenceComponent.valid()) {
                reject();
                return;
            }

            if (this.shipmentCaptureComponent) {
                this.shipmentCaptureComponent.submit();
            }

            if (this.shipmentCaptureComponent && !this.shipmentCaptureComponent.valid()) {
                reject();
                return;
            }

            if (this.scannedDocumentComponent) {
                this.scannedDocumentComponent.submit();
            }

            if (this.scannedDocumentComponent && !this.scannedDocumentComponent.valid()) {
                reject();
                return;
            }

            const finaliseCheckoutInformationRequest = new FinaliseCheckoutInformationRequest()
            finaliseCheckoutInformationRequest.scannedDocument = this.scannedDocuments;
            finaliseCheckoutInformationRequest.countryRegistrationConfigurationInformationCaptured = true;
            finaliseCheckoutInformationRequest.countryRegistrationConfigurationInformationProvided = this.countryRegistrationConfigurationInformationProvided;
            finaliseCheckoutInformationRequest.legalEntityDetails = this.legalEntityDetails;

            if (this.judicialPerson) {
                const countryRegistrationConfigurationInformation = new JudicialPersonCountryRegistrationConfigurationInformation()
                countryRegistrationConfigurationInformation.legalEntityIdentifier = Utils.getTpiIdentifier(this.legalEntity.legalEntityIdentifiers)
                countryRegistrationConfigurationInformation.proofOfExistenceIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier
                countryRegistrationConfigurationInformation.ciproDocumentIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier
                countryRegistrationConfigurationInformation.companyLetterHeadIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier
                countryRegistrationConfigurationInformation.authorisedSignatoryName = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).authorisedSignatoryName
                countryRegistrationConfigurationInformation.authorisedSignatoryPosition = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).authorisedSignatoryPosition
                countryRegistrationConfigurationInformation.seniorManagerNames = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).seniorManagerNames
                countryRegistrationConfigurationInformation.vatNumber = this.countryRegistrationConfigurationInformation.vatNumber
                countryRegistrationConfigurationInformation.bankName = this.countryRegistrationConfigurationInformation.bankName
                countryRegistrationConfigurationInformation.bankAccountNumber = this.countryRegistrationConfigurationInformation.bankAccountNumber
                countryRegistrationConfigurationInformation.income = this.countryRegistrationConfigurationInformation.income
                countryRegistrationConfigurationInformation.sourceOfIncome = this.countryRegistrationConfigurationInformation.sourceOfIncome
                countryRegistrationConfigurationInformation.shippingAddressLineOne = this.countryRegistrationConfigurationInformation.shippingAddressLineOne
                countryRegistrationConfigurationInformation.shippingAddressLineTwo = this.countryRegistrationConfigurationInformation.shippingAddressLineTwo
                countryRegistrationConfigurationInformation.shippingAddressLineThree = this.countryRegistrationConfigurationInformation.shippingAddressLineThree
                countryRegistrationConfigurationInformation.shippingAddressCity = this.countryRegistrationConfigurationInformation.shippingAddressCity
                countryRegistrationConfigurationInformation.shippingAddressCode = this.countryRegistrationConfigurationInformation.shippingAddressCode
                countryRegistrationConfigurationInformation.proofOfAddressIdentifier = this.countryRegistrationConfigurationInformation.proofOfAddressIdentifier
                countryRegistrationConfigurationInformation.shipmentType = this.countryRegistrationConfigurationInformation.shipmentType
                countryRegistrationConfigurationInformation.shippingCost = this.countryRegistrationConfigurationInformation.shippingCost
                finaliseCheckoutInformationRequest.countryRegistrationConfigurationInformation = countryRegistrationConfigurationInformation
            } else {
                const countryRegistrationConfigurationInformation = new NaturalPersonCountryRegistrationConfigurationInformation()
                countryRegistrationConfigurationInformation.legalEntityIdentifier = Utils.getTpiIdentifier(this.legalEntity.legalEntityIdentifiers)
                countryRegistrationConfigurationInformation.proofOfIdentification = (this.countryRegistrationConfigurationInformation as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification
                countryRegistrationConfigurationInformation.vatNumber = this.countryRegistrationConfigurationInformation.vatNumber
                countryRegistrationConfigurationInformation.bankName = this.countryRegistrationConfigurationInformation.bankName
                countryRegistrationConfigurationInformation.bankAccountNumber = this.countryRegistrationConfigurationInformation.bankAccountNumber
                countryRegistrationConfigurationInformation.income = this.countryRegistrationConfigurationInformation.income
                countryRegistrationConfigurationInformation.sourceOfIncome = this.countryRegistrationConfigurationInformation.sourceOfIncome
                countryRegistrationConfigurationInformation.shippingAddressLineOne = this.countryRegistrationConfigurationInformation.shippingAddressLineOne
                countryRegistrationConfigurationInformation.shippingAddressLineTwo = this.countryRegistrationConfigurationInformation.shippingAddressLineTwo
                countryRegistrationConfigurationInformation.shippingAddressLineThree = this.countryRegistrationConfigurationInformation.shippingAddressLineThree
                countryRegistrationConfigurationInformation.shippingAddressCity = this.countryRegistrationConfigurationInformation.shippingAddressCity
                countryRegistrationConfigurationInformation.shippingAddressCode = this.countryRegistrationConfigurationInformation.shippingAddressCode
                countryRegistrationConfigurationInformation.proofOfAddressIdentifier = this.countryRegistrationConfigurationInformation.proofOfAddressIdentifier
                countryRegistrationConfigurationInformation.shipmentType = this.countryRegistrationConfigurationInformation.shipmentType
                countryRegistrationConfigurationInformation.shippingCost = this.countryRegistrationConfigurationInformation.shippingCost
                finaliseCheckoutInformationRequest.countryRegistrationConfigurationInformation = countryRegistrationConfigurationInformation
            }
            this.countryCheckoutFinalisationManagerService.finaliseCheckoutInformation(finaliseCheckoutInformationRequest).subscribe((response) => {
                resolve({})
            })

        })
    }

    checkRequiredDocument(): boolean {
        // return true
        if (this.scannedDocuments.length > 0) {
            for (const reqDoc of this.required.requiredDocuments) {
                if (this.scannedDocuments.some(scannedDocument => scannedDocument.documentType.description === (reqDoc as unknown as DocumentType).description)) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            // FIXME check all documents are provided
            return true
        }
    }

    onSourceOfFunds(sourceOfFunds): void {
        this.countryRegistrationConfigurationInformation.bankName = sourceOfFunds.bankName;
        this.countryRegistrationConfigurationInformation.bankAccountNumber = sourceOfFunds.bankAccountNumber;
    }

    onSourceOfWealth(sourceOfWealth): void {
        this.countryRegistrationConfigurationInformation.income = sourceOfWealth.income;
        this.countryRegistrationConfigurationInformation.sourceOfIncome = sourceOfWealth.sourceOfIncome;
    }

    onProofOfExistence(proofOfExistence): void {
        this.countryRegistrationConfigurationInformation.vatNumber = proofOfExistence.vatNumber
        if (this.countryRegistrationConfigurationInformation instanceof JudicialPersonCountryRegistrationConfigurationInformation) {
            this.countryRegistrationConfigurationInformation.authorisedSignatoryName = proofOfExistence.authorisedSignatoryName;
            this.countryRegistrationConfigurationInformation.authorisedSignatoryPosition = proofOfExistence.authorisedSignatoryPosition;
            this.countryRegistrationConfigurationInformation.seniorManagerNames = proofOfExistence.seniorManagerNames;
        }
    }

    onShipmentCapture(shippingAddress: ShippingAddress): void {
        this.countryRegistrationConfigurationInformation.shippingAddressLineOne = shippingAddress.shippingAddressLineOne
        this.countryRegistrationConfigurationInformation.shippingAddressLineTwo = shippingAddress.shippingAddressLineTwo
        this.countryRegistrationConfigurationInformation.shippingAddressLineThree = shippingAddress.shippingAddressLineThree
        this.countryRegistrationConfigurationInformation.shippingAddressCity = shippingAddress.shippingAddressCity
        this.countryRegistrationConfigurationInformation.shippingAddressCode = shippingAddress.shippingAddressCode;
    }

    onScannedDocument(scannedDocument: ScannedDocument): void {
        if (scannedDocument.documentIdentifier) {
            for (let i = 0; i < this.scannedDocuments.length; i++) {
                if (this.scannedDocuments[i].documentType.description === scannedDocument.documentType.description) {
                    this.scannedDocuments.splice(i, 1);
                    break;
                }
            }
            this.scannedDocuments.push(scannedDocument);
        }
        if (scannedDocument.documentType.description === 'Proof of Address') {
            this.countryRegistrationConfigurationInformation.proofOfAddressIdentifier = scannedDocument.documentIdentifier
        }
        if (scannedDocument.documentType.description === 'National ID / Passport') {
            (this.countryRegistrationConfigurationInformation as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification = scannedDocument.documentIdentifier
        }
        if (scannedDocument.documentType.description === 'Proof of Existence') {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier = scannedDocument.documentIdentifier
        }
        if (scannedDocument.documentType.description === 'Company Registration') {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier = scannedDocument.documentIdentifier
        }
        if (scannedDocument.documentType.description === 'Letterhead') {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier = scannedDocument.documentIdentifier
        }
    }

    onRemoveDocument(scannedDocumentIdentifier: ScannedDocumentIdentifier): void {

        const scannedDocumentsArray = [...this.scannedDocuments];
        if (scannedDocumentIdentifier) {
            this.scannedDocuments = scannedDocumentsArray.filter(id => (id as any).documentIdentifier !== (scannedDocumentIdentifier as any).documentIdentifier);
            this.scannedDocumentIdentifiers = this.scannedDocumentIdentifiers.filter(id => (id as any).documentIdentifier !== (scannedDocumentIdentifier as any).documentIdentifier);;
        }

        if ((this.countryRegistrationConfigurationInformation as any).proofOfIdentification && (scannedDocumentIdentifier as any).documentIdentifier === (this.countryRegistrationConfigurationInformation as any).proofOfIdentification) {
            (this.countryRegistrationConfigurationInformation as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification = null
        }
        if ((this.countryRegistrationConfigurationInformation as any).proofOfExistenceIdentifier && (scannedDocumentIdentifier as any).documentIdentifier === (this.countryRegistrationConfigurationInformation as any).proofOfExistenceIdentifier) {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier = null
        }
        if ((this.countryRegistrationConfigurationInformation as any).proofOfAddressIdentifier && (scannedDocumentIdentifier as any).documentIdentifier === (this.countryRegistrationConfigurationInformation as any).proofOfAddressIdentifier) {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfAddressIdentifier = null
        }
        if ((this.countryRegistrationConfigurationInformation as any).ciproDocumentIdentifier && (scannedDocumentIdentifier as any).documentIdentifier === (this.countryRegistrationConfigurationInformation as any).ciproDocumentIdentifier) {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier = null
        }
        if ((this.countryRegistrationConfigurationInformation as any).companyLetterHeadIdentifier && (scannedDocumentIdentifier as any).documentIdentifier === (this.countryRegistrationConfigurationInformation as any).companyLetterHeadIdentifier) {
            (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier = null
        }

    }

    onShipmentFormChanges(form: FormGroup): void {
        this.formCaptureShipment.emit(form);
    }

    onLegalEntityDetails(data): void {
      this.legalEntityDetails = data;
    }
}
