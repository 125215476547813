import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/entity_api";
import { TimeFrame } from "./time-frame.model";

export class PopularProductsLegalEntityCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.PopularProductsLegalEntityCriteria";
    }

    public dashboardPeriod : TimeFrame;
    public legalEntityIdentifier : LegalEntityIdentifier;
}