import { JudicialPersonIdentifier } from '../../models/judicial-person-identifier.model';

export class TrafficRegisterNumber extends JudicialPersonIdentifier
{
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.identification.judicialperson.TrafficRegisterNumber";
  }

  public number: string;
  public countryOfIssue: string;

}
