import {Criteria} from '../../../criteria/models/criteria.model';

export class UnderwriterCriteria extends Criteria {

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.UnderwriterCriteria";
    }

    identifier: string;
}
