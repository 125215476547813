export class GpsLocation
{
  	constructor(){
      this['@class'] = "za.co.magnabc.tpi.location.geographic.GpsLocation";
    }

    public latitude: number;
	  public longitude: number;


}
