import {Criteria} from '../../../../../../../criteria/models/criteria.model';
import { Request } from '../../../../../../../urdad/services/models/request.model';

export class QueryPublicInsurancePolicyInformationRequest extends Request{
    constructor(){
        super();
    }

    public criteria: Criteria;
}
