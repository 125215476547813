import { NaturalPersonIdentifier } from "./natural-person-identifier.model";

export class NationalIdentityNumber extends NaturalPersonIdentifier
{
  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.entity.identification.NationalIdentityNumber";
  }
    public countryOfIssue: string;
    public number: string;

}
