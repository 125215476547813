import { Request } from '../../../../../../urdad/services/models/request.model';
import {CountryIdentifier} from "../../../../../../location/geographic/identification/models/country-identifier.model"
import { InsuranceCategory } from '../../../../models/insurance-category.model';

export class ProvideCountryUnderwriterProductInformationRequest extends Request {
    constructor(){
        super();
    }

    public insuranceCategory: InsuranceCategory;
    public countryIdentifier: CountryIdentifier;
}