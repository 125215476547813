import { ServiceIdentifier } from "./service-identifier.model";

export class ServiceNameTypeIdentifier extends ServiceIdentifier {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.identification.ServiceNameTypeIdentifier";
    }

    public name: string;
    public type: string [] = [];
}