import { VehicleImportError, ImportFleetResponse } from '@magnabc/tpi';
import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../../../view.components/shared/form/form.component';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-import-fleet-page',
  templateUrl: './import-fleet-page.component.html',
  styleUrls: ['./import-fleet-page.component.scss']
})
export class ImportFleetPageComponent extends FormComponent implements OnInit {
  importFleetResponse: ImportFleetResponse;

  constructor(private translate: TranslateService,
    private translateParser: TranslateParser,) {
    super(translate, translateParser);
  }

  ngOnInit() {
  }

  onUploadDone(data: ImportFleetResponse): void {
    this.importFleetResponse = data;
  };

  resetUpload(): void {
    this.importFleetResponse = null;
  }
}
