import { Criteria } from '../../../../../../../criteria/models/criteria.model';
import { Request } from '../../../../../../../urdad/services/models/request.model';

export class ProvideSupplierInvoiceSummaryRequest extends Request {
    constructor(){
        super();
    }

    public batchSize: number;
    public batchNumber: number;
    public criteria: Criteria;
}
