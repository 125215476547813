export class PaymentOption{
    tokenIdentifier: string;
    cardType: string;
    cardLastFourDigits: string;

    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.PaymentOption";
    }

}
