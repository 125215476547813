import { LevyType } from "./levy-type.model";
import { Money } from "./money.model";

export class Levy{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.financial.Levy";
    }

    public levyType: LevyType;
	public levy: Money;
}