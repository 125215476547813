import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../shared/form/form.component";
import {Dropdown} from "../../../common/model/dropdown.model";
import {CountryComponent} from "../../shared/country/country.component";
import {
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    NaturalPerson,
    LegalEntityProviderCriteria,
    NationalIdentityNumber,
    QueryLegalEntityInformationRequest,
    UnMarshallerService,
    QueryLegalEntityInformationResponse,
    InitialiseCreateVerifiedAccountRequest,
    PassportNumber,
    NaturalPersonIdentifier
} from '@magnabc/tpi';
import {QueryLegalEntityInformationManagerService} from "../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service";
import {Utils} from '../../shared/Utils/Utils';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';
import { QuickTransactManagerService } from '../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';

@Component({
  selector: 'app-natural-person-query',
  templateUrl: './query-natural-person.component.html',
  styleUrls: ['./query-natural-person.component.scss']
})
export class QueryNaturalPersonComponent extends FormComponent implements OnInit {

    @Input() naturalPerson: NaturalPerson;
    @Input() title: string;
    @Input() isProposalQuery: boolean;
    @Output() onNaturalPerson = new EventEmitter<NaturalPerson>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onExists = new EventEmitter<boolean>();

    types = [
        new Dropdown(1, "National Identity Number"),
        new Dropdown(2, "Passport Number")
    ];

    formGroup: UntypedFormGroup;
    type: UntypedFormControl;
    identifier: UntypedFormControl;
    typePlaceholder: string;
    country: CountryDialingIdentifier;
    userMap: {[id: string]: NaturalPerson} = {};
    naturalPersonIdentifier: NaturalPersonIdentifier;
    maxlength = 15;

    @ViewChild(CountryComponent) countryComponent: CountryComponent;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private unMarshallerService: UnMarshallerService,
                private quickStartService: QuickTransactManagerService) {
        super(translate, translateParser);

        this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";

    }

    ngOnInit(): void {

        this.type = new UntypedFormControl(this.types[0], []);

        this.identifier = new UntypedFormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
        ]);

        this.formGroup = new UntypedFormGroup({
            'type': this.type,
            'identifier': this.identifier,
        });

        if (this.quickStartService.npIdentifier) {
            const nationalIdentity = (Utils.getNationalIdentityNumber([this.quickStartService.npIdentifier]) as NationalIdentityNumber);
            this.identifier.setValue(nationalIdentity.number);
            setTimeout(() => {
                this.countryComponent.setCountryByAlphaTwo(nationalIdentity.countryOfIssue);
            },10);
        }
    }

    onCountry(country): void {
        this.country = country;
    }

    onCountryValid(event): void {
        document.getElementById('form-submit-query').click();
    }

    onTypeChanged(event): void {
        if (this.type.value.value === 1) {
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
            this.maxlength = 15;
        }else if (this.type.value.value === 2){
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_PN";
            this.maxlength = 9;
        }

    }

    submit(): void {
        this.countryComponent.submit();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {
            const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
            legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";

            const countryDescriptionIdentifier = new CountryDescriptionIdentifier()
            countryDescriptionIdentifier.description = this.country.alphaTwo;
            legalEntityProviderCriteria.countryIdentifier = countryDescriptionIdentifier;

            if(this.type.value.value === 1){
                const nationalIdentityNumber = new NationalIdentityNumber();
                nationalIdentityNumber.countryOfIssue =  this.country.alphaTwo;
                nationalIdentityNumber.number = this.identifier.value.toLocaleUpperCase();
                legalEntityProviderCriteria.legalEntityIdentifier = nationalIdentityNumber;

                this.naturalPersonIdentifier = nationalIdentityNumber;
            }else if(this.type.value.value === 2){
                const passportNumber = new PassportNumber();
                passportNumber.countryOfIssue =  this.country.alphaTwo;
                passportNumber.number = this.identifier.value.toLocaleUpperCase();
                legalEntityProviderCriteria.legalEntityIdentifier = passportNumber;

                this.naturalPersonIdentifier = passportNumber;
            }


            const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
            queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

            this.startLoad();

            const request = new InitialiseCreateVerifiedAccountRequest();
            request.naturalPersonIdentifier = this.naturalPersonIdentifier;
            this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then(httpResponse => {
                this.stopLoad();
                let exists = false;

                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                if(response.legalEntities.length > 0)
                {
                    exists = true;
                    this.naturalPerson = response.legalEntities[0] as NaturalPerson;
                }  else {
                    this.naturalPerson = new NaturalPerson();
                    this.naturalPerson.legalEntityIdentifiers = [];
                }

                this.onNaturalPerson.emit(this.naturalPerson);
                this.onValid.emit();
                this.onExists.emit(exists);
            }).catch(exception=>{
                RaygunErrorHandler.sendError(exception);
                this.stopLoad();
                console.log(exception);
            });
        }
    }

    getValueId(value) {
        return value.replace(/\s/g, "")
    }
}
