import { InsuranceSalesByProductEntry } from "./insurance-sales-by-product-entry.model";

export class InsuranceSalesByProductReport
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.InsuranceSalesByProductReport";
    }

    public dateFrom: Date;
    public dateTo: Date;
    public insuranceSalesByProductEntries: InsuranceSalesByProductEntry[] = [];
}
