import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {CountryStartDateConfiguration} from "../../../../../models/country-start-date-configuration.model";

export class ProvideCountryStartDateConfigurationResponse extends Response {
    constructor() {
        super();
    }

    countryStartDateConfiguration: CountryStartDateConfiguration;
}
