import {Response} from "../../../../../urdad/services/models/response.model";
import { Vehicle } from '../../../../models/vehicle.model';

export class QueryVehicleInformationResponse extends Response {
    constructor(){
        super();
    }

    public vehicles :Vehicle[] = [];
}
