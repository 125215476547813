import {Response} from "../../../../../../../urdad/services/models/response.model";
import { Make } from "../../../../../models/make.model";

export class ProvideVehicleMakesResponse extends Response{
    constructor(){
        super();
    }

    public makes:Make []=[];
}
