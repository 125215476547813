import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {FormComponent} from "../../shared/form/form.component"
import {Dropdown} from "../../../common/model/dropdown.model"
import {CountryComponent} from "../../shared/country/country.component"
import {
    Country,
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    JudicialPerson,
    LegalEntity,
    LegalEntityProviderCriteria,
    NationalIdentityNumber,
    NaturalPerson,
    PassportNumber,
    QueryLegalEntityInformationRequest,
    RegistrationNumber
} from "@magnabc/tpi"
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import {
    SecuredLegalEntityService
} from '../../../app.services/managers/secured-legal-entity/secured-legal-entity.service'
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

declare const document: any;
@Component({
    selector: 'app-entity-query',
    templateUrl: './query-legal-entity.component.html',
    styleUrls: ['./query-legal-entity.component.scss']
})
export class QueryLegalEntityComponent extends FormComponent implements OnInit { // Deprecated

    @Input() legalEntity: LegalEntity;
    @Input() title: string;
    @Output() onLegalEntity = new EventEmitter<LegalEntity>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onExists = new EventEmitter<boolean>();
    @Output() selectedCountry = new EventEmitter<Country>();

    types = [
        new Dropdown(1, "National ID"),
        new Dropdown(2, "Passport Number"),
        new Dropdown(3, "Business Registration Number")
    ];

    formGroup: UntypedFormGroup;
    type: UntypedFormControl;
    identifier: UntypedFormControl;
    typePlaceholder: string;
    country: CountryDialingIdentifier;
    maxlength = 15;

    @ViewChild(CountryComponent) countryComponent: CountryComponent;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService,
        private translateParser: TranslateParser,
        private secureLegalEntity: SecuredLegalEntityService,
        private errorToastService: ErrorToastService) {
        super(translate, translateParser);

        this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";

    }

    ngOnInit(): void {

        this.type = new UntypedFormControl(this.types[0], []);
        this.identifier = new UntypedFormControl('', [
            Validators.required,
            Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
        ]);
        this.formGroup = new UntypedFormGroup({
            'type': this.type,
            'identifier': this.identifier,
        });
    }

    onCountry(country): void {
        this.country = country;
    }

    onCountryValid(event): void {
        document.getElementById('form-submit-query').click();
    }

    onTypeChanged(event): void {
        if (this.type.value.value === 1) {
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
            this.maxlength = 15;
        } else if (this.type.value.value === 2) {
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_PN";
            this.maxlength = 9;
        }
        else {
            this.typePlaceholder = "ENTITY.LABEL_COMPANY_REGISTRATION_NUMBER";
            this.maxlength = 15;
        }
    }

    submit(): void {
        this.countryComponent.submit();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {

            const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
            legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";

            const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
            queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

            const countryDescriptionIdentifier = new CountryDescriptionIdentifier()
            countryDescriptionIdentifier.description = this.country.description;
            legalEntityProviderCriteria.countryIdentifier = countryDescriptionIdentifier;
            this.legalEntity = new NaturalPerson();
            const nationalIdentityNumber = new NationalIdentityNumber();
            const passportNumber = new PassportNumber();
            if (this.type.value.value === 1) {

                nationalIdentityNumber.countryOfIssue = this.country.alphaTwo;
                nationalIdentityNumber.number = this.identifier.value.toLocaleUpperCase();
                legalEntityProviderCriteria.legalEntityIdentifier = nationalIdentityNumber;
            } else if (this.type.value.value === 2) {

                passportNumber.countryOfIssue = this.country.alphaTwo;
                passportNumber.number = this.identifier.value.toLocaleUpperCase();
                legalEntityProviderCriteria.legalEntityIdentifier = passportNumber;
            }
            else {
                this.legalEntity = new JudicialPerson();
                const registrationNumber = new RegistrationNumber(this.identifier.value.toLocaleUpperCase(), this.country.alphaTwo);
                legalEntityProviderCriteria.legalEntityIdentifier = registrationNumber;
            }
            this.legalEntity.legalEntityIdentifiers = [];

            this.secureLegalEntity.queryLegalEntityInformation(legalEntityProviderCriteria).then(response => {
                this.stopLoad();
                let exists = false;
                if (response.legalEntities.length > 0) {
                    exists = true;
                    this.legalEntity = response.legalEntities[0];
                } else {
                    if (this.type.value.value === 1) {
                        nationalIdentityNumber.countryOfIssue = this.country.alphaTwo;
                        nationalIdentityNumber.number = this.identifier.value.toLocaleUpperCase();
                        this.legalEntity.legalEntityIdentifiers.push(nationalIdentityNumber);
                    } else if (this.type.value.value === 2) {
                        passportNumber.countryOfIssue = this.country.alphaTwo;
                        passportNumber.number = this.identifier.value.toLocaleUpperCase();
                        this.legalEntity.legalEntityIdentifiers.push(passportNumber);
                    } else {
                        const registrationNumber = new RegistrationNumber(this.identifier.value.toLocaleUpperCase(), this.country.alphaTwo);
                        this.legalEntity.legalEntityIdentifiers.push(registrationNumber);
                    }
                }
                this.onLegalEntity.emit(this.legalEntity);
                this.onValid.emit();
                this.selectedCountry.emit(this.countryComponent.getCountrySelected());
                this.onExists.emit(exists);
            }).catch(exception => {
                RaygunErrorHandler.sendError(exception);
                this.errorToastService.success('Legal Entity not found.', 'Server Error')
            });

        }

    }

}
