import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from "rxjs/internal/Observable"
import {InitialiseIssueInsuranceCertificateRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class InsuranceCertificateInitialisationIssuanceManagerService {

  basePath = '/retail/insurance/certificate/insurancecertificateinitialisationissuancemanager';

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    initialiseIssueInsuranceCertificate(initialiseIssueInsuranceCertificateRequest: InitialiseIssueInsuranceCertificateRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/initialiseissueinsurancecertificate';
        return this.http.post(path, initialiseIssueInsuranceCertificateRequest, this.options);
    }
}
