import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataModel} from "./dialog-data.model";


@Component({
    selector: 'app-custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CustomDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataModel
    ) { }

    close(): void {
        this.dialogRef.close();
    }

    confirm(): void {
        this.dialogRef.close(true);
    }
}

