import { Response } from "../../../../../../../urdad/services/models/response.model";
import { RenderedDocument } from "../../../../../../../rendering/models/rendered-document.model";

export class InitialiseIssueInsuranceCertificateResponse extends Response{
    constructor(){ 
        super();
    }

    public renderedDocuments: RenderedDocument[];
    public issueDate: Date;
}
