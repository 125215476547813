import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FormComponent } from "../form/form.component";
import {
    AllSupportedCountriesCriteria,
    Country, CountryDescriptionIdentifier, ProvideSupportedCountriesRequest, ProvideSupportedCountriesResponse, SupportedCountry, UnMarshallerService
} from '@magnabc/tpi';
import { MatSelect } from "@angular/material/select";
import { environment } from '../../../../environments/environment';
import { CountryService } from './services/country.service';
import { SupportedCountriesProviderService } from "../../../http.services/retail/setup/supported-countries-provider/supported-countries-provider.service";
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
declare const jQuery: any;
@Component({
    selector: 'app-country',
    styleUrls: ['./country.component.scss'],
    templateUrl: './country.component.html'
})
export class CountryComponent extends FormComponent implements OnInit {

    @Input() title = "";
    @Input() id = "";
    @Input() enabled = true;
    @Input() countryAlphaTwo;
    @Input() countryDescription;
    @Input() prePopulate = true;
    @Input() notifyOnSelect = false;
    @Input() required = true;
    @Input() supportedCountriesOnly = false;
    @Input() selectedCountry = null;
    @Input() helpCountryText = null;

    @Input() countries: Country[] = [];
    @Input() supportedCountries: SupportedCountry[];

    @Output() onCountry = new EventEmitter<Country>();
    @Output() onValid = new EventEmitter<void>();

    invalid = false;

    country: Country;

    formGroup: UntypedFormGroup;
    countryCode: UntypedFormControl;

    @ViewChild('form') form: FormGroupDirective;
    @ViewChild(MatSelect) matSelect: MatSelect;

    constructor(private translate: TranslateService, private translateParser: TranslateParser, public countryService: CountryService,
                public supportedCountriesProviderService: SupportedCountriesProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService
    ) {
        super(translate, translateParser);
    }

    ngOnInit(): void {
        if (this.supportedCountriesOnly) {
            this.getSupportedCountries().then(() => this.doSetup());
        } else {
            this.doSetup();
        }
    }

    private doSetup(): void {
        if (this.countries.length < 1) {
            if (this.countryService.getCountries().length < 1) {
                this.countryService.populateCountries().then(response => {
                    if (response) {
                        this.countries = response;
                    }
                    this.init();
                });
            } else {
                this.countries = this.countryService.getCountries();
                this.init();
            }
        } else {
            this.init();
        }
    }

    init(): void {

        if (this.selectedCountry) {
            this.country = this.selectedCountry;
        } else if (this.countryAlphaTwo) {
            this.country = this.countries.find((country, ind, arr) => {
                return country.alphaTwo === this.countryAlphaTwo;
            });
        } else if (this.countryDescription) {
            this.country = this.countries.find((country, ind, arr) => {
                return country.description === this.countryDescription;
            });
        }

        if (!this.country && this.runtimeConfigurationService.defaultCountryAlphaTwo) {
            this.country = this.countries.find((country, ind, arr) => {
                return country.alphaTwo === this.runtimeConfigurationService.defaultCountryAlphaTwo;
            });
        }

        let validators = [];
        if (this.required) {
            validators = [Validators.required]
        }

        if (this.prePopulate && this.country) {
            this.countryCode = new UntypedFormControl(this.country.alphaTwo, validators);
        } else {
            this.countryCode = new UntypedFormControl('', validators);
        }

        this.formGroup = new UntypedFormGroup({
            countryCode: this.countryCode
        });

        if (!this.enabled) {
            this.countryCode.disable();
        }

        this.countries.sort((a, b) => {
            if (a.description > b.description) {
                return 1;
            }
            if (a.description < b.description) {
                return -1;
            }
            return 0;
        });

        this.emitCountry();
    }

    onCountryChange(event): void {
        this.country = null;
        setTimeout(() => {
            this.country = this.getCountryByAlphaTwo(this.countryCode.value);
            this.emitCountry();
        }, 0);
    }

    private emitCountry(): void {
        if (this.notifyOnSelect) {
            this.onCountry.emit(this.country);
        }
    }

    getCountryByAlphaTwo(code): any {

        for (const country of this.countries) {
            if (country.alphaTwo === code) {
                return country;
            }
        }

        return null;

    }

    getCountryByDescription(description): any {

        for (const country of this.countries) {
            if (country.description === description) {
                return country;
            }
        }

        return null;

    }

    submit(): void {
        document.getElementById('form-submit-country-' + this.id).click();
    }

    onCheckValidation(event): void {

        this.invalid = false;

        if (this.form.valid) {
            this.onCountry.emit(this.country);
            this.onValid.emit();
        } else {
            this.invalid = true;

            const target = jQuery('form .ng-invalid:first');

            if (target && jQuery(target).offset()) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    isDirty(): boolean {
        return this.form && this.form.dirty;
    }

    setCountryByAlphaTwo(alphaTwo: string) {
        this.country = null;
        setTimeout(() => {
            this.country = this.getCountryByAlphaTwo(alphaTwo);
            this.countryCode.enable();
            this.countryCode.setValue(this.country.alphaTwo);
            if (!this.enabled) {
                this.countryCode.disable();
            }
        }, 1000);
    }

    setCountry(country: any) {
        this.country = null;
        setTimeout(() => {
            this.country = this.getCountryByAlphaTwo(country.alphaTwo);
            this.countryCode.enable();
            this.countryCode.setValue(country.alphaTwo);
            if (!this.enabled) {
                this.countryCode.disable();
            }
        }, 1000);
    }

    getCountryAsDescriptionIdentifier() {
        const descriptionIdentifier = new CountryDescriptionIdentifier();
        descriptionIdentifier.description = this.country.description;
        return descriptionIdentifier;
    }

    getCountrySelected(): Country {
        return this.country;
    }

    optionsId(event) {
        return event.replace(/\s/g, "").toLowerCase();
    }

    private getSupportedCountries(): Promise<void> {

        return new Promise<void>((resolve) => {

            const provideSupportedCountriesRequest = new ProvideSupportedCountriesRequest();
            provideSupportedCountriesRequest.criteria = new AllSupportedCountriesCriteria();

            this.supportedCountriesProviderService.provideSupportedCountries(provideSupportedCountriesRequest).then((httpResponse) => {
                const supportedCountries: Country[] = [];
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSupportedCountriesResponse) as ProvideSupportedCountriesResponse);
                    const supportedCountries = response.supportedCountries.map(item => item.description);
                    if (this.countries.length > 0) {
                        this.countries = this.countries.filter(item => supportedCountries.includes(item.description));
                    }
                    resolve();
                }
            }).catch(() => {
                resolve()
            })

        })
    };
}
