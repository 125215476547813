import { BrokerIdentifier } from "../../insurance_api";
import {InsuranceCategory} from "../../models/insurance-category.model";

export class InsuranceType {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.InsuranceType";
    }

    public insuranceCategory: InsuranceCategory;
    public description: string;
    public brokerIdentifier: BrokerIdentifier;

}
