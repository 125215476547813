import { Response } from "../../../../../../../urdad/services/models/response.model";
import { ServiceGroup } from '../../../../../models/service-group.model';

export class ProvideServiceGroupsResponse extends Response{
    constructor(){ 
        super();
    }

    public serviceGroups: ServiceGroup[] = [];
}
