import {Criteria} from "../../../criteria/criteria_api"
import {AccountIdentifier} from '../../../security/account/identification/models/account-identifier.model'

export class AccountingTransactionAccountDateCriteria extends Criteria
{ 
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.accountingtransaction.AccountingTransactionAccountDateCriteria";
    }

    public accountIdentifiers : AccountIdentifier [] = [];
    public dateFrom : Date;
    public dateTo : Date;
}
