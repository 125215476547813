import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {ProvideVehicleMakesRequest, ProvideVehicleModelsRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class MakeModelTrimProviderService {

    basePath = '/vehicle/makemodeltrim/makemodeltrimprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideMakes(provideMakeRequest: ProvideVehicleMakesRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providevehiclemakes';
        return this.http.post<any>(path, provideMakeRequest, this.options).toPromise();
    }

    provideModel(provideModelRequest: ProvideVehicleModelsRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providevehiclemodels';
        return this.http.post<any>(path, provideModelRequest, this.options).toPromise();
    }
}
