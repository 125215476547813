import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
  RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-back-office-authenticator-page',
  templateUrl: './back-office-authenticator-page.component.html',
  styleUrls: ['./back-office-authenticator-page.component.scss']
})
export class BackOfficeAuthenticatorPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Login - Console - ${runtimeConfigurationService.title}`);
    }

}
