import { RenderedDocumentType } from './rendered-document-type.model';
import { Criteria } from "../../criteria/models/criteria.model";
export class RenderedDocumentTypeIdCriteria extends Criteria {

  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.rendering.RenderedDocumentTypeIdCriteria";
  }

  public documentId: string;
  public types: RenderedDocumentType[] = [];
  public userID: string;
}
