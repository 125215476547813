import {Component, Inject, OnInit} from "@angular/core"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActivatedRoute, NavigationEnd, NavigationError, Router} from "@angular/router"
import {
  RetailProfileFinalisationManagerService
} from "./http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import {ServiceList} from "./common/model/service-list.model"
import {WhiteLabelService} from "./app.services/managers/white-label/white-label.service"
import {Utils} from "./view.components/shared/Utils/Utils"
import {
  NaturalPersonIdentifier,
  ProvideSessionTrackingRequest,
  ProvideSessionTrackingResponse,
  ScheduledMaintenance,
  SessionTracking,
  SubscriptionOwner,
  UnMarshallerService
} from '@magnabc/tpi'
import {AuthenticationService} from './app.services/managers/authentication/authentication.service'
import {
  PublicPasswordResetManagerService
} from './http.services/security/account/public-password-reset-manager/public-password-reset-manager.service'
import {AgentManagerService} from "./app.services/managers/agent-manager/agent-manager.service"
import * as rg4js from 'raygun4js'
import {DOCUMENT} from "@angular/common"
import {TokenManagerService} from "./app.services/managers/token-manager/token-manager.service"
import {
  UnderwriterInformationProviderService
} from "./http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {
  QuickTransactManagerService
} from "./app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {StatusService} from './app.services/common/status/status.service'
import * as moment from 'moment'
import {SessionTrackingManagerService} from "./http.services/security/session-tracking/session-tracking.service"
import {MatDialog} from "@angular/material/dialog"
import {
  SessionTrackingModalComponent
} from "./view.components/shared/session-tracking-modal/session-tracking-modal.component"
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {Platform} from '@angular/cdk/platform'
import {
  SupportedCountriesProviderService
} from './http.services/retail/setup/supported-countries-provider/supported-countries-provider.service'
import {CountryService} from './view.components/shared/country/services/country.service'
import {RuntimeConfigurationService} from './app.services/common/runtime-configuration/runtime-configuration.service'
import {TokenIssuerService} from './http.services/security/token/token-issuer/token-issuer.service'
import {
    LegalEntityFinalisationManagerService
} from "./http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

declare const jQuery: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title: string;
    proxies: SubscriptionOwner[] = [];
    identifierMap: {[id: string]: NaturalPersonIdentifier} = {};
    utils = Utils;
    showSwitch = false;
    count = 0;
    upcomingScheduleMaintenance: ScheduledMaintenance;

    consoleService = [
        ServiceList.CREATEVEHICLEMAKE,
        ServiceList.CREATEVEHICLEMODEL,
        ServiceList.UPDATEVEHICLEMAKE,
        ServiceList.UPDATEVEHICLEMODEL,
        ServiceList.PROVIDEVEHICLEMAKES,
        ServiceList.PROVIDEVEHICLEMODELS,
        ServiceList.CREATESERVICEGROUP,
        ServiceList.UPDATESERVICEGROUP,
        ServiceList.CANCELORDER,
        ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT,
        ServiceList.UPDATETHIRDPARTYINSURANCEPRODUCT,
        ServiceList.RECEIVECASHIERPAYMENT,
        ServiceList.PROVIDEINSURANCEPOLICIES,
        ServiceList.ISSUEINSURANCECERTIFICATE,
        ServiceList.PROVIDEREVENUEREPORT,
        ServiceList.ASSISTEDQUICKTRANSACT
    ];

    activeToast: ActiveToast<any>;
    unsupportedBrowser = false;
    updateBrowser = false;
    getScreen = 'showPopUp'

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public router: Router,
                public authenticationService: AuthenticationService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private route: ActivatedRoute,
                public passwordResetManagerService: PublicPasswordResetManagerService,
                public quickStartService: QuickTransactManagerService,
                public supportedCountriesProviderService: SupportedCountriesProviderService,
                public countryService: CountryService,
                private brokerService: UnderwriterInformationProviderService,
                public whiteLabelService: WhiteLabelService,
                public agentService: AgentManagerService,
                private tokenService: TokenManagerService,
                private tokenIssuerService: TokenIssuerService,
                public statusService: StatusService,
                private sessionTrackingManagerService: SessionTrackingManagerService,
                private unMarshallerService: UnMarshallerService,
                private toastr: ToastrService,
                private platform: Platform,
                @Inject(DOCUMENT) private _document: HTMLDocument,
                private dialog: MatDialog,
                public runtimeConfigurationService: RuntimeConfigurationService) {

        this.getScreen = sessionStorage.getItem('browseProducts')
        console.log(window.location.host)
        this.title = runtimeConfigurationService.title
        translate.setDefaultLang('en');
        translate.use('en');

        this.authenticationService.subscriptionListener.subscribe(response => {
            if (response === null) {
                this.retailProfileService.retailProfile = null;
            }
        });

        this.route.queryParams.subscribe((params) => {
            if (params.underwriter) {
                this.whiteLabel(params.underwriter);
            }
        });

        this.authenticationService.proxiesUpdated.subscribe(res => {
            if (res) {
                this.proxies = this.authenticationService.getProxies();
            } else {
                this.proxies = [];
            }
        });

        this.statusService.subscribeUpcomingMaintenances().subscribe((scheduledMaintenances) => {
            if (scheduledMaintenances.length > 0) {
                if (moment(scheduledMaintenances[0].scheduled_for).isAfter(moment().add(-2, 'hours'))) {
                    this.upcomingScheduleMaintenance = scheduledMaintenances[0];
                } else {
                    this.upcomingScheduleMaintenance = null;
                }
            } else {
                this.upcomingScheduleMaintenance = null;
            }
        });

        this.statusService.subscribeActiveMaintenances().subscribe((scheduledMaintenances) => {
            if (scheduledMaintenances.length > 0) {
                this.router.navigate(['maintenance']);
            }
        });

    }

    getHours(scheduleMaintenance: ScheduledMaintenance): string {
        const milliseconds = moment(scheduleMaintenance.scheduled_until).diff(moment(scheduleMaintenance.scheduled_for));
        const minutes = Math.round( milliseconds / 1000 / 60);
        const hours = Math.round(milliseconds / 1000 / 60 / 60);
        if (minutes < 60) {
            return `${minutes} minutes`;
        }
        if (minutes === 60) {
            return `1 hour`;
        }
        return `${hours} hours`;
    }

    ngOnInit() {

        this._document.getElementById('appFavicon').setAttribute('href', this.runtimeConfigurationService.favicon);

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            // Track page views when the NavigationEnd event occurs
            if (evt instanceof NavigationEnd) {
                rg4js('trackEvent', {
                    type: 'pageView',
                    path: evt.url
                });
            }

            // Track navigation errors when the NavigationError event occurs
            if (evt instanceof NavigationError) {
                // Track navigation error
                rg4js('send', {
                    error: (evt as any).error
                });
            }
            window.scrollTo(0, 0);
        });
        var self = this
        // Check access token validity on click
        jQuery('body').on("click",function(){
            // console.log(e);
            self.checkAccessToken();
            // If yes then do not run check session tracking
            if(sessionStorage.getItem("sessionyesnopopupbutton")==='Y')
                sessionStorage.setItem("sessionyesnopopupbutton", null);
            else if(sessionStorage.getItem("sessionyesnopopupbutton")==='SKIP'){
                // Dont check the session and dont unassign - keep skip value till button yes or no is clicked
            }
            else
                self.checkSessionTracking();
        });

        this.detectBrowser();
    }

    detectBrowser() {

        const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
        const isEdgeChromium = navigator.userAgent.indexOf("Edg") != -1;
        // const isOpera = (navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1;
        // const isSafari = navigator.userAgent.indexOf("Safari") != -1;
        // const isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
        // const isInternetExplorer = (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!(document as any).documentMode == true );

        if (isChrome || isEdgeChromium) {
            if (!this.isMobile() && isChrome && this.getChromeVersion() < 92) {
                this.updateBrowser = true;
            }
            if (!this.isMobile() && isEdgeChromium && this.getEdgeVersion() < 92) {
                this.updateBrowser = true;
            }
        } else {
            this.unsupportedBrowser = true;
        }

    }

    closeUnsupportedBrowserPopup() {
        this.unsupportedBrowser = false;
    }

    closeUpdateBrowserPopup() {
        this.updateBrowser = false;
    }

    getChromeVersion() {
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        return raw ? parseInt(raw[2], 10) : 9999;
    }

    getEdgeVersion() {
        const raw = navigator.userAgent.match(/edg\/([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
        return raw ? parseInt(raw[1], 10) : 9999;
    }

    isMobile() {
        return this.platform.ANDROID || this.platform.IOS;
    }

    checkAccessToken()
    {
        // console.log("this.checkAccessToken()",this.tokenService.getAccessTokenExpired());
        if(this.tokenService.getAccessTokenExpired())
        {
            if(this.tokenService.getAuthenticationData())
            {
                // console.log("Access token expired refreshing");
                // console.log("this.tokenService.getTokens().refreshToken",this.tokenService.getTokens().refreshToken);
                this.tokenIssuerService.issueNewTokens(this.tokenService.getTokens().refreshToken).subscribe(
                    ((httpResponse: any) => {
                        // console.log("httpResponse",httpResponse);
                        this.tokenService.setTokensHttp(httpResponse);
                    })
                );
            }
        }
    }

    checkSessionTracking()
    {
        // Provide a SessionTracking
        if(sessionStorage.getItem("accountnumber"))
        {
            const provideSessionTrackingRequest = new ProvideSessionTrackingRequest();
            let sessionTrackings: SessionTracking[] = [];
            provideSessionTrackingRequest.accountNumber = sessionStorage.getItem("accountnumber");
            this.sessionTrackingManagerService.provideSessionTracking(provideSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                    const provideSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, ProvideSessionTrackingResponse) as ProvideSessionTrackingResponse);
                    sessionTrackings = provideSessionTrackingResponse.sessionTrackings;
                    let sessionExist = false;
                    sessionTrackings.forEach(session => {
                        if(sessionStorage.getItem('sessiontrackingid') &&
                            session.sessionTrackingId!==sessionStorage.getItem('sessiontrackingid'))
                        {
                            this.authenticationService.logout();
                            this.tokenService.logoutSessionTracking();
                            sessionExist = true;
                            this.dialog.open(SessionTrackingModalComponent, {
                                width: '400px',
                                panelClass: 'padded-modal',
                                disableClose: true,
                                data:  {message: "The session is closed by another session", displayButton: false}
                            });
                        }
                    });
                    if(sessionTrackings.length===0 && sessionExist===false)
                    {
                        this.authenticationService.logout();
                        this.tokenService.logoutSessionTracking();
                        this.dialog.open(SessionTrackingModalComponent, {
                            width: '400px',
                            panelClass: 'padded-modal',
                            disableClose: true,
                            data:  {message: "The session is closed by another session", displayButton: false}
                        });
                    }
                }
            })
        }
        if(sessionStorage.getItem("backofficeaccountnumber"))
        {
            const provideSessionTrackingRequest = new ProvideSessionTrackingRequest();
            let sessionTrackings: SessionTracking[] = [];
            provideSessionTrackingRequest.accountNumber = sessionStorage.getItem("backofficeaccountnumber");
            this.sessionTrackingManagerService.provideSessionTracking(provideSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                    const provideSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, ProvideSessionTrackingResponse) as ProvideSessionTrackingResponse);

                    sessionTrackings = provideSessionTrackingResponse.sessionTrackings;
                    let sessionExist = false;
                    sessionTrackings.forEach(session => {
                        if(sessionStorage.getItem('sessiontrackingid') && session.sessionTrackingId!==sessionStorage.getItem('sessiontrackingid'))
                        {
                            this.authenticationService.logout();
                            this.tokenService.logoutSessionTracking();
                            sessionExist = true;
                            this.dialog.open(SessionTrackingModalComponent, {
                                width: '380px',
                                panelClass: 'padded-modal',
                                disableClose: true,
                                data:  {message: "The session is closed by another session", displayButton: false}
                            });
                        }
                    });
                    if(sessionTrackings.length===0 && sessionExist===false)
                    {
                        this.authenticationService.logout();
                        this.tokenService.logoutSessionTracking();
                        this.dialog.open(SessionTrackingModalComponent, {
                            width: '380px',
                            panelClass: 'padded-modal',
                            disableClose: true,
                            data:  {message: "The session is closed by another session", displayButton: false}
                        });
                    }
                }
            })
        }
    }

    whiteLabel(underwriterHashId) {
        this.authenticationService.underwriterHashId = underwriterHashId;
        this.authenticationService.whiteLabel();
        this.router.navigate(['/']);
    }

    onBuyInsurance(): boolean {
        this.router.navigate(['/retail/browse/select-insurance']);
        return false;
    }

    onHome(): boolean {
        this.router.navigate(['/']);
        return false;
    }

    isConsole(): boolean {

        const backOfficeAccountNumber = sessionStorage.getItem('backofficeaccountnumber');
        return !!backOfficeAccountNumber;

    }

    onCart(event): boolean {

        if (this.route.snapshot.queryParams.quickTransact || this.quickStartService.retailProfile) {
            this.router.navigate(['/retail/cart'], {queryParams: {quickTransact: true}});
        } else {
            this.router.navigate(['/retail/cart']);
        }

        return false;
    }

    onClearAssisted(event): boolean {
        this.quickStartService.reset();
        this.retailProfileService.retailProfile = null
        if (this.authenticationService.isBackOfficeUser()) {
            this.router.navigate(['/quickstart/assisted'], {queryParams: {quickTransact: true}});
        } else {
            this.router.navigate(['/']);
        }
        return false
    }

    onConsoleHome() {
        this.quickStartService.reset();
        this.retailProfileService.retailProfile = null
        this.router.navigate(['/console/home']);
        return false
    }

    onConsoleProfile() {
        this.quickStartService.reset();
        this.retailProfileService.retailProfile = null
        this.router.navigate(['/retail/profile/update']);
        return false
    }

    onCheckout(event, service): boolean {

        if (this.retailProfileService.retailProfile.cart.items.length === 0) {
            return false
        }

        if (this.route.snapshot.queryParams.quickTransact || this.quickStartService.retailProfile) {
            this.router.navigate(['/retail/checkout'], {queryParams: {quickTransact: true}});
        } else {
            this.router.navigate(['/retail/checkout']);
        }

        this.onClosePopup(service);

        return false;
    }

    onClosePopup(service, redirect = false): boolean {
        service.showPopup = service.retailProfile.cart.items.length;
        if (redirect) {
            this.router.navigate(['retail/browse/select-insurance'], {queryParams: {showVehicles: service.showPopup}});
            sessionStorage.setItem('browseProducts', 'browseProductDialog');
            this.getScreen = sessionStorage.getItem('browseProducts')
        }
        return false;
    }

    onLogOut(event): boolean {
        this.authenticationService.logout();
        this.legalEntityFinalisationManagerService.legalEntity = null;
        this.retailProfileService.retailProfile = null;
        this.brokerService.broker = null;
        this.brokerService.selectedUnderwriter = null;
        this.router.navigate(['/']);
        return true;
    }

    onNavigation(): void {
        jQuery('#navbarNav').collapse('hide');
    }

    onLogin(): void {
        jQuery('#navbarNav').collapse('hide');
        this.quickStartService.reset();
    }

    onConsoleLogin(): void {
        jQuery('#navbarNav').collapse('hide');
    }

    getFirstLetter(legalEntity): string {

        if (legalEntity.firstNames) {
            return legalEntity.firstNames.substr(0, 1);
        } else if (legalEntity.name) {
            return legalEntity.name.substr(0, 1);
        } else {
            return legalEntity.substr(0, 1);
        }

    }

}
