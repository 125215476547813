import { Request } from "../../../../../../../urdad/services/models/request.model";
import {ServiceGroup} from "../../../../../models/service-group.model";
import {TpiIdentifier} from '../../../../../../../entity/identification/models/tpi-identifier.model';

export class CreateSubscriptionRequest extends Request {
  constructor(){
    super();
  }

  type: string;
  user: TpiIdentifier;
  owner: TpiIdentifier;
  serviceGroups: ServiceGroup[] = [];


}
