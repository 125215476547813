import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {
    FinaliseLinkVehicleRequest, 
    FinaliseSyncVehicleRequest,
    FinaliseClearCartRequest,
    FinaliseInsertRetailProfileRequest,
    MarshallerService,
    ProvideRetailProfileInformationRequest,
    ProvideRetailProfileInformationResponse,
    FinaliseRemoveItemRequest,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    UnMarshallerService,
    FinaliseUpdateItemRequest,
    FinaliseUpdateRetailProfileRequest,
    Vehicle} from '@magnabc/tpi'
import {
    RetailProfileInformationProviderService
} from "../retail-profile-information-provider/retail-profile-information-provider.service"
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {Utils} from '../../../../view.components/shared/Utils/Utils'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {
    QuickTransactManagerService
} from "../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class RetailProfileFinalisationManagerService {

    basePath = '/retail/profile/retailprofilefinalisationmanager';

    retailProfileListeners = [];

    showPopup = 0;
    retailProfile: RetailProfile;
    vehicles: Vehicle[];
    checkoutPage = false;

    constructor(private http: HttpClient, private marshallerService: MarshallerService, private unMarshallerService: UnMarshallerService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                private authenticationService: AuthenticationService, private quickStartService: QuickTransactManagerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        this.refreshRetailProfile();
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    refreshRetailProfile(): Promise<RetailProfile> {
        this.checkoutPage = false;
        if (!this.authenticationService.getLoggedInLegalEntityIdentifiers()) {
            return null;
        }

        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers());

        if (this.quickStartService.selectedLegalEntity) {
            criteria.tpiIdentifier = Utils.getTpiIdentifier(this.quickStartService.selectedLegalEntity.legalEntityIdentifiers);
        }

        const provideRetailProfileInformationRequest = new ProvideRetailProfileInformationRequest();
        provideRetailProfileInformationRequest.criteria = criteria;

        this.vehicles = [];
        this.retailProfile = null;
        return new Promise<RetailProfile>((resolve, reject) => {
            this.retailProfileInformationProviderService.provideRetailProfileInformation(provideRetailProfileInformationRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response =
                        (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                            ProvideRetailProfileInformationResponse) as ProvideRetailProfileInformationResponse);
                    if (response.retailProfile) {
                        this.retailProfile = response.retailProfile;
                        if (this.quickStartService.retailProfile) {
                            this.quickStartService.retailProfile = response.retailProfile
                        }
                        resolve(this.retailProfile);
                        return;
                    }
                } else {
                    reject(null);
                }
            }).catch((error) => {
                RaygunErrorHandler.sendError(error);
                reject(null);
                return;
            });
        });
    }

    registerRetailProfileListener(listener): void {
        this.retailProfileListeners.push(listener);
        listener(this.retailProfile);
    }

    deregisterRetailProfileListener(listener): void {
        const index = this.retailProfileListeners.indexOf(listener);
        this.retailProfileListeners.splice(index, 1);
    }

    finaliseInsertRetailProfile(insertRetailProfileRequest: FinaliseInsertRetailProfileRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseinsertretailprofile';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(insertRetailProfileRequest);
        return this.http.post(path, jsonRequest,this.options);
    }

    finaliseUpdateRetailProfile(updateRetailProfileRequest: FinaliseUpdateRetailProfileRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdateretailprofile';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(updateRetailProfileRequest);
        return this.http.post(path, jsonRequest,this.options);
    }

    finaliseClearCart(finaliseClearCartRequest: FinaliseClearCartRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseclearcart';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseClearCartRequest);
        return this.http.post(path, jsonRequest,this.options);
    }

    finaliseUpdateItem(updateItemRequest: FinaliseUpdateItemRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdateitem';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(updateItemRequest);
        return this.http.post(path, jsonRequest,this.options);
    }

    finaliseRemoveItem(removeItemRequest: FinaliseRemoveItemRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseremoveitem';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(removeItemRequest);
        return this.http.post(path, jsonRequest,this.options);
    }

    finaliseLinkVehicle(finaliseLinkVehicleRequest: FinaliseLinkVehicleRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliselinkvehicle';
        return this.http.post(path, finaliseLinkVehicleRequest, this.options);
    }

    finaliseSyncVehicle(finaliseSyncVehicleRequest: FinaliseSyncVehicleRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisesyncvehicle';
        return this.http.post(path, finaliseSyncVehicleRequest, this.options);
    }
}
