import {Component} from "@angular/core"
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: "app-proposal-list-page",
    templateUrl: "./proposal-list-page.component.html",
    styleUrls: ["./proposal-list-page.component.scss"],
})
export class ProposalListPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Proposals - Console - ${this.runtimeConfigurationService.title}`);
    }

}
