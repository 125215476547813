import {VehicleIdentifier} from "../identification/models/vehicle-identifier.model";

export class PlateNumber extends VehicleIdentifier
{
  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.vehicle.PlateNumber";
  }
  public effectiveFrom: String;
  public effectiveTo: String;
  public number:string;
}
