import { RevenueReportEntry } from "../../../retail/insurance/thirdpartyinsurance/reporting/models/revenue-report-entry.model";

export class RevenueReport
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.RevenueReport";
    }

    public dateTo : Date;
    public dateFrom : Date;
    public revenueReportEntries: RevenueReportEntry[] = [];
}
