import { error } from 'protractor';
import { InsuranceTypeManagerService } from './../insurance-type-manager/insurance-type-manager.service';
import {BehaviorSubject} from 'rxjs'
import {Injectable} from '@angular/core'
import {
    AgentIdentifier,
    Broker,
    BrokerIdentifier,
    BrokerLegalEntityCriteria,
    BrokerLegalEntityIdentifier,
    ComprehensiveInsuranceProduct,
    Country,
    CountryDescriptionIdentifier,
    CountryIdentifier,
    Duration,
    DurationType,
    Excess,
    FinaliseCreateInsuranceProductRequest,
    FinaliseCreateYCInsuranceProductRequest,
    InitialiseInsuranceProductRequest,
    InitialiseInsuranceProductResponse,
    InsuranceCategory,
    InsuranceType,
    InsuranceVehicleCategorySummary,
    LegalEntityDescriptionIdentifier,
    Limit,
    PriceSchedule, Product, ProductNumberIdentifier,
    ProvideBrokerInformationRequest,
    ProvideBrokerInformationResponse,
    ProvideCountrySellingRuleRequest,
    ProvideCountrySellingRuleResponse,
    ProvideUnderwriterInformationResponse,
    SetupClaimSpecificExcess,
    SetupLimitType,
    ThirdPartyInsuranceProduct,
    TpiIdentifier,
    Underwriter,
    UnderwriterCountryCriteria,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    YCInsuranceProduct,
    YCPriceInterval,
    YCPriceSchedule,
    YCSellingCountryRule
} from '@magnabc/tpi'
import {v4 as uuidv4} from 'uuid'

import {AgentManagerService} from '../agent-manager/agent-manager.service'
import {
  InsuranceProductInitialisationManagerService,
} from '../../../http.services/retail/insurance/insurance-product-initialisation-manager/insurance-product-initialisation-manager.service'
import {
  UnderwriterInformationProviderService,
} from '../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {
  BrokerInformationProviderService
} from '../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {UnderwriterManagerService} from '../underwriter-manager/underwriter-manager.service'
import {
  InsuranceProductFinalisationManagerService
} from '../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service'
import {AuthenticationService} from '../authentication/authentication.service'
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import {Utils} from '../../../view.components/shared/Utils/Utils'
import {ProductTypePipe} from '../../../view.components/shared/pipes/product-type.pipe'
import { YCCountrySellingRuleProviderService } from '../../../http.services/retail/insurance/yellow-card-insurance/setup/yc-country-selling-rule-provider/yc-country-selling-rule-provider.service';

@Injectable({
    providedIn: 'root'
})
export class InsuranceProductManagerService {

    insuranceTypes: InsuranceType[] = [];
    insuranceVehicleCategoriesSummaries: InsuranceVehicleCategorySummary[] = [];
    limitTypes: SetupLimitType[] = [];
    claimSpecificExcesses: SetupClaimSpecificExcess[] = [];
    countries: Country[] = [];
    sellingToCountries: Country[] = [];
    selectedSellToCountry: Country = null;
    ycSellingCountryRules: YCSellingCountryRule[] = [];
    brokerCountry: CountryIdentifier;
    currency = null;
    insuranceCategory: InsuranceCategory;
    agentIdentifiers: AgentIdentifier[] = [];
    brokerOwnAgent: AgentIdentifier[] = [];
    broker: Broker;
    brokerIdentifier: BrokerIdentifier;
    addOnProductOptions: Product[] = [];
    selectedAddOnProducts: Product[] = [];

    underwriter: Underwriter;
    product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct;
    priceSchedule: PriceSchedule;
    ycPriceSchedule: YCPriceSchedule;
    limit: Limit;
    editingIndex = -1;
    custom = false;
    disabledFields = true;
    refreshProduct = true;
    excess: Excess;
    selectedUnderwriter: Underwriter;

    ycPriceIntervals = new BehaviorSubject<YCPriceInterval[]>([]);
    ycPriceSchedules = new BehaviorSubject<YCPriceSchedule[]>([]);
    intervalIds: string[] = [];

    constructor(
        private authenticationService: AuthenticationService,
        private insuranceProductInitialisationManagerService: InsuranceProductInitialisationManagerService,
        private ycCountrySellingRuleProviderService: YCCountrySellingRuleProviderService,
        private insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService,
        private unMarshallerService: UnMarshallerService,
        private underwriterManagerService: UnderwriterManagerService,
        private underwriterInformationProviderService: UnderwriterInformationProviderService,
        private brokerInformationService: BrokerInformationProviderService,
        private insuranceTypeManager: InsuranceTypeManagerService) {
    }

    initProduct(): Promise<void> {
        return this.provideUnderwriter()
    }

    initialiseInsuranceProduct(): Promise<void> {

        return new Promise<void>((resolve, reject) => {
            this.setBroker().then(() => {
                const initialiseInsuranceProductRequest = this.constructInitialiseRequest();
                this.insuranceProductInitialisationManagerService.initialiseInsuranceProduct(initialiseInsuranceProductRequest).toPromise().then((response) => {

                    if (response && response.body) {
                        const initialiseInsuranceProductResponse =
                            (this.unMarshallerService.unMarshallFromJson(response.body, InitialiseInsuranceProductResponse) as InitialiseInsuranceProductResponse);
                        this.agentIdentifiers = initialiseInsuranceProductResponse.agentIdentifiers;
                        this.brokerOwnAgent = initialiseInsuranceProductResponse.brokerOwnAgent;
                        this.insuranceVehicleCategoriesSummaries = initialiseInsuranceProductResponse.insuranceVehicleCategorySummaries;
                        this.limitTypes = initialiseInsuranceProductResponse.limitTypes;
                        this.claimSpecificExcesses = (initialiseInsuranceProductResponse.claimSpecificExcesses as any);
                        this.countries = initialiseInsuranceProductResponse.countries;
                        this.brokerCountry = initialiseInsuranceProductResponse.brokerCountry;
                        this.currency = initialiseInsuranceProductResponse.countryCurrency;
                        this.sellingToCountries = initialiseInsuranceProductResponse.sellingToCountries;
                        this.selectedSellToCountry = this.selectedSellToCountry == null? this.sellingToCountries[0] : this.selectedSellToCountry;
                        this.addOnProductOptions = initialiseInsuranceProductResponse.products.filter((p) => p.active)

                        if (this.product instanceof YCInsuranceProduct) {
                            this.selectedAddOnProducts = []
                            for (const productIdentifier of (this.product as YCInsuranceProduct).productIdentifiers) {
                                for (const product of this.addOnProductOptions) {
                                    if ((productIdentifier as ProductNumberIdentifier).number === product.number) {
                                        this.selectedAddOnProducts.push(product)
                                    }
                                }
                            }
                        }

                        if(this.product && this.product instanceof YCInsuranceProduct && this.product.sellingToCountry) {
                            this.selectedSellToCountry = this.product.sellingToCountry;
                        }
                        else {
                            this.selectedSellToCountry = !this.selectedSellToCountry ? this.sellingToCountries[0] : this.selectedSellToCountry;
                        }

                        this.insuranceVehicleCategoriesSummaries
                            .sort((a, b) => a.subDescription.localeCompare(b.subDescription))
                            .sort((a, b) => a.description.localeCompare(b.description));

                        this.countries.sort((a, b) => a.description.localeCompare(b.description));
                        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
                        legalEntityDescriptionIdentifier.name = this.broker.name;
                        legalEntityDescriptionIdentifier.tpiIdentifier = this.broker.legalEntityIdentifier as TpiIdentifier;
                        this.brokerIdentifier = new BrokerLegalEntityIdentifier(this.broker.name, legalEntityDescriptionIdentifier);

                        resolve();

                    }
                }).catch(err => {
                    RaygunErrorHandler.sendError(err);
                    reject(err);
                    return;
                });
            });
        });

    }

    private constructInitialiseRequest(): InitialiseInsuranceProductRequest {
        const initialiseInsuranceProductRequest = new InitialiseInsuranceProductRequest();
        if (this.authenticationService.getLoggedInNaturalPerson() != null) {
            this.getSelectedUnderwriter();
            const broker = this.broker;
            initialiseInsuranceProductRequest.broker = broker;
            let insuranceType = new InsuranceType();
            insuranceType.insuranceCategory = this.insuranceCategory;
            let description = 'Yellow Card';
            if (this.insuranceCategory === InsuranceCategory.COMPREHENSIVE) {
                description = 'Comprehensive';
            } else if (this.insuranceCategory === InsuranceCategory.THIRDPARTY) {
                description = 'Third Party';
            }
            insuranceType.description = description;

            const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
            legalEntityDescriptionIdentifier.name = broker.name;
            legalEntityDescriptionIdentifier.tpiIdentifier = broker.legalEntityIdentifier as TpiIdentifier;
            insuranceType.brokerIdentifier = new BrokerLegalEntityIdentifier(broker.name, legalEntityDescriptionIdentifier);
            initialiseInsuranceProductRequest.insuranceType = insuranceType;
        }
        return initialiseInsuranceProductRequest;
    }

    private getSelectedUnderwriter() {
        if (this.selectedUnderwriter) {
            this.broker.underwriterIdentifiers = [];
            const legalEntityDescriptionIdentifierUnderwriter = new LegalEntityDescriptionIdentifier();
            legalEntityDescriptionIdentifierUnderwriter.name = this.selectedUnderwriter.name;
            legalEntityDescriptionIdentifierUnderwriter.surname = this.selectedUnderwriter.name;
            legalEntityDescriptionIdentifierUnderwriter.tpiIdentifier = Utils.getTpiIdentifier([this.selectedUnderwriter.legalEntityIdentifier]);

            const selectedUnderwriter = new UnderwriterLegalEntityIdentifier(
                this.selectedUnderwriter.name,
                legalEntityDescriptionIdentifierUnderwriter
            );
            this.broker.underwriterIdentifiers.push(selectedUnderwriter);
        }
    }

    initialisePriceSchedule() {
        this.priceSchedule = new PriceSchedule()
        this.priceSchedule.priceScheduleId = uuidv4()
        this.priceSchedule.currency = this.product.baseCurrency
        this.priceSchedule.proRata = false
        this.priceSchedule.minimumPolicyValue = 0
        this.priceSchedule.durationType = DurationType.EXACT
        this.priceSchedule.exactDuration = new Duration()
        this.priceSchedule.exactDuration.months = 0
        this.priceSchedule.exactDuration.days = 0
        this.priceSchedule.intervalDurationFrom = new Duration()
        this.priceSchedule.intervalDurationFrom.months = 0
        this.priceSchedule.intervalDurationFrom.days = 0
        this.priceSchedule.intervalDurationTo = new Duration()
        this.priceSchedule.intervalDurationTo.months = 0
        this.priceSchedule.intervalDurationTo.days = 0
        this.priceSchedule.extension = false
        this.priceSchedule.gracePeriod = new Duration()
        this.priceSchedule.gracePeriod.months = 0
        this.priceSchedule.gracePeriod.days = 0
        this.priceSchedule.lengthOfExtension = new Duration()
        this.priceSchedule.lengthOfExtension.months = 0
        this.priceSchedule.lengthOfExtension.days = 0
        this.priceSchedule.percentageOfAnnualAmount = false
    }

    initialiseLimit() {
        this.limit = new Limit();
    }

    initaliseExcess(): void {
        this.excess = {};
    }

    provideUnderwriter(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();

            if (this.authenticationService.getLoggedInNaturalPerson() != null) {
                underwriterLegalEntityCriteria.legalEntityIdentifiers = [Utils.getTpiIdentifier([this.underwriterManagerService.selectedUnderwriter.legalEntityIdentifier])];
            }
            const provideUnderwriterInformationRequest = new ProvideBrokerInformationRequest();
            provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
            this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {

                if (response && response.body) {
                    const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);

                    const underwriter = provideUnderwriterInformationResponse.underwriters[0];
                    this.underwriter = underwriter;
                    resolve();
                }

            });

        });

    }

    getInsuranceTypes(broker: Broker): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.insuranceTypeManager.getInsuranceTypes(broker)
                .then((response) => {
                    this.insuranceTypes = response;
                    resolve();
                }).catch(() => {
                    reject();
                })
        });
    }

    getSellingCountryRule(countryIdentifier: CountryIdentifier): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const provideCountrySellingRuleRequest = new ProvideCountrySellingRuleRequest();
            const underwriterCountryCriteria = new UnderwriterCountryCriteria();
            underwriterCountryCriteria.countryIdentifiers = [countryIdentifier];

            provideCountrySellingRuleRequest.criteria = underwriterCountryCriteria;
            this.ycCountrySellingRuleProviderService.provideCountrySellingRule(provideCountrySellingRuleRequest).then((response) => {
                if (response && response.body) {
                    const provideCountrySellingRuleResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideCountrySellingRuleResponse) as ProvideCountrySellingRuleResponse);
                    this.ycSellingCountryRules = provideCountrySellingRuleResponse.ycSellingCountryRules;
                    resolve();
                }
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
                reject(err);
                return;
            });
        });
    }

    /**
     * @description This method is used to finalise the insurance product
     * @returns Promise<InsuranceType[]>
     */
    finaliseInsuranceProduct(): Promise<any> {
        if (ProductTypePipe.isComprehensive(this.product) || ProductTypePipe.isThirdParty(this.product)) {
            const finaliseCreateInsuranceProductRequest = new FinaliseCreateInsuranceProductRequest()
            if (ProductTypePipe.isComprehensive(this.product)) {

                const comprehensiveInsuranceProduct = this.product as ComprehensiveInsuranceProduct

                const insuranceVehicleCategorySummary = new InsuranceVehicleCategorySummary()
                insuranceVehicleCategorySummary.description = comprehensiveInsuranceProduct.insuranceVehicleCategory.description
                insuranceVehicleCategorySummary.subDescription = comprehensiveInsuranceProduct.insuranceVehicleCategory.subDescription
                insuranceVehicleCategorySummary.insuranceVehicleCategoryId = comprehensiveInsuranceProduct.insuranceVehicleCategory.insuranceVehicleCategoryId
                insuranceVehicleCategorySummary.insuranceCategory = this.insuranceCategory;
                for (const priceSchedule of comprehensiveInsuranceProduct.priceSchedules) {
                    if (priceSchedule.minimumPolicyValue === null) {
                        priceSchedule.minimumPolicyValue = 0
                    }
                }

                finaliseCreateInsuranceProductRequest.name = comprehensiveInsuranceProduct.name
                finaliseCreateInsuranceProductRequest.description = comprehensiveInsuranceProduct.description
                finaliseCreateInsuranceProductRequest.active = true
                finaliseCreateInsuranceProductRequest.baseCurrency = comprehensiveInsuranceProduct.baseCurrency
                finaliseCreateInsuranceProductRequest.brokerIdentifier = comprehensiveInsuranceProduct.brokerIdentifier
                finaliseCreateInsuranceProductRequest.underwriterIdentifier = comprehensiveInsuranceProduct.underwriterIdentifier
                finaliseCreateInsuranceProductRequest.conditions = comprehensiveInsuranceProduct.conditions
                finaliseCreateInsuranceProductRequest.countries = comprehensiveInsuranceProduct.coveredCountries
                finaliseCreateInsuranceProductRequest.excesses = comprehensiveInsuranceProduct.excess
                finaliseCreateInsuranceProductRequest.limits = comprehensiveInsuranceProduct.limit
                finaliseCreateInsuranceProductRequest.agentIdentifiers = comprehensiveInsuranceProduct.agentIdentifiers
                finaliseCreateInsuranceProductRequest.priceSchedules = comprehensiveInsuranceProduct.priceSchedules
                finaliseCreateInsuranceProductRequest.insuranceCategory = InsuranceCategory.COMPREHENSIVE
                finaliseCreateInsuranceProductRequest.insuranceVehicleCategorySummary = insuranceVehicleCategorySummary
                finaliseCreateInsuranceProductRequest.annualPercentage = comprehensiveInsuranceProduct.annualPercentage

            } else if (ProductTypePipe.isThirdParty(this.product)) {

                const thirdPartyInsuranceProduct = this.product as ThirdPartyInsuranceProduct

                const insuranceVehicleCategorySummary = new InsuranceVehicleCategorySummary()
                insuranceVehicleCategorySummary.description = thirdPartyInsuranceProduct.insuranceVehicleCategory.description
                insuranceVehicleCategorySummary.subDescription = thirdPartyInsuranceProduct.insuranceVehicleCategory.subDescription
                insuranceVehicleCategorySummary.insuranceVehicleCategoryId = thirdPartyInsuranceProduct.insuranceVehicleCategory.insuranceVehicleCategoryId;
                insuranceVehicleCategorySummary.insuranceCategory = this.insuranceCategory;

                finaliseCreateInsuranceProductRequest.name = thirdPartyInsuranceProduct.name
                finaliseCreateInsuranceProductRequest.description = thirdPartyInsuranceProduct.description
                finaliseCreateInsuranceProductRequest.active = true
                finaliseCreateInsuranceProductRequest.baseCurrency = thirdPartyInsuranceProduct.baseCurrency
                finaliseCreateInsuranceProductRequest.brokerIdentifier = thirdPartyInsuranceProduct.brokerIdentifier
                finaliseCreateInsuranceProductRequest.underwriterIdentifier = thirdPartyInsuranceProduct.underwriterIdentifier
                finaliseCreateInsuranceProductRequest.conditions = thirdPartyInsuranceProduct.conditions
                finaliseCreateInsuranceProductRequest.countries = thirdPartyInsuranceProduct.coveredCountries
                finaliseCreateInsuranceProductRequest.excesses = thirdPartyInsuranceProduct.excess
                finaliseCreateInsuranceProductRequest.limits = thirdPartyInsuranceProduct.limit
                finaliseCreateInsuranceProductRequest.agentIdentifiers = thirdPartyInsuranceProduct.agentIdentifiers
                finaliseCreateInsuranceProductRequest.priceSchedules = thirdPartyInsuranceProduct.priceSchedules
                finaliseCreateInsuranceProductRequest.insuranceCategory = InsuranceCategory.THIRDPARTY
                finaliseCreateInsuranceProductRequest.insuranceVehicleCategorySummary = insuranceVehicleCategorySummary
                finaliseCreateInsuranceProductRequest.insuranceType = thirdPartyInsuranceProduct.insuranceType
                finaliseCreateInsuranceProductRequest.annualAmount = thirdPartyInsuranceProduct.annualAmount

            }
            return this.insuranceProductFinalisationManagerService.finaliseInsuranceProduct(finaliseCreateInsuranceProductRequest).toPromise()
        } else if (ProductTypePipe.isYellowCard(this.product)) {

            const finaliseCreateInsuranceProductRequest = new FinaliseCreateYCInsuranceProductRequest()
            const yellowCardInsuranceProduct = this.product as YCInsuranceProduct

            finaliseCreateInsuranceProductRequest.name = yellowCardInsuranceProduct.name
            finaliseCreateInsuranceProductRequest.description = yellowCardInsuranceProduct.description
            finaliseCreateInsuranceProductRequest.active = true
            finaliseCreateInsuranceProductRequest.baseCurrency = yellowCardInsuranceProduct.baseCurrency
            finaliseCreateInsuranceProductRequest.brokerIdentifier = yellowCardInsuranceProduct.broker
            finaliseCreateInsuranceProductRequest.underwriterIdentifier = yellowCardInsuranceProduct.underwriter
            finaliseCreateInsuranceProductRequest.conditions = yellowCardInsuranceProduct.conditions
            finaliseCreateInsuranceProductRequest.excesses = yellowCardInsuranceProduct.excess
            finaliseCreateInsuranceProductRequest.limits = yellowCardInsuranceProduct.limits
            finaliseCreateInsuranceProductRequest.agentIdentifiers = yellowCardInsuranceProduct.agentIdentifiers
            finaliseCreateInsuranceProductRequest.sellingToCountry = yellowCardInsuranceProduct.sellingToCountry
            finaliseCreateInsuranceProductRequest.ycPriceIntervals =  yellowCardInsuranceProduct.ycPriceIntervals.map((item) => {
                item.ycPriceIntervalId = null;
                return item;
            });
            finaliseCreateInsuranceProductRequest.ycPriceSchedules = yellowCardInsuranceProduct.ycPriceSchedules.map((item) => {
                item.ycPriceScheduleId = null;
                return item;
            });
            finaliseCreateInsuranceProductRequest.insuranceCategory = InsuranceCategory.YELLOWCARD
            finaliseCreateInsuranceProductRequest.products = this.selectedAddOnProducts

            return this.insuranceProductFinalisationManagerService.finaliseCreateYCInsuranceProduct(finaliseCreateInsuranceProductRequest).toPromise()
        }
    }

    setBroker(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let brokerRequest = new ProvideBrokerInformationRequest();
            let criteria = new BrokerLegalEntityCriteria();
            criteria.legalEntity = Utils.getTpiIdentifier(this.authenticationService.getLinkedToLegalEntityIdentifiers())
            brokerRequest.criteria = criteria;
            this.brokerInformationService.provideBroker(brokerRequest).toPromise().then(response => {
                if (response && response.body) {
                    const result = (this.unMarshallerService.unMarshallFromJson(response.body,
                        ProvideBrokerInformationResponse) as ProvideBrokerInformationResponse);
                    this.broker = result.brokers[0];
                    resolve(true);
                } else {
                    reject(response.status);
                }
            })
        })
    }

    getCoveredCountries() {
        this.product.coveredCountries = [];
        const coveredCountries = this.ycPriceSchedules.value as any;
        for (const country of coveredCountries) {
            const index = this.product.coveredCountries.findIndex(object => object['description'] === country.country)
            if (country.country && index === -1) {
                const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                countryDescriptionIdentifier.description = country.country
                this.product.coveredCountries.push(countryDescriptionIdentifier);
            }
        }
    }

    reset() {
        this.sellingToCountries = [];
        this.ycSellingCountryRules = [];
        this.selectedSellToCountry = null;
        this.underwriter = null;
    }

}
