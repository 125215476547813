import {Component, OnInit, ViewChild} from '@angular/core'
import {ActiveToast} from "ngx-toastr"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {LegalEntityComponent} from "../../../../../view.components/entity/legal-entity/legal-entity.component"
import {CredentialsComponent} from "../../../../../view.components/security/credentials/credentials.component"
import {
  RetailProfileFinalisationManagerService
} from "../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import {OTPVerifyComponent} from "../../../../../view.components/security/opt-verify/opt-verify.component"
import {NaturalPersonComponent} from "../../../../../view.components/entity/natural-person/natural-person.component"
import {OTPComponent} from "../../../../../view.components/security/otp.component"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {ActivatedRoute, Router} from "@angular/router"
import {
  ObfuscatedNumberComponent
} from "../../../../../view.components/security/obfuscated-number/obfuscated-number.component"
import {JudicialPersonComponent} from "../../../../../view.components/entity/judicial-person/judicial-person.component"
import {
  QueryLegalEntityComponent
} from "../../../../../view.components/entity/query-legal-entity/query-legal-entity.component"
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component"
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard"
import {LocationStrategy} from "@angular/common"
import {
  RetailProfileInformationProviderService
} from "../../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service"
import {
  AllCountryCriteria,
  Cart,
  ContactNumber,
  Country,
  CountryDescriptionIdentifier,
  CreateIdentifierRequest,
  CreateIdentifierResponse,
  DriverTrafficRegisterNumber,
  IdentifierType,
  FinaliseInsertRetailProfileRequest,
  IssueOneTimePinRequest,
  IssueOneTimePinResponse,
  JudicialPerson,
  LegalEntityDescriptionIdentifier,
  LegalEntityIdentifier,
  LegalEntityProviderCriteria,
  NationalIdentityNumber,
  NaturalPerson,
  OneTimePinContext,
  OneTimePinStringIdentifier,
  PassportNumber,
  PinContext,
  PlainTextUsername,
  ProvideCountryInformationRequest,
  ProvideCountryInformationResponse,
  ProvideLegalEntityInformationRequest,
  ProvideLegalEntityInformationResponse,
  ProvideRetailProfileInformationRequest,
  QueryLegalEntityInformationRequest,
  RegistrationNumber,
  RetailProfile,
  RetailProfileLegalEntityCriteria,
  TpiIdentifier,
  TrafficRegisterNumber,
  UnMarshallerService,
  ValidateOneTimePinRequest,
  ValidateOneTimePinResponse,
  FinaliseUpdateLegalEntityRequest,
  LegalEntityPersistenceManagerType,
  FinaliseInsertLegalEntityRequest
} from "@magnabc/tpi"
import {Utils} from "../../../../../view.components/shared/Utils/Utils"
import {
  QueryLegalEntityInformationManagerService
} from "../../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service"
import {RaygunErrorHandler} from '../../../../../common/utils/utils.raygun'
import {
  VerifiedAccountManagerService
} from '../../../../../http.services/security/account/verified-account-manager/verified-account-manager.service'
import {OneTimePinService} from '../../../../../view.components/security/services/one-time-pin.service'
import {
  QuickTransactManagerService
} from '../../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {Title} from '@angular/platform-browser'
import {GoogleAnalyticsService} from 'ngx-google-analytics'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {Dropdown} from '../../../../../common/model/dropdown.model'
import {
  CountryProviderService
} from '../../../../../http.services/location/geographic/country-provider/country-provider.service'
import {CountryService} from '../../../../../view.components/shared/country/services/country.service'
import {
  PublicLegalEntityInformationProviderService
} from '../../../../../http.services/entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service'
import {
  SecuredLegalEntityService
} from '../../../../../app.services/managers/secured-legal-entity/secured-legal-entity.service'
import {
  RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
  OneTimePinValidationManagerProviderService
} from '../../../../../http.services/security/onetimepin/one-time-pin-validation-manager/one-time-pin-validation-manager-provider.service'
import {
  IdentifierManagerService
} from '../../../../../http.services/identification/identifier-manager/identifier-manager.service'
import {
  OneTimePinIssuanceManagerService
} from '../../../../../http.services/security/onetimepin/one-time-pin-issuance-manager/one-time-pin-issuance-manager.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import {
  LegalEntityFinalisationManagerService
} from "../../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";
import {translate} from "@angular/localize/tools";

@Component({
  selector: 'app-insurance-assisted-quick-transact-manager',
  templateUrl: './insurance-assisted-quick-transact-manager-page.component.html',
  styleUrls: ['./insurance-assisted-quick-transact-manager-page.component.scss']
})
export class InsuranceAssistedQuickTransactManagerPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps: any = [];
    utils = Utils;

    @ViewChild(QueryLegalEntityComponent) entityQueryComponent: QueryLegalEntityComponent;
    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    @ViewChild(JudicialPersonComponent) judicialPersonComponent: JudicialPersonComponent;
    @ViewChild(LegalEntityComponent) legalEntityComponent: LegalEntityComponent;
    @ViewChild(CredentialsComponent) credentialsComponent: CredentialsComponent;
    @ViewChild(OTPComponent) otpComponent: OTPComponent;
    @ViewChild(ObfuscatedNumberComponent) obfuscatedNumberComponent: ObfuscatedNumberComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;

    pinContext = PinContext;
    activeToast: ActiveToast<any>;

    changeMobile = false;
    oneTimePinStringIdentifier: OneTimePinStringIdentifier = null;
    identifier: string = '';
    retailProfile:RetailProfile;
    obfuscatedNumber: string;

    provideExternalLegalEntityFormGroup: UntypedFormGroup;
    externalIdentifierType: UntypedFormControl;
    externalIdentifierTypes = [
        new Dropdown(0, "Traffic Register Number"),
    ];
    externalIdentifierTypePlaceholder = "ENTITY.LABEL_TRN_NUMBER";
    externalIdentifier: UntypedFormControl;

    countries: Country[] = null;
    externalCountries: Country[] = [];
    selectedCountry: Country;
    capturedExternalIdentifier: DriverTrafficRegisterNumber;
    errorMessage: string;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ErrorToastService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                public quickStartService: QuickTransactManagerService,
                locationStrategy: LocationStrategy,
                private unMarshallerService: UnMarshallerService,
                public createVerifiedAccountService: VerifiedAccountManagerService,
                private oneTimePinService : OneTimePinService,
                private oneTimePinValidationManagerProviderService: OneTimePinValidationManagerProviderService,
                private identifierManagerService: IdentifierManagerService,
                private oneTimePinIssuanceManagerService: OneTimePinIssuanceManagerService,
                private countryProviderService: CountryProviderService,
                private countryService: CountryService,
                private legalEntityInformationProvider: PublicLegalEntityInformationProviderService,
                private securedLegalEntityInformationService: SecuredLegalEntityService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.quickStartService.isAssistedQuickTransact = true;

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
        setTimeout(() => {
            this.backPressed = false; // For Firefox
        }, 500);

        this.steps = [];
        setTimeout(() => {

            if (this.quickStartService.currentStep === 0) {
                this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                    this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
                });
                this.setTitle();
            } else {
                this.setSteps();
            }

        });

    }

    onDeactivate(): boolean {
        if (this.quickStartService.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    setSteps(): void {

        this.steps = [];
        if (!this.quickStartService.exists && !this.quickStartService.trn) {
            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-id-card-o", borderColor: "#318cdf"});
            });

            if (this.quickStartService.judicial) {
                this.translate.get("ENTITY.TITLE_COMPANY_DETAILS").subscribe((response) => {
                    this.steps.push({id: 2, name: response, icon: "fa-user", borderColor: "#318cdf"});
                });
            } else {
                this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                    this.steps.push({id: 2, name: response, icon: "fa-user", borderColor: "#318cdf"});
                });
            }

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-phone", borderColor: "#318cdf"});
            });

            this.translate.get("SECURITY.TITLE_ONE_TIME_PIN").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-mobile", borderColor: "#3171c3"});
            });

            this.translate.get("SECURITY.TITLE_VERIFY").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-check", borderColor: "#3171c3", hidden: true});
            });

            this.translate.get("QUICK_TRANSACT.TITLE_BUY_INSURANCE").subscribe((response) => {
                this.steps.push({id: 6, name: response, icon: "fa-car", borderColor: "#3e67b5"});
            });
        } else if(this.quickStartService.trn){

            this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe(() => {
                this.steps.push({id: 1, name: "Capture Identifier", icon: "fa-id-card-o", borderColor: "#318cdf"});
            });

            this.translate.get("SECURITY.TITLE_ONE_TIME_PIN").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-mobile", borderColor: "#3171c3"});
            });

            this.translate.get("SECURITY.TITLE_VERIFY").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-check", borderColor: "#3171c3"});
            });

            if (this.quickStartService.judicial) {
                this.translate.get("ENTITY.TITLE_COMPANY_DETAILS").subscribe((response) => {
                    this.steps.push({id: 3, name: response, icon: "fa-user", borderColor: "#318cdf"});
                });
            } else {
                this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                    this.steps.push({id: 3, name: response, icon: "fa-user", borderColor: "#318cdf"});
                });
            }

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 4, name: response, icon: "fa-phone", borderColor: "#318cdf"});
            });

            this.translate.get("QUICK_TRANSACT.TITLE_BUY_INSURANCE").subscribe((response) => {
                this.steps.push({id: 5, name: response, icon: "fa-car", borderColor: "#3e67b5"});
            });
        }
        else {

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("SECURITY.TITLE_ONE_TIME_PIN").subscribe((response) => {
                this.steps.push({id: 1, name: response, icon: "fa-mobile", borderColor: "#3171c3"});
            });

            this.translate.get("SECURITY.TITLE_VERIFY").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-check", borderColor: "#3171c3", hidden: true});
            });

            this.translate.get("QUICK_TRANSACT.TITLE_BUY_INSURANCE").subscribe((response) => {
                this.steps.push({id: 3, name: response, icon: "fa-car", borderColor: "#3e67b5"});
            });

        }

        this.setTitle();

    }

    ngOnInit(): void {

        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.startLoad();
        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                this.countries = response.countries;
                this.countryService.setCountries(response.countries);
                this.createVerifiedAccountService.setDropdownCountries(response.countries);
            }
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: '+ err);
            RaygunErrorHandler.sendError(err);

            this.stopLoad();
        });
    }

    setTitle() {
        let title = `${this.steps[this.quickStartService.currentStep].name} - Assisted Quick Start - ${this.runtimeConfigurationService.title}`;
        if (this.quickStartService.currentStep === 0) {
            title = `Assisted Quick Start - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    isLocal(identifier): boolean {
        return identifier.countryOfIssue === 'ZM';
    }

    onSubmit(event): void {

        if (!this.quickStartService.exists) {

            switch (this.quickStartService.currentStep) {
                case 0:

                    this.externalIdentifierType = new UntypedFormControl(this.externalIdentifierTypes[0], []);
                    this.externalIdentifier = new UntypedFormControl('', [
                        Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/)
                    ]);
                    this.provideExternalLegalEntityFormGroup = new UntypedFormGroup({
                        externalIdentifierType: this.externalIdentifierType,
                        externalIdentifier: this.externalIdentifier,
                    });

                    this.entityQueryComponent.submit();
                    break;
                case 1:
                    this.provideExternalLegalEntityInformation();
                    break;
                case 2:
                     if (this.naturalPersonComponent) {
                        this.naturalPersonComponent.submit();
                    } else if (this.judicialPersonComponent) {
                        this.judicialPersonComponent.submit();
                    }
                    break;
                case 3:
                    this.legalEntityComponent.submit();
                    break;
                case 4:
                    this.otpComponent.submit();
                    break;
                case 5:
                    this.otpVerifyComponent.submit();
                    break;
                case 6:
                    this.onCart();
                    break;
            }

        }
        else {

            switch (this.quickStartService.currentStep) {
                case 0:
                    this.entityQueryComponent.submit();
                    break;
                case 1:
                    this.obfuscatedNumberComponent.submit();
                    break;
                case 2:
                    this.otpVerifyComponent.submit();
                    break;
                case 3:
                    if (this.naturalPersonComponent) {
                        this.naturalPersonComponent.submit();
                    } else if (this.judicialPersonComponent) {
                        this.judicialPersonComponent.submit();
                    }
                    break;
                case 4:
                    this.legalEntityComponent.submit();
                    this.createLegalEntity();
                    break;
                case 5:
                    this.onCart();
                    break;
            }

        }

    }

    onCart(): void {

        if (this.quickStartService.retailProfile.cart && this.quickStartService.retailProfile.cart.items.length > 0) {
            this.router.navigate(['/retail/cart'], {queryParams: {quickTransact: true}});
        } else {
            this.toast("TOAST.SELECT_PRODUCT");
        }

    }

    toast(message): void {

        this.translate.get(message).subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: false,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {
                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    onExists(exists): void {
        this.quickStartService.exists = exists;
        this.setSteps();
        this.onNext(null);
        if (!this.quickStartService.exists && !this.isFromMalawi()){
            this.onNext(null);
        }
    }

    onNext(event): void {

        this.quickStartService.currentStep++;
        window.scrollTo(0, 0);
        this.setTitle();

    }

    onBack(event): void {
        if (this.quickStartService.currentStep > 0) {
            this.quickStartService.currentStep--;
            window.scrollTo(0, 0);
            this.setTitle();
        }
    }

    onConcreteLegalEntity(legalEntity): void {

        if (Utils.isJudicialPerson(legalEntity)) {
            this.quickStartService.judicial = true;
        } else {
            this.quickStartService.judicial = false;
        }

        this.onLegalEntity(legalEntity);

        this.quickStartService.legalEntity = legalEntity;
        if (!this.quickStartService.judicial) {
            if (this.quickStartService.legalEntity.contactPersons[0]) {
                if (!this.quickStartService.legalEntity.contactPersons[0].firstNames) {
                    this.quickStartService.legalEntity.contactPersons[0].firstNames = this.quickStartService.getLegalEntityNames().name;
                }
                if (!this.quickStartService.legalEntity.contactPersons[0].surname) {
                    this.quickStartService.legalEntity.contactPersons[0].surname = this.quickStartService.getLegalEntityNames().surname;
                }
            }
        }
    }

    onLegalEntity(legalEntity): void {
        this.quickStartService.legalEntity = legalEntity;
        if (this.quickStartService.judicial) {
            if (this.quickStartService.legalEntity.contactPersons && this.quickStartService.legalEntity.contactPersons[0] && this.quickStartService.legalEntity.contactPersons[0].contactNumbers[0]) {
                if (!this.quickStartService.contactNumber ||
                    this.quickStartService.contactNumber.number !== this.quickStartService.legalEntity.contactNumbers[0].number) {
                    this.quickStartService.contactNumber = this.quickStartService.legalEntity.contactPersons[0].contactNumbers[0];
                    this.obfuscatedNumber = (this.quickStartService.contactNumber.number.substr(0,
                        this.quickStartService.contactNumber.number.length - 4))

                }
            }else if(this.quickStartService.legalEntity.contactNumbers && this.quickStartService.legalEntity.contactNumbers[0]){
                if (!this.quickStartService.contactNumber ||
                    this.quickStartService.contactNumber.number !== this.quickStartService.legalEntity.contactNumbers[0].number) {
                    this.quickStartService.contactNumber = this.quickStartService.legalEntity.contactNumbers[0];
                    this.obfuscatedNumber = (this.quickStartService.contactNumber.number.substr(0,
                        this.quickStartService.contactNumber.number.length - 4))

                }
            }
        } else {
            if (this.quickStartService.legalEntity.contactNumbers && this.quickStartService.legalEntity.contactNumbers[0]) {
                if (!this.quickStartService.legalEntity.contactNumbers[0].number) {
                    this.quickStartService.legalEntity.contactNumbers[0].number = this.quickStartService.legalEntity.contactPersons[0].contactNumbers[0].number;
                }
                if (!this.quickStartService.contactNumber ||
                    this.quickStartService.contactNumber.number !== this.quickStartService.legalEntity.contactNumbers[0].number) {
                    this.quickStartService.contactNumber = this.quickStartService.legalEntity.contactNumbers[0];
                    this.obfuscatedNumber = (this.quickStartService.contactNumber.number.substr(0,
                        this.quickStartService.contactNumber.number.length - 4))
                }
            }
        }
    }

    onVerifyContact(contact): void {

        this.quickStartService.contactNumber.number = contact.number;
        this.quickStartService.contactNumber.contactNumberType = contact.contactNumberType;
        this.quickStartService.contactNumber.country = contact.country;

    }

    validateOTP(capturedOTP: string) {

        const oneTimePinIdentifier = new OneTimePinStringIdentifier();

        if(this.oneTimePinStringIdentifier){

            oneTimePinIdentifier.identifier =  this.oneTimePinStringIdentifier.identifier;
        }else{

            oneTimePinIdentifier.identifier = this.oneTimePinService.otpIdentifier;
        }

        const validateOTPRequest = new ValidateOneTimePinRequest();
        validateOTPRequest.oneTimePinIdentifier = oneTimePinIdentifier;
        validateOTPRequest.capturedOneTimePin = capturedOTP;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinValidationManagerProviderService.validateOneTimePin(validateOTPRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ValidateOneTimePinRequest) as ValidateOneTimePinResponse);
                    resolve(response.valid);
                    return;
                }
            }).catch(error => {
                console.error('Error while validating OTP', error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return;
            });
        });
    }

    updateLegalEntity(){

        const finaliseUpdateLegalEntityRequest = new FinaliseUpdateLegalEntityRequest();
        finaliseUpdateLegalEntityRequest.legalEntity = this.quickStartService.legalEntity;
        finaliseUpdateLegalEntityRequest.pmType = LegalEntityPersistenceManagerType.PUBLIC;

        this.startLoad();
        this.legalEntityFinalisationManagerService.finaliseUpdateLegalEntity(finaliseUpdateLegalEntityRequest).subscribe(() => {
            this.getRetailProfile(this.quickStartService.legalEntity.legalEntityIdentifiers);
            this.legalEntityFinalisationManagerService.legalEntity = this.quickStartService.legalEntity;
            this.stopLoad();
        }, (error) => {
            console.log(error);
            this.stopLoad();
        });
    }

    onVerified(event){
        this.startLoad();
        this.validateOTP(event).then(response => {
            if (response !== undefined) {
                if (!response){
                    this.otpVerifyComponent.otpInvalid();
                    this.stopLoad();
                } else {
                    if (this.quickStartService.exists && !this.quickStartService.trn) {
                        this.updateLegalEntity();
                        this.stopLoad();
                    } else {
                        if (this.quickStartService.judicial) {
                            this.generateIdentifier();
                            this.stopLoad();
                        } else {
                            this.generateIdentifier();
                            this.stopLoad();
                         }
                    }
                }
            }
        });
    }

    generateIdentifier(){

        const createIdentifierRequest = new CreateIdentifierRequest()
        createIdentifierRequest.quantity = 1;
        createIdentifierRequest.type = IdentifierType.TPI_IDENTIFIER;

        return new Promise<string[]>((resolve, reject) => {
            this.identifierManagerService.createIdentifier(createIdentifierRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateIdentifierResponse) as CreateIdentifierResponse);
                    this.identifier = response.identifier[0];
                    if(this.quickStartService.trn){
                        this.onNext(null)
                    }else{
                        this.createLegalEntity();
                    }
                    resolve(response.identifier);
                    return;
                }
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
            });
        });

    }

    createLegalEntity() {
        const tpiIdentifier = new TpiIdentifier();
        tpiIdentifier.tpiIdentifier = this.identifier;

        const plaintextUsername = new PlainTextUsername();
        plaintextUsername.username = this.getPlainTextUsername(this.quickStartService.legalEntity.legalEntityIdentifiers);

        if (plaintextUsername.username) {
            this.quickStartService.legalEntity.legalEntityIdentifiers.push(plaintextUsername);
        }

        this.quickStartService.legalEntity.legalEntityIdentifiers.push(tpiIdentifier);

        const finaliseInsertLegalEntityRequest = new FinaliseInsertLegalEntityRequest();
        finaliseInsertLegalEntityRequest.legalEntities.push(this.quickStartService.legalEntity);
        this.startLoad();
        this.legalEntityFinalisationManagerService.finaliseInsertLegalEntity(finaliseInsertLegalEntityRequest).subscribe(() => {
            this.createRetailProfile();
            if (this.quickStartService.trn) {
                this.quickStartService.currentStep = 5;
            }
        }, (error) => {
            this.stopLoad();
        });
    }

    createRetailProfile(): void {
        const retailProfile = new RetailProfile()
        retailProfile.cart = new Cart();
        delete retailProfile.cart["@class"];
        retailProfile.cart.items = [];
        const tpiIdentifier = new TpiIdentifier();
        tpiIdentifier.tpiIdentifier = this.identifier;
        const createRetailProfile = new FinaliseInsertRetailProfileRequest();

        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
        if(this.quickStartService.judicial){
            legalEntityDescriptionIdentifier.name = (this.quickStartService.legalEntity as JudicialPerson).name;
            legalEntityDescriptionIdentifier.tpiIdentifier =  tpiIdentifier;

        }else {

            legalEntityDescriptionIdentifier.name = (this.quickStartService.legalEntity as NaturalPerson).firstNames;
            legalEntityDescriptionIdentifier.surname = (this.quickStartService.legalEntity as NaturalPerson).surname;
            legalEntityDescriptionIdentifier.tpiIdentifier =  tpiIdentifier;
        }

        retailProfile.legalEntityDescriptionIdentifier = legalEntityDescriptionIdentifier;
        createRetailProfile.retailProfile  =  retailProfile;
        delete retailProfile["@class"];

        this.startLoad();
        this.retailProfileService.finaliseInsertRetailProfile(createRetailProfile).subscribe(response => {
            this.getLegalEntity(this.quickStartService.legalEntity.legalEntityIdentifiers[0]);
            this.getRetailProfile(this.quickStartService.legalEntity.legalEntityIdentifiers);
            this.legalEntityFinalisationManagerService.legalEntity = this.quickStartService.legalEntity;
        },(error) => {
            this.stopLoad();
        });
    }

    onStep(step: any): void {
        this.quickStartService.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    getLegalEntity(legalEntityIdentifier: LegalEntityIdentifier): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = legalEntityIdentifier;
        legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((response) => {

            this.quickStartService.legalEntity = this.quickStartService.legalEntity;
            this.quickStartService.selectedLegalEntity = this.quickStartService.legalEntity;

        }).catch(exception =>{
            RaygunErrorHandler.sendError(exception);

            console.log(exception);
        });

    }

    getRetailProfile(legalEntityIdentifiers: LegalEntityIdentifier[]) {

        const provideRetailProfileInformationRequest = new ProvideRetailProfileInformationRequest();
        const retailProfileLegalEntityCriteria = new RetailProfileLegalEntityCriteria();
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {

                retailProfileLegalEntityCriteria.tpiIdentifier = (legalEntityIdentifier as TpiIdentifier);
                provideRetailProfileInformationRequest.criteria = retailProfileLegalEntityCriteria;
                break;
            }
        }

        this.startLoad();
        this.retailProfileInformationProviderService.provideRetailProfileInformation(provideRetailProfileInformationRequest).then(response => {
            this.stopLoad();
            this.quickStartService.retailProfile = response.body.retailProfile;
            this.quickStartService.selectedLegalEntity = this.quickStartService.legalEntity;
            this.router.navigate(['retail/profile'])
        }).catch((error) => {
            this.stopLoad();
        });

    }

    validObfuscatedContactNumber(event) {
        this.startLoad();
        this.obfuscatedNumberComponent.invalidNumber = false;
        if((this.obfuscatedNumber + event) === this.quickStartService.contactNumber.number){
            this.issueOneTimePin(this.quickStartService.contactNumber);
            this.quickStartService.currentStep++;
        }else{
            this.obfuscatedNumberComponent.invalidNumber = true;
        }
        this.stopLoad();
    }

    checkInputClicked() {
        this.errorMessage = null;
    }

    skipTRNCapturing(): boolean{
        this.onNext(null);
        return false
    }

    onSelectedCountry(country): void {

        if (country.alphaTwo === 'MW') {
            this.externalCountries = [];
            this.externalCountries.push(country);
            this.selectedCountry = country;
        }

    }

    /* --------------- External Legal Entity --------------- */
    provideExternalLegalEntityInformation() {
        if(this.provideExternalLegalEntityFormGroup.valid){
            let nationalIdentityNumber: NationalIdentityNumber;
            let passportNumber: PassportNumber;
            let registrationNumber: RegistrationNumber;
            if(this.quickStartService.legalEntity instanceof NaturalPerson){
                if(this.quickStartService.legalEntity.legalEntityIdentifiers[0] instanceof NationalIdentityNumber){
                    nationalIdentityNumber = (this.quickStartService.legalEntity.legalEntityIdentifiers[0] as NationalIdentityNumber)
                }else if(this.quickStartService.legalEntity.legalEntityIdentifiers[0] instanceof PassportNumber){
                    passportNumber = (this.quickStartService.legalEntity.legalEntityIdentifiers[0] as PassportNumber)
                }

            }else if(this.quickStartService.legalEntity instanceof JudicialPerson){
                registrationNumber = (this.quickStartService.legalEntity.legalEntityIdentifiers[0] as RegistrationNumber)
            }

            const trn = new DriverTrafficRegisterNumber();
            trn.number = this.externalIdentifier.value.toLocaleUpperCase();
            trn.countryOfIssue = this.selectedCountry.description;
            const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
            provideLegalEntityInformationRequest.legalEntityIdentifiers.push(trn);


            this.legalEntityInformationProvider.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const provideLegalEntityInformationResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities && provideLegalEntityInformationResponse.legalEntities[0]) {
                        this.errorMessage = "TRN number already exist";

                    } else {
                        this.errorMessage = null;
                        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
                        const trnJP = new TrafficRegisterNumber();
                        trnJP.number = this.externalIdentifier.value.toLocaleUpperCase();
                        trnJP.countryOfIssue = this.selectedCountry.description;
                        legalEntityProviderCriteria.legalEntityIdentifier = trnJP;
                        legalEntityProviderCriteria.type = "EXTERNAL";
                        const countryIdentifier = new CountryDescriptionIdentifier();
                        countryIdentifier.description = this.selectedCountry.description;
                        legalEntityProviderCriteria.countryIdentifier = countryIdentifier;

                        this.startLoad();
                        this.securedLegalEntityInformationService.queryLegalEntityInformation(legalEntityProviderCriteria).then(queryLegalEntityResponse =>{
                            if (queryLegalEntityResponse.legalEntities.length > 0) {

                                for (const emailAddress of queryLegalEntityResponse.legalEntities[0].emailAddresses) {
                                    if (emailAddress.address.toLowerCase() === 'n/a') {
                                        queryLegalEntityResponse.legalEntities[0].emailAddresses.splice(queryLegalEntityResponse
                                            .legalEntities[0].emailAddresses.indexOf(emailAddress), 1);
                                    }
                                }
                                if (queryLegalEntityResponse.legalEntities[0] instanceof NaturalPerson){
                                    if(nationalIdentityNumber){
                                        queryLegalEntityResponse.legalEntities[0].legalEntityIdentifiers.push(nationalIdentityNumber);
                                    }else{
                                        queryLegalEntityResponse.legalEntities[0].legalEntityIdentifiers.push(passportNumber);
                                    }

                                }else if(queryLegalEntityResponse.legalEntities[0] instanceof JudicialPerson){
                                    queryLegalEntityResponse.legalEntities[0].legalEntityIdentifiers.push(registrationNumber);
                                }

                                if(queryLegalEntityResponse.legalEntities[0] instanceof NaturalPerson && this.quickStartService.legalEntity instanceof NaturalPerson
                                    || queryLegalEntityResponse.legalEntities[0] instanceof JudicialPerson && this.quickStartService.legalEntity instanceof JudicialPerson){
                                    this.quickStartService.currentStep--
                                    this.quickStartService.trn = true;
                                    this.onConcreteLegalEntity(queryLegalEntityResponse.legalEntities[0]);
                                    // this.quickStartService.legalEntity = queryLegalEntityResponse.legalEntities[0];
                                    this.onExists(true);
                                    this.stopLoad();
                                }else{
                                    this.stopLoad();
                                    if(nationalIdentityNumber || passportNumber){
                                        this.errorMessage = "The TRN provided is not a natural person TRN";
                                    }else if(registrationNumber){
                                        this.errorMessage = "The TRN provided is not a business TRN";
                                    }
                                }
                            }
                            else{
                                this.stopLoad();
                                this.errorMessage = "The TRN provided was not found.";
                            }

                        }).catch(exception => {
                            this.toastr.errorToast(exception);
                            RaygunErrorHandler.sendError(exception);
                            this.onNext(null);
                            this.stopLoad();
                        });

                    }

                }
            });
        }else{
            this.errorMessage = "Traffic Register Number is required.";
        }
    }

    issueOneTimePin(contactNumber: ContactNumber): Promise<boolean> {
        const context = new OneTimePinContext();
        context.context = PinContext.VERIFY_ACCOUNT;
        if (this.quickStartService.exists) {
            context.context = PinContext.LOGIN;
        }

        const issueOneTimePinRequest = new IssueOneTimePinRequest();
        issueOneTimePinRequest.contactNumber = contactNumber;
        issueOneTimePinRequest.oneTimePinContext = context;

        return new Promise<boolean>((resolve, reject) => {
            this.oneTimePinIssuanceManagerService.issueOneTimePin(issueOneTimePinRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, IssueOneTimePinResponse) as IssueOneTimePinResponse);

                    this.oneTimePinStringIdentifier =  (response.oneTimePinIdentifier as OneTimePinStringIdentifier);

                    resolve(true);
                    return;
                }

                resolve(false);
                return;
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                reject('Could not issue one time pin.');

                return;
            });
        });
    }

    onChangeMobile(event): void {
        this.changeMobile = true;
    }

    onMobileComplete(event): void {
        if (event) {
            this.legalEntityFinalisationManagerService.legalEntity = event;
            this.quickStartService.currentStep++;
        }
        this.changeMobile = false;
    }

    resendOtp(contactNumber: ContactNumber): Promise<boolean> {
        return this.issueOneTimePin(contactNumber);
    }

    resendOTP(){
        this.resendOtp(this.quickStartService.contactNumber);
    }

    getPlainTextUsername(legalEntityIdentifiersList: LegalEntityIdentifier[]){
        let usernameValue : string;
        for (const legalEntityIdentifier of legalEntityIdentifiersList) {
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.NationalIdentityNumber') {

                usernameValue = (legalEntityIdentifier as NationalIdentityNumber).number;
            }
        }

        return usernameValue;
    }

    isFromMalawi(): boolean {
        if (this.quickStartService.legalEntity && this.quickStartService.legalEntity.legalEntityIdentifiers[0]) {
            if ((this.quickStartService.legalEntity.legalEntityIdentifiers[0] as NationalIdentityNumber).countryOfIssue === 'MW' ||
                (this.quickStartService.legalEntity.legalEntityIdentifiers[0] as PassportNumber).countryOfIssue === 'MW') {
                return true;
            }
        }
        return false;
    }
}
