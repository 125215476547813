import {Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {InitialiseExtensionValidationRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ExtensionValidationInitialisationManagerService {
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    public initialiseExtensionValidation(initialiseExtensionValidationRequest: InitialiseExtensionValidationRequest):
        Observable<any> {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/extensionvalidationinitialisationmanager/initialiseextensionvalidation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseExtensionValidationRequest);
        return this.httpClient.post<any>(url, jsonRequest, this.options);
    }
}
