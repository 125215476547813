import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../shared/form/form.component";
import {Dropdown} from "../../../common/model/dropdown.model";
import {ServiceGroupFinalisationManagerService} from "../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service";
import {
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    JudicialPerson,
    LegalEntityProviderCriteria,
    RegistrationNumber,
    QueryLegalEntityInformationRequest,
    TpiIdentifier,
    UnMarshallerService, QueryLegalEntityInformationResponse, Country
} from '@magnabc/tpi';
import {QueryLegalEntityInformationManagerService} from "../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service";
import {CountryComponent} from '../../shared/country/country.component';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';
import {
    LegalEntityFinalisationManagerService
} from "../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
  selector: 'app-query-judicial-person',
  templateUrl: './query-judicial-person.component.html',
  styleUrls: ['./query-judicial-person.component.scss']
})
export class QueryJudicialPersonComponent extends FormComponent implements OnInit {

    @Input() judicialPerson: JudicialPerson;
    @Input() title: string;
    @Input() legalEntityType: string;
    @Input() country: Country;
    @Output() onJudicialPerson = new EventEmitter<JudicialPerson>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onAlreadyAdded = new EventEmitter<boolean>();
    @Output() onExists = new EventEmitter<boolean>();
    @Output() selectedCountry = new EventEmitter<Country>();

    types = [
        new Dropdown(0, "Business Registration Number"),
    ];

    typeSelected: number = 0;

    formGroup: UntypedFormGroup;
    type: UntypedFormControl;
    identifier: UntypedFormControl;

    typePlaceholder: string;

    @ViewChild('form', { static: true }) form: FormGroupDirective;
    @ViewChild(CountryComponent) countryComponent: CountryComponent;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private entityService: LegalEntityFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private auth: ServiceGroupFinalisationManagerService) {
        super(translate, translateParser);

        this.typePlaceholder = this.types[0].name;

    }

    ngOnInit(): void {

        if(this.judicialPerson && this.judicialPerson.legalEntityIdentifiers && this.judicialPerson.legalEntityIdentifiers.length > 0)
        {
            this.type = new UntypedFormControl(this.types[0], []);
            this.identifier = new UntypedFormControl((this.judicialPerson.legalEntityIdentifiers[0] as RegistrationNumber).number, [
                Validators.required
            ]);
        }
        else
        {
            this.type = new UntypedFormControl(this.types[0], []);
            this.identifier = new UntypedFormControl('', [
                Validators.required
            ]);
        }

        this.formGroup = new UntypedFormGroup({
            type: this.type,
            identifier: this.identifier
        });

        if(!this.legalEntityType)
        {
            this.legalEntityType = "EXTERNAL";
        }

    }

    onCountry(country): void {
        this.country = country;

    }

    onCountryValid(event): void {
        document.getElementById('form-submit-query').click();
    }

    onTypeChanged(event): void {
        console.log(event);
        this.typeSelected = event.value.value;
        this.typePlaceholder = "ENTITY.LABEL_COMPANY_REGISTRATION_NUMBER";
    }

    submit(): void {
        document.getElementById('form-submit-query').click();
    }

    onCheckValidation(event): void {

        if (this.form.valid) {

            this.country = this.countryComponent.getCountrySelected();

            this.selectedCountry.emit(this.countryComponent.getCountrySelected());

            const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
            legalEntityProviderCriteria.type = this.legalEntityType;

            const countryDescriptionIdentifier = new CountryDescriptionIdentifier()
            countryDescriptionIdentifier.description = this.country.description;
            legalEntityProviderCriteria.countryIdentifier = countryDescriptionIdentifier;


            const tpiIdentifier = new TpiIdentifier();

            // FIXME this used a RegistrationNumber
            if(this.typeSelected === 0)
            {
                legalEntityProviderCriteria.legalEntityIdentifier = new RegistrationNumber(this.identifier.value.toLocaleUpperCase() + "", this.country.alphaTwo);
                legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";
            }
            else
            {
                tpiIdentifier.tpiIdentifier = this.identifier.value;
                legalEntityProviderCriteria.legalEntityIdentifier = tpiIdentifier;
            }

            const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
            queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

            this.onAlreadyAdded.emit(false);
            // noinspection TsLint
            for (const index in this.auth.invites) {
                for (const subscription of this.auth.invites[index]) {
                    if (subscription.ownedBy.tpiIdentifier === tpiIdentifier.tpiIdentifier) {
                        this.onAlreadyAdded.emit(true);
                    }

                }
            }

            this.startLoad();
            this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then(httpResponse => {

                if (httpResponse && httpResponse.body) {

                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                    let exists = false;
                    if (response.legalEntities.length > 0) {
                        exists = true;
                        this.judicialPerson = (response.legalEntities[0] as JudicialPerson);
                    } else {
                        this.judicialPerson = new JudicialPerson();
                        this.judicialPerson.legalEntityIdentifiers = [];
                        this.judicialPerson.legalEntityIdentifiers.push(new RegistrationNumber(this.identifier.value.toLocaleUpperCase(), this.country.alphaTwo));
                    }

                    this.onJudicialPerson.emit(this.judicialPerson);
                    this.onValid.emit();
                    this.onExists.emit(exists);

                    this.stopLoad();
                }
            }).catch(exception =>{
                RaygunErrorHandler.sendError(exception);
                this.stopLoad();
                console.log(exception);
            });

        }

    }
}
