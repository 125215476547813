import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model"
import {MotorInsuranceLimitDetails} from "../../../checkout/models/motor-insurance-limit-details.model"
import {AgentIdentifier} from "../../../../entity/identification/models/agent-identifier.model"
import {BrokerIdentifier} from "../../identification/models/broker-identifier.model"
import {UnderwriterIdentifier} from "../../identification/models/underwriter-identifier.model"
import {Excess} from "../../models/excess.model"
import {InsuranceProductCartItem} from "../../models/insurance-product-cart-item.model"
import {PriceSchedule} from "../../models/price-schedule.model"

export class ThirdPartyInsuranceProductCartItem extends InsuranceProductCartItem {
    constructor(){
        super()
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsuranceProductCartItem"
    }

    public conditions: string
    public insuranceVehicleCategoryDescription: string
    public insuranceVehicleCategorySubDescription: string
    public coveredCountries: CountryIdentifier[] = []
    public agentIdentifiers: AgentIdentifier[] = []
    public brokerIdentifier: BrokerIdentifier
    public underwriterIdentifier: UnderwriterIdentifier
    public motorInsuranceLimitDetails: MotorInsuranceLimitDetails[] = []
    public priceSchedule: PriceSchedule
    public insuranceTypeDescription: String
    public excesses: Excess[] = []


}
