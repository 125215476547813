import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';
import { Injectable } from '@angular/core';
import * as PDFJS from 'pdfjs-dist';

declare const jQuery: any;

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    private readonly DEFAULT_SCALE = 3;

    constructor() { }

    displayPDF(data: PDFDisplayModel): Promise<any> {

        const pdfData = atob(data.base64);
        PDFJS.GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.js';
        const loadingTask = PDFJS.getDocument({ data: pdfData });

        if (data.canvasId) {
            return this.displayMultiPage(loadingTask, data);
        } else {
            return this.displaySinglePage(loadingTask, data);
        }
    }

    printDocument(data: PDFPrintModel): Window {
        let dataUrl;
        let printWin: Window;

        if (data.targetId) {
            const canvases = (document.getElementById(data.targetId) as any).childNodes;
            const newCanvas = document.createElement("canvas");
            const context = newCanvas.getContext('2d');

            newCanvas.height = canvases[0].height * data.numberOfPages;
            newCanvas.width = canvases[0].width;

            let y = 0;
            for (const canvas of canvases) {
                context.drawImage(canvas, 0, y);
                y = y + canvas.height;
            }

            dataUrl = newCanvas.toDataURL();
        } else if (data.dataUrl) {
            dataUrl = data.dataUrl;
        }

        if (data.openBlank) {
            printWin = window.open('about:blank');
        } else {
            printWin = window.open(dataUrl, '', data.features);
        }

        printWin.document.open();
        printWin.document.write(`
        <html>
            <head>
            <style style type="text/css" media="print">
                body .myPagesClass {
                    z-index: 100;
                    visibility: visible;
                    display: block;
                    top: 0 ;
                    left: 0 ;
                    margin: 0;
                    overflow: visible;
                    page-break-after: always;
                }
                @page{
                    size: A4;
                    margin: 0 ;
                }
            </style>
            </head>
            <body onload="window.print();window.close()">
                <img style="width: 100%;" src="${dataUrl}">
            </body>
        </html>`
        );

        printWin.document.close();
        printWin.focus();

        return printWin;
    }

    private displaySinglePage(loadingTask, data: PDFDisplayModel): Promise<any> {
        const canvas = data.canvas;
        const scale = data.scale ? data.scale : this.DEFAULT_SCALE;

        return new Promise((resolve, reject) => {
            loadingTask.promise.then((pdf) => {

                const pageNumber = 1;
                pdf.getPage(pageNumber).then((page) => {
                    const viewport = page.getViewport({ scale: scale });
                    const context = canvas.getContext('2d');

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    canvas.style.height = viewport.height;
                    canvas.style.width = data.styleWidth ? data.styleWidth : '100%';

                    const renderContext = {
                        intent: 'print',
                        canvasContext: context,
                        viewport
                    };
                    const renderTask = page.render(renderContext);
                    renderTask.promise.then(() => {
                        data.complete(canvas);
                        resolve('');
                    }, reason => console.error(reason));
                });
            }, (reason) => {
                console.error(reason);
                RaygunErrorHandler.sendError(reason);
            });
        });
    }

    private displayMultiPage(loadingTask, data: PDFDisplayModel): Promise<number> {
        const canvasId = data.canvasId;
        const scale = data.scale ? data.scale : this.DEFAULT_SCALE;

        return new Promise((resolve, reject) => {
            loadingTask.promise.then((pdf) => {
                const promises = [];
                jQuery(canvasId).empty();
                for (let pageNumber = 0; pageNumber < pdf.numPages; pageNumber++) {
                    promises.push(new Promise<void>((resolveInner) => {
                        pdf.getPage(pageNumber + 1).then((page) => {
                            const viewport = page.getViewport({ scale: scale });
                            const canvas = document.createElement("canvas");
                            const context = canvas.getContext('2d');

                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            canvas.style.display = "block";
                            canvas.style.width = data.styleWidth ? data.styleWidth : '100%';

                            const renderContext = {
                                intent: 'print',
                                canvasContext: context,
                                viewport
                            };
                            const renderTask = page.render(renderContext);
                            jQuery(canvasId).append(canvas);
                            renderTask.promise.then(() => {
                                resolveInner();
                            }, reason => console.error(reason));
                        });
                    }));
                }

                Promise.all(promises).then(() => {
                    data.complete();
                    resolve(pdf.numPages);
                });

            }, (reason) => {
                console.error(reason);
                RaygunErrorHandler.sendError(reason);
            });
        })
    }
}


export interface PDFPrintModel {
    numberOfPages: number;
    openBlank: boolean;
    targetId?: string;
    dataUrl?: any;
    features?: string;
}

export interface PDFDisplayModel {
    base64: string;
    complete: CallableFunction;
    canvas?: HTMLCanvasElement;
    canvasId?: string;
    scale?: number;
    styleWidth?: string;
}
