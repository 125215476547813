import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {FinaliseIssueInsuranceCertificateRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class InsuranceCertificateFinalisationIssuanceManagerService {

    basePath = '/retail/insurance/certificate/insurancecertificatefinalisationissuancemanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseIssueInsuranceCertificate(finaliseIssueInsuranceCertificateRequest: FinaliseIssueInsuranceCertificateRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseissueinsurancecertificate';
        return this.http.post<any>(path, finaliseIssueInsuranceCertificateRequest, this.options).toPromise();
    }

}
