import {Criteria} from '../../../../criteria/models/criteria.model';
import {CountryIdentifier} from '../../../../location/geographic/identification/models/country-identifier.model';

export class CountryStartDateConfigurationCriteria extends Criteria {

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.CountryStartDateConfigurationCriteria";
    } 

    public countryIdentifier: CountryIdentifier;
}