import { CountryIdentifier } from "../../location/geographic/identification/models/country-identifier.model";
import { Levy } from "./levy.model";

export class CountryTaxes{
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.financial.CountryTaxes";
    }

    public vat: number;
	public countryIdentifier: CountryIdentifier;
    public levies: Levy[] = [];
}