import { Product } from '../../../../../../products/models/product.model';
import {Response} from '../../../../../../../urdad/services/models/response.model';

export class FinaliseInsuranceProductResponse extends Response{
    constructor(){
        super();
    }

    public product: Product;

}
