import {Request} from '../../../../../../../../urdad/services/models/request.model';
import { CardPaymentOption } from '../../../../../models/card-payment-option.model';
import {RetailProfileIdentifier} from "../../../../../../../profile/identification/retail-profile-identifier.model";

export class RemovePaymentOptionRequest extends Request{
    constructor(){
        super();
    }

    public retailProfileIdentifier: RetailProfileIdentifier;
    public cardPaymentOption: CardPaymentOption;
}
