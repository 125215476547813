import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {FormComponent} from '../../../../../shared/form/form.component'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {HttpErrorResponse} from '@angular/common/http'
import {Router} from '@angular/router'
import {FileInputComponent} from 'ngx-material-file-input'
import {
    ImportFleetRequest,
    ImportFleetResponse,
    IncorrectDocumentFormat,
    RetailProfileOwnerIdentifier,
    VehicleImportError
} from '@magnabc/tpi'
import {
    ImportFleetManagerService
} from '../../../../../../http.services/retail/profile/import-fleet-manager/import-fleet-manager.service'
import {
    RetailProfileFinalisationManagerService
} from '../../../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {ErrorToastService} from '../../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-upload-fleet',
    templateUrl: './upload-fleet.component.html',
    styleUrls: ['./upload-fleet.component.scss']
})
export class UploadFleetComponent extends FormComponent implements OnInit {

    @Output() onUploadDone = new EventEmitter<ImportFleetResponse>();
    @Output() onReset = new EventEmitter<any>();

    formGroup: UntypedFormGroup;
    fileControl: UntypedFormControl;
    controlClass = 'firstUpload';
    vehicleErrors: VehicleImportError[] = [];
    invalidPlateNumbers = [];
    uploading = false;
    @ViewChild(FileInputComponent) fileInput: FileInputComponent;

    constructor(private importFleetService: ImportFleetManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private translate: TranslateService,
                private translateParser: TranslateParser,
                private toastr: ErrorToastService,
                public router: Router
    ) {
        super(translate, translateParser);
    }

    ngOnInit() {
        this.fileControl = new UntypedFormControl(undefined, [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup(
            {
                fileControl: this.fileControl
            }
        );
    }

    onFileSelected(event): void {
        if (this.fileControl && this.fileControl.value) {
            const file = this.fileControl.value.files[0];
            const retailProfile = this.retailProfileService.retailProfile;
            if (retailProfile) {
                const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
                retailProfileOwnerIdentifier.tpiIdentifier = retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
                let importFleetRequest = new ImportFleetRequest();
                importFleetRequest.document = file;
                importFleetRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
                this.importFleet(importFleetRequest);
            }
            event.target.value = '';
        }
    }

    private importFleet(importFleetRequest: ImportFleetRequest): void {
        this.loading = true;
        this.uploading = true;
        this.vehicleErrors = [];
        this.importFleetService.importFleet(importFleetRequest)
            .then((response) => {
                this.controlClass = 'success';
                this.vehicleErrors = response.vehicleImportErrors;
                this.onUploadDone.emit(response);
            }).catch((err) => {
            this.controlClass = 'error';
            if (err instanceof HttpErrorResponse) {
                const message = err.error.toString().toLocaleLowerCase()
                if (err.error.toString().toLocaleLowerCase().includes('document structure invalid')) {
                    this.fileControl.setErrors({ invaliddocumentstructure: true })
                } else if (message.includes('no vehicles returned by compiler')) {
                    this.fileControl.setErrors({ noVehicle: true })
                } else {
                    this.toastr.errorToast(err.error);
                }
            } else if (err instanceof IncorrectDocumentFormat) {
                this.fileControl.setErrors({ incorrectdocumentformat: true })
            }
        }).finally(() => {
            this.loading = false;
            this.uploading = false;
        });
    }

    placeholderText(): string {
        return this.uploading ? 'UPLOADING...' : this.fileControl.value ? this.fileControl.value.name : 'UPLOAD';
    }

    resetErrors(): void {
        this.controlClass = 'firstUpload';
        this.fileControl.reset();
        this.onReset.emit();
    }

    uploadFile(): void {
        this.fileInput.open();
    }

    clear(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.fileControl.setValue(null);
        this.resetErrors();
        this.invalidPlateNumbers = [];
    }

    onDone() {
        this.router.navigate(['/retail/profile']);
    }
}
