import { Response } from "../../../../../../../urdad/services/models/response.model";
import {ScannedDocument} from '../../../../../models/scanned-document.model';

export class ProvideScannedDocumentResponse extends Response{
  constructor(){
    super();
  }

  public scannedDocuments: ScannedDocument[] = [];
}
