import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {
    ProvideInsuranceSalesByProductReportRequest,
    ProvideInsuranceSalesBySalesChannelReportRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class SalesReportProviderService {

    basePath = '/ledger/reporting/salesreportprovider';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideInsuranceSalesByProductReport(provideInsuranceSalesByProductReportRequest: ProvideInsuranceSalesByProductReportRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancesalesbyproductreport';
        return this.http.post(path, provideInsuranceSalesByProductReportRequest, this.options);
    }

    provideInsuranceSalesBySalesChannelReport(provideInsuranceSalesBySalesChannelReportRequest: ProvideInsuranceSalesBySalesChannelReportRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideinsurancesalesbysaleschannelreport';
        return this.http.post(path, provideInsuranceSalesBySalesChannelReportRequest, this.options);
    }

}
