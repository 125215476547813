import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { ProposalRecord } from '../../../../../models/proposal-record.model';

export class FindProposalRecordResponse extends Response {
    constructor(){
        super();
    }

    public proposalRecords: ProposalRecord [] = [];

}