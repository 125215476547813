import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";
import { Criteria } from "../../../criteria/models/criteria.model";
import { UnderwriterIdentifier } from "../insurance_api";

export class InsurancePolicyVehicleCategoryCriteria extends Criteria {

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicyVehicleCategoryCriteria";
    }

    public expiryDate: Date;
    public insuranceVehicleCategoryDescription: string;
    public underwriterIdentifier: UnderwriterIdentifier;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public coveredCountries: string[] = [];
}
