import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { Proposal } from '../../../../../models/proposal.model';

export class FindProposalResponse extends Response {
    constructor(){
        super();
    }

    public proposals: Proposal [] = [];

}