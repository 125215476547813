import { ShortCountryNamePipe } from './../../../shared/pipes/short-country-name.pipe/short-country-name.pipe';
import { YCInsuranceProduct } from '@magnabc/tpi';
import { Component, Inject, ViewChild } from '@angular/core';
import { ComprehensiveInsuranceProduct, Item, Product, RetailVehicle, ThirdPartyInsuranceProduct } from '@magnabc/tpi'
import { ProductComponent } from '../../insurance/insurance-product-information-provider/product/product.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceGroupFinalisationManagerService } from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service';
import { ProductActionEnum } from '../../insurance/insurance-product-information-provider/product/product-action.enum';
import { InsuranceProductService } from '../../../../app.services/managers/insurance-product/insurance-product.service';

@Component({
    selector: 'app-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent {

    product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct;
    vehicles: RetailVehicle[] = [];
    item: Item;
    action = ProductActionEnum.ADD_TO_CART;
    allProducts: Product[] = [];

    @ViewChild(ProductComponent, { static: true }) productComponent: ProductComponent

    constructor(public dialogRef: MatDialogRef<ProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        public authenticationService: ServiceGroupFinalisationManagerService,
        private insuranceProductService: InsuranceProductService,
        private shortNamePipe: ShortCountryNamePipe) {
        this.product = data.product;
        this.allProducts.push(this.product);
        this.vehicles = data.vehicles;
        this.action = data.action;
        this.item = data.item;
        this.allProducts = this.allProducts.concat(data.extraProducts ?? []);
    }

    removeItem(data: Product): void {
        const temp = [];
        if (data && data.number) {
            this.allProducts.forEach((item) => { if (item.number !== data.number) temp.push(item) });
            this.allProducts = temp;
            if (this.allProducts.length === 1) {
                this.dialogRef.removePanelClass('multi');
            }
        }
    }

    title(product: Product): string {
        if (product instanceof YCInsuranceProduct) {
            return 'Yellow Card';
        } else if (product instanceof ThirdPartyInsuranceProduct || product instanceof ComprehensiveInsuranceProduct) {
            const countryIdentifier = product.coveredCountries.at(0);
            const category = product.insuranceVehicleCategory;
            const country = countryIdentifier ? `${this.shortNamePipe.transform(countryIdentifier['description'])} - ` : '';
            return `${country}${category.subDescription}`;
        }
    }

    close(reload: boolean): void {
        if (this.productComponent && this.productComponent.captureImages) {
            this.productComponent.onCloseCaptureImages(null)
        } else if(this.productComponent && this.productComponent.captureVehicleRegistration){
            const tpiId = this.insuranceProductService.tpiIdentifier;
            if(tpiId && this.insuranceProductService.vehicleRegistrationFileMap.length > 0){
                const doc = this.insuranceProductService.vehicleRegistrationFileMap.find(item => item.tpiId === tpiId);
                if(doc){
                    this.productComponent.onCloseCaptureVehicleRegistrationDoc({
                        documentId: doc.document.documentIdentifier,
                        tpiIdentifier: tpiId
                    });
                    return;
                }
                this.productComponent.onCloseCaptureVehicleRegistrationDoc({
                    documentId: null,
                    tpiIdentifier: tpiId
                });
            } else {
                this.productComponent.onCloseCaptureVehicleRegistrationDoc({
                    documentId: null,
                    tpiIdentifier: tpiId
                });
            }
        } else {
            this.dialogRef.close(reload);
        }
    }

    getShowVehicles(product): RetailVehicle[] {
        if (this.product instanceof YCInsuranceProduct) {
            if (product instanceof YCInsuranceProduct) {
                return this.vehicles.filter((vehicle) => {
                    return this.isVehicleApplicableToYC(product, vehicle);
                });
            }
            let insuranceVehicleCategory = product.insuranceVehicleCategory.description;
            insuranceVehicleCategory = insuranceVehicleCategory.toLocaleLowerCase() === 'private' ? insuranceVehicleCategory : 'Other';
            return this.vehicles.filter((item) => {
                const usage = item.usage.toLocaleLowerCase() === 'private' ? item.usage : 'Other';
                if (usage === insuranceVehicleCategory) {
                    return item
                }
            })
        } else {
            return this.vehicles;
        }
    }

    private isVehicleApplicableToYC(product: YCInsuranceProduct, vehicle: RetailVehicle): boolean {
        let uniqueCountries = Array.from(new Set(product.ycPriceSchedules.map(item => item.country)));
        const coveredCountries: string[] = [];
        uniqueCountries
            .forEach((item) => {
                if (this.insuranceProductService.selectedCoveredCountries.includes(item)) {
                    coveredCountries.push(item)
                }
            });

        const insuranceVehicleCategory = vehicle.insuranceVehicleCategories[0];
        const selectedPriceSchedules = product.ycPriceSchedules.filter((schedule) => {
            if (schedule.price !== 0 && coveredCountries.includes(schedule.country)) {

                if(schedule.insuranceVehicleCategorySubDescription.includes(insuranceVehicleCategory.description)
                    && coveredCountries.includes(schedule.country) && schedule.price !== 0)
                {
                    return schedule;
                }
            }
        });
        if (selectedPriceSchedules.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}
