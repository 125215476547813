import {Response} from '../../../../../../../urdad/services/models/response.model';
import { Order } from '../../../../../models/order.model';

export class ProvideOrderInformationResponse extends Response {
    constructor() {
        super();
    }

    public orders: Order [] = [];
}
