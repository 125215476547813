export class CardToken {

    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.payment.CardToken";
    }

    public identifier: string;
    public value: string;
    public cardType: string;
    public cardExpiryDate: Date;
    public cardLastFourDigits: string;

}
