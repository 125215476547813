import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarshallerService, RetrieveBankTransferInformationRequest } from '@magnabc/tpi';
import { RuntimeConfigurationService } from '../../../../app.services/common/runtime-configuration/runtime-configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankTransferInformationManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    retrieveBankTransferInformation(retrieveBankTransferInformationRequest: RetrieveBankTransferInformationRequest): Observable<any> {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/payment/retrievebanktransferinformationfinaliser/finaliseretrievebanktransferinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(retrieveBankTransferInformationRequest);
        return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
    }
}
