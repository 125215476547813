import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

import {TranslateModule} from "@ngx-translate/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {LoadingModule} from "ngx-loading";
import {SharedModule} from "../shared/shared.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {HttpClientModule} from "@angular/common/http";
import {LegalEntityComponent} from "./legal-entity/legal-entity.component";
import {NaturalPersonComponent} from "./natural-person/natural-person.component";
import {environment} from "../../../environments/environment";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {QueryLegalEntityComponent} from "./query-legal-entity/query-legal-entity.component";
import {JudicialPersonComponent} from "./judicial-person/judicial-person.component";
import {QueryNaturalPersonComponent} from './query-natural-person/query-natural-person.component';
import {QueryJudicialPersonComponent} from './query-judicial-person/query-judicial-person.component';
import {QueryLegalEntityInformationManagerService} from "../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service";
import {SourceOfFundsComponent} from './kyc/source-of-funds/source-of-funds.component';
import {SourceOfWealthComponent} from './kyc/source-of-wealth/source-of-wealth.component';
import {ProofOfExistenceComponent} from './kyc/proof-of-existence/proof-of-existence.component';
import {MaterialFileInputModule} from "ngx-material-file-input";
import {MatIconModule} from "@angular/material/icon";
import {ScannedDocumentComponent} from './kyc/scanned-document/scanned-document.component';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
    declarations: [
        LegalEntityComponent,
        NaturalPersonComponent,
        JudicialPersonComponent,
        QueryLegalEntityComponent,
        QueryNaturalPersonComponent,
        QueryJudicialPersonComponent,
        SourceOfFundsComponent,
        SourceOfWealthComponent,
        ProofOfExistenceComponent,
        ScannedDocumentComponent
    ],
    imports: [
        RouterModule,
        HttpClientModule,
        TranslateModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxDatatableModule,
        LoadingModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MaterialFileInputModule,
        MatIconModule,
        MatRadioModule,
        MatButtonToggleModule,

    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: environment.dateFormat}
    ],
    exports: [
        LegalEntityComponent,
        NaturalPersonComponent,
        JudicialPersonComponent,
        QueryLegalEntityComponent,
        QueryNaturalPersonComponent,
        QueryJudicialPersonComponent,
        SourceOfFundsComponent,
        SourceOfWealthComponent,
        ProofOfExistenceComponent,
        ScannedDocumentComponent
    ]
})
export class EntityModule { }
