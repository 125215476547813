import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../shared/form/form.component'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {
  BackOfficeAuthenticateCredentialsRequest,
  BackOfficeAuthenticateCredentialsResponse,
  Broker,
  CloseSessionTrackingRequest,
  CloseSessionTrackingResponse,
  CreateSessionTrackingRequest,
  CreateSessionTrackingResponse,
  PlainTextUsernameAndPassword,
  ProvideSessionTrackingRequest,
  ProvideSessionTrackingResponse,
  SessionTracking, UnMarshallerService
} from '@magnabc/tpi'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import * as moment from 'moment/moment'
import {SessionTrackingModalComponent} from '../../shared/session-tracking-modal/session-tracking-modal.component'
import {Router} from '@angular/router'
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service'
import {
  BackOfficeAuthenticatorService
} from '../../../http.services/security/back-office-authenticator/back-office-authenticator.service'
import {
  PublicPasswordResetManagerService
} from '../../../http.services/security/account/public-password-reset-manager/public-password-reset-manager.service'
import {TokenManagerService} from '../../../app.services/managers/token-manager/token-manager.service'
import {SessionTrackingManagerService} from '../../../http.services/security/session-tracking/session-tracking.service'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {v4 as uuidv4} from 'uuid'
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
  selector: 'app-back-office-authenticator',
  templateUrl: './back-office-authenticator.component.html',
  styleUrls: ['./back-office-authenticator.component.scss']
})
export class BackOfficeAuthenticatorComponent extends FormComponent implements AfterViewInit, OnDestroy {

  formGroup: UntypedFormGroup;
  username: UntypedFormControl;
  password: UntypedFormControl;

  @ViewChild('form', { static: true }) form: FormGroupDirective;

  broker: Broker;
  authentic = true;
  sessionTracking = new SessionTracking;
  sessionTrackings: SessionTracking[] = [];
  plainTextUsernameAndPassword: PlainTextUsernameAndPassword;


  constructor(translateServiceParent: TranslateService,
              translateParserParent: TranslateParser,
              private router: Router,
              private authenticationService: AuthenticationService,
              private backOfficeAuthenticator: BackOfficeAuthenticatorService,
              private passwordResetManagerService: PublicPasswordResetManagerService,
              private unMarshaller: UnMarshallerService,
              private tokenManagerService: TokenManagerService,
              private sessionTrackingManagerService: SessionTrackingManagerService,
              private unMarshallerService: UnMarshallerService,
              private dialog: MatDialog,
              private errorToastService: ErrorToastService,) {
    super(translateServiceParent, translateParserParent)

    this.username = new UntypedFormControl('', [
      Validators.required
    ]);
    this.password = new UntypedFormControl('', [
      Validators.required
    ]);

    this.formGroup = new UntypedFormGroup({
      'username': this.username,
      'password': this.password
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngOnDestroy(): void {
    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
  }

  onForgotPassword(resetPassword: boolean): boolean {
    this.passwordResetManagerService.changePasswordProcessCondition(resetPassword);
    this.router.navigate(['/security/account/backoffice-reset-password']);
    return false;
  }



  onCheckValidation(event): void {

    if (this.form.valid) {

      const authenticateCredentialsRequest = new BackOfficeAuthenticateCredentialsRequest();
      this.plainTextUsernameAndPassword = new PlainTextUsernameAndPassword();
      this.plainTextUsernameAndPassword.username = this.username.value;
      this.plainTextUsernameAndPassword.password = this.password.value;
      authenticateCredentialsRequest.credentials = this.plainTextUsernameAndPassword;
      this.startLoad();
      this.backOfficeAuthenticator.authenticateCredentials(authenticateCredentialsRequest).then(httpResponse => {
        if (httpResponse && httpResponse.body) {
          const response = (this.unMarshaller.unMarshallFromJson(httpResponse.body, BackOfficeAuthenticateCredentialsResponse) as BackOfficeAuthenticateCredentialsResponse);
          this.authentic = response.authenticationAdvice.authentic;
          if (response.authenticationAdvice.authentic) {
            this.authenticationService.setBackOfficeAuthentication(response.authenticationAdvice).then(result => {
              if (result) {
                this.processSession();
              }

            });
          } else {
            this.stopLoad();
          }
        }
      }).catch((error) => {
        this.errorToastService.success('Please try again.', 'Invalid Credentials');
        this.stopLoad();
      });

    }

  }

  public processSession() {

    // Create a new SessionTracking
    let currentTokenExpDate = this.tokenManagerService.getTokens().expirationDate;
    this.sessionTracking.accountNumber = this.tokenManagerService.getAccountNumber();
    this.sessionTracking.sessionTrackingId = uuidv4();
    let addExpDate = new Date(currentTokenExpDate.getTime() + 15000);
    this.sessionTracking.endDate = moment(addExpDate).format('YYYY-MM-DDTHH:mm:ss') as any;

    sessionStorage.setItem('sessiontrackingid', this.sessionTracking.sessionTrackingId);
    sessionStorage.setItem('sessiontrackingenddate', this.sessionTracking.endDate.toString());

    const provideSessionTrackingRequest = new ProvideSessionTrackingRequest();
    provideSessionTrackingRequest.accountNumber = this.tokenManagerService.getAccountNumber();
    this.sessionTrackingManagerService.provideSessionTracking(provideSessionTrackingRequest).then(sessionTrackingHttpResponse => {
      if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
        const provideSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, ProvideSessionTrackingResponse) as ProvideSessionTrackingResponse);
        if(provideSessionTrackingResponse.sessionTrackings.length > 0)
        {
          this.sessionTrackings = provideSessionTrackingResponse.sessionTrackings;
          this.stopLoad();
          let dialogRef = this.dialog.open(SessionTrackingModalComponent, {
            width: '400px',
            panelClass: 'padded-modal',
            disableClose: true,
            data:  {message: "Another session is open, do you want to continue", displayButton: true}
          });
          dialogRef.afterOpened().subscribe(_ => {
            sessionStorage.setItem("sessionyesnopopupbutton", "SKIP");
          });
          dialogRef.beforeClosed().subscribe(beforeCloseResult => {
            sessionStorage.setItem("sessionyesnopopupbutton", null);
            if(beforeCloseResult === 'Y'){
              sessionStorage.setItem("sessionyesnopopupbutton", "Y");
            }
            if(beforeCloseResult === 'N'){
              sessionStorage.setItem("sessionyesnopopupbutton", "N");
            }
          });
          dialogRef.afterClosed().subscribe( afterClosedResult => {
            if(afterClosedResult === 'Y'){
              this.nextRoute();
            }
            if(afterClosedResult === 'N') {
              this.authenticationService.logout();
              this.tokenManagerService.logoutSessionTracking();
            }
            sessionStorage.setItem("sessionyesnopopupbutton", null);
          });
        } else {
          this.nextRoute();
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  nextRoute () {
    this.startLoad();
    if(this.sessionTrackings.length>0){
      // Remove all duplicates
      this.sessionTrackings.forEach(session => {
        const closeSessionTrackingRequest = new CloseSessionTrackingRequest();
        closeSessionTrackingRequest.accountNumber = session.accountNumber;

        this.sessionTrackingManagerService.closeSessionTracking(closeSessionTrackingRequest).then(sessionTrackingHttpResponse => {
          if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
            const closeSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CloseSessionTrackingResponse) as CloseSessionTrackingResponse);
          }
          this.createSession();
        });
      });
    } else {
      this.createSession();
    }

    this.router.navigate(['/console/home']).then(() => {
      this.stopLoad();
    }).catch((error) => {
      console.log(error);
    })
  }

  private createSession() {
    const createSessionTrackingRequest = new CreateSessionTrackingRequest();
    createSessionTrackingRequest.sessionTracking = this.sessionTracking;
    this.sessionTrackingManagerService.createSessionTracking(createSessionTrackingRequest).then(sessionTrackingHttpResponse => {
      if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
        const createSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CreateSessionTrackingResponse) as CreateSessionTrackingResponse);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

}
