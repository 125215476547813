import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {OTPComponent} from "./otp.component"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {BrowserModule} from "@angular/platform-browser"
import {HttpClientModule} from "@angular/common/http"
import {TranslateModule} from "@ngx-translate/core"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {LoadingModule} from "ngx-loading"
import {SharedModule} from "../shared/shared.module"
import {OTPVerifyComponent} from './opt-verify/opt-verify.component'
import {ObfuscatedNumberComponent} from './obfuscated-number/obfuscated-number.component'
import {CredentialsComponent} from './credentials/credentials.component'
import {ObscureNumberComponent} from "./obscure-number/obscure-number.component"
import {RouterModule} from '@angular/router'
import {
    CaptureEntityInformationComponent
} from './account/verified-account-manager/capture-entity-information/capture-entity-information.component'
import {EntityModule} from '../entity/entity.module'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {TextMaskModule} from 'angular2-text-mask'
import {SupervisorOverrideDialogComponent} from './supervisor-override-dialog/supervisor-override-dialog.component'
import {DialogQueryUserComponent, SubscriptionComponent} from './authorisation/subscription/subscription.component'
import {ServiceGroupComponent} from './authorisation/service-group/service-group.component'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {MatDialogModule} from '@angular/material/dialog'
import {MatChipsModule} from '@angular/material/chips'
import {PublicAuthenticatorComponent} from './public-authenticator/public-authenticator.component'
import {
    BackOfficeForgotPasswordManagerComponent
} from './account/back-office-forgot-password-manager/back-office-forgot-password-manager.component'
import {ServiceGroupListComponent} from './authorisation/service-group-list/service-group-list.component'
import {SubscriptionListComponent} from './authorisation/subscription-list/subscription-list.component'
import {
    BackOfficeAgentAuthenticatorComponent
} from './back-office-agent-authenticator/back-office-agent-authenticator.component'
import {MatLegacyChipsModule} from '@angular/material/legacy-chips'
import {BackOfficeAuthenticatorComponent} from './back-office-authenticator/back-office-authenticator.component'

@NgModule({
    declarations: [
        OTPComponent,
        OTPVerifyComponent,
        ObfuscatedNumberComponent,
        CredentialsComponent,
        ObscureNumberComponent,
        CaptureEntityInformationComponent,
        SupervisorOverrideDialogComponent,
        ServiceGroupComponent,
        SubscriptionComponent,
        DialogQueryUserComponent,
        BackOfficeAuthenticatorComponent,
        PublicAuthenticatorComponent,
        BackOfficeForgotPasswordManagerComponent,
        ServiceGroupListComponent,
        SubscriptionListComponent,
        BackOfficeAgentAuthenticatorComponent,
        BackOfficeAuthenticatorComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        MatSelectModule,
        MatInputModule,
        LoadingModule,
        EntityModule,
        SharedModule,
        MatCheckboxModule,
        TextMaskModule,
        NgxDatatableModule,
        MatDialogModule,
        MatChipsModule,
        MatLegacyChipsModule
    ],
    exports: [
        OTPComponent,
        OTPVerifyComponent,
        ObfuscatedNumberComponent,
        CredentialsComponent,
        ObscureNumberComponent,
        ServiceGroupComponent,
        SubscriptionComponent,
        CaptureEntityInformationComponent,
        BackOfficeAuthenticatorComponent,
        PublicAuthenticatorComponent,
        BackOfficeForgotPasswordManagerComponent,
        ServiceGroupListComponent,
        SubscriptionListComponent,
        BackOfficeAgentAuthenticatorComponent,
        CaptureEntityInformationComponent,
        BackOfficeAuthenticatorComponent
    ],
    providers: [],
})
export class SecurityModule { }
