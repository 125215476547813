import { EmailAddress } from "../../entity/models/email-address.model";

export class Email{
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.email.Email";
    }

    public subject: string;
    public body: string;
    public from: EmailAddress;
    public to: EmailAddress[] = [];
    public cc: EmailAddress[] = [];
    public bcc: EmailAddress[] = [];
}