import { AuthenticationAdvice } from "../../../../../models/authentication-advice.model";
import { Response } from "../../../../../../urdad/services/models/response.model";
import {Account} from "../../../../../account/models/account.model";
import {Subscription} from "../../../../../authorisation/models/subscription.model";
import {LegalEntity} from "../../../../../../entity/models/legal-entity.model";

export class AuthenticationResponse extends Response {
  constructor() {
    super()
  }

  public authenticationAdvice: AuthenticationAdvice;
  public account: Account;
  public legalEntity: LegalEntity;
  public subscriptions: Subscription[] = [];
}
