import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from "@angular/platform-browser"
import {ClaimSpecificExcess, Excess, UniversalExcess} from '@magnabc/tpi'

@Pipe({name: 'productExcessType'})
export class ProductExcessTypePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    static isClaimSpecific(value: Excess) {
        return value['@class'] === new ClaimSpecificExcess('')['@class'];
    }

    static isUniversal(value: Excess) {
        return value['@class'] === new UniversalExcess('')['@class'];
    }

    transform(value: Excess): string {
        if (value['@class'] === new ClaimSpecificExcess('')['@class']) {
            return 'Claim Specific'
        } else if (value['@class'] === new UniversalExcess('')['@class']) {
            return 'Universal'
        }
        return 'Unknown Excess Type';
    }

}
