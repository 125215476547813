import {PreconditionViolation} from "../../../../../../urdad/services/models/precondition-violation.model";

export class UpdateVehicleModelAlreadyExistsException extends PreconditionViolation {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.makemodeltrim.UpdateVehicleModelAlreadyExistsException";
    }

    public duplicate: string;
}
