import { Request } from '../../../../../../urdad/services/models/request.model';
import { AgentIdentifier } from '../../../../../../entity/identification/models/agent-identifier.model';
import { InsuranceCategory } from '../../../../models/insurance-category.model';

export class ProvideAgentBrokersProductsRequest extends Request {
    constructor(){
        super();
    }

    public insuranceCategory: InsuranceCategory;
    public agentIdentifier: AgentIdentifier;
}
