import {Request} from '../../../../../../../urdad/services/models/request.model';
import { OneTimePinIdentifier } from '../../../../../models/one-time-pin-identifier.model';

export class ValidateOneTimePinRequest extends Request{
    constructor(){
        super();
    }

    public capturedOneTimePin: string;
    public oneTimePinIdentifier: OneTimePinIdentifier;

}