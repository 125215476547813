import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BankTransferInformationManagerService } from '../../../../http.services/retail/payment/bank-transfer-information-manager/bank-transfer-information-manager.service';
import { BankAccount, Money, RetrieveBankTransferInformationRequest, RetrieveBankTransferInformationResponse, TpiIdentifier, UnMarshallerService } from '@magnabc/tpi';
import { FormComponent } from '../../../shared/form/form.component';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckoutEnum } from '../../checkout/checkout-enum';

@Component({
  selector: 'app-bank-transfer-information',
  templateUrl: './bank-transfer-information.component.html',
  styleUrls: ['./bank-transfer-information.component.scss']
})
export class BankTransferInformationComponent extends FormComponent implements OnInit{

    disableControls = true;
    bankAccount: BankAccount = new BankAccount();
    totalDue: Money = new Money();
    orderNumber: string = '';
    states = CheckoutEnum;


    constructor(
        private dialogRef: MatDialogRef<BankTransferInformationComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private bankTransferInformationManagerService: BankTransferInformationManagerService,
        private unMarshallerService: UnMarshallerService,
        private location: Location,
        private router: Router
        ) {
        super(translate, translateParser);

        this.orderNumber = data.orderNumber;
    }

    ngOnInit(): void {
        this.retrieveBankTransferInformation();
    }

    /**
     *  Retrieve bank transfer information
    */
    retrieveBankTransferInformation() {
        const tpiIdentifier = new TpiIdentifier();
        tpiIdentifier.tpiIdentifier = 'ENT-2';
        const retrieveBankTransferInformationRequest = new RetrieveBankTransferInformationRequest();
        retrieveBankTransferInformationRequest.legalEntityIdentifier = tpiIdentifier;
        retrieveBankTransferInformationRequest.orderNumber = this.orderNumber;

        this.startLoad();
        this.bankTransferInformationManagerService.retrieveBankTransferInformation(retrieveBankTransferInformationRequest).toPromise().then( httpResponse => {
            this.stopLoad();
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, RetrieveBankTransferInformationResponse) as RetrieveBankTransferInformationResponse);

                this.totalDue = response.totalDue;
                this.orderNumber = response.orderNumber;
                if (response.bankAccounts && response.bankAccounts.length > 0) {
                    this.bankAccount = response.bankAccounts[0];
                }
            }
        }).catch(err=>{
            this.stopLoad();
        });
    }

    /**
     * Close dialog
    */
    close() {
        this.dialogRef.close();
    }
}
