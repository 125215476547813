import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YellowCardProductPricingComponentModel } from './yellow-card-product-pricing-component.model';
import { InsuranceProductManagerService } from '../../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';

@Component({
    selector: 'app-yellow-card-product-pricing',
    templateUrl: './yellow-card-product-pricing.component.html',
    styleUrls: ['./yellow-card-product-pricing.component.scss']
})
export class YellowCardProductPricingComponent implements OnInit {
    @Input() data: YellowCardProductPricingComponentModel;
    @Input() editable: boolean = true;
    @Input() tableOnly: boolean = false;
    @Input() includeHeader: boolean = true;
    @Input() includeFooter: boolean = true;
    @Input() currency: string = '';

    @Output() onEdit = new EventEmitter<YellowCardProductPricingComponentModel>;
    @Output() onInvalid = new EventEmitter<string>();
    editing = {};
    pricingError: string = null;

    constructor(private insuranceProductManagerService: InsuranceProductManagerService) { }

    ngOnInit(): void {
        if (this.tableOnly) {
            this.includeHeader = false;
            this.includeFooter = false;
        }
        if (this.editable) {
            this.validatePricing();
        }
    }

    editItem(rowIndex, column): void {
        const columnId = this.getRowId(rowIndex, column);
        if (this.editable) {
            this.editing[columnId] = true;
            setTimeout(() => {
                document.getElementById(columnId).focus();
            }, 50);
        }
    }

    get columns(): any[] {
        const columns: string[] = [];
        this.data.pricing.forEach((category) => {
            category.coveredCountries.forEach((coveredCountry) => {
                if (!columns.includes(coveredCountry.name)) {
                    columns.push(coveredCountry.name);
                }
            });
        });
        columns.sort();
        return columns;
    }

    get rows(): any[] {
        let rows = [];

        this.data.pricing.forEach((category) => {
            let obj = {
                name: category.categoryName
            };
            category.coveredCountries.forEach((coveredCountry) => {
                if (coveredCountry.price) {
                    obj[coveredCountry.name] = coveredCountry.price.toString();
                }
            });
            rows.push(obj);
        })

        return rows;
    }

    isRowEdit(id: string): boolean {
        let found = this.editing[id];
        return found ? this.editing[id] : false;
    }

    getClass(value: string): string {
        let classes = 'display-value';
        if (value === undefined) {
            return classes + ' no-value';
        }
        return classes;
    }

    updateValue(event, cell, rowIndex) {
        const column = this.rows[rowIndex]?.name;
        const newValue = Number(event.target.value);

        this.data.pricing.forEach((category) => {
            if (category.categoryName === column) {
                if (category.coveredCountries.filter(item => item.name === cell).length > 0) {
                    category.coveredCountries.forEach((coveredCountry) => {
                        if (coveredCountry.name === cell) {
                            coveredCountry.price = newValue;
                        }
                    })
                } else {
                    category.coveredCountries.push({
                        name: cell,
                        price: newValue,
                        id: ''
                    });
                }
            }
        })

        this.editing[rowIndex + '-' + cell] = false;
        this.onEdit.emit(this.data);
        this.validatePricing();
    }

    private validatePricing(): void {
        const validationItems = this.getCountriesForValidation();
        this.data.pricing.forEach((category) => {
            category.coveredCountries.forEach((country) => {
                if (country.price > 0) {
                    let foundItem = validationItems.find(item => item.country === country.name);
                    if (foundItem) {
                        foundItem.hasPrice = true;
                    }
                }
            });
        });

        const pricingInvalid = validationItems.filter(item => item.hasPrice === false).length > 0;
        if (pricingInvalid) {
            this.pricingError = 'Each country should have pricing for one or more categories';
        } else {
            this.pricingError = null;
        }
        this.onInvalid.emit(this.pricingError);
    }

    private getCountriesForValidation(): { country: string, hasPrice: boolean }[] {
        let result = [];
        this.data.pricing.forEach((category) => {
            category.coveredCountries.forEach((country) => {
                if (result.filter(item => item.country === country.name).length === 0) {
                    result.push(
                        {
                            country: country.name,
                            hasPrice: false
                        }
                    );
                }
            });
        });

        return result;
    }

    getDisplayValue(value: string): string {
        if (value && value.length > 0) {
            return new Intl.NumberFormat('en-IN', { style: 'currency', currency: this.currency }).format(Number(value));
        };
        return '-';
    }

    getEditValue(value: string): number {
        let result;
        if (value && value.length > 0) {
            const valueAsNumber = Number(value);
            if (valueAsNumber) {
                result = valueAsNumber;
            }
        }

        return result;
    }

    getRowId(rowIndex, column): string {
        return `${rowIndex}-${column}`;
    }

    onTabPress(event: KeyboardEvent): void {
        const sourceId = event.srcElement['id'];
        const sourceSplitId = sourceId.split('-');
        const idData = this.generateTableIds();
        const index = idData.indexOf(sourceId) + 1;
        if(index <= idData.length - 1){
            this.updateValue(event, sourceSplitId[1], sourceSplitId[0]);
            this.editing[sourceId] = false;
            const focusId = idData[index];
            this.editing[focusId] = true;
        }
    }

    private generateTableIds(): string[] {
        let data = [];
        this.rows.forEach((row, index) => {
            this.columns.forEach((column) => {
                data.push(`${index}-${column}`);
            })
        });
        return data;
    }
}
