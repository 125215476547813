import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../shared/form/form.component'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service'
import {Router} from '@angular/router'
import {
    BackOfficeAuthenticatorService
} from '../../../http.services/security/back-office-authenticator/back-office-authenticator.service'
import {AgentManagerService} from '../../../app.services/managers/agent-manager/agent-manager.service'
import {
    OneTimePinVerificationManagerProviderService
} from '../../../http.services/security/onetimepin/one-time-pin-verification-manager-provider/one-time-pin-verification-manager-provider.service'
import {ToastrService} from 'ngx-toastr'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    BackOfficeAuthenticateCredentialsRequest,
    BackOfficeAuthenticateCredentialsResponse,
    PlainTextUsernameAndPassword,
    UnMarshallerService
} from "@magnabc/tpi"
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-back-office-agent-authenticator',
  templateUrl: './back-office-agent-authenticator.component.html',
  styleUrls: ['./back-office-agent-authenticator.component.scss']
})
export class BackOfficeAgentAuthenticatorComponent  extends FormComponent implements OnInit, AfterViewInit {

    formGroup: UntypedFormGroup;
    username: UntypedFormControl;
    password: UntypedFormControl;
    plainTextUsernameAndPassword: PlainTextUsernameAndPassword;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    authentic = true;
    loggingOut = false;
    unauthorised = false;

    constructor(private translate: TranslateService,
                translateParser: TranslateParser,
                private title: Title,
                private authenticationService: AuthenticationService,
                private unMarshaller: UnMarshallerService,
                private router: Router,
                private backOfficeAuthenticator: BackOfficeAuthenticatorService,
                public agentService: AgentManagerService,
                public securityService: OneTimePinVerificationManagerProviderService,
                private toastr: ErrorToastService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Agent - Login - ${this.runtimeConfigurationService.title}`);

        this.username = new UntypedFormControl('', [
            Validators.required
        ]);
        this.password = new UntypedFormControl('', [
            Validators.required
        ]);

        this.formGroup = new UntypedFormGroup({
            'username': this.username,
            'password': this.password
        });
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
    }

    logout(): void {
        this.loggingOut = true;
    }

    onCheckValidation(event): void {

        this.unauthorised = false;

        if (this.agentService.agentLogin && localStorage.getItem('agentUsername') !== this.username.value) {
            this.unauthorised = true;
            return;
        }

        if (this.form.valid) {

            const authenticateCredentialsRequest = new BackOfficeAuthenticateCredentialsRequest();
            this.plainTextUsernameAndPassword = new PlainTextUsernameAndPassword();
            this.plainTextUsernameAndPassword.username = this.username.value;
            this.plainTextUsernameAndPassword.password = this.password.value;
            authenticateCredentialsRequest.credentials = this.plainTextUsernameAndPassword;
            this.startLoad();
            this.backOfficeAuthenticator.authenticateCredentials(authenticateCredentialsRequest).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshaller.unMarshallFromJson(httpResponse.body, BackOfficeAuthenticateCredentialsResponse) as BackOfficeAuthenticateCredentialsResponse);
                    this.authentic = response.authenticationAdvice.authentic;
                    if (response.authenticationAdvice.authentic) {
                        this.authenticationService.setBackOfficeAuthentication(response.authenticationAdvice).then(result => {
                            if (result) {
                                if (!this.agentService.agentLogin) {
                                    this.agentService.agentLogin = true;
                                    localStorage.setItem('agent', JSON.stringify(this.agentService.agent));
                                    localStorage.setItem('agentIdentifier', JSON.stringify(this.agentService.agentIdentifier));
                                    localStorage.setItem('agentLogin', 'true');
                                    localStorage.setItem('agentUsername', this.username.value)
                                } else {
                                    localStorage.removeItem('agent');
                                    localStorage.removeItem('agentIdentifier');
                                    localStorage.removeItem('agentLogin');
                                    localStorage.removeItem('agentUsername');
                                }
                                this.authenticationService.logout()
                                location.reload()
                            }
                        });
                    } else {
                        this.stopLoad();
                    }
                }
            }).catch((error) => {
                this.toastr.errorToast(error);
                this.stopLoad();
            });

        }

    }

}
