import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {ActivatedRoute, Router} from '@angular/router'
import {Location} from '@angular/common'
import {
    QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {StringUtils} from '../../../../common/utils/utils.string'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {environment} from '../../../../../environments/environment'
import {ServiceList} from '../../../../common/model/service-list.model'
import {ItemNumberIdentifier, FinaliseRemoveItemRequest, RetailProfile, RetailProfileOwnerIdentifier } from '@magnabc/tpi'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent  extends FormComponent implements OnInit, OnDestroy {

    quickTransact = false;

    retailProfileListener: any;
    retailProfile: RetailProfile;

    constructor(public translate: TranslateService, public translateParser: TranslateParser,
                private title: Title,
                public profileRetailService: RetailProfileFinalisationManagerService, private errorToastService: ErrorToastService,
                private router: Router, private route: ActivatedRoute, private location: Location,
                private quickStartService: QuickTransactManagerService, private authenticationService: AuthenticationService,
                private runtimeConfigurationService: RuntimeConfigurationService
    ) {
        super(translate, translateParser);
        title.setTitle(`Cart - ${runtimeConfigurationService.title}`);
    }

    ngOnInit() {

        this.route.queryParams.forEach((params) => {
            this.quickTransact = params['quickTransact'];
            if (this.quickTransact) {
                this.retailProfileListener = (retailProfile) => {
                    if (retailProfile) {
                        this.retailProfile = this.quickStartService.retailProfile;
                    }
                };
                this.profileRetailService.refreshRetailProfile().then((refreshedProfile) => {
                    this.profileRetailService.registerRetailProfileListener(this.retailProfileListener);
                    this.retailProfile.cart = refreshedProfile.cart;
                });
            } else {
                this.retailProfileListener = (retailProfile) => {
                    if (retailProfile) {
                        this.retailProfile = this.profileRetailService.retailProfile;
                    }
                };
                this.profileRetailService.refreshRetailProfile().then(() => {
                    this.profileRetailService.registerRetailProfileListener(this.retailProfileListener);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.retailProfileListener) {
            this.profileRetailService.deregisterRetailProfileListener(this.retailProfileListener);
        }
    }

    onBack(event): void {
        this.location.back();
    }

    onSubmit(event): void {
        if (this.quickTransact) {
            if (this.retailProfile.cart.items.length > 0) {
                this.router.navigate(['retail/checkout'], {queryParams: {quickTransact: true}});
            } else {
                this.emptyCart();
            }
        } else {
            this.router.navigate(['retail/checkout']);
        }
    }

    formatCurrency(value): string {
        return StringUtils.formatCents(value);
    }

    onItemExpand(event): boolean {
        return false;
    }

    onCheckout(event): void {
        if (this.quickTransact) {
            this.router.navigate(['retail/checkout'], {queryParams: {quickTransact: true}});
        } else {
            this.router.navigate(['retail/checkout']);
        }
    }

    onRemove(productItem): boolean {

        this.translate.get('TOAST.REMOVE_ITEM_FROM_CART_CONFIRM').subscribe((res: any) => {
            const activeToast = this.errorToastService.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
            });
            activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        activeToast.portal.destroy();
                        this.removeItem(productItem);
                        break;
                    case ToastComponent.SECONDARY:
                        activeToast.portal.destroy();
                        break;
                }
            });
            activeToast.onHidden.subscribe(() => {

            });
        });

        return false;

    }

    removeItem(productItem) {
        var productItemIndex = this.retailProfile.cart.items.findIndex(item => item.number === (productItem as any).number);
        if(productItemIndex !== -1){
            this.removeItemOnCart((productItem as any).number);
            this.retailProfile.cart.items.splice(productItemIndex, 1);
            this.profileRetailService.showPopup = this.retailProfile.cart.items.length;
        }
    }

    getTotal(): string {

        let total = 0;
        for (const item of this.retailProfile.cart.items) {
            total += (item as any).thirdPartyInsuranceProduct.price.value.amount;
        }

        return this.formatCurrency(total);

    }

    removeItemOnCart(number:string): void {

        const removeItemRequest = new FinaliseRemoveItemRequest();
        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

        const itemNumberIdentifier = new ItemNumberIdentifier();
        itemNumberIdentifier.itemNumber = number;

        removeItemRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
        removeItemRequest.itemIdentifier = itemNumberIdentifier;
        this.startLoad();
        this.profileRetailService.finaliseRemoveItem(removeItemRequest).toPromise().then(response => {
            this.stopLoad();
            this.toast(response);
        }).catch(err =>{
            this.stopLoad();
            this.toast(err);
        });
    }

    toast(response) {

        this.translate.get('TOAST.REMOVE_FROM_CART').subscribe((res: any) => {
            const activeToast = this.errorToastService.success(this.translateParser.interpolate(res.MESSAGE, {}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        activeToast.portal.destroy();
                        break;
                }
            });
            activeToast.onHidden.subscribe(() => {

            });
        });
    }

    emptyCart() {

        this.translate.get('TOAST.EMPTY_CART').subscribe((res: any) => {
            this.errorToastService.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
        });
    }

    servicesRemoveFromCart(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.REMOVEITEM]) || this.authenticationService.isAuthorised([ServiceList.ASSISTEDQUICKTRANSACT]));
    }

}
