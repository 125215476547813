import {Request} from '../../../../../../urdad/services/models/request.model';

export class ReceivePayfastPaymentRequest extends Request{

  constructor(){
      super();
  }

  public payload: any;

}