import { Criteria } from '../../../../criteria/models/criteria.model';
import { LegalEntityIdentifier } from '../../../../entity/identification/models/legal-entity-identifier.model';

export class ProposalRecordSummaryLegalEntityCriteria extends Criteria{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalRecordSummaryLegalEntityCriteria";
    }

    public legalEntityIdentifier: LegalEntityIdentifier;

}