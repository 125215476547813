import {Criteria} from "../../../criteria/models/criteria.model";
import {ProductIdentifier} from "../../products/identification/models/product-identifier.model";


export class ProductCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.ProductCriteria";
    }

    public productIdentifier : ProductIdentifier;
}
