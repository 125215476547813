import {Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {Router} from '@angular/router'
import {
    ServiceGroupInformationProviderService
} from '../../../../http.services/security/authorisation/service-group-information-provider/service-group-information-provider.service'
import {
    ServiceIdentifierProviderService
} from '../../../../http.services/security/authorisation/identification/service-identifier-provider/service-identifier-provider.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ToastComponent} from '../../../shared/toast/toast.component'
import {environment} from '../../../../../environments/environment'
import {
    AllServiceGroupCriteria,
    ProvideServiceGroupsRequest,
    ProvideServiceGroupsResponse,
    ServiceGroup,
    UnMarshallerService
} from "@magnabc/tpi"
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

declare const jQuery: any;

@Component({
  selector: 'app-service-group-list',
  templateUrl: './service-group-list.component.html',
  styleUrls: ['./service-group-list.component.scss']
})
export class ServiceGroupListComponent  extends FormComponent implements OnInit {

    rows: ServiceGroup[] = [];
    columns = [
        {name: 'name'},
        {description: 'description'}
    ];

    status: { [status: string]: any } = {};
    activeToast: ActiveToast<any>;
    temp: ServiceGroup[] = [];

    @ViewChild(DatatableComponent, {static: true}) table: DatatableComponent;
    type: string;
    id: string;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                private serviceGroupInformationProviderService: ServiceGroupInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private serviceIdentifierProviderService: ServiceIdentifierProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Service Groups - Console - ${this.runtimeConfigurationService.title}`);

        this.temp = this.rows;
    }

    ngOnInit(): void {
        this.onToggleSearch();

        const promises = [];

        promises.push(this.getServiceGroups());

        Promise.all(promises).then((results) => {

            const serviceGroups = [];

            for (const list of results) {
                for (const i of list) {
                    serviceGroups.push(i);
                }
            }

            this.rows = serviceGroups;
            this.rows.sort((first, second) => {
                if (first.name > second.name) {
                    return 1;
                }
                if (first.name < second.name) {
                    return -1;
                }
                return 0;
            });

            this.rows.forEach(row => {
                this.sortDisplayList(row);
            });

            this.temp = this.rows;

            for (const s of serviceGroups) {
                this.status[s.name] = s.active;
            }

        });

    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter((d) => {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    onSelect(event): void {
        const serviceGroup = event.selected[0] as ServiceGroup;
        this.router.navigate(['/service-groups/' + serviceGroup.type + '/' + serviceGroup.name.split(' ').join('-')]);
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/service-group/create']);
    }

    onRemove(event, id, title): boolean {
        event.stopPropagation();

        this.translate.get('TOAST.REMOVE_SERVICE_GROUP').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: title}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        // TODO
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

        return false;
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    private getServiceGroups(): Promise<ServiceGroup[]> {

        return new Promise<ServiceGroup[]>((resolve) => {

            const criteria = new AllServiceGroupCriteria();

            const provideServiceGroupsRequest: ProvideServiceGroupsRequest = new ProvideServiceGroupsRequest();
            provideServiceGroupsRequest.criteria = criteria;
            this.serviceGroupInformationProviderService.provideServiceGroups(provideServiceGroupsRequest)
                .then((httpResponse) => {

                    if (httpResponse && httpResponse.body) {
                        const response =
                            (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideServiceGroupsResponse) as ProvideServiceGroupsResponse);
                        resolve(response.serviceGroups);
                    }
                });

        });

    }

    private toast(title) {

        this.translate.get('TOAST.SERVICE_GROUP_REMOVED').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: title}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    sortDisplayList(row) {
        row.linked.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }
}
