import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActivatedRoute, Router} from "@angular/router"
import {ActiveToast} from "ngx-toastr"
import {NaturalPersonComponent} from "../../../../view.components/entity/natural-person/natural-person.component"
import {FormComponent} from "../../../../view.components/shared/form/form.component"
import {LegalEntityComponent} from "../../../../view.components/entity/legal-entity/legal-entity.component"
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component"
import {environment} from "../../../../../environments/environment"
import {Location} from "@angular/common"
import {
  FinaliseUpdateLegalEntityRequest,
  JudicialPerson,
  LegalEntity,
  LegalEntityPersistenceManagerType,
  LegalEntityProviderCriteria,
  NaturalPerson,
  QueryLegalEntityInformationRequest,
  QueryLegalEntityInformationResponse,
  RetailProfile,
  TpiIdentifier,
  UnMarshallerService,
  Vehicle
} from "@magnabc/tpi"
import {JudicialPersonComponent} from "../../../../view.components/entity/judicial-person/judicial-person.component"
import {MatDialog} from "@angular/material/dialog"
import {ServiceList} from "../../../../common/model/service-list.model"
import {Utils} from "../../../../view.components/shared/Utils/Utils"
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
  RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
  QueryLegalEntityInformationManagerService
} from '../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service'
import {CountryService} from "../../../../view.components/shared/country/services/country.service"
import {
  LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service"
import {Title} from '@angular/platform-browser'
import {
  QuickTransactManagerService
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {DialogUpdatePasswordComponent} from "./dialog-update-password/dialog-update-password"
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    templateUrl: './retail-profile-update-page.component.html',
    styleUrls: ['../retail-profile-page/retail-profile-page.component.scss']
})
export class RetailProfileUpdatePageComponent extends FormComponent implements OnInit, OnDestroy {

    vehicle: Vehicle;
    utils = Utils;
    retailProfile: RetailProfile;

    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    @ViewChild(JudicialPersonComponent) judicialPersonComponent: JudicialPersonComponent;
    @ViewChild(LegalEntityComponent) legalEntityComponent: LegalEntityComponent;

    legalEntity: LegalEntity;
    naturalPerson: NaturalPerson;
    judicialPerson: JudicialPerson;
    activeToast: ActiveToast<any>;
    judicialKey: {[id: string]: boolean} = {};
    users: NaturalPerson[] = [];
    changeMobile = false;

    constructor(private translate: TranslateService, private translateParser: TranslateParser, private dialog: MatDialog,
                private title: Title,
                private retailProfileService: RetailProfileFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private router: Router, private route: ActivatedRoute,
                private toastr: ErrorToastService,
                public authenticationService: AuthenticationService,
                public entityService: LegalEntityFinalisationManagerService,
                public backOfficeEntityService: LegalEntityFinalisationManagerService,
                private location: Location,
                public quickStartService: QuickTransactManagerService,
                private countryService: CountryService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Retail Profile - Update - ${this.runtimeConfigurationService.title}`);

    }

    ngOnInit(): void {

        if(this.authenticationService.isBackOfficeUser() && !this.quickStartService.retailProfile){
            this.getLegalEntity(Utils.getTpiIdentifier(this.authenticationService.getLoggedInNaturalPerson().legalEntityIdentifiers));
        }
        else{
            this.retailProfileService.refreshRetailProfile().then(retailProfile=> {
                console.log(retailProfile)
                if (retailProfile) {
                    this.getLegalEntity(retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier)
                }
            })
        }
    }

    ngOnDestroy(){
        if (this.naturalPersonComponent) {
            this.naturalPersonComponent.ngOnDestroy();
        }
    }

    getLegalEntity(identifier: TpiIdentifier): Promise<boolean> {

        const criteria = new LegalEntityProviderCriteria();
        criteria.legalEntityIdentifier = identifier;

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = identifier;
        if(this.authenticationService.isBackOfficeUser() && !this.quickStartService.retailProfile){
            this.countryService.populateCountries();
            legalEntityProviderCriteria.type = 'INTERNAL_BACKOFFICE';
        }
        else{
            legalEntityProviderCriteria.type = 'INTERNAL_PUBLIC';
        }

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest();
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        return new Promise<boolean>(resolve => {
            this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {
                    const provideLegalEntityInformationResponse = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities.length > 0) {
                        const legalEntity = provideLegalEntityInformationResponse.legalEntities[0];
                        this.legalEntity = legalEntity;

                        if (legalEntity instanceof JudicialPerson) {
                            // Judicial
                            if (!this.judicialKey[Utils.getTpiIdentifier(legalEntity.legalEntityIdentifiers).tpiIdentifier]) {
                                this.judicialKey[Utils.getTpiIdentifier(legalEntity.legalEntityIdentifiers).tpiIdentifier] = true;
                                this.legalEntity = legalEntity;
                            }
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            });
        });
    }

    canDeactivate(): boolean {

        let legalEntity = this.entityService.legalEntity
        if (this.quickStartService.legalEntity) {
            legalEntity = this.quickStartService.legalEntity
        }

        if (!legalEntity) {
            return true;
        }

        if (this.isNaturalPerson(legalEntity)) {
            return this.naturalPersonComponent && this.naturalPersonComponent.canDeactivate() && this.legalEntityComponent && this.legalEntityComponent.canDeactivate();
        } else {
            return this.judicialPersonComponent && this.judicialPersonComponent.canDeactivate() && this.legalEntityComponent && this.legalEntityComponent.canDeactivate();
        }

    }

    submit(): void {
        if (this.isNaturalPerson(this.legalEntity)) {
            this.naturalPersonComponent.submit();
        } else {
            this.judicialPersonComponent.submit();
        }
    }

    isNaturalPerson(legalEntity: any): boolean {
        if(legalEntity && (legalEntity instanceof NaturalPerson || legalEntity['@class'] === new NaturalPerson()['@class']))
        {
            this.naturalPerson = legalEntity;
        }
        return legalEntity && !!legalEntity.firstNames;
    }

    isJudicialPerson(legalEntity: any): boolean {
        if(legalEntity && (legalEntity instanceof JudicialPerson || legalEntity['@class'] === new JudicialPerson()['@class']))
        {
            this.judicialPerson = legalEntity;
        }
        return legalEntity && !!legalEntity.name;
    }

    getAddressLabel(): string {
        if (this.isJudicialPerson(this.legalEntity)) {
            return 'Physical';
        } else {
            return 'Residential';
        }
    }

    isDirty(): boolean {
        return (this.naturalPersonComponent && this.naturalPersonComponent.isDirty()) ||
            (this.judicialPersonComponent && this.judicialPersonComponent.isDirty()) ||
            (this.legalEntityComponent && this.legalEntityComponent.isDirty());
    }

    onNaturalPersonValid(event): void {
        this.legalEntityComponent.submit();
    }

    onJudicialPersonValid(event): void {
        this.legalEntityComponent.submit();
    }

    onLegalEntityValid(event): void {

        if(this.authenticationService.isBackOfficeUser() && !this.quickStartService.retailProfile)
        {
            const updateLegalEntity = new FinaliseUpdateLegalEntityRequest();
            const entityIdentifier = Utils.getLegalEntityIdentifier(this.legalEntity.legalEntityIdentifiers);
            updateLegalEntity.legalEntity = this.legalEntity;
            updateLegalEntity.pmType = LegalEntityPersistenceManagerType.BACKOFFICE;

            this.startLoad();
            this.backOfficeEntityService.finaliseUpdateLegalEntity(updateLegalEntity).toPromise().then(response => {
                this.stopLoad();
                this.entityService.refreshLegalEntity(entityIdentifier);
                this.toast(response);
            }).catch(err=>{
                this.stopLoad();
            });

        }
        else{
            const finaliseUpdateLegalEntityRequest = new FinaliseUpdateLegalEntityRequest();
            const entityIdentifier = Utils.getLegalEntityIdentifier(this.legalEntity.legalEntityIdentifiers);

            finaliseUpdateLegalEntityRequest.legalEntity = this.legalEntity;
            finaliseUpdateLegalEntityRequest.pmType = LegalEntityPersistenceManagerType.PUBLIC;

            this.startLoad();
            this.entityService.finaliseUpdateLegalEntity(finaliseUpdateLegalEntityRequest).toPromise().then(response => {
                this.stopLoad();
                if (!this.quickStartService.retailProfile) {
                    this.entityService.refreshLegalEntity(entityIdentifier);
                } else {
                    this.quickStartService.legalEntity = this.legalEntity
                }
                this.toast(response);
            }).catch(err=>{
                this.stopLoad();
            });
        }

    }

    onLegalEntity(legalEntity): void {
        this.legalEntity = legalEntity;
    }

    toast(response) {
        this.translate.get('TOAST.UPDATE_DROPDOWN').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: "User Profile"}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
                if(this.authenticationService.isBackOfficeUser() && !this.quickStartService.retailProfile)
                {
                    this.authenticationService.refreshBackOfficeAuthentication();
                }
            });
        });

    }

    onChangeMobile(event): void {
        this.changeMobile = true;
    }

    onMobileComplete(event): void {
        if (event) {
            this.entityService.legalEntity = event;
        }
        this.changeMobile = false;
    }

    onChangePassword(event): void {
        this.openDialog();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogUpdatePasswordComponent, {
            width: '80%',
            panelClass: 'padded-modal',
            position: {top: "5%"},
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.passwordUpdated(result.created);
            }
        });
    }

    passwordUpdated(created) {

        if (!created) {
            this.translate.get('TOAST.UPDATE_PASSWORD').subscribe((res: any) => {
                this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            });
        } else {
            this.translate.get('TOAST.CREATE_PASSWORD').subscribe((res: any) => {
                this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: ""}), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            });
        }

    }

    servicesResetPassword(): boolean {
        return !!this.authenticationService.isAuthorised([ServiceList.UPDATEPASSWORD]);
    }

}
