import {Component, ViewChild} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {NaturalPersonComponent} from "../../../../view.components/entity/natural-person/natural-person.component";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {QueryLegalEntityComponent} from "../../../../view.components/entity/query-legal-entity/query-legal-entity.component";
import {LegalEntityComponent} from "../../../../view.components/entity/legal-entity/legal-entity.component";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {environment} from "../../../../../environments/environment";
import {RetailProfileFinalisationManagerService} from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import {IDropdown} from "../../../../common/model/dropdown.model";
import {JudicialPersonComponent} from "../../../../view.components/entity/judicial-person/judicial-person.component";
import {IStepComponent} from "../../../../view.components/shared/guards/step-guard";
import {LocationStrategy} from "@angular/common";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {
    FinaliseUpdateRetailProfileRequest,
    LegalEntity,
    RetailProfile,
    RegistrationNumber,
    FinaliseInsertLegalEntityRequest
} from "@magnabc/tpi";
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

declare const window: any;
@Component({
    templateUrl: './retail-profile-create-page.component.html',
    styleUrls: ['../retail-profile-page/retail-profile-page.component.scss']
})
export class RetailProfileCreatePageComponent extends FormComponent implements IStepComponent {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;

    legalEntity: LegalEntity;

    @ViewChild(QueryLegalEntityComponent) entityQueryComponent: QueryLegalEntityComponent;
    @ViewChild(NaturalPersonComponent) naturalPersonComponent: NaturalPersonComponent;
    @ViewChild(JudicialPersonComponent) JudicialPersonComponent: JudicialPersonComponent;
    @ViewChild(LegalEntityComponent) legalEntityComponent: LegalEntityComponent;

    activeToast: ActiveToast<any>;
    type: IDropdown;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private router: Router,
                private toastr: ErrorToastService,
                private legalEntityFinalisationManagerService: LegalEntityFinalisationManagerService,
                private retailProfileService: RetailProfileFinalisationManagerService, locationStrategy: LocationStrategy) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        }
    }

    onType(event): void {

        this.steps = [];
        setTimeout(() => {

            this.type = event;

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
            });
            if (event.value === 1) {
                // this.legalEntity = new NaturalPerson();

                this.translate.get("ENTITY.TITLE_PERSONAL_DETAILS").subscribe((response) => {
                    this.steps.push({id: 1, name: response, icon: "fa-user", borderColor: "#3171c3"});
                });

            } else {
                // this.legalEntity = new JudicialPerson();

                this.translate.get("ENTITY.TITLE_COMPANY_DETAILS").subscribe((response) => {
                    this.steps.push({id: 1, name: response, icon: "fa-building", borderColor: "#3f5baa"});
                });
            }
            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 2, name: response, icon: "fa-phone", borderColor: "#3f5baa"});
            });

        });

    }

    onSubmit(event): void {
        switch (this.currentStep) {
            case 0:
                this.entityQueryComponent.submit();
                break;
            case 1:
                if (this.type.value === 1) {
                    this.naturalPersonComponent.submit();
                } else {
                    this.JudicialPersonComponent.submit();
                }
                break;
            case 2:
                this.legalEntityComponent.submit();
                break;
        }
    }

    onExists(exists): void {
        if (exists) {
            this.router.navigate(['/retail/profile/' + (Utils.getLegalEntityIdentifier(this.legalEntity.legalEntityIdentifiers) as RegistrationNumber).countryOfIssue + '/' + (Utils.getLegalEntityIdentifier(this.legalEntity.legalEntityIdentifiers) as RegistrationNumber).number]);
        }
    }

    onNext(event): void {

        if (this.currentStep === 2) {
            this.submitNaturalPerson();
        } else {
            this.currentStep++;
            window.scrollTo(0, 0);
        }

    }

    onNaturalPerson(naturalPerson): void {
        this.legalEntity = naturalPerson;
    }

    onJudicialPerson(naturalPerson): void {
        this.legalEntity = naturalPerson;
    }

    submitNaturalPerson(): void {
        const finaliseInsertLegalEntityRequest = new FinaliseInsertLegalEntityRequest();
        finaliseInsertLegalEntityRequest.legalEntities.push(this.legalEntity);
        this.startLoad();
        this.legalEntityFinalisationManagerService.finaliseInsertLegalEntity(finaliseInsertLegalEntityRequest).subscribe(() => {
            this.createRetailProfile();
        });
    }

    createRetailProfile(): void {
        const updateRetailProfile = new FinaliseUpdateRetailProfileRequest();
        const retailProfile: RetailProfile = new RetailProfile();
        retailProfile.legalEntityDescriptionIdentifier = Utils.getLegalEntityDescriptionIdentifier(this.legalEntity.legalEntityIdentifiers);
        updateRetailProfile.retailProfile = retailProfile;
        this.startLoad();
        this.retailProfileService.finaliseUpdateRetailProfile(updateRetailProfile).subscribe(response => {
            this.stopLoad();
            this.toast(response);
        });
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    toast(response) {

        const identifier = (Utils.getLegalEntityIdentifier(this.legalEntity.legalEntityIdentifiers) as RegistrationNumber);

        this.translate.get('TOAST.CREATE_RETAIL_PROFILE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: "Legal EntityØ"}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.router.navigate(['/retail/profile/' + identifier.countryOfIssue + '/' + identifier.number]);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.router.navigate(['/retail/profile/' + identifier.countryOfIssue + '/' + identifier.number]);
            });
        });
    }

}
