import {Component, OnInit, ViewChild} from '@angular/core';
import {ServiceGroupComponent} from "../../../../view.components/security/authorisation/service-group/service-group.component";
import {environment} from "../../../../../environments/environment";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ServiceGroupInformationProviderService} from "../../../../http.services/security/authorisation/service-group-information-provider/service-group-information-provider.service";
import {ServiceGroupFinalisationManagerService} from "../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {
    ProvideServiceGroupsRequest,
    ProvideServiceGroupsResponse,
    ServiceGroup,
    ServiceGroupNameIdentifier,
    ServiceGroupNameTypeCriteria,
    UnMarshallerService,
    FinaliseUpdateServiceGroupRequest
} from "@magnabc/tpi";
import {Title} from '@angular/platform-browser';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-service-group-update',
    templateUrl: './service-group-update-page.component.html',
    styleUrls: ['./service-group-update-page.component.scss']
})
export class ServiceGroupUpdatePageComponent extends FormComponent implements OnInit {

    serviceGroup: ServiceGroup;
    duplicateError = false;

    @ViewChild(ServiceGroupComponent) serviceGroupComponent: ServiceGroupComponent;
    private activeToast: ActiveToast<any>;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private toastr: ErrorToastService,
                private authenticationService: ServiceGroupFinalisationManagerService,
                private authenticationInformationService: ServiceGroupInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private router: Router,
                private location: Location,
                private route: ActivatedRoute,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);
        title.setTitle(`Update Service Group - Console - ${this.runtimeConfigurationService.title}`);
    }

    ngOnInit() {

        this.route.params.subscribe((params) => {
            this.getServiceGroup(params.type, params.name.split('-').join(' '));
        });

    }

    getServiceGroup(type: string, name: string): void {

        const serviceGroupNameTypeCriteria = new ServiceGroupNameTypeCriteria();
        serviceGroupNameTypeCriteria.type = type;
        serviceGroupNameTypeCriteria.name = name;
        const provideServiceGroupRequest = new ProvideServiceGroupsRequest();
        provideServiceGroupRequest.criteria = serviceGroupNameTypeCriteria;
        this.authenticationInformationService.provideServiceGroups(provideServiceGroupRequest).then((httpResponse) => {
            if (httpResponse && httpResponse.body) {
                const response =
                    (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideServiceGroupsResponse) as ProvideServiceGroupsResponse);
                this.serviceGroup = response.serviceGroups[0];
            }
        });

    }

    onServiceGroup(serviceGroup: ServiceGroup): void {

        const serviceGroupIdentifier = new ServiceGroupNameIdentifier();
        serviceGroupIdentifier.name = this.serviceGroup.name;

        const finaliseUpdateServiceGroupRequest = new FinaliseUpdateServiceGroupRequest();
        finaliseUpdateServiceGroupRequest.oldServiceGroup = serviceGroupIdentifier;
        finaliseUpdateServiceGroupRequest.newServiceGroup = serviceGroup;
        this.serviceGroup = serviceGroup;
        this.startLoad();
        this.duplicateError = false;
        this.authenticationService.finaliseUpdateServiceGroup(finaliseUpdateServiceGroupRequest).then(() => {
            this.stopLoad();
            this.toast();
        }, (error) => {
            this.stopLoad();
            if (error.error.trim() === "UpdatedServiceGroupAlreadyExistException") {
                this.duplicateError = true;
            }
        });

    }

    submit(): void {
        this.serviceGroupComponent.submit();
    }

    toast() {

        this.translate.get('TOAST.UPDATE_SERVICE_GROUP').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.serviceGroup.name}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        this.location.back();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });
    }

}
