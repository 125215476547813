import {ProductIdentifier} from '../../products/identification/models/product-identifier.model';

export class InsuranceProductCartItem {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.InsuranceProductCartItem"; 
    }

    name: string;
    description: string;
    productIdentifier: ProductIdentifier;

}
