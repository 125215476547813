import {Component, OnInit, ViewChild} from '@angular/core';
import {ServiceGroupComponent} from "../../../../view.components/security/authorisation/service-group/service-group.component";
import {environment} from "../../../../../environments/environment";
import {ToastComponent} from "../../../../view.components/shared/toast/toast.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ServiceGroupFinalisationManagerService} from "../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {FinaliseCreateServiceGroupRequest, ServiceGroup} from "@magnabc/tpi";
import {Title} from '@angular/platform-browser';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-service-group-create',
    templateUrl: './service-group-create-page.component.html',
    styleUrls: ['./service-group-create-page.component.scss']
})
export class ServiceGroupCreatePageComponent extends FormComponent implements OnInit {

    serviceGroup: ServiceGroup;
    duplicateError = false;

    @ViewChild(ServiceGroupComponent, { static: true }) serviceGroupComponent: ServiceGroupComponent;
    private activeToast: ActiveToast<any>;

    constructor(private translate: TranslateService,
                private title: Title,
                private translateParser: TranslateParser,
                private toastr: ErrorToastService,
                private authenticationService: ServiceGroupFinalisationManagerService,
                private router: Router,
                private location: Location,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Create Service Group - Console - ${this.runtimeConfigurationService.title}`);

        this.serviceGroup = new ServiceGroup();

    }

    ngOnInit() {
    }

    onServiceGroup(serviceGroup: ServiceGroup): void {

        this.serviceGroup = serviceGroup;
        const createServiceGroupRequest = new FinaliseCreateServiceGroupRequest();
        createServiceGroupRequest.serviceGroup = serviceGroup;
        this.duplicateError = false;
        this.authenticationService.finaliseCreateServiceGroup(createServiceGroupRequest).then((response) => {
            this.toast(response);
        }, (error) => {
            if (error.error === "ServiceGroupAlreadyExistsException") {
                this.duplicateError = true;
            }
        });

    }

    submit(): void {
        this.serviceGroupComponent.submit();
    }

    toast(response) {

        this.translate.get('TOAST.CREATE_SERVICE_GROUP').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.serviceGroup.name}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        this.serviceGroupComponent.initServiceGroup();
                        break;
                    case ToastComponent.SECONDARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });
    }

}
