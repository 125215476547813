import { Criteria } from "../../../criteria/criteria_api";
import { OrderIdentifier } from "../identification/models/order-identifier.model";

export class OrderNumberCriteria extends Criteria{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.OrderNumberCriteria";
    }
     
    public orderIdentifiers: OrderIdentifier [] = [];
}