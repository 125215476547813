import {InsurancePolicyVehicleSummary} from './insurance-policy-vehicle-summary.model';

export class InsurancePolicySummary {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.InsurancePolicySummary";
    }

    public policyNumber: string;
    public underwriterName: string;
    public insuranceVehicleCategoryDescription: string;
    public expiryDate: Date;
    public insurancePolicyVehicleSummaries: InsurancePolicyVehicleSummary[] = []

}
