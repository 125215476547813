import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarshallerService, RetrieveExchangeRateRequest } from '@magnabc/tpi';
import { RuntimeConfigurationService } from '../../../app.services/common/runtime-configuration/runtime-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RetrieveExchangeRateService {

    basePath = '/financial/exchangeratemanager';
    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };


    constructor(
        private http: HttpClient,
        private runtimeConfigurationService: RuntimeConfigurationService,
        private marshallerService: MarshallerService
    ) { }

    retrieveExchangeRate(retrieveExchangeRateRequest: RetrieveExchangeRateRequest): Promise<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/retrieveexchangerate';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(retrieveExchangeRateRequest);
        return this.http.post(path, jsonRequest, this.options).toPromise();
    }

}
