import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {RuntimeConfigurationService} from '../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;
@Component({
    templateUrl: './dropdowns-page.component.html',
    styleUrls: ['./dropdowns-page.component.scss']
})
export class DropdownsPageComponent implements OnInit {

    rows = [
        { name: 'Vehicle Make' },
    ];
    columns = [
        { prop: 'name' },
    ];
    temp = [];

    constructor(public translate: TranslateService,
                private router: Router,
                private title: Title,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Dropdowns - Console - ${runtimeConfigurationService.title}`);
        this.temp = this.rows;
    }

    ngOnInit(): void {
        this.onToggleSearch();
    }

    //noinspection JSUnusedLocalSymbols
    onSelect(event): void {
        //noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/makemodeltrim/make']);
    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

}
