import {Injectable} from '@angular/core';
import {AllCountryCriteria, Country, ProvideCountryInformationRequest, ProvideCountryInformationResponse, UnMarshallerService} from '@magnabc/tpi';
import {CountryProviderService} from '../../../../http.services/location/geographic/country-provider/country-provider.service';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    private countries: Country[] = [];

    constructor(private countryProviderService: CountryProviderService,
                private unMarshallerService: UnMarshallerService) {
    }

    setCountries(countries:Country[]) {
        this.countries = [];
        this.countries = [...countries];
    }

    getCountries(): Country[] {
        return this.countries;
    }

    populateCountries(): Promise<any> {

        const request = new ProvideCountryInformationRequest();
        request.criteria = new AllCountryCriteria();

        return new Promise<any>(resolve => {
            this.countryProviderService.provideCountryInformation(request).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                    this.countries = response.countries;
                    resolve(this.countries);
                    return;
                }

                resolve(null);
            });
        });
    }
}
