import { OrderIdentifier } from "./order-identifier.model";
import { OrderItemIdentifier } from "./order-item-identifier.model";

export class OrderItemOrderNumberIdentifier extends OrderItemIdentifier {

    orderItemIdentifier: OrderItemIdentifier;
    orderIdentifier: OrderIdentifier;

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.checkout.identification.OrderItemOrderNumberIdentifier";
    }
}
