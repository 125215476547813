import {Request} from '../../../../../../urdad/urdad_api'
import {
    BrokerIdentifier,
    Excess,
    InsuranceCategory,
    Limit, Product,
    UnderwriterIdentifier,
    YCPriceInterval,
    YCPriceSchedule
} from '../../../../../retail_api'
import {AgentIdentifier} from '../../../../../../entity/identification/models/agent-identifier.model'
import { Country } from '../../../../../../location/geographic/models/country.model';

export class FinaliseCreateYCInsuranceProductRequest extends Request {
    constructor() {
        super();
    }

    public name: string;
    public description: string;
    public conditions: string;
    public insuranceCategory: InsuranceCategory;
    public active: boolean;
    public baseCurrency: string;

    public limits: Limit[];
    public excesses: Excess[] = [];
    public brokerIdentifier: BrokerIdentifier;
    public underwriterIdentifier: UnderwriterIdentifier;
    public agentIdentifiers: AgentIdentifier[] = [];
    public ycPriceSchedules: YCPriceSchedule[];
    public ycPriceIntervals: YCPriceInterval[];
    public sellingToCountry: Country;
    public products: Product[] = [];
}
