import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Country } from '@magnabc/tpi';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
    @Input() supportedCountries: Country[];
    @Input() selectedCountries: string[] = [];
    @Input() multiSelect = true;

    @Input() columns = 3;
    @Input() rowHeight = "4:1";

    @Output() onSelect = new EventEmitter<Country[]>();
    @Output() countryOptions = new EventEmitter<Country[]>();

    countries = new BehaviorSubject<Country[]>([]);
    storeCountries: Country[] = [];
    allSelected = false;
    allMarked = false;

    constructor() { }

    ngOnInit(): void {
        this.countries.next(this.supportedCountries);
        this.countryOptions.emit(this.supportedCountries);

        const filtered = this.countries.value.filter(item => this.selectedCountries.includes(item.description));
        this.storeCountries = [... new Set(this.storeCountries.concat(filtered))];
    }

    checkCountry(event: MatRadioChange, country: Country){
        const checked = event.source.checked
        this.storeCountries = [];
        this.toggleCountry(checked, country);
    }

    toggleCountry(checked: boolean, country: Country, emitAfterChange = true) {
        const findItem = (item: Country) => item.description === country.description;
        const itemIndex = this.storeCountries.findIndex(findItem);

        if (checked) {
            if (itemIndex === -1) {
                this.storeCountries.push(country);
            }
        } else {
            if (this.allMarked) {
                this.allMarked = false;
            }
            if (itemIndex > -1) {
                this.storeCountries.splice(itemIndex, 1);
            }
        }

        if (emitAfterChange) {
            this.emitChanges();
        }
    }

    toggleAll(checked: boolean): void {
        this.allSelected = !this.allSelected;
        setTimeout(() => {
            this.allSelected = checked;
            this.allMarked = checked;
            this.countries.value.forEach((item) => {
                this.toggleCountry(checked, item, false);
            });
            this.emitChanges();
        }, 10)
    }

    emitChanges(): void {
        this.onSelect.emit(this.storeCountries);
    }

    selected(value: string): boolean {
        if (this.allSelected) return true;
        return this.selectedCountries.includes(value);
    }
}
