import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {InitialiseCreateVerifiedAccountRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class VerifiedAccountInitialisationManagerService {
    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseCreateVerifiedAccount(initialiseCreateVerifiedAccountRequest: InitialiseCreateVerifiedAccountRequest) {
        const URI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/verifiedaccountinitialisationmanager/initialisecreateverifiedaccount';

        const jsonRequest = this.marshallerService.marshallObjectToJSON(initialiseCreateVerifiedAccountRequest);

        return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
    }
}
