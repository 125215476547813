import { VehicleIdentifier } from "./vehicle-identifier.model";
import { CountryIdentifier } from "../../../location/geographic/identification/models/country-identifier.model";


export class VehicleRegisterNumber extends VehicleIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.vehicle.identification.VehicleRegisterNumber";
    }

    public countryOfIssue:CountryIdentifier;
    public number:string;

}
