import { Criteria } from "../../../../../criteria/criteria_api";
import { Request } from "../../../../../urdad/urdad_api";


export class ProvideProposalRecordSummaryRequest extends Request {
    constructor() {
        super();
    }

    public batchSize: number;
    public batchNumber: number;
    public criteria: Criteria;
}