import {ServiceGroup} from '@magnabc/tpi';

export class UserSummary{
    id:number;
    name:string;
    surname:string;
    email:string;
    status:string;
    tpiIdentifier:string;
    serviceGroup: ServiceGroup[] = [];
}
