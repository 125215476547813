import { JournalEntryType } from "../../models/journal-entry-type.model";

export class AccountStatementReportEntry
{ 
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.reporting.AccountStatementReportEntry";
    }

    public amount: number;
    public amountType: JournalEntryType;
    public date: Date;
    public description: string;

}