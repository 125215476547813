import { Criteria } from '../../../../../../../criteria/models/criteria.model';
import { Request } from '../../../../../../../urdad/urdad_api';

export class ProvideInsuranceTypeRequest extends Request {
  constructor() {
      super();
  }

  public criteria : Criteria;
}
