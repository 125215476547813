import {Component, OnInit} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {IStepComponent} from '../../../shared/guards/step-guard'
import {UntypedFormControl} from '@angular/forms'
import {Dropdown} from '../../../../common/model/dropdown.model'
import {ActivatedRoute} from '@angular/router'
import {RenderService} from '../../../../http.services/rendering/renderer/rendering.service'
import {
    FinancialReportProviderService
} from '../../../../http.services/ledger/reporting/financial-report-provider/financial-report-provider.service'
import {
    UnderwriterInformationProviderService
} from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {AgentManagerService} from '../../../../app.services/managers/agent-manager/agent-manager.service'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {
    AccountingTransactionInformationProviderService
} from '../../../../http.services/ledger/accountingtransaction/accounting-transaction-information-provider/accounting-transaction-information-provider.service'
import {
    ServiceGroupFinalisationManagerService
} from '../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {PDFDisplayModel, PDFPrintModel, PrintService} from '../../../../app.services/common/printing/print.service'
import {ServiceList} from '../../../../common/model/service-list.model'
import * as moment from 'moment/moment'
import * as XLSX from 'xlsx'
import {StringUtils} from '../../../../common/utils/utils.string'
import {
    AccountingTransaction,
    AccountingTransactionReferenceDateCriteria,
    AccountingTransactionReferenceIdentifier,
    AccountReportLegalEntityDateCriteria,
    AccountStatementPeriod,
    AccountStatementReport,
    AccountStatementReportConceptualDocument,
    ConceptualDocumentType,
    FinancialAccount,
    FinancialAccountNumberIdentifier,
    ProvideAccountingTransactionInformationRequest,
    ProvideAccountingTransactionInformationResponse,
    ProvideAccountStatementReportRequest,
    ProvideAccountStatementReportResponse,
    RenderDocumentRequest,
    UnMarshallerService
} from '@magnabc/tpi'

type DoubleArray = any[][];
declare const jQuery: any;

@Component({
  selector: 'app-account-reports',
  templateUrl: './account-reports.component.html',
  styleUrls: ['./account-reports.component.scss']
})
export class AccountReportsComponent extends FormComponent implements OnInit,IStepComponent {

    reportType : string;
    accountStatementReport= new AccountStatementReport();
    accountingTransactions= new Array<AccountingTransaction>();
    transactionEntries = new Array<any>();
    from;
    to;
    documentToPrint: any;
    statementPeriod: UntypedFormControl;
    accountType: string;
    entity: string;
    financialAccountsList : FinancialAccount[];
    statementPeriods = [
        new Dropdown(1, "Daily"),
        new Dropdown(2, "Weekly"),
        new Dropdown(3, "Monthly")
    ];

    accountSettlementData: DoubleArray = [];
    settlementHistoryData: DoubleArray = [];
    currency: string;
    fileName = 'export.xlsx';
    noResults = false;
    queryBy = 0;
    accountNumber: string;
    numberOfPages: number;


    constructor(private route: ActivatedRoute,
                private renderService: RenderService,
                private financialReportProviderService: FinancialReportProviderService,
                private unMarshallerService: UnMarshallerService,
                private brokerService : UnderwriterInformationProviderService,
                private agentService: AgentManagerService,
                private translate: TranslateService,
                private translateParser: TranslateParser,
                public accountingTransactionInformationProvider: AccountingTransactionInformationProviderService,
                public authenticationService: ServiceGroupFinalisationManagerService,
                private errorToastService: ErrorToastService,
                private printService: PrintService) {
        super(translate, translateParser);
    }

    backPressed: boolean;

    onDeactivate(): boolean {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.accountType = "Back office user"
        if(!(this.authenticationService.services[ServiceList.PROVIDESERVICEGROUPS])){
            this.accountType = "Administrator";
        }

        this.route.paramMap.subscribe(params => {
            this.reportType = params.get('type');
            this.accountNumber = params.get('number');
        });

        this.from = moment();
        this.to = moment();

        this.statementPeriod = new UntypedFormControl(this.statementPeriods[0], []);

        if (this.brokerService.selectedUnderwriter) {
            this.entity = this.brokerService.selectedUnderwriter.name;
        } else if (this.brokerService.broker) {
            this.entity = this.brokerService.broker.id;
        }
        if (this.agentService.agent) {
            if(this.accountType.match("Back office user")){
                this.entity = this.brokerService.selectedUnderwriter.name;
            }
            else if(this.accountType.match("Administrator")){
                this.entity = this.agentService.agent.name;
            }
        }
        this.getAccountStatementReport();
        this.getAccountSettlementHistoryReport();
    }

    getDate(date): string {
        return moment(date).format("DD/MM/YYYY");
    }

    onSubmit(): void {

        if(this.reportType === 'statement'){
            this.getAccountStatementReport();
        }
        else if(this.reportType === 'settlement'){
            this.getAccountSettlementHistoryReport();
        }
    }

    getRevenueSplit(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        this.accountStatementReport.accountStatementReportEntries.sort((a, b) => {
            if (moment(a.date) > moment(b.date)) {
                return 1;
            }
            if (moment(a.date) < moment(b.date)) {
                return -1;
            }
            return 0;
        });

        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        }, 100);
    }

    onPrint(){

        if(this.accountStatementReport.accountStatementReportEntries.length > 0){
            let conceptualDocument = new AccountStatementReportConceptualDocument();
            conceptualDocument.documentId = ConceptualDocumentType.ACCOUNT_STATEMENT_REPORT;
            conceptualDocument.conceptualDocumentType = ConceptualDocumentType.ACCOUNT_STATEMENT_REPORT;
            conceptualDocument.accountStatementReport = this.accountStatementReport;

            const renderDocumentRequest = new RenderDocumentRequest();
            renderDocumentRequest.conceptualDocument = conceptualDocument;

            this.renderService.renderDocument(renderDocumentRequest).then((response) => {
                this.displayPDF(response.body.renderedDocument[0].encodedPdf, () => {
                    setTimeout(() => {
                        this.printDocument();
                    }, 100);
                });
            });
        }
    }

    displayPDF(base64, complete) {
        const canvasId = `#print-section`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.numberOfPages = numPages;
        });
    }

    printDocument(): void {
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId: `print-section`,
            features: 'top=0,height=1024px,width=800px',
            openBlank: false
        };
        const printWin = this.printService.printDocument(pdfPrintData);

        setTimeout(() => {
            printWin.print();
        }, 1000);
    }

    getAccountStatementReport(): void {
        this.from.startOf('day').format('YYYY MM DD');
        this.to.endOf('day').format('YYYY MM DD');

        const accountReportLegalEntityDateCriteria = new AccountReportLegalEntityDateCriteria();
        const provideAccountStatementReportRequest = new ProvideAccountStatementReportRequest();
        const financialAccountNumberIdentifier = new FinancialAccountNumberIdentifier();

        financialAccountNumberIdentifier.number = this.accountNumber;

        accountReportLegalEntityDateCriteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
        accountReportLegalEntityDateCriteria.dateTo = this.to.endOf('day').format('YYYY-MM-DD') as Date;
        accountReportLegalEntityDateCriteria.period = this.getStatementPeriod(this.statementPeriod.value.name);
        accountReportLegalEntityDateCriteria.financialAccountIdentifier = financialAccountNumberIdentifier;

        if(this.accountType.match("Back office user")){
            accountReportLegalEntityDateCriteria.legalEntityIdentifier = this.brokerService.selectedUnderwriter.legalEntityIdentifier;
        }
        else if(this.accountType.match("Administrator")){
            accountReportLegalEntityDateCriteria.legalEntityIdentifier = this.agentService.agent.legalEntityIdentifier;
        }

        provideAccountStatementReportRequest.criteria = accountReportLegalEntityDateCriteria;

        this.startLoad();

        this.financialReportProviderService.provideAccountStatementReport(provideAccountStatementReportRequest).subscribe(response =>{
            this.stopLoad();
            if(response && response.body && response.body.accountStatementReport){
                const provideAccountStatementReportResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideAccountStatementReportResponse) as ProvideAccountStatementReportResponse);
                this.accountStatementReport = provideAccountStatementReportResponse.accountStatementReport;

                this.currency = provideAccountStatementReportResponse.accountStatementReport.currency;


                this.accountSettlementData = [];

                this.accountSettlementData.push([this.entity])
                this.accountSettlementData.push([""]);
                this.accountSettlementData.push(["From Date", "Date To", "Period", "Currency"]);
                this.accountSettlementData.push([this.getDate(this.from), this.getDate(this.to), provideAccountStatementReportResponse.accountStatementReport.period.toString(), this.currency.toString() ]);
                this.accountSettlementData.push([""]);
                this.accountSettlementData.push(["Date", "Description", "Amount Debit", "Amount Credit"]);

                this.accountSettlementData.push([this.accountStatementReport.dateFrom, "Opening Balance", this.accountStatementReport.openingBalanceType.valueOf().toUpperCase() === "DEBIT" ? this.accountStatementReport.openingBalance : "", this.accountStatementReport.openingBalanceType.valueOf().toUpperCase() === "CREDIT" ? this.accountStatementReport.openingBalance : "" ]);

                for(const i of provideAccountStatementReportResponse.accountStatementReport.accountStatementReportEntries){
                    if(i.amountType.valueOf().toLocaleLowerCase().includes("debit")){
                        this.accountSettlementData.push([i.date, i.description, i.amount, "" ]);
                    }
                    else if(i.amountType.valueOf().toLocaleLowerCase().includes("credit")){
                        this.accountSettlementData.push([i.date, i.description, "",i.amount ]);
                    }
                }

                this.accountSettlementData.push([this.accountStatementReport.dateTo, "Closing Balance", this.accountStatementReport.closingBalanceType.valueOf().toUpperCase() === "DEBIT" ? this.accountStatementReport.closingBalance : "", this.accountStatementReport.closingBalanceType.valueOf().toUpperCase() === "CREDIT" ? this.accountStatementReport.closingBalance : "" ]);

                this.accountSettlementData.push([""]);
                this.accountSettlementData.push(["End"]);

                setTimeout(() => {
                    jQuery('[data-toggle="tooltip"]').tooltip();
                }, 100);
            }


        }, exception => {
            this.stopLoad();
            this.errorToastService.errorToast(exception);
        });
    }

    getAccountSettlementHistoryReport(): void {

        this.from.startOf('day');
        this.to.endOf('day');

        const accountingTransactionReferenceIdentifier = new AccountingTransactionReferenceIdentifier();
        accountingTransactionReferenceIdentifier.reference = this.accountNumber;

        const accountingTransactionReferenceDateCriteria = new AccountingTransactionReferenceDateCriteria();
        accountingTransactionReferenceDateCriteria.dateFrom = this.from.startOf('day').format('YYYY-MM-DD') as Date;
        accountingTransactionReferenceDateCriteria.dateTo = this.to.endOf('day').format('YYYY-MM-DD') as Date;


        accountingTransactionReferenceDateCriteria.accountingTransactionIdentifiers.push(accountingTransactionReferenceIdentifier);

        const provideAccountingTransactionInformationRequest = new ProvideAccountingTransactionInformationRequest();
        provideAccountingTransactionInformationRequest.criteria = accountingTransactionReferenceDateCriteria;
        provideAccountingTransactionInformationRequest.batchNumber = -1;  //-1 indicates returning the full list of accounting transactions
        provideAccountingTransactionInformationRequest.batchSize = -1; //-1 indicates returning the full list of accounting transactions

        this.settlementHistoryData = [];
        this.transactionEntries = [];
        var date : string;
        this.startLoad();
        this.accountingTransactionInformationProvider.provideAccountingTransactionInformation(provideAccountingTransactionInformationRequest).subscribe(response =>{
            this.stopLoad();
            const provideAccountingTransactionInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideAccountingTransactionInformationResponse) as ProvideAccountingTransactionInformationResponse);
            this.accountingTransactions = provideAccountingTransactionInformationResponse.accountingTransactions;
            this.settlementHistoryData.push([this.entity])
            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["From Date", "To Date", "Account Number", "Currency"]);

            this.settlementHistoryData.push([this.getDate(this.from), this.getDate(this.to), this.accountNumber, this.currency ]);

            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["Date","Amount Debit", "Amount Credit"]);
            for(const r of provideAccountingTransactionInformationResponse.accountingTransactions){

                date = this.getDate(r.date);

                for(const i of r.entries){
                    this.transactionEntries.push({...i, date: date});

                    if(i.entryType.toLocaleLowerCase().includes("debit")){
                        this.settlementHistoryData.push([date, this.formatCurrency(-i.amount.amount), "" ]);
                    }
                    else if(i.entryType.toLocaleLowerCase().includes("credit")){
                        this.settlementHistoryData.push([date, "", this.formatCurrency(i.amount.amount) ]);
                    }

                    if(this.currency === undefined){
                        this.currency = i.amount.currency;
                        this.settlementHistoryData[3] ? this.settlementHistoryData[3][3] = this.currency : "";
                    }
                }
            }

            this.settlementHistoryData.push([""]);
            this.settlementHistoryData.push(["End"]);

            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 100);

        }, exception =>{
            this.stopLoad();
            this.errorToastService.errorToast(exception);
        });
    }

    onQueryType(type): boolean {

        this.noResults = false;

        this.queryBy = type;

        return false;
    }

    getStatementPeriod(preferredPeriod: string): AccountStatementPeriod{
        if(preferredPeriod === "Monthly"){
            return AccountStatementPeriod.MONTHLY

        }
        else if(preferredPeriod === "Weekly"){
            return AccountStatementPeriod.WEEKLY

        }
        else{
            return AccountStatementPeriod.DAILY
        }
    }

    export(): void {
        if(this.reportType.match("statement")){

            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.accountSettlementData);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Account Statement Repor");

            this.fileName = "Account Statement Report.xlsx";

            XLSX.writeFile(wb, this.fileName);
        }else{
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.settlementHistoryData);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Account Settlement Report");

            this.fileName = "Account Settlement Report.xlsx";

            XLSX.writeFile(wb, this.fileName);
        }
    }

    formatCurrency(amount): string {
        return StringUtils.formatCents(amount);
    }
}
