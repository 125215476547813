import { Criteria } from "../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../identification/models/legal-entity-identifier.model";

export class LegalEntityIdentifierCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.LegalEntityIdentifierCriteria";
    }

    public legalEntityIdentifier: LegalEntityIdentifier;
}