import {Criteria} from '../../criteria/models/criteria.model';

export class ActiveSystemParameterNameCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.system.ActiveSystemParameterNameCriteria";
    }

    systemParameterName: string;
    active: boolean;
}