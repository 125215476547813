import { InsuranceTypeSelectorPageComponent } from './retail/insurance/insurance-type-selector-page/insurance-type-selector-page/insurance-type-selector-page.component';
import {
  YellowCardProductViewModule
} from './../view.components/retail/insurance/insurance-product-manager/yellow-card-product-view/yellow-card-product-view.module'
import {
  YellowCardProductPricingPageComponent
} from './retail/insurance/insurance-product-manager/yellow-card-product/yellow-card-product-pricing-page/yellow-card-product-pricing-page.component'
import {MatExpansionModule} from '@angular/material/expansion'
import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {LoadingModule} from 'ngx-loading'
import {SharedModule} from '../view.components/shared/shared.module'
import {SecurityModule} from '../view.components/security/security.module'
import {CertificateModule} from '../view.components/retail/insurance/certificate/certificate.module'
import {EntityModule} from '../view.components/entity/entity.module'
import {VehicleModule} from '../view.components/vehicle/vehicle.module'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatSelectModule} from '@angular/material/select'
import {MatCardModule} from '@angular/material/card'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatSliderModule} from '@angular/material/slider'
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatMenuModule} from '@angular/material/menu'
import {MatRadioModule} from '@angular/material/radio'
import {MatSlideToggleModule} from '@angular/material/slide-toggle'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatInputModule} from '@angular/material/input'
import {InsuranceModule} from '../view.components/retail/insurance/insurance.module'
import {
  InsurancePersonalQuickTransactManagerPageComponent
} from './retail/insurance/quick-transact/insurance-personal-quick-transact-manager-page/insurance-personal-quick-transact-manager-page.component'
import {
  RetailProductsPageComponent
} from './retail/insurance/insurance-product-manager/product-list-page/retail.products.list.component'
import {
  RetailProductsUpdatePageComponent
} from './retail/insurance/insurance-product-manager/product-update-page/retail-products-update-page.component'
import {
  RetailProfileCreatePageComponent
} from './retail/profile/retail-profile-create--page/retail-profile-create-page.component'
import {
  RetailProfileUpdatePageComponent
} from './retail/profile/retail-profile-update-page/retail-profile-update-page.component'
import {VehicleCreatePageComponent} from './retail/profile/vehicle-create-page/vehicle-create-page.component'
import {RetailProfilePageComponent} from './retail/profile/retail-profile-page/retail-profile-page.component'
import {VehicleUpdatePageComponent} from './retail/profile/vehicle-update-page/vehicle-update-page.component'
import {BrowseProductsPageComponent} from './retail/insurance/browse-products-page/browse-products-page.component'
import {CartPageComponent} from './retail/checkout/cart-page/cart-page.component'
import {CheckoutPageComponent} from './retail/checkout/checkout-page/checkout-page.component'
import {OrderHistoryPageComponent} from './retail/checkout/order-history-page/order-history-page.component'
import {OrderPageComponent} from './retail/checkout/order-page/order-page.component'
import {
  QueryPolicyPageComponent
} from './retail/insurance/third-party-insurance/query-policy-page/query-policy-page.component'
import {
  PublicQueryPageComponent
} from './retail/insurance/third-party-insurance/public-query-page/public-query-page.component'
import {
  InsuranceAssistedQuickTransactManagerPageComponent
} from './retail/insurance/quick-transact/insurance-assisted-quick-transact-manager-page/insurance-assisted-quick-transact-manager-page.component'
import {LegalPageComponent} from './legal/legal-page/legal-page.component'
import {IndexPageComponent} from './index-page/index-page.component'
import {DropdownsPageComponent} from './dropdowns-page/dropdowns-page.component'
import {VehicleMakePageComponent} from './vehicle/vehicle-make-page/vehicle-make-page.component'
import {VehicleModelPageComponent} from './vehicle/vehicle-model-page/vehicle-model-page.component'
import {
  CreateMakeModelTrimPageComponent
} from './vehicle/make-model-trim-create-page/make-model-trim-create-page.component'
import {
  UpdateMakeModelTrimPageComponent
} from './vehicle/make-model-trim-update-page/make-model-trim-update-page.component'
import {
  ServiceGroupCreatePageComponent
} from './security/authorisation/service-group-create-page/service-group-create-page.component'
import {
  ServiceGroupListPageComponent
} from './security/authorisation/service-group-list-page/service-group-list-page.component'
import {
  ServiceGroupUpdatePageComponent
} from './security/authorisation/service-group-update-page/service-group-update-page.component'
import {
  SubscriptionCreatePageComponent
} from './security/authorisation/subscription-create-page/subscription-create-page.component'
import {
  SubscriptionListPageComponent
} from './security/authorisation/subscription-list-page/subscription-list-page.component'
import {
  PublicAuthenticatorPageComponent
} from './security/public-authenticator-page/public-authenticator-page.component'
import {
  BackOfficeAuthenticatorPageComponent
} from './security/back-office-authenticator-page/back-office-authenticator-page.component'
import {
  InsuranceCertificateIssuanceAdministratorPageComponent
} from './retail/insurance/certificate/insurance-certificate-issuance-administrator-page/insurance-certificate-issuance-administrator-page.component'
import {
  InsurancePolicyManagerPageComponent
} from './retail/insurance/insurance-policy-manager-page/insurance-policy-manager-page.component'
import {
  VerifiedAccountManagerPageComponent
} from './security/account/verified-account-manager/verified-account-manager-page/verified-account-manager-page.component'
import {
  PublicOneTimePinAuthenticatorPageComponent
} from './security/public-one-time-pin-authenticator-page/public-one-time-pin-authenticator-page.component'
import {
  PublicPasswordResetManagerPageComponent
} from './security/account/public-password-reset-manager-page/public-password-reset-manager-page.component'
import {ChangeNumberPageComponent} from './security/account/change-number-page/change-number-page.component'
import {
  PrincipalManagerPageComponent
} from './security/authorisation/principal-manager-page/principal-manager-page.component'
import {DashboardPageComponent} from './ledger/reporting/dashboard/dashboard-page/dashboard-page.component'
import {
  BackOfficeAgentAuthenticatorPageComponent
} from './security/back-office-agent-authenticator-page/back-office-agent-authenticator-page.component'
import {
  AccountStatementsPageComponent
} from './ledger/accounting-transaction/account-statements-page/account-statements-page.component'
import {
  UserManagementPageComponent
} from './user-account-management/user-management-page/user-management-page.component'
import {
  CreateBackOfficeAccountPageComponent
} from './user-account-management/create-back-office-account-page/create-back-office-account-page.component'
import {
  AccountLinkVerificationPageComponent
} from './user-account-management/account-link-verification-page/account-link-verification-page.component'
import {FinancialReportPageComponent} from './ledger/reporting/financial-report-page/financial-report-page.component'
import {RevenueReportPageComponent} from './ledger/reporting/revenue-report-page/revenue-report-page.component'
import {
  TransactionReportPageComponent
} from './ledger/reporting/transaction-report-page/transaction-report-page.component'
import {
  AccountReportsPageComponent
} from './ledger/accounting-transaction/account-reports-page/account-reports-page.component'
import {
  AccountingTransactionPageComponent
} from './ledger/accounting-transaction/accounting-transations-page/accounting-transaction-page.component'
import {SalesReportPageComponent} from './ledger/reporting/sales-report-page/sales-report-page.component'
import {
  ProposalAssessmentManagerComponent
} from './retail/insurance/comprehensive-insurance/proposal-assessment-manager-page/proposal-assessment-manager.component'
import {ProposalListPageComponent} from './retail/checkout/proposal-list-page/proposal-list-page.component'
import {
  DirectPayOnlineCardPaymentVerifierPageComponent
} from './retail/payment/dpo/direct-pay-online-card-payment-verifier-page/direct-pay-online-card-payment-verifier-page.component'
import {
  BackOfficeInvoicesPageComponent
} from './retail/invoice/back-office-invoices-page/back-office-invoices-page.component'
import {MaintenancePageComponent} from './maintenance-page/maintenance-page.component'
import {
  BackOfficeForgotPasswordManagerPageComponent
} from './security/account/back-office-forgot-password-manager-page/back-office-forgot-password-manager-page.component'
import {QueryProposalPageComponent} from './retail/checkout/query-proposal-page/query-proposal-page.component'
import {ProposalHistoryPageComponent} from './retail/checkout/proposal-history-page/proposal-history-page.component'
import {
  ProductOverviewPageComponent
} from './retail/insurance/insurance-product-manager/product-overview-page/product-overview-page.component'
import {
  CreateProductDetailsPageComponent
} from './retail/insurance/insurance-product-manager/create-product-details-page/create-product-details-page.component'
import {
  ProductPricingSchedulePageComponent
} from './retail/insurance/insurance-product-manager/product-pricing-schedule-page/product-pricing-schedule-page.component'
import {
  RetailProductExcessPageComponent
} from './retail/insurance/retail-excess-page/retail-product-excess-page.component'
import {
  ProductLimitPageComponent
} from './retail/insurance/insurance-product-manager/product-limit-page/product-limit-page.component'
import {InvoiceModule} from '../view.components/retail/invoice/invoice.module'
import {CheckoutModule} from '../view.components/retail/checkout/checkout.module'
import {MatTabsModule} from '@angular/material/tabs'
import {UserAccountManagementModule} from '../view.components/user-account-management/user-account-management.module'
import {LedgerModule} from '../view.components/ledger/ledger.module'
import {LegalModule} from '../view.components/legal/legal.module'
import {ProfileModule} from '../view.components/retail/profile/profile.module'
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatLegacyChipsModule} from '@angular/material/legacy-chips'
import {
  ThirdPartyInsuranceModule
} from '../view.components/retail/insurance/third-party-insurance/third-party-insurance.module'
import {QuickTransactModule} from '../view.components/retail/insurance/quick-transact/quick-transact.module'
import {PolicyModule} from '../view.components/retail/insurance/policy/policy.module'
import {MatTableModule} from '@angular/material/table'
import {MatIconModule} from '@angular/material/icon'
import {WhiteLabelModule} from '../view.components/white-label/white-label.module'
import {AssessmentsPageComponent} from './retail/insurance/assessment/assessments-page/assessments-page.component'
import {
  YcAssessmentManagerPageComponent
} from './retail/insurance/yellow-card-insurance/yc-assessment-manager-page/yc-assessment-manager-page.component'
import {
  ImportFleetPageComponent
} from './retail/profile/import-fleet-page/import-fleet-page/import-fleet-page.component'
import {
  SelectPaymentMethodPageComponent
} from './retail/payment/select-payment-method-page/select-payment-method-page.component'
import { CoveredCountriesSelectorPageComponent } from './retail/covered-countries-selector-page/covered-countries-selector-page.component';
import { ProductAddOnPageComponent } from './retail/insurance/insurance-product-manager/product-add-on-page/product-add-on-page.component'

@NgModule({
  declarations: [
      InsurancePersonalQuickTransactManagerPageComponent,
      RetailProductsPageComponent,
      RetailProductsUpdatePageComponent,
      RetailProfileCreatePageComponent,
      RetailProfileUpdatePageComponent,
      VehicleCreatePageComponent,
      RetailProfilePageComponent,
      VehicleUpdatePageComponent,
      BrowseProductsPageComponent,
      CartPageComponent,
      CheckoutPageComponent,
      OrderHistoryPageComponent,
      OrderPageComponent,
      QueryPolicyPageComponent,
      PublicQueryPageComponent,
      InsuranceAssistedQuickTransactManagerPageComponent,
      LegalPageComponent,
      IndexPageComponent,
      DropdownsPageComponent,
      VehicleMakePageComponent,
      VehicleModelPageComponent,
      CreateMakeModelTrimPageComponent,
      UpdateMakeModelTrimPageComponent,
      ServiceGroupCreatePageComponent,
      ServiceGroupListPageComponent,
      ServiceGroupUpdatePageComponent,
      SubscriptionCreatePageComponent,
      SubscriptionListPageComponent,
      BackOfficeAuthenticatorPageComponent,
      PublicAuthenticatorPageComponent,
      InsuranceCertificateIssuanceAdministratorPageComponent,
      InsurancePolicyManagerPageComponent,
      VerifiedAccountManagerPageComponent,
      PublicOneTimePinAuthenticatorPageComponent,
      PublicPasswordResetManagerPageComponent,
      ChangeNumberPageComponent,
      PrincipalManagerPageComponent,
      DashboardPageComponent,
      BackOfficeAgentAuthenticatorPageComponent,
      AccountStatementsPageComponent,
      UserManagementPageComponent,
      CreateBackOfficeAccountPageComponent,
      AccountLinkVerificationPageComponent,
      FinancialReportPageComponent,
      RevenueReportPageComponent,
      TransactionReportPageComponent,
      AccountReportsPageComponent,
      AccountingTransactionPageComponent,
      SalesReportPageComponent,
      ProposalAssessmentManagerComponent,
      ProposalListPageComponent,
      DirectPayOnlineCardPaymentVerifierPageComponent,
      BackOfficeInvoicesPageComponent,
      MaintenancePageComponent,
      BackOfficeForgotPasswordManagerPageComponent,
      QueryProposalPageComponent,
      ProposalHistoryPageComponent,
      ProductOverviewPageComponent,
      CreateProductDetailsPageComponent,
      ProductPricingSchedulePageComponent,
      RetailProductExcessPageComponent,
      ProductLimitPageComponent,
      YellowCardProductPricingPageComponent,
      AssessmentsPageComponent,
      YcAssessmentManagerPageComponent,
      ImportFleetPageComponent,
      SelectPaymentMethodPageComponent,
      CoveredCountriesSelectorPageComponent,
      InsuranceTypeSelectorPageComponent,
      ProductAddOnPageComponent
      ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        SharedModule,
        SecurityModule,
        CertificateModule,
        EntityModule,
        VehicleModule,
        InsuranceModule,
        NgxDatatableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatDatepickerModule,
        MatSliderModule,
        CdkDropList,
        CdkDrag,
        MatCheckboxModule,
        MatMenuModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatInputModule,
        InvoiceModule,
        CheckoutModule,
        MatTabsModule,
        UserAccountManagementModule,
        LedgerModule,
        LegalModule,
        ProfileModule,
        MatPaginatorModule,
        MatLegacyChipsModule,
        ThirdPartyInsuranceModule,
        QuickTransactModule,
        PolicyModule,
        MatTableModule,
        MatExpansionModule,
        YellowCardProductViewModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        WhiteLabelModule,
        CheckoutModule
    ]
})
export class PagesModule { }
