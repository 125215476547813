import { CountryIdentifier } from "../../geographic/identification/models/country-identifier.model";
import { Identifier } from "../../../identification/identification_api";

export abstract class CityTownIdentifier extends Identifier {
    
    constructor(){
        super();
    }

    public countryIdentifier:CountryIdentifier;
}