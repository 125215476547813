import {Component, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from "../../../view.components/shared/form/form.component"
import {OTPVerifyComponent} from "../../../view.components/security/opt-verify/opt-verify.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {ActivatedRoute, Router} from "@angular/router"
import {
  ServiceGroupFinalisationManagerService
} from "../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service"
import {
  ObfuscatedNumberComponent
} from "../../../view.components/security/obfuscated-number/obfuscated-number.component"
import {IStepComponent} from "../../../view.components/shared/guards/step-guard"
import {LocationStrategy} from "@angular/common"
import {Utils} from "../../../view.components/shared/Utils/Utils"
import {Dropdown} from '../../../common/model/dropdown.model'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {
  AllCountryCriteria,
  CloseSessionTrackingRequest,
  CloseSessionTrackingResponse,
  ContactNumber,
  Country,
  CreateSessionTrackingRequest,
  CreateSessionTrackingResponse,
  NationalIdentityNumber,
  NaturalPerson,
  NaturalPersonIdentifierCredential,
  PassportNumber,
  ProvideCountryInformationRequest,
  ProvideCountryInformationResponse,
  ProvideSessionTrackingRequest,
  ProvideSessionTrackingResponse,
  SessionTracking,
  UnMarshallerService
} from "@magnabc/tpi"
import {CountryComponent} from '../../../view.components/shared/country/country.component'
import {
  OneTimePinPublicAuthenticatorService
} from '../../../http.services/security/onetimepin/public-authenticator/one-time-pin-public-authenticator.service'
import {
  CountryProviderService
} from '../../../http.services/location/geographic/country-provider/country-provider.service'
import {CountryService} from '../../../view.components/shared/country/services/country.service'
import {
  PublicPasswordResetManagerService
} from '../../../http.services/security/account/public-password-reset-manager/public-password-reset-manager.service'
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import {
  OneTimePinVerificationManagerService
} from '../../../http.services/security/onetimepin/one-time-pin-verification-manager/one-time-pin-verification-manager.service'
import {
  BackOfficeOtpAuthenticatorService
} from "../../../http.services/security/onetimepin/back-office-authenticator/back-office-otp-authenticator.service"
import {Title} from '@angular/platform-browser'
import {GoogleAnalyticsService} from 'ngx-google-analytics'
import {TokenManagerService} from '../../../app.services/managers/token-manager/token-manager.service'
import * as moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {SessionTrackingManagerService} from '../../../http.services/security/session-tracking/session-tracking.service'
import {
  RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
  OneTimePinVerificationManagerProviderService
} from '../../../http.services/security/onetimepin/one-time-pin-verification-manager-provider/one-time-pin-verification-manager-provider.service'

@Component({
    selector: 'one-time-pin-public-authenticator',
    templateUrl: './public-one-time-pin-authenticator-page.component.html',
    styleUrls: ['./public-one-time-pin-authenticator-page.component.scss']
})
export class PublicOneTimePinAuthenticatorPageComponent extends FormComponent implements IStepComponent, OnInit {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;
    utils = Utils;

    isBackOffice = false;
    naturalPerson: NaturalPerson;
    contactNumber: ContactNumber;
    obfuscatedNumber: string;

    authentic = true;

    @ViewChild(ObfuscatedNumberComponent) obfuscatedNumberComponent: ObfuscatedNumberComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;

    activeToast: ActiveToast<any>;
    exists = true;
    errorMessage = null;

    /* Getting NaturalPerson identifier*/
    types = [
        new Dropdown(1, "National Identity Number"),
        new Dropdown(2, "Passport Number")
    ];

    npIdentifierFormGroup: UntypedFormGroup;
    type: UntypedFormControl;
    identifier: UntypedFormControl;
    username: UntypedFormControl;
    typePlaceholder: string;
    country: Country;
    hiddenNumbers: string;
    maxLength = 15;

    @ViewChild(CountryComponent) countryComponent: CountryComponent;
    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private router: Router, private toastr: ToastrService,
                private securityService: OneTimePinVerificationManagerProviderService,
                private authenticationService: ServiceGroupFinalisationManagerService,
                private passwordResetManagerService: PublicPasswordResetManagerService,
                private sessionTrackingManagerService: SessionTrackingManagerService,
                private tokenManagerService: TokenManagerService,
                private countryProviderService: CountryProviderService, public countryService: CountryService,
                public publicOtpAuthenticator: OneTimePinPublicAuthenticatorService,
                public backOfficeOtpAuthenticator: BackOfficeOtpAuthenticatorService,
                private route: ActivatedRoute,
                private unMarshallerService: UnMarshallerService,
                private otpService: OneTimePinVerificationManagerService,
                locationStrategy: LocationStrategy,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.route.queryParams.forEach((param) => {
            if (param['isBackOffice']) {
                this.isBackOffice = true;
            }
        });

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.naturalPerson = new NaturalPerson();
        this.contactNumber = new ContactNumber();

        this.steps = [];
        setTimeout(() => {

            this.translate.get("ENTITY.TITLE_QUERY_NATURAL_PERSON").subscribe((response) => {
                this.steps.push({id: 0, name: "Query", icon: "fa-search", borderColor: "#1592E6"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 1, name: "Verify Number", icon: "fa-mobile", borderColor: "#1689df"});
            });

            this.translate.get("ENTITY.TITLE_CONTACT_INFORMATION").subscribe((response) => {
                this.steps.push({id: 2, name: "One Time Pin", icon: "fa-check", borderColor: "#3f5baa", hidden: true});
            });

            this.setTitle();

        });

        this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";

    }

    resendOTP(){
        if (this.isBackOffice) {
            this.backOfficeOtpAuthenticator.validateBackOfficeOTPObscured(this.hiddenNumbers).then(response => {
                if (response && response === true) {
                    this.onNext(null);
                }
                this.stopLoad();
            });
        } else {
            this.publicOtpAuthenticator.validatePublicOTPObscured(this.hiddenNumbers).then(response => {
                if (response && response === true) {
                    this.onNext(null);
                }
                this.stopLoad();
            });
        }
    }

    setTitle() {
        let title = `${this.steps[this.currentStep].name} - Mobile Login - ${this.runtimeConfigurationService.title}`;
        if (this.currentStep === 0) {
            title = `Mobile Login - ${this.runtimeConfigurationService.title}`;
        }
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    ngOnInit(): void {

        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.startLoad();
        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                this.countryService.setCountries(response.countries);
            }
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: ', err);
            RaygunErrorHandler.sendError(err);

            this.errorMessage = 'Could not load countries.';
            this.stopLoad();
        });

        if (this.isBackOffice) {

            this.username = new UntypedFormControl('', [
                Validators.required
            ]);
            this.npIdentifierFormGroup = new UntypedFormGroup({
                username: this.username,
            });

        } else {

            this.type = new UntypedFormControl(this.types[0], []);
            this.identifier = new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(/^[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+[\/]{0,1}[A-Za-z0-9]+$/),
                Validators.maxLength(this.maxLength)
            ]);
            this.npIdentifierFormGroup = new UntypedFormGroup({
                type: this.type,
                identifier: this.identifier,
            });

        }
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    onSubmit(event): void {
        switch (this.currentStep) {
            case 0:
                this.provideObscuredContactNumber();
                break;
            case 1:
                this.obfuscatedNumberComponent.submit();
                break;
            case 2:
                this.otpVerifyComponent.submit();
                break;
        }
    }

    // onExists(exists): void {
    //     this.exists = exists;
    //     if (exists) {
    //
    //         const provideAccountInformationRequest = new ProvideAccountInformationRequest();
    //         const criteria = new AccountNaturalPersonCriteria();
    //         criteria.naturalPersonIdentifiers = [Utils.getLegalEntityIdentifier(this.naturalPerson.legalEntityIdentifiers) as NationalIdentityNumber];
    //         provideAccountInformationRequest.criteria = criteria;
    //         this.contactNumber = this.naturalPerson.contactNumbers[0];
    //         this.startLoad();
    //         /*this.accountInformationProviderService.provideAccount(provideAccountInformationRequest).subscribe((response) => {
    //             this.stopLoad();
    //
    //             if (response.accounts.length > 0) {
    //                 this.exists = true;
    //                 this.onNext(null);
    //             } else {
    //                 this.exists = false;
    //             }
    //         }, (error) => {
    //             console.log(error);
    //         });*/
    //
    //     } else {
    //         this.exists = false;
    //     }
    // }

    onNext(event): void {

        if (this.currentStep === 2) {
            // this.provideAccount();
        } else {
            this.currentStep++;
            window.scrollTo(0, 0);
        }

        this.setTitle();

    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        }

        this.setTitle();
    }

    onCountry(country): void {
        this.country = country;
    }

    onCountryValid(event): void {
        document.getElementById('form-submit-query').click();
    }

    onTypeChanged(event): void {
        if (this.type.value.value === 1) {
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_ID";
            this.maxLength = 15;
            this.identifier.setValidators([Validators.maxLength(15)]);
            this.identifier.updateValueAndValidity();
        }else if (this.type.value.value === 2){
            this.typePlaceholder = "ENTITY.LABEL_IDENTIFIER_PN";
            this.maxLength = 9;
            this.identifier.setValidators([Validators.maxLength(9)]);
            this.identifier.updateValueAndValidity();
        }
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    provideObscuredContactNumber() {
        if (this.npIdentifierFormGroup.valid) {
            this.errorMessage = null;
            this.startLoad();
            if (this.isBackOffice) {
                this.backOfficeOtpAuthenticator.initialiseBackOfficeOTPAuthentication(this.username.value).then(result => {
                    if (result) {
                        this.contactNumber.number = result;
                        this.contactNumber.country = 'na';
                        this.contactNumber.contactNumberType = 'na';
                        this.obfuscatedNumber = result;
                        this.exists = true;
                        this.onNext(null);
                    }
                    this.stopLoad();
                }).catch(error => {
                    RaygunErrorHandler.sendError(error);

                    this.errorMessage = error;
                    this.stopLoad();
                });
            } else {
                this.country = this.country ? this.country : this.countryComponent.getCountrySelected();

                const credentials = new NaturalPersonIdentifierCredential();

                if(this.type.value.value === 1){
                    const nationalIdentityNumber = new NationalIdentityNumber();
                    nationalIdentityNumber.countryOfIssue =  this.country.alphaTwo;
                    nationalIdentityNumber.number = this.identifier.value.toLocaleUpperCase();
                    credentials.naturalPersonIdentifier = nationalIdentityNumber;

                }else if(this.type.value.value === 2){
                    const passportNumber = new PassportNumber();
                    passportNumber.countryOfIssue =  this.country.alphaTwo;
                    passportNumber.number = this.identifier.value.toLocaleUpperCase();
                    credentials.naturalPersonIdentifier = passportNumber;

                }

                this.publicOtpAuthenticator.initialisePublicOTPAuthentication(credentials).then(result => {
                    if (result) {
                        this.contactNumber.number = result;
                        this.contactNumber.country = 'na';
                        this.contactNumber.contactNumberType = 'na';
                        this.obfuscatedNumber = result;
                        this.exists = true;
                        this.onNext(null);
                    }
                    this.stopLoad();
                }).catch(error => {
                    RaygunErrorHandler.sendError(error);

                    this.errorMessage = error;
                    this.stopLoad();
                });
            }
        }
    }

    validObfuscatedContactNumber(event) {
        this.startLoad();
        if (this.obfuscatedNumberComponent) {
            this.obfuscatedNumberComponent.invalidNumber = false;
        }
        this.hiddenNumbers = event;
        if (this.isBackOffice) {
            this.backOfficeOtpAuthenticator.validateBackOfficeOTPObscured(event).then(response => {
                if (response && response === true) {
                    this.contactNumber.number += event;
                    this.onNext(null);
                } else {
                    this.obfuscatedNumberComponent.invalidNumber = true;
                }
                this.stopLoad();
            });
        } else {
            this.publicOtpAuthenticator.validatePublicOTPObscured(event).then(response => {
                if (response && response === true) {
                    this.contactNumber.number += event;
                    this.onNext(null);
                } else {
                    this.obfuscatedNumberComponent.invalidNumber = true;
                }
                this.stopLoad();
            });
        }
    }

    validateAndRoute() {
        const route = this.isBackOffice ? '/console-login' : '/login';
        if(this.passwordResetManagerService.getPasswordProcessState().value){
            this.router.navigate([route]).then(() => {
                this.passwordResetManagerService.changeHideLoginProcessCondition(false);
                this.passwordResetManagerService.changePasswordProcessCondition(false);
                this.stopLoad();
            });
        }
    }

    validateOneTimePin(oneTimePin: string) {
        this.startLoad();
        this.errorMessage = null;
        if (this.otpVerifyComponent) {
            this.otpVerifyComponent.invalidOTP = false;
        }
        if (this.isBackOffice) {
            this.backOfficeOtpAuthenticator.authenticateCredentialsByOTP(oneTimePin).then(response => {

                if (!this.passwordResetManagerService.getPasswordProcessState().value && response && response === true) {
                    this.router.navigate(['/console/home']).then(() => {
                        this.stopLoad();
                    });
                }
                else if(this.passwordResetManagerService.getPasswordProcessState().value && response && response === true){
                    this.router.navigate(['/security/account/reset-password'], {queryParams: {resetPassword: true}}).then(() => {
                        this.passwordResetManagerService.changeHideLoginProcessCondition(true);
                        this.stopLoad();
                    });
                }
                else {
                    this.otpVerifyComponent.invalidOTP = true;
                    this.stopLoad();
                }
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                this.otpVerifyComponent.disable();
                this.otpVerifyComponent.setErrorMessage(error);
                this.stopLoad();
            });
        } else {
            this.publicOtpAuthenticator.authenticateCredentialsByOTP(oneTimePin).then(response => {

                if (!this.passwordResetManagerService.getPasswordProcessState().value && response && response === true) {
                    this.router.navigate(['/retail/profile']).then(() => {
                        this.stopLoad();
                    });
                }
                else if(this.passwordResetManagerService.getPasswordProcessState().value && response && response === true){

                    this.verifySession();

                    this.router.navigate(['/security/account/reset-password']).then(() => {
                        this.passwordResetManagerService.changeHideLoginProcessCondition(true);
                        this.stopLoad();
                    });
                }
                else {
                    this.otpVerifyComponent.invalidOTP = true;
                    this.stopLoad();
                }
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                this.otpVerifyComponent.disable();
                this.otpVerifyComponent.setErrorMessage(error);
                this.stopLoad();
            });
        }
    }

    verifySession()
    {
        const provideSessionTrackingRequest = new ProvideSessionTrackingRequest();
        provideSessionTrackingRequest.accountNumber = this.tokenManagerService.getAccountNumber();
        this.sessionTrackingManagerService.provideSessionTracking(provideSessionTrackingRequest).then(sessionTrackingHttpResponse => {
            if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                const provideSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, ProvideSessionTrackingResponse) as ProvideSessionTrackingResponse);

                if(provideSessionTrackingResponse.sessionTrackings.length>0){
                    // Remove all duplicates
                    provideSessionTrackingResponse.sessionTrackings.forEach(session => {
                        const closeSessionTrackingRequest = new CloseSessionTrackingRequest();
                        closeSessionTrackingRequest.accountNumber = session.accountNumber;

                        this.sessionTrackingManagerService.closeSessionTracking(closeSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                            if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                                const closeSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CloseSessionTrackingResponse) as CloseSessionTrackingResponse);
                            }

                        });
                    });
                }

                this.createSession();
            }

        });
    }

    createSession()
    {
        let sessionTracking = new SessionTracking();
        let currentTokenExpDate = this.tokenManagerService.getTokens().expirationDate;

        sessionTracking.accountNumber = this.tokenManagerService.getAccountNumber();
        sessionTracking.sessionTrackingId = uuidv4()

        let addExpDate = new Date(currentTokenExpDate.getTime() + 15000);
        sessionTracking.endDate = moment(addExpDate).format('YYYY-MM-DDTHH:mm:ss') as any;
        sessionStorage.setItem('sessiontrackingid', sessionTracking.sessionTrackingId);
        sessionStorage.setItem('accountnumber', sessionTracking.accountNumber);
        sessionStorage.setItem('sessiontrackingenddate', sessionTracking.endDate.toString());

        const createSessionTrackingRequest = new CreateSessionTrackingRequest();
        createSessionTrackingRequest.sessionTracking = sessionTracking;
        this.sessionTrackingManagerService.createSessionTracking(createSessionTrackingRequest).then(sessionTrackingHttpResponse => {
            if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                const createSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CreateSessionTrackingResponse) as CreateSessionTrackingResponse);
            }
        })
    }

    /**
     *
     * Removing spaces from the ID name ad adding the -
     * e.g National Identintification = national-indentification
     * @returns string
     */
    getIdName(value): string {
        return value.replace(/\s/g, "-").toLowerCase();
    }
}
