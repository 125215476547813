import { Criteria } from "../../../../criteria/models/criteria.model";

export class ServiceGroupIdentifierTypeCriteria extends Criteria {
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.security.authorisation.identification.ServiceGroupIdentifierTypeCriteria";
    }

    public identifierType: string;

}