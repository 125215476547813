import { TranslateParser } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Injector, NgZone } from '@angular/core';
import { ActiveToast, Overlay, ToastToken, TOAST_CONFIG } from 'ngx-toastr';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomToastrService extends ToastrService {
    defaultMessage: string;
    defaultTile: string;

    constructor(
        @Inject(TOAST_CONFIG) token: ToastToken,
        private overlayC: Overlay,
        private _injectorC: Injector,
        private sanitizerC: DomSanitizer,
        private ngZoneC: NgZone,
        private translate: TranslateService,
        private translateParser: TranslateParser) {
        super(token, overlayC, _injectorC, sanitizerC, ngZoneC);
        this.doTranslations();
    }

    private doTranslations(): void {
        setTimeout(() => {
            this.translate.get('TOAST.DEFAULT_ERROR').pipe(
                take(1)
            ).subscribe((res: any) => {
                this.defaultMessage = this.translateParser.interpolate(res.MESSAGE);
                this.defaultTile = res.TITLE;
            });
        });
    }

    error(message?: any, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        //TODO catch and format error messages here if not done where the method is called
        let newMessage: string;
        if (message instanceof HttpErrorResponse) {
            if (message.error) {
                newMessage = message.error;
                title = message.statusText;
            } else {
                newMessage = this.defaultMessage;
                title = this.defaultTile;
            }
        } else {
            newMessage = message;
        }
        return super.error(newMessage, title, override);
    }
}

