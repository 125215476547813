import {CountryIdentifier} from "../../../../location/geographic/identification/models/country-identifier.model";
import {CategoryIdentifier} from "../../../../vehicle/identification/models/category-identifier.model";
import {UsageIdentifier} from "../../../../vehicle/identification/models/usage-identifier.model";
import {PropulsionIdentifier} from "../../../../vehicle/identification/models/propulsion-identifier.model";

export class InsuranceVehicleCategoryClassification {

    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.InsuranceVehicleCategoryClassification";
    }

    public categoryIdentifier: CategoryIdentifier;
    public usageIdentifier: UsageIdentifier;
    public countryIdentifier: CountryIdentifier;
    public propulsionIdentifier: PropulsionIdentifier;

}
