import { ScannedDocument } from "../../../../../../media/media_api";
import { Request } from "../../../../../../urdad/urdad_api";
import { PricePerVehicle, RetailProfileIdentifier, YCMotorInsuranceDetails } from "../../../../../retail_api";

export class AddYCToCartRequest extends Request {
    constructor() {
        super();
    }

    identifiers: string[];
    retailProfileIdentifier: RetailProfileIdentifier;
    ycMotorInsuranceDetails: YCMotorInsuranceDetails;
    pricePerVehicles: PricePerVehicle[];
    scannedDocuments: ScannedDocument[];
}