import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
    FinaliseCreatePrincipalByInsertRequest,
    FinaliseCreatePrincipalByUpdateRequest,
    MarshallerService
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class PrincipalFinalisationManagerService {

    basePath = '/security/authorisation/principalfinalisationmanager';

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    finaliseCreatePrincipalByInsert(finaliseCreatePrincipalByInsertRequest: FinaliseCreatePrincipalByInsertRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreateprincipalbyinsert';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreatePrincipalByInsertRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    finaliseCreatePrincipalByUpdate(finaliseCreatePrincipalByUpdateRequest: FinaliseCreatePrincipalByUpdateRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finalisecreateprincipalbyupdate';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreatePrincipalByUpdateRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }
}
