export abstract class ShippingAddress {
    constructor() { }

    public shippingAddressLineOne: string
    public shippingAddressLineTwo: string
    public shippingAddressLineThree: string
    public shippingAddressCity: string
    public shippingAddressCode: string
    public shippingAddressName: string;
}
