import { RaygunErrorHandler } from './utils.raygun';

export class ObjectUtils {

    //Recursively create new copies of object attributes with new references
    static createObjectCopy(originalObject) {

        var objectCopy;
        if (null == originalObject || "object" != typeof originalObject){
            return originalObject;
        } else if (originalObject instanceof Date) {
            objectCopy = new Date();
            objectCopy.setTime(originalObject.getTime());
            return objectCopy;
        } else if (originalObject instanceof Array) {
            objectCopy = [];
            for (var i = 0, len = originalObject.length; i < len; i++) {
                objectCopy[i] = this.createObjectCopy(originalObject[i]);
            }
            return objectCopy;
        } else if (originalObject instanceof Object) {
            objectCopy = {};
            for (var attr in originalObject) {
                if (originalObject.hasOwnProperty(attr)) objectCopy[attr] = this.createObjectCopy(originalObject[attr]);
            }
            return objectCopy;
        }

        console.error("Unable to create object copy. Its type isn't supported.");
        RaygunErrorHandler.sendError("Unable to create object copy. Its type isn't supported.");
    }
}