import {Component, OnInit, ViewChild} from '@angular/core';
import {ObfuscatedNumberComponent} from "../../../../view.components/security/obfuscated-number/obfuscated-number.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {QueryNaturalPersonComponent} from "../../../../view.components/entity/query-natural-person/query-natural-person.component";
import {ActiveToast} from "ngx-toastr";
import {OTPVerifyComponent} from "../../../../view.components/security/opt-verify/opt-verify.component";
import {Router} from "@angular/router";
import {FormComponent} from "../../../../view.components/shared/form/form.component";
import {CredentialsComponent} from "../../../../view.components/security/credentials/credentials.component";
import {IStepComponent} from "../../../../view.components/shared/guards/step-guard";
import {LocationStrategy} from "@angular/common";
import {Utils} from "../../../../view.components/shared/Utils/Utils";
import {
    NaturalPerson,
    ContactNumber,
    NationalIdentityNumber,
    ProvideAccountInformationRequest,
    AccountNaturalPersonCriteria,
    AccountNumberIdentifier,
    PlainTextUsernameAndPassword, FinaliseResetPasswordRequest
} from "@magnabc/tpi";
import { PublicPasswordResetManagerService } from '../../../../http.services/security/account/public-password-reset-manager/public-password-reset-manager.service';
import { AuthenticationService } from '../../../../app.services/managers/authentication/authentication.service';
import { RetailProfileFinalisationManagerService } from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import { UnderwriterInformationProviderService } from '../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';
import { PublicPasswordResetFinalisationManagerService } from "../../../../http.services/security/account/public-password-reset-finalisation-manager/public-password-reset-finalisation-manager.service";
import {
    LegalEntityFinalisationManagerService
} from "../../../../http.services/entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Component({
    selector: 'app-password-reset',
    templateUrl: './public-password-reset-manager-page.component.html',
    styleUrls: ['./public-password-reset-manager-page.component.scss']
})
export class PublicPasswordResetManagerPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps: any = [];
    currentStep = 0;
    noCredentials = false;
    private newPassword;
    naturalPerson: NaturalPerson;
    contactNumber: ContactNumber;
    accountInformationRetrieved = false;

    authentic = true;

    @ViewChild(QueryNaturalPersonComponent) entityQueryComponent: QueryNaturalPersonComponent;
    @ViewChild(ObfuscatedNumberComponent) obfuscatedNumberComponent: ObfuscatedNumberComponent;
    @ViewChild(OTPVerifyComponent) otpVerifyComponent: OTPVerifyComponent;
    @ViewChild(CredentialsComponent) credentialsComponent: CredentialsComponent;

    activeToast: ActiveToast<any>;
    exists = true;
    message: string;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private router: Router,
                private authenticationService: AuthenticationService,
                private passwordResetManagerService: PublicPasswordResetManagerService,
                private publicPasswordResetFinalisationManagerService: PublicPasswordResetFinalisationManagerService,
                private entityService: LegalEntityFinalisationManagerService,
                public retailProfileService: RetailProfileFinalisationManagerService,
                private brokerService: UnderwriterInformationProviderService,
                locationStrategy: LocationStrategy) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.naturalPerson = new NaturalPerson();

        this.steps = [];
        setTimeout(() => {

            this.translate.get("SECURITY.TITLE_CREDENTIALS_RESET").subscribe((response) => {
                this.steps.push({id: 0, name: response, icon: "fa-lock", borderColor: "#3f5baa"});
            });

        });

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit(): void {

            if(this.passwordResetManagerService.getPasswordProcessState().value
                && this.authenticationService.getAccount()){
                if(this.authenticationService.getAccount().credentials
                    && this.authenticationService.getAccount().credentials[0]
                    && (this.authenticationService.getAccount().credentials[0] as PlainTextUsernameAndPassword).username){

                        this.accountInformationRetrieved = true;
                }
                this.passwordResetManagerService.changePasswordProcessCondition(false);
            }
            else{
                this.passwordResetManagerService.changePasswordProcessCondition(true);
                this.router.navigate(['/security/account/verify']);
            }
    }

    onSubmit(event): void {
        switch (this.currentStep) {
            case 0:
                this.credentialsComponent.submit();
                break;
        }
    }

    onExists(exists): void {
        this.exists = exists;
        if (exists) {

            const provideAccountInformationRequest = new ProvideAccountInformationRequest();
            const criteria = new AccountNaturalPersonCriteria();
            criteria.naturalPersonIdentifiers = [Utils.getLegalEntityIdentifier(this.naturalPerson.legalEntityIdentifiers) as NationalIdentityNumber];
            provideAccountInformationRequest.criteria = criteria;
            this.contactNumber = this.naturalPerson.contactNumbers[0];
            this.onNext(null);

        }
    }

    onNext(event): void {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    onBack(event): void {

        this.onLogOut(event);
    }

    onLogOut(event): boolean {
        this.authenticationService.logout();
        this.entityService.legalEntity = null;
        this.retailProfileService.retailProfile = null;
        this.brokerService.broker = null;
        this.brokerService.selectedUnderwriter = null;
        this.router.navigate(['/login']);
        this.passwordResetManagerService.changePasswordProcessCondition(false);
        this.passwordResetManagerService.changeHideLoginProcessCondition(false);
         return true;
    }

    onNaturalPerson(naturalPerson): void {
        this.naturalPerson = naturalPerson;
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    onCredentials(event): void {
        this.newPassword = event.password;
    }

    onValidCredentials(event): void {

        this.message = null;

        const finaliseResetPasswordRequest = new FinaliseResetPasswordRequest();

        const accountNumberIdentifier = new AccountNumberIdentifier();
        accountNumberIdentifier.number = this.authenticationService.getAccount().number;

        finaliseResetPasswordRequest.accountIdentifier = accountNumberIdentifier;
        finaliseResetPasswordRequest.password = this.newPassword;

        this.startLoad();
        this.publicPasswordResetFinalisationManagerService.finaliseResetPassword(finaliseResetPasswordRequest).subscribe(() => {
            this.router.navigate(['/retail/profile']).then(() => {
                this.passwordResetManagerService.changeHideLoginProcessCondition(false);
                this.stopLoad();
            });
        }, (error) => {
            console.log(error);
            this.message = error.error;
            this.stopLoad();
        });
    }
}
