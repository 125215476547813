import { Criteria } from "../../../criteria/criteria_api";
import { CountryIdentifier } from "../../../location/location_api";

export class FinancialAccountCountryOnlyCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.FinancialAccountCountryOnlyCriteria";
    }

    public countryIdentifiers : CountryIdentifier [] = [];
}