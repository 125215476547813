import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {IssueOneTimePinRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class OneTimePinIssuanceManagerService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    issueOneTimePin(issueOneTimePinRequest: IssueOneTimePinRequest) {
        const options = {
            observe: 'response' as 'body',
            ResponseType: 'json'
        };

        const issueOneTimePinURI = this.runtimeConfigurationService.apiBaseUrl + '/security/onetimepin/onetimepinissuance/issueonetimepin';

        const jsonIssueOneTimePinRequest = this.marshallerService.marshallObjectToJSON(issueOneTimePinRequest);

        return this.httpClient.post<any>(issueOneTimePinURI, jsonIssueOneTimePinRequest, options).toPromise();
    }
}
