import { Criteria } from "../../../../criteria/models/criteria.model";

export class ProposalGroupEntryProposalCriteria extends Criteria {
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalGroupEntryProposalCriteria";
    }
    
    public proposalId: string;

}