import { Money } from '../../../../financial/models/money.model';
import { ThirdPartyInsuranceOrderItem } from "./third-party-insurance-order-item.model";
import { OrderThirdPartyInsuranceProduct } from "../../../checkout/models/order-third-party-insurance-product.model";
import { SnapshotVehicle } from '../../../checkout/models/snapshot-vehicle.model';

export class ThirdPartyInsurancePolicyOrderItem extends ThirdPartyInsuranceOrderItem {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyOrderItem";
    }

    public startDate: Date
    public expiryDate: Date
    public orderThirdPartyInsuranceProduct: OrderThirdPartyInsuranceProduct
    public snapshotVehicle: SnapshotVehicle
    public extensionExpiryDate: Date;
    public annualAmount: Money;
}
