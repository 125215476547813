import { TestData } from './../../../../mock/test-data';
import { HttpResponse } from '@angular/common/http';
import { async } from '@angular/core/testing';
import { RetailProfileInformationProviderService } from '../retail-profile-information-provider/retail-profile-information-provider.service';
import { Injectable } from '@angular/core';
import { RetailProfileFinalisationManagerService } from '../retail-profile-finalisation-manager/retail-profile-finalisation-manager.service';
import {
  FinaliseImportFleetRequest,
  FinaliseImportFleetResponse,
  ImportFleetRequest, ImportFleetResponse,
  IncorrectDocumentFormat,
  RetailProfileIdentifier,
  UnMarshallerService,
  ValidateImportFleetDocumentRequest,
} from '@magnabc/tpi'

import {
  ImportFleetFinalisationManagerService,
} from '../import-fleet-finalisation-manager/import-fleet-finalisation-manager.service';
import {
  ImportFleetDocumentValidatorService,
} from './../import-fleet-document-validator/import-fleet-document-validator.service';

@Injectable({
  providedIn: 'root'
})
export class ImportFleetManagerService {

  constructor(private documentValidatorService: ImportFleetDocumentValidatorService,
    private importFleetFinalisationManagerService: ImportFleetFinalisationManagerService,
    private unMarshallerService: UnMarshallerService) { }

  importFleet(importFleetRequest: ImportFleetRequest): Promise<ImportFleetResponse> {
    return new Promise(async (resolve, reject) => {
      let validateImportFleetDocumentRequest = new ValidateImportFleetDocumentRequest();
      const document = importFleetRequest.document;
      validateImportFleetDocumentRequest.document = document;
      try {
        const validDocumentResponse = await this.documentValidatorService.validateImportFleetDocument(validateImportFleetDocumentRequest);
        if (validDocumentResponse.validDocument) {
          const byteArray = await this.convertFileToByteArray(document);
          const finaliseImportFleetResponse = await this.finaliseImportFleet(byteArray, importFleetRequest.retailProfileIdentifier);
          let response = new ImportFleetResponse();
          response.vehicleImportErrors = finaliseImportFleetResponse.vehicleImportErrors;
          response.totalValidVehicles = finaliseImportFleetResponse.totalValidVehicles;
          response.totalVehicles = finaliseImportFleetResponse.totalVehicles;
          resolve(response);
        } else {
          throw new IncorrectDocumentFormat();
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }

  private finaliseImportFleet(byteArray: any[], retailProfileIdentifier: RetailProfileIdentifier): Promise<FinaliseImportFleetResponse> {
    return new Promise((resolve, reject) => {
      let finaliseImportFleetRequest = new FinaliseImportFleetRequest();
      finaliseImportFleetRequest.document = byteArray;
      finaliseImportFleetRequest.retailProfileIdentifier = retailProfileIdentifier;
      this.importFleetFinalisationManagerService
        .finaliseImportFleet(finaliseImportFleetRequest).toPromise().then((httpResponse: HttpResponse<any>) => {
          if (httpResponse && httpResponse.body) {
            const response =
              (this.unMarshallerService.unMarshallFromJson(httpResponse.body,
                FinaliseImportFleetResponse) as FinaliseImportFleetResponse);
            resolve(response);
          }
        }).catch(err => reject(err));
    })

  }

  private convertFileToByteArray(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let byteAr = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target.readyState == FileReader.DONE) {
            let arrayBuffer: ArrayBuffer = evt.target.result as ArrayBuffer,
              array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < array.length; i++) {
              byteAr.push(array[i]);
            }
          }
          resolve(byteAr);
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
