import {Response} from "../../../../../../../../urdad/services/models/response.model";
import { YCSellingCountryRule } from "../../../../models/yc-selling-country-rule.model";

export class ProvideCountrySellingRuleResponse extends Response{

    constructor(){
        super();
    }

    public ycSellingCountryRules: YCSellingCountryRule[] = [];
}