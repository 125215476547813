import {Injectable, OnDestroy} from '@angular/core'
import {Subject} from 'rxjs'
import {Router} from '@angular/router'
import * as jwt_decode from 'jwt-decode'
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun'
import {
  CloseSessionTrackingRequest,
  CloseSessionTrackingResponse,
  UnMarshallerService,
  UpdateSessionTrackingRequest,
  UpdateSessionTrackingResponse
} from '@magnabc/tpi'
import {SessionTrackingManagerService} from '../../../http.services/security/session-tracking/session-tracking.service'
import {
  SessionTrackingModalComponent
} from '../../../view.components/shared/session-tracking-modal/session-tracking-modal.component'
import * as moment from 'moment'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {TokenIssuerService} from '../../../http.services/security/token/token-issuer/token-issuer.service'

@Injectable({
    providedIn: 'root'
})
export class TokenManagerService implements OnDestroy {

    constructor(private tokenIssuerService: TokenIssuerService,
                private router: Router, private sessionTrackingManagerService: SessionTrackingManagerService,
                private unMarshallerService: UnMarshallerService,
                private dialog: MatDialog,
                private dialogRef:MatDialogRef<SessionTrackingModalComponent>) {
    }

    private loginPath = '/login';
    private backOfficeAuth = false;
    private accessToken: string = null;
    private refreshToken: string = null;
    private accessExpiry;
    private refreshExpiry;
    private refreshTokenTimer: any;
    private accessTokenTimer: any;
    private warningTokenTimer: any;
    private tempToken = null;
    private trnHeader = null;
    private accountNumber: string = null;
    private accessTokenExpired = null;

    tokenValidityObservable = new Subject<number>();
    accessTokenExpiredObservable = new Subject<boolean>();

    private static decodeJwt(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    async issueNewTokens() {
        return new Promise<any>(((resolve, reject) => {
            this.tokenIssuerService.issueNewTokens(this.refreshToken).toPromise().then(httpResponse => {
                if (httpResponse) {
                    this.setTokensHttp(httpResponse);
                    this.tokenValidityObservable.next(1);
                    resolve(true);
                }
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                if (error.status === 401) {
                    this.dialog.open(SessionTrackingModalComponent, {
                        width: '400px',
                        panelClass: 'padded-modal',
                        disableClose: true,
                        data:  {message: "User not authorized to perform this action", displayButton: false}
                    });
                } else if (error.status === 406) {
                    this.router.navigate(['/']).then(() => {
                        this.dialog.open(SessionTrackingModalComponent, {
                            width: '400px',
                            panelClass: 'padded-modal',
                            disableClose: true,
                            data:  {message: "Your login session has expired, please login again", displayButton: false}
                        });
                    });
                } else if (error.status === 500) {
                    this.dialog.open(SessionTrackingModalComponent, {
                        width: '400px',
                        panelClass: 'padded-modal',
                        disableClose: true,
                        data:  {message: "Unable to reach server. Please try again later", displayButton: false}
                    });
                } else {
                    this.dialog.open(SessionTrackingModalComponent, {
                        width: '400px',
                        panelClass: 'padded-modal',
                        disableClose: true,
                        data:  {message: "An unexpected error encountered", displayButton: false}
                    });
                }
                resolve(false);
            });
        }));
    }

    async issueTempQueryToken(accountNumber) {
        return new Promise<any>(((resolve, reject) => {
            this.tokenIssuerService.issueTempQueryToken(accountNumber).then(httpResponse => {
                if (httpResponse) {
                    this.accessToken = httpResponse.body.accessToken;
                    this.refreshToken = httpResponse.body.refreshToken;

                    const accessJWT = TokenManagerService.decodeJwt(this.accessToken);
                    const refreshJWT = TokenManagerService.decodeJwt(this.refreshToken);

                    this.accessExpiry = new Date(0);
                    this.accessExpiry.setUTCSeconds(accessJWT.exp);

                    this.refreshExpiry = new Date(0);
                    this.refreshExpiry.setUTCSeconds(refreshJWT.exp);

                    this.saveAuthenticationData();
                    this.setAuthTokenTimer(this.refreshExpiry.getTime() - new Date().getTime());
                    resolve(true);
                }
            }).catch(error => {
                console.error('IssueTempTokenError', error);
                RaygunErrorHandler.sendError(error);

                reject('An error occurred while obtaining user account information.');
            });
        }));
    }

    setTempToken(tempToken: string) {
        this.tempToken = tempToken;
    }

    getTempToken() {
        return this.tempToken;
    }

    getTrnHeader() {
        return this.trnHeader;
    }

    setTrnHeader(trn: string) {
        this.trnHeader = trn;
    }

    setTokens(accessToken: string, refreshToken: string) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        const accessJWT = TokenManagerService.decodeJwt(this.accessToken);
        this.accountNumber = accessJWT.accountNumber;
        this.accessExpiry = new Date(0);
        this.accessExpiry.setUTCSeconds(accessJWT.exp);
        console.log('Access token expires at :: ', this.accessExpiry);

        this.accessTokenExpired = false;

        const refreshJWT = TokenManagerService.decodeJwt(this.refreshToken);
        this.refreshExpiry = new Date(0);
        this.refreshExpiry.setUTCSeconds(refreshJWT.exp);

        this.tempToken = null;
        this.saveAuthenticationData();
        // console.log('this.refreshExpiry.getTime() :: ', this.refreshExpiry);
        // console.log('this.accessExpiry.getTime() :: ', this.accessExpiry);

        // console.log('time on machine :: ', new Date());

        const accessIssuedAt = new Date(0);
        accessIssuedAt.setUTCSeconds(accessJWT.iat);
        // console.log('accessIssuedAt :: ', accessIssuedAt);

        const refreshIssuedAt = new Date(0);
        refreshIssuedAt.setUTCSeconds(refreshJWT.iat);
        // console.log('refreshIssuedAt :: ', refreshIssuedAt);
        const newTimer = new Date(0);
        newTimer.setTime(refreshIssuedAt.getTime());
        newTimer.setMinutes(newTimer.getMinutes() + 1)

        this.setAuthTokenTimer(this.refreshExpiry.getTime() - refreshIssuedAt.getTime());
        this.setAccessTokenTimer(this.accessExpiry.getTime() - accessIssuedAt.getTime());
        this.setTokenWarning(this.refreshExpiry.getTime() - newTimer.getTime());


        let sessionAccountNumber = null;
        if(sessionStorage.getItem('accountnumber'))
            sessionAccountNumber = sessionStorage.getItem('accountnumber');
        if(sessionStorage.getItem('backofficeaccountnumber'))
            sessionAccountNumber = sessionStorage.getItem('backofficeaccountnumber');

        if(sessionAccountNumber)
        {
            // Update session when refreshing tokens
            const updateSessionTrackingRequest = new UpdateSessionTrackingRequest();
            updateSessionTrackingRequest.accountNumber = sessionAccountNumber;
            updateSessionTrackingRequest.sessionTrackingId = sessionStorage.getItem('sessiontrackingid');
            let addExpDate = new Date(this.refreshExpiry.getTime() + 15000);
            updateSessionTrackingRequest.endDate = moment(addExpDate).format('YYYY-MM-DDTHH:mm:ss') as any;
            this.sessionTrackingManagerService.updateSessionTracking(updateSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                    const updateSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, UpdateSessionTrackingResponse) as UpdateSessionTrackingResponse);
                }
            });
        }
    }

    setTokenWarning(duration: number){
        if (this.warningTokenTimer != null) {
            clearTimeout(this.warningTokenTimer);
        }
        this.warningTokenTimer = setTimeout(() => {
            const timeout = 15000;
            let dialogRef = this.dialog.open(SessionTrackingModalComponent, {
                width: '400px',
                panelClass: 'padded-modal',
                disableClose: true,
                data:  {message: "Your session is about to expire", displayButton: false}
            });
            dialogRef.afterOpened().subscribe(_ => {
                setTimeout(() => {
                    dialogRef.close();
                }, timeout)
              })
            this.warningTokenTimer = null;
        }, duration);
    }

    setTokensHttp(httpResponse: any) {
        const accessToken = httpResponse.body.accessToken;
        const refreshToken = httpResponse.body.refreshToken;

        this.setTokens(accessToken, refreshToken);
    }

    getTokens() {
        return {
            accessToken : this.accessToken,
            refreshToken : this.refreshToken,
            expirationDate: new Date(this.refreshExpiry)
        };
    }

    setBackOfficeUser(val: boolean) {
        this.backOfficeAuth = val;
    }

    private saveAuthenticationData() {
        sessionStorage.setItem('accessToken', this.accessToken);
        sessionStorage.setItem('refreshToken', this.refreshToken);
        sessionStorage.setItem('tokenExpiration', this.refreshExpiry);
        sessionStorage.setItem('accessTokenExpired', this.accessTokenExpired);
    }

    clearAuthenticationData() {
        if(this.getAccountNumber() && sessionStorage.getItem('sessiontrackingid'))
        {
            // Close all other sessions when opening new tab
            const closeSessionTrackingRequest = new CloseSessionTrackingRequest();
            closeSessionTrackingRequest.accountNumber = this.getAccountNumber();
            closeSessionTrackingRequest.sessionTrackingId = sessionStorage.getItem('sessiontrackingid');

            this.sessionTrackingManagerService.closeSessionTracking(closeSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                    const closeSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CloseSessionTrackingResponse) as CloseSessionTrackingResponse);
                }
            });
        }

        // Close all duplicate sessions when logging out of a duplicate session
        let sessionAccountNumber = null;
        if(sessionStorage.getItem('accountnumber'))
            sessionAccountNumber = sessionStorage.getItem('accountnumber');
        if(sessionStorage.getItem('backofficeaccountnumber'))
            sessionAccountNumber = sessionStorage.getItem('backofficeaccountnumber');

        if(this.getAccountNumber()===null && sessionAccountNumber)
        {
            const closeSessionTrackingRequest = new CloseSessionTrackingRequest();
            closeSessionTrackingRequest.accountNumber = sessionAccountNumber;
            closeSessionTrackingRequest.sessionTrackingId = sessionStorage.getItem('sessiontrackingid');

            this.sessionTrackingManagerService.closeSessionTracking(closeSessionTrackingRequest).then(sessionTrackingHttpResponse => {
                if (sessionTrackingHttpResponse && sessionTrackingHttpResponse.body) {
                    const closeSessionTrackingResponse = (this.unMarshallerService.unMarshallFromJson(sessionTrackingHttpResponse.body, CloseSessionTrackingResponse) as CloseSessionTrackingResponse);
                }
            });
        }

        this.accessToken = null;
        this.refreshToken = null;
        this.refreshExpiry = null;
        this.accessTokenExpired = null;
        clearTimeout(this.refreshTokenTimer);
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('tokenExpiration');
        sessionStorage.removeItem('accessTokenExpired');
        sessionStorage.removeItem('sessiontrackingid');
    }

    getAuthenticationData() {
        const accessToken = sessionStorage.getItem('accessToken');
        const refreshToken = sessionStorage.getItem('refreshToken');
        const expirationDate = new Date(sessionStorage.getItem('tokenExpiration'));

        if (!accessToken || !refreshToken || !expirationDate) {
            return false;
        }

        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.refreshExpiry = expirationDate;

        return true;
    }

    getAccessTokenExpired() : boolean{
        if(this.accessTokenExpired){
            const accessTokenExpired = sessionStorage.getItem('accessTokenExpired');
            if(accessTokenExpired === 'true'){
                return true;
            }
        }
        else{
            return false;
        }
    }

    getAccountNumber() : string {
        return this.accountNumber;
    }

    private setAuthTokenTimer(duration: number) {
        // console.log('setAuthTokenTimer :: duration : ', duration);
        if (this.refreshTokenTimer != null) {
            clearTimeout(this.refreshTokenTimer);
        }
        this.refreshTokenTimer = setTimeout(() => {
            this.logout().then(() => {
                setTimeout(() => {
                    this.dialog.open(SessionTrackingModalComponent, {
                        width: '400px',
                        panelClass: 'padded-modal',
                        disableClose: true,
                        data:  {message: "Your login session has expired", displayButton: false}
                    }).afterClosed().toPromise().then(() => {
                        this.dialog.closeAll();
                    });
                })
            });
        }, duration);
    }

    private setAccessTokenTimer(duration: number) {
        // console.log('setAccessTokenTimer :: duration : ', duration);
        if (this.accessTokenTimer != null) {
            clearTimeout(this.accessTokenTimer);
        }
        this.accessTokenTimer = setTimeout(() => {
            this.accessTokenExpiredObservable.next(true);
            this.accessTokenExpired = true;
            sessionStorage.setItem('accessTokenExpired', this.accessTokenExpired);
            this.accessTokenTimer = null;
        }, duration);
    }

    private logout() {
        this.accessToken = null;
        this.refreshToken = null;
        this.refreshTokenTimer = null;
        this.clearAuthenticationData();
        if (this.backOfficeAuth) {
            this.loginPath = '/console-login';
        } else {
            this.loginPath = '/login';
        }
        this.tokenValidityObservable.next(0);
        return this.router.navigate([this.loginPath]);
    }

    public logoutSessionTracking() {
        this.accessToken = null;
        this.refreshToken = null;
        this.refreshTokenTimer = null;
        this.loginPath = '/';
        this.tokenValidityObservable.next(0);
        return this.router.navigate([this.loginPath]);
    }

    setLoginPath(loginPath) {
        this.loginPath = loginPath;
    }

    ngOnDestroy(): void {
        this.logout();
    }
}
