import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({name: 'base64Image'})
export class Base64ImagePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: Int8Array[] | Uint32Array[]): SafeHtml {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${value}`);
    }

}
