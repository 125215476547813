import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../shared/form/form.component";
import {passwordValidator} from "../../shared/directives/password-validator.directive";
import { PlainTextUsernameAndPassword, Broker } from '@magnabc/tpi';

@Component({
    selector: 'app-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent extends FormComponent implements OnInit {

    @Input() accountUsername: string;
    @Input() resetPassword: boolean;
    @Output() onValid = new EventEmitter<void>();
    @Output() onCredentials = new EventEmitter<PlainTextUsernameAndPassword>();
    plainTextUsernameAndPassword: PlainTextUsernameAndPassword;

    formGroup: UntypedFormGroup;
    username: UntypedFormControl;
    password: UntypedFormControl;
    passwordVerify: UntypedFormControl;

    invalidPassword = false;
    accountAlreadyExists = false;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    broker: Broker;
    authentic = true;

    constructor(private translate: TranslateService, translateParser: TranslateParser) {
        super(translate, translateParser);

    }

    submit(): void {
        document.getElementById('form-submit-credentials').click();
    }

    ngOnInit() {

        if(!this.resetPassword)
        {
            this.username = new UntypedFormControl(this.accountUsername, [
                Validators.required, Validators.minLength(3)
            ]);

            this.password = new UntypedFormControl('', [
                Validators.required, passwordValidator()
            ]);
            this.passwordVerify = new UntypedFormControl('', [
                Validators.required, passwordValidator()
            ]);


             this.formGroup = new UntypedFormGroup({
            'username': this.username,
            'password': this.password,
            'passwordVerify': this.passwordVerify
            });
        }
        else{

            this.password = new UntypedFormControl('', [
                Validators.required, passwordValidator()
            ]);
            this.passwordVerify = new UntypedFormControl('', [
                Validators.required, passwordValidator()
            ]);

            this.formGroup = new UntypedFormGroup({
                'password': this.password,
                'passwordVerify': this.passwordVerify
            });
        }


        if (this.accountUsername) {
            this.username.disable();
        }

        this.plainTextUsernameAndPassword = new PlainTextUsernameAndPassword();
    }

    onCheckValidation(event): void {

        this.invalidPassword = false;
        this.accountAlreadyExists = false;

        if (this.form.valid) {
            console.log('valid');
            if (this.password.value === this.passwordVerify.value) {

                if(!this.resetPassword)
                {
                    this.plainTextUsernameAndPassword.username = this.username.value;
                }
                this.plainTextUsernameAndPassword.password = this.password.value;

                this.onCredentials.emit(this.plainTextUsernameAndPassword);
                this.onValid.emit();

            } else {
                this.invalidPassword = true;
            }
        }

    }
}
