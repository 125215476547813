import {RefundStatus} from './refund-status.model';
import {Criteria} from '../../../criteria/models/criteria.model';

export class RefundOrderItemNumberStatusCriteria extends Criteria {
    policyNumber: string;
    status: RefundStatus;

    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.payment.RefundOrderItemNumberStatusCriteria";
    }

}
