import { Location } from "./location.model";
import { CityTownIdentifier } from "../identification/models/city-town-identifier.model";
import { CountryIdentifier } from "../geographic/identification/models/country-identifier.model";

export class Locality extends Location{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.location.Locality";
    }

    public countryIdentifier: CountryIdentifier;
    public cityTownIdentifier: CityTownIdentifier;
}