import { Address } from "./address.model";

export class GenericAddress extends Address {

  constructor() {
    super();
    this['@class'] = "za.co.magnabc.tpi.location.geographic.GenericAddress";
  }

  public lineOne: string;
  public lineTwo: string;
  public lineThree: string;
  public lineFour: string;
  public lineFive: string;
}
