import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {InitialiseProcessCardPaymentRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class DirectPayOnlineCardPaymentInitialisationManagerService {

    basePath = '/retail/payment/dpo/directpayonlinecardpaymentinitialisationmanager';

    constructor(private http: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    initialiseProcessCardPayment(initialiseProcessCardPaymentRequest: InitialiseProcessCardPaymentRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/initialiseprocesscardpayment';
        return this.http.post(path, initialiseProcessCardPaymentRequest, this.options);
    }

}
