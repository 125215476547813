import { Money } from "../../../financial/models/money.model";
import { OfxCorrectAction } from "./ofx-correct-action.model";

export class OfxEntry{
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.ofx.OfxEntry";
    }

    public fitid : string;
    public paymentReference : string;
    public date : Date;
    public amount : Money;
    public correctFitid : string;
    public correctAction : OfxCorrectAction;
    public checkNumber : string;
    public referenceNumber : string;
    public memo : string;
    public name : string;
}