import { Request } from "../../../../../urdad/services/models/request.model";
import { ConceptualDocument } from "../../../../../conceptualdocument/models/conceptual-document.model";

export class RenderDocumentRequest extends Request {

  constructor(){
    super();
  }

  public conceptualDocument: ConceptualDocument;
  public printRequestId :string
  public userID: string;

}
