import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MarshallerService, ProvideCountrySellingRuleRequest } from "@magnabc/tpi";
import { RuntimeConfigurationService } from "../../../../../../../app/app.services/common/runtime-configuration/runtime-configuration.service";

@Injectable({
    providedIn: 'root'
})
export class YCCountrySellingRuleProviderService {

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    provideCountrySellingRule(provideCountrySellingRuleRequest: ProvideCountrySellingRuleRequest) {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/yellowcardinsurance/setup/yccountrysellingruleprovider/provideyccountrysellingrule';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountrySellingRuleRequest);
        return this.httpClient.post<any>(url, jsonRequest, this.options).toPromise();
    }

}
