import {CountryIdentifier} from '../../../location/geographic/identification/models/country-identifier.model';
import { UnderwriterCheckoutItemGrouping } from './underwriter-checkout-item-grouping.model';

export class CountryCheckoutItemGrouping {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.checkout.CountryCheckoutItemGrouping";
    } 

    public countryIdentifier: CountryIdentifier;
    public underwriterCheckoutItemGroupings: UnderwriterCheckoutItemGrouping[] = [];
}