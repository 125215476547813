import {
  AccountNumberIdentifier,
  PlainTextUsernameAndPassword,
  UpdateBackOfficePasswordRequest,
  UpdatePublicPasswordRequest
} from "@magnabc/tpi"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {Component, Inject, ViewChild} from "@angular/core"
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {AuthenticationService} from "../../../../../app.services/managers/authentication/authentication.service"
import {
  BackOfficePasswordUpdateManagerService
} from "../../../../../http.services/security/account/back-office-password-update-manager/back-office-password-update-manager.service"
import {
  PublicPasswordUpdateManagerService
} from "../../../../../http.services/security/account/public-password-update-manager/public-password-update-manager.service"
import {passwordValidator} from "../../../../../view.components/shared/directives/password-validator.directive"
import {RaygunErrorHandler} from "../../../../../common/utils/utils.raygun"

@Component({
    selector: 'app-dialog-update-password',
    templateUrl: './dialog-update-password.html',
})
export class DialogUpdatePasswordComponent extends FormComponent {

    credentials: PlainTextUsernameAndPassword;

    passwordIncorrect: boolean;
    passwordDoesNotMatch: boolean;
    notAllowed: boolean;

    createPassword = false;
    message: string;

    formGroup: UntypedFormGroup;
    username: UntypedFormControl;
    oldPassword: UntypedFormControl;
    password: UntypedFormControl;
    passwordVerify: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(public dialogRef: MatDialogRef<DialogUpdatePasswordComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private translate: TranslateService,
                private translateParser: TranslateParser,
                private authenticationService: AuthenticationService,
                private backOfficePasswordUpdateManagerService :BackOfficePasswordUpdateManagerService,
                private publicPasswordUpdateManagerService: PublicPasswordUpdateManagerService) {
        super(translate, translateParser);

        this.passwordIncorrect = false;
        this.passwordDoesNotMatch = false;

        if ((this.authenticationService.getAccount().credentials[0] as PlainTextUsernameAndPassword).username
            && (this.authenticationService.getAccount().credentials[0] as PlainTextUsernameAndPassword).username.length !== 0)
        {
            this.credentials = this.authenticationService.getAccount().credentials[0] as PlainTextUsernameAndPassword;
        }
        else
        {
            this.createPassword = true;
        }

        let username;
        if (this.credentials)
        {
            username = this.credentials.username;
        } else
        {
            username = (this.authenticationService.getAccount().naturalPersonIdentifier as any).number;
        }

        this.username = new UntypedFormControl(username, [
            Validators.required, Validators.minLength(3)
        ]);
        this.oldPassword = new UntypedFormControl('', [
            Validators.required
        ]);
        this.password = new UntypedFormControl('', [
            Validators.required, passwordValidator(), Validators.minLength(8), Validators.maxLength(30)
        ]);
        this.passwordVerify = new UntypedFormControl('', [
            Validators.required, passwordValidator(), Validators.minLength(8), Validators.maxLength(30)
        ]);

        this.formGroup = new UntypedFormGroup({
            username: this.username,
            oldPassword: this.oldPassword,
            password: this.password,
            passwordVerify: this.passwordVerify
        });

        this.username.disable();

        if (this.createPassword) {
            this.oldPassword.setValidators([]);
        }

    }

    submit(): void {
        document.getElementById('form-submit-old-password').click();
    }

    close(): void {
        this.dialogRef.close({success: false, created: false});
    }

    onReEnterChanged(event): void {

        if (this.password.value !== this.passwordVerify.value) {
            this.passwordDoesNotMatch = true;
        } else {
            this.passwordDoesNotMatch = false;
        }

    }

    onUpdate(event): void {

        this.passwordDoesNotMatch = false;
        this.passwordIncorrect = false;
        this.message = null;

        if (this.formGroup.valid) {
            if (this.password.value !== this.passwordVerify.value) {
                this.passwordDoesNotMatch = true;
                return;
            }
            this.startLoad();
            if(this.authenticationService.isBackOfficeUser()){
                const updateBackOfficePasswordRequest = new UpdateBackOfficePasswordRequest();

                const accountNumberIdentifier = new AccountNumberIdentifier();
                accountNumberIdentifier.number = this.authenticationService.getAccount().number;

                updateBackOfficePasswordRequest.accountIdentifier = accountNumberIdentifier;
                updateBackOfficePasswordRequest.passwordNew = this.password.value;
                updateBackOfficePasswordRequest.passwordOld = this.oldPassword.value;

                this.backOfficePasswordUpdateManagerService.updatePassword(updateBackOfficePasswordRequest).then((response) => {
                    this.dialogRef.close({success: true, created: this.createPassword});
                    this.stopLoad();
                }, (error) => {
                    this.message = error.error;
                    this.stopLoad();
                });
            }else{

                const accountNumberIdentifier = new AccountNumberIdentifier()
                accountNumberIdentifier.number =  this.authenticationService.getAccount().number;

                const updatePasswordRequest = new UpdatePublicPasswordRequest()
                updatePasswordRequest.accountIdentifier = accountNumberIdentifier;
                updatePasswordRequest.passwordOld = this.oldPassword.value;
                updatePasswordRequest.passwordNew = this.password.value;

                this.publicPasswordUpdateManagerService.updatePublicPassword(updatePasswordRequest).then( response =>{
                    this.dialogRef.close({success: true, created: this.createPassword});
                    this.stopLoad();
                }).catch(errorMessage => {
                    RaygunErrorHandler.sendError(errorMessage);
                    this.message = errorMessage.error;
                    this.stopLoad();
                });
            }
        }

    }

}
