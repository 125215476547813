import {ComprehensiveInsuranceOrderItem} from "./comprehensive-insurance-order-item.model";
import {OrderComprehensiveInsuranceProduct} from "./order-comprehensive-insurance-product.model";
import {SnapshotVehicle} from '../../../checkout/models/snapshot-vehicle.model';
import {Money} from '../../../../financial/models/money.model';

export class ComprehensiveInsurancePolicyOrderItem extends ComprehensiveInsuranceOrderItem{

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ComprehensiveInsurancePolicyOrderItem";
    }

    public expiryDate: Date
    public startDate: Date
    public snapshotVehicle: SnapshotVehicle
    public orderComprehensiveInsuranceProduct: OrderComprehensiveInsuranceProduct
    public ncdPercentage: number
    public vehicleValue: Money
    public extensionExpiryDate: Date;
}
