import {Injectable} from '@angular/core';
import { Underwriter } from '@magnabc/tpi';
import { UnderwriterInformationProviderService } from '../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service';

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelService {

    static STORAGE_WHITE_LABEL = 'white_label';

    active = false;
    underwriter: Underwriter;

    constructor(private brokerService: UnderwriterInformationProviderService) {

        // if (localStorage.getItem(WhiteLabelService.STORAGE_WHITE_LABEL)) {
        //     this.active = true;
        //     this.setUnderwriter(localStorage.getItem(WhiteLabelService.STORAGE_WHITE_LABEL));
        // }

    }

    setUnderwriter(underwriterHashId: string): Promise<Underwriter> {
        this.active = true;
        return new Promise<Underwriter>((resolve) => {
            this.brokerService.getAllUnderwriters((underwriters: Underwriter[]) => {
                for (const underwriter of underwriters) {
                    if (underwriterHashId === underwriter.hashId) {
                        this.underwriter = underwriter;
                        localStorage.setItem(WhiteLabelService.STORAGE_WHITE_LABEL, underwriterHashId);
                    }
                }
                resolve(this.underwriter);
            });
        });
    }
}
