import {Component} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
    selector: 'app-revenue-report-page',
    templateUrl: './revenue-report-page.component.html',
    styleUrls: ['./revenue-report-page.component.scss']
})
export class RevenueReportPageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Revenue Report - Console - ${runtimeConfigurationService.title}`);
    }

}
