import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MarshallerService, ProvidePublicInsurancePolicyInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class PublicInsurancePolicyInformationProviderService {

  basePath = '/retail/insurance/publicinsurancepolicyinformationprovider';

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) { }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  providePublicInsurancePolicyInformation(providePublicInsurancePolicyInformationRequest: ProvidePublicInsurancePolicyInformationRequest) {
    const URI = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providepublicinsurancepolicyinformation';

    const jsonRequest = this.marshallerService.marshallObjectToJSON(providePublicInsurancePolicyInformationRequest);

    return this.httpClient.post<any>(URI, jsonRequest, this.options).toPromise();
  }

}
