import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FormComponent} from "../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Dropdown} from "../../../common/model/dropdown.model";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import * as moment from "moment";
import {environment} from "../../../../environments/environment";
import {DATE_MASK, MY_FORMATS} from "../../../common/utils/utils.date";
import { NaturalPerson, Occupation } from '@magnabc/tpi';
import { SystemParameterProviderService } from "../../../http.services/system/system-parameter-provider/system-parameter-provider.service";
import { AuthenticationService } from "../../../app.services/managers/authentication/authentication.service";
import { QuickTransactManagerService } from "../../../app.services/managers/quick-transact-manager/quick-transact-manager.service";

declare const jQuery: any;
declare const document: any;

@Component({
    selector: 'app-entity-natural-person',
    templateUrl: './natural-person.component.html',
    styleUrls: ['./natural-person.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class NaturalPersonComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    dateMask = DATE_MASK;

    @Input() naturalPerson: NaturalPerson;
    @Output() onNaturalPerson = new EventEmitter<NaturalPerson>();
    @Output() onValid = new EventEmitter<void>();

    genders = [
        new Dropdown(1, "Male"),
        new Dropdown(2, "Female")
    ];

    formGroup: UntypedFormGroup;
    firstNames: UntypedFormControl;
    surname: UntypedFormControl;
    gender: UntypedFormControl;
    dateOfBirth: UntypedFormControl;
    photo: UntypedFormControl;
    showTextbox = false

    showErrorMessage: boolean = false;
    today = new Date();

    @ViewChild('form', { static: true }) form: FormGroupDirective;


    constructor(private translate: TranslateService, private translateParser: TranslateParser, private systemParameterService : SystemParameterProviderService, public authenticationService: AuthenticationService, public quickStartService: QuickTransactManagerService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        this.firstNames = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(50),
            this.noWhitespaceValidator
        ]);

        this.surname = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(50),
            this.noWhitespaceValidator
        ]);

        this.gender = new UntypedFormControl(null, []);
        this.dateOfBirth = new UntypedFormControl(null, []);

        if (this.naturalPerson) {
            this.firstNames.setValue(this.naturalPerson.firstNames);
            this.surname.setValue(this.naturalPerson.surname);
            this.gender = new UntypedFormControl(this.getDropdown(this.genders, this.naturalPerson.gender), []);

            if(this.naturalPerson.dateOfBirth){
                this.dateOfBirth = new UntypedFormControl(moment(this.naturalPerson.dateOfBirth, environment.formatDate), []);
            }
        } else {
            this.naturalPerson = new NaturalPerson();
        }

        this.formGroup = new UntypedFormGroup({
            firstNames: this.firstNames,
            surname: this.surname,
            gender: this.gender,
            dateOfBirth: this.dateOfBirth
        });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    submit(): void {
        document.getElementById('form-submit-natural-person').click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            this.naturalPerson.firstNames = this.firstNames.value;
            this.naturalPerson.surname = this.surname.value;
            this.naturalPerson.preferredName = this.firstNames.value.includes(" ") ? this.firstNames.value.substr(0, this.firstNames.value.indexOf(" ")) : this.firstNames.value;

            if (this.gender.value) {
                this.naturalPerson.gender = this.gender.value.name;
            }
            if (this.dateOfBirth.value) {
                this.naturalPerson.dateOfBirth = this.dateOfBirth.value.format(environment.formatDate);
            }

            this.onNaturalPerson.emit(this.naturalPerson);
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    isValid() {
        this.onNaturalPerson.emit(this.naturalPerson);
        return this.form.valid;
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    isDirty() {
        return this.form.dirty;
    }

    optionChecked(){
        this.showErrorMessage = false;
    }

    optionsId(event) {
        return event.replace(/\s/g, "").toLowerCase();
    }

}
