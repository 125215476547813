import { InsurancePolicyIdentifier } from "./insurance-policy-identifier.model";

export class InsurancePolicyNumberIdentifier extends InsurancePolicyIdentifier {
    constructor() {
        super();
        this["@class"] = "za.co.magnabc.tpi.retail.insurance.identification.InsurancePolicyNumberIdentifier";
    }

    public number:string;
}


