export class PublicInsurancePolicySummary {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.PublicInsurancePolicySummary"; 
    }

    public policyStatus: string;
    public policyNumber: string;
    public plateNumber: string;
    public certificateNumber: string;
    public expiryDate: Date;
    public insuranceCategory: string;
    public insuranceType: string;
    public make: string;
    public model: string;
    public colour : string;
    public insuranceVehicleCategorySubDescription: string;
    public insurancePolicyItemType: string;
    public cancelled: boolean;
    public ycPolicyNumber: string;
}
