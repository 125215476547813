import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
    MarshallerService,
    ProvideCountryIdentificationConfigurationRequest,
    ProvideCountryRegistrationConfigurationRequest,
    ProvideCountryStartDateConfigurationRequest,
    ProvideCoveredCountriesRequest
} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class InsuranceConfigurationProviderService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideCountryIdentificationConfiguration(provideCountryIdentificationConfigurationRequest: ProvideCountryIdentificationConfigurationRequest) {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/setup/insuranceconfigurationprovider/providecountryidentificationconfiguration';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryIdentificationConfigurationRequest);
        return this.httpClient.post<any>(provideSubscriptionsURI, jsonRequest, this.options).toPromise();
    }

    provideCountryRegistrationConfiguration(provideCountryRegistrationConfigurationRequest: ProvideCountryRegistrationConfigurationRequest) {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/setup/insuranceconfigurationprovider/providecountryregistrationconfiguration';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryRegistrationConfigurationRequest);
        return this.httpClient.post<any>(provideSubscriptionsURI, jsonRequest, this.options).toPromise();
    }

    provideCountryStartDateConfiguration(provideCountryStartDateConfigurationRequest: ProvideCountryStartDateConfigurationRequest) {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/setup/insuranceconfigurationprovider/providecountrystartdateconfiguration';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCountryStartDateConfigurationRequest);
        return this.httpClient.post<any>(provideSubscriptionsURI, jsonRequest, this.options).toPromise();
    }

    provideCoveredCountries(provideCoveredCountriesRequest: ProvideCoveredCountriesRequest) {
        const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/setup/insuranceconfigurationprovider/providecoveredcountries';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideCoveredCountriesRequest);
        return this.httpClient.post<any>(provideSubscriptionsURI, jsonRequest, this.options).toPromise();
    }
}
