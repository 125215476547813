import {Component, OnInit, ViewChild} from '@angular/core'
import {IStepComponent} from "../../../../../view.components/shared/guards/step-guard"
import {FormComponent} from "../../../../../view.components/shared/form/form.component"
import {VehicleListComponent} from "../../../../../view.components/retail/insurance/vehicle-list/vehicle.list.component"
import {
    InsurancePolicyQueryComponent
} from "../../../../../view.components/retail/insurance/policy/query/query.component"
import {
    CountryDescriptionIdentifier,
    Criteria,
    InsurancePolicyNumberCriteria,
    InsurancePolicyVehicleCriteria,
    LegalEntity,
    ProvidePublicInsurancePolicyInformationRequest,
    ProvidePublicInsurancePolicyInformationResponse,
    ProvideRetailProfileInformationRequest,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehicleInformationResponse,
    PublicInsurancePolicySummary,
    QueryPublicInsurancePolicyInformationRequest,
    QueryPublicInsurancePolicyInformationResponse,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    RetailVehicle,
    TpiIdentifier,
    UnMarshallerService
} from "@magnabc/tpi"
import {ActiveToast, ToastrService} from "ngx-toastr"
import {VehicleQueryComponent} from "../../../../../view.components/vehicle/query/vehicle.query.component"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {MatDialog} from "@angular/material/dialog"
import {Location, LocationStrategy} from "@angular/common"
import {ToastComponent} from "../../../../../view.components/shared/toast/toast.component"
import {Router} from "@angular/router"
import {
    RetailProfileInformationProviderService
} from "../../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service"
import {
    PublicInsurancePolicyInformationProviderService
} from '../../../../../http.services/retail/insurance/publicinsurancepolicyinformationprovider/public-insurance-policy-information-provider.service'
import {
    QueryPublicInsurancePolicyInformationService
} from '../../../../../http.services/retail/insurance/query-public-insurance-policy-information/query-public-insurance-policy-information.service'
import {
    UnderwriterInformationProviderService
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {Title} from '@angular/platform-browser'
import * as moment from 'moment'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
    selector: 'app-public-query',
    templateUrl: './public-query-page.component.html',
    styleUrls: ['./public-query-page.component.scss']
})
export class PublicQueryPageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    steps = [];
    currentStep = 0;

    queryBy = 0; //

    legalEntity: LegalEntity;
    vehicles: RetailVehicle[];

    noResults = false;
    activeToast: ActiveToast<any>;
    dropdownValue: string;
    targetPolicies: PublicInsurancePolicySummary[]

    @ViewChild(VehicleQueryComponent) vehicleQueryComponent: VehicleQueryComponent;
    @ViewChild(InsurancePolicyQueryComponent) insurancePolicyQueryComponent: InsurancePolicyQueryComponent;
    @ViewChild(VehicleListComponent) vehicleListComponent: VehicleListComponent;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                private retailProfileInformationProviderService: RetailProfileInformationProviderService,
                private location: Location,
                private dialog: MatDialog,
                private unMarshallerService: UnMarshallerService,
                locationStrategy: LocationStrategy,
                public brokerService: UnderwriterInformationProviderService,
                private queryPublicInsurancePolicyInformationManagerService: QueryPublicInsurancePolicyInformationService,
                private publicInsurancePolicyInformationProviderService: PublicInsurancePolicyInformationProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        this.title.setTitle(`Query Policy - ${this.runtimeConfigurationService.title}`);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.translate.get("RTSA.TITLE_QUERY_VEHICLE").subscribe((response) => {
            this.steps.push({id: 0, name: response, icon: "fa-search", borderColor: "#1592E6"});
        });
        this.translate.get("RTSA.TITLE_DETAILS").subscribe((response) => {
            this.steps.push({id: 1, name: response, icon: "fa-bars", borderColor: "#3f5baa"});
        });

    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {

    }

    onLegalEntity(legalEntity): void {

        this.legalEntity = legalEntity;
        this.noResults = false;
        if (this.legalEntity) {
            this.getRetailProfile(this.legalEntity);
        } else {
            this.noResults = true;
        }
    }

    onExists(event): void {
        this.noResults = !event;
    }

    onVehicle(vehicle): void {
        this.onSubmit(null);
    }

    onInsurancePolicy(event): void {
        this.onSubmit(null);
    }

    onQueryType(type): boolean {

        this.noResults = false;
        this.queryBy = type;

        return false;
    }

    onSubmit(event): void {
        if (this.currentStep === 0) {

            if (this.vehicleQueryComponent) {
                var identifierName = this.vehicleQueryComponent.type.value.name;
                var identifierVal = this.vehicleQueryComponent.identifier.value;
                var country = this.vehicleQueryComponent.countryComponent.country.description;

                this.makeQueryThirdPartyInsurancePolicyInformationRequest(identifierName, identifierVal, country);

            } else if (this.insurancePolicyQueryComponent) {
                var identifierValue = this.insurancePolicyQueryComponent.identifier.value;
                var identifierType = 'Insurance Number';

                this.makeQueryThirdPartyInsurancePolicyInformationRequest(identifierType, identifierValue, country);
            }
        } else {
            this.currentStep = 0;
        }
    }

    onStep(event): void {
        this.currentStep = event.id;
    }

    onNext(event): void {

        // if (this.currentStep === 1) {
        //     this.currentStep = 0;
        // } else {
        //     this.currentStep++;
        //     window.scrollTo(0, 0);
        // }

    }

    onBack(event): void {
        if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
        } else {
            this.location.back();
        }
    }

    onVehicleSelect(event): void {

    }

    getRetailProfile(legalEntity): void {

        const provideRetailProfileInformationRequest = new ProvideRetailProfileInformationRequest();
        for (const legalEntityIdentifier of legalEntity.legalEntityIdentifiers) {
            if (legalEntityIdentifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier') {

                const retailProfileLegalEntityCriteria = new RetailProfileLegalEntityCriteria();
                retailProfileLegalEntityCriteria.tpiIdentifier = (legalEntityIdentifier as TpiIdentifier);
                provideRetailProfileInformationRequest.criteria = retailProfileLegalEntityCriteria;
                break;
            }
        }

        this.noResults = false;
        this.retailProfileInformationProviderService.provideRetailProfileInformation(provideRetailProfileInformationRequest).then((response) => {
            if (response.retailProfile) {
                this.getRetailProfileVehicles(response.retailProfile);
            } else {
                this.noResults = true;
            }
        });

    }

    getRetailProfileVehicles(retailProfile: RetailProfile) {

        const provideRetailProfileRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileRequest.criteria = criteria;

        this.startLoad();
        this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileRequest).then((data) => {
            const response: ProvideRetailProfileVehicleInformationResponse = this.unMarshallerService.unMarshallFromJson(data, ProvideRetailProfileVehicleInformationResponse);

            this.stopLoad();
            this.vehicles = response.retailVehicles;
            if (this.vehicles.length > 0) {
                this.currentStep++;
            }
        }, (error) => {
            this.stopLoad();
        });

    }

    openPolicyDialog(policies: PublicInsurancePolicySummary[]): void {
        this.targetPolicies = policies
    }

    onClosePolicyView() {
        this.targetPolicies = null
    }

    openPublicPolicyDialog(policies: PublicInsurancePolicySummary[]): void {
        this.targetPolicies = policies
    }

    toast() {

        this.translate.get('TOAST.NO_INSURANCE_POLICY').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
        });

    }

    makeQueryThirdPartyInsurancePolicyInformationRequest(identifierName: string, identifierValue: string, identifierCountry?: string){

        var queryPublicInsurancePolicyInformationRequest = new QueryPublicInsurancePolicyInformationRequest();
        var insurancePolicyCriteria: Criteria;

        switch(identifierName){
            case 'Engine Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                insurancePolicyVehicleCriteria.engineNumber = identifierValue.toUpperCase();
                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'Plate Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                var countryOfIssue = new CountryDescriptionIdentifier();

                countryOfIssue.description = identifierCountry;
                insurancePolicyVehicleCriteria.plateNumber = identifierValue.toUpperCase();
                insurancePolicyVehicleCriteria.plateNumberCountryOfIssue = countryOfIssue;
                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'Vehicle Identification Number': {
                var insurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();
                insurancePolicyVehicleCriteria.vehicleIdentificationNumber = identifierValue.toUpperCase();
                insurancePolicyCriteria = insurancePolicyVehicleCriteria;
                break;
            }
            case 'Insurance Number': {
                var insurancePolicyNumberCriteria = new InsurancePolicyNumberCriteria();

                insurancePolicyNumberCriteria.insurancePolicyNumber = identifierValue.toUpperCase();
                insurancePolicyNumberCriteria.ycInsurancePolicyNumber = identifierValue.toUpperCase();
                insurancePolicyCriteria = insurancePolicyNumberCriteria;
                break;
            }
            default: {
                break;
            }
        }

        (insurancePolicyCriteria as any).date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
        queryPublicInsurancePolicyInformationRequest.criteria = insurancePolicyCriteria;

        this.getThirdPartyInsurancePolicies(queryPublicInsurancePolicyInformationRequest);
    }

    getThirdPartyInsurancePolicies(queryPublicInsurancePolicyInformationRequest: QueryPublicInsurancePolicyInformationRequest){
        this.noResults = false;
        this.queryPublicInsurancePolicyInformationManagerService.queryPublicInsurancePolicyInformation(queryPublicInsurancePolicyInformationRequest) .then((httpResponse) => {
            if(httpResponse && httpResponse.body){
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryPublicInsurancePolicyInformationResponse) as QueryPublicInsurancePolicyInformationResponse);
                if(response.publicInsurancePolicySummaries.length > 0){
                    this.openPolicyDialog(response.publicInsurancePolicySummaries);
                }
                else{
                    this.noResults = true;
                }
            }

        }).catch(error=>{
        });
    }

    getInsurancePolicies(providePublicInsurancePolicyInformationRequest: ProvidePublicInsurancePolicyInformationRequest){
        this.noResults = false;
        this.publicInsurancePolicyInformationProviderService.providePublicInsurancePolicyInformation(providePublicInsurancePolicyInformationRequest) .then((httpResponse) => {

            if(httpResponse && httpResponse.body){
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvidePublicInsurancePolicyInformationResponse) as ProvidePublicInsurancePolicyInformationResponse);
                if(response.publicInsurancePolicySummaries.length > 0){
                    this.openPublicPolicyDialog(response.publicInsurancePolicySummaries);
                }
                else{
                    this.noResults = true;
                }
            }

        }).catch(error=>{
        });
    }

    getDropDownValue(searchType: string): void {
        this.dropdownValue = searchType;
        this.noResults = false;
    }

}
