import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConfigurationService } from '../../../../app.services/common/runtime-configuration/runtime-configuration.service';
import { ValidateImportFleetDocumentRequest, ValidateImportFleetDocumentResponse } from '@magnabc/tpi';
import { Observable } from 'rxjs-compat';

@Injectable({
    providedIn: 'root'
})
export class ImportFleetDocumentValidatorService {
    private readonly validExtension = ['xls', 'xlsx'];
    constructor() { }

    validateImportFleetDocument(validateImportFleetDocumentRequest: ValidateImportFleetDocumentRequest):
        Promise<ValidateImportFleetDocumentResponse> {
        return new Promise((resolve, reject) => {
            const file = validateImportFleetDocumentRequest.document;
            const fileNameParts = file.name.split('.');
            const ext = fileNameParts.at(fileNameParts.length - 1);
            let response = new ValidateImportFleetDocumentResponse();
            response.validDocument = this.validExtension.includes(ext);;
            resolve(response);
        });
    }
}
