import { LegalEntityIdentifier } from "../../../../../../../entity/identification/models/legal-entity-identifier.model";
import { Request } from "../../../../../../../urdad/services/models/request.model";
import { Answers } from "../../../../../../insurance/comprehensiveinsurance/models/answers.model";
import {ComprehensiveInsurancePolicyCartItem} from '../../../../../../insurance/comprehensiveinsurance/models/comprehensive-insurance-policy-cart-item.model';

export class CreateProposalRequest extends Request{
    constructor(){
        super();
    }

    public orderItemNumbers: string[] = [];
    public proposalIdentifiers: string[] = [];
    public comprehensiveInsurancePolicyItems: ComprehensiveInsurancePolicyCartItem[] = [];
    public proposalFormId: string;
    public answers: Answers[] = [];
    public agentId: string;
    public legalEntityIdentifier: LegalEntityIdentifier;
    public insurancePolicyFound: boolean;

}
