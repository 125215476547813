import { Location, LocationStrategy } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    Engine,
    FinaliseLinkVehicleRequest,
    Make,
    Model,
    PlateNumber,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehicleInformationResponse,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    RetailProfileOwnerIdentifier,
    RetailVehicle,
    TpiVehicleIdentifier,
    UnMarshallerService,
    Vehicle,
    VehicleIdentificationNumber,
    VehicleRegistration
} from "@magnabc/tpi";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { ActiveToast, ToastrService } from "ngx-toastr";
import { QuickTransactManagerService } from "../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service";
import { environment } from "../../../../../environments/environment";
import { RetailProfileFinalisationManagerService } from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service";
import { RetailProfileInformationProviderService } from "../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service";
import { FormComponent } from "../../../../view.components/shared/form/form.component";
import { IStepComponent } from "../../../../view.components/shared/guards/step-guard";
import { ToastComponent } from "../../../../view.components/shared/toast/toast.component";
import { ObscureNumberComponent } from "../../../../view.components/security/obscure-number/obscure-number.component";
import { VehicleEngineComponent } from "../../../../view.components/vehicle/engine/vehicle.engine.component";
import { VehicleQueryComponent } from "../../../../view.components/vehicle/query/vehicle.query.component";
import { VehicleGeneralComponent } from "../../../../view.components/vehicle/general/vehicle_general.component";
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'

declare const window: any;
@Component({
    templateUrl: './vehicle-create-page.component.html',
    styleUrls: ['../../../../view.components/vehicle/vehicle.component.scss']
})
export class VehicleCreatePageComponent extends FormComponent implements OnInit, IStepComponent {

    backPressed: boolean;
    skipDeactivate: boolean;
    steps = [];
    currentStep = 0;

    retailProfile: RetailProfile;
    vehicle: Vehicle;

    @ViewChild(VehicleQueryComponent) vehicleQueryComponent: VehicleQueryComponent;
    @ViewChild(VehicleGeneralComponent) vehicleGeneralComponent: VehicleGeneralComponent;
    @ViewChild(ObscureNumberComponent) obscureNumberComponent: ObscureNumberComponent;
    @ViewChild(VehicleEngineComponent) vehicleEngineComponent: VehicleEngineComponent;

    private activeToast: ActiveToast<any>;

    quickTransact: boolean;
    vehicles: RetailVehicle[] = [];

    obscureLabel: string;
    obscure: string;

    vehicleIdentificationNumber: string;
    plateNumber: string;
    engine: string;
    country: CountryDialingIdentifier;
    unverifiedModel: Model;
    unverifiedMake: Make;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
                private toastr: ErrorToastService,
        private retailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
        private title: Title,
        private googleAnalyticsService: GoogleAnalyticsService,
        private location: Location,
        private unMarshallerService: UnMarshallerService,
        private retailProfileService: RetailProfileFinalisationManagerService,
        private quickStartService: QuickTransactManagerService,
        private retailProfileInformationProviderService: RetailProfileInformationProviderService,
        private route: ActivatedRoute,
        locationStrategy: LocationStrategy,
        private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });
        this.setSteps();

        this.setTitle();

        this.initVehicle();

    }

    setSteps(): void {
        this.steps = [];
        this.translate.get("VEHICLE.TITLE_QUERY_VEHICLE").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-search", borderColor: "#1592E6" });
        });
        this.translate.get("VEHICLE.TITLE_OBSCURE").subscribe((response) => {
            this.steps.push({ id: 1, name: response, icon: "fa-search", borderColor: "#3171c3", hidden: true });
        });
        this.translate.get("VEHICLE.TITLE_GENERAL").subscribe((response) => {
            this.steps.push({ id: 2, name: response, icon: "fa-car", borderColor: "#3171c3" });
        });
        if (!this.country || this.country.alphaTwo.toLocaleLowerCase() !== 'za') {
            this.translate.get("VEHICLE.TITLE_ENGINE").subscribe((response) => {
                this.steps.push({ id: 3, name: response, icon: "fa-cogs", borderColor: "#3f5baa" });
            });
        }
    }

    onDeactivate(): boolean {
        if (this.quickStartService.currentStep > 0 && this.backPressed && !this.skipDeactivate) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit(): void {

        this.route.queryParams.forEach((params) => {

            this.quickTransact = params.quickTransact;
            if (this.quickTransact) {
                this.retailProfile = this.quickStartService.retailProfile;
            } else {
                this.retailProfile = this.retailProfileService.retailProfile;
            }
            if (this.retailProfile && this.retailProfile.vehicleIdentifiers && this.retailProfile.vehicleIdentifiers.length > 0) {
                this.getRetailProfileVehicles();
            }

        });

    }

    setTitle() {
        let title = `${this.steps[this.currentStep].name} - Add Vehicle - ${this.runtimeConfigurationService.title}`;
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    private getRetailProfileVehicles() {

        const provideRetailProfileVehicleInformationRequest: ProvideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;

        this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then((data) => {
            const response: ProvideRetailProfileVehicleInformationResponse =
                this.unMarshallerService.unMarshallFromJson(data, ProvideRetailProfileVehicleInformationResponse);
            this.vehicles = response.retailVehicles;
        });

    }

    initVehicle(): void {
        this.vehicle = new Vehicle();
        const plateNumber = new PlateNumber();
        this.vehicle.vehicleIdentifiers.push(plateNumber);
    }

    onSubmit(event): void {
        switch (this.currentStep) {
            case 0:
                if (event === true) {
                    this.vehicleQueryComponent.onSkipVehicleSearch(event);
                }
                this.vehicleQueryComponent.submit();
                break;
            case 1:
                this.obscureNumberComponent.submit();
                break;
            case 2:
                this.vehicleGeneralComponent.submit();
                break;
            case 3:
                this.vehicleEngineComponent.submit();
                break;
        }
    }

    onSkipVerification(event): void {
        this.resetVehicle();
        this.onNext(event);
    }

    private resetVehicle(emptyAll = false): void {
        const temp = new Vehicle();
        temp.engine = new Engine();
        if (!emptyAll) {
            const vehiclePlateNumber = new PlateNumber()
            const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
            countryDescriptionIdentifier.description = this.country.description;
            vehiclePlateNumber.number = this.plateNumber;
            vehiclePlateNumber.countryOfIssue = countryDescriptionIdentifier;
            temp.vehicleIdentificationNumber = this.vehicleIdentificationNumber;
            temp.vehicleIdentifiers.push(vehiclePlateNumber);
            temp.engine.engineNumber = this.engine;
        }
        this.vehicle = temp;
    }

    onNext(event): void {
        if (this.currentStep === 3) {
            this.submitVehicle();
        } else if (this.currentStep === 2 &&
            ((this.vehicle.propulsion.toLowerCase() === 'semi-trailer') ||
                (this.vehicle.propulsion.toLowerCase() === 'draw bar - trailer'))) {
            this.vehicle.engine = new Engine;
            this.submitVehicle();
        } else if (this.currentStep === 2 && this.country && this.country.alphaTwo.toLocaleLowerCase() === 'za') {
            this.submitVehicle();
        } else if (this.currentStep === 0 && (!this.vehicle.vehicleRegisterNumber || (this.vehicle.vehicleRegisterNumber && this.obscure === 'undefined'))) {
            this.currentStep = 2;
            this.setTitle();
        } else {
            this.currentStep++;
            window.scrollTo(0, 0);
            this.setTitle();
        }

    }

    onBack(event): void {
        if (this.currentStep === 2) {
            this.resetVehicle(true);
            this.currentStep = 0;
            this.setTitle();
        } else if (this.currentStep > 0) {
            this.currentStep--;
            window.scrollTo(0, 0);
            this.setTitle();
        } else {
            this.skipDeactivate = true;
            this.location.back();
        }
    }

    onVehicle(vehicle): void {
        this.vehicle = vehicle;
    }

    onUnverifiedMake(unverifiedMake) {
        this.unverifiedMake = unverifiedMake;
    }

    onUnverifiedModel(unverifiedModel) {
        this.unverifiedModel = unverifiedModel;
    }

    onVehicles(vehicles): void {
        if (vehicles && vehicles.length > 0) {
            this.vehicle = vehicles[0];
        } else {
            if (!this.vehicle) {
                this.vehicle = new Vehicle();
                this.vehicle.vehicleIdentifiers.push(new PlateNumber());
            }
        }
    }

    onVin(vin: string): void {
        this.vehicle.vehicleIdentificationNumber = vin;
        if (this.vehicle.engine && this.vehicle.engine.engineNumber) {
            this.obscure = this.vehicle.engine.engineNumber;
            this.obscureLabel = "Please verify the Engine Number";
        } else {
            this.obscure = this.getPlateNumber(this.vehicle).number;
            this.obscureLabel = "Please verify the Plate Number";
        }
    }

    onPlateNumber(plateNo: string): void {
        this.plateNumber = plateNo;
        let plateFound = false;
        if (this.vehicle.vehicleIdentifiers) {
            for (const identifier of this.vehicle.vehicleIdentifiers) {
                if (identifier instanceof PlateNumber || identifier['@class'].includes('.PlateNumber')) {
                    (identifier as PlateNumber).number = plateNo;
                    plateFound = true;
                    break;
                }
            }
        }

        if (!plateFound) {
            const plate = new PlateNumber();
            const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
            countryDescriptionIdentifier.description = this.country.description;
            plate.number = plateNo;
            plate.countryOfIssue = countryDescriptionIdentifier;
            this.vehicle.vehicleIdentifiers.push(plate);
        }
        this.obscure = this.vehicle.vehicleIdentificationNumber + '';
        this.obscureLabel = "Please verify the VIN";
    }

    onEngineNumber(engineNo: string): void {
        this.engine = engineNo;
        if (!this.vehicle.engine || !this.vehicle.engine.engineNumber) {
            this.vehicle.engine = new Engine();
            this.vehicle.engine.engineNumber = engineNo;
        }
        this.obscure = this.vehicle.vehicleIdentificationNumber + '';
        this.obscureLabel = "Please verify the VIN";
    }

    onVehicleRegisterNumber(vehicleRegisterNo: string): void {
        this.vehicle.vehicleRegisterNumber = vehicleRegisterNo;
        this.obscure = this.vehicle.vehicleIdentificationNumber + '';
        this.obscureLabel = "Please verify the VIN";
    }

    onCountryOfRegistration(country: CountryDialingIdentifier): void {
        this.country = country;
        this.setSteps();
    }

    onEngine(engine): void {
        this.vehicle.engine = engine;
    }

    submitVehicle(): void {

        if (!this.retailProfile) {
            this.retailProfile = this.retailProfileService.retailProfile;
        }

        if (!this.retailProfile.vehicleIdentifiers) {
            this.retailProfile.vehicleIdentifiers = [];
        }

        for (const v of this.vehicles) {
            if ((this.vehicle.vehicleIdentificationNumber + '') === v.vehicleIdentificationNumber) {
                this.alreadyLinked();
                return;
            }
        }

        const identifiers = [];
        this.vehicle.vehicleIdentifiers.forEach(
            vehicleIdentifier => {
                if (!vehicleIdentifier['@class'].includes('za.co.magnabc.tpi.location.geographic.identification.CountryDescriptionIdentifier') &&
                    !vehicleIdentifier['@class'].includes('za.co.magnabc.tpi.vehicle.identification.PlateNumber')) {
                    identifiers.push(vehicleIdentifier);
                } else {
                    const plateNumber = new PlateNumber();
                    plateNumber.number = (vehicleIdentifier as PlateNumber).number;
                    const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                    countryDescriptionIdentifier.description = this.country.description;

                    plateNumber.countryOfIssue = countryDescriptionIdentifier;
                    identifiers.push(plateNumber);
                }
            }
        );
        this.vehicle.vehicleIdentifiers = identifiers;

        const vehicleRegistration = new VehicleRegistration();
        vehicleRegistration.countryOfRegistration = this.country.description;
        vehicleRegistration.effectiveFrom = moment().format("YYYY-MM-DD") as any;

        const vehicleIdentificationNumber = new VehicleIdentificationNumber();
        vehicleIdentificationNumber.number = this.vehicle.vehicleIdentificationNumber as any;

        const indexOfObject = this.vehicle.vehicleIdentifiers.findIndex((object) => {
            return object['@class'].includes('za.co.magnabc.tpi.vehicle.identification.VehicleIdentificationNumber')
        })

        if (indexOfObject !== -1) {
            this.vehicle.vehicleIdentifiers.splice(indexOfObject, 1)
        }

        this.vehicle.vehicleIdentifiers.push(vehicleIdentificationNumber);
        const finaliseLinkVehicleRequest = new FinaliseLinkVehicleRequest();
        finaliseLinkVehicleRequest.vehicle = this.vehicle;
        finaliseLinkVehicleRequest.vehicle.vehicleRegistrations = [vehicleRegistration];

        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        finaliseLinkVehicleRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
        if (this.unverifiedMake) {
            finaliseLinkVehicleRequest.unverifiedMake = this.unverifiedMake;
        }
        if (this.unverifiedModel) {
            finaliseLinkVehicleRequest.unverifiedModel = this.unverifiedModel;
        }
        this.startLoad();
        this.retailProfileFinalisationManagerService.finaliseLinkVehicle(finaliseLinkVehicleRequest).subscribe(() => {
            this.retailProfileService.refreshRetailProfile().then((retailProfile) => {
                if (this.quickStartService.npIdentifier) {
                    this.quickStartService.retailProfile = retailProfile;
                }

                this.stopLoad();
                this.toast();
            });
        }, error => {
            if (error && error.error === 'Vehicle already exists.') {
                this.alreadyLinked();
                this.stopLoad();
            }
            else {
                this.unableToLink();
                this.stopLoad();
            }
        });

    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);
    }

    getPlateNumber(vehicle: Vehicle): PlateNumber {
        let plate = null;
        vehicle.vehicleIdentifiers.forEach(identifier => {
            if (identifier instanceof PlateNumber || identifier['@class'].includes('.PlateNumber')) {
                plate = identifier;
            }
        });
        return plate;
    }

    toast() {

        this.translate.get('TOAST.CREATE_VEHICLE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.vehicle.make + " " + this.vehicle.model }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.initVehicle();
                        this.currentStep = 0;
                        this.activeToast.portal.destroy();
                        window.scrollTo(0, 0);
                        break;
                    case ToastComponent.SECONDARY:
                        this.skipDeactivate = true;
                        this.location.back();
                        // if (this.quickTransact) {
                        // } else {
                        //     this.router.navigate(['/retail/profile']);
                        // }
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.skipDeactivate = true;
                this.location.back();
                // if (this.quickTransact) {
                // } else {
                //     this.router.navigate(['/retail/profile']);
                // }
            });
        });
    }

    alreadyLinked() {

        let linked: string;
        linked = 'TOAST.VEHICLE_ALREADY_LINKED_LOGGED_IN_ACCOUNT';

        this.vehicle.vehicleIdentifiers.forEach(vehicleId => {
            if (vehicleId instanceof TpiVehicleIdentifier) {
                this.retailProfile.vehicleIdentifiers.forEach(retailvehicle => {
                    if (retailvehicle instanceof TpiVehicleIdentifier) {
                        if (vehicleId.number === retailvehicle.number) {
                            linked = 'TOAST.VEHICLE_ALREADY_LINKED_LOGGED_IN_ACCOUNT';
                        }
                    }
                });
            }
        });

        this.translate.get(linked).subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.vehicle.make + " " + this.vehicle.model + "  VIN: " + this.vehicle.vehicleIdentificationNumber }), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.initVehicle();
                        this.currentStep = 0;
                        this.activeToast.portal.destroy();
                        window.scrollTo(0, 0);
                        break;
                    case ToastComponent.SECONDARY:
                        this.skipDeactivate = true;
                        this.location.back();
                        // if (this.quickTransact) {
                        // } else {
                        //     this.router.navigate(['/retail/profile']);
                        // }
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    unableToLink() {

        let linked: string;
        linked = 'TOAST.VEHICLE_UNABLE_TO_LINK_LOGGED_IN_ACCOUNT';

        this.vehicle.vehicleIdentifiers.forEach(vehicleId => {
            if (vehicleId instanceof TpiVehicleIdentifier) {
                this.retailProfile.vehicleIdentifiers.forEach(retailvehicle => {
                    if (retailvehicle instanceof TpiVehicleIdentifier) {
                        if (vehicleId.number === retailvehicle.number) {
                            linked = 'TOAST.VEHICLE_UNABLE_TO_LINK_LOGGED_IN_ACCOUNT';
                        }
                    }
                });
            }
        });

        this.translate.get(linked).subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.vehicle.make + " " + this.vehicle.model }), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.initVehicle();
                        this.currentStep = 0;
                        this.activeToast.portal.destroy();
                        window.scrollTo(0, 0);
                        break;
                    case ToastComponent.SECONDARY:
                        this.skipDeactivate = true;
                        this.location.back();
                        // if (this.quickTransact) {
                        // } else {
                        //     this.router.navigate(['/retail/profile']);
                        // }
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    get isMaltisApplicable(): boolean {
        return this.country && this.country.alphaTwo === 'MW';
    }
}
