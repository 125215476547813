import {Response} from '../../../../../../../urdad/services/models/response.model';
import { Order } from '../../../../../models/order.model';
import { Underwriter } from '../../../../../../insurance/insurance_api';

export class ProvideOrderResponse extends Response {
    constructor() {
        super();
    }

    public order: Order;
    public underwriters : Underwriter [] = [];
}
