import {AgentIdentifier} from "./agent-identifier.model";
import {LegalEntityDescriptionIdentifier} from "./legal-entity-description-identifier.model";

export class AgentLegalEntityIdentifier extends AgentIdentifier{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.identification.AgentLegalEntityIdentifier";
    }

    public name: string;
    public legalEntityDescriptionIdentifier: LegalEntityDescriptionIdentifier;
}
