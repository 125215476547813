export class StringUtils {

    static applySentenceCase(string): string {
        if (string && string.charAt(0)) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return "";
    }

    static formatCents(x) {
        x = x / 100;
        x = parseFloat(x).toFixed(2);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static formatPrice(x) {
        x = x / 100;
        x = parseFloat(x).toFixed(0);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static sanitiseNumber(number: string): string {
        number = number.replace(/ /g, "");
        number = number.replace(/-/g, "");
        return number;
    }

}
