import { CertificateIdentifier } from "./certificate-identifier.model";

export class CertificateNumberIdentifier extends CertificateIdentifier{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.identification.CertificateNumberIdentifier";
    }

    public certificateNumber: string;
}
