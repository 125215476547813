import { CountryIdentifier } from "./country-identifier.model";

export class AlphaThree extends CountryIdentifier {

    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.location.geographic.identification.AlphaThree";
    }

    public description:string;
    public alphaThree:string;
    public flagUrl:string;

}
