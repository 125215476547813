import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core"
import {TranslateService} from "@ngx-translate/core"
import {Router} from "@angular/router"
import {ServiceList} from "../../../common/model/service-list.model"
import {
    BrokerLegalEntityCriteria, LegalEntityDescriptionIdentifier, LegalEntityIdentifier,
    ProvideBrokerInformationRequest, ProvideBrokerInformationResponse,
    ProvideUnderwriterInformationRequest,
    ProvideUnderwriterInformationResponse,
    TpiIdentifier,
    Underwriter,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService
} from '@magnabc/tpi'
import {AuthenticationService} from "../../../app.services/managers/authentication/authentication.service"
import {
    UnderwriterInformationProviderService
} from "../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {
    QuickTransactManagerService
} from "../../../app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {AgentManagerService} from '../../../app.services/managers/agent-manager/agent-manager.service'
import {
    BrokerInformationProviderService
} from '../../../http.services/retail/insurance/broker-information-provider/broker-information-provider.service'
import {
    UnderwriterManagerService
} from '../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import { InsuranceProductManagerService } from "../../../app.services/managers/insurance-product-manager/insurance-product-manager.service"

declare const jQuery: any;

@Component({
    selector: 'app-back-office-side-menu',
    templateUrl: './back-office-side-menu.component.html',
    styleUrls: ['./back-office-side-menu.component.scss']
})
export class BackOfficeSideMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(public underwriterManagerService: UnderwriterManagerService,
                public authenticationService: AuthenticationService,
                private router: Router,
                private quickStartService: QuickTransactManagerService,
                private insuranceProductManagerService: InsuranceProductManagerService
                ) {

    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    onQuickStart(event): boolean {
        this.quickStartService.reset();
        this.router.navigate(['/quickstart/assisted'], {queryParams: {quickTransact: true}});
        return false;
    }

    onUnderwriter(underwriter): boolean {
        this.underwriterManagerService.selectedUnderwriter = underwriter;
        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier()
        legalEntityDescriptionIdentifier.name = underwriter.name
        legalEntityDescriptionIdentifier.tpiIdentifier = underwriter.legalEntityIdentifier
        this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier = new UnderwriterLegalEntityIdentifier(underwriter.name, legalEntityDescriptionIdentifier);
        this.underwriterManagerService.onUnderwriterChange();
        this.insuranceProductManagerService.reset();
        jQuery("#accounts").removeClass("show");

        this.router.navigate(['/console/home'], {replaceUrl: true});
        return false;
    }

    servicesDashboard(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEPOPULARPRODUCTS]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDEROLEREVENUE]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDESALESSUMMARY]));
    }

    servicesProducts(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.CREATETHIRDPARTYINSURANCEPRODUCT]) &&
                  this.authenticationService.isAuthorised([ServiceList.UPDATETHIRDPARTYINSURANCEPRODUCT]));
    }

    servicesServiceGroups(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.UPDATESERVICEGROUP]) &&
                  this.authenticationService.isAuthorised([ServiceList.CREATESERVICEGROUP]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDESERVICEGROUPS]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDESERVICEIDENTIFIERS]));
    }

    servicesVerifyCardPayment(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.VERIFYCARDPAYMENT]));
    }

    servicesMakeModelTrim(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.UPDATEVEHICLEMAKE]) &&
                  this.authenticationService.isAuthorised([ServiceList.UPDATEVEHICLEMODEL]) &&
                  this.authenticationService.isAuthorised([ServiceList.CREATEVEHICLEMAKE]) &&
                  this.authenticationService.isAuthorised([ServiceList.CREATEVEHICLEMODEL]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDEVEHICLEMAKES]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDEVEHICLEMODELS]));
    }

    servicesIssueCertificate(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.ISSUEINSURANCECERTIFICATE]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDEINSURANCEPOLICIES]));
    }

    servicesQueryPolicy(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.QUERYINSURANCEPOLICYINFORMATION]));
    }

    servicesReceivePayment(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.RECEIVECASHIERPAYMENT]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDEORDERINFORMATION]));
    }

    servicesReports(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEREVENUEREPORT]));
    }

    servicesSupplierInvoices(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDESUPPLIERINVOICESUMMARY]));
    }

    servicesTransactionReport(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEREVENUEREPORT]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDERENDEREDCONCEPTUALDOCUMENT]) &&
                  this.authenticationService.isAuthorised([ServiceList.PROVIDEPAYOUTMETHOD]));
    }

    servicesAssistedTransact(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.ASSISTEDQUICKTRANSACT]));
    }

    servicesAddUsers(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDESUBSCRIPTIONSSUMMARY]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDESUBSCRIPTIONS]) &&
        this.authenticationService.isAuthorised([ServiceList.UPDATESUBSCRIPTION]) &&
        this.authenticationService.isAuthorised([ServiceList.CREATESUBSCRIPTION])&&
        this.authenticationService.isAuthorised([ServiceList.CREATEUSERACCOUNT]) &&
        this.authenticationService.isAuthorised([ServiceList.PROVIDESERVICEGROUPS]));
    }

    servicesAccountStatements(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.PROVIDEACCOUNTSTATEMENTREPORT]));
    }

    servicesProposals(): boolean {
        return !!(this.authenticationService.isAuthorised([ServiceList.ASSESSPROPOSAL])) ||
            !!(this.authenticationService.isAuthorised([ServiceList.ASSESSYELLOWCARD]));
    }
}
