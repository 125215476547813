import { Address } from "./address.model";
import { CountryIdentifier } from "../identification/models/country-identifier.model";

export class BasicPostalAddress extends Address {
    constructor() {
        super();
        this['@class'] = "za.co.magnabc.tpi.location.geographic.BasicPostalAddress";
    }

    public city: string;
    public code: string;
    public addressIdentifier: string;
    public lineOne: string;
    public lineTwo: string;
    public lineThree: string;
    public countryIdentifier:CountryIdentifier;
}