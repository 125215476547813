import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {FinaliseMaintainVehicleInformationRequest, MarshallerService} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})

export class VehicleInformationFinalisationManagerService {

    constructor(private http: HttpClient,
                private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    finaliseMaintainVehicleInformation(request: FinaliseMaintainVehicleInformationRequest): Observable<any> {
        const url = this.runtimeConfigurationService.apiBaseUrl + '/vehicle/vehicleinformationfinalisationmanager/finalisemaintainvehicleinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(request);
        return this.http.post(url, jsonRequest, this.options);
    }

}
