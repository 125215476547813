import { Response } from '../../../../../../../urdad/services/models/response.model';

export class InitialiseSyncVehicleResponse extends Response{

    constructor() {
        super();
    }
    
    public totalNumberOfVehicles: number;

}
