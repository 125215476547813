import {Response} from '../../../../../../../urdad/services/models/response.model';
import {Country} from '../../../../../models/country.model';

export class ProvideCountryInformationResponse extends Response {
    constructor() {
        super();
    }

    countries: Country[] = [];
}
