import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {MarshallerService, ProvideOrderLegalEntityRequest} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class OrderLegalEntityProviderService {

    basePath = '/retail/checkout/orderlegalentityprovider';

    constructor(private http: HttpClient, private marshaller: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideOrderLegalEntity(provideOrderLegalEntityRequest: ProvideOrderLegalEntityRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providelegalentity';
        const jsonRequest = this.marshaller.marshallObjectToJSON(provideOrderLegalEntityRequest);
        return this.http.post(path, jsonRequest, this.options);
    }
}
