import { Criteria } from "../../../criteria/models/criteria.model";
import { LegalEntityIdentifier } from "../../../entity/identification/models/legal-entity-identifier.model";

export class AccountLegalEntityIdentifierCriteria extends Criteria
{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.security.account.AccountLegalEntityIdentifierCriteria";
    }

    public legalEntityIdentifiers: LegalEntityIdentifier[] = [];
}
