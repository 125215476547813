import { CountryDescriptionIdentifier } from '../../../../location/geographic/identification/models/country-description-identifier.model'
import {InsuranceCategory, ShippingAddress} from "../../../retail_api"

export class PickUpPoint {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.setup.PickupPoint"
    }

    insuranceCategory: InsuranceCategory
    countryDescriptionIdentifier: CountryDescriptionIdentifier
    shippingAddress: ShippingAddress
}
