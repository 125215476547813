import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {FormComponent} from "../../../../shared/form/form.component";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ComprehensiveInsuranceProduct, InsuranceCategory, ThirdPartyInsuranceProduct} from '@magnabc/tpi';

declare const jQuery: any;

@Component({
    selector: 'app-product-price',
    templateUrl: './retail.products.price.component.html',
    styleUrls: ['./retail.products.price.component.scss']
})
export class RetailProductsPriceComponent extends FormComponent implements OnInit {

    @Input() product: ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct;
    @Output() onProduct = new EventEmitter<ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct>();
    @Output() onNext = new EventEmitter<boolean>();

    formGroup: UntypedFormGroup;
    price: UntypedFormControl;
    pricePercentage: UntypedFormControl;

    recommendedPriceValue = 0;
    recommendedPrice = false;
    labelPrice: string;
    buttonLabelChange: string;
    insuranceCategory = InsuranceCategory;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser) {
        super(translate, translateParser);
        this.translate.get("ADMIN.PRODUCTS.LABEL_RECOMMENDED_FEE").subscribe((response) => {
            this.labelPrice = response;
        });
        this.translate.get("BUTTON.CHANGE").subscribe((response) => {
            this.buttonLabelChange = response;
        });

    }
    //TODO : Product price to be fixed
    ngOnInit(): void {

        console.log(this.product);

        if (this.product instanceof ThirdPartyInsuranceProduct || this.product['@class'] === new ThirdPartyInsuranceProduct()['@class']) {

            // this.price = new FormControl((this.product as ThirdPartyInsuranceProduct).price.amount / 100, [
            //     Validators.required,
            //     Validators.min(1),
            //     Validators.max(99999999),
            //     Validators.pattern("^[0-9]*(|\.[0-9]{2})$")
            // ]);

            this.price = new UntypedFormControl('', [
                Validators.required,
                Validators.min(1),
                Validators.max(99999999),
                Validators.pattern("^[0-9]*(|\.[0-9]{2})$")
            ]);

            // if ((this.product as ThirdPartyInsuranceProduct).price.amount) {
            //     this.price.setValue((this.product as ThirdPartyInsuranceProduct).price.amount / 100);
            // } else {
            //     this.price.setValue(null);
            // }

            this.enablePrice(false, false);

            this.formGroup = new UntypedFormGroup({
                'price': this.price
            });

        } else if (this.product instanceof ComprehensiveInsuranceProduct || this.product['@class'] === new ComprehensiveInsuranceProduct()['@class']) {

            // this.pricePercentage = new FormControl((this.product as ComprehensiveInsuranceProduct).pricePercentage, [
            //     Validators.required,
            //     Validators.min(0),
            //     Validators.max(100)
            // ]);

            this.pricePercentage = new UntypedFormControl("", [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
            ]);

            this.formGroup = new UntypedFormGroup({
                'pricePercentage': this.pricePercentage
            });

        }

    }

    //TODO : Product price to be fixed
    onCheckValidation(event): void {
        if (!this.form.invalid) {

            // if (this.product instanceof ThirdPartyInsuranceProduct || this.product['@class'] === new ThirdPartyInsuranceProduct()['@class']) {
            //     let newVal = this.price.value.toString();
            //     newVal = newVal.replace(',', '.');
            //     (this.product as ThirdPartyInsuranceProduct).price.amount = newVal * 100;
            // } else if (this.product instanceof ComprehensiveInsuranceProduct || this.product['@class'] === new ComprehensiveInsuranceProduct()['@class']) {
            //     (this.product as ComprehensiveInsuranceProduct).pricePercentage = parseFloat(this.pricePercentage.value);
            // }
            this.onProduct.emit(this.product);
            this.onNext.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }
    }

    submit(): void {
        document.getElementById('form-submit-price').click();
    }

    onChangeFeeSchedule(event): boolean {

        this.enablePrice(this.recommendedPrice);

        return false;

    }

    //TODO : Product price to be fixed
    enablePrice(enable, focus = true): void {

        if (enable) {
            this.translate.get("ADMIN.PRODUCTS.LABEL_FEE").subscribe((response) => {
                this.labelPrice = response;
            });
            this.translate.get("BUTTON.RECOMMENDED_FEE").subscribe((response) => {
                this.buttonLabelChange = response;
            });
            setTimeout(() => {
                this.price.enable();
                if (focus) {
                    jQuery("#fee").focus();
                }
            });
        } else {
            this.translate.get("ADMIN.PRODUCTS.LABEL_RECOMMENDED_FEE").subscribe((response) => {
                this.labelPrice = response;
            });
            this.translate.get("BUTTON.CHANGE").subscribe((response) => {
                this.buttonLabelChange = response;
            });
            // this.price.setValue(((this.product as ThirdPartyInsuranceProduct).price.amount / 100).toFixed(2)); // FIXME
            // setTimeout(() => {
            //     this.price.disable();
            // });
        }

        this.recommendedPrice = !this.recommendedPrice;

    }

}
