import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {RuntimeConfigurationService} from '../runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    basePath = '/file';

    constructor(private httpClient: HttpClient,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
      };

    uploadFile(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/uploadfile';
        return this.httpClient.post(path, formData, this.options);
    }

}
