import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { FormComponent } from "../../shared/form/form.component";
import {
    AllCountryCriteria,
    Country,
    CountryDescriptionIdentifier,
    CountryDialingIdentifier,
    EngineNumber,
    PlateNumber,
    ProvideCountryInformationRequest,
    ProvideCountryInformationResponse,
    QueryVehicleInformationRequest,
    QueryVehicleInformationResponse,
    UnMarshallerService,
    Vehicle,
    VehicleIdentificationNumber,
    VehicleProviderCriteria,
    VehicleRegisterNumber
} from '@magnabc/tpi';
import { Dropdown } from "../../../common/model/dropdown.model";
import { QueryVehicleInformationManagerService } from "../../../http.services/vehicle/query-vehicle-information-manager/query-vehicle-information-manager.service";
import { CountryComponent } from "../../shared/country/country.component";
import { CountryProviderService } from '../../../http.services/location/geographic/country-provider/country-provider.service';
import { CountryService } from '../../shared/country/services/country.service';
import { RaygunErrorHandler } from '../../../common/utils/utils.raygun';

@Component({
    selector: 'app-vehicle-query',
    templateUrl: './vehicle.query.component.html'
})
export class VehicleQueryComponent extends FormComponent implements OnInit {

    @Input() numberPlateOnly = false;
    @Input() allowRegisterNumberQuery = false;
    @Output() onVehicles = new EventEmitter<Vehicle[]>();
    @Output() onVIN = new EventEmitter<string>();
    @Output() onPlateNumber = new EventEmitter<string>();
    @Output() onEngineNumber = new EventEmitter<string>();
    @Output() onVehicleRegisterNumber = new EventEmitter<string>();
    @Output() onCountryOfRegistration = new EventEmitter<CountryDialingIdentifier>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onSelected = new EventEmitter<string>();

    countries: Country[] = [];
    skipVehicleSearch = false;

    types = [
        new Dropdown(1, "Vehicle Identification Number"),
        new Dropdown(2, "Plate Number"),
        new Dropdown(3, "Engine Number"),
        new Dropdown(4, "Vehicle Register Number"),
    ];

    formGroup: UntypedFormGroup;
    type: UntypedFormControl;
    identifier: UntypedFormControl;
    typePlaceholder: string;
    selectedDropdown = "Vehicle Identification Number";
    country: Country;
    maxLength: number;

    @ViewChild(CountryComponent) countryComponent: CountryComponent;
    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(
        private translate: TranslateService,
        private translateParser: TranslateParser,
        private countryProviderService: CountryProviderService,
        private countryService: CountryService,
        private unMarshallerService: UnMarshallerService,
        private queryVehicleInformationManagerService: QueryVehicleInformationManagerService
    ) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        if (this.numberPlateOnly) {
            this.types = [
                new Dropdown(2, "Plate Number")
            ];
            this.typePlaceholder = "VEHICLE.PLATE_NUMBER";
            this.maxLength = 9;
        } else {
            if (!this.allowRegisterNumberQuery) {
                this.types = [
                    new Dropdown(1, "Vehicle Identification Number"),
                    new Dropdown(2, "Plate Number"),
                    new Dropdown(3, "Engine Number"),
                ];
            }
            this.typePlaceholder = "VEHICLE.VEHICLE_IDENTIFICATION_NUMBER";
            this.maxLength = 17;
        }

        this.type = new UntypedFormControl(this.types[0], []);
        this.identifier = new UntypedFormControl('', [
            Validators.required
        ]);
        this.formGroup = new UntypedFormGroup({
            type: this.type,
            identifier: this.identifier,
        });

        if (this.countryService.getCountries() && this.countryService.getCountries().length > 0) {
            this.countries = this.countryService.getCountries();
        } else {
            this.populateCountries();
        }

        this.onSelected.emit(this.types[0].name);
    }

    populateCountries() {
        this.startLoad();
        const criteria = new AllCountryCriteria();
        const provideCountryInformationRequest = new ProvideCountryInformationRequest();
        provideCountryInformationRequest.criteria = criteria;

        this.countryProviderService.provideCountryInformation(provideCountryInformationRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideCountryInformationResponse) as ProvideCountryInformationResponse);
                this.countries = response.countries;
                this.countryService.setCountries(response.countries);
            }
            this.stopLoad();
        }).catch(err => {
            console.error('Error while getting countries :: ', err);
            RaygunErrorHandler.sendError(err);

            this.stopLoad();
        });
    }

    onCountry(country: Country): void {
        this.country = country;
        if (country) {
            let dialingId = new CountryDialingIdentifier();
            dialingId.alphaTwo = country.alphaTwo;
            dialingId.description = country.description;
            dialingId.dialingCode = country.dialingCode;
            dialingId.dialingMask = country.dialingMask;
            dialingId.flagUrl = country.flagUrl;
            this.onCountryOfRegistration.emit(dialingId);
        }
    }

    onCountryValid(event): void {
        document.getElementById('form-submit-query').click();
    }

    onTypeChanged(event): void {
        this.onSelected.emit(event.value.name);
        if (this.type.value.value === 1) {
            this.typePlaceholder = "VEHICLE.VEHICLE_IDENTIFICATION_NUMBER";
            this.maxLength = 17;
        } else if (this.type.value.value === 2) {
            this.typePlaceholder = "VEHICLE.PLATE_NUMBER";
            this.maxLength = 9;
        } else if (this.type.value.value === 3) {
            this.typePlaceholder = "VEHICLE.ENGINE_NUMBER";
            this.maxLength = 20;
        } else {
            this.typePlaceholder = "VEHICLE.VEHICLE_REGISTER_NUMBER";
            this.maxLength = 14;
        }
    }

    submit(): void {
        this.countryComponent.submit();
    }

    onSkipVehicleSearch(skipVehicleSearch): void {
        this.skipVehicleSearch = skipVehicleSearch;
    }

    keyDownHandler(event) {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }

    onCheckValidation(event): void {


        if (this.formGroup.valid) {

            let vehicleIdentifier = null;
            switch (this.type.value.value) {
                case 1:
                    vehicleIdentifier = new VehicleIdentificationNumber();
                    vehicleIdentifier.number = this.identifier.value.toLocaleUpperCase();
                    break;
                case 2:
                    vehicleIdentifier = new PlateNumber();
                    vehicleIdentifier.number = this.identifier.value.toLocaleUpperCase();
                    const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                    countryDescriptionIdentifier.description = this.country.description;
                    vehicleIdentifier.countryOfIssue = countryDescriptionIdentifier;
                    break;
                case 3:
                    vehicleIdentifier = new EngineNumber();
                    vehicleIdentifier.number = this.identifier.value.toLocaleUpperCase();
                    break;
                case 4:
                    vehicleIdentifier = new VehicleRegisterNumber();
                    vehicleIdentifier.number = this.identifier.value.toLocaleUpperCase();
                    vehicleIdentifier.countryOfIssue = this.country.description;
            }
            this.startLoad();

            if (this.country.description === "Malawi" && !this.skipVehicleSearch) {
                const queryVehicleInformationRequest = new QueryVehicleInformationRequest();
                const vehicleProviderCriteria = new VehicleProviderCriteria();
                const countryIdentifier = new CountryDescriptionIdentifier();
                countryIdentifier.description = this.country.description;
                vehicleProviderCriteria.countryIdentifier = countryIdentifier;

                vehicleProviderCriteria.type = 'EXTERNAL';
                vehicleProviderCriteria.vehicleIdentifier = vehicleIdentifier;
                queryVehicleInformationRequest.criteria = vehicleProviderCriteria

                this.queryVehicleInformationManagerService.provideVehicle(queryVehicleInformationRequest).toPromise().then((response) => {
                    if (response && response.body) {
                        const queryVehicleInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, QueryVehicleInformationResponse) as QueryVehicleInformationResponse);

                        this.onVehicles.emit(queryVehicleInformationResponse.vehicles);
                        this.onCountryOfRegistration.emit(this.country);
                        this.onValid.emit();
                    }

                    this.switchTypeValue();
                    this.stopLoad();
                }).catch((err) => {
                    RaygunErrorHandler.sendError(err);

                    console.log('Could not load vehicles.');
                    this.stopLoad();
                    this.onVehicles.emit([]);
                    this.onCountryOfRegistration.emit(this.country);
                    this.onValid.emit();
                });
            } else {
                this.switchTypeValue();
                this.stopLoad();
                this.onVehicles.emit([]);
                this.onCountryOfRegistration.emit(this.country);
                this.onValid.emit();
            }
        }

    }


    private switchTypeValue() {
        switch (this.type.value.value) {
            case 1:
                this.onVIN.emit(this.identifier.value.toLocaleUpperCase());
                break;
            case 2:
                this.onPlateNumber.emit(this.identifier.value.toLocaleUpperCase());
                break;
            case 3:
                this.onEngineNumber.emit(this.identifier.value.toLocaleUpperCase());
                break;
            case 4:
                this.onVehicleRegisterNumber.emit(this.identifier.value.toLocaleUpperCase());
                break;
        }
    }
}
