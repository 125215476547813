import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { ProposalRecordStatus } from '../../../../../comprehensiveinsurance_api';
import { RetailProfileProposalSummary } from '../../../../../models/retail-profile-proposal-summary.model';

export class ProvideRetailProfileProposalSummaryResponse extends Response {
    constructor(){
        super();
    }

    public proposalId: string;
    public proposalRecordStatus: ProposalRecordStatus;
    public retailProfileProposalSummary: RetailProfileProposalSummary [] = [];
}
