/******************************************* */
/******** !! DO NOT EDIT THIS FILE !! ********/
/******************************************* */

//Marshaller with underscores
import { Injectable } from "@angular/core";

@Injectable()
export class MarshallerService
{
  marshallObjectToJSON(object: Object) : JSON {

      // console.log('marshall :: received :: ', object);
    return recursiveMarshaller(object);//parse stringified json to JSON object

  }

}

var classesToBeBound = new Object();

function addClassesToBeBound(clazz) {
   var obj = new clazz();
   classesToBeBound[obj['@class']] = obj;
}

var atIdMarshallingMap = new Map();

function recursiveMarshaller(object){

    // console.log('Recursive marhsall :: isArray=', Array.isArray(object))
    //handle collections
    if(Array.isArray(object))
    {
      for(var index = 0; index < object.length; ++index) {
        if(typeof object[index] == 'object'){
          object[index] = recursiveMarshaller(object[index]);
        }

      }

      return object;
    }

  if(object != null && object != undefined) {

    var keys = Object.keys(object);
    var json = new Object();
    atIdMarshallingMap.clear();

    for (var i in keys) {
        let newKey = keys[i];

        //Hanlde objects
        if(typeof object[keys[i]] == "object"){
          // console.log("\nIts an Object!!! ", object[keys[i]])
          object[keys[i]] = recursiveMarshaller(object[keys[i]]);
        }
         // console.log("\nCurrent Object[keys[i]]: ", object[keys[i]]);
        if(object[keys[i]] == undefined || object[keys[i]] == null)
        {
          object[keys[i]] = null;
        }

        // console.log('Check :: ', JSON.stringify(object[keys[i]]));
        if (JSON.stringify(object[keys[i]]) === '{}') {
            // console.log('Caught {} object :: ', object);
            object[keys[i]] = null;
        }

        json[newKey] = object[keys[i]];
    }

    // console.log('Now marshalling json :: ', json);
    return marshall(json);

  }
}
//Marshall object to JSON
function marshall(object)
{
  //console.log('now marshalling :: ', object);
  return JSON.parse(JSON.stringify(object, atIdReplacer));

}

function atIdReplacer(name, val) {

  if(val == undefined || val == null )
  {
    return val;
  }

  if(val['@Id'] != undefined && val['@Id'] != null ) {

    if(atIdMarshallingMap.get(val) != null) {
      return atIdMarshallingMap.get(val);
    }
    else {
        var id = guid();
        atIdMarshallingMap.set(val,id);
        val['@Id'] = id;
        return val;
    }
  }
  else{
    return val;
  }
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
