import { Response } from "../../../../../urdad/services/models/response.model";
import { UsageIdentifier } from "../../../../identification/models/usage-identifier.model";

export class ProvideUsageIdentifiersResponse extends Response {
    constructor() {
        super();
    }

    public usageIdentifiers: UsageIdentifier[] = [];

}

