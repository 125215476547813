import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {
    ComprehensiveInsuranceProduct,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    Product, ProductNumberIdentifier,
    ProvideBrokerInformationResponse,
    ProvideUnderwriterInformationRequest,
    ProvideUnderwriterInformationResponse, ThirdPartyInsuranceProduct,
    TpiIdentifier,
    Underwriter, UnderwriterIdentifier,
    UnderwriterLegalEntityCriteria,
    UnderwriterLegalEntityIdentifier, UnMarshallerService, YCInsuranceProduct
} from '@magnabc/tpi'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {
    ProductListComponent
} from '../../../../../view.components/retail/insurance/insurance-product-manager/product-list/product-list.component'
import {ActivatedRoute, Router} from '@angular/router'
import {
    UnderwriterManagerService
} from '../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {Utils} from '../../../../../view.components/shared/Utils/Utils'
import {
    UnderwriterInformationProviderService
} from '../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service'
import {ToastrService} from 'ngx-toastr'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {environment} from '../../../../../../environments/environment'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-product-add-on-page',
  templateUrl: './product-add-on-page.component.html',
  styleUrls: ['./product-add-on-page.component.scss']
})
export class ProductAddOnPageComponent implements OnInit, AfterViewInit {

    underwriterMap: { [id: string]: Underwriter }
    productSelectedMap: { [id: string]: Product }
    vehicleCategoryAndCountryMap: { [id: string]: boolean } = {}

    @ViewChild(ProductListComponent) productListComponent: ProductListComponent

    constructor(private title: Title,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private underwriterManagerService: UnderwriterManagerService,
                private underwriterInformationProviderService: UnderwriterInformationProviderService,
                private unMarshallerService: UnMarshallerService,
                private toastr: ErrorToastService,
                public insuranceProductManagerService: InsuranceProductManagerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Product Add-On - Console - ${this.runtimeConfigurationService.title}`);
    }

    ngOnInit(): void {
        const underwriterIdentifiers = []
        for (const product of this.insuranceProductManagerService.addOnProductOptions) {
            if (product instanceof ThirdPartyInsuranceProduct || product instanceof ComprehensiveInsuranceProduct || product instanceof YCInsuranceProduct) {
                underwriterIdentifiers.push(product.underwriterIdentifier)
            }
        }
        this.getUnderwriters(underwriterIdentifiers)
    }

    ngAfterViewInit(): void {
    }

    onProductSelect(product: Product) {
        const vechicleCategory = (product as any).insuranceVehicleCategory.description
        const coveredCountries = (product as any).coveredCountries
        if (this.productSelectedMap[product.number]) {
            delete this.productSelectedMap[product.number]
            for (const country of coveredCountries) {
                delete this.vehicleCategoryAndCountryMap[`${country.description}-${vechicleCategory}`]
            }
        } else {
            for (const country of coveredCountries) {
                if (this.vehicleCategoryAndCountryMap[`${country.description}-${vechicleCategory}`]) {
                    this.toast('Only one of each insurance category can be selected as an add on product for a specific country.', 'Invalid selection')
                    return
                }
            }
            for (const country of coveredCountries) {
                this.vehicleCategoryAndCountryMap[`${country.description}-${vechicleCategory}`] = true
            }
            this.productSelectedMap[product.number] = product
        }
        (this.insuranceProductManagerService.product as YCInsuranceProduct).productIdentifiers = []
        this.insuranceProductManagerService.selectedAddOnProducts = []
        for (const key in this.productSelectedMap) {
            const productNumberIdentifier = new ProductNumberIdentifier()
            productNumberIdentifier.number = this.productSelectedMap[key].number;
            (this.insuranceProductManagerService.product as YCInsuranceProduct).productIdentifiers.push(productNumberIdentifier)
            this.insuranceProductManagerService.selectedAddOnProducts.push(this.productSelectedMap[key])
        }
        if (this.productListComponent) {
            this.productListComponent.setSelectedProducts(this.productSelectedMap)
        }
    }

    onBackToOverview() {
        this.activatedRoute.params.subscribe((data) => {
            const productNumber = data['number'];
            this.router.navigate([`retail/insurance/product-overview/${productNumber}`])
        });
    }

    getUnderwriters(legalEntityIdentifiers: UnderwriterIdentifier[]) {
        const tpiIdentifier: TpiIdentifier[] = []

        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            tpiIdentifier.push((legalEntityIdentifier as UnderwriterLegalEntityIdentifier).legalEntityDescriptionIdentifier.tpiIdentifier)
        }
        this.provideUnderwriters(tpiIdentifier).then((underwriters) => {
            this.underwriterMap = {}
            for (const underwriter of underwriters) {
                if (!this.underwriterMap[(underwriter as Underwriter).name]) {
                    this.underwriterMap[(underwriter as Underwriter).name] = underwriter
                }
            }
            setTimeout(() => {
                this.productListComponent.setProducts(this.insuranceProductManagerService.addOnProductOptions)
                this.productSelectedMap = {}
                for (const product of this.insuranceProductManagerService.selectedAddOnProducts) {
                    this.onProductSelect(product)
                }
            })
        })

    }

    toast(message: string, title: string) {

        this.toastr.success(message, title, {
            toastComponent: ToastComponent,
            timeOut: 5000,
            progressBar: true,
            closeButton: true
        });

    }

    provideUnderwriters(tpiIdentifiers: TpiIdentifier[]): Promise<Underwriter[]> {
        return new Promise<Underwriter[]>((resolve) => {
            const underwriterLegalEntityCriteria = new UnderwriterLegalEntityCriteria();
            underwriterLegalEntityCriteria.legalEntityIdentifiers = tpiIdentifiers
            const provideUnderwriterInformationRequest = new ProvideUnderwriterInformationRequest();
            provideUnderwriterInformationRequest.criteria = underwriterLegalEntityCriteria;
            this.underwriterInformationProviderService.provideUnderwriters(provideUnderwriterInformationRequest).subscribe((response) => {
                if(response && response.body){
                    const provideUnderwriterInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideUnderwriterInformationResponse) as ProvideUnderwriterInformationResponse);
                    resolve(provideUnderwriterInformationResponse.underwriters)
                } else {
                    resolve([])
                }
            });
        })
    }

    onDeactivate(): boolean {
        return true
    }

}
