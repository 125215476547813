import {Component, OnInit} from '@angular/core'
import { InsuranceCategory } from '@magnabc/tpi'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'

declare const jQuery: any

@Component({
    selector: 'app-product-information-price-schedule',
    templateUrl: './product-information-price-schedule.component.html',
    styleUrls: ['./product-information-price-schedule.component.scss']
})
export class ProductInformationPriceScheduleComponent implements OnInit {

    static scrollToPriceSchedule() {
        setTimeout(() => {
            const container = jQuery('.tpi-product-container')
            const scrollTo = jQuery('#price-schedule')
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top - 50 + container.scrollTop()
            })
        })
    }

    constructor(public insuranceProductService: InsuranceProductService) { }

    ngOnInit(): void {
    }

    onSelectPriceSchedule(priceSchedule) {
        this.insuranceProductService.onSelectPriceSchedule(priceSchedule)
        ProductInformationPriceScheduleComponent.scrollToPriceSchedule()
    }

    showOriginalPricing(): boolean{
        if(this.insuranceProductService.insuranceCategory === InsuranceCategory.THIRDPARTY && this.insuranceProductService.isExtension){
            return false;
        }
        return true;
    }

}
