import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {MotorInsuranceCertificate} from '../../../../../models/motor-insurance-certificate.model';

export class ProvideInsuranceCertificatesResponse extends Response{
    constructor(){
        super();
    }

    public motorInsuranceCertificates: MotorInsuranceCertificate [] = [];
} 
