import { Event } from '../../events/models/event.model';

export abstract class ServiceProviderEvent extends Event{
    constructor(){
        super();
    }

    public serviceProviderType: string;
    public serviceProviderIdentifier: string;

}