import {Injectable} from '@angular/core';
import {PublicAuthenticatorService} from '../../../http.services/security/public-authenticator/public-authenticator.service';
import {
    Account,
    AccountCriteria,
    AccountNaturalPersonCriteria,
    AccountNumberIdentifier,
    Agent,
    AgentLegalEntityCriteria,
    AgentLegalEntityIdentifier,
    AuthenticationAdvice,
    AuthenticationResponse,
    Criteria,
    JsonWebToken,
    JudicialPerson,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    NationalIdentityNumber,
    NaturalPerson,
    PlainTextUsername,
    PlainTextUsernameAndPassword,
    ProvideAgentInformationRequest,
    ProvideAgentInformationResponse,
    ProvideLegalEntityInformationRequest,
    ProvideLegalEntityInformationResponse,
    ProvideSubscriptionsOwnersBackOfficeRequest,
    ProvideSubscriptionsOwnersBackOfficeResponse,
    ProvideSubscriptionsOwnersRequest,
    ProvideSubscriptionsOwnersResponse,
    ProvideSubscriptionsRequest,
    ProvideSubscriptionsResponse,
    PublicAuthenticateCredentialsRequest,
    PublicAuthenticateCredentialsResponse,
    PublicProvideAccountInformationRequest,
    PublicProvideAccountInformationResponse,
    ServiceGroup,
    ServiceNameTypeIdentifier,
    Subscription,
    SubscriptionOwnedByLegalEntityCriteria,
    SubscriptionOwner,
    SubscriptionUsedByLegalEntityCriteria,
    TokenElaboration,
    TpiIdentifier,
    LegalEntity,
    UnMarshallerService, BrokerLegalEntityIdentifier
} from '@magnabc/tpi'
import {TokenManagerService} from '../token-manager/token-manager.service';
import {PublicAccountInformationProvider} from '../../../http.services/security/account/public-account-information-provider/public-account-information-provider.service';
import {AuthenticationLocalService} from '../authentication-local/authentication-local.service';
import {Subject, Subscription as RxSubscription, BehaviorSubject, ReplaySubject} from 'rxjs';
import {CompareEntityIdentifiersService} from '../compare-entity-identifiers/compare-entity-identifiers.service';
import {PublicLegalEntityInformationProviderService} from '../../../http.services/entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service';
import {Utils} from '../../../view.components/shared/Utils/Utils';
import {BackOfficeAccountInformationProvider} from "../../../http.services/security/account/back-office-account-information-provider/back-office-account-information-provider.service";
import {BackOfficeLegalEntityInformationProviderService} from "../../../http.services/entity/back-office-legal-entity-information-provider/back-office-legal-entity-information-provider.service";
import {AgentInformationProviderService} from "../../../http.services/retail/agent-information-provider/agent-information-provider.service";
import {AgentManagerService} from "../agent-manager/agent-manager.service";
import {SubscriptionInformationProviderService} from '../../../http.services/security/authorisation/subscription-information-provider/subscription-information-provider.service';
import {RaygunErrorHandler} from '../../../common/utils/utils.raygun';
import {WhiteLabelService} from "../white-label/white-label.service";
import {QuickTransactManagerService} from '../quick-transact-manager/quick-transact-manager.service';

export interface LoginSubscriptionWrapper {
    firstName: string;
    surname: string;
    subscription: Subscription[];
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    static STORAGE_AGENT = 'agent_v3';
    static STORAGE_JWT = 'jwt_v3';
    static STORAGE_ACTIVE_SUBSCRIPTION = 'active_subscription_v3';

    constructor(private publicAuthenticatorService: PublicAuthenticatorService,
        private tokenManagerService: TokenManagerService,
        private publicAccountInformationProvider: PublicAccountInformationProvider,
        private backOfficeAccountInformationProvider: BackOfficeAccountInformationProvider,
        private subscriptionInformationProvider: SubscriptionInformationProviderService,
        private authenticationProviderService: AuthenticationLocalService,
        private publicLegalEntityInformationProviderService: PublicLegalEntityInformationProviderService,
        private backOfficeLegalEntityInformationProviderService: BackOfficeLegalEntityInformationProviderService,
        private compareEntityIdentifierService: CompareEntityIdentifiersService,
        private agentService: AgentManagerService,
        private provideAgentInformationService: AgentInformationProviderService,
        private whiteLabelService: WhiteLabelService,
        private agentInformationProviderService: AgentInformationProviderService,
        private quickStartService: QuickTransactManagerService,
        private unMarshallerService: UnMarshallerService) {

    }

    private account: Account;
    private loggedInUsername: string;
    private loggedInNaturalPerson: NaturalPerson = null;
    private loggedInJudicialPerson: JudicialPerson = null;
    private judicialPersonLinkedTo: JudicialPerson = null;
    private subscriptions: Subscription[] = [];
    private serviceGroups = new Map<string, ServiceGroup>();
    private services = new Map<string, boolean>();
    private proxyServices: Map<string, boolean> = null;
    private activeProxy: TpiIdentifier = null;
    private activeProxyLegalEntity: LegalEntity = null;
    private activeProxySubscriptions: SubscriptionOwner = null;
    private authenticated = false;
    busyAuthenticating = false;
    underwriterHashId: string;

    subscriptionListener = new ReplaySubject<boolean>(1, 2000);
    proxiesUpdated = new Subject<boolean>();

    private agent: Agent = null;
    private agentIdentifier: AgentLegalEntityIdentifier = null;

    private naturalPersonSubscriptions = new Map<string, SubscriptionOwner>();
    private judicialPersonSubscriptions = new Map<string, SubscriptionOwner>();

    private activeProxyNaturalPersonSubscriptions = new Map<string, SubscriptionOwner>();
    private activeProxyJudicialPersonSubscriptions = new Map<string, SubscriptionOwner>();

    tokenListener: RxSubscription;
    private publicUserAuthenticated = false;
    private backOfficeUserAuthenticated = false;

    refreshPublicAuthentication(){

        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(this.account.naturalPersonIdentifier);
        let subscriptionLegalEntityIdentifier: LegalEntityIdentifier = null;

        this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpLegalEntityInformation =>{
            const provideLegalEntityInformationResponse = (this.unMarshallerService.
                unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

            if (provideLegalEntityInformationResponse.legalEntities.length>0){
                if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                    this.loggedInNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                } else {
                    this.loggedInJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                }

                const subscriptionOwnedByCriteria = new SubscriptionOwnedByLegalEntityCriteria();
                provideLegalEntityInformationResponse.legalEntities[0].legalEntityIdentifiers.forEach(identifier => {
                    if (identifier instanceof TpiIdentifier) {
                        subscriptionOwnedByCriteria.legalEntityIdentifier = identifier;
                        subscriptionLegalEntityIdentifier = identifier;
                    }
                });

                const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
                provideSubscriptionsRequest.criteria = subscriptionOwnedByCriteria;

                return this.subscriptionInformationProvider.provideSubscriptions(provideSubscriptionsRequest);
            } else {
                return;
            }
        })
    }


    refreshBackOfficeAuthentication(){

        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(this.account.naturalPersonIdentifier);
        let subscriptionLegalEntityIdentifier: LegalEntityIdentifier = null;

        this.backOfficeLegalEntityInformationProviderService.provideBackOfficeLegalEntityInformation(provideLegalEntityInformationRequest).then(httpLegalEntityInformation =>{
            const provideLegalEntityInformationResponse = (this.unMarshallerService.
                unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);


            if (provideLegalEntityInformationResponse.legalEntities.length>0){
                if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                    this.loggedInNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                } else {
                    this.loggedInJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                }

                const subscriptionOwnedByCriteria = new SubscriptionOwnedByLegalEntityCriteria();
                provideLegalEntityInformationResponse.legalEntities[0].legalEntityIdentifiers.forEach(identifier => {
                    if (identifier instanceof TpiIdentifier) {
                        subscriptionOwnedByCriteria.legalEntityIdentifier = identifier;
                        subscriptionLegalEntityIdentifier = identifier;
                    }
                });

                const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
                provideSubscriptionsRequest.criteria = subscriptionOwnedByCriteria;

                return this.subscriptionInformationProvider.provideSubscriptions(provideSubscriptionsRequest);
            } else {
                return;
            }
        })
    }

    publicAuthentication(username: string, password: string) {
        const authenticateCredentialsRequest = new PublicAuthenticateCredentialsRequest();
        const plainTextUsernameAndPassword = new PlainTextUsernameAndPassword();
        plainTextUsernameAndPassword.username = username;
        plainTextUsernameAndPassword.password = password;
        authenticateCredentialsRequest.credentials = plainTextUsernameAndPassword;

        const advice = new AuthenticationAdvice();
        advice.authentic = false;

        return new Promise<AuthenticationAdvice>(((resolve, reject) => {
            this.busyAuthenticating = true;
            this.publicAuthenticatorService.authenticateCredentials(authenticateCredentialsRequest).then(response => {
                if (response && response.body){
                    const authResponse = (response.body as PublicAuthenticateCredentialsResponse);
                    const authentic = (authResponse.authenticationAdvice) && (authResponse.authenticationAdvice.authentic);

                    if (authentic){
                        advice.authentic = true;
                        return this.setAuthentication(authResponse.authenticationAdvice);
                    } else {
                        this.busyAuthenticating = false;
                    }
                }
                resolve(advice);
                return;
            }).then(authentication => {
                advice.authentic = authentication;
                resolve(advice);
            }).catch(error => {
                RaygunErrorHandler.sendError(error);

                reject(error);
            });
        }));
    }

    getUserAuthenticated() {
        return new Promise<any>((resolve, reject) => {

            const accountNumber = sessionStorage.getItem('accountnumber');
            const backOfficeAccountNumber = sessionStorage.getItem('backofficeaccountnumber');
            if (!(accountNumber) && !(backOfficeAccountNumber) && (!this.authenticated)) {
                this.account = null;
                resolve({authentic: false});
                return;
            }
            else if ((accountNumber) && !(this.authenticated)) {

                if (this.tokenManagerService.getAuthenticationData()) {

                    const accountNumberIdentifier = new AccountNumberIdentifier();
                    accountNumberIdentifier.number = accountNumber;

                    const accountCriteria = new AccountCriteria();
                    accountCriteria.accountNumberIdentifier = accountNumberIdentifier;

                    this.authenticationProviderService.authenticatePublicUserFromLocalStorage(accountCriteria).then(httpResponse => {

                        if (httpResponse && httpResponse.body){

                            const authenticationResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, AuthenticationResponse) as AuthenticationResponse);

                            if (!authenticationResponse.legalEntity || !authenticationResponse.legalEntity.legalEntityIdentifiers ||
                                !(authenticationResponse.legalEntity.legalEntityIdentifiers.length > 0)) {
                                this.authenticated = false;
                                this.publicUserAuthenticated = false;
                                resolve({authentic: false});
                                return;
                            }

                            this.setAuthentication(authenticationResponse.authenticationAdvice).then(result => {
                                resolve({authentic: result});
                                return;
                            }).catch((error) => {
                                RaygunErrorHandler.sendError(error);
                                resolve({authentic: false});
                                return;
                            });
                        }
                    })
                }
            }
            else if ((backOfficeAccountNumber) && !(this.authenticated)) {

                if (this.tokenManagerService.getAuthenticationData()) {

                    const accountNumberIdentifier = new AccountNumberIdentifier();
                    accountNumberIdentifier.number = backOfficeAccountNumber;

                    const accountCriteria = new AccountCriteria();
                    accountCriteria.accountNumberIdentifier = accountNumberIdentifier;

                    this.authenticationProviderService.authenticateBackOfficeUserFromLocalStorage(accountCriteria).then(httpResponse => {

                        if (httpResponse && httpResponse.body){

                            const authenticationResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, AuthenticationResponse) as AuthenticationResponse);

                            if (!authenticationResponse.legalEntity || !authenticationResponse.legalEntity.legalEntityIdentifiers ||
                                !(authenticationResponse.legalEntity.legalEntityIdentifiers.length > 0)) {
                                this.authenticated = false;
                                this.backOfficeUserAuthenticated = false;
                                resolve({authentic: false});
                                return;
                            }

                            this.setBackOfficeAuthentication(authenticationResponse.authenticationAdvice).then(result => {
                                resolve({authentic: result});
                                return;
                            }).catch((error) => {
                                RaygunErrorHandler.sendError(error);

                                resolve({authentic: false});
                                return;
                            });
                        }
                    })
                }
            }

        });
    }

    setAgent(agent: Agent){
        this.agent = agent;

        if (this.agent) {
            this.agentIdentifier = new AgentLegalEntityIdentifier();
            const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
            legalEntityDescriptionIdentifier.tpiIdentifier = this.agent.legalEntityIdentifier as TpiIdentifier;
            legalEntityDescriptionIdentifier.name = this.agent.name;
            this.agentIdentifier.legalEntityDescriptionIdentifier = legalEntityDescriptionIdentifier;
            this.agentIdentifier.name = this.agent.name;
        } else {
            this.agentIdentifier = null;
        }
        console.log("set agent" + agent);
    }

    setAuthentication(authenticationAdvice: AuthenticationAdvice) {
        return new Promise<boolean>((resolve, reject) => {
            this.authenticated = true;
            if (authenticationAdvice && authenticationAdvice.elaboration) {
                const elaboration = (authenticationAdvice.elaboration as TokenElaboration);
                const accessToken = (elaboration.accessToken as JsonWebToken);
                const refreshToken = (elaboration.refreshToken as JsonWebToken);
                this.tokenManagerService.setTokens(accessToken.token, refreshToken.token);
                this.tokenManagerService.setLoginPath('/login');
            }

            this.tokenListener = this.tokenManagerService.tokenValidityObservable.subscribe(res => {
                if (res === 0){
                    this.logout();
                }
            });

            this.whiteLabel();

            let criteria: Criteria;
            if (sessionStorage.getItem('accountnumber')) {
                criteria = new AccountCriteria();
                const accountIdentifier = new AccountNumberIdentifier();
                accountIdentifier.number = sessionStorage.getItem('accountnumber');
                (criteria as AccountCriteria).accountNumberIdentifier = accountIdentifier;
            } else if (this.tokenManagerService.getAccountNumber()){
                criteria = new AccountCriteria();
                const accountIdentifier = new AccountNumberIdentifier();
                accountIdentifier.number = this.tokenManagerService.getAccountNumber();
                (criteria as AccountCriteria).accountNumberIdentifier = accountIdentifier;
            } else {
                const plainTextUser = new PlainTextUsername();
                plainTextUser.username = this.loggedInUsername;

                criteria = new AccountNaturalPersonCriteria();
                (criteria as AccountNaturalPersonCriteria).naturalPersonIdentifiers.push(plainTextUser);
            }

            const provideAccountInformationRequest = new PublicProvideAccountInformationRequest();
            provideAccountInformationRequest.criteria = criteria;

            this.publicAccountInformationProvider.provideAccountInformation(provideAccountInformationRequest).then(accountResponse => {

                if (accountResponse && accountResponse.body) {
                    const provideAccountResponse =
                        (this.unMarshallerService.unMarshallFromJson(accountResponse.body, PublicProvideAccountInformationResponse) as PublicProvideAccountInformationResponse);

                    if (provideAccountResponse.accounts.length === 0) {
                        reject('No accounts found.');
                        this.busyAuthenticating = false;
                        return;
                    }

                    const account = provideAccountResponse.accounts[0];
                    this.account = account;
                    if (account.effectiveTo !== null && new Date(account.effectiveTo) < new Date()) {
                        reject('Account is disabled.');
                        this.busyAuthenticating = false;
                        return;
                    }

                    sessionStorage.setItem('accountnumber', this.account.number);

                    const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
                    provideLegalEntityInformationRequest.legalEntityIdentifiers.push(this.account.naturalPersonIdentifier);

                    return this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest);

                } else {
                    this.busyAuthenticating = false;
                    resolve(false);
                    return;
                }

            }).then(httpLegalEntityInformation => {

                if (httpLegalEntityInformation && httpLegalEntityInformation.body) {

                    const provideLegalEntityInformationResponse = (this.unMarshallerService.
                        unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities.length>0){
                        if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                            this.loggedInNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                        } else {
                            this.loggedInJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                        }

                        return this.loadEntitySubscriptions();
                    } else {
                        this.busyAuthenticating = false;
                        resolve(false);
                        return;
                    }

                } else {
                    this.busyAuthenticating = false;
                    resolve(false);
                    return;
                }
            }).then(subscriptionsResponse => {

                if (subscriptionsResponse) {
                    this.setServices(this.subscriptions);
                    this.populateLoggedInProxySubscriptionMaps().then(() => {
                        this.checkForProxyInStorage();
                        this.publicUserAuthenticated = true;
                        this.proxiesUpdated.next(true);
                        this.busyAuthenticating = false;
                        resolve(true);
                        return;
                    });
                } else {
                    resolve(false);
                    this.busyAuthenticating = false;
                    return;
                }
            }).catch(err => {
                this.busyAuthenticating = false;
                console.error('Authentication error :: ', err);
                RaygunErrorHandler.sendError(err);

                reject('Could not authenticate.');
                return;
            })
        });
    }

    whiteLabel() {

        if (this.underwriterHashId) {
            this.whiteLabelService.setUnderwriter(this.underwriterHashId).then((underwriter) => {
                const provideAgentInformationRequest = new ProvideAgentInformationRequest();
                const agentLegalEntityCriteria = new AgentLegalEntityCriteria();
                agentLegalEntityCriteria.legalEntity = underwriter.legalEntityIdentifier;
                provideAgentInformationRequest.criteria = agentLegalEntityCriteria;
                this.provideAgentInformationService.provideAgentInformation(provideAgentInformationRequest).then((response) => {
                    if (response && response.body){
                        const provideAgentInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideAgentInformationResponse) as ProvideAgentInformationResponse);
                        if (provideAgentInformationResponse.agents.length > 0) {
                            this.setAgent(provideAgentInformationResponse.agents[0]);
                            this.storeAgent();
                        }
                    }
                });
            });
        }

    }

    setBackOfficeAuthentication(authenticationAdvice: AuthenticationAdvice) {

        return new Promise<boolean>((resolve, reject) => {
            this.backOfficeUserAuthenticated = true;

            if (authenticationAdvice && authenticationAdvice.elaboration) {
                const elaboration = (authenticationAdvice.elaboration as TokenElaboration);
                const accessToken = (elaboration.accessToken as JsonWebToken);
                const refreshToken = (elaboration.refreshToken as JsonWebToken);
                this.tokenManagerService.setTokens(accessToken.token, refreshToken.token);
                this.tokenManagerService.setLoginPath('/console-login');
            }

            this.tokenManagerService.setBackOfficeUser(true);
            this.tokenListener = this.tokenManagerService.tokenValidityObservable.subscribe(res => {
                if (res === 0){
                    this.logout();
                }
            });

            let criteria: Criteria;
            if (sessionStorage.getItem('backofficeaccountnumber')) {
                criteria = new AccountCriteria();
                const accountIdentifier = new AccountNumberIdentifier();
                accountIdentifier.number = sessionStorage.getItem('backofficeaccountnumber');
                (criteria as AccountCriteria).accountNumberIdentifier = accountIdentifier;
            } else if (this.tokenManagerService.getAccountNumber()){
                criteria = new AccountCriteria();
                const accountIdentifier = new AccountNumberIdentifier();
                accountIdentifier.number = this.tokenManagerService.getAccountNumber();
                (criteria as AccountCriteria).accountNumberIdentifier = accountIdentifier;
            } else {
                const plainTextUser = new PlainTextUsername();
                plainTextUser.username = this.loggedInUsername;

                criteria = new AccountNaturalPersonCriteria();
                (criteria as AccountNaturalPersonCriteria).naturalPersonIdentifiers.push(plainTextUser);
            }

            const provideAccountInformationRequest = new PublicProvideAccountInformationRequest();
            provideAccountInformationRequest.criteria = criteria;

            let subscriptionLegalEntityIdentifier: LegalEntityIdentifier = null;

            this.backOfficeAccountInformationProvider.provideAccountInformation(provideAccountInformationRequest).then(accountResponse => {

                if (accountResponse && accountResponse.body) {
                    const provideAccountResponse =
                        (this.unMarshallerService.unMarshallFromJson(accountResponse.body, PublicProvideAccountInformationResponse) as PublicProvideAccountInformationResponse);

                    if (provideAccountResponse.accounts.length === 0) {
                        reject('No accounts found.');
                        return;
                    }

                    const account = provideAccountResponse.accounts[0];
                    this.account = account;
                    if (account.effectiveTo !== null && new Date(account.effectiveTo) < new Date()) {
                        reject('Account is disabled.');
                        return;
                    }

                    if (!this.account.verified) {
                        alert('Account not verified, please check your email for the verification link.');
                    }

                    sessionStorage.setItem('backofficeaccountnumber', this.account.number);

                    const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
                    provideLegalEntityInformationRequest.legalEntityIdentifiers.push(this.account.naturalPersonIdentifier);

                    return this.backOfficeLegalEntityInformationProviderService.provideBackOfficeLegalEntityInformation(provideLegalEntityInformationRequest);

                } else {
                    resolve(false);
                    return;
                }

            }).then(httpLegalEntityInformation => {

                if (httpLegalEntityInformation && httpLegalEntityInformation.body) {

                    const provideLegalEntityInformationResponse = (this.unMarshallerService.
                    unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities.length>0){

                        const subscriptionUsedByLegalEntityCriteria = new SubscriptionUsedByLegalEntityCriteria();
                        provideLegalEntityInformationResponse.legalEntities[0].legalEntityIdentifiers.forEach(identifier => {
                            if (identifier instanceof TpiIdentifier) {
                                subscriptionUsedByLegalEntityCriteria.tpiIdentifier = identifier;
                                subscriptionLegalEntityIdentifier = identifier;
                            }
                        });

                        if (provideLegalEntityInformationResponse.legalEntities[0] instanceof JudicialPerson) {
                            this.loggedInJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                        } else if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                            this.loggedInNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                        }

                        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
                        provideSubscriptionsRequest.criteria = subscriptionUsedByLegalEntityCriteria;

                        return this.subscriptionInformationProvider.provideSubscriptions(provideSubscriptionsRequest);
                    } else {
                        resolve(false);
                        return;
                    }

                } else {
                    resolve(false);
                    return;
                }
            }).then(subscriptionsResponse => {

                if (subscriptionsResponse && subscriptionsResponse.body) {

                    const provideSubscriptionsResponse = (this.unMarshallerService.unMarshallFromJson(subscriptionsResponse.body, ProvideSubscriptionsResponse) as ProvideSubscriptionsResponse);
                    this.subscriptions = provideSubscriptionsResponse.subscriptions;
                    this.backOfficeUserAuthenticated = true;

                    let brokerLegalEntity = null;

                    for (const subscription of this.subscriptions) {
                        if (subscription.serviceGroup.type === 'RETAIL' && Utils.getTpiIdentifier(this.loggedInNaturalPerson.legalEntityIdentifiers).tpiIdentifier != subscription.ownedBy.tpiIdentifier) {
                            brokerLegalEntity = subscription.ownedBy;
                            break;
                        }
                    }

                    if (brokerLegalEntity) {
                        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
                        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(brokerLegalEntity);
                        return this.backOfficeLegalEntityInformationProviderService.provideBackOfficeLegalEntityInformation(provideLegalEntityInformationRequest);
                    }

                    return;

                } else {
                    resolve(false);
                    return;
                }
            }).then((httpLegalEntityInformation) => {

                if (httpLegalEntityInformation && httpLegalEntityInformation.body) {

                    const provideLegalEntityInformationResponse = (this.unMarshallerService.unMarshallFromJson(httpLegalEntityInformation.body,
                        ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

                    if (provideLegalEntityInformationResponse.legalEntities.length>0) {
                        this.judicialPersonLinkedTo = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                    }

                }

                if (this.loggedInJudicialPerson) {

                    const agentLegalEntityCriteria = new AgentLegalEntityCriteria();
                    agentLegalEntityCriteria.legalEntity = Utils.getTpiIdentifier(this.getLinkedToLegalEntityIdentifiers());

                    const provideAgentInformationRequest = new ProvideAgentInformationRequest();
                    provideAgentInformationRequest.criteria = agentLegalEntityCriteria;
                    return this.agentInformationProviderService.provideAgentInformation(provideAgentInformationRequest);

                }
                else if(this.judicialPersonLinkedTo)
                {
                    const agentLegalEntityCriteria = new AgentLegalEntityCriteria();
                    agentLegalEntityCriteria.legalEntity = Utils.getTpiIdentifier(this.judicialPersonLinkedTo.legalEntityIdentifiers);

                    const provideAgentInformationRequest = new ProvideAgentInformationRequest();
                    provideAgentInformationRequest.criteria = agentLegalEntityCriteria;
                    return this.agentInformationProviderService.provideAgentInformation(provideAgentInformationRequest);
                }
            }).then((httpAgentInformationResponse) => {

                if (httpAgentInformationResponse && httpAgentInformationResponse.body) {

                    const provideAgentInformationResponse = (this.unMarshallerService.unMarshallFromJson(httpAgentInformationResponse.body,
                        ProvideAgentInformationResponse) as ProvideAgentInformationResponse);

                    if (provideAgentInformationResponse.agents.length > 0) {

                        this.agentService.agent = provideAgentInformationResponse.agents[0];

                        const agentLegalEntityIdentifier = new AgentLegalEntityIdentifier();
                        const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
                        legalEntityDescriptionIdentifier.tpiIdentifier = this.agentService.agent.legalEntityIdentifier as TpiIdentifier;
                        legalEntityDescriptionIdentifier.name = this.agentService.agent.name;
                        agentLegalEntityIdentifier.legalEntityDescriptionIdentifier = legalEntityDescriptionIdentifier;
                        agentLegalEntityIdentifier.name = this.agentService.agent.name;

                        this.agentService.agentIdentifier = agentLegalEntityIdentifier;
                        this.agentService.brokerIdentifier = new BrokerLegalEntityIdentifier(this.agentService.agent.name, legalEntityDescriptionIdentifier);

                    }
                }

                this.setServices(this.subscriptions);
                console.log("Could not retrieve agent");
                resolve(true);
                return;

            }).catch(err => {
                console.error('Authentication error :: ', err);
                RaygunErrorHandler.sendError(err);

                this.logout();
                reject('Could not authenticate.');
                return;
            });
        });
    }

    setActiveProxySubscription(subscriptions: Subscription[], confirmation: boolean = false) {
        this.proxyServices = new Map<string, boolean>();
        subscriptions.forEach(subscription => {
            this.activeProxy = subscription.ownedBy;
            const serviceGroup = subscription.serviceGroup;
            serviceGroup.linked.forEach(linked => {
                const service = (linked as ServiceNameTypeIdentifier).name;
                this.proxyServices.set(service.toUpperCase(), true);
            })
        });

        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
                    provideLegalEntityInformationRequest.legalEntityIdentifiers.push(this.activeProxy);

        this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest)
            .then(providePublicLegalEntityInformationResponse => {

            const provideLegalEntityInformationResponse = (this.unMarshallerService.
                unMarshallFromJson(providePublicLegalEntityInformationResponse.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

            if (provideLegalEntityInformationResponse.legalEntities.length > 0){
                this.activeProxyLegalEntity = (provideLegalEntityInformationResponse.legalEntities[0]);
            }

            this.populateActiveProxySubscriptionMaps();
        });

        if (this.naturalPersonSubscriptions.get(this.activeProxy.tpiIdentifier)) {
            this.activeProxySubscriptions = this.naturalPersonSubscriptions.get(this.activeProxy.tpiIdentifier);
        } else if (this.judicialPersonSubscriptions.get(this.activeProxy.tpiIdentifier)){
            this.activeProxySubscriptions = this.judicialPersonSubscriptions.get(this.activeProxy.tpiIdentifier);
        } else {
            console.error('Unable to load proxy subscriptions.');
            RaygunErrorHandler.sendError('Unable to load proxy subscriptions.');
        }

        sessionStorage.setItem('activeProxy', this.activeProxy.tpiIdentifier);
        this.proxiesUpdated.next(true);
    }

    loadEntitySubscriptions():Promise<boolean> {
        const subscriptionUsedBy = new SubscriptionUsedByLegalEntityCriteria();
        subscriptionUsedBy.tpiIdentifier = Utils.getTpiIdentifier(this.getLoggedInLegalEntityIdentifiers());
        const provideSubscriptionsRequest = new ProvideSubscriptionsRequest();
        provideSubscriptionsRequest.criteria = subscriptionUsedBy;

        return new Promise<boolean>(resolve => {
            this.subscriptionInformationProvider.provideSubscriptions(provideSubscriptionsRequest).then(subscriptionsResponse => {

                if (subscriptionsResponse && subscriptionsResponse.body) {

                    const provideSubscriptionsResponse = (this.unMarshallerService.unMarshallFromJson
                    (subscriptionsResponse.body, ProvideSubscriptionsResponse) as ProvideSubscriptionsResponse);

                    this.subscriptions = provideSubscriptionsResponse.subscriptions;
                    resolve(true);
                } else {
                    resolve(false);
                    return;
                }
            })
        })
    }

    private setServices(subscriptions: Subscription[]) {

        const loggedInUserIdentifier = Utils.getTpiIdentifier(this.getLoggedInLegalEntityIdentifiers());
        subscriptions.forEach(subscription => {

            if (this.compareEntityIdentifierService.compareIdentifiers(loggedInUserIdentifier, subscription.ownedBy) ||
            this.loggedInJudicialPerson || this.loggedInNaturalPerson ||
            this.compareEntityIdentifierService.compareIdentifiers(loggedInUserIdentifier, subscription.usedBy) ||
             subscription.serviceGroup.type.indexOf('ADMINISTRATOR')>-1) {
                const serviceGroup = subscription.serviceGroup;
                this.serviceGroups.set(serviceGroup.name, serviceGroup);
                serviceGroup.linked.forEach(linked => {
                    const service = (linked as ServiceNameTypeIdentifier).name;
                    this.services.set(service.toUpperCase(), true);
                });
            }
        });

        this.subscriptionListener.next(true);
    }

    private checkForProxyInStorage() {
        if (sessionStorage.getItem('activeProxy')) {
            const activeProxyTpiIdentifier = sessionStorage.getItem('activeProxy');
            if (this.naturalPersonSubscriptions.get(activeProxyTpiIdentifier)){
                return this.setActiveProxySubscription(this.naturalPersonSubscriptions.get(activeProxyTpiIdentifier).subscriptions);
            } else {
                if(this.judicialPersonSubscriptions.get(activeProxyTpiIdentifier)){
                    return this.setActiveProxySubscription(this.judicialPersonSubscriptions.get(activeProxyTpiIdentifier).subscriptions);
                }
            }
        }
    }

    reloadProxyInformation(): Promise<boolean> {
        let tempIdentifier: TpiIdentifier = null;
        if (this.activeProxy){
            tempIdentifier = new TpiIdentifier();
            tempIdentifier.tpiIdentifier = this.activeProxy.tpiIdentifier;
        } else if (sessionStorage.getItem('activeProxy')) {
            tempIdentifier = new TpiIdentifier();
            tempIdentifier.tpiIdentifier = sessionStorage.getItem('activeProxy');
        }

        this.clearActiveProxySubscription();
        if (tempIdentifier) {
            sessionStorage.setItem('activeProxy', tempIdentifier.tpiIdentifier);
        }

        this.naturalPersonSubscriptions = new Map<string, SubscriptionOwner>();
        this.judicialPersonSubscriptions = new Map<string, SubscriptionOwner>();
        return new Promise(resolve => {
            this.populateLoggedInProxySubscriptionMaps().then(() => {
                this.checkForProxyInStorage();
                this.proxiesUpdated.next(true);
                resolve(true);
            });
        })
    }

    private populateLoggedInProxySubscriptionMaps() {

        if(this.backOfficeUserAuthenticated)
        {
            const usedByCriteria = new SubscriptionUsedByLegalEntityCriteria();
            usedByCriteria.tpiIdentifier = Utils.getTpiIdentifier(this.getLoggedInLegalEntityIdentifiers());
            const provideSubscriptionOwners = new ProvideSubscriptionsOwnersBackOfficeRequest();
            provideSubscriptionOwners.criteria = usedByCriteria;

            return new Promise<void>(resolve => {
                this.subscriptionInformationProvider.provideSubscriptionsOwnersBackOffice(provideSubscriptionOwners).then(httpOwnersResponse => {

                    if (httpOwnersResponse && httpOwnersResponse.body) {
                        const subscriptionOwnersResponse =
                            (this.unMarshallerService.unMarshallFromJson(httpOwnersResponse.body, ProvideSubscriptionsOwnersBackOfficeResponse) as ProvideSubscriptionsOwnersBackOfficeResponse);

                        for (const npSubscription of subscriptionOwnersResponse.naturalPersons) {
                            this.naturalPersonSubscriptions.set(npSubscription.subscriptions[0].ownedBy.tpiIdentifier, npSubscription);
                        }

                        for (const jpSubscription of subscriptionOwnersResponse.judicialPersons) {
                            this.judicialPersonSubscriptions.set(jpSubscription.subscriptions[0].ownedBy.tpiIdentifier, jpSubscription);
                        }
                    }
                    resolve();
                    return;
                });
            });
        }
        else
        {
            const usedByCriteria = new SubscriptionUsedByLegalEntityCriteria();
            usedByCriteria.tpiIdentifier = Utils.getTpiIdentifier(this.getLoggedInLegalEntityIdentifiers());
            const provideSubscriptionOwners = new ProvideSubscriptionsOwnersRequest();
            provideSubscriptionOwners.criteria = usedByCriteria;

            return new Promise<void>(resolve => {
                this.subscriptionInformationProvider.provideSubscriptionsOwners(provideSubscriptionOwners).then(httpOwnersResponse => {

                    if (httpOwnersResponse && httpOwnersResponse.body) {
                        const subscriptionOwnersResponse =
                            (this.unMarshallerService.unMarshallFromJson(httpOwnersResponse.body, ProvideSubscriptionsOwnersResponse) as ProvideSubscriptionsOwnersResponse);

                        for (const npSubscription of subscriptionOwnersResponse.naturalPersons) {
                            this.naturalPersonSubscriptions.set(npSubscription.subscriptions[0].ownedBy.tpiIdentifier, npSubscription);
                        }

                        for (const jpSubscription of subscriptionOwnersResponse.judicialPersons) {
                            this.judicialPersonSubscriptions.set(jpSubscription.subscriptions[0].ownedBy.tpiIdentifier, jpSubscription);
                        }
                    }
                    resolve();
                    return;
                });
            });
        }

    }

    populateActiveProxySubscriptionMaps() {

        this.activeProxyNaturalPersonSubscriptions.clear();
        this.activeProxyJudicialPersonSubscriptions.clear();

        if(this.backOfficeUserAuthenticated)
        {
            const usedByCriteria = new SubscriptionUsedByLegalEntityCriteria();
            usedByCriteria.tpiIdentifier = Utils.getTpiIdentifier(this.getActiveProxyLegalEntityIdentifiers());
            const provideSubscriptionOwners = new ProvideSubscriptionsOwnersBackOfficeRequest();
            provideSubscriptionOwners.criteria = usedByCriteria;

            return new Promise<void>(resolve => {
                this.subscriptionInformationProvider.provideSubscriptionsOwnersBackOffice(provideSubscriptionOwners).then(httpOwnersResponse => {

                    if (httpOwnersResponse && httpOwnersResponse.body) {
                        const subscriptionOwnersResponse =
                            (this.unMarshallerService.unMarshallFromJson(httpOwnersResponse.body, ProvideSubscriptionsOwnersBackOfficeResponse) as ProvideSubscriptionsOwnersBackOfficeResponse);

                        for (const npSubscription of subscriptionOwnersResponse.naturalPersons) {
                            this.activeProxyNaturalPersonSubscriptions.set(npSubscription.subscriptions[0].ownedBy.tpiIdentifier, npSubscription);
                        }

                        for (const jpSubscription of subscriptionOwnersResponse.judicialPersons) {
                            this.activeProxyJudicialPersonSubscriptions.set(jpSubscription.subscriptions[0].ownedBy.tpiIdentifier, jpSubscription);
                        }
                    }
                    resolve();
                    return;
                });
            });
        }
        else
        {
            const usedByCriteria = new SubscriptionUsedByLegalEntityCriteria();

            usedByCriteria.tpiIdentifier = Utils.getTpiIdentifier(this.getActiveProxyLegalEntityIdentifiers());
            const provideSubscriptionOwners = new ProvideSubscriptionsOwnersRequest();
            provideSubscriptionOwners.criteria = usedByCriteria;

            return new Promise<void>(resolve => {
                this.subscriptionInformationProvider.provideSubscriptionsOwners(provideSubscriptionOwners).then(httpOwnersResponse => {

                    if (httpOwnersResponse && httpOwnersResponse.body) {
                        const subscriptionOwnersResponse =
                            (this.unMarshallerService.unMarshallFromJson(httpOwnersResponse.body, ProvideSubscriptionsOwnersResponse) as ProvideSubscriptionsOwnersResponse);

                        for (const npSubscription of subscriptionOwnersResponse.naturalPersons) {
                            this.activeProxyNaturalPersonSubscriptions.set(npSubscription.subscriptions[0].ownedBy.tpiIdentifier, npSubscription);
                        }

                        for (const jpSubscription of subscriptionOwnersResponse.judicialPersons) {
                            this.activeProxyJudicialPersonSubscriptions.set(jpSubscription.subscriptions[0].ownedBy.tpiIdentifier, jpSubscription);
                        }
                    }
                    resolve();
                    return;
                });
            });
        }

    }

    storeAgent() {
        sessionStorage.setItem(AuthenticationService.STORAGE_AGENT, JSON.stringify(this.agent));
    }

    isAuthorised(routeServices: string[]): boolean {
        let result = false;
        routeServices.forEach(service => {
            if (this.activeProxy && this.proxyServices) {
                if (this.proxyServices.get(service.toUpperCase())) {
                    result = true;
                    return;
                }
            } else if (this.services.get(service.toUpperCase())) {
                result = true;
                return;
            }
        });
        return result;
    }

    isPublicUser(): boolean {
        return this.publicUserAuthenticated;
    }

    isBackOfficeUser(): boolean {
        if (sessionStorage.getItem("sessionyesnopopupbutton") === 'SKIP') {
            return false;
        }
        return this.backOfficeUserAuthenticated;
    }

    getLoggedInNaturalPerson(): NaturalPerson {
        return this.loggedInNaturalPerson;
    }

    getLoginSubscriptions() {
        return this.naturalPersonSubscriptions;
    }

    getProxies(): SubscriptionOwner[] {
        if (this.naturalPersonSubscriptions.size < 1 && this.judicialPersonSubscriptions.size < 1) {
            return null;
        }

        const proxyList: SubscriptionOwner[] = [];
        for(const key of this.naturalPersonSubscriptions.keys()){
            proxyList.push(this.naturalPersonSubscriptions.get(key));
        }
        for(const key of this.judicialPersonSubscriptions.keys()){
            proxyList.push(this.judicialPersonSubscriptions.get(key));
        }
        return proxyList;
    }

    getJudicialPersonSubscriptions() {
        return this.judicialPersonSubscriptions;
    }

    getProxyServices() {
        return this.proxyServices;
    }

    getLegalEntityDescriptionIdentifier(): LegalEntityDescriptionIdentifier {
        let identifier = Utils.getLegalEntityDescriptionIdentifier(this.getLoggedInLegalEntityIdentifiers());

        if (!identifier) {
            identifier = new LegalEntityDescriptionIdentifier();
            identifier.tpiIdentifier = Utils.getTpiIdentifier(this.getLoggedInLegalEntityIdentifiers());
            if (this.loggedInNaturalPerson) {
                identifier.name = this.loggedInNaturalPerson.firstNames;
                identifier.surname = this.loggedInNaturalPerson.surname;
            } else if (this.loggedInJudicialPerson) {
                identifier.name = this.loggedInJudicialPerson.name;
            } else {
                identifier = null;
            }
        }

        return identifier;
    }

    getLoggedInJudicialPerson(): JudicialPerson {
        return this.loggedInJudicialPerson;
    }

    getLoggedInNaturalPersonLegalEntityIdentifiers(): LegalEntityIdentifier[] {
        if (this.loggedInNaturalPerson){
            return this.loggedInNaturalPerson.legalEntityIdentifiers;
        }
        return null;
    }

    getLoggedInLegalEntityIdentifiers(): LegalEntityIdentifier[] {

        if (this.activeProxy) {
            return [this.activeProxy];
        } else if (this.loggedInNaturalPerson) {
            return this.loggedInNaturalPerson.legalEntityIdentifiers;
        } else if (this.loggedInJudicialPerson) {
            return this.loggedInJudicialPerson.legalEntityIdentifiers;
        }

        return null;
    }

    getLinkedToLegalEntityIdentifiers(): LegalEntityIdentifier[] {

        if (this.judicialPersonLinkedTo != null) {
            return this.judicialPersonLinkedTo.legalEntityIdentifiers;
        }

        return null;
    }

    getLinkedToJudicialPerson(): JudicialPerson {
        return this.judicialPersonLinkedTo;
    }

    setLoggedInUsername(username: string) {
        this.loggedInUsername = username;
    }

    getLoggedInUsername(): string {
        if (this.account.naturalPersonIdentifier instanceof NationalIdentityNumber) {
            return (this.account.naturalPersonIdentifier as NationalIdentityNumber).number;
        }

        for(const identifier of this.getLoggedInLegalEntityIdentifiers()) {
            if (identifier instanceof NationalIdentityNumber) {
                return (identifier as NationalIdentityNumber).number;
            }
        }
    }

    getLoggedInName() : string {
        if (this.loggedInNaturalPerson) {
            return this.loggedInNaturalPerson.firstNames;
        } else if (this.loggedInJudicialPerson) {
            return this.loggedInJudicialPerson.name;
        }
        return null;
    }

    getAccount(): Account {
        return this.account;
    }

    getActiveProxy(): TpiIdentifier {
        return this.activeProxy;
    }

    getActiveProxyLegalEntityIdentifiers(): LegalEntityIdentifier[] {
        if(this.activeProxyLegalEntity === null)
            return [];
        else
            return this.activeProxyLegalEntity.legalEntityIdentifiers;
    }

    getActiveProxySubscriptions(): SubscriptionOwner {
        return this.activeProxySubscriptions;
    }

    getActiveProxyNaturalPersonSubscriptions() {
        return this.activeProxyNaturalPersonSubscriptions;
    }

    getActiveProxyJudicialPersonSubscriptions() {
        return this.activeProxyJudicialPersonSubscriptions;
    }

    isProxyJudicialPerson(): boolean {
        if(this.activeProxyLegalEntity instanceof JudicialPerson)
            return (this.judicialPersonSubscriptions && this.activeProxy &&
                this.judicialPersonSubscriptions.get(this.activeProxy.tpiIdentifier) !== null);
    }

    getActiveAccount(): Account {
        return this.account;
    }

    getSubscriptions(): Subscription[] {
        return this.subscriptions;
    }

    getServiceGroups(): Map<string, ServiceGroup> {
        return this.serviceGroups;
    }

    getAgent(): Agent {
        return this.agent;
    }

    getJudicialPersonName(): string {
        if (this.loggedInJudicialPerson) {
            return this.loggedInJudicialPerson.name;
        } else if (this.agent) {
            return this.agent.name;
        } else {
            return null;
        }
    }

    getCurrentSubscriptionDetails() {
        if (this.getActiveProxySubscriptions()) {
            return {
                name: this.getActiveProxySubscriptions().name,
                surname: this.activeProxySubscriptions.surname
            }
        } else if (this.loggedInNaturalPerson){
            return {
                name: this.loggedInNaturalPerson.firstNames,
                surname: this.loggedInNaturalPerson.surname
            }
        } else if (this.loggedInJudicialPerson) {
            return {
                name: this.loggedInJudicialPerson.name,
                surname: null
            }
        } else {
            return null;
        }
    }

    setJudicialSubscriptionByIdentifier(legalEntityIdentifiers: LegalEntityIdentifier[]) {
        this.setActiveProxySubscription(this.judicialPersonSubscriptions.get(Utils.getTpiIdentifier(legalEntityIdentifiers).tpiIdentifier)
            .subscriptions);
    }

    isAuthenticated(): boolean {
        if (sessionStorage.getItem("sessionyesnopopupbutton") === 'SKIP') {
            return false;
        }
        return this.authenticated;
    }

    getServices(): Map<string, boolean> {
        return this.services;
    }

    clearActiveProxySubscription() {
        this.activeProxyLegalEntity = null;
        this.activeProxy = null;
        this.activeProxySubscriptions = null;
        this.proxyServices = null;
        sessionStorage.removeItem('activeProxy');
    }

    logout() {
        this.account = null;
        this.loggedInNaturalPerson = null;
        this.loggedInJudicialPerson = null;
        this.subscriptions = null;
        this.activeProxyLegalEntity = null;
        this.services = new Map<string, boolean>();
        this.proxyServices = null;
        this.activeProxy = null;
        this.activeProxySubscriptions = null;
        this.authenticated = false;
        this.publicUserAuthenticated = false;
        this.backOfficeUserAuthenticated = false;
        this.naturalPersonSubscriptions = new Map<string, SubscriptionOwner>();
        this.judicialPersonSubscriptions = new Map<string, SubscriptionOwner>();
        this.agent = null;
        this.agentIdentifier = null;
        this.tokenManagerService.clearAuthenticationData();
        this.quickStartService.reset();
        if (!this.agentService.agentLogin) {
            this.agentService.agent = null;
            this.agentService.agentIdentifier = null;
            this.agentService.brokerIdentifier = null;
            this.agentService.agentLogin = false;
        }
        sessionStorage.removeItem('accountnumber');
        sessionStorage.removeItem('backofficeaccountnumber');
        sessionStorage.removeItem('activeProxy');
        this.subscriptionListener.next(null);
        this.proxiesUpdated.next(null);
        this.tokenManagerService.setBackOfficeUser(false);
        if (this.tokenListener) {
            this.tokenListener.unsubscribe();
            this.tokenListener = null;
        }
    }

    validateSession(): boolean {

        const tokenExpiration = sessionStorage.getItem("tokenExpiration");
        const accessToken = sessionStorage.getItem('accessToken');

        if (accessToken && tokenExpiration) {
            const now = new Date;
            const tokenExpiry = new Date(tokenExpiration);
            if (tokenExpiry.getTime() >= now.getTime()) {
                const backOfficeAccountNumber = sessionStorage.getItem('backofficeaccountnumber');
                const publicAccountNumber = sessionStorage.getItem('accountnumber');
                if (backOfficeAccountNumber) {
                    this.backOfficeUserAuthenticated = true;
                } else if (publicAccountNumber) {
                    this.publicUserAuthenticated = true;
                } else {
                    this.logout();
                }
            } else {
                this.logout();
            }
        } else {
            this.logout();
        }

        return true

    }

    applySession(): Promise<boolean> {

        this.validateSession()

        return new Promise((resolve, reject) => {
            const sessionRefreshToken = sessionStorage.getItem('refreshToken');
            const sessionAccessToken = sessionStorage.getItem('accessToken');
            const backOfficeAccountNumber = sessionStorage.getItem('backofficeaccountnumber');
            const publicAccountNumber = sessionStorage.getItem('accountnumber');

            if (sessionRefreshToken && sessionAccessToken) {
                const accessToken: JsonWebToken = {
                    token: sessionAccessToken
                };
                const refreshToken: JsonWebToken = {
                    token: sessionRefreshToken
                };
                const elaboration: TokenElaboration = {
                    accessToken,
                    refreshToken
                }
                const authAdvice: AuthenticationAdvice = {
                    authentic: true,
                    elaboration
                };
                if(backOfficeAccountNumber){
                    this.setBackOfficeAuthentication(authAdvice).then((response) => {
                        resolve(true);
                    });
                }else if(publicAccountNumber){
                    this.setAuthentication(authAdvice).then((response) => {
                        resolve(true);
                    });
                }

            }else{
                resolve(true);
            }
        })
    }
}
