import {Location, LocationStrategy} from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, Router} from '@angular/router'
import {
    Answers,
    FinaliseClearCartRequest,
    CountryCheckoutItemGrouping,
    CountryDescriptionIdentifier,
    CountryIdentifier,
    CountryRegistrationConfiguration,
    CountryRegistrationConfigurationInformation,
    CountryStartDateConfiguration,
    CreateIdentifierRequest,
    CreateOrderRequest,
    CreateOrderResponse,
    CreateProposalRequest,
    CreateProposalResponse,
    DocumentType,
    FinaliseCancelOrderRequest,
    IdentifierType,
    InitialiseCheckoutRequest,
    InitialiseCheckoutResponse,
    InitialiseCountryCheckoutRequest,
    InitialiseCountryCheckoutResponse,
    InsuranceCategory,
    InsurancePolicyVehicleCategoryCriteria,
    Item,
    JudicialPersonCountryRegistrationConfigurationInformation,
    LegalEntity,
    LegalEntityProviderCriteria,
    NaturalPersonCountryRegistrationConfigurationInformation,
    Order,
    OrderStatus,
    OrderSummary,
    PdfDocument,
    PickUpPoint,
    ProposalForm,
    ProposalRecordSummary,
    ProposalRecordSummaryLegalEntityCriteria,
    ProvideProposalRecordSummaryRequest,
    ProvideProposalRecordSummaryResponse,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    ProvideSuitableInsurancePolicyRequest,
    ProvideSuitableInsurancePolicyResponse,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    RenderedDocumentIdCriteria,
    RenderedDocumentType,
    RenderedDocumentTypeIdCriteria,
    RequiredJudicialPersonCountryRegistrationInformation,
    RequiredNaturalPersonCountryRegistrationInformation,
    RetailProfile,
    RetailProfileIdentifier,
    RetailProfileOwnerIdentifier,
    ScannedDocumentIdentifier,
    ScannedDocumentTypeDescriptionIdentifier,
    ThirdPartyInsurancePolicyCartItem,
    ThirdPartyInsurancePolicyOrderItem,
    UnderwriterCheckoutItemGrouping,
    UnMarshallerService,
    FinaliseUpdateItemRequest,
} from '@magnabc/tpi'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {GoogleAnalyticsService} from 'ngx-google-analytics'
import {ActiveToast} from 'ngx-toastr'

import {environment} from '../../../../../environments/environment'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {AgentManagerService} from '../../../../app.services/managers/agent-manager/agent-manager.service'
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import {
    QueryLegalEntityInformationManagerService,
} from '../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service'
import {
    IdentifierManagerService
} from '../../../../http.services/identification/identifier-manager/identifier-manager.service'
import {
    RenderedConceptualDocumentProviderService,
} from '../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service'
import {
    CheckoutInitialisationManagerService,
} from '../../../../http.services/retail/checkout/checkout-initialisation-manager/checkout-initialisation-manager.service'
import {OrderManagerService,} from '../../../../http.services/retail/checkout/checkout-manager/order-manager.service'
import {
    CountryCheckoutInitialisationManagerService,
} from '../../../../http.services/malawi/retail/checkout/country-checkout-initialisation-manager/country-checkout-initialisation-manager.service'
import {
    ProposalManagerService,
} from '../../../../http.services/retail/checkout/proposal-manager/proposal-manager.service'
import {
    QuickTransactManagerService,
} from '../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service'
import {
    RetailProfileFinalisationManagerService,
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
    MalawiCountryCheckoutManagerComponent,
} from '../../../../view.components/malawi/retail/checkout/malawi-country-checkout-manager/malawi-country-checkout-manager.component'
import {
    CaptureProposalComponent,
} from '../../../../view.components/retail/checkout/capture-proposal/capture-proposal.component'
import {CheckoutEnum} from '../../../../view.components/retail/checkout/checkout-enum'
import {
    CountryCheckoutRetrieverComponent,
} from '../../../../view.components/retail/checkout/country-checkout-retriever/country-checkout-retriever.component'
import {OrderSummaryComponent} from '../../../../view.components/retail/checkout/order-summary/order-summary.component'
import {
    PaymentMethodComponent
} from '../../../../view.components/retail/checkout/payment-method/payment-method.component'
import {DocumentRenderComponent} from '../../../../view.components/shared/document-render/document-render.component'
import {FormComponent} from '../../../../view.components/shared/form/form.component'
import {IStepComponent} from '../../../../view.components/shared/guards/step-guard'
import {ToastComponent} from '../../../../view.components/shared/toast/toast.component'
import {Utils} from '../../../../view.components/shared/Utils/Utils'
import {PDFDisplayModel, PDFPrintModel, PrintService} from '../../../../app.services/common/printing/print.service'
import {
    InsurancePolicyProviderService
} from '../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {
    OrderInformationProviderService
} from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service'
import {v4 as uuidv4} from 'uuid'
import {FormGroup} from '@angular/forms'
import {Observable} from 'rxjs'
import {MatTab, MatTabGroup} from '@angular/material/tabs'
import {LegalEntityDetails} from '@magnabc/tpi/src/entity/models/legal-entity-details.model'
import {
    OrderFinalisationManagerService
} from '../../../../http.services/retail/checkout/order-finalisation-manager/order-finalisation-manager.service'
import { PayfastPaymentManagerService } from '../../../../app.services/managers/payafast-payament-manager/payfast-payment-manager.service'
import { ProposalInformationProviderService } from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service'
import * as moment from "moment";

declare const jQuery: any;
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout-page.component.html',
    styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy, IStepComponent {

    declarationAccepted = false;
    backPressed: boolean;
    quickTransact = false;

    orderStatus = OrderStatus;
    steps: any = [];
    currentStep = 0;
    breadcrumbStep = 0;

    retailProfile: RetailProfile;
    orderCreated = false;
    numberOfPages: number;
    showQuestions = false;
    countryRegistrationConfigurationInformationProvided = false;
    numberOfComprehensiveItems = 0
    temp: OrderSummary[] = [];
    rows: OrderSummary[] = [];
    proposalSummary: ProposalRecordSummary[] = [];
    proposalTemp: ProposalRecordSummary[] = [];
    latestProposal: ProposalRecordSummary[] = [];
    @ViewChild(MatTabGroup, { read: MatTabGroup }) public matGroup: MatTabGroup
    @ViewChildren(MatTab, { read: MatTab }) public tabNodes: QueryList<MatTab>;


    @ViewChild(OrderSummaryComponent) orderSummaryComponent: OrderSummaryComponent;
    @ViewChild(CountryCheckoutRetrieverComponent) countryCheckoutRetrieverComponent: CountryCheckoutRetrieverComponent;
    @ViewChild(PaymentMethodComponent) paymentMethodComponent: PaymentMethodComponent;
    @ViewChild(DocumentRenderComponent) invoiceComponent: DocumentRenderComponent;
    @ViewChild(CaptureProposalComponent) captureProposalComponent: CaptureProposalComponent;
    @ViewChild(MalawiCountryCheckoutManagerComponent) malawiCountryCheckoutManagerComponent: MalawiCountryCheckoutManagerComponent;

    activeToast: ActiveToast<any>;
    legalEntity: LegalEntity;
    countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    pickUpPoints: PickUpPoint[] = [];
    countryRegistrationConfigurationList: CountryRegistrationConfiguration[] = [];
    judicialPerson: boolean;
    naturalPerson: boolean;
    countryMap: { [id: string]: CountryIdentifier } = {};
    countries: CountryIdentifier[] = [];
    retailProfileListener: any;
    answers: Answers[] = [];
    proposalForm: ProposalForm;
    scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = []
    scannedDocumentIdentifierMap: { [id: string]: ScannedDocumentIdentifier } = {};

    insuranceCategory: InsuranceCategory = InsuranceCategory.THIRDPARTY;
    insuranceCategories = InsuranceCategory;
    proposalIds: string[] = [];
    orderIds: string[] = [];
    invalidProposal = false;
    previewDocument = false;
    documentToPreview: any;
    currentProposalId: string;
    proposalPages = 1;
    currentOrderId: string;
    showOnlyOrderPanel = true;
    showOnlyProposalPanel = true;

    underwriterCheckoutItemGroupings: UnderwriterCheckoutItemGrouping[] = [];
    countryRegistrationConfigurations: CountryRegistrationConfiguration[];
    countryStartDateConfiguration: CountryStartDateConfiguration;
    countryCheckoutItemGroupings: CountryCheckoutItemGrouping[] = [];

    states = CheckoutEnum;
    currentState: CheckoutEnum = CheckoutEnum.VIEW_CHECKOUT_SUMMARY;

    thirdPartyInsurance = false
    comprehensiveInsurance = false
    extensionInsurance = false
    yellowCardInsurance = false
    selectedTab = 0;
    order: Order;
    ycOrder: Order[];
    thirdPartyOrder: Order[];
    thirdPartyOrderCountryGrouping: Map<string, Order[]> = new Map<string, Order[]>();
    expandPanel: Observable<any>
    selectedTabIndex = 0;
    checkFirstLoad: boolean
    backFromPayment: string;
    orderNumber: string
    tempOrder: Order[];

    formCaptureShipment: FormGroup;
    legalEntityDetails: LegalEntityDetails;
    paymentStatus: string;
    payfastOrderCancelled: string;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private googleAnalyticsService: GoogleAnalyticsService,
                private orderFinalisationManagerService: OrderFinalisationManagerService,
                public RetailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                public quickstartService: QuickTransactManagerService,
                private router: Router,
                private toastr: ErrorToastService,
                private agentService: AgentManagerService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                private location: Location,
                private route: ActivatedRoute,
                private quickStartService: QuickTransactManagerService,
                private authenticationService: AuthenticationService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                locationStrategy: LocationStrategy,
                private unMarshallerService: UnMarshallerService,
                private checkoutInitialisationManagerService: CheckoutInitialisationManagerService,
                private countryCheckoutInitialisationService: CountryCheckoutInitialisationManagerService,
                private identifierManagerService: IdentifierManagerService,
                private orderManagerService: OrderManagerService,
                private errorToastService: ErrorToastService,
                private proposalManagerService: ProposalManagerService,
                private printService: PrintService,
                private insurancePolicyProviderService: InsurancePolicyProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService,
                private changeDetectorRef: ChangeDetectorRef,
                private payfastPaymentManager: PayfastPaymentManagerService,
                private proposalInformationProviderService: ProposalInformationProviderService
    ) {
        super(translate, translateParser);

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.declarationAccepted = false;

    }
    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onCurrentStateChange(state: CheckoutEnum) {
        this.currentState = state; CheckoutEnum
        this.breadcrumbStep++;
    }

    onDeactivate(): boolean {
        if (this.currentStep > 0 && this.backPressed) {
            this.onBack(null);
            this.backPressed = false;
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.startLoad()
        this.insuranceCategory = InsuranceCategory.THIRDPARTY;
        this.steps = [];
        setTimeout(() => {

            this.translate.get("CHECKOUT.TITLE_SUMMARY").subscribe((response) => {
                this.steps.push({ id: 0, name: response, icon: "fa-list-ul", borderColor: "#1592E6" });
            });
            this.translate.get("CHECKOUT.TITLE_KYC").subscribe((response) => {
                this.steps.push({ id: 1, name: response, icon: "fa-address-card-o", borderColor: "#4472c7" });
            });
            this.translate.get("CHECKOUT.TITLE_CONFIRMATION").subscribe((response) => {
                this.steps.push({ id: 2, name: response, icon: "fa-check", borderColor: "#3f5baa" });
            });

            this.setTitle();

        });

        this.route.queryParams.forEach((params) => {
            this.quickTransact = params['quickTransact']
        })

        this.route.queryParams.forEach((params: any) => {
            this.backFromPayment = params.backToOrderSummary
            this.orderNumber = params.orderNumber
            this.paymentStatus = params.status
            this.payfastOrderCancelled = params.cancelled
        })

        this.getRetailProfile()
    }

    checkParams() {
        this.onCurrentStateChange(this.states.CONFIRMATION)
        const ordersList = JSON.parse(localStorage.getItem('orderIds'));
        const proposalId = JSON.parse(localStorage.getItem('proposalId'));
        ordersList.forEach((element: any) => {
            if(this.payfastOrderCancelled === 'true'){
                // After payfast has been finalized, get updated order with updated status.
                this.getCreatedOrder(element)
            }else{
                if (element === this.orderNumber) {
                    this.payfastPaymentManager.finalizePayfastPayment(element).finally(() => {
                        // After payfast has been finalized, get updated order with updated status.
                        this.getCreatedOrder(element)
                    });
                } else {
                    this.getCreatedOrder(element)
                }
            }
        });

        if (proposalId && proposalId.length > 0) {
            this.proposalIds.push(proposalId);
            this.provideProposal();
        }
        this.declarationAccepted = true;
    }

    getRetailProfile() {
        this.RetailProfileFinalisationManagerService.refreshRetailProfile().then((retailProfile) => {
            if (this.route.routeConfig.path.split('?')[0] === "retail/checkout") {
                this.RetailProfileFinalisationManagerService.checkoutPage = true;
            }
            if (this.quickTransact) {
                this.retailProfile = this.quickStartService.retailProfile;
            } else {
                this.retailProfile = retailProfile;
            }
            this.getLegalEntity()
            if (this.backFromPayment) {
                this.checkParams()
            } else {
                this.initialiseCheckout()
            }
        })
    }

    initialiseCheckout() {
        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
        const initialiseCheckoutRequest = new InitialiseCheckoutRequest()
        initialiseCheckoutRequest.retailProfileIdentifier = retailProfileOwnerIdentifier
        this.underwriterCheckoutItemGroupings = [];

        this.startLoad()
        this.checkoutInitialisationManagerService.initialiseCheckout(initialiseCheckoutRequest).then((data) => {
            const response = this.unMarshallerService.unMarshallFromJson(data.body, InitialiseCheckoutResponse) as InitialiseCheckoutResponse

            this.countryCheckoutItemGroupings = response.countryCheckoutItemGroupings;
            response.countryCheckoutItemGroupings.forEach(element => {
                this.underwriterCheckoutItemGroupings.push(...element.underwriterCheckoutItemGroupings)
            });
            this.countryRegistrationConfigurations = response.countryRegistrationConfigurations
            this.countryStartDateConfiguration = response.countryStartDateConfiguration

            const countries = []
            for (const grouping of response.countryCheckoutItemGroupings) {
                countries.push(grouping.countryIdentifier)
            }
            this.countries = countries

            this.initialiseCountryCheckout()
        }).catch(() => {
            this.router.navigate(['retail/browse/select-insurance'])
        });

    }

    initialiseCountryCheckout() {
        this.startLoad();
        this.proposalForm = null;
        const countryCheckoutItemGrouping = new CountryCheckoutItemGrouping()
        for (const grouping of this.countries) {
            countryCheckoutItemGrouping.countryIdentifier = grouping
            countryCheckoutItemGrouping.underwriterCheckoutItemGroupings = this.underwriterCheckoutItemGroupings

            delete countryCheckoutItemGrouping["@class"];
            const initialiseCountryCheckoutRequest = new InitialiseCountryCheckoutRequest()
            initialiseCountryCheckoutRequest.legalEntityIdentifier = Utils.getTpiIdentifier(this.legalEntity.legalEntityIdentifiers);
            initialiseCountryCheckoutRequest.countryCheckoutItemGrouping = countryCheckoutItemGrouping

            this.countryCheckoutInitialisationService.initialiseCountryCheckout(initialiseCountryCheckoutRequest).then((data) => {
                const response = this.unMarshallerService.unMarshallFromJson(data.body, InitialiseCountryCheckoutResponse) as InitialiseCountryCheckoutResponse


                if (response.pickupPoints && response.pickupPoints.length > 0) {
                    this.pickUpPoints = response.pickupPoints;
                }

                if(response.legalEntityDetails){
                    this.legalEntityDetails = response.legalEntityDetails;
                }

                if ((response.countryRegistrationConfigurationInformations as any).length > 0) {
                    this.countryRegistrationConfigurationInformation = response.countryRegistrationConfigurationInformations[0]
                    this.countryRegistrationConfigurationInformationProvided = true;
                }

                this.naturalPerson = response.naturalPerson
                this.judicialPerson = response.judicialPerson
                this.proposalForm = !response.proposalForm ? this.proposalForm : response.proposalForm;

                this.createScannedDocuments()
                localStorage.removeItem('checkOutPage');
                localStorage.removeItem('orderIds');
                localStorage.removeItem('proposalId');
                this.stopLoad()
            }).catch((error) => {
                this.activeToast = this.toastr.success(error, 'Error', {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true,
                    closeButton: true
                });
            });
        }
    }

    createScannedDocuments() {
        this.scannedDocumentIdentifiers = []
        this.scannedDocumentIdentifierMap = {}

        if (!this.countryRegistrationConfigurationInformation) {
            return;
        }

        if (this.countryRegistrationConfigurationInformation.proofOfAddressIdentifier) {
            const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
            scannedDocumentIdentifier.documentIdentifier = this.countryRegistrationConfigurationInformation.proofOfAddressIdentifier
            scannedDocumentIdentifier.documentTypeDescription = new DocumentType()
            scannedDocumentIdentifier.documentTypeDescription.description = 'Proof of Address'
            this.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
            this.scannedDocumentIdentifierMap[scannedDocumentIdentifier.documentTypeDescription.description] = scannedDocumentIdentifier
        }
        if (this.judicialPerson) {
            if ((this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier) {
                const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
                scannedDocumentIdentifier.documentIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).proofOfExistenceIdentifier
                scannedDocumentIdentifier.documentTypeDescription = new DocumentType()
                scannedDocumentIdentifier.documentTypeDescription.description = 'Proof of Existence'
                this.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
                this.scannedDocumentIdentifierMap[scannedDocumentIdentifier.documentTypeDescription.description] = scannedDocumentIdentifier
            }
            if ((this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier) {
                const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
                scannedDocumentIdentifier.documentIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).ciproDocumentIdentifier
                scannedDocumentIdentifier.documentTypeDescription = new DocumentType()
                scannedDocumentIdentifier.documentTypeDescription.description = 'Company Registration'
                this.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
                this.scannedDocumentIdentifierMap[scannedDocumentIdentifier.documentTypeDescription.description] = scannedDocumentIdentifier
            }
            if ((this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier) {
                const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
                scannedDocumentIdentifier.documentIdentifier = (this.countryRegistrationConfigurationInformation as JudicialPersonCountryRegistrationConfigurationInformation).companyLetterHeadIdentifier
                scannedDocumentIdentifier.documentTypeDescription = new DocumentType()
                scannedDocumentIdentifier.documentTypeDescription.description = 'Letterhead'
                this.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
                this.scannedDocumentIdentifierMap[scannedDocumentIdentifier.documentTypeDescription.description] = scannedDocumentIdentifier
            }
        } else {
            if ((this.countryRegistrationConfigurationInformation as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification) {
                const scannedDocumentIdentifier = new ScannedDocumentTypeDescriptionIdentifier()
                scannedDocumentIdentifier.documentIdentifier = (this.countryRegistrationConfigurationInformation as NaturalPersonCountryRegistrationConfigurationInformation).proofOfIdentification
                scannedDocumentIdentifier.documentTypeDescription = new DocumentType()
                scannedDocumentIdentifier.documentTypeDescription.description = 'National ID / Passport'
                this.scannedDocumentIdentifiers.push(scannedDocumentIdentifier)
                this.scannedDocumentIdentifierMap[scannedDocumentIdentifier.documentTypeDescription.description] = scannedDocumentIdentifier
            }
        }
    }

    setTitle() {
        let title = `${this.steps[this.currentStep].name} - Checkout - ${this.runtimeConfigurationService.title}`;
        this.title.setTitle(title);
        this.googleAnalyticsService.pageView(this.route.pathFromRoot.join('/'), title);
    }

    ngOnDestroy() { }

    onOrderSummaryChanged(_) {
        this.getRetailProfile()
    }

    getLegalEntity(): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        legalEntityProviderCriteria.type = "INTERNAL_PUBLIC";

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse);
                if (response.legalEntities.length > 0) {
                    this.legalEntity = response.legalEntities[0];
                }
            }
        });

    }

    hasSameName(listnames, name: string): Boolean {
        for (const listname of listnames) {
            if (listname === name) {
                return true
            } else {
                return false
            }
        }
    }

    updateOrderItems(items: Item[]): Promise<any> {

        return new Promise<any>((resolve, reject) => {

            const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier();
            retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
            const updateItemsRequest = new FinaliseUpdateItemRequest();
            updateItemsRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;
            updateItemsRequest.items = [];

            for (var i = 0; i < items.length; i++) {
                (items[i] as any).vehicle['@class'] = 'za.co.magnabc.tpi.vehicle.Vehicle';
                updateItemsRequest.items.push(items[i]);
            }

            this.RetailProfileFinalisationManagerService.finaliseUpdateItem(updateItemsRequest).subscribe((response) => {
                resolve({});
            }, (error) => {
                this.stopLoad();
                reject(error);
            });

        });

    }

    createProposal(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            const createProposalRequest = new CreateProposalRequest()
            const provideSuitableInsurancePolicyRequest = new ProvideSuitableInsurancePolicyRequest();
            const insurancePolicyVehicleCategoryCriteria = new InsurancePolicyVehicleCategoryCriteria();

            const createItemIdentifierRequest = new CreateIdentifierRequest()
            createItemIdentifierRequest.type = IdentifierType.ORDER_ITEM_NUMBER;

            for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
                for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                    if (checkoutDatedItemGrouping.comprehensiveInsurancePolicyCartItems.length > 0) {
                        for (const comprehensiveInsurancePolicyCartItem of checkoutDatedItemGrouping.comprehensiveInsurancePolicyCartItems) {
                            this.numberOfComprehensiveItems++;
                            createProposalRequest.comprehensiveInsurancePolicyItems.push(comprehensiveInsurancePolicyCartItem);

                            if (comprehensiveInsurancePolicyCartItem.extensionExpiryDate) {
                                insurancePolicyVehicleCategoryCriteria.expiryDate = comprehensiveInsurancePolicyCartItem.extensionExpiryDate;
                            } else {
                                insurancePolicyVehicleCategoryCriteria.expiryDate = comprehensiveInsurancePolicyCartItem.expiryDate;
                            }

                            insurancePolicyVehicleCategoryCriteria.insuranceVehicleCategoryDescription = comprehensiveInsurancePolicyCartItem.comprehensiveInsuranceProductCartItem.insuranceVehicleCategoryDescription;
                            insurancePolicyVehicleCategoryCriteria.underwriterIdentifier = comprehensiveInsurancePolicyCartItem.comprehensiveInsuranceProductCartItem.underwriterIdentifier;
                            insurancePolicyVehicleCategoryCriteria.legalEntityIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;

                            if (insurancePolicyVehicleCategoryCriteria.coveredCountries.length === 0) {
                                insurancePolicyVehicleCategoryCriteria.coveredCountries.push((comprehensiveInsurancePolicyCartItem
                                    .comprehensiveInsuranceProductCartItem.coveredCountries.at(0) as CountryDescriptionIdentifier).description); // Comprehensive currently only supports one country
                            }
                        }
                    }
                }
            }

            provideSuitableInsurancePolicyRequest.criteria = insurancePolicyVehicleCategoryCriteria;
            createItemIdentifierRequest.quantity = this.numberOfComprehensiveItems;
            const createProposalIdentifierRequest = new CreateIdentifierRequest()
            createProposalIdentifierRequest.type = IdentifierType.PROPOSAL_IDENTIFIER;
            createProposalIdentifierRequest.quantity = this.orderQuantity;

            this.startLoad();
            this.identifierManagerService.createIdentifier(createItemIdentifierRequest).then((response) => {

                createProposalRequest.orderItemNumbers = response.body.identifier;
            }).then(() => {
                this.identifierManagerService.createIdentifier(createProposalIdentifierRequest).then((response) => {
                    createProposalRequest.proposalIdentifiers = response.body.identifier;

                }).then(() => {
                    this.insurancePolicyProviderService.provideSuitableInsurancePolicy(provideSuitableInsurancePolicyRequest)
                        .then(httpResponse => {
                            if (httpResponse && httpResponse.body) {
                                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideSuitableInsurancePolicyResponse) as ProvideSuitableInsurancePolicyResponse);

                                if (this.agentService.agentIdentifier) {
                                    createProposalRequest.agentId = this.agentService.agentIdentifier.legalEntityDescriptionIdentifier.tpiIdentifier.tpiIdentifier;
                                } else {
                                    createProposalRequest.agentId = "ENT-2";
                                }

                                createProposalRequest.proposalFormId = this.proposalForm.proposalFormId;
                                createProposalRequest.answers = this.answers;
                                createProposalRequest.legalEntityIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
                                createProposalRequest.insurancePolicyFound = response.insurancePolicyFound;

                                this.proposalManagerService.createProposal(createProposalRequest).toPromise().then(httpResponse => {

                                    if (httpResponse && httpResponse.body) {
                                        const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateProposalResponse) as CreateProposalResponse);

                                        for (const proposalId of response.proposalIds) {
                                            this.proposalIds.push(proposalId);
                                        }
                                        resolve(response.proposalIds);

                                        this.showQuestions = false;
                                        this.onCurrentStateChange(this.states.CONFIRMATION);
                                        this.comprehensiveInsurance = this.hasComprehensiveProducts()

                                        const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
                                        retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier
                                        this.provideProposal();
                                        this.clearCart(retailProfileOwnerIdentifier);
                                        this.stopLoad();
                                    } else {
                                        resolve(null);
                                    }
                                }).catch((error) => {
                                    this.stopLoad();
                                    this.errorToastService.errorToast(error);
                                    RaygunErrorHandler.sendError(error);
                                    reject(null);
                                });
                            }
                        }).catch(error => {
                        console.log('Error', error);
                        this.stopLoad();
                    });
                });
            })
        });
    }

    createOrders(): Promise<string[]> {
        this.startLoad();
        return new Promise<string[]>((resolve, reject) => {
            const createOrderRequest = new CreateOrderRequest()
            const retailProfileOwnerIdentifier = new RetailProfileOwnerIdentifier()
            retailProfileOwnerIdentifier.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier

            if (this.agentService.agentIdentifier) {
                createOrderRequest.agentIdentifier = this.agentService.agentIdentifier;
            }

            const createIdentifierRequest = new CreateIdentifierRequest()
            createIdentifierRequest.type = IdentifierType.ORDER_NUMBER;
            createIdentifierRequest.quantity = this.orderQuantity;

            this.identifierManagerService.createIdentifier(createIdentifierRequest).then((response) => {
                createOrderRequest.identifiers = response.body.identifier;
                createOrderRequest.retailProfileIdentifier = retailProfileOwnerIdentifier;

                for (const countryCheckoutItemGrouping of this.countryCheckoutItemGroupings) {
                    createOrderRequest.countryIdentifiers.push(countryCheckoutItemGrouping.countryIdentifier);
                }

                createOrderRequest.item = this.buildOrderItems();
                this.createOrder(resolve, reject, createOrderRequest, retailProfileOwnerIdentifier);
            });

        });
    }

    get orderQuantity(): number {
        let quantity: number = 0;
        this.underwriterCheckoutItemGroupings.forEach((grouping) => {
            const currencies = this.getUnderwriterGroupingCurrencies(grouping);
            quantity += currencies.length;
        })
        return quantity;
    }

    getUnderwriterGroupingCurrencies(grouping: UnderwriterCheckoutItemGrouping): string[] {
        const currencies: string [] = [];
        grouping.checkoutDatedItemGroupings.forEach((item) => {
            currencies.push(...item.comprehensiveInsurancePolicyCartItems.map(item => item.vehicleValue.currency));
            currencies.push(...item.thirdPartyInsurancePolicyCartItems.map(item => item.price.currency));
            currencies.push(...item.extensionInsurancePolicyCartItems.map(item => item.price.currency));
            currencies.push(...item.ycinsurancePolicyCartItems.map(item => item.price.currency));
        });
        return Array.from(new Set(currencies));
    }

    buildOrderItems(): Item[] {
        let items: Item[] = [];

        for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.thirdPartyInsurancePolicyCartItems.length > 0) {
                    for (const thirdPartyInsurancePolicyCartItem of checkoutDatedItemGrouping.thirdPartyInsurancePolicyCartItems) {
                        items.push(thirdPartyInsurancePolicyCartItem)
                    }
                }

                if (checkoutDatedItemGrouping.extensionInsurancePolicyCartItems.length > 0) {
                    for (const extensionInsurancePolicyCartItem of checkoutDatedItemGrouping.extensionInsurancePolicyCartItems) {
                        items.push(extensionInsurancePolicyCartItem);
                    }
                }

                if (checkoutDatedItemGrouping.ycinsurancePolicyCartItems.length > 0) {
                    for (const ycInsurancePolicyCartItem of checkoutDatedItemGrouping.ycinsurancePolicyCartItems) {
                        if (ycInsurancePolicyCartItem.ycInsuranceProductCartItem.ycPriceSchedules) {
                            ycInsurancePolicyCartItem.ycInsuranceProductCartItem.ycPriceSchedules.forEach(value =>
                                value.ycPriceScheduleId = uuidv4()
                            );
                        }

                        if (ycInsurancePolicyCartItem.ycInsuranceProductCartItem['ycPriceInterval']) {
                            ycInsurancePolicyCartItem.ycInsuranceProductCartItem['ycPriceInterval'].ycPriceIntervalId = uuidv4();
                        }
                        items.push(ycInsurancePolicyCartItem)
                    }
                }
            }
        }
        return items;
    }

    createOrder(resolve: any, reject: any, createOrderRequest: CreateOrderRequest, retailProfileOwnerIdentifier: RetailProfileIdentifier): void {
        createOrderRequest.item = this.setThirdPartyPriceScheduleAdditionalVehicle(createOrderRequest.item);
        this.orderManagerService.createOrder(createOrderRequest).then(httpResponse => {
            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, CreateOrderResponse) as CreateOrderResponse);
                this.orderIds = response.numbers;
                localStorage.setItem('orderIds', JSON.stringify(this.orderIds));
                resolve(response.numbers);

                if (!this.showQuestions) {
                    this.onCurrentStateChange(this.states.CONFIRMATION);
                    this.clearCart(retailProfileOwnerIdentifier);
                }
                this.orderIds.forEach(element => {
                    this.getCreatedOrder(element)
                });
            } else {
                resolve(null);
            }
        }).catch((error) => {
            this.errorToastService.errorToast(error);
            this.stopLoad();
            RaygunErrorHandler.sendError(error);
            reject(null);
        });
    }

    private getCreatedOrder(element: string) {
        this.tempOrder = [];
        this.startLoad();
        this.payfastPaymentManager.getOrder(element).then(() => {
            this.order = this.payfastPaymentManager.order;
            for (const orderItem of this.order.orderItems) {
                if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCInsurancePolicyOrderItem") {
                    (this.order as any).orderType = InsuranceCategory.YELLOWCARD

                    if (this.ycOrder) {
                        const orderIncluded = this.ycOrder.some(order => {
                            return order.number === this.order.number
                        });
                        if (!orderIncluded) {
                            this.ycOrder.push(this.order)
                        }
                    } else {
                        this.ycOrder = [this.order]
                    }

                    const firstItem = this.ycOrder[0].number;
                    this.ycOrder.forEach(ycElement => {
                        if (ycElement.number === firstItem) {
                            (ycElement as any).expandPanel = true;
                        } else {
                            (ycElement as any).expandPanel = false;
                        }
                    });
                    this.tempOrder.push(...this.ycOrder)
                } else if (orderItem["@class"] === "za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyOrderItem") {
                    (this.order as any).orderType = InsuranceCategory.THIRDPARTY;
                    const coveredCountry = (orderItem as ThirdPartyInsurancePolicyOrderItem).orderThirdPartyInsuranceProduct.coveredCountries[0] as CountryDescriptionIdentifier;

                    if (this.thirdPartyOrderCountryGrouping.has(coveredCountry.description)) {
                        const orderIncluded = this.thirdPartyOrderCountryGrouping.get(coveredCountry.description).some(order => {
                            return order.number === this.order.number
                        });
                        if (!orderIncluded) {
                            this.thirdPartyOrderCountryGrouping.get(coveredCountry.description).push(this.order)
                        }
                    } else {
                        this.thirdPartyOrderCountryGrouping.set(coveredCountry.description, [this.order])
                    }

                    const firstItem = this.thirdPartyOrderCountryGrouping.get(coveredCountry.description)[0].number;
                    this.thirdPartyOrderCountryGrouping.get(coveredCountry.description).forEach(countryOrder => {
                        if (countryOrder.number === firstItem) {
                            (countryOrder as any).expandPanel = true;
                        } else {
                            (countryOrder as any).expandPanel = false;
                        }
                    });
                }
            }

            this.stopLoad();
        })
    }

    private setThirdPartyPriceScheduleAdditionalVehicle(items: Item[]): Item[] {
        const className = 'za.co.magnabc.tpi.retail.insurance.thirdpartyinsurance.ThirdPartyInsurancePolicyCartItem';
        items.forEach((item) => {
            if (item['@class'] && item['@class'] === className) {
                const thirdPartyItem = item as ThirdPartyInsurancePolicyCartItem;
                item['additionalVehicleToPolicy'] = thirdPartyItem.thirdPartyInsuranceProductCartItem.priceSchedule.additionalVehicleToPolicy;
            }
        });
        return items;
    }

    async onSelectedTab(event) {
        const selectedCountry = event.tab.textLabel === 'DRC' ? 'Democratic Republic of the Congo' : event.tab.textLabel;
        if (selectedCountry === 'Malawi' && this.latestProposal.length > 0) {
            // Wait for both Orders and Proposal to be created before loading the tabs
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        if (event.tab.textLabel !== 'Yellow Card') {
            if ((this.thirdPartyOrderCountryGrouping && this.thirdPartyOrderCountryGrouping.size > 0) || (this.latestProposal && this.latestProposal.length > 0)) {

                if (selectedCountry === 'Malawi' && this.thirdPartyOrderCountryGrouping.get('Malawi').length > 0 && this.latestProposal.length > 0) {
                    this.onSelectInsuranceCategory(this.insuranceCategories.THIRDPARTY, this.thirdPartyOrderCountryGrouping.get(selectedCountry));
                    this.onSelectInsuranceCategory(this.insuranceCategories.COMPREHENSIVE)
                } else if (selectedCountry === 'Malawi' && this.latestProposal.length > 0) {
                    this.onSelectInsuranceCategory(this.insuranceCategories.COMPREHENSIVE)
                    this.onSelectInsuranceCategory(this.insuranceCategories.THIRDPARTY, this.thirdPartyOrderCountryGrouping.get('selectedCountry'));
                } else {
                    this.onSelectInsuranceCategory(this.insuranceCategories.THIRDPARTY, this.thirdPartyOrderCountryGrouping.get(selectedCountry));
                }
            }
        } else if (event.tab.textLabel === 'Yellow Card') {
            this.onSelectInsuranceCategory(this.insuranceCategories.YELLOWCARD)
        }
    }

    clearCart(retailProfileIdentifier: RetailProfileIdentifier) {

        const clearCartRequest = new FinaliseClearCartRequest();
        clearCartRequest.retailProfileIdentifier = retailProfileIdentifier;
        this.loading = true;
        this.RetailProfileFinalisationManagerService.finaliseClearCart(clearCartRequest).subscribe((response) => {
            this.RetailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
                this.stopLoad();
            });
        }, (error) => {
            this.stopLoad();
            this.translate.get('TOAST.CHECKOUT_ERROR').subscribe((res: any) => {
                this.activeToast = this.toastr.success(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        });
    }

    provideProposal(): void {

        let criteria;

        this.proposalSummary = [];

        const provideProposalRecordSummaryRequest = new ProvideProposalRecordSummaryRequest();
        criteria = new ProposalRecordSummaryLegalEntityCriteria();
        criteria.legalEntityIdentifier = this.RetailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier;

        provideProposalRecordSummaryRequest.batchNumber = 1;
        provideProposalRecordSummaryRequest.batchSize = 10;
        provideProposalRecordSummaryRequest.criteria = criteria;

        this.loading = true;
        this.proposalInformationProviderService.provideProposalRecordSummary(provideProposalRecordSummaryRequest).then((data) => {
            const response: ProvideProposalRecordSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideProposalRecordSummaryResponse);
            for (const order of response.proposalRecordSummaries) {
                this.proposalSummary.push(order);
            }

            this.proposalSummary.sort((first, second) => {
                if (first.capturedDate > second.capturedDate) {
                    return -1;
                } else if (first.capturedDate < second.capturedDate) {
                    return 1;
                }
                return 0;
            });
            this.proposalTemp = this.proposalSummary;
            this.proposalSummary = [...this.proposalSummary];
            this.latestProposal = this.getLatestProposals(this.proposalSummary);
            if (this.latestProposal.length > 0) {
                const firstItem = this.latestProposal[0].proposalId;

                if (this.thirdPartyOrderCountryGrouping.get('Malawi')
                && this.thirdPartyOrderCountryGrouping.get('Malawi').length > 0) {
                    this.latestProposal.forEach(element => {
                        (element as any).expandPanel = false;
                    });
                } else {
                    this.thirdPartyOrderCountryGrouping.set('Malawi', []);
                    this.latestProposal.forEach(element => {
                        if (element.proposalId === firstItem) {
                            (element as any).expandPanel = true;
                        } else {
                            (element as any).expandPanel = false;
                        }
                    });
                }
                localStorage.setItem('proposalId', JSON.stringify(this.latestProposal[0].proposalId));
            }
            this.loading = false;

        }).catch(err => {
            console.error(err);
            this.loading = false;
        });
    }

    getLatestProposals(proposals: ProposalRecordSummary[]): ProposalRecordSummary[] {
        const latestProposal = proposals.at(0);
        const latestStatusDate = moment(latestProposal.statusDate);
        return proposals.filter((item) => {
            const itemStatusDate = moment(item.statusDate);
            const diff = latestStatusDate.diff(itemStatusDate, 'minute');
            if (diff <= 1 && diff >= -1 && item.status === latestProposal.status) return item;
        })
    }

    toast() {

        this.translate.get('TOAST.CREATE_ORDER').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: "" }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    onSelectInsuranceCategory(insuranceCategory: InsuranceCategory, orders?: Order[], orderId?: string) {
        this.insuranceCategory = insuranceCategory;

        if (this.insuranceCategory === InsuranceCategory.COMPREHENSIVE) {
            const proposalId = orderId ? orderId : this.proposalIds.at(0);
            this.onPreview(proposalId);
        } else if (this.insuranceCategory === InsuranceCategory.THIRDPARTY) {
            let order = this.order ? this.order : orders[0];
            let orderNumber = orderId ? orderId : order.number;
            this.payfastPaymentManager.getOrder(orderNumber).then(() => {
                this.order = this.payfastPaymentManager.order;
            });
            this.onPreviewOrder(orderNumber);
        } else if (this.insuranceCategory === InsuranceCategory.YELLOWCARD && this.selectedTabIndex === 0) {
            this.payfastPaymentManager.getOrder(this.ycOrder[0].number).then(() => {
                this.order = this.payfastPaymentManager.order;
            })
            this.onPreviewOrder(this.ycOrder[0].number);
        }
    }

    toastDates() {

        this.translate.get('TOAST.CHOOSE_DATES').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    toastFileUpload() {

        this.translate.get('TOAST.FILEUPLOAD_ERROR').subscribe((res: any) => {
            this.activeToast = this.toastr.success('Please Upload required Document, in the Documentation section', "Upload file", {
                toastComponent: ToastComponent,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    onStep(step: any): void {
        this.currentStep = step.id;
        window.scrollTo(0, 0);

    }

    onBack(_): void {

        if (this.breadcrumbStep > 0) {
            this.breadcrumbStep--;
        }

        switch (this.currentState) {
            case CheckoutEnum.VIEW_CHECKOUT_SUMMARY: {
                this.location.back();
                break;
            }
            case CheckoutEnum.CAPTURE_KYC: {
                if (this.showQuestions) {
                    this.showQuestions = false;
                    this.currentState = CheckoutEnum.CAPTURE_KYC;
                    this.initialiseCountryCheckout();
                } else {
                    this.initialiseCountryCheckout();
                    this.currentState = CheckoutEnum.VIEW_CHECKOUT_SUMMARY;
                }
                break;
            }
        }
    }

    onPaymentMethod(event): void {
        this.RetailProfileFinalisationManagerService.refreshRetailProfile().then((retailProfile) => {
            this.retailProfile = retailProfile;
            if (this.orderFinalisationManagerService.orders.length === 0 && this.proposalIds.length > 0) {
                this.insuranceCategory = InsuranceCategory.COMPREHENSIVE;
                this.onPreview(this.proposalIds[0]);
            } else {
                this.insuranceCategory = InsuranceCategory.THIRDPARTY;
            }
            this.onCurrentStateChange(this.states.CONFIRMATION);
            this.setTitle();
        });
    }

    hasComprehensiveProducts(): boolean {
        for (const item of this.retailProfile.cart.items) {
            if ((item as any).comprehensiveInsuranceProductCartItem) {
                return true;
            }
        }
        this.selectedTab = 1
        return false;
    }

    hasThirdPartyProducts(): boolean {

        for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.thirdPartyInsurancePolicyCartItems.length > 0) {
                    return true;
                }
            }

        }
        return false;
    }

    hasYCProducts(): boolean {

        for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.ycinsurancePolicyCartItems.length > 0) {
                    return true;
                }
            }

        }
        return false;
    }

    hasExtensionProducts(): boolean {

        for (const underwriterCheckoutItemGrouping of this.underwriterCheckoutItemGroupings) {
            for (const checkoutDatedItemGrouping of underwriterCheckoutItemGrouping.checkoutDatedItemGroupings) {
                if (checkoutDatedItemGrouping.extensionInsurancePolicyCartItems.length > 0) {
                    return true;
                }
            }

        }
        return false;
    }

    isFileUploaded(): boolean {
        if (Object.entries((this.countryCheckoutRetrieverComponent.checkoutManagers.last as MalawiCountryCheckoutManagerComponent).scannedDocumentIdentifiers).length === 0) {
            if ((this.countryCheckoutRetrieverComponent.checkoutManagers.last as MalawiCountryCheckoutManagerComponent).scannedDocuments.length === 0) {
                return false;
            }
            else {
                return true;
            }
        }

        return true;
    }

    isUploadedDocumentRequired (): boolean {
        const allScannedDoc: string[] = [];
        let allRequiredDocCount = 0;

        for (let config of this.countryRegistrationConfigurations) {
            if(config instanceof RequiredNaturalPersonCountryRegistrationInformation || config instanceof RequiredJudicialPersonCountryRegistrationInformation){
                var requiredDocumentsList = config.requiredDocuments;
                for(let doc of requiredDocumentsList){
                    allRequiredDocCount++;
                    const requiredDocsScanned = (this.countryCheckoutRetrieverComponent.checkoutManagers.last as MalawiCountryCheckoutManagerComponent).scannedDocuments
                    .filter(scannedDoc => {
                        return scannedDoc.documentType.description === doc;
                    });

                    if (requiredDocsScanned.length > 0) {
                        allScannedDoc.push(requiredDocsScanned[0].documentType.description);
                    }
                }
            }
        }
        return allScannedDoc.length === allRequiredDocCount;
    }

    onSubmit(event): void {
        switch (this.currentState) {
            case this.states.VIEW_CHECKOUT_SUMMARY:
                this.onCurrentStateChange(this.states.CAPTURE_KYC);
                break;
            case this.states.CAPTURE_KYC:
                this.captureKyc();
                break;
            case this.states.CONFIRMATION:
                this.router.navigate(['/retail/profile']);
                this.setTitle();
                localStorage.removeItem('checkOutPage');
                localStorage.removeItem('orderIds');
                localStorage.removeItem('proposalId');
                break;
        }
    }

    onPayOnline(orderNumber): void {
        localStorage.setItem('checkOutPage', 'true');
        this.router.navigate(['/retail/checkout/payment/' + orderNumber]);
    }

    oncaptureProposalData(data) {
        this.answers = data.answers;
        this.proposalForm = data.proposalForm;
        this.invalidProposal = false;
    }

    onCancelOrder(insuranceCategory: InsuranceCategory, selectedCountry?: string): void {
        this.translate.get('TOAST.CANCEL_ORDER').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: this.order.number }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.cancel(insuranceCategory, selectedCountry);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    cancel(insuranceCategory: InsuranceCategory, selectedCountry?: string): void {
        const finaliseCancelOrderRequest = new FinaliseCancelOrderRequest();
        finaliseCancelOrderRequest.order = this.order;
        finaliseCancelOrderRequest.order.status = OrderStatus.CANCELLED;

        this.startLoad();
        this.orderFinalisationManagerService.finaliseCancelOrder(finaliseCancelOrderRequest).subscribe(() => {
            this.stopLoad();
            this.payfastPaymentManager.getOrder(this.order.number).then(() => {
               this.order = this.payfastPaymentManager.order;
            });
            this.getOrderToDelete(insuranceCategory, selectedCountry);
        }, (error) => {
            this.stopLoad();
        });
    }

    ycDisableTab(): boolean{
        return this.thirdPartyOrderCountryGrouping.size === 0;
    }

    disableTab(): boolean{

        return (!this.ycOrder || this.ycOrder.length === 0) && this.thirdPartyOrderCountryGrouping.size === 1;
    }

    private getOrderToDelete(insuranceCategory: InsuranceCategory, selectedCountry?: string): void {
        if (insuranceCategory === InsuranceCategory.YELLOWCARD) {
            const ycCardOrderNumber = this.ycOrder[0].number
            if (this.order.number === ycCardOrderNumber) {
                this.ycOrder[0].status = this.order.status
            }
        } else if (InsuranceCategory.THIRDPARTY) {
            const thirdPartyNumber = this.thirdPartyOrderCountryGrouping.get(selectedCountry)[0].number;
            if (this.order.number === thirdPartyNumber) {
                this.thirdPartyOrderCountryGrouping.get(selectedCountry)[0].status = this.order.status
            }
        }
    }

    onEditProposal(): boolean {
        return false;
    }

    getNumberOfPages(numOfPages) {
        this.numberOfPages = numOfPages;
    }

    print(orderNumber): void {
        const targetId = `print-section-${orderNumber}`;
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.numberOfPages,
            targetId,
            openBlank: true
        };
        this.printService.printDocument(pdfPrintData);
    }

    printProposalConfirmation(proposalId): void {
        const targetId = `canvas-proposal-${proposalId}`;
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.proposalPages,
            targetId,
            openBlank: false,
            features: 'top=0,height=1024px,width=800px'
        };
        this.printService.printDocument(pdfPrintData);
    }

    onSelectedTabChange(event) {
        let index = 0;
        if (event.tab.textLabel.substring(0, 3) === "PRO") {
            index = this.proposalIds.indexOf(event.tab.textLabel);
            this.onPreview(this.proposalIds[index]);
        } else {
            index = this.orderIds.indexOf(event.tab.textLabel);
            this.onPreviewOrder(this.orderIds[index]);
        }
    }

    onSelectedOrderTabChange(event) {
        this.onPreviewOrder(this.orderIds[event.index]);
    }

    onPreview(proposalId: string) {
        this.getRenderedDocument(proposalId, (pdfDocument) => {
            if (pdfDocument.type === RenderedDocumentType.PROPOSAL_CONFIRMATION) {
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.startLoad()
                this.displayPDF(this.documentToPreview, proposalId, () => {
                    this.stopLoad();
                });
            });
        });
    }

    onPreviewOrder(orderId: string) {
        this.startLoad()
        this.getOrderRenderedDocument(orderId, (pdfDocument) => {
            if (pdfDocument.type === RenderedDocumentType.ORDER) {
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayOrderPDF(this.documentToPreview, orderId, () => {
                    this.stopLoad();
                });
            });
        });

    }

    getRenderedDocument(proposalId: string, callback): void {

        const renderedDocumentIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentIdCriteria.documentId = proposalId;
        renderedDocumentIdCriteria.userID = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;
        renderedDocumentIdCriteria.types = [RenderedDocumentType.PROPOSAL_CONFIRMATION];

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentIdCriteria;

        this.startLoad();
        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {
            this.stopLoad();
            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback(provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument);
            } else {
                callback({});
            }
        });

    }

    getOrderRenderedDocument(orderId: string, callback): void {

        const renderedDocumentIdCriteria = new RenderedDocumentIdCriteria();
        renderedDocumentIdCriteria.documentId = orderId;
        renderedDocumentIdCriteria.userID = Utils.getTpiIdentifier(this.authenticationService.getLoggedInLegalEntityIdentifiers()).tpiIdentifier;

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentIdCriteria;

        this.startLoad();
        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {
            this.stopLoad();
            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback(provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument);
            } else {
                callback({});
            }
        });

    }

    displayPDF(base64, proposalNumber, complete) {
        const canvasId = `#canvas-proposal-${proposalNumber}`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData);
    }

    displayOrderPDF(base64, orderNumber, complete) {
        const canvasId = `#print-section-${orderNumber}`;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numberOfPages) => {
            this.numberOfPages = numberOfPages;
        });
    }

    selectedFilterItem(event) {
        if (event && event.value === 'proposal') {
            this.showOnlyProposalPanel = true;
            this.showOnlyOrderPanel = false;
        } else if (event && event.value === 'order') {
            this.showOnlyProposalPanel = false;
            this.showOnlyOrderPanel = true;
        } else {
            this.showOnlyProposalPanel = true;
            this.showOnlyOrderPanel = true;
        }
    }

    onShipmentFormChanges(form: FormGroup): void {
        this.formCaptureShipment = form;
    }

    private captureKyc() {
        // Check if the Comprehensive product is present
        // this.proposalForm is defined when initialiseCountryCheckout
        if (this.proposalForm) {
            this.showQuestions = true;
        }
        if (!this.showQuestions) {
            this.startLoad();
            this.countryCheckoutRetrieverComponent.validate().then(() => {
                if (this.hasThirdPartyProducts() || this.hasExtensionProducts() || this.hasYCProducts()) {
                    this.createOrders();
                } else {
                    this.onCurrentStateChange(this.states.CONFIRMATION);
                }
            }).catch((error) => {
                this.stopLoad();
                RaygunErrorHandler.sendError(error);
            });
        } else {
            if (this.captureProposalComponent && this.captureProposalComponent.submitAnswers()) {
                if (this.hasThirdPartyProducts() || this.hasExtensionProducts() || this.hasYCProducts()) {
                    Promise.all([this.createOrders(), this.createProposal()])
                } else {
                    this.createProposal()
                }
            }
        }
    }

}
