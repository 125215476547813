import { InsuranceCategory } from '@magnabc/tpi';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { InsuranceTypeManagerService } from '../../../../../../../app.services/managers/insurance-type-manager/insurance-type-manager.service';

@Component({
    selector: 'app-insurance-type-card',
    templateUrl: './insurance-type-card.component.html',
    styleUrls: ['./insurance-type-card.component.scss']
})
export class InsuranceTypeCardComponent implements OnInit {
    @Input() data: InsuranceTypeCardModel;
    readonly routes = {
        selectCountry: "/retail/browse/select-covered-countries"
    };

    readonly meta = {
        YELLOWCARD: {
            subTitle: "Mandatory Third Party",
            description: 'Insurance policy designed to cover vehicles when they travel from one country to another.'
        },
        CROSSBORDER: {
            subTitle: "Mandatory Third Party",
            description: 'Insurance policy designed to cover vehicles when they travel from one country to another.'
        },
        THIRDPARTY: {
            subTitle: "Home Country",
            description: "Insurance policy that covers the insured individual's liability for damages caused to another person or their property."
        },
        COMPREHENSIVE: {
            subTitle: "Home Country",
            description: "Insurance policy that provides coverage to damage of the policyholder's own vehicle, in addition to liabilities towards third parties."
        }
    };

    constructor(private router: Router, private insuranceTypeManagerService: InsuranceTypeManagerService, private route: ActivatedRoute
        ) { }

    ngOnInit() {
    }

    onTileClicked(event): void {
        this.insuranceTypeManagerService.selectedInsuranceCategory = (this.data.title) as InsuranceCategory;
        if (this.route.snapshot.queryParams.quickBuy) {
            this.router.navigate([this.routes.selectCountry], {queryParams: {quickBuy: true}});
        } else {
            this.router.navigate([this.routes.selectCountry]);
        }
    }

    get id(): string {
        return this.data.title;
    }

    get subTitle(): string {
        switch (this.data.title) {
            case InsuranceCategory.YELLOWCARD:
                return this.meta.YELLOWCARD.subTitle;
            case InsuranceCategory.CROSSBORDER:
                return this.meta.CROSSBORDER.subTitle;
            case InsuranceCategory.THIRDPARTY:
                return this.meta.THIRDPARTY.subTitle;
            case InsuranceCategory.COMPREHENSIVE:
                return this.meta.COMPREHENSIVE.subTitle;
        }
    }

    get hint(): string {
        switch (this.data.title) {
            case InsuranceCategory.YELLOWCARD:
                return this.meta.YELLOWCARD.description;
            case InsuranceCategory.CROSSBORDER:
                return this.meta.CROSSBORDER.description;
            case InsuranceCategory.THIRDPARTY:
                return this.meta.THIRDPARTY.description;
            case InsuranceCategory.COMPREHENSIVE:
                return this.meta.COMPREHENSIVE.description;
        }
    }

    get title(): string {
        return this.data.title === InsuranceCategory.YELLOWCARD ? 'CROSS-BORDER' : this.data.title;
    }
}

export class InsuranceTypeCardModel {
    title: string;
    hint: string;
}
