import {Request} from "../../../urdad/services/models/request.model";
import {Criteria} from "../../../criteria/models/criteria.model";

export class ProvideUsageIdentifiersRequest extends Request {
    criteria: Criteria;

    constructor() {
        super();
    }
}

