import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class RuntimeConfigurationService {

    private runtimeConfiguration: RuntimeConfiguration

    constructor(private http: HttpClient) {}

    initialise(): Promise<any> {
        return this.http.get('/assets/config/runtime.json').toPromise().then(config => {
            this.runtimeConfiguration = config as any
            return Promise.resolve()
        })
    }

    get releaseStage() : string {
        return this.runtimeConfiguration.releaseStage
    }

    get hostUrl() : string {
        return this.runtimeConfiguration.hostUrl
    }

    get apiBaseUrl() : string {
        return this.runtimeConfiguration.apiBaseUrl
    }

    get defaultCountryDialingCode(): string {
        return this.runtimeConfiguration.defaultCountryDialingCode
    }

    get defaultCountryAlphaTwo(): string {
        return this.runtimeConfiguration.defaultCountryAlphaTwo
    }

    get onlinePayment(): boolean {
        return this.runtimeConfiguration.onlinePayment
    }

    get branding(): string {
        return this.runtimeConfiguration.branding
    }

    get title(): string {
        return this.runtimeConfiguration.title
    }

    get infoEmail(): string {
        return this.runtimeConfiguration.infoEmail
    }

    get supportEmail(): string {
        return this.runtimeConfiguration.supportEmail
    }

    get paymentTerms(): string {
        return this.runtimeConfiguration.paymentTerms
    }

    get productConditions(): string {
        return this.runtimeConfiguration.productConditions
    }

    get domain(): string {
        return this.runtimeConfiguration.domain
    }

    get favicon(): string {
        return this.runtimeConfiguration.favicon
    }

    get statusEnabled(): boolean {
        return this.runtimeConfiguration.statusEnabled
    }

    get statusBaseUrl(): string {
        return this.runtimeConfiguration.statusBaseUrl
    }

    get payfastPaymentBaseUrl(): string {
        return this.runtimeConfiguration.payfastPaymentBaseUrl
    }


}

export interface RuntimeConfiguration {
    apiBaseUrl: string
    branding: string
    defaultCountryAlphaTwo: string
    defaultCountryDialingCode: string
    domain: string
    favicon: string
    hostUrl: string
    infoEmail: string
    onlinePayment: boolean
    paymentTerms: string
    productConditions: string
    releaseStage: string
    statusBaseUrl: string
    statusEnabled: boolean
    supportEmail: string
    title: string
    payfastPaymentBaseUrl: string
}
