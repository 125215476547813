import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    FinaliseUpdateLegalEntityRequest,
    FinaliseInsertLegalEntityRequest,
    LegalEntityProviderCriteria,
    MarshallerService,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    TpiIdentifier,
    UnMarshallerService
} from '@magnabc/tpi';
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service';
import {AuthenticationService} from "../../../app.services/managers/authentication/authentication.service";
import {RaygunErrorHandler} from "../../../common/utils/utils.raygun";
import {
    QueryLegalEntityInformationManagerService
} from "../query-legal-entity-information-manager/query-legal-entity-information-manager.service";

@Injectable({
    providedIn: 'root'
})
export class LegalEntityFinalisationManagerService {

    basePath = '/entity/legalentityfinalisationmanager';

    legalEntityListeners = [];

    legalEntity: any;

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    constructor(private http: HttpClient,
                private marshallerService: MarshallerService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService,
                private authenticationService: AuthenticationService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                ) {
        authenticationService.subscriptionListener.subscribe(response => {
            if(response !== null){
                if(this.legalEntity) {
                    var tpi: TpiIdentifier;
                    tpi = new TpiIdentifier();
                    tpi.tpiIdentifier = this.legalEntity.legalEntityIdentifiers[0].tpiIdentifier;
                    if(tpi.tpiIdentifier === null) return;
                    this.refreshLegalEntity(tpi);
                } else{
                    this.refreshLegalEntity(authenticationService.getLegalEntityDescriptionIdentifier().tpiIdentifier);
                }
            }
        });
    }

    refreshLegalEntity(legalEntityIdentifier) {


        // INTERNAL PUBLIC
        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = legalEntityIdentifier;
        legalEntityProviderCriteria.type = 'INTERNAL_PUBLIC';

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then(httpResponse => {

            if(httpResponse && httpResponse.body){

                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                if (response.legalEntities.length > 0) {
                    this.legalEntity = response.legalEntities[0];
                    for (const listener of this.legalEntityListeners) {
                        listener(this.legalEntity);
                    }
                    this.authenticationService.reloadProxyInformation();
                }

            }

            //INTERNAL BACKOFFICE
            const legalEntityProviderCriteriaBackOffice = new LegalEntityProviderCriteria();
            legalEntityProviderCriteriaBackOffice.legalEntityIdentifier = legalEntityIdentifier;
            legalEntityProviderCriteriaBackOffice.type = 'INTERNAL_BACKOFFICE';

            const queryLegalEntityInformationRequestBackOffice = new QueryLegalEntityInformationRequest()
            queryLegalEntityInformationRequestBackOffice.criteria = legalEntityProviderCriteriaBackOffice;

            return this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequestBackOffice);


        }).then(httpResponse =>{

            if(httpResponse && httpResponse.body){

                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);

                if (response.legalEntities.length > 0) {
                    this.legalEntity = response.legalEntities[0];
                    for (const listener of this.legalEntityListeners) {
                        listener(this.legalEntity);
                    }
                    this.authenticationService.reloadProxyInformation();
                }
            }

        }).catch(error=>{

            let errorMessage = '';
            if (error.error !== null) {
                errorMessage = 'Request is not valid.';
            } else {
                errorMessage = 'Unknown server error';
            }
            RaygunErrorHandler.sendError(error);
        });

    }

    registerLegalEntityListener(listener): void {
        this.legalEntityListeners.push(listener);
        if (this.legalEntity) {
            listener(this.legalEntity);
        }
    }

    finaliseInsertLegalEntity(finaliseInsertLegalEntityRequest: FinaliseInsertLegalEntityRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseinsertlegalentity';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseInsertLegalEntityRequest);
        return this.http.post(path, jsonRequest, this.options);
    }

    finaliseUpdateLegalEntity(finaliseUpdateLegalEntityRequest: FinaliseUpdateLegalEntityRequest): Observable<any> {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/finaliseupdatelegalentity';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseUpdateLegalEntityRequest);
        return this.http.post(path, jsonRequest, this.options);
    }
}
