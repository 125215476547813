import {Response} from '../../../../../../../urdad/services/models/response.model';
import {NaturalPerson} from '../../../../../../../entity/models/natural-person.model';

export class InitialiseCreateVerifiedAccountResponse extends Response {
    constructor() {
        super();
    }

    naturalPerson: NaturalPerson;
}
