// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    dateFormat: 'en-GB',
    daysInYear: 365,
    formatDate: "YYYY-MM-DD",
    formatDateServer: "YYYY-MM-DDTHH:mm:ss.SSS",
    formatDateTime: "YYYY-MM-DD HH:mm",
    formatDateTimestamp: "YYYY-MM-DD HH:mm:ss",
    formatDateYear: "YYYY",
    googleAnalyticsTrackingId: null,
    raygun_api_key: '4yUryp1VZec8e2a27AauLA',
    toast_time_out: 5000,
    payFastUrl: 'https://sandbox.payfast.co.za/eng/process',
    merchant_id: '10031508',
    merchant_key: 'm21xta3cpvwlj',
    validatePayfast: 'https://sandbox.payfast.co.za/eng/query/validate'
};
