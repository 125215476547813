import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

    @Input() mapOption: EChartsOption = {};
    @Input() selectedCountries: any[] = [];
    @Input() mapName: string = '';
    @Input() geoJsonUrl: any;
    @Input() updatedOptions: EChartsOption = {};

    constructor() { }

    ngOnInit() {
        this.mapFunction();
    }

    mapFunction(): void {
        echarts.registerMap(this.mapName, this.geoJsonUrl, {});
    }

}
