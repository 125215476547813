import { RaygunErrorHandler } from './../../../common/utils/utils.raygun';
import { Injectable } from '@angular/core';
import {
    ProvideInsuranceTypeRequest,
    InsuranceType,
    UnMarshallerService,
    ProvideInsuranceTypeResponse,
    AllInsuranceTypeCriteria,
    BrokerLegalEntityIdentifier,
    LegalEntityDescriptionIdentifier,
    TpiIdentifier,
    Broker,
    InsuranceTypeBrokerCriteria,
    InsuranceCategory
} from '@magnabc/tpi';
import { InsuranceTypeProviderService } from '../../../http.services/retail/insurance/setup/insurance-type-provider/insurance-type-provider.service';

@Injectable({
    providedIn: 'root'
})
export class InsuranceTypeManagerService {
    selectedInsuranceCategory: InsuranceCategory;

    constructor(private insuranceTypeProviderService: InsuranceTypeProviderService,
        private unMarshallerService: UnMarshallerService) { }

    getInsuranceTypes(broker: Broker): Promise<InsuranceType[]> {

        return new Promise((resolve, reject) => {
            if(!broker) {
                // Can't get insurance types without a broker
                reject(null);
            }

            const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();
            legalEntityDescriptionIdentifier.name = broker.name;
            legalEntityDescriptionIdentifier.tpiIdentifier = broker.legalEntityIdentifier as TpiIdentifier;
            const brokerLegalEntityIdentifier: BrokerLegalEntityIdentifier = new BrokerLegalEntityIdentifier(broker.name, legalEntityDescriptionIdentifier);
            const insuranceTypeBrokerCriteria = new InsuranceTypeBrokerCriteria();
            const insuranceTypeRequest = new ProvideInsuranceTypeRequest();
            insuranceTypeBrokerCriteria.brokerIdentifier = brokerLegalEntityIdentifier;
            insuranceTypeRequest.criteria = insuranceTypeBrokerCriteria;

            this.insuranceTypeProviderService.provideInsuranceTypes(insuranceTypeRequest).toPromise().then((response) => {
                if (response && response.body) {
                    const provideInsuranceTypeResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceTypeResponse) as ProvideInsuranceTypeResponse);
                    resolve(provideInsuranceTypeResponse.insuranceTypes);
                }
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
                reject(null);
            });
        });
    }

    getAllInsuranceTypes(): Promise<InsuranceType[]> {
        return new Promise((resolve, reject) => {
            const insuranceTypeRequest = new ProvideInsuranceTypeRequest();
            insuranceTypeRequest.criteria = new AllInsuranceTypeCriteria();
            this.insuranceTypeProviderService.provideInsuranceTypes(insuranceTypeRequest).toPromise().then((response) => {
                if (response && response.body) {
                    const provideInsuranceTypeResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideInsuranceTypeResponse) as ProvideInsuranceTypeResponse);
                    resolve(provideInsuranceTypeResponse.insuranceTypes);
                }
            }).catch(err => {
                RaygunErrorHandler.sendError(err);
                reject(null);
            });
        });
    }
}
