import {Request} from '../../../../../../../urdad/services/models/request.model';
import {MakeIdentifier} from '../../../../../../identification/models/make-identifier.model';
import {Make} from '../../../../../models/make.model';

export class FinaliseUpdateVehicleMakeRequest extends Request {
    constructor() {
        super();
    }

    public makeIdentifier: MakeIdentifier;
    public make: Make;
}
