import {Component, OnInit} from '@angular/core'
import {Router} from "@angular/router"
import {TranslateService} from "@ngx-translate/core"
import {AgentManagerService} from "../../../app.services/managers/agent-manager/agent-manager.service"
import * as moment from "moment"
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

const appVersion = require('../../../../../package.json').version;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    year = moment().format('YYYY');
    packageVersion = appVersion;

    constructor(private translate: TranslateService, private router: Router, public agentService: AgentManagerService,
                public runtimeConfigurationService: RuntimeConfigurationService) {}

    ngOnInit() {

        if (this.runtimeConfigurationService.releaseStage !== 'Production') {
            this.packageVersion = `${appVersion} ${moment().format('YYYY-MM-DD HH:mm')}`;

        }

    }

    lang(iso: string): boolean {
        this.translate.use(iso);
        return false;
    }

}
