import {Money} from "../../../financial/models/money.model";

export class CustomerInvoiceSummary {
    constructor() {
        this["@class"] = "za.co.magnabc.tpi.retail.invoice.CustomerInvoiceSummary";
    }

    public duty: Money;
    public invoiceNumber: string;
    public orderDate: Date;
    public orderNumber: string;
    public subTotal: Money;
    public totalDue: Money;
    public vat: Money;
}