import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, TimeoutError} from 'rxjs';
import {TokenManagerService} from '../../managers/token-manager/token-manager.service';
import {catchError, filter, switchMap, take, tap, timeout} from 'rxjs/operators';
import {TokenIssuerService} from '../../../http.services/security/token/token-issuer/token-issuer.service'

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private refreshTokenInProgress = false;
    private timeOutDialog = false;
    private REST_TIMEOUT = 600000;

    constructor(private tokenService: TokenManagerService, private tokenIssuerService: TokenIssuerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.tokenService.getTempToken() !== null) {
            if (this.tokenService.getTrnHeader()) {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'temp-token': this.tokenService.getTempToken(),
                        'X-Traffic-Register-Number': this.tokenService.getTrnHeader()
                    }
                });
            } else {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'temp-token': this.tokenService.getTempToken()
                    }
                });
            }
        } else if (this.tokenService.getTokens().accessToken !== null) {
            req = this.addAuthToken(req);
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });
        }

        return next.handle(req).pipe(
            timeout(this.REST_TIMEOUT),
            catchError((error: any) => {
                if (error && error.status === 406) {
                    return this.handleRefreshToken(req, next);
                } else if (error instanceof TimeoutError) {
                    if (!this.timeOutDialog) {
                        this.timeOutDialog = true;
                        alert('Timeout :: Server timeout occurred, please try again');
                    }
                    return throwError(error);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
                switchMap(() => {
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(this.tokenService.getTokens().accessToken);
                    return next.handle(this.addAuthToken(request));
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(() => {
                    return next.handle(this.addAuthToken(request));
                }));
        }
    }

    private refreshAccessToken() {
        return this.tokenIssuerService.issueNewTokens(this.tokenService.getTokens().refreshToken).pipe(
            tap((httpResponse: any) => {
                this.tokenService.setTokensHttp(httpResponse);
                this.refreshTokenInProgress = false;
            })
        );
    }

    private addAuthToken(request: HttpRequest<any>): HttpRequest<any> {

        if(request.body instanceof FormData)
        {
            return request.clone({
                setHeaders: {
                    Authorisation: 'Bearer ' + this.tokenService.getTokens().accessToken
                }
            });
        }
        else
        {
            if (this.tokenService.getTrnHeader()) {
                return request.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        Authorisation: 'Bearer ' + this.tokenService.getTokens().accessToken,
                        'X-Traffic-Register-Number': this.tokenService.getTrnHeader()
                    }
                });
            } else {
                return request.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        Authorisation: 'Bearer ' + this.tokenService.getTokens().accessToken
                    }
                });
            }
        }
    }
}
