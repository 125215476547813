import { CountryRegistrationConfiguration } from "./country-registration-configuration.model";

export abstract class ShownNaturalPersonCountryRegistrationInformation extends CountryRegistrationConfiguration {
    constructor() {
        super();
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.ShownNaturalPersonCountryRegistrationInformation';
    }

    public bankAccountNumber: boolean;
    public bankName: boolean;
    public contactEmail: boolean;
    public contactNumber: boolean;
    public income: boolean;
    public nationalIdentityNumber: boolean;
    public nationality: boolean;
    public nationalityStatus: boolean;
    public occupation: boolean;
    public physicalAddress: boolean;
    public postalAddress: boolean;
    public sourceOfIncome: boolean;
    public title: boolean;
    public vatNumber: boolean;
    public shownDocuments: string[] = [];
    public shipping: boolean;
    public politicallyExposed: boolean;
}
