import {CertificateStatus} from './certificate-status.model';

export class InsuranceCertificatePolicyNumberVehicleCriteria {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.certificate.InsuranceCertificatePolicyNumberVehicleCriteria";
    }

    public tpiVehicleIdentifier: string
    public policyNumber: string
    public status: CertificateStatus

}
