import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
    MarshallerService,
    ProvideRetailProfileInformationRequest,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehiclesRequest
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class RetailProfileInformationProviderService {

    basePath = '/retail/profile/retailprofileinformationprovider';

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {}

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideRetailProfileInformation(provideRetailProfileRequest: ProvideRetailProfileInformationRequest) {
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideretailprofileinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRetailProfileRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideRetailProfileVehicleInformation(provideRetailProfileRequest: ProvideRetailProfileVehicleInformationRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideretailprofilevehicleinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRetailProfileRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideRetailProfileVehicles(provideRetailProfileRequest: ProvideRetailProfileVehiclesRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideretailprofilevehicles';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRetailProfileRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

    provideOrderLegalEntity(provideRetailProfileRequest: ProvideRetailProfileVehicleInformationRequest){
        const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/provideorderlegalentity';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRetailProfileRequest);
        return this.http.post<any>(path, jsonRequest, this.options).toPromise();
    }

}
