import {Request} from "../../../../../../urdad/services/models/request.model";
import {VehicleIdentifier} from '../../../../../identification/models/vehicle-identifier.model';

export class ProvideVehicleInformationRequest extends Request {

    constructor() {
        super();
    }

    public vehicleIdentifiers : VehicleIdentifier[] = [];
}

