import {AgentIdentifier} from '../../../../entity/identification/models/agent-identifier.model';
import {CertificateStatus} from './certificate-status.model';
import {InsurancePolicyIdentifier} from "../../identification/models/insurance-policy-identifier.model";

export class MotorInsuranceCertificate {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.certificate.MotorInsuranceCertificate";
    }

    public insurancePolicyIdentifier: InsurancePolicyIdentifier;
    public certificateNumber: string;
    public printDate: Date;
    public printedBy: AgentIdentifier;
    public status: CertificateStatus;
    public printedByUserName: string;
    public printedByUserId: string;
    public tpiVehicleIdentifier: string;

}
