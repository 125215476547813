import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {MarshallerService, PublicProvideAccountInformationRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class PublicAccountInformationProvider {

    constructor(private http: HttpClient, private marshallerService: MarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
    }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideAccountInformation(provideAccountInformationRequest: PublicProvideAccountInformationRequest) {

        const provideAccountInformationURI = this.runtimeConfigurationService.apiBaseUrl + '/security/account/publicaccountinformationprovider/provideaccountinformation';

        const jsonProvideAccountInformationRequest = this.marshallerService.marshallObjectToJSON(provideAccountInformationRequest);

        return this.http.post<any>(provideAccountInformationURI, jsonProvideAccountInformationRequest, this.options).toPromise();
    }
}
