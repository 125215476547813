import { Response } from '../../../../../../../../urdad/services/models/response.model';
import { ProposalSummary } from '../../../../../models/proposal-summary.model';

export class ProvideProposalInformationResponse extends Response {
    constructor(){
        super();
    }

    public proposalSummary: ProposalSummary [] = [];
    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
}