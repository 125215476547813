import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {
    DeterminePersonalOrBusinessTransactionComponent
} from './determine-personal-or-business-transaction/determine-personal-or-business-transaction.component'
import {
    CaptureMotorInsuranceDetailsComponent
} from './insurance-quick-transact-user/capture-motor-insurance-details/capture-motor-insurance-details.component'
import {
    CaptureVehicleImagesComponent
} from './insurance-quick-transact-user/capture-vehicle-images/capture-vehicle-images.component'
import {RouterModule} from '@angular/router'
import {HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {LoadingModule} from 'ngx-loading'
import {SharedModule} from '../../../shared/shared.module'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatSelectModule} from '@angular/material/select'
import {EntityModule} from '../../../entity/entity.module'
import { MatInputModule } from '@angular/material/input'
import { CaptureVehicleRegistrationDocumentComponent } from './insurance-quick-transact-user/capture-vehicle-registration-document/capture-vehicle-registration-document.component'


@NgModule({
    declarations: [
        DeterminePersonalOrBusinessTransactionComponent,
        CaptureMotorInsuranceDetailsComponent,
        CaptureVehicleImagesComponent,
        CaptureVehicleRegistrationDocumentComponent
    ],
    exports: [
        CaptureVehicleImagesComponent,
        DeterminePersonalOrBusinessTransactionComponent,
        CaptureMotorInsuranceDetailsComponent,
        CaptureVehicleRegistrationDocumentComponent
    ],
    imports: [
        RouterModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        SharedModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatSelectModule,
        EntityModule,
        MatInputModule
    ]
})
export class QuickTransactModule { }
