import { Criteria } from "../../../criteria/criteria_api";
import { CountryIdentifier } from "../../../location/location_api";

export class FinancialAccountLegalEntityCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.FinancialAccountLegalEntityCriteria";
    }

    public legalEntityIdentifiers : string [] = [];
}