import { MotorInsuranceCertificate } from "../models/motor-insurance-certificate.model";
import { InsuranceCertificateEvent } from "./insurance-certificate-event.model";

export class DuplicateInsuranceCertificateCreatedEvent extends InsuranceCertificateEvent{
    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.certificate.events.DuplicateInsuranceCertificateCreatedEvent";
    }

    public motorInsuranceCertificate: MotorInsuranceCertificate;
}