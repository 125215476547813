import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { OnDestroy } from '@angular/core';
import { YellowCardProductPricingComponentModel, YellowCardProductPricingComponentScheduleModel } from './../../../../../../view.components/retail/insurance/insurance-product-manager/yellow-card-product-view/yellow-card-product-pricing/yellow-card-product-pricing-component.model';
import { ProductPriceScheduleType } from './../../../../../../view.components/retail/insurance/insurance-product-manager/product-price-schedule/product-price-schedule.component';
import { ProductPriceScheduleDurationPipe } from './../../../../../../view.components/shared/pipes/product-price-schedule.pipe';
import { CountryDescriptionIdentifier, Duration, DurationType, InsuranceCategory, InsuranceType, YCInsuranceProduct, YCPriceInterval, YCPriceSchedule } from '@magnabc/tpi';
import { Dropdown } from './../../../../../../common/model/dropdown.model';
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { InsuranceProductManagerService } from '../../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Country } from '@magnabc/tpi';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from '../../../../../../view.components/shared/dialogs/custom-dialog/custom-dialog.component';
import { DialogDataModel } from './../../../../../../view.components/shared/dialogs/custom-dialog/dialog-data.model';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { IStepComponent } from '../../../../../../view.components/shared/guards/step-guard';
import { Location } from '@angular/common';
import { v4 as uuid } from 'uuid';
import { environment } from '../../../../../../../environments/environment';

@Component({
    selector: 'app-yellow-card-product-pricing-page',
    templateUrl: './yellow-card-product-pricing-page.component.html',
    styleUrls: ['./yellow-card-product-pricing-page.component.scss']
})
export class YellowCardProductPricingPageComponent implements OnInit, OnDestroy, IStepComponent {
    loading = false;
    steps = [];
    currentStep = 0;
    priceScedule: YellowCardProductPricingComponentModel = { pricing: [] };
    durationTypes = [
        new Dropdown(DurationType.INTERVAL, "Interval"),
        new Dropdown(DurationType.PRO_RATA, "ProRata")
    ]
    months = []
    days = []

    storeCountries: Country[] = [];
    formGroupPricing: UntypedFormGroup;
    intervalIDs = ['-0'];
    formType = ProductPriceScheduleType.YELLOW_CARD;
    selectedCountries: string[] = [];

    subscriptions = new Subscription();
    productNumber: string;
    countrySelectErrors: Error[];
    countryOptions: Country[];
    yellowCardTranslations;
    currency: string;
    pricingError: string = null;
    supportedCountries: Country[] = [];

    backPressed: boolean;

    constructor(
        public insuranceProductManagerService: InsuranceProductManagerService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private translateServiceParent: TranslateService,
        private translateParserParent: TranslateParser,
        private location: Location) {
        for (let i = 0; i <= ProductPriceScheduleDurationPipe.MONTHS_IN_YEAR; i++) {
            this.months.push(new Dropdown(i, `${i}`))
        }

        for (let i = 0; i <= ProductPriceScheduleDurationPipe.DAYS_IN_YEAR; i++) {
            this.days.push(new Dropdown(i, `${i}`))
        }

        let subscription = translateServiceParent.get("YELLOW_CARD").subscribe((response) => {
            setTimeout(() => {
                this.yellowCardTranslations = response;
            }, 0);
        });
        this.subscriptions.add(subscription);

        subscription = this.translate.get("YELLOW_CARD.PRICING_PAGE.STEP_1").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-globe", borderColor: "#1592E6" });
        });
        this.subscriptions.add(subscription);

        subscription = this.translate.get("YELLOW_CARD.PRICING_PAGE.STEP_2").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-usd", borderColor: "#1592E6" });
        });
        this.subscriptions.add(subscription);

        subscription = this.translate.get("YELLOW_CARD.PRICING_PAGE.STEP_3").subscribe((response) => {
            this.steps.push({ id: 0, name: response, icon: "fa-clock-o", borderColor: "#1592E6" });
        });
        this.subscriptions.add(subscription);
    }

    ngOnInit(): void {
        //TODO refactor
        this.loading = true;
        this.insuranceProductManagerService.insuranceCategory = InsuranceCategory.YELLOWCARD;
        this.insuranceProductManagerService.initialiseInsuranceProduct().then(() => {
            this.currency = this.insuranceProductManagerService.currency;
            this.supportedCountries = this.insuranceProductManagerService.countries;

            const subcription = this.activatedRoute.params.subscribe((data) => {
                this.productNumber = data['number'];
            });
            this.subscriptions.add(subcription);
            const schedules = this.insuranceProductManagerService.ycPriceSchedules.value;
            if (schedules.length > 0) {
                this.setPriceSchedules(schedules);
            }
            const intervals = this.insuranceProductManagerService.ycPriceIntervals.value;
            if (intervals.length > 0) {
                this.setPriceIntervals(intervals);
            } else {
                this.createPricingForm();
            }
            this.loading = false;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onDeactivate(): boolean {
        return true;
    }

    onCountryOptions(data: Country[]): void {
        this.countryOptions = data;
        this.storeCountries = this.countryOptions.filter((item) => this.selectedCountries.includes(item.description));
    }

    private setPriceIntervals(intervals: YCPriceInterval[]): void {
        intervals.sort((a, b) => {
            return a.intervalDurationFrom.days - b.intervalDurationFrom.days;
        });

        this.intervalIDs = intervals.map((item) => `-${item.ycPriceIntervalId}`);
        const durationType = intervals[0].durationType;
        this.buildPricingForm(durationType);
        if (durationType === DurationType.PRO_RATA) {
            const interval = intervals[0];
            const suffix = `-${interval.ycPriceIntervalId}`;
            this.addIntervalControls(suffix, interval.durationType, interval);
        } else if (durationType === DurationType.INTERVAL) {
            intervals.forEach((interval, index) => {
                const suffix = `-${interval.ycPriceIntervalId}`;
                this.addIntervalControls(suffix, interval.durationType, interval);
                if (index < intervals.length - 1) {
                    this.setEditableInterval(false, suffix);
                }
            });
        }
    }

    private setPriceSchedules(schedules: YCPriceSchedule[]): void {
        let pricing: YellowCardProductPricingComponentScheduleModel[] = [];
        schedules.forEach((schedule) => {
            if (!this.selectedCountries.includes(schedule.country)) {
                this.selectedCountries.push(schedule.country);
            }

            let foundCategories = pricing.filter((item) => item.categoryId === schedule.insuranceVehicleCategoryId);
            if (foundCategories.length > 0) {
                foundCategories[0].coveredCountries.push({
                    id: schedule.ycPriceScheduleId,
                    name: schedule.country,
                    price: schedule.price / 100
                });
            } else {
                const description = [...new Set(schedule.insuranceVehicleCategorySubDescription.split('-')
                    .map(item => item.trim()))];
                let categoryName = schedule.insuranceVehicleCategorySubDescription;
                if (description.length < 2) {
                    categoryName = description[0];
                }

                pricing.push({
                    categoryId: schedule.insuranceVehicleCategoryId,
                    categoryName,
                    coveredCountries: [{
                        id: schedule.ycPriceScheduleId,
                        name: schedule.country,
                        price: schedule.price / 100
                    }]
                });
            }
        });
        this.priceScedule.pricing = pricing;
    }

    onBack(): void {
        if (this.currentStep > 0) {
            this.currentStep--;
        } else {
            this.location.back();
        }
    }

    onNext(): void {
        if (this.currentStep === 0) {
            if (!this.validSelectedCountries()) {
                return;
            }
            if (this.priceScedule.pricing.length === 0) {
                this.generateNewPricingScheduleModel();
            } else {
                this.updatePricingScheduleModel();
            }
        } else if (this.currentStep === 1) {
            if (this.pricingError) {
                return;
            }
        }
        this.currentStep++;
    }

    private validSelectedCountries(): boolean {
        this.countrySelectErrors = [];
        if (this.storeCountries.length === 0) {
            const message = this.translateParserParent.interpolate(
                this.yellowCardTranslations.VALIDATION_ERROR_MESSAGE.COUNTRY_NOT_SELECTED);
            this.countrySelectErrors.push(new Error(message));

            return false;
        }
        return true;
    }

    private updatePricingScheduleModel(): void {
        if (this.storeCountries.length > 0) {
            this.selectedCountries = this.storeCountries.map(item => item.description);
        }

        this.storeCountries.forEach((country) => {
            this.priceScedule.pricing.forEach((pricing) => {
                pricing.coveredCountries.forEach((cc) => {
                    if (!cc.id) { cc.id = uuid(); }
                });
                const index = pricing.coveredCountries.findIndex((item) => item.name === country.description);
                if (index < 0) {
                    pricing.coveredCountries.push({
                        id: null,
                        name: country.description,
                        price: 0
                    })
                }

                let selectedCountries = this.storeCountries.map(item => item.description);
                let keepPrices = pricing.coveredCountries.filter(item => selectedCountries.includes(item.name));
                pricing.coveredCountries = keepPrices;
            })
        });

    }

    /**
     * Covered countries
     */
    private coveredCountries() {
        this.insuranceProductManagerService.product.coveredCountries = [];
        for (const country of this.insuranceProductManagerService.countries) {
            for (const selected of this.selectedCountries) {
                if (selected === country.description) {
                    const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                    countryDescriptionIdentifier.description = country.description
                    this.insuranceProductManagerService.product.coveredCountries.push(countryDescriptionIdentifier);
                }
            }
        }
    }

    private onSubmitYCPricing() {
        if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
            const product = this.insuranceProductManagerService.product;
            product.ycPriceSchedules = this.insuranceProductManagerService.ycPriceSchedules.value
            product.ycPriceSchedules = product.ycPriceSchedules.map((item) => {
                item.price = item.price * 100;
                return item;
            });
            product.ycPriceIntervals = this.insuranceProductManagerService.ycPriceIntervals.value
            product.baseCurrency = this.insuranceProductManagerService.currency
        }

    }

    onSubmit(): void {
        const intervals: YCPriceInterval[] = this.generateYCPrceIntervals();
        const firstInterval = intervals[intervals.length - 1];
        if (firstInterval.durationType === DurationType.INTERVAL && firstInterval.intervalDurationTo.days < environment.daysInYear) {
            return;
        }

        const schedules: YCPriceSchedule[] = this.generateYCPriceSchedules();
        this.insuranceProductManagerService.ycPriceIntervals.next(intervals);
        this.insuranceProductManagerService.ycPriceSchedules.next(schedules);
        this.onSubmitYCPricing();
        this.coveredCountries();
        if (intervals.length > 0 && schedules.length > 0) {
            this.router.navigate([`/retail/insurance/product-overview/${this.productNumber}`]);
        }
    }

    private generateYCPriceSchedules(): YCPriceSchedule[] {
        let schedules: YCPriceSchedule[] = [];
        this.priceScedule.pricing.forEach((pricing) => {
            pricing.coveredCountries.forEach((item) => {
                let schedule = new YCPriceSchedule();
                let foundSchedule: YCPriceSchedule = null;
                if (this.insuranceProductManagerService.product
                    && this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                    if (this.insuranceProductManagerService.product.ycPriceSchedules) {
                        foundSchedule = this.insuranceProductManagerService.product.ycPriceSchedules
                            .find(schedule => schedule.ycPriceScheduleId === item.id);
                    }
                }
                if (foundSchedule) {
                    schedule = foundSchedule;
                }

                schedule.ycPriceScheduleId = item.id;
                schedule.country = item.name;
                schedule.price = item.price;
                schedule.insuranceVehicleCategorySubDescription = pricing.categoryName;
                schedule.insuranceVehicleCategoryId = pricing.categoryId;
                schedule.currency = this.insuranceProductManagerService.currency;
                schedules.push(schedule);
            })
        })
        return schedules;
    }

    private generateYCPrceIntervals(): YCPriceInterval[] {
        let intervals: YCPriceInterval[] = [];
        if (this.formGroupPricing.status === 'INVALID') {
            this.formGroupPricing.markAllAsTouched();
        } else {
            this.insuranceProductManagerService.intervalIds = [];

            const durationType = this.formGroupPricing.get('durationType').value;
            if (durationType === DurationType.INTERVAL) {
                this.intervalIDs.forEach((suffix) => {
                    let interval = new YCPriceInterval();
                    const intervalId = suffix.replace('-', '');
                    interval.ycPriceIntervalId = intervalId;
                    this.insuranceProductManagerService.intervalIds.push(intervalId);
                    let foundInterval: YCPriceInterval = null;
                    if (this.insuranceProductManagerService.product
                        && this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                        if (this.insuranceProductManagerService.product.ycPriceIntervals) {
                            foundInterval = this.insuranceProductManagerService.product.ycPriceIntervals
                                .find(interval => interval.ycPriceIntervalId == intervalId);
                        }
                    }
                    if (foundInterval) {
                        interval = foundInterval;
                    }

                    interval.durationType = DurationType.INTERVAL;
                    let days = this.formGroupPricing.get('intervalDurationToDays' + suffix).value;
                    let duration = new Duration();
                    duration.days = days;
                    interval.intervalDurationTo = duration;

                    days = this.formGroupPricing.get('intervalDurationFromDays' + suffix).value;
                    duration = new Duration();
                    duration.days = days;
                    interval.intervalDurationFrom = duration;

                    interval.proRata = this.formGroupPricing.get('proRata' + suffix).value;
                    interval.minimumPolicyValue = parseFloat(this.formGroupPricing.get('minimumPolicyValue' + suffix).value) * 100;
                    interval.newPolicyValue = this.formGroupPricing.get('newPolicyValue' + suffix).value * 100;
                    intervals.push(interval);
                })
            } else if (durationType === DurationType.PRO_RATA) {
                if (this.intervalIDs.length > 0) {
                    let interval = new YCPriceInterval();
                    const suffix = this.intervalIDs[0];
                    const intervalId = suffix.replace('-', '');
                    let foundInterval: YCPriceInterval = null;
                    if (this.insuranceProductManagerService.product
                        && this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                        if (this.insuranceProductManagerService.product.ycPriceIntervals) {
                            foundInterval = this.insuranceProductManagerService.product
                                .ycPriceIntervals.find(interval => interval.ycPriceIntervalId == intervalId);
                        }
                    }
                    if (foundInterval) {
                        interval = foundInterval;
                    }

                    interval.durationType = DurationType.PRO_RATA;
                    interval.minimumPolicyValue = parseFloat(this.formGroupPricing.get('minimumPolicyValue' + suffix).value) * 100;
                    intervals.push(interval);
                }

            }
        }

        return intervals;
    }

    onCountrySelect(countries: Country[]): void {
        const countryNames = countries.map(item => item.description);
        const revertCountries = this.selectedCountries;
        this.selectedCountries = [];
        let countriesWithData: string[] = [];
        this.priceScedule.pricing.forEach((pricing) => {
            pricing.coveredCountries.forEach((coveredCountry) => {
                if (!countryNames.includes(coveredCountry.name) && coveredCountry.price > 0) {
                    if (!countriesWithData.includes(coveredCountry.name)) {
                        countriesWithData.push(coveredCountry.name);
                    }
                }
            })
        });

        switch (countriesWithData.length) {
            case 0:
                this.storeCountries = countries;
                this.selectedCountries = this.storeCountries.map(item => item.description);
                break;
            default:
                const messageValue = `${countriesWithData.join(', ')}`;
        
                const message = this.translateParserParent.interpolate(
                    this.yellowCardTranslations?.VALIDATION_ERROR_MESSAGE.CONTAINS_PRICING, { value: messageValue });
                const dialogData: DialogDataModel = {
                    title: 'Country Select',
                    message,
                    error: false,
                    cancelText: 'Cancel',
                    confirmText: 'Remove',
                    hideIcons: true
                };
        
                this.dialog.open(CustomDialogComponent, {
                    width: '400px',
                    panelClass: 'padded-modal',
                    disableClose: true,
                    data: dialogData
                }).afterClosed().subscribe((data) => {
                    switch (data) {
                        case true:
                            this.storeCountries = countries;
                            this.priceScedule.pricing.forEach((pricing) => {
                                pricing.coveredCountries.forEach((coveredCountry) => {                    
                                    if (!countryNames.includes(coveredCountry.name) && coveredCountry.price > 0) {
                                        coveredCountry.price = 0;
                                    }
                                })
                            });
                            break;
                        default:
                            this.storeCountries = this.countryOptions.filter(item => revertCountries.includes(item.description));
                            break;
                    }
                    this.selectedCountries = this.storeCountries.map(item => item.description);
                });
                break;
        }
        
    }

    addInterval(): void {
        this.setEditableInterval(false);
        let suffix = `-${this.intervalIDs.length}`;
        let duration: number = 0;
        if (this.intervalIDs.length > 0) {
            const lastElement = this.intervalIDs[this.intervalIDs.length - 1];
            duration = Number(this.formGroupPricing.get('intervalDurationToDays' + lastElement).value) + 1;
        }
        this.intervalIDs.push(suffix);
        this.addIntervalControls(suffix, DurationType.INTERVAL, undefined, duration);
    }

    changeDurationType(event: MatSelectChange): void {
        this.intervalIDs = ['-0'];
        this.createPricingForm(event.value);
    }

    removeInterval(data): void {
        const index = this.intervalIDs.findIndex(value => value === data.suffix);
        if (index >= 0) {
            this.intervalIDs.splice(index, 1);
        }
        this.removeIntervalControls(data.suffix);
        this.setEditableInterval(true);
    }

    createPricingForm(durationType = DurationType.INTERVAL): void {
        this.buildPricingForm(durationType);

        this.intervalIDs.forEach((item) => {
            this.addIntervalControls(item, durationType);
        });
    }

    setPricingError(data): void {
        this.pricingError = data;
    }

    private setEditableInterval(enable: boolean, suffix?: string): void {
        suffix = suffix ? suffix : this.intervalIDs[this.intervalIDs.length - 1];
        const control = this.formGroupPricing.get('intervalDurationToDays' + suffix);
        if (enable) {
            control.enable();
        } else {
            control.disable();
        }
    }

    private buildPricingForm(durationType: DurationType): void {
        this.formGroupPricing = new UntypedFormGroup({
            durationType: new UntypedFormControl(durationType, [Validators.required]),
        })
    }

    private addIntervalControls(suffix: string, duartionType: DurationType, interval?: YCPriceInterval, daysFrom?: number): void {
        daysFrom = daysFrom ? daysFrom : 0;
        const intervalDurationFromDays = interval && interval.intervalDurationFrom ? interval.intervalDurationFrom.days : daysFrom;
        this.formGroupPricing.addControl('intervalDurationFromDays' + suffix,
            new UntypedFormControl({ value: intervalDurationFromDays, disabled: true }, []));
        const intervalDurationToDays = interval && interval.intervalDurationTo ? interval.intervalDurationTo.days : '';
        this.formGroupPricing.addControl('intervalDurationToDays' + suffix, new UntypedFormControl(intervalDurationToDays,
            duartionType === DurationType.INTERVAL ? [Validators.required, Validators.min(daysFrom + 1)] : []));
        const proRata = interval && interval.proRata ? interval.proRata : false;
        this.formGroupPricing.addControl('proRata' + suffix, new UntypedFormControl(proRata, []));
        const minimumPolicyValue = interval && interval.minimumPolicyValue ? interval.minimumPolicyValue / 100 : '';
        this.formGroupPricing.addControl('minimumPolicyValue' + suffix, new UntypedFormControl(minimumPolicyValue, [Validators.required]));
        const newPolicyValue = interval && interval.newPolicyValue ? interval.newPolicyValue / 100 : '';
        this.formGroupPricing.addControl('newPolicyValue' + suffix, new UntypedFormControl(newPolicyValue,
            duartionType === DurationType.INTERVAL ? [Validators.required, Validators.min(1), Validators.max(100)] : []));
    }

    private removeIntervalControls(suffix: string): void {
        this.formGroupPricing.removeControl('intervalDurationFromDays' + suffix);
        this.formGroupPricing.removeControl('intervalDurationToDays' + suffix);
        this.formGroupPricing.removeControl('proRata' + suffix);
        this.formGroupPricing.removeControl('minimumPolicyValue' + suffix);
        this.formGroupPricing.removeControl('newPolicyValue' + suffix);
    }

    private generateNewPricingScheduleModel(): void {
        this.priceScedule.pricing = [];
        this.insuranceProductManagerService.insuranceVehicleCategoriesSummaries.forEach((category) => {
            let addItem = {
                categoryId: category.insuranceVehicleCategoryId,
                categoryName: category.description.trim().toLocaleLowerCase() === category.subDescription.trim().toLocaleLowerCase() ?
                    category.description : `${category.description} - ${category.subDescription}`,
                coveredCountries: []
            };
            this.storeCountries.forEach((country) => {
                addItem.coveredCountries.push({ name: country.description, price: 0, id: uuid() },);
                if (!this.selectedCountries.includes(country.description)) {
                    this.selectedCountries.push(country.description);
                }
            })
            this.priceScedule.pricing.push(addItem);
        })
    }
}
