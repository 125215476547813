import { ImportVehicle } from "./import-vehicle";

export class VehicleImportError {
    constructor() {
        this['@class'] = "za.co.magnabc.tpi.retail.profile.VehicleImportError";
    }

    vin: string;
    plateNumber: string;
    manufactureDate: string;
    engineNumber: string;
    category: string;
    vehicleType: string;
    usage: string;
    propulsion: string;
    seatingCapacity: string;
    grossVehicleMass: string;
    countryOfRegistration: string;
    importVehicle: ImportVehicle;
}