import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, ValidationErrors, Validators} from "@angular/forms";
import {FormComponent} from "../form/form.component";
import {StringUtils} from "../../../common/utils/utils.string";
import {CountryContactNumberComponent} from "../country-code-number/country_contact_number.component";
import {ContactPerson, ContactNumber, CountryDialingIdentifier, EmailAddress, LegalEntity} from '@magnabc/tpi';
import { SystemParameterProviderService } from '../../../http.services/system/system-parameter-provider/system-parameter-provider.service';
import {Utils} from "../Utils/Utils";
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service';


declare const jQuery: any;

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
})
export class ContactPersonComponent extends FormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() contactPerson: ContactPerson;
    @Input() legalEntity: LegalEntity;
    @Input() title = "";
    @Input() disabled = false;
    @Input() obfuscated = false;
    @Output() onContactPerson = new EventEmitter<ContactPerson>();
    @Output() onValid = new EventEmitter<void>();
    @Output() onCountry = new EventEmitter<CountryDialingIdentifier>();
    @Output() onToggleShow = new EventEmitter<boolean>();

    utils = Utils;
    personCheckOne: boolean =false;


    suffix = "";
    formGroup: UntypedFormGroup;
    firstNames: UntypedFormControl;
    surname: UntypedFormControl;
    emailAddress: UntypedFormControl;
    relationship: UntypedFormControl;
    contactNumber: ContactNumber;
    contactNumberValid = true;

    @ViewChild('form') form: FormGroupDirective;

    @ViewChild('countyContactNumber') countyContactNumber: CountryContactNumberComponent;

    constructor(private translate: TranslateService, private translateParser: TranslateParser,
        public authenticationService: AuthenticationService, private systemParameterService : SystemParameterProviderService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        this.suffix = StringUtils.sanitiseNumber(this.title);

        if (this.contactPerson) {

            this.personCheckOne=true;

            const emailAddress = this.contactPerson.emailAddresses && this.contactPerson.emailAddresses[0] ? this.contactPerson.emailAddresses[0].address : '';

            this.firstNames = new UntypedFormControl(this.contactPerson.firstNames, [
                Validators.required,
                Validators.maxLength(50),
                this.noWhitespaceValidator
            ]);
            this.surname = new UntypedFormControl(this.contactPerson.surname, [
                Validators.required,
                Validators.maxLength(50),
                this.noWhitespaceValidator
            ]);

            if (this.contactPerson.contactNumbers[0] && this.contactPerson.contactNumbers[0].number) {
                this.contactPerson.contactNumbers[0].number = this.contactPerson.contactNumbers[0].number.replace("+", "");
            }

            this.emailAddress = new UntypedFormControl(emailAddress, [
                Validators.maxLength(254),
                this.customEmailValidator,
                Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
            ]);

            this.relationship = new UntypedFormControl(this.contactPerson.relationship, [
                Validators.maxLength(50)
            ]);

        } else {


            if(this.utils.isJudicialPerson(this.legalEntity))
            {
                this.personCheckOne = true;
            }

            this.contactPerson = new ContactPerson();
            this.firstNames = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(50),
                this.noWhitespaceValidator
            ]);
            this.surname = new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(50),
                this.noWhitespaceValidator
            ]);
            this.emailAddress = new UntypedFormControl('', [
                Validators.maxLength(254),
                this.customEmailValidator,
                Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9.-])+\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$")
            ]);
            this.relationship = new UntypedFormControl('', [
                Validators.maxLength(50)
            ]);

        }

            this.formGroup = new UntypedFormGroup({
                'firstNames': this.firstNames,
                'surname': this.surname,
                'emailAddress': this.emailAddress,
                'relationship': this.relationship
            });


    }

    customEmailValidator(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return null;
        }

        return Validators.email(control);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery('[data-toggle="tooltip"]').tooltip();
        });
    }

    ngOnDestroy(): void {
        jQuery('[data-toggle="tooltip"]').tooltip('dispose');
    }

    onCountryContactNumber(contactNumber): void {
        this.contactNumber = contactNumber;
    }

    onCountryChanged(event): void {
        this.onCountry.emit(event);
    }

    onCountryNumberValid(): void {
        document.getElementById('form-submit-contact-person-' + this.suffix).click();
    }

    submit(): void {

        if (this.personCheckOne) {
            if(this.form.form.invalid){
                this.onCheckValidation({});
            }else{
                this.countyContactNumber.submit();
            }
        } else {
            this.onValid.emit();
        }
    }

  onCheckValidation(event): void {
    this.form.form.markAllAsTouched();
    if(this.countyContactNumber){
        this.countyContactNumber.markAllAsTouched();
    }

    if (!this.form.invalid) {
      if (this.countyContactNumber) {
        this.countyContactNumber.onCheckValidation({});
      }
      this.contactNumberValid = this.countyContactNumber ? this.countyContactNumber.form.form.valid : true;

      if (!this.contactNumberValid) {
        return;
      }

      this.contactPerson.firstNames = this.firstNames.value;
      this.contactPerson.surname = this.surname.value;
      this.contactPerson.relationship = this.relationship.value;
      this.contactPerson.contactNumbers[0] = this.contactNumber;

      if (this.emailAddress.value && this.emailAddress.value !== '') {
        const email = new EmailAddress();
        email.address = this.emailAddress.value;
        email.emailAddressType = 'Primary';

        this.contactPerson.emailAddresses[0] = email;
      }

      this.onContactPerson.emit(this.contactPerson);
      this.onValid.emit();

    } else {
      this.formGroup.markAllAsTouched();

      const element: HTMLElement = document.querySelector("form .ng-invalid");
      const target = jQuery('form .ng-invalid:first');

      if (element) {
        jQuery('html,body').animate({ scrollTop: element.offsetTop }, 'slow');
      }
      if (target) {
        target.focus();
      }

    }
  }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    onPersonCheck(value): void {

        if (value) {
            this.personCheckOne  = true ;
        } else {
            this.formGroup.reset();
            this.personCheckOne = false;
        }

        this.onToggleShow.emit(value);
    }

    /* Contact Persons functions */
    getContactPerson(): ContactPerson {
        if (this.formGroup.valid) {

            const contactPerson = new ContactPerson();
            contactPerson.firstNames = this.firstNames.value;
            contactPerson.surname = this.surname.value;
            contactPerson.relationship = this.relationship.value;
            contactPerson.contactNumbers[0]  = this.countyContactNumber.getContactNumber();

            if (this.emailAddress.value && this.emailAddress.value !== '') {
                const email = new EmailAddress();
                email.address = this.emailAddress.value;
                email.emailAddressType = 'Primary';
                contactPerson.emailAddresses[0] = email;
            }

            return contactPerson;
        }

        return null;
    }

    isDirty() {
        if(this.personCheckOne && this.countyContactNumber!=null)
        {
            return this.form && this.form.dirty || this.countyContactNumber.isDirty();
        }
        else{
            return true;
        }
    }
}
