import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Utils} from "../../../../shared/Utils/Utils";
import {QueryLegalEntityInformationManagerService} from "../../../../../http.services/entity/query-legal-entity-information-manager/query-legal-entity-information-manager.service";
import {
    JudicialPerson,
    LegalEntityProviderCriteria,
    QueryLegalEntityInformationRequest,
    QueryLegalEntityInformationResponse,
    SubscriptionOwner,
    TpiIdentifier,
    UnMarshallerService
} from '@magnabc/tpi';
import {RaygunErrorHandler} from '../../../../../common/utils/utils.raygun';
import {AuthenticationService} from "../../../../../app.services/managers/authentication/authentication.service";
import {Subscription as RxJsSubscription} from "rxjs/internal/Subscription";
import { QuickTransactManagerService } from '../../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service';

@Component({
    selector: 'app-determine-personal-or-business-transaction',
    templateUrl: './determine-personal-or-business-transaction.component.html',
    styleUrls: ['./determine-personal-or-business-transaction.component.scss']
})
export class DeterminePersonalOrBusinessTransactionComponent implements OnInit, OnDestroy {

    @Output() onSelected = new EventEmitter<void>();

    utils = Utils;

    judicialList: SubscriptionOwner[] = [];
    judicialMap: {[id: string]: JudicialPerson} = {};
    judicialKey: {[id: string]: boolean} = {};

    proxies: SubscriptionOwner[] = [];
    proxySubscriber: RxJsSubscription;

    constructor(private router: Router,
                public quickStartService: QuickTransactManagerService,
                private queryLegalEntityInformationManagerService: QueryLegalEntityInformationManagerService,
                public authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService) { }

    ngOnInit() {

        this.authenticationService.refreshPublicAuthentication();
        this.proxySubscriber = this.authenticationService.proxiesUpdated.subscribe(val => {
            if (val) {
                this.loadProxies();
            }
        });

        this.authenticationService.clearActiveProxySubscription();
        this.loadProxies();

    }

    onJudicialPerson(event): void {
        this.router.navigate(['/create/company'], {queryParams: {quickTransact: true}});
    }

    onType(type): void {
        this.quickStartService.type = type;
        if (this.quickStartService.type === 'personal') {
            this.quickStartService.selectedLegalEntity = null;
            this.onSelected.emit();
        }
    }

    onLegalEntitySelected(legalEntity: JudicialPerson): void {
        this.quickStartService.selectedLegalEntity = legalEntity;
        this.onSelected.emit();
    }

    getJudicialPerson(identifier: TpiIdentifier): void {

        const legalEntityProviderCriteria = new LegalEntityProviderCriteria();
        legalEntityProviderCriteria.legalEntityIdentifier = identifier;
        legalEntityProviderCriteria.type = 'INTERNAL_PUBLIC';

        const queryLegalEntityInformationRequest = new QueryLegalEntityInformationRequest()
        queryLegalEntityInformationRequest.criteria = legalEntityProviderCriteria;

        this.queryLegalEntityInformationManagerService.provideLegalEntity(queryLegalEntityInformationRequest).then((httpResponse) => {

            if (httpResponse && httpResponse.body) {
                const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, QueryLegalEntityInformationResponse) as QueryLegalEntityInformationResponse);
                if (response.legalEntities.length > 0) {
                    this.onLegalEntitySelected(response.legalEntities[0] as JudicialPerson);
                }
            }
        }).catch(exception=>{
            RaygunErrorHandler.sendError(exception);

            console.log(exception);
        });

    }

    onSwitchAccount(loginSubscription: SubscriptionOwner): boolean {
        this.authenticationService.setActiveProxySubscription(loginSubscription.subscriptions);
        this.getJudicialPerson(loginSubscription.subscriptions[0].ownedBy);
        return false;
    }

    loadProxies() {
        this.proxies = [];
        this.judicialList = [];
        for (const key of this.authenticationService.getLoginSubscriptions().keys()) {
            this.proxies.push(this.authenticationService.getLoginSubscriptions().get(key));
        }

        for (const key of this.authenticationService.getJudicialPersonSubscriptions().keys()) {
            this.proxies.push(this.authenticationService.getJudicialPersonSubscriptions().get(key));
            this.judicialList.push(this.authenticationService.getJudicialPersonSubscriptions().get(key));
        }
    }

    ngOnDestroy(): void {

        if (this.proxySubscriber) {
            this.proxySubscriber.unsubscribe();
            this.proxySubscriber = null;
        }

    }

}
