import {Criteria} from '../../../../../../criteria/models/criteria.model';
import { Request } from '../../../../../../urdad/services/models/request.model';
import { UnderwriterIdentifier } from '../../../../identification/models/underwriter-identifier.model';

export class QueryInsurancePolicyInformationRequest extends Request{
    constructor(){
        super();
    }

    public batchSize: number;
    public batchNumber : number;
    public criteria: Criteria;
    public underwriterIdentifier: UnderwriterIdentifier;
}
