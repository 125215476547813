import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormComponent } from "../../../../../shared/form/form.component";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import {
    AgentLegalEntityIdentifier,
    ComprehensiveInsuranceProduct,
    Country,
    CountryDescriptionIdentifier,
    InsuranceCategory,
    InsuranceType,
    Money,
    PolicyWording,
    PolicyWordingUnderwriterCategoryCriteria,
    ProvidePolicyWordingRequest,
    ProvidePolicyWordingResponse,
    ThirdPartyInsuranceProduct,
    Underwriter,
    UnMarshallerService,
    InsuranceVehicleCategory,
    YCInsuranceProduct,
    CountryIdentifier,
} from '@magnabc/tpi';
import { Dropdown } from "../../../../../../common/model/dropdown.model";
import { InsuranceProductManagerService } from '../../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';
import { ActiveToast, ToastrService } from "ngx-toastr";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PolicyWordingProviderService } from "../../../../../../http.services/retail/insurance/setup/policy-wording-provider/policy-wording-provider.service";
import { environment } from "../../../../../../../environments/environment";
import { RaygunErrorHandler } from "../../../../../../common/utils/utils.raygun";
import { promise } from "protractor";
import {
    UnderwriterManagerService
} from '../../../../../../app.services/managers/underwriter-manager/underwriter-manager.service'
import {
    RuntimeConfigurationService
} from '../../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import { ToastComponent } from "../../../../../shared/toast/toast.component";
declare const jQuery: any;

@Component({
    selector: 'app-product-details',
    templateUrl: './retail.products.details.component.html',
    styleUrls: ['./retail.products.details.component.scss']
})
export class RetailProductsDetailsComponent extends FormComponent implements OnInit {

    @Input() product: any;
    @Input() currentState;
    @Input() editing: boolean;
    @Input() underwriters: Underwriter[] = [];
    @Output() onProduct = new EventEmitter<ThirdPartyInsuranceProduct | ComprehensiveInsuranceProduct | YCInsuranceProduct>();
    @Output() onNext = new EventEmitter<void>();

    assignedBrokers = [];
    unAssignedBrokers = [];

    activeToast: ActiveToast<any>;
    countries: Country[];
    policyWordings: PolicyWording[];
    insuranceTypes = [];
    insuranceVehicleCategoryList = [];
    categories = [
        { value: InsuranceCategory.THIRDPARTY, name: 'Third Party', disabled: true },
        { value: InsuranceCategory.COMPREHENSIVE, name: 'Comprehensive', disabled: true },
        { value: InsuranceCategory.YELLOWCARD, name: 'Yellow Card', disabled: true }
    ];
    insuranceCategories = InsuranceCategory;

    insuranceCategoryHide = true;
    insuranceProductTypeHide = true;
    insuranceShowAnnualPercentage = false;
    displayDropDown = FormComponent.displayDropDown;

    urlLink: UntypedFormControl;
    formGroup: UntypedFormGroup;
    name: UntypedFormControl;
    category: UntypedFormControl;
    insuranceVehicleCategory: UntypedFormControl;
    insuranceType: UntypedFormControl;
    conditions: UntypedFormControl;
    description: UntypedFormControl;
    annualAmount: UntypedFormControl;
    annualPercentage: UntypedFormControl;
    identifier: UntypedFormControl;
    typePlaceholder: string;
    underwriterName: string;
    hasSellingToCountries: boolean = false;
    readonly defaultAgent = 'FCA Ltd';

    @ViewChild('form') form: FormGroupDirective;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                public insuranceProductManagerService: InsuranceProductManagerService,
                public underwriterManagerService: UnderwriterManagerService,
                private fb: UntypedFormBuilder,
                private policyWordingProviderService: PolicyWordingProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService,
                private toastrService: ToastrService
                ) {
        super(translate, translateParser);

        this.underwriterName = this.insuranceProductManagerService.underwriter && this.insuranceProductManagerService.underwriter.name;

        for (const agentIdentifier of insuranceProductManagerService.agentIdentifiers) {
            if (agentIdentifier instanceof AgentLegalEntityIdentifier) {
                if (this.underwriterName !== agentIdentifier.name && agentIdentifier.name !== this.defaultAgent) {
                    this.unAssignedBrokers.push(agentIdentifier.name);
                }
                else {
                    this.assignedBrokers.push(agentIdentifier.name);
                }
            }
        }
        this.categories.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    ngOnInit(): void {
        this.startLoad();
        this.getSellingCountryRule(this.insuranceProductManagerService.underwriter.countryIdentifier).then(() => {
            this.getInsuranceCategories().then(() => {
                this.stopLoad();
                if (this.insuranceProductManagerService.ycSellingCountryRules.length < 1) {
                    this.categories.filter(category => category.value === InsuranceCategory.YELLOWCARD)[0].disabled = true;
                }
             }).catch((error) => {
                this.stopLoad();
                RaygunErrorHandler.sendError(error);
             });
        }).catch((error) => {
            this.stopLoad();
            RaygunErrorHandler.sendError(error);
        });
        this.checkInsuranceType();
        this.init();
        this.checkSelectedInsuranceCategory();
    }

    init(): void {
        if (this.currentState === 0) {
            this.productTypeFormCreation();

        } else if (this.currentState === 1) {
            this.productDetailsFormCreation();

        } else if (this.currentState === 2) {
            if (this.product.agentIdentifiers.length > 0) {
                for (const agentIdentifier of this.product.agentIdentifiers) {
                    for (const unAssignedBroker of this.unAssignedBrokers) {
                        if (agentIdentifier instanceof AgentLegalEntityIdentifier) {
                            if (agentIdentifier.name === unAssignedBroker) {
                                this.assignedBrokers.push(agentIdentifier.name);
                                const index = this.unAssignedBrokers.indexOf(unAssignedBroker);
                                this.unAssignedBrokers.splice(index, 1);
                            }
                        }
                    }
                }
            }

        } else if (this.currentState === 3) {
            this.getPolicyWording();
            if (!this.product.conditions) {
                this.product.conditions = this.runtimeConfigurationService.productConditions;
            }
            this.urlLink = new UntypedFormControl(this.product.conditions, [
                Validators.required
            ]);

            this.formGroup = new UntypedFormGroup({
                'urlLink': this.urlLink
            });
        }
    }

    onCountry($event) {
        this.insuranceProductManagerService.selectedSellToCountry = $event;
    }

    checkInsuranceType() {
        const found = this.insuranceProductManagerService.insuranceTypes.filter(type => type.insuranceCategory === InsuranceCategory.YELLOWCARD);
        if (found.length > 0) {
            this.hideForYCProduct();
        }
    }

    productTypeFormCreation() {
        this.setProductDataValidation();
        if (this.product instanceof ThirdPartyInsuranceProduct) {
            this.category = new UntypedFormControl({ value: InsuranceCategory.THIRDPARTY, disabled: this.insuranceProductManagerService.disabledFields }, [
                Validators.required
            ]);
        } else if (this.product instanceof ComprehensiveInsuranceProduct) {
            this.category = new UntypedFormControl({ value: InsuranceCategory.COMPREHENSIVE, disabled: this.insuranceProductManagerService.disabledFields }, [
                Validators.required
            ]);
        } else if (this.product instanceof YCInsuranceProduct) {
            this.category = new UntypedFormControl({ value: InsuranceCategory.YELLOWCARD, disabled: this.insuranceProductManagerService.disabledFields }, [
                Validators.required
            ]);
            this.hideForYCProduct();
        } else {
            this.category = new UntypedFormControl(null, [
                Validators.required
            ]);
        }

        if (this.insuranceCategoryHide && this.insuranceProductTypeHide) {
            this.formGroup = new UntypedFormGroup({
                'category': this.category
            });
        } else if (this.insuranceCategoryHide) {
            this.formGroup = new UntypedFormGroup({
                'category': this.category,
                'insuranceVehicleCategory': this.insuranceVehicleCategory
            });
            if (!this.product) {
                this.formGroup.controls.insuranceVehicleCategory.disable()
            }
        } else {
            this.formGroup = new UntypedFormGroup({
                'category': this.category,
                'insuranceType': this.insuranceType,
                'insuranceVehicleCategory': this.insuranceVehicleCategory

            });
            if (!this.product) {
                this.formGroup.controls.insuranceType.disable()
                this.formGroup.controls.insuranceVehicleCategory.disable()
            }
        }

    }

    productDetailsFormCreation(): void {
        if (this.product instanceof ThirdPartyInsuranceProduct) {
            this.typePlaceholder = "Annual Amount";

            if (this.product.annualAmount) {
                this.identifier = new UntypedFormControl(this.product.annualAmount.amount / 100, [
                    Validators.required,
                    Validators.min(0.01)
                ]);
            } else {
                this.identifier = new UntypedFormControl(null, [
                    Validators.required,
                    Validators.min(0.01)
                ]);
            }

        } else if (this.product instanceof ComprehensiveInsuranceProduct) {
            this.typePlaceholder = "Annual Percentage";

            this.identifier = new UntypedFormControl(this.product.annualPercentage, [
                Validators.required,
                Validators.max(100)
            ]);
        }
        this.name = new UntypedFormControl(this.product.name, [
            Validators.required,
            Validators.maxLength(70)
        ]);

        this.description = new UntypedFormControl(this.product.description, [
            Validators.required,
            Validators.maxLength(250)
        ]);

        if (this.product instanceof YCInsuranceProduct) {
            this.insuranceShowAnnualPercentage = true;
        } else {
            this.insuranceShowAnnualPercentage = false;
        }

        if (this.insuranceShowAnnualPercentage) {
            this.formGroup = new UntypedFormGroup({
                'name': this.name,
                'description': this.description
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                'name': this.name,
                'description': this.description,
                'identifier': this.identifier,
            });
        }
    }

    /**
     * Sets product data validation
     */
    setProductDataValidation() {
        if (this.product) {
            this.filterCategories();
            if (this.product instanceof ThirdPartyInsuranceProduct || this.product instanceof ComprehensiveInsuranceProduct) {
                this.setInsuranceTypes();
                // Populate vehicle dropdown
                this.setInsuranceVehicleCategoryList();
                this.unhideForTPIAndComprehensiveProducts();
            }
            if (!this.insuranceProductTypeHide) {
                for (const checkList of this.insuranceTypes) {
                    if (this.product.insuranceType) {
                        if (this.product.insuranceType.description === checkList.name) {
                            this.insuranceType = new UntypedFormControl({
                                value: this.getDropdown(this.insuranceTypes, checkList.name),
                                disabled: this.insuranceProductManagerService.disabledFields
                            }, [
                                Validators.required
                            ]);
                        }
                    } else {
                        this.insuranceCategoryHide = true;
                    }

                    for (const checkList of this.insuranceVehicleCategoryList) {
                        if (this.product.insuranceVehicleCategory.subDescription === checkList.name) {
                            this.insuranceVehicleCategory = new UntypedFormControl({
                                value: this.getDropdown(this.insuranceVehicleCategoryList,
                                    checkList.name), disabled: this.insuranceProductManagerService.disabledFields
                            }, [
                                Validators.required
                            ]);
                        }
                    }
                }
            }
        } else {
            this.insuranceType = new UntypedFormControl(null, [
                Validators.required
            ]);
            this.insuranceVehicleCategory = new UntypedFormControl(null, [
                Validators.required
            ]);
        }
    }

    /**
     * Adds insurance vehicle category control
     */
    addInsuranceVehicleCategoryControl(): void {
        const getControl = this.formGroup.get('insuranceVehicleCategory');
        if (getControl === null) {
            this.formGroup.addControl('insuranceVehicleCategory', this.fb.control([]));
        }
    }

    /**
     * Initializes product type: when insurance type is clicked
     * @param event
     */
    onChangeInsuranceType(event) {
        this.startLoad();
        this.insuranceProductManagerService.insuranceCategory = event;
        this.initializeProduct().then(() => { });
        switch (event) {
            case this.insuranceCategories.THIRDPARTY:
                this.product = new ThirdPartyInsuranceProduct();
                this.formGroup.addControl('insuranceType', this.fb.control([]));
                this.addInsuranceVehicleCategoryControl();
                this.formGroup.controls.insuranceType.enable()
                this.formGroup.controls.insuranceVehicleCategory.enable()
                this.insuranceProductTypeHide = false;
                this.insuranceCategoryHide = false;
                break;
            case this.insuranceCategories.COMPREHENSIVE:
                this.product = new ComprehensiveInsuranceProduct()
                this.formGroup.removeControl('insuranceType');
                this.addInsuranceVehicleCategoryControl();
                this.formGroup.controls.insuranceVehicleCategory.enable()
                this.insuranceCategoryHide = true;
                this.insuranceProductTypeHide = false;
                break;
            case this.insuranceCategories.YELLOWCARD:
                this.product = new YCInsuranceProduct();
                this.hideForYCProduct();
                this.removeControls();
                break;
        }
        this.stopLoad();
    }


    /**
     * Removes controls
     * When Yellow Card product is created
     */
    removeControls() {
        this.formGroup.removeControl('insuranceType');
        this.formGroup.removeControl('insuranceVehicleCategory');
    }

    /**
     * Hides for yellow card product
     */
    hideForYCProduct() {
        this.insuranceCategoryHide = true;
        this.insuranceProductTypeHide = true;
    }

    /**
     * Unhide for TPI and Comprehensive products
     */
    unhideForTPIAndComprehensiveProducts() {
        this.insuranceCategoryHide = false;
        this.insuranceProductTypeHide = false;
    }

    /**
     * Determines whether check validation on
     * @param event
     */
    onCheckValidation(event): void {

        if (!this.form.invalid) {
            if (this.currentState === 0) {

                if (this.product instanceof YCInsuranceProduct && this.insuranceProductManagerService.sellingToCountries.length === 0) {

                    this.translate.get('TOAST.NO_SELLING_TO_COUNTRIES').subscribe((res: any) => {
                        this.activeToast = this.toastrService.error(res.MESSAGE, res.TITLE, {
                            toastComponent: ToastComponent,
                            timeOut: environment.toast_time_out,
                            progressBar: true
                        });
                    });

                    return;
                }

                this.product.active = false;
                this.product.baseCurrency = this.insuranceProductManagerService.currency
                if (this.product instanceof YCInsuranceProduct){
                    this.product.broker = this.insuranceProductManagerService.brokerIdentifier;
                    this.product.underwriter = this.insuranceProductManagerService.broker.underwriterIdentifiers[0];
                } else {
                    this.product.brokerIdentifier = this.insuranceProductManagerService.brokerIdentifier;
                    this.product.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier
                }
                if (this.product.coveredCountries.length === 0 && !(this.product instanceof YCInsuranceProduct)) {
                    for (const country of this.insuranceProductManagerService.countries) {
                        const countryDescriptionIdentifier = new CountryDescriptionIdentifier();
                        countryDescriptionIdentifier.description = country.description
                        this.product.coveredCountries.push(countryDescriptionIdentifier);
                    }
                }

                this.product.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier

                if (this.product instanceof ThirdPartyInsuranceProduct) {
                    const insuranceType = new InsuranceType();
                    insuranceType.insuranceCategory = InsuranceCategory.THIRDPARTY
                    insuranceType.description = this.formGroup.value.insuranceType.name;
                    insuranceType.brokerIdentifier = this.insuranceProductManagerService.brokerIdentifier;
                    this.product.insuranceType = insuranceType;
                }

                if (this.product instanceof ThirdPartyInsuranceProduct || this.product instanceof ComprehensiveInsuranceProduct) {
                    for (const insuranceVehicleCategoriesSummary of this.insuranceProductManagerService.insuranceVehicleCategoriesSummaries) {
                        if (insuranceVehicleCategoriesSummary.subDescription === this.formGroup.value.insuranceVehicleCategory.name) {
                            if (this.product.insuranceVehicleCategory && this.product.insuranceVehicleCategory.subDescription === this.formGroup.value.insuranceVehicleCategory.name) {

                            } else {
                                const insuranceVehicleCategory = new InsuranceVehicleCategory()
                                insuranceVehicleCategory.insuranceVehicleCategoryId = insuranceVehicleCategoriesSummary.insuranceVehicleCategoryId
                                insuranceVehicleCategory.subDescription = insuranceVehicleCategoriesSummary.subDescription
                                insuranceVehicleCategory.description = insuranceVehicleCategoriesSummary.description
                                this.product.insuranceVehicleCategory = insuranceVehicleCategory
                            }
                        }
                    }
                }

            } else if (this.currentState === 1) {

                this.product.name = this.formGroup.value.name;
                this.product.description = this.formGroup.value.description;
                if (this.product instanceof ThirdPartyInsuranceProduct) {
                    const money = new Money();
                    money.amount = this.formGroup.value.identifier * 100;
                    money.currency = this.insuranceProductManagerService.currency;
                    this.product.annualAmount = money;
                } else if (this.product instanceof YCInsuranceProduct) {
                    this.product.sellingToCountry = this.insuranceProductManagerService.selectedSellToCountry;
                } else {
                    this.product.annualPercentage = this.formGroup.value.identifier;

                }

            } else if (this.currentState === 3) {
                this.product.conditions = this.formGroup.value.urlLink
            }

            this.onProduct.emit(this.product);
            this.onNext.emit();

        } else {
            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }
    }

    /**
     * Assigns brokers
     */
    assignBrokers(): void {

        this.product.agentIdentifiers = [];
        for (const agentIdentifier of this.insuranceProductManagerService.agentIdentifiers) {
            if (agentIdentifier instanceof AgentLegalEntityIdentifier) {
                for (const assignedBroker of this.assignedBrokers) {
                    if (agentIdentifier.name === assignedBroker) {
                        this.product.agentIdentifiers.push(agentIdentifier);
                    }
                }
            }
        }

        this.onNext.emit();
    }

    /**
     * Gets policy wording
     */
    getPolicyWording(): void {

        const policyWordingUnderwriterCategoryCriteria = new PolicyWordingUnderwriterCategoryCriteria();

        policyWordingUnderwriterCategoryCriteria.underwriterIdentifier = this.product.underwriterIdentifier;
        if (this.product instanceof ThirdPartyInsuranceProduct) {
            policyWordingUnderwriterCategoryCriteria.insuranceCategory = InsuranceCategory.THIRDPARTY
            policyWordingUnderwriterCategoryCriteria.insuranceVehicleCategory = [this.product.insuranceVehicleCategory.subDescription];
        } else if (this.product instanceof ComprehensiveInsuranceProduct) {
            policyWordingUnderwriterCategoryCriteria.insuranceCategory = InsuranceCategory.COMPREHENSIVE
            policyWordingUnderwriterCategoryCriteria.insuranceVehicleCategory = [this.product.insuranceVehicleCategory.subDescription];
        } else if (this.product instanceof YCInsuranceProduct) {
            policyWordingUnderwriterCategoryCriteria.insuranceCategory = InsuranceCategory.YELLOWCARD
            policyWordingUnderwriterCategoryCriteria.insuranceVehicleCategory = [];
        }

        const providePolicyWordingRequest = new ProvidePolicyWordingRequest();
        providePolicyWordingRequest.criteria = policyWordingUnderwriterCategoryCriteria;

        this.startLoad();
        this.policyWordingProviderService.providePolicyWording(providePolicyWordingRequest).then((response) => {
            this.stopLoad()
            const providePolicyWordingResponse = this.unMarshallerService.unMarshallFromJson(response.body, ProvidePolicyWordingResponse) as ProvidePolicyWordingResponse;
            this.policyWordings = providePolicyWordingResponse.policyWording;
        }).catch(error => {
            this.stopLoad();
        });

    }

    downloadTsAndCsPdf(event, policyWording): void {

        let filepdf = 'data:application/pdf;base64,' + policyWording.policyDocument;
        let fileholder = document.createElement('a');
        fileholder.href = filepdf;
        fileholder.download = policyWording.name;
        fileholder.click();
        event.preventDefault();
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    /**
     * Submits retail products details component
     */
    submit(): void {
        document.getElementById('form-submit-details').click();
    }

    /**
     * Sorts display lists
     */
    sortDisplayLists() {
        this.insuranceVehicleCategoryList.sort((val1, val2) => val1.name.localeCompare(val2.name));
        this.insuranceTypes.sort((val1, val2) => val1.name.localeCompare(val2.name));
        this.categories.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    /**
     * Provides insurance types
     */
    getInsuranceCategories(): Promise<void> {
        this.startLoad();
        return new Promise<void>((resolve, reject) => {
            this.insuranceProductManagerService.getInsuranceTypes(this.insuranceProductManagerService.broker).then((results) => {
                this.filterCategories();
                resolve();
                this.stopLoad();
            }).catch((error) => {
                RaygunErrorHandler.sendError(error);
                reject(error);
                this.stopLoad();
            });
        })
    }

    getSellingCountryRule(countryIdentifier: CountryIdentifier): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.insuranceProductManagerService.getSellingCountryRule(countryIdentifier).then((results) => {
                resolve();
            }).catch((error) => {
                RaygunErrorHandler.sendError(error);
                reject(error);
            });
        });
    }

    /**
     * Filters categories
     */
    filterCategories() {
        let newInsurance = [];
        newInsurance = this.insuranceProductManagerService.insuranceTypes;
        const tempArray = newInsurance;
        newInsurance = [];
        tempArray.forEach(insuranceType => {
            const found = newInsurance.filter(type => type.name === insuranceType.insuranceCategory);
            if (found.length === 0) {
                newInsurance.push({ value: insuranceType, name: insuranceType.insuranceCategory });
            }
        })
        newInsurance.forEach((item, index) => {
            for (const insuranceType of this.categories) {
                if (insuranceType.value === item.name) {
                    insuranceType.disabled = false;
                }
            }
        });
    }

    /**
     * Initializes product
     * @returns product
     */
    initializeProduct(): Promise<void> {
        this.startLoad();
        return new Promise<void>((resolve, reject) => {
            this.insuranceProductManagerService.initialiseInsuranceProduct().then((response) => {
                this.insuranceProductManagerService.getInsuranceTypes(this.insuranceProductManagerService.broker).then(() => {
                    // Populate insurance type dropdown
                    this.setInsuranceTypes();
                    // Populate vehicle dropdown
                    this.setInsuranceVehicleCategoryList();
                    this.sortDisplayLists();
                    resolve();
                    this.stopLoad();
                }).catch((error) => {
                    RaygunErrorHandler.sendError(error);
                    reject(error);
                    this.stopLoad();
                });
            });
        });
    }

    /**
     * Sets insurance types
     */
    setInsuranceTypes() {
        this.insuranceTypes = [];
        for (const insuranceType of this.insuranceProductManagerService.insuranceTypes) {
            if (!this.getDropdownExact(this.insuranceTypes, insuranceType.description)) {
                if (insuranceType.insuranceCategory === this.insuranceProductManagerService.insuranceCategory)
                    this.insuranceTypes.push(new Dropdown(insuranceType, insuranceType.description));
            }
        }
    }

    /**
     * Sets insurance vehicle category list
     */
    setInsuranceVehicleCategoryList() {
        this.insuranceVehicleCategoryList = [];
        for (const insuranceVehicleCategoriesSummary of this.insuranceProductManagerService.insuranceVehicleCategoriesSummaries) {
            if (!this.getDropdownExact(this.insuranceVehicleCategoryList, insuranceVehicleCategoriesSummary.subDescription)) {
                this.insuranceVehicleCategoryList.push(new Dropdown(insuranceVehicleCategoriesSummary, insuranceVehicleCategoriesSummary.subDescription));
            }
        }
    }

    /**
     * Initialise product details when insurance category is already selected
     */
    checkSelectedInsuranceCategory() {
        for (const insuranceType of this.categories) {
            if (this.category && insuranceType.value === this.category.value) {
                this.insuranceProductManagerService.insuranceCategory = insuranceType.value;
                this.insuranceProductManagerService.initialiseInsuranceProduct().then(() => {});
            }
        }
    }
}
