import {Injectable} from "@angular/core"
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router"
import {
  ServiceGroupFinalisationManagerService
} from "../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service"
import {AuthenticationService} from '../../managers/authentication/authentication.service'
import {Observable} from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService,
                private serviceGroup: ServiceGroupFinalisationManagerService,
                private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!route.data.services || route.data.services.length < 1){
            return true;
        }

        if (this.authenticationService.isAuthenticated() || this.authenticationService.isBackOfficeUser()) {
            return this.authenticationService.isAuthorised(route.data.services);
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
