import {AfterViewInit, Component, EventEmitter, Output} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../../../environments/environment";
import {ToastComponent} from "../../../../shared/toast/toast.component";
import {ToastrService} from "ngx-toastr";
import {FormComponent} from "../../../../shared/form/form.component";
import {
    ComprehensiveInsuranceProduct,
    CountryIdentifier,
    Duration,
    Excess,
    InsuranceProductTemplate,
    InsuranceType,
    InsuranceVehicleCategory,
    Money,
    ThirdPartyInsuranceProduct,
    UniversalExcess,
    YCInsuranceProduct
} from '@magnabc/tpi';
import {StringUtils} from "../../../../../common/utils/utils.string";
import {InsuranceProductManagerService} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service';
import { UnderwriterInformationProviderService } from "../../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service";
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
@Component({
    selector: 'app-product-template',
    templateUrl: './retail.products.template.component.html',
    styleUrls: ['./retail.products.template.component.scss']
})
export class RetailProductsTemplateComponent extends FormComponent implements AfterViewInit {

    @Output() onNext = new EventEmitter<void>();

    insuranceType: InsuranceType;
    coveragePeriod: Duration = new Duration();
    insuranceVehicleCategory: InsuranceVehicleCategory;

    grid: {[id: string]: boolean} = {};
    map: {[id: string]: InsuranceProductTemplate} = {};
    insuranceProductTemplate: InsuranceProductTemplate;
    countryDescriptionIdentifier: CountryIdentifier;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private toastr: ToastrService,
                public retailProductService: InsuranceProductManagerService,
                public brokerService: UnderwriterInformationProviderService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        if (this.retailProductService.insuranceTypes.length > 0) {
            this.insuranceType = this.retailProductService.insuranceTypes[0];
        }

        if (this.retailProductService.product instanceof ThirdPartyInsuranceProduct) {
            if (this.retailProductService.product.insuranceType) {
                for (const type of this.retailProductService.insuranceTypes) {
                    if (this.retailProductService.product.insuranceType.description === type.description) {
                        this.insuranceType = type;
                    }
                }
            }
        }
        //TODO: Templete does not exist anymore. Do be removed.
        // (this.retailProductService.insuranceProductTemplates as any[]).sort((first, second) => {
        //     if (first.insuranceVehicleCategory.description > second.insuranceVehicleCategory.description) {
        //         return 1;
        //     }
        //     if (first.insuranceVehicleCategory.description < second.insuranceVehicleCategory.description) {
        //         return -1;
        //     }
        //     return first.coveragePeriod.months - second.coveragePeriod.months;
        // })

        // for (const template of this.retailProductService.insuranceProductTemplates as any[]) {
        //     this.map[template.coveragePeriod.months + template.insuranceVehicleCategory.description + template.insuranceType.description] = template;
        //     this.countryDescriptionIdentifier = template.countryIdentifier;
        // }

    }

    ngAfterViewInit(): void {
        this.retailProductService.custom = false;
    }

    //TODO : Product coverage period and price to be fixed
    onSelectOption(insuranceProductTemplate: InsuranceProductTemplate): void {

        this.insuranceProductTemplate = insuranceProductTemplate;

        if (!this.retailProductService.custom) {

            if ((this.retailProductService.product instanceof ThirdPartyInsuranceProduct || this.retailProductService.product instanceof ComprehensiveInsuranceProduct || this.retailProductService.product instanceof YCInsuranceProduct)) {

                if (this.retailProductService.product instanceof YCInsuranceProduct) {
                    this.retailProductService.product.limits = (this.insuranceProductTemplate as any).limit;
                } else {
                    this.retailProductService.product.limit = (this.insuranceProductTemplate as any).limit;
                }
                this.retailProductService.product.insuranceVehicleCategory = (this.insuranceProductTemplate as any).insuranceVehicleCategory;
                //this.retailProductService.product.coveragePeriod = (this.insuranceProductTemplate as any).coveragePeriod;
                this.retailProductService.product.excess = (this.insuranceProductTemplate as any).excess;
                this.retailProductService.product.coveredCountries = [];
                this.retailProductService.product.coveredCountries.push((this.insuranceProductTemplate as any).countryIdentifier);
                this.retailProductService.product.conditions = this.runtimeConfigurationService.productConditions;

            }

            if (this.retailProductService.product instanceof ThirdPartyInsuranceProduct) {
                this.retailProductService.product.insuranceType = (this.insuranceProductTemplate as any).insuranceType;
            }

        } else {

            if (this.retailProductService.product instanceof YCInsuranceProduct)  {
                this.retailProductService.product.limits = [];
            } else {
                this.retailProductService.product.limit = [];
            }
            this.retailProductService.product.coveredCountries = [];
            this.retailProductService.product.coveredCountries.push(this.countryDescriptionIdentifier);
            this.retailProductService.product.insuranceVehicleCategory = new InsuranceVehicleCategory();
            this.retailProductService.product.insuranceVehicleCategory.description = "";
            this.retailProductService.product.excess = [this.createExcess()];
            this.retailProductService.product.conditions = this.runtimeConfigurationService.productConditions;

            if (this.retailProductService.product instanceof ThirdPartyInsuranceProduct) {
                this.retailProductService.product.insuranceType = this.insuranceType;
            }

        }

    }

    createExcess(): Excess {

        const excess = new UniversalExcess(null);
        excess.description = "";
        excess.percentage = 0;

        const money = new Money;
        money.amount = 0;
        money.currency = this.retailProductService.currency;
        excess.value = money;

        return excess;

    }

    onSelectCoverageAndCategory(insuranceProductTemplate: InsuranceProductTemplate): void {
        this.retailProductService.custom = false;
        this.coveragePeriod = (insuranceProductTemplate as any).coveragePeriod;
        this.insuranceVehicleCategory = (insuranceProductTemplate as any).insuranceVehicleCategory;
        this.onSelectOption(this.map[this.coveragePeriod.months + this.insuranceVehicleCategory.description + this.insuranceType.description]);
    }

    onSelectCustomProduct(): void {
        this.retailProductService.custom = true;
        this.coveragePeriod.months = 0;
        this.onSelectOption(null);
        this.onNext.emit();
    }

    onSelectCover(insuranceType: InsuranceType): void {
        this.insuranceType = insuranceType;
        if (this.retailProductService.product instanceof ThirdPartyInsuranceProduct) {
            this.retailProductService.product.insuranceType = this.insuranceType;
        }
        if (this.coveragePeriod && this.insuranceVehicleCategory) {
            this.onSelectOption(this.map[this.coveragePeriod.months + this.insuranceVehicleCategory.description + this.insuranceType.description]);
        }
    }

    formatCurrency(value): string {
        return StringUtils.formatCents(value);
    }

    //TODO : Product coverage period to be fixed
    submit(): void {

        if (this.retailProductService.product instanceof ThirdPartyInsuranceProduct || this.retailProductService.product instanceof ComprehensiveInsuranceProduct) {

            // if (this.retailProductService.product.coveragePeriod) {
            //     this.onNext.emit();
            // } else {
            //     this.translate.get("ADMIN.PRODUCTS.TOAST.INVALID_PRODUCT_SELECTION").subscribe((res: any) => {
            //         this.toastr.success(res.MESSAGE, res.TITLE, {
            //             toastComponent: ToastComponent,
            //             timeOut: environment.toast_time_out,
            //             progressBar: true,
            //             closeButton: true
            //         });
            //     });
            // }

        }
    }

}
