import {CountryRegistrationConfiguration} from "./country-registration-configuration.model";

export abstract class RequiredNaturalPersonCountryRegistrationInformation extends CountryRegistrationConfiguration {
    constructor() {
        super();
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.setup.RequiredNaturalPersonCountryRegistrationInformation';
    }

    public bankAccountNumber: boolean;
    public bankName: boolean;
    public contactEmail: boolean;
    public contactNumber: boolean;
    public income: boolean;
    public nationalIdentityNumber: boolean;
    public nationality: boolean;
    public nationalityStatus: boolean;
    public occupation: boolean;
    public physicalAddress: boolean;
    public postalAddress: boolean;
    public sourceOfIncome: boolean;
    public title: boolean;
    public vatNumber: boolean;
    public requiredDocuments: string[] = [];
}
