import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from "rxjs/internal/Observable"
import {ProvideRenderedConceptualDocumentRequest} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class RenderedConceptualDocumentProviderService {

  basePath = '/rendering/renderedconceptualdocumentprovider';

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

  constructor(private http: HttpClient,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest: ProvideRenderedConceptualDocumentRequest): Observable<any> {
      const path = this.runtimeConfigurationService.apiBaseUrl + this.basePath + '/providerenderedconceptualdocument';
      return this.http.post(path, provideRenderedConceptualDocumentRequest, this.options);
  }
}
