import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { RetailVehicle, ScannedDocument, ScannedDocumentIdentifier, ScannedDocumentIdentifierIdentifier, Vehicle, TpiIdentifier } from '@magnabc/tpi';
import { Utils } from '../../../../../shared/Utils/Utils';
import { InsuranceProductService } from '../../../../../../app.services/managers/insurance-product/insurance-product.service';
import { PDFDisplayModel, PrintService } from '../../../../../../app.services/common/printing/print.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-capture-vehicle-registration-document',
    templateUrl: './capture-vehicle-registration-document.component.html',
    styleUrls: ['./capture-vehicle-registration-document.component.scss']
})
export class CaptureVehicleRegistrationDocumentComponent implements OnInit {
    @Output() onCloseCaptureDocuments = new EventEmitter<any>();
    @Output() onDocumentRemoved = new EventEmitter<any>()

    file: any = null;
    fileBase64 = new BehaviorSubject<any>(null);
    description = "Vehicle Regstration";
    documentType = 'Vehicle Regstration';
    uploadPdf = false;
    scannedDocumentInput = true;
    // removeImages: ScannedDocumentIdentifier[] = []
    removeDocuments: ScannedDocumentIdentifier[] = []

    documentId: string;
    tpiIdentifier: string;
    showPreview = false;

    constructor(
        public insuranceProductService: InsuranceProductService,
        private printService: PrintService) { }

    ngOnInit() {
        if (this.insuranceProductService.tpiIdentifier && this.insuranceProductService.vehicleRegistrationFileMap.length > 0) {
            const id = this.insuranceProductService.tpiIdentifier;
            const doc = this.insuranceProductService.vehicleRegistrationFileMap.find(item => item.tpiId === id);
            if (doc && doc.file) {
                this.onFile(doc.file);
            }
        }
    }

    close(): void {
        this.showPreview = false;
        document.getElementById('btnCloseProductDialog').style.display = "inline-block";
    }

    getVehiclePlateNumber(vehicle: Vehicle | RetailVehicle): string {
        return Utils.getVehiclePlateNumber(vehicle)
    }

    onScannedDocument(scannedDocument: ScannedDocument) {
        const id = this.insuranceProductService.tpiIdentifier;
        const foundDocument = this.insuranceProductService.vehicleRegistrationFileMap.find(item => item.tpiId === id);
        if (foundDocument) {
            foundDocument.document = scannedDocument;
            foundDocument.file = this.file;
        } else {
            this.insuranceProductService.vehicleRegistrationFileMap.push({
                tpiId: id,
                document: scannedDocument,
                file: this.file
            });
        }
        this.tpiIdentifier = id;
        this.documentId = scannedDocument.documentIdentifier;
        // reset scanned document component
        this.scannedDocumentInput = false
        setTimeout(() => {
            this.scannedDocumentInput = true
        })

    }

    onFile(file: any) {
        this.file = file;
        this.fileToBase64(file).then(data => {
            this.fileBase64.next(data);
        });
    }

    previewDocument(file: File) {
        this.showPreview = true;
        document.getElementById('btnCloseProductDialog').style.display = "none";

        setTimeout(async () => {
            const canvas = (document.getElementById('canvas-preview-pdf') as HTMLCanvasElement);
            const base64 = (await this.fileToBase64(file)).split(',').at(1);
            const model: PDFDisplayModel = {
                base64,
                complete: () => { },
                canvas,
                styleWidth: '50%',
                scale: 8
            }
            this.printService.displayPDF(model);
        }, 10);
    }

    private fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result.toString());
            }
            reader.onerror = reject;
        });
    }

    isImage(file: File): boolean {
        const result = !!file.type.match('image.*');
        return result;
    }

    get displayDocuments(): any[] {
        const id = this.insuranceProductService.tpiIdentifier;

        if (id) {
            const documents = this.insuranceProductService.vehicleRegistrationFileMap.filter(
                item => item.tpiId === id)
                .map((item) => {
                    return { doc: item.document, file: item.file }
                });
            return documents;
        }
        return [];
    }

    removeDocument(tpiIdentifier: string) {
        let doc = this.insuranceProductService.vehicleRegistrationFileMap.find(item => item.tpiId === tpiIdentifier);
        if (doc) {
            const scannedDocumentIdentifierIdentifier = new ScannedDocumentIdentifierIdentifier();
            scannedDocumentIdentifierIdentifier.documentIdentifier = doc.document.documentIdentifier;
            this.removeDocuments.push(scannedDocumentIdentifierIdentifier);
            this.insuranceProductService.vehicleRegistrationFileMap = [];
            this.file = null;
            this.fileBase64.next(null);
            this.onDocumentRemoved.emit({ tpiIdentifier, document, doc });
        }
    }

    closeAll(): void {
        this.onCloseCaptureDocuments.emit({ tpiIdentifier: this.tpiIdentifier, documentId: this.documentId });
    }
}
