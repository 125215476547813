import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormComponent} from "../../shared/form/form.component";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {Dropdown} from "../../../common/model/dropdown.model";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {DATE_MASK, MY_FORMATS} from "../../../common/utils/utils.date";
import { JudicialPerson } from '@magnabc/tpi';
import { SystemParameterProviderService } from "../../../http.services/system/system-parameter-provider/system-parameter-provider.service";

declare const jQuery: any;
declare const document: any;

@Component({
    selector: 'app-entity-judicial-person',
    templateUrl: './judicial-person.component.html',
    styleUrls: ['./judicial-person.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class JudicialPersonComponent extends FormComponent implements OnInit {

    dateMask = DATE_MASK;

    @Input() judicialPerson: JudicialPerson;
    @Output() onJudicialPerson = new EventEmitter<JudicialPerson>();
    @Output() onValid = new EventEmitter<void>();

    genders = [
        new Dropdown(1, "Male"),
        new Dropdown(2, "Female"),
        new Dropdown(3, "Other")
    ];

    types = [
        new Dropdown(1, "Public / Private Company"),
        new Dropdown(2, "Partnership"),
        new Dropdown(3, "Sole Proprietor"),
        new Dropdown(4, "Statutory Company"),
        new Dropdown(5, "NGO"),
        new Dropdown(6, "NPO"),
        new Dropdown(7, "Embassy"),
        new Dropdown(8, "Gov. Department Armed Forces"),
        new Dropdown(9, "Universities, Schools and Colleges"),
        new Dropdown(10, "Church"),
        new Dropdown(11, "Sports Club"),
        new Dropdown(12, "Political Organisation")
    ];

    industries = [
        new Dropdown(1, "Agriculture"),
        new Dropdown(2, "Construction"),
        new Dropdown(3, "Education"),
        new Dropdown(4, "Engineering"),
        new Dropdown(5, "Finance"),
        new Dropdown(6, "Health Care"),
        new Dropdown(7, "Legal"),
        new Dropdown(8, "Mining"),
        new Dropdown(9, "Retail"),
        new Dropdown(10, "Technology"),
        new Dropdown(11, "Tourism"),
        new Dropdown(12, "Transportation"),
        new Dropdown(12, "Other")
    ];



    invalidName = false;
    showTextbox= false;

    formGroup: UntypedFormGroup;
    name: UntypedFormControl;
    type: UntypedFormControl;
    industry: UntypedFormControl;
    otherIndustry: UntypedFormControl;



    @ViewChild('form', { static: true }) form: FormGroupDirective;

    constructor(private translate: TranslateService, private translateParser: TranslateParser, private systemParameterService : SystemParameterProviderService) {
        super(translate, translateParser);

    }

    ngOnInit(): void {

        this.type = new UntypedFormControl(null);
        this.industry = new UntypedFormControl(null);
        this.otherIndustry = new UntypedFormControl(null);

        this.name = new UntypedFormControl(this.judicialPerson.name, [
            Validators.required,
            Validators.maxLength(100),
            Validators.minLength(2)
        ]);


        if (this.judicialPerson)
        {

            this.name.setValue(this.judicialPerson.name);
            this.type =  new UntypedFormControl(this.getDropdown(this.types, this.judicialPerson.type));

            if(this.judicialPerson.industry != null)
            {
                const industryCheck = this.getDropdown(this.industries, this.judicialPerson.industry);

                if(industryCheck == null)
                {
                    this.otherIndustry.setValue(this.judicialPerson.industry);
                    this.showTextbox=true;
                    this.industry = new UntypedFormControl(this.industries[12]);

                }else{
                    this.industry = new UntypedFormControl(this.getDropdown(this.industries, this.judicialPerson.industry));
                }

            }
        }

        this.formGroup = new UntypedFormGroup({
            'name': this.name,
            'type': this.type,
            'industry': this.industry,
            'otherIndustry': this.otherIndustry
        });

    }

    canDeactivate(): boolean {
        return !this.form || this.form.submitted || !this.form.dirty;
    }

    submit(): void {
        document.getElementById('form-submit-judicial-person').click();
    }

    onCheckValidation(event): void {

        if (!this.form.invalid) {

            if (this.name.value.startsWith(" ")) {
                this.invalidName = true;
                return;
            } else {
                this.invalidName = false;
            }

            this.judicialPerson.name = this.name.value;

            if(this.type.value)
            {
                this.judicialPerson.type = this.type.value.name;
            }

            if(this.showTextbox){
                this.judicialPerson.industry = this.otherIndustry.value;
            }else{
                if(this.industry.value)
                {
                    this.judicialPerson.industry = this.industry.value.name;
                }
            }

            this.onJudicialPerson.emit(this.judicialPerson);
            this.onValid.emit();

        } else {

            const target = jQuery('form .ng-invalid:first');

            if (target) {
                jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top - 300 }, 'slow');
                target.focus();
            }

        }

    }

    isValid() {
        this.onJudicialPerson.emit(this.judicialPerson);
        return this.form.valid;
    }

    isDirty(): boolean {
        return this.form.dirty;
    }

    onTypeChanged(type){
        this.type = type;
    }

    onIndustryChanged(industry){
        if(industry.value.name === "Other"){
            this.showTextbox = true;
        }else{
            this.showTextbox = false;
            this.industry = industry;
        }
    }

}
