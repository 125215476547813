import {Criteria} from "../../../criteria/models/criteria.model";
import {UnderwriterIdentifier} from '../identification/models/underwriter-identifier.model';
import {InsurancePolicyItem} from './insurance-policy-item.model';

export class InsurancePolicyItemGrouping extends Criteria{
    constructor(){
        super();
        this['@class']="za.co.magnabc.tpi.retail.insurance.InsurancePolicyItemGrouping"
    }

    public underwriterIdentifier: UnderwriterIdentifier
    public insurancePolicyNumber: String
    public expiryDate: Date
    public insurancePolicyItems: InsurancePolicyItem[] = []

}
