import {Component, HostListener} from "@angular/core";
import {TranslateParser, TranslateService} from "@ngx-translate/core";
import {IDropdown} from "../../../common/model/dropdown.model";

declare const jQuery: any;
@Component({
    selector: 'app-form-component',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent {

    loading = false;
    validationErrorMessages: any;

    static filterDropDown(list, name: any): IDropdown[] {
        return list.filter(model =>
        model.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    static displayDropDown(dropdown?: any): string | undefined {
        return dropdown ? dropdown.name : undefined;
    }

    constructor(private translateServiceParent: TranslateService, private translateParserParent: TranslateParser) {

        translateServiceParent.get("VALIDATION_ERROR_MESSAGE").subscribe((response) => {
            setTimeout(() => {
                this.validationErrorMessages = response;
            }, 0);
        });

    }

    canDeactivate(): boolean {
        return true;
    }

    getErrorMessage(fieldName: string, formControl) {
        if (this.validationErrorMessages) {
            let fieldLabel = jQuery("[id='" + fieldName + "']").attr('placeholder');
            if (!fieldLabel || fieldLabel === '') {
                fieldLabel = jQuery("[id='" + fieldName + "']").attr('ng-reflect-placeholder');
            }
            if (!fieldLabel || fieldLabel === '') {
                if (fieldName) {
                    fieldLabel = `${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`;
                } else {
                    fieldLabel = ' ';
                }
            }
            return formControl.hasError('required') ? this.translateParserParent.interpolate(this.validationErrorMessages.REQUIRED, {field: fieldLabel}) :
                formControl.hasError('email') ? this.translateParserParent.interpolate(this.validationErrorMessages.EMAIL, {field: fieldLabel}) :
                formControl.hasError('maxlength') ? this.translateParserParent.interpolate(this.validationErrorMessages.MAX_LENGTH, {field: fieldLabel}) :
                formControl.hasError('minlength') ? this.translateParserParent.interpolate(this.validationErrorMessages.MIN_LENGTH, {field: fieldLabel}) :
                formControl.hasError('max') ? this.translateParserParent.interpolate(this.validationErrorMessages.MAX, {field: fieldLabel}) :
                formControl.hasError('min') ? this.translateParserParent.interpolate(this.validationErrorMessages.MIN, {field: fieldLabel}) :
                formControl.hasError('exceedsgvm') ? this.translateParserParent.interpolate(this.validationErrorMessages.EXCESS_GVM, {field: fieldLabel}) :
                formControl.hasError('validategvmless') ? this.translateParserParent.interpolate(this.validationErrorMessages.GVM_LESS, {field: fieldLabel}) :
                formControl.hasError('validategvmbetween') ? this.translateParserParent.interpolate(this.validationErrorMessages.GVM_BETWEEN, {field: fieldLabel}) :
                formControl.hasError('validategvmmore') ? this.translateParserParent.interpolate(this.validationErrorMessages.GVM_MORE, {field: fieldLabel}) :
                formControl.hasError('validategvmlessthanzero') ? this.translateParserParent.interpolate(this.validationErrorMessages.GVM_LESS_THAN_ZERO, {field: fieldLabel}) :
                formControl.hasError('validateseatless') ? this.translateParserParent.interpolate(this.validationErrorMessages.SEAT_LESS, {field: fieldLabel}) :
                formControl.hasError('validateseatbetween') ? this.translateParserParent.interpolate(this.validationErrorMessages.SEAT_BETWEEN, {field: fieldLabel}) :
                formControl.hasError('validateseatmore') ? this.translateParserParent.interpolate(this.validationErrorMessages.SEAT_MORE, {field: fieldLabel}) :
                formControl.hasError('validateseatlessthanzero') ? this.translateParserParent.interpolate(this.validationErrorMessages.SEAT_LESS_THAN_ZERO, {field: fieldLabel}) :
                formControl.hasError('autoComplete') ? this.translateParserParent.interpolate(this.validationErrorMessages.AUTO_COMPLETE, {field: fieldLabel}) :
                formControl.hasError('pattern') ? this.translateParserParent.interpolate(this.validationErrorMessages.PATTERN, {field: fieldLabel}) :
                formControl.hasError('password') ? this.translateParserParent.interpolate(this.validationErrorMessages.PASSWORD, {field: fieldLabel}) :
                formControl.hasError('manufactureDate') ? this.translateParserParent.interpolate(this.validationErrorMessages.MANUFACTURE_DATE, {field: fieldLabel}) :
                formControl.hasError('manufactureFutureDate') ? this.translateParserParent.interpolate(this.validationErrorMessages.MANUFACTURE_FUTURE_DATE, {field: fieldLabel}) :
                formControl.hasError('exceedsmaxinterval') ? this.translateParserParent.interpolate(this.validationErrorMessages.EXCEEDS_MAX_INTERVAL, {field: fieldLabel}) :
                formControl.hasError('invaliddocumentstructure') ? this.translateParserParent.interpolate(this.validationErrorMessages.INVALID_DOCUMENT_STRUCTURE, {field: fieldLabel}) :
                formControl.hasError('incorrectdocumentformat') ? this.translateParserParent.interpolate(this.validationErrorMessages.INCORRECT_DOCUMENT_FORMAT, {field: fieldLabel}) :
                    '';
        }
        return '';
    }

    startLoad(): void {
        this.loading = true;
    }

    stopLoad(): void {
        this.loading = false;
    }

    getDropdown(list: any, name): IDropdown {

        if (name) {
            for (const item of list) {
                if (item.name.toLowerCase().replaceAll(' ','') === name.toLowerCase().replaceAll(' ','')) {
                    return item;
                }
            }

            for (const item of list) {
                if (item.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
                    return item;
                }
            }
        }

        return null;

    }

    getDropdownExact(list: any, name): IDropdown {

        if (name) {
            for (const item of list) {
                if (item.name.toLowerCase() === name.toLowerCase()) {
                    return item;
                }
            }
        }

        return null;

    }

    getDropdownByValue(list: any, value): IDropdown {

        if (value) {
            for (const item of list) {
                if (item.value === value) {
                    return item;
                }
            }
        }

        return null;

    }

    getDropdownByCode(list: any, value): IDropdown {

        for (const item of list) {
            if (item.value === value) {
                return item;
            }
        }

        if (list.length > 0) {
            return list[0];
        }

        return null;

    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

}
