import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core"
import {Router} from "@angular/router"
import {
    CertificateStatus,
    InsuranceCertificatePolicyNumberVehicleCriteria,
    InsurancePolicy,
    InsurancePolicyNumberIdentifier,
    InsurancePolicyVehicleCriteria,
    JudicialPerson,
    LegalEntity,
    LegalEntityDescriptionIdentifier,
    LegalEntityIdentifier,
    MotorInsuranceCertificate,
    MotorInsurancePolicy,
    MotorInsurancePolicyItem,
    NationalIdentityNumber,
    NaturalPerson,
    Order,
    OrderNumberCriteria,
    OrderStatus,
    PassportNumber,
    PlateNumber,
    ProposalRecordStatus,
    ProposalRecordSummaryStatusCriteria,
    ProposalSummaryInformation,
    ProvideInsuranceCertificatesRequest,
    ProvideInsuranceCertificatesResponse,
    ProvideInsurancePoliciesRequest,
    ProvideInsurancePoliciesResponse,
    ProvideLegalEntityInformationRequest,
    ProvideLegalEntityInformationResponse,
    ProvideOrderInformationRequest,
    ProvideOrderInformationResponse,
    ProvideProposalSummaryRequest,
    ProvideProposalSummaryResponse,
    ProvideRetailProfileVehicleInformationRequest,
    ProvideRetailProfileVehicleInformationResponse,
    RegistrationNumber,
    RetailProfile,
    RetailProfileLegalEntityCriteria,
    RetailVehicle,
    ThirdPartyInsurancePolicyOrderItem,
    TpiVehicleIdentifier,
    TrafficRegisterNumber,
    UnderwriterIdentifier,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
    FinaliseUpdateRetailProfileRequest,
    Vehicle,
    VehiclePlateNumber
} from "@magnabc/tpi"
import {TranslateParser, TranslateService} from "@ngx-translate/core"
import {DatatableComponent} from "@swimlane/ngx-datatable"
import * as moment from "moment"
import {ActiveToast} from "ngx-toastr"
import {environment} from "../../../../../environments/environment"
import {ServiceList} from "../../../../common/model/service-list.model"
import {
    ServiceGroupFinalisationManagerService
} from "../../../../http.services/security/authorisation/service-group-manager/service-group-finalisation-manager.service"
import {
    RetailProfileInformationProviderService
} from "../../../../http.services/retail/profile/retail-profile-information-provider/retail-profile-information-provider.service"
import {
    RetailProfileFinalisationManagerService
} from "../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service"
import {FormComponent} from "../../../shared/form/form.component"
import {Utils} from "../../../shared/Utils/Utils"
import {ToastComponent} from "../../../shared/toast/toast.component"
import {ObjectUtils} from "../../../../common/utils/utils.object"
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {MatDialog} from "@angular/material/dialog"
import {
    QuickTransactManagerService
} from "../../../../app.services/managers/quick-transact-manager/quick-transact-manager.service"
import {
    InsurancePolicyProviderService
} from "../../../../http.services/retail/insurance/insurance-policy-provider/insurance-policy-provider.service"
import {
    UnderwriterInformationProviderService
} from "../../../../http.services/retail/insurance/underwriter-information-provider/underwriter-information-provider.service"
import {
    InsuranceCertificateProviderService
} from "../../../../http.services/retail/insurance/certificate/insurance-certificate-provider/insurance-certificate-provider.service"
import {Subject} from "rxjs"
import {ProductDialogComponent} from '../../checkout/product-dialog/product-dialog.component'
import {
    OrderInformationProviderService
} from '../../../../http.services/retail/checkout/order-information-provider/order-information-provider.service'
import {SyncVehicleDialogComponent} from "../../profile/dialogs/sync-vehicle-dialog/sync-vehicle-dialog.component"
import {
    PublicLegalEntityInformationProviderService
} from "../../../../http.services/entity/public-legal-entity-information-provider/public-legal-entity-information-provider.service"
import {BrowseProductsPageComponent} from "../../../../view.pages/retail/insurance/browse-products-page/browse-products-page.component"
import {
    ProposalInformationProviderService
} from "../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service"
import {ProgressSpinnerMode} from "@angular/material/progress-spinner"
import {ErrorToastService} from '../../../../app.services/common/error-toast/error-toast.service'
import { InsuranceProductFinalisationManagerService } from "../../../../http.services/retail/insurance/insurance-product-finalisation-manager/insurance-product-finalisation-manager.service"

@Component({
    selector: 'app-vehicle-list',
    templateUrl: './vehicle.list.component.html',
    styleUrls: ['../../../vehicle/vehicle.component.scss']
})
export class VehicleListComponent extends FormComponent implements OnInit {

    TPI_4543: boolean;
    page = new Page();
    cache: any = {};

    @Input() vehicles: RetailVehicle[];
    @Input() insurancePolicies: InsurancePolicy[] =[];
    @Input() certificates: MotorInsuranceCertificate[] =[];
    @Input() addButton = true;
    @Input() syncButton = true;
    @Input() isPolicyQuery = false;
    @Input() unlinkButton = true;
    @Input() onlyActivePolicies = false;
    @Input() viewDetails = false;
    @Input() hideDetails = false;
    @Input() underwriterIdentifier: UnderwriterIdentifier;
    @Input() legalEntity: LegalEntity;
    @Input() filter: Subject<string>;
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onVehicleSelect = new EventEmitter<Vehicle>();
    @Output() onRetailVehicles= new EventEmitter<RetailVehicle[]>();

    retailProfile: RetailProfile;

    rows: RetailVehicle[] = [];
    columns = [
        { details: 'details' }
    ];
    expanded: any = {};

    orders: { [id: string]: Order[] } = {};
    ordersTemp: Order[] = [];
    proposals: { [id: string]: ProposalSummaryInformation[] } = {};
    policies: { [id: string]: InsurancePolicy[] } = {};
    targetPolicies: MotorInsurancePolicy[]

    activeToast: ActiveToast<any>;
    temp: RetailVehicle[] = [];

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
    @ViewChild(BrowseProductsPageComponent, { static: false }) browseProducts: BrowseProductsPageComponent;
    currentJudicialPerson: any;
    currentNaturalPerson: NaturalPerson;
    destroyed: boolean = false;
    loadComplete: boolean = false;
    spinnerMode: ProgressSpinnerMode  = 'indeterminate';
    extensionPolicyMap: {[id: string]: MotorInsurancePolicyItem[]} = {};
    originalPolicyMap: {[id: string]: MotorInsurancePolicyItem[]} = {};
    isPublicUserView = true;
    loadedPolicies = false

    constructor(
        public translate: TranslateService,
        private translateParser: TranslateParser,
        private router: Router,
        private toastr: ErrorToastService,
        public retailProfileService: RetailProfileFinalisationManagerService,
        private retailProfileInformationProviderService: RetailProfileInformationProviderService,
        private quickStartService: QuickTransactManagerService,
        public ServiceGroupManagerService: ServiceGroupFinalisationManagerService,
        private unMarshallerService: UnMarshallerService,
        private insurancePolicyProviderService: InsurancePolicyProviderService,
        public authenticationService: AuthenticationService,
        public insuranceCertificateProviderService : InsuranceCertificateProviderService,
        public dialog: MatDialog,
        private orderInformationProviderService : OrderInformationProviderService,
        private proposalInformationProviderService : ProposalInformationProviderService,
        public brokerService : UnderwriterInformationProviderService,
        private publicLegalEntityInformationProviderService: PublicLegalEntityInformationProviderService,
        public insuranceProductFinalisationManagerService: InsuranceProductFinalisationManagerService
    ) {
        super(translate, translateParser);

        this.temp = this.rows;
    }

    ngOnInit(): void {

        this.filter.subscribe(
            filter => {
                this.loadedPolicies = false
                setTimeout(() => {
                    const val = filter.toLowerCase();
                    this.rows = this.temp.filter((vehicle) => {
                        return this.getPlateNumber(vehicle).replace(/ /g, "").toLowerCase().indexOf(val) !== -1 || !val;
                    });
                    this.table.offset = 0;
                    this.loadComplete = true;
                    this.loadedPolicies = true
                })
            }
        );

        if (this.quickStartService.retailProfile) {
            this.retailProfile = this.quickStartService.retailProfile;
        } else {
            this.retailProfile = this.retailProfileService.retailProfile;
        }
        this.legalEntity = this.getCurrentLegalEntity(this.retailProfileService.retailProfile, false);

        this.refresh();
    }

    getCurrentLegalEntity(retailProfile : RetailProfile, stopLoading :boolean): LegalEntity
    {
        const provideLegalEntityInformationRequest = new ProvideLegalEntityInformationRequest();
        provideLegalEntityInformationRequest.legalEntityIdentifiers.push(retailProfile.legalEntityDescriptionIdentifier);
        this.publicLegalEntityInformationProviderService.providePublicLegalEntityInformation(provideLegalEntityInformationRequest).then(httpLegalEntityInformation =>{
            const provideLegalEntityInformationResponse = (this.unMarshallerService.
                unMarshallFromJson(httpLegalEntityInformation.body, ProvideLegalEntityInformationResponse) as ProvideLegalEntityInformationResponse);

            if (provideLegalEntityInformationResponse.legalEntities.length>0){
                if (provideLegalEntityInformationResponse.legalEntities[0] instanceof NaturalPerson) {
                    this.currentJudicialPerson = null;
                    this.currentNaturalPerson = (provideLegalEntityInformationResponse.legalEntities[0] as NaturalPerson);
                } else {
                    this.currentNaturalPerson=null;
                    this.currentJudicialPerson = (provideLegalEntityInformationResponse.legalEntities[0] as JudicialPerson);
                }

                if(stopLoading)
                {
                    this.stopLoad();
                }

            } else {
                return null;
            }
        });

        if(this.currentNaturalPerson==null)
        {
            return this.currentJudicialPerson;
        }

        if(this.currentJudicialPerson==null)
        {
            return this.currentNaturalPerson;
        }

        return null;
    }

      /**
       * Populate the table with new data based on the page number
       * @param page The page to select
       */
      setPage(pageInfo) {
        this.loading = false;
        this.page.pageNumber = pageInfo.offset;
        this.page.size = pageInfo.pageSize;
        let rows = this.rows;
        if (rows.length !== this.vehicles.length) {
            //then query
            rows = rows.map((x, i) => this.rows[i]);
        }

      }


    refresh(): void {

        if (this.vehicles && this.vehicles.length > 0) {
            this.vehicles.sort((first, second) => {
                const firstPlate = this.getPlateNumber(first);
                const secondPlate = this.getPlateNumber(second);
                if (firstPlate > secondPlate) {
                    return 1;
                }

                if (firstPlate < secondPlate) {
                    return -1;
                }
            });

            this.rows = [...this.vehicles];
            this.temp = [...this.rows];


            this.providePolicies(this.rows)
            this.provideOrders();
            this.provideProposals();

        }
    }

    vehicleSort(): void {

        if (this.vehicles && this.vehicles.length > 0) {
            this.vehicles.sort((first, second) => {
                const firstPlate = this.getPlateNumber(first);
                const secondPlate = this.getPlateNumber(second);
                if (firstPlate > secondPlate) {
                    return 1;
                }

                if (firstPlate < secondPlate) {
                    return -1;
                }
            });

            this.rows = this.vehicles;
            this.rows =[...this.rows];
            this.temp =[...this.rows];

        }
    }

    providePolicies(vehicles: RetailVehicle[]): void {

        this.policies = {};
        this.extensionPolicyMap = {};
        this.originalPolicyMap = {};

        const promises = []
        for (const vehicle of vehicles) {

            const insurancePolicyNumberIdentifier = new InsurancePolicyNumberIdentifier();
            const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
            const thirdPartyInsurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();

            thirdPartyInsurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
            thirdPartyInsurancePolicyVehicleCriteria.tpiVehicleIdentifier =Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers).number.toString();

            provideThirdPartyInsurancePoliciesRequest.criteria = thirdPartyInsurancePolicyVehicleCriteria;
            provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
            provideThirdPartyInsurancePoliciesRequest.batchSize = -1;

            if(this.isPolicyQuery){
                const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

                legalEntityDescriptionIdentifier.name = this.brokerService.selectedUnderwriter.name;
                legalEntityDescriptionIdentifier.surname = this.brokerService.selectedUnderwriter.name;
                legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier([this.brokerService.selectedUnderwriter.legalEntityIdentifier]);

                provideThirdPartyInsurancePoliciesRequest.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
                    this.brokerService.selectedUnderwriter.name,
                    legalEntityDescriptionIdentifier
                );
            }

            promises.push(this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {

                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                    if (response.insurancePolicies && response.insurancePolicies.length > 0) {
                        const policies: InsurancePolicy[] = response.insurancePolicies;
                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)] = [];
                        for (const policy of policies) {
                            if (policy instanceof MotorInsurancePolicy) {
                                // the policy is expired after the full day has passed.
                                // I.e. expiry date = 24/11, the policy will expire on 24/11 when the clock hits 24:00 or 00:00.
                                policy.expiryDate = this.addExtraDay(policy.expiryDate);

                                policy.insurancePolicyItems.forEach((item) => {
                                    (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate = this.addExtraDay((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.expiryDate);
                                    if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'EXTENSION') {
                                        if (!this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier]) {
                                            this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier] = [];
                                        }
                                        this.extensionPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier].push((item as MotorInsurancePolicyItem));
                                    }
                                    if ((item as MotorInsurancePolicyItem).insurancePolicyItemType === 'NEW') {
                                        if (!this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier]) {
                                            this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier] = [];
                                        }
                                        this.originalPolicyMap[(item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier].push((item as MotorInsurancePolicyItem));
                                    }
                                });

                                if (!this.underwriterIdentifier || Utils.equalUnderwriters(policy.underwriterIdentifier, this.underwriterIdentifier)) {
                                    if (!moment(policy.expiryDate).isBefore(moment().subtract(4, 'months'))) {
                                        if (policy.insurancePolicyItems && policy.insurancePolicyItems.length > 1) {
                                            const vehiclePolicyItem = policy.insurancePolicyItems.filter(item => {
                                                return (item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers);
                                            });

                                            policy.insurancePolicyItems = vehiclePolicyItem;
                                        }
                                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)].push((policy));
                                        insurancePolicyNumberIdentifier.number = policy.number;
                                        this.provideInsuranceCertificates(this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers), policy.number)
                                    }
                                }
                            }

                        }

                        if (this.onlyActivePolicies && (!this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)] ||
                            this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)].length === 0)) {
                            this.removeVehicleFromList(vehicle);
                        }

                    } else if (this.onlyActivePolicies) {
                        this.removeVehicleFromList(vehicle);
                    }
                }
            }))

        }

        this.loadedPolicies = false
        Promise.allSettled(promises).then(() => {
            this.loadedPolicies = true
        })
    }

    providePoliciesBatch(vehicles: RetailVehicle[]): void {

        for (const vehicle of vehicles) {

            const insurancePolicyNumberIdentifier = new InsurancePolicyNumberIdentifier();
            const provideThirdPartyInsurancePoliciesRequest = new ProvideInsurancePoliciesRequest();
            const thirdPartyInsurancePolicyVehicleCriteria = new InsurancePolicyVehicleCriteria();

            thirdPartyInsurancePolicyVehicleCriteria.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') as any;
            thirdPartyInsurancePolicyVehicleCriteria.tpiVehicleIdentifier =Utils.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers).number.toString();

            provideThirdPartyInsurancePoliciesRequest.criteria = thirdPartyInsurancePolicyVehicleCriteria;
            provideThirdPartyInsurancePoliciesRequest.batchNumber = -1;
            provideThirdPartyInsurancePoliciesRequest.batchSize = -1;

            if(this.isPolicyQuery){
                const legalEntityDescriptionIdentifier = new LegalEntityDescriptionIdentifier();

                legalEntityDescriptionIdentifier.name = this.brokerService.selectedUnderwriter.name;
                legalEntityDescriptionIdentifier.surname = this.brokerService.selectedUnderwriter.name;
                legalEntityDescriptionIdentifier.tpiIdentifier = Utils.getTpiIdentifier([this.brokerService.selectedUnderwriter.legalEntityIdentifier]);

                provideThirdPartyInsurancePoliciesRequest.underwriterIdentifier = new UnderwriterLegalEntityIdentifier(
                    this.brokerService.selectedUnderwriter.name,
                    legalEntityDescriptionIdentifier
                );
            }

            this.insurancePolicyProviderService.providePolicy(provideThirdPartyInsurancePoliciesRequest).then((httpResponse) => {

                if (httpResponse && httpResponse.body) {

                    const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideInsurancePoliciesResponse) as ProvideInsurancePoliciesResponse);

                    if (response.insurancePolicies && response.insurancePolicies.length > 0) {
                        const policies: InsurancePolicy[] = response.insurancePolicies;
                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)] = [];
                        for (const policy of policies) {

                            if (policy instanceof MotorInsurancePolicy) {
                                if (!this.underwriterIdentifier || Utils.equalUnderwriters(policy.underwriterIdentifier, this.underwriterIdentifier)) {

                                    if (!moment(policy.expiryDate).isBefore(moment().subtract(4, 'months'))) {
                                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)].push((policy));
                                        insurancePolicyNumberIdentifier.number = policy.number;
                                        this.provideInsuranceCertificates(this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers), policy.number)
                                    }
                                }
                            }

                        }

                        if (this.onlyActivePolicies && (!this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)] ||
                        this.policies[this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers)].length === 0)) {
                            this.removeVehicleFromList(vehicle);
                        }

                    } else if (this.onlyActivePolicies) {
                        this.removeVehicleFromList(vehicle);
                    }
                }
            });

        }
    }

    provideProposals(): void {
        const proposalRecordSummaryStatusCriteria = new ProposalRecordSummaryStatusCriteria();
        if(this.retailProfileService.retailProfile){
            proposalRecordSummaryStatusCriteria.legalEntityIdentifier = this.retailProfileService.retailProfile.legalEntityDescriptionIdentifier;
        }
        const status = [ProposalRecordStatus.OPEN, ProposalRecordStatus.PENDING]
        proposalRecordSummaryStatusCriteria.status = status;

        const provideProposalSummaryRequest = new ProvideProposalSummaryRequest();
        provideProposalSummaryRequest.criteria = proposalRecordSummaryStatusCriteria;

        this.proposalInformationProviderService.provideProposalSummary(provideProposalSummaryRequest).then((data) => {
            this.proposals = {};
            this.stopLoad();
            const response: ProvideProposalSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideProposalSummaryResponse);

            for (const item of response.proposalSummaryInformation) {
                if (!this.proposals[item.tpiVehicleIdentifier]) {
                    this.proposals[item.tpiVehicleIdentifier] = [];
                }
                this.proposals[item.tpiVehicleIdentifier].push(item);
            }

        }).catch(err =>{
            console.error(err);
            this.stopLoad();
        });
    }

    provideOrders(): void {

        const orderNumberCriteria = new OrderNumberCriteria();
        if(this.retailProfileService.retailProfile){
            orderNumberCriteria.orderIdentifiers = this.retailProfileService.retailProfile.orderIdentifiers;
        }

        const provideOrdersRequest = new ProvideOrderInformationRequest();
        provideOrdersRequest.criteria = orderNumberCriteria;

        this.orderInformationProviderService.provideOrder(provideOrdersRequest).then((data) => {

            this.orders = {};
            const response: ProvideOrderInformationResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideOrderInformationResponse);
            for (const order of response.orders) {
                if (order.status === OrderStatus.PENDING_PAYMENT) {
                    for (const item of order.orderItems) {
                        if ((item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle) {
                            if (!this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier]) {
                                this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier] = [];
                            }
                            this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].push(order);
                            this.ordersTemp = this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier];
                            this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier] = [];
                            this.ordersTemp.forEach(itemCheck => {
                                const found =  this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].filter(vehicle => vehicle.number === itemCheck.number);
                                if (found.length === 0 ) {
                                    this.orders[(item as ThirdPartyInsurancePolicyOrderItem).snapshotVehicle.tpiVehicleIdentifier].push(itemCheck);
                                }
                            })
                        }
                    }
                }
            }

        });

    }

    provideInsuranceCertificates(tpiVehicleIdentifier: string, policyNumber: string) {
        if(!CertificateStatus)return;

        const insuranceCertificatePolicyNumberVehicleCriteria = new InsuranceCertificatePolicyNumberVehicleCriteria();
        insuranceCertificatePolicyNumberVehicleCriteria.tpiVehicleIdentifier = tpiVehicleIdentifier;
        insuranceCertificatePolicyNumberVehicleCriteria.policyNumber = policyNumber;
        insuranceCertificatePolicyNumberVehicleCriteria.status = CertificateStatus.VALID;

        const provideInsuranceCertificatesRequest = new ProvideInsuranceCertificatesRequest();
        provideInsuranceCertificatesRequest.criteria = insuranceCertificatePolicyNumberVehicleCriteria;

        this.insuranceCertificateProviderService.provideInsuranceCertificates(provideInsuranceCertificatesRequest).subscribe((data) => {

            const response = this.unMarshallerService.unMarshallFromJson(data.body, ProvideInsuranceCertificatesResponse) as ProvideInsuranceCertificatesResponse;

            for (const insuranceCertificates of response.motorInsuranceCertificates) {
                this.certificates.push(insuranceCertificates);
            }
        });

    }

    getTpiVehicleIdentifier(vehicleIdentifiers: any): string {
        return Utils.getTpiVehicleIdentifier(vehicleIdentifiers).number;
    }

    getFormattedDate(vehicle: RetailVehicle) {
        return moment(vehicle.manufactureDate).format("YYYY");
    }

    removeVehicleFromList(vehicle: RetailVehicle): void {
        if (this.rows.indexOf(vehicle) >= 0) {
            this.rows.splice(this.rows.indexOf(vehicle), 1);
        }
        if (this.temp.indexOf(vehicle) >= 0) {
            this.temp.splice(this.temp.indexOf(vehicle), 1);
        }
        // this.updateFilter({ target: { value: "" } });
    }

    isValid(policy: InsurancePolicy, tpiVehicleIdentifier): boolean {
        const motorInsurancePolicy = (policy as MotorInsurancePolicy)
        for (const insurancePolicyItem of motorInsurancePolicy.insurancePolicyItems) {
            const motorInsurancePolicyItem = insurancePolicyItem as MotorInsurancePolicyItem
            if (tpiVehicleIdentifier === motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier) {
                if (!motorInsurancePolicyItem.motorInsuranceVehiclePolicy.cancelled && !this.isExpired(motorInsurancePolicyItem.motorInsuranceVehiclePolicy.expiryDate)) {
                    return true
                }
            }
        }
        return false
    }

    allPoliciesCancelled(policies: any[], vehicleId) {
        if(!policies) return false;
        let countCancelled = 0;
        policies.forEach((policy) => {
            if (this.isCancelled(policy, vehicleId) || this.isExpired(policy.expiryDate)) {
                countCancelled++;
            }
        })
        return countCancelled === policies.length ? true : false;
    }

    isCancelled(policy: InsurancePolicy, tpiVehicleIdentifier): boolean {
        const motorInsurancePolicy = (policy as MotorInsurancePolicy)
        for (const insurancePolicyItem of motorInsurancePolicy.insurancePolicyItems) {
            const motorInsurancePolicyItem = insurancePolicyItem as MotorInsurancePolicyItem
            if (tpiVehicleIdentifier === motorInsurancePolicyItem.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier) {
                if (!motorInsurancePolicyItem.motorInsuranceVehiclePolicy.cancelled) {
                    return false
                }
            }
        }
        return true
    }

    isExpired(expiryDate: Date): boolean {
        return moment(new Date()).isAfter(moment(expiryDate).add(-1, 'days').endOf('day'));
    }

    formatDate(date: Date): string {
        return moment(date).format("DD MMM YYYY");
    }

    toggleExpandRow(event, row): boolean {
        if (event.type === "click") {
            if (event.event) {
                event.event.stopPropagation();
            } else {
                event.stopPropagation();
            }
            this.table.rowDetail.toggleExpandRow(row);
        }

        return false;
    }

    viewPolicyDetails(event,policy,vehicleIdentifier): boolean {
        if (policy.insurancePolicyItems && policy.insurancePolicyItems.length > 1) {
            const vehiclePolicyItem = policy.insurancePolicyItems.filter(item => {
                return item.motorInsuranceVehiclePolicy.snapshotVehicle.tpiVehicleIdentifier === vehicleIdentifier;
            });
            policy.insurancePolicyItems = vehiclePolicyItem;
        }
        event.stopPropagation();
        this.targetPolicies = [policy]

        return false;
    }

    onClosePolicyView() {
        this.targetPolicies = null
    }

    onDetailToggle(event) {

    }

    onSelect(event): void {
        if (!this.viewDetails) {
            this.onVehicleSelect.emit(event.selected[0]);
        }
    }

    onEdit(row): void {
        this.router.navigate(['/retail/profile/vehicle/' +row.vehicleIdentificationNumber]);
    }

    onBuyInsurance(row) {
        this.insuranceProductFinalisationManagerService.vehicles = [];
        this.insuranceProductFinalisationManagerService.vehicles.push(row);
        this.router.navigate(['/retail/browse/select-insurance'], {queryParams: {quickBuy: true}});
    }

    onViewDetails(vehicle): void {
        this.onVehicleSelect.emit(vehicle);
    }

    //noinspection JSUnusedLocalSymbols
    onNew(event): void {
        this.router.navigate(['/retail/profile/link']);
    }

    onSyncVehicle(event): void {
        this.startLoad()
        this.legalEntity = this.getCurrentLegalEntity(this.retailProfileService.retailProfile,true);
        this.showDialog(false, 'Sync Vehicles',
        'Please note any changes to a vehicle already added to your profile with the same VIN as a vehicles to be synced will be lost.', this.legalEntity);
    }

    getTRN(legalEntity: LegalEntity): string {

        for(var legalEntityIdentifier of legalEntity.legalEntityIdentifiers)
        {
            if(legalEntityIdentifier instanceof TrafficRegisterNumber)
            {
                return legalEntityIdentifier.number;
            }
        }

        return null;
    }

    getTrafficRegisterNumberString(legalEntityIdentifiers: LegalEntityIdentifier[]): string {
        for (const legalEntityIdentifier of legalEntityIdentifiers) {
            if (legalEntityIdentifier instanceof TrafficRegisterNumber ||
                legalEntityIdentifier['@class'] === "za.co.magnabc.tpi.entity.identification.judicialperson.TrafficRegisterNumber" ||
                legalEntityIdentifier['@class'] === "za.co.magnabc.tpi.entity.driver.identification.TrafficRegisterNumber"
            ) {
                return (legalEntityIdentifier as TrafficRegisterNumber).number;
            }
        }

        return null;
    }

    showDialog(error: boolean, title: string, message: string, legalEntity: LegalEntity) {
        const trn_number = this.getTrafficRegisterNumberString(this.legalEntity.legalEntityIdentifiers);
        return this.dialog.open(SyncVehicleDialogComponent, {
            disableClose: true,
            data: {error, title, message, trn_number, legalEntity}
        }).afterClosed().subscribe((response) => {
            if(response){
                this.getRetailProfileVehicles()
                this.getCurrentLegalEntity(this.retailProfileService.retailProfile,false);
            }
        }) ;

    }


    onExtend(plateNumber) {
        let insurancePolicy;
        this.insurancePolicies.forEach(policy => {
            policy.insurancePolicyItems.forEach(item => {
                if ((item as MotorInsurancePolicyItem).motorInsuranceVehiclePolicy.snapshotVehicle.plateNumber === plateNumber) {
                    insurancePolicy = policy;
                }
            });
        });

        const data = {
            product: insurancePolicy,
            vehicles: this.vehicles
        }

        const dialogRef = this.dialog.open(ProductDialogComponent, {
            width: '96%',
            maxWidth: '1024px',
            position: { top: "1%" },
            panelClass: 'product-dialog',
            data
        });
    }

    onUnlink(event, vehicle: RetailVehicle): boolean {
        event.stopPropagation();
        const title = this.getPlateNumber(vehicle);
        const id = this.getTpiVehicleIdentifier(vehicle.vehicleIdentifiers);

        this.translate.get('TOAST.UNLINK_VEHICLE').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: title }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: 0,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        for (let i = 0; i < this.retailProfile.vehicleIdentifiers.length; i++) {
                            const vehicleIdentifier = this.retailProfile.vehicleIdentifiers[i] as TpiVehicleIdentifier;
                            if (vehicleIdentifier.number === id) {
                                this.retailProfile.vehicleIdentifiers.splice(i, 1);
                                break;
                            }
                        }
                        this.submit(title);
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

        return false;
    }

    private submit(title: string) {
        const createRetailProfile = new FinaliseUpdateRetailProfileRequest();
        createRetailProfile.retailProfile = ObjectUtils.createObjectCopy(this.retailProfile);
        this.startLoad();
        this.retailProfileService.finaliseUpdateRetailProfile(createRetailProfile).subscribe(() => {
            this.stopLoad();
            this.getRetailProfileVehicles();
            this.toast(title);
        },(error) => {
            this.stopLoad();
        });
    }

     getRetailProfileVehicles() {

        const provideRetailProfileVehicleInformationRequest: ProvideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;
        provideRetailProfileVehicleInformationRequest.batchSize = 20;
        provideRetailProfileVehicleInformationRequest.batchNumber = 1;
        this.startLoad();
        this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then(
            (data) => {
                const response: ProvideRetailProfileVehicleInformationResponse =
                    this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileVehicleInformationResponse);

                this.page.totalElements = response.totalNumberOfResults;

                this.vehicles = response.retailVehicles;
                this.rows = response.retailVehicles;
                this.temp = this.rows;
                this.vehicleSort();
                this.providePolicies(this.rows);
                this.stopLoad();
                this.getRetailProfileVehiclesBatch(20, response.batchTo);
            }, (error) => {
                this.stopLoad();
            }
        ).catch((error) => {
            this.stopLoad();
        });;

    }

    getRetailProfileVehiclesBatch(batchSize:number ,batchTo:number) {

        const provideRetailProfileVehicleInformationRequest: ProvideRetailProfileVehicleInformationRequest = new ProvideRetailProfileVehicleInformationRequest();
        const criteria = new RetailProfileLegalEntityCriteria();
        criteria.tpiIdentifier = this.retailProfile.legalEntityDescriptionIdentifier.tpiIdentifier;
        provideRetailProfileVehicleInformationRequest.criteria = criteria;
        provideRetailProfileVehicleInformationRequest.batchSize = 20;
        provideRetailProfileVehicleInformationRequest.batchNumber = Math.ceil(batchTo / batchSize) + 1;

        if(this.destroyed)
        {

        }
        else if(this.rows.length === this.page.totalElements || (this.rows.length > this.page.totalElements))
        {
            this.table.recalculate();
            this.vehicleSort();
            this.onRetailVehicles.emit(this.vehicles);
            this.loadComplete = true;
        }
        else{

            this.retailProfileInformationProviderService.provideRetailProfileVehicleInformation(provideRetailProfileVehicleInformationRequest).then(
                (data) => {
                    const response: ProvideRetailProfileVehicleInformationResponse =
                        this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileVehicleInformationResponse);
                    const currentList = response.retailVehicles;
                    for (const retailVehicle of currentList) {
                        if (this.vehicles.findIndex((retailVehicle1: RetailVehicle) =>
                            retailVehicle1.vehicleIdentificationNumber === retailVehicle.vehicleIdentificationNumber) === -1) {
                            this.vehicles.push(retailVehicle);
                        }
                    }
                    //this.vehicles = [...this.vehicles];
                    this.temp = this.vehicles;
                    this.temp = [...this.temp];
                    this.rows = this.vehicles;
                    this.rows = [...this.rows];
                    this.providePoliciesBatch(currentList);
                    this.stopLoad();

                    this.onRetailVehicles.emit(this.vehicles);
                    this.refresh();
                    if (response.batchTo) { this.getRetailProfileVehiclesBatch(20, response.batchTo); }

                }, (error) => {
                    this.stopLoad();
                }
            ).catch((error) => {
                this.stopLoad();
            });;

        }
    }



    public getPlateNumber(vehicle: RetailVehicle): string {
        let plateNo = '';
        vehicle.vehicleIdentifiers.forEach(
            identifier => {
                if (identifier instanceof VehiclePlateNumber) {
                    plateNo = identifier.number;
                } else if (identifier instanceof PlateNumber) {
                    plateNo = identifier.number;
                }
            }
        )
        return plateNo;
    }

    private toast(title: string) {

        this.translate.get('TOAST.VEHICLE_UNLINKED').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, { value: title }), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });

    }

    servicesLinkVehicles(): boolean {
        return this.authenticationService.isAuthorised([ServiceList.LINKVEHICLE]) || this.authenticationService.isAuthorised([ServiceList.ASSISTEDQUICKTRANSACT]);
    }

    getVehiclePlateNumber(vehicle: Vehicle): string {
        return Utils.getVehiclePlateNumber(vehicle);
    }


    onRetailVehicle():void
    {
        this.onRetailVehicles.emit(this.vehicles);
    }

    getPolicyExpiry(policy: MotorInsurancePolicy): string {
        let expiryDate: string = "";

        for (let item of policy.insurancePolicyItems) {
            const policyItem = item as MotorInsurancePolicyItem;
            if (policyItem.insurancePolicyItemType === 'EXTENSION' && !policyItem.motorInsuranceVehiclePolicy.cancelled) {
                expiryDate = this.formatPolicyExpiry(policy, policyItem);
                break;
            } else if (policyItem.insurancePolicyItemType === 'NEW') {
                expiryDate = this.formatPolicyExpiry(policy, policyItem);
            }
        }
        return expiryDate;
    }

    private formatPolicyExpiry(policy: MotorInsurancePolicy, policyItem: MotorInsurancePolicyItem): string {
        let expiryDate: string;
        if ('expiryDate' in policyItem.motorInsuranceVehiclePolicy) {
            expiryDate = this.formatDate(policyItem.motorInsuranceVehiclePolicy.expiryDate)
        } else {
            expiryDate = this.formatDate(policy.expiryDate);
        }
        return expiryDate;
    }

    ngOnDestroy():void {
        this.destroyed=true;
    }

    addExtraDay(date) {
        const newDate = new Date(date)
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1, 0, 0, 0);
   }

    removeExtraDay(date) {
        const newDate = new Date(date);
        return this.formatDate(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1, 0, 0, 0));
    }

    /**
     * Checks if the current legal entity is a Malawian legal entity
     * @returns true if the current legal entity is a Malawian legal entity
     */
    isMalawianLegalEntity(): boolean {
        const naturalPersonLegalEntityIdentifiers = this.currentNaturalPerson?.legalEntityIdentifiers || [];
        const judicialPersonLegalEntityIdentifiers = this.currentJudicialPerson?.legalEntityIdentifiers || [];

        const hasMalawianNationalIdentityNumber = naturalPersonLegalEntityIdentifiers.some(
            (legalEntityIdentifier) =>
                legalEntityIdentifier instanceof NationalIdentityNumber && legalEntityIdentifier.countryOfIssue === 'MW'
        );

        const hasMalawianPassportNumber = naturalPersonLegalEntityIdentifiers.some(
            (legalEntityIdentifier) =>
                legalEntityIdentifier instanceof PassportNumber && legalEntityIdentifier.countryOfIssue === 'MW'
        );

        const hasMalawianRegistrationNumber = judicialPersonLegalEntityIdentifiers.some(
            (legalEntityIdentifier) =>
                legalEntityIdentifier instanceof RegistrationNumber && legalEntityIdentifier.countryOfIssue === 'MW'
        );

        return hasMalawianNationalIdentityNumber || hasMalawianPassportNumber || hasMalawianRegistrationNumber;
    }

    onImportVehicle(): void {
        this.router.navigate(['retail/profile/vehicles/import']);
    }

}

export class Page {
    //The number of elements in the page
    size: number = 0;
    //The total number of elements
    totalElements: number = 0;
    //The total number of pages
    totalPages: number = 0;
    //The current page number
    pageNumber: number = 0;
}
