import {Component, OnInit} from '@angular/core'
import {
    FinancialAccountInformationProviderService
} from '../../../../http.services/ledger/financialaccount/financial-account-information-provider/financial-account-information-provider.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {StringUtils} from '../../../../common/utils/utils.string'
import {
    FinancialAccount,
    FinancialAccountLegalEntityCriteria,
    LegalEntityIdentifier,
    ProvideFinancialAccountInformationRequest,
    ProvideFinancialAccountInformationResponse,
    TpiIdentifier,
    UnMarshallerService
} from '@magnabc/tpi'

@Component({
  selector: 'app-accounting-transations',
  templateUrl: './accounting-transations.component.html',
  styleUrls: ['./accounting-transations.component.scss']
})
export class AccountingTransationsComponent implements OnInit {

    constructor(private financialAccountInformationProviderService: FinancialAccountInformationProviderService,
                private unMarshallerService: UnMarshallerService, private authenticationService: AuthenticationService) {
    }

    financialAccountsList = new Array<FinancialAccount>();
    ngOnInit() {
        this.authenticationService.getLinkedToJudicialPerson().legalEntityIdentifiers;
        const provideFinancialAccountInformationRequest = new ProvideFinancialAccountInformationRequest();
        const financialAccountLegalEntityCriteria = new FinancialAccountLegalEntityCriteria();
        financialAccountLegalEntityCriteria.legalEntityIdentifiers.push(this.getTPIIdentifier(this.authenticationService.getLinkedToJudicialPerson().legalEntityIdentifiers));

        provideFinancialAccountInformationRequest.batchNumber = -1;
        provideFinancialAccountInformationRequest.batchSize = -1;
        provideFinancialAccountInformationRequest.criteria = financialAccountLegalEntityCriteria;

        this.financialAccountInformationProviderService.provideFinancialAccountInformation(provideFinancialAccountInformationRequest).subscribe(response =>{

            if (response && response.body) {
                const provideFinancialAccountInformationResponse = (this.unMarshallerService.unMarshallFromJson(response.body, ProvideFinancialAccountInformationResponse) as ProvideFinancialAccountInformationResponse);

                this.financialAccountsList = provideFinancialAccountInformationResponse.financialAccounts;
            }
        });
    }

    getTPIIdentifier(identifiers: LegalEntityIdentifier[]): string{
        for(var identifier of identifiers){
            if(identifier instanceof TpiIdentifier || identifier['@class'] === 'za.co.magnabc.tpi.entity.identification.TpiIdentifier'){
                var tpiIdentifier = <TpiIdentifier> identifier;
                return  tpiIdentifier.tpiIdentifier;
            }
        }
    }

    formatCurrency(amount): string {
        return StringUtils.formatCents(amount);
    }
}
