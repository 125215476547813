import {Component, OnInit} from '@angular/core'
import {Router} from "@angular/router"
import {AuthenticationService} from '../../../app.services/managers/authentication/authentication.service'
import {Title} from '@angular/platform-browser'
import {
  RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Component({
  selector: 'app-public-authenticator-page',
  templateUrl: './public-authenticator-page.component.html',
  styleUrls: ['./public-authenticator-page.component.scss']
})
export class PublicAuthenticatorPageComponent implements OnInit {

    constructor(private title: Title,
                private router: Router,
                private authenticationService: AuthenticationService,
                private runtimeConfigurationService: RuntimeConfigurationService) {

        title.setTitle(`Login - ${this.runtimeConfigurationService.title}`);

    }

    ngOnInit() {

        if (this.authenticationService.isAuthenticated()) {
            this.router.navigate(['/retail/profile']);
        } else if (this.authenticationService.isBackOfficeUser()) {
            this.router.navigate(['/console/home']).then(() => {
                return;
            });
        }

    }

}
