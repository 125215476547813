import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../shared/form/form.component'
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Title} from '@angular/platform-browser'
import {Location} from '@angular/common'
import {ActivatedRoute, Params} from '@angular/router'
import {
    MakeModelTrimProviderService
} from '../../../http.services/vehicle/makemodeltrim/make-model-trim-provider/make-model-trim-provider.service'
import {
    RuntimeConfigurationService
} from '../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ToastComponent} from '../../shared/toast/toast.component'
import {environment} from '../../../../environments/environment'
import {
    AllMakeCriteria,
    Make,
    MakeDescriptionCriteria,
    MakeDescriptionIdentifier,
    Model,
    ProvideVehicleMakesRequest,
    ProvideVehicleMakesResponse,
    ProvideVehicleModelsRequest,
    ProvideVehicleModelsResponse,
    UnMarshallerService,
    VehicleMakeCriteria
} from "@magnabc/tpi"
import {ErrorToastService} from '../../../app.services/common/error-toast/error-toast.service'

@Component({
  selector: 'app-make-model-trim-update',
  templateUrl: './make-model-trim-update.component.html',
  styleUrls: ['./make-model-trim-update.component.scss']
})
export class MakeModelTrimUpdateComponent  extends FormComponent implements OnInit, OnDestroy {

    formGroup: UntypedFormGroup;
    nameFormControl: UntypedFormControl;
    modelFormControl: UntypedFormControl;
    makeFormControl: UntypedFormControl;

    @ViewChild('form', { static: true }) form: FormGroupDirective;

    type: string;
    id: string;
    exists = false;
    activeToast: ActiveToast<any>;

    makes: Make[];
    make: Make;

    models: Model[];
    model: Model;

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private toastr: ErrorToastService,
                private location: Location,
                private route: ActivatedRoute,
                private makeModelTrimProviderService: MakeModelTrimProviderService,
                private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser);

        title.setTitle(`Update Vehicle Make - Console - ${this.runtimeConfigurationService.title}`);

        this.nameFormControl = new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]);
        this.modelFormControl = new UntypedFormControl('', []);
        this.makeFormControl = new UntypedFormControl('', []);

        this.formGroup = new UntypedFormGroup({
            'nameControl': this.nameFormControl,
            'modelControl': this.modelFormControl,
            'makeControl': this.makeFormControl
        });

    }

    ngOnInit(): void {

        //noinspection JSIgnoredPromiseFromCall
        this.route.queryParams.forEach((params: Params) => {

            this.type = params['type'];

            if (this.type) {

                if (this.type === 'trim') {
                    this.modelFormControl.disable();
                }
                if (this.type === 'trim' || this.type === 'model') {
                    this.makeFormControl.disable();
                }

            }

        });

        //noinspection JSIgnoredPromiseFromCall
        this.route.params.forEach((params: Params) => {

            this.id = params['id'];
            // this.nameFormControl.setValue(StringUtils.applySentenceCase(params['id']));

            switch (this.type) {
                case 'make':

                    const criteria = new AllMakeCriteria();
                    const request = new ProvideVehicleMakesRequest();
                    request.criteria = criteria;

                    this.makeModelTrimProviderService.provideMakes(request).then(httResponse => {

                        if (httResponse && httResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httResponse.body, ProvideVehicleMakesResponse) as ProvideVehicleMakesResponse);

                            // this.makes = response.makes;
                            for (const make of response.makes) {
                                if (this.id === make.name) {
                                    this.make = make;
                                    this.nameFormControl.setValue(this.make.name);
                                }
                            }
                        }
                    });

                    break;
                case 'model':

                    const makeDescriptionCriteria = new MakeDescriptionCriteria();
                    const makeDescriptionIdentifier = new MakeDescriptionIdentifier();
                    makeDescriptionIdentifier.description = this.id;
                    makeDescriptionCriteria.makeIdentifier = makeDescriptionIdentifier;
                    const provideModelRequest = new ProvideVehicleModelsRequest();
                    provideModelRequest.criteria = makeDescriptionCriteria;
                    this.makeModelTrimProviderService.provideModel(provideModelRequest).then(httpResponse => {

                        if (httpResponse && httpResponse.body) {
                            const response = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideVehicleModelsResponse) as ProvideVehicleModelsResponse)

                            this.model = response.models[0];
                            this.nameFormControl.setValue(this.model.name);
                            this.makeFormControl.setValue(this.model.makeIdentifier);

                            const vehicleMakeCriteria2 = new VehicleMakeCriteria();
                            vehicleMakeCriteria2.name = this.model.makeIdentifier.toString();

                            const provideModelsRequest = new ProvideVehicleModelsRequest();
                            provideModelsRequest.criteria = vehicleMakeCriteria2;
                            this.makeModelTrimProviderService.provideModel(provideModelsRequest).then(httpModelResponse => {
                                if (httpModelResponse && httpModelResponse.body) {
                                    const modelResponse = (this.unMarshallerService.unMarshallFromJson(httpModelResponse.body, ProvideVehicleModelsResponse) as ProvideVehicleModelsResponse);
                                    this.models = modelResponse.models;
                                }
                            });
                        }

                    });

                    break;
            }

        });

    }

    ngOnDestroy(): void {
        if (this.activeToast) {
            this.activeToast.portal.destroy();
        }
    }

    onSubmitUpdateDropdown(event): void {

        if (!this.form.invalid) {

            if (this.make) {

                if (this.contains(this.makes, this.nameFormControl.value)) {
                    this.exists = true;
                    return;
                } else {
                    this.exists = false;
                }

                // this.make.name = this.nameFormControl.value; // FIXME
                //
                // const updateMakeRequest = new UpdateVehicleMakeRequest();
                // updateMakeRequest.make = this.make;
                // this.startLoad();
                // this.makeModelTrimIdentifierProviderService.updateMake(updateMakeRequest).subscribe((response) => {
                //     this.stopLoad();
                //     this.toast();
                // });
            }

            if (this.model) {

                if (this.contains(this.models, this.nameFormControl.value)) {
                    this.exists = true;
                    return;
                } else {
                    this.exists = false;
                }

                // this.model.name = this.nameFormControl.value; // FIXME
                //
                // const updateModelRequest = new UpdateVehicleModelRequest();
                // updateModelRequest.model = this.model;
                // this.startLoad();
                // this.makeModelTrimService.updateModel(updateModelRequest).subscribe((response) => {
                //     this.stopLoad();
                //     this.toast();
                // });
            }

        }

    }

    contains(list, item): boolean {

        for (const i of list) {
            if (i.name.toLowerCase() === item.toLowerCase()) {
                return true;
            }
        }

        return false;

    }

    toast() {

        this.translate.get('TOAST.UPDATE_DROPDOWN').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: this.nameFormControl.value}), res.TITLE, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:
                        this.location.back();
                        this.activeToast.portal.destroy();
                        break;
                }
            });
            this.activeToast.onHidden.subscribe(() => {
                this.location.back();
            });
        });

    }

    onCancel(): void {
        this.location.back();
    }

}
