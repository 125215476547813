export class Duration {

    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.temporal.Duration';
    }

    hours: number;
    days: number;
    months: number;
    years: number;

}

