import {Component} from "@angular/core"
import {Title} from '@angular/platform-browser'
import {
    RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

declare const jQuery: any;

@Component({
    selector: 'app-retail-profile-page',
    templateUrl: './retail-profile-page.component.html',
    styleUrls: ['./retail-profile-page.component.scss']
})
export class RetailProfilePageComponent {

    constructor(private title: Title, private runtimeConfigurationService: RuntimeConfigurationService) {
        title.setTitle(`Retail Profile - ${this.runtimeConfigurationService.title}`);
    }

}
