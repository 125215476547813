import { SupplierInvoiceSummary } from '../../../../../models/supplier-invoice-summary.model';
import { Response } from '../../../../../../../urdad/services/models/response.model';

export class ProvideSupplierInvoiceSummaryResponse extends Response {
    constructor(){
        super();
    }

    public resultsFrom: number;
    public resultsTo: number;
    public totalNumberOfResults: number;
    public supplierInvoiceSummaries: SupplierInvoiceSummary [] = [];

}
