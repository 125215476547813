import { NaturalPersonIdentifier } from "../../models/natural-person-identifier.model";

export class TaxNumber extends NaturalPersonIdentifier {

    constructor(){
        super();
        this['@class'] = "za.co.magnabc.tpi.entity.identification.naturalperson.TaxNumber";
    }

}
