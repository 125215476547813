import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
    CountryCheckoutItemGrouping,
    CountryDescriptionIdentifier,
    CountryIdentifier,
    CountryRegistrationConfiguration, CountryRegistrationConfigurationInformation,
    LegalEntity,
    PickUpPoint,
    ScannedDocumentIdentifier,
    UnderwriterCheckoutItemGrouping
} from "@magnabc/tpi"
import { RaygunErrorHandler } from '../../../../common/utils/utils.raygun';
import { CheckoutManagerComponent } from "../checkout-manager/checkout-manager.component";
import { CheckoutManagerService } from '../../../../http.services/retail/checkout/checkout-manager/checkout-manager.service';
import { FormGroup } from '@angular/forms';
import { LegalEntityDetails } from '@magnabc/tpi/src/entity/models/legal-entity-details.model';
@Component({
    selector: 'app-country-checkout-retriever',
    templateUrl: './country-checkout-retriever.component.html',
    styleUrls: ['./country-checkout-retriever.component.scss']
})
export class CountryCheckoutRetrieverComponent implements OnInit {

    @Input() countries: CountryIdentifier;
    @Input() legalEntity: LegalEntity;
    @Input() countryRegistrationConfigurationInformation: CountryRegistrationConfigurationInformation;
    @Input() countryRegistrationConfigurationList: CountryRegistrationConfiguration[] = [];
    @Input() naturalPerson: boolean;
    @Input() judicialPerson: boolean;
    @Input() countryRegistrationConfigurationInformationProvided: boolean;
    @Input() scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
    @Input() scannedDocumentIdentifierMap: { [id: string]: ScannedDocumentIdentifier } = {};
    @Input() pickUpPoints: PickUpPoint[];
    @Input() legalEntityDetails: LegalEntityDetails;

    @Output() formCaptureShipment = new EventEmitter<FormGroup>(null);

    countryDescription: string;

    @ViewChildren('CheckoutManagerComponent') public checkoutManagers: QueryList<CheckoutManagerComponent>;

    constructor(public checkoutManagerService: CheckoutManagerService) { }

    ngOnInit() { }

    validate(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const promises = [];
            for (const checkoutManager of this.checkoutManagers.toArray()) {
                if(checkoutManager && checkoutManager.validate){
                  promises.push(checkoutManager.validate());
                }
            }
            Promise.all(promises).then(() => {
                resolve({});
            }).catch((error) => {
                RaygunErrorHandler.sendError(error);

                reject(error);
            });
        })
    }

    onShipmentFormChanges(form: FormGroup): void {
        this.formCaptureShipment.emit(form);
    }
}
