
export enum VerifiedAccountManagerEnum {
    CAPTURE_NATURAL_PERSON_ID,

    /* Existing Legal Entity */
    CAPTURE_INTERNAL_OTP = 10,
    CAPTURE_INTERNAL_PASSWORD,
    CAPTURE_INTERNAL_PERSON,
    CAPTURE_INTERNAL_PERSON_CONTACT,
    CAPTURE_INTERNAL_OTP_UPDATED,

    /* Existing External Legal Entity */
    CAPTURE_EXTERNAL_PERSON_ID = 20,
    CAPTURE_EXTERNAL_OBSCURED_NUMBER,
    CAPTURE_EXTERNAL_OTP,
    CAPTURE_UPDATED_EXT_PERSON,
    CAPTURE_UPDATED_EXT_PERSON_CONTACT,
    CAPTURE_EXT_OTP_UPDATED,

    /* New Legal Entity */
    CAPTURE_NEW_PERSON = 40,
    CAPTURE_NEW_PERSON_CONTACT,
    CAPTURE_NEW_PERSON_OTP,

    CAPTURE_PASSWORD = 50
}
