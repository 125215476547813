import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {
  MarshallerService,
  PaymentProfile,
  PaymentProfileLegalEntityCriteria,
  ProvidePaymentProfileInformationRequest
} from '@magnabc/tpi'
import {
  PaymentProfileInformationProviderService
} from '../payment-profile-information-provider/payment-profile-information-provider.service'
import {
  RuntimeConfigurationService
} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {Utils} from '../../../../view.components/shared/Utils/Utils'
import {
    LegalEntityFinalisationManagerService
} from "../../../entity/legal-entity-finalisation-manager/legal-entity-finalisation-manager.service";

@Injectable({
  providedIn: 'root'
})
// TTE 05-Feb-2024 FIXME: This is not a persistence manager service - it needs to be deleted and its functionality
//                  inlined directly into DirectPayOnlinePaymentGatewayManagerComponent
export class PaymentPersistenceManagerService {

  paymentProfile: PaymentProfile;

  constructor(private http: HttpClient,
              entityService: LegalEntityFinalisationManagerService,
              private marshallerService: MarshallerService,
              private paymentProfileInformationProviderService: PaymentProfileInformationProviderService,
              private runtimeConfigurationService: RuntimeConfigurationService) {

    entityService.registerLegalEntityListener((legalEntity) => {
      this.refreshRetailProfile(legalEntity);
    });

  }

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  refreshRetailProfile(legalEntity, callback = null): void {

    const paymentProfileLegalEntityCriteria = new PaymentProfileLegalEntityCriteria();
    paymentProfileLegalEntityCriteria.legalEntity = Utils.getTpiIdentifier(legalEntity.legalEntityIdentifiers);

    const providePaymentProfileInformationRequest = new ProvidePaymentProfileInformationRequest();
    providePaymentProfileInformationRequest.criteria = paymentProfileLegalEntityCriteria;

    this.paymentProfileInformationProviderService.providePaymentProfileInformation(providePaymentProfileInformationRequest).subscribe((response) => {
      if (response.body.paymentProfiles.length > 0) {
        this.paymentProfile = response.body.paymentProfiles[0];
      }
      if (callback) {
        callback(this.paymentProfile);
      }
    });

  }
}
