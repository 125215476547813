import { Money } from "../../../financial/financial_api";
import { CountryIdentifier } from "../../../location/location_api";
import { JournalEntryType } from "../../models/journal-entry-type.model";

export class FinancialAccount{
    constructor(){ 
        this['@class'] = "za.co.magnabc.tpi.ledger.account.FinancialAccount";
    }

    public legalEntityIdentifier : string;
    public number : string;
    public balance : Money;
    public balanceType : JournalEntryType;
    public countryIdentifier : CountryIdentifier;

}