import {Response} from '../../../../../../../urdad/services/models/response.model';
import { Vehicle } from '../../../../../../../vehicle/vehicle_api';

export class FinaliseLinkVehicleResponse extends Response{

    constructor() {
        super();
    }

    public vehicle: Vehicle;
}

