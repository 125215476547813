import {MatTabsModule} from '@angular/material/tabs'
import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {LoadingModule} from "ngx-loading"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {SharedModule} from "../shared/shared.module"
import {BrowserModule} from "@angular/platform-browser"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {RouterModule} from "@angular/router"
import {EntityModule} from "../entity/entity.module"
import {HttpClientModule} from "@angular/common/http"
import {TranslateModule} from "@ngx-translate/core"
import {SecurityModule} from "../security/security.module"
import {TextMaskModule} from 'angular2-text-mask'
import {NgxDatatableModule} from '@swimlane/ngx-datatable'
import {ManageUsersListComponent} from './manage-users-list/manage-users-list.component'
import {MatChipsModule} from '@angular/material/chips'
import {MatIconModule} from "@angular/material/icon"
import {MatPaginatorModule} from "@angular/material/paginator"
import {MatExpansionModule} from "@angular/material/expansion"
import {VerifyDisableUserDialogComponent} from './verify-disable-user-dialog/verify-disable-user-dialog.component'
import {MatDialogModule} from "@angular/material/dialog"
import {UserUpdateComponent} from './user-update/user-update.component'
import {MatGridListModule} from "@angular/material/grid-list"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatButtonModule} from "@angular/material/button"
import {DragDropModule} from "@angular/cdk/drag-drop"
import {AccountBranchComponent} from './account-branch/account-branch.component'
import {AccountServiceGroupsComponent} from './account-service-groups/account-service-groups.component'
import {MatLegacyChipsModule} from '@angular/material/legacy-chips'

@NgModule({
    declarations: [
        ManageUsersListComponent,
        VerifyDisableUserDialogComponent,
        UserUpdateComponent,
        AccountBranchComponent,
        AccountServiceGroupsComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        RouterModule,
        CommonModule,
        TranslateModule,
        MatSelectModule,
        MatInputModule,
        LoadingModule,
        SharedModule,
        EntityModule,
        SecurityModule,
        MatCheckboxModule,
        TextMaskModule,
        NgxDatatableModule,
        MatChipsModule,
        MatIconModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatDialogModule,
        MatGridListModule,
        MatTooltipModule,
        MatButtonModule,
        DragDropModule,
        MatTabsModule,
        MatLegacyChipsModule
    ],
    exports: [
        AccountBranchComponent,
        ManageUsersListComponent
    ]
})
export class UserAccountManagementModule { }
