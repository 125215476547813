import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Params, Router} from '@angular/router'
import {
    ProposalReleaseManagerService
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalreleasemanager/proposal-release-manager.service'
import {
    ProposalCheckoutManagerService
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalcheckoutmanager/proposal-checkout-manager.service'
import {
    ProposalGroupEntryProviderService
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalgroupentryprovider/proposal-group-entry-provider.service'
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service'
import {
    ProposalInformationProviderService
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service'
import {Utils} from '../../../shared/Utils/Utils'
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'
import {
    CheckoutProposalRequest,
    CheckoutProposalResponse,
    LegalEntityIdentifier,
    ProposalGroupEntryAssessorCriteria,
    ProposalRecordStatus,
    ProposalRecordStatusCriteria,
    ProvideProposalGroupEntryRequest, ProvideProposalGroupEntryResponse,
    ProvideProposalInformationRequest,
    ProvideProposalInformationResponse, ProvideServiceGroupsResponse,
    ReleaseProposalRequest,
    UnderwriterLegalEntityIdentifier,
    UnMarshallerService,
} from '@magnabc/tpi'
import {
    UnderwriterManagerService
} from '../../../../app.services/managers/underwriter-manager/underwriter-manager.service'

export interface ProposalEntry {
    proposalId: string;
    proposalScore: number;
    proposalDate: Date;
}

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit, OnDestroy {

    loading = false;
    proposalStatus: string;
    proposalQuantity: number;
    proposalAssesor: LegalEntityIdentifier;
    // currentUnderwriter: UnderwriterLegalEntityIdentifier;
    loadingIndicator = true;
    proposalList: ProposalEntry[] = [];
    tempList: ProposalEntry[] = [];
    pageNumber = 0;
    pageSize = 10;
    totalElements = 0;
    subscriptions = new Subscription();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private proposalReleaseManagerService: ProposalReleaseManagerService,
                private proposalCheckoutManagerService: ProposalCheckoutManagerService,
                private proposalGroupEntryProviderService: ProposalGroupEntryProviderService,
                private unMarshallerService: UnMarshallerService,
                private underwriterManagerService: UnderwriterManagerService,
                private authenticationService: AuthenticationService,
                private proposalInformationProviderService: ProposalInformationProviderService) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.route.params.forEach((params: Params) => {
            this.proposalStatus = params["status"];
            this.proposalQuantity = params["quantity"];

        });

        this.proposalAssesor = this.authenticationService.getLegalEntityDescriptionIdentifier().tpiIdentifier;

        if (this.proposalStatus === 'open' && (history.state.proposalCount || this.proposalQuantity)) {
            const quantity = this.proposalQuantity ? this.proposalQuantity : history.state.proposalCount;
            const subscription = this.underwriterManagerService.$selectedUnderwriterLegalEntityIdentifier.subscribe((value) => {
                if (value) {
                    this.checkoutProposal(quantity, false).catch(() => {
                        this.provideProposals()
                    });
                }
            });
            this.subscriptions.add(subscription);
        }
        else if (this.proposalStatus === 'open' || this.proposalStatus === 'pending') {
            this.provideProposals()
        }
    }

    provideProposals() {
       const subscription = this.underwriterManagerService.$selectedUnderwriterLegalEntityIdentifier.subscribe(() => {
            this.getProposalGroupEntry().then((status) => {
                if (status) {
                    if (this.proposalStatus === 'pending') {
                        this.provideProposalInformation(ProposalRecordStatus.PENDING);
                    } else if (this.proposalStatus === 'open') {
                        this.provideProposalInformation(ProposalRecordStatus.OPEN);
                    }
                }
            });
        });
        this.subscriptions.add(subscription);
    }

    provideProposalInformation(status){
        this.proposalList = [];
        var provideProposalInformationRequest = new ProvideProposalInformationRequest();
        var proposalProposalRecordStatusCriteria = new ProposalRecordStatusCriteria();

        proposalProposalRecordStatusCriteria.status = status;
        proposalProposalRecordStatusCriteria.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier;

        provideProposalInformationRequest.criteria = proposalProposalRecordStatusCriteria;
        provideProposalInformationRequest.batchNumber = this.pageNumber + 1;
        provideProposalInformationRequest.batchSize = this.pageSize;
        this.proposalInformationProviderService.provideProposalInformation(provideProposalInformationRequest).then((httpResponse) => {
            if (httpResponse != null) {
                const response = this.unMarshallerService.unMarshallFromJson(
                    httpResponse.body,
                    ProvideProposalInformationResponse
                ) as ProvideProposalInformationResponse;

                for(var proposalSummary of response.proposalSummary){
                    this.proposalList = [
                        ...this.proposalList,
                        {
                            proposalId: proposalSummary.proposalId,
                            proposalScore: 0,
                            proposalDate: proposalSummary.captureDateTime
                        },
                    ];
                }
                this.totalElements = response.totalNumberOfResults;
                this.tempList = this.proposalList;
            }
        });
    }

    setPage(event) {
        this.pageNumber = event.offset;
        if (this.proposalStatus === 'pending') {
            this.provideProposalInformation(ProposalRecordStatus.PENDING);
        } else if (this.proposalStatus === 'open') {
            this.provideProposalInformation(ProposalRecordStatus.OPEN);
        }
    }

    getProposalGroupEntry() {
        var provideProposalGroupEntryRequest = new ProvideProposalGroupEntryRequest();

        var proposalGroupEntryAssessorCriteria = new ProposalGroupEntryAssessorCriteria();

        proposalGroupEntryAssessorCriteria.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier;
        proposalGroupEntryAssessorCriteria.assessor = this.proposalAssesor;

        provideProposalGroupEntryRequest.criteria = proposalGroupEntryAssessorCriteria;

        this.proposalList = [];

        return new Promise<Boolean>((resolve, reject) => {
            this.proposalGroupEntryProviderService
                .provideProposalGroupEntry(provideProposalGroupEntryRequest)
                .then((httpResponse) => {
                    if (httpResponse && httpResponse.body) {
                        const provideProposalGroupEntryResponse = (this.unMarshallerService.unMarshallFromJson(httpResponse.body, ProvideProposalGroupEntryResponse) as ProvideProposalGroupEntryResponse);
                        if (provideProposalGroupEntryResponse.proposalGroupEntries.length > 0) {

                            var proposalGroupEntry = provideProposalGroupEntryResponse.proposalGroupEntries[0];

                            for(var entryId of proposalGroupEntry.proposalIds){
                                this.proposalList = [
                                    ...this.proposalList,
                                    {
                                        proposalId: entryId,
                                        proposalScore: 0,
                                        proposalDate: null
                                    },
                                ];
                            }
                            this.tempList = this.proposalList;
                            this.totalElements = this.proposalList.length;;

                            resolve(true);

                            return;
                        }
                    }
                })
                .catch((err) => {
                    RaygunErrorHandler.sendError(err);

                    resolve(null);
                    return;
                });
        });
    }

    releaseProposal(proposalId: string) {
        var releaseProposalRequest = new ReleaseProposalRequest();
        releaseProposalRequest.proposalId = proposalId;

        return new Promise<boolean>(
            (resolve, reject) => {
                this.proposalReleaseManagerService
                    .releaseProposal(releaseProposalRequest)
                    .toPromise()
                    .then((httpResponse) => {
                        if (httpResponse && httpResponse.body) {
                            this.getProposalGroupEntry().then((status) => {
                                if (status) {
                                    //this.populateProposalList();
                                }
                            });
                        } else {
                            console.log("not done");
                        }
                    })
                    .catch((error) => {
                        RaygunErrorHandler.sendError(error);
                        console.log("not done");
                    });
            }
        );
    }

    checkoutProposal(proposalValue, isProposalId: boolean) {
        var checkoutProposalRequest = new CheckoutProposalRequest();

        checkoutProposalRequest.assessor = this.proposalAssesor;

        if(isProposalId){
            checkoutProposalRequest.proposalId = proposalValue;
            this.proposalList = [];
        }
        else{
            checkoutProposalRequest.quantity = proposalValue;
        }
        checkoutProposalRequest.underwriterIdentifier = this.underwriterManagerService.selectedUnderwriterLegalEntityIdentifier;

        return new Promise<boolean>(
            (resolve, reject) => {
                this.proposalCheckoutManagerService
                    .checkoutProposal(checkoutProposalRequest)
                    .toPromise()
                    .then((httpResponse) => {
                        if (httpResponse && httpResponse.body) {
                            const response = this.unMarshallerService.unMarshallFromJson(
                                httpResponse.body,
                                CheckoutProposalResponse
                            ) as CheckoutProposalResponse;

                            for(var entryId of response.proposalGroupEntry.proposalIds){
                                this.proposalList = [
                                    ...this.proposalList,
                                    {
                                        proposalId: entryId,
                                        proposalScore: 0,
                                        proposalDate: null
                                    },
                                ];
                            }
                            this.tempList = this.proposalList;
                            this.totalElements = this.proposalList.length;;

                            this.router.navigate(["/proposal-list/open/" + checkoutProposalRequest.quantity]);

                            resolve(true);
                        } else {
                            console.log("not done");
                            this.router.navigate(["/proposal-list/open/"+ checkoutProposalRequest.quantity]);

                        }
                    })
                    .catch((error) => {
                        RaygunErrorHandler.sendError(error);
                        console.log("not done");
                        this.router.navigate(["/proposal-list/open/" + checkoutProposalRequest.quantity]);
                        reject()
                    });

            }
        );
    }

    filterProposals(event) {
        const val = event.target.value.toLowerCase();

        if(val === "" || event.target.value === "" || event.target.value === undefined){
            this.proposalList = this.tempList;
        }
        else{
            this.proposalList = this.tempList.filter((d) => {
                return d.proposalId.toLowerCase().indexOf(val) !== -1 || !val;
            });
        }
    }

    getPreviewId(proposalId: string){
        var previewId = proposalId;

        if(proposalId.indexOf('_') != -1){
            previewId = proposalId.substring(0, proposalId.indexOf('_'));
        }

        return previewId;
    }
}
