import {DocumentType} from "./document-type.model";

export class ScannedDocument {

  constructor() {
    this['@class'] = 'za.co.magnabc.tpi.media.scanneddocument.ScannedDocument';
  }

  public documentName: string;
  public documentIdentifier: string;
  public documentType: DocumentType;
  public document: Uint32Array[] = [];
}
