import { DepositMethod } from "./deposit-method.model";
import { CountryIdentifier } from "../../../location/location_api";
import { AccountType } from "../../models/account-type.model";

export class DepositBankTransfer extends DepositMethod{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.deposit.BankTransfer";
    }

    public accountName : string;
    public accountNumber : string;
    public accountType : AccountType;

    public bankName : string;
    public branchCode : string;
    public swiftBicCode : string;
    public countryIdentifier : CountryIdentifier;
}
