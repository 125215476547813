import {Injectable} from '@angular/core';
import {PublicOtpAuthenticatorProvider} from '../public-otp-authenticator-provider/public-otp-authenticator-provider.service';
import {
    Credentials,
    NationalIdentityNumber,
    NaturalPersonIdentifierCredential,
    OneTimePinIdentifier,
    PublicOTPAuthenticateCredentialsRequest,
    PublicOTPAuthenticateCredentialsResponse,
    PublicOTPInitialiseAuthenticationRequest,
    PublicOTPInitialiseAuthenticationResponse,
    PublicOTPValidateObscuredContactNumberRequest,
    PublicOTPValidateObscuredContactNumberResponse,
    UnMarshallerService
} from '@magnabc/tpi';
import {AuthenticationService} from '../../../../app.services/managers/authentication/authentication.service';
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun';

@Injectable({
    providedIn: 'root'
})
export class OneTimePinPublicAuthenticatorService {

    private oneTimePinIdentifier: OneTimePinIdentifier;
    private credentials: Credentials;

    constructor(private publicOtpAuthenticatorProvider: PublicOtpAuthenticatorProvider,
                private authenticationService: AuthenticationService,
                private unMarshallerService: UnMarshallerService) {
    }

    initialisePublicOTPAuthentication(naturalPersonIdentifierCredentials: NaturalPersonIdentifierCredential) {



        const request = new PublicOTPInitialiseAuthenticationRequest();
        request.credentials = naturalPersonIdentifierCredentials;

        return new Promise<string>((resolve, reject) => {
            this.publicOtpAuthenticatorProvider.initialiseAuthentication(request).then(httpResponse => {
                if (httpResponse && httpResponse.body){
                    this.credentials = naturalPersonIdentifierCredentials;
                    const provideContactNumberResponse = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, PublicOTPInitialiseAuthenticationResponse) as PublicOTPInitialiseAuthenticationResponse);
                    resolve(provideContactNumberResponse.obscuredContactNumber);
                    return;
                }
                reject('Account could not be found.');
                return;
            }).catch(error => {
                console.error('Error while issuing OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                reject('Account could not be found.');
                return;
            });
        });
    }

    validatePublicOTPObscured(fullContactNumber: string) {
        const request = new PublicOTPValidateObscuredContactNumberRequest();
        request.obscuredNumbers = fullContactNumber;
        request.credentials = this.credentials;

        return new Promise<boolean>((resolve, reject) => {
            this.publicOtpAuthenticatorProvider.validateObscuredContactNumber(request).then(httpResponse => {
                if (httpResponse && httpResponse.body) {
                    const validateContactNumberResponse = (this.unMarshallerService.
                    unMarshallFromJson(httpResponse.body, PublicOTPValidateObscuredContactNumberResponse) as PublicOTPValidateObscuredContactNumberResponse);
                    this.oneTimePinIdentifier = validateContactNumberResponse.oneTimePinIdentifier;

                    resolve(true);
                    return
                }
                resolve(false);
                return
            }).catch(error => {
                console.error(error);
                RaygunErrorHandler.sendError(error);

                resolve(false);
                return
            });
        });
    }

    authenticateCredentialsByOTP(otp: string) {

        const request = new PublicOTPAuthenticateCredentialsRequest();
        request.capturedOneTimePin = otp;
        request.oneTimePinIdentifier = this.oneTimePinIdentifier;
        request.credentials = this.credentials;

        return new Promise<boolean>(((resolve, reject) => {
            if (!this.credentials || !this.oneTimePinIdentifier){
                resolve(false);
                return;
            }

            this.publicOtpAuthenticatorProvider.authenticateCredentials(request).then(httpResponse => {

                if (httpResponse && httpResponse.body){
                    const response = (this.unMarshallerService.
                        unMarshallFromJson(httpResponse.body, PublicOTPAuthenticateCredentialsResponse) as PublicOTPAuthenticateCredentialsResponse);

                    if (response.authenticationAdvice.authentic) {
                        console.log('Logged in!');
                        return this.authenticationService.setAuthentication(response.authenticationAdvice);
                    } else {
                        console.log('Login failure');
                        resolve(false);
                        return;
                    }
                }
            }).then(authenticationAdvice => {
                resolve(authenticationAdvice);
                return;
            }).catch(error => {
                console.error('Error while authenticating OTP :: ', error);
                RaygunErrorHandler.sendError(error);

                reject(error);
                return;
            })
        }));
    }
}
