import { TpiIdentifier } from '../../../../../../../entity/identification/models/tpi-identifier.model';
import { Request } from "../../../../../../../urdad/services/models/request.model";
import { JudicialPerson } from '../../../../../../../entity/models/judicial-person.model';

export class FinaliseCreatePrincipalByUpdateRequest extends Request {
    constructor(){
        super();
    }

    public ownedBy: JudicialPerson;
    public usedBy: TpiIdentifier;
}
