import {Injectable} from '@angular/core'
import {Account, Agent} from "@magnabc/tpi"
import {RaygunErrorHandler} from '../../../../common/utils/utils.raygun'

@Injectable({
  providedIn: 'root'
})
export class OneTimePinVerificationManagerProviderService {

  static STORAGE_AGENT = 'agent_v3';
  static STORAGE_JWT = 'jwt_v3';
  static STORAGE_ACTIVE_SUBSCRIPTION = 'active_subscription_v3';

  basePath = '/security/onetimepin/onetimepinverificationmanager';

  accountListeners = [];

  jwt: any;
  agents: Agent[] = [];
  logoutAgentOnUserLogout = false;

  account: Account | any;
  activeAccount: Account;

  constructor() {
    if (localStorage.getItem(OneTimePinVerificationManagerProviderService.STORAGE_AGENT) && localStorage.getItem(OneTimePinVerificationManagerProviderService.STORAGE_AGENT) !== "undefined") {
      //this.setAgent(JSON.parse(localStorage.getItem(OneTimePinVerificationManagerProviderService.STORAGE_AGENT)));
    }
  }

  reset(): void {

    localStorage.removeItem(OneTimePinVerificationManagerProviderService.STORAGE_JWT);
    localStorage.removeItem(OneTimePinVerificationManagerProviderService.STORAGE_ACTIVE_SUBSCRIPTION);
    this.accountListeners = [];
    this.jwt = null;
    this.account = null;
    this.activeAccount = null;
    if (this.logoutAgentOnUserLogout) {
      this.agents = [];
      // this.setAgent(null);
      this.logoutAgentOnUserLogout = false;
    }


  }

  onJWT(token): void {
    console.error('Should not use this service for JWT.');
    RaygunErrorHandler.sendError('Should not use this service for JWT.');
  }

  registerActiveAccountListener(listener): void {
    this.accountListeners.push(listener);
    if (this.activeAccount) {
      listener(this.activeAccount);
    }
  }

  parseJwt(token): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
