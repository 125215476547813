import { Cart } from "./cart.model";
import { CustomerToken } from "../../payment/dpo/models/customer-token.model";
import {LegalEntityDescriptionIdentifier} from '../../../entity/identification/models/legal-entity-description-identifier.model';
import {VehicleIdentifier} from '../../../vehicle/identification/models/vehicle-identifier.model';
import {OrderIdentifier} from '../../checkout/identification/models/order-identifier.model';

export class RetailProfile {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.profile.RetailProfile";
    } 

    public legalEntityDescriptionIdentifier: LegalEntityDescriptionIdentifier;
    public cart: Cart;
    public vehicleIdentifiers: VehicleIdentifier[] = [];
    public orderIdentifiers: OrderIdentifier[] = [];
    public customerToken: CustomerToken;
}
