import { Criteria } from "../../../criteria/criteria_api";
import { CountryIdentifier } from "../../../location/location_api";

export class FinancialAccountCountryLegalEntityCriteria extends Criteria{
    constructor(){ 
        super();
        this['@class'] = "za.co.magnabc.tpi.ledger.financialaccount.FinancialAccountCountryLegalEntityCriteria";
    }

    public countryIdentifier : CountryIdentifier;
    public legalEntityIdentifiers : string [] = [];
}