import {Subscription} from 'rxjs'
import {Component, OnDestroy} from '@angular/core'
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms'
import {ActivatedRoute, Router} from '@angular/router'
import {Title} from '@angular/platform-browser'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {ActiveToast, ToastrService} from 'ngx-toastr'
import {FormComponent} from '../../../../../view.components/shared/form/form.component'
import {
    InsuranceProductManagerService
} from '../../../../../app.services/managers/insurance-product-manager/insurance-product-manager.service'
import {IStepComponent} from '../../../../../view.components/shared/guards/step-guard'
import {ProductLimitEnum} from './product-limit.enum'
import {Dropdown} from '../../../../../common/model/dropdown.model'
import {
    ComprehensiveInsuranceProduct,
    InsuranceCategory,
    Limit,
    LimitInterval,
    LimitValueType,
    PremiumAdjustmentCategory,
    ThirdPartyInsuranceProduct,
    YCInsuranceProduct
} from "@magnabc/tpi"
import {Location, LocationStrategy} from '@angular/common'
import {ToastComponent} from '../../../../../view.components/shared/toast/toast.component'
import {ObjectUtils} from '../../../../../common/utils/utils.object'
import {
    RuntimeConfigurationService
} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'
import {ErrorToastService} from '../../../../../app.services/common/error-toast/error-toast.service'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'

@Component({
  selector: 'app-product-limit',
  templateUrl: './product-limit-page.component.html',
  styleUrls: ['./product-limit-page.component.scss']
})
export class ProductLimitPageComponent extends FormComponent implements IStepComponent, OnDestroy {

    backPressed: boolean;
    steps = [];
    currentStep = 0;
    breadcrumbStep = 0;

    excessTypes: Dropdown[] = [];
    limitTypes = [];
    limit = new Limit();
    limitCustomizationCopy : Limit;
    limitAdjustmentCopy : Limit;

    limitType: UntypedFormControl;
    description: UntypedFormControl;
    standartAmountOrPecentage: UntypedFormControl;
    identifier: UntypedFormControl;
    customisable: UntypedFormControl;
    minimumValue: UntypedFormControl;
    maximumValue: UntypedFormControl;
    userIncrementValue: UntypedFormControl;
    premiumAdjustmentCategory: UntypedFormControl;
    proRata: UntypedFormControl;
    pricePerSeat:UntypedFormControl;
    formGroupLimit: UntypedFormGroup;
    checkValues = [];

    typePlaceholder = "Limit Value";
    showPercentage= false;
    showPassangerSeatingValue = false;
    invalidMaxValue = false;
    sameRangeValue = false;
    invalidRange = false;
    invalidUserIncrementValue = false;
    invalidMaxLimitValue = false;
    invalidMinValue = false;
    invalidMinMaxValue = false;
    rangeFromNotEqualMinValue = false;
    rangeToNotEqualMaxValue = false;
    invalidLastToValue = false;
    states = ProductLimitEnum;
    currentState: ProductLimitEnum = ProductLimitEnum.CAPTURE_LIMIT_DETAILS;
    lastToValue: number;
    showPerPassenger = false;

    activeToast: ActiveToast<any>;
    productNumber: string;
    currentLimitTypeValue: string;

    subscriptions = new Subscription();
    defaultSteps = [];

    constructor(private translate: TranslateService,
                private translateParser: TranslateParser,
                private title: Title,
                private router: Router,
                private toastr: ErrorToastService,
                private fb: UntypedFormBuilder,
                public insuranceProductManagerService : InsuranceProductManagerService,
                locationStrategy: LocationStrategy,
                private location: Location,
                private activatedRoute: ActivatedRoute,
                private runtimeConfigurationService: RuntimeConfigurationService) {
        super(translate, translateParser)

        title.setTitle(`Product Limit - Console - ${this.runtimeConfigurationService.title}`)

        locationStrategy.onPopState(() => {
            this.backPressed = true;
            return false;
        });

        this.defaultSteps = [];
        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_LIMIT").subscribe((response) => {
            this.defaultSteps.push({id: 0, name: response, icon: "fa-list", borderColor: "#1592E6"});
        });
        this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_LIMIT_VALUE").subscribe((response) => {
            this.defaultSteps.push({id: 1, name: response, icon: "fa fa-inr", borderColor: "#3171c3"});
        });
        this.steps = [...this.defaultSteps];

        if(insuranceProductManagerService.limit && insuranceProductManagerService.limit.type){
            this.setLimitSteps(insuranceProductManagerService.limit.customisable);
        }
    }

    onCustomisable(event: MatSlideToggleChange): void {
        this.setLimitSteps(event.checked);
    }

    setLimitSteps(addCustomisation: boolean): void {
        if (addCustomisation) {
            this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_CUSTOMER_CUSTOMISATION").subscribe((response) => {
                this.steps.push({ id: 2, name: response, icon: "fa fa-sort-amount-desc", borderColor: "#3f5baa" });
            });
            this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_CUSTOMER_CUSTOMISATION_CATEGORY").subscribe((response) => {
                this.steps.push({ id: 3, name: response, icon: "fa fa-balance-scale", borderColor: "#3f5baa" });
            });
            this.translate.get("ADMIN.PRODUCTS.TITLE_PRODUCTS_CUSTOMER_CUSTOMISATION_ADJUSTMENTS").subscribe((response) => {
                this.steps.push({ id: 3, name: response, icon: "fa fa-users", borderColor: "#3f5baa" });
            });
        } else {
            this.steps = [...this.defaultSteps];
        }
    }

    onCurrentStateChange(state:ProductLimitEnum){
        this.currentState = state;
        this.breadcrumbStep++;
    }

    ngOnInit() {

        this.activatedRoute.params.subscribe((data) => {
            this.productNumber = data['number'];
        });

        if (this.insuranceProductManagerService.product instanceof ThirdPartyInsuranceProduct) {
            this.insuranceProductManagerService.insuranceCategory = InsuranceCategory.THIRDPARTY
        } else if (this.insuranceProductManagerService.product instanceof ComprehensiveInsuranceProduct) {
            this.insuranceProductManagerService.insuranceCategory = InsuranceCategory.COMPREHENSIVE
        } else if (this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
            this.insuranceProductManagerService.insuranceCategory = InsuranceCategory.YELLOWCARD
        }

        this.insuranceProductManagerService.initialiseInsuranceProduct().then(() =>{

            for (const limitType of this.insuranceProductManagerService.limitTypes) {
                if (!this.getDropdownExact(this.limitTypes, limitType.description)) {
                    this.limitTypes.push(new Dropdown(limitType, limitType.description));
                }
            }

            if(this.limitCustomizationCopy == undefined || this.limitCustomizationCopy == null){
                this.limitCustomizationCopy = ObjectUtils.createObjectCopy(this.insuranceProductManagerService.limit);
                this.limitAdjustmentCopy = ObjectUtils.createObjectCopy(this.insuranceProductManagerService.limit);
            }

            this.sortDisplayList();


            if(this.insuranceProductManagerService.limit){
                this.limit = this.insuranceProductManagerService.limit;
                this.limitCustomizationCopy = ObjectUtils.createObjectCopy(this.insuranceProductManagerService.limit);
                this.limitAdjustmentCopy = ObjectUtils.createObjectCopy(this.insuranceProductManagerService.limit);
                this.currentLimitTypeValue = this.insuranceProductManagerService.limit.type;
            }

            this.createForm();

        })
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onChangeLimitType(event){
        this.currentLimitTypeValue = event.value.name;
        if(this.currentLimitTypeValue === "Passenger Liability (Per Passenger)"){
            this.showPassangerSeatingValue = true;
        }else{
            this.showPassangerSeatingValue = false;
        }

        if (this.currentLimitTypeValue === "Medical Expenses" && this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
            this.showPerPassenger = true
        } else {
            this.showPerPassenger = false
        }
    }

    createForm(): void {
        if(this.currentState ===  this.states.CAPTURE_LIMIT_DETAILS){
            let limitsUpdated: Limit[] = []
            if(this.limit){
                if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                    limitsUpdated = this.insuranceProductManagerService.product.limits
                } else {
                    limitsUpdated = this.insuranceProductManagerService.product.limit
                }
                this.limitType = new UntypedFormControl(this.getDropdown(this.limitTypes, this.limit.type),[
                    Validators.required
                ]);

                this.description = new UntypedFormControl(this.limit.description,[
                    Validators.maxLength(250),
                    duplicateLimitValidator(this.limit, this.limitType, limitsUpdated)
                ]);
            }else{
                this.limitType = new UntypedFormControl(null,[
                    Validators.required
                ]);

                this.description = new UntypedFormControl(null,[
                    Validators.required,
                    duplicateLimitValidator(this.limit, this.limitType, limitsUpdated)
                ]);
            }

            this.subscriptions.add(this.limitType.valueChanges.subscribe(() => {
                this.description.updateValueAndValidity();
            })
            );

            this.formGroupLimit = new UntypedFormGroup({
                'limitType':this.limitType,
                'description': this.description
            });
        }else if(this.currentState ===  this.states.CAPTURE_LIMIT_VALUE){
            if(this.limit.valueType != null && this.limit.valueType != undefined){
                if(this.limit.valueType === LimitValueType.STANDARD_AMOUNT){
                    this.standartAmountOrPecentage = new UntypedFormControl("Standard Amount",[
                        Validators.required
                    ]);
                    this.identifier = new UntypedFormControl(this.limit.value/100,[
                        Validators.required
                    ]);
                }else{
                    this.standartAmountOrPecentage = new UntypedFormControl("Standard Percentage",[
                        Validators.required,
                    ]);
                    this.identifier = new UntypedFormControl(this.limit.value,[
                        Validators.required,
                        Validators.max(100)
                    ]);
                    this.showPercentage = true;
                }

                this.customisable = new UntypedFormControl(this.limit.customisable,[
                    Validators.required
                ]);
                if(this.currentLimitTypeValue === "Passenger Liability (Per Passenger)"){
                    this.showPassangerSeatingValue = true;
                } else {
                    this.showPassangerSeatingValue = false;
                }

                if(this.showPassangerSeatingValue){
                    this.pricePerSeat = new UntypedFormControl(this.limit.pricePerSeat/100,[
                        Validators.required
                    ]);
                } else {
                    this.limit.pricePerSeat = null;
                    this.pricePerSeat = new UntypedFormControl(null,[
                        Validators.required
                    ]);
                }

            }else{

                if(this.insuranceProductManagerService.product instanceof ComprehensiveInsuranceProduct ){
                    this.standartAmountOrPecentage = new UntypedFormControl("Standard Percentage",[
                        Validators.required
                    ]);
                    this.identifier = new UntypedFormControl(null,[
                        Validators.required,
                        Validators.max(100)
                    ]);
                    this.showPercentage = true;
                }else {
                    this.standartAmountOrPecentage = new UntypedFormControl("Standard Amount",[
                        Validators.required
                    ]);
                    this.identifier = new UntypedFormControl(null,[
                        Validators.required
                    ]);
                }

                this.customisable = new UntypedFormControl(false);

                if(this.showPassangerSeatingValue){
                    this.pricePerSeat = new UntypedFormControl(null,[
                        Validators.required
                    ]);
                }
            }

            if(this.showPassangerSeatingValue){
                this.formGroupLimit = new UntypedFormGroup({
                    'standartAmountOrPecentage': this.standartAmountOrPecentage,
                    'identifier': this.identifier,
                    'customisable': this.customisable,
                    'pricePerSeat': this.pricePerSeat
                });
            }else{
                this.formGroupLimit = new UntypedFormGroup({
                    'standartAmountOrPecentage': this.standartAmountOrPecentage,
                    'identifier': this.identifier,
                    'customisable': this.customisable
                });
            }

        }else if(this.currentState ===  this.states.CAPTURE_CUSTOMER_CUSTOMASATION_VALUES){
            if(this.limit.minimumValue != null && this.limit.minimumValue != undefined && (this.limit.valueType == this.limitCustomizationCopy.valueType)){
                if(this.showPercentage){
                    this.minimumValue = new UntypedFormControl(this.limit.minimumValue,[
                        Validators.required,
                        Validators.max(100)
                    ]);

                    this.maximumValue = new UntypedFormControl(this.limit.maximumValue,[
                        Validators.required,
                        Validators.max(100)
                    ]);

                    this.userIncrementValue = new UntypedFormControl(this.limit.userIncrementValue,[
                        Validators.required,
                        Validators.max(100)
                    ]);
                }else{
                    this.minimumValue = new UntypedFormControl(this.limit.minimumValue/100,[
                        Validators.required
                    ]);

                    this.maximumValue = new UntypedFormControl(this.limit.maximumValue/100,[
                        Validators.required
                    ]);

                    this.userIncrementValue = new UntypedFormControl(this.limit.userIncrementValue/100,[
                        Validators.required
                    ]);
                }

            }else {
                if(this.showPercentage){
                    this.minimumValue = new UntypedFormControl(null,[
                        Validators.required,
                        Validators.max(100)
                    ]);

                    this.maximumValue = new UntypedFormControl(null,[
                        Validators.required,
                        Validators.max(100)
                    ]);

                    this.userIncrementValue = new UntypedFormControl(null,[
                        Validators.required,
                        Validators.max(100)
                    ]);
                }else {
                    this.minimumValue = new UntypedFormControl(null,[
                        Validators.required
                    ]);

                    this.maximumValue = new UntypedFormControl(null,[
                        Validators.required
                    ]);

                    this.userIncrementValue = new UntypedFormControl(null,[
                        Validators.required
                    ]);
                }
            }

            this.formGroupLimit = new UntypedFormGroup({
                'minimumValue': this.minimumValue,
                'maximumValue': this.maximumValue,
                'userIncrementValue': this.userIncrementValue
            });
        }else if(this.currentState ===  this.states.CAPTURE_PREMIUM_CATEGORY){
            if(this.limit.premiumAdjustmentCategory != null && this.limit.premiumAdjustmentCategory != undefined){
                this.proRata = new UntypedFormControl(this.limit.proRata,[
                    Validators.required
                ]);

                if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.FIXED){
                    this.premiumAdjustmentCategory = new UntypedFormControl("Fixed",[
                        Validators.required
                    ]);
                }else {
                    this.premiumAdjustmentCategory = new UntypedFormControl("Range",[
                        Validators.required
                    ]);
                }


            }else {
                this.proRata = new UntypedFormControl(false,[
                    Validators.required
                ]);

                this.premiumAdjustmentCategory = new UntypedFormControl("Fixed",[
                    Validators.required
                ]);
            }

            this.formGroupLimit = new UntypedFormGroup({
                'proRata': this.proRata,
                'premiumAdjustmentCategory': this.premiumAdjustmentCategory
            });
        }else if (this.currentState ===  this.states.CAPTURE_PREMIUM_ADJUSTMENTS){

            this.formGroupLimit = new UntypedFormGroup({
                'limitInterval': this.fb.array([])
            });

            if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.FIXED){
                this.limit.limitIntervals = [];
            }
            if(this.limit.limitIntervals.length > 0) {

                this.limit.limitIntervals.sort((a, b) => {
                    if (a.rangeFrom > b.rangeFrom) {
                        return 1;
                    }
                    if (a.rangeFrom < b.rangeFrom) {
                        return -1;
                    }
                    return 0;
                });
                for (const limitInterval of this.limit.limitIntervals) {
                        if(this.showPercentage){
                            const formGroupExcess = this.fb.group({
                                'premiumIntervalAdjustmentValue': [limitInterval.premiumIntervalAdjustmentValue/100, [Validators.required]],
                                'rangeFrom': [{value:limitInterval.rangeFrom, disabled: true}, [Validators.required,Validators.max(100)]],
                                'rangeTo': [limitInterval.rangeTo, [Validators.required,Validators.max(100)]],
                            });
                            (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                        }else{
                            const formGroupExcess = this.fb.group({
                                'premiumIntervalAdjustmentValue': [limitInterval.premiumIntervalAdjustmentValue/100, [Validators.required]],
                                'rangeFrom': [{value:limitInterval.rangeFrom/100, disabled: true}, [Validators.required]],
                                'rangeTo': [limitInterval.rangeTo/100, [Validators.required]],
                            });
                            (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                        }

                }
            }else{
                this.onAddLimitInterval(null);
            }

        }

    }

    onRemoveLimitInterval(event, index: number): boolean {
        this.toastRemoveLimit(index);
        event.stopPropagation();
        return false;
    }

    onAddLimitInterval(limitInterval): void {

        this.sameRangeValue = false;
        this.rangeToNotEqualMaxValue = false;
        this.rangeFromNotEqualMinValue = false;
        this.invalidLastToValue = false;
        if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.FIXED){
            if(this.limit.increment != null && this.limit.increment != undefined  && (this.limit.valueType == this.limitAdjustmentCopy.valueType)){
                if(this.showPercentage){
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [this.limit.premiumFixedAdjustmentValue / 100, [Validators.required]],
                        'rangeFrom': [this.limit.increment, [Validators.required,Validators.max(100)]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }else{
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [this.limit.premiumFixedAdjustmentValue / 100, [Validators.required]],
                        'rangeFrom': [this.limit.increment/100, [Validators.required]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }
            }else{
                if(this.showPercentage){
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [null, [Validators.required]],
                        'rangeFrom': [null, [Validators.required,Validators.max(100)]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }else{
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [null, [Validators.required]],
                        'rangeFrom': [null, [Validators.required]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }
            }

        }else{
            if(this.showPercentage){
                if(limitInterval){
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [null, [Validators.required]],
                        'rangeFrom': [{value: (parseFloat(limitInterval.rangeTo.value) + 0.01).toFixed(2), disabled: true}, [Validators.required,Validators.max(100)]],
                        'rangeTo': [null, [Validators.required,Validators.max(100)]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }else{
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [null, [Validators.required]],
                        'rangeFrom': [{ value: 0, disabled: true}, [Validators.required,Validators.max(100)]],
                        'rangeTo': [null, [Validators.required,Validators.max(100)]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }
            }else{
                if(limitInterval){
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [, [Validators.required]],
                        'rangeFrom': [{value: (parseFloat(limitInterval.rangeTo.value) + 0.01).toFixed(2), disabled: true}, [Validators.required]],
                        'rangeTo': [null, [Validators.required]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);

                }else{
                    const formGroupExcess = this.fb.group({
                        'premiumIntervalAdjustmentValue': [null, [Validators.required]],
                        'rangeFrom': [{ value: 0, disabled: true}, [Validators.required]],
                        'rangeTo': [null, [Validators.required]],
                    });
                    (this.formGroupLimit.get('limitInterval') as UntypedFormArray).push(formGroupExcess);
                }
            }
        }
        this.updateLimitToValue();
    }

    updateLimitToValue() {
        if (this.getLimitsControls.length > 1) {
            this.getLimitsControls.forEach((element, index) => {
                this.onCheckRange(element.controls, index)
            });
        }
    }

    radioChange(event): void {
        if(event.value === 'Standard Percentage'){
            this.showPercentage = true;
            this.formGroupLimit.controls["identifier"].setValidators([Validators.required, Validators.max(100)]);
            this.formGroupLimit.get('identifier').updateValueAndValidity();
        }else{
            this.showPercentage = false;
            this.formGroupLimit.controls["identifier"].setValidators([Validators.required]);
            this.formGroupLimit.get('identifier').updateValueAndValidity();
        }
    }

    onSubmitLimit(){

        if(this.formGroupLimit.valid) {
            switch (this.currentState) {
            case ProductLimitEnum.CAPTURE_LIMIT_DETAILS:
                this.onCurrentStateChange(this.states.CAPTURE_LIMIT_VALUE);
                this.limit.description =  this.formGroupLimit.value.description;
                this.limit.type = this.formGroupLimit.value.limitType.name;
                this.limit.currency = this.insuranceProductManagerService.currency;
                this.createForm();
                return;
            case ProductLimitEnum.CAPTURE_LIMIT_VALUE:
                if(this.formGroupLimit.value.standartAmountOrPecentage === "Standard Amount"){
                    this.limit.valueType = LimitValueType.STANDARD_AMOUNT;
                    this.limit.value = this.formGroupLimit.value.identifier * 100;
                    this.limit.currency = this.insuranceProductManagerService.currency;
                }else{
                    this.limit.valueType = LimitValueType.STANDARD_PERCENTAGE;
                    this.limit.value = this.formGroupLimit.value.identifier;
                    this.limit.currency = null;
                }

                if(this.showPassangerSeatingValue){
                    this.limit.pricePerSeat = this.formGroupLimit.value.pricePerSeat *100;
                }

                this.limit.customisable =  this.formGroupLimit.value.customisable;
                if(this.limit.customisable){
                    this.onCurrentStateChange(this.states.CAPTURE_CUSTOMER_CUSTOMASATION_VALUES);
                    this.createForm();
                }else{
                    if (this.insuranceProductManagerService.editingIndex >= 0) {
                        if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                            this.insuranceProductManagerService.product.limits[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.limit
                        } else{
                            this.insuranceProductManagerService.product.limit[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.limit
                        }
                    } else {
                        if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                            this.insuranceProductManagerService.product.limits.push(this.limit);
                        } else {
                            this.insuranceProductManagerService.product.limit.push(this.limit);
                        }
                    }

                    this.router.navigate([`retail/insurance/product-overview/${this.productNumber}`]);
                }
                return;
            case ProductLimitEnum.CAPTURE_CUSTOMER_CUSTOMASATION_VALUES:
                let limitValue;
                if(this.showPercentage){
                    limitValue =  this.limit.value;
                }else{
                    limitValue = this.limit.value/100;
                }
                if(parseFloat(this.formGroupLimit.value.minimumValue) > limitValue){
                    this.invalidMinValue = true;
                }
                if(parseFloat(this.formGroupLimit.value.maximumValue) < limitValue){
                    this.invalidMaxLimitValue = true;
                }
                if(parseFloat(this.formGroupLimit.value.maximumValue) < parseFloat(this.formGroupLimit.value.minimumValue)){
                    this.invalidMaxValue = true;
                }
                if(parseFloat(this.formGroupLimit.value.userIncrementValue) > parseFloat(this.formGroupLimit.value.maximumValue)){
                    this.invalidUserIncrementValue = true;
                }
                if(parseFloat(this.formGroupLimit.value.minimumValue) > parseFloat(this.formGroupLimit.value.maximumValue)){
                    this.invalidMinMaxValue = true;
                }
                if(!this.invalidMinValue && !this.invalidMaxLimitValue && !this.invalidMaxLimitValue && !this.invalidUserIncrementValue && !this.invalidMinMaxValue){
                    this.onCurrentStateChange(this.states.CAPTURE_PREMIUM_CATEGORY);
                    if(this.showPercentage){
                        this.limit.minimumValue = this.formGroupLimit.value.minimumValue;
                        this.limit.maximumValue = this.formGroupLimit.value.maximumValue;
                        this.limit.userIncrementValue = this.formGroupLimit.value.userIncrementValue;
                    }else{
                        this.limit.minimumValue = this.formGroupLimit.value.minimumValue * 100;
                        this.limit.maximumValue = this.formGroupLimit.value.maximumValue * 100;
                        this.limit.userIncrementValue = this.formGroupLimit.value.userIncrementValue * 100;
                    }
                    this.createForm();
                }

                return;
            case ProductLimitEnum.CAPTURE_PREMIUM_CATEGORY:
                this.onCurrentStateChange(this.states.CAPTURE_PREMIUM_ADJUSTMENTS);
                this.limit.proRata = this.formGroupLimit.value.proRata;

                if(this.formGroupLimit.value.premiumAdjustmentCategory === "Fixed"){
                    this.limit.premiumAdjustmentCategory = PremiumAdjustmentCategory.FIXED;
                }else{
                    this.limit.premiumAdjustmentCategory = PremiumAdjustmentCategory.RANGE;
                }

                this.createForm();
                return;
            case ProductLimitEnum.CAPTURE_PREMIUM_ADJUSTMENTS:
                this.limit.limitIntervals = [];
                if(this.invalidRange || this.onCheckLastToValue()){
                    return
                }else{
                    for(var i = 0; i < (this.formGroupLimit.get('limitInterval') as UntypedFormArray).length; i++){

                        var currentInterval = (this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(i);
                        var newLimitInterval = new LimitInterval();
                        if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.FIXED){
                            if(this.showPercentage){
                                this.limit.increment = currentInterval.get('rangeFrom').value;
                                this.limit.premiumFixedAdjustmentValue = currentInterval.get('premiumIntervalAdjustmentValue').value * 100;
                            }else{
                                this.limit.increment = currentInterval.get('rangeFrom').value * 100;
                                this.limit.premiumFixedAdjustmentValue = currentInterval.get('premiumIntervalAdjustmentValue').value * 100;
                            }

                        }else{
                            if(parseFloat(currentInterval.get('rangeTo').value) <= parseFloat(currentInterval.get('rangeFrom').value)){
                                this.sameRangeValue = true;
                                return
                            }else{
                                this.sameRangeValue = false;
                                if(this.showPercentage){
                                    newLimitInterval.rangeFrom = currentInterval.get('rangeFrom').value;
                                    newLimitInterval.rangeTo = currentInterval.get('rangeTo').value;
                                    newLimitInterval.premiumIntervalAdjustmentValue = currentInterval.get('premiumIntervalAdjustmentValue').value * 100;
                                }else{
                                    newLimitInterval.rangeFrom = currentInterval.get('rangeFrom').value * 100;
                                    newLimitInterval.rangeTo = currentInterval.get('rangeTo').value * 100;
                                    newLimitInterval.premiumIntervalAdjustmentValue = currentInterval.get('premiumIntervalAdjustmentValue').value * 100;
                                }
                            }
                            this.limit.limitIntervals.push(newLimitInterval);
                        }

                    }
                }

                if (this.insuranceProductManagerService.editingIndex >= 0) {
                    if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                        this.insuranceProductManagerService.product.limits[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.limit;
                    } else {
                        this.insuranceProductManagerService.product.limit[this.insuranceProductManagerService.editingIndex] = this.insuranceProductManagerService.limit;
                    }
                } else {
                    if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                        this.insuranceProductManagerService.product.limits.push(this.limit);

                    } else {
                        this.insuranceProductManagerService.product.limit.push(this.limit);
                    }
                }
                this.router.navigate([`retail/insurance/product-overview/${this.productNumber}`]);

                return;
            default: {
                break;
            }
        }
        }
    }

    onCheckLastToValue(): boolean {
        if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.RANGE){
            let arrayControl = (this.formGroupLimit.get('limitInterval') as UntypedFormArray).controls
            [(this.formGroupLimit.get('limitInterval') as UntypedFormArray).length - 1];

            let minValue;
            let maxValue;
            if(this.showPercentage){
                minValue = Math.abs((this.limit.value) - (this.limit.minimumValue));
                maxValue = Math.abs((this.limit.value) - (this.limit.maximumValue));
            }else{
                minValue = Math.abs((this.limit.value/ 100) - (this.limit.minimumValue/100));
                maxValue = Math.abs((this.limit.value/ 100) - (this.limit.maximumValue/100));
            }

            if(minValue > maxValue){
                this.lastToValue = minValue
            }else if(maxValue > minValue){
                this.lastToValue = maxValue
            }else if(maxValue == minValue || minValue == maxValue){
                this.lastToValue = maxValue
            }

            this.lastToValue.toFixed(2);
            if(parseFloat(arrayControl.value.rangeTo) == this.lastToValue){
                return  false
            }else if(this.lastToValue !== parseFloat(arrayControl.value.rangeTo)){
                this.invalidLastToValue = true;
                return true
            }
            else{
                return false
            }
        }else{
            this.invalidLastToValue = true;
            false
        }

    }

    onRemoveError(event){
        this.invalidMaxValue = false;
        this.invalidUserIncrementValue = false;
        this.invalidMaxLimitValue = false
        this.invalidMinValue = false
        this.invalidMinMaxValue = false
    }

    onRemoveMinValueError(event){
        this.invalidMaxValue = false;
        this.invalidUserIncrementValue = false;
        this.invalidMaxLimitValue = false
        this.invalidMinValue = false
        this.invalidMinMaxValue = false;
    }

    onRemoveUserIncrementError(event){
        this.invalidMaxValue = false;
        this.invalidUserIncrementValue = false;
        this.invalidMaxLimitValue = false
        this.invalidMinValue = false

    }

    removeRangeErrors(){
        this.rangeFromNotEqualMinValue = false;
        this.invalidLastToValue = false;
        this.invalidRange = false;
        this.sameRangeValue = false
    }

    onCheckRange(limitInterval1, index){
        this.removeRangeErrors();

        var nextInterval = (this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(index + 1);
        if(nextInterval){
            var newValue = (parseFloat(limitInterval1.rangeTo.value) + 0.01).toFixed(2);
            nextInterval.get('rangeFrom').setValue(newValue);
        }

        if(this.formGroupLimit.value.limitInterval.length > 1){
                this.checkRangeIfisUnique(limitInterval1,index)
        }else{

            if(this.limit.premiumAdjustmentCategory === PremiumAdjustmentCategory.RANGE){
                let minimumValue;
                if(this.showPercentage){
                    minimumValue =  this.limit.minimumValue;
                }else{
                    minimumValue = this.limit.minimumValue/100;
                }
                if(parseFloat(limitInterval1.rangeFrom.value) !== minimumValue){
                    this.rangeFromNotEqualMinValue = true;
                }
            }
        }
    }

    checkRangeIfisUnique(limitInterval1, index): void{
        this.checkValues = [];
        for(var i = 0; i < (this.formGroupLimit.get('limitInterval') as UntypedFormArray).length; i++){
            if(i != index ){
                var currentInterval = (this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(i);

                if (parseFloat(limitInterval1.rangeFrom.value) == parseFloat(currentInterval.get('rangeFrom').value)){
                    this.checkValues.push(true);
                }else if(parseFloat(limitInterval1.rangeTo.value) == parseFloat(currentInterval.get('rangeTo').value)){
                    this.checkValues.push(true);
                }else{
                    this.checkValues.push(false);
                }
            }
        }

        if(this.checkValues.includes(true)){
            this.invalidRange = true;
        }else{
           this.invalidRange = false;
        }
    }

    toastRemoveLimit(index: number) {

        this.translate.get('TOAST.REMOVE_EXCESS').subscribe((res: any) => {
            this.activeToast = this.toastr.success(this.translateParser.interpolate(res.MESSAGE, {value: "limit adjustment"}), res.TITLE, {
                toastComponent: ToastComponent,
                disableTimeOut: true,
                closeButton: true
            });
            this.activeToast.portal.instance.primaryButton = res.PRIMARY_BUTTON;
            this.activeToast.portal.instance.secondaryButton = res.SECONDARY_BUTTON;
            this.activeToast.onAction.subscribe((type) => {

                switch (type) {
                    case ToastComponent.PRIMARY:

                        (this.formGroupLimit.get('limitInterval') as UntypedFormArray).removeAt(index);
                        if(index > 0){
                            if((this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(index)){
                                this.updateLimitToValue();
                            }
                        }else{
                            if((this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(0)){
                                (this.formGroupLimit.get('limitInterval') as UntypedFormArray).at(0).get('rangeFrom').setValue(0);
                            }
                        }
                        this.limit.limitIntervals.splice(index, 1);
                        this.removeRangeErrors();
                        this.activeToast.portal.destroy();
                        break;
                    case ToastComponent.SECONDARY:
                        this.activeToast.portal.destroy();
                        break;
                }
            });
        });
    }

    onSubmit(event): void {
        if (this.currentState ===  this.states.CAPTURE_PREMIUM_ADJUSTMENTS){
            document.getElementById('form-submit-limitInterval').click();
        }else{
            document.getElementById('form-submit-limit').click();
        }
    }

    onBack(_): void {

        if(this.breadcrumbStep > 0){
            this.breadcrumbStep--;
        }

        switch (this.currentState) {
            case ProductLimitEnum.CAPTURE_LIMIT_DETAILS: {
                if(this.insuranceProductManagerService.product instanceof YCInsuranceProduct) {
                    this.insuranceProductManagerService.product.limits[this.insuranceProductManagerService.editingIndex] = this.limitAdjustmentCopy;
                } else {
                    this.insuranceProductManagerService.product.limit[this.insuranceProductManagerService.editingIndex] = this.limitAdjustmentCopy;
                }
                this.location.back();
                break;
            }
            case ProductLimitEnum.CAPTURE_LIMIT_VALUE: {
                this.currentState =  ProductLimitEnum.CAPTURE_LIMIT_DETAILS;
                this.showPercentage = false;
                this.createForm();
                break;
            }
            case ProductLimitEnum.CAPTURE_CUSTOMER_CUSTOMASATION_VALUES: {
                this.currentState = ProductLimitEnum.CAPTURE_LIMIT_VALUE;
                this.createForm();
                break;
            }
            case ProductLimitEnum.CAPTURE_PREMIUM_CATEGORY: {
                this.currentState = ProductLimitEnum.CAPTURE_CUSTOMER_CUSTOMASATION_VALUES;
                this.limitCustomizationCopy.valueType = this.limit.valueType;
                this.createForm();
                break;
            }
            case ProductLimitEnum.CAPTURE_PREMIUM_ADJUSTMENTS: {
                this.currentState = ProductLimitEnum.CAPTURE_PREMIUM_CATEGORY;
                this.invalidRange = false;
                this.createForm();
                break;
            }
        }
    }

    onDeactivate(): boolean {
        return true
    }

    sortDisplayList() {
        this.limitTypes.sort((val1, val2) => val1.name.localeCompare(val2.name));
    }

    get getLimitsControls () {
        return this.formGroupLimit.get('limitInterval')['controls'];
    }
}

export function duplicateLimitValidator(currentLimit: Limit, limitType: AbstractControl, limits: Limit[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const notChanged = currentLimit && currentLimit.description
            && currentLimit.type.toLowerCase() === limitType.value.name.toLowerCase() ?
            currentLimit.description.trim().toLowerCase() === control.value.trim().toLowerCase() :
            false;
        if (limitType.invalid || notChanged) return null;
        const hasDuplicate = limits.filter((item) => {
            if (item.description && item.type.toLowerCase() === limitType.value.name.toLowerCase() &&
                item.description.trim().toLowerCase() == control.value.trim().toLowerCase()) {
                return item;
            }
        }).length > 0;
        return hasDuplicate ? { notUnique: { value: control.value } } : null;
    }
}
