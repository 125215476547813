import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {CountryRegistrationConfiguration} from '../../../../../models/country-registration-configuration.model';

export class ProvideCountryRegistrationConfigurationResponse extends Response {
    constructor() {
        super();
    }

    countryRegistrationConfigurations: CountryRegistrationConfiguration[] = [];
}
