import { LegalEntityIdentifier } from "../../../../entity/identification/models/legal-entity-identifier.model";
import { UnderwriterIdentifier } from "../../identification/models/underwriter-identifier.model";


export class ProposalGroupEntry {

    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.comprehensiveinsurance.ProposalGroupEntry";
    }

    public checkoutDate: Date;
    public underwriterIdentifier: UnderwriterIdentifier;
    public assessor: LegalEntityIdentifier;

    public proposalIds: string[] = [];
}