import {Request} from '../../../../../../../../urdad/services/models/request.model';
import { Card } from '../../../../../../models/card.model';
import {EmailAddress} from "../../../../../../../../entity/models/email-address.model";
import {RetailProfileIdentifier} from "../../../../../../../profile/identification/retail-profile-identifier.model";

export class CreatePaymentProfileRequest extends Request{
    constructor(){
        super();
    }

    public emailAddress: EmailAddress;
    public card: Card;
    public retailProfileIdentifier: RetailProfileIdentifier;
}
