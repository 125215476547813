
export class YCPriceSchedule {
    constructor() {
        this['@class'] = 'za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCPriceSchedule';
    }

    ycPriceScheduleId: string;
    currency: string;
    insuranceVehicleCategoryId: string;
    insuranceVehicleCategorySubDescription: string;
    country: string;
    price: number;
}