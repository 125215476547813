import {Request} from '../../../../../../../urdad/services/models/request.model';
import {ContactNumber} from '../../../../../../../entity/models/contact-number.model';
import {OneTimePinContext} from '../../../../../models/one-time-pin-context.model';

export class IssueOneTimePinRequest extends Request {
    constructor(){
        super();
    }

    contactNumber: ContactNumber;
    oneTimePinContext: OneTimePinContext;
}
