import { PaymentReference } from "./payment-reference.model";

export class OrderReferenceNumber extends PaymentReference {
    
    constructor(reference) {
        super();
        this["@class"] = "za.co.magnabc.tpi.financial.OrderReferenceNumber";
        this.orderReferenceNumber = reference;
    }

    public orderReferenceNumber: string;
}
