import { Response } from "../../../../../../../urdad/services/models/response.model";
import {Subscription} from "../../../../../models/subscription.model";

export class CreateSubscriptionResponse extends Response {
  constructor() {
    super();
  }

  subscriptions: Subscription[] = [];
}
