import {Component, Inject, OnInit, ViewChild} from '@angular/core'
import {FormComponent} from '../../../shared/form/form.component'
import {DatatableComponent} from '@swimlane/ngx-datatable'
import {TranslateParser, TranslateService} from '@ngx-translate/core'
import {Router} from '@angular/router'
import {
    RetailProfileFinalisationManagerService
} from '../../../../http.services/retail/profile/retail-profile-finalisation-manager/retail-profile-finalisation-manager.service'
import {
    RenderedConceptualDocumentProviderService
} from '../../../../http.services/rendering/rendered-conceptual-document-provider/rendered-conceptual-document-provider.service'
import {
    ProposalInformationProviderService
} from '../../../../http.services/retail/insurance/comprehensiveinsurance/proposalinformationprovider/proposal-information-provider.service'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {PDFDisplayModel, PDFPrintModel, PrintService} from '../../../../app.services/common/printing/print.service'
import * as moment from 'moment/moment'
import {environment} from '../../../../../environments/environment'
import {
    FindProposalRecordSummaryRequest,
    FindProposalRecordSummaryResponse,
    PdfDocument,
    ProposalCriteria,
    ProposalRecordStatus,
    ProposalRecordSummary,
    ProposalRecordSummaryDateCriteria,
    ProposalRecordSummaryLegalEntityCriteria,
    ProposalRecordSummaryStatusCriteria,
    ProvideProposalRecordSummaryRequest,
    ProvideProposalRecordSummaryResponse,
    ProvideRenderedConceptualDocumentRequest,
    ProvideRenderedConceptualDocumentResponse,
    ProvideRetailProfileProposalSummaryRequest,
    ProvideRetailProfileProposalSummaryResponse,
    RenderedDocumentType,
    RenderedDocumentTypeIdCriteria,
    UnMarshallerService,
} from '@magnabc/tpi'

declare const jQuery: any;

@Component({
  selector: 'app-proposal-history',
  templateUrl: './proposal-history.component.html',
  styleUrls: ['./proposal-history.component.scss']
})
export class ProposalHistoryComponent  extends FormComponent implements OnInit {

    FILTER_ALL = 0;
    FILTER_STATUS = 1;
    FILTER_DATE = 2;

    rows: ProposalRecordSummary[] = [];

    totalElements = 0;
    pageNumber = 0;
    pageSize = 8;
    selectedStatus = [];
    proposalPages = 1;
    filter = this.FILTER_ALL;
    temp: ProposalRecordSummary[] = [];
    proposalRecordStatus = ProposalRecordStatus;
    renderedDocumentType = RenderedDocumentType;
    from;
    to;

    previewDocument = false;
    documentToPreview: any;
    active: ProposalRecordSummary;

    pdfScale = 4;

    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

    constructor(public translate: TranslateService,
                private translateParser: TranslateParser,
                private router: Router,
                private retailProfileFinalisationManagerService: RetailProfileFinalisationManagerService,
                private unMarshallerService: UnMarshallerService,
                private renderedConceptualDocumentProviderService: RenderedConceptualDocumentProviderService,
                private proposalInformationProviderService: ProposalInformationProviderService,
                private dialog: MatDialog,
                private printService: PrintService) {
        super(translate, translateParser);

        this.temp = this.rows;
        this.from = moment();
        this.to = moment();

    }

    ngOnInit(): void {
        this.onToggleSearch();
        this.startLoad();
        this.retailProfileFinalisationManagerService.refreshRetailProfile().then(() => {
            this.provideProposalRecordSummaries();
        });
    }

    provideProposalRecordSummaries(): void {

        let criteria;

        this.rows = [];

        const provideProposalRecordSummaryRequest = new ProvideProposalRecordSummaryRequest();

        if (this.filter === this.FILTER_DATE) {
            this.from.startOf('day');
            this.to.endOf('day');
            criteria = new ProposalRecordSummaryDateCriteria();
            criteria.dateFrom = this.from.startOf('day').format(environment.formatDateServer) as Date;
            criteria.dateTo = this.to.endOf('day').format(environment.formatDateServer) as Date;
            criteria.legalEntityIdentifier = this.retailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier;;
        } else if (this.filter === this.FILTER_STATUS && this.selectedStatus.length > 0) {
            criteria = new ProposalRecordSummaryStatusCriteria();
            criteria.status = this.selectedStatus;
            criteria.legalEntityIdentifier = this.retailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier;;
        } else {
            criteria = new ProposalRecordSummaryLegalEntityCriteria();
            criteria.legalEntityIdentifier = this.retailProfileFinalisationManagerService.retailProfile.legalEntityDescriptionIdentifier;
        }

        provideProposalRecordSummaryRequest.batchNumber = this.pageNumber + 1;
        provideProposalRecordSummaryRequest.batchSize = this.pageSize;
        provideProposalRecordSummaryRequest.criteria = criteria;

        this.proposalInformationProviderService.provideProposalRecordSummary(provideProposalRecordSummaryRequest).then((data) => {
            this.stopLoad();
            const response: ProvideProposalRecordSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideProposalRecordSummaryResponse);
            for (const order of response.proposalRecordSummaries) {
                this.rows.push(order);
            }

            this.rows.sort((first, second) => {
                if (first.capturedDate > second.capturedDate) {
                    return -1;
                } else if (first.capturedDate < second.capturedDate) {
                    return 1;
                }
                return 0;
            });
            this.temp = this.rows;
            this.rows = [...this.rows];

            this.totalElements = response.totalNumberOfResults;

        }).catch(err =>{
            console.error(err);
            this.stopLoad();
        });

    }

    setPage(event) {
        this.pageNumber = event.offset;
        this.provideProposalRecordSummaries();
    }

    onStatus(status): boolean {
        if (this.selectedStatus.indexOf(status) > -1) {
            this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        } else {
            this.selectedStatus.push(status);
        }
        this.provideProposalRecordSummaries();
        return false;
    }

    onSelectionChanged() {
        this.provideProposalRecordSummaries();
    }

    onFilter(filter: number): boolean {
        this.filter = this.FILTER_ALL;
        this.provideProposalRecordSummaries();
        this.filter = filter;
        return false;
    }

    isStatusSelected(status): boolean {
        return this.selectedStatus.indexOf(status) > -1;
    }

    updateFilter(event) {

        const val = event.target.value.toLowerCase();

        this.rows = this.temp.filter(function(d) {
            return d.proposalId.replace(/ /g, "").toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.table.offset = 0;

    }

    getFormatedDate(value): string {
        return value.year + "-" + value.monthValue + "-" + value.dayOfMonth;
    }

    onPreview(event,proposalRecordSummary: ProposalRecordSummary, renderedDocumentType: RenderedDocumentType): boolean {
        if (event) {
            event.stopPropagation();
        }

        this.active = proposalRecordSummary;

        this.startLoad();
        this.getRenderedDocument(proposalRecordSummary, renderedDocumentType, (pdfDocument) => {
            this.stopLoad();
            if(pdfDocument.type === renderedDocumentType){
                this.documentToPreview = pdfDocument.encodedPdf;
            }

            this.previewDocument = true;
            setTimeout(() => {
                this.displayPDF(this.documentToPreview, () => {});
            });
        });

        return false;

    }

    getRenderedDocument(proposalRecordSummary: ProposalRecordSummary, renderedDocumentType: RenderedDocumentType, callback): void {

        const renderedDocumentTypeIdCriteria = new RenderedDocumentTypeIdCriteria();
        renderedDocumentTypeIdCriteria.documentId = proposalRecordSummary.proposalId;

        if(renderedDocumentType === RenderedDocumentType.PROPOSAL){
            renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.PROPOSAL];
        }
        else{
            renderedDocumentTypeIdCriteria.types = [RenderedDocumentType.PROPOSAL_CONFIRMATION];
        }

        const provideRenderedConceptualDocumentRequest = new ProvideRenderedConceptualDocumentRequest();
        provideRenderedConceptualDocumentRequest.criteria = renderedDocumentTypeIdCriteria;

        this.renderedConceptualDocumentProviderService.provideRenderedConceptualDocument(provideRenderedConceptualDocumentRequest).subscribe((data) => {

            const provideRenderedConceptualDocumentResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRenderedConceptualDocumentResponse) as ProvideRenderedConceptualDocumentResponse;
            if (provideRenderedConceptualDocumentResponse.renderedDocuments.length > 0) {
                callback((provideRenderedConceptualDocumentResponse.renderedDocuments[0] as PdfDocument));
            } else {
                callback({});
            }

        });

    }

    onPrintPreview(): void {
        this.printDocument();
    }

    displayPDF(base64, complete) {
        const canvasId = '#canvas-' + this.active.proposalId;
        const pdfDisplayData: PDFDisplayModel = {
            canvasId,
            base64,
            complete
        };
        this.printService.displayPDF(pdfDisplayData).then((numPages) => {
            this.proposalPages = numPages;
        });
    }

    printDocument(): void {
        const targetId = 'canvas-' + this.active.proposalId;
        const pdfPrintData: PDFPrintModel = {
            numberOfPages: this.proposalPages,
            targetId,
            openBlank: true
        };
        this.printService.printDocument(pdfPrintData);
    }

    closePreview(): void {

        this.previewDocument = false;
        this.documentToPreview = null;

    }

    onToggleSearch(): void {
        const input = jQuery('#input-search');
        input.toggle();
        input.val('');
        input.focus();
        this.rows = this.temp;
    }

    openProposalCommentsDialog(proposalId): void {
        this.startLoad();
        let retailProfileProposalSummary: any;
        const criteria = new ProposalCriteria;
        const provideRetailProfileProposalSummaryRequest = new ProvideRetailProfileProposalSummaryRequest();
        criteria.proposalId = proposalId;
        provideRetailProfileProposalSummaryRequest.criteria = criteria;

        this.proposalInformationProviderService.provideRetailProfileProposalSummary(provideRetailProfileProposalSummaryRequest).then(data => {
            this.stopLoad();
            const response: ProvideRetailProfileProposalSummaryResponse = this.unMarshallerService.unMarshallFromJson(data.body, ProvideRetailProfileProposalSummaryResponse);

            if(response.proposalRecordStatus === ProposalRecordStatus.COMPLETED) {
                retailProfileProposalSummary = response;
            }

            const dialogRef = this.dialog.open(ProposalResultsDialogComponent, {
                width: 'auto',
                panelClass: 'padded-modal',
                position: {top: "5%"},
                data: {
                    retailProfileProposalSummary
                }
            });
        }). catch(err => {
            console.error(err);
            this.stopLoad();
        });
    }
}

@Component({
    selector: 'app-proposal-results-dialog',
    templateUrl: './proposal-results-dialog.component.html',
    styleUrls: ['./proposal-results-dialog.component.scss'],
})
export class ProposalResultsDialogComponent implements OnInit {
    retailProfileProposalSummaries;
    constructor(
        public dialogRef: MatDialogRef<ProposalResultsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    ngOnInit(): void {
        this.retailProfileProposalSummaries = this.data.retailProfileProposalSummary.retailProfileProposalSummary;
    }

    close(): void {
        this.dialogRef.close({success: false, created: false});
    }

}
