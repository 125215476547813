import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {
    MarshallerService,
    ProvideProposalInformationRequest,
    ProvideProposalRecordSummaryRequest,
    ProvideProposalSummaryRequest,
    ProvideRetailProfileProposalSummaryRequest,
    UnMarshallerService,
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class ProposalInformationProviderService {

    constructor(private httpClient: HttpClient, private marshallerService: MarshallerService, private unMarshallerService: UnMarshallerService,
                private runtimeConfigurationService: RuntimeConfigurationService) { }

    private options = {
        observe: 'response' as 'body',
        ResponseType: 'json'
    };

    provideProposalInformation(provideProposalInformationRequest: ProvideProposalInformationRequest): Promise<any> {

        const pathURL = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/comprehensive/proposalinformationprovider/provideproposalinformation';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideProposalInformationRequest);
        return this.httpClient.post(pathURL, jsonRequest, this.options).toPromise();

    }

    provideProposalSummary(provideProposalSummaryRequest: ProvideProposalSummaryRequest): Promise<any> {

        const pathURL = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/comprehensive/proposalinformationprovider/provideproposalsummary';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideProposalSummaryRequest);
        return this.httpClient.post(pathURL, jsonRequest, this.options).toPromise();

    }

    provideRetailProfileProposalSummary(provideRetailProfileProposalSummaryRequest: ProvideRetailProfileProposalSummaryRequest): Promise<any> {

        const pathURL = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/comprehensive/proposalinformationprovider/provideretailprofileproposalsummary';
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideRetailProfileProposalSummaryRequest);
        return this.httpClient.post(pathURL, jsonRequest, this.options).toPromise();

    }

    provideProposalRecordSummary(provideProposalRecordSummaryRequest: ProvideProposalRecordSummaryRequest): Promise<any> {
        const pathURL = `${this.runtimeConfigurationService.apiBaseUrl}/retail/insurance/comprehensive/proposalinformationprovider/provideproposalrecordsummary`;
        const jsonRequest = this.marshallerService.marshallObjectToJSON(provideProposalRecordSummaryRequest);
        return this.httpClient.post(pathURL, jsonRequest, this.options).toPromise();
    }
}
