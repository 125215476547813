import {Request} from '../../../../../../urdad/services/models/request.model';
import {Credentials} from '../../../../../credentials/models/credentials.model';

export class AuthenticateCredentialsRequest extends Request {

    constructor() {
        super();
    }

    credentials: Credentials;
}
