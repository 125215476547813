import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core'
import {
    InsuranceProductService
} from '../../../../../app.services/managers/insurance-product/insurance-product.service'

@Component({
    selector: 'app-product-vehicle-selection',
    templateUrl: './product-vehicle-selection.component.html',
    styleUrls: ['./product-vehicle-selection.component.scss']
})
export class ProductVehicleSelectionComponent implements OnInit, AfterViewInit {

    showAllVehicles = false

    constructor(public insuranceProductService: InsuranceProductService,
                private elementRef: ElementRef) {}

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.insuranceProductService.vehicleButtons = this.elementRef.nativeElement.querySelectorAll('.vehicle-button') // FIXME
    }

    updateFilter(event) {
        this.insuranceProductService.filterValue = event.target.value.toLowerCase()
        this.insuranceProductService.filterVehicles()
    }

    onToggleShowALlVehicles() {
        this.showAllVehicles = !this.showAllVehicles
        return false
    }

    allSelected(): boolean {
        return Object.keys(this.insuranceProductService.selectedVehicleMap).length === this.insuranceProductService.vehicles.length
    }

    getSelectedCount() {
        return Object.keys(this.insuranceProductService.selectedVehicleMap).length
    }

}
