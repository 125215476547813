import { ScannedDocument, ScannedDocumentIdentifier } from "../../../../media/media_api";
import { VehicleIdentifier } from "../../../../vehicle/vehicle_api";

export class YCMotorVehicleInsuranceDetails {
    constructor(){
        this['@class'] = "za.co.magnabc.tpi.retail.insurance.yellowcardinsurance.YCMotorVehicleInsuranceDetails";
    }

    vehicleIdentifier: VehicleIdentifier;
    scannedDocumentIdentifiers: ScannedDocumentIdentifier[] = [];
}