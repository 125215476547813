import {Response} from '../../../../../../../../urdad/services/models/response.model';
import {Payment} from "../../../../../../../../financial/models/payment.model";
import {PaymentAdvice} from "../../../../../../models/payment-advice.model";

export class ProcessCardPaymentResponse extends Response{
    constructor(){
        super();
    }

    public payment: Payment;
    public paymentAdvice: PaymentAdvice;
}
