import {Response} from "../../../../../../../urdad/services/models/response.model"
import {PolicyDate} from '../../../../../models/policy-date.model'

export class CaptureStartDateGroupingResponse extends Response{
    constructor(){
        super();
    }

    public policyDates : PolicyDate[] = [];

}
