import {DocumentTypeIdentifier} from './document-type-identifier.model';

export class DocumentTypeDescriptionIdentifier extends DocumentTypeIdentifier {
  constructor(){
    super();
    this['@class'] = "za.co.magnabc.tpi.media.scanneddocument.identification.DocumentTypeDescriptionIdentifier";
  }

  public description:string;
}
