import { Response } from "../../../../../../urdad/urdad_api";
import { YCInsuranceProduct } from "../../../../insurance_api";


export class FinaliseCreateYCInsuranceProductResponse extends Response {
    constructor() {
        super();
    }

    public product: YCInsuranceProduct;
}
