import {Injectable} from '@angular/core';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import {ActiveToast, IndividualConfig, ToastrService} from 'ngx-toastr'
import { environment } from '../../../../environments/environment';
import { ToastComponent } from '../../../view.components/shared/toast/toast.component';

@Injectable({
    providedIn: 'root'
})

export class ErrorToastService {

    activeToast: ActiveToast<any>;
    activeToastMap: {[id: string]: ActiveToast<any>} = {}

    constructor(private translate: TranslateService,
                private toastr: ToastrService,
                private translateParser: TranslateParser,){}

    errorToast(error){

        if(error.error){
            this.translate.get('TOAST.CHECKOUT_ERROR').subscribe((res: any) => {
                this.activeToast = this.toastr.error(error.error, res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        }else{
            this.translate.get('TOAST.DEFAULT_ERROR').subscribe((res: any) => {
                this.activeToast = this.toastr.error(this.translateParser.interpolate(res.MESSAGE), res.TITLE, {
                    toastComponent: ToastComponent,
                    timeOut: environment.toast_time_out,
                    progressBar: true
                });
            });
        }

    }

    success(message: string, title: string, config?: Partial<IndividualConfig>) {
        const key = `${title}${message}`
        if (this.activeToastMap[key]) {
            this.activeToastMap[key].toastRef.close()
            delete this.activeToastMap[key]
        }
        if (config) {
            this.activeToastMap[key] = this.toastr.success(message, title, config)
        } else {
            this.activeToastMap[key] = this.toastr.success(message, title, {
                toastComponent: ToastComponent,
                timeOut: environment.toast_time_out,
                progressBar: true,
                closeButton: true
            })
        }
        this.activeToastMap[key].toastRef.afterClosed().subscribe(() => {
            delete this.activeToastMap[key]
        })
        return this.activeToastMap[key]
    }

}
