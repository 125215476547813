import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tpi-terms-and-conditions',
  templateUrl: './tpi-terms-and-conditions.component.html',
  styleUrls: ['./tpi-terms-and-conditions.component.scss']
})
export class TpiTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
