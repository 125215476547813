import {Injectable} from '@angular/core'
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/internal/Observable"
import {
    FinaliseCreateInsuranceProductRequest,
    FinaliseCreateYCInsuranceProductRequest,
    MarshallerService,
    RetailVehicle
} from '@magnabc/tpi'
import {RuntimeConfigurationService} from '../../../../app.services/common/runtime-configuration/runtime-configuration.service'

@Injectable({
    providedIn: 'root'
})
export class InsuranceProductFinalisationManagerService {

  vehicles: RetailVehicle[] = [];

  constructor(private httpClient: HttpClient, private marshallerService: MarshallerService,
              private runtimeConfigurationService: RuntimeConfigurationService) {}

  private options = {
    observe: 'response' as 'body',
    ResponseType: 'json'
  };

  finaliseInsuranceProduct(finaliseCreateInsuranceProductRequest: FinaliseCreateInsuranceProductRequest): Observable<any> {
    const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/insuranceproductfinalisationmanager/finalisecreateinsuranceproduct';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateInsuranceProductRequest);
    return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
  }

  finaliseCreateYCInsuranceProduct(finaliseCreateYCInsuranceProductRequest: FinaliseCreateYCInsuranceProductRequest): Observable<any> {
    const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/insuranceproductfinalisationmanager/finalisecreateycinsuranceproduct';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseCreateYCInsuranceProductRequest);
    return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
  }

  finaliseUpdateInsuranceProduct(finaliseUpdateInsuranceProductRequest: any): Observable<any> {
    const provideSubscriptionsURI = this.runtimeConfigurationService.apiBaseUrl + '/retail/insurance/insuranceproductfinalisationmanager/finaliseupdateinsuranceproduct';
    const jsonRequest = this.marshallerService.marshallObjectToJSON(finaliseUpdateInsuranceProductRequest);
    return this.httpClient.post(provideSubscriptionsURI, jsonRequest, this.options);
  }
}
