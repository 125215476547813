import { LegalEntityIdentifier } from "../identification/models/legal-entity-identifier.model";
import { Address } from "../../location/geographic/models/address.model";
import { ContactPerson } from "./contact-person.model";
import { ContactNumber } from "./contact-number.model";
import { EmailAddress } from "./email-address.model";
import { PreferredAddressIdentifier } from "../../location/geographic/identification/models/preferred-address-identifier.model";
import { PreferredContactMethod } from "./preferred-contact-method.model";
import { ContactNumberIdentifier } from "../identification/models/contact-number-identifier.model";
import { EmailAddressIdentifier } from "../identification/models/email-address-identifier.model";


export abstract class LegalEntity{

    constructor(){}

	public legalEntityIdentifiers: LegalEntityIdentifier[] = [];
	public proxies: LegalEntity[]= [];
	public addresses: Address[] = [];
	public contactPersons: ContactPerson[]= [];
	public contactNumbers: ContactNumber[]= [];
	public emailAddresses: EmailAddress[]= [];
	public preferredAddress: PreferredAddressIdentifier;
	public preferredContactNumber: ContactNumberIdentifier;
	public preferredEmailAddress: EmailAddressIdentifier;
	public preferredContactMethod: PreferredContactMethod;

}
